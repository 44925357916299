import {
  EVENTFEEDBACK_LOADING,
  EVENTFEEDBACK_PROCESS,
  EVENTFEEDBACK_QUESTIONS,
  CREATE_EVENTFEEDBACK_ANSWER,
  SEND_EVENTFEEDBACK_LINK,
  EVENT_ERROR
} from "../Actions/types";

const initialState = {
  eventFeedback: [],
  loading: false,
  eventFeedbackQuestions: {},
  eventFeedbackAnswer: {},
  sendFeedbackLink: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case EVENTFEEDBACK_PROCESS: {
      return {
        ...state,
        loading: false,
        eventFeedback: action.payload.data
      };
    }
    case EVENTFEEDBACK_QUESTIONS: {
      return {
        ...state,
        loading: false,
        eventFeedbackQuestions: action.payload.data
      };
    }
    case CREATE_EVENTFEEDBACK_ANSWER:
      return {
        ...state,
        eventFeedbackAnswer: { success: true, data: action.payload }
      };
    case SEND_EVENTFEEDBACK_LINK:
      return {
        ...state,
        sendFeedbackLink: { success: true, data: action.payload }
      };
    case EVENTFEEDBACK_LOADING:
      return {
        ...state,
        loading: true
      };

    case EVENT_ERROR:
      return {
        ...state,
        loading: false,
        eventFeedback: {
          success: false,
          data: action.payload && action.payload.data
        }
      };

    default:
      return state;
  }
}
