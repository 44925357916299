import {
  PARTNER_OFFERS_ERROR,
  PARTNEROFFERS_LOADING,
  PARTNEROFFERS_ERRORS,
  UPDATE_PARTNER_OFFER,
  GET_PARTNER_OFFER
} from "../Actions/types";

const initialState = {
  loading: false,
  partneroffer: {},
  update_partneroffer: {},
  partneroffers: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_PARTNER_OFFER:
      return {
        ...state,
        partneroffers: action.payload && action.payload.data,
        loading: false
      };
    case PARTNER_OFFERS_ERROR:
      return {
        ...state,
        partneroffer: { success: true, data: action.payload }
      };
    case PARTNEROFFERS_LOADING:
      return {
        ...state,
        loading: true
      };
    case PARTNEROFFERS_ERRORS:
      return {
        ...state,
        loading: false,
        partneroffer: { success: false, data: action.payload.data }
      };
    case UPDATE_PARTNER_OFFER:
      return {
        ...state,
        loading: false,
        update_partneroffer: { success: true, data: action.payload.data }
      };
    default:
      return state;
  }
}
