import { combineReducers } from 'redux'
import authReducer from './authReducer'
import errorReducer from './errorReducer'
import companyReducer from './companyReducer'
import profileReducer from './profileReducer'
import eventsReducer from './eventsReducer'
import applicationReducer from './applicationReducer'
import participantReducer from './participantReducer'
import partnerReducer from './partnerReducer'
import associateReducer from './associateReducer'
import coworkerReducer from './coworkerReducer'
import spaceReducer from './spaceReducer'
import planReducer from './planReducer'
import plancategoryReducer from './plancategoryReducer'
import resourcecategoryReducer from './resourcecategoryReducer'
import bookingReducer from './bookingReducer'
import resourceReducer from './resourceReducer'
import visitorReducer from './visitorReducer'
import notificationReducer from './notificationReducer'
import roleTypeReducer from './roleTypeReducer'
import CompaniesServicesReducer from './CompaniesServicesReducer'
import partneroffersReducer from './partneroffersReducer'
import CoworkerSkillsReducer from './CoworkerSkillsReducer'
import foodcategoryReducer from './foodcategoryReducer'
import fooditemReducer from './fooditemReducer'
import AllClubReducers from './AllClubReducers'
import issueDepartmentReducer from './issueDepartmentReducer'
import issueCategoryReducer from './issueCategoryReducer'
import issueTitleReducer from './issueTitleReducer'
import HelpdeskReducer from './HelpdeskReducer'
import OrderReducer from './OrderReducer'
import securityVisitorReducer from './securityVisitorReducer'
import EditProfileReducer from './EditProfileReducer'
import RolesReducer from './RolesReducer'
import UserReducer from './userReducer'
import announcementReducer from './announcementReducer'
import InventoryCategoryReducer from './InventoryCategoryReducer'
import InventoryReducer from './InventoryReducer'
import EventFeedbackReducer from './EventFeedbackReducer'
import KnowledgeCenterReducer from './KnowledgeCenterReducer'
import AlliedServicesReducer from './AlliedServicesReducer'
import ReportBugListReducer from './ReportBugListReducer'
import CreditAccountReducer from './CreditAccountReducer'
import CommunityAmbassadorReducer from './CommunityAmbassadorReducer'
import merchandiseReducer from './merchandiseReducer'
import ReferFriendReducer from './ReferFriendReducer'
import SuggestFeatureReducer from './SuggestfeatureReducer'
import ClubCategoryReducer from './ClubCategoryReducer'
import DocumentReducer from './documentReducers'
import ReportAnalyticsReducer from './ReportAnalyticsReducer'

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  company: companyReducer,
  companies: companyReducer,
  profile: profileReducer,
  events: eventsReducer,
  applications: applicationReducer,
  participants: participantReducer,
  associates: associateReducer,
  partners: partnerReducer,
  coworkers: coworkerReducer,
  spaces: spaceReducer,
  plan_categories: plancategoryReducer,
  resource_categories: resourcecategoryReducer,
  bookingData: bookingReducer,
  plans: planReducer,
  resources: resourceReducer,
  visitors: visitorReducer,
  notifications: notificationReducer,
  roletypes: roleTypeReducer,
  servicelist: CompaniesServicesReducer,
  partneroffers: partneroffersReducer,
  coworkerskills: CoworkerSkillsReducer,
  all_food_category: foodcategoryReducer,
  food_items: fooditemReducer,
  // posts: postReducer,
  // club_categories: ClubCategoryReducer,
  clubs: AllClubReducers,
  // clublist: ClubDropdownReducer
  // posts: postReducer,
  club_categories: ClubCategoryReducer,
  // allclubs: AllClubReducers,
  // clublist: ClubDropdownReducer,
  all_issue_department: issueDepartmentReducer,
  all_issue_category: issueCategoryReducer,
  all_issue_title: issueTitleReducer,
  helpdesk: HelpdeskReducer,
  get_all_order: OrderReducer,
  securityVisitor: securityVisitorReducer,
  user: EditProfileReducer,
  roles: RolesReducer,
  manageuser: UserReducer,
  announcements: announcementReducer,
  inventoryCategory: InventoryCategoryReducer,
  inventory: InventoryReducer,
  eventFeedback: EventFeedbackReducer,
  knowledgeCenterData: KnowledgeCenterReducer,
  alliedServices: AlliedServicesReducer,
  reportBug: ReportBugListReducer,
  CreditAccount: CreditAccountReducer,
  communityAmbassador: CommunityAmbassadorReducer,
  allMerchandiseOrder: merchandiseReducer,
  referFriend: ReferFriendReducer,
  suggestfeature: SuggestFeatureReducer,
  document: DocumentReducer,
  reportAnalysisData: ReportAnalyticsReducer,
})
