/* eslint-disable camelcase */
import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router-dom";
import { Table, TableBody, TableHead, TableRow } from "@material-ui/core";
import DataNotFound from "../../Components/Common/DataNotFound";
import Cell from "../../Components/Common/Table/TableCell";
import TableHeader from "../../Components/Common/Table/TableHeader";
import {
  spacecapitalize,
  capitalize,
  specialCharacterSeparate,
} from "../../Utils/StringFunctions";
import ScreenHeader from "../../Components/Common/ScreenHeader";
import { getAllAlliedServices } from "../../Actions/AlliedServicesActions";
import Pagination from "../../Components/Common/Table/TablePagination";
import { CircularLoader } from "../../Components/Common/Spinner";

const moment = require("moment");

const styles = () => ({
  root: {
    backgroundColor: "#fff",
  },
  table: {
    minWidth: 650,
    marginTop: "20px",
  },
  main: {
    padding: "24px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "-20px",
  },
  ScreenHeader: {
    marginLeft: "-20px",
    width: "100%",
  },
  root1: {
    "&:nth-child(even)": {
      backgroundColor: "#FCFDFE",
    },
  },
  phone: {
    color: "#a2a2a2",
  },
});
// const StyledTableCell = withStyles((theme) => ({
//   head: {
//     backgroundColor: "#F8FAFF",
//     color: theme.palette.common.black,
//     borderBottom: "none",
//     padding: "8px 40px 8px 16px",
//   },
//   body: {
//     fontSize: 14,
//     textTransform: "normal",
//     borderBottom: "none",
//   },
// }))(Cell);
export class Inquiries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alliedServices: [],
      page: 0,
      rowsPerPage: 10,
    };
  }

  componentDidMount() {
    this.props.getAllAlliedServices();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading !== this.props.loading) {
      this.props.setLoading(this.props.loading);
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        loading: this.props.loading,
      });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.alliedServices !== prevState.alliedServices) {
      return {
        alliedServices: nextProps.alliedServices,
      };
    }
    return [];
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  render() {
    const { classes, alliedServices, loading } = this.props;
    const { page, rowsPerPage } = this.state;

    const itemLength = alliedServices ? alliedServices.length : 0;

    if (loading) {
      return <CircularLoader />;
    }
    if (alliedServices && alliedServices.length <= 0) {
      return (
        <DataNotFound
          type="event"
          text="You don't have any inquiries!!"
          subtext=""
        />
      );
    }
    return (
      <div className={classes.main}>
        <div className={classes.header}>
          <div className={classes.ScreenHeader}>
            <ScreenHeader type="Allied Services Inquiry" />
          </div>
        </div>
        <div className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableHeader width="2%">Sr. No.</TableHeader>
                <TableHeader width="3%" align="left">
                  Date
                </TableHeader>
                <TableHeader width="20%" align="left">
                  Name
                </TableHeader>
                <TableHeader align="left" width="10%">
                  Company Name
                </TableHeader>
                <TableHeader align="left" width="10%">
                  Email ID
                </TableHeader>
                <TableHeader width="15%" align="left">
                  Category
                </TableHeader>
                <TableHeader width="20%" align="left">
                  Description
                </TableHeader>
                <TableHeader width="15%" align="left">
                  Space Name
                </TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {alliedServices.length > 0 &&
                alliedServices
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, key) => {
                    return (
                      <TableRow className={classes.root1}>
                        <Cell align="left" width="2%">
                          {page * rowsPerPage + key + 1}
                        </Cell>
                        <Cell align="left" width="3%">
                          {moment(row.createdAt).format("DD/MM/YYYY")}
                        </Cell>
                        <Cell align="left" width="20%">
                          {spacecapitalize(
                            row.created_by &&
                              `${row.created_by.first_name} ${row.created_by.last_name}`
                          )}
                          <div className={classes.phone}>
                            {row.created_by && row.created_by.contact_number}
                          </div>
                        </Cell>
                        <Cell align="left" width="10%">
                          {capitalize(
                            row.company_id ? row.company_id.company_name : "-"
                          )}
                        </Cell>
                        <Cell align="left" width="10%">
                          {row.created_by && row.created_by.email}
                        </Cell>
                        <Cell align="left" width="15%">
                          {specialCharacterSeparate(row.allied_services)}
                        </Cell>
                        <Cell align="left" width="20%">
                          {row.details}
                        </Cell>
                        <Cell align="left" width="20%">
                          {spacecapitalize(row.space && row.space.space_title)}
                        </Cell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
          {alliedServices && alliedServices.length > 0 && (
            <Pagination
              count={itemLength}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    alliedServices: state.alliedServices.alliedServices,
    loading: state.alliedServices.loading,
  };
};
export default connect(mapStateToProps, { getAllAlliedServices })(
  withStyles(styles)(withRouter(Inquiries))
);
