import axios from "axios";
import {
  GET_FOOD_CATEGORY,
  FOOD_CATEGORY_LOADING,
  FOOD_CATEGORY_ERROR,
  ADD_FOOD_CATEGORY,
  GET_SINGLE_FOOD_CATEGORY,
  EDIT_FOOD_CATEGORY
} from "./types";
import { showSuccess, showError } from "./ActionsHelper";
import { transformErrorsFromApi } from "./TransformError";

//  Loading
export const setFoodcategoryLoading = () => {
  return {
    type: FOOD_CATEGORY_LOADING
  };
};

export const addfoodcategory = (
  userData,
  setErrors,
  history,
  setSubmitting
) => dispatch => {
  dispatch(setFoodcategoryLoading());
  axios
    .post("/api/foodcategory", userData)
    .then(result => {
      dispatch({
        type: ADD_FOOD_CATEGORY,
        payload: result.data
      });
      history.push("/food-categories");
      dispatch(showSuccess("Food Category"));
      setSubmitting(false);
    })
    .catch(err => {
      const error = err.response || {};
      dispatch({ type: FOOD_CATEGORY_ERROR, payload: error.data });
      dispatch(showError("There was some error while add food category"));
      setSubmitting(false);
      setErrors(transformErrorsFromApi(error));
    });
};

export const getFoodcategory = () => dispatch => {
  dispatch(setFoodcategoryLoading());
  axios
    .get(`/api/foodcategory`)
    .then(res => {
      dispatch({
        type: GET_FOOD_CATEGORY,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: FOOD_CATEGORY_ERROR,
        payload: err && err.response ? err.response.data : ""
      })
    );
};

export const getSingleFoodcategory = id => dispatch => {
  dispatch(setFoodcategoryLoading());
  axios
    .get(`/api/foodcategory/${id}`)
    .then(res => {
      dispatch({
        type: GET_SINGLE_FOOD_CATEGORY,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: FOOD_CATEGORY_ERROR,
        payload: err.response && err.response.data
      })
    );
};

export const editFoodcategory = (
  id,
  data,
  setErrors,
  setSubmitting
) => dispatch => {
  dispatch(setFoodcategoryLoading());
  axios
    .put(`/api/foodcategory/${id}`, data)
    .then(result => {
      dispatch({
        type: EDIT_FOOD_CATEGORY,
        payload: result.data
      });
      dispatch(showSuccess("", "Food Category edited successfully!"));
      setSubmitting(false);
    })
    .catch(err => {
      const error = err.response;
      dispatch({ type: FOOD_CATEGORY_ERROR, payload: {} });
      setSubmitting(false);
      setErrors(transformErrorsFromApi(error));
    });
};
