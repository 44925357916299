import axios from 'axios'
import {
  GET_ERRORS,
  VISITOR_ERRORS,
  GET_VISITORS,
  VISITOR_LOADING,
  ADD_VISITOR,
  UPDATE_VISITOR,
  GET_SINGLE_WALKTHROUGH_VISITORS,
  ADD_WALKTHROUGH_VISITOR_DETAIL,
  CHANGE_VISITOR_TYPE,
} from './types'
import { showSuccess, showError } from './ActionsHelper'
import { transformErrorsFromApi } from './TransformError'

//  Loading
export const setVisitorsLoading = () => {
  return {
    type: VISITOR_LOADING,
  }
}

export const getvisitors = (dashboard, page, search, searchWlakthrough) => (
  dispatch
) => {
  dispatch(setVisitorsLoading())
  let url = `/api/visitors/allwalkthrough`
  if (dashboard) {
    url = `/api/visitors?q=upcoming`
  } else if (search) {
    url = `/api/visitors?search=${search}`
  } else if (searchWlakthrough) {
    url = `/api/visitors/allwalkthrough?q=${searchWlakthrough}`
  }
  axios
    .get(url)
    .then((result) => {
      dispatch({
        type: GET_VISITORS,
        payload: result.data,
      })
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : '',
      })
    })
}

export const getvisitor = (dashboard, page, search, searchWlakthrough) => (
  dispatch
) => {
  dispatch(setVisitorsLoading())
  let url = '/api/visitors'
  if (dashboard) {
    url = `/api/visitors?q=upcoming`
  } else if (search) {
    url = `/api/visitors?search=${search}`
  } else if (searchWlakthrough) {
    url = `/api/visitors/allwalkthrough?q=${searchWlakthrough}`
  }
  axios
    .get(url)
    .then((result) => {
      dispatch({
        type: GET_VISITORS,
        payload: result.data,
      })
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : '',
      })
    })
}

export const changeVisitorType = (id, userData) => (dispatch) => {
  // dispatch(setVisitorsLoading());
  axios
    .put(`/api/visitors/change/type/${id}`, userData)
    .then((result) => {
      dispatch({
        type: CHANGE_VISITOR_TYPE,
        payload: result.data,
      })
      dispatch(showSuccess('', 'Visitor type updated successfully!'))
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        // payload: err.response.data
        payload: ((err || {}).response || {}).data || 'Error unexpected',
      })
    )
}

export const addvisitor = (userData, setErrors, setSubmitting, history) => (
  dispatch
) => {
  dispatch(setVisitorsLoading())
  axios
    .post('/api/visitors', userData)
    .then((result) => {
      dispatch({
        type: ADD_VISITOR,
        payload: result.data,
      })
      history.push('/visitors')
      dispatch(showSuccess('Visitor'))
      setSubmitting(false)
    })
    .catch((err) => {
      dispatch({ type: VISITOR_ERRORS, payload: err.data })
      dispatch(showError('There was some error while invite visitor. '))
      history.push('/visitors')
      setSubmitting(false)
      setErrors(transformErrorsFromApi(err))
    })
}
export const updateVisitor = (id, userData, setSubmitting) => (dispatch) => {
  // dispatch(setVisitorsLoading());
  axios
    .put(`/api/visitors/${id}`, userData)
    .then((result) => {
      dispatch({
        type: UPDATE_VISITOR,
        payload: result.data,
      })
      dispatch(showSuccess('', 'Status updated successfully!'))
      setSubmitting(false)
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        // payload: err.response.data
        payload: ((err || {}).response || {}).data || 'Error unexpected',
      })
    )
}

export const getSingleWalkthroughVisitor = (id) => (dispatch) => {
  dispatch(setVisitorsLoading())
  axios
    .get(`/api/visitors/getone/walkthrough/${id}?walkthroughby=communitymanager`)
    .then((result) => {
      dispatch({
        type: GET_SINGLE_WALKTHROUGH_VISITORS,
        payload: result.data,
      })
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : '',
      })
    })
}

export const insertWalkthroughVisitorDetail = (userData, setSubmitting) => (
  dispatch
) => {
  // dispatch(setVisitorsLoading());
  axios
    .put(`/api/visitors/add/walkthrough`, userData)
    .then((result) => {
      dispatch({
        type: ADD_WALKTHROUGH_VISITOR_DETAIL,
        payload: result.data,
      })
      dispatch(showSuccess('', 'Walkthrough details added successfully!'))
      setSubmitting(false)
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        // payload: err.response.data
        payload: ((err || {}).response || {}).data || 'Error unexpected',
      })
    )
}
