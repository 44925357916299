import axios from "axios";
import {
  GET_PLAN_CATEGORY,
  PLAN_CATEGORY_LOADING,
  ADD_PLAN_CATEGORY,
  ADD_PLAN_CATEGORY_ERROR,
  GET_SINGLE_PLAN_CATEGORY,
  EDIT_PLAN_CATEGORY,
  PLAN_ERRORS,
} from "./types";
import { showSuccess, showError } from "./ActionsHelper";
import { transformErrorsFromApi } from "./TransformError";

//  Loading
export const setPlancategoryLoading = () => {
  return {
    type: PLAN_CATEGORY_LOADING,
  };
};

export const addplancategory = (
  userData,
  setErrors,
  history,
  setSubmitting
) => (dispatch) => {
  dispatch(setPlancategoryLoading());
  axios
    .post("/api/category", userData)
    .then((result) => {
      dispatch({
        type: ADD_PLAN_CATEGORY,
        payload: result.data,
      });
      history.push("/manage-plans");
      dispatch(showSuccess("", "Plan category added successfully!"));
      setSubmitting(false);
    })
    .catch((err) => {
      dispatch({
        type: ADD_PLAN_CATEGORY_ERROR,
        payload: err && err.response ? err.response.data.data : "",
      });
      dispatch(showError(err.response.data.data));
    });
};

// eslint-disable-next-line no-unused-vars
export const getPlancategory = (id) => (dispatch) => {
  // eslint-disable-next-line no-shadow
  const id = localStorage.getItem("space_id");
  dispatch(setPlancategoryLoading());
  axios
    .get(`/api/category/${id}`)
    .then((res) => {
      dispatch({
        type: GET_PLAN_CATEGORY,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: PLAN_ERRORS,
        payload: err && err.response ? err.response.data : "",
      })
    );
};

//GET SINGLE PLAN TYPE
export const getSinglePlancategory = (id) => (dispatch) => {
  dispatch(setPlancategoryLoading());
  axios
    .get(`/api/category/getsingle/${id}`)
    .then((res) => {
      dispatch({
        type: GET_SINGLE_PLAN_CATEGORY,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: PLAN_ERRORS,
        payload: err.response && err.response.data,
      })
    );
};

export const editPlanCategory = (plan_id, data, setErrors, setSubmitting) => (
  dispatch
) => {
  dispatch(setPlancategoryLoading());
  axios
    .put(`/api/category/${plan_id}`, data)
    .then((result) => {
      dispatch({
        type: EDIT_PLAN_CATEGORY,
        payload: result.data,
      });
      dispatch(showSuccess("", "Plan Category edited successfully!"));
      setSubmitting(false);
    })
    .catch((err) => {
      const error = err.response;
      dispatch({ type: PLAN_ERRORS, payload: {} });
      setSubmitting(false);
      setErrors(transformErrorsFromApi(error));
    });
};
