/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import React, { useEffect, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
} from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";
import { createMuiTheme } from "@material-ui/core/styles";
// eslint-disable-next-line import/no-extraneous-dependencies
import { ThemeProvider } from "@material-ui/styles";
import {
  getSingleWalkthroughVisitor,
  insertWalkthroughVisitorDetail,
} from "../../Actions/visitorActions";
import { capitalize } from "../../Utils/StringFunctions";
import {
  sourceWalkthrougOptions,
  interestedProductsOptions,
} from "../../Utils/dropDownData";
import { InputField, SelectField } from "../../Components/Common/Form";
import TextareaField from "../../Components/Common/Form/TextareaField";

const $quoteSym = " ";

const styles = () => ({
  createTeamForm: {
    width: 500,
    padding: "8px",
  },
  formContent: {
    height: "400px",
  },
  modelHeader: {
    paddingBottom: "5px",
    position: "relative",
    "&:after": {
      top: "48px",
      left: "24px",
      width: "24px",
      borderBottom: "3px solid #fb0",
      content: `'${$quoteSym}'`,
      position: "absolute",
    },
  },
  mainTitle: {
    fontWeight: 600,
    fontSize: "14px",
    letterSpacing: "0.02em",
    color: " #252631",
  },
  iconImage: {
    width: "46px",
    height: "46px",
    margin: "5px 0px 15px 0px",
  },
  modalFooter: {
    padding: "40px 0px 8px",
    display: "flex",
    justifyContent: "flex-end",
  },
  cancelbutton: {
    marginRight: "20px",
    backgroundColor: "#dddddd",
    color: "#151039",
    "&:hover": {
      color: "#151039",
      backgroundColor: "#d1d1d1!important",
    },
  },
  addbutton: {
    color: "#fff",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  input_field: {
    border: "1px solid #949494",
    height: "81%",
    position: "relative",
    width: "100%",
    padding: "6px 8px",
    margin: "0px",
    color: "gray",
    // borderBottom: "none",
    fontFamily:
      "'-apple-system,BlinkMacSystemFont','Segoe UI','Roboto','Helvetica Neue','Arial,sans-serif','Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol'",
    // color: "currentColor",
    fontSize: "14px",
    background: "white",
  },
  descriptionLabel: {
    margin: "15px 0px 8px 0px",
    fontSize: "12px",
    fontWeight: "400",
    color: "rgba(0, 0, 0, 0.54)",
  },
});
const theme = createMuiTheme({
  overrides: {
    MuiInputBase: {
      input: {
        paddingLeft: "10px !important",
        fontSize: "14px",
        color: "lite",
      },
    },
  },
});
const addWalkthroughSchema = Yup.object().shape({
  other_space_considerations: Yup.string().required("This field is required."),
  source: Yup.string().required("This field is required."),
  interested_products: Yup.string().required("This field is required."),
  no_of_pax: Yup.number()
    .typeError("Please input a number.")
    .required("This field is required."),
  quoted_price: Yup.number()
    .typeError("Please input a number.")
    .required("This field is required."),
  visitor_location: Yup.string().required("This field is required."),
  visitor_building: Yup.string().required("This field is required."),
});

function AddWalkthroughDetailModal(props) {
  const { classes, visitorID, walkThroughInfo } = props;
  const [singleVisitor, setsingleVisitor] = useState([]); // all user
  const handleClose = () => {
    props.closeCreateModal();
  };

  useEffect(() => {
    if (props.add_walkthrough_detail && props.add_walkthrough_detail.success) {
      props.closeCreateModal();
    }
  }, [props.add_walkthrough_detail]);

  useEffect(() => {
    if (visitorID) {
      props.getSingleWalkthroughVisitor(visitorID);
    }
  }, [props.visitorID]);

  useEffect(() => {
    setsingleVisitor(props.single_walkthrough_visitor);
  }, [props.single_walkthrough_visitor]);

  const getInitialValues = (detailInserted) => {
    const visitorName = singleVisitor
      ? singleVisitor.visitor && singleVisitor.visitor.visitor_name
      : "";
    if (detailInserted === "yes") {
      const selectedProds =
        singleVisitor.interested_products &&
        interestedProductsOptions.filter((option) =>
          singleVisitor.interested_products.join().includes(option.value)
        );
      return {
        visitor: visitorID,
        visitor_name: visitorName,
        contact: singleVisitor
          ? singleVisitor.visitor && singleVisitor.visitor.contact
          : "",
        mail: singleVisitor
          ? singleVisitor.visitor && singleVisitor.visitor.email
          : "",
        company_name: singleVisitor
          ? singleVisitor.visitor && singleVisitor.visitor.visitor_company_name
          : "",
        source: sourceWalkthrougOptions.filter(
          (option) => option.value === singleVisitor.source
        )[0],
        interested_products: selectedProds,
        // singleVisitor.interested_products &&
        // interestedProductsOptions.filter((option) =>
        //   singleVisitor.interested_products.join().includes(option.value)
        // ),

        no_of_pax: singleVisitor.no_of_pax,
        quoted_price: singleVisitor.quoted_price,
        comments: singleVisitor.comments,
        visitor_location: singleVisitor.visitor_location,
        visitor_building: singleVisitor.visitor_building,
        other_space_considerations: singleVisitor.other_space_considerations,
        source_details: singleVisitor.source_details,
      };
    }
    return {
      visitor: visitorID,
      visitor_name: walkThroughInfo.name || visitorName,
      contact: singleVisitor
        ? singleVisitor.visitor && singleVisitor.visitor.contact
        : walkThroughInfo.contact,
      mail: singleVisitor
        ? singleVisitor.visitor && singleVisitor.visitor.email
        : walkThroughInfo.email,
      company_name: singleVisitor
        ? singleVisitor.visitor && singleVisitor.visitor.visitor_company_name
        : capitalize(walkThroughInfo.company_name),
      source: "",
      interested_products: [],
      no_of_pax: "",
      quoted_price: "",
      comments: "",
      visitor_location: "",
      visitor_building: "",
      other_space_considerations: "",
    };
  };

  return (
    <Dialog
      open={props.openCreateModal}
      onClose={props.closeCreateModal}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" className={classes.modelHeader}>
        {singleVisitor && singleVisitor.details_inserted === "yes"
          ? "View Walkthrough Details"
          : "Add Walkthrough Details"}
      </DialogTitle>

      <Formik
        initialValues={getInitialValues(
          singleVisitor && singleVisitor.details_inserted
        )}
        enableReinitialize
        validationSchema={addWalkthroughSchema}
        onSubmit={(values, { setSubmitting }) => {
          const sourceval = values.source ? values.source.value : "";
          values.source = sourceval;
          const prodVal =
            values.interested_products &&
            values.interested_products.map((skl) => skl && skl.value);
          values.interested_products = prodVal;

          props.insertWalkthroughVisitorDetail(values, setSubmitting);
          //   createTeamData(values, setSubmitting);
        }}
        render={({
          errors,
          touched,
          setFieldValue,
          values,
          setFieldTouched,
        }) => (
          <Form className={classes.createTeamForm}>
            <DialogContent className={classes.formContent}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6}>
                  <ThemeProvider theme={theme}>
                    <InputField
                      name="visitor_name"
                      label="Enter Name"
                      placeholder="Enter Name"
                      disabled={
                        singleVisitor &&
                        singleVisitor.details_inserted === "yes"
                      }
                    />
                  </ThemeProvider>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <ThemeProvider theme={theme}>
                    <InputField
                      name="contact"
                      label="Contact NO."
                      placeholder="91XXXXXXXX"
                      type="tel"
                      max="10"
                      disabled={
                        singleVisitor &&
                        singleVisitor.details_inserted === "yes"
                      }
                    />
                  </ThemeProvider>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <ThemeProvider theme={theme}>
                    <InputField
                      name="mail"
                      label="Mail ID"
                      placeholder="Enter Email ID"
                      disabled={
                        singleVisitor &&
                        singleVisitor.details_inserted === "yes"
                      }
                    />
                  </ThemeProvider>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <ThemeProvider theme={theme}>
                    <InputField
                      name="company_name"
                      label="Company Name"
                      placeholder="Enter Company Name"
                      disabled={
                        singleVisitor &&
                        singleVisitor.details_inserted === "yes"
                      }
                    />
                  </ThemeProvider>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <ThemeProvider theme={theme}>
                    <InputField
                      name="visitor_location"
                      label="Current Location"
                      placeholder="Enter Current Location"
                      disabled={
                        singleVisitor &&
                        singleVisitor.details_inserted === "yes"
                      }
                    />
                  </ThemeProvider>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <ThemeProvider theme={theme}>
                    <InputField
                      name="visitor_building"
                      label="Current Building"
                      placeholder="Enter Current Building"
                      disabled={
                        singleVisitor &&
                        singleVisitor.details_inserted === "yes"
                      }
                    />
                  </ThemeProvider>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <SelectField
                    value={values.source}
                    name="source"
                    id="source"
                    label="Source"
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    multi={false}
                    error={errors.source}
                    touched={touched.source}
                    disabled={
                      singleVisitor && singleVisitor.details_inserted === "yes"
                    }
                    options={sourceWalkthrougOptions}
                  />
                </Grid>
                {(values.source && values.source.value === "reference") ||
                (values.source && values.source.value === "sales_team") ||
                (values.source && values.source.value === "other") ? (
                  <Grid item xs={6} sm={6}>
                    <InputField
                      name="source_details"
                      label="Other Source"
                      placeholder="Enter Other Source"
                      disabled={
                        singleVisitor &&
                        singleVisitor.details_inserted === "yes"
                      }
                    />
                  </Grid>
                ) : (
                  ""
                )}
                <Grid item xs={6} sm={6}>
                  <SelectField
                    value={values.interested_products}
                    name="interested_products"
                    id="interested_products"
                    label="Interested Products"
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    multi
                    error={errors.interested_products}
                    touched={touched.interested_products}
                    options={interestedProductsOptions}
                    disabled={
                      singleVisitor && singleVisitor.details_inserted === "yes"
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormLabel
                    className={classes.descriptionLabel}
                    component="legend"
                  >
                    Other Co-working Space Consideration
                  </FormLabel>
                  <TextareaField
                    name="other_space_considerations"
                    className={classes.input_field}
                    rows="5"
                    placeholder="Type Here"
                    disabled={
                      singleVisitor && singleVisitor.details_inserted === "yes"
                    }
                  ></TextareaField>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <InputField
                    name="no_of_pax"
                    label="Number of PAX"
                    placeholder="Enter Number of PAX"
                    disabled={
                      singleVisitor && singleVisitor.details_inserted === "yes"
                    }
                    // type="tel"
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <InputField
                    name="quoted_price"
                    label="Quoted Price"
                    placeholder="Enter Quoted Price"
                    disabled={
                      singleVisitor && singleVisitor.details_inserted === "yes"
                    }
                    // type="tel"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormLabel
                    className={classes.descriptionLabel}
                    component="legend"
                  >
                    Comments
                  </FormLabel>
                  <TextareaField
                    name="comments"
                    className={classes.input_field}
                    rows="5"
                    placeholder="Type Here"
                    disabled={
                      singleVisitor && singleVisitor.details_inserted === "yes"
                    }
                  ></TextareaField>
                </Grid>
              </Grid>
              {singleVisitor && singleVisitor.details_inserted === "yes" ? (
                ""
              ) : (
                <Grid className={classes.modalFooter}>
                  <Button
                    onClick={handleClose}
                    color="primary"
                    className={classes.cancelbutton}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    className={classes.addbutton}
                    type="submit"
                    variant="contained"
                  >
                    Add
                  </Button>
                </Grid>
              )}
            </DialogContent>
          </Form>
        )}
      />
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    errors: state.errors,
    single_walkthrough_visitor: state.visitors.single_walkthrough_visitor,
    add_walkthrough_detail: state.visitors.add_walkthrough_detail,
  };
};

export default connect(mapStateToProps, {
  getSingleWalkthroughVisitor,
  insertWalkthroughVisitorDetail,
})(withStyles(styles)(AddWalkthroughDetailModal));
