/* eslint-disable no-else-return */
/* eslint-disable no-unused-expressions */
import React, { useEffect } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import {
  Dialog,
  DialogContent,
  Grid,
  Typography,
  Avatar,
} from "@material-ui/core";
import avatar from "../../Assets/coworker3.png";

const styles = () => ({
  registerTeammodal: {
    width: 500,
    padding: "34px 24px",
    overflow: "hidden",
  },
  member_modal: {
    minWidth: "400px",
  },
  main: {
    padding: "24px",
  },
  members: {
    fontSize: "18px",
    fontWeight: "600",
    color: "#222",
    paddingLeft: "4%",
    textTransform: "capitalize",
    lineHeight: "14px",
  },
  designationInfo: {
    fontSize: "16px",
    fontWeight: "normal",
    color: "#626262",
    paddingLeft: "4.5%",
    textTransform: "capitalize",
  },
  defaultmemberImage: {
    width: "48px",
    height: "48px",
    borderRadius: "29.5px",
    marginTop: "3px",
  },
  memberImage: {
    width: "60px",
    height: "60px",
    borderRadius: "29.5px",
    margin: "6px 2px",
  },
  UserDetail: {
    position: "relative",
    bottom: "54px",
    left: "57px",
  },
  coworkerSection: {
    borderBottom: "1px solid #efefef",
    height: "74%",
  },
  badgesTitle: {
    fontSize: "16px",
    fontWeight: "600",
    color: "#222",
    padding: "0% 4% 4% 0%",
    textTransform: "capitalize",
    lineHeight: "14px",
  },
  badgesImage: {
    border: "1px solid #efefef",
    boxShadow: "0 9px 10px rgba(0, 0, 0, 0.1), 0 4px 0px rgba(0,0,0,0.05)",
    transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
    padding: "10px",
    width: "100%",
  },
});

function BadgesDetailModal(props) {
  const { classes, coworkerData, type } = props;
  useEffect(() => {
    if (props.register_team && props.register_team.success) {
      props.close();
    }
  }, [props.register_team]);

  const renderBadges = () => {
    if (type === "freelancer") {
      return (
        coworkerData.user &&
        coworkerData.user.user_badge &&
        coworkerData.user.user_badge.map((element) => {
          return (
            <Grid item xs={4} sm={4}>
              <img
                src={element.picture}
                alt=""
                className={classes.badgesImage}
              />
            </Grid>
          );
        })
      );
    } else {
      return (
        coworkerData.user_id &&
        coworkerData.user_id.user_badge &&
        coworkerData.user_id.user_badge.map((element) => {
          return (
            <Grid item xs={4} sm={4}>
              <img
                src={element.picture}
                alt=""
                className={classes.badgesImage}
              />
            </Grid>
          );
        })
      );
    }
  };

  const renderUserDetail = () => {
    if (type === "freelancer") {
      return (
        <div className={classes.coworkerSection}>
          {coworkerData.user && coworkerData.user.profile_pic ? (
            <div className={classes.clubmemberImage}>
              <Avatar
                src={coworkerData.user && coworkerData.user.profile_pic}
                className={classes.memberImage}
              />
            </div>
          ) : (
            <img
              src={avatar}
              alt="avatar"
              className={classes.defaultmemberImage}
            />
          )}

          <div className={classes.UserDetail}>
            <Typography variant="subtitle1" className={classes.members}>
              {coworkerData.user && coworkerData.user.first_name}&nbsp;
              {coworkerData.user && coworkerData.user.last_name}
            </Typography>
            <Typography variant="subtitle1" className={classes.designationInfo}>
              {coworkerData.user && coworkerData.user.designation
                ? coworkerData.user && coworkerData.user.designation
                : "-"}
            </Typography>
          </div>
        </div>
      );
    } else {
      return (
        <div className={classes.coworkerSection}>
          {coworkerData.profile_pic ? (
            <div className={classes.clubmemberImage}>
              <Avatar
                src={coworkerData.profile_pic}
                className={classes.memberImage}
              />
            </div>
          ) : (
            <img
              src={avatar}
              alt="avatar"
              className={classes.defaultmemberImage}
            />
          )}

          <div className={classes.UserDetail}>
            <Typography variant="subtitle1" className={classes.members}>
              {coworkerData.first_name} {coworkerData.last_name}
            </Typography>
            <Typography variant="subtitle1" className={classes.designationInfo}>
              {coworkerData.designation ? coworkerData.designation : "-"}
            </Typography>
          </div>
        </div>
      );
    }
  };

  return (
    <Dialog
      open={props.data}
      onClose={props.close}
      aria-labelledby="form-dialog-title"
    >
      <DialogContent className={classes.registerTeammodal}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            {renderUserDetail()}
          </Grid>
        </Grid>
        <div className={classes.badgesSection}>
          <Typography variant="subtitle1" className={classes.badgesTitle}>
            Badges
          </Typography>
          <Grid container spacing={2}>
            {renderBadges()}
          </Grid>
        </div>
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    register_team: state.events.register_team,
    errors: state.errors,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withStyles(styles)(BadgesDetailModal));
