import React, { useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Grid, Typography, Button } from "@material-ui/core";
import OverallSatisfaction from "../../Assets/Overall-Satisfaction.png";
import StarStyle from "../Companies/StarStyle";

const styles = (theme) => ({
  subText: {
    margin: "10px 0px 10px",
    fontSize: "22px",
    color: "#000000",
    textAlign: "center",
    fontWeight: "600",
  },
  layout: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "15px",
  },
  thankyouSvg: {
    width: "300px",
  },
  button: {
    marginTop: "20px",
    background: "#211b4e !important",
    padding: "5px 20px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      borderRadius: "0px",
      // position: "absolute",s
      position: "fixed",
      bottom: "0",
      cursor: "pointer",
      "&:disabled": {
        backgroundColor: "lightgrey !important",
        border: "1px solid lightgrey",
        position: "fixed",
        bottom: "0",
      },
    },
    "&:disabled": {
      backgroundColor: "lightgrey !important",
      border: "1px solid lightgrey",
    },
  },
  description: {
    fontSize: "14px",
    lineHeight: "20px",
    color: "#626262",
    fontWeight: "normal",
  },

  input_field: {
    border: "1px solid #f5f5f5",
    height: "100%",
    position: "relative",
    width: "100%",
    padding: "6px 8px",
    margin: "0px",
    fontFamily:
      "'-apple-system,BlinkMacSystemFont','Segoe UI','Roboto','Helvetica Neue','Arial,sans-serif','Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol'",
    color: "grey",
    fontSize: "14px",
    resize: "none",
  },
  textLayout: {
    padding: "0 24%",
    display: "flex",
    justifyContent: "center",
  },
  starLayout: {
    padding: "5% 30%",
  },
  buttonLayout: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: "15px",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "0px",
    },
  },
  errmessage: {
    display: "none",
  },
  newerrormessage: {
    color: "red",
  },
});

const OverallSatisfactionPopup = (props) => {
  const { classes, questionsData, answerArray } = props;
  const [answerSheet, setAnswerSheet] = useState(answerArray);
  const [answerVal, setAnswerVal] = useState("");
  // const [setRating] = useState("");
  const [comment, setComment] = useState("");
  const [editorValidated, setEditorValidated] = useState(false);
  const [maxLength] = useState(750);

  const setSelectedStar = (val) => {
    // const ratingVal = val.rating;
    return val.rating;
    // setRating(ratingVal);
  };

  const setAnswerData = (id, answer) => {
    setAnswerVal(answer);
  };

  const onChangeDescription = (e) => {
    setComment(e.target.value);
  };

  const openOverallModal = () => {
    const questionId = questionsData[0]._id;
    const answerData = {
      process_id: props.processId,
      question_id: questionId,
      rating: answerVal,
      answer: "",
      type: "rating",
      comment,
    };

    answerSheet.push(answerData);
    setAnswerSheet(answerSheet);
    props.answerData(answerSheet);
    props.openOverallModal("thankyou");
  };

  const checkLengthValidation = (length) => {
    if (length >= maxLength) {
      setEditorValidated(true);
    } else {
      setEditorValidated(false);
    }
  };

  return (
    <Grid>
      <Grid container className={classes.layout}>
        <Grid item xs={12} sm={12} md={12} className={classes.layout}>
          <img
            alt="logo"
            src={OverallSatisfaction}
            className={classes.thankyouSvg}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Typography align="center" variant="h1" className={classes.subText}>
            Overall Satisfaction
          </Typography>
        </Grid>

        <Grid item lg={12} xs={12}>
          <Grid className={classes.textLayout}>
            <Typography align="center" variant="p">
              {questionsData[0].questions}
            </Typography>
          </Grid>
          <Grid className={classes.starLayout}>
            <StarStyle
              eventFeedback
              setSelectedStar={setSelectedStar}
              processId={props.processId}
              questionId={questionsData[0]._id}
              setRatingAnswer={setAnswerData}
            />
          </Grid>
          <Grid>
            <textarea
              rows="5"
              cols="5"
              type="text"
              placeholder="Add Comment here.."
              className={classes.input_field}
              value={comment}
              onChange={onChangeDescription}
              name="comment"
              maxLength={maxLength}
              onKeyUp={() => checkLengthValidation(comment.length)}
            />
            <span
              className={
                editorValidated === true
                  ? classes.newerrormessage
                  : classes.errmessage
              }
            >
              The text entered exceeds the maximum length
            </span>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.buttonLayout}>
        <Button
          className={classes.button}
          type="button"
          variant="contained"
          color="primary"
          size="small"
          disabled={answerVal === ""}
          onClick={openOverallModal}
        >
          Done
        </Button>
      </Grid>
    </Grid>
  );
};
export default withStyles(styles)(OverallSatisfactionPopup);
