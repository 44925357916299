import React, { useEffect, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { SelectField } from "./Form";
import { spacecapitalize } from "../../Utils/StringFunctions";

const styles = () => ({});

const ClubCategoryDropDown = (props) => {
  const {
    values,
    setFieldValue,
    setFieldTouched,
    errors,
    touched,
    categoryList,
  } = props;

  const [selectedValue, setSelectedValue] = useState([]);

  // eslint-disable-next-line no-shadow
  const getAvailableClubList = (clublist) => {
    return clublist
      ? clublist.map((clubList) => {
          return {
            value: clubList._id,
            label: spacecapitalize(clubList.clubcategory_name),
          };
        })
      : [];
  };

  useEffect(() => {
    if (values.club_category) {
      // eslint-disable-next-line no-shadow
      const selectedValue = [];
      // eslint-disable-next-line no-unused-expressions
      props.categoryList &&
        props.categoryList.map((club) => {
          if (club._id === values.club_category) {
            selectedValue.push({
              value: club._id,
              label: club.clubcategory_name,
            });
          }
          return null;
        });
      setFieldValue("club_category_id", selectedValue[0]);
      setSelectedValue(selectedValue[0]);
    }
  }, [props.categoryList]);

  const changeDropDown = (name, value) => {
    setSelectedValue(value);
    setFieldValue(name, value);
  };

  return (
    <SelectField
      value={selectedValue}
      name="club_category"
      id="club_category"
      label="Category"
      onChange={changeDropDown}
      onBlur={setFieldTouched}
      error={errors.clublist}
      touched={touched}
      options={getAvailableClubList(categoryList)}
    />
  );
};

const mapStateToProps = () => {
  return {
    // errors: state.errors,
    // clublist: state.club_categories.club_categories || [],
  };
};

export default connect(
  mapStateToProps,
  {}
)(withStyles(styles)(ClubCategoryDropDown));
