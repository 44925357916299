import React, { useState } from "react";
import { MenuItem, withStyles, FormControl } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import classNames from "classnames";
import { connect } from "react-redux";
import { capitalize } from "../../Utils/StringFunctions";
import { updateOrderProcessStatus } from "../../Actions/OrderActions";
import OrderProcessStatusType from "./Types/OrderProcessStatusType";

const styles = (theme) => {
  return {
    accept: {
      backgroundColor: theme.palette.applications.shortlisted,
    },
    delivered: {
      backgroundColor: theme.palette.applications.active,
    },
    pending: {
      backgroundColor: theme.palette.applications.inactive,
    },
    prepared: {
      backgroundColor: "#4CAF50",
    },
    inprocess: {
      backgroundColor: theme.palette.applications.trial,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 50,
    },
    statusDropdown: {
      color: "white",
      border: `1px solid ${theme.palette.border.color}`,
      borderRadius: `${theme.shape.borderRadius * 4}px`,
      // padding: "0px 5px 3px 16px",
      height: "25px",
      padding: "14px 12px 13px",
      fontSize: "10px",
      fontWeight: "500",
    },
    icon: {
      color: "white",
      right: "5px",
    },
  };
};
const OrderProcessStatus = (props) => {
  const [selected, setSelected] = useState(props.selectedItem);
  const { classes, id } = props;

  const callStatusChangeAPI = (el) => {
    props.updateOrderProcessStatus(id, {
      order_process_status: el.target.value,
    });
    setSelected(el.target.value);
  };

  return (
    <React.Fragment>
      <FormControl variant="filled" className={classes.formControl}>
        <Select
          className={classNames(classes[selected], classes.statusDropdown)}
          value={selected}
          onChange={callStatusChangeAPI}
          name="name"
          disableUnderline
          variant="standard"
          classes={{
            icon: classes.icon,
          }}
        >
          {OrderProcessStatusType.map((element) => {
            return (
              <MenuItem value={element} key={props.key}>
                {capitalize(element)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    errors: state.errors,
    selected: state.selected,
  };
};
export default connect(mapStateToProps, { updateOrderProcessStatus })(
  withStyles(styles)(OrderProcessStatus)
);
