import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core'
import { MuiPickersUtilsProvider } from 'material-ui-pickers'
import MomentUtils from '@date-io/moment'
// eslint-disable-next-line no-unused-vars
// import { checkPermission } from "./Utils/checkPermission";
import ToastProvider from './ToastManager'
import store from './store'
import setAuthToken from './Utils/setAuthToken'
import { setCurrentUser, logoutUser } from './Actions/authAction'
import { clearCurrentProfile } from './Actions/profileAction'
import PrivateRoute from './Components/Common/PrivateRoute'
import customTheme from './Theme'
import Login from './Screens/Login'
import Notification from './Screens/Notification/NotificationView'
import AdminManageUser from './Screens/ManageUser/AdminManageUser'
import AdminCompanies from './Screens/Companies/AdminCompanies'
import AllCompany from './Screens/Companies/adminCompany'
import AdminCoworkers from './Screens/Co-Workers/AdminCoworkers'
import CompanyPage from './Screens/Companies/CompanyPage'
import AdminSpaces from './Screens/Spaces/AdminSpaces'
import AdminPartners from './Screens/Partner/AdminPartners'
import CompanyForm from './Screens/Companies/CompanyForm'
import CoworkerForm from './Screens/Co-Workers/CoworkerForm'
import SpaceForm from './Screens/Spaces/SpaceForm'
import PartnerForm from './Screens/Partner/PartnerForm'
import Dashboard from './Screens/Dashboard/Dashboard'
import AdminBookings from './Screens/Booking/AdminBookings'
import AdminEvents from './Screens/Events/AdminEvents'
import EventForm from './Screens/Events/EventForm'
import Visitors from './Screens/Visitor/Visitors'
import VisitorForm from './Screens/Visitor/VisitorForm'
import BookingForm from './Screens/Booking/BookingForm'
import NotificationForm from './Screens/Notification/NotificationForm'
// eslint-disable-next-line import/no-named-as-default
import AdminManageResources from './Screens/Setup/AdminManageResources'
// eslint-disable-next-line import/no-named-as-default
// import AddPlan from "./Screens/Setup/AddPlan";
import AllRole from './Screens/Setup/ViewRole'
import AddRole from './Screens/Setup/AddRole'
// eslint-disable-next-line import/no-named-as-default
import AddResources from './Screens/Setup/AddResources'
import ViewaSpace from './Screens/Spaces/ViewaSpace'
import AdminPlan from './Screens/Setup/AdminPlan'
// eslint-disable-next-line import/no-named-as-default
import ViewaEvent from './Screens/Events/ViewaEvent'
import ComponentDemo from './Screens/Demo'
import NotFound from './Screens/NotFound'
import interceptor from './Utils/interceptor'
// eslint-disable-next-line import/no-named-as-default
import EventAttendesList from './Screens/Events/EventAttendesList'
import ViewPartner from './Screens/Partner/ViewPartner'
import PartnerOffers from './Screens/Partner/PartnerOffers'

// eslint-disable-next-line import/no-named-as-default
import Cafeteria from './Screens/Cafeteria/Cafeteria'
// eslint-disable-next-line import/no-named-as-default
import FoodCategory from './Screens/Cafeteria/FoodCategory'
import ViewAllOrders from './Screens/Cafeteria/ViewAllOrders'
import OrderStatus from './Screens/Cafeteria/OrderStatus'
// import AddFoodItem from "./Screens/Cafeteria/AddFoodItem";
// eslint-disable-next-line import/no-named-as-default
import AllClubs from './Screens/Clubs/AllClubs'
import ClubPlan from './Screens/Clubs/ClubCategory'
import ViewaClub from './Screens/Clubs/ViewaClub'
import IssueCategory from './Screens/Issue/IssueCategory'
import IssueDepartment from './Screens/Issue/IssueDepartment'
import IssueTitle from './Screens/Issue/IssueTitle'
import AdminHelpdesk from './Screens/Helpdesk/AdminHelpdesk'
import HelpdeskForm from './Screens/Helpdesk/HelpdeskForm'
import HelpdeskDetail from './Screens/Helpdesk/HelpdeskDetail'
import NewVisitorForm from './Screens/Visitor/NewVisitorForm'
import ManageUserForm from './Screens/ManageUser/ManageUserForm'
import VisitorEvent from './Screens/Visitor/VisitorEvent'
// import ForgetPassword from "./Screens/Login/newpassword";
import Newpassword from './Screens/Login/newpassword'
import Profile from './Screens/EditProfile/Profile'
import ChangePassword from './Screens/Login/ChangePassword'
import MyOrders from './Screens/Cafeteria/MyOrders'
import Settings from './Screens/Settings/index'
import signupConfirm from './Screens/Co-Workers/SignupConfirmation'
import AllAnnouncement from './Screens/Announcements/AdminAnnouncement'
import AllInventoryCategory from './Screens/Inventory/InventoryCategory'
import InventoryForm from './Screens/Inventory/InventoryForm'
import AdminInventory from './Screens/Inventory/Inventory'

import ViewTeamAttendees from './Screens/Events/ViewTeamAttendees'
import ManageArea from './Screens/Area/AdminManageArea'
import ViewPlan from './Screens/Setup/ViewPlan'
import EventFeedback from './Screens/Events/EventFeedback'
import AdminFreelancer from './Screens/Freelancer/AdminFreelancer'
import FreelancerForm from './Screens/Freelancer/FreelancerForm'
import ViewFreelancer from './Screens/Freelancer/ViewFreelancer'
import KnowledgeCenterForm from './Screens/Setup/KnowledgeCenterForm'
import MyWalkthroughDetail from './Screens/Visitor/MyWalkthroughDetail'
import AdminKnowledge from './Screens/Knowledge/index'
import AdminAlliedServices from './Screens/AlliedServices/index'
import AdminInquiry from './Screens/AlliedServices/Inquiries'
import AdminAmbassador from './Screens/Community-Ambassador/AdminAmbassador'
import CofirmAmbassadorRole from './Screens/Community-Ambassador/ConfirmAmbassador'
import ReportBug from './Screens/ReportBug'
import ALlIssueReport from './Screens/ReportBug/IssueReportList'
import ReportList from './Screens/Reports/ReportsList'
import AdminCreditAccount from './Screens/CreditAccount/AdminCreditAccount'
import CreditAccountDetail from './Screens/CreditAccount/CreditAccountDetail'
import Merchandise from './Screens/Merchandise/AdminMerchandise'
import AddtoCart from './Screens/Merchandise/AddtoCart'
import AdminReferFriend from './Screens/ReferFriend/AdminReferFriend'
import ReferFriendList from './Screens/ReferFriend/ReferFriendList'
import ReferFriendDetail from './Screens/ReferFriend/ReferFriendDetail'
import AdminSuggest from './Screens/Suggest-Feature/index'
import CofirmSuggestList from './Screens/Suggest-Feature/ConfirmSuggest'
// import DocumentList from "./Screens/Documents/DocumentList";
// import DocumentForm from "./Screens/Documents/DocumentForm";
interceptor()
// import getSpaces from "./Actions/spaceActions";

// require("./mocks/index");
const drawerWidth = 240

let theme = createMuiTheme(customTheme)

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: '#FFF',
      },
    },
    MuiButton: {
      label: {
        textTransform: 'initial',
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'initial',
        margin: '0 16px',
        minWidth: 0,
        [theme.breakpoints.up('md')]: {
          minWidth: 0,
        },
      },
      //       labelContainer: {
      //         padding: 0,
      //         [theme.breakpoints.up("md")]: {
      //           padding: 0
      //         }
      //       }
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#404854',
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    ...theme.mixins,
    toolbar: {
      minHeight: 48,
    },
  },
}

const styles = () => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  drawer: {
    // width: "fit-content",
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  mainContent: {
    flex: 1,
    background: '#eaeff1',
  },
})

// Check for token and set current user
if (localStorage.jwtToken) {
  // Set auth token header
  setAuthToken(localStorage.jwtToken)

  // decode token
  const decoded = jwt_decode(localStorage.jwtToken)

  // set user in
  store.dispatch(setCurrentUser(decoded))

  // Check for expired tockens
  const currentTime = Date.now() / 1000
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser())

    // clear current profile
    store.dispatch(clearCurrentProfile())

    // Redirect to Login
    window.location.href = '/login'
  }
}

class App extends Component {
  state = {
    // eslint-disable-next-line react/no-unused-state
    mobileOpen: false,
  }
  // componentDidMount() {
  //   this.props.getSpaces();
  // }

  render() {
    const { classes } = this.props
    const location = window.location.pathname
    // eslint-disable-next-line no-unused-vars
    let navBar = true
    if (location === '/' || location === '/challenges') {
      navBar = false
    }
    const role = localStorage.getItem('role')

    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <MuiThemeProvider theme={theme}>
          <Provider store={store}>
            <Router>
              <div className={classes.root}>
                <CssBaseline />
                {/* {navBar && (
                  <NavContainer
                    classes={classes}
                    mobileOpen={this.state.mobileOpen}
                    handleDrawerToggle={this.handleDrawerToggle.bind(this)}
                  />
                )} */}
                <div className={classes.appContent}>
                  {/* <Header onDrawerToggle={this.handleDrawerToggle} /> */}
                  <ToastProvider>
                    <main
                      className={classes.mainContent}
                      style={{ width: '100%' }}
                    >
                      {/* Router Code will go here */}
                      <Switch>
                        <Route exact path="/" component={Login} />
                        <Route exact path="/login" component={Login} />
                        <Route
                          exact
                          path="/reset/:id"
                          component={Newpassword}
                        />
                        <Route
                          exact
                          path="/event-feedback/:id"
                          component={EventFeedback}
                        />
                        <Route
                          exact
                          path="/new-visitor"
                          component={NewVisitorForm}
                        />
                        <Route
                          exact
                          path="/VisitorEvent"
                          component={VisitorEvent}
                        />
                        {role === 'security' && (
                          <PrivateRoute
                            exact
                            path="/dashboard"
                            component={Dashboard}
                          />
                        )}
                        {role === 'security' && (
                          <Route exact path="/visitors" component={Visitors} />
                        )}
                        <PrivateRoute
                          exact
                          path="/dashboard"
                          component={Dashboard}
                        />
                        <PrivateRoute
                          exact
                          path="/notifications"
                          component={Notification}
                        />
                        <PrivateRoute
                          exact
                          path="/companies"
                          component={AdminCompanies}
                        />
                        <PrivateRoute
                          exact
                          path="/community"
                          component={AllCompany}
                        />
                        adminCompany
                        <PrivateRoute
                          exact
                          path="/co-workers"
                          component={AdminCoworkers}
                        />
                        <PrivateRoute
                          exact
                          path="/users"
                          component={AdminManageUser}
                        />
                        <PrivateRoute
                          exact
                          path="/spaces"
                          component={AdminSpaces}
                        />
                        <PrivateRoute
                          exact
                          path="/space/add"
                          component={SpaceForm}
                        />
                        <PrivateRoute
                          path="/space/edit/:id"
                          component={SpaceForm}
                        />
                        <PrivateRoute
                          exact
                          path="/space/:id"
                          component={ViewaSpace}
                        />
                        <PrivateRoute
                          exact
                          path="/areas"
                          component={ManageArea}
                        />
                        <PrivateRoute
                          exact
                          path="/visitors"
                          component={Visitors}
                        />
                        <PrivateRoute
                          exact
                          path="/partners"
                          component={AdminPartners}
                        />
                        <PrivateRoute
                          exact
                          path="/bookings"
                          component={AdminBookings}
                        />
                        <PrivateRoute
                          exact
                          path="/events"
                          component={AdminEvents}
                        />
                        <PrivateRoute
                          exact
                          path="/company/add"
                          component={CompanyForm}
                        />
                        <PrivateRoute
                          path="/company/edit/:id"
                          component={CompanyForm}
                        />
                        <PrivateRoute
                          exact
                          path="/co-worker/add"
                          component={CoworkerForm}
                        />
                        <PrivateRoute
                          exact
                          path="/user/add"
                          component={ManageUserForm}
                        />
                        <PrivateRoute
                          exact
                          path="/user/edit/:id"
                          component={ManageUserForm}
                        />
                        <PrivateRoute
                          path="/co-worker/edit/:id"
                          component={CoworkerForm}
                        />
                        <PrivateRoute
                          exact
                          path="/visitors/add"
                          component={VisitorForm}
                        />
                        <PrivateRoute
                          exact
                          path="/partner/add"
                          component={PartnerForm}
                        />
                        <PrivateRoute
                          exact
                          path="/partner-offer/add"
                          component={PartnerOffers}
                        />
                        <PrivateRoute
                          exact
                          path="/partner-offer/edit/:id"
                          component={PartnerOffers}
                        />
                        <PrivateRoute
                          path="/partner/edit/:id"
                          component={PartnerForm}
                        />
                        <PrivateRoute
                          path="/partner/:id"
                          component={ViewPartner}
                        />
                        <PrivateRoute
                          exact
                          path="/company/:id"
                          component={CompanyPage}
                        />
                        <PrivateRoute
                          exact
                          path="/booking/add"
                          component={BookingForm}
                        />
                        <PrivateRoute
                          exact
                          path="/notification/add"
                          component={NotificationForm}
                        />
                        <PrivateRoute
                          exact
                          path="/events"
                          component={AdminEvents}
                        />
                        <PrivateRoute
                          exact
                          path="/event/add"
                          component={EventForm}
                        />
                        <PrivateRoute
                          path="/event/edit/:id"
                          component={EventForm}
                        />
                        <PrivateRoute
                          exact
                          path="/company/add"
                          component={CompanyForm}
                        />
                        <PrivateRoute
                          path="/event/:id"
                          component={ViewaEvent}
                        />
                        {/* <PrivateRoute exact path="/plans" component={AddPlan} /> */}
                        <PrivateRoute
                          exact
                          path="/view-plan"
                          component={ViewPlan}
                          viewtype="view"
                        />
                        <PrivateRoute exact path="/roles" component={AllRole} />
                        <PrivateRoute
                          exact
                          path="/role/add"
                          component={AddRole}
                        />
                        <PrivateRoute
                          path="/role/edit/:id"
                          component={AddRole}
                        />
                        <PrivateRoute
                          exact
                          path="/resources"
                          component={AddResources}
                        />
                        {/* <PrivateRoute path="/setup" component={SetupOptions} /> */}
                        <PrivateRoute
                          exact
                          path="/manage-resources"
                          component={AdminManageResources}
                        />
                        <PrivateRoute
                          exact
                          path="/manage-plans"
                          component={AdminPlan}
                        />
                        <PrivateRoute
                          exact
                          path="/club-categories"
                          component={ClubPlan}
                        />
                        <PrivateRoute
                          exact
                          path="/demo"
                          component={ComponentDemo}
                        />
                        <PrivateRoute
                          path="/events/attendees/:id"
                          component={EventAttendesList}
                        />
                        <PrivateRoute
                          path="/events/teamattendees/:id"
                          component={ViewTeamAttendees}
                        />
                        <PrivateRoute
                          exact
                          path="/cafeteria"
                          component={Cafeteria}
                        />
                        {/* {role === "cafe" && ( */}
                        <PrivateRoute
                          exact
                          path="/food-items"
                          component={Cafeteria}
                        />
                        {/* )} */}
                        <PrivateRoute
                          exact
                          path="/view-orders"
                          component={ViewAllOrders}
                        />
                        <PrivateRoute
                          exact
                          path="/food-categories"
                          component={FoodCategory}
                        />
                        <PrivateRoute
                          exact
                          path="/order-status"
                          component={OrderStatus}
                        />
                        {/* <PrivateRoute
                          exact
                          path="/fooditems"
                          component={AddFoodItem}
                        /> */}
                        <PrivateRoute
                          exact
                          path="/clubs"
                          component={AllClubs}
                        />
                        <PrivateRoute
                          exact
                          path="/issue-department"
                          component={IssueDepartment}
                        />
                        <PrivateRoute
                          exact
                          path="/issue-category"
                          component={IssueCategory}
                        />
                        <PrivateRoute
                          exact
                          path="/issue-title"
                          component={IssueTitle}
                        />
                        <PrivateRoute
                          exact
                          path="/club/:id"
                          component={ViewaClub}
                        />
                        <PrivateRoute
                          exact
                          path="/helpdesk"
                          component={AdminHelpdesk}
                        />
                        <PrivateRoute
                          exact
                          path="/helpdesk/add"
                          component={HelpdeskForm}
                        />
                        <PrivateRoute
                          path="/helpdesk/:id"
                          component={HelpdeskDetail}
                          paperType="noPaper"
                        />
                        <PrivateRoute
                          path="/refer-friends/:id"
                          component={ReferFriendDetail}
                          paperType="noPaper"
                        />
                        <PrivateRoute
                          path="/edit-profile"
                          component={Profile}
                          paperType="noPaper"
                        />
                        <PrivateRoute
                          path="/myorders"
                          component={MyOrders}
                          // paperType="noPaper"
                        />
                        <PrivateRoute
                          path="/changepassword"
                          component={ChangePassword}
                          paperType="noPaper"
                        />
                        <PrivateRoute
                          exact
                          path="/settings"
                          component={Settings}
                        />
                        <PrivateRoute
                          exact
                          path="/signup-confirm"
                          component={signupConfirm}
                        />
                        <PrivateRoute
                          exact
                          path="/announcement"
                          component={AllAnnouncement}
                        />
                        <PrivateRoute
                          exact
                          path="/inventory-categories"
                          component={AllInventoryCategory}
                        />
                        <PrivateRoute
                          exact
                          path="/inventories"
                          component={AdminInventory}
                        />
                        <PrivateRoute
                          exact
                          path="/inventory/add"
                          component={InventoryForm}
                        />
                        <PrivateRoute
                          exact
                          path="/inventory/edit/:id"
                          component={InventoryForm}
                        />
                        <PrivateRoute
                          exact
                          path="/freelancers"
                          component={AdminFreelancer}
                        />
                        <PrivateRoute
                          exact
                          path="/freelancer/add"
                          component={FreelancerForm}
                        />
                        <PrivateRoute
                          path="/freelancer/edit/:id"
                          component={FreelancerForm}
                        />
                        <PrivateRoute
                          exact
                          path="/freelancer/:id"
                          component={ViewFreelancer}
                        />
                        <PrivateRoute
                          exact
                          path="/information/add"
                          component={KnowledgeCenterForm}
                        />
                        <PrivateRoute
                          exact
                          path="/walkthrough-detail"
                          component={MyWalkthroughDetail}
                        />
                        <PrivateRoute
                          path="/knowledge-centre"
                          component={AdminKnowledge}
                          paperType="noPaper"
                        />
                        <PrivateRoute
                          exact
                          path="/allied-services"
                          component={AdminAlliedServices}
                          paperType="noPaper"
                        />
                        <PrivateRoute
                          exact
                          path="/allied-services-inquiry"
                          component={AdminInquiry}
                        />
                        <PrivateRoute
                          path="/community-ambassador"
                          component={AdminAmbassador}
                          paperType="noPaper"
                        />
                        <PrivateRoute
                          path="/community-ambassador-confirmation"
                          component={CofirmAmbassadorRole}
                        />
                        <PrivateRoute
                          path="/report-bug"
                          component={ReportBug}
                          paperType="noPaper"
                        />
                        <PrivateRoute
                          path="/report-bug-list"
                          component={ALlIssueReport}
                        />
                        <PrivateRoute
                          path="/report-list"
                          component={ReportList}
                        />
                        <PrivateRoute
                          path="/credit-accounts"
                          component={AdminCreditAccount}
                        />
                        <PrivateRoute
                          path="/credit-account/:id"
                          component={CreditAccountDetail}
                        />
                        <PrivateRoute
                          path="/merchandise"
                          component={Merchandise}
                        />
                        <PrivateRoute
                          path="/suggest-feature"
                          component={AdminSuggest}
                          paperType="noPaper"
                        />
                        <PrivateRoute
                          path="/suggest-feature-confirmation"
                          component={CofirmSuggestList}
                        />
                        <PrivateRoute
                          path="/refer-friend"
                          component={AdminReferFriend}
                          paperType="noPaper"
                        />
                        <PrivateRoute
                          path="/refer-friends"
                          component={ReferFriendList}
                        />
                        <PrivateRoute
                          paperType="noPaper"
                          path="/devx-store"
                          component={AddtoCart}
                        />
                        {/* <PrivateRoute
                          paperType="noPaper"
                          path="/document-list"
                          component={DocumentList}
                        />
                        <PrivateRoute
                          paperType="noPaper"
                          path="/document/add"
                          component={DocumentForm}
                        />
                        <PrivateRoute
                          paperType="noPaper"
                          path="/document/edit/:id"
                          component={DocumentForm}
                        /> */}
                        {/* <Route exact path="/forget" component={newpassword} /> */}
                        <PrivateRoute component={NotFound} />
                        {/* <Route exact path="/dashboard" component={Dashboard} /> */}
                      </Switch>
                      {/* <Route exact path="/company" component={CompaniesPage} />
                    {/* <Route exact path="/register" component={Register} /> */}
                    </main>
                  </ToastProvider>
                </div>
              </div>
            </Router>
          </Provider>
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
    )
  }
}

export default withStyles(styles, {
  withTheme: true,
})(App)
