/* eslint-disable no-shadow */
/* eslint-disable camelcase */
import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import CreateIcon from "@material-ui/icons/Create";
import * as Yup from "yup";
import { CircularLoader } from "../../Components/Common/Spinner";
import InputField from "../../Components/Common/Form/InputField";
import {
  addfoodcategory,
  getFoodcategory,
  editFoodcategory,
  getSingleFoodcategory,
} from "../../Actions/foodcategoryActions";
import ScreenHeader from "../../Components/Common/ScreenHeader";
import NoRecordsFound from '../../Components/Common/NoRecordsFound'

const $quoteSym = " ";
const styles = () => ({
  root: {
    backgroundColor: "#fff",
  },
  table: {
    minWidth: 650,
    marginTop: "20px",
  },
  addFoodCategory: {
    width: "100%",
    textAlign: "right",
  },
  resource_form_modal: {
    width: "500px",
  },
  form_modal: {
    minWidth: "500px",
  },
  main: {
    padding: "24px",
    paddingTop: "0px",
  },
  editbutton: {
    borderRadius: "50px",
  },
  editicon: {
    fontSize: "16px",
    marginRight: "6px",
  },
  modalHeader: {
    paddingBottom: "5px",
    fontSize: "20px",
    fontWeight: "600",
    position: "relative",
    "&:after": {
      top: "46px",
      left: "24px",
      width: "24px",
      borderBottom: "3px solid #fb0",
      content: `'${$quoteSym}'`,
      position: "absolute",
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  pageHeader: {
    margin: "0px -19px",
    width: "100%",
  },
  Cancelbutton: {
    marginRight: "20px",
    backgroundColor: "#dddddd",
    color: "#151039",
    "&:hover": {
      color: "#151039",
      backgroundColor: "#d1d1d1!important",
    },
  },
  Addbutton: {
    marginRight: "16px",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  foodCategorybutton: {
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  root1: {
    "&:nth-child(even)": {
      backgroundColor: "#FCFDFE",
    },
  },
});
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F8FAFF",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
    textTransform: "capitalize",
  },
}))(TableCell);
const FoodCategorySchema = Yup.object().shape({
  food_category: Yup.string().trim().required("This field is required."),
});
export class FoodCategory extends Component {
  constructor(props) {
    super(props);
    this.state = { all_food_category: [], id: "" };
  }

  state = {
    modalOpen: false,
  };

  componentDidMount() {
    const role = localStorage.getItem("role");
    if (role === "cafe") {
      this.props.getFoodcategory();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.all_food_category !== prevState.all_food_category) {
      return { all_food_category: nextProps.all_food_category };
    }
    return [];
  }

  componentDidUpdate(prevProps) {
    if (prevProps.all_food_category !== this.props.all_food_category) {
      let all_food_category = this.props.all_food_category || [];
      all_food_category = all_food_category.map((x, id) => {
        return Object.assign({}, x, {
          idx: id + 1,
        });
      });
      this.setState({
        all_food_category,
      });
    }
    if (prevProps.loading !== this.props.loading) {
      this.props.setLoading(this.props.loading);
      this.setState({
        loading: this.props.loading,
      });
    }
    if (prevProps.add_food_category !== this.props.add_food_category) {
      if (this.props.add_food_category.success) {
        this.props.getFoodcategory();
      }
    }
  }

  handleClickOpen = () => {
    this.setState({
      modalOpen: true,
      id: "",
    });
  };

  handleClickModalOpen = (id) => {
    this.setState({
      id,
      modalOpen: true,
    });
    this.props.getSingleFoodcategory(id);
  };

  handleClose = () => {
    this.setState(
      {
        modalOpen: false,
      },
      () => this.props.getFoodcategory()
    );
  };

  render() {
    const { classes, single_food_category } = this.props;
    const { loading, id } = this.state;
    if (loading) {
      return <CircularLoader />;
    }
    const getInitialValues = (id) => {
      if (id) {
        return {
          food_category: single_food_category.food_category,
        };
      }
      return {
        food_category: "",
      };
    };

    return (
      <div className={classes.main}>
        <div className={classes.header}>
          <div className={classes.pageHeader}>
            <ScreenHeader type="All Food Categories" />
          </div>
          <Dialog
            open={this.state.modalOpen}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
          >
            <Formik
              initialValues={getInitialValues(id)}
              validationSchema={FoodCategorySchema}
              onSubmit={(values, { setErrors, setSubmitting }) => {
                if (id) {
                  this.props.editFoodcategory(
                    id,
                    values,
                    setErrors,
                    setSubmitting
                  );
                } else {
                  this.props.addfoodcategory(
                    values,
                    setErrors,
                    this.props.history,
                    setSubmitting
                  );
                }
                this.handleClose();
                this.props.getFoodcategory();
                // this.props.history.push("/foodCategory");
              }}
            >
              {() => (
                <Form className={classes.resource_form_modal}>
                  <DialogTitle
                    id="food-category-dialog-title"
                    className={classes.modalHeader}
                  >
                    {id ? "Edit Food Category" : "Add Food Category"}
                  </DialogTitle>
                  <DialogContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <InputField
                          name="food_category"
                          label="Enter Food Category"
                          placeholder="Enter Food Category"
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={this.handleClose}
                      color="primary"
                      className={classes.Cancelbutton}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      className={classes.Addbutton}
                      type="submit"
                      variant="contained"
                    >
                      {id ? "Edit" : "Add"}
                    </Button>
                  </DialogActions>
                  {loading && <CircularLoader />}
                </Form>
              )}
            </Formik>
          </Dialog>
          <div className={classes.addFoodCategory}>
            <Button
              onClick={this.handleClickOpen}
              className={classes.foodCategorybutton}
              size="medium"
              variant="contained"
              color="primary"
            >
              Add Food Category
            </Button>
          </div>
        </div>
        {this.state.all_food_category === null ? <NoRecordsFound text="No records found" /> :

          <div className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Sr. No.</StyledTableCell>
                  <StyledTableCell align="left">Category</StyledTableCell>
                  <StyledTableCell align="left" />
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.all_food_category &&
                  this.state.all_food_category.map((item, id) => {
                    return (
                      <TableRow className={classes.root1} key={item._id}>
                        <StyledTableCell component="th" scope="row">
                          {id + 1}
                        </StyledTableCell>

                        <StyledTableCell align="left">
                          {item.food_category}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            className={classes.editbutton}
                            onClick={() => this.handleClickModalOpen(item._id)}
                          >
                            <CreateIcon
                              size="small"
                              className={classes.editicon}
                            />
                          Edit
                        </Button>
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    all_food_category: state.all_food_category.all_food_category,
    loading: state.all_food_category.loading,
    add_food_category: state.all_food_category.food_category,
    single_food_category: state.all_food_category.single_food_category,
    edit_food_category: state.all_food_category.edit_food_category,
  };
};
export default connect(mapStateToProps, {
  addfoodcategory,
  getFoodcategory,
  editFoodcategory,
  getSingleFoodcategory,
})(withStyles(styles)(withRouter(FoodCategory)));
