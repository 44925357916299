import React, { Component } from "react";
import {
  withStyles,
  Paper,
  Grid,
  Typography,
  InputAdornment,
  Button,
} from "@material-ui/core";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-formik-ui";
import * as Yup from "yup";
import { getUserData, editProfile } from "../../Actions/EditProfileActions";
import {
  Instagram,
  Linkedin,
  Twitter,
  FacebookRoundIcon,
} from "../../Assets/index";
import { BloodGroupOptions } from "../../Utils/dropDownData";
import { capitalize } from "../../Utils/StringFunctions";
import InputField from "../../Components/Common/Form/InputField";
import SelectField from "../../Components/Common/Form/SelectField";
import DatePickerField from "../../Components/Common/Form/DatePickerField";
import CoworkerSkillsDropdown from "../../Components/Common/Dropdowns/CoworkerSkillsDropdown";
import UploadField from "../../Components/Common/Form/UploadField";

const $quoteSym = " ";

const styles = () => {
  return {
    containerPaper: {
      // border: `1px solid ${theme.palette.border.color}`
      borderRadius: "5px",
      height: "200px",
      display: "flex",
      alignItems: "center",
    },
    profileImage: {
      height: "100px",
      width: "100px",
    },
    profileName: {
      fontFamily: "Open Sans",
      fontWeight: 600,
      fontSize: "20px",
      textAlign: "center",
      color: "#4d7cfe",
    },
    profileSkills: {
      fontFamily: "Open Sans",
      fontWeight: "normalize",
      fontSize: "14px",
      textAlign: "center",
      color: "#626262",
    },
    social: {
      height: "20px",
      width: "20px",
    },
    formfooter: {
      margin: "24px 15px",
      marginRight: "-2.3%",
    },
    Addbutton: {
      width: "148px",
      height: "46px",
      backgroundColor: "#211b4e !important",
      "&:hover": {
        backgroundColor: "#151039 !important",
      },
    },
    cancelbutton: {
      width: "148px",
      height: "46px",
      color: "#151039",
      backgroundColor: "#dddddd",
      marginRight: "20px",
      "&:hover": {
        color: "#151039",
        backgroundColor: "#d1d1d1!important",
      },
    },
    topContainer: {
      display: "flex",
      alignItems: "center",
    },
    formDiv: {
      marginTop: "3%",
      background: " white",
      padding: "3%",
      borderRadius: "5px",
      paddingTop: "0%",
    },
    uploadField: {
      paddingLeft: "2%",
    },
    thumbContainerClass: {
      "& img": {
        borderRadius: "50%",
        height: "100px",
        width: "100px",
      },
    },
    badgesImage: {
      border: "1px solid #efefef",
      boxShadow: "0 9px 10px rgba(0, 0, 0, 0.1), 0 4px 0px rgba(0,0,0,0.05)",
      transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
      padding: "10px",
      width: "100%",
      margin: "24px 0px",
    },
    badgeTitle: {
      margin: "20px 14px 0px",
      paddingBottom: "5px",
      fontSize: "16px",
      fontWeight: "500",
      width: "100%",
      position: "relative",
      "&:after": {
        top: "31px",
        left: "1px",
        width: "24px",
        borderBottom: "3px solid #fb0",
        content: `'${$quoteSym}'`,
        position: "absolute",
      },
    },
    badgesMain: {
      display: "flex",
      width: "100%",
    },
    badgesSection: {
      width: "22%",
      padding: "12px",
    },
    objname: {
      marginTop: "31px",
    },
  };
};
const phoneRegExp = /[1-9]{1}[0-9]{9}/;
const CoWorkerSchema = Yup.object().shape({
  first_name: Yup.string()
    .max(15, "test")
    .trim()
    .required("This field is required."),
  last_name: Yup.string()
    .max(15, "test")
    .trim()
    .required("This field is required."),
  contact_number: Yup.string()
    .matches(phoneRegExp, "Please enter valid number.")
    .required("This field is required."),
  // .min(10, "Please enter valid number."),
});

class Profile extends Component {
  state = {
    deletedKey: [],
  };

  componentDidMount() {
    this.props.getUserData();
  }

  shouldComponentUpdate() {
    if (this.state.deletedKey.length > 0) {
      return false;
    }
    return true;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      if (this.props.user.success) {
        this.props.getUserData();
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user !== prevState.user) {
      return { user: nextProps.user };
    }
    return [];
  }

  handleClose = () => {
    this.props.history.goBack();
  };

  formatValuesAndCallAPI = (data, setErrors, setSubmitting) => {
    const values = { ...data };
    values.user_id = localStorage.getItem("user_id");
    values.space = localStorage.getItem("space_id");
    const val = values.blood_group ? values.blood_group.value : "";
    values.blood_group = val;
    // const val2 = values.roletypeid ? values.roletypeid.value : "";
    // values.roletypeid = val2;
    const skill = values.skills && values.skills.map((skl) => skl && skl.label);
    values.skills = skill;
    const id = localStorage.getItem("user_id");
    this.props.editProfile(
      id,
      values,
      setErrors,
      setSubmitting,
      this.props.history
    );
  };

  setDelImageUrl = (url) => {
    const { deletedKey } = this.state;
    this.setState({ deletedKey: deletedKey.push(url) });
  };

  renderProfile(setFieldValue, values) {
    const { classes, user } = this.props;
    return (
      <div className={classes.containerPaper}>
        <Grid
          container
          direction="column"
          alignContent="center"
          className={classes.topContainer}
        >
          <Grid item className={classes.uploadField}>
            <UploadField
              label="Upload Profile Photo"
              name="profile_pic"
              id="profile_pic"
              onChange={setFieldValue}
              value={values.profile_pic}
              folder="profile/picture"
              thumbContainerClass={classes.thumbContainerClass}
              style={{ top: "-83px", left: "-22%" }}
              delImageUrl={this.setDelImageUrl}
            />
          </Grid>
          <Grid item>
            {user && (
              <Typography variant="h5" className={classes.profileName}>
                {capitalize(user.first_name)} {capitalize(user.last_name)}
              </Typography>
            )}
          </Grid>
          <Grid item>
            {user.company_id && (
              <Typography variant="h6" className={classes.profileSkills}>
                {capitalize(user.designation)} @{" "}
                {capitalize(user.company_id.company_name)}
              </Typography>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }

  render() {
    const { classes, user } = this.props;
    return (
      <React.Fragment>
        <div className={classes.formPaper}>
          <Formik
            initialValues={{
              first_name: user.first_name,
              last_name: user.last_name,
              profile_pic: user.profile_pic,
              // email: user.email,
              contact_number: user.contact_number,
              // company_id: user.company_id,
              designation: user.designation,
              date_of_birth:
                user.date_of_birth === null ? new Date() : user.date_of_birth,
              blood_group: BloodGroupOptions.filter(
                (option) => option.value === user.blood_group
              )[0],
              skills: user.skills,
              facebook: user.facebook,
              linkedin: user.linkedin,
              twitter: user.twitter,
              instagram: user.instagram,
              user_id: "",
            }}
            enableReinitialize
            validationSchema={CoWorkerSchema}
            onSubmit={(values, { setErrors, setSubmitting }) =>
              this.formatValuesAndCallAPI(values, setErrors, setSubmitting)
            }
          >
            {({
              values,
              errors,
              touched,
              setFieldTouched,
              setFieldValue,
              handleSubmit,
            }) => (
              <Form
                onSubmit={handleSubmit}
                className={classes.form}
                encType="multipart/form-data"
                autoComplete="off"
              >
                <Paper>{this.renderProfile(setFieldValue, values)}</Paper>
                <div className={classes.formDiv}>
                  <Grid container spacing={3} className={classes.formContainer}>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name="first_name"
                        label="First Name"
                        placeholder="Enter First Name"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name="last_name"
                        label="Last Name"
                        placeholder="Enter Last Name"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      className={classes.datePickerInput}
                      style={{ marginTop: "16px" }}
                    >
                      <DatePickerField
                        name="date_of_birth"
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        value={values.date_of_birth}
                        disableFuture
                        label="Birth Date"
                      />
                    </Grid>

                    {/* <Grid item xs={12} sm={6}>
                    <InputField
                      name="email"
                      label="Email Id"
                      placeholder="Enter Email Id"
                    />
                  </Grid> */}
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name="contact_number"
                        label="Contact No"
                        placeholder="Enter Contact No"
                        type="tel"
                        max="10"
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={6}>
                    <CompaniesDropdown
                      values={values} 
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      errors={errors}
                      touched={touched}
                      name="company_id"
                    />
                  </Grid> */}
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name="designation"
                        label="Designation"
                        placeholder="Enter Job Role"
                        className={classes.objname}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectField
                        value={values.blood_group}
                        name="blood_group"
                        id="blood_group"
                        label="Select Blood Group"
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        multi={false}
                        error={errors.blood_group}
                        touched={touched.blood_group}
                        options={BloodGroupOptions}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CoworkerSkillsDropdown
                        values={values.skills}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        errors={errors}
                        touched={touched}
                        name="skills"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        className={classes.margin}
                        name="facebook"
                        label="Facebook"
                        placeholder="Enter Facebook Profile Link"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img
                                src={FacebookRoundIcon}
                                alt=""
                                className={classes.social}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        className={classes.margin}
                        name="linkedin"
                        label="Linkedin"
                        placeholder="Enter Linkedin Profile Link"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img
                                src={Linkedin}
                                alt=""
                                className={classes.social}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        className={classes.margin}
                        name="twitter"
                        label="Twitter"
                        placeholder="Enter Twitter Profile Link"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img
                                src={Twitter}
                                alt=""
                                className={classes.social}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        className={classes.margin}
                        name="instagram"
                        label="Instagram"
                        placeholder="Enter Instagram Profile Link"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img
                                src={Instagram}
                                alt=""
                                className={classes.social}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    {/* <Typography
                      variant="subtitle1"
                      className={classes.badgeTitle}
                    >
                      My Badges
                    </Typography>
                    {user.user_id &&
                    user.user_id.user_badge &&
                    user.user_id.user_badge.length > 0 ? (
                      <div container spacing={2} className={classes.badgesMain}>
                        {user.user_id &&
                          user.user_id.user_badge &&
                          user.user_id.user_badge.map((element) => {
                            return (
                              <div
                                item
                                xs={4}
                                sm={3}
                                className={classes.badgesSection}
                              >
                                <img
                                  src={element.picture}
                                  alt=""
                                  className={classes.badgesImage}
                                />
                              </div>
                            );
                          })}
                      </div>
                    ) : (
                      ""
                    )} */}
                  </Grid>
                  <div className={classes.formfooter}>
                    <Grid
                      container
                      item
                      display="flex"
                      justify="flex-end"
                      alignItems="center"
                      direction="row"
                    >
                      <Grid xs={6} sm={2}>
                        <Button
                          color="grey"
                          className={classes.cancelbutton}
                          variant="contained"
                          onClick={this.handleClose}
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid xs={6} sm={2}>
                        <Button
                          variant="contained"
                          className={classes.Addbutton}
                          color="primary"
                          type="submit"
                        >
                          Update
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    errors: state.errors,
    // edit: state.user.edit
  };
};
export default connect(mapStateToProps, { getUserData, editProfile })(
  withStyles(styles)(Profile)
);
