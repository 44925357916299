/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { Star } from "react-feather";

const styles = () => {
  return {
    starSection: {
      padding: "4%",
      display: "flex",
      margin: "0 auto",
      alignItems: "flex-end",
      flexGrow: 1,
    },
    greyStar: {
      size: "22",
      fill: "#E5E5E5",
      color: "white",
      strokeWidth: "0",
    },
    yellowStar: {
      size: "22",
      fill: "#FFBB00",
      color: "white",
      strokeWidth: "0",
    },
  };
};

const FreelancerStarStyle = (props) => {
  const { classes, processId, defaultStar } = props;
  const [rating, setRating] = useState(
    Array.from(Array(defaultStar).keys()) || []
  );

  //   const [processRating, setProcessRating] = useState([]);
  const [ratingFlag, setRatingFlag] = useState(false);
  const starArray = [1, 2, 3, 4, 5];

  function setRatingVal(id, val) {
    // if (val < 2) {
    //   rating.push(1);
    // } else if (val < 3) {
    //   rating.push(1, 2);
    // } else if (val < 4) {
    //   rating.push(1, 2, 3);
    // } else if (val < 5) {
    //   rating.push(1, 2, 3, 4);
    // } else {
    //   rating.push(1, 2, 3, 4, 5);
    // }

    const newRating = Array.from(Array(val + 1).keys());

    // processRating.push({ process_id: id, star: newRating });
    setRating(newRating);
    // setProcessRating(processRating);
    setRatingFlag(true);
    props.setSelectedStar({ process_id: { _id: id }, rating: val });
  }

  useEffect(() => {
    props.defaultStar &&
      setRatingVal(props.defaultStar.process_id._id, props.defaultStar.rating);
  }, [props.defaultStar]);

  return (
    <div className={classes.starSection}>
      {starArray.map((val) => (
        <Star
          className={
            rating.includes(val) && ratingFlag
              ? classes.yellowStar
              : classes.greyStar
          }
          onClick={() => setRatingVal(processId, val)}
        />
      ))}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    errors: state.errors,
  };
};
export default connect(
  mapStateToProps,
  {}
)(withStyles(styles)(FreelancerStarStyle));
