import React from 'react'
import { Button, Paper, Typography, Card } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Circle } from 'react-feather'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PlaceOrder from '../../../Screens/Cafeteria/PlaceOrder'
import { checkPermission } from '../../../Utils'
import { updateOrderStatus } from '../../../Actions/OrderActions'
import { getallFoodItem } from '../../../Actions/foodItemActions'
import Settings from '../Settings'
import NameTruncate from '../NameTruncate'

const styles = (theme) => {
  return {
    cardGrid: {
      padding: `${theme.spacing(8)}px 0`,
    },
    card: {
      // height: "287px",
      display: 'flex',
      flexDirection: 'column',
      boxShadow: '0px 0px 20px rgba(0,0,0,0.05)',
      transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
      backgroundColor: '#FCFDFE',
      position: 'relative',
      '&:hover': {
        transform: 'scale(1.01)',
        boxShadow:
          'rgb(178 183 187 / 6%) 0px 2px 4px 6px, rgb(212 219 226 / 60%) 0px 2px 10px 0px',
      },
    },
    containerPaper: {
      border: `1px solid ${theme.palette.border.color}`,
    },
    foodImage: {
      width: '100%',
      height: '210px',
      borderTopLeftRadius: '5px',
      borderTopRightRadius: '5px',
      position: 'absolute',
    },
    cardimage: {
      height: '212px',
      width: '100%',
    },
    foodDetail: {
      padding: '4px 15px',
    },
    foodName: {
      fontWeight: 600,
      fontSize: ' 14px',
      lineHeight: '24px',
      color: ' #333',
      marginRight: '15px',
      textTransform: 'capitalize',
    },
    foodCategory: {
      fontWeight: 'normal',
      fontSize: ' 12px',
      lineHeight: '24px',
      color: ' #626262',
      textTransform: 'capitalize',
    },
    foodSection: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
    icon: {
      marginRight: '10px',
    },
    foodPrice: {
      color: '#000',
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: 600,
      paddingTop: '2%',
    },
    cardFooter: {
      textAlign: 'center',
      padding: '20px 10px',
    },
    moveButton: {
      color: '#673AB7 !important',
      backgroundColor: '#fff',
      borderRadius: ' 50px',
      borderColor: '#673AB7 !important',
      '&:hover': {
        backgroundColor: '#e6e6e6 !important',
        borderColor: '#673AB7 !important',
      },
    },
    cardMiddle: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    settingIcon: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  }
}

class CafeteriaCard extends React.Component {
  constructor(props) {
    super(props)
    const statusView = false
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      statusView,
    }
  }

  orderStatus = async () => {
    const id = this.props.data._id
    let values = ''
    if (this.props.foodstatus === 'available') {
      values = 'unavailable'
    } else {
      values = 'available'
    }
    const apiData = { status: values }
    await this.props.updateOrderStatus(id, apiData)
    setTimeout(() => {
      this.props.getallFoodItem()
    }, 1000)
  }

  render() {
    const { classes, data, foodstatus, isOrderView } = this.props
    // eslint-disable-next-line no-unused-vars
    let statusView = false

    if (this.props.match.path === '/food-items') {
      statusView = true
    }

    return (
      <Card className={classes.card}>
        <Paper className={classes.containerPaper}>
          <div className={classes.profileImage}>
            <div className={classes.cardimage}>
              <img
                className={classes.foodImage}
                src={data.food_photo}
                alt="Food"
              />
              {this.props.match.path === '/food-items' && (
                <div className={classes.settingIcon}>
                  <Settings
                    isEdit
                    message="Are you sure want to delete this club?"
                    editMethod={() => this.props.openEditModal(data)}
                  />
                </div>
              )}
            </div>
          </div>
          <div className={classes.foodDetail}>
            <div className={classes.foodSection} direction="row">
              <Typography
                component="h2"
                align="left"
                className={classes.foodName}
              >
                <NameTruncate text={data.food_name} amount={18} />
              </Typography>
              <Typography
                component="p"
                variant="subtitle2"
                className={classes.foodCategory}
              >
                <Circle size={5} className={classes.icon} fill="#ABABAB" />
                {data.food_category && data.food_category.food_category}
              </Typography>
            </div>
            <div className={classes.cardMiddle}>
              <Typography
                component="p"
                variant="subtitle2"
                className={classes.foodPrice}
              >
                ₹ {data.price}
              </Typography>
              {checkPermission('ORDER_CREATE') && !isOrderView && (
                <PlaceOrder
                  data={this.props.data}
                  addOrder={this.props.addOrder}
                />
              )}
            </div>
          </div>
        </Paper>
        {checkPermission('ORDER_CAFE_STATUS') && isOrderView && (
          <div className={classes.cardFooter}>
            {foodstatus === 'available' && (
              <Button
                size="small"
                variant="outlined"
                color="primary"
                className={classes.moveButton}
                onClick={this.orderStatus}
              >
                Move to Unavailable
              </Button>
            )}
            {foodstatus === 'unavailable' && (
              <Button
                size="small"
                variant="outlined"
                color="primary"
                className={classes.moveButton}
                onClick={this.orderStatus}
              >
                Move to Available
              </Button>
            )}
          </div>
        )}
      </Card>
    )
  }
}
CafeteriaCard.propTypes = {}
const mapStateToProps = (state) => {
  return {
    food_items: state.food_items,
  }
}
export default connect(mapStateToProps, { updateOrderStatus, getallFoodItem })(
  withStyles(styles)(withRouter(CafeteriaCard))
)
