/* eslint-disable react/prefer-stateless-function */
import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { NavLink } from "react-router-dom";
import { checkPermission } from "../../Utils/checkPermission";

// eslint-disable-next-line import/prefer-default-export
export class RenderMenu extends React.Component {
  render() {
    const role = localStorage.getItem("role");
    const { anchorEl, isMenuOpen } = this.props;
    return (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        open={isMenuOpen}
        onClose={this.props.handleMenuClose}
        style={{
          marginTop:
            role !== "cafe" && role !== "admin" && role !== "it"
              ? "25px"
              : "-6px",
        }}
      >
        {/* <MenuItem onClick={this.props.handleMenuClose}>Profile</MenuItem> */}
        {/* {checkPermission("SPACE_CREATE") && (
          <MenuItem
            component={NavLink}
            to="/spaces"
            onClick={this.props.handleMenuClose}
          >
            Manage spaces
          </MenuItem>
        )}
        <MenuItem
          component={NavLink}
          to="/setup/viewrole"
          onClick={this.props.handleMenuClose}
        >
          Manage Roles
        </MenuItem>
        <MenuItem
          component={NavLink}
          to="/manageuser"
          onClick={this.props.handleMenuClose}
        >
          Manage User
        </MenuItem>
        {checkPermission("RESOURCETYPE_VIEW") && (
          <MenuItem
            component={NavLink}
            to="/resource"
            onClick={this.props.handleMenuClose}
          >
            Resource Category
          </MenuItem>
        )}
        {checkPermission("RESOURCE_CREATE") && (
          <MenuItem
            component={NavLink}
            to="/setup/addresources"
            onClick={this.props.handleMenuClose}
          >
            Resources
          </MenuItem>
        )}
        {checkPermission("PLAN_CATEGORY_VIEW") && (
          <MenuItem
            component={NavLink}
            to="/plan"
            onClick={this.props.handleMenuClose}
          >
            Plan Category
          </MenuItem>
        )}
        {checkPermission("PLAN_VIEW") && (
          <MenuItem
            component={NavLink}
            to="/setup/addplan"
            onClick={this.props.handleMenuClose}
          >
            Plans
          </MenuItem>
        )}
        {checkPermission("CLUB_VIEW") && (
          <MenuItem
            component={NavLink}
            to="/club"
            onClick={this.props.handleMenuClose}
          >
            Club Category
          </MenuItem>
        )}
        {checkPermission("ADMIN_VIEW") && (
          <MenuItem
            component={NavLink}
            to="/issueDepartment"
            onClick={this.props.handleMenuClose}
          >
            Issue Department
          </MenuItem>
        )}
        {checkPermission("ADMIN_VIEW") && (
          <MenuItem
            component={NavLink}
            to="/issueCategory"
            onClick={this.props.handleMenuClose}
          >
            Issue Category
          </MenuItem>
        )}
        {checkPermission("ADMIN_VIEW") && (
          <MenuItem
            component={NavLink}
            to="/issueTitle"
            onClick={this.props.handleMenuClose}
          >
            Issue Title
          </MenuItem>
        )} */}

        {role !== "it" &&
          role !== "cafe" &&
          role !== "admin" &&
          role !== "security" && (
            <div style={{ padding: "5px 15px 0px 15px", width: "190px" }}>
              <span style={{ fontSize: "16px" }}>
                <b>{this.props.username && this.props.username}</b>
              </span>
              <br />
              <span>PIN : {this.props.userpin && this.props.userpin}</span>
              <hr style={{ marginBottom: "0px" }} />
            </div>
          )}
        {checkPermission("SETTING_MENU") && (
          <MenuItem
            component={NavLink}
            to="/settings"
            onClick={this.props.handleMenuClose}
          >
            Settings
          </MenuItem>
        )}
        {role !== "cafe" && role !== "admin" && role !== "security" && (
          <MenuItem
            component={NavLink}
            to="/edit-profile"
            onClick={this.props.handleMenuClose}
          >
            Edit Profile
          </MenuItem>
        )}
        {role !== "cafe" && role !== "security" && (
          <MenuItem
            component={NavLink}
            to="/myorders"
            onClick={this.props.handleMenuClose}
          >
            My Orders
          </MenuItem>
        )}
        {checkPermission("WALKTHROUGH_VIEW") && (
          <MenuItem
            component={NavLink}
            to="/walkthrough-detail"
            onClick={this.props.handleMenuClose}
          >
            My Walkthroughs
          </MenuItem>
        )}
        {role !== "it" && role !== "cafe" && role !== "security" && (
          <MenuItem
            component={NavLink}
            to="/changepassword"
            onClick={this.props.handleMenuClose}
          >
            Change Password
          </MenuItem>
        )}
        {role !== "it" &&
          role !== "cafe" &&
          role !== "security" &&
          checkPermission("KNOWLEDGE_CENTER_VIEW") && (
            <MenuItem
              component={NavLink}
              to="/knowledge-centre"
              onClick={this.props.handleMenuClose}
            >
              Knowledge Centre
            </MenuItem>
          )}
        {checkPermission("REFER_FRIEND_CREATE") &&
          checkPermission("REFER_FRIEND_VIEW") && (
            <MenuItem
              component={NavLink}
              to="/refer-friend"
              onClick={this.props.handleMenuClose}
            >
              Refer a Friend
            </MenuItem>
          )}
        {checkPermission("SUGGEST_FEATURE_CREATE") && (
          <MenuItem
            component={NavLink}
            to="/suggest-feature"
            onClick={this.props.handleMenuClose}
          >
            Suggest a Feature
          </MenuItem>
        )}
        {checkPermission("REPORT_BUG_CREATE") && (
          <MenuItem
            component={NavLink}
            to="/report-bug"
            onClick={this.props.handleMenuClose}
          >
            Report a Bug
          </MenuItem>
        )}
        {checkPermission("ALLIED_SERVICE_VIEW") && (
          <MenuItem
            component={NavLink}
            to="/allied-services-inquiry"
            onClick={this.props.handleMenuClose}
          >
            Allied Services Inquiry
          </MenuItem>
        )}

        <MenuItem onClick={this.props.logout}>Logout</MenuItem>
      </Menu>
    );
  }
}
