/* eslint-disable no-unused-expressions */
import React from "react";
import { withStyles, Typography, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import classNames from "classnames";
import rightMark from "../../Assets/rightMark.png";

const $quoteSym = " ";

const styles = () => {
  return {
    starSection: {
      padding: "4%",
      display: "flex",
      margin: "0 auto",
      alignItems: "flex-end",
      flexGrow: 1,
    },
    greyStar: {
      size: "22",
      fill: "#E5E5E5",
      color: "white",
      strokeWidth: "0",
    },
    yellowStar: {
      size: "22",
      fill: "#FFBB00",
      color: "white",
      strokeWidth: "0",
    },
    introduction: {
      border: "1px solid #efefef",
      borderRadius: "5px",
      margin: "2% 0%",
    },
    title: {
      color: "#4682b4",
      fontSize: "16px",
      fontWeight: "normal",
      padding: "1%",
    },
    historyTime: {
      marginLeft: "11px",
      color: "#ababab",
      fontWeight: "normal",
      paddingTop: "0px",
      fontSize: "12px",
      textTransform: "capitalize",
    },
    description: {
      fontSize: "14px",
      lineHeight: "20px",
      color: "#626262",
      fontWeight: "normal",
    },
    questionHeading: {
      display: "flex",
      position: "relative",
      "&:after": {
        content: `'${$quoteSym}'`,
        position: "absolute",
        // z-index: -1,
        top: "25px",
        bottom: "0",
        left: "1%",
        height: "270px",
        borderLeft: "2px dashed #ababab",
      },
    },
    heading: {
      fontWeight: "500",
      fontSize: "14px",
      textAlign: "left",
      color: "#626262",
      paddingLeft: "10px",
    },
    queAnsSection: {
      margin: "1% 0% 1% 4%",
      padding: "15px",
      border: "1px solid #efefef",
      borderRadius: "5px",
    },
    ansStyle: {
      borderRadius: "5px",
      textAlign: "center",
      margin: "10px 3px",
      padding: "5px",
      color: "#ababab",
      fontSize: "12px",
      background: "#EFEFEF",
    },
    feedBackScore: {
      margin: "1%",
      display: "flex",
      flexDirection: "column",
    },
    defaultYes: {
      background: "#D5EED3",
    },
    defaultNo: {
      background: "#F6E0E0",
    },
    defaultNotsure: {
      background: "#FBF7C9",
    },
    yes: {
      background: "#4CAF50",
      color: "#000000",
    },
    no: {
      background: "#F05757",
      color: "#000000",
    },
    notsure: {
      background: "#FFBB00",
      color: "#000000",
    },
  };
};

const ClientAnswers = (props) => {
  const { classes, answerData } = props;
  const resultData =
    answerData &&
    // eslint-disable-next-line func-names
    Object.keys(answerData).map(function (key) {
      return answerData[key];
    });

  return (
    <div className={classes.starSection}>
      <Grid item lg={12} xs={12}>
        {resultData &&
          resultData.slice(0, 4).map((feedback) => (
            <Grid>
              <div className={classes.questionHeading}>
                <img src={rightMark} alt=""></img>
                <Typography
                  className={classes.heading}
                  variant="subtitle1"
                  component="p"
                >
                  {feedback &&
                    feedback[0] &&
                    feedback[0].process_id &&
                    feedback[0].process_id.process_name}
                </Typography>
              </div>
              <div className={classes.queAnsSection}>
                {feedback &&
                  feedback.length > 0 &&
                  feedback.slice(0, 4).map(
                    (answer, count) =>
                      answer.answer && (
                        <div>
                          <Typography
                            className={classes.description}
                            variant="subtitle1"
                            component="p"
                          >
                            Q{count + 1}.{" "}
                            {answer.question_id && answer.question_id.questions}
                          </Typography>
                          <div style={{ display: "flex" }}>
                            <div
                              className={classNames(
                                classes.ansStyle,

                                answer.answer === "yes"
                                  ? classes.yes
                                  : classes.defaultYes
                              )}
                            >
                              Yes
                            </div>
                            <div
                              className={classNames(
                                classes.ansStyle,
                                answer.answer === "no"
                                  ? classes.no
                                  : classes.defaultNo
                              )}
                            >
                              No
                            </div>
                            <div
                              className={classNames(
                                classes.ansStyle,
                                answer.answer === "notsure"
                                  ? classes.notsure
                                  : classes.defaultNotsure
                              )}
                            >
                              NA
                            </div>
                          </div>
                        </div>
                      )
                  )}
              </div>
            </Grid>
          ))}
      </Grid>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    errors: state.errors,
  };
};
export default connect(mapStateToProps, {})(withStyles(styles)(ClientAnswers));
