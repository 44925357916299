import axios from "axios";
import {
  GET_ERRORS,
  GET_SPACES,
  SPACE_LOADING,
  ADD_SPACE,
  SPACE_ERROR,
  GET_SPACE,
  UPDATE_SPACE,
  GET_AREA,
  AREA_ERROR,
  CREATE_AREA,
  GET_SLOT,
  UPDATE_AREA,
  GET_SINGLE_AREA,
  CREATE_TASK
} from "./types";
import { showSuccess, showError } from "./ActionsHelper";

//  Loading
export const setSpacesLoading = () => {
  return {
    type: SPACE_LOADING
  };
};

export const addSpace = (
  userData,
  setErrors,
  setSubmitting,
  history
) => dispatch => {
  axios
    .post("/api/space", userData)
    .then(result => {
      dispatch({
        type: ADD_SPACE,
        payload: result.data
      });
      history.push("/spaces");
      dispatch(showSuccess("Space"));
      setSubmitting(false);
    })
    .catch(err => {
      const error = err.response;
      dispatch({ type: SPACE_ERROR, payload: error.data });
      setSubmitting(false);
      history.push("/spaces");
      dispatch(showError("There was some error while adding space!"));
    });
};

export const getSpaces = () => dispatch => {
  dispatch(setSpacesLoading());
  axios
    .get("/api/space")
    .then(res => {
      dispatch({
        type: GET_SPACES,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : ""
      })
    );
};

export const getSpace = id => dispatch => {
  dispatch(setSpacesLoading());
  axios
    .get(`/api/space/${id}`)
    .then(res => {
      dispatch({
        type: GET_SPACE,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: SPACE_ERROR,
        payload: {}
      })
    );
};

export const updateSpace = (
  id,
  data,
  setErrors,
  setSubmitting,
  history
) => dispatch => {
  dispatch(setSpacesLoading());
  axios
    .put(`/api/space/${id}`, data)

    .then(res => {
      dispatch({
        type: UPDATE_SPACE,
        payload: res.data
      });
      history.push("/spaces");
      dispatch(showSuccess(" ", "Space Updated Successfully!"));
      setSubmitting(false);
    })
    .catch(err => {
      const error = err.response;
      dispatch({ type: SPACE_ERROR, payload: error.data });
      setSubmitting(false);
    });
};

export const createArea = (data, setSubmitting) => dispatch => {
  axios
    .post(`/api/area`, data)
    .then(result => {
      dispatch({
        type: CREATE_AREA,
        payload: result.data
      });
      // history.push("/events");
      dispatch(showSuccess("Area"));
    })
    .catch(err => {
      dispatch({
        type: AREA_ERROR,
        payload: err && err.response ? err.response.data.data : ""
      });
      dispatch(showError(err.response.data.data));
    });
};

export const getArea = () => dispatch => {
  dispatch(setSpacesLoading());
  axios
    .get(`/api/area`)
    .then(res => {
      dispatch({
        type: GET_AREA,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: AREA_ERROR,
        payload: {}
      })
    );
};

export const getSlot = () => dispatch => {
  dispatch(setSpacesLoading());
  axios
    .get(`/api/slot`)
    .then(res => {
      dispatch({
        type: GET_SLOT,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: AREA_ERROR,
        payload: {}
      })
    );
};

export const getSingleArea = id => dispatch => {
  dispatch(setSpacesLoading());
  axios
    .get(`/api/area/getsingle/${id}`)
    .then(res => {
      dispatch({
        type: GET_SINGLE_AREA,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: AREA_ERROR,
        payload: {}
      })
    );
};

export const updateArea = (id, data, setSubmitting) => dispatch => {
  dispatch(setSpacesLoading());
  axios
    .put(`/api/area/update/${id}`, data)
    .then(res => {
      dispatch({
        type: UPDATE_AREA,
        payload: res.data
      });
      dispatch(showSuccess(" ", "Area updated successfully!"));
      setSubmitting(false);
    })
    .catch(err => {
      const error = err.response;
      dispatch({ type: AREA_ERROR, payload: error.data });
      setSubmitting(false);
    });
};

export const createTask = data => dispatch => {
  axios
    .post(`/api/task`, data)
    .then(result => {
      dispatch({
        type: CREATE_TASK,
        payload: result.data
      });
      dispatch(showSuccess("Task"));
    })
    .catch(err => {
      const error = err.response;
      dispatch({ type: AREA_ERROR, payload: error.data });
      dispatch(showError("There was some error add task!"));
      // setErrors(transformErrorsFromApi(error));
    });
};
