// import React from 'react';
import moment from "moment";

const SplitTime = () => {
  const timeSlotArray = [];
  let start = moment().toDate();
  start.setHours(0);
  start.setMinutes(0);
  start.setSeconds(0);
  let end = moment().toDate();
  end.setHours(24);
  end.setMinutes(0);
  end.setSeconds(0);
  start = Math.ceil(start / 30) * 30;

  let current = moment(start);
  let index = 0;
  while (current <= end) {
    index++;
    timeSlotArray.push({
      index,
      time: current.format("HH:mm"),
      // selectedSlotFlag: false,
    });
    current.add(30, "minutes");
  }
  return timeSlotArray;
};
export default SplitTime;
