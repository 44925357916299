import React, { useEffect, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { SelectField } from "../Form";
import { getServices } from "../../../Actions/ServicesActions";
import { capitalize } from "../../../Utils/StringFunctions";

const styles = () => ({});

const ServicelistDropDown = (props) => {
  const { values, setFieldValue, setFieldTouched, errors, touched } = props;
  const [servicelist, setCompanies] = useState([]);

  // eslint-disable-next-line no-shadow
  const getAvailableServicesOptions = (servicelist) => {
    return servicelist
      ? // eslint-disable-next-line no-shadow
        servicelist.map((servicelist) => {
          return {
            value: servicelist._id,
            label: capitalize(servicelist.label),
          };
        })
      : [];
  };

  useEffect(() => {
    props.getServices();
  }, []);

  useEffect(() => {
    setCompanies(props.servicelist);
  }, [props.servicelist]);

  return (
    <SelectField
      value={values.services}
      name="services"
      id="services"
      label="Services"
      onChange={setFieldValue}
      onBlur={setFieldTouched}
      error={errors.services}
      touched={touched}
      options={getAvailableServicesOptions(servicelist)}
      multi="true"
      creatable
    />
  );
};

const mapStateToProps = (state) => {
  return {
    errors: state.errors,
    servicelist: state.servicelist.servicelist,
  };
};

export default connect(mapStateToProps, { getServices })(
  withStyles(styles)(ServicelistDropDown)
);
