import {
  USER_DATA_ERROR,
  USER_DATA,
  USER_DATA_LOADING,
  EDIT_PROFILE_LOADING,
  EDIT_PROFILE_ERROR,
  EDIT_PROFILE,
  NOTIFICATION,
  GET_NOTIFICATIONS_ERROR,
} from "../Actions/types";

const initialState = {
  user: { user: {} },
  loading: false,
  // notificationData:[]
};
export default function (state = initialState, action) {
  switch (action.type) {
    case USER_DATA:
      return {
        ...state,
        user: action.payload.data,
        loading: false,
      };

    case USER_DATA_ERROR:
      return {
        ...state,
        error: { success: false, errors: action.payload },
      };

    case USER_DATA_LOADING:
      return {
        ...state,
        loading: true,
      };
    case EDIT_PROFILE:
      return {
        ...state,
        edit: action.payload.data,
        loading: false,
      };

    case EDIT_PROFILE_ERROR:
      return {
        ...state,
        error: { success: false, errors: action.payload },
      };

    case EDIT_PROFILE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case NOTIFICATION:
      return {
        ...state,
        notificationData: action.payload.data,
        loading: false,
      };
    case GET_NOTIFICATIONS_ERROR:
      return {
        ...state,
        error: { success: false, errors: action.payload },
      };

    default:
      return state;
  }
}
