import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { CssBaseline, Button, Grid } from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form } from "react-formik-ui";
import classNames from "classnames";
import { getUserData } from "../../Actions/EditProfileActions";
import avatar from "../../Assets/DevxLogo.png";
import InputField from "../../Components/Common/Form/InputField";
import UploadField from "../../Components/Common/Form/UploadField";
import {
  addAnnouncement,
  editAnnouncement,
  getSingleAnnouncement,
} from "../../Actions/AnnouncementActions";

const styles = () => ({
  main: {
    border: "1px solid #efefef",
    backgroundColor: "#fff",
  },
  addAnnouncement: {
    padding: "20px 19px 20px",
    boxShadow: "0px 3px 15px rgba(0, 0, 0, 0.05)",

    // boxShadow: "0px 3px 15px rgba(0, 0, 0, 0.05)"
  },
  editAnnouncement: {
    padding: "20px 19px 20px",
  },
  avatar: {
    height: "50px",
    maxWidth: "56px",
    marginBottom: "9px",
    fontSize: "40px",
    borderRadius: "50px",
    border: "1px solid #a3a3a3",
  },
  logo: {
    width: "100%",
    position: "relative",
    top: "-4px",
  },
  postbutton: {
    border: "1px solid #211b4e",
    lineHeight: "1.222",
    marginTop: "24px",
    color: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#f7f2ff !important",
    },
  },
  uploadPost: {
    padding: "0px !important",
  },
  postFooter: {
    textAlign: "right",
    padding: "0px 11px 0px",
  },
  imageIcon: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  imageDiv: {
    marginTop: "2.5%",
    paddingRight: "1%",
  },
  closeIcon: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "-2%",
  },
});
const AddPostSchema = Yup.object().shape({
  description: Yup.string()
    .trim()
    .max(700, "Post length must be less than 700 characters")
    .required("This field is required."),
});

class AddAnnouncement extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: true, deletedKey: [] };
  }

  componentDidMount() {
    // this.props.getUserData();
    // this.props.getSingleAnnouncement(this.props.id);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user !== prevState.user) {
      return { user: nextProps.user };
      // eslint-disable-next-line no-else-return
    } else if (nextProps.discussion !== prevState.discussion) {
      return {
        discussion: nextProps.discussion,
      };
    } else {
      return [];
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.discussion !== this.props.discussion) {
      this.setState({
        discussion: this.props.discussion,
      });
    }
  }

  handleClose = () => {
    this.setState({ open: false }, () => {
      this.props.handleEditClose();
    });
  };

  setDelImageUrl = (url) => {
    const keyArray = [];
    this.setState({ deletedKey: keyArray.push(url) });
  };

  render() {
    // eslint-disable-next-line camelcase
    const { classes, announcementData, announcement_id } = this.props;
    const { deletedKey } = this.state;
    const getInitialValues = () => {
      if (announcementData) {
        return {
          description: announcementData.description,
          media: announcementData.media,
          deleted_key: deletedKey,
        };
      }
      return {
        description: "",
        media: "",
      };
    };
    return (
      <React.Fragment>
        <CssBaseline />
        {this.state.open && (
          <main>
            <div className={classes.main}>
              <Formik
                initialValues={getInitialValues()}
                enableReinitialize
                validationSchema={AddPostSchema}
                onSubmit={(values, { setErrors, setSubmitting, resetForm }) => {
                  // eslint-disable-next-line no-param-reassign

                  // eslint-disable-next-line no-param-reassign
                  values.media = values.media !== "" ? values.media : [];
                  if (announcementData) {
                    this.props.editAnnouncement(
                      announcement_id,
                      values,
                      setErrors,
                      setSubmitting
                    );
                  } else {
                    this.props.addAnnouncement(
                      values,
                      setErrors,
                      setSubmitting
                    );
                    // this.props.getEventDiscussion(this.props.id);
                  }
                  resetForm(this.initialValues);
                }}
              >
                {({ values, setFieldValue, handleSubmit }) => (
                  <Form
                    className={classes.form}
                    onSubmit={handleSubmit}
                    encType="multipart/form-data"
                    autoComplete="off"
                  >
                    <div
                      className={classNames(
                        announcementData
                          ? classes.editAnnouncement
                          : classes.addAnnouncement
                      )}
                    >
                      <Grid container className={classes.root}>
                        <Grid
                          item
                          sm={12}
                          lg={12}
                          className={classes.imageIcon}
                        >
                          <div className={classes.imageDiv}>
                            <div className={classes.avatar}>
                              <img
                                alt=""
                                className={classes.logo}
                                src={avatar}
                              />
                            </div>
                          </div>
                          <InputField
                            name="description"
                            placeholder="Write a announcement..."
                            multiline
                            rowsMax={5}
                            max={700}
                          />
                        </Grid>

                        <Grid
                          item
                          sm={8}
                          lg={10}
                          className={classes.uploadPost}
                        >
                          {announcementData ? (
                            <UploadField
                              label="Upload Photo"
                              name="media"
                              id="media"
                              onChange={setFieldValue}
                              value={values.media}
                              data={announcementData.media}
                              multiple
                              folder="announcement/media"
                              delImageUrl={this.setDelImageUrl}
                            />
                          ) : (
                            <UploadField
                              label="Upload Photo"
                              name="media"
                              id="media"
                              onChange={setFieldValue}
                              value={values.media}
                              multiple
                              folder="announcement/media"
                              delImageUrl={this.setDelImageUrl}
                            />
                          )}
                        </Grid>
                        <Grid
                          xs={8}
                          sm={4}
                          lg={2}
                          className={classes.postFooter}
                        >
                          <Button
                            className={classes.postbutton}
                            variant="outlined"
                            type="submit"
                            disabled={
                              values.description.trim() === "" && !values.media
                            }
                          >
                            Post
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </main>
        )}
      </React.Fragment>
    );
  }
}

AddAnnouncement.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    discussion: state.events.discussion,
    post: state.clubs.post,
    single_announcement: state.announcements.single_announcement,
  };
};

export default connect(mapStateToProps, {
  addAnnouncement,
  getUserData,
  editAnnouncement,
  getSingleAnnouncement,
})(withStyles(styles)(AddAnnouncement));
