/* eslint-disable react/no-unused-state */
import withStyles from '@material-ui/core/styles/withStyles'
import React from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Paper,
  CardContent,
  Typography,
  CssBaseline,
} from '@material-ui/core'
import { connect } from 'react-redux'
import classNames from 'classnames'
import bannerImage from '../../Assets/company_default_banner.jpg'
import {
  Instagram,
  Linkedin,
  Twitter,
  FacebookRoundIcon,
  FacebookGrey,
  InstagramGrey,
  TwitterGrey,
  LinkedinGrey,
} from '../../Assets/index'
import { getCompany } from '../../Actions/companiesAction'
import defaultLogo from '../../Assets/default_logo.png'
import { checkPermission } from '../../Utils/checkPermission'
import { spacecapitalize } from '../../Utils/StringFunctions'
import { getAvailablePlans } from '../../Actions/planActions'
import CompanyTabs from './CompanyTabs'
import SocialButton from '../../Components/Common/SocialButton'

const moment = require('moment')

const styles = (theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: 'transparent',
    height: '-webkit-fill-available',
  },
  heroContent: {
    maxWidth: 600,
    margin: '0 auto',
    padding: `${theme.spacing(15)}px 0 ${theme.spacing(15)}px`,
  },
  logo: {
    minWidth: '70%',
  },
  orangeAvatar: {
    color: '#fff',
    backgroundColor: '#ffffff',
    width: '60%',
    height: 95,
    fontSize: '36px',
    position: 'relative',
    top: '-30px',
    left: '10%',
    border: '1px solid',
    borderRadius: '4px',
    objectFit: 'contain',
  },
  eventTitle: {
    margin: '11px 0px 0px -8px',
    paddingBottom: '8px',
  },
  eventsubtitle: {
    fontSize: '12px',
    color: 'grey',
    paddingTop: '4px',
  },
  eventbasicdetail: {
    backgroundColor: '#fff',
    position: 'relative',
  },
  iconContainer: {
    marginTop: '10px',
    justifyContent: 'flex-end',
    paddingRight: '16px',
  },
  social: {
    height: '26px',
    width: '26px',
    marginRight: '14px',
  },
  eventInfo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    textAlign: 'center',
  },
  monthly_credit: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  status: {
    textTransform: 'capitalize',
    fontSize: '14px',
    fontWeight: '600',
    color: '#4caf50',
  },
  eventParticipant: {
    marginTop: '-21px',
    marginRight: '20px',
  },
  eventmaintitle: {
    fontSize: '24px',
    fontWeight: '600',
    color: '#626262',
    textTransform: 'capitalize',
    lineHeight: 1.3,
  },
  eventsubtitle1: {
    color: '#bfbfbf',
    fontSize: '16px',
    textTransform: 'capitalize',
    lineHeight: 1.3,
  },
  eventsubtitle2: {
    color: '#435bf2',
    fontSize: '16px',
  },
  eventDate: {
    marginRight: '67px',
  },
  innertitle: {
    fontSize: '12px',
    color: '#ababab',
  },
  innercontent: {
    fontSize: '16px',
    color: '#626262',
  },
  credit: {
    fontSize: '12px',
    color: '#626262',
  },
  totalCredits: {
    display: 'grid',
  },
  websiteName: {
    textDecoration: 'none',
    color: '#4682B4',
    fontSize: '14px',
    '& active': {
      color: '#4682B4',
    },
  },
  fontSize: {
    fontSize: '18px',
  },
  subFont: {
    fontSize: '14px',
  },
  banner: { maxHeight: '300px' },
})

class CompanyPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.state.id = this.props.match.params.id
  }

  componentDidMount() {
    this.props.getCompany(this.state.id)
    if (
      localStorage.getItem('role') === 'admin' ||
      localStorage.getItem('role') === 'communitymanager'
    ) {
      this.props.getAvailablePlans()
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.company !== prevState.company) {
      return { company: nextProps.company }
    }
    return []
  }

  componentDidUpdate(prevProps) {
    if (prevProps.comapny !== this.props.comapny) {
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        comapny: this.props.comapny,
      })
    }
    if (prevProps.add_credit_topup !== this.props.add_credit_topup) {
      this.setState({
        add_credit_topup: this.props.add_credit_topup,
      })
      if (this.props.add_credit_topup.success) {
        this.props.getCompany(this.state.id)
        this.props.getAvailablePlans()
      }
    }
    if (prevProps.deletePlan !== this.props.deletePlan) {
      if (this.props.deletePlan.success) {
        this.props.getCompany(this.state.id)
        this.props.getAvailablePlans()
      }
    }
    if (prevProps.loading !== this.props.loading) {
      this.setState({
        loading: this.props.loading,
      })
    }
  }

  handleChange = (event, value) => {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ value })
  }

  render() {
    const { classes } = this.props
    const { company } = this.state
    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.mainsection}>
          <main>
            {/* <div
              style={{
                backgroundImage: `url(${
                  company.banner_image ? company.banner_image : bannerImage
                })`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                maxHeight: "300px"
              }}
            >
              <AppBar position="static" className={classes.appBar}>
                <Toolbar />
              </AppBar>
            </div> */}
            <div className={classes.banner}>
              <img
                src={company.banner_image ? company.banner_image : bannerImage}
                alt=""
                width="100%"
                height="50%"
                className={classes.banner}
              />
            </div>
            <div className={classNames(classes.layout, classes.cardGrid)}>
              {/* End hero unit */}
              <div className={classes.eventbasicdetail}>
                <Grid
                  container
                  item
                  lg={12}
                  xs={12}
                  style={{ paddingLeft: '20px', paddingRight: '20px' }}
                >
                  <Grid item lg={2} xs={2}>
                    <div className={classes.logo}>
                      <img
                        className={classes.orangeAvatar}
                        src={company.logo ? company.logo : defaultLogo}
                        alt="logo"
                      />
                    </div>
                  </Grid>
                  <Grid item lg={5} xs={5}>
                    <div className={classes.eventTitle}>
                      <Typography
                        variant="body1"
                        component="p"
                        className={classNames(
                          classes.eventmaintitle,
                          classes.fontSize
                        )}
                      >
                        {company.company_name}
                      </Typography>
                      <Typography
                        className={classNames(
                          classes.eventsubtitle1,
                          classes.subFont
                        )}
                        variant="subtitle1"
                        component="p"
                        style={{ paddingTop: '3px' }}
                      >
                        {spacecapitalize(company.industry)}
                      </Typography>
                      <Typography
                        className={classNames(
                          classes.eventsubtitle2,
                          classes.subFont
                        )}
                        variant="subtitle1"
                        component="p"
                      >
                        <a
                          href={company.website}
                          // eslint-disable-next-line react/jsx-no-target-blank
                          target="_blank"
                          className={classes.websiteName}
                          style={{ textDecoration: 'none' }}
                        >
                          {company.website}
                        </a>
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={5} lg={5}>
                    <Grid
                      container
                      className={classes.iconContainer}
                      spacing={2}
                    >
                      <Grid item>
                        <Paper className={classes.paper}>
                          {company.linkedin ? (
                            <SocialButton
                              type={Linkedin}
                              url={company.linkedin}
                            />
                          ) : (
                            <SocialButton type={LinkedinGrey} disable />
                          )}
                        </Paper>
                      </Grid>
                      <Grid item>
                        <Paper className={classes.paper}>
                          {company.facebook ? (
                            <SocialButton
                              type={FacebookRoundIcon}
                              url={company.facebook}
                            />
                          ) : (
                            <SocialButton type={FacebookGrey} disable />
                          )}
                        </Paper>
                      </Grid>
                      <Grid item>
                        <Paper className={classes.paper}>
                          {company.twitter ? (
                            <SocialButton
                              type={Twitter}
                              url={company.twitter}
                            />
                          ) : (
                            <SocialButton type={TwitterGrey} disable />
                          )}
                        </Paper>
                      </Grid>
                      <Grid item>
                        <Paper className={classes.paper}>
                          {company.instagram ? (
                            <SocialButton
                              type={Instagram}
                              url={company.instagram}
                            />
                          ) : (
                            <SocialButton type={InstagramGrey} disable />
                          )}
                        </Paper>
                      </Grid>
                    </Grid>
                    {checkPermission('COMPANY_CRITICAL_DETAILS') && (
                      <Paper className={classes.containerPaper}>
                        <CardContent className={classes.cardContent}>
                          <div className={classes.eventInfo}>
                            <div className={classes.eventParticipant}>
                              <Typography
                                variant="subtitle2"
                                component="p"
                                className={classes.innertitle}
                              >
                                Seats
                              </Typography>
                              <Typography
                                variant="subtitle1"
                                component="p"
                                className={classes.innercontent}
                              >
                                {company.total_seats}
                              </Typography>
                            </div>
                            <div className={classes.eventDate}>
                              <Typography
                                variant="subtitle2"
                                component="p"
                                className={classes.innertitle}
                                style={{ paddingLeft: '12px' }}
                              >
                                Monthly credit
                              </Typography>
                              <div className={classes.monthly_credit}>
                                <Grid
                                  item
                                  xs={2}
                                  lg={2}
                                  className={classes.totalCredits}
                                >
                                  <Typography
                                    variant="subtitle1"
                                    component="p"
                                    className={classes.innercontent}
                                  >
                                    {company.total_credits}
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    className={classes.credit}
                                  >
                                    Total
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={2}
                                  lg={2}
                                  className={classes.totalCredits}
                                >
                                  <Typography variant="subtitle1" component="p">
                                    {parseFloat(
                                      company.available_credits
                                    ).toFixed(2)}
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    className={classes.credit}
                                  >
                                    Remaining
                                  </Typography>
                                </Grid>
                              </div>
                            </div>
                            <div className={classes.contractDate}>
                              <Typography
                                variant="subtitle2"
                                component="p"
                                className={classes.innertitle}
                              >
                                Contract expiry
                              </Typography>

                              <Typography
                                variant="subtitle1"
                                component="p"
                                className={classes.innercontent}
                              >
                                {moment(company.contract_end_date).format(
                                  'DD/MM/YYYY'
                                )}
                              </Typography>
                              <Typography
                                variant="caption"
                                className={classes.status}
                              >
                                {company.status}
                              </Typography>
                            </div>
                          </div>
                        </CardContent>
                      </Paper>
                    )}
                  </Grid>
                </Grid>
              </div>
              <CompanyTabs
                company={this.state.company}
                id={this.state.id}
                plans={this.state.plans}
              />
            </div>
          </main>
        </div>
      </React.Fragment>
    )
  }
}

CompanyPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
  return {
    company: state.company.company,
    loading: state.company.loading,
    add_credit_topup: state.company.add_credit_topup,
    deletePlan: state.company.deletePlan,
    plans: state.plans,
  }
}

export default connect(mapStateToProps, { getCompany, getAvailablePlans })(
  withStyles(styles)(CompanyPage)
)
