import {
  GET_ALL_ORDER,
  ADD_ORDER,
  ADD_ORDER_LOADING,
  ADD_ORDER_ERRORS,
  ORDER_DELIVERED_PENDING,
  ORDER_PROCESS_STATUS,
  ORDER_STATUS,
  MAIN_ORDER_STATUS_SUCCESS,
  MAIN_ORDER_STATUS_ERRORS,
  MY_ORDERS,
  MY_ORDERS_ACCOUNT_DETAILS,
  GET_ORDER_ERRORS,
} from "../Actions/types";

const initialState = {
  add_order: null,
  get_all_order: null,
  loading: false,
  // get_order: null,
  my_orders: null,
  orderStatus: {},
  my_orders_account_details: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_ORDER:
      return {
        ...state,
        loading: false,
        get_all_order: action.payload && action.payload.data,
      };
    case ADD_ORDER:
      return {
        ...state,
        loading: false,
        add_order: {
          success: true,
          data: action.payload && action.payload.data,
        },
      };
    case ORDER_DELIVERED_PENDING:
      return {
        ...state,
        loading: false,
        get_order: action.payload && action.payload.data,
      };
    case ORDER_PROCESS_STATUS:
      return {
        ...state,
        loading: false,
        orderStatus: {
          success: true,
          data: action.payload && action.payload.data,
        },
      };
    case ORDER_STATUS:
      return {
        ...state,
        loading: false,
        orderStatus: {
          success: false,
          data: action.payload && action.payload.data,
        },
      };
    case ADD_ORDER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ADD_ORDER_ERRORS:
      return {
        ...state,
        loading: false,
        add_order: {
          success: false,
          data: action.payload && action.payload.data,
        },
      };
    case MAIN_ORDER_STATUS_ERRORS:
      return {
        ...state,
        loading: false,
        mainOrderStatusData: {
          data: action.payload && action.payload.data,
          success: false,
        },
      };
    case MAIN_ORDER_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        mainOrderStatusData: {
          data: action.payload && action.payload.data,
          success: true,
        },
      };
    case MY_ORDERS:
      return {
        ...state,
        loading: false,
        my_orders: action.payload && action.payload.data,
      };
    case MY_ORDERS_ACCOUNT_DETAILS:
      return {
        ...state,
        loading: false,
        my_orders_account_details: action.payload && action.payload.data,
      };
    case GET_ORDER_ERRORS:
      return {
        ...state,
        loading: false,
        get_order:
          action.payload && action.payload.data && action.payload.data.data,
      };
    default:
      return state;
  }
}
