import React from "react";
import {
  Grid,
  CssBaseline,
  Typography,
  AppBar,
  Toolbar,
  Hidden,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import clsx from "clsx";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import DevxLogo from "../../Assets/Whitelogo.png";
import BackgroundImage from "../../Assets/event_feedback_bg.png";
// import { makeStyles } from "@material-ui/core/styles";
import FeedbackPopup from "./FeedbackPopup";

const styles = (theme) => {
  return {
    root: {
      height: "100vh",
      backgroundColor: "#ffffff",
      top: "8%",
    },
    devxlogo: {
      height: "30%",
      width: "40%",
      display: "none",
      color: theme.palette.primary.main,
      [theme.breakpoints.up("xs")]: {
        display: "block",
      },
      marginLeft: "3.2%",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      backgroundColor: "#211b4e",
      color: theme.palette.primary.main,
    },
    appBarShift: {
      width: "100%",
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      height: "8%",
    },
    bgImage: {
      width: "102%",
      height: "100vh",
      display: "block",
      [theme.breakpoints.only("xs")]: {
        display: "none",
      },
    },
    toolbar: {
      minHeight: "55px",
    },
  };
};

const themes = createMuiTheme((newTheme) => ({
  overrides: {
    MuiBackdrop: {
      // Name of the component ⚛️ / style sheet
      root: {
        backgroundColor: "rgba(0,0,0,0)",
      },
    },
    MuiDialog: {
      paperWidthSm: {
        top: "5% !important",
      },
    },
    MuiToolbar: {
      regular: {
        minHeight: "55px",
      },
    },
    bgImage: {
      width: "102%",
      height: "100vh",
      display: "block",
      [newTheme.breakpoints.only("xs")]: {
        display: "none",
      },
      devxlogo: {
        height: "30%",
        width: "40%",
        display: "none",
        color: newTheme.palette.primary.main,
        [newTheme.breakpoints.up("xs")]: {
          display: "block",
        },
        marginLeft: "3.2%",
      },
    },
  },
}));
class EventFeedback extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: true,
    };
  }

  componentDidMount() {}

  handleClose = () => {
    this.setState({
      modalOpen: false,
    });
  };

  render() {
    const { classes } = this.props;
    // const classes = this.themes();
    return (
      <ThemeProvider theme={themes}>
        <div className={classes.root}>
          <Hidden only={["xs"]}>
            <AppBar
              position="fixed"
              className={clsx(classes.appBar, classes.appBarShift)}
            >
              <Toolbar className={classes.toolbar}>
                <Typography
                  component="h1"
                  variant="h6"
                  noWrap
                  className={classes.title}
                >
                  <div className={classes.ImageAlign}>
                    <img src={DevxLogo} alt="" className={classes.devxlogo} />
                  </div>
                </Typography>
              </Toolbar>
            </AppBar>
            <CssBaseline />
            <Grid>
              <img src={BackgroundImage} alt="" className={classes.bgImage} />
            </Grid>
          </Hidden>
          <FeedbackPopup
            modalOpen={this.state.modalOpen}
            handleClose={this.handleClose}
          />
        </div>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  errors: state.errors,
});

export default connect(mapStateToProps, {})(withStyles(styles)(EventFeedback));
