import {
  GET_SPACES,
  ADD_SPACE,
  GET_SPACE,
  UPDATE_SPACE,
  SPACE_ERROR,
  CREATE_AREA,
  GET_AREA,
  AREA_ERROR,
  GET_SLOT,
  UPDATE_AREA,
  GET_SINGLE_AREA,
  CREATE_TASK,
} from '../Actions/types'

const initialState = {
  spaces: [],
  space: null,
  loading: false,
  update_space: {},
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SPACES:
      return {
        ...state,
        spaces: action.payload.data,
      }
    case GET_SPACE:
      return {
        ...state,
        space: action.payload.data,
      }
    case UPDATE_SPACE:
      return {
        ...state,
        loading: false,
        update_space: { success: true, data: action.payload.data },
      }
    case ADD_SPACE:
      return {
        ...state,
        loading: false,
        space: { success: true, data: action.payload },
      }
    case CREATE_AREA:
      return {
        ...state,
        loading: false,
        add_area: { succes: true, data: action.payload && action.payload },
      }
    case CREATE_TASK:
      return {
        ...state,
        loading: false,
        create_task: { success: true, data: action.payload },
      }
    case GET_AREA:
      return {
        ...state,
        loading: false,
        all_area: action.payload && action.payload.data,
      }
    case GET_SLOT:
      return {
        ...state,
        loading: false,
        all_slot: action.payload && action.payload.data,
      }
    case UPDATE_AREA:
      return {
        ...state,
        loading: false,
        update_area: {
          success: true,
          data: action.payload && action.payload.data,
        },
      }
    case GET_SINGLE_AREA:
      return {
        ...state,
        loading: false,
        single_area: action.payload && action.payload.data,
      }
    case AREA_ERROR:
      return {
        ...state,
        loading: false,
        area: { success: false, data: action.payload && action.payload.data },
      }
    case SPACE_ERROR:
      return {
        ...state,
        error: { success: false, errors: action.payload },
      }
    default:
      return state
  }
}
