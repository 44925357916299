/* eslint-disable no-shadow */
import React from "react";
import {
  withStyles,
  CssBaseline,
  Grid,
  AppBar,
  Toolbar,
  Paper,
} from "@material-ui/core";
import { connect } from "react-redux";
import SearchBar from "../../Components/Common/SearchBar";
import NoRecordsFound from "../../Components/Common/NoRecordsFound";
import { CircularLoader } from "../../Components/Common/Spinner";
import EventCard from "../../Components/Common/Cards/EventCard";
import { getEvents } from "../../Actions/eventActions";
import AddFormButton from "../../Components/Common/AddFormButton";
import ScreenHeader from "../../Components/Common/ScreenHeader";

const styles = (theme) => ({
  paper: {
    padding: "0px  32px 0px 16px",
    marginTop: "-11px",
    flexGrow: 1,
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      padding: "0px  32px 0px 16px",
      marginTop: "-11px",
    },
  },
  appbar: {
    backgroundColor: "#ffffff",
    paddingTop: "23px",
  },
  root: {
    width: "100%",
    padding: "0px",
    marginBottom: "10px",
    paddingTop: "1%",
  },
  addevent: {
    color: "#fff",
    padding: "7px 16px",
    borderRadius: "4px",
    textDecoration: "none",
    fontSize: "0.875rem",
    fontWeight: "500",
    marginLeft: "18px",
    boxShadow: "rgba(137, 163, 169, 0.3) 0px 3px 5px 2px",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  DataNotFound: {
    marginTop: "10px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  events: {
    margin: "15px 0px",
  },
  cardDataHeader: {
    margin: "20px 27px",
    color: "#ababab",
    paddingBottom: "5px",
  },
  pastlayout: {
    marginTop: "25px",
  },
});

class AdminEvents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [], // all events
      page: 0,
      noMoreData: false,
      isSearch: false, // search event
      search: "",
    };
  }

  componentDidMount() {
    this.props.getEvents(this.state.page, "", "upcoming"); //  get upcoming events
    this.props.getEvents(this.state.page, "", "past"); // get past events
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.noMoreData !== prevState.noMoreData) {
      return {
        noMoreData: nextProps.noMoreData,
      };
    }

    if (nextProps.events !== prevState.events) {
      return { events: nextProps.events };
    }
    if (nextProps.sendEventFeedback !== prevState.sendEventFeedback) {
      return { events: nextProps.events };
    }
    return [];
  }

  // when any event action perform the component has been updated
  componentDidUpdate(prevProps) {
    if (prevProps.events !== this.props.events) {
      this.setState({
        events: this.props.events,
      });
    }

    if (prevProps.events.loading !== this.props.events.loading) {
      this.props.setLoading(this.props.events.loading);
      this.setState({
        loading: this.props.events.loading,
      });
    }
    if (prevProps.event_participant !== this.props.event_participant) {
      this.props.getEvents(this.state.page, "", "upcoming");
      this.props.getEvents(this.state.page, "", "past");
    }
    if (
      this.props.register_team !== prevProps.register_team &&
      this.props.register_team.success
    ) {
      this.props.getEvents(this.state.page, "", "upcoming");
    }
    if (prevProps.sendEventFeedback !== this.props.sendEventFeedback) {
      this.props.getEvents(this.state.page, "", "upcoming");
      this.props.getEvents(this.state.page, "", "past");
    }
  }

  // for search both upcoming and past event
  searchEvent = (search) => {
    this.setState({ isSearch: true, search });
    this.props.getEvents(this.state.page, search, "upcoming");
    this.props.getEvents(this.state.page, search, "past");
  };

  // TODO: in next release for lazy loading
  // showDivChange = () => {
  //   const { page } = this.state;
  //   const { noMoreData } = this.state;
  //   if (!noMoreData) {
  //     this.props.getEvents(page + 1);
  //     this.setState({
  //       page
  //     });
  //   }
  // };

  render() {
    const { classes } = this.props;
    const { events, isSearch, search, loading } = this.state;
    const { past, upcoming } = events;

    // for event no search data found
    function renderNoSearchData() {
      if (
        upcoming &&
        upcoming.length === 0 &&
        past &&
        past.length === 0 &&
        isSearch
      ) {
        return (
          <NoRecordsFound text={`No records found for your text "${search}"`} />
        );
      }
      if (
        upcoming &&
        upcoming.length === 0 &&
        past &&
        past.length === 0 &&
        !isSearch
      ) {
        return <NoRecordsFound text="No events found." />;
      }
      return null;
    }

    //  render event(upcoming and past) card
    const renderEventCards = (events) => {
      return (
        events &&
        events.map((element) => {
          return (
            <Grid item lg={4} xs={12} sm={6} key={element.id}>
              <EventCard data={element} history={this.props.history} />
            </Grid>
          );
        })
      );
    };

    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.header}>
          <ScreenHeader type="All Events" />
          <AddFormButton type="Event" url="/event/add" />
        </div>
        <div className={classes.root}>
          <AppBar position="static" className={classes.appbar}>
            <Toolbar>
              <SearchBar
                type="Search Events"
                onSearch={this.searchEvent}
                value={this.state.search}
              />
              {/* <div className={classes.grow} /> */}
            </Toolbar>
          </AppBar>
        </div>

        {/* search events(upcomming)   */}
        {renderNoSearchData(upcoming, past, isSearch)}
        {upcoming && upcoming.length === 0 ? (
          ""
        ) : (
          <main className={classes.layout}>
            {loading && <CircularLoader />}
            <span className={classes.cardDataHeader}>Upcoming Events</span>{" "}
            <Paper className={classes.paper}>
              <Grid container spacing={2} className={classes.events}>
                {renderEventCards(upcoming)}
              </Grid>
            </Paper>
          </main>
        )}
        {/* search events(past)   */}
        {past && past.length === 0 ? (
          ""
        ) : (
          <main className={classes.pastlayout}>
            <span className={classes.cardDataHeader}>Past Events</span>{" "}
            <Paper className={classes.paper}>
              <Grid container spacing={2} className={classes.events}>
                {renderEventCards(past)}
              </Grid>
            </Paper>
          </main>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    events: state.events,
    // loading: state.events.loading,
    noMoreData: state.events.noMoreData,
    event_participant: state.events.event_participant,
    register_team: state.events.register_team,
    sendEventFeedback: state.eventFeedback.sendFeedbackLink,
  };
};

export default connect(mapStateToProps, { getEvents })(
  withStyles(styles)(AdminEvents)
);
