/* eslint-disable camelcase */
import axios from "axios";
import {
  CREATE_REFER_FRIEND,
  REFER_FRIEND_LOADING,
  REFER_FRIEND_ERROR,
  GET_REFER_FRIENDS,
  GET_SINGLE_REFER_FRIEND,
  CREATE_REFER_FRIEND_COMMENT,
} from "./types";
import { showSuccess, showError } from "./ActionsHelper";
import { transformErrorsFromApi } from "./TransformError";

export const setReferFriendLoading = () => {
  return {
    type: REFER_FRIEND_LOADING,
  };
};

export const getReferFriends = (page, search) => (dispatch) => {
  dispatch(setReferFriendLoading());
  let url = "/api/referfriend/";
  // if (search) {
  //   url = `/api/cafe?q=${search}`;
  // }
  axios
    .get(url)
    .then((result) => {
      dispatch({
        type: GET_REFER_FRIENDS,
        payload: result.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: REFER_FRIEND_ERROR,
        payload: err && err.response ? err.response.data : "",
      });
    });
};

export const createReferFriend = (
  userData,
  setErrors,
  history,
  setSubmitting
) => (dispatch) => {
  dispatch(setReferFriendLoading());
  axios
    .post("/api/referfriend/", userData)
    .then((result) => {
      dispatch({
        type: CREATE_REFER_FRIEND,
        payload: result.data,
      });
      // history.push("/credit-accounts");
      dispatch(showSuccess("", "Referral details submitted successfully!"));
      setSubmitting(false);
    })
    .catch((err) => {
      dispatch({
        type: REFER_FRIEND_ERROR,
        payload: err && err.response ? err.response.data.data : "",
      });
      dispatch(
        showError(err.response && err.response.data && err.response.data.data)
      );
    });
};

//GET SINGLE PLAN
export const getSingleReferFriend = (id) => (dispatch) => {
  dispatch(setReferFriendLoading());

  axios
    .get(`/api/referfriend/getone/${id}`)
    .then((res) => {
      dispatch({
        type: GET_SINGLE_REFER_FRIEND,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: REFER_FRIEND_ERROR,
        payload: err.response && err.response.data,
      })
    );
};

export const createComment = (id, userData, setErrors) => (dispatch) => {
  axios
    .put(`/api/referfriend/update/${id}`, userData)
    .then((result) => {
      dispatch({
        type: CREATE_REFER_FRIEND_COMMENT,
        payload: result.data,
      });

      dispatch(showSuccess("Comment"));
    })
    .catch((err) => {
      const error = err.response || {};
      dispatch({ type: REFER_FRIEND_ERROR, payload: error.data });
      setErrors(transformErrorsFromApi(error));
    });
};

// export const editPlan = (plan_id, data, setErrors, setSubmitting) => (
//   dispatch
// ) => {
//   dispatch(setCreditAccountLoading());
//   axios
//     .put(`/api/plan/${plan_id}`, data)
//     .then((result) => {
//       dispatch({
//         type: EDIT_PLAN,
//         payload: result.data,
//       });
//       dispatch(showSuccess("", "Plan edited successfully!"));
//       setSubmitting(false);
//     })
//     .catch((err) => {
//       const error = err.response;
//       dispatch({ type: PLAN_ERROR, payload: {} });
//       setSubmitting(false);
//       setErrors(transformErrorsFromApi(error));
//     });
// };
