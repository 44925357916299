/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
} from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";
import { addMerchandisePoint } from "../../Actions/merchandiseActions";
import { getCompanies } from "../../Actions/companiesAction";
import { getCoworker } from "../../Actions/coworkerActions";
import { InputField, SelectField } from "../../Components/Common/Form";
import { spacecapitalize } from "../../Utils/StringFunctions";
import TextareaField from "../../Components/Common/Form/TextareaField";

const $quoteSym = " ";

const styles = () => ({
  createIconForm: {
    width: 500,
    padding: "8px",
  },
  formContent: {
    height: "464px",
  },
  modelHeader: {
    paddingBottom: "5px",
    position: "relative",
    "&:after": {
      top: "48px",
      left: "24px",
      width: "24px",
      borderBottom: "3px solid #fb0",
      content: `'${$quoteSym}'`,
      position: "absolute",
    },
  },
  modalFooter: {
    padding: "40px 0px 8px",
    display: "flex",
    justifyContent: "flex-end",
  },
  cancelbutton: {
    marginRight: "20px",
    backgroundColor: "#dddddd",
    color: "#151039",
    "&:hover": {
      color: "#151039",
      backgroundColor: "#d1d1d1!important",
    },
  },
  addbutton: {
    color: "#fff",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  input_field: {
    border: "1px solid #949494",
    height: "81%",
    position: "relative",
    width: "100%",
    padding: "6px 8px",
    margin: "0px",
    // borderBottom: "none",
    fontFamily:
      "'-apple-system,BlinkMacSystemFont','Segoe UI','Roboto','Helvetica Neue','Arial,sans-serif','Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol'",
    color: "currentColor",
    fontSize: "14px",
    background: "white",
  },
  descriptionLabel: {
    margin: "15px 0px 8px 0px",
    fontSize: "12px",
    fontWeight: "400",
    color: "rgba(0, 0, 0, 0.54)",
  },
});
const addWalkthroughSchema = Yup.object().shape({
  company_name: Yup.string().required("This field is required."),
  remarks: Yup.string().required("This field is required."),
  points: Yup.number()
    .typeError("Please input a number.")
    .required("This field is required."),
  userid: Yup.string().required("This field is required."),
});

class AddMerchandisePointModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.getCompanies();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.merchandisePointAdd !== prevProps.merchandisePointAdd &&
      this.props.merchandisePointAdd.success
    ) {
      this.props.closeAddModal();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.companies !== prevState.companies) {
      return { companies: nextProps.companies };
    }
    if (nextProps.companyCoworkers !== prevState.companyCoworkers) {
      return { companyCoworkers: nextProps.companyCoworkers };
    }
    return [];
  }

  getInitialValues = (detailInserted) => {
    if (detailInserted === "yes") {
      return {
        company_name: "",
        points: "",
        remarks: "",
        userid: "",
      };
    }
    return {
      company_name: "",
      points: "",
      remarks: "",
      userid: "",
    };
  };

  callCoworkerAPI = (value) => {
    const id = value.value;
    this.props.getCoworker(id);
  };

  handleClose = () => {
    this.props.closeAddModal();
  };

  getCompanyOptions = (companies) => {
    // eslint-disable-next-line func-names
    const FilterCompanyData = companies.filter(function (activeCompanydata) {
      return activeCompanydata.status !== "inactive";
    });
    return FilterCompanyData
      ? FilterCompanyData.map((company) => {
          return {
            value: company._id,
            label: spacecapitalize(
              company.display_name || company.company_name
            ),
          };
        })
      : [];
  };

  getCoworkersOptions = (companyCoworkers) => {
    return companyCoworkers
      ? companyCoworkers.map((companyCoworker) => {
          const { user_id, first_name, last_name } = companyCoworker;
          return {
            value: user_id ? user_id._id : "",
            label: spacecapitalize(
              `${first_name && first_name} ${last_name && last_name}`
            ),
          };
        })
      : [];
  };

  render() {
    const { classes, singleVisitor, companies, companyCoworkers } = this.props;

    return (
      <Dialog
        open={this.props.openAddModal}
        onClose={this.props.closeAddModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className={classes.modelHeader}>
          {singleVisitor && singleVisitor.details_inserted === "yes"
            ? "View Merchandise Point"
            : "Add Merchandise Point"}
        </DialogTitle>

        <Formik
          initialValues={this.getInitialValues(
            singleVisitor && singleVisitor.details_inserted
          )}
          enableReinitialize
          validationSchema={addWalkthroughSchema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            console.group("valuee", values);
            const company_val = values.company_name
              ? values.company_name.label
              : "";
            values.company_name = company_val;
            const userid_val = values.userid ? values.userid.value : "";
            values.userid = userid_val;
            this.props.addMerchandisePoint(values, setErrors, setSubmitting);
          }}
          render={({ setFieldValue, values, errors, setFieldTouched }) => (
            <Form className={classes.createIconForm}>
              <DialogContent className={classes.formContent}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <SelectField
                      value={values.space}
                      name="company_name"
                      id="company_name"
                      label="Company Name"
                      onChange={(name, value) => {
                        setFieldValue("company_name", value);
                        this.callCoworkerAPI(value);
                        setFieldValue("userid", "");
                      }}
                      multi={false}
                      error={errors.space}
                      options={this.getCompanyOptions(companies)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <SelectField
                      value={values.userid}
                      name="userid"
                      id="userid"
                      label="Co-worker"
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      multi={false}
                      error={errors.userid}
                      // touched={touched.userid}
                      options={this.getCoworkersOptions(companyCoworkers)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <InputField
                      name="points"
                      label="Points"
                      placeholder="Enter Points"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <FormLabel
                      className={classes.descriptionLabel}
                      component="legend"
                    >
                      Remarks
                    </FormLabel>
                    <TextareaField
                      name="remarks"
                      className={classes.input_field}
                      rows="5"
                      placeholder="Type Here"
                    ></TextareaField>
                  </Grid>
                </Grid>

                <Grid className={classes.modalFooter}>
                  <Button
                    onClick={() => this.handleClose()}
                    color="primary"
                    className={classes.cancelbutton}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    className={classes.addbutton}
                    type="submit"
                    variant="contained"
                  >
                    Add
                  </Button>
                </Grid>
              </DialogContent>
            </Form>
          )}
        />
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errors: state.errors,
    single_walkthrough_visitor: state.visitors.single_walkthrough_visitor,
    merchandisePointAdd: state.allMerchandiseOrder.merchandisePointAdd,
    companies: state.company.companies,
    companyCoworkers: state.coworkers.companyCoworkers,
  };
};

export default connect(mapStateToProps, {
  addMerchandisePoint,
  getCompanies,
  getCoworker,
})(withStyles(styles)(AddMerchandisePointModal));
