import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Button, Grid, InputAdornment } from "@material-ui/core";
import { Form } from "react-formik-ui";
import * as Yup from "yup";
import avatar from "../../Assets/coworker3.png";
import {
  createAnnouncementComment,
  getAnnouncement,
} from "../../Actions/AnnouncementActions";
import InputField from "../../Components/Common/Form/InputField";
import { getEventDiscussion } from "../../Actions/eventActions";

const styles = () => ({
  main: {
    // border: "1px solid #efefef",
    backgroundColor: "#fff",
    marginTop: "0px",
    paddingLeft: "2.8%",
    paddingRight: "2.8%",
  },
  avatar: {
    height: "30px",
    width: "30px",
    paddingBottom: "2px",
    borderRadius: "50%",
  },
  postbutton: {
    // border: "1px solid #211b4e",
    color: "#211b4e",
    // lineHeight: "1.222"
    marginBottom: "10px",
    display: "contents",
  },
});

// eslint-disable-next-line no-unused-vars
const CommentSchema = Yup.object().shape({
  comment: Yup.string().min(3, "Please enter more than 3 character"),
});

class AnnouncementComment extends Component {
  constructor(props) {
    super(props);
    // eslint-disable-next-line react/no-unused-state
    this.state = { comments: {}, id: "" };
    this.state.id = this.props.commentData._id;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.discussion !== prevState.discussion) {
      return {
        discussion: nextProps.discussion,
      };
    }
    return [];
  }

  componentDidUpdate(prevProps) {
    if (prevProps.discussion !== this.props.discussion) {
      this.setState({
        discussion: this.props.discussion,
      });
    }
  }

  render() {
    const { classes, userData } = this.props;
    return (
      <div className={classes.main}>
        <Formik
          initialValues={{
            comment: "",
          }}
          enableReinitialize
          onSubmit={(values, { setErrors, setSubmitting, resetForm }) => {
            this.props.createAnnouncementComment(
              values,
              this.props.id,
              setErrors,
              setSubmitting
            );
            resetForm(this.initialValues);
            this.props.getAnnouncement();
          }}
        >
          {({ values, handleSubmit }) => (
            <Form
              className={classes.form}
              onSubmit={handleSubmit}
              encType="multipart/form-data"
              autoComplete="off"
            >
              <Grid item sm={12} lg={12}>
                <InputField
                  name="comment"
                  placeholder="Write a comment..."
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {userData.profile_pic ? (
                          <img
                            src={userData.profile_pic}
                            alt=""
                            className={classes.avatar}
                          />
                        ) : (
                          <img src={avatar} alt="" className={classes.avatar} />
                        )}
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          className={classes.postbutton}
                          type="submit"
                          disabled={!values.comment}
                        >
                          Comment
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    discussion: state.events.discussion,
  };
};
export default connect(mapStateToProps, {
  createAnnouncementComment,
  getAnnouncement,
  getEventDiscussion,
})(withStyles(styles)(AnnouncementComment));
