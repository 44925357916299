import withStyles from "@material-ui/core/styles/withStyles";
import React, { Component } from "react";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  Typography,
  Grid,
  Paper,
  Button,
  CardContent,
} from "@material-ui/core";
import { connect } from "react-redux";
import { checkPermission } from "../../Utils/checkPermission";
import { capitalize } from "../../Utils/StringFunctions";

const moment = require("moment");

const $quoteSym = " ";
const styles = (theme) => {
  return {
    aboutinnerdetail: {
      padding: "25px 0px 15px 1px",
    },
    aboutinnercontent: {
      padding: "25px 0px 15px 1px",
      marginLeft: "-3px",
    },
    main: {
      padding: "0px",
      backgroundColor: "#ffffff",
    },
    applicationHeader: {
      margin: "20px 3px",
      paddingBottom: "5px",
      textTransform: "capitalize",
      fontSize: "16px",
      fontWeight: "500",
      position: "relative",
      "&:after": {
        top: "31px",
        left: "1px",
        width: "24px",
        borderBottom: "3px solid #fb0",
        content: `'${$quoteSym}'`,
        position: "absolute",
      },
    },
    mainabout: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      paddingTop: "2%",
      paddingLeft: "20px",
    },
    button: {
      margin: "0px 10px 0px 0px",
      borderRadius: "20px",
      backgroundColor: "#efefef",
      color: "#626262",
      cursor: "default",
      "&:hover": {
        backgroundColor: "#efefef",
        color: "#626262",
      },
    },
    eventInfo: {
      display: "flex",
      "& p": {
        fontSize: "12px",
      },
    },
    contractData: {
      marginRight: "60px",
    },
    description: {
      textAlign: "justify",
    },
    innertitle: {
      fontSize: "12px",
      color: "#ababab",
    },
    companyContent: {
      fontSize: "14px",
      lineHeight: 1.3,
    },
    cardContent: {
      marginTop: " 1%",
      padding: "14px 4px",
    },
    contentFonts: {
      fontSize: "14px",
    },
  };
};

class AboutFreelancer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, company } = this.props;
    return (
      <React.Fragment>
        <CssBaseline />
        <main className={classes.main}>
          <div className={classes.mainabout}>
            <div className={classes.heroUnit}>
              <span className={classes.applicationHeader}>
                {/* About {spacecapitalize(company.user.first_name)} */}
                About {capitalize(company.user && company.user.first_name)}{" "}
                {capitalize(company.user && company.user.last_name)}
              </span>
            </div>
            <Grid
              className={classes.aboutinnercontent}
              container
              item
              xs={12}
              lg={12}
              spacing={2}
            >
              <Grid item xs={12} lg={12} className={classes.description}>
                <div
                  className={classes.companyContent}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html:
                      company.description === "fill description"
                        ? " "
                        : company.description,
                  }}
                />
              </Grid>
            </Grid>

            {/* Services */}
            {company.services && company.services.length !== 0 && (
              <div className={classes.heroUnit}>
                <span className={classes.applicationHeader}>Services</span>
              </div>
            )}
            <Grid
              container
              direction="row"
              className={classes.aboutinnercontent}
            >
              <Grid item>
                {company.services
                  ? company.services.map((service) => {
                      return (
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.button}
                        >
                          {capitalize(service.label)}
                        </Button>
                      );
                    })
                  : "No services"}
              </Grid>
            </Grid>

            {/* SPOC details and Contract data */}
            {checkPermission("COMPANY_CRITICAL_DETAILS") && (
              <Grid
                className={classes.aboutinnerdetail}
                container
                item
                xs={12}
                lg={12}
                spacing={2}
              >
                <Grid item xs={7} lg={7}>
                  <div className={classes.heroUnit}>
                    <span className={classes.applicationHeader}>
                      Contract Details
                    </span>
                  </div>
                  <Paper className={classes.containerPaper}>
                    <CardContent className={classes.cardContent}>
                      <div className={classes.eventInfo}>
                        <div className={classes.contractData}>
                          <Typography
                            variant="subtitle2"
                            component="p"
                            className={classes.innertitle}
                          >
                            Start Date
                          </Typography>

                          <Typography
                            variant="subtitle2"
                            className={classes.contentFonts}
                          >
                            {company.contract_start_date != null
                              ? moment(company.contract_start_date).format(
                                  "DD/MM/YYYY"
                                )
                              : "-"}
                          </Typography>
                        </div>
                        <div className={classes.contractData}>
                          <Typography
                            variant="subtitle2"
                            component="p"
                            className={classes.innertitle}
                          >
                            End Date
                          </Typography>

                          <Typography
                            variant="subtitle2"
                            className={classes.contentFonts}
                          >
                            {company.contract_end_date != null
                              ? moment(company.contract_end_date).format(
                                  "DD/MM/YYYY"
                                )
                              : "-"}
                          </Typography>
                        </div>
                      </div>
                    </CardContent>
                  </Paper>
                </Grid>
              </Grid>
            )}
          </div>
        </main>
      </React.Fragment>
    );
  }
}

AboutFreelancer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => {
  return {
    company: state.company.company,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withStyles(styles)(AboutFreelancer));
