/* eslint-disable camelcase */
import React from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { Typography, Grid } from "@material-ui/core";
import classNames from "classnames";

const styles = () => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "12px",
  },
  mainTitle: {
    fontWeight: "600",
    fontSize: "20px",
    textAlign: "left",
    color: "#222222",
    lineHeight: "25px",
  },
  heading: {
    fontSize: "16px",
    color: "#222222",
    fontWeight: "500",
    margin: "4px 0px",
    marginBottom: "15px",
  },
  title: {
    fontSize: "13px",
    color: "#222222",
    fontWeight: "500",
    padding: "10px",
  },
  subTitle: {
    fontSize: "15px",
    color: "#222222",
    fontWeight: "500",
  },
  phone: {
    color: "#1592E6",
    fontWeight: "500",
  },
  description: {
    color: "#222222",
    fontSize: "13px",
    lineHeight: "18px",
  },
  generalDetails: {
    padding: "3% 0% 0%",
  },
  cardLayout: {
    border: "1px solid #EFEFEF",
    borderRadius: "5px",
    padding: "10px",
  },
  padding: {
    padding: "10px",
  },
});

function GraphicDesign(props) {
  const { classes } = props;

  return (
    <div className={classes.main}>
      <div className={classes.header}>
        <Typography align="left" variant="h1" className={classes.mainTitle}>
          Graphic Designing
        </Typography>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Typography align="left" className={classes.description}>
            We invite you to our world of Design & Branding to walk this journey
            as Partners in building your brand through Graphics. For every
            graphic, our team will design for you, would be out of love for
            design, colours, intrinsic satisfaction, and above all a new
            learning with a new partner. Logo, Social Media, Brand Communication
            etc. we would help you come clear off your competition.
          </Typography>
          <Grid container spacing={2} className={classes.generalDetails}>
            <Grid item xs={12}>
              <Typography align="left" className={classes.subTitle}>
                Our services include;
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  Social Media <br />
                  Graphics
                  <br />
                  <br />
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  Logo Design & <br />
                  Corporate Identity
                  <br />
                  <br />
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  Corporate Brochure <br />
                  <br />
                  <br />
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  Print & Media <br />
                  Advertisement
                  <br />
                  <br />
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  Branding
                  <br />
                  <br />
                  <br />
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  Wireframe Design
                  <br />
                  <br />
                  <br />
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  Exhibition & Event
                  <br /> Design <br />
                  <br />
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  Brand Guidelines
                  <br /> Formation <br />
                  <br />
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2} className={classes.generalDetails}>
            <Typography
              align="left"
              className={classNames(classes.mainTitle, classes.padding)}
            >
              Why us?
            </Typography>
            <Grid item xs={12}>
              <Typography align="left" className={classes.description}>
                We are living in times where graphics do the major talking. We
                will help your marketing calendar stay upbeat with the right
                graphics to up your game. You can rely on us for;
                <br />
                <br />
                We listen to your requirement and understand the purpose to
                provide the best possible output.
                <br />
                <br />
                We adhere to the Brand Guideline setup and work within the
                scheme.
                <br />
                <br />
                We try and deliver graphic on-time with support for change.
                <br />
                <br />
                We have a team of graphic design experts both in terms of
                experience and education into Media & Graphics.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.spaces.loading,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(GraphicDesign));
