import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { SelectField } from "../Form";
import { getCoworkerSkills } from "../../../Actions/CoworkerSkillsActions";
import { capitalize } from "../../../Utils/StringFunctions";

const CoworkerSkillsDropdown = (props) => {
  const { setFieldValue, setFieldTouched, errors, touched } = props;
  const [coworkerskills, setCoworkerskills] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);

  // eslint-disable-next-line no-shadow
  const getAvailableSkillsOptions = (coworkerskills) => {
    return coworkerskills
      ? coworkerskills.map((coworkerskill) => {
          return {
            value: coworkerskill._id,
            label: capitalize(coworkerskill.skill_type),
          };
        })
      : [];
  };

  useEffect(() => {
    props.getCoworkerSkills();
  }, []);

  useEffect(() => {
    setCoworkerskills(props.coworkerskills);
  }, [props.coworkerskills]);

  useEffect(() => {
    setSelectedValue(props.values);
  }, [props.values]);

  const changeDropDown = (name, values) => {
    setSelectedValue(values);
    setFieldValue(name, values);
  };
  return (
    <SelectField
      value={selectedValue}
      name="skills"
      id="skills"
      label="Skills"
      onChange={changeDropDown}
      onBlur={setFieldTouched}
      error={errors.skills}
      touched={touched}
      options={getAvailableSkillsOptions(coworkerskills)}
      multi="true"
      creatable
    />
  );
};

const mapStateToProps = (state) => {
  return {
    errors: state.errors,
    coworkerskills: state.coworkerskills.coworkerskills,
  };
};

export default connect(mapStateToProps, { getCoworkerSkills })(
  CoworkerSkillsDropdown
);
