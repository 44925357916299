import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  CssBaseline,
  Grid,
  Typography,
  Paper,
  Button,
  Tooltip,
} from "@material-ui/core";
import avatar from "../../Assets/coworker3.png";
import { getEvent } from "../../Actions/eventActions";
import ClubMemberModal from "./ClubMemberModal";

const styles = () => ({
  main: {
    border: "1px solid #efefef",
    backgroundColor: "#fff",
  },
  OwnerDetail: {
    padding: "20px",
    boxShadow: "0 9px 10px rgba(0, 0, 0, 0.08), 0 4px 0px rgba(0,0,0,0.05)",
  },
  avatar: {
    // boxShadow: "0 1px 8px rgba(21, 20, 20, 0.08), 0 0px 1px rgba(0,0,0,0.05)",
    width: "50px",
    height: "50px",
    borderRadius: "29.5px",
    marginTop: "6px",
  },
  memberImage: {
    width: "30px",
    height: "30px",
    borderRadius: "29.5px",
    margin: "6px 2px 6px 0px",
  },
  defaultmemberImage: {
    width: "30px",
    height: "30px",
    borderRadius: "29.5px",
    marginTop: "6px",
  },
  ownerName: {
    fontSize: "16px",
    fontWeight: "bold",
    color: " #222",
    textTransform: "capitalize",
    paddingLeft: "4%",
  },
  members: {
    fontSize: "16px",
    fontWeight: "600",
    color: "#ababab",
    paddingLeft: "4%",
    marginTop: "-5px",
  },
  postUserDetail: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    top: "-56px",
    left: "50px",
    height: "0px",
    width: "75%",
  },
  postimage: {
    width: "100%",
  },
  postFooter: {
    display: "flex",
    color: "#bfbfbf",
    justifyContent: "space-between",
    width: "100%",
    padding: "0px 10px",
  },
  ownerTitle: {
    fontWeight: "normal",
    fontSize: " 17px",
    textAlign: "left",
    color: "#ababab",
    // paddingLeft: "14px"
  },
  clubDetail: {
    padding: "20px",
    boxShadow: "0 9px 10px rgba(0, 0, 0, 0.08), 0 4px 0px rgba(0,0,0,0.05)",
    border: "1px solid #efefef",
    // paddingTop: "30px"
  },
  clubtitle: {
    fontWeight: "normal",
    fontSize: "18px",
    color: "#ababab",
  },
  clubdescription: {
    fontWeight: "normal",
    fontSize: "12px",
    textAlign: "justify",
    color: "#333",
  },
  seperator: {
    marginTop: "4%",
  },
  viewMoreMember: {
    textDecoration: "none",
    margin: "4px 4px 0px 0px",
    fontSize: 12,
    backgroundColor: "#fff",
    color: "#2196F3",
    padding: "0px !important",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#2196F3",
    },
  },
  alignment: {
    display: "flex",
    marginTop: "4%",
  },
  memberName: {
    color: "#fff",
    fontSize: "12px",
    textTransform: "capitalize",
  },
  clubMemberSection: {
    display: "flex",
  },
});

class OwnerDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      value: 0,
      // eslint-disable-next-line react/no-unused-state
      event: {},
      modalOpen: false,
    };
  }

  handleClickOpen = () => {
    this.setState({
      modalOpen: true,
    });
  };

  handleClose = () => {
    this.setState({
      modalOpen: false,
    });
  };

  toolTipTitle = (member) => {
    const { classes } = this.props;
    return (
      <div>
        <Typography variant="subtitle2" className={classes.memberName}>
          {member && member.first_name} {member && member.last_name}
        </Typography>
        <Typography variant="subtitle2" className={classes.memberName}>
          {member &&
          member.company_id &&
          member.company_id.type === "freelancer"
            ? "-"
            : member && member.company_id && member.company_id.company_name}
        </Typography>
      </div>
    );
  };

  render() {
    const { classes, data } = this.props;
    return (
      <React.Fragment>
        <CssBaseline />
        <main>
          <div className={classes.main}>
            <Paper className={classes.OwnerDetail}>
              <Typography variant="subtitle2" className={classes.ownerTitle}>
                OWNER
              </Typography>
              <Grid container className={classes.root}>
                <Grid item sm={12} lg={12}>
                  {data && data.owner && data.owner.profile_pic ? (
                    <img
                      src={data.owner.profile_pic}
                      alt="avatar"
                      className={classes.avatar}
                    />
                  ) : (
                    <img src={avatar} alt="avatar" className={classes.avatar} />
                  )}
                  <div className={classes.postUserDetail}>
                    {data.owner && (
                      <Typography
                        variant="subtitle2"
                        className={classes.ownerName}
                      >
                        {data.owner.first_name} {data.owner.last_name}
                      </Typography>
                    )}
                    <Typography variant="subtitle1" className={classes.members}>
                      {data.total_members} Members
                    </Typography>
                  </div>
                  <div className={classes.clubMemberSection}>
                    {data &&
                    data.members_details &&
                    data.members_details.length > 0 ? (
                      <div className={classes.alignment}>
                        {data.members_details &&
                          data.members_details.map((member, id) => {
                            if (id > 6) {
                              return "";
                            }
                            return (
                              <div>
                                {member && member.profile_pic ? (
                                  <div>
                                    <Tooltip
                                      title={this.toolTipTitle(member)}
                                      aria-label="add"
                                    >
                                      <img
                                        src={member && member.profile_pic}
                                        alt="avatar"
                                        className={classes.memberImage}
                                      />
                                    </Tooltip>
                                  </div>
                                ) : (
                                  <div>
                                    <Tooltip
                                      title={this.toolTipTitle(member)}
                                      aria-label="add"
                                    >
                                      <img
                                        src={avatar}
                                        alt="avatar"
                                        className={classes.defaultmemberImage}
                                      />
                                    </Tooltip>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                      </div>
                    ) : (
                      ""
                    )}
                    {data.members_details && data.members_details.length > 6 ? (
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.viewMoreMember}
                        size="small"
                        onClick={this.handleClickOpen}
                      >
                        View All
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </div>
          <div className={classes.seperator}>
            <Paper className={classes.clubDetail}>
              <Grid item sm={12} lg={12}>
                <Typography variant="subtitle1" className={classes.clubtitle}>
                  CLUB DESCRIPTION
                </Typography>

                <Typography variant="body" className={classes.clubdescription}>
                  {data.description}
                </Typography>
              </Grid>
            </Paper>
          </div>
          {this.state.modalOpen === true && (
            <ClubMemberModal
              open={this.state.modalOpen}
              close={this.handleClose}
              data={data}
            />
          )}
        </main>
      </React.Fragment>
    );
  }
}

OwnerDetail.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, { getEvent })(
  withStyles(styles)(OwnerDetail)
);
