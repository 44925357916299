import React from "react";
import {
  Grid,
  InputAdornment,
  withStyles,
  Typography,
  Button,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { Form } from "react-formik-ui";
import { getRoletypes } from "../../Actions/roleTypeActions";
import { CircularLoader } from "../../Components/Common/Spinner";
import {
  Instagram,
  Linkedin,
  Twitter,
  FacebookRoundIcon,
} from "../../Assets/index";
import { BloodGroupOptions } from "../../Utils/dropDownData";
import { checkPermission } from "../../Utils/checkPermission";
import {
  addCompany,
  updateCompany,
  getCompanies,
} from "../../Actions/companiesAction";
import InputField from "../../Components/Common/Form/InputField";
import UploadField from "../../Components/Common/Form/UploadField";
import DatePickerField from "../../Components/Common/Form/DatePickerField";
import SelectField from "../../Components/Common/Form/SelectField";
import {
  createCoworker,
  updateCoworker,
  getSingleCoworker,
} from "../../Actions/coworkerActions";
import AllCompaniesDropDown from "../../Components/Common/Dropdowns/AllCompaniesDropDown";
import CoworkerSkillsDropdown from "../../Components/Common/Dropdowns/CoworkerSkillsDropdown";
import RoleTypesDropdown from "../../Components/Common/Dropdowns/RoleTypesDropdown";

const styles = (theme) => ({
  lightbg: {
    backgroundColor: "#fff",
    padding: "10px 22px",
  },
  label: {
    margin: "15px 0px 8px 0px",
    fontSize: "12px",
    fontWeight: "700",
  },
  Addbutton: {
    width: "148px",
    height: "46px",
    marginLeft: "-5%",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  margin: {
    margin: theme.spacing(1),
    marginLeft: "0px",
    width: "100%",
  },
  social: {
    height: "20px",
    width: "20px",
  },
  datePickerInput: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    padding: "4px",
    "& > div": {
      marginBottom: "8px",
    },
  },
  formfooter: {
    margin: "30px 15px",
    marginRight: "-3%",
  },
  cancelbutton: {
    width: "148px",
    height: "46px",
    color: "#151039",
    backgroundColor: "#dddddd",
    marginRight: "20px",
    "&:hover": {
      color: "#151039",
      backgroundColor: "#d1d1d1!important",
    },
  },
  form: {
    marginLeft: "4px",
  },
  contactno: {
    marginTop: "31px",
  },
});
const phoneRegExp = /[1-9]{1}[0-9]{9}/;

const CoWorkerSchema = Yup.object().shape({
  first_name: Yup.string()
    // .matches(/^.[a-zA-Z0-9 ]+$/, "Enter Valid Name")
    .trim()
    .required("This field is required."),
  last_name: Yup.string()
    // .matches(/^.[a-zA-Z0-9 ]+$/, "Enter Valid Name")
    .trim()
    .required("This field is required."),
  email: Yup.string()
    .email("Invalid email address")
    .required("This field is required."),
  contact_number: Yup.string()
    .matches(phoneRegExp, "Please enter valid number.")
    .required("This field is required."),
  designation: Yup.string().trim().required("This field is required."),
  roletypeid: Yup.string().required("This field is required."),
  company_id: Yup.string().when("role", {
    // eslint-disable-next-line no-unused-vars
    is: (role) => localStorage.getItem("role") === "admin",
    then: Yup.string().required("This field is required"),
  }),
  // company_id: Yup.string().test("match", "This field is required.", function() {
  //   if (localStorage.getItem("role") === "admin") {
  //     return true;
  //   }
  // }),
  blood_group: Yup.string().required("This field is required."),
});

class CoworkerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { roletypes: [] };
    this.state = {
      id:
        this.props.match && this.props.match.params
          ? this.props.match.params.id
          : "",
      roletypes: [],
      companies: [],
      deletedKey: [],
    };
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    this.props.getCompanies();
    this.props.getRoletypes();
    if (this.state.id) {
      this.props.getSingleCoworker(this.state.id);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.companies !== prevState.companies) {
      return { companies: nextProps.companies };
    }

    if (nextProps.roletypes !== prevState.roletypes) {
      return { roletypes: nextProps.roletypes };
    }
    if (nextProps.coworker !== prevState.coworker) {
      return {
        coworker: nextProps.coworker,
        loading: nextProps.coworker.loading,
      };
    }
    if (nextProps.errors) {
      return { errors: nextProps.errors };
    }
    return [];
  }

  componentDidUpdate(prevProps) {
    if (prevProps.companies !== this.props.companies) {
      this.setState({
        companies: this.props.companies,
      });
      if (prevProps.coworker !== this.props.coworker) {
        const coworker = { ...this.props.coworker };
        this.setState(Object.assign([], coworker), () => {});
      }
      if (prevProps.errors !== this.props.errors) {
        // eslint-disable-next-line react/no-unused-state
        this.setState({ errors: this.props.errors });
      }
    }
  }

  handleClose = () => {
    const path = "/co-workers";
    this.props.history.push(path);
  };

  getAvailableCompanyOptions = (companies) => {
    return (
      companies &&
      companies.map((company) => {
        return {
          value: company._id,
          label: company.display_name || company.company_name,
        };
      })
    );
  };

  getAvailableRoletypesOptions = (roletypes) => {
    return roletypes
      ? roletypes.map((roletype) => {
          return {
            value: roletype._id,
            label: roletype.name,
          };
        })
      : [];
  };

  formatData = (values) => {
    const formated = { ...values };
    formated.created_by = localStorage.getItem("user_id");
    formated.space = localStorage.getItem("space_id");
    const role = localStorage.getItem("role");
    const ID = localStorage.getItem("company_id");
    // formated.user_id = localStorage.setItem("userId");
    const val = formated.blood_group ? formated.blood_group.value : "";
    if (checkPermission("COMPANY_VIEW") && role === "coworkeradmin") {
      formated.company_id = localStorage.getItem("company_id");
    } else if (
      role === "admin" &&
      checkPermission("COMPANY_VIEW") &&
      ID === "undefined"
    ) {
      const companyVal =
        formated.company_id && formated.company_id.value
          ? formated.company_id && formated.company_id.value
          : formated.company_id && formated.company_id._id;
      formated.company_id = companyVal;
    } else if (checkPermission("COMPANY_VIEW")) {
      const companyVal = formated.company_id ? formated.company_id.value : "";
      formated.company_id = companyVal;
    } else {
      formated.company_id = localStorage.getItem("company_id");
    }
    formated.blood_group = val;
    const val2 = formated.roletypeid ? formated.roletypeid.value : "";
    formated.roletypeid = val2;

    const skill =
      formated.skills && formated.skills.map((skl) => skl && skl.label);
    formated.skills = skill;
    return formated;
  };

  getInitialValues = (id) => {
    const { coworker } = this.props;
    const { roletypes, companies } = this.state;
    if (id && coworker && roletypes.length > 0 && companies.length > 0) {
      return {
        first_name: coworker.first_name,
        last_name: coworker.last_name,
        profile_pic: coworker.profile_pic,
        email: coworker.email,
        contact_number: coworker.contact_number,
        company_id: coworker.company_id ? coworker.company_id : "",
        designation:
          coworker.designation === null || coworker.designation === ""
            ? "NA"
            : coworker.designation,
        date_of_birth:
          coworker.date_of_birth === null ? new Date() : coworker.date_of_birth,
        blood_group: BloodGroupOptions.filter(
          (option) => option.value === coworker.blood_group
        )[0],
        skills: coworker.skills,
        facebook: coworker.facebook,
        linkedin: coworker.linkedin,
        twitter: coworker.twitter,
        instagram: coworker.instagram,
        roletypeid:
          coworker.role &&
          this.getAvailableRoletypesOptions(this.state.roletypes).filter(
            (option) => option.value === coworker.role
            // coworker.setup.join().includes(option.value)
          )[0],
        // roletypeid: coworker.role
      };
    }
    return {
      first_name: "",
      last_name: "",
      profile_pic: "",
      email: "",
      contact_number: "",
      company_id: [],
      designation: "",
      date_of_birth: new Date(),
      blood_group: [],
      skills: [],
      facebook: "",
      linkedin: "",
      twitter: "",
      instagram: "",
      // roletypeid: "5cfa0b99c325a92aa42a4fb6"
      roletypeid: "",
    };
  };

  setDelImageUrl = (url) => {
    const { deletedKey } = this.state;
    this.setState({ deletedKey: deletedKey.push(url) });
  };

  render() {
    const { classes } = this.props;
    const { id, loading } = this.state;

    return (
      <React.Fragment>
        <div className={classes.lightbg}>
          <Typography variant="h6" gutterBottom style={{ marginLeft: "4px" }}>
            {id ? "Update" : "Add"} Co-worker
          </Typography>
          <Formik
            initialValues={this.getInitialValues(id)}
            enableReinitialize={!!id}
            validationSchema={CoWorkerSchema}
            onSubmit={(values, { setErrors, setSubmitting }) => {
              const data = this.formatData(values);
              if (!this.state.id) {
                this.props.createCoworker(
                  data,
                  setErrors,
                  setSubmitting,
                  this.props.history
                );
              } else {
                this.props.updateCoworker(
                  this.state.id,
                  data,
                  setErrors,
                  setSubmitting,
                  this.props.history
                );
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              setFieldTouched,
              setFieldValue,
              handleSubmit,
            }) => (
              <Form
                className={classes.form}
                onSubmit={handleSubmit}
                encType="multipart/form-data"
                autoComplete="off"
              >
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6}>
                    <InputField
                      name="first_name"
                      label="Full Name"
                      placeholder="Enter First Name"
                      max={15}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} style={{ marginTop: "16px" }}>
                    <InputField
                      name="last_name"
                      label=""
                      placeholder="Enter Last Name"
                      max={15}
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    className={classes.contract_main}
                    spacing={2}
                    direction="column"
                  >
                    <Grid item xs={6} sm={6}>
                      {/* <FormLabel
                        component="legend"
                        className={classes.label}
                        style={{
                          fontSize: "15px",
                          fontWeight: "400",
                          color: "#252631"
                        }}
                      >
                        Profile Photo
                      </FormLabel> */}
                      <UploadField
                        label="Upload Profile Photo"
                        name="profile_pic"
                        id="profile_pic"
                        onChange={setFieldValue}
                        value={values.profile_pic}
                        folder="profile/picture"
                        delImageUrl={this.setDelImageUrl}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={6} sm={6} className={classes.datePickerInput}>
                    <DatePickerField
                      name="date_of_birth"
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      value={values.date_of_birth}
                      disableFuture
                      label="Birth Date"
                    />
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <InputField
                      name="email"
                      label="Email Id"
                      placeholder="johndoe@mail.com"
                      inputProps={{
                        readOnly: !!id,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <InputField
                      name="contact_number"
                      label="Contact No"
                      placeholder="91XXXXXXXX"
                      type="tel"
                      max="10"
                      className={classes.contactno}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    {/* <SelectField
                      value={values.roletypeid}
                      name="roletypeid"
                      id="roletypeid"
                      label="Role"
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      multi={false}
                      error={errors.roletypeid}
                      touched={touched.roletypeid}
                      options={this.getAvailableRoletypesOptions(roletypes)}
                    /> */}
                    <RoleTypesDropdown
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      errors={errors}
                      touched={touched}
                      name="roletypeid"
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    {/* <InputLabel>Select Company</InputLabel> */}
                    {checkPermission("COMPANY_VIEW") && (
                      <AllCompaniesDropDown
                        values={values}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        errors={errors}
                        touched={touched}
                        name="company_id"
                      />
                    )}
                  </Grid>
                  {/* <Grid item xs={6} sm={6}>
                    <InputField
                      name="rfid"
                      label="RFID"
                      placeholder="Enter RFID number"
                      type="tel"
                      max="10"
                    />
                  </Grid> */}
                  <Grid item xs={6} sm={6}>
                    <InputField
                      name="designation"
                      label="Designation"
                      placeholder="Enter Title"
                      className={classes.contactno}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <SelectField
                      value={values.blood_group}
                      name="blood_group"
                      id="blood_group"
                      label="Blood Group"
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      multi={false}
                      error={errors.blood_group}
                      touched={touched.blood_group}
                      options={BloodGroupOptions}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <CoworkerSkillsDropdown
                      values={values.skills}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      errors={errors}
                      touched={touched}
                      name="skills"
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <InputField
                      className={classes.margin}
                      name="facebook"
                      label="Facebook"
                      placeholder="Enter Facebook Profile Link"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src={FacebookRoundIcon}
                              alt=""
                              className={classes.social}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <InputField
                      className={classes.margin}
                      name="linkedin"
                      label="Linkedin"
                      placeholder="Enter Linkedin Profile Link"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src={Linkedin}
                              alt=""
                              className={classes.social}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <InputField
                      className={classes.margin}
                      name="twitter"
                      label="Twitter"
                      placeholder="Enter Twitter Profile Link"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src={Twitter}
                              alt=""
                              className={classes.social}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <InputField
                      className={classes.margin}
                      name="instagram"
                      label="Instagram"
                      placeholder="Enter Instagram Profile Link"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src={Instagram}
                              alt=""
                              className={classes.social}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
                <div className={classes.formfooter}>
                  <Grid
                    container
                    item
                    display="flex"
                    justify="flex-end"
                    alignItems="center"
                    direction="row"
                  >
                    <Grid xs={6} sm={2}>
                      <Button
                        color="grey"
                        onClick={this.handleClose}
                        className={classes.cancelbutton}
                        variant="contained"
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid xs={6} sm={2}>
                      <Button
                        variant="contained"
                        className={classes.Addbutton}
                        color="primary"
                        type="submit"
                      >
                        {id ? "Update" : "Add"}
                      </Button>
                    </Grid>
                  </Grid>
                  {loading && <CircularLoader />}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companies: state.company.companies,
    auth: state.auth,
    errors: state.errors,
    roletypes: state.roletypes.roletypes,
    coworker: state.coworkers.coworker,
  };
};

export default connect(mapStateToProps, {
  addCompany,
  getCompanies,
  updateCompany,
  createCoworker,
  updateCoworker,
  getSingleCoworker,
  getRoletypes,
})(withStyles(styles)(withRouter(CoworkerForm)));
