/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
import axios from "axios";
import {
  GET_BOOKINGS,
  BOOKING_LOADING,
  BOOKING_ERRORS,
  GET_BOOKING,
  SAVE_BOOKING,
  DELETE_BOOKING,
  DELETE_BOOKING_ERRORS,
  COMPANY_CREDIT, GET_SELF_BOOKINGS
} from "./types";
import { showSuccess, showError } from "./ActionsHelper";
import { transformErrorsFromApi } from "./TransformError";

export const getBookings = () => dispatch => {
  dispatch(setBookingLoading());
  let url = "/api/booking/";
  // if (dashboard) {
  //   url = `/api/booking?q=self`;
  // }
  axios
    .get(url)
    .then(res => {
      dispatch({
        type: GET_BOOKINGS,
        payload: res.data
      });
    })
    // eslint-disable-next-line no-unused-vars
    .catch(err =>
      dispatch({
        type: GET_BOOKINGS,
        payload: {}
      })
    );
};

export const getSelfBookings = () => dispatch => {
  dispatch(setBookingLoading());
  // let url = "/api/booking/";
  // if (dashboard) {
  //   url = `/api/booking?q=self`;
  // }
  axios
    .get(`/api/booking?q=self`)
    .then(res => {
      dispatch({
        type: GET_SELF_BOOKINGS,
        payload: res.data
      });
    })
    // eslint-disable-next-line no-unused-vars
    .catch(err =>
      dispatch({
        type: GET_SELF_BOOKINGS,
        payload: {}
      })
    );
};

export const getBooking = id => dispatch => {
  dispatch(setBookingLoading());
  axios
    .get(`/api/booking/${id}`)
    .then(res => {
      dispatch({
        type: GET_BOOKING,
        payload: res.data
      });
    })
    // eslint-disable-next-line no-unused-vars
    .catch(err =>
      dispatch({
        type: GET_BOOKING,
        payload: {}
      })
    );
};

export const registerBooking = (
  userData,
  setErrors,
  setSubmitting,
  history,
  redirect
) => dispatch => {
  dispatch(setBookingLoading());

  // var bodyFormData = new FormData();
  // bodyFormData.append("image", userData.photo);
  // for (var key in userData) {
  //   if (key !== "photo") {
  //     bodyFormData.append(key, userData[key]);
  //   }
  // }
  // for (var pair of bodyFormData.entries()) {
  // }
  userData.space = localStorage.getItem("space_id");

  axios
    .post("/api/booking", userData)
    // .then(result => history.push("/bookings"))
    .then(res => {
      dispatch({
        type: SAVE_BOOKING,
        payload: res.data
      });
      if (redirect) {
        history.push("/bookings");
        dispatch(showSuccess("Booking"));
      }
      if (setSubmitting) {
        setSubmitting(false);
      }
    })
    .catch(err => {
      const error = err.response;

      dispatch({ type: BOOKING_ERRORS, payload: err.response.data });
      dispatch(showError("There was some error while add booking."));
      if (setSubmitting) {
        setSubmitting(false);
      }
      if (setErrors) {
        setErrors(transformErrorsFromApi(error));
      }
    });
};

export const updateBooking = (id, data) => dispatch => {
  // data.photo && bodyFormData.append("image", data.photo);
  // for (var key in data) {
  //   if (key !== "photo") {
  //     bodyFormData.append(key, data[key]);
  //   }
  // }
  dispatch(setBookingLoading());
  axios
    .put(`/api/event/${id}`, data)
    .then(res => {
      dispatch({
        type: GET_BOOKING,
        payload: res.data
      });
    })
    // eslint-disable-next-line no-unused-vars
    .catch(err =>
      dispatch({
        type: GET_BOOKING,
        payload: {}
      })
    );
};

export const deleteBooking = id => dispatch => {
  dispatch(setBookingLoading());
  axios
    .delete(`/api/booking/delete/${id}`)
    .then(result => {
      dispatch({
        type: DELETE_BOOKING,
        payload: result.data
      });
      dispatch(showSuccess("", "Booking deleted successfully!"));
    })

    .catch(err => {
      dispatch({
        type: DELETE_BOOKING_ERRORS,
        payload: err && err.response ? err.response.data.data : ""
      });
      dispatch(
        showError(err.response && err.response.data && err.response.data.data)
      );
    });
};

export const getCompanyCredit = data => dispatch => {
  axios
    .post(`/api/company/get/credit`, data)
    .then(result => {
      dispatch({
        type: COMPANY_CREDIT,
        payload: result.data
      });
    })
    .catch(err => {
      const error = err.response;
      dispatch({ type: BOOKING_ERRORS, payload: error.data });
    });
};

// Profile Loading
export const setBookingLoading = () => {
  return {
    type: BOOKING_LOADING
  };
};
