/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import InputField from "../../Components/Common/Form/InputField";
import { payNow } from "../../Actions/CreditAccountActions";

const $quoteSym = " ";
const styles = () => ({
  form_modal: {
    minWidth: "500px",
  },
  modalHeader: {
    paddingBottom: "5px",
    fontSize: "20px",
    fontWeight: "600",
    position: "relative",
    "&:after": {
      top: "46px",
      left: "26px",
      width: "24px",
      borderBottom: "3px solid #fb0",
      content: `'${$quoteSym}'`,
      position: "absolute",
    },
  },
  addButton: {
    color: "#fff",
    marginRight: "20px",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  cancelButton: {
    marginRight: "25px",
    backgroundColor: "#dddddd",
    color: "#151039",
    "&:hover": {
      color: "#151039",
      backgroundColor: "#d1d1d1!important",
    },
  },
  dialogContent: {
    height: "150px",
  },
});

const PaynowSchema = Yup.object().shape({
  amount: Yup.number()
    .required("This field is required.")
    .typeError("Please input a number."),
});

class PayingAccountPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.status !== prevState.status) {
      return { status: nextProps.status };
    }
    return [];
  }

  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <Formik
          initialValues={{ amount: "" }}
          validationSchema={PaynowSchema}
          onSubmit={(values, { setErrors, setSubmitting }) => {
            values.user_id = this.props.user_id;
            this.props.payNow(
              values,
              setErrors,
              this.props.history,
              setSubmitting
            );
            this.props.handleClose();
          }}
        >
          {({ values }) => (
            <Form className={classes.form_modal}>
              <DialogTitle
                id="issue-category-dialog-title"
                className={classes.modalHeader}
              >
                Paying Amount Details
              </DialogTitle>
              <DialogContent className={classes.dialogContent}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <InputField
                      name="amount"
                      label="Enter amount you received"
                      placeholder="Enter amount"
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.props.handleClose}
                  color="primary"
                  className={classes.cancelButton}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  className={classes.addButton}
                  type="submit"
                  variant="contained"
                >
                  Confirm
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    status: state.helpdesk.status,
    errors: state.errors,
  };
};
export default connect(mapStateToProps, {
  payNow,
})(withStyles(styles)(PayingAccountPopup));
