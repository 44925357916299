import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import {
  GroupSvg,
  EventSvg,
  CompanySvg,
  PartnerSvg,
  CoworkerSvg,
  AddVisitorSvg,
  CafeteriaSvg,
  MyOrderSvg,
  ClubSvg,
  VisitorsSvg,
  HelpdeskSvg,
} from "../../Assets/svgs";

const styles = () => {
  return {
    groupSvg: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: (props) => (props.paddingTop ? props.paddingTop : "4%"),
    },
    maintext: {
      color: "lightgrey",
      paddingBottom: "20px",
    },
    subtext: {
      color: "#ABABAB",
    },
  };
};

const DataNotFound = (props) => {
  const { type, text, subtext, subComponent, classes } = props;

  const renderSVG = () => {
    switch (type) {
      case "event":
        return <EventSvg />;
      case "coworker":
        return <CoworkerSvg />;
      case "company":
        return <CompanySvg />;
      case "partner":
        return <PartnerSvg />;
      case "addvisitor":
        return <AddVisitorSvg />;
      case "cafeteria":
        return <CafeteriaSvg />;
      case "myorders":
        return <MyOrderSvg />;
      case "allclubs":
        return <ClubSvg />;
      case "visitors":
        return <VisitorsSvg />;
      case "helpdesk":
        return <HelpdeskSvg />;

      default:
        return <GroupSvg />;
    }
    // return null;
  };
  return (
    <Grid
      container
      direction="column"
      justify="space-around"
      alignItems="center"
      className={classes.groupSvg}
    >
      <Grid
        item
        className={classes.image}
        style={{ width: props.width ? props.width : "300px" }}
      >
        {renderSVG()}
      </Grid>
      <Grid item>
        <Typography variant="h6" display="block" className={classes.maintext}>
          {text}
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          variant="caption"
          display="block"
          className={classes.subtext}
        >
          {subtext}
          {subComponent}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(DataNotFound);
