/* eslint-disable react/no-unused-state */
import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { Grid, Button } from "@material-ui/core";
import classNames from "classnames";
import embassdor2 from "../../Assets/embassdor2.png";
import {
  getCommunityAmbassdor,
  getoneCommunityAmbassdor,
  applyNow,
} from "../../Actions/CommunityAmbassadorAction";
import SuccessPopup from "../Visitor/SuccessPopup";
import DataNotFound from "../../Components/Common/DataNotFound";
import { checkPermission } from "../../Utils/checkPermission";

const styles = () => ({
  generalDetails: {
    padding: "3% 0% 0%",
  },
  cardLayout: {
    border: "1px solid #EFEFEF",
    borderRadius: "5px",
    padding: "10px",
  },
  submitbutton: {
    marginTop: "25px",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  disablesubmitbutton: {
    marginTop: "25px",
    backgroundColor: "gray",
  },
});

export class RocstarAmbassador extends Component {
  constructor(props) {
    super(props);
    this.state = {
      successPopup: false,
    };
  }

  componentDidMount() {
    this.props.getCommunityAmbassdor();
    this.props.getoneCommunityAmbassdor();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.communityEmbassdor !== this.props.communityEmbassdor) {
      let communityEmbassdor = this.props.communityEmbassdor || [];
      communityEmbassdor = communityEmbassdor.map((x, id) => {
        return Object.assign({}, x, {
          idx: id + 1,
        });
      });
      this.setState({
        communityEmbassdor,
      });
    }

    if (
      this.props.applyNowData !== prevProps.applyNowData &&
      this.props.applyNowData.success
    ) {
      this.openSuccessPopup();
    }
    if (
      this.props.singleuser !== prevProps.singleuser &&
      this.props.singleuser.success
    ) {
      this.props.getoneCommunityAmbassdor();
    }
  }

  applyNowAPIcall = () => {
    this.props.applyNow();
  };

  openSuccessPopup = () => {
    this.setState({ successPopup: true });
  };

  closeSuccessPopup = () => {
    this.setState({ successPopup: false });
    this.props.getCommunityAmbassdor();
    this.props.getoneCommunityAmbassdor();
  };

  handleSuccess = () => {
    this.closeSuccessPopup();
    this.props.history.push("/community-ambassador");
  };

  filteredCommunityEmbassdor = () => {
    const { communityEmbassdor } = this.props;
    const data =
      communityEmbassdor &&
      communityEmbassdor.filter(
        (item) =>
          item.ambassador_approve_status === "accept" &&
          item.ambassador_activation_status === "active"
      );
    return data;
  };

  render() {
    const { classes, communityEmbassdor, singleuser } = this.props;
    const defaultUser = localStorage.getItem("user_id");
    // eslint-disable-next-line func-names
    const FitlerData = communityEmbassdor.filter(function (hello) {
      return hello && hello.created_by && hello.created_by._id === defaultUser;
    });
    const EmbassdorData = this.filteredCommunityEmbassdor();
    return (
      <div className={classes.main}>
        <SuccessPopup
          data={this.state.successPopup}
          handleClose={this.closeSuccessPopup}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Grid container spacing={2} className={classes.generalDetails}>
              {EmbassdorData && EmbassdorData.length <= 0 ? (
                <DataNotFound
                  type="visitors"
                  text="No results found!"
                  subtext=""
                  paddingTop="6%"
                />
              ) : (
                EmbassdorData &&
                EmbassdorData.length > 0 &&
                EmbassdorData.map((Embassdor) => {
                  return (
                    <Grid item xs={6}>
                      {Embassdor.ambassador_approve_status === "accept" &&
                      Embassdor.ambassador_activation_status === "active" ? (
                        <Grid className={classes.cardLayout}>
                          <img
                            src={Embassdor.ambassador_profile_pic}
                            alt=""
                            width="156px"
                            height="176px"
                          />
                        </Grid>
                      ) : (
                        <Grid className={classes.cardLayout}>
                          <img src={embassdor2} alt="" />
                        </Grid>
                      )}
                    </Grid>
                  );
                })
              )}
            </Grid>
            {checkPermission("COMMUNITY_AMBASSADOR_CREATE") && (
              <div>
                {FitlerData.length >= 1 ||
                (singleuser && singleuser.is_applied === "yes") ? (
                  <Button
                    disabled
                    className={classNames(classes.disablesubmitbutton)}
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="Large"
                    onClick={this.applyNowAPIcall}
                  >
                    Apply Now
                  </Button>
                ) : (
                  <Button
                    className={classNames(classes.submitbutton)}
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="Large"
                    onClick={this.applyNowAPIcall}
                  >
                    Apply Now
                  </Button>
                )}
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    communityEmbassdor: state.communityAmbassador.community_embassadors,
    singleuser: state.communityAmbassador.community_one_embassadors,
    applyNowData: state.communityAmbassador.applynow,
  };
};

export default connect(mapStateToProps, {
  getCommunityAmbassdor,
  getoneCommunityAmbassdor,
  applyNow,
})(withStyles(styles)(RocstarAmbassador));
