import React from "react";
import {
  Grid,
  Paper,
  CssBaseline,
  withStyles,
  Toolbar,
  AppBar,
} from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import SearchBar from "../../Components/Common/SearchBar";
import DataNotFound from "../../Components/Common/DataNotFound";
import NoRecordsFound from "../../Components/Common/NoRecordsFound";
import { CircularLoader } from "../../Components/Common/Spinner";
import { checkPermission } from "../../Utils/checkPermission";
import ScreenHeader from "../../Components/Common/ScreenHeader";
import { getPartners } from "../../Actions/partnerActions";
import PartnerCard from "../../Components/Common/Cards/PartnerCard";

const styles = (theme) => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    backgroundColor: "white",
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    padding: theme.spacing(2),
    flexGrow: 1,
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      padding: theme.spacing(3),
    },
  },
  applicationHeader: {
    margin: "20px",
    borderBottom: "3px solid orange",
    paddingBottom: "5px",
  },
  heroUnit: {
    margin: "20px 0",
  },
  alignment: {
    float: "right",
  },

  appbar: {
    backgroundColor: "#ffffff",
    padding: "22px 0px 5px 0px",
  },
  root: {
    width: "100%",
    padding: "0px",
    paddingTop: "1%",
  },
  button: {
    margin: theme.spacing(1),
    fontSize: "10px",
    backgroundColor: theme.palette.applications.active,
    color: "#ffffff",
  },
  typo: {
    fontSize: "14px",
  },
  addpartner: {
    color: "#fff",
    padding: "7px 16px",
    borderRadius: "4px",
    textDecoration: "none",
    fontSize: "0.875rem",
    fontWeight: "500",
    boxShadow: "rgba(137, 163, 169, 0.3) 0px 3px 5px 2px",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  addsection: {
    display: "flex",
    justifyContent: "flex-end",
  },
  addoffers: {
    color: "#fff",
    padding: "7px 16px",
    borderRadius: "4px",
    textDecoration: "none",
    fontSize: "0.875rem",
    fontWeight: "500",
    marginLeft: "18px",
    boxShadow: "rgba(137, 163, 169, 0.3) 0px 3px 5px 2px",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  DataNotFound: {
    marginTop: "10px",
  },
  endSpacing: { padding: "8px 21px 0px" },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
});

class AdminPartners extends React.Component {
  constructor(props) {
    super(props);
    this.state = { partners: [], isSearch: false, search: "" };
  }

  componentDidMount() {
    this.props.getPartners();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.partners !== this.props.partners) {
      this.setState({
        partners: this.props.partners,
      });
    }
    if (prevProps.partners.loading !== this.props.partners.loading) {
      this.props.setLoading(this.props.partners.loading);
      this.setState({
        loading: this.props.partners.loading,
      });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.partners !== prevState.partners) {
      return {
        partners: nextProps.partners,
      };
    }
    return [];
  }

  searchPartner = (search, isSearch) => {
    this.setState({ isSearch: true, search });
    this.props.getPartners(search, isSearch);
  };

  render() {
    const { classes } = this.props;
    const { partners, isSearch, search, loading } = this.state;

    if (loading) {
      return <CircularLoader />;
    }

    if (partners && partners.length <= 0 && !isSearch) {
      return (
        <DataNotFound
          type="partner"
          text="You don't have any Partner Listed!!"
          subtext=""
          subComponent={
            <div className={classes.DataNotFound}>
              {checkPermission("PARTNER_CREATE") && (
                <Link to="/partner/add" className={classes.addpartner}>
                  Add Partner
                </Link>
              )}
            </div>
          }
        />
      );
    }

    return (
      <React.Fragment>
        <div className={classes.header}>
          <ScreenHeader type="All Partners" />
          <Grid
            container
            direction="row"
            justify="flex-end"
            className={classes.endSpacing}
          >
            {checkPermission("PARTNER_CREATE") && (
              <Grid item>
                <Link to="/partner-offer/add" className={classes.addoffers}>
                  Add Offers
                </Link>
              </Grid>
            )}
            {checkPermission("PARTNER_CREATE") && (
              <Grid item>
                <Link to="/partner/add" className={classes.addoffers}>
                  Add Partner
                </Link>
              </Grid>
            )}
          </Grid>
        </div>

        <div className={classes.root}>
          <AppBar position="static" className={classes.appbar}>
            <Toolbar>
              <SearchBar
                type="Search Partners"
                onSearch={this.searchPartner}
                value={this.state.search}
              />
              {/* <SortBy selectedItem="SortBy" style={{ color: "#049b04" }} /> */}
            </Toolbar>
          </AppBar>
        </div>
        {partners && partners.length <= 0 && isSearch && (
          <NoRecordsFound text={`No records found for your text "${search}"`} />
        )}
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            {/* Hero unit */}

            <Grid container spacing={2}>
              {partners &&
                partners.map((element) => {
                  return (
                    <Grid item lg={4} xs={12} sm={6} key={element.id}>
                      <PartnerCard
                        data={element}
                        history={this.props.history}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}

AdminPartners.propTypes = {};

const mapStateToProps = (state) => {
  return {
    partners: state.partners.partners,
    // loading: state.partners.loading,
  };
};

export default connect(mapStateToProps, { getPartners })(
  withStyles(styles)(AdminPartners)
);
