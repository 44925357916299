import React, { useState, useEffect } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { getEvents } from "../../Actions/eventActions";
import { CircularLoader } from "../../Components/Common/Spinner";

import EventCard from "../../Components/Common/Cards/EventCard";

const $quoteSym = " ";
const styles = () => ({
  events_section: {
    // padding: "10px"
  },
  event_card: {
    margin: "15px 0px",
  },
  noEvent: {
    padding: "10px 24px",
  },
  screenheader: {
    fontSize: "16px",
    fontWeight: "600",
    position: "relative",
    "&:after": {
      top: "24px",
      left: "0px",
      width: "24px",
      borderBottom: "3px solid #fb0",
      content: `'${$quoteSym}'`,
      position: "absolute",
    },
  },
  noVisitor: {
    paddingTop: "18px",
  },
});

function Events(props) {
  const { classes } = props;
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState([]);

  useEffect(() => {
    props.getEvents("", "", "upcoming");
  }, []);

  useEffect(() => {
    if (props.event_participant.success) props.getEvents("", "", "upcoming");
  }, [props.event_participant]);

  useEffect(() => {
    if (props.register_team && props.register_team.success) {
      props.getEvents("", "", "upcoming");
    }
  }, [props.register_team]);

  useEffect(() => {
    setEvents(props.events.upcoming);
  }, [props.events]);

  useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);

  if (loading) {
    return <CircularLoader />;
  }
  if (!events) {
    return <div></div>;
  }
  const renderEventCards = (event) => {
    return (
      event &&
      event.map((element, i) => {
        if (i > 2) {
          return "";
        }
        return (
          <Grid item lg={4} xs={12} sm={6} key={element.id}>
            <EventCard data={element} history={props.history} />
          </Grid>
        );
      })
    );
  };
  return (
    <div className={classes.events_section}>
      {events && events.length !== 0 && (
        <span className={classes.screenheader} align="left">
          Events
        </span>
      )}
      <div className={classes.event_card}>
        <Grid container spacing={2}>
          {renderEventCards(events)}
        </Grid>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    events: state.events,
    loading: state.events.loading,
    errors: state.errors,
    event_participant: state.events.event_participant,
    register_team: state.events.register_team,
  };
};

export default connect(mapStateToProps, { getEvents })(
  withStyles(styles)(Events)
);
