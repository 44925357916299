/* eslint-disable camelcase */
import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CreateIcon from "@material-ui/icons/Create";
import { CircularLoader } from "../../Components/Common/Spinner";
import InputField from "../../Components/Common/Form/InputField";
import {
  addresourcecategory,
  getResourcecategory,
  getSingleResourcecategory,
  editResourceCategory,
} from "../../Actions/resourcecategoryActions";
import { checkPermission } from "../../Utils/checkPermission";
import ScreenHeader from "../../Components/Common/ScreenHeader";
import nodata from "../../Assets/nodata.png";

const $quoteSym = " ";

const styles = () => ({
  root: {
    backgroundColor: "#fff",
  },
  table: {
    minWidth: 650,
    marginTop: "20px",
  },
  resource_form_modal: {
    width: "500px",
  },
  form_modal: {
    minWidth: "500px",
  },
  main: {
    padding: "24px",
  },
  editbutton: {
    borderRadius: "50px",
  },
  editicon: {
    fontSize: "16px",
    marginRight: "6px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  add_resource: {
    width: "100%",
    textAlign: "right",
  },
  add_new_resource: {
    width: "100%",
    textAlign: "center",
    marginLeft: "-45px",
    marginTop: "20px",
  },
  addcategorybutton: {
    color: "#fff",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  ScreenHeader: {
    marginLeft: "-20px",
    width: "100%",
  },
  cancelbutton: {
    marginRight: "20px",
    backgroundColor: "#dddddd",
    color: "#151039",
    "&:hover": {
      color: "#151039",
      backgroundColor: "#d1d1d1!important",
    },
  },
  addbutton: {
    color: "#fff",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  modelHeader: {
    paddingBottom: "5px",
    position: "relative",
    "&:after": {
      top: "48px",
      left: "24px",
      width: "24px",
      borderBottom: "3px solid #fb0",
      content: `'${$quoteSym}'`,
      position: "absolute",
    },
  },
  modalFooter: {
    padding: "8px 24px",
  },
  root1: {
    "&:nth-child(even)": {
      backgroundColor: "#FCFDFE",
    },
  },
  backButton: {
    margin: "0% 0% 2% 0%",
  },
});
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F8FAFF",
    color: theme.palette.common.black,
    borderBottom: "none",
    padding: "8px 40px 8px 16px",
  },
  body: {
    fontSize: 14,
    textTransform: "capitalize",
    borderBottom: "none",
  },
}))(TableCell);
const ResourceSchema = Yup.object().shape({
  name: Yup.string().trim().required("This field is required."),
});

export class AdminSetup extends Component {
  constructor(props) {
    super(props);
    this.state = { resource_categories: [], id: "" };
  }

  state = {
    modalOpen: false,
  };

  componentDidMount() {
    this.props.getResourcecategory();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.resource_categories !== prevState.resource_categories) {
      return { resource_categories: nextProps.resource_categories };
    }
    return [];
  }

  componentDidUpdate(prevProps) {
    if (prevProps.resource_categories !== this.props.resource_categories) {
      let resource_categories = this.props.resource_categories || [];
      resource_categories = resource_categories.map((x, id) => {
        return Object.assign({}, x, {
          idx: id + 1,
        });
      });
      this.setState({
        resource_categories,
        // rowData: resource_categories
      });
    }
    if (
      this.props.edit_resource_category !== prevProps.edit_resource_category &&
      this.props.edit_resource_category.success
    ) {
      this.handleClose();
    }
    if (prevProps.loading !== this.props.loading) {
      this.setState({
        loading: this.props.loading,
      });
    }
  }

  handleClickOpen = () => {
    this.setState({
      modalOpen: true,
      id: "",
    });
  };

  handleClickModalOpen = (id) => {
    this.setState({
      id,
      modalOpen: true,
    });
    this.props.getSingleResourcecategory(id);
  };

  handleClose = () => {
    this.setState({
      modalOpen: false,
    });
    this.props.getResourcecategory();
  };

  onBackButton = (e) => {
    e.preventDefault();
    this.props.history.goBack();
  };

  render() {
    const { classes, single_resource_category } = this.props;
    const { loading, id } = this.state;
    let data = true;
    // const { space } = this.state;
    if (loading) {
      return <CircularLoader />;
    }
    // eslint-disable-next-line no-shadow
    const getInitialValues = (id) => {
      if (id) {
        return {
          name: single_resource_category.name,
        };
      }
      return {
        name: "",
      };
    };
    if (this.state.resource_categories && this.state.resource_categories <= 0) {
      data = false;
    }
    return (
      <div className={classes.main}>
        <div className={classes.header}>
          {data && (
            <div className={classes.ScreenHeader}>
              <ScreenHeader type="All Resource Categories" />
            </div>
          )}

          <Dialog
            open={this.state.modalOpen}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
          >
            <Formik
              initialValues={getInitialValues(id)}
              enableReinitialize
              validationSchema={ResourceSchema}
              onSubmit={(values, { setErrors, setSubmitting }) => {
                // eslint-disable-next-line no-param-reassign
                values.created_by = "5cde64595cafe71468f0c483";
                // eslint-disable-next-line no-param-reassign
                values.space = localStorage.getItem("space_id");
                if (id) {
                  this.props.editResourceCategory(
                    id,
                    values,
                    setErrors,
                    setSubmitting
                  );
                  this.handleClose();
                } else {
                  this.props.addresourcecategory(
                    values,
                    setErrors,
                    this.props.history,
                    setSubmitting
                  );
                  this.props.getResourcecategory();
                  // this.props.history.push("/resource");
                  this.handleClose();
                }
              }}
            >
              {() => (
                <Form className={classes.resource_form_modal}>
                  <DialogTitle
                    id="plan-dialog-title"
                    className={classes.modelHeader}
                  >
                    {id ? "Edit Resource Category" : "Add Resource Category"}
                  </DialogTitle>
                  <DialogContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <InputField
                          name="name"
                          label="Category"
                          placeholder="Conference Room"
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions className={classes.modalFooter}>
                    <Button
                      onClick={this.handleClose}
                      color="primary"
                      className={classes.cancelbutton}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      className={classes.addbutton}
                      type="submit"
                      variant="contained"
                    >
                      {id ? "Edit" : "Add"}
                    </Button>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          </Dialog>

          {data && (
            <div className={classes.add_resource}>
              {checkPermission("RESOURCETYPE_CREATE") && (
                <Button
                  onClick={this.handleClickOpen}
                  className={classes.addcategorybutton}
                  size="medium"
                  variant="contained"
                  color="primary"
                >
                  Add Resource Category
                </Button>
              )}
            </div>
          )}
        </div>
        <div className={classes.root}>
          {data && (
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Sr. No.</StyledTableCell>
                  <StyledTableCell align="left">Category</StyledTableCell>
                  <StyledTableCell align="center" />
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.resource_categories &&
                  this.state.resource_categories.map((item, key) => {
                    return (
                      <TableRow className={classes.root1}>
                        <StyledTableCell component="th" scope="row">
                          {key + 1}
                        </StyledTableCell>

                        <StyledTableCell align="left">
                          {item.name}
                        </StyledTableCell>
                        {checkPermission("RESOURCETYPE_UPDATE") && (
                          <StyledTableCell align="right">
                            <Button
                              variant="outlined"
                              size="small"
                              color="primary"
                              className={classes.editbutton}
                              onClick={() =>
                                this.handleClickModalOpen(item._id)
                              }
                            >
                              <CreateIcon
                                size="small"
                                className={classes.editicon}
                              />
                              Edit
                            </Button>
                          </StyledTableCell>
                        )}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          )}
          {!data && (
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              className={classes.headerSpacing}
            >
              <Grid item justify="center">
                <Grid item sm={12} lg={12}>
                  <img src={nodata} alt="" width="76%" />
                </Grid>
                <div className={classes.add_new_resource}>
                  {checkPermission("RESOURCETYPE_CREATE") && (
                    <Button
                      onClick={this.handleClickOpen}
                      className={classes.addcategorybutton}
                      size="medium"
                      variant="contained"
                      color="primary"
                    >
                      Add Resource Category
                    </Button>
                  )}
                </div>
              </Grid>
            </Grid>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    resource_categories: state.resource_categories.resource_categories,
    loading: state.resource_categories.loading,
    single_resource_category:
      state.resource_categories.single_resource_category,
    edit_resource_category: state.resource_categories.edit_resource_category,
  };
};
export default connect(mapStateToProps, {
  addresourcecategory,
  getResourcecategory,
  getSingleResourcecategory,
  editResourceCategory,
})(withStyles(styles)(withRouter(AdminSetup)));
