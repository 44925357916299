/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
import React, { useState } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import classNames from "classnames";
import { Grid, FormLabel, IconButton } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import LaptopIcon from "@material-ui/icons/Laptop";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import NotificationPopup from "./NotificationPopup";
import Collasal from "../../Assets/Collasal.png";
import OnePage from "../../Assets/OnePage.png";

const styles = (theme) => ({
  main: {
    backgroundColor: "#fff",
    paddingLeft: "1%",
  },
  label: {
    margin: "15px 0px 8px 0px",
    fontSize: "12px",
    fontWeight: "700",
  },
  descriptionLabel: {
    margin: "15px 0px 8px 0px",
    fontSize: "12px",
    fontWeight: "400",
    color: "rgba(0, 0, 0, 0.54)",
  },
  margin: {
    margin: theme.spacing(1),
    color: "#979493",
  },
  setupImgSelectPopup: {
    height: "100px",
    width: "100px",
    padding: "2px",
    margin: "3px",
    border: "2px solid #5f5799",
    cursor: "pointer",
  },
  setupImgPopup: {
    height: "100px",
    width: "100px",
    padding: "2px",
    margin: "3px",
    border: "0px solid #211b4e",
    cursor: "pointer",
  },
});

const notification = Yup.object().shape({
  //   subject: Yup.string().required("Field is Required")
});
const PopupNotification = (props) => {
  const { classes } = props;
  const [modalOpen, setmodalOpen] = useState(false);
  const [imageSelector1, setimageSelector1] = useState(false);
  const [imageSelector, setimageSelector] = useState(true);
  const handleImage = () => {
    setimageSelector(true);
    setimageSelector1(false);
  };

  const handleImage1 = () => {
    setimageSelector(false);
    setimageSelector1(true);
  };
  const handleClickOpen = () => {
    setmodalOpen(true);
  };
  const handleClose = () => {
    setmodalOpen(false);
  };
  return (
    <React.Fragment>
      <div className={classes.main}>
        <div className={classes.header}>
          <Formik
            initialValues={{
              subject: "",
              button_link: "",
            }}
            validationSchema={notification}
            // eslint-disable-next-line no-unused-vars
            onSubmit={(values, { props, setErrors, setSubmitting }) => {
              // eslint-disable-next-line no-param-reassign
            }}
          >
            {() => (
              <Form className={classes.plan_form_modal}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <span>
                      <FormLabel
                        className={classes.descriptionLabel}
                        component="legend"
                      >
                        Select Template
                      </FormLabel>
                      <FormControl component="fieldset">
                        <div aria-hidden onClick={handleImage}>
                          <img
                            src={OnePage}
                            alt="manage notifications"
                            className={classNames(
                              imageSelector
                                ? classes.setupImgSelectPopup
                                : classes.setupImgPopup
                            )}
                          />
                        </div>
                        <span style={{ marginLeft: "31px" }}>1 Page</span>
                      </FormControl>
                      <FormControl component="fieldset">
                        <div aria-hidden onClick={handleImage1}>
                          <img
                            src={Collasal}
                            alt="manage notifications"
                            className={classNames(
                              imageSelector1
                                ? classes.setupImgSelectPopup
                                : classes.setupImgPopup
                            )}
                          />
                        </div>
                        <span style={{ marginLeft: "30px" }}>Collasal</span>
                      </FormControl>
                    </span>
                  </Grid>
                  {imageSelector && (
                    <Grid item xs={12} sm={12}>
                      <FormLabel
                        className={classes.descriptionLabel}
                        component="legend"
                      >
                        Web Pop-up Notifications
                      </FormLabel>
                      <IconButton
                        aria-label="Web Notifications"
                        className={classes.margin}
                        onClick={handleClickOpen}
                      >
                        <LaptopIcon fontSize="large" />
                      </IconButton>
                    </Grid>
                  )}
                  {imageSelector1 && (
                    <Grid item xs={12} sm={12}>
                      <FormLabel
                        className={classes.descriptionLabel}
                        component="legend"
                      >
                        Web Pop-up Notifications
                      </FormLabel>
                      <IconButton
                        aria-label="Web Notifications"
                        className={classes.margin}
                        onClick={handleClickOpen}
                      >
                        <LaptopIcon fontSize="large" />
                      </IconButton>
                      <IconButton
                        aria-label="Web Notifications"
                        className={classes.margin}
                        onClick={handleClickOpen}
                      >
                        <LaptopIcon fontSize="large" />
                      </IconButton>
                      <IconButton
                        aria-label="Web Notifications"
                        className={classes.margin}
                        onClick={handleClickOpen}
                      >
                        <LaptopIcon fontSize="large" />
                      </IconButton>
                      <IconButton
                        aria-label="Web Notifications"
                        className={classes.margin}
                        onClick={handleClickOpen}
                      >
                        <LaptopIcon fontSize="large" />
                      </IconButton>
                      <IconButton
                        aria-label="Web Notifications"
                        className={classes.margin}
                        onClick={handleClickOpen}
                      >
                        <LaptopIcon fontSize="large" />
                      </IconButton>
                      <IconButton
                        aria-label="Web Notifications"
                        className={classes.margin}
                        onClick={handleClickOpen}
                      >
                        <LaptopIcon fontSize="large" />
                      </IconButton>
                    </Grid>
                  )}
                  {imageSelector && (
                    <Grid item xs={12} sm={12}>
                      <FormLabel
                        className={classes.descriptionLabel}
                        component="legend"
                      >
                        Mobile Pop-up Notifications
                      </FormLabel>
                      <IconButton
                        aria-label="Mobile Notifications"
                        className={classes.margin}
                        onClick={handleClickOpen}
                      >
                        <PhoneAndroidIcon fontSize="large" />
                      </IconButton>
                    </Grid>
                  )}
                  {imageSelector1 && (
                    <Grid item xs={12} sm={12}>
                      <FormLabel
                        className={classes.descriptionLabel}
                        component="legend"
                      >
                        Mobile Pop-up Notifications
                      </FormLabel>
                      <IconButton
                        aria-label="Mobile Notifications"
                        className={classes.margin}
                        onClick={handleClickOpen}
                      >
                        <PhoneAndroidIcon fontSize="large" />
                      </IconButton>
                      <IconButton
                        aria-label="Mobile Notifications"
                        className={classes.margin}
                        onClick={handleClickOpen}
                      >
                        <PhoneAndroidIcon fontSize="large" />
                      </IconButton>
                      <IconButton
                        aria-label="Mobile Notifications"
                        className={classes.margin}
                        onClick={handleClickOpen}
                      >
                        <PhoneAndroidIcon fontSize="large" />
                      </IconButton>
                      <IconButton
                        aria-label="Mobile Notifications"
                        className={classes.margin}
                        onClick={handleClickOpen}
                      >
                        <PhoneAndroidIcon fontSize="large" />
                      </IconButton>
                      <IconButton
                        aria-label="Mobile Notifications"
                        className={classes.margin}
                        onClick={handleClickOpen}
                      >
                        <PhoneAndroidIcon fontSize="large" />
                      </IconButton>
                      <IconButton
                        aria-label="Mobile Notifications"
                        className={classes.margin}
                        onClick={handleClickOpen}
                      >
                        <PhoneAndroidIcon fontSize="large" />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </Form>
            )}
          </Formik>
          <NotificationPopup data={modalOpen} close={handleClose} />
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = () => {
  return {};
};

export default connect(
  mapStateToProps,
  {}
)(withStyles(styles)(PopupNotification));
