import React, { Component } from "react";

// eslint-disable-next-line react/prefer-stateless-function
class ViewaSpace extends Component {
  render() {
    return <div>Space</div>;
  }
}

export default ViewaSpace;
