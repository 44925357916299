/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable camelcase */
import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Formik } from "formik";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  IconButton,
  Dialog,
  Typography,
  Grid,
  DialogActions,
  DialogContent,
  DialogTitle,
  colors,
  TableBody,
  TableHead,
  TableRow,
  Table,
  Paper,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import * as Yup from "yup";
import { uploadexcel } from "../../Actions/participantActions";
import Cell from "../../Components/Common/Table/TableCell";
import Header from "../../Components/Common/Table/TableHeader";
import { CircularLoader } from "../../Components/Common/Spinner";
import "../company.css";

const $quoteSym = " ";
const styles = () => ({
  lightbg: {
    padding: "24px",
    backgroundColor: "#fff",
    maxWidth: "500px",
    margin: "0 auto",
  },
  label: {
    margin: "15px 0px 8px 0px",
    fontSize: "12px",
    fontWeight: "400",
    color: "rgba(0, 0, 0, 0.54)",
  },
  button: {
    width: "100%",
    marginRight: "25px",
  },
  bookingDate: {
    marginBottom: "7px",
  },
  booking_date_time: {
    display: "flex",
    justifyContent: "space-between",
  },
  booking_duration: {
    display: "flex",
    justifyContent: "space-between",
  },
  formfooter: {
    margin: "24px 15px",
  },
  form: {
    marginBottom: "20px",
  },
  ImportExcelLabel: {
    border: "1px solid #ccc",
    display: "inline-block",
    padding: "0px 6px 8px 6px",
    cursor: "pointer",
    marginTop: "9px",
  },
  ImportExcelActions: {
    position: "relative",
  },
  uploadfiletext: {
    textAlign: "center",
    position: "relative",
    fontSize: "20px",
    fontWeight: "400",
    color: "#c0c0c0",
  },
  uploadfilesubtext: {
    textAlign: "center",
    position: "relative",
    fontSize: "14px",
    fontWeight: "400",
    color: "#c0c0c0",
    marginBottom: "5px",
  },
  uploadfile: {
    width: "500px",
    height: "250px",
    textAlign: "center",
    position: "relative",
    border: "#ddd 3px dashed",
    borderRadius: "4px",
  },
  uploadfilecontainer: {
    marginTop: "14%",
  },
  inputfile: {
    position: "relative",
    boxShadow: "none",
    backgroundColor: "#4d90fe",
    color: "#fff",
    userSelect: "none",
    display: "block",
    marginTop: "10px",
    marginBottom: "10px",
    border: "none",
  },
  buttonProgress: {
    color: colors.green[500],
    position: "absolute",
    top: "50%",
    right: "30%",
    marginTop: -12,
    marginLeft: -12,
  },

  dateLabel: {
    fontSize: "12px",
    fontWeight: "normal",
  },
  bookbutton: {
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  cancelbutton: {
    marginRight: "-10px",
    float: "right",
  },
  paper: {
    overFlow: "scroll",
    maxHeight: "300px",
  },
  table: {
    marginBottom: "20px",
    width: "300px",
  },
  applicationHeader: {
    top: "10px",
    "&:after": {
      width: "24px",
      borderBottom: "3px solid #fb0",
      content: `'${$quoteSym}'`,
      position: "absolute",
    },
  },
  uploadIcon: {
    position: "relative",
    top: "6px",
  },
  successmessage: {
    backgroundColor: "#b6f2b8",
    padding: "7px",
    marginBottom: "3px",
    borderRadius: " 4px",
  },
  errormessage: {
    backgroundColor: "#f7a1a1",
    padding: "7px",
    marginBottom: "10px",
    borderRadius: " 4px",
  },
});

const ImportExcelSchema = Yup.object().shape({
  fileupload: Yup.string().required("This field is required."),
});

class EventImportExcel extends React.Component {
  state = {
    modalVisible: true,
    participants_data: [],
  };

  constructor(props) {
    super(props);
    this.formik = React.createRef();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.participants_data !== prevState.participants_data) {
      return {
        participants_data: nextProps.participants_data,
        registercount: nextProps.registercount,
      };
    }
    return [];
  }

  componentDidUpdate(prevProps) {
    if (prevProps.participants_data !== this.props.participants_data) {
      if (this.props.registercount.alreadyregistercount === 0) {
        this.handleCloseDialog();
      }
    }
    if (prevProps.loading !== this.props.loading) {
      this.setState({
        loading: this.props.loading,
      });
    }
  }

  renderBody = () => {
    const { classes } = this.props;
    const { participants_data, registercount, loading } = this.state;
    return (
      <div>
        {!loading && participants_data && participants_data.length !== 0 && (
          <div>
            <div className={classes.successmessage}>
              {registercount.newlyregistercount} attendees updated successfully
            </div>
            <div className={classes.errormessage}>
              {registercount.alreadyregistercount} attendees already exists
            </div>
            <TableHead>
              <TableRow>
                <Header>Sr. No.</Header>
                <Header>Name</Header>
              </TableRow>
            </TableHead>
            <TableBody>
              {participants_data.map((row, key) => (
                <TableRow className={classes.root1}>
                  <Cell>{Number(key) + 1}</Cell>
                  <Cell className={classes.descriptiontable}>{row.name}</Cell>
                </TableRow>
              ))}
            </TableBody>
          </div>
        )}
      </div>
    );
  };

  handleFiles = (files) => {
    // Check for the letious File API support.
    if (window.FileReader) {
      // FileReader are supported.
      const data = {
        event_id: this.props.eventID,
        file: files.target.files[0],
      };

      this.props.uploadexcel(data);
      this.setState({ modalVisible: false });
    }
  };

  handleCloseDialog = () => {
    this.setState({ modalVisible: true }, () => this.props.handleClose());
  };

  render() {
    const { classes } = this.props;
    const { loading } = this.state;
    // const { participants_data } = this.state;
    // const itemLength = participants_data ? participants_data.length : 0;

    return (
      <React.Fragment>
        <Dialog
          open={this.props.modalOpen}
          onClose={() => {
            this.handleCloseDialog();
          }}
          aria-labelledby="plan-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            className={classes.applicationHeader}
            id="plan-dialog-title"
          >
            {this.state.modalVisible ? "Open a file" : "Registered Users"}
            <IconButton
              aria-label="close"
              className={classes.cancelbutton}
              onClick={() => this.handleCloseDialog()}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <Formik
              ref={this.formik}
              validationSchema={ImportExcelSchema}
              render={({ errors, handleSubmit }) => (
                <form
                  className={classes.form}
                  onSubmit={handleSubmit}
                  encType="multipart/form-data"
                  autoComplete="off"
                >
                  {this.state.modalVisible && (
                    <Grid item xs={12} sm={12}>
                      <div className={classes.uploadfile}>
                        <div className={classes.uploadfilecontainer}>
                          <div className={classes.uploadfiletext}>
                            Drag a file here
                          </div>
                          <div className={classes.uploadfilesubtext}>
                            Or, if you prefer...
                          </div>
                          <label
                            htmlFor="file-upload"
                            className={classes.ImportExcelLabel}
                          >
                            <CloudUploadIcon className={classes.uploadIcon} />{" "}
                            Select a file from your device
                          </label>
                          <input
                            id="file-upload"
                            type="file"
                            style={{ display: "none" }}
                            onChange={this.handleFiles}
                          />

                          <div id="output"></div>
                          {loading && <CircularLoader />}
                        </div>
                      </div>
                    </Grid>
                  )}
                  {!this.state.modalVisible && (
                    <Grid>
                      <Paper className={classes.paper}>
                        <div>
                          {loading && <CircularLoader />}
                          <Table className={classes.table}>
                            {this.renderBody()}
                          </Table>
                        </div>
                      </Paper>
                    </Grid>
                  )}

                  <DialogActions className={classes.ImportExcelActions}>
                    <Typography as="h5" style={{ color: "red" }}>
                      {errors.generic}
                    </Typography>
                  </DialogActions>
                </form>
              )}
            />
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errors: state.errors,
    participants_data: state.participants.participants_data.alreadyregister,
    registercount: state.participants.participants_data,
    loading: state.participants.loading,
  };
};

export default connect(mapStateToProps, { uploadexcel })(
  withStyles(styles)(withRouter(EventImportExcel))
);
