/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router-dom";
import {
  CssBaseline,
  // AppBar,
  // Toolbar,
  Paper,
  Typography,
  Grid,
} from "@material-ui/core";
import ScreenHeader from "../../Components/Common/ScreenHeader";
//  import SearchBar from "../../Components/Common/SearchBar";
import {
  getMyOrders,
  getMyOrdersAccountDetails,
} from "../../Actions/OrderActions";
import { getallFoodItem } from "../../Actions/foodItemActions";
import DataNotFound from "../../Components/Common/DataNotFound";
import { CircularLoader } from "../../Components/Common/Spinner";
import DeliveredOrder from "./DeliveredOrder";

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    flexGrow: 1,
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      padding: theme.spacing(3),
    },
  },
  main: {
    padding: "24px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  active: {
    backgroundColor: "#211b4e",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#211b4e",
      color: "#fff",
    },
  },
  inActive: {
    color: "#211b4e",
  },
  statusButton: {
    borderRadius: "50px",
    marginRight: "10px",
    border: "1px solid #211b4e",
    height: "30px",
    padding: "1px 12px",
    "&:hover": {
      backgroundColor: "#211b4e",
      color: "#fff",
    },
  },
  appbar: {
    backgroundColor: "#ffffff",
  },
  searchSection: {
    margin: "24px 0px 0px",
  },
  pending: {
    backgroundColor: "#4d7cfe",
    color: "#fff",
  },
  createPostSection: {
    paddingLeft: "20px",
  },
  cardLayout: {
    border: "1px solid #EFEFEF",
    borderRadius: "5px",
    boxShadow: "0 9px 10px rgba(0, 0, 0, 0.1), 0 4px 0px rgba(0,0,0,0.05)",
    padding: "10px",
    margin: "20px 7px",
    width: "29%",
    height: "120px",
  },
  billAmount: {
    color: "#FF8800",
    fontSize: "40px",
    fontWeight: "normal",
  },
  paidAmount: {
    color: "#6FC46C",
    fontSize: "40px",
    fontWeight: "normal",
  },
  pendingAmount: {
    color: "#F84848",
    fontSize: "40px",
    fontWeight: "normal",
  },
  title: {
    fontSize: "13px",
    color: "#222222",
    fontWeight: "500",
  },
});
class OrderStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "delivered",
      // deliveredActive: "inActive"
      // isSearch: false,
      // search: ""
    };
  }

  componentDidMount() {
    this.props.getMyOrders();
    this.props.getMyOrdersAccountDetails();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.get_order !== prevState.get_order) {
      return { get_order: nextProps.get_order };
    }
    if (
      nextProps.get_order_account_details !==
      prevState.get_order_account_details
    ) {
      return { get_order_account_details: nextProps.get_order_account_details };
    }

    if (
      nextProps.mainOrderStatusData !== prevState.mainOrderStatusData &&
      nextProps.mainOrderStatusData.success
    ) {
      return null;
    }
    return {};
  }

  componentDidUpdate(prevProps) {
    // if (
    //   this.props.mainOrderStatusData !== prevProps.mainOrderStatusData &&
    //   this.props.mainOrderStatusData.success
    // ) {
    //   this.props.getDeliveredPending(this.state.status);
    // }
    if (prevProps.loading !== this.props.loading) {
      this.props.setLoading(this.props.loading);
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        loading: this.props.loading,
      });
    }
  }

  // handleClickStatus = status => {
  //   if (status === "delivered") {
  //     this.setState(
  //       {
  //         status,
  //         deliveredActive: "active",
  //         pendingActive: "inActive",
  //         rejectedActive: "inActive"
  //       },
  //       () => {
  //         this.props.getDeliveredPending(this.state.status);
  //       }
  //     );
  //   } else if (status === "pending") {
  //     this.setState(
  //       {
  //         status,
  //         pendingActive: "active",
  //         deliveredActive: "inActive",
  //         rejectedActive: "inActive"
  //       },
  //       () => {
  //         this.props.getDeliveredPending(this.state.status);
  //       }
  //     );
  //   } else if (status === "reject") {
  //     this.setState(
  //       {
  //         status,
  //         rejectedActive: "active",
  //         deliveredActive: "inActive",
  //         pendingActive: "inActive"
  //       },
  //       () => {
  //         this.props.getDeliveredPending(this.state.status);
  //       }
  //     );
  //   }
  // };

  searchCafeteria = (search, isSearch) => {
    this.setState({
      // isSearch: true,
      // search
    });
    this.props.getallFoodItem(search, isSearch);
  };

  isEmpty = (obj) => {
    const { hasOwnProperty } = Object.prototype;
    // null and undefined are "empty"
    if (obj == null) return true;

    // Assume if it has a length property with a non-zero value
    // that that property is correct.
    if (obj.length > 0) return false;
    if (obj.length === 0) return true;

    // If it isn't an object at this point
    // it is empty, but it can't be anything *but* empty
    // Is it empty?  Depends on your application.
    if (typeof obj !== "object") return true;

    // Otherwise, does it have any properties of its own?
    // Note that this doesn't handle
    // toString and valueOf enumeration bugs in IE < 9
    // eslint-disable-next-line no-restricted-syntax
    for (const key in obj) {
      if (hasOwnProperty.call(obj, key)) return false;
    }

    return true;
  };

  render() {
    const { classes } = this.props;
    const { get_order, loading, get_order_account_details } = this.state;
    const paid_amount =
      (get_order_account_details && get_order_account_details.paid_amount) || 0;
    const bill_amount =
      (get_order_account_details && get_order_account_details.total_amount) ||
      0;
    if (loading) {
      return <CircularLoader />;
    }

    if ((get_order && get_order.length <= 0) || get_order === null) {
      return (
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <DataNotFound
              paddingTop="9%"
              type="myorders"
              text="You don't have any Orders !!"
            />
          </Paper>
        </main>
      );
    }

    return (
      <React.Fragment>
        <CssBaseline />
        <Paper className={classes.paper}>
          <div className={classes.header}>
            <ScreenHeader type="Order Status" />
          </div>
          {!this.isEmpty(get_order_account_details) && (
            <div>
              <Grid
                className={classes.createPostSection}
                container
                item
                lg={12}
                xs={12}
              >
                <Grid className={classes.cardLayout}>
                  <Typography align="left" className={classes.billAmount}>
                    {bill_amount}
                    {/* 800 */}
                  </Typography>
                  <Typography align="left" className={classes.title}>
                    Total Bill Amount
                  </Typography>
                </Grid>

                <Grid className={classes.cardLayout}>
                  <Typography align="left" className={classes.paidAmount}>
                    {paid_amount}
                    {/* 200 */}
                  </Typography>
                  <Typography align="left" className={classes.title}>
                    Total Paid Amount
                  </Typography>
                </Grid>

                <Grid className={classes.cardLayout}>
                  <Typography align="left" className={classes.pendingAmount}>
                    {paid_amount - bill_amount}
                    {/* // 1000 */}
                  </Typography>
                  <Typography align="left" className={classes.title}>
                    Total Balance Amount
                  </Typography>
                </Grid>
              </Grid>
            </div>
          )}
          {/* <div className={classes.searchSection}>
            <AppBar position="static" className={classes.appbar}>
              <Toolbar>
                <SearchBar
                  type="Search"
                  className={classes.searchBox}
                  onSearch={this.searchCafeteria}
                />
              </Toolbar>
            </AppBar>
          </div> */}
          <main className={classes.layout}>
            <Paper className={classes.paper}>
              {/* Hero unit */}
              {this.state.status === "delivered" && (
                <DeliveredOrder data={this.state.get_order} />
              )}
            </Paper>
          </main>
        </Paper>
      </React.Fragment>
    );
  }
}
OrderStatus.propTypes = {};
const mapStateToProps = (state) => {
  return {
    get_order: state.get_all_order.my_orders,
    mainOrderStatusData: state.get_all_order.mainOrderStatusData,
    loading: state.get_all_order.loading,
    get_order_account_details:
      state.get_all_order.my_orders_account_details || {},
  };
};
export default connect(mapStateToProps, {
  getallFoodItem,
  getMyOrders,
  getMyOrdersAccountDetails,
})(withStyles(styles)(withRouter(OrderStatus)));
