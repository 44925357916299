/* eslint-disable camelcase */
import React, { useState } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import classNames from "classnames";
import FormControl from "@material-ui/core/FormControl";
import { connect } from "react-redux";
import { capitalize } from "../../Utils/StringFunctions";
import { CommunityAmbassdorStatusTypes } from "../../Components/Common/Types/HelpdeskStatusType";
import {
  updateStatus,
  getCommunityAmbassdor,
} from "../../Actions/CommunityAmbassadorAction";

const styles = (theme) => {
  return {
    active: {
      color: "#55A3F1 !important",
      backgroundColor: "#fff !important",
    },
    inactive: {
      color: "#4CAF50 !important",
      backgroundColor: "#fff !important",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 90,
    },
    statusDropdown: {
      color: "black",
      border: `1px solid ${theme.palette.border.color}`,
      borderRadius: "4px",
      height: "33px",
      // padding: "13px 12px 13px",
      fontSize: "12px",
      paddingLeft: "10px",
    },
    icon: {
      color: "#000",
      right: "8px",
      top: "5px",
    },
  };
};

const AmbassdorStatus = (props) => {
  // const [selected, setselected] = useState(props.selectedItem || "");
  // const [setOpen] = useState(false);
  const [Embassdor_id, setEmbassdor_id] = useState(props.Embassdor_id);

  const { classes } = props;
  const callStatusChangeAPI = (el) => {
    props.updateStatus(Embassdor_id, {
      ambassador_activation_status: el.target.value,
    });
    // setselected(el.target.value);
    setEmbassdor_id(Embassdor_id);
    props.getCommunityAmbassdor();
  };
  // const handleClose = () => {
  //   setOpen(false);
  //   setselected(props.selectedItem);
  // };

  return (
    <React.Fragment>
      <FormControl variant="filled" className={classes.formControl}>
        <Select
          className={classNames(
            classes[props.selectedItem],
            classes.statusDropdown
          )}
          value={props.selectedItem}
          onChange={callStatusChangeAPI}
          name="name"
          disableUnderline
          style={{ color: "black" }}
          variant="standard"
          data={Embassdor_id}
          classes={{
            icon: classes.icon,
          }}
        >
          {CommunityAmbassdorStatusTypes.map((element) => {
            return (
              <MenuItem value={element.value} key={element.value}>
                {capitalize(element.label)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    selected: state.communityAmbassador.selected,
  };
};
export default connect(mapStateToProps, {
  updateStatus,
  getCommunityAmbassdor,
})(withStyles(styles)(AmbassdorStatus));
