import store from "../store";

const axios = require("axios");

// https://github.com/sindresorhus/is-absolute-url/blob/master/index.js#L7
const isAbsoluteURLRegex = /^(?:\w+:)\/\//;

const interceptor = () => {
  // eslint-disable-next-line func-names
  axios.interceptors.request.use(function(config) {
    // Concatenate base path if not an absolute URL
    if (!isAbsoluteURLRegex.test(config.url)) {
      // eslint-disable-next-line no-param-reassign
      config.url = `${process.env.REACT_APP_api_url}${config.url}`;
    }

    return config;
  });

  axios.interceptors.response.use(
    res => {
      return Promise.resolve(res);
    },
    error => {
      if (error.response.status && error.response.status === 403) {
        localStorage.clear();
        store.dispatch({ type: "SET_CURRENT_USER", payload: {} });
        this.history.push("/login");
      }
      return Promise.reject(error);
    }
  );
};

export default interceptor;
