import React, { useEffect, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import { getAllTeam } from "../../Actions/eventActions";
import TeamCard from "../../Components/Common/Cards/TeamCard";
import DataNotFound from "../../Components/Common/DataNotFound";

const $quoteSym = " ";

const styles = () => ({
  modelHeader: {
    paddingBottom: "5px",
    position: "relative",
    "&:after": {
      top: "48px",
      left: "24px",
      width: "24px",
      borderBottom: "3px solid #fb0",
      content: `'${$quoteSym}'`,
      position: "absolute",
    },
  },
  team_card: {
    marginBottom: "25px",
  },
  teamCardSection: {
    width: "18%",
    marginRight: "18px",
  },
});

function ViewTeamAttendees(props) {
  const { classes } = props;
  const { id } = props.match.params;
  const [allTeam, setallTeam] = useState([]);
  useEffect(() => {
    props.getAllTeam(id);
  }, []);

  useEffect(() => {
    setallTeam(props.all_team);
  }, [props.all_team]);

  if ((allTeam && allTeam.length <= 0) || allTeam === undefined) {
    return <DataNotFound text="No Participants Found" />;
  }
  return (
    <React.Fragment>
      <div className={classes.teamMainSection}>
        <Grid container spacing={2}>
          {allTeam &&
            allTeam.map((element) => {
              return (
                <Grid key={element.id} item sm={4} lg={2}>
                  <div className={classes.team_card}>
                    <TeamCard data={element} type="event" />
                  </div>
                </Grid>
              );
            })}
        </Grid>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    errors: state.errors,
    all_team: state.events.all_team,
  };
};

export default connect(mapStateToProps, { getAllTeam })(
  withStyles(styles)(ViewTeamAttendees)
);
