/* eslint-disable no-unused-vars */
import axios from "axios";
import {
  EVENTFEEDBACK_LOADING,
  EVENTFEEDBACK_PROCESS,
  EVENTFEEDBACK_QUESTIONS,
  CREATE_EVENTFEEDBACK_ANSWER,
  SEND_EVENTFEEDBACK_LINK,
  EVENT_ERROR
} from "./types";
import { showSuccess, showError } from "./ActionsHelper";
import { transformErrorsFromApi } from "./TransformError";

// Profile Loading
export const setEventFeedbackLoading = () => {
  return {
    type: EVENTFEEDBACK_LOADING
  };
};

export const getEventFeedbackProcess = () => dispatch => {
  dispatch(setEventFeedbackLoading());
  const url = `/api/eventfeedback`;

  axios
    .get(url)
    .then(res => {
      dispatch({
        type: EVENTFEEDBACK_PROCESS,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: EVENTFEEDBACK_PROCESS,
        // payload: {}
        payload: err && err.response ? err.response.data : "",
      })
    );
};

export const getEventFeedbackQuestions = () => dispatch => {
  dispatch(setEventFeedbackLoading());
  const url = `/api/eventfeedback/getquestions/client`;

  axios
    .get(url)
    .then(res => {
      dispatch({
        type: EVENTFEEDBACK_QUESTIONS,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: EVENTFEEDBACK_QUESTIONS,
        // payload: {}
        payload: err && err.response ? err.response.data : "",
      })
    );
};

export const eventFeedbackAnswer = data => dispatch => {
  dispatch(setEventFeedbackLoading());
  axios
    .post("/api/eventfeedback/answer/client", data)
    .then(result => {
      dispatch({
        type: CREATE_EVENTFEEDBACK_ANSWER,
        payload: result.data
      });
      //   history.push("/events");
      // dispatch(showSuccess("Event Feedback"));
    })
    .catch(err => {
      const error = err.response;
      dispatch({ type: CREATE_EVENTFEEDBACK_ANSWER, payload: error.data });
      // setErrors(transformErrorsFromApi(error));
    });
};

export const sendEventFeedback = (
  data,
  setErrors,
  setSubmitting,
  history
) => dispatch => {
  dispatch(setEventFeedbackLoading());
  axios
    .post("/api/eventfeedback/sendformlink", data)
    .then(result => {
      dispatch({
        type: SEND_EVENTFEEDBACK_LINK,
        payload: result.data
      });
      //   history.push("/events");
      dispatch(showSuccess("Event Feedback"));
    })
    .catch(err => {
      const error = err.response;
      dispatch({ type: SEND_EVENTFEEDBACK_LINK, payload: error.data });
      setSubmitting(false);
      // setErrors(transformErrorsFromApi(error));
    });
};
