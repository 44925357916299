/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/no-unused-state */
import React from 'react'
import {
  Grid,
  CssBaseline,
  Paper,
  withStyles,
  AppBar,
  Toolbar,
  Button,
} from '@material-ui/core'
import { connect } from 'react-redux'
import axios from 'axios'
import { Link } from 'react-router-dom'
import _filter from 'lodash/filter'
import { getCompanies } from '../../Actions/companiesAction'
import CompanyCard from '../../Components/Common/Cards/CompanyCard'
import { checkPermission } from '../../Utils'
import AddFormButton from '../../Components/Common/AddFormButton'
import DataNotFound from '../../Components/Common/DataNotFound'
import SearchBar from '../../Components/Common/SearchBar'
import NoRecordsFound from '../../Components/Common/NoRecordsFound'
import { CircularLoader } from '../../Components/Common/Spinner'
import ScreenHeader from '../../Components/Common/ScreenHeader'

const styles = (theme) => {
  const borderRadius = theme.shape.borderRadius * 4

  return {
    layout: {
      width: 'auto',
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      backgroundColor: 'white',
      [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      // marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      flexGrow: 1,
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        // marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
    },
    applicationHeader: {
      margin: '20px',
      borderBottom: '3px solid orange',
      paddingBottom: '5px',
    },
    heroUnit: {
      margin: '8px 0px 15px',
    },
    alignment: {
      float: 'right',
    },
    progress: {
      margin: theme.spacing(2),
    },
    loader: {
      textAlign: 'center',
    },
    company_card: {
      marginBottom: '25px',
    },
    addcompanies: {
      color: '#fff',
      padding: '7px 16px',
      borderRadius: '4px',
      textDecoration: 'none',
      fontSize: '0.875rem',
      fontWeight: '500',
      marginLeft: '18px',
      boxShadow: 'rgba(137, 163, 169, 0.3) 0px 3px 5px 2px',
      backgroundColor: '#211b4e !important',
      '&:hover': {
        backgroundColor: '#151039 !important',
      },
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    totalCounts: {
      color: '#211b4e',
      fontWeight: '500',
      padding: '0px 26px',
      fontSize: '13px',
    },
    subHeader: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    // countSection: {
    //   padding: "0px 0px 30px",
    // },
    // active view for selected button
    activeView: {
      margin: theme.spacing(1),
      backgroundColor: '#211b4e',
      color: 'white',
      fontSize: '10px',
      borderRadius: `${borderRadius}px`,
      '&:hover': {
        backgroundColor: '#211b4e',
        color: 'white',
      },
    },
    // inactive view for non-slected tabs
    inactiveView: {
      margin: theme.spacing(1),
      fontSize: '10px',
      color: '#959595',
      backgroundColor: '#fff',
      border: '1px solid #959595',
      borderRadius: `${borderRadius}px`,
      '&:hover': {
        backgroundColor: 'white',
        color: '#959595',
      },
    },
    appbar: {
      backgroundColor: '#ffffff',
    },
    searchSection: {
      margin: '24px 0px 8px',
    },
  }
}

class AdminCompanies extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      companies: [],
      // eslint-disable-next-line react/no-unused-state
      visibility: 'hidden',
      page: 0,
      activeCompanies: [],
      inactiveCompanies: [],
      isSearch: false,
      search: '',
      isActive: true,
    }
  }

  componentDidMount() {
    // this.props.getCompanies(this.state.page);
    this.props.getCompanies('', '', 'company')
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.add_company !== prevProps.add_company &&
      this.props.add_company.success
    ) {
      this.props.getCompanies('', '', 'company')
    }
    if (
      this.props.update_company !== prevProps.update_company &&
      this.props.update_company.success
    ) {
      this.props.getCompanies('', '', 'company')
    }
    if (
      this.props.companyStatus !== prevProps.companyStatus &&
      this.props.companyStatus.success === false
    ) {
      this.props.getCompanies('', '', 'company')
    }

    if (prevProps.companyData.loading !== this.props.companyData.loading) {
      this.props.setLoading(this.props.companyData.loading)
      this.setState({
        loading: this.props.companyData.loading,
      })
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.companyData.noMoreData !== prevState.noMoreData) {
      return {
        noMoreData: nextProps.companyData.noMoreData,
      }
    }
    if (nextProps.companyData !== prevState.companyData) {
      return {
        companies: nextProps.companyData,
      }
    }
    return []
  }

  searchCompanies = (search) => {
    this.setState({ isSearch: true, search })
    this.props.getCompanies(this.state.page, search, 'company')
  }

  showDivChange = () => {
    const { noMoreData, page } = this.state
    if (!noMoreData) {
      this.props.getCompanies(page + 1)

      this.setState({
        page,
      })
    }
  }

  handleActiveCompanies = () => {
    const activeCompanies = _filter(this.state.companies, (item) => {
      return item.status !== 'inactive'
    })
    this.setState({
      activeCompanies,
      isActive: true,
    })
  }

  handleInactiveCompanies = () => {
    const inactiveCompanies = _filter(this.state.companies, (item) => {
      return item.status === 'inactive'
    })
    this.setState({
      inactiveCompanies,
      isActive: false,
    })
  }

  buttonContent = () => {
    const { classes } = this.props
    return (
      <div className={classes.header}>
        {/* {localStorage.getItem('role') === 'admin' && ( */}
        <ScreenHeader type="All Companies" />
        {/* )} */}
        <AddFormButton type="Company" url="/company/add" />
      </div>
    )
  }

  // eslint-disable-next-line class-methods-use-this
  changeStatus(id, value) {
    axios
      .put(`/api/company/${id}`, { status: value })
      .then(() => { })
      .catch(() => { })
  }

  render() {
    ''
    const { classes } = this.props
    const { companies, isSearch, search, isActive } = this.state
    const { loading } = this.state

    let filterCompany
    if (isActive) {
      filterCompany = _filter(companies, (item) => {
        return item.status !== 'inactive'
      })
    } else {
      filterCompany = _filter(companies, (item) => {
        return item.status !== 'active'
      })
    }

    if (loading) {
      return <CircularLoader />
    }

    if (companies && companies.length <= 0 && !isSearch) {
      return (
        <DataNotFound
          type="company"
          text="You don't have any Companies Listed!!"
          subComponent={
            <div style={{ marginTop: '10px' }}>
              {checkPermission('COMPANY_CREATE') && (
                <Link to="/company/add" className={classes.addcompanies}>
                  Add Company
                </Link>
              )}
            </div>
          }
        />
      )
    }

    return (
      <React.Fragment>
        <CssBaseline />

        {this.buttonContent()}
        <div className={classes.searchSection}>
          <AppBar position="static" className={classes.appbar}>
            <Toolbar className={classes.subHeader}>
              {/* <AdminCompanyTabs history={this.props.history} /> */}
              <SearchBar
                type="Search Companies"
                value={this.state.search}
                onSearch={this.searchCompanies}
              />
              {checkPermission('COMMON_PERMISSION_FOR_ADMIN_CM') && (
                <div>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    // className={classes.button}
                    className={
                      isActive ? classes.activeView : classes.inactiveView
                    }
                    onClick={this.handleActiveCompanies}
                  >
                    Active
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    className={
                      !isActive ? classes.activeView : classes.inactiveView
                    }
                    // className={classes.button}
                    onClick={this.handleInactiveCompanies}
                  >
                    Inactive
                  </Button>
                </div>
              )}
            </Toolbar>
          </AppBar>
        </div>
        {(checkPermission('COWORKER_ADMIN_VIEW') ||
          localStorage.getItem('role') === 'communitymanager') && (
            <Grid className={classes.countSection}>
              <span className={classes.totalCounts}>
                Total Companies: {filterCompany && filterCompany.length}
              </span>
            </Grid>
          )}
        {companies && companies.length <= 0 && isSearch && (
          <NoRecordsFound text={`No records found for your text "${search}"`} />
        )}

        {filterCompany && filterCompany.length <= 0 && (
          <NoRecordsFound text="No records found" />
        )}

        <main className={classes.layout}>
          <Paper className={classes.paper}>
            {/* Hero unit */}

            {checkPermission('COMPANY_VIEW') && (
              <Grid container spacing={2}>
                {filterCompany &&
                  filterCompany.map((element) => {
                    return (
                      <Grid item lg={4} xs={12} sm={6} key={element.id}>
                        <div className={classes.company_card}>
                          <CompanyCard
                            data={element}
                            history={this.props.history}
                          />
                        </div>
                      </Grid>
                    )
                  })}
              </Grid>
            )}
            {/* <Observer onChange={this.showDivChange}>
              <div className={`box ${this.state.visibility}`} />
            </Observer> */}
          </Paper>
        </main>
      </React.Fragment>
    )
  }
}

AdminCompanies.propTypes = {}

const mapStateToProps = (state) => {
  return {
    companyData: state.company.companies,
    add_company: state.companies.add_company,
    companyStatus: state.companies.companyStatus,
    update_company: state.companies.update_company,
  }
}

export default connect(mapStateToProps, { getCompanies })(
  withStyles(styles)(AdminCompanies)
)
