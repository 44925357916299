import React from "react";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { connect } from "react-redux";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  Typography,
  Button,
} from "@material-ui/core";
import { getReferFriends } from "../../Actions/ReferFriendActions";
import DataNotFound from "../../Components/Common/DataNotFound";
import { CircularLoader } from "../../Components/Common/Spinner";
import Pagination from "../../Components/Common/Table/TablePagination";
import Cell from "../../Components/Common/Table/TableCell";
import TableHeader from "../../Components/Common/Table/TableHeader";
import avatar from "../../Assets/coworker3.png";
import { spacecapitalize } from "../../Utils/StringFunctions";
import ScreenHeader from "../../Components/Common/ScreenHeader";

const moment = require("moment");

const styles = (theme) => ({
  root: {
    minHeight: "inherit",
    backgroundColor: "#fff",
  },
  paper: {
    padding: "17px 22px 17px 24px",
    flexGrow: 1,
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      padding: "17px 22px 17px 24px",
    },
  },
  root1: {
    "&:nth-child(even)": {
      backgroundColor: "#FCFDFE",
    },
  },
  userImage: {
    width: "32px",
    height: "32px",
    borderRadius: "50%",
    margin: "6px 2px 6px 0px",
  },
  defaultuserImage: {
    width: "38px",
    height: "38px",
    borderRadius: "50%",
    marginTop: "3px",
  },
  backButton: {
    margin: "0% 0% 2% 0%",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  ScreenHeader: {
    marginLeft: "-28px",
    width: "100%",
  },
});

class ReferFriendList extends React.Component {
  state = {
    // eslint-disable-next-line react/no-unused-state
    open: true,
    page: 0,
    rowsPerPage: 10,
  };

  componentDidMount() {
    this.props.getReferFriends();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.referFriends !== this.props.referFriends) {
      let referFriends = this.props.referFriends || [];
      referFriends = referFriends.map((x, id) => {
        return Object.assign({}, x, {
          // eslint-disable-next-line no-plusplus
          idx: id + 1,
        });
      });
      this.setState({
        referFriends,
      });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.referFriends !== prevState.referFriends) {
      return {
        referFriends: nextProps.referFriends,
      };
    }

    return [];
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  toolTipTitle = (user) => {
    const { classes } = this.props;
    return (
      <div>
        <Typography variant="subtitle2" className={classes.memberName}>
          {user && `${user.first_name} ${user.last_name}`}
        </Typography>
      </div>
    );
  };

  handleClick = (event, id) => {
    this.props.history.push({ pathname: `refer-friends/${id}` });
  };

  renderHead = () => {
    return (
      <TableHead>
        <TableRow>
          <TableHeader>Sr. No.</TableHeader>
          <TableHeader>Date</TableHeader>
          <TableHeader>Name</TableHeader>
          <TableHeader>Mobile No.</TableHeader>
          <TableHeader>Email ID</TableHeader>
          <TableHeader>Company Name</TableHeader>
          <TableHeader>Location</TableHeader>
          <TableHeader>Product</TableHeader>
          <TableHeader>Referred by</TableHeader>
        </TableRow>
      </TableHead>
    );
  };

  renderBody = () => {
    const { classes } = this.props;
    const { referFriends, page, rowsPerPage } = this.state;

    return (
      <TableBody>
        {referFriends &&
          referFriends
            //   // Pagination
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((item, id) => {
              return (
                <TableRow key={id} className={classes.root1}>
                  <Cell>{page * rowsPerPage + id + 1}</Cell>
                  <Cell>{moment(item.createdAt).format("DD/MM/YYYY")}</Cell>

                  <Cell
                    cursor
                    color
                    onClick={(event) => this.handleClick(event, item._id)}
                  >
                    {spacecapitalize(item.name)}
                  </Cell>
                  <Cell>{item.contact_number}</Cell>
                  <Cell style={{ justifyContent: "center" }}>{item.email}</Cell>
                  <Cell style={{ justifyContent: "center" }}>
                    {item.company_name
                      ? spacecapitalize(item.company_name)
                      : "-"}
                  </Cell>
                  <Cell style={{ justifyContent: "center" }}>
                    {item.location ? spacecapitalize(item.location) : "-"}
                  </Cell>
                  <Cell style={{ justifyContent: "center" }}>
                    {spacecapitalize(item.product)}
                  </Cell>
                  {item.created_by && item.created_by.profile_pic ? (
                    <Cell>
                      <Tooltip title={this.toolTipTitle(item.created_by)}>
                        <img
                          src={item.created_by && item.created_by.profile_pic}
                          alt="avatar"
                          className={classes.userImage}
                        />
                      </Tooltip>
                    </Cell>
                  ) : (
                    <Cell>
                      <Tooltip title={this.toolTipTitle(item.created_by)}>
                        <img
                          src={avatar}
                          alt="avatar"
                          className={classes.defaultuserImage}
                        />
                      </Tooltip>
                    </Cell>
                  )}
                </TableRow>
              );
            })}
      </TableBody>
    );
  };

  render() {
    const { classes } = this.props;
    const { referFriends, loading } = this.state;
    const itemLength = referFriends ? referFriends.length : 0;

    if (loading) {
      return <CircularLoader />;
    }

    if (referFriends && referFriends.length <= 0) {
      return (
        <DataNotFound
          type="visitors"
          text="You don't have any referral entries!!"
          subtext=""
          paddingTop="8%"
        />
      );
    }

    return (
      <div className={classes.root}>
        <Button
          color="grey"
          onClick={() => this.props.history.push("/settings")}
          className={classes.backButton}
          variant="contained"
          size="small"
        >
          Back
        </Button>
        <Paper className={classes.paper}>
          <div className={classes.header}>
            <div className={classes.ScreenHeader}>
              <ScreenHeader type="Referral Entries" />
            </div>
          </div>
          <div style={{ marginTop: "15px" }}>
            {referFriends && referFriends.length > 0 && (
              <Table className={classes.table}>
                {this.renderHead()}
                {this.renderBody()}
              </Table>
            )}
          </div>
          <CssBaseline />
        </Paper>
        {referFriends && referFriends.length > 0 && (
          <Pagination
            count={itemLength}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        )}
      </div>
    );
  }
}

ReferFriendList.propTypes = {};

const mapStateToProps = (state) => {
  return {
    referFriends: state.referFriend.referFriends,
  };
};

export default connect(mapStateToProps, { getReferFriends })(
  withStyles(styles)(ReferFriendList)
);
