import {
  GET_AVAIL_RESOURCES,
  RESOURCE_LOADING,
  ADD_RESOURCE,
  GET_UNBOOKED_RESOURCES,
  GET_RESOURCES_ERRORS,
  RESOURCE_ERRORS,
  GET_SINGLE_RESOURCE,
  EDIT_RESOURCE_ERROR,
  EDIT_RESOURCE,
  GET_SPACE_RESOURCES,
} from '../Actions/types'

const initialState = {
  resources: null,
  loading: false,
  resource: {},
  get_single_resource: {},
  edit_resource: {},
  allresources: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_AVAIL_RESOURCES:
      return {
        ...state,
        loading: false,
        resources: action.payload.data,
      }
    case GET_SPACE_RESOURCES:
      return {
        ...state,
        loading: false,
        allresources: action.payload.data,
      }
    case GET_UNBOOKED_RESOURCES:
      return {
        ...state,
        loading: false,
        unbooked: action.payload.data,
      }
    case GET_RESOURCES_ERRORS:
      return {
        ...state,
        loading: false,
        unbooked: { errors: false },
      }
    case GET_SINGLE_RESOURCE:
      return {
        ...state,
        loading: false,
        get_single_resource: action.payload && action.payload.data,
      }
    case ADD_RESOURCE:
      return {
        ...state,
        loading: false,
        resource: { success: true, data: action.payload.data },
      }
    case EDIT_RESOURCE:
      return {
        ...state,
        loading: false,
        edit_resource: {
          success: true,
          data: action.payload && action.payload.data,
        },
      }
    case RESOURCE_LOADING:
      return {
        ...state,
        loading: true,
      }
    case RESOURCE_ERRORS:
      return {
        ...state,
        loading: false,
      }
    case EDIT_RESOURCE_ERROR:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}
