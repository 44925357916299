import React from "react";
import PropTypes from "prop-types";
import { Field, ErrorMessage } from "formik";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = {
  label: {
    fontSize: "15px",
    fontWeight: "400",
    color: "#252631",
    marginBottom: "1rem"
  },
  error: {
    color: "#f53b57",
    fontSize: "0.75rem",
    fontWeight: "400",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ],
    marginTop: "2px",
    textAlaign: "left",
    lineHeight: "1em"
  }
};
const TextareaField = ({ classes, name, maxlength, label, ...rest }) => {
  return (
    <React.Fragment>
      <Field
        name={name}
        component="textarea"
        margin="normal"
        placeholder="Type text here.."
        fullWidth
        InputLabelProps={{ shrink: true }}
        {...rest}
        maxlength={maxlength}
      />
      <div className={classes.error}>
        <ErrorMessage name={name} />
      </div>
    </React.Fragment>
  );
};

TextareaField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

export default withStyles(styles)(TextareaField);
