import {
  CREATE_CLUB_CATEGORY,
  GET_CLUB_CATEGORY,
  CLUB_CATEGORY_LOADING,
  ERROR_CLUB_CATEGORY,
  ERROR_GET_CLUB_CATEGORY,
} from "../Actions/types";

const initialState = {
  club_category: [],
  // club_categories: [],
  loading: false,
  error: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CLUB_CATEGORY:
      return {
        ...state,
        loading: false,
        club_categories: action.payload.data,
      };
    case CREATE_CLUB_CATEGORY:
      return {
        ...state,
        loading: false,
        club_category: { success: true, data: action.payload.data },
      };
    case CLUB_CATEGORY_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ERROR_CLUB_CATEGORY:
      return {
        ...state,
        loading: false,
        error: { success: false, data: action.payload.data },
      };
    case ERROR_GET_CLUB_CATEGORY:
      return {
        ...state,
        loading: false,
        club_categories: action.payload && action.payload,
      };
    default:
      return state;
  }
}
