import React from 'react'
import { withStyles, Grid, Paper, Typography } from '@material-ui/core'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import manageResources from '../../Assets/clock.png'
import planCategory from '../../Assets/architecture.png'
import clubCategory from '../../Assets/fat.png'
import issueDepartment from '../../Assets/customer-service.png'
import issueCategory from '../../Assets/issue.png'
import issueTitle from '../../Assets/strategy.png'
import manageRoles from '../../Assets/role.png'
import manageUsers from '../../Assets/user.png'
import manageSpace from '../../Assets/data-management.png'
import ManageNotifications from '../../Assets/shout (1).png'
import Announcement from '../../Assets/shout.png'
import ManageInventory from '../../Assets/inventory.png'
import ManageArea from '../../Assets/select.png'
import communityAmbassador from '../../Assets/contact (1).png'
import KnowledgeCenter from '../../Assets/online-course.png'
import ReferralEntries from '../../Assets/find.png'
import SuggestFeature from '../../Assets/value (1).png'
import ReportedError from '../../Assets/404.png'
import reports from '../../Assets/business-report.png'
import { checkPermission } from '../../Utils/checkPermission'
import ScreenHeader from '../../Components/Common/ScreenHeader'

const styles = (theme) => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    backgroundColor: 'white',
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    // marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    flexGrow: 1,
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      // marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  root: {
    width: '100%',
    padding: '0px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  settingMenuDiv: {
    padding: '3% !important',
  },
  imageDiv: {
    background: 'transparent',
    border: '1px solid #bdbae9',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    minWidth: '100%',
    padding: '5px 10px',
    // height: "100px"
  },
  setupImg: {
    padding: '10px',
    width: '100%',
    // height: "90px"
  },
  subTitle: {
    margin: '20% 0% 40%',
    textAlign: 'center',
    fontWeight: '600',
    lineHeight: '18px',
    fontSize: '16px',
    padding: '0% 5%',
  },
  avatar: {
    width: '110px',
    height: '110px',
    border: '1px solid #bdbae9',
  },
})

class AdminCoworkers extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {}

  render() {
    const { classes } = this.props
    const role = localStorage.getItem('role')

    return (
      <React.Fragment>
        <div className={classes.header}>
          <ScreenHeader type="Settings" />
          {/* <AddFormButton type="Co-Worker" url="/co-worker/add" /> */}
        </div>
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              {checkPermission('NOTIFICATION_VIEW') && (
                <Grid
                  item
                  lg={2}
                  md={3}
                  xs={12}
                  sm={4}
                  className={classes.settingMenuDiv}
                >
                  <div className={classes.imageDiv}>
                    <Link
                      to="/announcement"
                      variant="contained"
                      className={classes.bodystyle}
                      component="button"
                    >
                      <img
                        src={Announcement}
                        alt="Announcement"
                        className={classes.setupImg}
                      ></img>
                    </Link>
                  </div>
                  <Typography
                    className={classes.subTitle}
                    variant="subtitle1"
                    component="p"
                  >
                    Announcement
                  </Typography>
                </Grid>
              )}
              {/* <Grid
                item
                lg={2}
                xs={12}
                sm={4}
                md={3}
                className={classes.settingMenuDiv}
              >
                <div className={classes.imageDiv}>
                  <Link
                    to="merchandise"
                    variant="contained"
                    className={classes.bodystyle}
                    component="button"
                  >
                    <img
                      src={InventoryCategory}
                      alt="Inventory Category"
                      className={classes.setupImg}
                    ></img>
                  </Link>
                </div>
                <Typography
                  className={classes.subTitle}
                  variant="subtitle1"
                  component="p"
                >
                  DevX Store
                </Typography>
              </Grid> */}
              {/* {checkPermission("RESOURCETYPE_VIEW") && (
                <Grid
                  item
                  lg={2}
                  xs={12}
                  sm={4}
                  md={3}
                  className={classes.settingMenuDiv}
                >
                   <Avatar className={classes.avatar} src={resourceCategory}>
                  <Link
                    to="resource"
                    variant="contained"
                    className={classes.bodystyle}
                    component="button"
                  ></Link>
                </Avatar> 
                   <div className={classes.imageDiv}>
                    <Link
                      to="resource-categories"
                      variant="contained"
                      className={classes.bodystyle}
                      component="button"
                    >
                      <img
                        src={resourceCategory}
                        alt="resource Category"
                        className={classes.setupImg}
                      ></img>
                    </Link>
                  </div>

                  <Typography
                    className={classes.subTitle}
                    variant="subtitle1"
                    component="p"
                  >
                    Resources Category
                  </Typography> 
                </Grid>
              )} */}
              {checkPermission('RESOURCE_VIEW') && (
                <Grid
                  item
                  lg={2}
                  xs={12}
                  sm={4}
                  md={3}
                  className={classes.settingMenuDiv}
                >
                  <div className={classes.imageDiv}>
                    <Link
                      to="manage-resources"
                      variant="contained"
                      className={classes.bodystyle}
                      component="button"
                    >
                      <img
                        src={manageResources}
                        alt="resources"
                        className={classes.setupImg}
                      ></img>
                    </Link>
                  </div>
                  <Typography
                    className={classes.subTitle}
                    variant="subtitle1"
                    component="p"
                  >
                    Manage Resources
                  </Typography>
                </Grid>
              )}
              {checkPermission('PLAN_CATEGORY_VIEW') && (
                <Grid
                  item
                  lg={2}
                  xs={12}
                  sm={4}
                  md={3}
                  className={classes.settingMenuDiv}
                >
                  <div className={classes.imageDiv}>
                    <Link
                      to="manage-plans"
                      variant="contained"
                      className={classes.bodystyle}
                      component="button"
                    >
                      <img
                        src={planCategory}
                        alt="plan Category"
                        className={classes.setupImg}
                      ></img>
                    </Link>
                  </div>
                  <Typography
                    className={classes.subTitle}
                    variant="subtitle1"
                    component="p"
                  >
                    Manage Plans
                  </Typography>
                </Grid>
              )}
              {/* {checkPermission("PLAN_VIEW") && (
                <Grid
                  item
                  lg={2}
                  xs={12}
                  sm={4}
                  md={3}
                  className={classes.settingMenuDiv}
                >
                  <div className={classes.imageDiv}>
                    <Link
                      to="plans"
                      variant="contained"
                      className={classes.bodystyle}
                      component="button"
                    >
                      <img
                        src={managePlans}
                        alt="manage plans"
                        className={classes.setupImg}
                      ></img>
                    </Link>
                  </div>
                  <Typography
                    className={classes.subTitle}
                    variant="subtitle1"
                    component="p"
                  >
                    Manage Plans
                  </Typography>
                </Grid>
              )} */}
              {checkPermission('CLUBCATEGORY_VIEW') && (
                <Grid
                  item
                  lg={2}
                  xs={12}
                  sm={4}
                  md={3}
                  className={classes.settingMenuDiv}
                >
                  <div className={classes.imageDiv}>
                    <Link
                      to="club-categories"
                      variant="contained"
                      className={classes.bodystyle}
                      component="button"
                    >
                      <img
                        src={clubCategory}
                        alt="club Category"
                        className={classes.setupImg}
                      ></img>
                    </Link>
                  </div>
                  <Typography
                    className={classes.subTitle}
                    variant="subtitle1"
                    component="p"
                  >
                    Club Category
                  </Typography>
                </Grid>
              )}
              {checkPermission('HELPDESK_DEPARTMENT_VIEW') && (
                <Grid
                  item
                  lg={2}
                  xs={12}
                  sm={4}
                  md={3}
                  className={classes.settingMenuDiv}
                >
                  <div className={classes.imageDiv}>
                    <Link
                      to="issue-department"
                      variant="contained"
                      className={classes.bodystyle}
                      component="button"
                    >
                      <img
                        src={issueDepartment}
                        alt="resource Category"
                        className={classes.setupImg}
                      ></img>
                    </Link>
                  </div>
                  <Typography
                    className={classes.subTitle}
                    variant="subtitle1"
                    component="p"
                  >
                    Issue Department
                  </Typography>
                </Grid>
              )}
              {checkPermission('HELPDESK_CATEGORY_VIEW') && (
                <Grid
                  item
                  lg={2}
                  xs={12}
                  sm={4}
                  md={3}
                  className={classes.settingMenuDiv}
                >
                  <div className={classes.imageDiv}>
                    <Link
                      to="issue-category"
                      variant="contained"
                      className={classes.bodystyle}
                      component="button"
                    >
                      <img
                        src={issueCategory}
                        alt="resource Category"
                        className={classes.setupImg}
                      ></img>
                    </Link>
                  </div>
                  <Typography
                    className={classes.subTitle}
                    variant="subtitle1"
                    component="p"
                  >
                    Issue Category
                  </Typography>
                </Grid>
              )}
              {checkPermission('ISSUE_TITLE_VIEW') && (
                <Grid
                  item
                  lg={2}
                  xs={12}
                  sm={4}
                  md={3}
                  className={classes.settingMenuDiv}
                >
                  <div className={classes.imageDiv}>
                    <Link
                      to="issue-title"
                      variant="contained"
                      className={classes.bodystyle}
                      component="button"
                    >
                      <img
                        src={issueTitle}
                        alt="resource Category"
                        className={classes.setupImg}
                      ></img>
                    </Link>
                  </div>
                  <Typography
                    className={classes.subTitle}
                    variant="subtitle1"
                    component="p"
                  >
                    Issue Title
                  </Typography>
                </Grid>
              )}
              {role === 'admin' && (
                <Grid
                  item
                  lg={2}
                  xs={12}
                  sm={4}
                  md={3}
                  className={classes.settingMenuDiv}
                >
                  <div className={classes.imageDiv}>
                    <Link
                      to="roles"
                      variant="contained"
                      className={classes.bodystyle}
                      component="button"
                    >
                      <img
                        src={manageRoles}
                        alt="resource Category"
                        className={classes.setupImg}
                      ></img>
                    </Link>
                  </div>
                  <Typography
                    className={classes.subTitle}
                    variant="subtitle1"
                    component="p"
                  >
                    Manage Roles
                  </Typography>
                </Grid>
              )}
              {checkPermission('USER_VIEW') && role === 'admin' && (
                <Grid
                  item
                  lg={2}
                  xs={12}
                  sm={4}
                  md={3}
                  className={classes.settingMenuDiv}
                >
                  <div className={classes.imageDiv}>
                    <Link
                      to="users"
                      variant="contained"
                      className={classes.bodystyle}
                      component="button"
                    >
                      <img
                        src={manageUsers}
                        alt="resource Category"
                        className={classes.setupImg}
                      ></img>
                    </Link>
                  </div>
                  <Typography
                    className={classes.subTitle}
                    variant="subtitle1"
                    component="p"
                  >
                    Manage Users
                  </Typography>
                </Grid>
              )}
              {checkPermission('SPACE_VIEW') && (
                <Grid
                  item
                  lg={2}
                  xs={12}
                  sm={4}
                  md={3}
                  className={classes.settingMenuDiv}
                >
                  <div className={classes.imageDiv}>
                    <Link
                      to="spaces"
                      variant="contained"
                      className={classes.bodystyle}
                      component="button"
                    >
                      <img
                        src={manageSpace}
                        alt="manage space"
                        className={classes.setupImg}
                      ></img>
                    </Link>
                  </div>
                  <Typography
                    className={classes.subTitle}
                    variant="subtitle1"
                    component="p"
                  >
                    Manage Space
                  </Typography>
                </Grid>
              )}
              {checkPermission('NOTIFICATION_VIEW') && (
                <Grid
                  item
                  lg={2}
                  md={3}
                  xs={12}
                  sm={4}
                  className={classes.settingMenuDiv}
                >
                  <div className={classes.imageDiv}>
                    <Link
                      to="/notifications"
                      variant="contained"
                      className={classes.bodystyle}
                      component="button"
                    >
                      <img
                        src={ManageNotifications}
                        alt="manage notifications"
                        className={classes.setupImg}
                      ></img>
                    </Link>
                  </div>
                  <Typography
                    className={classes.subTitle}
                    variant="subtitle1"
                    component="p"
                  >
                    Manage Notifications
                  </Typography>
                </Grid>
              )}
              {checkPermission('SPACE_VIEW') && (
                <Grid
                  item
                  lg={2}
                  xs={12}
                  sm={4}
                  md={3}
                  className={classes.settingMenuDiv}
                >
                  <div className={classes.imageDiv}>
                    <Link
                      to="/areas"
                      variant="contained"
                      className={classes.bodystyle}
                      component="button"
                    >
                      <img
                        src={ManageArea}
                        alt="manage space"
                        className={classes.setupImg}
                      ></img>
                    </Link>
                  </div>
                  <Typography
                    className={classes.subTitle}
                    variant="subtitle1"
                    component="p"
                  >
                    Manage Area
                  </Typography>
                </Grid>
              )}
              {/* {checkPermission("PLAN_CATEGORY_VIEW") && ( */}
              {/* <Grid
                item
                lg={2}
                xs={12}
                sm={4}
                md={3}
                className={classes.settingMenuDiv}
              >
                <div className={classes.imageDiv}>
                  <Link
                    to="inventory-categories"
                    variant="contained"
                    className={classes.bodystyle}
                    component="button"
                  >
                    <img
                      src={InventoryCategory}
                      alt="Inventory Category"
                      className={classes.setupImg}
                    ></img>
                  </Link>
                </div>
                <Typography
                  className={classes.subTitle}
                  variant="subtitle1"
                  component="p"
                >
                  Inventory Category
                </Typography>
              </Grid> */}
              {/* )} */}
              {/* {checkPermission("PLAN_VIEW") && ( */}
              <Grid
                item
                lg={2}
                xs={12}
                sm={4}
                md={3}
                className={classes.settingMenuDiv}
              >
                <div className={classes.imageDiv}>
                  <Link
                    to="inventories"
                    variant="contained"
                    className={classes.bodystyle}
                    component="button"
                  >
                    <img
                      src={ManageInventory}
                      alt="Manage Inventory"
                      className={classes.setupImg}
                    ></img>
                  </Link>
                </div>
                <Typography
                  className={classes.subTitle}
                  variant="subtitle1"
                  component="p"
                >
                  Manage Inventory
                </Typography>
              </Grid>
              {/* )} */}

              <Grid
                item
                lg={2}
                xs={12}
                sm={4}
                md={3}
                className={classes.settingMenuDiv}
              >
                <div className={classes.imageDiv}>
                  <Link
                    to="/information/add"
                    variant="contained"
                    className={classes.bodystyle}
                    component="button"
                  >
                    <img
                      src={KnowledgeCenter}
                      alt="Knowledge Centre"
                      className={classes.setupImg}
                    ></img>
                  </Link>
                </div>
                <Typography
                  className={classes.subTitle}
                  variant="subtitle1"
                  component="p"
                >
                  Knowledge Centre
                </Typography>
              </Grid>

              <Grid
                item
                lg={2}
                xs={12}
                sm={4}
                md={3}
                className={classes.settingMenuDiv}
              >
                <div className={classes.imageDiv}>
                  <Link
                    to="/community-ambassador-confirmation"
                    variant="contained"
                    className={classes.bodystyle}
                    component="button"
                  >
                    <img
                      src={communityAmbassador}
                      alt="Community Ambassador"
                      className={classes.setupImg}
                    ></img>
                  </Link>
                </div>
                <Typography
                  className={classes.subTitle}
                  variant="subtitle1"
                  component="p"
                >
                  Community Ambassador
                </Typography>
              </Grid>

              {checkPermission('REFER_FRIEND_VIEW') && (
                <Grid
                  item
                  lg={2}
                  xs={12}
                  sm={4}
                  md={3}
                  className={classes.settingMenuDiv}
                >
                  <div className={classes.imageDiv}>
                    <Link
                      to="/refer-friends"
                      variant="contained"
                      className={classes.bodystyle}
                      component="button"
                    >
                      <img
                        src={ReferralEntries}
                        alt="Referral Entries"
                        className={classes.setupImg}
                      ></img>
                    </Link>
                  </div>
                  <Typography
                    className={classes.subTitle}
                    variant="subtitle1"
                    component="p"
                  >
                    Referral Entries
                  </Typography>
                </Grid>
              )}
              {checkPermission('SUGGEST_FEATURE_VIEW') && (
                <Grid
                  item
                  lg={2}
                  xs={12}
                  sm={4}
                  md={3}
                  className={classes.settingMenuDiv}
                >
                  <div className={classes.imageDiv}>
                    <Link
                      to="/suggest-feature-confirmation"
                      variant="contained"
                      className={classes.bodystyle}
                      component="button"
                    >
                      <img
                        src={SuggestFeature}
                        alt="Knowledge Centre"
                        className={classes.setupImg}
                      ></img>
                    </Link>
                  </div>
                  <Typography
                    className={classes.subTitle}
                    variant="subtitle1"
                    component="p"
                  >
                    Suggested Features
                  </Typography>
                </Grid>
              )}
              {checkPermission('REPORT_BUG_VIEW') && (
                <Grid
                  item
                  lg={2}
                  xs={12}
                  sm={4}
                  md={3}
                  className={classes.settingMenuDiv}
                >
                  <div className={classes.imageDiv}>
                    <Link
                      to="/report-bug-list"
                      variant="contained"
                      className={classes.bodystyle}
                      component="button"
                    >
                      <img
                        src={ReportedError}
                        alt="Reported Errors"
                        className={classes.setupImg}
                      ></img>
                    </Link>
                  </div>
                  <Typography
                    className={classes.subTitle}
                    variant="subtitle1"
                    component="p"
                  >
                    Reported Errors
                  </Typography>
                </Grid>
              )}
              {/* <Grid
                  item
                  lg={2}
                  xs={12}
                  sm={4}
                  md={3}
                  className={classes.settingMenuDiv}
                >
                  <div className={classes.imageDiv}>
                    <Link
                      to="/document-list"
                      variant="contained"
                      className={classes.bodystyle}
                      component="button"
                    >
                      <img
                        src={InventoryCategory}
                        alt="Document List"
                        className={classes.setupImg}
                      ></img>
                    </Link>
                  </div>
                  <Typography
                    className={classes.subTitle}
                    variant="subtitle1"
                    component="p"
                  >
                    Document List
                </Typography>
                </Grid> */}
              {checkPermission('SPECIAL_PERMISSION_FOR_ADMIN') && (
                <Grid
                  item
                  lg={2}
                  xs={12}
                  sm={4}
                  md={3}
                  className={classes.settingMenuDiv}
                >
                  <div className={classes.imageDiv}>
                    <Link
                      to="/report-list"
                      variant="contained"
                      className={classes.bodystyle}
                      component="button"
                    >
                      <img
                        src={reports}
                        alt="Reports"
                        className={classes.setupImg}
                      ></img>
                    </Link>
                  </div>
                  <Typography
                    className={classes.subTitle}
                    variant="subtitle1"
                    component="p"
                  >
                    Reports
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Paper>
        </main>
      </React.Fragment>
    )
  }
}

AdminCoworkers.propTypes = {}

const mapStateToProps = () => {
  return {}
}

export default connect(mapStateToProps, {})(withStyles(styles)(AdminCoworkers))
