import React from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { Typography, Grid } from "@material-ui/core";
import suggestFeature from "../../Assets/suggestFeature.png";

const styles = () => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "12px",
  },
  mainTitle: {
    fontWeight: "600",
    fontSize: "20px",
    textAlign: "left",
    color: "#222222",
    lineHeight: "25px",
  },
  heading: {
    fontSize: "16px",
    color: "#222222",
    fontWeight: "500",
    margin: "4px 0px",
    marginBottom: "15px",
  },
  title: {
    fontSize: "14px",
    color: "#222222",
    fontWeight: "500",
    padding: "10px",
  },
  subTitle: {
    fontSize: "15px",
    color: "#222222",
    fontWeight: "500",
  },
  phone: {
    color: "#1592E6",
    fontWeight: "500",
  },
  description: {
    color: "#222222",
    fontSize: "13px",
    lineHeight: "18px",
  },
  generalDetails: {
    padding: "3% 0% 0%",
  },
  cardLayout: {
    border: "1px solid #EFEFEF",
    borderRadius: "5px",
    padding: "10px",
  },
  padding: {
    padding: "10px",
  },
});

function AboutSuggest(props) {
  const { classes } = props;

  return (
    <div className={classes.main}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Typography align="left" className={classes.description}>
            In this section you can suggest DevX team to add any specific
            feature in the existing module or can suggest to create a completely
            new module which you feel can help product be in it&apos;s better
            version for you.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} style={{ marginTop: "50px" }}>
          <img src={suggestFeature} alt="" />
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.spaces.loading,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(AboutSuggest));
