/* eslint-disable no-param-reassign */
import React from 'react'
import {
  Grid,
  InputAdornment,
  withStyles,
  Typography,
  Button,
} from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { Form } from 'react-formik-ui'
import { getRoletypes } from '../../Actions/roleTypeActions'
import { CircularLoader } from '../../Components/Common/Spinner'
import {
  Instagram,
  Linkedin,
  Twitter,
  FacebookRoundIcon,
} from '../../Assets/index'
import { BloodGroupOptions } from '../../Utils/dropDownData'
import { checkPermission } from '../../Utils/checkPermission'
import InputField from '../../Components/Common/Form/InputField'
import UploadField from '../../Components/Common/Form/UploadField'
import DatePickerField from '../../Components/Common/Form/DatePickerField'
import SelectField from '../../Components/Common/Form/SelectField'
import {
  createUser,
  updateUser,
  getSingleUser,
} from '../../Actions/userActions'
import CoworkerSkillsDropdown from '../../Components/Common/Dropdowns/CoworkerSkillsDropdown'
import RoleTypesDropdown from '../../Components/Common/Dropdowns/RolesDropdown'
import { getSpaces } from '../../Actions/spaceActions'
import { spacecapitalize } from '../../Utils/StringFunctions'

const styles = (theme) => ({
  lightbg: {
    backgroundColor: '#fff',
    padding: '10px 22px',
  },
  label: {
    margin: '15px 0px 8px 0px',
    fontSize: '12px',
    fontWeight: '700',
  },
  Addbutton: {
    width: '148px',
    height: '46px',
    marginLeft: '-5%',
    backgroundColor: '#211b4e !important',
    '&:hover': {
      backgroundColor: '#151039 !important',
    },
  },
  margin: {
    margin: theme.spacing(1),
    marginLeft: '0px',
    width: '100%',
  },
  social: {
    height: '20px',
    width: '20px',
  },
  datePickerInput: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    padding: '4px',
    '& > div': {
      marginBottom: '8px',
    },
  },
  formfooter: {
    margin: '30px 15px',
    marginRight: '-3%',
  },
  cancelbutton: {
    width: '148px',
    height: '46px',
    color: '#151039',
    backgroundColor: '#dddddd',
    marginRight: '20px',
    '&:hover': {
      color: '#151039',
      backgroundColor: '#d1d1d1!important',
    },
  },
  form: {
    marginLeft: '4px',
  },
  dropinsidetext: {
    marginTop: '30px',
  },
})
const phoneRegExp = /[1-9]{1}[0-9]{9}/

const ManageUserSchema = Yup.object().shape({
  first_name: Yup.string()
    .matches(/^.[a-zA-Z0-9 ]+$/, 'Enter Valid Name')
    .trim()
    .required('This field is required.'),
  last_name: Yup.string()
    .matches(/^.[a-zA-Z0-9 ]+$/, 'Enter Valid Name')
    .trim()
    .required('This field is required.'),
  email: Yup.string()
    .email('Invalid email address')
    .required('This field is required.'),
  contact_number: Yup.string()
    .matches(phoneRegExp, 'Please enter valid number.')
    .min(10, 'Please enter valid number.')
    .required('This field is required.'),
  designation: Yup.string().trim().required('This field is required.'),
  roletypeid: Yup.string().required('This field is required.'),
  space: Yup.string().required('This field is required.'),
  blood_group: Yup.string().required('This field is required.'),
})

class ManageUserForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.state = {
      id:
        this.props.match && this.props.match.params
          ? this.props.match.params.id
          : '',
      roletypes: [],
      spaces: [],
      deletedKey: [],
    }
    this.handleClose = this.handleClose.bind(this)
  }

  componentDidMount() {
    this.props.getSpaces()
    this.props.getRoletypes()
    if (this.state.id) {
      this.props.getSingleUser(this.state.id)
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.roletypes !== prevState.roletypes) {
      return { roletypes: nextProps.roletypes }
    }
    if (nextProps.spaces !== prevState.spaces) {
      return { spaces: nextProps.spaces }
    }
    if (nextProps.user !== prevState.user) {
      return { user: nextProps.user }
    }
    if (nextProps.errors) {
      return { errors: nextProps.errors }
    }
    return []
  }

  shouldComponentUpdate() {
    if (this.state.deletedKey.length > 0) {
      return false
    }
    return true
  }

  handleClose = () => {
    const path = '/users'
    this.props.history.push(path)
  }

  getAvailableSpacesOptions = (spaces) => {
    return (
      checkPermission('SPACE_VIEW') &&
      spaces &&
      spaces.map((space) => {
        return {
          value: space._id,
          label: spacecapitalize(space.space_title),
        }
      })
    )
  }

  getAvailableRoletypesOptions = (roletypes) => {
    return roletypes
      ? roletypes.map((roletype) => {
          return {
            value: roletype._id,
            label: roletype.name,
          }
        })
      : []
  }

  formatData = (values) => {
    const formated = { ...values }
    formated.created_by = localStorage.getItem('user_id')
    const val = formated.blood_group ? formated.blood_group.value : ''
    formated.blood_group = val
    const val2 = formated.roletypeid ? formated.roletypeid.value : ''
    formated.roletypeid = val2

    const skill =
      formated.skills && formated.skills.map((skl) => skl && skl.label)
    formated.skills = skill
    const spaceData = formated.space && formated.space.map((el) => el.value)
    formated.space = formated.space[0].value
    formated.access_spaces = spaceData
    return formated
  }

  getInitialValues = (id) => {
    const { user } = this.props
    const { roletypes } = this.state
    if (id && user) {
      return {
        first_name: user.first_name,
        last_name: user.last_name,
        profile_pic: user.profile_pic,
        email: user.email,
        contact_number: user.contact_number,
        designation: user.designation,
        date_of_birth: user.date_of_birth,
        blood_group: BloodGroupOptions.filter(
          (option) => option.value === user.blood_group
        )[0],
        skills: user.skills,
        facebook: user.facebook,
        linkedin: user.linkedin,
        twitter: user.twitter,
        instagram: user.instagram,
        roletypeid:
          (user &&
            user.role &&
            user.role.roletype_id &&
            this.getAvailableRoletypesOptions(roletypes).filter(
              (option) => option.value === user.role.roletype_id._id
            )[0]) ||
          '',
        space:
          user && user.access_spaces
            ? this.getAvailableSpacesOptions(user && user.access_spaces)
            : user.space &&
              this.getAvailableSpacesOptions(user.space).filter(
                (option) => option.value === user.sapce._id
              ),
      }
    }
    return {
      first_name: '',
      last_name: '',
      profile_pic: '',
      email: '',
      contact_number: '',
      designation: '',
      date_of_birth: new Date(),
      blood_group: '',
      skills: [],
      facebook: '',
      linkedin: '',
      twitter: '',
      instagram: '',
      roletypeid: '',
      space: [],
    }
  }

  setDelImageUrl = (url) => {
    const { deletedKey } = this.state
    this.setState({ deletedKey: deletedKey.push(url) })
  }

  render() {
    const { classes } = this.props
    const { id, loading, spaces } = this.state

    return (
      <React.Fragment>
        <div className={classes.lightbg}>
          <Typography variant="h6" gutterBottom style={{ marginLeft: '4px' }}>
            {id ? 'Update' : 'Add'} User
          </Typography>
          <Formik
            initialValues={this.getInitialValues(id)}
            enableReinitialize={!!id}
            validationSchema={ManageUserSchema}
            onSubmit={(values, { setErrors, setSubmitting }) => {
              const data = this.formatData(values)
              values.password = 'password'
              // values.created_by = localStorage.getItem("user_id");
              const val = values.blood_group ? values.blood_group.value : ''
              values.blood_group = val
              const val2 = values.roletypeid ? values.roletypeid.value : ''
              values.roletypeid = val2
              const spaceData =
                values.space && values.space.map((el) => el.value)
              // values.space = spaceData
              const skill =
                values.skills && values.skills.map((skl) => skl && skl.label)
              values.skills = skill
              values.space = values.space[0].value
              values.access_spaces = spaceData
              if (!this.state.id) {
                this.props.createUser(
                  values,
                  setErrors,
                  setSubmitting,
                  this.props.history
                )
              } else {
                this.props.updateUser(
                  this.state.id,
                  data,
                  setErrors,
                  setSubmitting,
                  this.props.history
                )
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              setFieldTouched,
              setFieldValue,
              handleSubmit,
            }) => (
              <Form
                className={classes.form}
                onSubmit={handleSubmit}
                encType="multipart/form-data"
                autoComplete="off"
              >
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6}>
                    <InputField
                      name="first_name"
                      label="Full Name"
                      placeholder="Enter First Name"
                      max={15}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} style={{ marginTop: '16px' }}>
                    <InputField
                      name="last_name"
                      label=""
                      placeholder="Enter Last Name"
                      max={15}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <UploadField
                      label="Upload Profile Photo"
                      name="profile_pic"
                      id="profile_pic"
                      onChange={setFieldValue}
                      value={values.profile_pic}
                      folder="profile/picture"
                      delImageUrl={this.setDelImageUrl}
                    />
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <SelectField
                      value={values.space}
                      name="space"
                      id="space"
                      label="Space"
                      onChange={setFieldValue}
                      multi
                      error={errors.space}
                      touched={touched.space}
                      options={this.getAvailableSpacesOptions(spaces)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} className={classes.datePickerInput}>
                    <DatePickerField
                      name="date_of_birth"
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      value={values.date_of_birth}
                      disableFuture
                      label="Birth Date"
                    />
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <InputField
                      name="email"
                      label="Email Id"
                      placeholder="johndoe@mail.com"
                      inputProps={{
                        readOnly: !!id,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <InputField
                      name="contact_number"
                      label="Contact No"
                      placeholder="91XXXXXXXX"
                      type="tel"
                      max="10"
                      className={classes.dropinsidetext}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <RoleTypesDropdown
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      errors={errors}
                      touched={touched}
                      name="roletypeid"
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <InputField
                      name="designation"
                      label="Designation"
                      placeholder="Enter Title"
                      className={classes.dropinsidetext}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <SelectField
                      value={values.blood_group}
                      name="blood_group"
                      id="blood_group"
                      label="Blood Group"
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      multi={false}
                      error={errors.blood_group}
                      touched={touched.blood_group}
                      options={BloodGroupOptions}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <CoworkerSkillsDropdown
                      values={values.skills}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      errors={errors}
                      touched={touched}
                      name="skills"
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <InputField
                      // className={classes.margin}
                      name="facebook"
                      label="Facebook"
                      placeholder="Enter Facebook Profile Link"
                      className={classes.dropinsidetext}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src={FacebookRoundIcon}
                              alt=""
                              className={classes.social}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <InputField
                      className={classes.margin}
                      name="linkedin"
                      label="Linkedin"
                      placeholder="Enter Linkedin Profile Link"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src={Linkedin}
                              alt=""
                              className={classes.social}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <InputField
                      className={classes.margin}
                      name="twitter"
                      label="Twitter"
                      placeholder="Enter Twitter Profile Link"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src={Twitter}
                              alt=""
                              className={classes.social}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <InputField
                      className={classes.margin}
                      name="instagram"
                      label="Instagram"
                      placeholder="Enter Instagram Profile Link"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src={Instagram}
                              alt=""
                              className={classes.social}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
                <div className={classes.formfooter}>
                  <Grid
                    container
                    item
                    display="flex"
                    justify="flex-end"
                    alignItems="center"
                    direction="row"
                  >
                    <Grid xs={6} sm={2}>
                      <Button
                        color="grey"
                        onClick={this.handleClose}
                        className={classes.cancelbutton}
                        variant="contained"
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid xs={6} sm={2}>
                      <Button
                        variant="contained"
                        className={classes.Addbutton}
                        color="primary"
                        type="submit"
                      >
                        {id ? 'Update' : 'Add'}
                      </Button>
                    </Grid>
                  </Grid>
                  {loading && <CircularLoader />}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    errors: state.errors,
    roletypes: state.roletypes.roles,
    user: state.manageuser.user,
    spaces: state.spaces.spaces,
  }
}

export default connect(mapStateToProps, {
  createUser,
  updateUser,
  getSingleUser,
  getRoletypes,
  getSpaces,
})(withStyles(styles)(withRouter(ManageUserForm)))
