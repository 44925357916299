import React, { useState } from "react";
import { Button, Typography, Card } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { updateOrderStatus } from "../../../Actions/OrderActions";
import { getallFoodItem } from "../../../Actions/foodItemActions";
import { spacecapitalize } from "../../../Utils/StringFunctions";
import teamIcon from "../../../Assets/group_icon.png";
import AttendesStatus from "../AttendesStatus";
import JoinTeamDetailModal from "../../../Screens/Events/JoinTeamDetailModal";
import NameTruncate from "../NameTruncate";

const styles = (theme) => {
  return {
    card: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      boxShadow: "0 9px 10px rgba(0, 0, 0, 0.1), 0 4px 0px rgba(0,0,0,0.05)",
      transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
      backgroundColor: "#FCFDFE",
      position: "relative",
      "&:hover": {
        transform: "scale(1.01)",
        boxShadow:
          "rgba(34, 36, 38, 0.08) 0px 2px 4px 6px, rgba(34, 36, 38, 0.15) 0px 2px 10px 0px",
      },
    },
    teamImage: {
      width: "65px",
      height: "65px",
      borderRadius: "50px",
      border: "1px solid #efefef ",
    },
    cardimage: {
      height: "65px",
      display: "flex",
      justifyContent: "center",
      width: "100%",
      marginTop: "16px",
    },
    memberSection: {
      padding: "4px 15px",
      // flexGrow: 1
    },
    teamName: {
      height: "28px",
      fontWeight: 600,
      textAlign: "center",
      fontSize: " 16px",
      lineHeight: "16px",
      color: " #222",
      textTransform: "capitalize",
      margin: "4px 0px 12px",
    },
    createTeamName: {
      fontWeight: 600,
      textAlign: "center",
      fontSize: " 14px",
      lineHeight: "9px",
      color: " #626262",
      textTransform: "capitalize",
    },
    memberCount: {
      textAlign: "center",
      color: "#ababab",
      fontSize: "12px",
      lineHeight: "24px",
      fontWeight: "normal",
    },
    cardFooter: {
      display: "flex",
      margin: " 0 auto",
      alignItems: "flex-end",
      justifyContent: "center",
      flexGrow: 1,
    },
    joinbutton: {
      margin: theme.spacing(1),
      fontSize: 10,
      border: "1px solid #959595",
      borderRadius: theme.shape.borderRadius * 4,
      backgroundColor: "#fff",
      color: "#959595",
      "&:hover": {
        backgroundColor: "#fff",
      },
    },
    footer: {
      textAlign: "center",
    },
  };
};

function TeamCard(props) {
  const { classes, data, type, footer } = props;
  const [openModal, setopenModal] = useState(false);
  const [TeamID, setTeamID] = useState();
  const memeberCount = data.total_team_member;
  const teamMember = memeberCount === 0 ? memeberCount : memeberCount - 1;
  const handleClose = () => {
    setopenModal(false);
    setTeamID();
  };

  const handleOpen = (id) => {
    setTeamID(id);
    setopenModal(true);
  };

  return (
    <Card className={classes.card}>
      <div className={classes.profileImage}>
        <div className={classes.cardimage}>
          <img
            className={classes.teamImage}
            src={data.icon ? data.icon.icon : teamIcon}
            alt="Food"
          />
        </div>
      </div>
      <div className={classes.memberSection}>
        <div>
          <Typography
            component="h2"
            align="center"
            className={classes.teamName}
          >
            <NameTruncate text={data.team_name} amount={12} />
          </Typography>
          <Typography
            component="p"
            variant="subtitle2"
            className={classes.createTeamName}
          >
            <NameTruncate
              text={spacecapitalize(
                `${data.created_by && data.created_by.first_name} ${
                  data.created_by && data.created_by.last_name
                }`
              )}
              amount={12}
            />
            {/* Parth Shah */}
          </Typography>
          <Typography
            component="p"
            variant="subtitle2"
            className={classes.memberCount}
          >
            + {teamMember} Members
          </Typography>
        </div>
      </div>

      {footer === "no" ? (
        ""
      ) : (
        <div className={classes.cardFooter}>
          <Button
            size="small"
            variant="contained"
            className={classes.joinbutton}
            onClick={() => handleOpen(data._id)}
          >
            {type === "join" ? "Join" : "View More"}
          </Button>
        </div>
      )}
      {type === "event" ? (
        <div className={classes.footer}>
          <AttendesStatus
            selectedItem={data.team_status}
            value={data.team_status}
            id={data._id}
            key={data._id}
            event_id={data.event_id && data.event_id._id}
          />
        </div>
      ) : (
        ""
      )}
      <JoinTeamDetailModal
        openModal={openModal}
        close={handleClose}
        teamID={TeamID}
        type={props.type}
      />
    </Card>
  );
}

TeamCard.propTypes = {};
const mapStateToProps = () => {
  return {};
};
export default connect(mapStateToProps, { updateOrderStatus, getallFoodItem })(
  withStyles(styles)(withRouter(TeamCard))
);
