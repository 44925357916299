import React from 'react'
import Select, { Creatable } from 'react-select'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { FormLabel } from '@material-ui/core'
import { ErrorMessage } from 'formik'

const styles = () => ({
  selectContainer: {
    marginTop: '6px',
    paddingTop: '6px',
  },
  error: {
    color: '#f53b57',
    fontSize: '0.75rem',
    fontWeight: '400',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ],
    textAlign: 'left',
    lineHeight: '1em',
    marginTop: '7px',
  },
  selectField: {
    border: 'none',
    marginTop: '-8px',
  },
})

const customStyles = {
  option: (provided) => ({
    ...provided,
    // this is for options box opened on click
  }),
  control: (base) => {
    return {
      ...base,
      borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
      borderWidth: '0 0 1px 0',
      boxShadow: 'none',
      '&:hover': {
        borderWidth: '0 0 1px 0',
      },
      '&:focus': {
        border: 'none',
        borderWidth: '0 0 1px 0',
      },
      background: 'transparent',
      // minHeight: 32,
      // height: 32

      // borderBottom: "1px solid",
      // You can also use state.isFocused to conditionally style based on the focus state
      // borderBottom: state.isFocused ? base.borderBottom : base.borderBottom
    }
  },
  placeholder: (provided) => {
    const top = '61%'
    const color = 'hsl(0,0%,65%)'
    return { ...provided, top, color }
  },
  singleValue: (provided) => {
    const top = '61%'
    return { ...provided, top }
  },
}

class SelectField extends React.Component {
  handleChange = (value) => {
    // this is going to call setFieldValue and manually update values.topcis
    this.props.onChange(this.props.name, value)
  }

  handleBlur = () => {
    // this is going to call setFieldTouched and manually update touched.topcis
    // eslint-disable-next-line no-unused-expressions
    this.props.onBlur && this.props.onBlur(this.props.name, true)
  }

  render() {
    const components = {
      creatable: Creatable,
      select: Select,
    }
    const {
      classes,
      options,
      multi,
      openMenuOnClick,
      onInputChange,
    } = this.props
    // let selectValue = "";
    // let MultiSelectValue = [];
    // if (multi) {
    //   options.map(option => {
    //     if (value && value.includes(option.value)) {
    //       MultiSelectValue.push(option);
    //     }
    //   });
    // } else {
    //   selectValue = options.filter(option => option.value === value);
    //   // debugger;
    // }
    const ComponentType =
      components[this.props.creatable ? 'creatable' : 'select']
    return (
      <div className={classes.selectContainer}>
        <FormLabel
          htmlFor={this.props.id}
          style={{
            // marginBottom: "1rem",
            fontSize: '14px',
            //  fontWeight: "400",
          }}
        >
          {this.props.label}
        </FormLabel>
        <ComponentType
          id={this.props.id}
          options={options}
          isMulti={multi}
          onInputChange={onInputChange}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          value={this.props.value}
          defaultValue={this.props.defaultValue}
          // className={classes.selectField}
          styles={this.props.styles || customStyles}
          placeholder={this.props.placeholder}
          isDisabled={this.props.disabled}
          isClearable={this.props.isClearable}
          openMenuOnClick={openMenuOnClick}
          className="basic-multi-select"
          classNamePrefix="select"
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            borderWidth: '0 0 1px 0',
            colors: {
              ...theme.colors,
              primary25: '#f2f1ed',
              primary: 'lightgrey',
            },
          })}
          // {...this.props}
        />
        {/* {this.props.error && (
          <div style={{ color: "red", marginTop: ".5rem" }}>
            {this.props.error}
          </div>
        )} */}

        <div className={classes.error}>
          <ErrorMessage
            name={this.props.name}
            key={this.props.name}
            component="div"
          />
        </div>
      </div>
    )
  }
}

SelectField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array.isRequired,
  multi: PropTypes.bool.isRequired,
}

export default withStyles(styles)(SelectField)
