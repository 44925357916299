/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from "classnames";
import { Grid, FormLabel } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
// import { EditorState } from "draft-js";
import NotificationTemp1 from "../../Assets/NotificationTemp1.png";
import NotificationTemp from "../../Assets/NotificationTemp.png";
import { InputField, UploadField } from "../../Components/Common/Form";
// import RichEditorExample from "../../Components/Common/RichEditor";

const styles = () => ({
  main: {
    backgroundColor: "#fff",
  },
  label: {
    margin: "15px 0px 8px 0px",
    fontSize: "12px",
    fontWeight: "700",
  },
  descriptionLabel: {
    margin: "15px 0px 8px 0px",
    fontSize: "12px",
    fontWeight: "400",
    color: "rgba(0, 0, 0, 0.54)",
  },
  setupImgSelect: {
    height: "140px",
    width: "110px",
    padding: "5px",
    margin: "3px",
    border: "2px solid #5f5799",
    cursor: "pointer",
  },
  setupImg: {
    height: "140px",
    width: "110px",
    padding: "5px",
    margin: "3px",
    border: "0px solid #211b4e",
    cursor: "pointer",
  },
});

const EmailNotification = (props) => {
  const {
    classes,
    values,
    setFieldValue,
    // setFieldTouched,
    // errors,
    // touched
  } = props;
  const check = values.temp_name ? values.temp_name : "";
  const [imageSelector1, setimageSelector1] = useState(false);
  const [imageSelector, setimageSelector] = useState(true);
  const [deletedKey, setdeletedKey] = useState([]);
  useEffect(() => {
    // console.log("check : ",values.temp_name)
    if (check === "bulk_email_test_with_link") {
      setimageSelector(false);
      setimageSelector1(true);
      // console.log("bulk_email_test_with_link")
    } else if (check === "bulk_email_test_without_link") {
      setimageSelector(true);
      setimageSelector1(false);
      // console.log("bulk_email_test_without_link")
    }
  }, []);
  // const [temp_name,settemp_name] = useState("");
  const handleImage = () => {
    setimageSelector(true);
    setimageSelector1(false);
    props.settemp_name("bulk_email_test_without_link");
  };

  const handleImage1 = () => {
    setimageSelector(false);
    setimageSelector1(true);
    props.settemp_name("bulk_email_test_with_link");
  };

  const setDelImageUrl = (url) => {
    // setdeletedKey(deletedKey.push(url));
    setdeletedKey({ deletedKey: deletedKey.push(url) });
  };

  return (
    <React.Fragment>
      <div className={classes.main}>
        <div className={classes.header}>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={6}>
              <InputField
                name="subject"
                label="Subject"
                placeholder="Subject"
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <span>
                <FormLabel
                  className={classes.descriptionLabel}
                  component="legend"
                >
                  Select Template
                </FormLabel>
                <FormControl component="fieldset">
                  <div aria-hidden onClick={handleImage}>
                    <img
                      src={NotificationTemp}
                      alt="manage notifications"
                      className={classNames(
                        imageSelector
                          ? classes.setupImgSelect
                          : classes.setupImg
                      )}
                    />
                  </div>
                </FormControl>
                <FormControl component="fieldset">
                  <div aria-hidden onClick={handleImage1}>
                    <img
                      src={NotificationTemp1}
                      alt="manage notifications"
                      className={classNames(
                        imageSelector1
                          ? classes.setupImgSelect
                          : classes.setupImg
                      )}
                    />
                  </div>
                </FormControl>
              </span>
            </Grid>
            <Grid item xs={6} sm={6}>
              <UploadField
                name="banner"
                label="Upload banner "
                id="banner"
                onChange={setFieldValue}
                value={values.banner}
                delImageUrl={() => setDelImageUrl}
                folder="notification/banner"
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              {imageSelector1 && (
                <InputField
                  name="link"
                  label="Button Link"
                  placeholder="Enter Link"
                />
              )}
            </Grid>
            {/* <Grid item xs={12} sm={12}>
                    <FormLabel
                      className={classes.descriptionLabel}
                      component="legend"
                    >
                      Content
                    </FormLabel>
                    <RichEditorExample
                      name="emailcontent"
                      editorState={values.editorState}
                      onChange={setFieldValue}
                      placeholder="Type your text"
                    />
                  </Grid> */}
          </Grid>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = () => {
  return {};
};

export default connect(
  mapStateToProps,
  {}
)(withStyles(styles)(EmailNotification));
