/* eslint-disable func-names */
/* eslint-disable no-use-before-define */
import axios from "axios";
import {
  GET_PARTNERS,
  PARTNERS_LOADING,
  ADD_PARTNER,
  GET_PARTNER,
  PARTNER_ERROR,
} from "./types";
import { showSuccess } from "./ActionsHelper";
import { transformErrorsFromApi } from "./TransformError";

export const getPartners = (search) => (dispatch) => {
  dispatch(setPartnersLoading());
  let url = "/api/partner";
  if (search) {
    url = `/api/partner?q=${search}`;
  }
  axios
    .get(url)
    .then((res) => {
      dispatch({
        type: GET_PARTNERS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: PARTNER_ERROR,
        payload: err && err.response ? err.response.data : "",
      });
    });
};
export const getPartner = (id) => (dispatch) => {
  dispatch(setPartnersLoading());
  axios
    .get(`/api/partner/${id}`)
    .then((res) => {
      dispatch({
        type: GET_PARTNER,
        payload: res.data,
      });
    })
    .catch(() =>
      dispatch({
        type: PARTNER_ERROR,
        payload: {},
      })
    );
};
export const addPartner = (userData, setErrors, setSubmitting, history) => (
  dispatch
) => {
  dispatch(setPartnersLoading());
  axios
    .post("/api/partner", userData)
    .then(function (result) {
      dispatch({
        type: ADD_PARTNER,
        payload: result.data,
      });

      history.push("/partners");
      dispatch(showSuccess("", "Partner added successfully!"));
      setSubmitting(false);
    })
    .catch(function (err) {
      const error = err.response || {};
      dispatch({ type: PARTNER_ERROR, payload: error.data });
      setSubmitting(false);
      setErrors(transformErrorsFromApi(error));
    });
};
export const updatePartner = (id, data, setErrors, setSubmitting, history) => (
  dispatch
) => {
  dispatch(setPartnersLoading());
  axios
    .put(`/api/partner/${id}`, data)
    .then(() => {
      history.push("/partners");
      dispatch(showSuccess(" ", "Partner updated successfully!"));
      setSubmitting(false);
    })
    .catch((err) => {
      const error = err.response;
      setSubmitting(false);
      setErrors(transformErrorsFromApi(error));
    });
};

export const setPartnersLoading = () => {
  return {
    type: PARTNERS_LOADING,
  };
};
