/* eslint-disable react/no-unused-state */
import React from "react";
import {
  withStyles,
  Grid,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Button,
  TableCell,
} from "@material-ui/core";
import { connect } from "react-redux";
import DataNotFound from "../../Components/Common/DataNotFound";
import { CircularLoader } from "../../Components/Common/Spinner";
import { getMerchandiseAllOrder } from "../../Actions/merchandiseActions";
import Cell from "../../Components/Common/Table/TableCell";
import TableHeader from "../../Components/Common/Table/TableHeader";
import { capitalize, spacecapitalize } from "../../Utils/StringFunctions";
import Pagination from "../../Components/Common/Table/TablePagination";
import ViewMerchandisePoint from "./ViewMerchandisePoint";
import ViewProduct from "./ViewProduct";
import MerchandiseOrderStatus from "../../Components/Common/MerchandiseOrderStatus";

const moment = require("moment");

const styles = (theme) => ({
  searchSection: {
    margin: "24px 0px 8px",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    backgroundColor: "white",
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    padding: theme.spacing(2),
    flexGrow: 1,
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      padding: theme.spacing(3),
    },
  },
  table: {
    marginTop: "0px",
  },
  addcoworker: {
    color: "#fff",
    padding: "7px 16px",
    borderRadius: "4px",
    textDecoration: "none",
    fontSize: "0.875rem",
    fontWeight: "500",
    marginLeft: "18px",
    boxShadow: "rgba(137, 163, 169, 0.3) 0px 3px 5px 2px",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  viewButton: {
    padding: "7px 16px",
    borderRadius: "4px",
    textDecoration: "none",
    fontSize: "0.875rem",
    fontWeight: "500",
    marginLeft: "18px",
    boxShadow: "rgba(215, 229, 232, 0.3) 0px 3px 5px 2px",
    color: "#151039",
    backgroundColor: "#dddddd",
    marginRight: "20px",
    "&:hover": {
      color: "#151039",
      backgroundColor: "#d1d1d1!important",
    },
  },
  DataNotFound: {
    marginTop: "10px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  backButton: {
    margin: "0% 0% 2% 0%",
  },
  endSpacing: { padding: "8px 21px 0px" },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F8FAFF",
    color: theme.palette.common.black,
    borderBottom: "none",
  },
  body: {
    fontSize: 14,
    textTransform: "capitalize",
    borderBottom: "none",
  },
}))(TableCell);

const SubTableRow = withStyles(() => ({
  body: {
    fontSize: 12,
    borderBottom: "none",
  },
}))(TableRow);

const SubStyledTableCell = withStyles(() => ({
  body: {
    fontSize: 12,
    color: "gray",
    fontWeight: "bold",
  },
}))(StyledTableCell);

class AdminMerchandise extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 10,
      showHistory: true,
      showProduct: false,
      showMerchandise: false,
      viewAccordion: {},
    };
  }

  componentDidMount() {
    this.props.getMerchandiseAllOrder();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.noMoreData !== prevState.noMoreData) {
      return {
        noMoreData: nextProps.noMoreData,
      };
    }
    if (nextProps.allMerchandiseOrder !== prevState.allMerchandiseOrder) {
      return {
        allMerchandiseOrder: nextProps.allMerchandiseOrder,
      };
    }
    return [];
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  accordionOpen = (id) => {
    const { viewAccordion } = this.state;
    viewAccordion[id] = viewAccordion[id] ? !viewAccordion[id] : true;
    this.setState({
      viewAccordion,
    });
  };

  renderHead = () => {
    return (
      <TableHead>
        <TableRow>
          <TableHeader width="5%">Sr. No.</TableHeader>
          <TableHeader width="9%">Date </TableHeader>
          <TableHeader width="10%">Name</TableHeader>
          <TableHeader width="10%">Company Name</TableHeader>
          {/* <TableHeader width="10%">Product Name</TableHeader>
          <TableHeader width="8%">Quantity</TableHeader> */}
          <TableHeader width="8%">Total Amount</TableHeader>
          <TableHeader width="9%">Merchandise Amount </TableHeader>
          <TableHeader width="9%"> Bill Amount</TableHeader>
          <TableHeader width="10%">Status</TableHeader>
        </TableRow>
      </TableHead>
    );
  };

  renderBody = () => {
    const { classes, allMerchandiseOrder } = this.props;
    const { page, rowsPerPage } = this.state;

    return (
      <TableBody>
        {allMerchandiseOrder &&
          allMerchandiseOrder
            //   // Pagination
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((item, id) => {
              return (
                <React.Fragment>
                  <TableRow key={id} className={classes.root1}>
                    <Cell width="5%">{page * rowsPerPage + id + 1}</Cell>
                    <Cell width="9%">
                      {moment(item.createdAt).format("DD/MM/YYYY ")}
                    </Cell>
                    <Cell
                      width="15%"
                      capitalize
                      onClick={() => this.accordionOpen(id)}
                      cursor
                    >
                      {capitalize(
                        `${item.created_by && item.created_by.first_name} ${
                          item.created_by && item.created_by.last_name
                        }`
                      )}
                    </Cell>
                    <Cell width="10%">
                      {spacecapitalize(
                        item.created_by && item.created_by.company_id
                          ? item.created_by.company_id.company_name
                          : "-"
                      )}
                    </Cell>
                    <Cell width="8%" style={{ justifyContent: "center" }}>
                      {item.total_cost}
                    </Cell>
                    <Cell width="9%">{item.redeem_points}</Cell>
                    <Cell width="9%">
                      {item.total_cost - item.redeem_points}
                    </Cell>
                    <Cell width="10%" align="right">
                      <MerchandiseOrderStatus
                        selectedItem={item.order_status}
                        id={item._id}
                        key={item._id}
                        changeStatus={this.props.changeStatus}
                      />
                    </Cell>
                  </TableRow>
                  {this.state.viewAccordion[id] === true &&
                    item.items &&
                    item.items.map((fooddata) => {
                      return (
                        <SubTableRow>
                          <SubStyledTableCell />
                          <SubStyledTableCell />
                          <SubStyledTableCell align="left">
                            {fooddata.merchandise &&
                              fooddata.merchandise.product_name}
                            &nbsp; Quantity: {fooddata.quantity}
                          </SubStyledTableCell>
                          <SubStyledTableCell />
                          <SubStyledTableCell>
                            Rs. {fooddata.price}
                          </SubStyledTableCell>
                        </SubTableRow>
                      );
                    })}
                </React.Fragment>
              );
            })}
      </TableBody>
    );
  };

  showDivChange = (type) => {
    this.setState({
      showHistory: type,
      showProduct: false,
      showMerchandise: false,
    });
  };

  showProduct = (type) => {
    this.setState({
      showHistory: false,
      showProduct: type,
      showMerchandise: false,
    });
  };

  showMerchandisePoint = (type) => {
    this.setState({
      showHistory: false,
      showMerchandise: type,
      showProduct: false,
    });
  };

  render() {
    const { classes, allMerchandiseOrder } = this.props;
    const { loading } = this.state;
    const itemLength = allMerchandiseOrder ? allMerchandiseOrder.length : 0;

    if (loading) {
      return <CircularLoader />;
    }

    // if (allMerchandiseOrder && allMerchandiseOrder.length <= 0) {
    //   return (
    //     <>
    //     <div className={classes.header}>
    //       <Grid
    //         container
    //         direction="row"
    //         justify="flex-end"
    //         className={classes.endSpacing}
    //       >
    //         <Grid item>
    //           <Button
    //             size="small"
    //             onClick={() => this.showDivChange(true)}
    //             className={classes.viewButton}
    //           >
    //             Order History
    //           </Button>
    //         </Grid>

    //         <Grid item>
    //           <Button
    //             size="small"
    //             onClick={() => this.showProduct(true)}
    //             className={classes.addcoworker}
    //           >
    //             Product
    //           </Button>
    //         </Grid>
    //         <Grid item>
    //           <Button
    //             onClick={() => this.showMerchandisePoint(true)}
    //             size="small"
    //             className={classes.viewButton}
    //           >
    //             Merchandise Point
    //           </Button>
    //         </Grid>
    //       </Grid>
    //     </div>
    //     <DataNotFound
    //       type="coworker"
    //       text="ABC No history found!!"
    //       subComponent={
    //         <div className={classes.DataNotFound}>
    //           {/* {checkPermission("COWORKER_CREATE") && (
    //             <Link to="/co-worker/add" className={classes.addcoworker}>
    //               Add Co-worker
    //             </Link>
    //           )} */}
    //         </div>
    //       }
    //     />
    //     </>
    //   );
    // }
    return (
      <React.Fragment>
        <div className={classes.header}>
          <Button
            color="grey"
            onClick={() => this.props.history.push("/settings")}
            className={classes.backButton}
            variant="contained"
            size="small"
          >
            Back
          </Button>
          <Grid
            container
            direction="row"
            justify="flex-end"
            className={classes.endSpacing}
          >
            <Grid item>
              <Button
                size="small"
                onClick={() => this.showDivChange(true)}
                className={classes.viewButton}
              >
                Order History
              </Button>
            </Grid>

            <Grid item>
              <Button
                size="small"
                onClick={() => this.showProduct(true)}
                className={classes.addcoworker}
              >
                Product
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => this.showMerchandisePoint(true)}
                size="small"
                className={classes.viewButton}
              >
                Merchandise Point
              </Button>
            </Grid>
          </Grid>
        </div>

        {this.state.showHistory === true ? (
          <div>
            <main className={classes.layout}>
              <Paper className={classes.paper}>
                {allMerchandiseOrder && allMerchandiseOrder.length <= 0 && (
                  <DataNotFound
                    type="coworker"
                    text="No history found!!"
                    subComponent={<div className={classes.DataNotFound} />}
                  />
                )}
                <div>
                  {allMerchandiseOrder && allMerchandiseOrder.length > 0 && (
                    <Table className={classes.table}>
                      {this.renderHead()}
                      {this.renderBody()}
                    </Table>
                  )}
                </div>
              </Paper>
            </main>
            {allMerchandiseOrder && allMerchandiseOrder.length > 0 && (
              <Pagination
                count={itemLength}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            )}
          </div>
        ) : (
          ""
        )}
        {this.state.showProduct === true ? <ViewProduct /> : ""}
        {this.state.showMerchandise === true ? <ViewMerchandisePoint /> : ""}
      </React.Fragment>
    );
  }
}

AdminMerchandise.propTypes = {};

const mapStateToProps = (state) => {
  return {
    allMerchandiseOrder: state.allMerchandiseOrder.allMerchandiseOrder,
    // loading: state.coworkers.loading,
    noMoreData: state.coworkers.noMoreData,
  };
};

export default connect(mapStateToProps, { getMerchandiseAllOrder })(
  withStyles(styles)(AdminMerchandise)
);
