import React, { useState } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import classNames from "classnames";
import FormControl from "@material-ui/core/FormControl";
import { connect } from "react-redux";
import moment from "moment";
import { updateVisitor, getvisitors } from "../../Actions/visitorActions";
import { capitalize } from "../../Utils/StringFunctions";
import VisitorTypes from "./Types/VisitorTypes";

const styles = (theme) => {
  return {
    invited: {
      backgroundColor: theme.palette.applications.trial,
    },
    inpremise: {
      backgroundColor: theme.palette.applications.active,
    },
    visited: {
      backgroundColor: theme.palette.applications.winners,
    },
    formControl: {
      margin: "-5px",
      minWidth: 50,
    },
    statusDropdown: {
      color: "white",
      border: `1px solid ${theme.palette.border.color}`,
      borderRadius: `${theme.shape.borderRadius * 4}px`,
      height: "33px",
      padding: "15px 12px 13px",
      fontSize: "12px",
    },
  };
};

const VisitorStatus = (props) => {
  const [selected, setSelected] = useState(props.selectedItem || "inpremise");
  const { classes, id } = props;
  const callStatusChangeAPI = (el) => {
    props.updateVisitor(id, {
      status: el.target.value,
      time: moment().format(),
    });
    setSelected(el.target.value);
    props.getvisitors();
  };
  return (
    <React.Fragment>
      <FormControl
        variant="filled"
        className={classes.formControl}
        key={props.key}
      >
        {props.selectedItem === "visited" ? (
          <div>Visited</div>
        ) : (
          <Select
            className={classNames(classes[selected], classes.statusDropdown)}
            value={selected}
            onChange={callStatusChangeAPI}
            name="name"
            disableUnderline
            variant="standard"
          >
            {VisitorTypes.map((element) => {
              return (
                <MenuItem value={element} key={element}>
                  {capitalize(element)}
                </MenuItem>
              );
            })}
          </Select>
        )}
      </FormControl>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    errors: state.errors,
    selected: state.visitors.selected,
  };
};
export default connect(mapStateToProps, { updateVisitor, getvisitors })(
  withStyles(styles)(VisitorStatus)
);
