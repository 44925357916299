/* eslint-disable no-shadow */
/* eslint-disable camelcase */
import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import { Formik, Form } from "formik";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import * as Yup from "yup";
import CreateIcon from "@material-ui/icons/Create";
import {
  addInventoryCategory,
  getInventoryCategory,
  getSingleInventoryCategory,
  editInventoryCategory,
} from "../../Actions/InventoryCategoryActions";
import Pagination from "../../Components/Common/Table/TablePagination";
import { checkPermission } from "../../Utils/checkPermission";
import RadioField from "../../Components/Common/Form/RadioField";
import InputField from "../../Components/Common/Form/InputField";
import ScreenHeader from "../../Components/Common/ScreenHeader";
import nodata from "../../Assets/nodata.png";

const $quoteSym = " ";
const styles = () => ({
  main: {
    padding: "24px",
  },
  plancontainer: {
    backgroundColor: "#fff",
  },
  label: {
    margin: "15px 0px 8px 0px",
    fontSize: "12px",
    fontWeight: "700",
  },
  table: {
    marginTop: "20px",
  },
  add_plan_button: {
    width: "100%",
    textAlign: "right",
  },
  plan_form_modal: {
    width: "500px",
  },
  plan_table: {
    height: "500px",
  },
  editbutton: {
    borderRadius: "50px",
  },
  editicon: {
    fontSize: "16px",
    marginRight: "6px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  planCategoryButton: {
    color: "#fff",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  ScreenHeader: {
    marginLeft: "-20px",
    width: "100%",
  },
  cancelbutton: {
    marginRight: "20px",
    backgroundColor: "#dddddd",
    color: "#151039",
    "&:hover": {
      color: "#151039",
      backgroundColor: "#d1d1d1!important",
    },
  },
  addbutton: {
    color: "#fff",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  modelHeader: {
    paddingBottom: "5px",
    position: "relative",
    "&:after": {
      top: "48px",
      left: "24px",
      width: "24px",
      borderBottom: "3px solid #fb0",
      content: `'${$quoteSym}'`,
      position: "absolute",
    },
  },
  modalFooter: {
    padding: "8px 24px",
  },
  root1: {
    "&:nth-child(even)": {
      backgroundColor: "#FCFDFE",
    },
  },
  backButton: {
    margin: "0% 0% 2% 0%",
  },
});
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F8FAFF",
    color: theme.palette.common.black,
    borderBottom: "none",
    padding: "8px 40px 8px 16px",
  },
  body: {
    fontSize: 14,
    textTransform: "capitalize",
    borderBottom: "none",
  },
}))(TableCell);

const typeOptions = [
  { value: "movable", label: "Movable" },
  { value: "nonmovable", label: "Nonmovable" },
];
const InventoryCategorySchema = Yup.object().shape({
  object_category: Yup.string()
    .matches(/^.[a-zA-Z0-9 ]+$/, "Enter Valid Name")
    .trim()
    .required("This field is required."),
  // type: Yup.string().required("This field is required.")
});
export class InventoryCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inventory_categories: [],
      id: "",
      page: 0,
      rowsPerPage: 10,
      modalOpen: false,
    };
  }

  componentDidMount() {
    this.props.getInventoryCategory();
    if (this.state.id) {
      this.props.getSingleInventoryCategory(this.state.id);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.inventory_categories !== prevState.inventory_categories) {
      return { inventory_categories: nextProps.inventory_categories };
    }
    if (
      nextProps.get_single_inventory_category !==
      prevState.get_single_inventory_category
    ) {
      return {
        get_single_inventory_category: nextProps.get_single_inventory_category,
      };
    }
    return [];
  }

  componentDidUpdate(prevProps) {
    if (prevProps.inventory_categories !== this.props.inventory_categories) {
      let inventory_categories = this.props.inventory_categories || [];
      inventory_categories = inventory_categories.map((x, id) => {
        return Object.assign({}, x, {
          idx: id + 1,
        });
      });
      this.setState({
        inventory_categories,
      });
    }
    if (
      this.props.edit_inventory_category !==
        prevProps.edit_inventory_category &&
      this.props.edit_inventory_category.success
    ) {
      this.handleClose();
    }
    if (prevProps.loading !== this.props.loading) {
      // this.props.setLoading(this.props.loading);
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        loading: this.props.loading,
      });
    }
  }

  handleClickModalOpen = (id) => {
    this.setState({
      id,
      modalOpen: true,
    });
    this.props.getSingleInventoryCategory(id);
  };

  handleClickOpen = () => {
    this.setState({
      modalOpen: true,
      //   id: ""
    });
  };

  handleClose = () => {
    this.setState({
      modalOpen: false,
      id: "",
    });
    this.props.getInventoryCategory();
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  render() {
    const { classes, get_single_inventory_category } = this.props;
    const { id, page, rowsPerPage, inventory_categories } = this.state;
    let showData = true;
    if (inventory_categories && inventory_categories.length <= 0) {
      showData = false;
    }
    const getInitialValues = (id) => {
      if (id) {
        return {
          object_category: get_single_inventory_category.object_category,
          type: get_single_inventory_category.type || "",
        };
      }
      return {
        object_category: "",
        type: "movable",
      };
    };
    return (
      <div className={classes.main}>
        <div className={classes.header}>
          {showData && (
            <div className={classes.ScreenHeader}>
              <ScreenHeader type="All Inventory Categories" />
            </div>
          )}
          <Dialog
            open={this.state.modalOpen}
            onClose={this.handleClose}
            aria-labelledby="plan-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <Formik
              initialValues={getInitialValues(id)}
              enableReinitialize
              validationSchema={InventoryCategorySchema}
              onSubmit={(values, { setErrors, setSubmitting }) => {
                if (id) {
                  this.props.editInventoryCategory(
                    id,
                    values,
                    setErrors,
                    setSubmitting
                  );
                } else {
                  this.props.addInventoryCategory(
                    values,
                    setErrors,
                    this.props.history,
                    setSubmitting
                  );
                  this.props.getInventoryCategory();

                  this.props.history.push("/inventories");
                  this.handleClose();
                }
              }}
            >
              {() => (
                <Form className={classes.plan_form_modal}>
                  <DialogTitle
                    id="plan-dialog-title"
                    className={classes.modelHeader}
                  >
                    {id ? "Edit Inventory Category" : "Add Inventory Category"}
                  </DialogTitle>
                  <DialogContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <InputField
                          name="object_category"
                          label="Category"
                          placeholder="Category"
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <RadioField
                          name="type"
                          label="Type"
                          id="type"
                          options={typeOptions}
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions className={classes.modalFooter}>
                    <Button
                      onClick={this.handleClose}
                      color="primary"
                      className={classes.cancelbutton}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      className={classes.addbutton}
                      type="submit"
                      variant="contained"
                    >
                      {id ? "Edit" : "Add"}
                    </Button>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          </Dialog>
          {showData && (
            <div className={classes.add_plan_button}>
              {checkPermission("INVENTORY_CATEGORY_CREATE") && (
                <Button
                  onClick={this.handleClickOpen}
                  variant="contained"
                  color="primary"
                  className={classes.planCategoryButton}
                >
                  Add Inventory Category
                </Button>
              )}
            </div>
          )}
        </div>
        {showData && (
          <div className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Sr. No.</StyledTableCell>
                  <StyledTableCell align="left">Category Name</StyledTableCell>
                  <StyledTableCell align="left">Type</StyledTableCell>
                  <StyledTableCell align="left" />
                </TableRow>
              </TableHead>
              <TableBody>
                {inventory_categories &&
                  // eslint-disable-next-line no-shadow
                  inventory_categories
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, id) => {
                      return (
                        <TableRow className={classes.root1}>
                          <StyledTableCell component="th" scope="row">
                            {id + 1}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {item.object_category}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {item.type}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            <Button
                              variant="outlined"
                              size="small"
                              color="primary"
                              className={classes.editbutton}
                              onClick={() =>
                                this.handleClickModalOpen(item._id)
                              }
                            >
                              <CreateIcon
                                size="small"
                                className={classes.editicon}
                              />{" "}
                              Edit
                            </Button>
                          </StyledTableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
            <Pagination
              count={inventory_categories ? inventory_categories.length : 0}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </div>
        )}
        {!showData && (
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.headerSpacing}
          >
            <Grid item justify="center">
              <Grid item sm={12} lg={12}>
                <img src={nodata} alt="" width="76%" />
              </Grid>
              <div style={{ marginTop: "20px", marginLeft: "70px" }}>
                {checkPermission("INVENTORY_CATEGORY_CREATE") && (
                  <Button
                    onClick={this.handleClickOpen}
                    variant="contained"
                    color="primary"
                    className={classes.planCategoryButton}
                  >
                    Add Inventory Category
                  </Button>
                )}
              </div>
            </Grid>
          </Grid>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    inventory_categories: state.inventoryCategory.inventory_categories,
    loading: state.inventoryCategory.loading,
    get_single_inventory_category:
      state.inventoryCategory.get_single_inventory_category,
    edit_inventory_category: state.inventoryCategory.edit_inventory_category,
  };
};

export default connect(mapStateToProps, {
  addInventoryCategory,
  getInventoryCategory,
  getSingleInventoryCategory,
  editInventoryCategory,
})(withStyles(styles)(InventoryCategory));
