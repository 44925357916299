import React from "react";

const UploadLogoSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXink="http://www.w3.org/1999/xlink"
      width="14.818"
      height="18"
      viewBox="0 0 14.818 18"
    >
      <defs>
        <clipPath id="clip-path">
          <rect width="14.818" height="18" fill="none" />
        </clipPath>
      </defs>
      <g id="Upload_icon" data-name="Upload icon" clipPath="url(#clip-path)">
        <path
          id="Shape_1"
          data-name="Shape 1"
          d="M15.686,6.564a1.2,1.2,0,1,1-1.7,1.7L9.83,4.1V16.8a1.2,1.2,0,1,1-2.4,0V4.1L3.269,8.262a1.2,1.2,0,0,1-1.7-1.7L7.781.353a1.2,1.2,0,0,1,1.7,0Z"
          transform="translate(-1.221)"
          fill="#a614f9"
        />
      </g>
    </svg>
  );
};

UploadLogoSvg.propTypes = {};

export default UploadLogoSvg;
