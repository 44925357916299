/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  CssBaseline,
  Typography,
  CardContent,
  Card,
  CardHeader,
  CardActions,
  Button,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import { ThumbsUp, MessageCircle } from "react-feather";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import Carousel from "re-carousel";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { getUserData } from "../../../Actions/EditProfileActions";
import Settings from "../Settings";
import Buttons from "../buttons";
import { likePost, createComment } from "../../../Actions/postActions";
import ViewComment from "../../../Screens/Clubs/ViewComment";
import avatar from "../../../Assets/DevxLogo.png";
import {
  likeAnnouncement,
  deleteAnnouncement,
  getSingleAnnouncement,
} from "../../../Actions/AnnouncementActions";
import AnnouncementComment from "../../../Screens/Announcements/AnnouncementComment";
import AddAnnouncement from "../../../Screens/Announcements/AddAnnouncement";
import LinkifyString from "../../../Utils/LinkifyString";

const moment = require("moment");

const theme = createMuiTheme({
  overrides: {
    MuiPaper: {
      // Name of the component ⚛️ / style sheet
      root: {
        // backgroundColor: "#ffffff"
      },
    },
    MuiDialogContent: {
      root: {
        "&:first-child": {
          paddingTop: "0px",
        },
        padding: "0px",
      },
    },
    MuiGridListTile: {
      tile: {
        display: "flex",
      },
      imgFullWidth: {
        height: "100%",
      },
      imgFullHeight: {
        width: "100%",
      },
    },
  },
});

const styles = () => ({
  main: {
    border: "1px solid #efefef",
    backgroundColor: "#fff",
    marginTop: "35px",
  },
  clubName: {
    color: "#333",
    fontWeight: "normal",
    fontSize: "24px",
  },
  postCard: {
    padding: "20px 35px 36px",
    boxShadow: "0 9px 10px rgba(0, 0, 0, 0.08), 0 4px 0px rgba(0,0,0,0.05)",
  },

  userName: {
    fontSize: "14px",
    fontWeight: "600",
    color: " #358cee",
  },
  postUserDeatil: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    top: "-51px",
    left: "63px",
    height: "0px",
  },
  postImage: {
    width: "100%",
    paddingTop: "10px",
    paddingLeft: "0.8%",
    paddingRight: "0.8%",
    height: "100%",
  },
  MessageIcon: {
    position: "relative",
    top: "3px",
    margin: " 0px 10px",
    fontWeight: 600,
    fontSize: "15px",
    textAlign: "left",
    color: "#bfbfbf",
  },
  icons: {
    position: "relative",
    top: "2px",
    margin: " 0px 10px",
    fontWeight: 600,
    fontSize: "15px",
    textAlign: "left",
    color: "#bfbfbf",
  },
  card: {
    border: "1px solid #efefef",
    backgroundColor: "#fff",
    marginBottom: "20px",
    boxShadow: "0 9px 10px rgba(0, 0, 0, 0.1), 0 4px 0px rgba(0,0,0,0.05)",
    transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
  },
  cardevent: {
    border: "1px solid #efefef",
    backgroundColor: "#fff",
    marginBottom: "20px",
    boxShadow: "0 9px 10px rgba(0, 0, 0, 0.1), 0 4px 0px rgba(0,0,0,0.05)",
    transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
    margin: "0px 25px",
  },
  media: {
    paddingTop: "56.25%", // 16:9
  },
  postDescription: {
    marginTop: "-2.5%",
    padding: "16px 16px 6px 16px",
  },
  postTypo: {
    paddingLeft: "1%",
    whiteSpace: "pre-line",
  },
  cardActions: {
    marginLeft: "0.8%",
    paddingTop: "0px",
  },
  likeSection: {
    display: "flex",
    alignItems: "baseline",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  titleColor: {
    color: "#4d7cfe",
  },
  MultipleImages: {
    width: "50%",
  },
  announcementImagesCard: {
    display: "flex",
    paddingBottom: "0px",
    paddingTop: "0px",
  },
  commentButton: {
    display: "block",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  sliderSection: {
    // padding: "10px",
    width: "100%",
    height: "100%",
  },
  imageSliderSection: {
    height: "105%",
    // marginLeft: "5px"
  },
  overlapText: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "white",
    fontSize: "19px",
    fontWeight: "600",
    cursor: "pointer",
  },
  greyOut: {
    opacity: "0.4",
  },
  avatar: {
    height: "56px",
    maxWidth: "56px",
    marginBottom: "9px",
    fontSize: "40px",
    borderRadius: "50px",
    border: "1px solid #a3a3a3",
  },
  logo: {
    width: "100%",
    position: "relative",
    top: "1px",
  },
  imageDiv: {
    marginTop: "2.5%",
    paddingRight: "1%",
  },
});

class AnnouncementCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      value: 0,
      // eslint-disable-next-line react/no-unused-state
      likeData: [],
      viewcomment: false,
      // eslint-disable-next-line react/no-unused-state
      comments: "",
      like: "",
      edit: false,
      modalOpen: false,
      mediaIndex: 0,
    };
    this.state.like = this.props.data.like;
  }

  componentDidMount() {
    // this.props.getUserData();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.discussion !== prevState.discussion) {
      return {
        discussion: nextProps.discussion,
      };
    }
    return [];
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ like: this.props.data.like, edit: false });
    }
  }

  // eslint-disable-next-line react/sort-comp
  onLikePost = (e) => {
    e.preventDefault();
    let { id, like, likeData } = this.state;
    id = this.props.data._id;
    if (like === "yes") {
      this.setState({ like: "no" });
      like = "no";
    } else {
      this.setState({ like: "yes" });
      like = "yes";
    }

    likeData = { announcement_id: id, like };
    this.props.likeAnnouncement(likeData);
  };

  OnComment = (e) => {
    e.preventDefault();
    let { viewcomment } = this.state;
    if (viewcomment === false) {
      this.setState({ viewcomment: true });
      viewcomment = true;
    } else {
      this.setState({ viewcomment: false });
      viewcomment = false;
    }
  };

  editPostButton = () => {
    let { edit } = this.state;
    if (edit === false) {
      this.setState({ edit: true });
      edit = true;
    } else {
      this.setState({ edit: false });
      edit = false;
    }
  };

  typeValue = (type) => {
    localStorage.setItem("type", type);
  };

  handleClickOpen = (index) => {
    this.setState({
      modalOpen: true,
      mediaIndex: index,
    });
  };

  handleClose = () => {
    this.setState({
      modalOpen: false,
    });
  };

  render() {
    const { classes, data, user, type } = this.props;
    const { mediaIndex } = this.state;

    const postedAt = moment(data.createdAt).fromNow();

    const timeObj = {
      minutes: "m",
      days: "d",
      minute: "1 m",
      day: "1 d",
      hours: "h",
      years: "y",
      hour: "1 h",
      an: "",
      a: "",
      few: "0 m",
      seconds: "",
      months: "mo",
      month: "1 mo",
      go: "",
    };

    const time = postedAt.replace(
      /minutes|minute|hours|hour|years|few|seconds|months|month|days|day|an|a|go/gi,
      // eslint-disable-next-line func-names
      function (matched) {
        return timeObj[matched];
      }
    );

    const user_id = localStorage.getItem("user_id");
    const announcement_id = data._id;
    const onPostDelete = () => {
      this.props.deleteAnnouncement(announcement_id);
      // this.props.getSingleAnnouncement(announcement_id);
    };
    const mediaLength = data.media && data.media.length;
    return (
      <ThemeProvider theme={theme}>
        <React.Fragment>
          <CssBaseline />
          <Card className={type === "event" ? classes.cardevent : classes.card}>
            <CardHeader
              avatar={
                <div className={classes.imageDiv}>
                  <div className={classes.avatar}>
                    <img alt="" className={classes.logo} src={avatar} />
                  </div>
                </div>
              }
              title="DevX"
              action={
                user_id === data.created_by._id && (
                  <Settings
                    editMethod={this.editPostButton}
                    isEdit
                    type={this.props.type}
                    typeValue={this.typeValue}
                    isDelete
                    deleteMethod={onPostDelete}
                    message="Are you sure want to delete this announcement?"
                  />
                )
              }
              subheader={time}
              className={classes.titleColor}
            />
            <Dialog
              open={this.state.modalOpen}
              onClose={this.handleClose}
              aria-labelledby="club-dialog-title"
              aria-describedby="alert-dialog-description"
              // fullWidth={true}
              maxWidth="700px"
            >
              <DialogContent
                className={classes.popup}
                style={{
                  height: "400px",
                  width: "700px",
                  maxWidth: "700px",
                  overflow: "hidden",
                }}
              >
                <div className={classes.sliderSection}>
                  <div className={classes.imageSliderSection}>
                    <Carousel widgets={[Buttons]}>
                      {data.media &&
                        data.media
                          .slice(
                            mediaIndex > 0 ? mediaIndex : 0,
                            data.media.length
                          )
                          .map((tile) => {
                            return (
                              <img
                                alt="logo"
                                src={tile}
                                className={classes.group}
                                width="100%"
                                height="400px"
                              />
                            );
                          })}
                    </Carousel>
                  </div>
                </div>
              </DialogContent>
            </Dialog>

            <CardContent className={classes.postDescription}>
              <div>
                {this.state.edit ? (
                  <AddAnnouncement
                    announcementData={data}
                    announcement_id={data._id}
                    handleEditClose={this.editPostButton}
                  />
                ) : (
                  <Typography
                    variant="body2"
                    color="#626262"
                    component="p"
                    className={classes.postTypo}
                  >
                    {LinkifyString(data.description)}
                  </Typography>
                )}
              </div>
            </CardContent>

            {data.media.length === 0 ? (
              ""
            ) : (
              <CardContent className={classes.announcementImagesCard}>
                <GridList className={classes.gridList} cols={4}>
                  {data.media.slice(0, 6).map((tile, i) =>
                    tile === "" ? (
                      ""
                    ) : (
                      <GridListTile
                        key={tile}
                        cols={i % 4 === 0 ? 2 : 1}
                        onClick={() => this.handleClickOpen(i)}
                        className={
                          i === 5 && mediaLength > 6 ? classes.greyOut : ""
                        }
                        style={{ position: "relative" }}
                      >
                        <img src={tile} alt="" />
                        {i === 5 && mediaLength > 6 && (
                          <div className={classes.overlapText}> View more</div>
                        )}
                      </GridListTile>
                    )
                  )}
                </GridList>
              </CardContent>
            )}
            <CardActions className={classes.cardActions}>
              <Button className={classes.likeSection} onClick={this.onLikePost}>
                <ThumbsUp
                  size={18}
                  className={classes.icons}
                  fill={this.state.like === "yes" ? "	#3b5998	" : "white"}
                  color={this.state.like === "yes" ? "	#3b5998	" : "grey"}
                />
                Like
              </Button>
              <Button
                onClick={this.OnComment}
                className={classes.commentButton}
              >
                <MessageCircle
                  size={18}
                  className={classes.MessageIcon}
                  onClick={this.OnComment}
                />
                Comment
              </Button>
            </CardActions>
            {this.state.viewcomment ? (
              <AnnouncementComment
                commentData={data}
                userData={user}
                id={this.props.id}
              />
            ) : (
              ""
            )}
            {data.comments && data.comments.length > 0 ? (
              <ViewComment viewcomment={data.comments} announcementType />
            ) : (
              ""
            )}
          </Card>
        </React.Fragment>
      </ThemeProvider>
    );
  }
}

AnnouncementCard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    post: state.clubs.post,
    discussion: state.events.discussion,
  };
};

export default connect(mapStateToProps, {
  likePost,
  createComment,
  getUserData,
  deleteAnnouncement,
  getSingleAnnouncement,
  likeAnnouncement,
})(withStyles(styles)(AnnouncementCard));
