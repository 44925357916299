/* eslint-disable camelcase */
import axios from "axios";
import {
  CREATE_CLUB_CATEGORY,
  GET_CLUB_CATEGORY,
  CLUB_CATEGORY_LOADING,
  ERROR_CLUB_CATEGORY,
  ERROR_GET_CLUB_CATEGORY,
} from "./types";

import { showSuccess, showError } from "./ActionsHelper";

// Club Category Loading
export const setClubcategoryLoading = () => {
  return {
    type: CLUB_CATEGORY_LOADING,
  };
};
export const getClubCategory = () => (dispatch) => {
  dispatch(setClubcategoryLoading());
  axios
    .get(`/api/clubcategory`)
    .then((result) => {
      dispatch({
        type: GET_CLUB_CATEGORY,
        payload: result.data,
      });
    })
    .catch((err) => {
      console.log("err", err.response);
      dispatch({
        type: ERROR_GET_CLUB_CATEGORY,
        payload: err && err.response ? err.response.data.data : "",
      });
    });
};

export const addClubCategory = (
  userData,
  setErrors,
  history,
  setSubmitting
) => (dispatch) => {
  dispatch(setClubcategoryLoading());
  axios
    .post("/api/clubcategory", userData)
    .then((result) => {
      dispatch({
        type: CREATE_CLUB_CATEGORY,
        payload: result.data,
      });
      dispatch(showSuccess("Club Category "));
      setSubmitting(false);
    })
    .catch((err) => {
      dispatch({
        type: ERROR_CLUB_CATEGORY,
        payload: err && err.response ? err.response.data.data : "",
      });
      dispatch(showError(err.response.data.data));
    });
};
