/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-else-return */
import React from 'react'
import {
  withStyles,
  Grid,
  Paper,
  AppBar,
  Toolbar,
  Button,
} from '@material-ui/core'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { ExportToCsv } from 'export-to-csv'
import moment from 'moment'
import _filter from 'lodash/filter'
import SearchBar from '../../Components/Common/SearchBar'
import DataNotFound from '../../Components/Common/DataNotFound'
import NoRecordsFound from '../../Components/Common/NoRecordsFound'
import { CircularLoader } from '../../Components/Common/Spinner'
import CoworkerCard from '../../Components/Common/Cards/CoworkerCard'
import { getCoworkers } from '../../Actions/coworkerActions'
import { getUserData } from '../../Actions/EditProfileActions'
import { checkPermission } from '../../Utils/checkPermission'
import ScreenHeader from '../../Components/Common/ScreenHeader'
import CSV from '../../Assets/icons/CSV_logo.png'
import { spacecapitalize } from '../../Utils/StringFunctions'

const styles = (theme) => ({
  searchSection: {
    margin: '24px 0px 8px',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    backgroundColor: 'white',
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    // marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    flexGrow: 1,
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      // marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  applicationHeader: {
    margin: '20px',
    borderBottom: '3px solid orange',
    paddingBottom: '5px',
  },
  appbar: {
    backgroundColor: '#fff',
    display: 'flex',
  },
  exportCsv: {
    border: 'none',
    marginLeft: '10px',
    width: '22px',
    display: 'flex',
    cursor: 'pointer',
  },
  root: {
    width: '100%',
    padding: '0px',
  },
  button: {
    margin: theme.spacing(1),
    fontSize: '10px',
    backgroundColor: theme.palette.applications.active,
    color: '#ffffff',
  },
  typo: {
    fontSize: '14px',
  },
  coworkerCardContainer: {
    margin: '5px 0 0px',
  },
  addcoworker: {
    color: '#fff',
    padding: '7px 16px',
    borderRadius: '4px',
    textDecoration: 'none',
    fontSize: '0.875rem',
    fontWeight: '500',
    marginLeft: '18px',
    boxShadow: 'rgba(137, 163, 169, 0.3) 0px 3px 5px 2px',
    backgroundColor: '#211b4e !important',
    '&:hover': {
      backgroundColor: '#151039 !important',
    },
  },
  DataNotFound: {
    marginTop: '10px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  sortby: {
    marginRight: '-5px',
  },
  endSpacing: { padding: '8px 21px 0px' },
  totalCounts: {
    color: '#211b4e',
    fontWeight: '500',
    padding: '0px 26px',
    fontSize: '13px',
  },

  otherHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: '380px',
  },
  exportButton: {
    padding: '7px 16px',
    borderRadius: '4px',
    textDecoration: 'none',
    fontSize: '0.875rem',
    lineHeight: 1.43,
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff !important',
      color: '#211b4e !important',
    },
  },
  activeView: {
    margin: theme.spacing(1),
    backgroundColor: '#211b4e',
    color: 'white',
    fontSize: '10px',
    borderRadius: `50px`,
    '&:hover': {
      backgroundColor: '#211b4e',
      color: 'white',
    },
  },
  // inactive view for non-slected tabs
  inactiveView: {
    margin: theme.spacing(1),
    fontSize: '10px',
    color: '#959595',
    backgroundColor: '#fff',
    border: '1px solid #959595',
    borderRadius: `50px`,
    '&:hover': {
      backgroundColor: 'white',
      color: '#959595',
    },
  },
  subHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  searchHeader: {
    display: 'flex',
  },
})

class AdminCoworkers extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      coworkers: [],
      page: 0,
      noMoreData: false,
      search: '',
      isSearch: false,
      user: {},
      activeCoworkers: [],
      inActiveCoworkers: [],

      isActive: true,
    }
  }

  componentDidMount() {
    this.props.getCoworkers(this.state.page)
    this.props.getUserData()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.coworkers.loading !== this.props.coworkers.loading) {
      this.props.setLoading(this.props.coworkers.loading)
      this.setState({
        loading: this.props.coworkers.loading,
      })
    }
    if (
      this.props.coworkerStatus !== prevProps.coworkerStatus &&
      this.props.coworkerStatus.success === true
    ) {
      this.props.getCoworkers(this.state.page)
    }
    if (prevProps.user !== this.props.user) {
      this.setState({
        user: this.props.user,
      })
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.noMoreData !== prevState.noMoreData) {
      return {
        noMoreData: nextProps.noMoreData,
      }
    }
    if (nextProps.coworkers !== prevState.coworkers) {
      return {
        coworkers: nextProps.coworkers,
      }
    }
    if (nextProps.user !== prevState.user) {
      return {
        user: nextProps.user,
      }
    }
    return []
  }

  searchCoworkers = (search, isSearch) => {
    this.setState({ isSearch: true, search })
    this.props.getCoworkers(this.state.page, search, isSearch)
  }

  // eslint-disable-next-line consistent-return
  renderAddCoworkerButton = (user) => {
    const { classes } = this.props
    const role = localStorage.getItem('role')
    if (
      user &&
      user.company_id &&
      user.company_id.type === 'freelancer' &&
      checkPermission('COWORKER_CREATE') &&
      role === 'coworkeradmin'
    ) {
      return ''
    } else if (
      checkPermission('COWORKER_CREATE') &&
      user &&
      user.company_id &&
      user.company_id.type === 'company' &&
      role === 'coworkeradmin'
    ) {
      return (
        <div>
          <Grid item>
            <Link to="/co-worker/add" className={classes.addcoworker}>
              Add Co-worker
            </Link>
          </Grid>
        </div>
      )
    } else if (checkPermission('COWORKER_CREATE') && role === 'admin') {
      return (
        <div>
          <Grid item>
            <Link to="/co-worker/add" className={classes.addcoworker}>
              Add Co-worker
            </Link>
          </Grid>
        </div>
      )
    } else if (
      checkPermission('COWORKER_CREATE') &&
      role === 'communitymanager'
    ) {
      return (
        <div>
          <Grid item>
            <Link to="/co-worker/add" className={classes.addcoworker}>
              Add Co-worker
            </Link>
          </Grid>
        </div>
      )
    }
  }

  handleChange = () => {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      filename: 'Coworker Data',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      // headers: ['Name', 'Email ID', 'Contact No.', 'Date of Birth'],
    }
    const csvExporter = new ExportToCsv(options)
    const data = this.state.coworkers.map((item) => {
      return {
        // _id: item._id,
        Name: spacecapitalize(`${item.first_name} ${item.last_name}`),
        'Contact Number': item.contact_number,
        DOB: moment(item.date_of_birth).format('DD / MM / YYYY'),
        Email: item.user_id ? item.user_id.email : '-',
        'Company Name':
          item.company_id && item.company_id.company_name
            ? item.company_id.company_name
            : '-',
        Status: item.user_id && item.user_id.status ? item.user_id.status : '-',
      }
    })

    csvExporter.generateCsv(data)
  }

  handleActiveCoworkers = () => {
    const activeCoworkers = _filter(this.state.coworkers, (item) => {
      return item && item.user_id && item.user_id.status !== 'inactive'
    })
    this.setState({
      activeCoworkers,
      isActive: true,
    })
  }

  handleInactiveCoworkers = () => {
    const inActiveCoworkers = _filter(this.state.coworkers, (item) => {
      return item && item.user_id && item.user_id.status === 'inactive'
    })
    this.setState({
      inActiveCoworkers,
      isActive: false,
    })
  }

  render() {
    const { classes } = this.props
    const { coworkers, isSearch, search, user, isActive } = this.state
    const { loading } = this.state
    const role = localStorage.getItem('role')
    let filterCoworkers
    if (isActive) {
      filterCoworkers = _filter(coworkers, (item) => {
        return item && item.user_id && item.user_id.status !== 'inactive'
      })
    } else {
      filterCoworkers = _filter(coworkers, (item) => {
        return item && item.user_id && item.user_id.status !== 'active'
      })
    }
    if (loading) {
      return <CircularLoader />
    }
    if (coworkers && coworkers.length <= 0 && !isSearch) {
      return (
        <DataNotFound
          type="coworker"
          text="You don't have any co-workers listed!"
          subComponent={
            <div className={classes.DataNotFound}>
              {user &&
                user.company_id &&
                user.company_id.type === 'freelancer' &&
                role === 'coworkeradmin'
                ? ''
                : checkPermission('COWORKER_CREATE') && (
                  <Link to="/co-worker/add" className={classes.addcoworker}>
                    Add Co-worker
                  </Link>
                )}
            </div>
          }
        />
      )
    }
    return (
      <React.Fragment>
        <div className={classes.header}>
          <ScreenHeader type="All Co-workers" />
          <Grid
            container
            direction="row"
            justify="flex-end"
            className={classes.endSpacing}
          >
            {this.renderAddCoworkerButton(user)}
            {role === 'communitymanager' ||
              checkPermission('SPECIAL_PERMISSION_FOR_ADMIN') ? (
              <Grid item>
                <Link to="/signup-confirm" className={classes.addcoworker}>
                  Approve Co-worker
                </Link>
              </Grid>
            ) : (
              ''
            )}
          </Grid>
        </div>
        <div className={classes.searchSection}>
          <AppBar position="static" className={classes.appbar}>
            <Toolbar className={classes.subHeader}>
              <div className={classes.searchHeader}>
                <SearchBar
                  type="Search Co-workers"
                  value={this.state.search}
                  onSearch={this.searchCoworkers}
                />
                {checkPermission('SPECIAL_PERMISSION_FOR_ADMIN') && (
                  <div className={classes.otherHeader}>
                    <div>
                      <img
                        src={CSV}
                        size={10}
                        className={classes.exportCsv}
                        onClick={() => this.handleChange()}
                        alt="img"
                      />
                    </div>
                  </div>
                )}
              </div>
              {checkPermission('COMMON_PERMISSION_FOR_ADMIN_CM') && (
                <div>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    className={
                      isActive ? classes.activeView : classes.inactiveView
                    }
                    onClick={this.handleActiveCoworkers}
                  >
                    Active
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    className={
                      !isActive ? classes.activeView : classes.inactiveView
                    }
                    onClick={this.handleInactiveCoworkers}
                  >
                    Inactive
                  </Button>
                </div>
              )}
            </Toolbar>
          </AppBar>
        </div>
        {(checkPermission('COWORKER_ADMIN_VIEW') ||
          localStorage.getItem('role') === 'communitymanager') && (
            <span className={classes.totalCounts}>
              Total Co-workers: {filterCoworkers && filterCoworkers.length}
            </span>
          )}
        {filterCoworkers && filterCoworkers.length <= 0 && isSearch && (
          <NoRecordsFound text={`No records found for your text "${search}"`} />
        )}
        {filterCoworkers && filterCoworkers.length <= 0 && (
          <NoRecordsFound text="No records found" />
        )}
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            {/* Hero unit */}
            {checkPermission('COWORKER_VIEW') && (
              <Grid container spacing={2}>
                {filterCoworkers &&
                  filterCoworkers.map((element) => {
                    return (
                      <Grid
                        item
                        lg={4}
                        xs={12}
                        sm={6}
                        key={element.id}
                        className={classes.coworkerCardContainer}
                      >
                        <CoworkerCard data={element} user={user} role={role} />
                      </Grid>
                    )
                  })}
              </Grid>
            )}
          </Paper>
        </main>
      </React.Fragment>
    )
  }
}

AdminCoworkers.propTypes = {}

const mapStateToProps = (state) => {
  return {
    coworkers: state.coworkers.coworkers,
    // loading: state.coworkers.loading,
    noMoreData: state.coworkers.noMoreData,
    user: state.user.user,
    coworkerStatus: state.coworkers.coworkerStatus,
  }
}

export default connect(mapStateToProps, { getCoworkers, getUserData })(
  withStyles(styles)(AdminCoworkers)
)
