/* eslint-disable no-shadow */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import React from 'react'
import {
  Grid,
  Paper,
  CssBaseline,
  withStyles,
  Toolbar,
  AppBar,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Button,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import _filter from 'lodash/filter'
import SearchBar from '../../Components/Common/SearchBar'
import NoRecordsFound from '../../Components/Common/NoRecordsFound'
import HelpdeskStatus from '../../Components/Common/HelpdeskStatus'
import DataNotFound from '../../Components/Common/DataNotFound'
import avatar from '../../Assets/coworker3.png'
import Icon from '../../Assets/helpdesk_tablet_icon.png'
import NameTruncate from '../../Components/Common/NameTruncate'
import { CircularLoader } from '../../Components/Common/Spinner'
import Cell from '../../Components/Common/Table/TableCell'
import Header from '../../Components/Common/Table/TableHeader'
import Pagination from '../../Components/Common/Table/TablePagination'
import { getTickets, changeStatus } from '../../Actions/helpdeskActions'
import ScreenHeader from '../../Components/Common/ScreenHeader'
import ChangeStatusPopup from './ChangeStatusPopup'
import { checkPermission } from '../../Utils/checkPermission'
import { capitalize } from '../../Utils/StringFunctions'

const moment = require('moment')

const styles = (theme) => {
  const borderRadius = theme.shape.borderRadius * 4
  return {
    paper: {
      padding: '28px 20px 14px 24px',
      flexGrow: 1,
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        padding: '28px 20px 14px 24px',
      },
    },
    appbar: {
      backgroundColor: '#ffffff',
      // paddingTop: "15px"
      margin: '22px 0px 0px',
    },
    root: {
      width: '100%',
      padding: '0px',
    },
    endSpacing: { padding: '8px 21px 0px' },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    addtickets: {
      color: '#fff',
      padding: '7px 16px',
      borderRadius: '4px',
      textDecoration: 'none',
      fontSize: '0.875rem',
      fontWeight: '500',
      marginLeft: '18px',
      boxShadow: 'rgba(137, 163, 169, 0.3) 0px 3px 5px 2px',
      backgroundColor: '#211b4e !important',
      '&:hover': {
        backgroundColor: '#151039 !important',
      },
    },
    root1: {
      '&:nth-child(even)': {
        backgroundColor: '#FCFDFE',
      },
    },
    button: {
      margin: theme.spacing(1),
      fontSize: 10,
      border: '1px solid #959595',
      borderRadius: `${borderRadius}px`,
      backgroundColor: '#fff',
      color: '#959595',
      '&:hover': {
        backgroundColor: '#fff',
      },
    },
    // active view for selected button
    activeView: {
      margin: theme.spacing(1),
      backgroundColor: '#211b4e',
      color: 'white',
      fontSize: '10px',
      borderRadius: `${borderRadius}px`,
      '&:hover': {
        backgroundColor: '#211b4e',
        color: 'white',
      },
    },
    // inactive view for non-slected tabs
    inactiveView: {
      margin: theme.spacing(1),
      fontSize: '10px',
      color: '#959595',
      backgroundColor: '#fff',
      border: '1px solid #959595',
      borderRadius: `${borderRadius}px`,
      '&:hover': {
        backgroundColor: 'white',
        color: '#959595',
      },
    },
    memberName: {
      color: '#fff',
      fontSize: '12px',
      textTransform: 'capitalize',
    },
    userImage: {
      width: '32px',
      height: '32px',
      borderRadius: '50%',
      margin: '6px 2px 6px 0px',
    },
    defaultuserImage: {
      width: '38px',
      height: '38px',
      borderRadius: '50%',
      marginTop: '3px',
    },
    assignToAlignment: {
      display: 'flex',
      justifyContent: 'center',
    },
    tabletTicketIcon: {
      width: '18%',
      position: 'relative',
      top: '10px',
    },
    subHeader: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  }
}

class AdminHelpdesk extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      tickets: [],
      status: {},
      isSearch: false,
      search: '',
      page: 0,
      rowsPerPage: 10,
      modalOpen: false,
      ticket_id: '',
      openTickets: [],
      closedTickets: [],
      isOpen: true,
    }
  }

  componentDidMount() {
    this.props.getTickets()
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.tickets !== prevState.tickets) {
      return {
        tickets: nextProps.tickets,
      }
    }
    if (nextProps.status !== prevState.status) {
      return { status: nextProps.status }
    }
    return []
  }

  componentDidUpdate(prevProps) {
    let tickets = this.props.tickets || []
    if (prevProps.tickets !== this.props.tickets) {
      tickets = tickets.map((x, id) => {
        return Object.assign({}, x, {
          idx: ++id,
        })
      })
      this.setState({
        // eslint-disable-next-line object-shorthand
        tickets: tickets,
      })
    }
    if (prevProps.status !== this.props.status) {
      this.setState({
        status: this.props.status,
      })
      this.props.getTickets()
    }
    if (prevProps.tickets.loading !== this.props.tickets.loading) {
      this.props.setLoading(this.props.tickets.loading)
      this.setState({
        loading: this.props.tickets.loading,
      })
    }
  }

  // Search functionality
  searchHelpdesk = (search, isSearch) => {
    this.setState({ isSearch: true, search })
    this.props.getTickets(search, isSearch)
  }

  // Link to view ticket by clicking on issue title
  handleClick = (event, id) => {
    this.props.history.push({ pathname: `helpdesk/${id}`, state: { id } })
  }

  // Pagination change page function which is performed on click of next page button
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage })
  }

  // To set rows per page count ex. here is set to 10
  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    })
  }

  // Open modal box for revoke comment for co-worker user only
  handleClickOpen = (el) => {
    this.setState({
      modalOpen: true,
    })
    this.setState({ ticket_id: el._id })
  }

  // Close change status comment popup box
  handleClose = () => {
    const { modalOpen } = this.state
    this.setState({
      modalOpen: !modalOpen,
    })
  }

  renderHead = () => {
    return (
      <TableHead>
        <TableRow>
          <Header width="9%">Sr. No.</Header>
          <Header width="5%">Date</Header>
          <Header width="10%">Ticket ID</Header>
          <Header width="17%">Title</Header>
          {checkPermission('COMMON_PERMISSION_FOR_ADMIN_CM') && (
            <Header width="17%">Company Name</Header>
          )}
          <Header width="3%">Days</Header>
          <Header width="18%">Raised By</Header>
          <Header>Assign To</Header>
          <Header width="8%">Priority</Header>
          <Header>Modified Date</Header>
          <Header>Status</Header>
          {!checkPermission('COWORKER_ADMIN_VIEW') && <Header> </Header>}
        </TableRow>
      </TableHead>
    )
  }

  toolTipTitle = (user) => {
    const { classes } = this.props
    return (
      <div>
        <Typography variant="subtitle2" className={classes.memberName}>
          {capitalize(user && `${user.first_name} ${user.last_name}`)}
        </Typography>
      </div>
    )
  }

  handleClosedIssues = () => {
    const closedTickets = _filter(this.state.tickets, (item) => {
      return item.status === 'qc_pass' || item.status === 'revoked'
    })
    this.setState({
      closedTickets,
      isOpen: false,
    })
  }

  handleOpenIssues = () => {
    const openTickets = _filter(this.state.tickets, (item) => {
      return item.status !== 'qc_pass' && item.status !== 'revoked'
    })
    this.setState({
      openTickets,
      isOpen: true,
    })
  }

  renderBody = (item) => {
    const { classes } = this.props
    const { tickets, page, rowsPerPage, isOpen } = this.state

    let currentTickets
    if (isOpen) {
      currentTickets = _filter(tickets, (item) => {
        return item.status !== 'qc_pass' && item.status !== 'revoked'
      })
    } else {
      currentTickets = _filter(this.state.tickets, (item) => {
        return item.status === 'qc_pass' || item.status === 'revoked'
      })
    }
    return (
      <>
        {currentTickets && currentTickets.length > 0 ? (
          <>
            <Table className={classes.table}>
              {this.renderHead()}
              <TableBody>
                {currentTickets &&
                  currentTickets
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, id) => {
                      const a = moment().format('YYYY,MM,DD')
                      const cDate = moment(item.createdAt).format('YYYY,MM,DD')
                      const createddate = moment(cDate)
                      const currentdate = moment(a)
                      let diffdate
                      if (
                        item.status === 'closed' ||
                        item.status === 'revoked'
                      ) {
                        const updatedDate = moment(item.closed_at).format(
                          'YYYY,MM,DD'
                        )
                        diffdate = moment(updatedDate).diff(createddate, 'days')
                      } else {
                        diffdate = currentdate.diff(createddate, 'days')
                      }
                      return (
                        <TableRow className={classes.root1}>
                          <Cell width="9%">{page * rowsPerPage + id + 1}</Cell>
                          <Cell width="5%">
                            {moment(item.createdAt).format('DD/MM/YYYY')}
                          </Cell>
                          <Cell width="10%" upper>
                            {' '}
                            {item.ticket_number}
                          </Cell>
                          <Cell
                            width="17%"
                            cursor
                            color
                            onClick={(event) =>
                              this.handleClick(event, item._id)
                            }
                          >
                            <NameTruncate
                              text={capitalize(
                                item.issuetitle_id &&
                                item.issuetitle_id.issuetitle_name
                              )}
                              amount={25}
                            />{' '}
                            {item.ticket_create_from === 'tablet' ? (
                              <img
                                className={classes.tabletTicketIcon}
                                src={Icon}
                                alt="Tablet"
                              />
                            ) : (
                              ''
                            )}
                          </Cell>
                          {checkPermission(
                            'COMMON_PERMISSION_FOR_ADMIN_CM'
                          ) && (
                              <Cell width="2%">
                                {item.company_id && item.company_id.company_name
                                  ? capitalize(
                                    item.company_id &&
                                    item.company_id.company_name
                                  )
                                  : '-'}
                              </Cell>
                            )}

                          <Cell width="2%">{diffdate}</Cell>
                          <Cell width="18%">
                            {capitalize(
                              item.created_by &&
                              `${item.created_by.first_name} ${capitalize(
                                item.created_by.last_name
                              )}`
                            )}
                          </Cell>
                          {item.assigned_to ? (
                            <Cell>
                              {item.assigned_to.profile_pic ? (
                                <div className={classes.assignToAlignment}>
                                  <Tooltip
                                    title={this.toolTipTitle(item.assigned_to)}
                                  >
                                    <img
                                      src={
                                        item.assigned_to &&
                                        item.assigned_to.profile_pic
                                      }
                                      alt="avatar"
                                      className={classes.userImage}
                                    />
                                  </Tooltip>
                                </div>
                              ) : (
                                <div className={classes.assignToAlignment}>
                                  <Tooltip
                                    title={this.toolTipTitle(item.assigned_to)}
                                  >
                                    <img
                                      src={avatar}
                                      alt="avatar"
                                      className={classes.defaultuserImage}
                                    />
                                  </Tooltip>
                                </div>
                              )}
                            </Cell>
                          ) : (
                            <Cell>
                              <div className={classes.assignToAlignment}>-</div>
                            </Cell>
                          )}

                          <Cell
                            width="8%"
                            className={classNames(classes[item.severity_level])}
                          >
                            {capitalize(item.severity_level)}
                          </Cell>
                          <Cell>
                            {moment(item.updatedAt).format('DD/MM/YYYY')}
                          </Cell>
                          {checkPermission('HELPDESK_TICKET_STATUS_UPDATE') ? (
                            <Cell>
                              {item.status !== 'revoked' ? (
                                <HelpdeskStatus
                                  selectedItem={item.status}
                                  id={item._id}
                                  ticket_id={item._id}
                                  description={item.description}
                                />
                              ) : (
                                <div>Revoked</div>
                              )}
                              {/* {item.status === "closed" && (
                        <HelpdeskStatus
                          selectedItem={item.status}
                          id={item._id}
                          ticket_id={item._id}
                          description={item.description}
                        />
                      )} */}
                            </Cell>
                          ) : (
                            <Cell padding="0px">
                              {checkPermission(
                                'HELPDESK_TICKET_STATUS_UPDATE_BYCOWORKER'
                              ) && item.status === 'closed' ? (
                                <HelpdeskStatus
                                  selectedItem={item.status}
                                  id={item._id}
                                  ticket_id={item._id}
                                  description={item.description}
                                />
                              ) : (
                                <div
                                // style={{ marginLeft: "20px"  }}
                                >
                                  {capitalize(
                                    item.status === 'reopen'
                                      ? 'Reopened'
                                      : item.status
                                  )}
                                </div>
                              )}
                            </Cell>
                          )}
                          {checkPermission(
                            'HELPDESK_TICKET_STATUS_UPDATE_BYCOWORKER'
                          ) &&
                            !checkPermission('HELPDESK_TICKET_STATUS_UPDATE') ? (
                            <Cell padding="10px">
                              {item.status !== 'revoked' && (
                                <Button
                                  size="small"
                                  variant="contained"
                                  color="primary"
                                  className={classes.button}
                                  onClick={() => this.handleClickOpen(item)}
                                // ticket_id={item._id}
                                >
                                  Revoke
                                </Button>
                              )}
                            </Cell>
                          ) : (
                            ''
                          )}
                        </TableRow>
                      )
                    })}
              </TableBody>
            </Table>
            <Pagination
              count={item}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </>
        ) : (
          <NoRecordsFound text="No Results Found" />
        )}
      </>
    )
  }

  render() {
    const { classes } = this.props
    const {
      isSearch,
      search,
      loading,
      tickets,
      isOpen,
      openTickets,
      closedTickets,
    } = this.state
    // const itemLength = tickets ? tickets.length : 0;

    // eslint-disable-next-line camelcase
    const open_tickets = _filter(tickets, (item) => {
      return item.status !== 'closed' && item.status !== 'revoked'
    })
    let itemLength
    if (isOpen) {
      itemLength =
        openTickets && openTickets.length > 0
          ? openTickets.length
          : open_tickets.length
    } else {
      itemLength = closedTickets ? closedTickets.length : 0
    }

    if (loading) {
      return <CircularLoader />
    }

    if (tickets && tickets.length <= 0 && !isSearch) {
      return (
        <DataNotFound
          type="helpdesk"
          text="You don't have any tickets!!"
          subtext=""
          paddingTop="12%"
          subComponent={
            <div className={classes.DataNotFound}>
              {checkPermission('HELPDESK_TICKET_CREATE') && (
                <Link to="/helpdesk/add" className={classes.addtickets}>
                  Create Ticket
                </Link>
              )}
            </div>
          }
        />
      )
    }
    return (
      <React.Fragment>
        <div className={classes.header}>
          <ScreenHeader type="Help Desk" />
          <Grid
            container
            direction="row"
            justify="flex-end"
            className={classes.endSpacing}
          >
            {checkPermission('HELPDESK_TICKET_CREATE') && (
              <Grid item>
                <Link to="/helpdesk/add" className={classes.addtickets}>
                  Create Ticket
                </Link>
              </Grid>
            )}
          </Grid>
        </div>
        {/* </div> */}
        <div className={classes.root}>
          <AppBar position="static" className={classes.appbar}>
            <Toolbar className={classes.subHeader}>
              <SearchBar
                type="Search Tickets"
                onSearch={this.searchHelpdesk}
                value={this.state.search}
              />
              <div>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  // className={classes.button}
                  className={isOpen ? classes.activeView : classes.inactiveView}
                  onClick={this.handleOpenIssues}
                >
                  Open Issues
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  className={
                    !isOpen ? classes.activeView : classes.inactiveView
                  }
                  // className={classes.button}
                  onClick={this.handleClosedIssues}
                >
                  Closed Issues
                </Button>
              </div>

              {/* <SortBy selectedItem="SortBy" style={{ color: "#049b04" }} /> */}
            </Toolbar>
          </AppBar>
        </div>
        {tickets && tickets.length <= 0 && isSearch && (
          <NoRecordsFound text={`No records found for your text "${search}"`} />
        )}
        <CssBaseline />
        {tickets && tickets.length > 0 && (
          <main className={classes.layout}>
            <Paper className={classes.paper}>
              <div>
                {/* <Table className={classes.table}> */}
                {/* {this.renderHead()} */}
                {this.renderBody(itemLength)}
                {/* </Table> */}
              </div>
            </Paper>
            <ChangeStatusPopup
              data={this.state.modalOpen}
              close={this.handleClose}
              ticket_id={this.state.ticket_id}
              selected="revoked"
            />
            {/* <Pagination
              count={itemLength}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            /> */}
          </main>
        )}
      </React.Fragment>
    )
  }
}

AdminHelpdesk.propTypes = {}

const mapStateToProps = (state) => {
  return {
    tickets: state.helpdesk.tickets,
    status: state.helpdesk.status,
    // loading: state.helpdesk.loading,
  }
}

export default connect(mapStateToProps, { getTickets, changeStatus })(
  withStyles(styles)(AdminHelpdesk)
)
