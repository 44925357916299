import React, { useEffect, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { SelectField } from "../Form";
import { getPartners } from "../../../Actions/partnerActions";
import { capitalize } from "../../../Utils/StringFunctions";

const styles = () => ({});

const PartnerDropDown = (props) => {
  const { setFieldValue, setFieldTouched, errors, touched } = props;
  const [partners, setPartners] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);

  // eslint-disable-next-line no-shadow
  const getAvailablePartnersOptions = (partners) => {
    return partners
      ? partners.map((partner) => {
          return {
            value: partner._id,
            label: capitalize(partner.company_name),
          };
        })
      : [];
  };

  useEffect(() => {
    props.getPartners();
  }, []);

  useEffect(() => {
    setPartners(props.partners);
  }, [props.partners]);

  useEffect(() => {
    setSelectedValue(props.values.partner_id);
  }, [props.values]);

  const changeDropDown = (name, values) => {
    setSelectedValue(values);
    setFieldValue(name, values);
  };

  return (
    <SelectField
      value={selectedValue}
      name="partner_id"
      id="partner_id"
      label="Partner"
      onChange={changeDropDown}
      onBlur={setFieldTouched}
      error={errors.partner_id}
      touched={touched.partner_id}
      options={getAvailablePartnersOptions(partners)}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    errors: state.errors,
    partners: state.partners.partners,
  };
};

export default connect(mapStateToProps, { getPartners })(
  withStyles(styles)(PartnerDropDown)
);
