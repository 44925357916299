/* eslint-disable no-shadow */
import React, { useEffect, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { SelectField } from "../Form";
import { getAllIssueCategory } from "../../../Actions/issueCategoryActions";
import { spacecapitalize } from "../../../Utils/StringFunctions";

const styles = () => ({});

const IssueCategoryDropDown = (props) => {
  const {
    values,
    setFieldTouched,
    errors,
    touched,
    noIntialListing,
    departmentData,
  } = props;

  const [allIssueCategory, setallissueCategory] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);

  const isUpperCase = (str) => {
    return str === str.toUpperCase();
  };

  const getAvailableCategoryOptions = (allIssueCategory) => {
    return allIssueCategory
      ? allIssueCategory.map((allIssueCategory) => {
          if (isUpperCase(allIssueCategory.helpdeskcategory_name)) {
            return {
              value: allIssueCategory._id,
              label: allIssueCategory.helpdeskcategory_name,
            };
          }
          return {
            value: allIssueCategory._id,
            label: spacecapitalize(allIssueCategory.helpdeskcategory_name),
          };
        })
      : [];
  };

  useEffect(() => {
    if (!noIntialListing) {
      props.getAllIssueCategory();
    }
  }, []);

  useEffect(() => {
    if (!noIntialListing) {
      props.getAllIssueCategory(departmentData.value);
      setSelectedValue([]);
    }
  }, [props.departmentData && props.departmentData.value]);

  useEffect(() => {
    if (
      // noIntialListing &&
      props.departmentData &&
      props.departmentData.value !== ""
    ) {
      props.getAllIssueCategory(departmentData.value);
      setSelectedValue([]);
    }
  }, [props.departmentData && props.departmentData.value]);

  useEffect(() => {
    setallissueCategory(props.allIssueCategory);
    if (values.helpdeskcategory) {
      // eslint-disable-next-line no-unused-expressions
      props.allIssueCategory &&
        props.allIssueCategory.length > 0 &&
        props.allIssueCategory.map((category) => {
          if (category._id === values.helpdeskcategory) {
            selectedValue.push({
              value: category._id,
              label: spacecapitalize(category.helpdeskcategory_name),
            });
          }
          return null;
        });

      setSelectedValue(selectedValue);
    }
  }, [props.allIssueCategory]);

  // useEffect(() => {
  //   setSelectedValue(values.helpdeskcategory);
  // }, [props.departmentData]);

  const changeDropDown = (name, value) => {
    setSelectedValue(value);
    props.onChange(name, value);
  };
  return (
    <SelectField
      value={selectedValue}
      name="helpdeskcategory"
      id="helpdeskcategory"
      label="Category"
      onChange={changeDropDown}
      onBlur={setFieldTouched}
      placeholder="Select..."
      error={errors.helpdeskcategory}
      touched={touched.helpdeskcategory}
      options={getAvailableCategoryOptions(allIssueCategory)}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    errors: state.errors,
    allIssueCategory: state.all_issue_category.all_issue_category,
  };
};

export default connect(mapStateToProps, { getAllIssueCategory })(
  withStyles(styles)(IssueCategoryDropDown)
);
