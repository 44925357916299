import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles, Grid, Button, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-formik-ui";
import * as Yup from "yup";
import InputField from "../../Components/Common/Form/InputField";
import SelectField from "../../Components/Common/Form/SelectField";
import TextareaField from "../../Components/Common/Form/TextareaField";
import { addSuggest } from "../../Actions/SuggestFeatureAction";
// eslint-disable-next-line import/no-cycle
import AdminSuggest from "./index";

const styles = () => ({
  lightbg: {
    // padding: “24px”,
    backgroundColor: "#fff",
    padding: "10px 22px 35px",
    textAlign: "left",
  },
  label: {
    margin: "10px",
    fontSize: "12px",
    fontWeight: "700",
  },
  button: {
    width: "148px",
    height: "46px",
    margin: "-140px",
    backgroundColor: "#dddddd",
    color: "#151039",
    "&:hover": {
      color: "#151039",
      backgroundColor: "#d1d1d1!important",
    },
  },
  Addbutton: {
    width: "148px",
    height: "46px",
    margin: "-40px",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  remove_icon: {
    position: "relative",
  },
  social: {
    height: "20px",
    width: "20px",
  },
  formfooter: {
    marginTop: "10px",
    paddingTop: "130px",
  },
  form: {
    marginLeft: "4px",
  },
  formlabel: {
    fontsize: "12px",
  },
  input_field: {
    border: "1px solid #949494",
    height: "133px",
    position: "relative",
    width: "450px",
    padding: "6px 8px",
    margin: "0px",
    // borderBottom: "none",
    fontFamily:
      "'-apple-system,BlinkMacSystemFont','Segoe UI','Roboto','Helvetica Neue','Arial,sans-serif','Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol'",
    color: "currentColor",
    fontSize: "14px",
  },
  uploadClubBanner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "30px",
  },
  borderppup: {
    margin: "30px 40px 0px 0px",
    boxsizing: "border-box",
    height: "133px",
    position: "relative",
    width: "388px",
    borderRadius: "5px",
    border: "3px dashed pink",
  },
  description: {
    color: "#222222",
    fontSize: "13px",
    lineHeight: "18px",
    // marginBottom: "20px",
  },
  inputfield: {
    margin: "2px",
  },
});

const validationShape = {
  module_name: Yup.string().required("This field is required."),
  feature_description: Yup.string()
    .required("This field is required.")
    .max(1000, "this field allow maximum 1000 Charaters"),
  feature_title: Yup.string().required("This field is required."),
};
const SuggestSchema = Yup.object().shape(validationShape);
const ModuleOptions = [
  { value: "community", label: "Community" },
  { value: "co-workers", label: "Co-workers" },
  { value: "freelancers", label: "Freelancers" },
  { value: "cafeteria", label: "Cafeteria" },
  { value: "partner", label: "Partner" },
  { value: "club", label: "Club" },
  { value: "events", label: "Events" },
  { value: "booking", label: "Booking" },
  { value: "other", label: "Other" },
];

class SuggestForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      value: "",
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleClose = () => {
    return <AdminSuggest />;
  };

  formatDataAndCallAPI = (data, setErrors, setSubmitting) => {
    const values = {};
    values.module_name = data.module_name.value;
    values.feature_title = data.feature_title;
    values.feature_description = data.feature_description;
    this.props.addSuggest(values, setErrors, setSubmitting, this.props.history);
    this.props.submit();
  };

  handleChange(event) {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ value: event.target.value });
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <div className={classes.lightbg}>
          <Formik
            initialValues={{
              feature_title: "",
              feature_description: "",
              module_name: [],
            }}
            // enableReinitialize
            validationSchema={SuggestSchema}
            onSubmit={(values, { setErrors, setSubmitting }) => {
              this.formatDataAndCallAPI(values, setErrors, setSubmitting);
            }}
          >
            {({
              values,
              errors,
              touched,
              setFieldTouched,
              setFieldValue,
              handleSubmit,
            }) => (
              <Form
                onSubmit={handleSubmit}
                className={classes.form}
                encType="multipart/form-data"
                autoComplete="off"
              >
                {/* <Grid container xs={12}>
                    <Grid item xs={12} sm={12}> */}
                <Typography
                  align="left"
                  style={{ marginBottom: "-18px" }}
                  className={`${classes.description} ${classes.moduleLabel}`}
                >
                  Module
                </Typography>
                <SelectField
                  value={values.module_name}
                  name="module_name"
                  id="module_name"
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                  multi={false}
                  error={errors.module}
                  touched={touched.module}
                  options={ModuleOptions}
                />
                {/* </Grid> */}
                <Grid item xs={12} sm={12}>
                  <Typography align="left" className={classes.description}>
                    Define a title
                  </Typography>
                  <InputField
                    className={classes.inputfield}
                    name="feature_title"
                    placeholder="Define Title"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography
                    align="left"
                    className={classes.description}
                    style={{ marginBottom: "10px" }}
                  >
                    Description of the feature
                  </Typography>
                  <TextareaField
                    name="feature_description"
                    className={classes.input_field}
                    rows="5"
                    placeholder="Add Comments"
                    maxlength="1000"
                  ></TextareaField>
                </Grid>
                {/* <Grid item xs={12} className={classes.borderppup}>  
                      <Grid item xs={12} className={classes.uploadClubBanner}>
                      
                      <UploadField
                      label="Upload refrence image"
                      name="feature_refrence_image"
                      id="feature_refrence_image"
                      onChange={setFieldValue}
                      value={values.feature_refrence_image}
                      error={errors.feature_refrence_image}
                      folder="suggest-refrence/image"
                      delImageUrl={this.setDelImageUrl}
                    />
                      </Grid>
                    </Grid> */}

                <div className={classes.formfooter}>
                  <Grid
                    container
                    item
                    display="flex"
                    justify="flex-end"
                    alignItems="center"
                    direction="row"
                  >
                    <Grid xs={6} sm={2}>
                      <Button
                        color="primary"
                        onClick={this.props.submit}
                        className={classes.button}
                      >
                        CANCEL
                      </Button>
                    </Grid>
                    <Grid xs={6} sm={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        className={classes.Addbutton}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    suggest: state.suggestfeature.like_suggesion,
    loading: state.suggestfeature.loading,
  };
};

export default connect(mapStateToProps, {
  addSuggest,
})(withStyles(styles)(withRouter(SuggestForm)));
