import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import {
  Dialog,
  DialogContent,
  IconButton,
  Grid,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import thankyou from "../../Assets/thankyou.png";
// import ThankyouSvg from "../../Assets/svgs/index";

const styles = (theme) => ({
  thankyou_modal: {
    width: 500,
    // height: 430,
    padding: "15px 0px",
    overflow: "hidden",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  thankyou: {
    fontFamily: "Dancing Script",
    fontWeight: "bold",
    fontSize: "67px",
    textAlign: "center",
    color: "#512da8",
    margin: "0px",
    marginTop: "-18px",
    paddingBottom: "6px",
  },
  plainThankyou: {
    fontWeight: "500",
    fontSize: "35px",
    textAlign: "center",
    color: "#222222",
    margin: "0px",
    marginTop: "-18px",
    paddingBottom: "6px",
  },
  innercont: {
    margin: "10px 0px 0px",
    textAlign: "center",
  },
  layout: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  thankyouSvg: {
    width: "334px",
    marginBottom: "10px",
  },
  root: {
    padding: "0px !important",
  },
});

class SuccessPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      successPopup: false,
      // eslint-disable-next-line react/no-unused-state
      errors: {},
    };
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  openSuccessPopup = () => {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ successPopup: true });
  };

  closeSuccessPopup = () => {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ successPopup: false });
  };

  handleSuccess = () => {
    this.props.handleClose();
    // this.setState({ modalOpen: false });
    // this.props.history.push("/Dashboard");
  };

  render() {
    const { classes, image } = this.props;
    return (
      <Dialog
        open={this.props.data}
        onClose={this.props.handleClose}
        aria-labelledby="plan-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.root}
      >
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={this.props.handleClose}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent className={classes.thankyou_modal}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} className={classes.layout}>
              <img
                alt="logo"
                src={image || thankyou}
                className={classes.thankyouSvg}
              />
            </Grid>
            {/* <ThankyouSvg /> */}
            <Grid item xs={12} sm={12} md={12} className={classes.layout}>
              <Typography
                align="center"
                variant="h1"
                className={image ? classes.plainThankyou : classes.thankyou}
              >
                Thank you !
              </Typography>
            </Grid>
            {image ? (
              <Grid item xs={12} sm={12} md={12} className={classes.layout}>
                <div className={classes.innercont}>
                  <Typography align="center" variant="p">
                    Our community manager will reach out to you!
                  </Typography>
                </div>
              </Grid>
            ) : (
              <Grid item xs={12} sm={12} md={12} className={classes.layout}>
                <div className={classes.innercont}>
                  <Typography align="center" variant="p">
                    Hope you have an amazing day!!
                  </Typography>
                </div>
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errors: state.errors,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(SuccessPopup));
