/* eslint-disable camelcase */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import { withRouter } from 'react-router-dom'
import {
  Dialog,
  DialogContent,
  Grid,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import classNames from 'classnames'
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import { CircularLoader } from '../../Components/Common/Spinner'
import { getSpaceCompanyList } from '../../Actions/companiesAction'
import {
  getUserSignupList,
  AddSignupConfirmation,
} from '../../Actions/authAction'
import { checkPermission } from '../../Utils/checkPermission'
import { spacecapitalize } from '../../Utils/StringFunctions'
import Icon_Accept_Grey from '../../Assets/icons/Icon_Accept Selected.png'
import Icon_Reject_Selected from '../../Assets/icons/Icon_Reject Selected.png'
import Icon_Reject_Grey from '../../Assets/icons/Icon_Reject Grey.png'
import ScreenHeader from '../../Components/Common/ScreenHeader'
import NameTruncate from '../../Components/Common/NameTruncate'
import Pagination from '../../Components/Common/Table/TablePagination'
import RoleTypesDropdown from '../../Components/Common/Dropdowns/RoleTypesDropdown'
import SelectField from '../../Components/Common/Form/SelectField'
import { getSpaces } from '../../Actions/spaceActions'
import DeleteAlert from '../../Components/Common/DeleteAlert'
import nodata from '../../Assets/nodata.png'

const $quoteSym = ' '
const moment = require('moment')

const styles = (theme) => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    backgroundColor: 'white',
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    padding: '15px 24px 24px',
    flexGrow: 1,
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      padding: '15px 24px 24px',
    },
  },
  root: {
    backgroundColor: '#fff',
  },
  table: {
    minWidth: 650,
    marginTop: '20px',
  },
  resource_form_modal: {
    width: '500px',
    height: '410px',
  },
  form_modal: {
    minWidth: '500px',
    height: '410px',
  },
  correctbutton: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  ScreenHeader: {
    marginLeft: '-28px',
    width: '100%',
  },
  cancelbutton: {
    marginRight: '20px',
    backgroundColor: '#dddddd',
    color: '#151039',
    '&:hover': {
      color: '#151039',
      backgroundColor: '#d1d1d1!important',
    },
  },
  addbutton: {
    color: '#fff',
    backgroundColor: '#211b4e !important',
    '&:hover': {
      backgroundColor: '#151039 !important',
    },
  },
  modelHeader: {
    paddingBottom: '5px',
    position: 'relative',
    '&:after': {
      top: '48px',
      left: '24px',
      width: '24px',
      borderBottom: '3px solid #fb0',
      content: `'${$quoteSym}'`,
      position: 'absolute',
    },
  },
  modalFooter: {
    padding: '40px 0px 8px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  root1: {
    '&:nth-child(even)': {
      backgroundColor: '#FCFDFE',
    },
  },
  rejected: {
    color: '#cdcdcd',
  },
  addDeleteUser: {
    display: 'flex',
  },
  headerSpacing: {
    margin: '13% 0% 0% 3%',
  },
})

const themes = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        minWidth: '40px',
      },
    },
  },
})
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#F8FAFF',
    color: theme.palette.common.black,
    borderBottom: 'none',
    padding: '8px 40px 8px 16px',
  },
  body: {
    fontSize: 14,
    borderBottom: 'none',
  },
}))(TableCell)

const ResourceSchema = Yup.object().shape({
  space: Yup.string().required('This field is required.'),
  client_details: Yup.string().required('This field is required.'),
  company: Yup.string().when('client_details', {
    is: (client_details) => client_details === 'company',
    then: Yup.string().required('This field is required'),
  }),
  roletypeid: Yup.string().when('client_details', {
    is: (client_details) => client_details === 'company',
    then: Yup.string().required('This field is required'),
  }),
  // company: Yup.string().required("This field is required."),
  // roletypeid: Yup.string().required("This field is required."),
})

const ClientDetailsOptions = [
  { value: 'company', label: 'Company' },
  { value: 'freelancer', label: 'Freelancer' },
]

export class SignupConfirmation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user_Signup_list: [],
      id: '',
      page: 0,
      rowsPerPage: 10,
      allspaces: [],
      allcompanies: [],
      showConfirmDel: false,
      client_details: 'freelancer',
    }
  }

  state = {
    modalOpen: false,
  }

  componentDidMount() {
    this.props.getUserSignupList()
    this.props.getSpaces()
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user_Signup_list !== prevState.user_Signup_list) {
      return { user_Signup_list: nextProps.user_Signup_list }
    }
    if (nextProps.spaces !== prevState.spaces) {
      const allspaces =
        (nextProps.spaces &&
          nextProps.spaces.map((element) => {
            return {
              label: spacecapitalize(element.space_title),
              value: element._id,
            }
          })) ||
        []
      return { spaces: nextProps.spaces, allspaces }
    }
    if (nextProps.spaceCompany !== prevState.spaceCompany) {
      const allcompanies =
        (nextProps.spaceCompany &&
          nextProps.spaceCompany.map((company) => {
            return {
              value: company._id,
              label: spacecapitalize(company.company_name),
            }
          })) ||
        []
      return { spaceCompany: nextProps.compaspaceCompanynies, allcompanies }
    }
    return []
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user_Signup_list !== this.props.user_Signup_list) {
      let user_Signup_list = this.props.user_Signup_list || []
      user_Signup_list = user_Signup_list.map((x, id) => {
        return Object.assign({}, x, {
          idx: id + 1,
        })
      })
      this.setState({
        user_Signup_list,
      })
    }
    if (
      this.props.signup_confirmation !== prevProps.signup_confirmation &&
      this.props.signup_confirmation.success
    ) {
      this.handleClose()
    }
    if (prevProps.loading !== this.props.loading) {
      this.props.setLoading(this.props.loading)
      this.setState({
        loading: this.props.loading,
      })
    }
  }

  handleClickModalOpen = (id, status) => {
    this.setState({
      id,
      status,
      modalOpen: true,
    })
  }

  handleClose = () => {
    this.setState({
      modalOpen: false,
    })
    this.props.getUserSignupList()
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage })
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    })
  }

  callComapnyAPI = (value) => {
    this.props.getSpaceCompanyList(value.value)
  }

  setClientDetails = (value) => {
    this.setState({ client_details: value.value })
  }

  handleDelete = (id, status) => {
    this.setState({
      id,
      status,
      showConfirmDel: true,
    })
  }

  handleDeletePlan = () => {
    const data = {}
    data.status = this.state.status
    data.signupid = this.state.id
    this.props.AddSignupConfirmation(data)
  }

  render() {
    const { classes, user_Signup_list } = this.props
    const { loading, page, rowsPerPage, id, client_details } = this.state
    const itemLength = user_Signup_list ? user_Signup_list.length : 0
    const role = localStorage.getItem('role')

    if (loading) {
      return <CircularLoader />
    }
    if (user_Signup_list && user_Signup_list.length <= 0) {
      return (
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.headerSpacing}
        >
          <Grid item justify="center">
            <Grid item sm={12} lg={12}>
              <img src={nodata} alt="" width="76%" />
            </Grid>
          </Grid>
        </Grid>
      )
    }
    return (
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <div className={classes.header}>
            <div className={classes.ScreenHeader}>
              <ScreenHeader type=" Signup Confirmation" />
            </div>

            <Dialog
              open={this.state.modalOpen}
              onClose={this.handleClose}
              aria-labelledby="form-dialog-title"
            >
              <Formik
                initialValues={{
                  space: '',
                  company: '',
                  roletypeid: '',
                  client_details: '',
                }}
                enableReinitialize
                validationSchema={ResourceSchema}
                onSubmit={(values) => {
                  const data = { ...values }

                  if (client_details === 'company') {
                    const companyVal = data.company ? data.company.value : ''
                    data.company = companyVal
                    const roleVal = data.roletypeid ? data.roletypeid.value : ''
                    data.roletype = roleVal
                    data.status = this.state.status
                  } else {
                    data.status = 'draft'
                    data.roletype = '5d98923b2af0ac3edcf3384a'
                  }
                  delete data.roletypeid
                  data.client_details = this.state.client_details
                  const spaceVal = data.space ? data.space.value : ''
                  data.space = spaceVal

                  data.signupid = id

                  this.props.AddSignupConfirmation(data)
                  this.props.getUserSignupList()
                  this.handleClose()
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  setFieldTouched,
                  setFieldValue,
                }) => (
                  <Form className={classes.resource_form_modal}>
                    <DialogTitle
                      id="plan-dialog-title"
                      className={classes.modelHeader}
                    >
                      Space Information
                    </DialogTitle>
                    <DialogContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                          <SelectField
                            value={values.space}
                            name="space"
                            id="space"
                            label="Select Space"
                            onChange={(name, value) => {
                              setFieldValue('space', value)
                              this.callComapnyAPI(value)
                              setFieldValue('company', '')
                            }}
                            multi={false}
                            error={errors.space}
                            options={this.state.allspaces}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <SelectField
                            value={values.clientDetails}
                            name="client_details"
                            id="client_details"
                            label="Select Client Details"
                            onChange={(name, value) => {
                              setFieldValue('client_details', value.value)
                              this.setClientDetails(value)
                              // setFieldValue("company", "");
                            }}
                            multi={false}
                            error={errors.space}
                            options={ClientDetailsOptions}
                          />
                        </Grid>
                        {client_details === 'company' && (
                          <Grid item xs={12} sm={12}>
                            <SelectField
                              value={values.company}
                              name="company"
                              id="company"
                              label="Company"
                              onChange={setFieldValue}
                              onBlur={setFieldTouched}
                              multi={false}
                              error={errors.company}
                              touched={touched.company}
                              options={this.state.allcompanies}
                            />
                          </Grid>
                        )}
                        {client_details === 'company' && (
                          <Grid item xs={12} sm={12}>
                            <RoleTypesDropdown
                              values={values}
                              setFieldValue={setFieldValue}
                              setFieldTouched={setFieldTouched}
                              errors={errors}
                              touched={touched}
                              name="roletypeid"
                            />
                          </Grid>
                        )}
                      </Grid>
                      <Grid className={classes.modalFooter}>
                        <Button
                          onClick={this.handleClose}
                          color="primary"
                          className={classes.cancelbutton}
                        >
                          Cancel
                        </Button>
                        <Button
                          color="primary"
                          className={classes.addbutton}
                          type="submit"
                          variant="contained"
                        >
                          Add
                        </Button>
                      </Grid>
                    </DialogContent>
                    {/* <DialogActions className={classes.modalFooter}> */}

                    {/* </DialogActions> */}
                  </Form>
                )}
              </Formik>
            </Dialog>
          </div>
          <div className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Sr. No.</StyledTableCell>
                  <StyledTableCell align="left">Date</StyledTableCell>
                  <StyledTableCell align="left">Name</StyledTableCell>
                  <StyledTableCell align="left">Mobile No.</StyledTableCell>
                  <StyledTableCell align="left">Email ID</StyledTableCell>
                  <StyledTableCell align="left">Company Name</StyledTableCell>
                  <StyledTableCell align="left">Location</StyledTableCell>
                  <StyledTableCell align="center" />
                </TableRow>
              </TableHead>

              <TableBody>
                {user_Signup_list &&
                  user_Signup_list
                    //   // Pagination
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, id) => {
                      return (
                        <TableRow className={classes.root1}>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            className={classNames(
                              classes.accept,
                              item.status === 'rejected' ? classes.rejected : ''
                            )}
                          >
                            {page * rowsPerPage + id + 1}
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            className={classNames(
                              classes.accept,
                              item.status === 'rejected' ? classes.rejected : ''
                            )}
                          >
                            {moment(item.createdAt).format('DD/MM/YYYY')}
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            className={classNames(
                              classes.accept,
                              item.status === 'rejected' ? classes.rejected : ''
                            )}
                          >
                            <NameTruncate
                              text={spacecapitalize(
                                item.first_name.concat(' ', item.last_name)
                              )}
                              amount={22}
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            className={classNames(
                              classes.accept,
                              item.status === 'rejected' ? classes.rejected : ''
                            )}
                          >
                            {item.contact_number}
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            className={classNames(
                              classes.accept,
                              item.status === 'rejected' ? classes.rejected : ''
                            )}
                          >
                            {item.email}
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            className={classNames(
                              classes.accept,
                              item.status === 'rejected' ? classes.rejected : ''
                            )}
                          >
                            {spacecapitalize(
                              item.company_name ? item.company_name : '-'
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            className={classNames(
                              classes.accept,
                              item.status === 'rejected' ? classes.rejected : ''
                            )}
                          >
                            {spacecapitalize(
                              item.location ? item.location : '-'
                            )}
                          </StyledTableCell>
                          <ThemeProvider theme={themes}>
                            {role === 'communitymanager' ||
                            checkPermission('RESOURCETYPE_UPDATE') ? (
                              <StyledTableCell
                                align="left"
                                className={classNames(
                                  classes.accept,
                                  item.status === 'rejected'
                                    ? classes.rejected
                                    : ''
                                )}
                              >
                                <div className={classes.addDeleteUser}>
                                  <Button
                                    className={classes.correctbutton}
                                    onClick={() =>
                                      this.handleClickModalOpen(item._id, 'yes')
                                    }
                                  >
                                    <img
                                      src={Icon_Accept_Grey}
                                      alt=""
                                      width={30}
                                    />
                                  </Button>
                                  <Button
                                    className={classes.correctbutton}
                                    disabled={item.status === 'rejected'}
                                    onClick={() =>
                                      this.handleDelete(item._id, 'no')
                                    }
                                  >
                                    {item.status === 'rejected' ? (
                                      <img
                                        src={Icon_Reject_Grey}
                                        alt=""
                                        width={30}
                                      />
                                    ) : (
                                      <img
                                        src={Icon_Reject_Selected}
                                        alt=""
                                        width={30}
                                      />
                                    )}
                                  </Button>
                                </div>
                              </StyledTableCell>
                            ) : (
                              ''
                            )}
                          </ThemeProvider>
                        </TableRow>
                      )
                    })}
              </TableBody>
            </Table>
          </div>
        </Paper>
        <Pagination
          count={itemLength}
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
        <DeleteAlert
          message="Are you sure want to reject guest user?"
          open={this.state.showConfirmDel}
          setOpen={(data) => this.setState({ showConfirmDel: data })}
          alert={this.handleDeletePlan}
        />
      </main>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user_Signup_list: state.auth.user_Signup_list,
    loading: state.auth.loading,
    spaces: state.spaces.spaces,
    spaceCompany: state.company.spaceCompany,
    signup_confirmation: state.auth.signup_confirmation,
  }
}
export default connect(mapStateToProps, {
  getUserSignupList,
  getSpaces,
  getSpaceCompanyList,
  AddSignupConfirmation,
})(withStyles(styles)(withRouter(SignupConfirmation)))
