import axios from "axios";
import {
  GET_MERCHANDISE_PRODUCT,
  MERCHANDISE_ERROR,
  MERCHANDISE_LOADING,
  ADD_MERCHANDISE_PRODUCT,
  ADD_MERCHANDISE_POINT,
  GET_MERCHANDISE_POINT,
  GET_MERCHANDISE_ALL_ORDER,
  UPDATE_MERCHANDISE_ORDER_STATUS,
  PLACE_ORDER,
  GET_USER_MERCHANDISE_POINT,
} from "./types";
import { showSuccess, showError } from "./ActionsHelper";
import { transformErrorsFromApi } from "./TransformError";

//  Loading
export const setMerchandiseLoading = () => {
  return {
    type: MERCHANDISE_LOADING,
  };
};

export const getMerchandiseProduct = () => (dispatch) => {
  dispatch(setMerchandiseLoading());

  axios
    .get(`/api/merchandise`)
    .then((result) => {
      dispatch({
        type: GET_MERCHANDISE_PRODUCT,
        payload: result.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: MERCHANDISE_ERROR,
        payload: err && err.response ? err.response.data : "",
      });
    });
};

export const addMerchandiseProduct = (userData, setErrors, setSubmitting) => (
  dispatch
) => {
  dispatch(setMerchandiseLoading());
  axios
    .post("/api/merchandise", userData)
    .then((result) => {
      dispatch({
        type: ADD_MERCHANDISE_PRODUCT,
        payload: result.data,
      });
      dispatch(showSuccess("Product"));
      setSubmitting(false);
    })
    .catch((err) => {
      dispatch({ type: MERCHANDISE_ERROR, payload: err.data });
      dispatch(showError("There was some error while create product. "));
      setSubmitting(false);
      setErrors(transformErrorsFromApi(err));
    });
};

export const getMerchandiseAllOrder = () => (dispatch) => {
  dispatch(setMerchandiseLoading());
  axios
    .get(`/api/merchandise/orders/all`)
    .then((result) => {
      dispatch({
        type: GET_MERCHANDISE_ALL_ORDER,
        payload: result.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: MERCHANDISE_ERROR,
        payload: err && err.response ? err.response.data : "",
      });
    });
};

export const addMerchandisePoint = (userData, setErrors, setSubmitting) => (
  dispatch
) => {
  dispatch(setMerchandiseLoading());
  axios
    .put("/api/merchandise/addpoints", userData)
    .then((result) => {
      dispatch({
        type: ADD_MERCHANDISE_POINT,
        payload: result.data,
      });
      dispatch(showSuccess("Merchandise point"));
      setSubmitting(false);
    })
    .catch((err) => {
      dispatch({ type: MERCHANDISE_ERROR, payload: err.data });
      dispatch(showError("There was some error while add merchandise point. "));
      setSubmitting(false);
      setErrors(transformErrorsFromApi(err));
    });
};

export const getMerchandisePoint = () => (dispatch) => {
  dispatch(setMerchandiseLoading());
  axios
    .get("/api/merchandise/added/points")
    .then((result) => {
      dispatch({
        type: GET_MERCHANDISE_POINT,
        payload: result.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: MERCHANDISE_ERROR,
        payload: err && err.response ? err.response.data : "",
      });
    });
};

export const updateMerchandiseOrderStatus = (id, userData, setSubmitting) => (
  dispatch
) => {
  // dispatch(setCoworkersLoading());

  axios
    .put(`/api/merchandise/update/orderstatus/${id}`, userData)
    .then((result) => {
      dispatch({
        type: UPDATE_MERCHANDISE_ORDER_STATUS,
        payload: result.data,
      });
      dispatch(showSuccess("", "Status updated successfully!"));
      setSubmitting(false);
    })
    .catch((err) => {
      dispatch({
        type: MERCHANDISE_ERROR,
        // payload: err.response.data
        payload: ((err || {}).response || {}).data || "Error unexpected",
      });
    });
};

export const placeOrder = (userData, setErrors, setSubmitting) => (
  dispatch
) => {
  dispatch(setMerchandiseLoading());
  axios
    .post("/api/merchandise/checkout", userData)
    .then((result) => {
      dispatch({
        type: PLACE_ORDER,
        payload: result.data,
      });
      dispatch(showSuccess("Order"));
      setSubmitting(false);
    })
    .catch((err) => {
      dispatch({ type: MERCHANDISE_ERROR, payload: err.data });
      dispatch(showError("There was some error while add order. "));
      setSubmitting(false);
      setErrors(transformErrorsFromApi(err));
    });
};

export const getUserMerchandisePoint = () => (dispatch) => {
  dispatch(setMerchandiseLoading());
  axios
    .get("/api/user/merchandise/points")
    .then((result) => {
      dispatch({
        type: GET_USER_MERCHANDISE_POINT,
        payload: result.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: MERCHANDISE_ERROR,
        payload: err && err.response ? err.response.data : "",
      });
    });
};
