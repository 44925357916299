/* eslint-disable func-names */
/* eslint-disable camelcase */
/* eslint-disable import/imports-first */
/* eslint-disable no-param-reassign */
import React from "react";
import {
  Grid,
  InputAdornment,
  Typography,
  withStyles,
  Button,
  FormLabel,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import "../company.css";
import * as Yup from "yup";
import { Formik } from "formik";
import { connect } from "react-redux";
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import moment from "moment";
import { FacebookRoundIcon } from "../../Assets/index";
import TimePickerField from "../../Components/Common/Form/TimePickerField";
import { CircularLoader } from "../../Components/Common/Spinner";
import { getUnbookedResources } from "../../Actions/resourceActions";
import { registerBooking } from "../../Actions/bookingActions";
import {
  EventCategory,
  AttendesCategory,
  SetupOptions,
  eventTypeOptions,
} from "../../Utils/dropDownData";
import { checkPermission } from "../../Utils/checkPermission";
import { setEditorState } from "../../Utils";
import { capitalize } from "../../Utils/StringFunctions";

import RichEditorExample from "../../Components/Common/RichEditor";
import {
  InputField,
  SelectField,
  RadioField,
  DatePickerField,
  UploadField,
} from "../../Components/Common/Form";

import {
  registerEvent,
  getEvent,
  updateEvent,
} from "../../Actions/eventActions";
import CompaniesDropdown from "../../Components/Common/Dropdowns/CompaniesDropdown";
import { getSpaces } from "../../Actions/spaceActions";

const styles = () => ({
  lightbg: { padding: "20px 30px 0px", backgroundColor: "#fff" },
  label: {
    margin: "15px 0px 8px 0px",
    fontSize: "12px",
    fontWeight: "700",
  },
  button: {
    width: "148px",
    height: "46px",
    color: "#151039",
    backgroundColor: "#dddddd",
    "&:hover": {
      color: "#151039",
      backgroundColor: "#d1d1d1!important",
    },
  },
  Addbutton: {
    width: "148px",
    height: "46px",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  formfooter: {
    margin: "30px 15px",
    marginRight: "-2%",
  },
  social: {
    height: "20px",
    width: "20px",
  },
  AdjustSizeOfCategory: {
    maxWidth: "50%",
    paddingLeft: "8px",
  },
  AdjustSizeOfPrice: {
    paddingLeft: "40px",
  },
  descriptionLabel: {
    margin: "15px 0px 8px 0px",
    fontSize: "12px",
    fontWeight: "400",
    color: "rgba(0, 0, 0, 0.54)",
  },
  form: {
    marginLeft: "-4px",
  },
  errmessage: {
    display: "none",
  },
  newerrormessage: {
    color: "red",
  },
  displayLayout: {
    display: "flex",
  },
  eventTypeSection: {
    maxWidth: "50%",
    // paddingLeft: "8px"
    padding: "15px 0px 0px 8px",
  },
  teamTypeSection: {
    display: "flex ",
  },
  teamSection: {
    marginRight: "6px",
  },
  dropinsidetext: {
    marginTop: "31px",
  },
});

const categoryOptions = [
  { label: "Free", value: "free" },
  { label: "Paid", value: "paid" },
];

const EventSchema = Yup.object().shape({
  category_type: Yup.string(),
  price: Yup.number()
    .typeError("Please input a number")
    .test("match", "price is required", function (value) {
      const { category_type } = this.parent;
      if (category_type === "paid") {
        if (!value) {
          return false;
        }
        return true;
      }
      return true;
    }),
  team_min: Yup.number()
    .typeError("Please input a number")
    .test("match", "Min is required", function (value) {
      const { event_type } = this.parent;
      if (event_type === "team") {
        if (!value) {
          return false;
        }
        return true;
      }
      return true;
    }),

  team_max: Yup.number()
    .typeError("Please input a number")
    .test("match", "Max should not be less than Min.", function (value) {
      const { event_type } = this.parent;
      const { team_min } = this.parent;
      if (event_type === "team") {
        if (team_min >= value || !value) {
          return false;
        }
        return true;
      }
      return true;
    }),
  event_name: Yup.string()
    .max(15, "")
    .trim()
    .required("This field is required."),
  resources: Yup.string(),
  company_id: Yup.string().when("resources", {
    is: (resources) => resources,
    then: Yup.string().required("This field is required"),
  }),
  no_of_seats: Yup.number()
    .typeError("Please input a number")
    .required("This field is required."),
  banner: Yup.mixed().required("This field is required."),
  space_id: Yup.string().required("This field is required."),
  display_banner: Yup.mixed().required("This field is required."),
  setup: Yup.string().required("This field is required."),
  attendees: Yup.string().required("This field is required."),
  host: Yup.string().required("This field is required."),
  event_category: Yup.string().required("This field is required."),
  details: Yup.mixed().test("match", "This field is required", function () {
    const finalContent =
      this.parent &&
      this.parent.editorState.getCurrentContent().getPlainText() !== "";
    if (finalContent === true) {
      return true;
    }
    return false;
  }),
});

class EventForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      resources: [],
      resourceOptions: [], // resource
      isResourcesVisible: false,
      values: {},
      id:
        this.props.match && this.props.match.params
          ? this.props.match.params.id
          : "",
      isErrorMessageVisible: false,
      spaces: [],
      deletedKey: [],
      deletedKey2: [],
    };
  }

  componentDidMount() {
    if (this.state.id) {
      this.props.getEvent(this.state.id);
    }
    const role = localStorage.getItem("role");
    if (role === "admin") {
      this.props.getSpaces();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.unbooked !== prevState.unbooked) {
      const resourceOptions =
        (nextProps.unbooked &&
          nextProps.unbooked.map((resource) => {
            return {
              label: `${resource.resource_name} || ${resource.pax} seats`,
              value: resource._id,
            };
          })) ||
        [];
      return { unbooked: nextProps.unbooked, resourceOptions };
    }

    if (nextProps.event !== prevState.event) {
      return { event: nextProps.event, loading: nextProps.event.loading };
    }
    if (nextProps.spaces !== prevState.spaces) {
      return { spaces: nextProps.spaces };
    }

    if (nextProps.errors) {
      return { errors: nextProps.errors };
    }
    return {};
  }

  componentDidUpdate(prevProps) {
    if (prevProps.event !== this.props.event) {
      if (this.props.event.success) {
        if (this.state.id === undefined) {
          this.addBooking(this.state.values);
        }
      }
    }
  }

  addBooking = (values, setErrors, setSubmitting) => {
    const bookingData = {};
    const resourceValue = values.resources ? values.resources.value : "";
    bookingData.resources = resourceValue;
    bookingData.resource_id = values.resources && values.resources.value;
    bookingData.time = moment(values.start_time).format("YYYY-MM-DD HH:mm:ss");
    const mergedatetime = `${moment(values.start_date).format(
      "YYYY-MM-DD"
    )} ${moment(values.start_time).format("HH:mm:ss")}`;

    const startdatetime = moment(mergedatetime).format();
    const enddatetime = `${moment(values.end_date).format(
      "YYYY-MM-DD"
    )} ${moment(values.end_time).format("HH:mm:ss")}`;
    const minutes = moment(enddatetime).diff(mergedatetime, "minutes");
    bookingData.booking_date = moment(startdatetime).format();
    bookingData.duration_in_minutes = minutes;
    bookingData.company_id = values.company_id.value;

    if (values.company_id && values.resources) {
      this.props.registerBooking(
        bookingData,
        setErrors,
        setSubmitting,
        this.props.history
      );
    }
  };

  callResourcesAPi = (values, name, value, setFieldValue) => {
    if (values.start_date && checkPermission("RESOURCE_VIEW")) {
      const data = {};
      // data.date = moment(values.start_date).format();
      setFieldValue(name, value);
      // TODO : this is not fixed thing, please make sure to change it.
      // use moment to convert it to date object
      const startdatetime = `${moment(values.start_date).format(
        "YYYY-MM-DD"
      )} ${moment(values.start_time).format("HH:mm")}:00`;
      const enddatetime = `${moment(values.end_date).format(
        "YYYY-MM-DD"
      )} ${moment(value).format("HH:mm:ss")}`;
      const minutes = moment(enddatetime).diff(startdatetime, "minutes");
      data.booking_date = moment(startdatetime).format();
      if (minutes <= 0) {
        this.setState({ isErrorMessageVisible: true });
      } else {
        this.setState({ isErrorMessageVisible: false });
        data.duration_in_minutes = minutes;
      }

      this.props.getUnbookedResources(data);
      this.setState({ isResourcesVisible: true }, () => {});
    } else {
      this.setState({ isResourcesVisible: false }, () => {});
    }
  };

  handleCancelButton = () => {
    this.props.history.push("/events");
  };

  onEditorStateChange = (html, name) => {
    this.setState({
      [name]: html,
    });
  };

  getAvailableSpacesOptions = (spaces, name) => {
    if (name) {
      const spacedata = [];
      spaces.filter((u) => {
        if (name.space_title.includes(u.space_title)) {
          return spacedata.push({
            value: u._id,
            label: capitalize(u.space_title),
          });
        }
        return null;
      });
      return spacedata;
    }
    return (
      checkPermission("SPACE_VIEW") &&
      spaces &&
      spaces.map((space) => {
        return {
          value: space._id,
          label: capitalize(space.space_title),
        };
      })
    );
  };

  setDelImageUrl = (url) => {
    const { deletedKey } = this.state;
    this.setState({ deletedKey: deletedKey.push(url) });
  };

  delDisplayBannerUrl = (url) => {
    const { deletedKey2 } = this.state;
    this.setState({ deletedKey2: deletedKey2.push(url) });
  };

  geIntialValues = (id) => {
    const { event } = this.props;

    if (id) {
      return {
        event_name: event.event_name,
        host: event.host,
        category_type: event.category_type === "free" ? "free" : "paid",
        price: event.price || "",
        event_category: EventCategory.filter(
          (option) => option.value === event.event_category
        )[0],
        details: event.details,
        start_date: event.start_date,
        start_time: event.start_time,
        end_date: event.end_date,
        end_time: event.end_time,
        no_of_seats: event.no_of_seats,
        attendees: AttendesCategory.filter(
          (option) => option.value === event.type_of_attendees
        )[0],
        setup:
          event.setup &&
          SetupOptions.filter((option) =>
            event.setup.join().includes(option.value)
          ),
        venue: event.venue === "-" ? "" : event.venue,
        banner: event.banner,
        display_banner: event.display_banner,
        facebook: event.facebook,
        linkedin: event.linkedin,
        editorState: setEditorState(event.details),
        company_id: event.company_id,
        event_type: event.event_type || "",
        team_min: event.team_min || 0,
        team_max: event.team_max || 0,
        space_id:
          event.space_id &&
          this.getAvailableSpacesOptions(this.state.spaces, event.space_id),
      };
    }
    return {
      event_name: "",
      host: "",
      category_type: "free",
      price: "",
      event_category: "",
      details: "",
      start_date: new Date(),
      start_time: new Date(),
      end_date: new Date(),
      end_time: new Date(),
      no_of_seats: "",
      attendees: "",
      setup: [],
      venue: "",
      banner: "",
      display_banner: "",
      facebook: "",
      linkedin: "",
      // eslint-disable-next-line new-cap
      editorState: new EditorState.createEmpty(),
      company_id: "",
      event_type: "individual",
      team_min: 0,
      team_max: 0,
      space_id: [],
    };
  };

  addOrUpdateEvent = (values, setErrors, setSubmitting) => {
    const bookingData = {};
    const submitValues = { ...values };
    submitValues.created_by = localStorage.getItem("user_id");
    const val = values.category ? values.category.value : "";
    submitValues.category = val;
    const eventValue = values.event_category ? values.event_category.value : "";

    if (values.category_type === "free") {
      submitValues.price = "";
    }

    submitValues.event_category = eventValue;
    const attendesValue = values.attendees ? values.attendees.value : "";
    submitValues.type_of_attendees = attendesValue;
    const eventSetup =
      values.setup && values.setup.map((eventsetup) => eventsetup.value);
    submitValues.setup = eventSetup;
    if (checkPermission("COMPANY_VIEW")) {
      const companyVal = values.company_id ? values.company_id.value : "";
      submitValues.company_id = companyVal;
    } else {
      submitValues.company_id = localStorage.getItem("company_id");
    }

    if (!this.state.id) {
      const spaceForAdd =
        submitValues.space_id &&
        submitValues.space_id.map((spaceid) => spaceid.value);
      submitValues.space_id = spaceForAdd;
    } else {
      const spaceForUpdate = localStorage.getItem("space_id");
      submitValues.space_id = spaceForUpdate;
    }
    submitValues.setup = eventSetup;
    submitValues.details = stateToHTML(values.editorState.getCurrentContent());

    const startdatetime = `${moment(values.start_date).format(
      "YYYY-MM-DD"
    )} ${moment(values.start_time).format("HH:mm:ss")}`;
    const enddatetime = `${moment(values.end_date).format(
      "YYYY-MM-DD"
    )} ${moment(values.end_time).format("HH:mm:ss")}`;

    const minutes = moment(enddatetime).diff(startdatetime, "minutes");

    bookingData.booking_date = values.start_date;
    const resourceValue = values.resources ? values.resources.value : "";
    // eslint-disable-next-line no-param-reassign
    bookingData.resources = resourceValue;
    bookingData.resource_id = resourceValue;
    bookingData.time = values.start_time;
    bookingData.duration_in_minutes = minutes;
    bookingData.company_id = values.company_id;

    this.setState({ values });

    delete submitValues.editorState;
    delete submitValues.images;
    if (!this.state.id) {
      this.props.registerEvent(
        submitValues,
        setErrors,
        setSubmitting,
        this.props.history
      );
    } else {
      this.props.updateEvent(
        this.state.id,
        submitValues,
        setErrors,
        setSubmitting,
        this.props.history
      );
    }
  };

  render() {
    const { classes } = this.props;
    const {
      id,
      loading,
      resourceOptions,
      isResourcesVisible,
      isErrorMessageVisible,
      spaces,
    } = this.state;
    return (
      <React.Fragment>
        <div className={classes.lightbg}>
          <Typography
            variant="h6"
            gutterBottom
            style={{ marginBottom: "30px", marginLeft: "-4px" }}
          >
            {id ? "Update" : "Add"} Event
          </Typography>
          <Formik
            initialValues={this.geIntialValues(id)}
            enableReinitialize={!!id}
            validationSchema={EventSchema}
            // eslint-disable-next-line no-unused-vars
            onSubmit={(values, { props, setErrors, setSubmitting }) => {
              values.venue = values.venue || "-";
              const startdatetime = `${moment(values.start_date).format(
                "YYYY-MM-DD"
              )} ${moment(values.start_time).format("HH:mm:ss")}`;
              const enddatetime = `${moment(values.end_date).format(
                "YYYY-MM-DD"
              )} ${moment(values.end_time).format("HH:mm:ss")}`;

              const minutes = moment(enddatetime).diff(
                startdatetime,
                "minutes"
              );
              if (minutes <= 0) {
                this.setState({ isErrorMessageVisible: true });
              } else {
                this.setState({ isErrorMessageVisible: false });
                this.addOrUpdateEvent(values, setErrors, setSubmitting);
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              setFieldTouched,
              handleBlur,
              setFieldValue,
              handleSubmit,
            }) => (
              <form
                onSubmit={handleSubmit}
                className={classes.form}
                encType="multipart/form-data"
                autoComplete="off"
              >
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6}>
                    <InputField
                      name="event_name"
                      label="Event Name"
                      placeholder="Enter Event Name"
                    />
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <InputField
                      name="host"
                      label="Host"
                      placeholder="Enter Host"
                    />
                  </Grid>
                  <Grid container className={classes.AdjustSizeOfCategory}>
                    <Grid item>
                      <RadioField
                        name="category_type"
                        label="Category"
                        id="category_type"
                        options={categoryOptions}
                        RadioGroupProps={{
                          checked: values.category_type
                            ? values.category_type
                            : "free",
                        }}
                      />
                    </Grid>
                    {values.category_type === "paid" && (
                      <Grid item xs={5} className={classes.AdjustSizeOfPrice}>
                        <InputField
                          name="price"
                          label="Price Per Seat"
                          placeholder="Enter price"
                          value={values.price}
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <SelectField
                      value={values.event_category}
                      name="event_category"
                      id="event_category"
                      label="Select Event Category"
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      multi={false}
                      error={errors.event_category}
                      touched={touched.event_category}
                      options={EventCategory}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} style={{ paddingBottom: "0px" }}>
                    <FormLabel
                      className={classes.descriptionLabel}
                      component="legend"
                    >
                      Description
                    </FormLabel>
                    <RichEditorExample
                      name="details"
                      editorState={values.editorState}
                      onChange={setFieldValue}
                      onBlur={handleBlur}
                      placeholder="Type your text"
                    />
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    direction="column"
                    justify="space-between"
                    alignItems="flex-start"
                  >
                    <FormLabel component="legend" className={classes.label}>
                      Schedule
                    </FormLabel>
                    <Grid container spacing={2}>
                      <Grid item xs={3} sm={3}>
                        <DatePickerField
                          name="start_date"
                          onChange={setFieldValue}
                          onBlur={setFieldTouched}
                          value={values.start_date}
                          disableFuture={false}
                          minDate={new Date()}
                          label="Start Date"
                        />
                      </Grid>
                      <Grid item xs={3} sm={3}>
                        <TimePickerField
                          name="start_time"
                          onChange={setFieldValue}
                          onBlur={setFieldTouched}
                          value={values.start_time}
                          label="Start Time"
                        />
                      </Grid>
                      <Grid item xs={3} sm={3}>
                        <DatePickerField
                          name="end_date"
                          onChange={setFieldValue}
                          onBlur={setFieldTouched}
                          value={values.end_date}
                          minDate={values.start_date}
                          disableFuture={false}
                          label="End Date"
                        />
                      </Grid>
                      <Grid item xs={3} sm={3}>
                        <TimePickerField
                          name="end_time"
                          touched={touched.end_time}
                          onChange={
                            id
                              ? setFieldValue
                              : (name, value) => {
                                  this.callResourcesAPi(
                                    values,
                                    name,
                                    value,
                                    setFieldValue
                                  );
                                }
                          }
                          onBlur={setFieldTouched}
                          value={values.end_time}
                          label="End Time"
                        />
                        <span
                          className={
                            isErrorMessageVisible
                              ? classes.newerrormessage
                              : classes.errmessage
                          }
                        >
                          Start time must be before end time
                        </span>
                      </Grid>
                    </Grid>
                    {/* </div> */}
                  </Grid>
                  <Grid container className={classes.eventTypeSection}>
                    <Grid item>
                      <RadioField
                        name="event_type"
                        label="Type"
                        id="event_type"
                        options={eventTypeOptions}
                        RadioGroupProps={{
                          checked: values.event_type
                            ? values.event_type
                            : "individual",
                        }}
                      />
                    </Grid>
                    {values.event_type === "team" && (
                      <Grid item xs={4} className={classes.teamTypeSection}>
                        <div className={classes.teamSection}>
                          <InputField
                            name="team_min"
                            label="Min"
                            placeholder="Enter min "
                            value={values.team_min}
                          />
                        </div>
                        <div className={classes.teamSection}>
                          <InputField
                            name="team_max"
                            label="Max"
                            placeholder="Enter max "
                            value={values.team_max}
                          />
                        </div>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <SelectField
                      value={values.space_id}
                      name="space_id"
                      id="space_id"
                      label="Space"
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      multi
                      error={errors.space_id}
                      touched={touched.space_id}
                      options={this.getAvailableSpacesOptions(spaces)}
                      disabled={id}
                    />
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <InputField
                      name="no_of_seats"
                      label="No of Seats"
                      placeholder="Enter No of Seats"
                      className={classes.dropinsidetext}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <SelectField
                      value={values.attendees}
                      name="attendees"
                      id="attendees"
                      label="Attendees"
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      multi={false}
                      error={errors.attendees}
                      touched={touched.attendees}
                      options={AttendesCategory}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <SelectField
                      value={values.setup}
                      name="setup"
                      id="setup"
                      label="Setup"
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      multi
                      error={errors.setup}
                      touched={touched.setup}
                      options={SetupOptions}
                      // disabled={id}
                    />
                  </Grid>
                  {this.state.id === undefined && (
                    <Grid item xs={6} sm={6} style={{ marginTop: "6px" }}>
                      <SelectField
                        value={values.resources}
                        name="resources"
                        id="resources"
                        label="Resources"
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        multi={false}
                        error={errors.resources}
                        touched={touched.resources}
                        options={resourceOptions}
                        placeholder="Please select date and time to load availlable resources."
                        disabled={!isResourcesVisible}
                      />
                    </Grid>
                  )}
                  <Grid item xs={6} sm={6}>
                    {(values.attendees &&
                      values.attendees.value === "company") ||
                    (values.resources && values.resources.value) ? (
                      <CompaniesDropdown
                        values={values}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        errors={errors}
                        touched={touched}
                        name="company_id"
                      />
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={6}>
                      <UploadField
                        name="banner"
                        label="Upload Banner "
                        id="banner"
                        onChange={setFieldValue}
                        value={values.banner}
                        folder="events/banner"
                        delImageUrl={() => this.setDelImageUrl}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <UploadField
                        name="display_banner"
                        label="Upload Display Banner "
                        id="display_banner"
                        onChange={setFieldValue}
                        value={values.display_banner}
                        folder="events/diplaybanner"
                        delImageUrl={() => this.delDisplayBannerUrl}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <InputField
                      name="venue"
                      label="Venue"
                      placeholder="Enter Venue Name"
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <InputField
                      name="facebook"
                      label="Facebook"
                      placeholder="Enter facebook Link"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src={FacebookRoundIcon}
                              alt=""
                              className={classes.social}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
                <div className={classes.formfooter}>
                  <Grid
                    container
                    item
                    display="flex"
                    justify="flex-end"
                    alignItems="center"
                    direction="row"
                  >
                    <Grid xs={6} sm={2}>
                      <Button
                        color="primary"
                        onClick={this.handleCancelButton}
                        className={classes.button}
                        style={{ right: "-5px" }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid xs={6} sm={2}>
                      <Button
                        className={classes.Addbutton}
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        {id ? "Update" : "Add"} Event
                      </Button>
                    </Grid>
                  </Grid>
                  {loading && <CircularLoader />}
                </div>
              </form>
            )}
          </Formik>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    event: state.events.event,
    auth: state.auth,
    errors: state.errors,
    unbooked: state.resources.unbooked,
    spaces: state.spaces.spaces,
  };
};

export default connect(mapStateToProps, {
  registerEvent,
  getEvent,
  updateEvent,
  getUnbookedResources,
  registerBooking,
  getSpaces,
})(withStyles(styles)(withRouter(EventForm)));
