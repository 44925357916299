import {
  GET_ALL_ISSUE_CATEGORY,
  ADD_ISSUE_CATEGORY,
  ISSUE_CATEGORY_LOADING,
  ISSUE_CATEGORY_ERROR,
  EDIT_ISSUE_CATEGORY,
  EDIT_ISSUE_CATEGORY_ERRORS,
  GET_SINGLE_ISSUE_CATEGORY
} from "../Actions/types";

const initialState = {
  issue_category: null,
  all_issue_category: null,
  loading: false,
  edit_issue_category: {},
  single_issue_category: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_ISSUE_CATEGORY:
      return {
        ...state,
        loading: false,
        all_issue_category: action.payload && action.payload.data
      };
    case ADD_ISSUE_CATEGORY:
      return {
        ...state,
        loading: false,
        issue_category: {
          success: true,
          data: action.payload && action.payload.data
        }
      };
    case ISSUE_CATEGORY_LOADING:
      return {
        ...state,
        loading: true
      };
    case ISSUE_CATEGORY_ERROR:
      return {
        ...state,
        loading: false,
        issue_category: {
          success: false,
          data: action.payload && action.payload.data
        }
      };
    case GET_SINGLE_ISSUE_CATEGORY:
      return {
        ...state,
        loading: false,
        single_issue_category: action.payload && action.payload.data
      };
    case EDIT_ISSUE_CATEGORY:
      return {
        ...state,
        loading: false,
        edit_issue_category: {
          success: true,
          data: action.payload && action.payload.data
        }
      };
    case EDIT_ISSUE_CATEGORY_ERRORS:
      return {
        ...state,
        loading: false,
        issue_category_error: { errors: false }
      };
    default:
      return state;
  }
}
