/* eslint-disable no-unused-vars */
import axios from "axios";
import {
  ALLIED_SERVICES_LOADING,
  CREATE_ALLIED_SERVICES,
  GET_ALL_ALLIED_SERVICES,
  ALLIED_SERVICES_ERRORS,
} from "./types";
import { showSuccess, showError } from "./ActionsHelper";
import { transformErrorsFromApi } from "./TransformError";

// Profile Loading
export const setAlliedServicesLoading = () => {
  return {
    type: ALLIED_SERVICES_LOADING,
  };
};

export const getAllAlliedServices = () => (dispatch) => {
  dispatch(setAlliedServicesLoading());
  const url = `/api/alliedservices`;

  axios
    .get(url)
    .then((res) => {
      dispatch({
        type: GET_ALL_ALLIED_SERVICES,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: ALLIED_SERVICES_ERRORS,
        payload: {},
      })
    );
};

export const createAlliedService = (
  data,
  setErrors,
  history,
  setSubmitting,

) => (dispatch) => {
  dispatch(setAlliedServicesLoading());
  axios
    .post("/api/alliedservices", data)
    .then((result) => {
      dispatch({

        type: CREATE_ALLIED_SERVICES,
        payload: result.data,
      });
      //   history.push("/events");
      dispatch(showSuccess("Request"));
    })
    .catch((err) => {
      const error = err.response;
      dispatch({ type: ALLIED_SERVICES_ERRORS, payload: error.data });
      setSubmitting(false);
      // setErrors(transformErrorsFromApi(error));
    });
};
