import axios from "axios";
import { GET_SERVICES, SERVICES_ERRORS, SERVICES_LOADING } from "./types";

//  Loading
export const setServicesLoading = () => {
  return {
    type: SERVICES_LOADING
  };
};

export const getServices = () => dispatch => {
  dispatch(setServicesLoading());
  axios
    .get("/api/servicelist")
    .then(result => {
      dispatch({
        type: GET_SERVICES,
        payload: result.data
      });
    })
    .catch(err =>
      dispatch({
        type: SERVICES_ERRORS,
        payload: err && err.res ? err.res.data : ""
      })
    );
};
