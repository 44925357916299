import {
  GET_INVENTORY_CATEGORY,
  ADD_INVENTORY_CATEGORY,
  INVENTORY_CATEGORY_LOADING,
  GET_SINGLE_INVENTORY_CATEGORY,
  EDIT_INVENTORY_CATEGORY,
  INVENTORY_ERRORS
} from "../Actions/types";

const initialState = {
  inventory_category: null,
  inventory_categories: null,
  loading: false,
  edit_inventory_category: {},
  get_single_inventory_category: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_INVENTORY_CATEGORY:
      return {
        ...state,
        loading: false,
        inventory_categories: action.payload.data
      };
    case ADD_INVENTORY_CATEGORY:
      return {
        ...state,
        loading: false,
        inventory_category: { success: true, data: action.payload.data }
      };
    case GET_SINGLE_INVENTORY_CATEGORY:
      return {
        ...state,
        loading: false,
        succes: true,
        get_single_inventory_category: action.payload && action.payload.data
      };
    case EDIT_INVENTORY_CATEGORY:
      return {
        ...state,
        loading: false,
        edit_inventory_category: {
          success: true,
          data: action.payload && action.payload.data
        }
      };
    case INVENTORY_CATEGORY_LOADING:
      return {
        ...state,
        loading: true
      };
    case INVENTORY_ERRORS:
      return {
        ...state,
        loading: false,
        inventory_error: { errors: false }
      };
    default:
      return state;
  }
}
