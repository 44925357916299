/* eslint-disable no-param-reassign */
import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import { Formik, Form } from "formik";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import * as Yup from "yup";
import { CircularLoader } from "../../Components/Common/Spinner";
// eslint-disable-next-line no-unused-vars
import { checkPermission } from "../../Utils/checkPermission";
import {
  addClubCategory,
  getClubCategory,
} from "../../Actions/ClubCategoryAction";
import InputField from "../../Components/Common/Form/InputField";
import ScreenHeader from "../../Components/Common/ScreenHeader";
import NoRecordsFound from "../../Components/Common/NoRecordsFound";

const $quoteSym = " ";
const styles = () => ({
  table: {
    marginTop: "20px",
  },
  add_plan_button: {
    display: "flex",
    justifyContent: "flex-end",
  },
  category_form_modal: {
    width: "500px",
  },
  editbutton: {
    borderRadius: "50px",
    color: "#211b4e !important",
    borderColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "white !important",
    },
  },
  editicon: {
    fontSize: "16px",
    marginRight: "6px",
    color: "#211b4e !important",
  },
  add_category_button: {
    color: "#fff",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
    marginTop: "-20px",
  },
  close: {
    color: "grey",
  },
  ScreenHeader: {
    marginLeft: "-20px",
    width: "100%",
  },
  dialogTitle: {
    top: "10px",
    padding: "3% 0% 0% 4.5%",
    "&:after": {
      width: "24px",
      paddingLeft: "10px",
      borderBottom: "3px solid #fb0",
      content: `'${$quoteSym}'`,
      position: "absolute",
    },
  },
  root1: {
    "&:nth-child(even)": {
      backgroundColor: "#FCFDFE",
    },
  },
  backButton: {
    margin: "0% 0% 2% 0%",
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F8FAFF",
    color: theme.palette.common.black,
    borderBottom: "none",
    padding: "8px 40px 8px 16px",
  },
  body: {
    fontSize: 14,
    textTransform: "capitalize",
    borderBottom: "none",
  },
}))(TableCell);

const categorySchema = Yup.object().shape({
  clubcategory_name: Yup.string().trim().required("This Field is Required"),
});

export class ClubPlanAdminplan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
    };
  }

  componentDidMount() {
    this.props.getClubCategory();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.all_club_category !== prevState.all_club_category) {
      return { all_club_category: nextProps.all_club_category };
      // eslint-disable-next-line no-else-return
    } else return [];
  }

  handleClickOpen = () => {
    this.setState({
      modalOpen: true,
    });
    this.props.getClubCategory();
  };

  handleClose = () => {
    this.setState({
      modalOpen: false,
    });
    this.props.getClubCategory();
  };

  render() {
    const { classes } = this.props;
    const { loading } = this.state;

    if (loading) {
      return <CircularLoader />;
    }
    // eslint-disable-next-line no-unused-vars
    const id = localStorage.getItem("space_id");

    return (
      <div className={classes.main}>
        <Button
          color="grey"
          onClick={() => this.props.history.push("/settings")}
          className={classes.backButton}
          variant="contained"
          size="small"
        >
          Back
        </Button>
        <div className={classes.header}>
          <div className={classes.ScreenHeader}>
            <ScreenHeader type="Club Category" />
          </div>
          <Dialog
            open={this.state.modalOpen}
            onClose={this.handleClose}
            aria-labelledby="club-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <Formik
              initialValues={{
                clubcategory_name: "",
              }}
              validationSchema={categorySchema}
              // eslint-disable-next-line no-unused-vars
              onSubmit={(values, { props, setErrors, setSubmitting }) => {
                // eslint-disable-next-line no-param-reassign
                values.space = localStorage.getItem("space_id");
                this.props.addClubCategory(
                  values,
                  setErrors,
                  this.props.history,
                  setSubmitting
                );
                this.props.getClubCategory();
                this.handleClose();
              }}
            >
              {() => (
                <Form className={classes.category_form_modal}>
                  <DialogTitle
                    id="club-dialog-title"
                    class={classes.dialogTitle}
                  >
                    Add Club Category
                  </DialogTitle>
                  <DialogContent className={classes.popup}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <InputField
                          name="clubcategory_name"
                          label="Category"
                          placeholder="Enter Category Name"
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={this.handleClose}
                      className={classes.close}
                      color="primary"
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      type="submit"
                      className={classes.add_category_button}
                      style={{ marginTop: "0px" }}
                    >
                      Add
                    </Button>
                  </DialogActions>
                  {/* {loading && <CircularLoader />} */}
                </Form>
              )}
            </Formik>
          </Dialog>
          {checkPermission("CLUBCATEGORY_CREATE") && (
            <div className={classes.add_plan_button}>
              <Button
                onClick={this.handleClickOpen}
                variant="contained"
                className={classes.add_category_button}
              >
                Add Club Category
              </Button>
            </div>
          )}
        </div>
        {this.props.all_club_category === undefined ||
        this.props.all_club_category === "No data found" ? (
          <NoRecordsFound text="No records found" />
        ) : (
          <div className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Sr. No.</StyledTableCell>
                  <StyledTableCell>Category</StyledTableCell>
                  {/* <StyledTableCell align="center" /> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.all_club_category &&
                  // eslint-disable-next-line no-shadow
                  this.state &&
                  this.props.all_club_category &&
                  // eslint-disable-next-line no-shadow
                  this.props.all_club_category.map((item, id) => {
                    return (
                      // eslint-disable-next-line no-param-reassign
                      <TableRow key={id++} className={classes.root1}>
                        <StyledTableCell component="th" scope="row">
                          {id}
                        </StyledTableCell>
                        <StyledTableCell>
                          {item.clubcategory_name}
                        </StyledTableCell>

                        {/* <StyledTableCell align="right">
                       <Button
                         variant="outlined"
                         size="small"
                         className={classes.editbutton}
                       >
                         <CreateIcon
                           size="small"
                           className={classes.editicon}
                         />{" "}
                         Edit
                       </Button>
                     </StyledTableCell> */}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    all_club_category: state.club_categories.club_categories,
    loading: state.club_categories.loading,
    createCategory: state.club_categories.club_category,
  };
};

export default connect(mapStateToProps, { addClubCategory, getClubCategory })(
  withStyles(styles)(ClubPlanAdminplan)
);
