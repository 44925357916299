import React from "react";
import { Paper, Typography, Card } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import PlaceOrder from "../../../Screens/Cafeteria/PlaceOrder";
import { updateOrderStatus } from "../../../Actions/OrderActions";
import { getallFoodItem } from "../../../Actions/foodItemActions";

const styles = (theme) => {
  return {
    cardGrid: {
      padding: `${theme.spacing(8)}px 0`,
    },
    cardHeight: {
      height: "100px",
    },
    Cardheight: {
      height: "140px",
    },
    cardItem: {
      width: "175px",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      boxShadow: "0 9px 10px rgba(0, 0, 0, 0.1), 0 4px 0px rgba(0,0,0,0.05)",
      transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
      // backgroundColor: "#FCFDFE",
      position: "relative",
      "&:hover": {
        transform: "scale(1.01)",
        boxShadow:
          "rgba(34, 36, 38, 0.08) 0px 2px 4px 6px, rgba(34, 36, 38, 0.15) 0px 2px 10px 0px",
      },
    },
    foodDetail: {
      padding: "4px 15px",
    },
    foodName: {
      fontWeight: 400,
      fontSize: "18px",
      textAlign: "center",
      color: "#222222",
      lineHeight: "22px",
      textTransform: "capitalize",
    },
    foodSection: {
      display: "flex",
      justifyContent: "center",
      minHeight: "60px",
      padding: "5px 0px",
      alignItems: "center",
      flexGrow: 1,
    },
    cardMiddle: {
      justifyContent: "center",
    },
    settingIcon: {
      display: "flex",
      justifyContent: "flex-end",
    },
  };
};

class CafeteriaItemCard extends React.Component {
  constructor(props) {
    super(props);
    const statusView = false;
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      statusView,
    };
  }

  orderStatus = () => {
    const id = this.props.data._id;
    let values = "";
    if (this.props.foodstatus === "available") {
      values = "unavailable";
    } else {
      values = "available";
    }
    const apiData = { status: values };
    this.props.updateOrderStatus(id, apiData);
    this.props.getallFoodItem();
  };

  render() {
    const { classes, data } = this.props;
    // eslint-disable-next-line no-unused-vars
    let statusView = false;

    if (this.props.match.path === "/food-items") {
      statusView = true;
    }
    return (
      //   <Card className={classes.card}>
      <Card
        // className={classes.cardItem}
        className={classNames(
          classes.cardItem,
          statusView ? classes.cardHeight : classes.Cardheight
        )}
      >
        <Paper className={classes.containerPaper}>
          {/* <div className={classes.profileImage}>
            <div className={classes.cardimage}>
              <img
                className={classes.foodImage}
                src={data.food_photo}
                alt="Food"
              />
              {this.props.match.path === "/food-items" && (
                <div className={classes.settingIcon}>
                  <Settings
                    isEdit
                    message="Are you sure want to delete this club?"
                    editMethod={() => this.props.openEditModal(data)}
                  />
                </div>
              )}
            </div>
          </div> */}
          <div className={classes.foodDetail}>
            <div className={classes.foodSection} direction="row">
              <Typography
                component="h2"
                align="left"
                className={classes.foodName}
              >
                {data.food_name}
              </Typography>
            </div>
            <div className={classes.cardMiddle}>
              {!statusView && (
                <PlaceOrder
                  data={this.props.data}
                  addOrder={this.props.addOrder}
                />
              )}
            </div>
          </div>
        </Paper>
        {/* {checkPermission("ORDER_CAFE_STATUS") && isOrderView && (
          <div className={classes.cardFooter}>
            {foodstatus === "available" && (
              <Button
                size="small"
                variant="outlined"
                color="primary"
                className={classes.moveButton}
                onClick={this.orderStatus}
              >
                Move to Unavailable
              </Button>
            )}
            {foodstatus === "unavailable" && (
              <Button
                size="small"
                variant="outlined"
                color="primary"
                className={classes.moveButton}
                onClick={this.orderStatus}
              >
                Move to Available
              </Button>
            )}
          </div>
        )} */}
      </Card>
    );
  }
}
CafeteriaItemCard.propTypes = {};
const mapStateToProps = () => {
  return {};
};
export default connect(mapStateToProps, { updateOrderStatus, getallFoodItem })(
  withStyles(styles)(withRouter(CafeteriaItemCard))
);
