/* eslint-disable camelcase */
import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { getRoles } from "../../Actions/rolesActions";
import { CircularLoader } from "../../Components/Common/Spinner";

import ScreenHeader from "../../Components/Common/ScreenHeader";

const styles = () => ({
  main: {
    padding: "24px",
  },
  table: {
    marginTop: "20px",
  },
  label: {
    margin: "15px 0px 8px 0px",
    fontSize: "12px",
    fontWeight: "700",
  },
  addPlanButton: {
    width: "100%",
    textAlign: "right",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  rolebutton: {
    color: "#fff",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  ScreenHeader: {
    marginLeft: "-20px",
    width: "100%",
  },
  root1: {
    "&:nth-child(even)": {
      backgroundColor: "#FCFDFE",
    },
  },
  deleteButton: {
    color: "#E3654A",
  },
  editButton: {
    color: "#50ADD5",
  },
  backButton: {
    margin: "0% 0% 2% 0%",
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F8FAFF",
    color: theme.palette.common.black,
    borderBottom: "none",
    padding: "8px 40px 8px 16px",
  },
  body: {
    fontSize: 14,
    textTransform: "capitalize",
    borderBottom: "none",
  },
}))(TableCell);

export class ViewRole extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.getRoles();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.roles !== prevState.roles) {
      return { roles: nextProps.roles };
    }
    return [];
  }

  componentDidUpdate(prevProps) {
    if (prevProps.roles !== this.props.roles) {
      let roles = this.props.roles || [];
      roles = roles.map((x, id) => {
        return Object.assign({}, x, {
          idx: id + 1,
        });
      });
      this.setState({
        roles,
        // rowData: roles
      });
    }
    if (prevProps.loading !== this.props.loading) {
      this.props.setLoading(this.props.loading);
      this.setState({
        loading: this.props.loading,
      });
    }
  }

  render() {
    const { classes } = this.props;
    const { loading, roles } = this.state;

    if (loading) {
      return <CircularLoader />;
    }

    return (
      <div>
        <div className={classes.main}>
          <Button
            color="grey"
            onClick={() => this.props.history.push("/settings")}
            className={classes.backButton}
            variant="contained"
            size="small"
          >
            Back
          </Button>
          <div className={classes.header}>
            <div className={classes.ScreenHeader}>
              <ScreenHeader type="Manage Roles" />
            </div>

            <Grid
              container
              direction="row"
              justify="flex-end"
              spacing={2}
              className={classes.addPlanButton}
            >
              <Grid item>
                <Button
                  onClick={() => {
                    this.props.history.push("/role/add");
                  }}
                  variant="contained"
                  color="primary"
                  className={classes.rolebutton}
                >
                  Add Role
                </Button>
              </Grid>
            </Grid>
          </div>
          <div className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Sr. No.</StyledTableCell>
                  <StyledTableCell align="left">Roles</StyledTableCell>
                  <StyledTableCell align="right">Actions</StyledTableCell>
                  {/* <StyledTableCell align="left">Delete</StyledTableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {roles &&
                  // eslint-disable-next-line no-shadow
                  roles.map((item, id) => {
                    return (
                      <TableRow className={classes.root1}>
                        <StyledTableCell component="th" scope="row">
                          {id + 1}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {item.name}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <IconButton
                            aria-label="Edit"
                            className={classes.editButton}
                            onClick={() => {
                              this.props.history.push(`/role/edit/${item._id}`);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                          {/* <span style={{ color: "#c0c0c0" }}>|</span>
                          <IconButton
                            aria-label="delete"
                            className={classes.deleteButton}
                          >
                            <DeleteIcon />
                          </IconButton> */}
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    roles: state.roles.roles,
    loading: state.roles.loading,
  };
};

export default connect(mapStateToProps, { getRoles })(
  withStyles(styles)(ViewRole)
);
