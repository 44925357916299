import React, { Component } from 'react';


// use is as <GroupSvg/>
// default  width='249.761' height='283.688' 
// eslint-disable-next-line react/prefer-stateless-function
class GroupSvg extends Component {


    render() {
        return (
            <svg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%'
            viewBox='0 0 250 300'>
                <g id='Group_881' data-name='Group 881' transform='translate(-221.415 -97.156)'>
                    <g id='Group_835' data-name='Group 835' transform='translate(327.295 97.156)'>
                        <g id='Group_832' data-name='Group 832' transform='translate(0 5.299)'>
                            <path id='Path_2821' data-name='Path 2821' d='M377.346,115.765a.627.627,0,0,1-.313.231.636.636,0,0,1-.806-.4l-3.365-10.024a.636.636,0,1,1,1.206-.4l3.365,10.024A.635.635,0,0,1,377.346,115.765Z'
                            transform='translate(-372.829 -104.734)' fill='#d8d8d8' />
                        </g>
                        <g id='Group_833' data-name='Group 833' transform='translate(16.933)'>
                            <path id='Path_2822' data-name='Path 2822' d='M398.2,106.653a.636.636,0,0,1-1.152-.374l.057-8.491a.636.636,0,1,1,1.272.008l-.057,8.491A.632.632,0,0,1,398.2,106.653Z'
                            transform='translate(-397.044 -97.156)' fill='#d8d8d8' />
                        </g>
                        <g id='Group_834' data-name='Group 834' transform='translate(30.164 5.032)'>
                            <path id='Path_2823' data-name='Path 2823' d='M425.572,105.357a.582.582,0,0,1-.037.048l-8.456,9.7a.635.635,0,1,1-.957-.836l8.455-9.7a.636.636,0,0,1,1,.788Z'
                            transform='translate(-415.964 -104.352)' fill='#d8d8d8' />
                        </g>
                    </g>
                    <g id='Group_836' data-name='Group 836' transform='translate(221.415 323.08)'>
                        <path id='Path_2824' data-name='Path 2824' d='M305.967,420.937c-39.412.342-133.01,52.791-53.468,56.3s48.97-12.53,89.564-3.509,18.38,1.5,58.8-2,106.243-14.9,46.021-35.516S325.5,420.767,305.967,420.937Z'
                        transform='translate(-221.415 -420.238)' fill='#eef5ff' />
                    </g>
                    <g id='Group_847' data-name='Group 847' transform='translate(269.155 119.217)'>
                        <g id='Group_837' data-name='Group 837'>
                            <path id='Path_2825' data-name='Path 2825' d='M439.3,152.649l-44.227-2.475S394.5,128.7,366.73,128.7c0,0-16.585-.436-20,21.47L294.6,154.1s-15.589,162.076,8.769,208.951c0,0,85.78,20.892,137.895,2.041C441.262,365.093,448.061,164.369,439.3,152.649Zm-68.667-2.038a4.079,4.079,0,1,1,3.9-4.073A3.99,3.99,0,0,1,370.629,150.612Z'
                            transform='translate(-289.685 -128.704)' fill='#ffd892' />
                        </g>
                        <path id='Path_2826' data-name='Path 2826' d='M309.1,180.394H434.8l.873,190.8L313.968,367.9S298.727,193.04,309.1,180.394Z'
                        transform='translate(-294.435 -144.248)' fill='#fff' />
                        <g id='Group_839' data-name='Group 839' transform='translate(62.979 63.333)'>
                            <g id='Group_838' data-name='Group 838' transform='translate(.038 29.561)'>
                                <path id='Path_2827' data-name='Path 2827' d='M380.191,263.076l-.073.012a.426.426,0,0,0-.31.508.412.412,0,0,0,.444.381h0l.073-.012c9.154-1.517,18.446-1.129,26.647-.787,7.814.326,14.56.608,19.627-.745a.441.441,0,0,0,.273-.538.4.4,0,0,0-.469-.338c-4.965,1.325-11.67,1.047-19.43.723C398.741,261.938,389.411,261.548,380.191,263.076Z'
                                transform='translate(-379.802 -261.546)' fill='#bbddea' />
                            </g>
                            <path id='Path_2828' data-name='Path 2828' d='M423.741,252.266a18.941,18.941,0,0,0-3.528-.046c-1.28.017-2.554-.536-3.823-.346a10.632,10.632,0,0,1-3.925-.3c-1.084-.231-1.672.792-2.706.665-.763-.093-1.875-.41-2.576.087-.762.538-1.326.15-2.2.127-.51-.014-.773.379-1.06.787-.627.889-2.345-.65-3.178-.806a.385.385,0,0,0-.417.387c-.019,1.136-2.5-.285-3.15-.267a.4.4,0,0,0-.315.387c-.007.833-1.762.64-3.132,0a4.008,4.008,0,0,0-.09-.792,2,2,0,0,0-1.859-1.412.39.39,0,0,0-.418.387,2.756,2.756,0,0,0,1.431,2.271l-.028.127a2.632,2.632,0,0,1-.51-.427,3.7,3.7,0,0,0-.891-.756,7.33,7.33,0,0,0-2.012-1.082,1.31,1.31,0,0,0-1.58.452c-.328.577-.16,1.035-.186,1.6-1.71-.731-2.638-3.866-4.684-3.44-1.453.3-.462,2.7.126,3.44.342.43.93-.124.585-.557a3.137,3.137,0,0,1-.566-1.011c-.233-.755.413-1.166.952-.741a11.907,11.907,0,0,1,1.522,1.56,4.613,4.613,0,0,0,2.338,1.75.363.363,0,0,0,.4-.275c.108-.473-.048-2.17.741-1.964a7.778,7.778,0,0,1,2.188,1.155c.651.428,1.048,1.253,1.859,1.206.3-.017.477-.249.576-.563,1.631.78,3.6.873,3.949-.4,1.092.206,3.125,1.578,3.47-.046,1.343.452,2.5,1.8,3.615.192.566-.815,1.682.392,2.379-.027.581-.348.839-.689,1.57-.6.478.058.952.134,1.431.158a1.734,1.734,0,0,0,1.115-.328,1.959,1.959,0,0,1,1.69-.259,9.083,9.083,0,0,0,3.3.259c1.287-.192,2.476.313,3.754.3a20.473,20.473,0,0,1,3.772.041C424.164,253.241,424.232,252.35,423.741,252.266Zm-30.864.168a2.164,2.164,0,0,1-.62-.678A1.574,1.574,0,0,1,392.877,252.434Z'
                            transform='translate(-380.471 -228.462)' fill='#bbddea' />
                            <path id='Path_2829' data-name='Path 2829' d='M424.843,237.356c-1.378-.309-2.864-.843-4.231-.352a3.9,3.9,0,0,1-2.394.248,2.527,2.527,0,0,0-2.032.518c-.186.139-.339.331-.592.3-.87-.108-2.433-.884-2.734.422-.157.68-.936.572-1.376.484a1.124,1.124,0,0,0-1.34.648.656.656,0,0,1-.586.264,4.247,4.247,0,0,1-3.134-1.9c-.965.33-2.982-.16-4.168-.584a2.508,2.508,0,0,1-1.523,1,1.234,1.234,0,0,1-.942-.2,1.961,1.961,0,0,1-.669.12,13.027,13.027,0,0,1-1.491-.173c-.483-.05-.859-.476-1.288.018a1.93,1.93,0,0,1-1.083.608c-1.241.336-2.869-.489-3.959-1.248-.77.993-3.205-.148-4.194-.266-.6-.072-1.01.034-1.464-.483-.16-.178-.316-.359-.47-.543q-.676-1.027-1.487.1a2.519,2.519,0,0,1,.006,1.025c-.146.452-.652.473-1,.462a3.929,3.929,0,0,1-1.107-.632c-.077-.049-.7-.156-.641-.063.285.482-.3,1.042-.584.557-.5-.852-.176-1.493.686-1.506a2.093,2.093,0,0,1,1.255.466c.815.544.574-.75.6-1.1a1.1,1.1,0,0,1,.335-.634c1.324-1.263,2.473,1.35,3.6,1.433.643.048,3.164,1.146,3.772.457-.262-.446-.26-.924.424-1.117.206-.059.4.158.445.382a3.223,3.223,0,0,1,.058.581c.058.047.113.1.172.138a4.616,4.616,0,0,0,.766.464c1.125.558,2.582,1.117,3.54.014.534-.616,1.35-.042,1.942.02a7.922,7.922,0,0,0,1.228.113c-.077-.363.091-.724.678-.9.172-.052.333.1.413.266a1.237,1.237,0,0,1,.158.738.822.822,0,0,0,.482-.078,4.231,4.231,0,0,0,.579-.347v0c-.034-.02-.064-.038-.088-.055-.377-.268-.033-1.027.352-.775.031.02.058.04.085.06l0,0a5.506,5.506,0,0,0,2.727.72,10.009,10.009,0,0,0,1.584.164c-.006-.045-.018-.09-.024-.136-.052-.492.54-.725.725-.241a1.389,1.389,0,0,1,.1.459c.323,1.092,1.542,1.332,2.38,1.654.315.121.276-.293.437-.436a1.824,1.824,0,0,1,1.026-.5,3.274,3.274,0,0,1,1.056.157c.292.059.419-.709.535-.9.346-.564.867-.522,1.4-.415.871.176,1.456.44,2.259-.037a3.59,3.59,0,0,1,2.634-.368c.987.164,1.792-.418,2.757-.518a11.255,11.255,0,0,1,3.47.555C425.4,236.574,425.335,237.465,424.843,237.356Z'
                            transform='translate(-379.862 -223.875)' fill='#bbddea' />
                            <path id='Path_2830' data-name='Path 2830' d='M424.708,222.99c-2.634.373-5.338-.2-7.961.19a10.323,10.323,0,0,1-2.378-.155,2.108,2.108,0,0,0-.974.035,2.265,2.265,0,0,1-1.982.315,7.328,7.328,0,0,0-1.121-.353,2,2,0,0,0-1.94.445c-.822.7-1.3-.45-2.127-.533-.534-.056-.792.22-1.086.688-.459.731-2.214-.929-2.76-1.194a.329.329,0,0,0-.38.063c-.824.894-2.068.655-3.128.093a7.269,7.269,0,0,0-.477-1.274c-.262-.548-1.84-3.165-2.347-1.483-.452,1.5.907,2.693,2.176,3.385a2.836,2.836,0,0,1,.048.392c.059,1.107-.892,1.1-1.573.817a5.907,5.907,0,0,1-2.7-2.033c-.288-.419-.8-.009-.638.457.554,1.646-1.117.692-1.669.241a12.5,12.5,0,0,0-1.561-1.178c-1.359-.778-1.019,2.3-1.773,2.073-1.05-.32-2.608-2.264-3.766-1.484-1.475.994-2.829.117-4.331-.5-.471-.194-.738.636-.265.831a13.9,13.9,0,0,0,2.668,1.027,3.572,3.572,0,0,0,2.259-.534c1.45-.6,3.143,2.882,4.556,1.075.225-.289.062-.954.182-1.285.224-.629,1.37.528,1.591.692a4.639,4.639,0,0,0,1.7.891c.823.171,1.208-.2,1.294-.8,1.091,1.052,2.69,1.994,3.829,1.5a1.708,1.708,0,0,0,.963-1.8c.141.057.272.1.393.145.759.251,1.989.557,2.649-.1.578-.58,1.7.957,2.473,1.027a1.493,1.493,0,0,0,.952-.171c.385-.216.506-.8,1.057-.456.748.471,1.236.869,2.022.354a2.131,2.131,0,0,1,2.243-.3,4.856,4.856,0,0,0,1.445.348,2.192,2.192,0,0,0,1.085-.36c.768-.4,1.739,0,2.538.129.664.107,1.412-.269,2.094-.215.785.062,1.569-.008,2.355.011a27.184,27.184,0,0,0,4.5-.126C425.332,223.811,425.2,222.921,424.708,222.99Zm-26.924-1.131a3.716,3.716,0,0,1-.946-.953q.135-.665.3-.169A4.654,4.654,0,0,1,397.784,221.859Z'
                            transform='translate(-379.748 -219.273)' fill='#bbddea' />
                        </g>
                        <g id='Group_841' data-name='Group 841' transform='translate(62.998 114.421)'>
                            <g id='Group_840' data-name='Group 840' transform='translate(.037 29.562)'>
                                <path id='Path_2831' data-name='Path 2831' d='M380.217,336.135l-.074.013a.427.427,0,0,0-.309.508.413.413,0,0,0,.444.382h0l.073-.011c9.153-1.517,18.446-1.129,26.648-.787,7.813.326,14.559.607,19.625-.745a.44.44,0,0,0,.273-.537.4.4,0,0,0-.469-.338c-4.966,1.325-11.672,1.046-19.432.723C398.767,335,389.437,334.608,380.217,336.135Z'
                                transform='translate(-379.828 -334.606)' fill='#bbddea' />
                            </g>
                            <path id='Path_2832' data-name='Path 2832' d='M423.767,325.325a18.985,18.985,0,0,0-3.526-.046c-1.282.017-2.555-.536-3.824-.345a10.668,10.668,0,0,1-3.926-.3c-1.082-.232-1.671.792-2.7.664-.762-.092-1.874-.41-2.575.087-.762.539-1.327.15-2.2.128-.509-.013-.772.379-1.058.787-.628.89-2.347-.651-3.179-.806a.387.387,0,0,0-.419.387c-.017,1.136-2.5-.285-3.148-.268a.4.4,0,0,0-.315.387c-.006.833-1.761.64-3.132,0a4.065,4.065,0,0,0-.09-.792,2.005,2.005,0,0,0-1.859-1.412.391.391,0,0,0-.418.389,2.758,2.758,0,0,0,1.431,2.271c-.008.042-.018.085-.029.126a2.658,2.658,0,0,1-.509-.427,3.683,3.683,0,0,0-.892-.755,7.347,7.347,0,0,0-2.011-1.083,1.311,1.311,0,0,0-1.58.452c-.328.576-.16,1.034-.186,1.6-1.71-.731-2.637-3.867-4.685-3.44-1.453.3-.462,2.7.127,3.441.341.43.93-.124.585-.557a3.135,3.135,0,0,1-.566-1.01c-.234-.755.414-1.166.953-.741a11.767,11.767,0,0,1,1.521,1.559,4.618,4.618,0,0,0,2.34,1.75.363.363,0,0,0,.4-.276c.108-.473-.048-2.17.741-1.964a7.774,7.774,0,0,1,2.189,1.155c.65.429,1.048,1.253,1.859,1.206.3-.017.477-.248.576-.562,1.631.78,3.6.872,3.95-.4,1.09.206,3.124,1.578,3.468-.045,1.343.451,2.5,1.8,3.616.192.565-.815,1.681.392,2.38-.026.58-.348.837-.689,1.568-.6.478.058.952.134,1.432.158a1.729,1.729,0,0,0,1.114-.327,1.957,1.957,0,0,1,1.69-.259,9.081,9.081,0,0,0,3.3.259c1.287-.193,2.477.313,3.756.294a20.528,20.528,0,0,1,3.771.041C424.189,326.3,424.259,325.409,423.767,325.325Zm-30.863.169a2.172,2.172,0,0,1-.622-.679A1.574,1.574,0,0,1,392.9,325.494Z'
                            transform='translate(-380.497 -301.521)' fill='#bbddea' />
                            <path id='Path_2833' data-name='Path 2833' d='M424.87,310.415c-1.379-.309-2.865-.843-4.231-.351a3.9,3.9,0,0,1-2.394.248,2.526,2.526,0,0,0-2.032.519c-.187.139-.339.331-.592.3-.869-.107-2.432-.883-2.733.422-.159.68-.937.571-1.378.483a1.123,1.123,0,0,0-1.34.649.658.658,0,0,1-.585.263,4.25,4.25,0,0,1-3.135-1.9c-.965.33-2.983-.159-4.168-.584a2.505,2.505,0,0,1-1.524,1.006,1.24,1.24,0,0,1-.941-.2,1.946,1.946,0,0,1-.668.119,13.122,13.122,0,0,1-1.491-.172c-.484-.05-.86-.475-1.289.018a1.925,1.925,0,0,1-1.082.608c-1.24.336-2.869-.489-3.959-1.247-.771.993-3.206-.148-4.194-.266-.6-.073-1.01.034-1.464-.484-.159-.177-.317-.359-.47-.543q-.676-1.026-1.487.1a2.5,2.5,0,0,1,.006,1.024c-.145.453-.652.473-1,.462a3.91,3.91,0,0,1-1.107-.632c-.077-.048-.7-.155-.641-.063.285.483-.3,1.043-.584.557-.5-.852-.176-1.492.686-1.506a2.1,2.1,0,0,1,1.255.466c.815.544.573-.749.6-1.1a1.1,1.1,0,0,1,.336-.634c1.322-1.263,2.472,1.349,3.6,1.433.643.047,3.164,1.146,3.773.457-.261-.448-.26-.924.423-1.119.206-.059.406.159.445.383a3.278,3.278,0,0,1,.058.581c.058.045.113.1.171.138a4.657,4.657,0,0,0,.766.464c1.125.559,2.582,1.118,3.54.015.534-.616,1.351-.042,1.943.02a7.9,7.9,0,0,0,1.229.113c-.078-.362.09-.724.676-.9.173-.052.334.1.415.266a1.239,1.239,0,0,1,.157.738.814.814,0,0,0,.483-.078,4.247,4.247,0,0,0,.579-.348v0a.764.764,0,0,1-.088-.055c-.377-.268-.034-1.027.351-.774.031.02.058.04.085.06v0a5.527,5.527,0,0,0,2.728.72,10,10,0,0,0,1.583.164c-.006-.045-.017-.089-.022-.135-.052-.492.539-.725.725-.241a1.38,1.38,0,0,1,.1.46c.324,1.09,1.542,1.331,2.381,1.653.315.122.275-.293.437-.436a1.829,1.829,0,0,1,1.025-.5,3.27,3.27,0,0,1,1.055.158c.292.059.419-.71.535-.9.346-.565.867-.522,1.4-.415.872.176,1.457.44,2.259-.038a3.6,3.6,0,0,1,2.636-.368c.987.164,1.791-.417,2.757-.517a11.278,11.278,0,0,1,3.47.554C425.428,309.634,425.361,310.525,424.87,310.415Z'
                            transform='translate(-379.888 -296.934)' fill='#bbddea' />
                            <path id='Path_2834' data-name='Path 2834' d='M424.733,296.049c-2.632.373-5.337-.2-7.958.19a10.336,10.336,0,0,1-2.379-.155,2.105,2.105,0,0,0-.973.035,2.263,2.263,0,0,1-1.982.315,7.335,7.335,0,0,0-1.122-.352,2,2,0,0,0-1.938.445c-.824.7-1.306-.449-2.127-.533-.535-.056-.794.22-1.087.688-.458.732-2.215-.929-2.759-1.194a.333.333,0,0,0-.383.063c-.822.894-2.066.655-3.127.093a7.407,7.407,0,0,0-.476-1.273c-.262-.549-1.84-3.166-2.348-1.484-.452,1.5.906,2.693,2.175,3.385a2.739,2.739,0,0,1,.048.392c.059,1.106-.893,1.1-1.573.817a5.911,5.911,0,0,1-2.7-2.033c-.289-.42-.8-.01-.638.457.554,1.646-1.115.692-1.669.241a12.5,12.5,0,0,0-1.562-1.178c-1.359-.778-1.018,2.3-1.773,2.073-1.05-.32-2.608-2.263-3.766-1.483-1.476.994-2.829.117-4.33-.5-.471-.194-.738.637-.266.831a13.929,13.929,0,0,0,2.668,1.027,3.565,3.565,0,0,0,2.259-.534c1.45-.6,3.142,2.883,4.557,1.075.224-.289.063-.953.181-1.285.225-.629,1.371.528,1.592.692a4.618,4.618,0,0,0,1.7.891c.822.171,1.208-.2,1.294-.8,1.092,1.052,2.69,2,3.83,1.5a1.709,1.709,0,0,0,.963-1.8q.209.084.392.144c.758.251,1.989.557,2.648-.1.579-.58,1.7.957,2.473,1.027a1.5,1.5,0,0,0,.952-.171c.384-.216.506-.8,1.057-.456.75.471,1.236.868,2.022.354a2.13,2.13,0,0,1,2.243-.3,4.866,4.866,0,0,0,1.445.348,2.192,2.192,0,0,0,1.085-.36c.769-.4,1.74,0,2.538.129.664.106,1.412-.27,2.094-.216.785.062,1.569-.006,2.354.012a27.265,27.265,0,0,0,4.5-.125C425.359,296.87,425.222,295.981,424.733,296.049Zm-26.922-1.131a3.716,3.716,0,0,1-.946-.953q.134-.665.3-.169A4.666,4.666,0,0,1,397.811,294.918Z'
                            transform='translate(-379.774 -292.332)' fill='#bbddea' />
                        </g>
                        <g id='Group_843' data-name='Group 843' transform='translate(62.262 165.051)'>
                            <g id='Group_842' data-name='Group 842' transform='translate(.037 29.561)'>
                                <path id='Path_2835' data-name='Path 2835' d='M379.166,408.539l-.073.012a.428.428,0,0,0-.312.508.414.414,0,0,0,.445.383h0l.073-.013c9.154-1.516,18.445-1.129,26.647-.787,7.815.326,14.56.607,19.627-.744a.442.442,0,0,0,.273-.538.4.4,0,0,0-.469-.338c-4.965,1.325-11.67,1.047-19.431.723C397.715,407.4,388.387,407.011,379.166,408.539Z'
                                transform='translate(-378.775 -407.009)' fill='#bbddea' />
                            </g>
                            <path id='Path_2836' data-name='Path 2836' d='M422.716,397.728a19.01,19.01,0,0,0-3.529-.046c-1.28.018-2.554-.535-3.823-.345a10.644,10.644,0,0,1-3.925-.3c-1.084-.231-1.672.792-2.706.665-.762-.093-1.875-.41-2.576.087-.761.538-1.327.15-2.2.127-.51-.013-.771.379-1.059.787-.627.89-2.345-.65-3.178-.8a.388.388,0,0,0-.419.388c-.018,1.136-2.5-.286-3.148-.269a.4.4,0,0,0-.315.387c-.006.833-1.761.641-3.132,0a4.08,4.08,0,0,0-.09-.792,2,2,0,0,0-1.859-1.411.389.389,0,0,0-.417.387,2.754,2.754,0,0,0,1.431,2.271c-.009.043-.019.085-.028.128a2.683,2.683,0,0,1-.511-.428,3.655,3.655,0,0,0-.891-.755,7.325,7.325,0,0,0-2.011-1.082,1.308,1.308,0,0,0-1.579.452c-.329.576-.161,1.034-.187,1.6-1.71-.731-2.637-3.866-4.686-3.44-1.452.3-.461,2.7.128,3.441.341.43.929-.124.585-.557a3.116,3.116,0,0,1-.566-1.01c-.234-.754.413-1.167.953-.741a11.729,11.729,0,0,1,1.52,1.56,4.615,4.615,0,0,0,2.34,1.75.362.362,0,0,0,.4-.275c.109-.473-.048-2.169.741-1.964a7.747,7.747,0,0,1,2.189,1.156c.652.427,1.048,1.254,1.859,1.206.3-.018.477-.248.576-.562,1.632.78,3.6.873,3.949-.4,1.092.2,3.125,1.577,3.47-.046,1.343.451,2.5,1.8,3.616.192.564-.815,1.68.392,2.379-.026.58-.348.838-.689,1.568-.6.479.058.952.134,1.431.159a1.745,1.745,0,0,0,1.117-.327,1.952,1.952,0,0,1,1.689-.259,9.106,9.106,0,0,0,3.3.259c1.287-.194,2.478.312,3.757.294a20.47,20.47,0,0,1,3.771.041C423.139,398.7,423.207,397.812,422.716,397.728Zm-30.865.169a2.162,2.162,0,0,1-.621-.679A1.573,1.573,0,0,1,391.852,397.9Z'
                            transform='translate(-379.445 -373.924)' fill='#bbddea' />
                            <path id='Path_2837' data-name='Path 2837' d='M423.819,382.819c-1.38-.309-2.866-.843-4.232-.352a3.9,3.9,0,0,1-2.394.248,2.521,2.521,0,0,0-2.031.518c-.187.139-.34.331-.592.3-.869-.108-2.433-.884-2.734.422-.157.68-.936.572-1.376.484a1.123,1.123,0,0,0-1.341.648.655.655,0,0,1-.585.264,4.248,4.248,0,0,1-3.136-1.9c-.964.331-2.982-.159-4.167-.584a2.5,2.5,0,0,1-1.524,1.006,1.232,1.232,0,0,1-.941-.2,1.962,1.962,0,0,1-.669.12,13.179,13.179,0,0,1-1.492-.173c-.483-.05-.859-.476-1.288.018a1.933,1.933,0,0,1-1.084.608c-1.238.336-2.868-.489-3.957-1.247-.77.993-3.206-.148-4.194-.267-.6-.071-1.012.034-1.466-.483-.159-.178-.315-.359-.47-.543q-.673-1.026-1.486.1a2.51,2.51,0,0,1,.006,1.026c-.145.452-.65.473-1,.462a3.933,3.933,0,0,1-1.106-.631c-.078-.049-.7-.156-.641-.063.284.482-.3,1.042-.585.557-.5-.852-.176-1.493.687-1.506a2.094,2.094,0,0,1,1.254.466c.815.545.573-.75.6-1.1a1.1,1.1,0,0,1,.336-.634c1.323-1.263,2.473,1.349,3.6,1.433.643.047,3.164,1.146,3.771.457-.26-.447-.258-.924.424-1.118.206-.059.4.159.445.383a3.217,3.217,0,0,1,.058.581c.057.047.113.1.171.138a4.576,4.576,0,0,0,.766.464c1.124.558,2.581,1.117,3.539.014.535-.616,1.351-.043,1.943.02a7.872,7.872,0,0,0,1.229.113c-.078-.362.089-.724.676-.9.173-.052.334.1.415.266a1.236,1.236,0,0,1,.157.738.818.818,0,0,0,.483-.078,4.33,4.33,0,0,0,.578-.348v0a.812.812,0,0,1-.087-.055c-.378-.267-.034-1.025.35-.774.032.02.059.04.087.06v0a5.515,5.515,0,0,0,2.729.721,10.065,10.065,0,0,0,1.582.164c-.006-.046-.017-.089-.022-.136-.052-.492.539-.726.725-.241a1.405,1.405,0,0,1,.1.459c.323,1.091,1.54,1.331,2.379,1.654.316.122.276-.293.438-.436a1.821,1.821,0,0,1,1.024-.5,3.273,3.273,0,0,1,1.056.158c.291.059.418-.71.535-.9.345-.564.867-.52,1.4-.414.872.176,1.457.439,2.259-.038a3.594,3.594,0,0,1,2.633-.368c.987.164,1.792-.417,2.758-.517a11.279,11.279,0,0,1,3.47.555C424.378,382.036,424.31,382.928,423.819,382.819Z'
                            transform='translate(-378.837 -369.337)' fill='#bbddea' />
                            <path id='Path_2838' data-name='Path 2838' d='M423.683,368.453c-2.633.372-5.338-.2-7.961.19a10.407,10.407,0,0,1-2.378-.155,2.1,2.1,0,0,0-.975.035,2.26,2.26,0,0,1-1.981.315,7.328,7.328,0,0,0-1.123-.352,2,2,0,0,0-1.939.445c-.823.7-1.306-.45-2.126-.534-.536-.055-.793.22-1.087.688-.459.731-2.214-.929-2.759-1.194a.336.336,0,0,0-.382.064c-.822.9-2.066.656-3.127.093a7.45,7.45,0,0,0-.476-1.273c-.262-.548-1.84-3.166-2.347-1.483-.452,1.5.906,2.692,2.175,3.385a2.944,2.944,0,0,1,.048.391c.059,1.107-.892,1.1-1.572.817a5.909,5.909,0,0,1-2.7-2.033c-.289-.42-.8-.01-.639.457.555,1.647-1.115.692-1.669.241a12.155,12.155,0,0,0-1.562-1.178c-1.359-.779-1.019,2.3-1.773,2.073-1.05-.32-2.607-2.263-3.766-1.483-1.476.994-2.829.117-4.33-.5-.472-.194-.738.637-.264.832a13.818,13.818,0,0,0,2.667,1.026,3.562,3.562,0,0,0,2.258-.532c1.451-.6,3.143,2.881,4.559,1.073.224-.288.063-.953.18-1.285.225-.629,1.371.528,1.592.692a4.636,4.636,0,0,0,1.694.891c.823.171,1.208-.2,1.294-.8,1.091,1.053,2.69,2,3.829,1.5a1.709,1.709,0,0,0,.962-1.8c.14.057.272.106.393.145.758.252,1.989.557,2.649-.1.58-.58,1.7.957,2.474,1.027a1.5,1.5,0,0,0,.952-.171c.385-.217.506-.8,1.056-.457.75.471,1.237.868,2.023.354a2.13,2.13,0,0,1,2.243-.3,4.88,4.88,0,0,0,1.445.347,2.181,2.181,0,0,0,1.085-.359c.769-.4,1.74,0,2.537.129.665.107,1.413-.269,2.1-.215.785.062,1.568-.008,2.354.01a27.258,27.258,0,0,0,4.5-.124C424.307,369.273,424.171,368.384,423.683,368.453Zm-26.924-1.131a3.775,3.775,0,0,1-.947-.953q.135-.665.3-.169A4.629,4.629,0,0,1,396.759,367.322Z'
                            transform='translate(-378.723 -364.735)' fill='#bbddea' />
                        </g>
                        <g id='Group_844' data-name='Group 844' transform='translate(24.115 62.064)'
                        fill='#bbddea'>
                            <path id='Path_2839' data-name='Path 2839' d='M351.546,220.4c-6.66-6.629-23.094,0-23.094,0-2.7,10.077-7.08,28.963-1.8,34.748,5.835,6.39,24.247-.578,24.247-.578S358.207,227.034,351.546,220.4Zm-.8,19.153a126.634,126.634,0,0,1-2.34,12.574c-2.668.877-8.489,2.578-13.481,2.578h0c-2.957,0-5.066-.6-6.1-1.727-1.062-1.162-3.985-6.746,2.1-30.154A39.224,39.224,0,0,1,342.6,220.6c3.178,0,5.489.7,6.877,2.082C350.192,223.392,352.4,226.717,350.745,239.557Z'
                            transform='translate(-324.17 -217.459)' />
                            <path id='Path_2840' data-name='Path 2840' d='M338.668,238.455l5.908,13.9s.522,1.8,1.416-.116,3.725-19.915,2.666-21.742-1.906,0-1.906,0-1.676,14.072-2.724,13.949-3.847-8.863-5.361-9.613S338.668,238.455,338.668,238.455Z'
                            transform='translate(-328.328 -221.135)' />
                        </g>
                        <g id='Group_845' data-name='Group 845' transform='translate(25.947 113.772)'>
                            <path id='Path_2841' data-name='Path 2841' d='M354.167,294.35c-6.661-6.629-23.1,0-23.1,0-2.7,10.077-7.08,28.963-1.8,34.748,5.835,6.39,24.247-.578,24.247-.578S360.827,300.979,354.167,294.35Zm-.8,19.152a126.758,126.758,0,0,1-2.34,12.574c-2.668.876-8.489,2.578-13.48,2.578h0c-2.958,0-5.066-.6-6.1-1.727-1.063-1.162-3.985-6.745,2.1-30.154a39.243,39.243,0,0,1,11.67-2.232c3.178,0,5.489.7,6.877,2.082C352.812,297.337,355.019,300.662,353.365,313.5Z'
                            transform='translate(-326.79 -291.404)' fill='#bbddea' />
                        </g>
                        <g id='Group_846' data-name='Group 846' transform='translate(26.801 164.172)'
                        fill='#bbddea'>
                            <path id='Path_2842' data-name='Path 2842' d='M355.388,366.425c-6.661-6.63-23.094,0-23.094,0-2.7,10.077-7.082,28.963-1.8,34.746,5.835,6.391,24.247-.577,24.247-.577S362.048,373.054,355.388,366.425Zm-.8,19.153a127.029,127.029,0,0,1-2.339,12.573c-2.67.875-8.491,2.578-13.481,2.578h0c-2.959,0-5.066-.6-6.1-1.727-1.063-1.161-3.985-6.746,2.1-30.153a39.224,39.224,0,0,1,11.67-2.233c3.178,0,5.49.7,6.877,2.082C354.033,369.411,356.241,372.736,354.585,385.578Z'
                            transform='translate(-328.011 -363.478)' />
                            <path id='Path_2843' data-name='Path 2843' d='M342.508,384.474l5.909,13.9s.523,1.8,1.416-.116,3.725-19.914,2.666-21.742-1.907,0-1.907,0-1.675,14.072-2.724,13.95-3.847-8.863-5.361-9.614S342.508,384.474,342.508,384.474Z'
                            transform='translate(-332.168 -367.155)' />
                        </g>
                        <path id='Path_2844' data-name='Path 2844' d='M426.137,181.564c0,.694-.862,1.257-1.927,1.257H357.761c-1.064,0-1.927-.562-1.927-1.257v-6.057c0-.694.862-1.256,1.927-1.256H424.21c1.064,0,1.927.562,1.927,1.256Z'
                        transform='translate(-309.578 -142.401)' fill='#f2c275' />
                    </g>
                    <g id='Group_860' data-name='Group 860' transform='translate(230.391 231.909)'>
                        <g id='Group_856' data-name='Group 856'>
                            <g id='Group_848' data-name='Group 848' transform='translate(0 13.096)'>
                                <path id='Path_2845' data-name='Path 2845' d='M247.134,452.178s-5.012.153-6.442-5.777c-2.077-8.62,2.553-13.909,1.214-19.188,0,0-1.123-3.973-3.471-1.795l1.525,3.763s1.368,3.3-.73,9.749-.818,12.074,4.515,14l4.325,1.269Z'
                                transform='translate(-235.46 -343.525)' fill='#d14b6b' />
                                <path id='Path_2846' data-name='Path 2846' d='M237.377,317.237a.673.673,0,0,0,.719.631h0a.686.686,0,0,0,.6-.751l-.662-7.9a.673.673,0,0,0-.719-.631h0a.685.685,0,0,0-.6.752Z'
                                transform='translate(-234.991 -308.587)' fill='#3994b7' />
                                <path id='Path_2847' data-name='Path 2847' d='M258.931,467.458l.606,10.286s-2.594,4.337-6.054.39l-1.254-10.484A8.9,8.9,0,0,0,258.931,467.458Z'
                                transform='translate(-239.657 -356.363)' fill='#f17187' />
                                <path id='Path_2848' data-name='Path 2848' d='M258.643,454.555s2.7,10.388-4.926,9.867c0,0-6.442,1.108-6.993-9.1C246.723,455.323,255.211,457.556,258.643,454.555Z'
                                transform='translate(-238.002 -352.483)' fill='#d14b6b' />
                                <path id='Path_2849' data-name='Path 2849' d='M234.271,326.242l1.191-11.661,2.839-.183,3.691,11.08.012.076C240.337,327.04,237.851,327.882,234.271,326.242Z'
                                transform='translate(-234.257 -310.335)' fill='#2f3033' />
                                <path id='Path_2850' data-name='Path 2850' d='M239.547,383.872l-5.3-52.7.014-.131c3.58,1.64,6.066.8,7.733-.689l7.726,52.033A13.373,13.373,0,0,1,239.547,383.872Z'
                                transform='translate(-234.251 -315.132)' fill='#f17187' />
                                <path id='Path_2851' data-name='Path 2851' d='M252,404.761l5.169,34.82s-2.392,4.522-11.92.768l-3.426-34.1A13.373,13.373,0,0,0,252,404.761Z'
                                transform='translate(-236.528 -337.509)' fill='#f17187' />
                                <path id='Path_2852' data-name='Path 2852' d='M247.119,381.3a.8.8,0,0,0,.9.711l.156-.023a.824.824,0,0,0,.68-.934l-6.062-46.537a.8.8,0,0,0-.894-.71l-.157.022a.823.823,0,0,0-.68.934Z'
                                transform='translate(-236.296 -316.169)' fill='#d14b6b' />
                                <path id='Path_2853' data-name='Path 2853' d='M255.154,440.18a.8.8,0,0,0,.889.716l.367-.05a.825.825,0,0,0,.685-.93l-3.826-30.831a.8.8,0,0,0-.89-.716l-.366.05a.824.824,0,0,0-.685.93Z'
                                transform='translate(-239.385 -338.592)' fill='#d14b6b' />
                                <path id='Path_2854' data-name='Path 2854' d='M239.141,315.869l3.158,9.969s-.43,1.567-1.838.227Z'
                                transform='translate(-235.722 -310.777)' fill='#1c1d1e' />
                            </g>
                            <g id='Group_855' data-name='Group 855' transform='translate(5.39)'>
                                <path id='Path_2855' data-name='Path 2855' d='M245.229,374.638c-3.559-1.948-4.492-5-1.4-6.017,0,0,1.483-.38,2.077,3.009Z'
                                transform='translate(-241.959 -313.54)' fill='#ffbfa4' />
                                <path id='Path_2856' data-name='Path 2856' d='M344.837,301.038c-.93-1.4-.4-4.307.332-5.441,0,0,1.129-4.668,2.125-4.761,0,0-.133,3.5-.863,4.761,0,0,3.278-.441,2.922,1.015,0,0,.067,3.43-1.461,4.426Z'
                                transform='translate(-272.747 -290.153)' fill='#ffbfa4' />
                                <path id='Path_2857' data-name='Path 2857' d='M261.394,475.971s.7,6.919-1.044,6.919c0,0-1.4-3.779-2.443-3.459,0,0-6.28,3.459-8.723,3.459,0,0,3.777-4.766,7.21-6.919Z'
                                transform='translate(-244.132 -345.827)' />
                                <path id='Path_2858' data-name='Path 2858' d='M287.845,476.622s-.7,6.919,1.045,6.919c0,0,1.4-3.778,2.441-3.459,0,0,6.28,3.459,8.723,3.459,0,0-3.777-4.766-7.21-6.919Z'
                                transform='translate(-255.716 -346.023)' />
                                <g id='Group_849' data-name='Group 849' transform='translate(9.691 50.205)'>
                                    <path id='Path_2859' data-name='Path 2859' d='M291.276,361.655c9.13,5.063,5.475,65.031-4.065,79.828,0,0-11.114,5.647-11.713.2s6.465-43.615,6.433-56.269c0,0-7.811,49.855-15.4,56.275,0,0-8.372,3.11-10.709-1.173,0,0,10.125-67.758,22.586-78.856Z'
                                    transform='translate(-255.818 -361.655)' fill='#2f3033' />
                                </g>
                                <path id='Path_2860' data-name='Path 2860' d='M285.316,310.393c8.375-.263,6.757,12.3,18.945,5.994,10.575-5.469,9.269-11.79,9.53-13.532l5.352,1.252s1.048,8.346-7.572,16.592c-13.917,13.311-19.938.257-18.455,10.309,0,0,3.55,18.369-4.667,18.891,0,0-21.658,4.269-18.235-8.186,0,0,.517-8.094,1.159-9.921,0,0-3.089,9.008-7.294,12.532,0,0-7.992,11.227-15.695,11.881,0,0-4.83-1.175-2.61-4.308a347.775,347.775,0,0,0,24.017-27.807Z'
                                transform='translate(-242.931 -293.767)' fill='#7eacf1' />
                                <path id='Path_2861' data-name='Path 2861' d='M311.152,296.75c4.833.487,1.294,15.871-4.4,11.679C306.756,308.428,302.873,295.914,311.152,296.75Z'
                                transform='translate(-261.171 -291.919)' fill='#ffbfa4' />
                                <g id='Group_850' data-name='Group 850' transform='translate(23.396)'>
                                    <path id='Path_2862' data-name='Path 2862' d='M305.734,292.313c2.324,3.382-.971,4.989-4.434,5.127s.278,7.581-3.048,8.155,6.789,16.509-3.048,17.34-6.374-3.187-13.163-1.524-9.283-7.2-3.048-10.946,6.789-4.434,7.621-11.362,5.126-9.837,10.946-9.145S304.21,290.1,305.734,292.313Z'
                                    transform='translate(-275.416 -289.859)' />
                                </g>
                                <g id='Group_854' data-name='Group 854' transform='translate(3.65 11.263)'>
                                    <g id='Group_851' data-name='Group 851' transform='translate(67.645)'>
                                        <path id='Path_2863' data-name='Path 2863' d='M347.95,307.483a.294.294,0,0,1-.131-.031,13.142,13.142,0,0,0-3.636-.892.3.3,0,0,1,.057-.594,13.5,13.5,0,0,1,3.84.951.3.3,0,0,1-.131.566Z'
                                        transform='translate(-343.914 -305.965)' fill='#6898dd' />
                                    </g>
                                    <g id='Group_852' data-name='Group 852' transform='translate(0 46.528)'>
                                        <path id='Path_2864' data-name='Path 2864' d='M249.907,376.108a.3.3,0,0,1-.151-.041,8.124,8.124,0,0,1-2.55-3.14.3.3,0,0,1,.541-.252,7.656,7.656,0,0,0,2.311,2.878.3.3,0,0,1-.151.555Z'
                                        transform='translate(-247.178 -372.503)' fill='#6898dd' />
                                    </g>
                                    <g id='Group_853' data-name='Group 853' transform='translate(24.451 39.392)'>
                                        <path id='Path_2865' data-name='Path 2865' d='M294.026,365.584a38.371,38.371,0,0,1-11.686-2.148.3.3,0,0,1,.2-.56c.138.051,13.848,4.98,20.132-.5a.3.3,0,1,1,.392.448C300.672,364.912,297.329,365.584,294.026,365.584Z'
                                        transform='translate(-282.144 -362.298)' fill='#6898dd' />
                                    </g>
                                </g>
                            </g>
                        </g>
                        <g id='Group_859' data-name='Group 859' transform='translate(16.143 126.603)'>
                            <g id='Group_857' data-name='Group 857' transform='translate(19)'>
                                <path id='Path_2866' data-name='Path 2866' d='M288.139,474.523a8.5,8.5,0,0,1-3.468-.664.3.3,0,0,1,.267-.533c.048.024,4.936,2.366,10.634-2.351a.3.3,0,0,1,.38.459A12.14,12.14,0,0,1,288.139,474.523Z'
                                transform='translate(-284.507 -470.907)' fill='#1c1d1e' />
                            </g>
                            <g id='Group_858' data-name='Group 858' transform='translate(0 .473)'>
                                <path id='Path_2867' data-name='Path 2867' d='M263.413,473.73a12.136,12.136,0,0,1-5.937-1.6.3.3,0,0,1,.315-.506,11.277,11.277,0,0,0,9.155.863.3.3,0,1,1,.211.557A10.51,10.51,0,0,1,263.413,473.73Z'
                                transform='translate(-257.336 -471.583)' fill='#1c1d1e' />
                            </g>
                        </g>
                    </g>
                    <g id='Group_880' data-name='Group 880' transform='translate(365.441 229.787)'>
                        <g id='Group_870' data-name='Group 870' transform='translate(22.277 53.959)'>
                            <g id='Group_868' data-name='Group 868'>
                                <path id='Path_2868' data-name='Path 2868' d='M459.63,476.934c-2.941,2.053,11.5,1.778,12.582-3.162C472.213,473.772,471.5,468.645,459.63,476.934Z'
                                transform='translate(-459.236 -396.533)' />
                                <path id='Path_2869' data-name='Path 2869' d='M482.714,365.621S458.1,440.2,467.984,441.754a13.213,13.213,0,0,0,8.528,0s14.342-49.227,16.474-57.561l-2.585-20.2Z'
                                transform='translate(-461.163 -363.99)' fill='#2f3033' />
                                <g id='Group_867' data-name='Group 867' transform='translate(11.015 11.257)'
                                fill='#2a2678'>
                                    <g id='Group_861' data-name='Group 861' transform='translate(13.082)'>
                                        <path id='Path_2870' data-name='Path 2870' d='M497.062,381.065h-.1l.256-.975h.1Z'
                                        transform='translate(-494.679 -380.088)' />
                                        <path id='Path_2871' data-name='Path 2871' d='M496.518,383.141h-.1l.256-.974h.1Z'
                                        transform='translate(-494.515 -380.713)' />
                                        <path id='Path_2872' data-name='Path 2872' d='M495.973,385.216h-.1l.256-.975h.1Z'
                                        transform='translate(-494.351 -381.337)' />
                                        <path id='Path_2873' data-name='Path 2873' d='M495.428,387.293h-.1l.256-.974h.1Z'
                                        transform='translate(-494.187 -381.962)' />
                                        <path id='Path_2874' data-name='Path 2874' d='M494.884,389.368h-.1l.255-.974h.1Z'
                                        transform='translate(-494.023 -382.586)' />
                                        <path id='Path_2875' data-name='Path 2875' d='M494.341,391.445h-.1l.255-.975h.1Z'
                                        transform='translate(-493.86 -383.21)' />
                                        <path id='Path_2876' data-name='Path 2876' d='M493.8,393.521h-.1l.255-.975h.1Z'
                                        transform='translate(-493.696 -383.835)' />
                                    </g>
                                    <g id='Group_862' data-name='Group 862' transform='translate(10.423 10.163)'>
                                        <path id='Path_2877' data-name='Path 2877' d='M493.252,395.6h-.1l.255-.975h.1Z'
                                        transform='translate(-490.873 -394.622)' />
                                        <path id='Path_2878' data-name='Path 2878' d='M492.709,397.674h-.1l.255-.974h.1Z'
                                        transform='translate(-490.71 -395.247)' />
                                        <path id='Path_2879' data-name='Path 2879' d='M492.166,399.749h-.1l.256-.975h.1Z'
                                        transform='translate(-490.546 -395.871)' />
                                        <path id='Path_2880' data-name='Path 2880' d='M491.624,401.826h-.1l.254-.975h.1Z'
                                        transform='translate(-490.384 -396.496)' />
                                        <path id='Path_2881' data-name='Path 2881' d='M491.081,403.9h-.1l.255-.975h.1Z'
                                        transform='translate(-490.22 -397.12)' />
                                        <path id='Path_2882' data-name='Path 2882' d='M490.538,405.978h-.1l.254-.975h.1Z'
                                        transform='translate(-490.057 -397.745)' />
                                        <path id='Path_2883' data-name='Path 2883' d='M490,408.054l-.1,0,.255-.976h.1Z'
                                        transform='translate(-489.894 -398.369)' />
                                    </g>
                                    <g id='Group_863' data-name='Group 863' transform='translate(7.775 20.326)'>
                                        <path id='Path_2884' data-name='Path 2884' d='M489.453,410.13l-.1,0,.255-.976h.1Z'
                                        transform='translate(-487.083 -409.156)' />
                                        <path id='Path_2885' data-name='Path 2885' d='M488.913,412.206l-.1,0,.254-.975.1,0Z'
                                        transform='translate(-486.92 -409.78)' />
                                        <path id='Path_2886' data-name='Path 2886' d='M488.371,414.282l-.1,0,.254-.975h.1Z'
                                        transform='translate(-486.757 -410.404)' />
                                        <path id='Path_2887' data-name='Path 2887' d='M487.829,416.358l-.1,0,.253-.974.1,0Z'
                                        transform='translate(-486.594 -411.028)' />
                                        <path id='Path_2888' data-name='Path 2888' d='M487.287,418.435l-.1,0,.255-.976.1,0Z'
                                        transform='translate(-486.431 -411.653)' />
                                        <path id='Path_2889' data-name='Path 2889' d='M486.748,420.511l-.1,0,.254-.974.1,0Z'
                                        transform='translate(-486.269 -412.277)' />
                                        <path id='Path_2890' data-name='Path 2890' d='M486.208,422.586l-.1,0,.253-.975.1,0Z'
                                        transform='translate(-486.107 -412.901)' />
                                    </g>
                                    <g id='Group_864' data-name='Group 864' transform='translate(5.138 30.488)'>
                                        <path id='Path_2891' data-name='Path 2891' d='M485.667,424.661l-.1,0,.254-.975.1,0Z'
                                        transform='translate(-483.307 -423.687)' />
                                        <path id='Path_2892' data-name='Path 2892' d='M485.128,426.738l-.1,0,.253-.975.1,0Z'
                                        transform='translate(-483.144 -424.311)' />
                                        <path id='Path_2893' data-name='Path 2893' d='M484.589,428.814l-.1,0,.253-.975.1,0Z'
                                        transform='translate(-482.982 -424.936)' />
                                        <path id='Path_2894' data-name='Path 2894' d='M484.05,430.89l-.1,0,.253-.975.1,0Z'
                                        transform='translate(-482.82 -425.56)' />
                                        <path id='Path_2895' data-name='Path 2895' d='M483.512,432.966l-.1,0,.253-.975.1,0Z'
                                        transform='translate(-482.658 -426.184)' />
                                        <path id='Path_2896' data-name='Path 2896' d='M482.975,435.042l-.1,0,.252-.975.1,0Z'
                                        transform='translate(-482.497 -426.808)' />
                                        <path id='Path_2897' data-name='Path 2897' d='M482.438,437.117l-.1.005.252-.975.1,0Z'
                                        transform='translate(-482.335 -427.433)' />
                                    </g>
                                    <g id='Group_865' data-name='Group 865' transform='translate(2.523 40.648)'>
                                        <path id='Path_2898' data-name='Path 2898' d='M481.9,439.192l-.1.007.251-.976.1-.006Z'
                                        transform='translate(-479.559 -438.217)' />
                                        <path id='Path_2899' data-name='Path 2899' d='M481.364,441.268l-.1.007.253-.975.1-.006Z'
                                        transform='translate(-479.398 -438.842)' />
                                        <path id='Path_2900' data-name='Path 2900' d='M480.83,443.344l-.1.008.252-.975.1-.007Z'
                                        transform='translate(-479.237 -439.466)' />
                                        <path id='Path_2901' data-name='Path 2901' d='M480.3,445.419l-.1.009.25-.974.1-.008Z'
                                        transform='translate(-479.076 -440.09)' />
                                        <path id='Path_2902' data-name='Path 2902' d='M479.763,447.494l-.1.009.249-.975.1-.008Z'
                                        transform='translate(-478.916 -440.714)' />
                                        <path id='Path_2903' data-name='Path 2903' d='M479.23,449.57l-.1.01.25-.975.1-.009Z'
                                        transform='translate(-478.756 -441.338)' />
                                        <path id='Path_2904' data-name='Path 2904' d='M478.7,451.643l-.1.012.249-.975.1-.01Z'
                                        transform='translate(-478.596 -441.962)' />
                                    </g>
                                    <g id='Group_866' data-name='Group 866' transform='translate(0 50.807)'>
                                        <path id='Path_2905' data-name='Path 2905' d='M478.17,453.718l-.1.013.249-.975.1-.012Z'
                                        transform='translate(-475.914 -452.745)' />
                                        <path id='Path_2906' data-name='Path 2906' d='M477.644,455.792l-.105.014.247-.975.1-.014Z'
                                        transform='translate(-475.755 -453.368)' />
                                        <path id='Path_2907' data-name='Path 2907' d='M477.12,457.865l-.105.017.246-.975.105-.015Z'
                                        transform='translate(-475.597 -453.992)' />
                                        <path id='Path_2908' data-name='Path 2908' d='M476.6,459.937l-.105.02.244-.975.106-.018Z'
                                        transform='translate(-475.441 -454.615)' />
                                        <path id='Path_2909' data-name='Path 2909' d='M476.085,462.006l-.107.026.242-.975.107-.023Z'
                                        transform='translate(-475.286 -455.238)' />
                                        <path id='Path_2910' data-name='Path 2910' d='M475.581,464.071l-.11.036.119-.487.119-.488.107-.029s-.059.243-.118.484Z'
                                        transform='translate(-475.133 -455.86)' />
                                        <path id='Path_2911' data-name='Path 2911' d='M475.108,466.106l-.12.082s.055-.249.111-.494.113-.489.113-.489l.111-.043s-.057.24-.112.478S475.108,466.106,475.108,466.106Z'
                                        transform='translate(-474.988 -456.478)' />
                                    </g>
                                </g>
                            </g>
                            <g id='Group_869' data-name='Group 869' transform='translate(23.455)'>
                                <path id='Path_2912' data-name='Path 2912' d='M529.931,476.991c2.93,2.068-2.239-11.419-7.254-10.756C522.677,466.236,518.1,468.647,529.931,476.991Z'
                                transform='translate(-501.507 -394.73)' />
                                <path id='Path_2913' data-name='Path 2913' d='M492.812,365.621c-.774,4.813,12.21,64.117,13.955,66.83,0,0,.194,7.559,9.5,6.008,0,0,7.753-1.357,2.908-10.659,0,0-13.041-55.137-14.079-63.81Z'
                                transform='translate(-492.778 -363.99)' fill='#1c1d1e' />
                            </g>
                        </g>
                        <path id='Path_2914' data-name='Path 2914' d='M445.115,323.374c-5.763.42,9.075,8.612,9.894,4.491S449.42,323.059,445.115,323.374Z'
                        transform='translate(-432.314 -297.796)' fill='#ffbfa4' />
                        <path id='Path_2915' data-name='Path 2915' d='M504.569,307.184l-1.829,6.591s-7.359,4.636-8.636,3.156l3.015-8.044Z'
                        transform='translate(-447.445 -292.948)' fill='#ffbfa4' />
                        <path id='Path_2916' data-name='Path 2916' d='M499.468,291.744s-3.343,4.825-2.708,8.286c0,0-1.366,3.936,4.193,1.168,0,0,3.112.653,4.183-5.192C505.136,296.006,506.28,288.305,499.468,291.744Z'
                        transform='translate(-448.199 -288.039)' fill='#ffbfa4' />
                        <g id='Group_871' data-name='Group 871' transform='translate(45.076)'>
                            <path id='Path_2917' data-name='Path 2917' d='M501.417,286.833c-10.481.367-12.482,2.6-5.393,4.394,0,0-2.2,2,4.494,1.8,0,0,3.4-2.3-.2,5.792,0,0-4.893,1.7-4.993,0,0,0-5.093,4.794-.4,4.893s.6,0,.6,0,8.089,2.1,10.686-5.093c0,0,5.893-7.19,1.9-9.287C508.107,289.33,506.382,286.659,501.417,286.833Z'
                            transform='translate(-491.841 -286.825)' />
                        </g>
                        <g id='Group_875' data-name='Group 875' transform='translate(0 14.373)'>
                            <path id='Path_2918' data-name='Path 2918' d='M552.592,359.848s13.111,3.217,13.622,5.659-3.087,8.274-6.31,8.7-11.883-6.723-11.883-6.723Z'
                            transform='translate(-463.659 -323.158)' fill='#f17187' />
                            <g id='Group_874' data-name='Group 874'>
                                <path id='Path_2919' data-name='Path 2919' d='M427.384,307.771c.169-.977,5.317.036,10.212,1.229.578.969-.016,1.489-4.069.192,0,0,7.259,3.819.075,1.724,0,0,3.026,3.33,1.673,3.91C431.25,311.907,427.22,308.721,427.384,307.771Z'
                                transform='translate(-427.379 -307.379)' fill='#55acd8' />
                                <path id='Path_2920' data-name='Path 2920' d='M449.957,318.548c7.893,1.66,1.082-3.063,1.082-3.063,4.052,1.286,4.483.155,3.945-1.251l66.425,30.24-5.494,8.812-65.089-30.53C451.645,321.13,449.957,318.548,449.957,318.548Z'
                                transform='translate(-434.169 -309.44)' fill='#55acd8' />
                                <path id='Path_2921' data-name='Path 2921' d='M436.17,309.89c4.053,1.3,4.647.777,4.069-.192,4.5,1.1,8.794,2.35,8.794,2.35l1.8.822c.538,1.406.107,2.537-3.945,1.251,0,0,6.811,4.723-1.082,3.063,0,0,1.688,2.582.869,4.208l-.908-.426s-3.923-2.593-7.854-5.443c1.353-.58-1.673-3.91-1.673-3.91C443.429,313.708,436.17,309.89,436.17,309.89Z'
                                transform='translate(-430.023 -308.076)' fill='#ffbfa4' />
                                <g id='Group_872' data-name='Group 872' transform='translate(20.615 7.664)'>
                                    <path id='Path_2922' data-name='Path 2922' d='M521.437,349.224a.265.265,0,0,0,.22-.11.2.2,0,0,0-.1-.3l-64.308-30.45a.286.286,0,0,0-.353.083.2.2,0,0,0,.1.3L521.3,349.2A.291.291,0,0,0,521.437,349.224Z'
                                    transform='translate(-456.859 -318.338)' fill='#3994b7' />
                                </g>
                                <g id='Group_873' data-name='Group 873' transform='translate(18.259 11.138)'>
                                    <path id='Path_2923' data-name='Path 2923' d='M519.169,352.731a.267.267,0,0,0,.222-.115.205.205,0,0,0-.115-.3l-65.408-28.987a.283.283,0,0,0-.35.091.207.207,0,0,0,.113.3l65.41,28.987A.3.3,0,0,0,519.169,352.731Z'
                                    transform='translate(-453.491 -323.307)' fill='#3994b7' />
                                </g>
                            </g>
                            <path id='Path_2924' data-name='Path 2924' d='M549.645,357.386s2.8-.946,3.089,1.115c0,0-3.3,9.82-5.894,9.868,0,0-4.107.1-2.689-2.171Z'
                            transform='translate(-462.405 -322.352)' fill='#3994b7' />
                            <path id='Path_2925' data-name='Path 2925' d='M567.361,367.371c-2.5.864-10.984-3.214-8.169-3.9S568.8,366.875,567.361,367.371Z'
                            transform='translate(-466.848 -324.224)' fill='#d14b6b' />
                        </g>
                        <path id='Path_2926' data-name='Path 2926' d='M456.859,329.076s1.253,11.064,6.352,10.051a3.681,3.681,0,0,0,.361-.09c5.4-1.621,4.612-4.65,4.612-4.65Z'
                        transform='translate(-436.244 -299.531)' fill='#c1dee5' />
                        <g id='Group_876' data-name='Group 876' transform='translate(41.141 19.23)'>
                            <path id='Path_2927' data-name='Path 2927' d='M499.587,314.57c4.384-3.563,10.13,32.777,3.53,34.484,0,0-19.466,10.02-16.62-4.554,0,0-2.066-24.571,6.629-29.93C493.126,314.57,491.727,320.96,499.587,314.57Z'
                            transform='translate(-486.213 -314.325)' fill='#afd2db' />
                        </g>
                        <g id='Group_877' data-name='Group 877' transform='translate(30.161 21.222)'>
                            <path id='Path_2928' data-name='Path 2928' d='M480.1,336.06s-2.364-8.875-3.21-8.77c0,0-.7-.041.194,5.323.009.049-4.77-6.66-5.539-6.166a2.241,2.241,0,0,0-.91,2.684c.25.9,3.236,8.4,6.126,10.01Z'
                            transform='translate(-470.511 -319.955)' fill='#ffbfa4' />
                            <path id='Path_2929' data-name='Path 2929' d='M497.616,317.175c4.2.1,12.4,18.445,2.536,27.884,0,0-8.8,10.307-21.6-3.364,0,0-7.812-7.161-.652-10.742,0,0,4.687-4.34,9.938,3.906,0,0,6.982,4.882,7.419-4.666C495.257,330.195,492.811,317.066,497.616,317.175Z'
                            transform='translate(-471.824 -317.174)' fill='#c1dee5' />
                        </g>
                        <g id='Group_878' data-name='Group 878' transform='translate(28.467 128.341)'>
                            <path id='Path_2930' data-name='Path 2930' d='M473.676,472.274a9.973,9.973,0,0,1-5.46-1.373.3.3,0,1,1,.341-.488c.039.026,2.99,2.01,8.112.945a.3.3,0,1,1,.121.583A15.31,15.31,0,0,1,473.676,472.274Z'
                            transform='translate(-468.088 -470.359)' fill='#1c1d1e' />
                        </g>
                        <g id='Group_879' data-name='Group 879' transform='translate(61.36 122.021)'>
                            <path id='Path_2931' data-name='Path 2931' d='M520.226,465.4a12.744,12.744,0,0,1-4.93-1.129.3.3,0,0,1,.256-.537c.038.018,3.887,1.82,6.961.694a5.251,5.251,0,0,0,3.009-2.933.3.3,0,0,1,.544.244,5.83,5.83,0,0,1-3.35,3.25A7.236,7.236,0,0,1,520.226,465.4Z'
                            transform='translate(-515.126 -461.322)' fill='#2f3033' />
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}

GroupSvg.propTypes = {

};

export default GroupSvg;