/* eslint-disable import/imports-first */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { withStyles, Grid, Paper } from "@material-ui/core";
import { connect } from "react-redux";
import "../company.css";
import moment from "moment";
import classNames from "classnames";
import { CircularLoader } from "../../Components/Common/Spinner";
import { capitalize } from "../../Utils/StringFunctions";
import { getBookings, getSelfBookings } from "../../Actions/bookingActions";
import ScreenHeader from "../../Components/Common/ScreenHeader";
import BookingsList from "./BookingsList";
import { getAvailableResources } from "../../Actions/resourceActions";
import BookResourceCard from "../../Components/Common/Cards/BookResourceCard";
import nodata from "../../Assets/nodata.png";

require("react-big-calendar/lib/css/react-big-calendar.css");

const styles = (theme) => ({
  paper: {
    // marginBottom: theme.spacing(3),
    padding: "0px 12px 0px 24px",
    flexGrow: 1,
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      // marginBottom: theme.spacing(6),
      padding: "0px 12px 0px 24px",
    },
  },
  calendarContainer: {
    height: `100%`,
    overflow: "hidden",
    minHeight: "75vh",
    margin: "24px 0px 0px",
    "& .rbc-calendar": {
      width: "100%",
      "& .customToolbar": {
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        "& .buttonsContainer": {
          "& > *": {
            marginRight: "20px",
          },
          "& .currentMonthButton": {
            "&:hover": {
              backgroundColor: "#fcfdfe",
              textDecoration: "none",
            },
          },
        },
        "& .monthsContainer": {
          float: "right",
        },
        "& .filterContainer": {
          float: "left",
        },
      },
      "& .rbc-header": {
        fontWeight: "normal",
        fontSize: "12px",
        color: "#ababab",
        padding: "6px 0px",
      },
      "& .rbc-event": {
        background: "none",
        color: "gray",
        borderColor: "#fff !important ",
        "& :before": {
          content: '"• "',
          float: "left",
          padding: "0px 3px",
          color: "green",
        },
        "& .selected": {
          borderColor: "#fff !important ",
        },
      },

      "& .rbc-off-range-bg": {
        background: " #f2f2f2",
      },
    },
  },
  commonGrid: {
    borderRadius: "5px",
    background: " #fcfdfe",
    border: "1px solid #efefef",
    padding: "8px 20px 20px",
  },
  calenderGrid: {
    marginRight: "5%",
  },
  eventGrid: {
    overflowY: "auto",
    maxHeight: "75vh",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  headerSpacing: {
    margin: "18px 0px 0px 18px",
  },
});

class AdminBookings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bookings: [],
      loading: false,
      selectedEvent: "",
      selectedMonth: moment().month() + 1,
      selectedYear: new Date().getYear(),
      selfBookings: [],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.bookings.bookings.data !== prevState.bookings) {
      return {
        bookings: nextProps.bookings.bookings.data,
        loading: nextProps.bookings.loading,
      };
    }
    if (nextProps.bookings.selfBookings.data !== prevState.selfBookings) {
      return {
        selfBookings: nextProps.bookings.selfBookings.data,
        loading: nextProps.bookings.loading,
      };
    }
    if (nextProps.resources !== prevState.resources) {
      return { resources: nextProps.resources };
    }

    return [];
  }

  componentDidMount() {
    const role = localStorage.getItem("role");
    if (role === "coworker" || role === "coworkeradmin") {
      this.props.getSelfBookings();
    } else {
      this.props.getBookings();
    }

    this.props.getAvailableResources();
  }

  componentDidUpdate(prevProps) {
    const role = localStorage.getItem("role");

    if (
      this.props.deleteBooking !== prevProps.deleteBooking &&
      this.props.deleteBooking.success
    ) {
      if (role === "coworker" || role === "coworkeradmin") {
        this.props.getSelfBookings();
      } else {
        this.props.getBookings();
      }
    }
  }

  // handleClickOpen = (data) => {
  //   const currentDate = new Date();
  //   const nextMonthDate = currentDate.setMonth(currentDate.getMonth() + 1);
  //   const maxDateFormat = moment(nextMonthDate).endOf("month");
  //   if (data >= new Date().setHours(0, 0, 0, 0) && data <= maxDateFormat) {
  //     this.setState({
  //       modalOpen: true,
  //       startdate: data,
  //     });
  //   }
  // };

  handleClose = () => {
    const role = localStorage.getItem("role");
    if (role === "coworker" || role === "coworkeradmin") {
      this.props.getSelfBookings();
    } else {
      this.props.getBookings();
    }
    // this.props.getBookings();
    this.setState({
      // eslint-disable-next-line react/no-unused-state
      modalOpen: false,
    });
    return null;
  };

  render() {
    const { bookings, loading, selfBookings } = this.state;
    const { classes, resources } = this.props;
    let calenderBookings = [];
    let selfBooking = [];

    selfBooking =
      selfBookings &&
      selfBookings.map((booking) => {
        const resourceName =
          booking && booking.resource_id && booking.resource_id.resource_name;
        return {
          id: booking._id,
          resource: resourceName || "",
          title: capitalize(resourceName || ""),
          start: new Date(moment.utc(booking.start).format()),
          end: new Date(moment.utc(booking.end).format()),
          member: booking.member,
          companyname:
            booking.created_by &&
            booking.created_by.company_id &&
            booking.created_by.company_id.company_name,
          firstname:
            booking.created_by &&
            booking.created_by.first_name &&
            booking.created_by.first_name,
          lastname:
            booking.created_by &&
            booking.created_by.last_name &&
            booking.created_by.last_name,
        };
      });

    calenderBookings =
      bookings &&
      bookings.map((booking) => {
        const resourceName =
          booking && booking.resource_id && booking.resource_id.resource_name;
        return {
          id: booking._id,
          resource: resourceName || "",
          title: capitalize(resourceName || ""),
          start: new Date(moment.utc(booking.start).format()),
          end: new Date(moment.utc(booking.end).format()),
          member: booking.member,
          companyname:
            booking.created_by &&
            booking.created_by.company_id &&
            booking.created_by.company_id.company_name,
          firstname:
            booking.created_by &&
            booking.created_by.first_name &&
            booking.created_by.first_name,
          lastname:
            booking.created_by &&
            booking.created_by.last_name &&
            booking.created_by.last_name,
        };
      });
    const role = localStorage.getItem("role");

    return (
      <React.Fragment>
        <div className={classes.header}>
          <ScreenHeader type="All Bookings" />
        </div>
        <Paper className={classes.paper}>
          {loading && <CircularLoader />}

          <Grid container spacing={3} className={classes.calendarContainer}>
            <Grid
              md="8"
              className={classNames(classes.calenderGrid, classes.cardGrid)}
            >
              {resources && resources.length <= 0 ? (
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                  className={classes.headerSpacing}
                >
                  <Grid item justify="center">
                    <Grid item sm={10} lg={10}>
                      <img src={nodata} alt="" width="100%" />
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={2}>
                  {resources &&
                    resources.map((element) => {
                      return (
                        <Grid item lg={4} xs={12} sm={6} key={element.id}>
                          <BookResourceCard data={element} />
                        </Grid>
                      );
                    })}
                </Grid>
              )}
            </Grid>
            <Grid
              md="3"
              className={classNames(classes.eventGrid, classes.commonGrid)}
            >
              {role === "coworker" || role === "coworkeradmin" ? (
                <BookingsList
                  events={selfBooking}
                  selected={this.state.selectedEvent}
                  selectedMonth={this.state.selectedMonth}
                  selectedYear={this.state.selectedYear}
                />
              ) : (
                <BookingsList
                  events={calenderBookings}
                  selected={this.state.selectedEvent}
                  selectedMonth={this.state.selectedMonth}
                  selectedYear={this.state.selectedYear}
                />
              )}
            </Grid>
          </Grid>
          {/* <BookingForm
            modalOpen={this.state.modalOpen}
            handleClose={this.handleClose}
            bookingdate={this.state.startdate}
          /> */}
        </Paper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    bookings: state.bookingData,
    resources: state.resources.resources,
    deleteBooking: state.bookingData.deleteBooking,
  };
};

export default connect(mapStateToProps, {
  getBookings,
  getSelfBookings,
  getAvailableResources,
})(withStyles(styles)(AdminBookings));
