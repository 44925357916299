import axios from "axios";
import {
  GET_ERRORS,
  VISITOR_ERRORS,
  GET_VISITORS,
  VISITOR_LOADING,
  CHECK_VISITOR,
  CREATE_VISITOR_BY_WALKIN,
  CREATE_VISITOR_BY_WALKTHROUGH
} from "./types";
import { showSuccess, showError } from "./ActionsHelper";
import { transformErrorsFromApi } from "./TransformError";

//  Loading
export const setVisitorsLoading = () => {
  return {
    type: VISITOR_LOADING
  };
};

export const checkVisitor = (
  userData,
  setErrors,
  setSubmitting
) => dispatch => {
  setVisitorsLoading();
  axios
    .post("/api/visitors/checkvisitor", userData)
    .then(result => {
      dispatch({
        type: CHECK_VISITOR,
        payload: result.data
      });
      // history.push("/visitors");
      // dispatch(showSuccess("Visitor"));
      setSubmitting(false);
    })
    .catch(err => {
      dispatch({ type: VISITOR_ERRORS, payload: err.data });
      dispatch(showError("There was some error while invite visitor. "));
      // history.push("/visitors");
      setSubmitting(false);
      setErrors(transformErrorsFromApi(err));
    });
};
export const getvisitors = () => dispatch => {
  setVisitorsLoading();
  axios
    .get("/api/visitors")
    .then(result => {
      dispatch({
        type: GET_VISITORS,
        payload: result.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : ""
      });
    });
};

export const addvisitorbywalkin = (
  userData,
  setErrors,
  setSubmitting
) => dispatch => {
  setVisitorsLoading();
  axios
    .post("/api/visitors", userData)
    .then(result => {
      dispatch({
        type: CREATE_VISITOR_BY_WALKIN,
        payload: result.data
      });
      dispatch(showSuccess("Visitor"));
      setSubmitting(false);
    })
    .catch(err => {
      dispatch({ type: VISITOR_ERRORS, payload: err.data });
      dispatch(showError("There was some error while invite visitor. "));
      setSubmitting(false);
      setErrors(transformErrorsFromApi(err));
    });
};
export const addvisitorbywalkthrough = (
  userData,
  setErrors,
  setSubmitting
) => dispatch => {
  setVisitorsLoading();
  axios
    .post("/api/visitors", userData)
    .then(result => {
      dispatch({
        type: CREATE_VISITOR_BY_WALKTHROUGH,
        payload: result.data
      });

      dispatch(showSuccess("Visitor"));
      setSubmitting(false);
    })
    .catch(err => {
      dispatch({ type: VISITOR_ERRORS, payload: err.data });
      dispatch(showError("There was some error while invite visitor. "));
      setSubmitting(false);
      setErrors(transformErrorsFromApi(err));
    });
};
