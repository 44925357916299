/* eslint-disable no-unused-vars */
import axios from "axios";
import {
  // GET_NOTIFICATION,
  NOTIFICATION_LOADING,
  GET_NOTIFICATIONS,
  GET_SINGLE_NOTIFICATION,
  GET_NOTIFICATIONS_ERROR,
  CREATE_NOTIFICATION,
  NOTIFICATION_ERROR
} from "./types";
import { showSuccess, showError } from "./ActionsHelper";
import { transformErrorsFromApi } from "./TransformError";

// Profile Loading
export const setNotificationLoading = () => {
  return {
    type: NOTIFICATION_LOADING
  };
};

export const getNotifications = () => dispatch => {
  dispatch(setNotificationLoading());

  axios
    .get(`/api/notification`)
    .then(res => {
      dispatch({
        type: GET_NOTIFICATIONS,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_NOTIFICATIONS_ERROR,
        payload: {}
      })
    );
};

export const getSingleNotification = id => dispatch => {
  dispatch(setNotificationLoading());
  axios
    .get(`/api/notification/${id}`)
    .then(res => {
      dispatch({
        type: GET_SINGLE_NOTIFICATION,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_NOTIFICATIONS_ERROR,
        payload: {}
      })
    );
};

export const createNotification = (
  data,
  setErrors,
  setSubmitting,
  history
) => dispatch => {
  dispatch(setNotificationLoading());
  axios
    .post("/api/notification", data)
    .then(result => {
      dispatch({
        type: CREATE_NOTIFICATION,
        payload: result.data
      });
      history.push("/notifications");
      dispatch(showSuccess("Notification"));
    })
    .catch(err => {
      const error = err.response;
      dispatch({ type: NOTIFICATION_ERROR, payload: error.data });
      setSubmitting(false);
      // setErrors(transformErrorsFromApi(error));
    });
};
