import React from "react";
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Info } from "react-feather";
import { capitalize } from "../../Utils/StringFunctions";

const styles = () => {
  return {
    boldStyles: {
      // fontWeight: "bold"
    },
    infoIcon: {
      position: "relative",
      top: "4px",
      left: "-13px",
    },
  };
};

const PolicyInfo = (props) => {
  const { text, classes } = props;
  return (
    <span>
      <Tooltip title={capitalize(text)} className={classes.customTooltip}>
        <span className={classes.boldStyles}>
          {text ? (
            <Info
              size={16}
              fill="#4682B4"
              color="white"
              className={classes.infoIcon}
            />
          ) : (
            " "
          )}
        </span>
      </Tooltip>
    </span>
  );
};

export default withStyles(styles)(PolicyInfo);
