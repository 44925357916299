/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import React, { useEffect, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
} from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";
import { addMerchandiseProduct } from "../../Actions/merchandiseActions";
import { InputField, UploadField } from "../../Components/Common/Form";
import TextareaField from "../../Components/Common/Form/TextareaField";

const $quoteSym = " ";

const styles = () => ({
  createIconForm: {
    width: 500,
    padding: "8px",
  },
  formContent: {
    height: "450px",
  },
  modelHeader: {
    paddingBottom: "5px",
    position: "relative",
    "&:after": {
      top: "48px",
      left: "24px",
      width: "24px",
      borderBottom: "3px solid #fb0",
      content: `'${$quoteSym}'`,
      position: "absolute",
    },
  },
  modalFooter: {
    padding: "40px 0px 8px",
    display: "flex",
    justifyContent: "flex-end",
  },
  cancelbutton: {
    marginRight: "20px",
    backgroundColor: "#dddddd",
    color: "#151039",
    "&:hover": {
      color: "#151039",
      backgroundColor: "#d1d1d1!important",
    },
  },
  addbutton: {
    color: "#fff",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  input_field: {
    border: "1px solid #949494",
    height: "81%",
    position: "relative",
    width: "100%",
    padding: "6px 8px",
    margin: "0px",
    // borderBottom: "none",
    fontFamily:
      "'-apple-system,BlinkMacSystemFont','Segoe UI','Roboto','Helvetica Neue','Arial,sans-serif','Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol'",
    color: "currentColor",
    fontSize: "14px",
    background: "white",
  },
  descriptionLabel: {
    margin: "15px 0px 8px 0px",
    fontSize: "12px",
    fontWeight: "400",
    color: "rgba(0, 0, 0, 0.54)",
  },
});
const addWalkthroughSchema = Yup.object().shape({
  product_name: Yup.string().required("This field is required."),
  description: Yup.string().required("This field is required."),
  price: Yup.number()
    .typeError("Please input a number.")
    .required("This field is required."),
  image: Yup.string().required("This field is required."),
});

function AddProductModal(props) {
  const { classes } = props;
  const [singleVisitor] = useState([]); // all user
  // const [deletedKey, setdeletedKey] = useState([]); // all user

  // const setDelImageUrl = (url) => {
  //   setdeletedKey({ deletedKey: deletedKey.push(url) });
  // };

  const handleClose = () => {
    props.closeAddProductModal();
  };

  useEffect(() => {
    if (props.addProduct && props.addProduct.success) {
      props.closeAddProductModal();
    }
  }, [props.addProduct]);

  const getInitialValues = (detailInserted) => {
    if (detailInserted === "yes") {
      return {
        product_name: "",
        price: "",
        description: "",
        image: "",
      };
    }
    return {
      product_name: "",
      price: "",
      description: "",
      image: "",
    };
  };

  return (
    <Dialog
      open={props.openAddProductModal}
      onClose={props.closeAddProductModal}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" className={classes.modelHeader}>
        {singleVisitor && singleVisitor.details_inserted === "yes"
          ? "View Product"
          : "Add Product"}
      </DialogTitle>

      <Formik
        initialValues={getInitialValues(
          singleVisitor && singleVisitor.details_inserted
        )}
        enableReinitialize
        validationSchema={addWalkthroughSchema}
        onSubmit={(values, { setSubmitting, setErrors }) => {
          props.addMerchandiseProduct(values, setErrors, setSubmitting);
          //   createTeamData(values, setSubmitting);
        }}
        render={({ setFieldValue, values }) => (
          <Form className={classes.createIconForm}>
            <DialogContent className={classes.formContent}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <InputField
                    name="product_name"
                    label="Enter Name"
                    placeholder="Enter Name"
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <InputField
                    name="price"
                    label="Price"
                    placeholder="Enter Price"
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <UploadField
                    label="Upload Photo"
                    name="image"
                    id="image"
                    onChange={setFieldValue}
                    value={values.image}
                    folder="merchandise/product"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormLabel
                    className={classes.descriptionLabel}
                    component="legend"
                  >
                    Description
                  </FormLabel>
                  <TextareaField
                    name="description"
                    className={classes.input_field}
                    rows="5"
                    placeholder="Type Here"
                  ></TextareaField>
                </Grid>
              </Grid>

              <Grid className={classes.modalFooter}>
                <Button
                  onClick={handleClose}
                  color="primary"
                  className={classes.cancelbutton}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  className={classes.addbutton}
                  type="submit"
                  variant="contained"
                >
                  Add
                </Button>
              </Grid>
            </DialogContent>
          </Form>
        )}
      />
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    errors: state.errors,
    single_walkthrough_visitor: state.visitors.single_walkthrough_visitor,
    addProduct: state.allMerchandiseOrder.addProduct,
  };
};

export default connect(mapStateToProps, {
  addMerchandiseProduct,
})(withStyles(styles)(AddProductModal));
