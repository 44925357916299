import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { SelectField } from "../../Components/Common/Form";
import TextareaField from "../../Components/Common/Form/TextareaField";
import { createAlliedService } from "../../Actions/AlliedServicesActions";

const $quoteSym = " ";
const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  form_modal: {
    minWidth: "600px",
  },
  modalHeader: {
    paddingBottom: "5px",
    fontSize: "20px",
    fontWeight: "600",
    position: "relative",
    "&:after": {
      top: "46px",
      left: "26px",
      width: "24px",
      borderBottom: "3px solid #fb0",
      content: `'${$quoteSym}'`,
      position: "absolute",
    },
  },
  input_field: {
    border: "1px solid #f5f5f5",
    height: "81%",
    position: "relative",
    width: "100%",
    padding: "6px 8px",
    margin: "0px",
    // borderBottom: "none",
    fontFamily:
      "'-apple-system,BlinkMacSystemFont','Segoe UI','Roboto','Helvetica Neue','Arial,sans-serif','Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol'",
    color: "grey",
    fontSize: "14px",
    resize: "none",
  },
  eventsubtitle1: {
    fontWeight: "normal",
    fontSize: "14px",
    textAlign: "left",
    color: "#626262",
  },
  addButton: {
    color: "#fff",
    marginRight: "20px",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  cancelButton: {
    marginRight: "25px",
    backgroundColor: "#dddddd",
    color: "#151039",
    "&:hover": {
      color: "#151039",
      backgroundColor: "#d1d1d1!important",
    },
  },
  dialogContent: {
    height: "400px",
  },
  scrolldropdown: {
    overflowY: "scroll",
  },
  labelFont: {
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "14px",
    fontWeight: "400",
    paddingBottom: "10px",
  },
});

const AlliedServicesOptions = [
  { value: "accounting", label: "Accounting Service" },
  { value: "graphic_designing", label: "Graphic Designing" },
  { value: "corporate_innovation", label: "Corporate Innovation" },
  { value: "legal", label: "Legal Service" },
  { value: "software_development", label: "Website Design" },
  { value: "interior_designing", label: "Interior Designing" },
  { value: "digital_marketing", label: "Digital Marketing" },
  {
    value: "hr_consulting&recruitment",
    label: "HR consulting & Recruitment Service",
  },
];

const AddInquirySchema = Yup.object().shape({
  details: Yup.string()
    .max(100, "The text entered exceeds the maximum length")
    .required("This field is required"),
});

class InquiryPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // errors: {}
    };
  }

  handleSuccess = () => {
    this.props.handleClose();
  };

  render() {
    const { classes } = this.props;

    return (
      <Dialog
        open={this.props.data}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <Formik
          initialValues={{
            service: "",
            details: "",
          }}
          enableReinitialize
          validationSchema={AddInquirySchema}
          onSubmit={(values, { setErrors, setSubmitting }) => {
            // eslint-disable-next-line no-param-reassign
            values.allied_services = values.allied_services.value;
            this.props.createAlliedService(
              values,
              setErrors,
              this.props.history,
              setSubmitting
            );
            this.handleSuccess();
          }}
        >
          {({ values, errors, touched, setFieldValue, handleSubmit }) => (
            <Form onSubmit={handleSubmit} className={classes.form_modal}>
              <DialogTitle
                id="issue-category-dialog-title"
                className={classes.modalHeader}
              >
                Allied Services Inquiry
              </DialogTitle>
              <DialogContent className={classes.dialogContent}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <SelectField
                      className={classes.scrolldropdown}
                      value={values.allied_services}
                      name="allied_services"
                      id="allied_services"
                      label="Select Service"
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                      }}
                      error={errors.allied_services}
                      touched={touched.allied_services}
                      options={AlliedServicesOptions}
                      selectedItem={this.props.alliedServices}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography
                      variant="subtitle2"
                      component="p"
                      className={classes.labelFont}
                    >
                      Details
                    </Typography>
                    <Typography
                      className={classes.eventsubtitle1}
                      variant="subtitle1"
                      component="p"
                    >
                      <TextareaField
                        name="details"
                        className={classes.input_field}
                        rows="5"
                      ></TextareaField>
                    </Typography>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.handleSuccess}
                  color="primary"
                  className={classes.cancelButton}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  className={classes.addButton}
                  type="submit"
                  variant="contained"
                >
                  Submit
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    // status: state.helpdesk.status,
    errors: state.errors,
  };
};
export default connect(mapStateToProps, {
  createAlliedService,
})(withStyles(styles)(InquiryPopup));
