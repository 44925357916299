import React from "react";
import { withStyles, Grid, Paper, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import AboutSuggest from "./AboutSuggest";
// eslint-disable-next-line import/no-cycle
import SuggestDetailData from "./SuggestDetail";

// import ImageTextLayout from "./ImageTextLayout";

const styles = () => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: "20px",
    textAlign: "center",
    // color: theme.palette.text.secondary,
    height: "100vh",
    overflowY: "scroll",
  },
  TextLayout: {
    paddingBottom: "3%",
  },
  mainTitle: {
    fontWeight: "600",
    fontSize: "40px",
    textAlign: "left",
    color: "#222222",
    margin: "0px",
    lineHeight: "50px",
  },
  descText: {
    fontSize: "15px",
    textAlign: "left",
    color: "#626262",
    marginTop: "5px",
  },
  AmbassadorLayout: {
    padding: "5% 0% 0%",
    display: "flex",
  },
  contentLayout: {
    margin: "0px 25px 0px 0px",
  },
  imageDiv: {
    border: "1px solid #EFEFEF",
    borderRadius: "5px",
  },
  setupImg: {
    padding: "10%",
  },
  divtitle: {
    fontSize: "25px",
  },
  subTitle: {
    margin: "0% 0% 10%",
    textAlign: "center",
    fontWeight: "600",
    lineHeight: "18px",
    fontSize: "16px",
  },
});

class AdminSuggest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <main className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <Grid className={classes.TextLayout}>
                  <Typography
                    align="center"
                    variant="h1"
                    className={classes.mainTitle}
                  >
                    Help us make DevX
                    <br />
                    Collab better for you
                  </Typography>
                </Grid>
                <Grid className={classes.Ambassadorayout}>
                  <AboutSuggest />
                </Grid>
                <Grid className={classes.Ambassadorayout}></Grid>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <Grid className={classes.TextLayout}>
                  <SuggestDetailData />
                </Grid>
                <Grid></Grid>
              </Paper>
            </Grid>
          </Grid>
        </main>
      </React.Fragment>
    );
  }
}

AdminSuggest.propTypes = {};

const mapStateToProps = (state) => {
  return {
    communityEmbassdor: state.communityAmbassador.community_embassadors,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(AdminSuggest));
