import React from "react";
import { withStyles, Grid, Paper, Button } from "@material-ui/core";
import { connect } from "react-redux";
import { CircularLoader } from "../../Components/Common/Spinner";
import UserCard from "../../Components/Common/Cards/UserCard";
import AddFormButton from "../../Components/Common/AddFormButton";
import ScreenHeader from "../../Components/Common/ScreenHeader";
import { getUsers } from "../../Actions/userActions";
import DataNotFound from "../../Components/Common/DataNotFound";

const styles = (theme) => ({
  searchSection: {
    margin: "24px 0px 0px",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    backgroundColor: "white",
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    // marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    flexGrow: 1,
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      // marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  applicationHeader: {
    margin: "20px",
    borderBottom: "3px solid orange",
    paddingBottom: "5px",
  },
  appbar: {
    backgroundColor: "#ffffff",
  },
  root: {
    width: "100%",
    padding: "0px",
  },
  button: {
    margin: theme.spacing(1),
    fontSize: "10px",
    backgroundColor: theme.palette.applications.active,
    color: "#ffffff",
  },
  typo: {
    fontSize: "14px",
  },
  coworkerCardContainer: {
    margin: "5px 0 0px",
  },
  addcoworker: {
    color: "#fff",
    padding: "7px 16px",
    borderRadius: "4px",
    textDecoration: "none",
    fontSize: "0.875rem",
    fontWeight: "500",
    marginLeft: "18px",
    boxShadow: "rgba(137, 163, 169, 0.3) 0px 3px 5px 2px",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  DataNotFound: {
    marginTop: "10px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  sortby: {
    marginRight: "-5px",
  },
  backButton: {
    margin: "0% 0% 2% 0%",
  },
});

class AdminManageUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
    };
  }

  componentDidMount() {
    this.props.getUsers("", "sysuser");
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.users.data !== prevState.users) {
      return {
        users: nextProps.users.data,
      };
    }
    return [];
  }
  componentDidUpdate(prevProps) {
  if (
    this.props.userStatus !== prevProps.userStatus &&
    this.props.userStatus.success === true
  ) {
    this.props.getUsers("", "sysuser");
  }
}

  render() {
    const { classes } = this.props;
    const { loading, users } = this.state;
   
    if (loading) {
      return <CircularLoader />;
    }

    if (users === undefined) {
      return (
        <div className={classes.noData}>
          <DataNotFound
            type="visitors"
            text="No results found!"
            subtext=""
            paddingTop="22%"
          />
        </div>
      );
    }
    return (
      <React.Fragment>
        <Button
          color="grey"
          onClick={() => this.props.history.push("/settings")}
          className={classes.backButton}
          variant="contained"
          size="small"
        >
          Back
        </Button>
        <div className={classes.header}>
          <ScreenHeader type="All User" />
          <AddFormButton type="User" url="/user/add" />
        </div>

        {/* {coworkers && coworkers.length <= 0 && isSearch && (
          <NoRecordsFound text={`No records found for your text "${search}"`} />
        )} */}

        <main className={classes.layout}>
          <Paper className={classes.paper}>
            {/* Hero unit */}

            <Grid container spacing={2}>
              {users && users.length <= 0 ? (
                <DataNotFound
                  type="visitors"
                  text="No results found!"
                  subtext=""
                  paddingTop="8%"
                />
              ) : (
                users &&
                users.length > 0 &&
                users.map((element) => {
                  return (
                    <Grid
                      item
                      lg={4}
                      xs={12}
                      sm={6}
                      key={1}
                      className={classes.coworkerCardContainer}
                    >
                      <UserCard data={element} />
                    </Grid>
                  );
                })
              )}
            </Grid>
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.manageuser.users,
    loading: state.manageuser.loading,
    userStatus:state.manageuser.userStatus
  };
};

export default connect(mapStateToProps, { getUsers })(
  withStyles(styles)(AdminManageUser)
);
