/* eslint-disable no-param-reassign */
import React, { Component } from "react";
import { Formik, Form } from "formik";
import {
  Dialog,
  DialogContent,
  Grid,
  DialogTitle,
  Button,
} from "@material-ui/core";
import * as Yup from "yup";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { CircularLoader } from "../../Components/Common/Spinner";
import { spaceOptions } from "../../Utils/dropDownData";
import { checkPermission } from "../../Utils/checkPermission";
import {
  getAllClubs,
  createClub,
  editClub,
} from "../../Actions/AllClubActions";
import {
  SelectField,
  UploadField,
  InputField,
} from "../../Components/Common/Form";
import ClubCategoryDropdown from "../../Components/Common/ClubCategoryDropdown";

const $quoteSym = " ";

const styles = () => ({
  club_form_modal: {
    width: "500px",
  },
  button: {
    width: "10%",
    color: "white",
    padding: "5px 16px",
    borderRadius: "4px",
    textDecoration: "none",
    fontSize: "0.875rem",
    fontWeight: "500",
    boxShadow: "rgba(137, 163, 169, 0.3) 0px 3px 5px 2px",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  applicationHeader: {
    marginTop: "-2px",
    top: "10px",
    padding: "16px 24px 10px 24px",
    "&:after": {
      width: "24px",
      borderBottom: "3px solid #fb0",
      content: `'${$quoteSym}'`,
      position: "absolute",
    },
  },
  clubActions: {
    padding: "40px 0px 8px",
    display: "flex",
    justifyContent: "flex-end",
  },
  clubbutton: {
    display: "contents",
  },
  uploadClubBanner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  clubdescription: {
    marginTop: "-9px",
  },
  cancelButton: {
    marginRight: "20px",
    backgroundColor: "#dddddd",
    color: "#151039",
    "&:hover": {
      color: "#151039",
      backgroundColor: "#d1d1d1!important",
    },
  },
});
const ClubSchema = Yup.object().shape({
  club_name: Yup.string().trim().required("This field is required."),
  description: Yup.string().required(" This field is required."),
  restrict_to: Yup.string().when("role", {
    // eslint-disable-next-line no-unused-vars
    is: (role) => localStorage.getItem("role") === "admin",
    then: Yup.string().required("This field is required"),
  }),
  banner: Yup.string().required("This field is required."),
  club_category: Yup.string().required("This field is required."),
});

// eslint-disable-next-line react/prefer-stateless-function
class AddClub extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      classes,
      open,
      handleClose,
      editingClub,
      addclub,
      clublist,
    } = this.props;

    const { loading } = this.state;
    let id;
    const getInitialValues = () => {
      if (editingClub && addclub) {
        id = editingClub._id;
        return {
          club_name: editingClub.club_name,
          banner: editingClub.banner,
          club_category: editingClub.club_category
            ? editingClub.club_category._id
            : "",
          description: editingClub.description,
          restrict_to: spaceOptions.filter(
            (option) => option.value === editingClub.restrict_to
          )[0],
        };
      }
      return {
        club_name: "",
        banner: "",
        club_category: [],
        description: "",
        restrict_to: "",
      };
    };

    return (
      <div className={classes.clubbutton}>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="plan-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Formik
            initialValues={getInitialValues(editingClub)}
            validationSchema={ClubSchema}
            onSubmit={(values, { setErrors, setSubmitting }) => {
              // eslint-disable-next-line no-param-reassign
              // values.created_by = "5cde64595cafe71468f0c483";
              // eslint-disable-next-line no-param-reassign
              values.space = localStorage.getItem("space_id");
              const val2 = values.club_category
                ? values.club_category.value
                : "";
              if (editingClub) {
                values.club_category = val2;
              }
              // eslint-disable-next-line no-param-reassign
              values.club_category_id = val2;
              if (localStorage.getItem("role") === "admin") {
                const val = values.restrict_to ? values.restrict_to.value : "";
                // eslint-disable-next-line no-param-reassign
                values.restrict_to = val;
              } else {
                values.restrict_to = "space";
              }
              delete values.images;
              if (editingClub && addclub) {
                this.props.editClub(
                  id,
                  values,
                  setErrors,
                  setSubmitting,
                  this.props.history
                );
              } else {
                this.props.createClub(
                  values,
                  setErrors,
                  this.props.history,
                  setSubmitting
                );
              }
              this.props.getAllClubs();
              this.props.handleClose();
            }}
          >
            {({ setFieldValue, values, setFieldTouched, errors, touched }) => (
              <Form className={classes.club_form_modal}>
                <DialogTitle
                  id="plan-dialog-title"
                  className={classes.applicationHeader}
                >
                  {editingClub && addclub ? `Edit Club` : `Add Club`}
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <InputField
                        name="club_name"
                        label="Title"
                        placeholder="Enter Club Name"
                      />
                    </Grid>
                    <Grid item xs={5} sm={5}>
                      <UploadField
                        label="Upload Banner"
                        name="banner"
                        id="banner"
                        onChange={setFieldValue}
                        value={values.banner}
                        folder="club/banner"
                        delImageUrl={() => this.setDelImageUrl}
                      />
                    </Grid>

                    {checkPermission("CLUB_GROUP") ? (
                      <Grid item xs={12} sm={12}>
                        <SelectField
                          value={values.restrict_to}
                          name="restrict_to"
                          id="restrict_to"
                          label="Group"
                          onChange={setFieldValue}
                          onBlur={setFieldTouched}
                          multi={false}
                          error={errors.restrict_to}
                          touched={touched.restrict_to}
                          options={spaceOptions}
                        />
                      </Grid>
                    ) : (
                      ""
                    )}
                    <Grid item xs={12}>
                      <ClubCategoryDropdown
                        values={values}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        errors={errors}
                        touched={touched}
                        name="club_category"
                        categoryList={clublist}
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.clubdescription}>
                      <InputField
                        name="description"
                        label="Club Description"
                        placeholder="Enter Description"
                        max={400}
                      />
                    </Grid>
                  </Grid>

                  <Grid className={classes.clubActions}>
                    <Button
                      onClick={handleClose}
                      color="lightgrey"
                      variant="contained"
                      className={classes.cancelButton}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      className={classes.button}
                    >
                      {editingClub && addclub ? `Edit` : `Add`}
                    </Button>
                  </Grid>
                </DialogContent>
                {loading && <CircularLoader />}
              </Form>
            )}
          </Formik>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clubs: state.clubs.allclubs,
    createclub: state.clubs.createclub,
    editClub: state.clubs.editClub,
  };
};
export default connect(mapStateToProps, { createClub, getAllClubs, editClub })(
  withStyles(styles)(AddClub)
);
