import React from 'react'
import {
  Grid,
  CardContent,
  withStyles,
  Paper,
  Avatar,
  Typography,
  Card,
  CardActions,
} from '@material-ui/core'
import Green from '@material-ui/core/colors/green'
import CreateIcon from '@material-ui/icons/Create'
import { Link } from 'react-router-dom'
import {
  Instagram,
  Linkedin,
  Twitter,
  FacebookRoundIcon,
  FacebookGrey,
  InstagramGrey,
  TwitterGrey,
  LinkedinGrey,
} from '../../../Assets/index'
import avatar from '../../../Assets/coworker3.png'
import CardFooterViewMore from '../CardFooterViewMore'
import SocialButton from '../SocialButton'
import { checkPermission } from '../../../Utils/checkPermission'
import { spacecapitalize } from '../../../Utils/StringFunctions'
import UserStatus from '../Types/UserStatus'

const moment = require('moment')

const styles = (theme) => {
  const borderRadius = theme.shape.borderRadius * 4

  return {
    cardGrid: {
      padding: `${theme.spacing(8)}px 0`,
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      border: '1px solid #EFEFEF',
      boxShadow: '0 9px 10px rgba(0, 0, 0, 0.1), 0 4px 0px rgba(0,0,0,0.05)',
      transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
      backgroundColor: '#FCFDFE',
      position: 'relative',
      '&:hover': {
        transform: 'scale(1.01)',
        boxShadow:
          'rgba(34, 36, 38, 0.08) 0px 2px 4px 6px, rgba(34, 36, 38, 0.15) 0px 2px 10px 0px',
      },
    },
    cardMedia: {
      paddingTop: '56.25%', // 16:9
    },
    cardContent: {
      flexGrow: 1,
      textAlign: 'center',
      '& p': {
        color: theme.palette.font.head1,
      },
      '& h2': {
        color: '#82A3FE',
      },
      '& h4': {
        color: theme.palette.font.head2,
        fontWeight: 'normal',
      },
      '&:last-child': {
        paddingBottom: '10px',
      },

      paddingTop: '10px',
    },
    containerPaper: {
      // border: `1px solid ${theme.palette.border.color}`
    },
    profileImage: {
      display: 'flex',
      flexDirection: 'row',
      height: 50,
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '36px',
    },
    avatar: {
      width: 60,
      height: 60,
      position: 'relative',
    },

    status: {
      color: 'white',
      backgroundColor: Green[700],
      padding: '5px 8px',
      fontSize: 14,
    },
    image: {
      height: 'auto',
    },
    coworkerInfo: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      justify: 'center',
      alignItems: 'center',
      '& p': {
        fontSize: '14px',
      },
    },
    teamAvtar: {
      display: 'flex',
      flexDirection: 'row',
    },
    footerContainer: {
      marginTop: '10px',
      color: theme.palette.font.head1,
      fontSize: '12px',
    },
    footerHeader: {
      fontSize: '20px',
    },
    iconContainer: {
      marginTop: '1px',
      marginBottom: '3px',
    },
    cardFooter: {
      justifyContent: 'space-around',
      '& a': {
        textDecoration: 'none',
      },
      '& button': {
        margin: theme.spacing(1),
        fontSize: 10,
        borderRadius: `${borderRadius}px`,
      },
    },
    customBadge: {
      position: 'absolute',

      color: 'white',
      padding: '2px 10px',
      right: '11px',
      '-webkitClipPath':
        'polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 7% 50%, 0% 0%)',
      clipPath: 'polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 7% 50%, 0% 0%)',
      fontSize: '10px',
      fontWeight: 'bold',
    },
    active: {
      backgroundColor: theme.palette.applications.active,
    },
    inactive: {
      backgroundColor: theme.palette.applications.inactive,
    },
    trial: {
      backgroundColor: theme.palette.applications.trial,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    typo: {
      fontSize: '11px',
    },
    designation: {
      fontSize: '12px',
      fontWeight: '400',
    },
    socialPaper: {
      minHeight: '45px',
    },
    localBoldFonts: {
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    edit_link: {
      textDecoration: 'none',
      color: 'grey',
      border: '1px solid #d1d1d1',
      borderRadius: '56px 38px 54px 100px',
      borderTop: 'none',
      borderRight: 'none',
      backgroundColor: '#d1d1d1',
      padding: '2px 4px 1px 5px',
      position: 'absolute',
      top: '-2px',
      right: '-4px',
    },
    skills: {
      paddingTop: '3px',
      color: '#ababab',
      fontSize: '12px',
    },
    coworkerName: {
      marginTop: 10,
      textTransform: 'capitalize',
      color: '#4D7CFE ',
      fontWeight: '600',
    },
    coworkerDetail: {
      color: '#ababab',
      fontSize: '12px',
    },
    contentDataFonts: {
      fontSize: '12px',
    },
  }
}

function UserCard(props) {
  const { classes, data } = props

  return (
    <Card className={classes.card}>
      <Paper className={classes.containerPaper}>
        {checkPermission('USER_UPDATE') && (
          <Link className={classes.edit_link} to={`/user/edit/${data._id}`}>
            <CreateIcon fontSize="small" />
          </Link>
        )}

        <div className={classes.profileImage}>
          <div className={classes.image}>
            {data.profile_pic ? (
              <Avatar className={classes.avatar} src={data.profile_pic} />
            ) : (
              <Avatar className={classes.avatar} src={avatar} />
            )}
          </div>
        </div>
        <div className={classes.image} direction="row">
          <Typography
            component="h2"
            align="center"
            className={classes.coworkerName}
          >
            {data.first_name} {data.last_name}
          </Typography>
        </div>
        <div style={{ clear: 'both' }} />
        <Grid
          container
          alignItems="center"
          alignContent="center"
          direction="row"
          justify="center"
        >
          <Grid item>
            <Typography
              component="p"
              variant="subtitle2"
              className={classes.designation}
            >
              {spacecapitalize(data.designation)} @{' '}
              {data.space && data.space.space_title}
              {/* <NameTruncate text={data.designation} amount={20} />@
              {data.space && (
                <NameTruncate text={data.space.space_title} amount={20} />
              )} */}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          alignContent="center"
          direction="row"
          justify="center"
        >
          <Grid item>
            <Typography
              component="p"
              variant="subtitle1"
              className={classes.typo}
            >
              {data.contact_number} | {data.email}
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12}>
            <Grid
              container
              className={classes.iconContainer}
              justify="center"
              spacing={2}
            >
              <Grid item>
                <Paper className={classes.socialPaper}>
                  {data.linkedin ? (
                    <SocialButton type={Linkedin} url={data.linkedin} />
                  ) : (
                    <SocialButton type={LinkedinGrey} url={data.linkedin} />
                  )}
                </Paper>
              </Grid>
              <Grid item>
                <Paper className={classes.socialPaper}>
                  {data.facebook ? (
                    <SocialButton
                      type={FacebookRoundIcon}
                      url={data.facebook}
                    />
                  ) : (
                    <SocialButton type={FacebookGrey} url={data.facebook} />
                  )}
                </Paper>
              </Grid>
              <Grid item>
                <Paper className={classes.socialPaper}>
                  {data.twitter ? (
                    <SocialButton type={Twitter} url={data.twitter} />
                  ) : (
                    <SocialButton type={TwitterGrey} url={data.twitter} />
                  )}
                </Paper>
              </Grid>
              <Grid item>
                <Paper className={classes.socialPaper}>
                  {data.instagram ? (
                    <SocialButton type={Instagram} url={data.instagram} />
                  ) : (
                    <SocialButton type={InstagramGrey} url={data.instagram} />
                  )}
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Paper className={classes.containerPaper}>
        <CardContent className={classes.cardContent}>
          <Grid
            container
            className={classes.coworkerInfo}
            spacing={2}
            alignItems="center"
            alignContent="space-between"
            direction="row"
            justify="center"
          >
            <Grid item xs={4} spacing={3} className={classes.eventParticipant}>
              {data.skills && data.skills.length ? (
                <Typography variant="h3" component="p">
                  <span className={classes.contentDataFonts}>
                    <CardFooterViewMore text={data} isSmallFont />
                  </span>
                </Typography>
              ) : (
                <Typography variant="h3" component="p">
                  N.A.
                </Typography>
              )}
              <Typography variant="subtitle2" className={classes.skills}>
                Skill Sets
              </Typography>
            </Grid>

            <Grid item xs={4} spacing={3} className={classes.eventDate}>
              {data.blood_group ? (
                <Typography
                  variant="subtitle2"
                  component="p"
                  className={classes.localBoldFonts}
                >
                  {data.blood_group}
                </Typography>
              ) : (
                <Typography variant="h3" component="p">
                  N.A
                </Typography>
              )}
              <Typography
                variant="subtitle2"
                className={classes.coworkerDetail}
              >
                Blood Group
              </Typography>
            </Grid>

            <Grid item xs={4} spacing={3} className={classes.eventDate}>
              {data.date_of_birth ? (
                <Typography
                  variant="subtitle2"
                  component="p"
                  className={classes.localBoldFonts}
                >
                  {moment(data.date_of_birth).format('DD/MM/YYYY')}
                </Typography>
              ) : (
                <Typography variant="h3" component="p">
                  N.A.
                </Typography>
              )}
              <Typography
                variant="subtitle2"
                className={classes.coworkerDetail}
              >
                DOB
              </Typography>
            </Grid>
            {/* </div> */}
          </Grid>
        </CardContent>
      </Paper>
      {checkPermission('USER_STATUS_CHANGE') && (
        <div>
          <CardActions className={classes.cardFooter}>
            <UserStatus
              selectedItem={data.status ? data.status : ''}
              id={data._id ? data._id : ''}
              changeStatus={props.changeStatus}
            />
          </CardActions>
        </div>
      )}
    </Card>
  )
}
export default withStyles(styles)(UserCard)
