import React from "react";

const CoworkerSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="300" height="400" viewBox="0 0 350 200">
  <defs>
    <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
      <stop offset="0" stopColor="#ffb685"/>
      <stop offset="1" stopColor="#fcd147"/>
    </linearGradient>
  </defs>
  <g id="Group_352" data-name="Group 352" transform="translate(-192.471 -121.275)">
    <path id="Path_970" data-name="Path 970" d="M208.148,193.417c-9.908,4.976-12.707,15.164-12.161,26.241a37.1,37.1,0,0,0,15.535,28.126c7.181,5.027,16.044,7.386,22.851,12.909s10.729,16.569,4.667,22.9c-5.718,5.973-15.517,3.605-23.685,4.886-11.82,1.853-21.129,12.772-22.636,24.646s4.02,24.018,12.921,32.016,20.8,12.137,32.712,13.212c12.247,1.105,25.74-.506,35.735,6.659,13.105,9.393,14.313,29.94,27.656,38.991,9.1,6.171,21.481,5.068,31.6.782s18.8-11.321,28.252-16.932,20.486-9.9,31.278-7.818c12.3,2.376,21.466,12.314,31.947,19.181s26.324,9.939,34.216.206c9.136-11.27,1.128-32.13,13.258-40.085,5.743-3.766,13.449-2.354,19.741.4s12.182,6.723,18.945,7.911c11.713,2.056,23.98-5.453,28.83-16.316s2.779-24.108-3.913-33.943S518.4,300.98,506.912,297.9c-7.674-2.058-15.755-2.694-23.1-5.717s-14.152-9.319-14.552-17.256c-.625-12.387,13.214-19.754,20.393-29.866a30.753,30.753,0,0,0-22.5-48.453c-12.621-1.054-24.4,5.612-36.386,9.707s-27.362,4.748-35.179-5.221c-4.542-5.791-5.216-13.615-7.681-20.55a40.21,40.21,0,0,0-73.215-5.747c-6.641,12.207-6.693,27.268-13.865,39.17a37.959,37.959,0,0,1-36.609,17.613c-14.206-1.884-23.443-14.217-30.759-26.544C229.792,198.864,219.361,187.786,208.148,193.417Z" transform="translate(0 -5.157)" fill="url(#linear-gradient)"/>
    <g id="Group_470" data-name="Group 470" transform="translate(294.746 121.275)">
      <path id="Path_971" data-name="Path 971" d="M326.766,350.765a15.347,15.347,0,0,1,.974-1.472c.715-.993,6.354-.416,6.354-.416s.623,1.341.693,1.409.473,4.222.473,4.222l-6.2,1.468-2.124-2.228Z" transform="translate(-315.784 -157.331)" fill="#4c565e"/>
      <path id="Path_972" data-name="Path 972" d="M334.786,350.287c-.07-.068-.693-1.409-.693-1.409s-5.639-.577-6.354.416a15.347,15.347,0,0,0-.974,1.472v.013c1.405,1.757,6.47,2.275,8.132.284A6.091,6.091,0,0,0,334.786,350.287Z" transform="translate(-315.784 -157.331)" fill="#3e484f"/>
      <g id="Group_455" data-name="Group 455" transform="translate(10.686 191.323)">
        <path id="Path_973" data-name="Path 973" d="M326.574,351.9l-.16,5.44.748.562v4.833s.431,4.436,1.031,5.634A2.53,2.53,0,0,0,330.164,370a6.773,6.773,0,0,0,6.435-1.153,7.029,7.029,0,0,0-.294-2.645c-.264-.536-5.141-.45-5.141-.45l-4.307-11.943Z" transform="translate(-326.414 -349.159)" fill="#353d44"/>
        <path id="Path_974" data-name="Path 974" d="M334.777,348.89l-.732-.247a25.013,25.013,0,0,1,.719,4.721,24.671,24.671,0,0,0-7.115.617c-.417-2.381-.3-4.691-.576-4.5-1.466,2.417-.045,3.7.123,6.489.032,1.632.413,9.323,1.048,10.931.767,1.526,2.89,1.881,3.856,2.078,1.755.357,4.234-.908,4.357-1.708C337.194,366.507,335.722,354.153,334.777,348.89Z" transform="translate(-326.414 -348.643)" fill="#273035"/>
        <path id="Path_975" data-name="Path 975" d="M334.777,348.915l-.337-.113c.828,5.287,2.028,16.564,1.4,17.277-.108.771-2.276,1.989-3.812,1.646-.844-.189-2.7-.532-3.373-2-.556-1.55-.889-8.962-.917-10.537-.125-2.3-1.054-3.544-.507-5.319-.036-.273-.082-.415-.159-.363-1.466,2.418-.045,3.7.123,6.489.032,1.632.413,9.323,1.048,10.931.767,1.526,2.89,1.882,3.856,2.078,1.755.357,4.234-.908,4.357-1.708C337.194,366.532,335.722,354.178,334.777,348.915Z" transform="translate(-326.414 -348.668)" fill="#21292d"/>
      </g>
      <path id="Path_976" data-name="Path 976" d="M347.814,343.335s.6-.2.811.052-.777,1.442-.777,1.442-.179-.707-.179-.73S347.814,343.335,347.814,343.335Z" transform="translate(-319.098 -156.466)" fill="#353d44"/>
      <path id="Path_977" data-name="Path 977" d="M355.354,342.4V336.66H348.1v7.027l4.373,4.69,6.068-1.568s-.513-.991-.73-1.3A36.26,36.26,0,0,0,355.354,342.4Z" transform="translate(-319.165 -155.42)" fill="#4c565e"/>
      <path id="Path_978" data-name="Path 978" d="M355.483,342.544l-.13-.147V336.66H348.1v5.835C350.2,343.55,353.7,344.724,355.483,342.544Z" transform="translate(-319.165 -155.42)" fill="#3e484f"/>
      <g id="Group_456" data-name="Group 456" transform="translate(27.917 186.868)">
        <path id="Path_979" data-name="Path 979" d="M346.891,345.662v2.747c0,1.246,2.711,3.206,4.875,3.829a.2.2,0,0,0,.257-.208l-.024-.65a14.417,14.417,0,0,1,2.78,1.521c.112.081.223.676.335.76,1.571,1.182,3.2,2.185,4.535,3.115,1.964,1.363,5.733-.125,6.72-.554a.4.4,0,0,0,.241-.37v-.475l-3.992.1-6.813-3.933-8.013-4.723-.083-.79-.818-.371" transform="translate(-346.891 -343.716)" fill="#353d44"/>
        <path id="Path_980" data-name="Path 980" d="M366.461,354.313a48.15,48.15,0,0,1-7.681-6.673,12.371,12.371,0,0,0-2.03-3.192c-2.294,0-4.573,1.7-5.236,2.241-1.931-1.085-3.885-2.9-3.824-3.341a1.429,1.429,0,0,0-.8,1.335v1.9c0,1.3,2.965,3.372,5.143,3.9s5.773,3.384,8.045,4.962c1.969,1.367,5.549.128,6.469-.227a.345.345,0,0,0,.22-.322A.725.725,0,0,0,366.461,354.313Z" transform="translate(-346.891 -343.349)" fill="#273035"/>
        <path id="Path_981" data-name="Path 981" d="M366.461,354.744l-.008-.006c-1.042.392-4.463,1.519-6.374.192-2.272-1.578-5.868-4.434-8.045-4.962s-5.143-2.6-5.143-3.9v.952c0,1.3,2.965,3.372,5.143,3.9s5.773,3.384,8.045,4.962c1.969,1.367,5.549.128,6.469-.227a.345.345,0,0,0,.22-.322A.725.725,0,0,0,366.461,354.744Z" transform="translate(-346.891 -343.78)" fill="#21292d"/>
      </g>
      <g id="Group_458" data-name="Group 458" transform="translate(33.111 27.938)">
        <path id="Path_982" data-name="Path 982" d="M369.382,226.292s-.127-3.812-.365-5.056a10.818,10.818,0,0,1-1.006-3.186c.014-.247-4.81-25.723-4.81-25.723l-1.368-28.252c.042-2.2-.2-5.13-2.4-5.172s-3.579.169-3.621,2.373l-.6,31.341,0,.132a3.168,3.168,0,0,0,.122.916l8.225,28.146c-.033,1.269-.141,2.69,0,7.673s3.106,3.517,3.465,2.87C368.158,229.662,369.382,226.292,369.382,226.292Z" transform="translate(-353.404 -155.177)" fill="#ffcbae"/>
        <g id="Group_457" data-name="Group 457">
          <path id="Path_983" data-name="Path 983" d="M362.267,218.383c4.391,1.186,6.409-1,6.409-1l-4.966-26.1s1.1-21.393-.737-29.389c-1.478-6.432-6.7-7.421-6.7-7.421-3.1.024-3.235,3.3-3.212,6.4l1.129,31.954Z" transform="translate(-353.064 -154.476)" fill="#273035"/>
        </g>
        <path id="Path_984" data-name="Path 984" d="M363.965,191.286s1.1-21.393-.736-29.389c-1.478-6.433-6.7-7.421-6.7-7.421a2.8,2.8,0,0,0-1.864.623,3.394,3.394,0,0,1,.988-.145s5.218.988,6.7,7.42c1.838,8,.737,29.389.737,29.389l4.966,26.1a3.2,3.2,0,0,1-.458.374,4.229,4.229,0,0,0,1.334-.852Z" transform="translate(-353.318 -154.476)" fill="#1f272b"/>
      </g>
      <g id="Group_459" data-name="Group 459" transform="translate(20.39 73.636)">
        <path id="Path_985" data-name="Path 985" d="M342.167,209.968c-9.524,5.566-.375,48.732,2.637,65.6-1.2,4.886.531,12.157.8,21.261.3,10.224-.431,23.479-.431,23.479,1.6,1.023,7.387,4.04,9.249-.072,0,0,2.263-31.033,2.386-40.89a342.57,342.57,0,0,0,2.487-58.391C358.305,216.23,350.391,205.161,342.167,209.968Z" transform="translate(-337.946 -208.784)" fill="#353d44"/>
        <path id="Path_986" data-name="Path 986" d="M360.22,220.955c-.958-4.589-8.453-15.159-16.418-11.358,7.12-1.235,13.395,7.877,14.266,12.049a342.667,342.667,0,0,1-2.487,58.391c-.124,9.857-2.386,40.89-2.386,40.89a3.509,3.509,0,0,1-1.111,1.4,3.412,3.412,0,0,0,3.263-2.094s2.263-31.033,2.386-40.89A342.57,342.57,0,0,0,360.22,220.955Z" transform="translate(-338.875 -208.784)" fill="#2f373d"/>
        <path id="Path_987" data-name="Path 987" d="M348.276,234.621s-4.756-3.842-10.291-3.7c.417,15.6,4.889,37.314,6.825,48.157-1.2,4.886.531,12.157.8,21.261.3,10.224-.431,23.479-.431,23.479a15.854,15.854,0,0,0,2.768,1.372l-.374-37.368S350.617,261.4,348.276,234.621Z" transform="translate(-337.952 -212.292)" fill="#2b343a"/>
        <path id="Path_988" data-name="Path 988" d="M348.128,234.783a18.257,18.257,0,0,0-2.646-1.921,15.738,15.738,0,0,0-7.5-1.943c.417,15.6,4.889,37.314,6.825,48.157a21.835,21.835,0,0,0-.356,6.207C345.6,278.084,348.639,256.665,348.128,234.783Z" transform="translate(-337.952 -212.292)" fill="#20282d"/>
      </g>
      <g id="Group_460" data-name="Group 460" transform="translate(8.954 91.238)">
        <path id="Path_989" data-name="Path 989" d="M329.714,231.215c-9.524,5.566-3.8,39.584-2.082,56.341-2.007,3.636-.624,11.526-.518,18.844.147,10.17-1.255,23.542-1.255,23.542.134,2.8,8.663,3.767,9.021-.081l4.4-42.489s6.012-25.911,6.711-52.78C345.99,234.591,337.937,226.409,329.714,231.215Z" transform="translate(-324.356 -229.702)" fill="#353d44"/>
      </g>
      <path id="Path_990" data-name="Path 990" d="M345.515,233.73A337.211,337.211,0,0,1,338.2,286.8l-4.973,44.743a2.475,2.475,0,0,1-.671,1.482c1.837-.122,3.472-.885,3.625-2.524l4.4-42.489s6.012-25.911,6.711-52.78A17.12,17.12,0,0,0,345.515,233.73Z" transform="translate(-316.702 -139.103)" fill="#2f373d"/>
      <path id="Path_991" data-name="Path 991" d="M357.5,235.427c-.009-6.154-.177-10.85-.273-13.574q-.128-.849-.255-1.719c-1.622-5.056-9.122-14.714-16.9-10.167-3.269,1.91-4.338,8.251-4.21,16.436a10.694,10.694,0,0,0-6.089,1.494c-3.023,1.766-4.505,6.4-5.072,12.44,3.175,2.029,9.244,1.787,14.674,1.24C345.242,240.989,354.281,238.595,357.5,235.427Z" transform="translate(-315.456 -135.148)" fill="#2f373d" opacity="0.2"/>
      <g id="Group_461" data-name="Group 461" transform="translate(4.523 27.202)">
        <path id="Path_992" data-name="Path 992" d="M357.588,221.421c0-5.54-2.914-18.116-3.157-28.176-.268-11.066,3.157-19.5-.061-27.966-1.537-4.042-3.661-13.646-9.533-11.318-4.607,1.967-7.394,1.823-9.484,3.065-5.95,6.6-11.4,4.679-14.847,10.889-4.33,7.249,2.121,22.808,8.026,37.317.036,2.674-4.121,7.6-7.066,18.432-.369,6.649,8.935,6.5,16.874,5.7C345.273,228.665,356.629,225.452,357.588,221.421Z" transform="translate(-319.091 -153.602)" fill="#bababa"/>
        <path id="Path_993" data-name="Path 993" d="M358.532,165.279c-1.537-4.042-3.661-13.646-9.533-11.318a29.624,29.624,0,0,1-3.65,1.283c3.5,13.493,10.367,44.356,8.2,71.685,4.2-1.429,7.688-3.351,8.2-5.508,0-5.54-2.914-18.116-3.157-28.176C358.326,182.179,361.751,173.744,358.532,165.279Z" transform="translate(-323.253 -153.602)" fill="#a7a9aa"/>
      </g>
      <path id="Path_994" data-name="Path 994" d="M358.2,194.381s2.4,22.914,1.623,30.18a7,7,0,0,0,4.517-4.061s-4.033-25.118-3.432-28.688.37-15.3.363-21.642-4.236-16.718-12.1-16.145,3.684,6.76,3.684,6.76l1.372,5.171,3.43,20.576Z" transform="translate(-318.901 -126.463)" fill="#31393f"/>
      <path id="Path_995" data-name="Path 995" d="M358.132,194.85s-2.73-30.4-8.7-34.731l-3.4-3.156a17.087,17.087,0,0,1,9.6,3.652l-1.819,2.84,3.262.475S358.664,193.968,358.132,194.85Z" transform="translate(-318.838 -126.933)" fill="#646f77"/>
      <path id="Path_996" data-name="Path 996" d="M347.534,165.284c-.348,1.637-3.5,2.354-7.046,1.6s-6.131-2.694-5.782-4.332,3.5-2.354,7.046-1.6S347.884,163.647,347.534,165.284Z" transform="translate(-317.038 -127.517)" fill="#898989"/>
      <path id="Path_997" data-name="Path 997" d="M371.179,238.523c-.523.831-2.309.108-2.124-2.909C368.285,237.83,369.812,240.9,371.179,238.523Z" transform="translate(-322.455 -139.401)" fill="#e5ac8f"/>
      <g id="Group_463" data-name="Group 463" transform="translate(10.499 3.339)">
        <path id="Path_998" data-name="Path 998" d="M336.356,152.23a37.11,37.11,0,0,1-1.02,9.184c2.007,3.188,10.615,5.181,11.914,1.522a10.527,10.527,0,0,1,1-5.585C349.61,155.028,336.356,152.23,336.356,152.23Z" transform="translate(-327.642 -129.521)" fill="#dda07f"/>
        <path id="Path_999" data-name="Path 999" d="M336.542,152.23s.03.855-.034,2.212a13.536,13.536,0,0,0,10.887,7.487,9.491,9.491,0,0,1,1.044-4.578C349.8,155.028,336.542,152.23,336.542,152.23Z" transform="translate(-327.827 -129.521)" fill="#cc8a6d"/>
        <path id="Path_1000" data-name="Path 1000" d="M349.133,130.822a22.252,22.252,0,0,1,2.664,9.7c-.2.673.953,1.962.673,4.767-.2,2.036-.614,6.173-.824,8.285a1.745,1.745,0,0,1-1.43,1.555c-4.089.721-10.631,1.139-15.411-5.353,0,0-2.3-2.692-3.365-3.028s-5.247-5.672-5.247-9.991c0-4.576,2.527-11.516,11.921-11.516A13.087,13.087,0,0,1,349.133,130.822Z" transform="translate(-326.192 -125.243)" fill="#eab18b"/>
        <g id="Group_462" data-name="Group 462" transform="translate(9.074 3.689)">
          <path id="Path_1001" data-name="Path 1001" d="M353.507,141.22a22.252,22.252,0,0,0-2.664-9.7,10.666,10.666,0,0,0-1.6-1.89c.23.3.344.492.344.492a22.252,22.252,0,0,1,2.664,9.7c-.2.672.953,1.962.673,4.767-.2,2.036-.614,6.173-.824,8.285a1.744,1.744,0,0,1-1.43,1.554c-3.617.639-9.152,1.029-13.691-3.381,4.733,5.872,10.99,5.478,14.95,4.78a1.745,1.745,0,0,0,1.43-1.555c.21-2.112.62-6.249.824-8.285C354.46,143.182,353.311,141.893,353.507,141.22Z" transform="translate(-336.976 -129.627)" fill="#e0a380"/>
        </g>
      </g>
      <path id="Path_1002" data-name="Path 1002" d="M336.206,135.169c2.543,1.847-1.032,9.355-.539,11.276-.739.32-1.214-2.161-2.423-2.09s-.2,1.61-.2,1.61l1.719,3.879-.154,2.772c-.392-4.549-9.9-5.657-9.287-16.963.184-2.77,2.478-6.429,3.31-5.655S335.507,132.526,336.206,135.169Z" transform="translate(-315.551 -122.641)" fill="#6d5e57"/>
      <path id="Path_1003" data-name="Path 1003" d="M338.264,202.785c.223,2.7.061,24.768-3.459,32.34-4.444.185-14.976-2.411-16.672-6.095,0,0,8.114-15.755,7.569-21.608s-3.283-17.176-3.283-17.176l-1.13-26.163s7.62-4.957,12.225-5.973l.125,1.555-.29-.069-.565.21-1.231,1.485-.83,5.415,2.407,6.21,1.724,6.434,2.236,17.914Z" transform="translate(-314.415 -127.115)" fill="#31393f"/>
      <g id="Group_464" data-name="Group 464" transform="translate(6.884 37.158)">
        <path id="Path_1004" data-name="Path 1004" d="M323.016,191.408s1.633,6.754,2.628,12.526L328,177.439c.527-6.49-1-9.376-2.605-10.815a4.525,4.525,0,0,0-3.5-1.156Z" transform="translate(-321.896 -165.434)" fill="#273035"/>
      </g>
      <path id="Path_1005" data-name="Path 1005" d="M335.278,159.893c-5.682,3.929,7.04,21.615,4.95,43.175,0,0-3.459-13.77-7.589-25.937l1.862-3.572-3.978-.929C330.7,171.472,331.171,161.015,335.278,159.893Z" transform="translate(-316.38 -127.397)" fill="#646f77"/>
      <g id="Group_468" data-name="Group 468" transform="translate(0 36.727)">
        <g id="Group_465" data-name="Group 465" transform="translate(0.54 5.595)">
          <path id="Path_1006" data-name="Path 1006" d="M318.187,174.965c-.378,2.5-.7,5-1.051,7.5l-.962,7.51c-.332,2.5-.6,5.013-.9,7.519l-.881,7.52-.014.119a3.381,3.381,0,0,0,0,.72l.99,9.144c.337,3.047.643,6.1,1.012,9.142l1.071,9.138c.389,3.043.747,6.089,1.162,9.129a2.878,2.878,0,0,0,3.241,2.464c1.474-.2,1.747-2.072,1.7-3.526-.106-3.067.519-5.555.387-8.62l-1.935-8.563c-.151-3.063-.463-6.59-.646-9.65L319.812,204.5l-.017.839,1.286-7.679c.332-2.5,1.685-4.481,1.986-6.987l2.193-7.181c.281-2.508.592-5.013.847-7.525a3.992,3.992,0,0,0-7.919-1Z" transform="translate(-314.357 -171.57)" fill="#ffcbae"/>
        </g>
        <path id="Path_1007" data-name="Path 1007" d="M319.42,255.476s-.242,4.546.682,6.146,3.856,3.648,5.495,3.579a3.544,3.544,0,0,0,2.216-.624c-.67-1.5-3.036-3.1-3.1-5.738-.2-1.6.467-.772.467-.426a4.558,4.558,0,0,0,.993,2.193c.461.323.675.185.554-1.362a12.77,12.77,0,0,0-.392-3.093c-.323-1.131-1.467-1.118-1.785-2.268a6.363,6.363,0,0,1-.2-2.164l-5.4.433A7.439,7.439,0,0,1,319.42,255.476Z" transform="translate(-314.545 -178.682)" fill="#ffcbae"/>
        <g id="Group_466" data-name="Group 466" transform="translate(4.404 73.037)">
          <path id="Path_1008" data-name="Path 1008" d="M325.728,260.065a4.348,4.348,0,0,1-.037-.48c-.184-.241-.446-.271-.309.814.061,2.58,2.326,4.169,3.049,5.643a1.759,1.759,0,0,0,.4-.239C328.157,264.3,325.791,262.7,325.728,260.065Z" transform="translate(-319.963 -252.944)" fill="#f2b699"/>
          <path id="Path_1009" data-name="Path 1009" d="M324,252.053a6.367,6.367,0,0,0,.2,2.165c.316,1.149,1.461,1.136,1.784,2.268a12.8,12.8,0,0,1,.393,3.093,5.439,5.439,0,0,1,.005,1.142c.31.113.442-.2.341-1.477a12.77,12.77,0,0,0-.392-3.093c-.323-1.131-1.467-1.118-1.784-2.268a6.351,6.351,0,0,1-.2-2.164l-5.4.433s.047.109.11.3Z" transform="translate(-318.949 -251.719)" fill="#f2b699"/>
        </g>
        <g id="Group_467" data-name="Group 467">
          <path id="Path_1010" data-name="Path 1010" d="M317.721,239.356c.388-.3,1.117.749,2.752.924,1.5.161,3.925-.514,4.053-1.42.426-3.233-2.5-34.144-2.5-34.144l4.674-27.358c1.048-6.426-.242-9.425-1.726-10.989-1.622-1.709-4.813-2.333-5.965.546-2.512,6.279-5.294,36.016-5.294,36.016S317.241,238.444,317.721,239.356Z" transform="translate(-313.715 -164.922)" fill="#273035"/>
        </g>
        <path id="Path_1011" data-name="Path 1011" d="M326.134,166.379a4.743,4.743,0,0,0-2.624-1.4,4.688,4.688,0,0,1,.464.419c1.484,1.563,2.773,4.562,1.726,10.989l-4.674,27.359s3.6,29.6,3.7,36.017c.53-.243.907-.553.955-.894.426-3.233-2.5-34.144-2.5-34.144l4.674-27.358C328.907,170.941,327.618,167.942,326.134,166.379Z" transform="translate(-314.874 -164.931)" fill="#1f272b"/>
      </g>
      <g id="Group_469" data-name="Group 469" transform="translate(14.638 20.445)">
        <path id="Path_1012" data-name="Path 1012" d="M334.166,146.925c-1.785-1.859-4.047-.266-2.585,1.945.314.475,1.007.753,1.254,1.193.138.246.271.454.4.645a16.566,16.566,0,0,1,1.225,1.154,1.067,1.067,0,0,0,1.2-.408Z" transform="translate(-331.111 -145.657)" fill="#d39779" opacity="0.4"/>
        <path id="Path_1013" data-name="Path 1013" d="M335.78,146.939c-1.124-2.319-3.767-1.5-3.059,1.054.152.549.726,1.027.825,1.521.5,2.476,1.4,2.7,2.262,2.2" transform="translate(-331.348 -145.572)" fill="#eab18b"/>
      </g>
      <path id="Path_1014" data-name="Path 1014" d="M335.839,137.466c3.768,1.431,12.973-3.023,14.769-3.916,3.746-3.533,2.031-9.822-1.616-12.275-1.932,1.754-3.813,2.8-8.6,1.877s-14.2,2.924-14.2,9.819C328.639,136.065,335.839,137.466,335.839,137.466Z" transform="translate(-315.693 -121.275)" fill="#60554f"/>
      <path id="Path_1015" data-name="Path 1015" d="M351.058,121.275l-.018.015a7.373,7.373,0,0,1-3.666,9.729c-4.52,2.355-6.813,4.711-8.149,6.708,4.261.29,11.838-3.376,13.449-4.178C356.42,130.016,354.706,123.727,351.058,121.275Z" transform="translate(-317.759 -121.275)" fill="#544843"/>
    </g>
    <g id="Group_483" data-name="Group 483" transform="translate(411.146 123.216)">
      <path id="Path_1016" data-name="Path 1016" d="M495.226,184.815l.431-6.9,5.264-1.878,1.918,6.184-4.146,3.327Z" transform="translate(-458.61 -131.899)" fill="#eab18b"/>
      <path id="Path_1017" data-name="Path 1017" d="M501.85,178.934l-.9-2.891-5.263,1.877-.259,4.143C497.965,181.449,500.718,180.135,501.85,178.934Z" transform="translate(-458.643 -131.899)" fill="#db9b77"/>
      <path id="Path_1018" data-name="Path 1018" d="M499.641,173.315c.523,2.257-10.135,6.505-11.827,3.793,0,0-4.448-10.364-4.953-13-.864-4.5,1.283-8.424,5.8-8.981C493.42,154.543,498.017,166.292,499.641,173.315Z" transform="translate(-456.62 -128.58)" fill="#afd2db"/>
      <path id="Path_1019" data-name="Path 1019" d="M500.215,173.315c-1.624-7.023-6.221-18.772-10.985-18.184a7.083,7.083,0,0,0-2.941,1.012,7.475,7.475,0,0,1,1.876-.482c4.764-.588,9.361,11.161,10.985,18.184.177.763-.927,1.754-2.529,2.627C498.77,175.495,500.428,174.238,500.215,173.315Z" transform="translate(-457.194 -128.58)" fill="#9bc4ce"/>
      <path id="Path_1020" data-name="Path 1020" d="M459.944,349.682a40.168,40.168,0,0,0,.476-6.086c.422-1.149,7.2.027,7.2.027s-.283,3.469-.214,3.538c.248.243,1.022,6.3,1.022,6.3l-6.055,1.434-2.124-2.228Z" transform="translate(-453.017 -158.381)" fill="#384444"/>
      <path id="Path_1021" data-name="Path 1021" d="M460.492,343.6c0,.941-.039,1.834-.093,2.636,1.47.785,4.556.962,7.087.21.056-1.033.2-2.819.2-2.819S460.914,342.447,460.492,343.6Z" transform="translate(-453.089 -158.381)" fill="#2c3838"/>
      <path id="Path_1022" data-name="Path 1022" d="M488.584,342.162v-5.736h-7.259a41.265,41.265,0,0,1-.539,5.114c0,2.642,4.913,6.6,4.913,6.6l6.069-1.568s-.514-.991-.73-1.3A36.454,36.454,0,0,0,488.584,342.162Z" transform="translate(-456.321 -157.324)" fill="#384444"/>
      <path id="Path_1023" data-name="Path 1023" d="M481,340.529a12.9,12.9,0,0,0,7.622-.014v-4.089h-7.259S481.253,338.732,481,340.529Z" transform="translate(-456.354 -157.324)" fill="#2c3838"/>
      <g id="Group_471" data-name="Group 471" transform="translate(16.465 71.498)">
        <path id="Path_1024" data-name="Path 1024" d="M475.5,209.734c-9.524,5.566-.375,48.732,2.638,65.6-1.2,4.886.53,12.157.8,21.261.3,10.225-.325,21.376-.325,21.376,1.6,1.022,7.388,1.425,9.249-.073,0,0,2.157-28.929,2.28-38.786a342.662,342.662,0,0,0,2.487-58.391C491.637,216,483.723,204.927,475.5,209.734Z" transform="translate(-471.278 -208.55)" fill="#384444"/>
        <path id="Path_1025" data-name="Path 1025" d="M494.12,220.721c-.836-4-6.648-12.56-13.407-12.152,5.417,1.83,9.685,8.556,10.394,12a354.817,354.817,0,0,1-2.619,59.958c-.121,8.745-1.81,32.113-2.267,38.3a6.066,6.066,0,0,0,3.131-.932s2.157-28.929,2.28-38.786A342.666,342.666,0,0,0,494.12,220.721Z" transform="translate(-472.774 -208.55)" fill="#323d3d"/>
        <path id="Path_1026" data-name="Path 1026" d="M481.483,234.581s-4.684-3.78-10.162-3.7c.445,15.592,4.892,37.187,6.823,47.992-1.2,4.886.53,12.157.8,21.261.3,10.225-.326,21.376-.326,21.376a7.267,7.267,0,0,0,2.631.756l-.433-34.485S483.841,261.355,481.483,234.581Z" transform="translate(-471.285 -212.09)" fill="#2e3a3a"/>
        <path id="Path_1027" data-name="Path 1027" d="M477.916,286.993a305.494,305.494,0,0,0,3.833-49.056c-.081-1.117-.167-2.236-.265-3.356,0,0-4.684-3.78-10.162-3.7.445,15.592,4.892,37.187,6.823,47.992A26.578,26.578,0,0,0,477.916,286.993Z" transform="translate(-471.285 -212.09)" fill="#273333"/>
      </g>
      <g id="Group_472" data-name="Group 472" transform="translate(3.993 89.1)">
        <path id="Path_1028" data-name="Path 1028" d="M462.85,230.981c-2.094,1.224-4.667-1.4-5.491,2.089-2.923,12.38,2.072,41.181,3.409,54.252-2.007,3.636-.623,11.526-.517,18.844.146,10.17-.6,17.9-.6,17.9.134,2.8,8.662,3.766,9.021-.081l3.745-36.845s6.012-25.912,6.711-52.78C479.127,234.357,471.074,226.174,462.85,230.981Z" transform="translate(-456.457 -229.468)" fill="#384444"/>
      </g>
      <path id="Path_1029" data-name="Path 1029" d="M477.784,232.527c-.852,28.187-7.11,55.379-7.11,55.379l-4.005,38.7a2.654,2.654,0,0,1-.069.394c1.859-.111,3.526-.873,3.68-2.529l3.745-36.845s6.012-25.912,6.711-52.78A18.787,18.787,0,0,0,477.784,232.527Z" transform="translate(-454.072 -140.853)" fill="#323d3d"/>
      <g id="Group_474" data-name="Group 474" transform="translate(1.286 27.054)">
        <g id="Group_473" data-name="Group 473">
          <path id="Path_1030" data-name="Path 1030" d="M490.519,212.936c0-5.541-1.733-7.455-1.977-17.515-.268-11.065,3.394-20.787.176-29.252-1.536-4.042-3.9-12.36-9.77-10.032-4.607,1.967-7.417-.122-9.507,1.119-5.555,4.2-13.062,5.388-15.721,10.9-.831,6.787-1.257,18.1,4.648,32.613,0,0,.308,11.481-2.15,15.428-.369,6.648,4.911,9.163,12.85,8.364C476,223.861,489.56,216.967,490.519,212.936Z" transform="translate(-453.24 -155.733)" fill="#d8eaef"/>
        </g>
        <path id="Path_1031" data-name="Path 1031" d="M493.65,195.422c-.268-11.066,3.394-20.787.176-29.252-1.408-3.7-3.51-10.988-8.367-10.4,3.6,1.247,5.094,7.768,6.178,11.307,2.823,9.224-1.189,19.538-1.383,31.447-.175,10.789,1.444,12.937,1.228,18.849,2.248-1.575,3.84-3.152,4.145-4.439C495.626,207.4,493.892,205.482,493.65,195.422Z" transform="translate(-458.348 -155.734)" fill="#bfdbe2"/>
        <path id="Path_1032" data-name="Path 1032" d="M457.256,229.886c1.331,1.861,5.361,6.773,14.728,4.557s17.616-7.687,19.225-11.134v2.9c-1.609,3.448-9.858,8.918-19.225,11.134s-13.4-2.7-14.728-4.556Z" transform="translate(-453.877 -166.446)" fill="#3b5056"/>
        <g id="Path_1033" data-name="Path 1033" transform="translate(-457.861 -167.718)" fill="none" strokeMiterlimit="10">
          <path d="M483.047,233.073l3.275-1.672a.6.6,0,0,1,.874.566l-.111,2.17a1.163,1.163,0,0,1-.632.974l-3.111,1.588a.658.658,0,0,1-.957-.62l.11-2.157A1.014,1.014,0,0,1,483.047,233.073Z" stroke="none"/>
          <path d="M 486.292724609375 232.4048461914062 L 483.4482421875 233.8570861816406 C 483.4055786132812 233.87890625 483.3775939941406 233.9215698242188 483.3751831054688 233.9682464599609 L 483.2845458984375 235.7415008544922 L 486.0534057617188 234.3282318115234 C 486.1424560546875 234.2827911376953 486.2010803222656 234.1920623779297 486.2063293457031 234.0929107666016 L 486.292724609375 232.4048461914062 M 486.5951232910156 231.3348083496094 C 486.9210510253906 231.3348083496094 487.2149658203125 231.6061706542969 487.196533203125 231.9677581787109 L 487.08544921875 234.1379089355469 C 487.0635681152344 234.5519104003906 486.8229064941406 234.9238433837891 486.4534912109375 235.1123352050781 L 483.3425903320312 236.7001800537109 C 483.2444763183594 236.7503356933594 483.1424865722656 236.7733001708984 483.0433044433594 236.7733001708984 C 482.6865539550781 236.7733001708984 482.3654479980469 236.4763946533203 482.3858337402344 236.0800170898438 L 482.4960632324219 233.9233245849609 C 482.5145874023438 233.5623321533203 482.7249450683594 233.2383728027344 483.0472412109375 233.0734558105469 L 486.3222351074219 231.4014434814453 C 486.4116516113281 231.3557281494141 486.504638671875 231.3348083496094 486.5951232910156 231.3348083496094 Z" stroke="none" fill="#b29a7b"/>
        </g>
      </g>
      <path id="Path_1034" data-name="Path 1034" d="M469.348,340.161c.544.123.037,2.949.141,3.755.038.241-.127.433-.387.555-3.42,1.611-8.95,1.006-9.162-.725.079-1.743-.259-3.349.394-3.386l-.01.552c.305.555,8.449.881,8.985-.327Z" transform="translate(-453.013 -157.917)" fill="#4c7271"/>
      <g id="Group_475" data-name="Group 475" transform="translate(6.91 182.244)">
        <path id="Path_1035" data-name="Path 1035" d="M470.5,340.161l-.039.425c-.118.267-.608.458-1.3.589.045.923-.181,2.479-.108,3.044.038.241-.128.432-.387.555a8.947,8.947,0,0,1-1.5.534,10.307,10.307,0,0,0,3.088-.837c.26-.122.425-.315.387-.555C470.533,343.11,471.04,340.284,470.5,340.161Z" transform="translate(-461.071 -340.161)" fill="#3b6361"/>
        <path id="Path_1036" data-name="Path 1036" d="M467.1,341.34c-2.6.268-6.568-.005-6.78-.39l.01-.552c-.347.019-.411.487-.411,1.176C461.859,341.823,465.606,341.86,467.1,341.34Z" transform="translate(-459.924 -340.199)" fill="#3b6361"/>
      </g>
      <path id="Path_1037" data-name="Path 1037" d="M480.055,334.942c-.551.139-.242,2.917-.324,3.726-.031.241.143.428.411.543,3.536,1.5,9.45.763,9.615-.974-.134-1.74.164-3.356-.5-3.373l.026.552c-.294.564-8.591,1.139-9.173-.05Z" transform="translate(-456.152 -157.077)" fill="#4c7271"/>
      <g id="Group_476" data-name="Group 476" transform="translate(23.569 177.787)">
        <path id="Path_1038" data-name="Path 1038" d="M487.254,335.9c-2.578.285-6.739.316-7.146-.515l-.052-.423c-.249.062-.322.665-.335,1.38C482.123,336.654,486.513,336.294,487.254,335.9Z" transform="translate(-479.721 -334.879)" fill="#3b6361"/>
        <path id="Path_1039" data-name="Path 1039" d="M490.466,334.864l.026.552a3.3,3.3,0,0,1-1.542.406c.1.625.01,1.683.1,2.8-.053.56-.709,1.015-1.685,1.326,1.971-.208,3.525-.831,3.608-1.709C490.835,336.5,491.133,334.882,490.466,334.864Z" transform="translate(-480.932 -334.864)" fill="#3b6361"/>
      </g>
      <path id="Path_1040" data-name="Path 1040" d="M480.175,172.249s2.966,12.273.114,31.11c.889,2.927,2.112,10.82,2.112,10.82s3.154-8.889,3.94-11.59c.541-19.665-5.38-30.5-5.38-30.5Z" transform="translate(-456.224 -131.271)" fill="#bfdbe2"/>
      <path id="Path_1041" data-name="Path 1041" d="M480.175,172.24s3.438,11.919,1.317,30.495c1,2.835,2.529,10.527,2.529,10.527s2.807-8.834,3.488-11.512c-.219-19.3-6.555-29.7-6.555-29.7Z" transform="translate(-456.224 -131.266)" fill="#44647f"/>
      <path id="Path_1042" data-name="Path 1042" d="M474.333,165.86l1.651,1.49,2.519.232,3.142-1.027-.721-2.658-5.379,1.177Z" transform="translate(-455.298 -129.973)" fill="#eab18b"/>
      <path id="Path_1043" data-name="Path 1043" d="M477.168,170.053s.624,2.078,1.547,2.262a3.615,3.615,0,0,0,2.932-1.5,4.743,4.743,0,0,0,.115-2.571c-.254-.315-1.6-.232-1.6-.232l-.759.173s-2.2,1.356-2.207,1.42S477.168,170.053,477.168,170.053Z" transform="translate(-455.748 -130.624)" fill="#44647f"/>
      <path id="Path_1044" data-name="Path 1044" d="M479.289,166.89a6.1,6.1,0,0,1,6.276,1.862s.373-6.123-3.736-9.226C482.336,160.718,483.768,164.335,479.289,166.89Z" transform="translate(-456.084 -129.28)" fill="#bfd7dd"/>
      <path id="Path_1045" data-name="Path 1045" d="M468.866,160.552a4.2,4.2,0,0,0-2.307,3.084s2.358,6.864,7.35,10.465c0,0,.776-4.936,3.362-7.049C476.009,166.622,469.022,164.405,468.866,160.552Z" transform="translate(-454.066 -129.443)" fill="#bfd7dd"/>
      <path id="Path_1046" data-name="Path 1046" d="M485.042,183.482,468.8,193.355l-.962-6.349-8.31,2.8L462.589,199a4.952,4.952,0,0,0,7.919,2.194l16.423-14.069,1.665,1.07s1.385.231,2.471-2.493S487.858,182.189,485.042,183.482Z" transform="translate(-452.95 -132.982)" fill="#eab18b"/>
      <path id="Path_1047" data-name="Path 1047" d="M467.834,187.785l-8.31,2.8,1.336,4.005c2.55-.481,5.935-1.955,7.466-3.566Z" transform="translate(-452.95 -133.76)" fill="#db9b77"/>
      <path id="Path_1048" data-name="Path 1048" d="M491.508,188.582s1.385.231,2.471-2.493c.162-.407-.257,0-.313-.324l-8.13-.423-7.65,4.65v1.77l7.635-.547,4.322-3.7Z" transform="translate(-455.861 -133.373)" fill="#d88d68"/>
      <path id="Path_1049" data-name="Path 1049" d="M472.445,193.24l9.556.717,5.934-5.083-7.336-.589Z" transform="translate(-454.999 -133.84)" fill="#d88d68"/>
      <path id="Path_1050" data-name="Path 1050" d="M456.964,189.006c1.108,2.034,11.9-1.864,11.363-5.017,0,0-3.554-10.7-4.924-13.006-2.343-3.942-6.828-5.065-10.406-1.4C449.646,173.022,453.517,182.675,456.964,189.006Z" transform="translate(-451.712 -130.521)" fill="#afd2db"/>
      <path id="Path_1051" data-name="Path 1051" d="M469.241,183.989s-3.554-10.7-4.924-13.006c-1.6-2.7-4.21-4.073-6.842-3.5a8.546,8.546,0,0,1,4.412,3.872c1.516,2.549,5.671,14.26,5.671,14.26a1.786,1.786,0,0,1-.161,1.112C468.613,185.861,469.394,184.895,469.241,183.989Z" transform="translate(-452.626 -130.522)" fill="#9bc4ce"/>
      <path id="Path_1052" data-name="Path 1052" d="M499.19,180.688l1.111,3.989a4.4,4.4,0,0,1-3.925,5.571l-20.028,1.434v-3.968c0-1.628.849-2.154,2.475-2.246l15.359-.609" transform="translate(-455.618 -132.635)" fill="#eab18b"/>
      <path id="Path_1053" data-name="Path 1053" d="M477.448,186.438s-7.134,1.133-5.991,7.413c0,0,5.362-1.432,6.571-2.586a8.778,8.778,0,0,0,1.837-2.709Z" transform="translate(-454.823 -133.547)" fill="#eab18b"/>
      <g id="Group_477" data-name="Group 477" transform="translate(6.435 188.779)">
        <path id="Path_1054" data-name="Path 1054" d="M459.518,351.054l-.159,5.44.748.562v4.833s.43,4.436,1.031,5.634a2.53,2.53,0,0,0,1.972,1.631A6.773,6.773,0,0,0,469.544,368a7.03,7.03,0,0,0-.294-2.645c-.264-.536-5.141-.45-5.141-.45L459.8,352.962Z" transform="translate(-459.359 -348.423)" fill="#353d44"/>
        <path id="Path_1055" data-name="Path 1055" d="M467.4,348.009l-.089-.082s.294,2.192.4,4.613a24.7,24.7,0,0,0-7.115.617c-.417-2.381-.3-4.691-.576-4.5-1.467,2.418-.045,3.7.123,6.489.032,1.632.413,9.323,1.049,10.931.767,1.526,2.89,1.882,3.856,2.078,1.754.357,4.234-.908,4.357-1.708C470.139,365.682,468.345,353.272,467.4,348.009Z" transform="translate(-459.359 -347.927)" fill="#44647f"/>
        <path id="Path_1056" data-name="Path 1056" d="M467.4,348.024h0c.828,5.287,2.015,16.508,1.386,17.22-.108.772-2.277,1.991-3.812,1.647-.844-.189-2.7-.532-3.373-2-.556-1.55-.889-8.962-.917-10.537-.125-2.3-1.054-3.544-.507-5.319-.036-.273-.082-.415-.159-.363-1.467,2.418-.045,3.7.123,6.489.032,1.632.413,9.323,1.049,10.931.767,1.526,2.89,1.882,3.856,2.078,1.754.357,4.234-.908,4.357-1.708C470.139,365.7,468.345,353.287,467.4,348.024Z" transform="translate(-459.359 -347.942)" fill="#375968"/>
      </g>
      <g id="Group_478" data-name="Group 478" transform="translate(23.665 184.216)">
        <path id="Path_1057" data-name="Path 1057" d="M479.836,344.817v2.747c0,1.246,2.711,3.206,4.875,3.829a.2.2,0,0,0,.257-.208l-.024-.65a14.417,14.417,0,0,1,2.78,1.521c.111.081.223.676.335.76,1.571,1.182,3.2,2.184,4.535,3.115,1.964,1.363,5.733-.125,6.72-.554a.4.4,0,0,0,.241-.37v-.475l-3.993.1-6.812-3.933-8.013-4.723-.084-.79-.817-.371" transform="translate(-479.836 -342.871)" fill="#353d44"/>
        <path id="Path_1058" data-name="Path 1058" d="M499.405,353.468a48.135,48.135,0,0,1-7.68-6.673,12.37,12.37,0,0,0-2.03-3.192c-2.294,0-4.573,1.7-5.236,2.241-1.931-1.085-3.885-2.9-3.824-3.341a1.429,1.429,0,0,0-.8,1.335v1.9c0,1.3,2.965,3.372,5.143,3.9s5.773,3.384,8.045,4.962c1.969,1.367,5.549.128,6.469-.227a.345.345,0,0,0,.22-.322A.724.724,0,0,0,499.405,353.468Z" transform="translate(-479.836 -342.504)" fill="#44647f"/>
        <path id="Path_1059" data-name="Path 1059" d="M499.405,353.9l-.007-.006c-1.042.392-4.463,1.519-6.374.192-2.272-1.578-5.868-4.434-8.045-4.962s-5.143-2.6-5.143-3.9v.952c0,1.3,2.965,3.372,5.143,3.9s5.773,3.384,8.045,4.962c1.969,1.367,5.549.128,6.469-.227a.345.345,0,0,0,.22-.322A.724.724,0,0,0,499.405,353.9Z" transform="translate(-479.836 -342.935)" fill="#375968"/>
      </g>
      <g id="Group_480" data-name="Group 480" transform="translate(6.764 1.066)">
        <path id="Path_1060" data-name="Path 1060" d="M469.914,151.836a37.084,37.084,0,0,1-1.019,9.184c2.007,3.187,10.614,5.181,11.913,1.522a10.536,10.536,0,0,1,1-5.585C483.169,154.634,469.914,151.836,469.914,151.836Z" transform="translate(-461.2 -129.127)" fill="#dda07f"/>
        <path id="Path_1061" data-name="Path 1061" d="M470.1,151.836s.031.855-.034,2.212a13.536,13.536,0,0,0,10.887,7.487A9.5,9.5,0,0,1,482,156.957C483.354,154.634,470.1,151.836,470.1,151.836Z" transform="translate(-461.385 -129.127)" fill="#cc8a6d"/>
        <path id="Path_1062" data-name="Path 1062" d="M482.692,130.428a22.252,22.252,0,0,1,2.664,9.7c-.2.672.953,1.962.673,4.767-.2,2.036-.615,6.173-.825,8.285a1.743,1.743,0,0,1-1.43,1.554c-4.089.722-10.631,1.139-15.412-5.353,0,0-2.3-2.692-3.365-3.028s-5.247-5.672-5.247-9.991c0-4.576,2.527-11.516,11.921-11.516A13.088,13.088,0,0,1,482.692,130.428Z" transform="translate(-459.75 -124.849)" fill="#eab18b"/>
        <g id="Group_479" data-name="Group 479" transform="translate(9.074 3.689)">
          <path id="Path_1063" data-name="Path 1063" d="M487.066,140.826a22.252,22.252,0,0,0-2.664-9.7,10.652,10.652,0,0,0-1.6-1.89c.23.3.345.492.345.492a22.252,22.252,0,0,1,2.664,9.7c-.2.672.953,1.962.673,4.767-.2,2.036-.615,6.172-.825,8.285a1.743,1.743,0,0,1-1.43,1.554c-3.617.639-9.152,1.029-13.692-3.381,4.734,5.872,10.99,5.478,14.95,4.779a1.743,1.743,0,0,0,1.43-1.554c.21-2.112.621-6.249.825-8.285C488.019,142.788,486.869,141.5,487.066,140.826Z" transform="translate(-470.534 -129.233)" fill="#e0a380"/>
        </g>
      </g>
      <path id="Path_1064" data-name="Path 1064" d="M466.143,142.723s2.928-4.147,1.931-6.917c8.237,2.955,9.25-3.945,15.254-3.543,1.837-.544,2.27-4.578,1.71-5.669-3.579,1.293-6.153-2.507-10.435-2.885-4.987-.646-15.013,1.064-14.836,7.725-3.218,14.11,8.556,17.48,8.285,19.8l.271-2.322Z" transform="translate(-452.901 -123.582)" fill="#d69b7b"/>
      <g id="Group_481" data-name="Group 481" transform="translate(6.334 13.231)">
        <path id="Path_1065" data-name="Path 1065" d="M459.239,139.306c.422,9.658,9.05,12.429,8.818,14.423l.265-2.275C465.771,149.219,460.956,145.809,459.239,139.306Z" transform="translate(-459.239 -139.306)" fill="#c68366"/>
      </g>
      <path id="Path_1066" data-name="Path 1066" d="M480.907,130.137c-3.105,1-4.49,2.34-6.8,3.964s-4.947.956-7.717.621a11.16,11.16,0,0,1-5.685-2.436c.383,1.242,4.777,2.627,5.678,4.586s0,6.418,0,6.418,2.928-4.147,1.931-6.917c8.237,2.955,9.25-3.945,15.254-3.543,1.837-.544,2.27-4.578,1.71-5.669C485.534,129.3,484.011,129.134,480.907,130.137Z" transform="translate(-453.137 -124.15)" fill="#c68366"/>
      <g id="Group_482" data-name="Group 482" transform="translate(10.713 18.307)">
        <path id="Path_1067" data-name="Path 1067" d="M467.5,146.691c-1.785-1.859-4.047-.266-2.585,1.945.315.475,1.008.753,1.254,1.193.138.246.271.454.4.645a16.239,16.239,0,0,1,1.225,1.155,1.068,1.068,0,0,0,1.2-.408Z" transform="translate(-464.444 -145.423)" fill="#d39779" opacity="0.4"/>
        <path id="Path_1068" data-name="Path 1068" d="M469.113,146.705c-1.125-2.319-3.768-1.5-3.06,1.054.152.549.726,1.027.826,1.521.495,2.476,1.4,2.7,2.261,2.2" transform="translate(-464.68 -145.338)" fill="#eab18b"/>
      </g>
    </g>
    <g id="Group_506" data-name="Group 506" transform="translate(341.307 167.292)">
      <g id="Group_494" data-name="Group 494" transform="translate(25.315 69.887)">
        <g id="Group_493" data-name="Group 493">
          <g id="Group_492" data-name="Group 492">
            <g id="Group_487" data-name="Group 487" transform="translate(18.798 107.908)">
              <path id="Path_1069" data-name="Path 1069" d="M422.226,393.486s.6-.2.8.052-.769,1.429-.769,1.429-.178-.7-.178-.724S422.226,393.486,422.226,393.486Z" transform="translate(-421.434 -387.912)" fill="#353d44"/>
              <g id="Group_486" data-name="Group 486">
                <path id="Path_1070" data-name="Path 1070" d="M429.692,392.558v-5.682h-7.187v6.959l4.33,4.644,6.009-1.552s-.508-.981-.723-1.286A35.926,35.926,0,0,0,429.692,392.558Z" transform="translate(-421.501 -386.876)" fill="#4c565e"/>
                <g id="Group_485" data-name="Group 485" transform="translate(0 5.574)">
                  <path id="Path_1071" data-name="Path 1071" d="M421.312,395.791v2.72c0,1.234,2.685,3.175,4.828,3.792a.2.2,0,0,0,.255-.206l-.024-.644a14.294,14.294,0,0,1,2.753,1.505c.11.08.22.669.331.752,1.556,1.17,3.165,2.164,4.491,3.085,1.945,1.351,5.677-.123,6.654-.548a.4.4,0,0,0,.239-.367v-.47l-3.954.1-6.746-3.894-7.934-4.677-.083-.783-.81-.368" transform="translate(-421.312 -393.863)" fill="#2b343a"/>
                  <path id="Path_1072" data-name="Path 1072" d="M422.1,393.5c-.086.633,3.943,4.062,6.109,4.131s3.246-1.144,2.365-2.758a50.132,50.132,0,0,0,10.114,9.485.717.717,0,0,1,.3.581h0a.341.341,0,0,1-.218.32c-.911.352-4.456,1.579-6.406.225-2.249-1.562-5.81-4.391-7.967-4.913s-5.093-2.577-5.093-3.862v-1.887A1.417,1.417,0,0,1,422.1,393.5Z" transform="translate(-421.312 -393.5)" fill="#3e454c"/>
                  <g id="Group_484" data-name="Group 484" transform="translate(0 0.531)">
                    <path id="Path_1073" data-name="Path 1073" d="M432.618,396.361a1.392,1.392,0,0,0,.2-.7,2.488,2.488,0,0,0-.5-.534A2.107,2.107,0,0,1,432.618,396.361Z" transform="translate(-423.058 -394.29)" fill="#353d44"/>
                    <path id="Path_1074" data-name="Path 1074" d="M434.563,405c-2.249-1.563-5.81-4.391-7.967-4.914s-5.093-2.577-5.093-3.862v-1.887a1.53,1.53,0,0,1,.032-.206,1.582,1.582,0,0,0-.223.792v1.886c0,1.286,2.937,3.339,5.093,3.862s5.718,3.352,7.967,4.913c1.95,1.354,5.5.127,6.406-.225a.341.341,0,0,0,.218-.32.7.7,0,0,0-.054-.256C440,405.148,436.5,406.342,434.563,405Z" transform="translate(-421.312 -394.131)" fill="#353d44"/>
                  </g>
                </g>
                <path id="Path_1075" data-name="Path 1075" d="M426.522,397.177s2.685-2.387,5.38-2.382a12.68,12.68,0,0,1,2.326,3.887,10.07,10.07,0,0,1-3.521,1.76Z" transform="translate(-422.138 -388.131)" fill="#353d44"/>
              </g>
            </g>
            <g id="Group_489" data-name="Group 489" transform="translate(1.736 117.895)">
              <path id="Path_1076" data-name="Path 1076" d="M401.384,400.845a15.159,15.159,0,0,1,.963-1.457c.709-.984,6.292-.412,6.292-.412s.617,1.329.686,1.4.468,4.181.468,4.181l-6.138,1.453-2.1-2.206Z" transform="translate(-401.091 -398.754)" fill="#4c565e"/>
              <path id="Path_1077" data-name="Path 1077" d="M401.193,401.967l-.157,5.387.74.557V412.7s.426,4.392,1.02,5.578a2.508,2.508,0,0,0,1.953,1.616,6.7,6.7,0,0,0,6.372-1.142,6.964,6.964,0,0,0-.29-2.619c-.262-.53-5.092-.445-5.092-.445l-4.265-11.827Z" transform="translate(-401.036 -399.255)" fill="#2b343a"/>
              <path id="Path_1078" data-name="Path 1078" d="M409.316,398.987c.937,5.213,2.395,17.445,1.663,18.2-.121.793-2.577,2.044-4.315,1.691-.955-.194-3.058-.546-3.818-2.058-.629-1.592-1.006-9.207-1.038-10.824-.166-2.76-1.574-4.031-.122-6.425.57-.382-.543,9.653,4.741,9.323,4.816-.244,2.164-10.15,2.164-10.15Z" transform="translate(-401.036 -398.744)" fill="#3e454c"/>
              <g id="Group_488" data-name="Group 488" transform="translate(0.006)">
                <path id="Path_1079" data-name="Path 1079" d="M406.958,417.763c-.956-.194-3.059-.545-3.818-2.057-.63-1.592-1.007-9.207-1.038-10.824-.111-1.847-.774-3.028-.769-4.323-.815,1.966.327,3.247.476,5.725.031,1.617.408,9.232,1.038,10.824.76,1.512,2.863,1.864,3.818,2.057,1.738.353,4.194-.9,4.315-1.691a2.345,2.345,0,0,0,.194-1.167A4.953,4.953,0,0,1,406.958,417.763Z" transform="translate(-401.042 -399.032)" fill="#353d44"/>
                <path id="Path_1080" data-name="Path 1080" d="M410.672,405.389c.638-1.912.306-4.688-.013-6.429l-.644-.216A21.55,21.55,0,0,1,410.672,405.389Z" transform="translate(-402.465 -398.744)" fill="#353d44"/>
              </g>
              <path id="Path_1081" data-name="Path 1081" d="M403.948,410.836a47.729,47.729,0,0,0,5.611-.741,45.722,45.722,0,0,0,.124-5.789,23.779,23.779,0,0,0-7.26.608A23.764,23.764,0,0,0,403.948,410.836Z" transform="translate(-401.255 -399.616)" fill="#353d44"/>
            </g>
            <path id="Path_1082" data-name="Path 1082" d="M409.4,400.142c-.069-.068-.693-1.409-.693-1.409s-5.638-.577-6.354.416a15.568,15.568,0,0,0-.974,1.472v.013c1.405,1.757,6.471,2.275,8.133.284A6,6,0,0,0,409.4,400.142Z" transform="translate(-399.354 -280.821)" fill="#424c54"/>
            <path id="Path_1083" data-name="Path 1083" d="M429.692,392.5l.245-5.986h-7.432v5.836C428.518,394.792,429.692,392.5,429.692,392.5Z" transform="translate(-402.703 -278.91)" fill="#424c54"/>
            <g id="Group_490" data-name="Group 490" transform="translate(11.436 0)">
              <path id="Path_1084" data-name="Path 1084" d="M416.783,259.823c-9.524,5.566-.375,48.732,2.638,65.6-1.2,4.886.53,12.157.8,21.261.3,10.225-.431,23.48-.431,23.48,1.6,1.022,7.388,4.039,9.249-.072,0,0,2.264-31.033,2.386-40.89a342.676,342.676,0,0,0,2.487-58.392C432.921,266.085,425.007,255.016,416.783,259.823Z" transform="translate(-412.562 -258.639)" fill="#353d44"/>
              <path id="Path_1085" data-name="Path 1085" d="M434.837,270.809c-.958-4.588-8.453-15.158-16.418-11.357,7.119-1.236,13.394,7.877,14.265,12.048a342.674,342.674,0,0,1-2.487,58.392c-.124,9.857-2.386,40.89-2.386,40.89a3.508,3.508,0,0,1-1.11,1.4,3.411,3.411,0,0,0,3.262-2.094s2.264-31.033,2.386-40.89A342.673,342.673,0,0,0,434.837,270.809Z" transform="translate(-413.491 -258.639)" fill="#2b3238"/>
              <path id="Path_1086" data-name="Path 1086" d="M422.892,284.476s-4.756-3.842-10.291-3.7c.417,15.6,4.889,37.315,6.826,48.158-1.2,4.886.53,12.157.8,21.261.3,10.225-.431,23.48-.431,23.48a15.919,15.919,0,0,0,2.768,1.372l-.374-37.368S425.233,311.251,422.892,284.476Z" transform="translate(-412.569 -262.147)" fill="#2b3238"/>
              <path id="Path_1087" data-name="Path 1087" d="M422.744,284.638a18.311,18.311,0,0,0-2.646-1.922,15.76,15.76,0,0,0-7.5-1.943c.417,15.6,4.889,37.315,6.826,48.158a21.832,21.832,0,0,0-.357,6.207C420.216,327.939,423.256,306.52,422.744,284.638Z" transform="translate(-412.569 -262.147)" fill="#242b30"/>
            </g>
            <g id="Group_491" data-name="Group 491" transform="translate(0 17.603)">
              <path id="Path_1088" data-name="Path 1088" d="M404.33,281.07c-9.524,5.566-3.8,39.584-2.082,56.341-2.007,3.636-.624,11.526-.518,18.844.147,10.17-1.255,23.542-1.255,23.542.134,2.8,8.663,3.767,9.021-.081l4.4-42.49s6.012-25.911,6.711-52.779C420.606,284.446,412.553,276.264,404.33,281.07Z" transform="translate(-398.972 -279.557)" fill="#353d44"/>
            </g>
            <path id="Path_1089" data-name="Path 1089" d="M420.131,283.585a337.274,337.274,0,0,1-7.316,53.069L407.842,381.4a2.483,2.483,0,0,1-.671,1.482c1.837-.123,3.473-.886,3.625-2.524l4.4-42.49s6.012-25.911,6.711-52.779A16.879,16.879,0,0,0,420.131,283.585Z" transform="translate(-400.272 -262.593)" fill="#2b3238"/>
            <path id="Path_1090" data-name="Path 1090" d="M432.112,285.282c-.009-6.154-.177-10.85-.273-13.575q-.128-.848-.254-1.719c-1.623-5.056-9.122-14.713-16.9-10.166-3.268,1.91-4.338,8.251-4.21,16.436a10.694,10.694,0,0,0-6.089,1.494c-3.023,1.766-4.505,6.4-5.072,12.44,3.175,2.029,9.244,1.786,14.674,1.24C419.858,290.844,428.9,288.449,432.112,285.282Z" transform="translate(-399.026 -258.638)" fill="#2f373d" opacity="0.2"/>
          </g>
        </g>
      </g>
      <g id="Group_496" data-name="Group 496" transform="translate(49.231 25.987)">
        <path id="Path_1091" data-name="Path 1091" d="M443.553,277.584s-.126-3.774-.362-5.007a10.712,10.712,0,0,1-1-3.155c.014-.245-4.763-25.472-4.763-25.472l-1.354-27.976c.041-2.183-.2-5.08-2.378-5.121s-3.544.167-3.585,2.349l-.594,31.035,0,.131a3.117,3.117,0,0,0,.121.908l8.144,27.872c-.032,1.256-.139,2.663,0,7.6s3.076,3.482,3.431,2.842C442.341,280.922,443.553,277.584,443.553,277.584Z" transform="translate(-427.73 -207.162)" fill="#ffcbae"/>
        <path id="Path_1092" data-name="Path 1092" d="M442.817,271.682a9.476,9.476,0,0,1-.624-2.259c.014-.245-4.763-25.472-4.763-25.472l-1.354-27.976a9.105,9.105,0,0,0-.569-3.883,9.064,9.064,0,0,0-.987-1.048,1.961,1.961,0,0,0-.822-.19c-2.182-.042-3.543.167-3.585,2.349l-.594,31.035,0,.131a3.117,3.117,0,0,0,.121.908l7.964,27.256A6.524,6.524,0,0,0,442.817,271.682Z" transform="translate(-427.73 -207.162)" fill="#efb497"/>
        <g id="Group_495" data-name="Group 495">
          <path id="Path_1093" data-name="Path 1093" d="M436.508,269.752c4.347,1.175,6.346-.99,6.346-.99l-4.918-25.843s1.09-21.184-.73-29.1c-1.464-6.37-6.632-7.349-6.632-7.349-3.07.024-3.2,3.272-3.18,6.342l1.118,31.643Z" transform="translate(-427.394 -206.468)" fill="#373e44"/>
        </g>
        <path id="Path_1094" data-name="Path 1094" d="M438.1,242.919s1.09-21.184-.73-29.1c-1.464-6.37-6.632-7.349-6.632-7.349a2.619,2.619,0,0,0-2.338,1.177,3.3,3.3,0,0,1,1.067-.173s5.167.979,6.631,7.349c1.82,7.918.73,29.1.73,29.1L441.71,269.6a4.189,4.189,0,0,0,1.3-.836Z" transform="translate(-427.553 -206.468)" fill="#2c343a"/>
      </g>
      <g id="Group_497" data-name="Group 497" transform="translate(20.922 25.259)">
        <path id="Path_1095" data-name="Path 1095" d="M431.874,270.889c0-5.486-2.885-16.068-3.126-26.03-.265-10.958,3.126-19.311-.061-27.694-1.521-4-3.625-13.513-9.44-11.208-4.562,1.948-7.322,1.806-9.392,3.034-5.893,6.531-11.294,4.635-14.7,10.784-4.288,7.179,2.1,22.585,7.947,36.953.036,2.648-4.08,7.529-7,18.252-.366,6.585,8.848,5.3,16.71,4.506C419.679,278.8,430.924,274.88,431.874,270.889Z" transform="translate(-393.752 -205.602)" fill="#b2ceea"/>
      </g>
      <path id="Path_1096" data-name="Path 1096" d="M420.733,223.07l-.844,5.632,4.16,23.2.219,1.959.089.534.269-1.107,1.261-13.416-.631-13.268L421.5,222.88Z" transform="translate(-376.973 -183.083)" fill="#3e454c"/>
      <path id="Path_1097" data-name="Path 1097" d="M417.915,220.9s.617,2.058,1.531,2.241a3.578,3.578,0,0,0,2.9-1.486,4.692,4.692,0,0,0,.114-2.545c-.252-.312-1.583-.231-1.583-.231l-.752.173s-2.18,1.342-2.185,1.405S417.915,220.9,417.915,220.9Z" transform="translate(-376.66 -182.448)" fill="#3e454c"/>
      <path id="Path_1098" data-name="Path 1098" d="M422.513,220.85a3.581,3.581,0,0,1-2.9,1.486c-.651-.13-1.15-1.209-1.384-1.82a1.68,1.68,0,0,0-.282.2c-.006.063-.029.443-.029.443s.617,2.058,1.531,2.241a3.578,3.578,0,0,0,2.9-1.486,5.639,5.639,0,0,0,.229-1.294C422.557,220.706,422.536,220.785,422.513,220.85Z" transform="translate(-376.66 -182.708)" fill="#343c42"/>
      <path id="Path_1099" data-name="Path 1099" d="M423.645,276.6a25.647,25.647,0,0,0,4.6,9.611s7.412-2.166,9.419-6.537c0,0-3.747-30.908-3.272-34.887s1.238-23.384,0-30.6c-1.483-8.655-7.886-9.053-10.506-9.05s-1.341,4.295-1.341,4.295l3.048,5.486,1.4,6.957.337,9.478-1.463,11.059-.783,6.127-.269,2.789-.227.663-1.646,7.613Z" transform="translate(-377.345 -180.27)" fill="#3e454c"/>
      <path id="Path_1100" data-name="Path 1100" d="M424.833,260.658l-.026-.135-.084.247-1.646,7.613.7,17a25.856,25.856,0,0,0,3.41,7.948C427.923,280.834,424.833,260.658,424.833,260.658Z" transform="translate(-377.478 -189.05)" fill="#363e44"/>
      <path id="Path_1101" data-name="Path 1101" d="M424.356,252.491s3.334-33.364-1.6-39.339l-2.683-4.145a16.621,16.621,0,0,1,8.6,6.035l-2.324,2.663,3.073,1.224S425.046,251.655,424.356,252.491Z" transform="translate(-377.002 -180.884)" fill="#646f77"/>
      <path id="Path_1102" data-name="Path 1102" d="M420.248,252.43s.9,25.1-2.275,36.438c-1.341,1.1-13.756,4.415-22.736,0,0,0,.237-16.355,2.1-19.944s5.24-10.787,4.343-13.01-6.344-13.317-6.344-13.317l-1.182-25.7s2.674-3.055,14.829-8.277l-1.629,3.331-.807,3.156,5.149,11.086,4.959,9.084,2.973,12.009.342,1.311Z" transform="translate(-372.893 -180.823)" fill="#3e454c"/>
      <g id="Group_498" data-name="Group 498" transform="translate(21.259 35.356)">
        <path id="Path_1103" data-name="Path 1103" d="M400.913,255.615l1.113-26.408c.262-6.442-1.8-9.231-3.01-10.6a4.22,4.22,0,0,0-4.439-.69,5.04,5.04,0,0,0-.426.4l1.182,25.7S399.141,251.774,400.913,255.615Z" transform="translate(-394.152 -217.603)" fill="#353e44"/>
      </g>
      <g id="Group_502" data-name="Group 502" transform="translate(0 34.466)">
        <g id="Group_499" data-name="Group 499" transform="translate(0 73.733)">
          <path id="Path_1104" data-name="Path 1104" d="M368.888,332.925V310.754l28.8,16.628v22.171Z" transform="translate(-368.888 -305.213)" fill="#c17656"/>
          <path id="Path_1105" data-name="Path 1105" d="M368.888,309.71l9.6-5.541,28.8,16.626-9.6,5.543Z" transform="translate(-368.888 -304.169)" fill="#db8a6e"/>
          <path id="Path_1106" data-name="Path 1106" d="M406.811,320.555l-10.081,5.884v13.908L369.6,325.7V310.158l9.929-5.353Z" transform="translate(-369.001 -304.27)" fill="#b56454"/>
          <path id="Path_1107" data-name="Path 1107" d="M396.729,341.356V327.448L369.6,311.166v15.546Z" transform="translate(-369.001 -305.278)" fill="#a85345"/>
          <path id="Path_1108" data-name="Path 1108" d="M412.715,323.929V346.1l-9.6,5.543V329.472Z" transform="translate(-374.314 -307.302)" fill="#c4745f"/>
          <path id="Path_1109" data-name="Path 1109" d="M401.84,343.316l.4-.242-.11-13.666,9.795-5.543v-.342l-10.081,5.884Z" transform="translate(-374.112 -307.237)" fill="#a55042"/>
        </g>
        <path id="Path_1110" data-name="Path 1110" d="M386.524,315.182v-2.308a1.577,1.577,0,0,1,2.366-1.366l3.85,2.221a5.027,5.027,0,0,1,2.514,4.355v2.1" transform="translate(-371.684 -231.566)" fill="none" stroke="#893d33" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.713"/>
        <g id="Group_500" data-name="Group 500" transform="translate(13.7 5.478)">
          <path id="Path_1111" data-name="Path 1111" d="M391.611,226.078c-.589,2.429-1.124,4.871-1.688,7.306l-1.6,7.325c-.544,2.439-1.029,4.892-1.542,7.338l-1.522,7.343-.024.116a3.3,3.3,0,0,0-.066.71l.183,9.106c.067,3.035.105,6.07.2,9.106l.264,9.106c.119,3.035.209,6.071.353,9.106a2.851,2.851,0,0,0,2.983,2.712c1.471-.071,1.9-1.892,1.984-3.331.162-3.034.995-5.435,1.13-8.469l.313-9.1c.117-3.035.171-6.07.255-9.106l.233-9.1-.089.826,1.654-7.314c.545-2.439,1.12-4.872,1.633-7.317l1.576-7.331c.5-2.45,1.019-4.894,1.489-7.349a3.953,3.953,0,0,0-7.725-1.675Z" transform="translate(-385.169 -223.055)" fill="#ffcbae"/>
        </g>
        <path id="Path_1112" data-name="Path 1112" d="M386.389,305.559s-.279,6.055.493,7.715,3.133,2.341,4.756,2.414a2.207,2.207,0,0,0,2.163-1.3c-.53-1.538-2.648-2.45-2.481-5.056-.061-1.6.527-.721.5-.38a4.509,4.509,0,0,0,.788,2.249c.427.359.65.242.665-1.3a12.668,12.668,0,0,0-.119-3.086c-.22-1.144-1.351-1.231-1.563-2.392a6.3,6.3,0,0,1-.01-2.152l-5.365-.042A7.369,7.369,0,0,1,386.389,305.559Z" transform="translate(-371.634 -230.13)" fill="#ffcbae"/>
        <path id="Path_1113" data-name="Path 1113" d="M388.371,305.453a6.286,6.286,0,0,0,3.469-.406,1.634,1.634,0,0,1-.251-.613,6.3,6.3,0,0,1-.01-2.152l-5.365-.042a7.332,7.332,0,0,1,.273,2.34A4.741,4.741,0,0,0,388.371,305.453Z" transform="translate(-371.634 -230.13)" fill="#efb497"/>
        <g id="Group_501" data-name="Group 501" transform="translate(12.087)">
          <path id="Path_1114" data-name="Path 1114" d="M385.267,289.57c.408-.257,1.037.836,2.634,1.15,1.466.289,3.917-.166,4.122-1.048.7-3.152.71-34.057.71-34.057l6.773-26.423c1.591-6.248.152-9.4-.748-10.99-1.153-2.029-5.549-2.477-6.935.262-3.023,5.977-8.57,33.03-8.57,33.03S384.873,288.629,385.267,289.57Z" transform="translate(-383.252 -216.545)" fill="#373e44"/>
        </g>
        <path id="Path_1115" data-name="Path 1115" d="M399.932,218.206a3.892,3.892,0,0,0-3.171-1.643,2.921,2.921,0,0,1,1.06,1.059c.9,1.65,2.338,4.938.679,11.478l-7.062,27.661s-.194,26.7-.781,34.022c1.208-.1,2.4-.506,2.54-1.109.7-3.152.711-34.057.711-34.057L400.68,229.2C402.271,222.947,400.832,219.792,399.932,218.206Z" transform="translate(-372.339 -216.548)" fill="#2e353a"/>
      </g>
      <path id="Path_1116" data-name="Path 1116" d="M410.127,211.9c-4.727,4.944,14.265,18.391,12.357,41.051,0,0-1.982-16.43-11.334-23.891l1.094-3.836-3.346.033C408.839,224.1,406.874,213.543,410.127,211.9Z" transform="translate(-375.129 -181.342)" fill="#646f77"/>
      <path id="Path_1117" data-name="Path 1117" d="M410.3,211.9c-2.248,1.362-.665,7.7,5.905,11.047,0,0,.1-3.867,2.857-5.061C415.777,217.924,409.665,214.559,410.3,211.9Z" transform="translate(-375.3 -181.342)" fill="#cce3ff"/>
      <path id="Path_1118" data-name="Path 1118" d="M420.892,218.312s1.7-.371,2.844,1.914c.868-1.417.865-4.271-.594-5.624C423.507,216.02,423.534,217.4,420.892,218.312Z" transform="translate(-377.132 -181.771)" fill="#cce3ff"/>
      <path id="Path_1119" data-name="Path 1119" d="M445.332,289.7c-.518.823-2.286.106-2.1-2.881C442.467,289.01,443.979,292.051,445.332,289.7Z" transform="translate(-380.641 -193.219)" fill="#e5ac8f"/>
      <g id="Group_504" data-name="Group 504" transform="translate(26.822 1.323)">
        <path id="Path_1120" data-name="Path 1120" d="M410.926,204.143a37.094,37.094,0,0,1-1.019,9.185c2.007,3.187,10.615,5.181,11.914,1.521a10.531,10.531,0,0,1,1-5.585C424.181,206.941,410.926,204.143,410.926,204.143Z" transform="translate(-402.213 -181.435)" fill="#dda07f"/>
        <path id="Path_1121" data-name="Path 1121" d="M411.112,204.143s.031.855-.033,2.212a13.539,13.539,0,0,0,10.887,7.488,9.5,9.5,0,0,1,1.043-4.579C424.367,206.941,411.112,204.143,411.112,204.143Z" transform="translate(-402.398 -181.435)" fill="#cc8a6d"/>
        <path id="Path_1122" data-name="Path 1122" d="M423.7,182.736a22.251,22.251,0,0,1,2.664,9.7c-.2.673.953,1.963.673,4.767-.2,2.037-.614,6.173-.824,8.286a1.745,1.745,0,0,1-1.431,1.554c-4.089.721-10.631,1.139-15.412-5.353,0,0-2.3-2.692-3.365-3.028s-5.247-5.671-5.247-9.99c0-4.577,2.527-11.516,11.921-11.516A13.087,13.087,0,0,1,423.7,182.736Z" transform="translate(-400.763 -177.157)" fill="#eab18b"/>
        <g id="Group_503" data-name="Group 503" transform="translate(9.074 3.689)">
          <path id="Path_1123" data-name="Path 1123" d="M428.078,193.133a22.251,22.251,0,0,0-2.664-9.7,10.63,10.63,0,0,0-1.6-1.89c.23.3.344.492.344.492a22.246,22.246,0,0,1,2.664,9.7c-.2.673.953,1.963.673,4.767-.2,2.036-.614,6.173-.824,8.285a1.744,1.744,0,0,1-1.431,1.555c-3.617.638-9.152,1.029-13.691-3.381,4.733,5.872,10.99,5.477,14.95,4.779a1.745,1.745,0,0,0,1.431-1.554c.21-2.113.62-6.249.824-8.286C429.031,195.1,427.882,193.806,428.078,193.133Z" transform="translate(-411.547 -181.541)" fill="#e0a380"/>
        </g>
      </g>
      <g id="Group_505" data-name="Group 505" transform="translate(30.939 18.568)">
        <path id="Path_1124" data-name="Path 1124" d="M408.679,198.99c-1.767-1.84-4.007-.263-2.56,1.927.311.47,1,.746,1.242,1.181.136.244.268.45.4.64a16.094,16.094,0,0,1,1.213,1.144,1.056,1.056,0,0,0,1.191-.4Z" transform="translate(-405.655 -197.735)" fill="#d39779" opacity="0.4"/>
        <path id="Path_1125" data-name="Path 1125" d="M410.279,199c-1.113-2.3-3.731-1.487-3.029,1.043.151.544.719,1.016.817,1.506.491,2.452,1.388,2.676,2.239,2.175" transform="translate(-405.889 -197.651)" fill="#eab18b"/>
      </g>
      <path id="Path_1126" data-name="Path 1126" d="M411.685,191.341s-2.012,5.585-1.524,7.487c-.732.316-1.2-2.14-2.4-2.07s-.194,1.594-.194,1.594l1.7,3.842-.151,2.744c-1.334-1.526-10.02-7.836-9.2-16.8.183-2.743,2.743-4.642,3.566-3.876S411.685,191.341,411.685,191.341Z" transform="translate(-373.799 -176.934)" fill="#60554f"/>
      <path id="Path_1127" data-name="Path 1127" d="M401.736,183.773c-.366-2.926,3.292-7.8,10.242-8.169s7.713,4.663,11.764,2.195c0,0,3.147,3.766,1.057,7.43,0,0-7.547,2.326-9.452,3.544s-3.2,1.95-4.14,1.218-7.4-4.268-7.4-4.268Z" transform="translate(-374.091 -175.585)" fill="#60554f"/>
      <path id="Path_1128" data-name="Path 1128" d="M424.827,270.316c0,.69-.4,1.25-.894,1.25s-.894-.56-.894-1.25.4-1.25.894-1.25S424.827,269.626,424.827,270.316Z" transform="translate(-377.473 -190.405)" fill="#31393f"/>
    </g>
    <g id="Group_520" data-name="Group 520" transform="translate(451.14 212.852)">
      <path id="Path_1129" data-name="Path 1129" d="M537.267,236.942s4.134,5.471.724,14.193c0,0-1.213-2.5-3-3.066s-3.267-7.061-3.267-7.061l2.981-4.565Z" transform="translate(-504.181 -230.471)" fill="#a3745a"/>
      <g id="Group_507" data-name="Group 507" transform="translate(34.785 77.97)">
        <path id="Path_1130" data-name="Path 1130" d="M550.432,338.035s1.14,5.137.91,5.8l-1.781,8.646a.491.491,0,0,1-.8.229l-2.476-2.17.765-12.509h3.377Z" transform="translate(-541.275 -324.547)" fill="#eab18b"/>
        <path id="Path_1131" data-name="Path 1131" d="M545.773,322.006s1.653,8.6,3.713,13.488c-.533,1.263-2.665,8.969-3.1,7.668-2.771-8.365-6.061-16.081-6.061-16.081Z" transform="translate(-540.33 -322.006)" fill="#eab18b"/>
        <path id="Path_1132" data-name="Path 1132" d="M551.119,348.832c-.491-.008-1.106-2.976-1.106-4.154C550.013,344.679,549.791,350.07,551.119,348.832Z" transform="translate(-541.863 -325.6)" fill="#d1916d"/>
      </g>
      <g id="Group_508" data-name="Group 508" transform="translate(27.965 26.863)">
        <path id="Path_1133" data-name="Path 1133" d="M533.827,300.023a2.217,2.217,0,0,1-.081-.457c-.2-2.793-1.772-29.87-1.487-32.3.365-3.1,1.818-6.1,5.06-5.994,2.555.088,6.054,3.97,6.155,8.807s-1.064,27.983-1.1,29.265c0,0,5.851,21.969,5.826,23.941,0,0-1.6,2.578-6.508,2.578C534.968,323.348,533.827,300.023,533.827,300.023Z" transform="translate(-532.225 -261.27)" fill="#2f3033"/>
      </g>
      <path id="Path_1134" data-name="Path 1134" d="M542.742,299.346c.034-1.282,1.2-24.43,1.1-29.266s-3.6-8.719-6.155-8.807a4.015,4.015,0,0,0-3.148,1.25,4.187,4.187,0,0,1,1.891-.358c2.555.087,6.054,3.971,6.155,8.807s-1.064,27.983-1.1,29.266c0,0,5.851,21.969,5.827,23.941a3.664,3.664,0,0,1-.561.633,5.033,5.033,0,0,0,1.818-1.526C548.593,321.315,542.742,299.346,542.742,299.346Z" transform="translate(-504.627 -234.407)" fill="#26282d"/>
      <g id="Group_511" data-name="Group 511" transform="translate(5.601 76.148)">
        <path id="Path_1135" data-name="Path 1135" d="M529.481,422.367c2.313-12.623-2.9-33.616-1.132-43.72,0,0-2.992-26.164-3.217-38.071a46.119,46.119,0,0,1,4.514-20.735s8.713.588,11.257,1.512c0,0,8.677,23.2-.961,59.67.6,2.078-4.122,31.812-3.226,42.274.57,3.923,3.817,12.589,3.852,14.072s-3.815,2.166-4.154,2.059-2.087-.3-2.087-.511-3.8-11.84-3.8-11.84-.919-3.388-.95-3.495S529.481,422.367,529.481,422.367Z" transform="translate(-508.736 -319.84)" fill="#eab18b"/>
        <path id="Path_1136" data-name="Path 1136" d="M542.623,321.5a21.207,21.207,0,0,0-3.68-.752c1.39,10,4.156,35.463-.454,54.3-.222,1.879-.173,4.377-.2,5.135s.071,3.363-2.317,3.923a3.155,3.155,0,0,0,2.535.8s-2.032,30.268-1.981,32.689-1.4,4.65,1.078,9.809,2.327,10.268,4.318,10.883a1.054,1.054,0,0,0,.365-.771c-.035-1.483-3.282-10.149-3.852-14.072-.9-10.462,3.826-40.2,3.226-42.274C551.3,344.7,542.623,321.5,542.623,321.5Z" transform="translate(-510.456 -319.983)" fill="#d69976"/>
        <g id="Group_509" data-name="Group 509" transform="translate(19.043 100.054)">
          <path id="Path_1137" data-name="Path 1137" d="M530.315,438.744s-2.036.3-2.036,4.349,2.171,3.261,2.715,5.134,2.235,9.992,5.254,10.944,5.677,2.033,7.791,1.663c.886-.186.3-1.616-.3-1.947-.517-.285-2.317-3.262-2.825-4.112a.12.12,0,0,0-.215.014,3.511,3.511,0,0,1-3.012,1.47c-1.842-.182-2.525-.888-7.477-16.091Z" transform="translate(-528.279 -438.744)" fill="#2f3033"/>
          <path id="Path_1138" data-name="Path 1138" d="M544.262,460.214c-2.114.37-4.771-.709-7.791-1.663s-4.711-9.073-5.254-10.945-2.715-1.087-2.715-5.133a9.491,9.491,0,0,1,.1-1.408,7.828,7.828,0,0,0-.321,2.395c0,4.047,2.171,3.261,2.715,5.134s2.235,9.992,5.254,10.944,5.677,2.033,7.791,1.663c.489-.1.523-.584.359-1.05A.55.55,0,0,1,544.262,460.214Z" transform="translate(-528.279 -439.112)" fill="#27282b"/>
          <path id="Path_1139" data-name="Path 1139" d="M529.946,456.355h0a.7.7,0,0,1-.7-.7v-10.7h1.027V456.03A.325.325,0,0,1,529.946,456.355Z" transform="translate(-528.432 -439.728)" fill="#2f3033"/>
        </g>
        <path id="Path_1140" data-name="Path 1140" d="M526.411,380.166c.831-25.3,4.96-27.216,1.073-52.072-.66-4.217-16.851-2.278-16.851-2.278-3.484,2.229-5.422,7.923-4.89,14.337.825,9.95,11.174,30.636,9.085,39.945-5.074,15.024.755,34.583-1.4,47.782.08.237-.155.642-.057.853.013,8.8-1.377,15.719.673,17.943,1.551,1.684,4.908,3.437,6.128.829.454-.972.081-4.394,0-5.989.029-3.043-.648-6.918-.727-11.982a3.211,3.211,0,0,0,.135-.748S523.648,402.113,526.411,380.166Z" transform="translate(-505.649 -320.717)" fill="#eab18b"/>
        <path id="Path_1141" data-name="Path 1141" d="M528.525,380.168c.831-25.3,4.96-27.216,1.073-52.072-.321-2.049-4.307-2.643-8.321-2.714,1.452,9.373,4.81,34.2,2.876,52.669.292,2.093.831,1.916.585,2.864s-.15,2.237-2.347,2.429.185,2.579,2.409,1.91c0,0-3.587,39.568-4.67,43.389s2.261,14.171,1.21,16.463-1.99,1.777-2.293,2.58a1.857,1.857,0,0,0,.011.921c1.286.44,2.572.31,3.231-1.1.454-.972.081-4.394,0-5.989.029-3.043-.648-6.918-.727-11.982a3.211,3.211,0,0,0,.135-.748S525.763,402.115,528.525,380.168Z" transform="translate(-507.764 -320.719)" fill="#d69976"/>
        <path id="Path_1142" data-name="Path 1142" d="M514.617,446.813s-.534,13.016.525,17a3.215,3.215,0,0,0,5.712,1.324c.718-1.023.317-4.239.426-6.013,0,0,.528,4.294.667,6.447a7.564,7.564,0,0,1-.218,2.353L520.59,472.4a1.676,1.676,0,0,1-1.624,1.262H518.2a1.676,1.676,0,0,1-1.49-.908l-2.2-4.281a9.875,9.875,0,0,1-1.082-5.186c.186-2.774,1.193-6.4-.316-10.279C512.259,451.391,513.492,447.045,514.617,446.813Z" transform="translate(-506.786 -339.969)" fill="#2f3033"/>
        <g id="Group_510" data-name="Group 510" transform="translate(6.077 107.449)">
          <path id="Path_1143" data-name="Path 1143" d="M522.726,466.678a3.085,3.085,0,0,0,.3-.366,2.215,2.215,0,0,0,.3-.789c-.172-1.915-.482-4.077-.482-4.077A50.336,50.336,0,0,1,522.726,466.678Z" transform="translate(-514.433 -449.738)" fill="#27282b"/>
          <path id="Path_1144" data-name="Path 1144" d="M519.577,472.634h-.768a1.677,1.677,0,0,1-1.49-.909l-2.2-4.281a9.875,9.875,0,0,1-1.082-5.186c.186-2.773,1.193-6.4-.316-10.279-.51-.977-.144-2.967.472-4.447-.925,1.345-1.738,4.323-1.075,5.593,1.51,3.882.5,7.505.316,10.279a9.875,9.875,0,0,0,1.082,5.186l2.2,4.281a1.676,1.676,0,0,0,1.49.908h.768a1.676,1.676,0,0,0,1.624-1.262l.077-.3A1.669,1.669,0,0,1,519.577,472.634Z" transform="translate(-512.87 -447.532)" fill="#27282b"/>
        </g>
        <path id="Path_1145" data-name="Path 1145" d="M538.07,320.872s-23.563,1.92-27.31,4.176c-2.518,1.516-5.112,4.753-5.112,12.306,0,8.368,10.181,33.777,9.01,40.887.175.673,1.008,1.182,2.242,1.516.337-2.6.825-7.245.377-9.681a32.819,32.819,0,0,1,1.84,10.049,26.155,26.155,0,0,0,12.46-2.169,16.914,16.914,0,0,0,8.167-8.559A98.878,98.878,0,0,0,538.07,320.872Z" transform="translate(-505.648 -320.004)" fill="#2f3033"/>
        <path id="Path_1146" data-name="Path 1146" d="M511.256,325.062c-.159.1-.319.2-.478.309-.712,1.786-1.518,3.288-2,4.392,2.924,6.415,20.06,8.563,20.06,8.563.994-1.249,1.32-3.8,1.31-6.943l2.83,6.35s5.471-4.543,5.9-8.751c.242-1.072-.4-4.159-1.138-8.024C533.562,321.317,514.587,323.056,511.256,325.062Z" transform="translate(-506.144 -320.017)" fill="#26282d"/>
        <path id="Path_1147" data-name="Path 1147" d="M543.174,320.872l-.252.021a130.279,130.279,0,0,1-4.294,54.935,7.886,7.886,0,0,1-.783,1.508,16.868,16.868,0,0,0,7-7.939A98.877,98.877,0,0,0,543.174,320.872Z" transform="translate(-510.752 -320.004)" fill="#26282d"/>
      </g>
      <path id="Path_1148" data-name="Path 1148" d="M528.759,270.227l.364-.035.938.035,2.2,2.859,1.337,14.853-.134,10.69-2.117.241-6.592-23.84-2.453-4.413.74-1.65,3.709,1.5Z" transform="translate(-502.686 -235.627)" fill="#fff"/>
      <path id="Path_1149" data-name="Path 1149" d="M529.15,270.192l-.364.035-2.012.237-3.709-1.5-.595,1.326a13.309,13.309,0,0,0,3.449,4.115c.2-2.566,3.227-4.214,3.23-4.216.769.606,1.282,2.4,1.834,3.536,0,0,.068-.974.058-2.26l-.953-1.24Z" transform="translate(-502.714 -235.628)" fill="#d6d6d6"/>
      <path id="Path_1150" data-name="Path 1150" d="M534.96,315.352a38.358,38.358,0,0,1,3.552,7.97s5.47-4.543,5.9-8.751c.642-2.853-4.944-19.987-2.129-28.1,2.2-.571,3.526-2.9,2.947-6.927-.519-3.6-1.345-5.019-1.817-7.124S543.19,262,535.6,261.489l-2.9,1.734,1.672,6.9.242,10.666-.242.567.443,11.052-1.192,7.456.73,15.491Z" transform="translate(-504.335 -234.442)" fill="#2f3033"/>
      <path id="Path_1151" data-name="Path 1151" d="M534.362,302.89l-.565,3.535.73,15.491h.607a33.945,33.945,0,0,1,2.75,5.771c.559-6.047-2.838-18.126-3.5-24.773Z" transform="translate(-504.509 -241.005)" fill="#292a2d"/>
      <path id="Path_1152" data-name="Path 1152" d="M529.529,274.078l-.48,2.942,4.579,20.341-1.507-21.5-1.428-2.32Z" transform="translate(-503.756 -236.353)" fill="#3c3e42"/>
      <path id="Path_1153" data-name="Path 1153" d="M527.417,271.438a2.324,2.324,0,0,0,2.247,2.3c1.417,0,2.139-.963,1.765-2.14l-1.023-1.554s-.368.458-2.293.351S527.417,271.438,527.417,271.438Z" transform="translate(-503.436 -235.798)" fill="#3c3e42"/>
      <path id="Path_1154" data-name="Path 1154" d="M533.994,296.586s.326-24.285-2.632-30.171v-2l1.941-1.445a9.681,9.681,0,0,1,3.439,2.423s-1.406,2.3-2.4,3.78l4.958,2.3S535.92,295.408,533.994,296.586Z" transform="translate(-504.123 -234.677)" fill="#4f4f4f"/>
      <path id="Path_1155" data-name="Path 1155" d="M528.718,268.649a9.076,9.076,0,0,1,3.643,3.564s.05-5.119-1.418-7.052A3.01,3.01,0,0,1,528.718,268.649Z" transform="translate(-503.704 -235.024)" fill="#e8e9ea"/>
      <g id="Group_513" data-name="Group 513" transform="translate(10.171 0.639)">
        <path id="Path_1156" data-name="Path 1156" d="M520.972,256.242a31.279,31.279,0,0,1-.72,9.2c1.943,3.087,8.8,4.687,10.059,1.144a10.2,10.2,0,0,1,.972-5.409C532.6,258.923,520.972,256.242,520.972,256.242Z" transform="translate(-512.533 -234.249)" fill="#eab18b"/>
        <path id="Path_1157" data-name="Path 1157" d="M521.108,256.242a29.911,29.911,0,0,1,.052,3.4c.633.648,1.132,1.231,1.132,1.231a12.834,12.834,0,0,0,8.127,5.289,9.716,9.716,0,0,1,1-4.993C532.733,258.923,521.108,256.242,521.108,256.242Z" transform="translate(-512.669 -234.249)" fill="#d39775"/>
        <path id="Path_1158" data-name="Path 1158" d="M533.3,235.51a21.547,21.547,0,0,1,2.58,9.4c-.19.652.923,1.9.651,4.617-.2,1.972-.595,5.978-.8,8.024a1.69,1.69,0,0,1-1.385,1.505c-3.96.7-10.3,1.1-14.926-5.184,0,0-2.227-2.608-3.259-2.933s-5.082-5.493-5.082-9.675c0-4.432,2.447-11.154,11.545-11.154A12.675,12.675,0,0,1,533.3,235.51Z" transform="translate(-511.079 -230.106)" fill="#eab18b"/>
        <g id="Group_512" data-name="Group 512" transform="translate(4.182 1.763)">
          <path id="Path_1159" data-name="Path 1159" d="M537.914,245.238a21.547,21.547,0,0,0-2.58-9.4,11.924,11.924,0,0,0-3.955-3.641,11.124,11.124,0,0,1,2.26,2.456s3.779,4.761,3.779,9.649c-.19.652.893,1.536.621,4.252-.2,1.972-1.293,6.266-1.5,8.311a1.689,1.689,0,0,1-1.385,1.505c-2.86.5-7.428.666-11.229-1.547,4.231,3.5,9.161,3.137,12.453,2.556a1.689,1.689,0,0,0,1.385-1.505c.2-2.046.6-6.052.8-8.024C538.837,247.139,537.724,245.89,537.914,245.238Z" transform="translate(-517.298 -232.201)" fill="#e0a380"/>
          <path id="Path_1160" data-name="Path 1160" d="M516.049,254.019a4.081,4.081,0,0,0,.7.607C516.515,254.4,516.276,254.2,516.049,254.019Z" transform="translate(-516.049 -235.66)" fill="#dd9e7a"/>
        </g>
      </g>
      <path id="Path_1161" data-name="Path 1161" d="M520.387,256.66a.668.668,0,1,1-.667-.668A.667.667,0,0,1,520.387,256.66Z" transform="translate(-502.172 -233.571)" fill="#fcf5ff"/>
      <path id="Path_1162" data-name="Path 1162" d="M528.86,269.51s-2.7.968-2.893,3.536c0,0-5.47-2.673-5.328-8.379C520.638,264.667,526.272,269.548,528.86,269.51Z" transform="translate(-502.423 -234.946)" fill="#e8e9ea"/>
      <path id="Path_1163" data-name="Path 1163" d="M525.781,285.724c1.022,2.308.294,4.838,4.473,11.13.046,7.91,3.191,23.766.166,27.566,0,0-17.136-2.148-20.06-8.564,1.545-3.533,6.409-11.158.877-19.128L507.9,275.919l-.13-5.325c.439-1.7,7.179-5.06,10.831-5.927l.412,3.192,2.811,5.937,2.1,8.4Z" transform="translate(-500.383 -234.946)" fill="#2f3033"/>
      <path id="Path_1164" data-name="Path 1164" d="M520.157,264.667s-2.253,2.821-2.558,8.219c1.519-.3,5.609,1.972,5.609,1.972l-2.2,7c2.158.931,8.348,15.179,10.8,15C531.812,296.854,522.963,268.38,520.157,264.667Z" transform="translate(-501.941 -234.946)" fill="#4f4f4f"/>
      <g id="Group_517" data-name="Group 517" transform="translate(0 35.064)">
        <g id="Group_515" data-name="Group 515" transform="translate(2.866 55.594)">
          <path id="Path_1165" data-name="Path 1165" d="M508.372,337.084s.308,10.125,1.455,15.3c-.752,1.146-5.025,3.05-5.215,1.693-1.219-8.728-2.213-16.921-2.213-16.921Z" transform="translate(-502.398 -337.084)" fill="#eab18b"/>
          <path id="Path_1166" data-name="Path 1166" d="M505.028,356.757s.71,7.46,1.05,7.651a13.7,13.7,0,0,0,3.985,1.579c3.133.836,3.978-.612,3.978-.612a29.748,29.748,0,0,1-.115-4.545c.171-1.717-2.678-1.831-3.537-5.315s-5.214-.029-5.214-.029Z" transform="translate(-502.815 -339.759)" fill="#eab18b"/>
          <g id="Group_514" data-name="Group 514" transform="translate(7.725 19.729)">
            <path id="Path_1167" data-name="Path 1167" d="M515.023,365.095a6.164,6.164,0,0,1-3.073-3.239" transform="translate(-511.637 -360.74)" fill="none" stroke="#c98865" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="0.583"/>
            <path id="Path_1168" data-name="Path 1168" d="M511.578,362.5a2.447,2.447,0,0,0,.793-1.967" transform="translate(-511.578 -360.53)" fill="none" stroke="#c98865" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="0.564"/>
          </g>
        </g>
        <g id="Group_516" data-name="Group 516">
          <path id="Path_1169" data-name="Path 1169" d="M499.01,308.614c-.015-.155-.028-1.146-.011-1.3.31-2.783,3.648-28.864,4.366-31.2.916-2.983,2.887-5.677,6.056-4.985,2.5.546,5.24,4.995,4.469,9.771s-6.085,27.334-6.351,28.59c0,0,1.425,28.953,2.355,31.877,0,0-2.731,3.566-6.218,2.245C501.858,337.339,499.01,308.614,499.01,308.614Z" transform="translate(-498.991 -271.017)" fill="#2f3033"/>
        </g>
        <path id="Path_1170" data-name="Path 1170" d="M510.6,271.129a4.7,4.7,0,0,0-.678-.094c2.259,1.349,4.322,5.589,3.547,10.056-.875,5.045-6.732,28.849-7.026,30.173,0,0,.8,25.379,1.623,32.328a7.65,7.65,0,0,0,3.008-2.225c-.93-2.924-2.354-31.877-2.354-31.877.265-1.255,5.58-23.814,6.35-28.59S513.1,271.675,510.6,271.129Z" transform="translate(-500.173 -271.019)" fill="#26282d"/>
      </g>
      <path id="Path_1171" data-name="Path 1171" d="M533.608,312.41c0,.532-.31.963-.692.963s-.692-.431-.692-.963.31-.964.692-.964S533.608,311.879,533.608,312.41Z" transform="translate(-504.26 -242.362)" fill="#4f4f4f"/>
      <path id="Path_1172" data-name="Path 1172" d="M533.735,235.779s-3.456,8.384-13.741,13.793a2.528,2.528,0,0,0-2.418-2.643l.261,3.186.539,1.23,1.014,2.672a31.532,31.532,0,0,1-.156,4.11c-4.531-.862-12.519-11.2-9.1-20.58,3.366-9.223,14.248-9.13,18.036-7.065C531.723,232.417,533.833,235.378,533.735,235.779Z" transform="translate(-500.627 -229.346)" fill="#a3745a"/>
      <g id="Group_518" data-name="Group 518" transform="translate(8.681 4.816)">
        <path id="Path_1173" data-name="Path 1173" d="M519.05,254.032c-6.41,1.023-10.327-13.307-7.058-18.962a12.457,12.457,0,0,0-1.854,3.385c-3.424,9.382,4.564,19.717,9.1,20.58a31.54,31.54,0,0,0,.156-4.11Z" transform="translate(-509.308 -235.07)" fill="#8e5d44"/>
      </g>
      <path id="Path_1174" data-name="Path 1174" d="M521.85,250.4s-2.991-8.457,2.359-15.833A30.058,30.058,0,0,0,521.85,250.4Z" transform="translate(-502.483 -230.174)" fill="#8e5d44"/>
      <g id="Group_519" data-name="Group 519" transform="translate(14.442 17.148)">
        <path id="Path_1175" data-name="Path 1175" d="M519.113,251.035c-1.729-1.8-3.92-.258-2.5,1.884.3.46.975.73,1.214,1.155.133.238.262.439.388.625a15.879,15.879,0,0,1,1.186,1.118c.456.162.707-.027,1.028-.39Z" transform="translate(-516.155 -249.807)" fill="#d39779" opacity="0.4"/>
        <path id="Path_1176" data-name="Path 1176" d="M520.676,251.049c-1.089-2.246-3.649-1.454-2.963,1.02.148.532.7.995.8,1.473.48,2.4,1.357,2.618,2.19,2.127" transform="translate(-516.384 -249.725)" fill="#eab18b"/>
      </g>
    </g>
    <g id="Group_535" data-name="Group 535" transform="translate(247.452 211.898)">
      <g id="Group_534" data-name="Group 534">
        <g id="Group_521" data-name="Group 521" transform="translate(32.095 76.272)">
          <path id="Path_1177" data-name="Path 1177" d="M305.708,334.586s1.118,5.04.894,5.7l-1.748,8.485a.483.483,0,0,1-.781.225l-2.43-2.13.751-12.276h3.315Z" transform="translate(-296.721 -321.348)" fill="#eab18b"/>
          <path id="Path_1178" data-name="Path 1178" d="M301.136,318.854s1.622,8.439,3.645,13.238c-.524,1.239-2.616,8.8-3.039,7.525-2.72-8.209-5.948-15.783-5.948-15.783Z" transform="translate(-295.794 -318.854)" fill="#eab18b"/>
          <path id="Path_1179" data-name="Path 1179" d="M306.383,345.182c-.481-.008-1.086-2.921-1.086-4.076C305.3,341.106,305.079,346.4,306.383,345.182Z" transform="translate(-297.298 -322.382)" fill="#d1916d"/>
        </g>
        <g id="Group_522" data-name="Group 522" transform="translate(25.402 26.115)">
          <path id="Path_1180" data-name="Path 1180" d="M289.412,297.28a2.189,2.189,0,0,1-.079-.449c-.193-2.741-1.739-29.315-1.459-31.7.358-3.041,1.784-5.99,4.966-5.882,2.507.086,5.942,3.9,6.041,8.643s-1.044,27.464-1.079,28.723c0,0,5.339,19.985,5.423,22.3-.98,2.694-6.02,3.728-6.02,3.728Z" transform="translate(-287.84 -259.248)" fill="#b2d3ea"/>
        </g>
        <path id="Path_1181" data-name="Path 1181" d="M298.958,296.667c.034-1.258,1.179-23.975,1.079-28.722-.085-4.057-2.606-7.423-4.9-8.37a11.3,11.3,0,0,1,3.325,7.691c.151,4.988-.755,28.846-.776,30.168,0,0,5.049,19.111,5.618,23.014a3.723,3.723,0,0,0,1.08-1.482C304.3,316.653,298.958,296.667,298.958,296.667Z" transform="translate(-263.595 -233.185)" fill="#9ac2d8"/>
        <path id="Path_1182" data-name="Path 1182" d="M274.243,262.461a65.834,65.834,0,0,0-8.124,3.352,9.507,9.507,0,0,0-5.152,8.664c.109,6.717,2.83,13.964,7,20.474,2.509,3.916,1.744,9.763.145,17.088,3.014,3.8,8.607,5.45,13.416,5.023,8.711-.772,11.483-5.942,10.47-9.8,0,0-.867-7.954-.841-13.044a46.816,46.816,0,0,1,1.235-11.083c2.9-.562,6.065-4.566,1.85-12.152-.141-2.353-.211-10.537-6.72-11.731-5.315,1.349-10.812,2.6-12.371,2.955A8.718,8.718,0,0,0,274.243,262.461Z" transform="translate(-258.178 -233.133)" fill="#c4ddf2"/>
        <g id="Group_523" data-name="Group 523" transform="translate(21.695 26.116)">
          <path id="Path_1183" data-name="Path 1183" d="M283.435,291.039s6.369,1.326,10.276.021c0,0-2.18,13.6.608,26.316a6.51,6.51,0,0,0,1.242-5.617s-.867-7.954-.841-13.044a46.762,46.762,0,0,1,1.236-11.083S291.692,291.374,283.435,291.039Z" transform="translate(-283.435 -263.749)" fill="#a6c7dd"/>
          <path id="Path_1184" data-name="Path 1184" d="M298.894,270.98c-.141-2.353-.211-10.537-6.72-11.731-.624.159-1.251.316-1.874.47h0s7.652,5.612,8.273,11.535c0,0,3.2,8.805-1.529,11.878C299.947,282.57,303.108,278.567,298.894,270.98Z" transform="translate(-284.523 -259.249)" fill="#a6c7dd"/>
        </g>
        <path id="Path_1185" data-name="Path 1185" d="M293,239.566s3.112,22.381,7.035,28.245c0,0-9.124-6.115-10.8-9.83s-1.4-17.154-1.4-17.154Z" transform="translate(-262.435 -230.013)" fill="#a56c5e"/>
        <path id="Path_1186" data-name="Path 1186" d="M276.351,262.941s-3.187,3.357,4.793,9.516c0,0,.957-4.607,2.98-5.191a34.945,34.945,0,0,0,3.932,2.094s.892-6.7-2.433-7.507a14.666,14.666,0,0,0-7.14.525Z" transform="translate(-260.517 -233.505)" fill="#9fc7dd"/>
        <g id="Group_525" data-name="Group 525" transform="translate(7.996 0.194)">
          <path id="Path_1187" data-name="Path 1187" d="M276.915,254.226a30.86,30.86,0,0,1-.711,9.073c1.917,3.044,8.683,4.623,9.923,1.128a10.063,10.063,0,0,1,.958-5.336C288.383,256.871,276.915,254.226,276.915,254.226Z" transform="translate(-268.59 -232.53)" fill="#eab18b"/>
          <path id="Path_1188" data-name="Path 1188" d="M277.049,254.226a29.483,29.483,0,0,1,.051,3.358c.624.639,1.117,1.214,1.117,1.214a12.659,12.659,0,0,0,8.017,5.217,9.589,9.589,0,0,1,.986-4.925C288.517,256.871,277.049,254.226,277.049,254.226Z" transform="translate(-268.724 -232.53)" fill="#d39775"/>
          <path id="Path_1189" data-name="Path 1189" d="M289.073,233.774a21.257,21.257,0,0,1,2.545,9.27c-.188.643.91,1.875.643,4.554-.194,1.945-.587,5.9-.788,7.916A1.666,1.666,0,0,1,290.108,257c-3.907.689-10.157,1.088-14.725-5.114,0,0-2.2-2.572-3.215-2.893s-5.013-5.419-5.013-9.545c0-4.372,2.414-11,11.389-11A12.5,12.5,0,0,1,289.073,233.774Z" transform="translate(-267.155 -228.443)" fill="#eab18b"/>
          <g id="Group_524" data-name="Group 524" transform="translate(4.126 1.739)">
            <path id="Path_1190" data-name="Path 1190" d="M293.628,243.371a21.257,21.257,0,0,0-2.545-9.27,11.76,11.76,0,0,0-3.9-3.591,10.981,10.981,0,0,1,2.23,2.423s3.728,4.7,3.728,9.52c-.188.643.88,1.515.613,4.194-.194,1.945-1.276,6.181-1.476,8.2a1.667,1.667,0,0,1-1.367,1.485c-2.821.5-7.327.657-11.076-1.526,4.173,3.453,9.037,3.094,12.285,2.521a1.666,1.666,0,0,0,1.366-1.485c.2-2.018.593-5.97.788-7.916C294.539,245.246,293.44,244.014,293.628,243.371Z" transform="translate(-273.291 -230.51)" fill="#e0a380"/>
            <path id="Path_1191" data-name="Path 1191" d="M272.058,252.033a4.078,4.078,0,0,0,.694.6C272.518,252.413,272.283,252.208,272.058,252.033Z" transform="translate(-272.058 -233.922)" fill="#dd9e7a"/>
          </g>
        </g>
        <path id="Path_1192" data-name="Path 1192" d="M276.222,254.723a.656.656,0,1,1-.656-.656A.656.656,0,0,1,276.222,254.723Z" transform="translate(-260.389 -232.312)" fill="#fcf5ff"/>
        <g id="Group_528" data-name="Group 528" transform="translate(4.152 76.356)">
          <path id="Path_1193" data-name="Path 1193" d="M284.2,421.481c2.314-12.622-2.9-33.615-1.132-43.719,0,0-2.991-26.165-3.217-38.071a46.121,46.121,0,0,1,4.514-20.736s8.714.588,11.257,1.512c0,0,8.678,23.2-.96,59.671.6,2.078-4.122,31.812-3.226,42.273.571,3.923,3.816,12.589,3.852,14.072s-3.816,2.166-4.155,2.059-2.087-.3-2.087-.511-3.8-11.839-3.8-11.839-.919-3.389-.95-3.5S284.2,421.481,284.2,421.481Z" transform="translate(-265.323 -318.954)" fill="#eab18b"/>
          <path id="Path_1194" data-name="Path 1194" d="M297.344,320.609a21.191,21.191,0,0,0-3.679-.751c1.389,10,4.155,35.462-.454,54.3-.223,1.879-.173,4.376-.2,5.134s.072,3.363-2.317,3.923a3.158,3.158,0,0,0,2.535.8s-2.033,30.268-1.982,32.689-1.4,4.649,1.078,9.808,2.328,10.268,4.318,10.883a1.052,1.052,0,0,0,.366-.771c-.036-1.483-3.282-10.149-3.852-14.072-.9-10.461,3.826-40.2,3.226-42.273C306.022,343.812,297.344,320.609,297.344,320.609Z" transform="translate(-267.043 -319.097)" fill="#d69976"/>
          <g id="Group_526" data-name="Group 526" transform="translate(17.178 100.055)">
            <path id="Path_1195" data-name="Path 1195" d="M284.668,455.469h0a.7.7,0,0,1-.7-.7v-10.7h1.027v11.077A.325.325,0,0,1,284.668,455.469Z" transform="translate(-283.154 -438.843)" fill="#276c93"/>
            <path id="Path_1196" data-name="Path 1196" d="M285.037,437.859s-2.036.3-2.036,4.348,2.17,3.262,2.714,5.134,2.235,9.992,5.255,10.945,5.677,2.032,7.79,1.663c.886-.187.3-1.616-.3-1.948-.517-.284-2.317-3.261-2.825-4.112a.119.119,0,0,0-.214.015,3.511,3.511,0,0,1-3.012,1.469c-1.842-.181-2.524-.888-7.477-16.091Z" transform="translate(-283.001 -437.859)" fill="#337aaa"/>
            <path id="Path_1197" data-name="Path 1197" d="M298.983,459.329c-2.114.37-4.771-.709-7.79-1.662s-4.711-9.073-5.255-10.945-2.714-1.087-2.714-5.134a9.485,9.485,0,0,1,.1-1.407,7.853,7.853,0,0,0-.321,2.394c0,4.047,2.17,3.262,2.714,5.134s2.235,9.992,5.255,10.945,5.677,2.032,7.79,1.663c.49-.1.523-.584.36-1.051A.541.541,0,0,1,298.983,459.329Z" transform="translate(-283.001 -438.227)" fill="#276c93"/>
          </g>
          <path id="Path_1198" data-name="Path 1198" d="M281.482,379.113c.831-25.3,4.96-27.216,1.073-52.072-.66-4.218-14.854-3.578-14.854-3.578-3.484,2.23-5.564,9.26-5.031,15.675.826,9.95,10.177,30.44,8.088,39.749-5.074,15.024-.1,34.741-2.256,47.94.081.236-.155.641-.056.852.013,8.8-1.377,15.719.672,17.943,1.551,1.684,4.909,3.437,6.129.83.454-.972.081-4.4,0-5.989.029-3.044-.647-6.918-.727-11.983a3.239,3.239,0,0,0,.136-.748S278.72,401.06,281.482,379.113Z" transform="translate(-262.587 -319.663)" fill="#eab18b"/>
          <path id="Path_1199" data-name="Path 1199" d="M283.246,379.282c.831-25.3,4.96-27.216,1.073-52.072-.321-2.049-4.307-2.643-8.32-2.715,1.452,9.374,4.81,34.2,2.875,52.669.292,2.094.831,1.916.586,2.864s-.15,2.238-2.348,2.429.186,2.579,2.41,1.911c0,0-3.588,39.567-4.67,43.389s2.26,14.17,1.21,16.463-1.99,1.776-2.293,2.579a1.848,1.848,0,0,0,.01.921c1.287.439,2.572.31,3.232-1.1.454-.972.081-4.4,0-5.989.029-3.044-.647-6.918-.727-11.983a3.24,3.24,0,0,0,.136-.748S280.484,401.229,283.246,379.282Z" transform="translate(-264.351 -319.833)" fill="#d69976"/>
          <path id="Path_1200" data-name="Path 1200" d="M269.338,445.927s-.533,13.017.525,17a3.214,3.214,0,0,0,5.712,1.324c.718-1.022.317-4.239.427-6.013,0,0,.528,4.294.666,6.447a7.532,7.532,0,0,1-.218,2.352l-1.138,4.478a1.677,1.677,0,0,1-1.625,1.263h-.768a1.676,1.676,0,0,1-1.489-.909l-2.205-4.281a9.882,9.882,0,0,1-1.081-5.186c.186-2.773,1.193-6.4-.316-10.279C266.981,450.505,268.213,446.16,269.338,445.927Z" transform="translate(-263.373 -339.083)" fill="#337aaa"/>
          <g id="Group_527" data-name="Group 527" transform="translate(4.212 107.449)">
            <path id="Path_1201" data-name="Path 1201" d="M274.3,471.749h-.768a1.676,1.676,0,0,1-1.489-.909l-2.205-4.281a9.874,9.874,0,0,1-1.081-5.186c.186-2.774,1.193-6.4-.316-10.279-.51-.978-.145-2.968.471-4.448-.924,1.345-1.738,4.324-1.075,5.594,1.51,3.882.5,7.505.316,10.279a9.882,9.882,0,0,0,1.081,5.186l2.206,4.281a1.676,1.676,0,0,0,1.489.909h.768a1.677,1.677,0,0,0,1.625-1.263l.076-.3A1.671,1.671,0,0,1,274.3,471.749Z" transform="translate(-267.592 -446.646)" fill="#276c93"/>
          </g>
        </g>
        <path id="Path_1202" data-name="Path 1202" d="M293.211,318.235a4.783,4.783,0,0,0-.141-1.169s-.977-4.8-1.573-9.34c-2.221,7.863-19.516,7.606-22.16,5.793a22.785,22.785,0,0,1-1.031,4.345,16.313,16.313,0,0,1-3.779,5.747c-1.759,1.868-3.19,5.045-3.19,10.6,0,8.213,9.992,33.15,8.843,40.128.171.661.989,1.16,2.2,1.487.331-2.547.809-7.11.37-9.5a32.183,32.183,0,0,1,1.806,9.861,25.659,25.659,0,0,0,12.229-2.128,16.6,16.6,0,0,0,8.015-8.4A97.076,97.076,0,0,0,293.211,318.235Z" transform="translate(-258.237 -240.818)" fill="#354554"/>
        <g id="Group_529" data-name="Group 529" transform="translate(11.943 66.908)">
          <path id="Path_1203" data-name="Path 1203" d="M271.91,386.188c-.015.207-.034.41-.064.593a1.244,1.244,0,0,0,.3.517C272.067,386.912,271.989,386.54,271.91,386.188Z" transform="translate(-271.846 -320.165)" fill="#2a3a47"/>
          <path id="Path_1204" data-name="Path 1204" d="M298.34,318.235a4.784,4.784,0,0,0-.141-1.169s-.977-4.8-1.573-9.34a6.95,6.95,0,0,1-2.936,3.808c.516,3.389,1.043,6.156,1.043,6.156a5.435,5.435,0,0,1,.142,1.247,110.281,110.281,0,0,1,1.588,50.6,9.633,9.633,0,0,1-1.059,2.247,15.731,15.731,0,0,0,4.525-6.12A97.076,97.076,0,0,0,298.34,318.235Z" transform="translate(-275.309 -307.726)" fill="#2a3a47"/>
        </g>
        <g id="Group_530" data-name="Group 530" transform="translate(10.885 66.575)">
          <path id="Path_1205" data-name="Path 1205" d="M277.38,315.223c-3.556,0-6.046-.324-6.792-.8l.262-1.784c1.457,1,10.451,1.69,16.872-.631,3.007-1.088,4.669-2.731,5.22-4.674l.44,2.529c-.227.7-1.544,2.442-5.089,3.725A33.957,33.957,0,0,1,277.38,315.223Z" transform="translate(-270.588 -307.33)" fill="#337aaa"/>
        </g>
        <rect id="Rectangle_249" data-name="Rectangle 249" width="20.251" height="24.367" transform="translate(11.953 53.029)" fill="#ffe4b3"/>
        <rect id="Rectangle_250" data-name="Rectangle 250" width="20.251" height="24.367" transform="translate(10.884 53.897)" fill="#f4b638"/>
        <path id="Path_1206" data-name="Path 1206" d="M290.839,303.359a11.007,11.007,0,0,1-1.265-.043c-1.127-.123-1.221,1.749-3.516,2.29a2.016,2.016,0,0,0-.365.123,1.8,1.8,0,0,0-1.231-.977c-6.723.977-11.126,1.766-13.874,2.318V315.3c6.82-1.984,14.6-4.277,15.664-4.709a2.331,2.331,0,0,0,.374-1.482l.214.027s3.742.21,3.987.136l.012-.006Z" transform="translate(-259.703 -240.118)" fill="#f48b38"/>
        <g id="Group_531" data-name="Group 531" transform="translate(15.918 61.949)">
          <path id="Path_1207" data-name="Path 1207" d="M276.808,304.633a74.337,74.337,0,0,0,10.1-.236c.716.547,1.764,3.437.865,3.5-5.779.39-11.206.8-11.206.8Z" transform="translate(-276.57 -302.239)" fill="#eab18b"/>
          <path id="Path_1208" data-name="Path 1208" d="M289.573,307.489s3.723.441,3.972.382,3.2-2.285,3.179-3.709a2.858,2.858,0,0,0-1.078-2.178,19.594,19.594,0,0,1-2.986-.138c-1.117-.192-1.326,1.67-3.65,2.07s-.263,3.417-.263,3.417Z" transform="translate(-278.367 -301.833)" fill="#eab18b"/>
        </g>
        <g id="Group_532" data-name="Group 532" transform="translate(0 33.792)">
          <path id="Path_1209" data-name="Path 1209" d="M263.122,308.47a4.485,4.485,0,0,1-5.465-4.578c.442-10.079,1.4-29.8,2.207-31.385,1.385-2.731,3.741-5.008,6.689-3.809,2.324.945,4.233,5.707,2.69,10.2s-4.079,19.707-4.546,20.877c0,0,4.129-.963,17.975-2.111,1.663.24,2.5,4.848,1.428,5.939C282.735,304.061,270.547,306.81,263.122,308.47Z" transform="translate(-257.653 -268.371)" fill="#b2d3ea"/>
        </g>
        <g id="Group_533" data-name="Group 533" transform="translate(1.26 34.162)">
          <path id="Path_1210" data-name="Path 1210" d="M282.911,303.179l-.089.008c.885,1.448,1.154,4.245.353,5.064-1.366.46-13.554,3.21-20.979,4.87a4.46,4.46,0,0,1-3.045-.392,4.469,4.469,0,0,0,4.209,1.26c7.425-1.66,19.613-4.409,20.98-4.87C285.406,308.027,284.573,303.419,282.911,303.179Z" transform="translate(-259.15 -274.259)" fill="#9ac2d8"/>
          <path id="Path_1211" data-name="Path 1211" d="M269.178,278.1c-1.543,4.489-4.079,19.706-4.545,20.876,0,0-.4,1.756,1.164.868.831-3.851,3.205-16.977,4.546-20.877,1.525-4.436-.321-9.131-2.608-10.154C269.409,270.626,270.432,274.451,269.178,278.1Z" transform="translate(-260.012 -268.811)" fill="#9ac2d8"/>
        </g>
        <path id="Path_1212" data-name="Path 1212" d="M279.264,239.866c1.25,5.7,10.411-.125,11.987,1.249.422-.974-1.1-9.534-7.853-12.029-5.67-2.1-14.615-.659-17.359,6.93-2.5,6.906-.4,17.569,4.83,27.929.421.937,4.092,5.777,12.81,9.417C278.417,263.912,278.145,247.577,279.264,239.866Z" transform="translate(-258.816 -228.213)" fill="#a56c5e"/>
        <path id="Path_1213" data-name="Path 1213" d="M283.677,274.538c-4.371-7.853-5.3-20.459-4.843-28.92-2.612,10.778.91,25.395.91,25.395s-6.406-5.814-8.113-8.662c-4.3-7.917-4.875-20.637-4.883-26.723a11.648,11.648,0,0,0-.711,1.564c-2.5,6.906-.4,17.569,4.83,27.929C271.288,266.058,274.96,270.9,283.677,274.538Z" transform="translate(-258.816 -229.388)" fill="#93584d"/>
      </g>
      <path id="Path_1214" data-name="Path 1214" d="M274.319,267.78s-3.855-11.082-3.027-20c0,0,.764,12.109,5.986,22.486Z" transform="translate(-259.796 -231.315)" fill="#93584d"/>
    </g>
    <path id="Path_1215" data-name="Path 1215" d="M208.148,193.417c-9.908,4.976-12.707,15.164-12.161,26.241a37.1,37.1,0,0,0,15.535,28.126c7.181,5.027,16.044,7.386,22.851,12.909s10.729,16.569,4.667,22.9c-5.718,5.973-15.517,3.605-23.685,4.886-11.82,1.853-21.129,12.772-22.636,24.646s4.02,24.018,12.921,32.016,20.8,12.137,32.712,13.212c12.247,1.105,25.74-.506,35.735,6.659,13.105,9.393,14.313,29.94,27.656,38.991,9.1,6.171,21.481,5.068,31.6.782s18.8-11.321,28.252-16.932,20.486-9.9,31.278-7.818c12.3,2.376,21.466,12.314,31.947,19.181s26.324,9.939,34.216.206c9.136-11.27,1.128-32.13,13.258-40.085,5.743-3.766,13.449-2.354,19.741.4s12.182,6.723,18.945,7.911c11.713,2.056,23.98-5.453,28.83-16.316s2.779-24.108-3.913-33.943S518.4,300.98,506.912,297.9c-7.674-2.058-15.755-2.694-23.1-5.717s-14.152-9.319-14.552-17.256c-.625-12.387,13.214-19.754,20.393-29.866a30.753,30.753,0,0,0-22.5-48.453c-12.621-1.054-24.4,5.612-36.386,9.707s-27.362,4.748-35.179-5.221c-4.542-5.791-5.216-13.615-7.681-20.55a40.21,40.21,0,0,0-73.215-5.747c-6.641,12.207-6.693,27.268-13.865,39.17a37.959,37.959,0,0,1-36.609,17.613c-14.206-1.884-23.443-14.217-30.759-26.544C229.792,198.864,219.361,187.786,208.148,193.417Z" transform="translate(0 -5.157)" fill="#e7eaf7"/>
    <g id="Group_551" data-name="Group 551" transform="translate(294.746 121.275)">
      <path id="Path_1216" data-name="Path 1216" d="M326.766,350.765a15.347,15.347,0,0,1,.974-1.472c.715-.993,6.354-.416,6.354-.416s.623,1.341.693,1.409.473,4.222.473,4.222l-6.2,1.468-2.124-2.228Z" transform="translate(-315.784 -157.331)" fill="#4c565e"/>
      <path id="Path_1217" data-name="Path 1217" d="M334.786,350.287c-.07-.068-.693-1.409-.693-1.409s-5.639-.577-6.354.416a15.347,15.347,0,0,0-.974,1.472v.013c1.405,1.757,6.47,2.275,8.132.284A6.091,6.091,0,0,0,334.786,350.287Z" transform="translate(-315.784 -157.331)" fill="#3e484f"/>
      <g id="Group_536" data-name="Group 536" transform="translate(10.686 191.323)">
        <path id="Path_1218" data-name="Path 1218" d="M326.574,351.9l-.16,5.44.748.562v4.833s.431,4.436,1.031,5.634A2.53,2.53,0,0,0,330.164,370a6.773,6.773,0,0,0,6.435-1.153,7.029,7.029,0,0,0-.294-2.645c-.264-.536-5.141-.45-5.141-.45l-4.307-11.943Z" transform="translate(-326.414 -349.159)" fill="#353d44"/>
        <path id="Path_1219" data-name="Path 1219" d="M334.777,348.89l-.732-.247a25.013,25.013,0,0,1,.719,4.721,24.671,24.671,0,0,0-7.115.617c-.417-2.381-.3-4.691-.576-4.5-1.466,2.417-.045,3.7.123,6.489.032,1.632.413,9.323,1.048,10.931.767,1.526,2.89,1.881,3.856,2.078,1.755.357,4.234-.908,4.357-1.708C337.194,366.507,335.722,354.153,334.777,348.89Z" transform="translate(-326.414 -348.643)" fill="#273035"/>
        <path id="Path_1220" data-name="Path 1220" d="M334.777,348.915l-.337-.113c.828,5.287,2.028,16.564,1.4,17.277-.108.771-2.276,1.989-3.812,1.646-.844-.189-2.7-.532-3.373-2-.556-1.55-.889-8.962-.917-10.537-.125-2.3-1.054-3.544-.507-5.319-.036-.273-.082-.415-.159-.363-1.466,2.418-.045,3.7.123,6.489.032,1.632.413,9.323,1.048,10.931.767,1.526,2.89,1.882,3.856,2.078,1.755.357,4.234-.908,4.357-1.708C337.194,366.532,335.722,354.178,334.777,348.915Z" transform="translate(-326.414 -348.668)" fill="#21292d"/>
      </g>
      <path id="Path_1221" data-name="Path 1221" d="M347.814,343.335s.6-.2.811.052-.777,1.442-.777,1.442-.179-.707-.179-.73S347.814,343.335,347.814,343.335Z" transform="translate(-319.098 -156.466)" fill="#353d44"/>
      <path id="Path_1222" data-name="Path 1222" d="M355.354,342.4V336.66H348.1v7.027l4.373,4.69,6.068-1.568s-.513-.991-.73-1.3A36.26,36.26,0,0,0,355.354,342.4Z" transform="translate(-319.165 -155.42)" fill="#4c565e"/>
      <path id="Path_1223" data-name="Path 1223" d="M355.483,342.544l-.13-.147V336.66H348.1v5.835C350.2,343.55,353.7,344.724,355.483,342.544Z" transform="translate(-319.165 -155.42)" fill="#3e484f"/>
      <g id="Group_537" data-name="Group 537" transform="translate(27.917 186.868)">
        <path id="Path_1224" data-name="Path 1224" d="M346.891,345.662v2.747c0,1.246,2.711,3.206,4.875,3.829a.2.2,0,0,0,.257-.208l-.024-.65a14.417,14.417,0,0,1,2.78,1.521c.112.081.223.676.335.76,1.571,1.182,3.2,2.185,4.535,3.115,1.964,1.363,5.733-.125,6.72-.554a.4.4,0,0,0,.241-.37v-.475l-3.992.1-6.813-3.933-8.013-4.723-.083-.79-.818-.371" transform="translate(-346.891 -343.716)" fill="#353d44"/>
        <path id="Path_1225" data-name="Path 1225" d="M366.461,354.313a48.15,48.15,0,0,1-7.681-6.673,12.371,12.371,0,0,0-2.03-3.192c-2.294,0-4.573,1.7-5.236,2.241-1.931-1.085-3.885-2.9-3.824-3.341a1.429,1.429,0,0,0-.8,1.335v1.9c0,1.3,2.965,3.372,5.143,3.9s5.773,3.384,8.045,4.962c1.969,1.367,5.549.128,6.469-.227a.345.345,0,0,0,.22-.322A.725.725,0,0,0,366.461,354.313Z" transform="translate(-346.891 -343.349)" fill="#273035"/>
        <path id="Path_1226" data-name="Path 1226" d="M366.461,354.744l-.008-.006c-1.042.392-4.463,1.519-6.374.192-2.272-1.578-5.868-4.434-8.045-4.962s-5.143-2.6-5.143-3.9v.952c0,1.3,2.965,3.372,5.143,3.9s5.773,3.384,8.045,4.962c1.969,1.367,5.549.128,6.469-.227a.345.345,0,0,0,.22-.322A.725.725,0,0,0,366.461,354.744Z" transform="translate(-346.891 -343.78)" fill="#21292d"/>
      </g>
      <g id="Group_539" data-name="Group 539" transform="translate(33.111 27.938)">
        <path id="Path_1227" data-name="Path 1227" d="M369.382,226.292s-.127-3.812-.365-5.056a10.818,10.818,0,0,1-1.006-3.186c.014-.247-4.81-25.723-4.81-25.723l-1.368-28.252c.042-2.2-.2-5.13-2.4-5.172s-3.579.169-3.621,2.373l-.6,31.341,0,.132a3.168,3.168,0,0,0,.122.916l8.225,28.146c-.033,1.269-.141,2.69,0,7.673s3.106,3.517,3.465,2.87C368.158,229.662,369.382,226.292,369.382,226.292Z" transform="translate(-353.404 -155.177)" fill="#ffcbae"/>
        <g id="Group_538" data-name="Group 538">
          <path id="Path_1228" data-name="Path 1228" d="M362.267,218.383c4.391,1.186,6.409-1,6.409-1l-4.966-26.1s1.1-21.393-.737-29.389c-1.478-6.432-6.7-7.421-6.7-7.421-3.1.024-3.235,3.3-3.212,6.4l1.129,31.954Z" transform="translate(-353.064 -154.476)" fill="#211b4e"/>
        </g>
        <path id="Path_1229" data-name="Path 1229" d="M363.965,191.286s1.1-21.393-.736-29.389c-1.478-6.433-6.7-7.421-6.7-7.421a2.8,2.8,0,0,0-1.864.623,3.394,3.394,0,0,1,.988-.145s5.218.988,6.7,7.42c1.838,8,.737,29.389.737,29.389l4.966,26.1a3.2,3.2,0,0,1-.458.374,4.229,4.229,0,0,0,1.334-.852Z" transform="translate(-353.318 -154.476)" fill="#17123b"/>
      </g>
      <g id="Group_540" data-name="Group 540" transform="translate(20.39 73.636)">
        <path id="Path_1230" data-name="Path 1230" d="M342.167,209.968c-9.524,5.566-.375,48.732,2.637,65.6-1.2,4.886.531,12.157.8,21.261.3,10.224-.431,23.479-.431,23.479,1.6,1.023,7.387,4.04,9.249-.072,0,0,2.263-31.033,2.386-40.89a342.57,342.57,0,0,0,2.487-58.391C358.305,216.23,350.391,205.161,342.167,209.968Z" transform="translate(-337.946 -208.784)" fill="#211b4e"/>
        <path id="Path_1231" data-name="Path 1231" d="M360.22,220.955c-.958-4.589-8.453-15.159-16.418-11.358,7.12-1.235,13.395,7.877,14.266,12.049a342.667,342.667,0,0,1-2.487,58.391c-.124,9.857-2.386,40.89-2.386,40.89a3.509,3.509,0,0,1-1.111,1.4,3.412,3.412,0,0,0,3.263-2.094s2.263-31.033,2.386-40.89A342.57,342.57,0,0,0,360.22,220.955Z" transform="translate(-338.875 -208.784)" fill="#17123b"/>
        <path id="Path_1232" data-name="Path 1232" d="M348.276,234.621s-4.756-3.842-10.291-3.7c.417,15.6,4.889,37.314,6.825,48.157-1.2,4.886.531,12.157.8,21.261.3,10.224-.431,23.479-.431,23.479a15.854,15.854,0,0,0,2.768,1.372l-.374-37.368S350.617,261.4,348.276,234.621Z" transform="translate(-337.952 -212.292)" fill="#17123b"/>
        <path id="Path_1233" data-name="Path 1233" d="M348.128,234.783a18.257,18.257,0,0,0-2.646-1.921,15.738,15.738,0,0,0-7.5-1.943c.417,15.6,4.889,37.314,6.825,48.157a21.835,21.835,0,0,0-.356,6.207C345.6,278.084,348.639,256.665,348.128,234.783Z" transform="translate(-337.952 -212.292)" fill="#100c2e"/>
      </g>
      <g id="Group_541" data-name="Group 541" transform="translate(8.954 91.238)">
        <path id="Path_1234" data-name="Path 1234" d="M329.714,231.215c-9.524,5.566-3.8,39.584-2.082,56.341-2.007,3.636-.624,11.526-.518,18.844.147,10.17-1.255,23.542-1.255,23.542.134,2.8,8.663,3.767,9.021-.081l4.4-42.489s6.012-25.911,6.711-52.78C345.99,234.591,337.937,226.409,329.714,231.215Z" transform="translate(-324.356 -229.702)" fill="#211b4e"/>
      </g>
      <path id="Path_1235" data-name="Path 1235" d="M345.515,233.73A337.211,337.211,0,0,1,338.2,286.8l-4.973,44.743a2.475,2.475,0,0,1-.671,1.482c1.837-.122,3.472-.885,3.625-2.524l4.4-42.489s6.012-25.911,6.711-52.78A17.12,17.12,0,0,0,345.515,233.73Z" transform="translate(-316.702 -139.103)" fill="#17123b"/>
      <path id="Path_1236" data-name="Path 1236" d="M357.5,235.427c-.009-6.154-.177-10.85-.273-13.574q-.128-.849-.255-1.719c-1.622-5.056-9.122-14.714-16.9-10.167-3.269,1.91-4.338,8.251-4.21,16.436a10.694,10.694,0,0,0-6.089,1.494c-3.023,1.766-4.505,6.4-5.072,12.44,3.175,2.029,9.244,1.787,14.674,1.24C345.242,240.989,354.281,238.595,357.5,235.427Z" transform="translate(-315.456 -135.148)" fill="#2f373d" opacity="0.2"/>
      <g id="Group_542" data-name="Group 542" transform="translate(4.523 27.202)">
        <path id="Path_1237" data-name="Path 1237" d="M357.588,221.421c0-5.54-2.914-18.116-3.157-28.176-.268-11.066,3.157-19.5-.061-27.966-1.537-4.042-3.661-13.646-9.533-11.318-4.607,1.967-7.394,1.823-9.484,3.065-5.95,6.6-11.4,4.679-14.847,10.889-4.33,7.249,2.121,22.808,8.026,37.317.036,2.674-4.121,7.6-7.066,18.432-.369,6.649,8.935,6.5,16.874,5.7C345.273,228.665,356.629,225.452,357.588,221.421Z" transform="translate(-319.091 -153.602)" fill="#a6bdd5"/>
        <path id="Path_1238" data-name="Path 1238" d="M358.532,165.279c-1.537-4.042-3.661-13.646-9.533-11.318a29.624,29.624,0,0,1-3.65,1.283c3.5,13.493,10.367,44.356,8.2,71.685,4.2-1.429,7.688-3.351,8.2-5.508,0-5.54-2.914-18.116-3.157-28.176C358.326,182.179,361.751,173.744,358.532,165.279Z" transform="translate(-323.253 -153.602)" fill="#8daecf"/>
      </g>
      <path id="Path_1239" data-name="Path 1239" d="M358.2,194.381s2.4,22.914,1.623,30.18a7,7,0,0,0,4.517-4.061s-4.033-25.118-3.432-28.688.37-15.3.363-21.642-4.236-16.718-12.1-16.145,3.684,6.76,3.684,6.76l1.372,5.171,3.43,20.576Z" transform="translate(-318.901 -126.463)" fill="#211b4e"/>
      <path id="Path_1240" data-name="Path 1240" d="M358.132,194.85s-2.73-30.4-8.7-34.731l-3.4-3.156a17.087,17.087,0,0,1,9.6,3.652l-1.819,2.84,3.262.475S358.664,193.968,358.132,194.85Z" transform="translate(-318.838 -126.933)" fill="#60589e"/>
      <path id="Path_1241" data-name="Path 1241" d="M347.534,165.284c-.348,1.637-3.5,2.354-7.046,1.6s-6.131-2.694-5.782-4.332,3.5-2.354,7.046-1.6S347.884,163.647,347.534,165.284Z" transform="translate(-317.038 -127.517)" fill="#898989"/>
      <path id="Path_1242" data-name="Path 1242" d="M371.179,238.523c-.523.831-2.309.108-2.124-2.909C368.285,237.83,369.812,240.9,371.179,238.523Z" transform="translate(-322.455 -139.401)" fill="#e5ac8f"/>
      <g id="Group_544" data-name="Group 544" transform="translate(10.499 3.339)">
        <path id="Path_1243" data-name="Path 1243" d="M336.356,152.23a37.11,37.11,0,0,1-1.02,9.184c2.007,3.188,10.615,5.181,11.914,1.522a10.527,10.527,0,0,1,1-5.585C349.61,155.028,336.356,152.23,336.356,152.23Z" transform="translate(-327.642 -129.521)" fill="#dda07f"/>
        <path id="Path_1244" data-name="Path 1244" d="M336.542,152.23s.03.855-.034,2.212a13.536,13.536,0,0,0,10.887,7.487,9.491,9.491,0,0,1,1.044-4.578C349.8,155.028,336.542,152.23,336.542,152.23Z" transform="translate(-327.827 -129.521)" fill="#cc8a6d"/>
        <path id="Path_1245" data-name="Path 1245" d="M349.133,130.822a22.252,22.252,0,0,1,2.664,9.7c-.2.673.953,1.962.673,4.767-.2,2.036-.614,6.173-.824,8.285a1.745,1.745,0,0,1-1.43,1.555c-4.089.721-10.631,1.139-15.411-5.353,0,0-2.3-2.692-3.365-3.028s-5.247-5.672-5.247-9.991c0-4.576,2.527-11.516,11.921-11.516A13.087,13.087,0,0,1,349.133,130.822Z" transform="translate(-326.192 -125.243)" fill="#eab18b"/>
        <g id="Group_543" data-name="Group 543" transform="translate(9.074 3.689)">
          <path id="Path_1246" data-name="Path 1246" d="M353.507,141.22a22.252,22.252,0,0,0-2.664-9.7,10.666,10.666,0,0,0-1.6-1.89c.23.3.344.492.344.492a22.252,22.252,0,0,1,2.664,9.7c-.2.672.953,1.962.673,4.767-.2,2.036-.614,6.173-.824,8.285a1.744,1.744,0,0,1-1.43,1.554c-3.617.639-9.152,1.029-13.691-3.381,4.733,5.872,10.99,5.478,14.95,4.78a1.745,1.745,0,0,0,1.43-1.555c.21-2.112.62-6.249.824-8.285C354.46,143.182,353.311,141.893,353.507,141.22Z" transform="translate(-336.976 -129.627)" fill="#e0a380"/>
        </g>
      </g>
      <path id="Path_1247" data-name="Path 1247" d="M336.206,135.169c2.543,1.847-1.032,9.355-.539,11.276-.739.32-1.214-2.161-2.423-2.09s-.2,1.61-.2,1.61l1.719,3.879-.154,2.772c-.392-4.549-9.9-5.657-9.287-16.963.184-2.77,2.478-6.429,3.31-5.655S335.507,132.526,336.206,135.169Z" transform="translate(-315.551 -122.641)" fill="#2e2b2a"/>
      <path id="Path_1248" data-name="Path 1248" d="M338.264,202.785c.223,2.7.061,24.768-3.459,32.34-4.444.185-14.976-2.411-16.672-6.095,0,0,8.114-15.755,7.569-21.608s-3.283-17.176-3.283-17.176l-1.13-26.163s7.62-4.957,12.225-5.973l.125,1.555-.29-.069-.565.21-1.231,1.485-.83,5.415,2.407,6.21,1.724,6.434,2.236,17.914Z" transform="translate(-314.415 -127.115)" fill="#211b4e"/>
      <g id="Group_545" data-name="Group 545" transform="translate(6.884 37.158)">
        <path id="Path_1249" data-name="Path 1249" d="M323.016,191.408s1.633,6.754,2.628,12.526L328,177.439c.527-6.49-1-9.376-2.605-10.815a4.525,4.525,0,0,0-3.5-1.156Z" transform="translate(-321.896 -165.434)" fill="#17123b"/>
      </g>
      <path id="Path_1250" data-name="Path 1250" d="M335.278,159.893c-5.682,3.929,7.04,21.615,4.95,43.175,0,0-3.459-13.77-7.589-25.937l1.862-3.572-3.978-.929C330.7,171.472,331.171,161.015,335.278,159.893Z" transform="translate(-316.38 -127.397)" fill="#60589e"/>
      <g id="Group_549" data-name="Group 549" transform="translate(0 36.727)">
        <g id="Group_546" data-name="Group 546" transform="translate(0.54 5.595)">
          <path id="Path_1251" data-name="Path 1251" d="M318.187,174.965c-.378,2.5-.7,5-1.051,7.5l-.962,7.51c-.332,2.5-.6,5.013-.9,7.519l-.881,7.52-.014.119a3.381,3.381,0,0,0,0,.72l.99,9.144c.337,3.047.643,6.1,1.012,9.142l1.071,9.138c.389,3.043.747,6.089,1.162,9.129a2.878,2.878,0,0,0,3.241,2.464c1.474-.2,1.747-2.072,1.7-3.526-.106-3.067.519-5.555.387-8.62l-1.935-8.563c-.151-3.063-.463-6.59-.646-9.65L319.812,204.5l-.017.839,1.286-7.679c.332-2.5,1.685-4.481,1.986-6.987l2.193-7.181c.281-2.508.592-5.013.847-7.525a3.992,3.992,0,0,0-7.919-1Z" transform="translate(-314.357 -171.57)" fill="#ffcbae"/>
        </g>
        <path id="Path_1252" data-name="Path 1252" d="M319.42,255.476s-.242,4.546.682,6.146,3.856,3.648,5.495,3.579a3.544,3.544,0,0,0,2.216-.624c-.67-1.5-3.036-3.1-3.1-5.738-.2-1.6.467-.772.467-.426a4.558,4.558,0,0,0,.993,2.193c.461.323.675.185.554-1.362a12.77,12.77,0,0,0-.392-3.093c-.323-1.131-1.467-1.118-1.785-2.268a6.363,6.363,0,0,1-.2-2.164l-5.4.433A7.439,7.439,0,0,1,319.42,255.476Z" transform="translate(-314.545 -178.682)" fill="#ffcbae"/>
        <g id="Group_547" data-name="Group 547" transform="translate(4.404 73.037)">
          <path id="Path_1253" data-name="Path 1253" d="M325.728,260.065a4.348,4.348,0,0,1-.037-.48c-.184-.241-.446-.271-.309.814.061,2.58,2.326,4.169,3.049,5.643a1.759,1.759,0,0,0,.4-.239C328.157,264.3,325.791,262.7,325.728,260.065Z" transform="translate(-319.963 -252.944)" fill="#f2b699"/>
          <path id="Path_1254" data-name="Path 1254" d="M324,252.053a6.367,6.367,0,0,0,.2,2.165c.316,1.149,1.461,1.136,1.784,2.268a12.8,12.8,0,0,1,.393,3.093,5.439,5.439,0,0,1,.005,1.142c.31.113.442-.2.341-1.477a12.77,12.77,0,0,0-.392-3.093c-.323-1.131-1.467-1.118-1.784-2.268a6.351,6.351,0,0,1-.2-2.164l-5.4.433s.047.109.11.3Z" transform="translate(-318.949 -251.719)" fill="#f2b699"/>
        </g>
        <g id="Group_548" data-name="Group 548">
          <path id="Path_1255" data-name="Path 1255" d="M317.721,239.356c.388-.3,1.117.749,2.752.924,1.5.161,3.925-.514,4.053-1.42.426-3.233-2.5-34.144-2.5-34.144l4.674-27.358c1.048-6.426-.242-9.425-1.726-10.989-1.622-1.709-4.813-2.333-5.965.546-2.512,6.279-5.294,36.016-5.294,36.016S317.241,238.444,317.721,239.356Z" transform="translate(-313.715 -164.922)" fill="#211b4e"/>
        </g>
        <path id="Path_1256" data-name="Path 1256" d="M326.134,166.379a4.743,4.743,0,0,0-2.624-1.4,4.688,4.688,0,0,1,.464.419c1.484,1.563,2.773,4.562,1.726,10.989l-4.674,27.359s3.6,29.6,3.7,36.017c.53-.243.907-.553.955-.894.426-3.233-2.5-34.144-2.5-34.144l4.674-27.358C328.907,170.941,327.618,167.942,326.134,166.379Z" transform="translate(-314.874 -164.931)" fill="#17123b"/>
      </g>
      <g id="Group_550" data-name="Group 550" transform="translate(14.638 20.445)">
        <path id="Path_1257" data-name="Path 1257" d="M334.166,146.925c-1.785-1.859-4.047-.266-2.585,1.945.314.475,1.007.753,1.254,1.193.138.246.271.454.4.645a16.566,16.566,0,0,1,1.225,1.154,1.067,1.067,0,0,0,1.2-.408Z" transform="translate(-331.111 -145.657)" fill="#d39779" opacity="0.4"/>
        <path id="Path_1258" data-name="Path 1258" d="M335.78,146.939c-1.124-2.319-3.767-1.5-3.059,1.054.152.549.726,1.027.825,1.521.5,2.476,1.4,2.7,2.262,2.2" transform="translate(-331.348 -145.572)" fill="#eab18b"/>
      </g>
      <path id="Path_1259" data-name="Path 1259" d="M335.839,137.466c3.768,1.431,12.973-3.023,14.769-3.916,3.746-3.533,2.031-9.822-1.616-12.275-1.932,1.754-3.813,2.8-8.6,1.877s-14.2,2.924-14.2,9.819C328.639,136.065,335.839,137.466,335.839,137.466Z" transform="translate(-315.693 -121.275)" fill="#2e2b2a"/>
      <path id="Path_1260" data-name="Path 1260" d="M351.058,121.275l-.018.015a7.373,7.373,0,0,1-3.666,9.729c-4.52,2.355-6.813,4.711-8.149,6.708,4.261.29,11.838-3.376,13.449-4.178C356.42,130.016,354.706,123.727,351.058,121.275Z" transform="translate(-317.759 -121.275)" fill="#1c1a19"/>
    </g>
    <g id="Group_564" data-name="Group 564" transform="translate(411.146 123.216)">
      <path id="Path_1261" data-name="Path 1261" d="M495.226,184.815l.431-6.9,5.264-1.878,1.918,6.184-4.146,3.327Z" transform="translate(-458.61 -131.899)" fill="#eab18b"/>
      <path id="Path_1262" data-name="Path 1262" d="M501.85,178.934l-.9-2.891-5.263,1.877-.259,4.143C497.965,181.449,500.718,180.135,501.85,178.934Z" transform="translate(-458.643 -131.899)" fill="#db9b77"/>
      <path id="Path_1263" data-name="Path 1263" d="M499.641,173.315c.523,2.257-10.135,6.505-11.827,3.793,0,0-4.448-10.364-4.953-13-.864-4.5,1.283-8.424,5.8-8.981C493.42,154.543,498.017,166.292,499.641,173.315Z" transform="translate(-456.62 -128.58)" fill="#afd2db"/>
      <path id="Path_1264" data-name="Path 1264" d="M500.215,173.315c-1.624-7.023-6.221-18.772-10.985-18.184a7.083,7.083,0,0,0-2.941,1.012,7.475,7.475,0,0,1,1.876-.482c4.764-.588,9.361,11.161,10.985,18.184.177.763-.927,1.754-2.529,2.627C498.77,175.495,500.428,174.238,500.215,173.315Z" transform="translate(-457.194 -128.58)" fill="#9bc4ce"/>
      <path id="Path_1265" data-name="Path 1265" d="M459.944,349.682a40.168,40.168,0,0,0,.476-6.086c.422-1.149,7.2.027,7.2.027s-.283,3.469-.214,3.538c.248.243,1.022,6.3,1.022,6.3l-6.055,1.434-2.124-2.228Z" transform="translate(-453.017 -158.381)" fill="#384444"/>
      <path id="Path_1266" data-name="Path 1266" d="M460.492,343.6c0,.941-.039,1.834-.093,2.636,1.47.785,4.556.962,7.087.21.056-1.033.2-2.819.2-2.819S460.914,342.447,460.492,343.6Z" transform="translate(-453.089 -158.381)" fill="#2c3838"/>
      <path id="Path_1267" data-name="Path 1267" d="M488.584,342.162v-5.736h-7.259a41.265,41.265,0,0,1-.539,5.114c0,2.642,4.913,6.6,4.913,6.6l6.069-1.568s-.514-.991-.73-1.3A36.454,36.454,0,0,0,488.584,342.162Z" transform="translate(-456.321 -157.324)" fill="#384444"/>
      <path id="Path_1268" data-name="Path 1268" d="M481,340.529a12.9,12.9,0,0,0,7.622-.014v-4.089h-7.259S481.253,338.732,481,340.529Z" transform="translate(-456.354 -157.324)" fill="#2c3838"/>
      <g id="Group_552" data-name="Group 552" transform="translate(16.465 71.498)">
        <path id="Path_1269" data-name="Path 1269" d="M475.5,209.734c-9.524,5.566-.375,48.732,2.638,65.6-1.2,4.886.53,12.157.8,21.261.3,10.225-.325,21.376-.325,21.376,1.6,1.022,7.388,1.425,9.249-.073,0,0,2.157-28.929,2.28-38.786a342.662,342.662,0,0,0,2.487-58.391C491.637,216,483.723,204.927,475.5,209.734Z" transform="translate(-471.278 -208.55)" fill="#384444"/>
        <path id="Path_1270" data-name="Path 1270" d="M494.12,220.721c-.836-4-6.648-12.56-13.407-12.152,5.417,1.83,9.685,8.556,10.394,12a354.817,354.817,0,0,1-2.619,59.958c-.121,8.745-1.81,32.113-2.267,38.3a6.066,6.066,0,0,0,3.131-.932s2.157-28.929,2.28-38.786A342.666,342.666,0,0,0,494.12,220.721Z" transform="translate(-472.774 -208.55)" fill="#323d3d"/>
        <path id="Path_1271" data-name="Path 1271" d="M481.483,234.581s-4.684-3.78-10.162-3.7c.445,15.592,4.892,37.187,6.823,47.992-1.2,4.886.53,12.157.8,21.261.3,10.225-.326,21.376-.326,21.376a7.267,7.267,0,0,0,2.631.756l-.433-34.485S483.841,261.355,481.483,234.581Z" transform="translate(-471.285 -212.09)" fill="#2e3a3a"/>
        <path id="Path_1272" data-name="Path 1272" d="M477.916,286.993a305.494,305.494,0,0,0,3.833-49.056c-.081-1.117-.167-2.236-.265-3.356,0,0-4.684-3.78-10.162-3.7.445,15.592,4.892,37.187,6.823,47.992A26.578,26.578,0,0,0,477.916,286.993Z" transform="translate(-471.285 -212.09)" fill="#273333"/>
      </g>
      <g id="Group_553" data-name="Group 553" transform="translate(3.993 89.1)">
        <path id="Path_1273" data-name="Path 1273" d="M462.85,230.981c-2.094,1.224-4.667-1.4-5.491,2.089-2.923,12.38,2.072,41.181,3.409,54.252-2.007,3.636-.623,11.526-.517,18.844.146,10.17-.6,17.9-.6,17.9.134,2.8,8.662,3.766,9.021-.081l3.745-36.845s6.012-25.912,6.711-52.78C479.127,234.357,471.074,226.174,462.85,230.981Z" transform="translate(-456.457 -229.468)" fill="#384444"/>
      </g>
      <path id="Path_1274" data-name="Path 1274" d="M477.784,232.527c-.852,28.187-7.11,55.379-7.11,55.379l-4.005,38.7a2.654,2.654,0,0,1-.069.394c1.859-.111,3.526-.873,3.68-2.529l3.745-36.845s6.012-25.912,6.711-52.78A18.787,18.787,0,0,0,477.784,232.527Z" transform="translate(-454.072 -140.853)" fill="#323d3d"/>
      <g id="Group_555" data-name="Group 555" transform="translate(1.286 27.054)">
        <g id="Group_554" data-name="Group 554">
          <path id="Path_1275" data-name="Path 1275" d="M490.519,212.936c0-5.541-1.733-7.455-1.977-17.515-.268-11.065,3.394-20.787.176-29.252-1.536-4.042-3.9-12.36-9.77-10.032-4.607,1.967-7.417-.122-9.507,1.119-5.555,4.2-13.062,5.388-15.721,10.9-.831,6.787-1.257,18.1,4.648,32.613,0,0,.308,11.481-2.15,15.428-.369,6.648,4.911,9.163,12.85,8.364C476,223.861,489.56,216.967,490.519,212.936Z" transform="translate(-453.24 -155.733)" fill="#d8eaef"/>
        </g>
        <path id="Path_1276" data-name="Path 1276" d="M493.65,195.422c-.268-11.066,3.394-20.787.176-29.252-1.408-3.7-3.51-10.988-8.367-10.4,3.6,1.247,5.094,7.768,6.178,11.307,2.823,9.224-1.189,19.538-1.383,31.447-.175,10.789,1.444,12.937,1.228,18.849,2.248-1.575,3.84-3.152,4.145-4.439C495.626,207.4,493.892,205.482,493.65,195.422Z" transform="translate(-458.348 -155.734)" fill="#bfdbe2"/>
        <path id="Path_1277" data-name="Path 1277" d="M457.256,229.886c1.331,1.861,5.361,6.773,14.728,4.557s17.616-7.687,19.225-11.134v2.9c-1.609,3.448-9.858,8.918-19.225,11.134s-13.4-2.7-14.728-4.556Z" transform="translate(-453.877 -166.446)" fill="#3b5056"/>
        <g id="Path_1278" data-name="Path 1278" transform="translate(-457.861 -167.718)" fill="none" strokeMiterlimit="10">
          <path d="M483.047,233.073l3.275-1.672a.6.6,0,0,1,.874.566l-.111,2.17a1.163,1.163,0,0,1-.632.974l-3.111,1.588a.658.658,0,0,1-.957-.62l.11-2.157A1.014,1.014,0,0,1,483.047,233.073Z" stroke="none"/>
          <path d="M 486.292724609375 232.4048461914062 L 483.4482421875 233.8570861816406 C 483.4055786132812 233.87890625 483.3775939941406 233.9215698242188 483.3751831054688 233.9682464599609 L 483.2845458984375 235.7415008544922 L 486.0534057617188 234.3282318115234 C 486.1424560546875 234.2827911376953 486.2010803222656 234.1920623779297 486.2063293457031 234.0929107666016 L 486.292724609375 232.4048461914062 M 486.5951232910156 231.3348083496094 C 486.9210510253906 231.3348083496094 487.2149658203125 231.6061706542969 487.196533203125 231.9677581787109 L 487.08544921875 234.1379089355469 C 487.0635681152344 234.5519104003906 486.8229064941406 234.9238433837891 486.4534912109375 235.1123352050781 L 483.3425903320312 236.7001800537109 C 483.2444763183594 236.7503356933594 483.1424865722656 236.7733001708984 483.0433044433594 236.7733001708984 C 482.6865539550781 236.7733001708984 482.3654479980469 236.4763946533203 482.3858337402344 236.0800170898438 L 482.4960632324219 233.9233245849609 C 482.5145874023438 233.5623321533203 482.7249450683594 233.2383728027344 483.0472412109375 233.0734558105469 L 486.3222351074219 231.4014434814453 C 486.4116516113281 231.3557281494141 486.504638671875 231.3348083496094 486.5951232910156 231.3348083496094 Z" stroke="none" fill="#b29a7b"/>
        </g>
      </g>
      <path id="Path_1279" data-name="Path 1279" d="M469.348,340.161c.544.123.037,2.949.141,3.755.038.241-.127.433-.387.555-3.42,1.611-8.95,1.006-9.162-.725.079-1.743-.259-3.349.394-3.386l-.01.552c.305.555,8.449.881,8.985-.327Z" transform="translate(-453.013 -157.917)" fill="#4c7271"/>
      <g id="Group_556" data-name="Group 556" transform="translate(6.91 182.244)">
        <path id="Path_1280" data-name="Path 1280" d="M470.5,340.161l-.039.425c-.118.267-.608.458-1.3.589.045.923-.181,2.479-.108,3.044.038.241-.128.432-.387.555a8.947,8.947,0,0,1-1.5.534,10.307,10.307,0,0,0,3.088-.837c.26-.122.425-.315.387-.555C470.533,343.11,471.04,340.284,470.5,340.161Z" transform="translate(-461.071 -340.161)" fill="#3b6361"/>
        <path id="Path_1281" data-name="Path 1281" d="M467.1,341.34c-2.6.268-6.568-.005-6.78-.39l.01-.552c-.347.019-.411.487-.411,1.176C461.859,341.823,465.606,341.86,467.1,341.34Z" transform="translate(-459.924 -340.199)" fill="#3b6361"/>
      </g>
      <path id="Path_1282" data-name="Path 1282" d="M480.055,334.942c-.551.139-.242,2.917-.324,3.726-.031.241.143.428.411.543,3.536,1.5,9.45.763,9.615-.974-.134-1.74.164-3.356-.5-3.373l.026.552c-.294.564-8.591,1.139-9.173-.05Z" transform="translate(-456.152 -157.077)" fill="#4c7271"/>
      <g id="Group_557" data-name="Group 557" transform="translate(23.569 177.787)">
        <path id="Path_1283" data-name="Path 1283" d="M487.254,335.9c-2.578.285-6.739.316-7.146-.515l-.052-.423c-.249.062-.322.665-.335,1.38C482.123,336.654,486.513,336.294,487.254,335.9Z" transform="translate(-479.721 -334.879)" fill="#3b6361"/>
        <path id="Path_1284" data-name="Path 1284" d="M490.466,334.864l.026.552a3.3,3.3,0,0,1-1.542.406c.1.625.01,1.683.1,2.8-.053.56-.709,1.015-1.685,1.326,1.971-.208,3.525-.831,3.608-1.709C490.835,336.5,491.133,334.882,490.466,334.864Z" transform="translate(-480.932 -334.864)" fill="#3b6361"/>
      </g>
      <path id="Path_1285" data-name="Path 1285" d="M480.175,172.249s2.966,12.273.114,31.11c.889,2.927,2.112,10.82,2.112,10.82s3.154-8.889,3.94-11.59c.541-19.665-5.38-30.5-5.38-30.5Z" transform="translate(-456.224 -131.271)" fill="#bfdbe2"/>
      <path id="Path_1286" data-name="Path 1286" d="M480.175,172.24s3.438,11.919,1.317,30.495c1,2.835,2.529,10.527,2.529,10.527s2.807-8.834,3.488-11.512c-.219-19.3-6.555-29.7-6.555-29.7Z" transform="translate(-456.224 -131.266)" fill="#44647f"/>
      <path id="Path_1287" data-name="Path 1287" d="M474.333,165.86l1.651,1.49,2.519.232,3.142-1.027-.721-2.658-5.379,1.177Z" transform="translate(-455.298 -129.973)" fill="#eab18b"/>
      <path id="Path_1288" data-name="Path 1288" d="M477.168,170.053s.624,2.078,1.547,2.262a3.615,3.615,0,0,0,2.932-1.5,4.743,4.743,0,0,0,.115-2.571c-.254-.315-1.6-.232-1.6-.232l-.759.173s-2.2,1.356-2.207,1.42S477.168,170.053,477.168,170.053Z" transform="translate(-455.748 -130.624)" fill="#44647f"/>
      <path id="Path_1289" data-name="Path 1289" d="M479.289,166.89a6.1,6.1,0,0,1,6.276,1.862s.373-6.123-3.736-9.226C482.336,160.718,483.768,164.335,479.289,166.89Z" transform="translate(-456.084 -129.28)" fill="#bfd7dd"/>
      <path id="Path_1290" data-name="Path 1290" d="M468.866,160.552a4.2,4.2,0,0,0-2.307,3.084s2.358,6.864,7.35,10.465c0,0,.776-4.936,3.362-7.049C476.009,166.622,469.022,164.405,468.866,160.552Z" transform="translate(-454.066 -129.443)" fill="#bfd7dd"/>
      <path id="Path_1291" data-name="Path 1291" d="M485.042,183.482,468.8,193.355l-.962-6.349-8.31,2.8L462.589,199a4.952,4.952,0,0,0,7.919,2.194l16.423-14.069,1.665,1.07s1.385.231,2.471-2.493S487.858,182.189,485.042,183.482Z" transform="translate(-452.95 -132.982)" fill="#eab18b"/>
      <path id="Path_1292" data-name="Path 1292" d="M467.834,187.785l-8.31,2.8,1.336,4.005c2.55-.481,5.935-1.955,7.466-3.566Z" transform="translate(-452.95 -133.76)" fill="#db9b77"/>
      <path id="Path_1293" data-name="Path 1293" d="M491.508,188.582s1.385.231,2.471-2.493c.162-.407-.257,0-.313-.324l-8.13-.423-7.65,4.65v1.77l7.635-.547,4.322-3.7Z" transform="translate(-455.861 -133.373)" fill="#d88d68"/>
      <path id="Path_1294" data-name="Path 1294" d="M472.445,193.24l9.556.717,5.934-5.083-7.336-.589Z" transform="translate(-454.999 -133.84)" fill="#d88d68"/>
      <path id="Path_1295" data-name="Path 1295" d="M456.964,189.006c1.108,2.034,11.9-1.864,11.363-5.017,0,0-3.554-10.7-4.924-13.006-2.343-3.942-6.828-5.065-10.406-1.4C449.646,173.022,453.517,182.675,456.964,189.006Z" transform="translate(-451.712 -130.521)" fill="#afd2db"/>
      <path id="Path_1296" data-name="Path 1296" d="M469.241,183.989s-3.554-10.7-4.924-13.006c-1.6-2.7-4.21-4.073-6.842-3.5a8.546,8.546,0,0,1,4.412,3.872c1.516,2.549,5.671,14.26,5.671,14.26a1.786,1.786,0,0,1-.161,1.112C468.613,185.861,469.394,184.895,469.241,183.989Z" transform="translate(-452.626 -130.522)" fill="#9bc4ce"/>
      <path id="Path_1297" data-name="Path 1297" d="M499.19,180.688l1.111,3.989a4.4,4.4,0,0,1-3.925,5.571l-20.028,1.434v-3.968c0-1.628.849-2.154,2.475-2.246l15.359-.609" transform="translate(-455.618 -132.635)" fill="#eab18b"/>
      <path id="Path_1298" data-name="Path 1298" d="M477.448,186.438s-7.134,1.133-5.991,7.413c0,0,5.362-1.432,6.571-2.586a8.778,8.778,0,0,0,1.837-2.709Z" transform="translate(-454.823 -133.547)" fill="#eab18b"/>
      <g id="Group_558" data-name="Group 558" transform="translate(6.435 188.779)">
        <path id="Path_1299" data-name="Path 1299" d="M459.518,351.054l-.159,5.44.748.562v4.833s.43,4.436,1.031,5.634a2.53,2.53,0,0,0,1.972,1.631A6.773,6.773,0,0,0,469.544,368a7.03,7.03,0,0,0-.294-2.645c-.264-.536-5.141-.45-5.141-.45L459.8,352.962Z" transform="translate(-459.359 -348.423)" fill="#353d44"/>
        <path id="Path_1300" data-name="Path 1300" d="M467.4,348.009l-.089-.082s.294,2.192.4,4.613a24.7,24.7,0,0,0-7.115.617c-.417-2.381-.3-4.691-.576-4.5-1.467,2.418-.045,3.7.123,6.489.032,1.632.413,9.323,1.049,10.931.767,1.526,2.89,1.882,3.856,2.078,1.754.357,4.234-.908,4.357-1.708C470.139,365.682,468.345,353.272,467.4,348.009Z" transform="translate(-459.359 -347.927)" fill="#44647f"/>
        <path id="Path_1301" data-name="Path 1301" d="M467.4,348.024h0c.828,5.287,2.015,16.508,1.386,17.22-.108.772-2.277,1.991-3.812,1.647-.844-.189-2.7-.532-3.373-2-.556-1.55-.889-8.962-.917-10.537-.125-2.3-1.054-3.544-.507-5.319-.036-.273-.082-.415-.159-.363-1.467,2.418-.045,3.7.123,6.489.032,1.632.413,9.323,1.049,10.931.767,1.526,2.89,1.882,3.856,2.078,1.754.357,4.234-.908,4.357-1.708C470.139,365.7,468.345,353.287,467.4,348.024Z" transform="translate(-459.359 -347.942)" fill="#375968"/>
      </g>
      <g id="Group_559" data-name="Group 559" transform="translate(23.665 184.216)">
        <path id="Path_1302" data-name="Path 1302" d="M479.836,344.817v2.747c0,1.246,2.711,3.206,4.875,3.829a.2.2,0,0,0,.257-.208l-.024-.65a14.417,14.417,0,0,1,2.78,1.521c.111.081.223.676.335.76,1.571,1.182,3.2,2.184,4.535,3.115,1.964,1.363,5.733-.125,6.72-.554a.4.4,0,0,0,.241-.37v-.475l-3.993.1-6.812-3.933-8.013-4.723-.084-.79-.817-.371" transform="translate(-479.836 -342.871)" fill="#353d44"/>
        <path id="Path_1303" data-name="Path 1303" d="M499.405,353.468a48.135,48.135,0,0,1-7.68-6.673,12.37,12.37,0,0,0-2.03-3.192c-2.294,0-4.573,1.7-5.236,2.241-1.931-1.085-3.885-2.9-3.824-3.341a1.429,1.429,0,0,0-.8,1.335v1.9c0,1.3,2.965,3.372,5.143,3.9s5.773,3.384,8.045,4.962c1.969,1.367,5.549.128,6.469-.227a.345.345,0,0,0,.22-.322A.724.724,0,0,0,499.405,353.468Z" transform="translate(-479.836 -342.504)" fill="#44647f"/>
        <path id="Path_1304" data-name="Path 1304" d="M499.405,353.9l-.007-.006c-1.042.392-4.463,1.519-6.374.192-2.272-1.578-5.868-4.434-8.045-4.962s-5.143-2.6-5.143-3.9v.952c0,1.3,2.965,3.372,5.143,3.9s5.773,3.384,8.045,4.962c1.969,1.367,5.549.128,6.469-.227a.345.345,0,0,0,.22-.322A.724.724,0,0,0,499.405,353.9Z" transform="translate(-479.836 -342.935)" fill="#375968"/>
      </g>
      <g id="Group_561" data-name="Group 561" transform="translate(6.764 1.066)">
        <path id="Path_1305" data-name="Path 1305" d="M469.914,151.836a37.084,37.084,0,0,1-1.019,9.184c2.007,3.187,10.614,5.181,11.913,1.522a10.536,10.536,0,0,1,1-5.585C483.169,154.634,469.914,151.836,469.914,151.836Z" transform="translate(-461.2 -129.127)" fill="#dda07f"/>
        <path id="Path_1306" data-name="Path 1306" d="M470.1,151.836s.031.855-.034,2.212a13.536,13.536,0,0,0,10.887,7.487A9.5,9.5,0,0,1,482,156.957C483.354,154.634,470.1,151.836,470.1,151.836Z" transform="translate(-461.385 -129.127)" fill="#cc8a6d"/>
        <path id="Path_1307" data-name="Path 1307" d="M482.692,130.428a22.252,22.252,0,0,1,2.664,9.7c-.2.672.953,1.962.673,4.767-.2,2.036-.615,6.173-.825,8.285a1.743,1.743,0,0,1-1.43,1.554c-4.089.722-10.631,1.139-15.412-5.353,0,0-2.3-2.692-3.365-3.028s-5.247-5.672-5.247-9.991c0-4.576,2.527-11.516,11.921-11.516A13.088,13.088,0,0,1,482.692,130.428Z" transform="translate(-459.75 -124.849)" fill="#eab18b"/>
        <g id="Group_560" data-name="Group 560" transform="translate(9.074 3.689)">
          <path id="Path_1308" data-name="Path 1308" d="M487.066,140.826a22.252,22.252,0,0,0-2.664-9.7,10.652,10.652,0,0,0-1.6-1.89c.23.3.345.492.345.492a22.252,22.252,0,0,1,2.664,9.7c-.2.672.953,1.962.673,4.767-.2,2.036-.615,6.172-.825,8.285a1.743,1.743,0,0,1-1.43,1.554c-3.617.639-9.152,1.029-13.692-3.381,4.734,5.872,10.99,5.478,14.95,4.779a1.743,1.743,0,0,0,1.43-1.554c.21-2.112.621-6.249.825-8.285C488.019,142.788,486.869,141.5,487.066,140.826Z" transform="translate(-470.534 -129.233)" fill="#e0a380"/>
        </g>
      </g>
      <path id="Path_1309" data-name="Path 1309" d="M466.143,142.723s2.928-4.147,1.931-6.917c8.237,2.955,9.25-3.945,15.254-3.543,1.837-.544,2.27-4.578,1.71-5.669-3.579,1.293-6.153-2.507-10.435-2.885-4.987-.646-15.013,1.064-14.836,7.725-3.218,14.11,8.556,17.48,8.285,19.8l.271-2.322Z" transform="translate(-452.901 -123.582)" fill="#d69b7b"/>
      <g id="Group_562" data-name="Group 562" transform="translate(6.334 13.231)">
        <path id="Path_1310" data-name="Path 1310" d="M459.239,139.306c.422,9.658,9.05,12.429,8.818,14.423l.265-2.275C465.771,149.219,460.956,145.809,459.239,139.306Z" transform="translate(-459.239 -139.306)" fill="#c68366"/>
      </g>
      <path id="Path_1311" data-name="Path 1311" d="M480.907,130.137c-3.105,1-4.49,2.34-6.8,3.964s-4.947.956-7.717.621a11.16,11.16,0,0,1-5.685-2.436c.383,1.242,4.777,2.627,5.678,4.586s0,6.418,0,6.418,2.928-4.147,1.931-6.917c8.237,2.955,9.25-3.945,15.254-3.543,1.837-.544,2.27-4.578,1.71-5.669C485.534,129.3,484.011,129.134,480.907,130.137Z" transform="translate(-453.137 -124.15)" fill="#c68366"/>
      <g id="Group_563" data-name="Group 563" transform="translate(10.713 18.307)">
        <path id="Path_1312" data-name="Path 1312" d="M467.5,146.691c-1.785-1.859-4.047-.266-2.585,1.945.315.475,1.008.753,1.254,1.193.138.246.271.454.4.645a16.239,16.239,0,0,1,1.225,1.155,1.068,1.068,0,0,0,1.2-.408Z" transform="translate(-464.444 -145.423)" fill="#d39779" opacity="0.4"/>
        <path id="Path_1313" data-name="Path 1313" d="M469.113,146.705c-1.125-2.319-3.768-1.5-3.06,1.054.152.549.726,1.027.826,1.521.495,2.476,1.4,2.7,2.261,2.2" transform="translate(-464.68 -145.338)" fill="#eab18b"/>
      </g>
    </g>
    <g id="Group_587" data-name="Group 587" transform="translate(341.307 167.292)">
      <g id="Group_575" data-name="Group 575" transform="translate(25.315 69.887)">
        <g id="Group_574" data-name="Group 574">
          <g id="Group_573" data-name="Group 573">
            <g id="Group_568" data-name="Group 568" transform="translate(18.798 107.908)">
              <path id="Path_1314" data-name="Path 1314" d="M422.226,393.486s.6-.2.8.052-.769,1.429-.769,1.429-.178-.7-.178-.724S422.226,393.486,422.226,393.486Z" transform="translate(-421.434 -387.912)" fill="#353d44"/>
              <g id="Group_567" data-name="Group 567">
                <path id="Path_1315" data-name="Path 1315" d="M429.692,392.558v-5.682h-7.187v6.959l4.33,4.644,6.009-1.552s-.508-.981-.723-1.286A35.926,35.926,0,0,0,429.692,392.558Z" transform="translate(-421.501 -386.876)" fill="#4c565e"/>
                <g id="Group_566" data-name="Group 566" transform="translate(0 5.574)">
                  <path id="Path_1316" data-name="Path 1316" d="M421.312,395.791v2.72c0,1.234,2.685,3.175,4.828,3.792a.2.2,0,0,0,.255-.206l-.024-.644a14.294,14.294,0,0,1,2.753,1.505c.11.08.22.669.331.752,1.556,1.17,3.165,2.164,4.491,3.085,1.945,1.351,5.677-.123,6.654-.548a.4.4,0,0,0,.239-.367v-.47l-3.954.1-6.746-3.894-7.934-4.677-.083-.783-.81-.368" transform="translate(-421.312 -393.863)" fill="#2b343a"/>
                  <path id="Path_1317" data-name="Path 1317" d="M422.1,393.5c-.086.633,3.943,4.062,6.109,4.131s3.246-1.144,2.365-2.758a50.132,50.132,0,0,0,10.114,9.485.717.717,0,0,1,.3.581h0a.341.341,0,0,1-.218.32c-.911.352-4.456,1.579-6.406.225-2.249-1.562-5.81-4.391-7.967-4.913s-5.093-2.577-5.093-3.862v-1.887A1.417,1.417,0,0,1,422.1,393.5Z" transform="translate(-421.312 -393.5)" fill="#3e454c"/>
                  <g id="Group_565" data-name="Group 565" transform="translate(0 0.531)">
                    <path id="Path_1318" data-name="Path 1318" d="M432.618,396.361a1.392,1.392,0,0,0,.2-.7,2.488,2.488,0,0,0-.5-.534A2.107,2.107,0,0,1,432.618,396.361Z" transform="translate(-423.058 -394.29)" fill="#353d44"/>
                    <path id="Path_1319" data-name="Path 1319" d="M434.563,405c-2.249-1.563-5.81-4.391-7.967-4.914s-5.093-2.577-5.093-3.862v-1.887a1.53,1.53,0,0,1,.032-.206,1.582,1.582,0,0,0-.223.792v1.886c0,1.286,2.937,3.339,5.093,3.862s5.718,3.352,7.967,4.913c1.95,1.354,5.5.127,6.406-.225a.341.341,0,0,0,.218-.32.7.7,0,0,0-.054-.256C440,405.148,436.5,406.342,434.563,405Z" transform="translate(-421.312 -394.131)" fill="#353d44"/>
                  </g>
                </g>
                <path id="Path_1320" data-name="Path 1320" d="M426.522,397.177s2.685-2.387,5.38-2.382a12.68,12.68,0,0,1,2.326,3.887,10.07,10.07,0,0,1-3.521,1.76Z" transform="translate(-422.138 -388.131)" fill="#353d44"/>
              </g>
            </g>
            <g id="Group_570" data-name="Group 570" transform="translate(1.736 117.895)">
              <path id="Path_1321" data-name="Path 1321" d="M401.384,400.845a15.159,15.159,0,0,1,.963-1.457c.709-.984,6.292-.412,6.292-.412s.617,1.329.686,1.4.468,4.181.468,4.181l-6.138,1.453-2.1-2.206Z" transform="translate(-401.091 -398.754)" fill="#4c565e"/>
              <path id="Path_1322" data-name="Path 1322" d="M401.193,401.967l-.157,5.387.74.557V412.7s.426,4.392,1.02,5.578a2.508,2.508,0,0,0,1.953,1.616,6.7,6.7,0,0,0,6.372-1.142,6.964,6.964,0,0,0-.29-2.619c-.262-.53-5.092-.445-5.092-.445l-4.265-11.827Z" transform="translate(-401.036 -399.255)" fill="#2b343a"/>
              <path id="Path_1323" data-name="Path 1323" d="M409.316,398.987c.937,5.213,2.395,17.445,1.663,18.2-.121.793-2.577,2.044-4.315,1.691-.955-.194-3.058-.546-3.818-2.058-.629-1.592-1.006-9.207-1.038-10.824-.166-2.76-1.574-4.031-.122-6.425.57-.382-.543,9.653,4.741,9.323,4.816-.244,2.164-10.15,2.164-10.15Z" transform="translate(-401.036 -398.744)" fill="#3e454c"/>
              <g id="Group_569" data-name="Group 569" transform="translate(0.006)">
                <path id="Path_1324" data-name="Path 1324" d="M406.958,417.763c-.956-.194-3.059-.545-3.818-2.057-.63-1.592-1.007-9.207-1.038-10.824-.111-1.847-.774-3.028-.769-4.323-.815,1.966.327,3.247.476,5.725.031,1.617.408,9.232,1.038,10.824.76,1.512,2.863,1.864,3.818,2.057,1.738.353,4.194-.9,4.315-1.691a2.345,2.345,0,0,0,.194-1.167A4.953,4.953,0,0,1,406.958,417.763Z" transform="translate(-401.042 -399.032)" fill="#353d44"/>
                <path id="Path_1325" data-name="Path 1325" d="M410.672,405.389c.638-1.912.306-4.688-.013-6.429l-.644-.216A21.55,21.55,0,0,1,410.672,405.389Z" transform="translate(-402.465 -398.744)" fill="#353d44"/>
              </g>
              <path id="Path_1326" data-name="Path 1326" d="M403.948,410.836a47.729,47.729,0,0,0,5.611-.741,45.722,45.722,0,0,0,.124-5.789,23.779,23.779,0,0,0-7.26.608A23.764,23.764,0,0,0,403.948,410.836Z" transform="translate(-401.255 -399.616)" fill="#353d44"/>
            </g>
            <path id="Path_1327" data-name="Path 1327" d="M409.4,400.142c-.069-.068-.693-1.409-.693-1.409s-5.638-.577-6.354.416a15.568,15.568,0,0,0-.974,1.472v.013c1.405,1.757,6.471,2.275,8.133.284A6,6,0,0,0,409.4,400.142Z" transform="translate(-399.354 -280.821)" fill="#424c54"/>
            <path id="Path_1328" data-name="Path 1328" d="M429.692,392.5l.245-5.986h-7.432v5.836C428.518,394.792,429.692,392.5,429.692,392.5Z" transform="translate(-402.703 -278.91)" fill="#424c54"/>
            <g id="Group_571" data-name="Group 571" transform="translate(11.436 0)">
              <path id="Path_1329" data-name="Path 1329" d="M416.783,259.823c-9.524,5.566-.375,48.732,2.638,65.6-1.2,4.886.53,12.157.8,21.261.3,10.225-.431,23.48-.431,23.48,1.6,1.022,7.388,4.039,9.249-.072,0,0,2.264-31.033,2.386-40.89a342.676,342.676,0,0,0,2.487-58.392C432.921,266.085,425.007,255.016,416.783,259.823Z" transform="translate(-412.562 -258.639)" fill="#353d44"/>
              <path id="Path_1330" data-name="Path 1330" d="M434.837,270.809c-.958-4.588-8.453-15.158-16.418-11.357,7.119-1.236,13.394,7.877,14.265,12.048a342.674,342.674,0,0,1-2.487,58.392c-.124,9.857-2.386,40.89-2.386,40.89a3.508,3.508,0,0,1-1.11,1.4,3.411,3.411,0,0,0,3.262-2.094s2.264-31.033,2.386-40.89A342.673,342.673,0,0,0,434.837,270.809Z" transform="translate(-413.491 -258.639)" fill="#2b3238"/>
              <path id="Path_1331" data-name="Path 1331" d="M422.892,284.476s-4.756-3.842-10.291-3.7c.417,15.6,4.889,37.315,6.826,48.158-1.2,4.886.53,12.157.8,21.261.3,10.225-.431,23.48-.431,23.48a15.919,15.919,0,0,0,2.768,1.372l-.374-37.368S425.233,311.251,422.892,284.476Z" transform="translate(-412.569 -262.147)" fill="#2b3238"/>
              <path id="Path_1332" data-name="Path 1332" d="M422.744,284.638a18.311,18.311,0,0,0-2.646-1.922,15.76,15.76,0,0,0-7.5-1.943c.417,15.6,4.889,37.315,6.826,48.158a21.832,21.832,0,0,0-.357,6.207C420.216,327.939,423.256,306.52,422.744,284.638Z" transform="translate(-412.569 -262.147)" fill="#242b30"/>
            </g>
            <g id="Group_572" data-name="Group 572" transform="translate(0 17.603)">
              <path id="Path_1333" data-name="Path 1333" d="M404.33,281.07c-9.524,5.566-3.8,39.584-2.082,56.341-2.007,3.636-.624,11.526-.518,18.844.147,10.17-1.255,23.542-1.255,23.542.134,2.8,8.663,3.767,9.021-.081l4.4-42.49s6.012-25.911,6.711-52.779C420.606,284.446,412.553,276.264,404.33,281.07Z" transform="translate(-398.972 -279.557)" fill="#353d44"/>
            </g>
            <path id="Path_1334" data-name="Path 1334" d="M420.131,283.585a337.274,337.274,0,0,1-7.316,53.069L407.842,381.4a2.483,2.483,0,0,1-.671,1.482c1.837-.123,3.473-.886,3.625-2.524l4.4-42.49s6.012-25.911,6.711-52.779A16.879,16.879,0,0,0,420.131,283.585Z" transform="translate(-400.272 -262.593)" fill="#2b3238"/>
            <path id="Path_1335" data-name="Path 1335" d="M432.112,285.282c-.009-6.154-.177-10.85-.273-13.575q-.128-.848-.254-1.719c-1.623-5.056-9.122-14.713-16.9-10.166-3.268,1.91-4.338,8.251-4.21,16.436a10.694,10.694,0,0,0-6.089,1.494c-3.023,1.766-4.505,6.4-5.072,12.44,3.175,2.029,9.244,1.786,14.674,1.24C419.858,290.844,428.9,288.449,432.112,285.282Z" transform="translate(-399.026 -258.638)" fill="#2f373d" opacity="0.2"/>
          </g>
        </g>
      </g>
      <g id="Group_577" data-name="Group 577" transform="translate(49.231 25.987)">
        <path id="Path_1336" data-name="Path 1336" d="M443.553,277.584s-.126-3.774-.362-5.007a10.712,10.712,0,0,1-1-3.155c.014-.245-4.763-25.472-4.763-25.472l-1.354-27.976c.041-2.183-.2-5.08-2.378-5.121s-3.544.167-3.585,2.349l-.594,31.035,0,.131a3.117,3.117,0,0,0,.121.908l8.144,27.872c-.032,1.256-.139,2.663,0,7.6s3.076,3.482,3.431,2.842C442.341,280.922,443.553,277.584,443.553,277.584Z" transform="translate(-427.73 -207.162)" fill="#ffcbae"/>
        <path id="Path_1337" data-name="Path 1337" d="M442.817,271.682a9.476,9.476,0,0,1-.624-2.259c.014-.245-4.763-25.472-4.763-25.472l-1.354-27.976a9.105,9.105,0,0,0-.569-3.883,9.064,9.064,0,0,0-.987-1.048,1.961,1.961,0,0,0-.822-.19c-2.182-.042-3.543.167-3.585,2.349l-.594,31.035,0,.131a3.117,3.117,0,0,0,.121.908l7.964,27.256A6.524,6.524,0,0,0,442.817,271.682Z" transform="translate(-427.73 -207.162)" fill="#efb497"/>
        <g id="Group_576" data-name="Group 576">
          <path id="Path_1338" data-name="Path 1338" d="M436.508,269.752c4.347,1.175,6.346-.99,6.346-.99l-4.918-25.843s1.09-21.184-.73-29.1c-1.464-6.37-6.632-7.349-6.632-7.349-3.07.024-3.2,3.272-3.18,6.342l1.118,31.643Z" transform="translate(-427.394 -206.468)" fill="#373e44"/>
        </g>
        <path id="Path_1339" data-name="Path 1339" d="M438.1,242.919s1.09-21.184-.73-29.1c-1.464-6.37-6.632-7.349-6.632-7.349a2.619,2.619,0,0,0-2.338,1.177,3.3,3.3,0,0,1,1.067-.173s5.167.979,6.631,7.349c1.82,7.918.73,29.1.73,29.1L441.71,269.6a4.189,4.189,0,0,0,1.3-.836Z" transform="translate(-427.553 -206.468)" fill="#2c343a"/>
      </g>
      <g id="Group_578" data-name="Group 578" transform="translate(20.922 25.259)">
        <path id="Path_1340" data-name="Path 1340" d="M431.874,270.889c0-5.486-2.885-16.068-3.126-26.03-.265-10.958,3.126-19.311-.061-27.694-1.521-4-3.625-13.513-9.44-11.208-4.562,1.948-7.322,1.806-9.392,3.034-5.893,6.531-11.294,4.635-14.7,10.784-4.288,7.179,2.1,22.585,7.947,36.953.036,2.648-4.08,7.529-7,18.252-.366,6.585,8.848,5.3,16.71,4.506C419.679,278.8,430.924,274.88,431.874,270.889Z" transform="translate(-393.752 -205.602)" fill="#b2ceea"/>
      </g>
      <path id="Path_1341" data-name="Path 1341" d="M420.733,223.07l-.844,5.632,4.16,23.2.219,1.959.089.534.269-1.107,1.261-13.416-.631-13.268L421.5,222.88Z" transform="translate(-376.973 -183.083)" fill="#3e454c"/>
      <path id="Path_1342" data-name="Path 1342" d="M417.915,220.9s.617,2.058,1.531,2.241a3.578,3.578,0,0,0,2.9-1.486,4.692,4.692,0,0,0,.114-2.545c-.252-.312-1.583-.231-1.583-.231l-.752.173s-2.18,1.342-2.185,1.405S417.915,220.9,417.915,220.9Z" transform="translate(-376.66 -182.448)" fill="#3e454c"/>
      <path id="Path_1343" data-name="Path 1343" d="M422.513,220.85a3.581,3.581,0,0,1-2.9,1.486c-.651-.13-1.15-1.209-1.384-1.82a1.68,1.68,0,0,0-.282.2c-.006.063-.029.443-.029.443s.617,2.058,1.531,2.241a3.578,3.578,0,0,0,2.9-1.486,5.639,5.639,0,0,0,.229-1.294C422.557,220.706,422.536,220.785,422.513,220.85Z" transform="translate(-376.66 -182.708)" fill="#343c42"/>
      <path id="Path_1344" data-name="Path 1344" d="M423.645,276.6a25.647,25.647,0,0,0,4.6,9.611s7.412-2.166,9.419-6.537c0,0-3.747-30.908-3.272-34.887s1.238-23.384,0-30.6c-1.483-8.655-7.886-9.053-10.506-9.05s-1.341,4.295-1.341,4.295l3.048,5.486,1.4,6.957.337,9.478-1.463,11.059-.783,6.127-.269,2.789-.227.663-1.646,7.613Z" transform="translate(-377.345 -180.27)" fill="#3e454c"/>
      <path id="Path_1345" data-name="Path 1345" d="M424.833,260.658l-.026-.135-.084.247-1.646,7.613.7,17a25.856,25.856,0,0,0,3.41,7.948C427.923,280.834,424.833,260.658,424.833,260.658Z" transform="translate(-377.478 -189.05)" fill="#363e44"/>
      <path id="Path_1346" data-name="Path 1346" d="M424.356,252.491s3.334-33.364-1.6-39.339l-2.683-4.145a16.621,16.621,0,0,1,8.6,6.035l-2.324,2.663,3.073,1.224S425.046,251.655,424.356,252.491Z" transform="translate(-377.002 -180.884)" fill="#646f77"/>
      <path id="Path_1347" data-name="Path 1347" d="M420.248,252.43s.9,25.1-2.275,36.438c-1.341,1.1-13.756,4.415-22.736,0,0,0,.237-16.355,2.1-19.944s5.24-10.787,4.343-13.01-6.344-13.317-6.344-13.317l-1.182-25.7s2.674-3.055,14.829-8.277l-1.629,3.331-.807,3.156,5.149,11.086,4.959,9.084,2.973,12.009.342,1.311Z" transform="translate(-372.893 -180.823)" fill="#3e454c"/>
      <g id="Group_579" data-name="Group 579" transform="translate(21.259 35.356)">
        <path id="Path_1348" data-name="Path 1348" d="M400.913,255.615l1.113-26.408c.262-6.442-1.8-9.231-3.01-10.6a4.22,4.22,0,0,0-4.439-.69,5.04,5.04,0,0,0-.426.4l1.182,25.7S399.141,251.774,400.913,255.615Z" transform="translate(-394.152 -217.603)" fill="#353e44"/>
      </g>
      <g id="Group_583" data-name="Group 583" transform="translate(0 34.466)">
        <g id="Group_580" data-name="Group 580" transform="translate(0 73.733)">
          <path id="Path_1349" data-name="Path 1349" d="M368.888,332.925V310.754l28.8,16.628v22.171Z" transform="translate(-368.888 -305.213)" fill="#c17656"/>
          <path id="Path_1350" data-name="Path 1350" d="M368.888,309.71l9.6-5.541,28.8,16.626-9.6,5.543Z" transform="translate(-368.888 -304.169)" fill="#db8a6e"/>
          <path id="Path_1351" data-name="Path 1351" d="M406.811,320.555l-10.081,5.884v13.908L369.6,325.7V310.158l9.929-5.353Z" transform="translate(-369.001 -304.27)" fill="#b56454"/>
          <path id="Path_1352" data-name="Path 1352" d="M396.729,341.356V327.448L369.6,311.166v15.546Z" transform="translate(-369.001 -305.278)" fill="#a85345"/>
          <path id="Path_1353" data-name="Path 1353" d="M412.715,323.929V346.1l-9.6,5.543V329.472Z" transform="translate(-374.314 -307.302)" fill="#c4745f"/>
          <path id="Path_1354" data-name="Path 1354" d="M401.84,343.316l.4-.242-.11-13.666,9.795-5.543v-.342l-10.081,5.884Z" transform="translate(-374.112 -307.237)" fill="#a55042"/>
        </g>
        <path id="Path_1355" data-name="Path 1355" d="M386.524,315.182v-2.308a1.577,1.577,0,0,1,2.366-1.366l3.85,2.221a5.027,5.027,0,0,1,2.514,4.355v2.1" transform="translate(-371.684 -231.566)" fill="none" stroke="#893d33" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.713"/>
        <g id="Group_581" data-name="Group 581" transform="translate(13.7 5.478)">
          <path id="Path_1356" data-name="Path 1356" d="M391.611,226.078c-.589,2.429-1.124,4.871-1.688,7.306l-1.6,7.325c-.544,2.439-1.029,4.892-1.542,7.338l-1.522,7.343-.024.116a3.3,3.3,0,0,0-.066.71l.183,9.106c.067,3.035.105,6.07.2,9.106l.264,9.106c.119,3.035.209,6.071.353,9.106a2.851,2.851,0,0,0,2.983,2.712c1.471-.071,1.9-1.892,1.984-3.331.162-3.034.995-5.435,1.13-8.469l.313-9.1c.117-3.035.171-6.07.255-9.106l.233-9.1-.089.826,1.654-7.314c.545-2.439,1.12-4.872,1.633-7.317l1.576-7.331c.5-2.45,1.019-4.894,1.489-7.349a3.953,3.953,0,0,0-7.725-1.675Z" transform="translate(-385.169 -223.055)" fill="#ffcbae"/>
        </g>
        <path id="Path_1357" data-name="Path 1357" d="M386.389,305.559s-.279,6.055.493,7.715,3.133,2.341,4.756,2.414a2.207,2.207,0,0,0,2.163-1.3c-.53-1.538-2.648-2.45-2.481-5.056-.061-1.6.527-.721.5-.38a4.509,4.509,0,0,0,.788,2.249c.427.359.65.242.665-1.3a12.668,12.668,0,0,0-.119-3.086c-.22-1.144-1.351-1.231-1.563-2.392a6.3,6.3,0,0,1-.01-2.152l-5.365-.042A7.369,7.369,0,0,1,386.389,305.559Z" transform="translate(-371.634 -230.13)" fill="#ffcbae"/>
        <path id="Path_1358" data-name="Path 1358" d="M388.371,305.453a6.286,6.286,0,0,0,3.469-.406,1.634,1.634,0,0,1-.251-.613,6.3,6.3,0,0,1-.01-2.152l-5.365-.042a7.332,7.332,0,0,1,.273,2.34A4.741,4.741,0,0,0,388.371,305.453Z" transform="translate(-371.634 -230.13)" fill="#efb497"/>
        <g id="Group_582" data-name="Group 582" transform="translate(12.087)">
          <path id="Path_1359" data-name="Path 1359" d="M385.267,289.57c.408-.257,1.037.836,2.634,1.15,1.466.289,3.917-.166,4.122-1.048.7-3.152.71-34.057.71-34.057l6.773-26.423c1.591-6.248.152-9.4-.748-10.99-1.153-2.029-5.549-2.477-6.935.262-3.023,5.977-8.57,33.03-8.57,33.03S384.873,288.629,385.267,289.57Z" transform="translate(-383.252 -216.545)" fill="#373e44"/>
        </g>
        <path id="Path_1360" data-name="Path 1360" d="M399.932,218.206a3.892,3.892,0,0,0-3.171-1.643,2.921,2.921,0,0,1,1.06,1.059c.9,1.65,2.338,4.938.679,11.478l-7.062,27.661s-.194,26.7-.781,34.022c1.208-.1,2.4-.506,2.54-1.109.7-3.152.711-34.057.711-34.057L400.68,229.2C402.271,222.947,400.832,219.792,399.932,218.206Z" transform="translate(-372.339 -216.548)" fill="#2e353a"/>
      </g>
      <path id="Path_1361" data-name="Path 1361" d="M410.127,211.9c-4.727,4.944,14.265,18.391,12.357,41.051,0,0-1.982-16.43-11.334-23.891l1.094-3.836-3.346.033C408.839,224.1,406.874,213.543,410.127,211.9Z" transform="translate(-375.129 -181.342)" fill="#646f77"/>
      <path id="Path_1362" data-name="Path 1362" d="M410.3,211.9c-2.248,1.362-.665,7.7,5.905,11.047,0,0,.1-3.867,2.857-5.061C415.777,217.924,409.665,214.559,410.3,211.9Z" transform="translate(-375.3 -181.342)" fill="#cce3ff"/>
      <path id="Path_1363" data-name="Path 1363" d="M420.892,218.312s1.7-.371,2.844,1.914c.868-1.417.865-4.271-.594-5.624C423.507,216.02,423.534,217.4,420.892,218.312Z" transform="translate(-377.132 -181.771)" fill="#cce3ff"/>
      <path id="Path_1364" data-name="Path 1364" d="M445.332,289.7c-.518.823-2.286.106-2.1-2.881C442.467,289.01,443.979,292.051,445.332,289.7Z" transform="translate(-380.641 -193.219)" fill="#e5ac8f"/>
      <g id="Group_585" data-name="Group 585" transform="translate(26.822 1.323)">
        <path id="Path_1365" data-name="Path 1365" d="M410.926,204.143a37.094,37.094,0,0,1-1.019,9.185c2.007,3.187,10.615,5.181,11.914,1.521a10.531,10.531,0,0,1,1-5.585C424.181,206.941,410.926,204.143,410.926,204.143Z" transform="translate(-402.213 -181.435)" fill="#dda07f"/>
        <path id="Path_1366" data-name="Path 1366" d="M411.112,204.143s.031.855-.033,2.212a13.539,13.539,0,0,0,10.887,7.488,9.5,9.5,0,0,1,1.043-4.579C424.367,206.941,411.112,204.143,411.112,204.143Z" transform="translate(-402.398 -181.435)" fill="#cc8a6d"/>
        <path id="Path_1367" data-name="Path 1367" d="M423.7,182.736a22.251,22.251,0,0,1,2.664,9.7c-.2.673.953,1.963.673,4.767-.2,2.037-.614,6.173-.824,8.286a1.745,1.745,0,0,1-1.431,1.554c-4.089.721-10.631,1.139-15.412-5.353,0,0-2.3-2.692-3.365-3.028s-5.247-5.671-5.247-9.99c0-4.577,2.527-11.516,11.921-11.516A13.087,13.087,0,0,1,423.7,182.736Z" transform="translate(-400.763 -177.157)" fill="#eab18b"/>
        <g id="Group_584" data-name="Group 584" transform="translate(9.074 3.689)">
          <path id="Path_1368" data-name="Path 1368" d="M428.078,193.133a22.251,22.251,0,0,0-2.664-9.7,10.63,10.63,0,0,0-1.6-1.89c.23.3.344.492.344.492a22.246,22.246,0,0,1,2.664,9.7c-.2.673.953,1.963.673,4.767-.2,2.036-.614,6.173-.824,8.285a1.744,1.744,0,0,1-1.431,1.555c-3.617.638-9.152,1.029-13.691-3.381,4.733,5.872,10.99,5.477,14.95,4.779a1.745,1.745,0,0,0,1.431-1.554c.21-2.113.62-6.249.824-8.286C429.031,195.1,427.882,193.806,428.078,193.133Z" transform="translate(-411.547 -181.541)" fill="#e0a380"/>
        </g>
      </g>
      <g id="Group_586" data-name="Group 586" transform="translate(30.939 18.568)">
        <path id="Path_1369" data-name="Path 1369" d="M408.679,198.99c-1.767-1.84-4.007-.263-2.56,1.927.311.47,1,.746,1.242,1.181.136.244.268.45.4.64a16.094,16.094,0,0,1,1.213,1.144,1.056,1.056,0,0,0,1.191-.4Z" transform="translate(-405.655 -197.735)" fill="#d39779" opacity="0.4"/>
        <path id="Path_1370" data-name="Path 1370" d="M410.279,199c-1.113-2.3-3.731-1.487-3.029,1.043.151.544.719,1.016.817,1.506.491,2.452,1.388,2.676,2.239,2.175" transform="translate(-405.889 -197.651)" fill="#eab18b"/>
      </g>
      <path id="Path_1371" data-name="Path 1371" d="M411.685,191.341s-2.012,5.585-1.524,7.487c-.732.316-1.2-2.14-2.4-2.07s-.194,1.594-.194,1.594l1.7,3.842-.151,2.744c-1.334-1.526-10.02-7.836-9.2-16.8.183-2.743,2.743-4.642,3.566-3.876S411.685,191.341,411.685,191.341Z" transform="translate(-373.799 -176.934)" fill="#2e2b2a"/>
      <path id="Path_1372" data-name="Path 1372" d="M401.736,183.773c-.366-2.926,3.292-7.8,10.242-8.169s7.713,4.663,11.764,2.195c0,0,3.147,3.766,1.057,7.43,0,0-7.547,2.326-9.452,3.544s-3.2,1.95-4.14,1.218-7.4-4.268-7.4-4.268Z" transform="translate(-374.091 -175.585)" fill="#2e2b2a"/>
      <path id="Path_1373" data-name="Path 1373" d="M424.827,270.316c0,.69-.4,1.25-.894,1.25s-.894-.56-.894-1.25.4-1.25.894-1.25S424.827,269.626,424.827,270.316Z" transform="translate(-377.473 -190.405)" fill="#31393f"/>
    </g>
    <g id="Group_601" data-name="Group 601" transform="translate(451.14 212.852)">
      <path id="Path_1374" data-name="Path 1374" d="M537.267,236.942s4.134,5.471.724,14.193c0,0-1.213-2.5-3-3.066s-3.267-7.061-3.267-7.061l2.981-4.565Z" transform="translate(-504.181 -230.471)" fill="#a3745a"/>
      <g id="Group_588" data-name="Group 588" transform="translate(34.785 77.97)">
        <path id="Path_1375" data-name="Path 1375" d="M550.432,338.035s1.14,5.137.91,5.8l-1.781,8.646a.491.491,0,0,1-.8.229l-2.476-2.17.765-12.509h3.377Z" transform="translate(-541.275 -324.547)" fill="#eab18b"/>
        <path id="Path_1376" data-name="Path 1376" d="M545.773,322.006s1.653,8.6,3.713,13.488c-.533,1.263-2.665,8.969-3.1,7.668-2.771-8.365-6.061-16.081-6.061-16.081Z" transform="translate(-540.33 -322.006)" fill="#eab18b"/>
        <path id="Path_1377" data-name="Path 1377" d="M551.119,348.832c-.491-.008-1.106-2.976-1.106-4.154C550.013,344.679,549.791,350.07,551.119,348.832Z" transform="translate(-541.863 -325.6)" fill="#d1916d"/>
      </g>
      <g id="Group_589" data-name="Group 589" transform="translate(27.965 26.863)">
        <path id="Path_1378" data-name="Path 1378" d="M533.827,300.023a2.217,2.217,0,0,1-.081-.457c-.2-2.793-1.772-29.87-1.487-32.3.365-3.1,1.818-6.1,5.06-5.994,2.555.088,6.054,3.97,6.155,8.807s-1.064,27.983-1.1,29.265c0,0,5.851,21.969,5.826,23.941,0,0-1.6,2.578-6.508,2.578C534.968,323.348,533.827,300.023,533.827,300.023Z" transform="translate(-532.225 -261.27)" fill="#191441"/>
      </g>
      <path id="Path_1379" data-name="Path 1379" d="M542.742,299.346c.034-1.282,1.2-24.43,1.1-29.266s-3.6-8.719-6.155-8.807a4.015,4.015,0,0,0-3.148,1.25,4.187,4.187,0,0,1,1.891-.358c2.555.087,6.054,3.971,6.155,8.807s-1.064,27.983-1.1,29.266c0,0,5.851,21.969,5.827,23.941a3.664,3.664,0,0,1-.561.633,5.033,5.033,0,0,0,1.818-1.526C548.593,321.315,542.742,299.346,542.742,299.346Z" transform="translate(-504.627 -234.407)" fill="#191441"/>
      <g id="Group_592" data-name="Group 592" transform="translate(5.601 76.148)">
        <path id="Path_1380" data-name="Path 1380" d="M529.481,422.367c2.313-12.623-2.9-33.616-1.132-43.72,0,0-2.992-26.164-3.217-38.071a46.119,46.119,0,0,1,4.514-20.735s8.713.588,11.257,1.512c0,0,8.677,23.2-.961,59.67.6,2.078-4.122,31.812-3.226,42.274.57,3.923,3.817,12.589,3.852,14.072s-3.815,2.166-4.154,2.059-2.087-.3-2.087-.511-3.8-11.84-3.8-11.84-.919-3.388-.95-3.495S529.481,422.367,529.481,422.367Z" transform="translate(-508.736 -319.84)" fill="#eab18b"/>
        <path id="Path_1381" data-name="Path 1381" d="M542.623,321.5a21.207,21.207,0,0,0-3.68-.752c1.39,10,4.156,35.463-.454,54.3-.222,1.879-.173,4.377-.2,5.135s.071,3.363-2.317,3.923a3.155,3.155,0,0,0,2.535.8s-2.032,30.268-1.981,32.689-1.4,4.65,1.078,9.809,2.327,10.268,4.318,10.883a1.054,1.054,0,0,0,.365-.771c-.035-1.483-3.282-10.149-3.852-14.072-.9-10.462,3.826-40.2,3.226-42.274C551.3,344.7,542.623,321.5,542.623,321.5Z" transform="translate(-510.456 -319.983)" fill="#d69976"/>
        <g id="Group_590" data-name="Group 590" transform="translate(19.043 100.054)">
          <path id="Path_1382" data-name="Path 1382" d="M530.315,438.744s-2.036.3-2.036,4.349,2.171,3.261,2.715,5.134,2.235,9.992,5.254,10.944,5.677,2.033,7.791,1.663c.886-.186.3-1.616-.3-1.947-.517-.285-2.317-3.262-2.825-4.112a.12.12,0,0,0-.215.014,3.511,3.511,0,0,1-3.012,1.47c-1.842-.182-2.525-.888-7.477-16.091Z" transform="translate(-528.279 -438.744)" fill="#2f3033"/>
          <path id="Path_1383" data-name="Path 1383" d="M544.262,460.214c-2.114.37-4.771-.709-7.791-1.663s-4.711-9.073-5.254-10.945-2.715-1.087-2.715-5.133a9.491,9.491,0,0,1,.1-1.408,7.828,7.828,0,0,0-.321,2.395c0,4.047,2.171,3.261,2.715,5.134s2.235,9.992,5.254,10.944,5.677,2.033,7.791,1.663c.489-.1.523-.584.359-1.05A.55.55,0,0,1,544.262,460.214Z" transform="translate(-528.279 -439.112)" fill="#27282b"/>
          <path id="Path_1384" data-name="Path 1384" d="M529.946,456.355h0a.7.7,0,0,1-.7-.7v-10.7h1.027V456.03A.325.325,0,0,1,529.946,456.355Z" transform="translate(-528.432 -439.728)" fill="#2f3033"/>
        </g>
        <path id="Path_1385" data-name="Path 1385" d="M526.411,380.166c.831-25.3,4.96-27.216,1.073-52.072-.66-4.217-16.851-2.278-16.851-2.278-3.484,2.229-5.422,7.923-4.89,14.337.825,9.95,11.174,30.636,9.085,39.945-5.074,15.024.755,34.583-1.4,47.782.08.237-.155.642-.057.853.013,8.8-1.377,15.719.673,17.943,1.551,1.684,4.908,3.437,6.128.829.454-.972.081-4.394,0-5.989.029-3.043-.648-6.918-.727-11.982a3.211,3.211,0,0,0,.135-.748S523.648,402.113,526.411,380.166Z" transform="translate(-505.649 -320.717)" fill="#eab18b"/>
        <path id="Path_1386" data-name="Path 1386" d="M528.525,380.168c.831-25.3,4.96-27.216,1.073-52.072-.321-2.049-4.307-2.643-8.321-2.714,1.452,9.373,4.81,34.2,2.876,52.669.292,2.093.831,1.916.585,2.864s-.15,2.237-2.347,2.429.185,2.579,2.409,1.91c0,0-3.587,39.568-4.67,43.389s2.261,14.171,1.21,16.463-1.99,1.777-2.293,2.58a1.857,1.857,0,0,0,.011.921c1.286.44,2.572.31,3.231-1.1.454-.972.081-4.394,0-5.989.029-3.043-.648-6.918-.727-11.982a3.211,3.211,0,0,0,.135-.748S525.763,402.115,528.525,380.168Z" transform="translate(-507.764 -320.719)" fill="#d69976"/>
        <path id="Path_1387" data-name="Path 1387" d="M514.617,446.813s-.534,13.016.525,17a3.215,3.215,0,0,0,5.712,1.324c.718-1.023.317-4.239.426-6.013,0,0,.528,4.294.667,6.447a7.564,7.564,0,0,1-.218,2.353L520.59,472.4a1.676,1.676,0,0,1-1.624,1.262H518.2a1.676,1.676,0,0,1-1.49-.908l-2.2-4.281a9.875,9.875,0,0,1-1.082-5.186c.186-2.774,1.193-6.4-.316-10.279C512.259,451.391,513.492,447.045,514.617,446.813Z" transform="translate(-506.786 -339.969)" fill="#2f3033"/>
        <g id="Group_591" data-name="Group 591" transform="translate(6.077 107.449)">
          <path id="Path_1388" data-name="Path 1388" d="M522.726,466.678a3.085,3.085,0,0,0,.3-.366,2.215,2.215,0,0,0,.3-.789c-.172-1.915-.482-4.077-.482-4.077A50.336,50.336,0,0,1,522.726,466.678Z" transform="translate(-514.433 -449.738)" fill="#27282b"/>
          <path id="Path_1389" data-name="Path 1389" d="M519.577,472.634h-.768a1.677,1.677,0,0,1-1.49-.909l-2.2-4.281a9.875,9.875,0,0,1-1.082-5.186c.186-2.773,1.193-6.4-.316-10.279-.51-.977-.144-2.967.472-4.447-.925,1.345-1.738,4.323-1.075,5.593,1.51,3.882.5,7.505.316,10.279a9.875,9.875,0,0,0,1.082,5.186l2.2,4.281a1.676,1.676,0,0,0,1.49.908h.768a1.676,1.676,0,0,0,1.624-1.262l.077-.3A1.669,1.669,0,0,1,519.577,472.634Z" transform="translate(-512.87 -447.532)" fill="#27282b"/>
        </g>
        <path id="Path_1390" data-name="Path 1390" d="M538.07,320.872s-23.563,1.92-27.31,4.176c-2.518,1.516-5.112,4.753-5.112,12.306,0,8.368,10.181,33.777,9.01,40.887.175.673,1.008,1.182,2.242,1.516.337-2.6.825-7.245.377-9.681a32.819,32.819,0,0,1,1.84,10.049,26.155,26.155,0,0,0,12.46-2.169,16.914,16.914,0,0,0,8.167-8.559A98.878,98.878,0,0,0,538.07,320.872Z" transform="translate(-505.648 -320.004)" fill="#211b4e"/>
        <path id="Path_1391" data-name="Path 1391" d="M511.256,325.062c-.159.1-.319.2-.478.309-.712,1.786-1.518,3.288-2,4.392,2.924,6.415,20.06,8.563,20.06,8.563.994-1.249,1.32-3.8,1.31-6.943l2.83,6.35s5.471-4.543,5.9-8.751c.242-1.072-.4-4.159-1.138-8.024C533.562,321.317,514.587,323.056,511.256,325.062Z" transform="translate(-506.144 -320.017)" fill="#191441"/>
        <path id="Path_1392" data-name="Path 1392" d="M543.174,320.872l-.252.021a130.279,130.279,0,0,1-4.294,54.935,7.886,7.886,0,0,1-.783,1.508,16.868,16.868,0,0,0,7-7.939A98.877,98.877,0,0,0,543.174,320.872Z" transform="translate(-510.752 -320.004)" fill="#191441"/>
      </g>
      <path id="Path_1393" data-name="Path 1393" d="M528.759,270.227l.364-.035.938.035,2.2,2.859,1.337,14.853-.134,10.69-2.117.241-6.592-23.84-2.453-4.413.74-1.65,3.709,1.5Z" transform="translate(-502.686 -235.627)" fill="#fff"/>
      <path id="Path_1394" data-name="Path 1394" d="M529.15,270.192l-.364.035-2.012.237-3.709-1.5-.595,1.326a13.309,13.309,0,0,0,3.449,4.115c.2-2.566,3.227-4.214,3.23-4.216.769.606,1.282,2.4,1.834,3.536,0,0,.068-.974.058-2.26l-.953-1.24Z" transform="translate(-502.714 -235.628)" fill="#d6d6d6"/>
      <path id="Path_1395" data-name="Path 1395" d="M534.96,315.352a38.358,38.358,0,0,1,3.552,7.97s5.47-4.543,5.9-8.751c.642-2.853-4.944-19.987-2.129-28.1,2.2-.571,3.526-2.9,2.947-6.927-.519-3.6-1.345-5.019-1.817-7.124S543.19,262,535.6,261.489l-2.9,1.734,1.672,6.9.242,10.666-.242.567.443,11.052-1.192,7.456.73,15.491Z" transform="translate(-504.335 -234.442)" fill="#211b4e"/>
      <path id="Path_1396" data-name="Path 1396" d="M534.362,302.89l-.565,3.535.73,15.491h.607a33.945,33.945,0,0,1,2.75,5.771c.559-6.047-2.838-18.126-3.5-24.773Z" transform="translate(-504.509 -241.005)" fill="#191441"/>
      <path id="Path_1397" data-name="Path 1397" d="M529.529,274.078l-.48,2.942,4.579,20.341-1.507-21.5-1.428-2.32Z" transform="translate(-503.756 -236.353)" fill="#2a3a47"/>
      <path id="Path_1398" data-name="Path 1398" d="M527.417,271.438a2.324,2.324,0,0,0,2.247,2.3c1.417,0,2.139-.963,1.765-2.14l-1.023-1.554s-.368.458-2.293.351S527.417,271.438,527.417,271.438Z" transform="translate(-503.436 -235.798)" fill="#2a3a47"/>
      <path id="Path_1399" data-name="Path 1399" d="M533.994,296.586s.326-24.285-2.632-30.171v-2l1.941-1.445a9.681,9.681,0,0,1,3.439,2.423s-1.406,2.3-2.4,3.78l4.958,2.3S535.92,295.408,533.994,296.586Z" transform="translate(-504.123 -234.677)" fill="#191441"/>
      <path id="Path_1400" data-name="Path 1400" d="M528.718,268.649a9.076,9.076,0,0,1,3.643,3.564s.05-5.119-1.418-7.052A3.01,3.01,0,0,1,528.718,268.649Z" transform="translate(-503.704 -235.024)" fill="#e8e9ea"/>
      <g id="Group_594" data-name="Group 594" transform="translate(10.171 0.639)">
        <path id="Path_1401" data-name="Path 1401" d="M520.972,256.242a31.279,31.279,0,0,1-.72,9.2c1.943,3.087,8.8,4.687,10.059,1.144a10.2,10.2,0,0,1,.972-5.409C532.6,258.923,520.972,256.242,520.972,256.242Z" transform="translate(-512.533 -234.249)" fill="#eab18b"/>
        <path id="Path_1402" data-name="Path 1402" d="M521.108,256.242a29.911,29.911,0,0,1,.052,3.4c.633.648,1.132,1.231,1.132,1.231a12.834,12.834,0,0,0,8.127,5.289,9.716,9.716,0,0,1,1-4.993C532.733,258.923,521.108,256.242,521.108,256.242Z" transform="translate(-512.669 -234.249)" fill="#d39775"/>
        <path id="Path_1403" data-name="Path 1403" d="M533.3,235.51a21.547,21.547,0,0,1,2.58,9.4c-.19.652.923,1.9.651,4.617-.2,1.972-.595,5.978-.8,8.024a1.69,1.69,0,0,1-1.385,1.505c-3.96.7-10.3,1.1-14.926-5.184,0,0-2.227-2.608-3.259-2.933s-5.082-5.493-5.082-9.675c0-4.432,2.447-11.154,11.545-11.154A12.675,12.675,0,0,1,533.3,235.51Z" transform="translate(-511.079 -230.106)" fill="#eab18b"/>
        <g id="Group_593" data-name="Group 593" transform="translate(4.182 1.763)">
          <path id="Path_1404" data-name="Path 1404" d="M537.914,245.238a21.547,21.547,0,0,0-2.58-9.4,11.924,11.924,0,0,0-3.955-3.641,11.124,11.124,0,0,1,2.26,2.456s3.779,4.761,3.779,9.649c-.19.652.893,1.536.621,4.252-.2,1.972-1.293,6.266-1.5,8.311a1.689,1.689,0,0,1-1.385,1.505c-2.86.5-7.428.666-11.229-1.547,4.231,3.5,9.161,3.137,12.453,2.556a1.689,1.689,0,0,0,1.385-1.505c.2-2.046.6-6.052.8-8.024C538.837,247.139,537.724,245.89,537.914,245.238Z" transform="translate(-517.298 -232.201)" fill="#e0a380"/>
          <path id="Path_1405" data-name="Path 1405" d="M516.049,254.019a4.081,4.081,0,0,0,.7.607C516.515,254.4,516.276,254.2,516.049,254.019Z" transform="translate(-516.049 -235.66)" fill="#dd9e7a"/>
        </g>
      </g>
      <path id="Path_1406" data-name="Path 1406" d="M520.387,256.66a.668.668,0,1,1-.667-.668A.667.667,0,0,1,520.387,256.66Z" transform="translate(-502.172 -233.571)" fill="#fcf5ff"/>
      <path id="Path_1407" data-name="Path 1407" d="M528.86,269.51s-2.7.968-2.893,3.536c0,0-5.47-2.673-5.328-8.379C520.638,264.667,526.272,269.548,528.86,269.51Z" transform="translate(-502.423 -234.946)" fill="#e8e9ea"/>
      <path id="Path_1408" data-name="Path 1408" d="M525.781,285.724c1.022,2.308.294,4.838,4.473,11.13.046,7.91,3.191,23.766.166,27.566,0,0-17.136-2.148-20.06-8.564,1.545-3.533,6.409-11.158.877-19.128L507.9,275.919l-.13-5.325c.439-1.7,7.179-5.06,10.831-5.927l.412,3.192,2.811,5.937,2.1,8.4Z" transform="translate(-500.383 -234.946)" fill="#211b4e"/>
      <path id="Path_1409" data-name="Path 1409" d="M520.157,264.667s-2.253,2.821-2.558,8.219c1.519-.3,5.609,1.972,5.609,1.972l-2.2,7c2.158.931,8.348,15.179,10.8,15C531.812,296.854,522.963,268.38,520.157,264.667Z" transform="translate(-501.941 -234.946)" fill="#191441"/>
      <g id="Group_598" data-name="Group 598" transform="translate(0 35.064)">
        <g id="Group_596" data-name="Group 596" transform="translate(2.866 55.594)">
          <path id="Path_1410" data-name="Path 1410" d="M508.372,337.084s.308,10.125,1.455,15.3c-.752,1.146-5.025,3.05-5.215,1.693-1.219-8.728-2.213-16.921-2.213-16.921Z" transform="translate(-502.398 -337.084)" fill="#eab18b"/>
          <path id="Path_1411" data-name="Path 1411" d="M505.028,356.757s.71,7.46,1.05,7.651a13.7,13.7,0,0,0,3.985,1.579c3.133.836,3.978-.612,3.978-.612a29.748,29.748,0,0,1-.115-4.545c.171-1.717-2.678-1.831-3.537-5.315s-5.214-.029-5.214-.029Z" transform="translate(-502.815 -339.759)" fill="#eab18b"/>
          <g id="Group_595" data-name="Group 595" transform="translate(7.725 19.729)">
            <path id="Path_1412" data-name="Path 1412" d="M515.023,365.095a6.164,6.164,0,0,1-3.073-3.239" transform="translate(-511.637 -360.74)" fill="#eab18b" stroke="#c98865" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="0.583"/>
            <path id="Path_1413" data-name="Path 1413" d="M511.578,362.5a2.447,2.447,0,0,0,.793-1.967" transform="translate(-511.578 -360.53)" fill="#eab18b" stroke="#c98865" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="0.564"/>
          </g>
        </g>
        <g id="Group_597" data-name="Group 597">
          <path id="Path_1414" data-name="Path 1414" d="M499.01,308.614c-.015-.155-.028-1.146-.011-1.3.31-2.783,3.648-28.864,4.366-31.2.916-2.983,2.887-5.677,6.056-4.985,2.5.546,5.24,4.995,4.469,9.771s-6.085,27.334-6.351,28.59c0,0,1.425,28.953,2.355,31.877,0,0-2.731,3.566-6.218,2.245C501.858,337.339,499.01,308.614,499.01,308.614Z" transform="translate(-498.991 -271.017)" fill="#211b4e"/>
        </g>
        <path id="Path_1415" data-name="Path 1415" d="M510.6,271.129a4.7,4.7,0,0,0-.678-.094c2.259,1.349,4.322,5.589,3.547,10.056-.875,5.045-6.732,28.849-7.026,30.173,0,0,.8,25.379,1.623,32.328a7.65,7.65,0,0,0,3.008-2.225c-.93-2.924-2.354-31.877-2.354-31.877.265-1.255,5.58-23.814,6.35-28.59S513.1,271.675,510.6,271.129Z" transform="translate(-500.173 -271.019)" fill="#191441"/>
      </g>
      <path id="Path_1416" data-name="Path 1416" d="M533.608,312.41c0,.532-.31.963-.692.963s-.692-.431-.692-.963.31-.964.692-.964S533.608,311.879,533.608,312.41Z" transform="translate(-504.26 -242.362)" fill="#4f4f4f"/>
      <path id="Path_1417" data-name="Path 1417" d="M533.735,235.779s-3.456,8.384-13.741,13.793a2.528,2.528,0,0,0-2.418-2.643l.261,3.186.539,1.23,1.014,2.672a31.532,31.532,0,0,1-.156,4.11c-4.531-.862-12.519-11.2-9.1-20.58,3.366-9.223,14.248-9.13,18.036-7.065C531.723,232.417,533.833,235.378,533.735,235.779Z" transform="translate(-500.627 -229.346)" fill="#a3745a"/>
      <g id="Group_599" data-name="Group 599" transform="translate(8.681 4.816)">
        <path id="Path_1418" data-name="Path 1418" d="M519.05,254.032c-6.41,1.023-10.327-13.307-7.058-18.962a12.457,12.457,0,0,0-1.854,3.385c-3.424,9.382,4.564,19.717,9.1,20.58a31.54,31.54,0,0,0,.156-4.11Z" transform="translate(-509.308 -235.07)" fill="#8e5d44"/>
      </g>
      <path id="Path_1419" data-name="Path 1419" d="M521.85,250.4s-2.991-8.457,2.359-15.833A30.058,30.058,0,0,0,521.85,250.4Z" transform="translate(-502.483 -230.174)" fill="#8e5d44"/>
      <g id="Group_600" data-name="Group 600" transform="translate(14.442 17.148)">
        <path id="Path_1420" data-name="Path 1420" d="M519.113,251.035c-1.729-1.8-3.92-.258-2.5,1.884.3.46.975.73,1.214,1.155.133.238.262.439.388.625a15.879,15.879,0,0,1,1.186,1.118c.456.162.707-.027,1.028-.39Z" transform="translate(-516.155 -249.807)" fill="#d39779" opacity="0.4"/>
        <path id="Path_1421" data-name="Path 1421" d="M520.676,251.049c-1.089-2.246-3.649-1.454-2.963,1.02.148.532.7.995.8,1.473.48,2.4,1.357,2.618,2.19,2.127" transform="translate(-516.384 -249.725)" fill="#eab18b"/>
      </g>
    </g>
    <g id="Group_616" data-name="Group 616" transform="translate(247.452 211.898)">
      <g id="Group_615" data-name="Group 615">
        <g id="Group_602" data-name="Group 602" transform="translate(32.095 76.272)">
          <path id="Path_1422" data-name="Path 1422" d="M305.708,334.586s1.118,5.04.894,5.7l-1.748,8.485a.483.483,0,0,1-.781.225l-2.43-2.13.751-12.276h3.315Z" transform="translate(-296.721 -321.348)" fill="#eab18b"/>
          <path id="Path_1423" data-name="Path 1423" d="M301.136,318.854s1.622,8.439,3.645,13.238c-.524,1.239-2.616,8.8-3.039,7.525-2.72-8.209-5.948-15.783-5.948-15.783Z" transform="translate(-295.794 -318.854)" fill="#eab18b"/>
          <path id="Path_1424" data-name="Path 1424" d="M306.383,345.182c-.481-.008-1.086-2.921-1.086-4.076C305.3,341.106,305.079,346.4,306.383,345.182Z" transform="translate(-297.298 -322.382)" fill="#d1916d"/>
        </g>
        <g id="Group_603" data-name="Group 603" transform="translate(25.402 26.115)">
          <path id="Path_1425" data-name="Path 1425" d="M289.412,297.28a2.189,2.189,0,0,1-.079-.449c-.193-2.741-1.739-29.315-1.459-31.7.358-3.041,1.784-5.99,4.966-5.882,2.507.086,5.942,3.9,6.041,8.643s-1.044,27.464-1.079,28.723c0,0,5.339,19.985,5.423,22.3-.98,2.694-6.02,3.728-6.02,3.728Z" transform="translate(-287.84 -259.248)" fill="#b2d3ea"/>
        </g>
        <path id="Path_1426" data-name="Path 1426" d="M298.958,296.667c.034-1.258,1.179-23.975,1.079-28.722-.085-4.057-2.606-7.423-4.9-8.37a11.3,11.3,0,0,1,3.325,7.691c.151,4.988-.755,28.846-.776,30.168,0,0,5.049,19.111,5.618,23.014a3.723,3.723,0,0,0,1.08-1.482C304.3,316.653,298.958,296.667,298.958,296.667Z" transform="translate(-263.595 -233.185)" fill="#9ac2d8"/>
        <path id="Path_1427" data-name="Path 1427" d="M274.243,262.461a65.834,65.834,0,0,0-8.124,3.352,9.507,9.507,0,0,0-5.152,8.664c.109,6.717,2.83,13.964,7,20.474,2.509,3.916,1.744,9.763.145,17.088,3.014,3.8,8.607,5.45,13.416,5.023,8.711-.772,11.483-5.942,10.47-9.8,0,0-.867-7.954-.841-13.044a46.816,46.816,0,0,1,1.235-11.083c2.9-.562,6.065-4.566,1.85-12.152-.141-2.353-.211-10.537-6.72-11.731-5.315,1.349-10.812,2.6-12.371,2.955A8.718,8.718,0,0,0,274.243,262.461Z" transform="translate(-258.178 -233.133)" fill="#211b4e"/>
        <g id="Group_604" data-name="Group 604" transform="translate(21.695 26.116)">
          <path id="Path_1428" data-name="Path 1428" d="M283.435,291.039s6.369,1.326,10.276.021c0,0-2.18,13.6.608,26.316a6.51,6.51,0,0,0,1.242-5.617s-.867-7.954-.841-13.044a46.762,46.762,0,0,1,1.236-11.083S291.692,291.374,283.435,291.039Z" transform="translate(-283.435 -263.749)" fill="#211b4e"/>
          <path id="Path_1429" data-name="Path 1429" d="M298.894,270.98c-.141-2.353-.211-10.537-6.72-11.731-.624.159-1.251.316-1.874.47h0s7.652,5.612,8.273,11.535c0,0,3.2,8.805-1.529,11.878C299.947,282.57,303.108,278.567,298.894,270.98Z" transform="translate(-284.523 -259.249)" fill="#211b4e"/>
        </g>
        <path id="Path_1430" data-name="Path 1430" d="M293,239.566s3.112,22.381,7.035,28.245c0,0-9.124-6.115-10.8-9.83s-1.4-17.154-1.4-17.154Z" transform="translate(-262.435 -230.013)" fill="#a56c5e"/>
        <path id="Path_1431" data-name="Path 1431" d="M276.351,262.941s-3.187,3.357,4.793,9.516c0,0,.957-4.607,2.98-5.191a34.945,34.945,0,0,0,3.932,2.094s.892-6.7-2.433-7.507a14.666,14.666,0,0,0-7.14.525Z" transform="translate(-260.517 -233.505)" fill="#9fc7dd"/>
        <g id="Group_606" data-name="Group 606" transform="translate(7.996 0.194)">
          <path id="Path_1432" data-name="Path 1432" d="M276.915,254.226a30.86,30.86,0,0,1-.711,9.073c1.917,3.044,8.683,4.623,9.923,1.128a10.063,10.063,0,0,1,.958-5.336C288.383,256.871,276.915,254.226,276.915,254.226Z" transform="translate(-268.59 -232.53)" fill="#eab18b"/>
          <path id="Path_1433" data-name="Path 1433" d="M277.049,254.226a29.483,29.483,0,0,1,.051,3.358c.624.639,1.117,1.214,1.117,1.214a12.659,12.659,0,0,0,8.017,5.217,9.589,9.589,0,0,1,.986-4.925C288.517,256.871,277.049,254.226,277.049,254.226Z" transform="translate(-268.724 -232.53)" fill="#d39775"/>
          <path id="Path_1434" data-name="Path 1434" d="M289.073,233.774a21.257,21.257,0,0,1,2.545,9.27c-.188.643.91,1.875.643,4.554-.194,1.945-.587,5.9-.788,7.916A1.666,1.666,0,0,1,290.108,257c-3.907.689-10.157,1.088-14.725-5.114,0,0-2.2-2.572-3.215-2.893s-5.013-5.419-5.013-9.545c0-4.372,2.414-11,11.389-11A12.5,12.5,0,0,1,289.073,233.774Z" transform="translate(-267.155 -228.443)" fill="#eab18b"/>
          <g id="Group_605" data-name="Group 605" transform="translate(4.126 1.739)">
            <path id="Path_1435" data-name="Path 1435" d="M293.628,243.371a21.257,21.257,0,0,0-2.545-9.27,11.76,11.76,0,0,0-3.9-3.591,10.981,10.981,0,0,1,2.23,2.423s3.728,4.7,3.728,9.52c-.188.643.88,1.515.613,4.194-.194,1.945-1.276,6.181-1.476,8.2a1.667,1.667,0,0,1-1.367,1.485c-2.821.5-7.327.657-11.076-1.526,4.173,3.453,9.037,3.094,12.285,2.521a1.666,1.666,0,0,0,1.366-1.485c.2-2.018.593-5.97.788-7.916C294.539,245.246,293.44,244.014,293.628,243.371Z" transform="translate(-273.291 -230.51)" fill="#e0a380"/>
            <path id="Path_1436" data-name="Path 1436" d="M272.058,252.033a4.078,4.078,0,0,0,.694.6C272.518,252.413,272.283,252.208,272.058,252.033Z" transform="translate(-272.058 -233.922)" fill="#dd9e7a"/>
          </g>
        </g>
        <path id="Path_1437" data-name="Path 1437" d="M276.222,254.723a.656.656,0,1,1-.656-.656A.656.656,0,0,1,276.222,254.723Z" transform="translate(-260.389 -232.312)" fill="#fcf5ff"/>
        <g id="Group_609" data-name="Group 609" transform="translate(4.152 76.356)">
          <path id="Path_1438" data-name="Path 1438" d="M284.2,421.481c2.314-12.622-2.9-33.615-1.132-43.719,0,0-2.991-26.165-3.217-38.071a46.121,46.121,0,0,1,4.514-20.736s8.714.588,11.257,1.512c0,0,8.678,23.2-.96,59.671.6,2.078-4.122,31.812-3.226,42.273.571,3.923,3.816,12.589,3.852,14.072s-3.816,2.166-4.155,2.059-2.087-.3-2.087-.511-3.8-11.839-3.8-11.839-.919-3.389-.95-3.5S284.2,421.481,284.2,421.481Z" transform="translate(-265.323 -318.954)" fill="#eab18b"/>
          <path id="Path_1439" data-name="Path 1439" d="M297.344,320.609a21.191,21.191,0,0,0-3.679-.751c1.389,10,4.155,35.462-.454,54.3-.223,1.879-.173,4.376-.2,5.134s.072,3.363-2.317,3.923a3.158,3.158,0,0,0,2.535.8s-2.033,30.268-1.982,32.689-1.4,4.649,1.078,9.808,2.328,10.268,4.318,10.883a1.052,1.052,0,0,0,.366-.771c-.036-1.483-3.282-10.149-3.852-14.072-.9-10.461,3.826-40.2,3.226-42.273C306.022,343.812,297.344,320.609,297.344,320.609Z" transform="translate(-267.043 -319.097)" fill="#d69976"/>
          <g id="Group_607" data-name="Group 607" transform="translate(17.178 100.055)">
            <path id="Path_1440" data-name="Path 1440" d="M284.668,455.469h0a.7.7,0,0,1-.7-.7v-10.7h1.027v11.077A.325.325,0,0,1,284.668,455.469Z" transform="translate(-283.154 -438.843)" fill="#276c93"/>
            <path id="Path_1441" data-name="Path 1441" d="M285.037,437.859s-2.036.3-2.036,4.348,2.17,3.262,2.714,5.134,2.235,9.992,5.255,10.945,5.677,2.032,7.79,1.663c.886-.187.3-1.616-.3-1.948-.517-.284-2.317-3.261-2.825-4.112a.119.119,0,0,0-.214.015,3.511,3.511,0,0,1-3.012,1.469c-1.842-.181-2.524-.888-7.477-16.091Z" transform="translate(-283.001 -437.859)" fill="#337aaa"/>
            <path id="Path_1442" data-name="Path 1442" d="M298.983,459.329c-2.114.37-4.771-.709-7.79-1.662s-4.711-9.073-5.255-10.945-2.714-1.087-2.714-5.134a9.485,9.485,0,0,1,.1-1.407,7.853,7.853,0,0,0-.321,2.394c0,4.047,2.17,3.262,2.714,5.134s2.235,9.992,5.255,10.945,5.677,2.032,7.79,1.663c.49-.1.523-.584.36-1.051A.541.541,0,0,1,298.983,459.329Z" transform="translate(-283.001 -438.227)" fill="#276c93"/>
          </g>
          <path id="Path_1443" data-name="Path 1443" d="M281.482,379.113c.831-25.3,4.96-27.216,1.073-52.072-.66-4.218-14.854-3.578-14.854-3.578-3.484,2.23-5.564,9.26-5.031,15.675.826,9.95,10.177,30.44,8.088,39.749-5.074,15.024-.1,34.741-2.256,47.94.081.236-.155.641-.056.852.013,8.8-1.377,15.719.672,17.943,1.551,1.684,4.909,3.437,6.129.83.454-.972.081-4.4,0-5.989.029-3.044-.647-6.918-.727-11.983a3.239,3.239,0,0,0,.136-.748S278.72,401.06,281.482,379.113Z" transform="translate(-262.587 -319.663)" fill="#eab18b"/>
          <path id="Path_1444" data-name="Path 1444" d="M283.246,379.282c.831-25.3,4.96-27.216,1.073-52.072-.321-2.049-4.307-2.643-8.32-2.715,1.452,9.374,4.81,34.2,2.875,52.669.292,2.094.831,1.916.586,2.864s-.15,2.238-2.348,2.429.186,2.579,2.41,1.911c0,0-3.588,39.567-4.67,43.389s2.26,14.17,1.21,16.463-1.99,1.776-2.293,2.579a1.848,1.848,0,0,0,.01.921c1.287.439,2.572.31,3.232-1.1.454-.972.081-4.4,0-5.989.029-3.044-.647-6.918-.727-11.983a3.24,3.24,0,0,0,.136-.748S280.484,401.229,283.246,379.282Z" transform="translate(-264.351 -319.833)" fill="#d69976"/>
          <path id="Path_1445" data-name="Path 1445" d="M269.338,445.927s-.533,13.017.525,17a3.214,3.214,0,0,0,5.712,1.324c.718-1.022.317-4.239.427-6.013,0,0,.528,4.294.666,6.447a7.532,7.532,0,0,1-.218,2.352l-1.138,4.478a1.677,1.677,0,0,1-1.625,1.263h-.768a1.676,1.676,0,0,1-1.489-.909l-2.205-4.281a9.882,9.882,0,0,1-1.081-5.186c.186-2.773,1.193-6.4-.316-10.279C266.981,450.505,268.213,446.16,269.338,445.927Z" transform="translate(-263.373 -339.083)" fill="#337aaa"/>
          <g id="Group_608" data-name="Group 608" transform="translate(4.212 107.449)">
            <path id="Path_1446" data-name="Path 1446" d="M274.3,471.749h-.768a1.676,1.676,0,0,1-1.489-.909l-2.205-4.281a9.874,9.874,0,0,1-1.081-5.186c.186-2.774,1.193-6.4-.316-10.279-.51-.978-.145-2.968.471-4.448-.924,1.345-1.738,4.324-1.075,5.594,1.51,3.882.5,7.505.316,10.279a9.882,9.882,0,0,0,1.081,5.186l2.206,4.281a1.676,1.676,0,0,0,1.489.909h.768a1.677,1.677,0,0,0,1.625-1.263l.076-.3A1.671,1.671,0,0,1,274.3,471.749Z" transform="translate(-267.592 -446.646)" fill="#276c93"/>
          </g>
        </g>
        <path id="Path_1447" data-name="Path 1447" d="M293.211,318.235a4.783,4.783,0,0,0-.141-1.169s-.977-4.8-1.573-9.34c-2.221,7.863-19.516,7.606-22.16,5.793a22.785,22.785,0,0,1-1.031,4.345,16.313,16.313,0,0,1-3.779,5.747c-1.759,1.868-3.19,5.045-3.19,10.6,0,8.213,9.992,33.15,8.843,40.128.171.661.989,1.16,2.2,1.487.331-2.547.809-7.11.37-9.5a32.183,32.183,0,0,1,1.806,9.861,25.659,25.659,0,0,0,12.229-2.128,16.6,16.6,0,0,0,8.015-8.4A97.076,97.076,0,0,0,293.211,318.235Z" transform="translate(-258.237 -240.818)" fill="#211b4e"/>
        <g id="Group_610" data-name="Group 610" transform="translate(11.943 66.908)">
          <path id="Path_1448" data-name="Path 1448" d="M271.91,386.188c-.015.207-.034.41-.064.593a1.244,1.244,0,0,0,.3.517C272.067,386.912,271.989,386.54,271.91,386.188Z" transform="translate(-271.846 -320.165)" fill="#191344"/>
          <path id="Path_1449" data-name="Path 1449" d="M298.34,318.235a4.784,4.784,0,0,0-.141-1.169s-.977-4.8-1.573-9.34a6.95,6.95,0,0,1-2.936,3.808c.516,3.389,1.043,6.156,1.043,6.156a5.435,5.435,0,0,1,.142,1.247,110.281,110.281,0,0,1,1.588,50.6,9.633,9.633,0,0,1-1.059,2.247,15.731,15.731,0,0,0,4.525-6.12A97.076,97.076,0,0,0,298.34,318.235Z" transform="translate(-275.309 -307.726)" fill="#191344"/>
        </g>
        <g id="Group_611" data-name="Group 611" transform="translate(10.885 66.575)">
          <path id="Path_1450" data-name="Path 1450" d="M277.38,315.223c-3.556,0-6.046-.324-6.792-.8l.262-1.784c1.457,1,10.451,1.69,16.872-.631,3.007-1.088,4.669-2.731,5.22-4.674l.44,2.529c-.227.7-1.544,2.442-5.089,3.725A33.957,33.957,0,0,1,277.38,315.223Z" transform="translate(-270.588 -307.33)" fill="#337aaa"/>
        </g>
        <rect id="Rectangle_251" data-name="Rectangle 251" width="20.251" height="24.367" transform="translate(11.953 53.029)" fill="#ffe4b3"/>
        <rect id="Rectangle_252" data-name="Rectangle 252" width="20.251" height="24.367" transform="translate(10.884 53.897)" fill="#f4b638"/>
        <path id="Path_1451" data-name="Path 1451" d="M290.839,303.359a11.007,11.007,0,0,1-1.265-.043c-1.127-.123-1.221,1.749-3.516,2.29a2.016,2.016,0,0,0-.365.123,1.8,1.8,0,0,0-1.231-.977c-6.723.977-11.126,1.766-13.874,2.318V315.3c6.82-1.984,14.6-4.277,15.664-4.709a2.331,2.331,0,0,0,.374-1.482l.214.027s3.742.21,3.987.136l.012-.006Z" transform="translate(-259.703 -240.118)" fill="#f48b38"/>
        <g id="Group_612" data-name="Group 612" transform="translate(15.918 61.949)">
          <path id="Path_1452" data-name="Path 1452" d="M276.808,304.633a74.337,74.337,0,0,0,10.1-.236c.716.547,1.764,3.437.865,3.5-5.779.39-11.206.8-11.206.8Z" transform="translate(-276.57 -302.239)" fill="#eab18b"/>
          <path id="Path_1453" data-name="Path 1453" d="M289.573,307.489s3.723.441,3.972.382,3.2-2.285,3.179-3.709a2.858,2.858,0,0,0-1.078-2.178,19.594,19.594,0,0,1-2.986-.138c-1.117-.192-1.326,1.67-3.65,2.07s-.263,3.417-.263,3.417Z" transform="translate(-278.367 -301.833)" fill="#eab18b"/>
        </g>
        <g id="Group_613" data-name="Group 613" transform="translate(0 33.792)">
          <path id="Path_1454" data-name="Path 1454" d="M263.122,308.47a4.485,4.485,0,0,1-5.465-4.578c.442-10.079,1.4-29.8,2.207-31.385,1.385-2.731,3.741-5.008,6.689-3.809,2.324.945,4.233,5.707,2.69,10.2s-4.079,19.707-4.546,20.877c0,0,4.129-.963,17.975-2.111,1.663.24,2.5,4.848,1.428,5.939C282.735,304.061,270.547,306.81,263.122,308.47Z" transform="translate(-257.653 -268.371)" fill="#b2d3ea"/>
        </g>
        <g id="Group_614" data-name="Group 614" transform="translate(1.26 34.162)">
          <path id="Path_1455" data-name="Path 1455" d="M282.911,303.179l-.089.008c.885,1.448,1.154,4.245.353,5.064-1.366.46-13.554,3.21-20.979,4.87a4.46,4.46,0,0,1-3.045-.392,4.469,4.469,0,0,0,4.209,1.26c7.425-1.66,19.613-4.409,20.98-4.87C285.406,308.027,284.573,303.419,282.911,303.179Z" transform="translate(-259.15 -274.259)" fill="#9ac2d8"/>
          <path id="Path_1456" data-name="Path 1456" d="M269.178,278.1c-1.543,4.489-4.079,19.706-4.545,20.876,0,0-.4,1.756,1.164.868.831-3.851,3.205-16.977,4.546-20.877,1.525-4.436-.321-9.131-2.608-10.154C269.409,270.626,270.432,274.451,269.178,278.1Z" transform="translate(-260.012 -268.811)" fill="#9ac2d8"/>
        </g>
        <path id="Path_1457" data-name="Path 1457" d="M279.264,239.866c1.25,5.7,10.411-.125,11.987,1.249.422-.974-1.1-9.534-7.853-12.029-5.67-2.1-14.615-.659-17.359,6.93-2.5,6.906-.4,17.569,4.83,27.929.421.937,4.092,5.777,12.81,9.417C278.417,263.912,278.145,247.577,279.264,239.866Z" transform="translate(-258.816 -228.213)" fill="#a56c5e"/>
        <path id="Path_1458" data-name="Path 1458" d="M283.677,274.538c-4.371-7.853-5.3-20.459-4.843-28.92-2.612,10.778.91,25.395.91,25.395s-6.406-5.814-8.113-8.662c-4.3-7.917-4.875-20.637-4.883-26.723a11.648,11.648,0,0,0-.711,1.564c-2.5,6.906-.4,17.569,4.83,27.929C271.288,266.058,274.96,270.9,283.677,274.538Z" transform="translate(-258.816 -229.388)" fill="#93584d"/>
      </g>
      <path id="Path_1459" data-name="Path 1459" d="M274.319,267.78s-3.855-11.082-3.027-20c0,0,.764,12.109,5.986,22.486Z" transform="translate(-259.796 -231.315)" fill="#93584d"/>
    </g>
  </g>
</svg>
  
     );
};

CoworkerSvg.propTypes = {};

export default CoworkerSvg;
