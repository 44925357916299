import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _filter from 'lodash/filter'
import { Select, MenuItem } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { getSpaces } from '../../Actions/spaceActions'
import { setSpaceId } from '../../Utils/setAuthToken'
import { spacecapitalize } from '../../Utils/StringFunctions'

const styles = (theme) => ({
  selectSpace: {
    width: '17%',
    color: 'white',
    marginLeft: '-6px',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  icon: {
    color: 'white',
  },
})

const SpaceMenu = (props) => {
  const { classes } = props
  const spaceData = localStorage.getItem('space_ids')
  const currentSpaceID = localStorage.getItem('space_id')
  const allSpace = JSON.parse(spaceData)

  const [space, setSpace] = useState(allSpace[0]._id)
  const [data] = useState(allSpace)
  const handleChange = (event) => {
    const { value } = event.target
    // console.log('value', value)
    setSpace(value)
    localStorage.setItem('space_id', value)
    setSpaceId(value)
    window.location.reload()
    window.location.href = '/dashboard'
    const activeSpace = _filter(data, (item) => {
      return item._id === value
    })
    localStorage.setItem('space_name', activeSpace[0].space_title)
  }

  return (
    <Select
      edge={props.edge}
      value={currentSpaceID === allSpace[0]._id ? space : currentSpaceID}
      onChange={handleChange}
      className={classes.selectSpace}
      disableUnderline
      classes={{
        icon: classes.icon,
      }}
    >
      {data &&
        // eslint-disable-next-line no-shadow
        data.map((item, key) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <MenuItem value={item._id} key={key}>
              {spacecapitalize(item.space_title)}
            </MenuItem>
          )
        })}
    </Select>
  )
}

SpaceMenu.propTypes = {}

const mapStateToProps = (state) => ({ spaces: state.spaces })

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getSpaces }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(withRouter(SpaceMenu)))
