/* eslint-disable camelcase */
import React, { useState } from "react";
import {
  Card,
  CardContent,
  withStyles,
  Paper,
  Grid,
  Button,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import classNames from "classnames";
import Settings from "../Settings";
import { capitalize } from "../../../Utils/StringFunctions";
import { checkPermission } from "../../../Utils/checkPermission";
import {
  getAllClubs,
  joinClub,
  deleteClub,
} from "../../../Actions/AllClubActions";
import NameTruncate from "../NameTruncate";

const styles = (theme) => {
  const borderRadius = theme.shape.borderRadius * 4;

  return {
    card: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      boxShadow: "0px 0px 20px rgba(0,0,0,0.05)",
      transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
      backgroundColor: "#FCFDFE",
      position: "relative",
      border: "1px solid #f3f3f3",
      "&:hover": {
        transform: "scale(1.01)",
        boxShadow:
          "rgb(178 183 187 / 6%) 0px 2px 4px 6px, rgb(212 219 226 / 60%) 0px 2px 10px 0px",
      },
      borderRadius: "6px",
      // borderTopRightRadius: "10px"
    },
    cardContent: {
      flexGrow: 1,
      "& p": {
        color: theme.palette.font.head1,
        textAlign: "left",
      },
      "& h2": {
        color: "#191919",
        fontSize: "15px",
      },
      "& h4": {
        color: theme.palette.font.head2,
        fontWeight: "normal",
      },
      // paddingBottom: "14px"
      "&:last-child": {
        paddingBottom: "20px",
      },
    },
    containerPaper: {
      border: `1px solid ${theme.palette.border.color}`,
    },
    image: {
      minHeight: "170px",
      height: "200px",
    },
    button: {
      fontSize: 10,
      borderRadius: `${borderRadius}px`,
      color: "#fff",
      backgroundColor: "#211b4e !important",
      "&:hover": {
        backgroundColor: "#151039 !important",
      },
      "&:disabled": {
        backgroundColor: "lightgrey !important",
      },
    },
    disabledButton: {
      fontSize: 10,
      borderRadius: `${borderRadius}px`,
      backgroundColor: "lightgrey !important",
    },
    ownerFonts: {
      color: "#626262",
      fontSize: "12px",
    },
    buttonDiv: {
      marginTop: "2px",
    },
    clubName: {
      fontWeight: "bold",
      fontSize: "14px",
      textTransform: "capitalize",
    },
    clubBanner: {
      borderTopRightRadius: "5px",
      borderTopLeftRadius: "5px",
      width: "100%",
      height: "200px",
      position: "absolute",
    },
    settingIcon: {
      display: "flex",
      justifyContent: "flex-end",
    },
  };
};

function ClubCard(props) {
  const { classes, data } = props;
  const id = data && data._id;
  const user_id = localStorage.getItem("user_id");
  const adminId = data && data.owner && data.owner._id;
  const [join_status, setJoin_status] = useState("yes");
  const handleJoinStatus = () => {
    if (join_status === "no") {
      setJoin_status("yes");
    } else if (join_status === "yes") setJoin_status("no");
    props.joinClub(data._id, join_status);
  };
  const onClubDelete = () => {
    props.deleteClub(id);
  };

  return (
    <Card className={classes.card}>
      <Paper className={classes.containerPaper}>
        <div className={classes.profileImage}>
          <div className={classes.image}>
            <Link to={`/club/${id}`}>
              <img
                src={data.display_banner || data.banner}
                alt=""
                club={data}
                className={classes.clubBanner}
              />
            </Link>
            {user_id === adminId && (
              <div className={classes.settingIcon}>
                <Settings
                  isEdit
                  isDelete
                  deleteMethod={onClubDelete}
                  message="Are you sure want to delete this club?"
                  editMethod={() => props.openEditModal(data)}
                />
              </div>
            )}
            {checkPermission("COWORKER_ADMIN_VIEW") && user_id !== adminId && (
              <div className={classes.settingIcon}>
                <Settings
                  isDelete
                  deleteMethod={onClubDelete}
                  message="Are you sure want to delete this club?"
                />
              </div>
            )}
          </div>
        </div>
        <CardContent className={classes.cardContent}>
          <Grid container>
            <Grid item xs={12} sm={9}>
              <div className={classes.clubName}>
                <NameTruncate text={capitalize(data.club_name)} amount={22} />
              </div>
              {data.owner && (
                <div className={classes.ownerFonts}>
                  {" "}
                  <span>Owner</span> : {capitalize(data.owner.first_name)} {""}
                  {capitalize(data.owner.last_name)}
                </div>
              )}
            </Grid>
            <Grid item xs={12} sm={3}>
              <div className={classes.buttonDiv}>
                <Button
                  size="small"
                  className={classNames(
                    data.join_status === "yes"
                      ? classes.disabledButton
                      : classes.button
                  )}
                  onClick={handleJoinStatus}
                  disabled={
                    data.created_by === user_id || data.join_status === "yes"
                  }
                >
                  {data.join_status === "yes" ? `Joined` : `Join`}
                </Button>
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Paper>
    </Card>
  );
}
const mapStateToProps = (state) => {
  return {
    errors: state.errors,
  };
};
export default connect(mapStateToProps, { getAllClubs, joinClub, deleteClub })(
  withStyles(styles)(ClubCard)
);
