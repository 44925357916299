export const StrengthOptions = [
  { value: '0-10', label: '0-10' },
  { value: '11-55', label: '11-55' },
  { value: '51-200', label: '51-200' },
  { value: '201-500', label: '200-500' },
  { value: '500+', label: '500+' },
]
export const ClienttypeOptions = [
  { value: 'enterprise', label: 'Enterprise' },
  { value: 'dedicated_cabin', label: 'Dedicated Cabin' },
  { value: 'dedicated_desk', label: 'Dedicated Desk' },
  { value: 'flexi_desk', label: 'Flexi Desk' },
]
export const IndustryOptions = [
  {
    value: 'agriculture_and_allied_industries',
    label: 'Agriculture and allied industries ',
  },
  { value: 'automobiles', label: 'Automobiles' },
  { value: 'auto_components', label: 'Auto components' },
  { value: 'aviation', label: 'Aviation' },
  { value: 'banking', label: 'Banking' },
  { value: 'cement', label: 'Cement' },
  { value: 'consumer_durables', label: 'Consumer Durables' },
  { value: 'financial_services', label: 'Financial services' },
  { value: 'fmcg', label: 'FMCG' },
  { value: 'gems_and_jewellery', label: 'Gems and Jewellery' },
  { value: 'healthcare', label: 'Healthcare' },
  { value: 'infastructure', label: 'Infastructure' },
  { value: 'it_ites', label: 'IT & ITES' },
  { value: 'manufacturing', label: 'Manufacturing' },
  { value: 'media_and_entertainment', label: 'Media and Entertainment' },
  { value: 'metals_and_mining', label: 'Metals and Mining' },
  { value: 'oil_and_gas', label: 'Oil and Gas' },
  { value: 'pharmaceuticals', label: 'Pharmaceuticals' },
  { value: 'ports', label: 'Ports' },
  { value: 'power', label: 'Power' },
  { value: 'railways', label: 'Railways' },
  { value: 'real_estate', label: 'Real estate' },
  { value: 'renewable_energy', label: 'Renewable energy' },
  { value: 'retail', label: 'Retail' },
  { value: 'science_and_technology', label: 'Science and Technology' },
  { value: 'services', label: 'Services' },
  { value: 'steel', label: 'Steel' },
  { value: 'telecommunication', label: 'Telecommunications' },
  { value: 'textiles', label: 'Textiles' },
  { value: 'tourism_and_hospitality', label: 'Tourism and Hospitality' },
  { value: 'other', label: 'Other' },
]

export const BloodGroupOptions = [
  { value: 'a+', label: 'A+' },
  { value: 'a-', label: 'A-' },
  { value: 'b+', label: 'B+' },
  { value: 'b-', label: 'B-' },
  { value: 'ab+', label: 'AB+' },
  { value: 'ab-', label: 'AB-' },
  { value: 'o+', label: 'O+' },
  { value: 'o-', label: 'O-' },
]

export const SkillsOptions = [
  { value: 'enterprise', label: 'Enterprise' },
  { value: 'dedicated_cabin', label: 'Dedicated Cabin' },
  { value: 'dedicated_desk', label: 'Dedicated Desk' },
  { value: 'flexi_desk', label: 'Flexi Desk' },
]
export const EventCategory = [
  { value: 'meeting', label: 'Meeting' },
  { value: 'interview', label: 'Interview' },
  { value: 'seminar', label: 'Seminar' },
  { value: 'traning', label: 'Traning' },
  { value: 'workshop', label: 'Workshop' },
  { value: 'event', label: 'Event' },
  { value: 'vendor_meeting', label: 'Vendor Meeting' },
  { value: 'startup_meet', label: 'Startup Meet' },
  { value: 'community_engagement', label: 'Community Engagement' },
  { value: 'festival', label: 'Festival' },
]
export const AttendesCategory = [
  { value: 'company', label: 'My Company' },
  { value: 'space', label: 'DevX Community' },
  { value: 'open_for_all', label: 'Open For All' },
  { value: 'external_closed_community', label: 'External Closed Events' },
]
export const serviceOptions = [
  { value: 'websitedevelopement', label: 'Website Development' },
  { value: 'seo', label: 'SEO' },
  { value: 'graphic designing', label: 'Graphic Designing' },
]
export const partnerCategories = [
  { value: 'hr', label: 'HR' },
  { value: 'cloud computing', label: 'Cloud Computing' },
  { value: 'legal', label: 'Legal' },
  { value: 'accommodation', label: 'Accommodation' },
  { value: 'hotel', label: 'Hotel' },
  { value: 'accounting', label: 'Accounting' },
  { value: 'digital marketing', label: 'Digital Marketing' },
  { value: 'it solutions', label: 'IT Solutions' },
  { value: 'payment gateway', label: 'Payment Gateway' },
  { value: 'cloud telephony', label: 'Cloud Telephony' },
  { value: 'crm', label: 'CRM' },
  { value: 'travel', label: 'Travel' },
  { value: 'grocery', label: 'Grocery' },
  { value: 'cab service', label: 'Cab Service' },
  { value: 'other', label: 'Other' },
]
export const SetupOptions = [
  { value: 'projector_tv', label: 'Projector / TV' },
  { value: 'sound_system', label: 'Sound System' },
  { value: 'mic', label: 'Mic' },
  { value: 'tea_coffee', label: 'Tea / Coffee' },
]
export const spaceOptions = [
  { value: 'space', label: 'Current Space' },
  { value: 'global', label: 'Across Space' },
]

export const eventTypeOptions = [
  { label: 'Individual', value: 'individual' },
  { label: 'Team', value: 'team' },
]

export const sourceWalkthrougOptions = [
  { label: 'Google', value: 'google' },
  { label: 'Social Media', value: 'facebook' },
  { label: 'Direct Walk-in', value: 'instagram' },
  { label: 'Reference', value: 'reference' },
  { label: 'Broker Partner', value: 'sales_team' },
  { label: 'Others', value: 'others' },
]

export const interestedProductsOptions = [
  // { value: "flexi_desk", label: "Flexi Desk" },
  // { value: "dedicated_desk", label: "Dedicated Desk" },
  // { value: "daily_pass", label: "Daily Pass" },
  // { value: "Manager’s Cabin", label:  "Manager’s Cabin" },
  // { value: "private_cabin", label:  "Private Cabin" },
  // { value: "managed_office", label: "Managed Office" },
  { value: 'Flexi Desk', label: 'Flexi Desk' },
  { value: 'Dedicated Desk', label: 'Dedicated Desk' },
  { value: 'Daily Pass', label: 'Daily Pass' },
  { value: 'Manager’s Cabin', label: 'Manager’s Cabin' },
  { value: 'Private Cabin', label: 'Private Cabin' },
  { value: 'Managed Office', label: 'Managed Office' },
  { value: 'Meeting / Conference Room', label: 'Meeting / Conference Room' },
  { value: 'Event Area', label: 'Event Area' },
  { value: 'Auditorium', label: 'Auditorium' },
]

export const reportCategoryOptions = [
  { label: 'Booking Reports', value: 'bookingreports' },
  { label: 'Visitors Reports', value: 'visitorreports' },
  { label: 'Walkthrough Reports', value: 'walkthroughreports' },
  { label: 'Company Reports', value: 'companyreports' },
  { label: 'Co-worker Reports', value: 'coworkerreports' },
  { label: 'Cafeteria Reports', value: 'cafeteriareports' },
  { label: 'Helpdesk Reports ', value: 'helpdeskreports' },
]

export const reportOptions = [
  { label: 'Booking Analysis Report', value: 'bookinganalytics' },
  { label: 'Company Resource Booking Log', value: 'bookinglog' },
  { label: 'Company Resource Booking Analysis', value: 'bookingresource' },
]

export const visitorOptions = [
  { label: 'Visitors Logs', value: 'visitorlogs' },
  { label: 'Visitor Analysis Report', value: 'visitoranalysis' },
  { label: 'Client’s Visitor Analysis Report', value: 'clientvisitor' },
]

export const walkthroughOptions = [
  { label: 'Walkthrough Logs', value: 'walkthroughlogs' },
  { label: 'Walkthrough Analysis Report', value: 'walkthroughanalysis' },
]

export const companyOptions = [
  { label: 'Company Plan Analysis Data', value: 'companyplan' },
]

export const coworkerOptions = [
  { label: 'Co-workers Log', value: 'coworkerlog' },
]

export const cafeteriaOptions = [
  { label: 'Monthly Café consumption Analysis', value: 'cafereport' },
]

export const helpdeskOptions = [
  {
    label: 'Client’s Ticket Analysis – (Date Range Based | Till Time Based)',
    value: 'clientticket',
  },
  {
    label: 'Issue Tickets Analysis (Date Range Based | Till Time Based)',
    value: 'ticketanalysis',
  },
  {
    label:
      'Internal User Tickets Analysis (Date Range Based | Till Time Based)',
    value: 'userticket',
  },
]

export const reportTypeData = [
  { label: 'All', value: 'all' },
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
]
