import React from 'react';

const EventSvg = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="300" height="400" viewBox="0 0 348.691 200">
      <g id="Group_640" data-name="Group 640" transform="translate(-126.884 -61.232)">
        <ellipse id="Ellipse_100" data-name="Ellipse 100" cx="17.827" cy="1.677" rx="17.827" ry="1.677" transform="translate(200.57 352.226)" fill="#6e70af" opacity="0.17"/>
        <path id="Path_1044" data-name="Path 1044" d="M221.069,490.355c0,.926-7.981,1.677-17.827,1.677s-17.826-.75-17.826-1.677,7.981-1.677,17.826-1.677S221.069,489.429,221.069,490.355Z" transform="translate(-23.048 -168.317)" fill="#6e70af" opacity="0.17"/>
        <ellipse id="Ellipse_101" data-name="Ellipse 101" cx="22.092" cy="1.677" rx="22.092" ry="1.677" transform="translate(381.928 323.233)" fill="#6e70af" opacity="0.17"/>
        <path id="Path_1045" data-name="Path 1045" d="M373.41,489.52c0,.81-7,1.466-15.633,1.466s-15.633-.657-15.633-1.466,7-1.466,15.633-1.466S373.41,488.711,373.41,489.52Z" transform="translate(-84.764 -168.071)" fill="#6e70af" opacity="0.17"/>
        <g id="Group_506" data-name="Group 506" transform="translate(198.719 136.932)">
          <path id="Path_1046" data-name="Path 1046" d="M246.933,186.738V369.4H441.016V192.782Z" transform="translate(-245.992 -186.354)" fill="#efefef"/>
          <path id="Path_1047" data-name="Path 1047" d="M330.713,408.418l-2.9,49.534,97.45-1.554Z" transform="translate(-277.838 -273.645)" fill="#6e70af" opacity="0.18"/>
          <path id="Path_1048" data-name="Path 1048" d="M333.89,454.448,332.6,405.084l92.932-5.132,1.614,53.112Z" transform="translate(-279.725 -270.312)" fill="#cddfee"/>
          <path id="Path_1049" data-name="Path 1049" d="M387.585,286.143l1.092,46.078,95.923-.77-4.435-53.876Z" transform="translate(-301.377 -222.123)" fill="#6e70af" opacity="0.18"/>
          <path id="Path_1050" data-name="Path 1050" d="M245.38,197.139,440.4,198.515V190.6L245.38,186.1Z" transform="translate(-245.38 -186.104)" fill="#f1f1f2"/>
          <path id="Path_1051" data-name="Path 1051" d="M245.38,204.307v16.037H440.4V205.683Z" transform="translate(-245.38 -193.272)" fill="#bab9b9"/>
          <ellipse id="Ellipse_102" data-name="Ellipse 102" cx="5.677" cy="5.677" rx="5.677" ry="5.677" transform="translate(12.202 23.28) rotate(-76.763)" fill="#fff"/>
          <path id="Path_1052" data-name="Path 1052" d="M297.71,214.34v3.871H326.1V214.34" transform="translate(-265.986 -197.223)" fill="#fff"/>
          <path id="Path_1053" data-name="Path 1053" d="M370.671,209.914v10.3h71.942V210.74Z" transform="translate(-294.716 -195.479)" fill="#fff"/>
          <text id="Text" transform="translate(80.753 20.408)" fill="#316daa" fontSize="3" fontFamily="GillSansMT, Gill Sans MT"><tspan x="0" y="0">Text</tspan></text>
          <g id="Group_505" data-name="Group 505" transform="translate(5.42 5.021)">
            <ellipse id="Ellipse_103" data-name="Ellipse 103" cx="1.185" cy="1.185" rx="1.185" ry="1.185" transform="translate(0 0)" fill="#f06c5a"/>
            <ellipse id="Ellipse_104" data-name="Ellipse 104" cx="1.185" cy="1.185" rx="1.185" ry="1.185" transform="translate(5.605 0)" fill="#ffc839"/>
            <path id="Path_1054" data-name="Path 1054" d="M275.229,195.571a1.184,1.184,0,1,1-1.184-1.185A1.184,1.184,0,0,1,275.229,195.571Z" transform="translate(-261.621 -194.386)" fill="#55b378"/>
          </g>
          <rect id="Rectangle_283" data-name="Rectangle 283" width="2.599" height="32.724" transform="translate(16.544 39.776)" fill="#bdbdbd"/>
          <path id="Path_1055" data-name="Path 1055" d="M286.131,255.387v2.747H321.54l.44-3.116Z" transform="translate(-261.427 -213.24)" fill="#bdbdbd"/>
          <path id="Path_1056" data-name="Path 1056" d="M384.811,249.432v2.748h98.808l1.228-3.116Z" transform="translate(-300.284 -210.896)" fill="#bdbdbd"/>
          <path id="Path_1057" data-name="Path 1057" d="M384.811,371.909v2.747h98.808l1.228-3.117Z" transform="translate(-300.284 -259.124)" fill="#bdbdbd"/>
          <path id="Path_1058" data-name="Path 1058" d="M384.811,264.924v2.748h37.6l.467-3.116Z" transform="translate(-300.284 -216.996)" fill="#bdbdbd"/>
          <path id="Path_1059" data-name="Path 1059" d="M384.811,387.6v2.748h37.6l.467-3.117Z" transform="translate(-300.284 -265.303)" fill="#bdbdbd"/>
          <path id="Path_1060" data-name="Path 1060" d="M286.639,269.7v2.747h35.408l.132-2.5Z" transform="translate(-261.627 -219.02)" fill="#bdbdbd"/>
          <path id="Path_1061" data-name="Path 1061" d="M286.385,284.634v2.747h35.408l.133-2.5Z" transform="translate(-261.527 -224.902)" fill="#bdbdbd"/>
          <path id="Path_1062" data-name="Path 1062" d="M286.385,301.163v2.747h35.408l.133-2.5Z" transform="translate(-261.527 -231.411)" fill="#bdbdbd"/>
          <path id="Path_1063" data-name="Path 1063" d="M387.585,281.9l4.014,48.76,92.657-7.628-4.091-49.7Z" transform="translate(-301.377 -220.453)" fill="#cddfee"/>
        </g>
        <g id="Group_507" data-name="Group 507" transform="translate(366.51 234.079)" opacity="0.18">
          <path id="Path_1064" data-name="Path 1064" d="M523.829,353.124a18.153,18.153,0,0,1-1.615-5,2.074,2.074,0,0,1-.018-.837,1.419,1.419,0,0,1,1.571-.922,2.918,2.918,0,0,1,1.676,1c.452.487.831,1.042,1.319,1.493a6.488,6.488,0,0,0,1.1.792,13.116,13.116,0,0,0,2.819,1.255,3.946,3.946,0,0,1,1.742.823,4.844,4.844,0,0,1-2.141.26,27.378,27.378,0,0,0-6.663.707.668.668,0,0,1,.645-.127" transform="translate(-522.159 -346.351)" fill="#6e70af"/>
        </g>
        <g id="Group_508" data-name="Group 508" transform="translate(373.4 208.174)" opacity="0.18">
          <path id="Path_1065" data-name="Path 1065" d="M541.4,324.549c-1.8-.9-2.3-3.172-2.774-5.129a35.5,35.5,0,0,0-4.222-10.213,5.59,5.59,0,0,1-.832-1.77,3.2,3.2,0,0,1,1.64-3.232,5.361,5.361,0,0,1,3.758-.437Z" transform="translate(-533.525 -303.62)" fill="#6e70af"/>
        </g>
        <g id="Group_550" data-name="Group 550" transform="translate(368.378 209.195)">
          <g id="Group_534" data-name="Group 534">
            <path id="Path_1066" data-name="Path 1066" d="M560.4,463.579l1.137,1.832,1.427-.678-1.282-2Z" transform="translate(-539.085 -367.295)" fill="#d3cce6"/>
            <path id="Path_1067" data-name="Path 1067" d="M566.354,474.066s-6.357-1.5-7.366-.734,7.426,1.885,7.426,1.885Z" transform="translate(-538.497 -371.383)" fill="#262668"/>
            <path id="Path_1068" data-name="Path 1068" d="M529.274,351.978a19.121,19.121,0,0,0-.139-3.255c-.545-.164-.669,1.483-.669,1.483s-.979-3.168-1.444-3.022c-.286.089.1,1.908.1,1.908s-.817-1.466-1.221-1.374.435,2.05.435,2.05-.707-.947-1.074-.829c-.252.081,2.292,4.406,2.292,4.406Z" transform="translate(-525.24 -321.794)" fill="#f7c898"/>
            <path id="Path_1069" data-name="Path 1069" d="M549.933,392.605s-10.131,12.265-5.066,26.661,8.5,15.925,8.5,15.925l2.934-1.535s-5.643-13.487-5.886-18.4c-.221-4.468,7.568-14.919,7.568-14.919L556.4,440.043l3.737.478s9.979-44.45,8.912-47.916S563.53,384.074,549.933,392.605Z" transform="translate(-532.41 -337.837)" fill="#262668"/>
            <path id="Path_1070" data-name="Path 1070" d="M541.593,360.435l2.2,16.8,18.12-2.119-7.343-26.234-5.447.874-3.879.453a7.7,7.7,0,0,0-5.18,2.2l-6.321,7.224-3.134-7.531-2.758,1.379s2.258,13.712,5.81,12.306C536.087,364.821,541.593,360.435,541.593,360.435Z" transform="translate(-526.267 -322.463)" fill="#211b4e"/>
            <path id="Path_1071" data-name="Path 1071" d="M543.79,314.287s4,10.132,4.532,11.731,11.577,9.239,11.577,9.239l-5.447,2.492-10.662-9.882-2.4-13.58Z" transform="translate(-531.6 -308.842)" fill="#18133e"/>
            <g id="Group_509" data-name="Group 509" transform="translate(14.758 14.628)">
              <path id="Path_1072" data-name="Path 1072" d="M551.212,331.895l-1.539,5.291a2.213,2.213,0,0,0,1.517,2.747l.7.2v3.782H555.1a2.052,2.052,0,0,0,2.052-2.052v-8.616Z" transform="translate(-549.584 -330.403)" fill="#f7c898"/>
              <path id="Path_1073" data-name="Path 1073" d="M554.342,339.491A3.041,3.041,0,0,1,552.9,337.2s2.245-.064,1.644-1.622c-.372-.968-1.657-.2-1.657-.2s.513-1.94.2-2.553c0,0-3.856,0-3.232-2.278a5.9,5.9,0,0,1,3.5-1.11c2.427-.137,3.763,3.961,3.763,3.961l-2.045,5.862A.473.473,0,0,1,554.342,339.491Z" transform="translate(-549.662 -329.434)" fill="#1b1d1e"/>
            </g>
            <path id="Path_1074" data-name="Path 1074" d="M540.271,310.75a19.094,19.094,0,0,1-1.912-2.636c.326-.467,1.446.746,1.446.746s-1.2-3.09-.747-3.265c.279-.107,1.11,1.555,1.11,1.555s-.272-1.657.1-1.834.934,1.876.934,1.876-.033-1.182.327-1.317c.247-.093.942,4.875.942,4.875Z" transform="translate(-530.404 -305.305)" fill="#f7c898"/>
            <g id="Group_510" data-name="Group 510" transform="translate(14.976 35.038)">
              <path id="Path_1075" data-name="Path 1075" d="M554.383,363.111a5.594,5.594,0,0,1-.632.467c-.384.295-.923.7-1.529,1.132a13,13,0,0,1-2.277,1.447,13.012,13.012,0,0,1,2.114-1.676c.608-.43,1.161-.811,1.581-1.069S554.371,363.074,554.383,363.111Z" transform="translate(-549.943 -363.102)" fill="#d18c31"/>
            </g>
            <g id="Group_511" data-name="Group 511" transform="translate(17.216 24.793)">
              <path id="Path_1076" data-name="Path 1076" d="M558.8,346.206c0-.008.034-.008.081.026a.342.342,0,0,1,.11.212,2.883,2.883,0,0,1-.049.814,3.249,3.249,0,0,1-.415,1.141,2.154,2.154,0,0,1-1.174.939,8.9,8.9,0,0,1-2.626.389,3.054,3.054,0,0,1-1.089-.117,6.825,6.825,0,0,1,1.082-.082,11.414,11.414,0,0,0,2.538-.456,1.985,1.985,0,0,0,1.049-.812,3.426,3.426,0,0,0,.439-1.043,4.286,4.286,0,0,0,.135-.761A.432.432,0,0,0,558.8,346.206Z" transform="translate(-553.638 -346.202)" fill="#d18c31"/>
            </g>
            <g id="Group_512" data-name="Group 512" transform="translate(7.139 37.065)">
              <path id="Path_1077" data-name="Path 1077" d="M537.153,368.921c.027,0,.052.074.075.208l-.144,0a5.73,5.73,0,0,1-.057-1.249,3.152,3.152,0,0,1,.276-1.436,4.981,4.981,0,0,1,0,1.45c-.025.524-.046.983-.079,1.231l-.144,0C537.1,369,537.126,368.921,537.153,368.921Z" transform="translate(-537.016 -366.446)" fill="#d18c31"/>
            </g>
            <g id="Group_513" data-name="Group 513" transform="translate(7.423 36.721)">
              <path id="Path_1078" data-name="Path 1078" d="M537.959,368.107c-.076.015-.235-.472-.355-1.088a2.414,2.414,0,0,1-.078-1.142c.076-.015.235.472.355,1.088A2.393,2.393,0,0,1,537.959,368.107Z" transform="translate(-537.485 -365.877)" fill="#d18c31"/>
            </g>
            <g id="Group_514" data-name="Group 514" transform="translate(1.523 30.345)">
              <path id="Path_1079" data-name="Path 1079" d="M530.8,355.376a9.485,9.485,0,0,1-3.046,1.165,9.536,9.536,0,0,1,3.046-1.165Z" transform="translate(-527.752 -355.361)" fill="#d18c31"/>
            </g>
            <g id="Group_515" data-name="Group 515" transform="translate(17.246 51.02)">
              <path id="Path_1080" data-name="Path 1080" d="M571.262,389.467a2.267,2.267,0,0,1-.6.382,9.522,9.522,0,0,1-1.82.674c-1.579.423-3.774.93-6.223,1.385-1.224.229-2.395.419-3.462.577s-2.029.3-2.85.341a7.127,7.127,0,0,1-1.94-.119,2.132,2.132,0,0,1-.677-.226c.007-.022.249.052.7.118a8.108,8.108,0,0,0,1.907.028c.8-.07,1.762-.233,2.826-.4s2.228-.369,3.45-.6c2.44-.454,4.641-.929,6.224-1.3a11.648,11.648,0,0,0,1.825-.581C571.031,389.573,571.25,389.447,571.262,389.467Z" transform="translate(-553.688 -389.465)" fill="#d18c31"/>
            </g>
            <path id="Path_1081" data-name="Path 1081" d="M554.4,362.909s-1.083,7.832-3.286,9.076l-.747-5.715Z" transform="translate(-535.132 -327.988)" fill="#6e70af" opacity="0.25"/>
            <path id="Path_1082" data-name="Path 1082" d="M550.645,331.983a2.786,2.786,0,0,1-.9-1.1,2.34,2.34,0,0,1,.484-2c.5-.312,1.139-.035,1.722.048a10.745,10.745,0,0,0,2.621-.313,2.007,2.007,0,0,1,2.216,1.034c.056.173.1.39.276.441a.618.618,0,0,0,.231,0c.682-.064,1.159.734,1.12,1.417a11.749,11.749,0,0,1-.553,1.971c-.346,1.458.262,3.087-.377,4.443-.5,1.067-2.088,2.01-3.181,1.242l1.4-6.171Z" transform="translate(-534.866 -314.478)" fill="#1b1d1e"/>
            <path id="Path_1083" data-name="Path 1083" d="M560.52,466.032s-5.973,2.646-6.312,3.867,7.056-2.985,7.056-2.985Z" transform="translate(-536.643 -368.595)" fill="#262668"/>
            <g id="Group_516" data-name="Group 516" transform="translate(14.266 75.679)">
              <path id="Path_1084" data-name="Path 1084" d="M552.452,430.148a12.172,12.172,0,0,1-3.679,1.955,21.159,21.159,0,0,1,3.679-1.955Z" transform="translate(-548.772 -430.141)" fill="#1a1d45"/>
            </g>
            <g id="Group_517" data-name="Group 517" transform="translate(15.336 77.403)">
              <path id="Path_1085" data-name="Path 1085" d="M553.071,433a101.739,101.739,0,0,0-2.529,1.462c-.053-.052.395-.568,1.118-.984A2.621,2.621,0,0,1,553.071,433Z" transform="translate(-550.538 -432.985)" fill="#1a1d45"/>
            </g>
            <g id="Group_518" data-name="Group 518" transform="translate(26.991 79.463)">
              <path id="Path_1086" data-name="Path 1086" d="M573.856,437.69a10.581,10.581,0,0,1-4.093-1.3,37.738,37.738,0,0,1,4.093,1.3Z" transform="translate(-569.763 -436.383)" fill="#1a1d45"/>
            </g>
            <g id="Group_519" data-name="Group 519" transform="translate(27.447 78.54)">
              <path id="Path_1087" data-name="Path 1087" d="M574.428,436.541a9.86,9.86,0,0,1-3.911-1.672,11.937,11.937,0,0,1,1.975.791A12.089,12.089,0,0,1,574.428,436.541Z" transform="translate(-570.516 -434.861)" fill="#1a1d45"/>
            </g>
            <g id="Group_533" data-name="Group 533" transform="translate(13.754 54.676)">
              <g id="Group_520" data-name="Group 520" transform="translate(7.54 41.724)">
                <path id="Path_1088" data-name="Path 1088" d="M560.393,464.329c.067-.041.232.106.37.327s.2.433.132.476-.232-.1-.37-.327S560.327,464.369,560.393,464.329Z" transform="translate(-560.366 -464.322)" fill="#1a1d45"/>
              </g>
              <g id="Group_521" data-name="Group 521" transform="translate(5.655 38.395)">
                <path id="Path_1089" data-name="Path 1089" d="M557.273,458.833c.07-.036.33.31.584.774s.4.87.336.909-.328-.307-.584-.773S557.2,458.869,557.273,458.833Z" transform="translate(-557.257 -458.831)" fill="#1a1d45"/>
              </g>
              <g id="Group_522" data-name="Group 522" transform="translate(3.985 34.943)">
                <path id="Path_1090" data-name="Path 1090" d="M554.522,453.137c.071-.031.309.332.53.811s.344.9.274.929-.307-.329-.529-.812S554.451,453.168,554.522,453.137Z" transform="translate(-554.502 -453.135)" fill="#1a1d45"/>
              </g>
              <g id="Group_523" data-name="Group 523" transform="translate(2.549 31.386)">
                <path id="Path_1091" data-name="Path 1091" d="M552.157,447.269c.074-.027.286.352.475.845s.283.916.211.946-.285-.349-.475-.845S552.084,447.3,552.157,447.269Z" transform="translate(-552.133 -447.268)" fill="#1a1d45"/>
              </g>
              <g id="Group_524" data-name="Group 524" transform="translate(1.353 27.74)">
                <path id="Path_1092" data-name="Path 1092" d="M550.192,441.255c.073-.021.258.371.415.875s.222.933.148.958-.261-.367-.418-.875S550.115,441.277,550.192,441.255Z" transform="translate(-550.16 -441.255)" fill="#1a1d45"/>
              </g>
              <g id="Group_525" data-name="Group 525" transform="translate(0.447 24.012)">
                <path id="Path_1093" data-name="Path 1093" d="M548.709,435.1c.076-.013.228.393.338.909s.138.948.062.967-.227-.389-.338-.909S548.633,435.119,548.709,435.1Z" transform="translate(-548.666 -435.105)" fill="#1a1d45"/>
              </g>
              <g id="Group_526" data-name="Group 526" transform="translate(0 20.202)">
                <path id="Path_1094" data-name="Path 1094" d="M548.02,428.819c.078,0,.169.423.2.947s0,.957-.078.966-.168-.416-.2-.948S547.942,428.821,548.02,428.819Z" transform="translate(-547.928 -428.819)" fill="#1a1d45"/>
              </g>
              <g id="Group_527" data-name="Group 527" transform="translate(0.032 16.374)">
                <path id="Path_1095" data-name="Path 1095" d="M548.225,422.5c.077.01.1.443.059.967s-.144.947-.222.944-.1-.436-.058-.968S548.148,422.5,548.225,422.5Z" transform="translate(-547.981 -422.505)" fill="#1a1d45"/>
              </g>
              <g id="Group_528" data-name="Group 528" transform="translate(0.533 12.61)">
                <path id="Path_1096" data-name="Path 1096" d="M549.276,416.3c.075.021.041.453-.076.966s-.274.917-.35.9-.043-.447.075-.966S549.2,416.276,549.276,416.3Z" transform="translate(-548.808 -416.296)" fill="#1a1d45"/>
              </g>
              <g id="Group_529" data-name="Group 529" transform="translate(1.524 8.973)">
                <path id="Path_1097" data-name="Path 1097" d="M551.131,410.3c.072.029-.017.453-.2.948s-.39.875-.464.851.015-.448.2-.948S551.059,410.269,551.131,410.3Z" transform="translate(-550.442 -410.297)" fill="#1a1d45"/>
              </g>
              <g id="Group_530" data-name="Group 530" transform="translate(2.968 5.511)">
                <path id="Path_1098" data-name="Path 1098" d="M553.718,404.588c.068.038-.072.448-.313.917s-.493.821-.563.789.069-.444.312-.917S553.65,404.55,553.718,404.588Z" transform="translate(-552.824 -404.585)" fill="#1a1d45"/>
              </g>
              <g id="Group_531" data-name="Group 531" transform="translate(4.822 2.264)">
                <path id="Path_1099" data-name="Path 1099" d="M556.964,399.234c.063.045-.124.437-.417.875s-.585.758-.651.718.121-.433.417-.875S556.9,399.189,556.964,399.234Z" transform="translate(-555.883 -399.23)" fill="#1a1d45"/>
              </g>
              <g id="Group_532" data-name="Group 532" transform="translate(7.039)">
                <path id="Path_1100" data-name="Path 1100" d="M560.162,395.507c.059.05-.027.254-.193.455s-.35.324-.41.276.025-.253.193-.455S560.1,395.456,560.162,395.507Z" transform="translate(-559.539 -395.495)" fill="#1a1d45"/>
              </g>
            </g>
          </g>
          <g id="Group_549" data-name="Group 549" transform="translate(16.59 58.773)">
            <g id="Group_535" data-name="Group 535" transform="translate(20.036 4.89)">
              <path id="Path_1101" data-name="Path 1101" d="M596.212,462.372c-.191.038-2.706-11.583-5.617-25.954s-5.118-26.058-4.927-26.1,2.7,11.581,5.617,25.957S596.4,462.333,596.212,462.372Z" transform="translate(-585.656 -410.321)" fill="#6e70af"/>
            </g>
            <g id="Group_536" data-name="Group 536" transform="translate(28.63 4.89)">
              <path id="Path_1102" data-name="Path 1102" d="M610.39,462.372c-.191.038-2.706-11.583-5.618-25.954s-5.118-26.058-4.927-26.1,2.706,11.581,5.617,25.957S610.58,462.333,610.39,462.372Z" transform="translate(-599.833 -410.321)" fill="#6e70af"/>
            </g>
            <rect id="Rectangle_284" data-name="Rectangle 284" width="25.867" height="4.891" transform="translate(4.725)" fill="#6e70af"/>
            <g id="Group_539" data-name="Group 539" transform="translate(0 2.458)">
              <g id="Group_537" data-name="Group 537" transform="translate(6.925)">
                <path id="Path_1103" data-name="Path 1103" d="M573.464,406.308c.191.034-1.762,12.257-4.364,27.3s-4.866,27.215-5.058,27.183,1.762-12.255,4.365-27.3S573.272,406.275,573.464,406.308Z" transform="translate(-564.029 -406.308)" fill="#6e70af"/>
              </g>
              <g id="Group_538" data-name="Group 538">
                <path id="Path_1104" data-name="Path 1104" d="M562.041,406.308c.191.034-1.762,12.257-4.364,27.3s-4.866,27.215-5.058,27.183,1.762-12.255,4.365-27.3S561.849,406.275,562.041,406.308Z" transform="translate(-552.606 -406.308)" fill="#6e70af"/>
              </g>
            </g>
            <path id="Path_1105" data-name="Path 1105" d="M592.937,446.168h8.595l.4,1.985h-8.595Z" transform="translate(-568.487 -419.546)" fill="#6e70af"/>
            <g id="Group_540" data-name="Group 540" transform="translate(0.964 51.399)">
              <path id="Path_1106" data-name="Path 1106" d="M561.264,487.145c.028.193-1.532.579-3.483.863s-3.557.358-3.585.166,1.531-.578,3.483-.862S561.236,486.953,561.264,487.145Z" transform="translate(-554.196 -487.038)" fill="#6e70af"/>
            </g>
            <g id="Group_541" data-name="Group 541" transform="translate(1.7 46.874)">
              <path id="Path_1107" data-name="Path 1107" d="M562.478,479.682c.028.193-1.531.579-3.483.863s-3.556.358-3.584.165,1.531-.578,3.482-.862S562.45,479.49,562.478,479.682Z" transform="translate(-555.41 -479.575)" fill="#6e70af"/>
            </g>
            <g id="Group_542" data-name="Group 542" transform="translate(2.472 41.618)">
              <path id="Path_1108" data-name="Path 1108" d="M563.751,471.012c.028.192-1.532.579-3.483.863s-3.556.358-3.584.166,1.531-.578,3.483-.863S563.723,470.82,563.751,471.012Z" transform="translate(-556.683 -470.905)" fill="#6e70af"/>
            </g>
            <g id="Group_543" data-name="Group 543" transform="translate(3.404 36.362)">
              <path id="Path_1109" data-name="Path 1109" d="M565.289,462.342c.029.193-1.531.579-3.482.863s-3.557.358-3.585.166,1.531-.578,3.483-.862S565.261,462.15,565.289,462.342Z" transform="translate(-558.221 -462.235)" fill="#6e70af"/>
            </g>
            <g id="Group_544" data-name="Group 544" transform="translate(4.04 31.695)">
              <path id="Path_1110" data-name="Path 1110" d="M566.337,454.643c.028.192-1.531.579-3.483.863s-3.557.358-3.584.165,1.531-.578,3.483-.863S566.309,454.451,566.337,454.643Z" transform="translate(-559.269 -454.536)" fill="#6e70af"/>
            </g>
            <g id="Group_545" data-name="Group 545" transform="translate(5.233 26.994)">
              <path id="Path_1111" data-name="Path 1111" d="M568.306,446.888c.028.192-1.531.578-3.482.862s-3.556.358-3.585.165,1.531-.579,3.483-.863S568.278,446.7,568.306,446.888Z" transform="translate(-561.238 -446.781)" fill="#6e70af"/>
            </g>
            <g id="Group_546" data-name="Group 546" transform="translate(6.006 21.89)">
              <path id="Path_1112" data-name="Path 1112" d="M569.58,438.47c.028.192-1.532.578-3.483.862s-3.556.358-3.584.166,1.531-.578,3.483-.862S569.552,438.277,569.58,438.47Z" transform="translate(-562.512 -438.363)" fill="#6e70af"/>
            </g>
            <g id="Group_547" data-name="Group 547" transform="translate(6.937 16.292)">
              <path id="Path_1113" data-name="Path 1113" d="M571.117,429.236c.028.192-1.531.578-3.482.862s-3.557.358-3.585.166,1.532-.578,3.483-.862S571.089,429.043,571.117,429.236Z" transform="translate(-564.049 -429.129)" fill="#6e70af"/>
            </g>
            <g id="Group_548" data-name="Group 548" transform="translate(8.115 10.024)">
              <path id="Path_1114" data-name="Path 1114" d="M573.06,418.9c.028.193-1.532.578-3.483.863s-3.556.358-3.584.165,1.531-.578,3.483-.862S573.032,418.7,573.06,418.9Z" transform="translate(-565.992 -418.789)" fill="#6e70af"/>
            </g>
          </g>
        </g>
        <g id="Group_561" data-name="Group 561" transform="translate(164.84 217.599)">
          <path id="Path_1115" data-name="Path 1115" d="M190.147,407.265s-1.16,3.406-.387,5.82,1.572,1.556,1.572,1.556a3.866,3.866,0,0,1-.413-2.308,4.9,4.9,0,0,1,.457-1.817l.646,2.845a7.982,7.982,0,0,0,.423-1.437c.182-.883-.443-2.473-.281-3.284s.436-1.746.436-1.746Z" transform="translate(-189.494 -353.712)" fill="#f7c898"/>
          <path id="Path_1116" data-name="Path 1116" d="M267.232,329.648l.192-1.259s-1.306-2.852-.909-3.472a13.561,13.561,0,0,1,1.713-1.784l.815.125a12.978,12.978,0,0,1,1.585,2.515c.132.626-.711,2.869-1.306,4.573Z" transform="translate(-219.793 -320.729)" fill="#f7c898"/>
          <path id="Path_1117" data-name="Path 1117" d="M268.265,320.242l.726,5.87.8-.182-.744-5.807-.48-.955Z" transform="translate(-220.512 -319.167)" fill="#6aade0"/>
          <path id="Path_1118" data-name="Path 1118" d="M268,323.925l.841-.792a1.58,1.58,0,0,1-.5,1.76Z" transform="translate(-220.408 -320.729)" fill="#f7c898"/>
          <g id="Group_551" data-name="Group 551" transform="translate(26.329 100.898)">
            <path id="Path_1119" data-name="Path 1119" d="M234.558,485.6l.088,2.153-1.562.24-.063-2.375Z" transform="translate(-232.963 -485.604)" fill="#f7c898"/>
            <path id="Path_1120" data-name="Path 1120" d="M233.046,489.138s6.428-1.163,7.394-.342-7.515,1.489-7.515,1.489Z" transform="translate(-232.925 -486.746)" fill="#262668"/>
          </g>
          <g id="Group_552" data-name="Group 552" transform="translate(1.552 92.021)">
            <path id="Path_1121" data-name="Path 1121" d="M196.586,472.265l-1.794,1.195-1.017-1.21,2-1.289Z" transform="translate(-192.732 -470.961)" fill="#f7c898"/>
            <path id="Path_1122" data-name="Path 1122" d="M193.1,473.086s4.335,4.887,4.137,6.139-5.18-5.645-5.18-5.645Z" transform="translate(-192.055 -471.798)" fill="#262668"/>
          </g>
          <path id="Path_1123" data-name="Path 1123" d="M221.839,397.205s5.4,4.986,5.493,23.479-6.36,30.763-6.36,30.763h-4.547s7.458-23.221-.921-38.62a60.288,60.288,0,0,1-17.647,31.663l-3.591-3.816s10.947-13.754,11.035-30.952c.017-3.408.587-5.132-.6-8.945Z" transform="translate(-191.373 -349.897)" fill="#444294"/>
          <path id="Path_1124" data-name="Path 1124" d="M217.067,351.055s14.139-3.815,17.73-13.69l2.02-4.488,2.693.9s-.7,18.174-18.249,23.118l-.279,1.066a29.663,29.663,0,0,0-.133,14.5l1.772,3.673L203.735,380.4a45.725,45.725,0,0,1-.164-19.043s-4.114-.911-11.011,18.4l-2.648-.532s3.815-25.926,17.5-29.517Z" transform="translate(-189.659 -324.566)" fill="#f28174"/>
          <g id="Group_553" data-name="Group 553" transform="translate(2.058 13.959)">
            <path id="Path_1125" data-name="Path 1125" d="M231.1,345.361l1.067,4.956a2.037,2.037,0,0,1-1.572,2.422l-.652.137-.247,3.47L224.862,356l.7-9.789Z" transform="translate(-205.48 -343.441)" fill="#f7c898"/>
            <path id="Path_1126" data-name="Path 1126" d="M227.711,352.314s1.91-.841,1.981-2.285c0,0-2.515-1.55-1.66-2.215s2.193.963,2.193.963-.746-2.233-.416-2.775c0,0,3.538.247,3.114-1.879a5.43,5.43,0,0,0-3.138-1.247c-2.217-.284-3.711,3.389-3.711,3.389Z" transform="translate(-205.956 -342.456)" fill="#1b1d1e"/>
            <path id="Path_1127" data-name="Path 1127" d="M226.352,342.8a2.754,2.754,0,0,0-1.878-.6,5.653,5.653,0,0,0-5.563,6.817,3.188,3.188,0,0,0,.407,1.072,4.46,4.46,0,0,0,2.516,1.524c.831.274,2.284.784,3.02.313,0,0-1.111-3.449-.4-5.521A13.769,13.769,0,0,1,226.352,342.8Z" transform="translate(-203.09 -342.194)" fill="#1b1d1e"/>
            <path id="Path_1128" data-name="Path 1128" d="M209.21,348.824a3.193,3.193,0,0,0-4.382.6c-.63.863-.818,2.06-1.67,2.7-1.006.762-2.418.4-3.677.321a6.458,6.458,0,0,0-5.789,2.667,4.887,4.887,0,0,0,.583,6.11,5.51,5.51,0,0,0,4.09,1.24,13.378,13.378,0,0,0,4.21-1.18c1.961-.825,4.052-1.982,4.7-4.009.445-1.393.1-2.91.275-4.362a1.216,1.216,0,0,1,.589-1.055c.322-.128.685.013,1.031-.007a1.419,1.419,0,0,0,1.058-1.881,2.566,2.566,0,0,0-1.887-1.48" transform="translate(-192.89 -344.563)" fill="#1b1d1e"/>
          </g>
          <path id="Path_1129" data-name="Path 1129" d="M220.232,361.317a18.7,18.7,0,0,0-3.454,2.641,6.275,6.275,0,0,0-1.859,3.843,1.312,1.312,0,0,0,.275,1.026,1.184,1.184,0,0,0,.533.273c1.506.41,3.03-.48,4.339-1.332a25.69,25.69,0,0,0,6.057-4.886c.121-.148.246-.349.153-.517a.49.49,0,0,0-.283-.189c-1.534-.564-4.171-.473-5.76-.859" transform="translate(-199.504 -335.765)" fill="#444294"/>
          <g id="Group_554" data-name="Group 554" transform="translate(14.212 25.552)">
            <path id="Path_1130" data-name="Path 1130" d="M225.529,363.1a.863.863,0,0,1-.132.221c-.095.139-.238.342-.425.6l-.313.432-.4.488-.467.56c-.174.191-.365.386-.563.595a15.521,15.521,0,0,1-3.123,2.542,12.466,12.466,0,0,1-2.152,1.04,9.58,9.58,0,0,1-2.516.536,6.832,6.832,0,0,1-1.317-.031,1.816,1.816,0,0,1-.647-.212,1.023,1.023,0,0,1-.455-.533,2.033,2.033,0,0,1,.07-1.272,9.8,9.8,0,0,1,.38-1.11,7.428,7.428,0,0,1,1.191-1.816c.438-.517.877-.965,1.285-1.366.817-.8,1.513-1.412,2-1.831.243-.207.433-.366.564-.472a.9.9,0,0,1,.21-.15c.007.008-.053.073-.172.193s-.3.291-.529.51c-.459.443-1.133,1.074-1.927,1.89-.4.407-.824.861-1.247,1.376a7.354,7.354,0,0,0-1.132,1.767,9.9,9.9,0,0,0-.361,1.085,1.8,1.8,0,0,0-.069,1.1,1.012,1.012,0,0,0,.886.565,6.716,6.716,0,0,0,1.257.027,9.43,9.43,0,0,0,2.442-.515,12.562,12.562,0,0,0,2.111-1,16.062,16.062,0,0,0,3.115-2.46l.572-.574.48-.542.42-.47.33-.413c.2-.249.356-.44.466-.569S225.521,363.09,225.529,363.1Z" transform="translate(-212.937 -361.316)" fill="#c1564c"/>
          </g>
          <g id="Group_555" data-name="Group 555" transform="translate(16.144 28.671)">
            <path id="Path_1131" data-name="Path 1131" d="M226.795,366.463c.013.024-.246.151-.638.477a5.872,5.872,0,0,0-1.334,1.675,19.593,19.593,0,0,0-1.234,2.971,16.165,16.165,0,0,1-.752,1.774,6.472,6.472,0,0,1-1.212,1.7,5.327,5.327,0,0,1-1.934,1.183,5.6,5.6,0,0,1-1.036.3,1.744,1.744,0,0,1-1.057-.119,1.4,1.4,0,0,1-.67-.757,2,2,0,0,1-.131-.917,4.973,4.973,0,0,1,.453-1.5l.155.1a.652.652,0,0,1-.6.184.843.843,0,0,1-.479-.26.888.888,0,0,1-.165-.811,1.217,1.217,0,0,1,.229-.46c.074-.09.123-.126.129-.121a2.038,2.038,0,0,0-.259.6.789.789,0,0,0,.173.69.687.687,0,0,0,.4.2.478.478,0,0,0,.436-.143l.35-.416-.2.512a4.982,4.982,0,0,0-.4,1.444,1.779,1.779,0,0,0,.129.808,1.164,1.164,0,0,0,.56.619,1.5,1.5,0,0,0,.9.085,5.372,5.372,0,0,0,.981-.29,5.126,5.126,0,0,0,1.836-1.128,6.316,6.316,0,0,0,1.163-1.626,16.64,16.64,0,0,0,.754-1.739,18.106,18.106,0,0,1,1.3-2.979,5.5,5.5,0,0,1,1.44-1.663,2.922,2.922,0,0,1,.288-.192,1.636,1.636,0,0,1,.222-.121C226.723,366.481,226.79,366.455,226.795,366.463Z" transform="translate(-216.125 -366.461)" fill="#c1564c"/>
          </g>
          <path id="Path_1132" data-name="Path 1132" d="M220.523,377.323s-1.448,4.335,2.116,1.9,4.075-11.247,4.075-11.247S224.276,376.791,220.523,377.323Z" transform="translate(-201.598 -338.388)" fill="#6e70af" opacity="0.18"/>
          <g id="Group_556" data-name="Group 556" transform="translate(28.583 80.244)">
            <path id="Path_1133" data-name="Path 1133" d="M240.782,451.675c-.006.082-.94.057-2.075.2s-2.038.382-2.063.3a6.657,6.657,0,0,1,4.138-.5Z" transform="translate(-236.643 -451.534)" fill="#292e76"/>
          </g>
          <g id="Group_557" data-name="Group 557" transform="translate(28.718 78.882)">
            <path id="Path_1134" data-name="Path 1134" d="M241.458,449.293c.019.029-.186.211-.577.416a4.905,4.905,0,0,1-3.357.392c-.427-.109-.669-.24-.657-.272a7.5,7.5,0,0,0,4.592-.536Z" transform="translate(-236.866 -449.286)" fill="#292e76"/>
          </g>
          <g id="Group_558" data-name="Group 558" transform="translate(14.294 74.203)">
            <path id="Path_1135" data-name="Path 1135" d="M215.191,444.387a3.672,3.672,0,0,1-2.1-2.818c.08-.024.333.737.917,1.509S215.237,444.317,215.191,444.387Z" transform="translate(-213.073 -441.568)" fill="#292e76"/>
          </g>
          <g id="Group_559" data-name="Group 559" transform="translate(13.568 75.84)">
            <path id="Path_1136" data-name="Path 1136" d="M214.316,445.925c-.041.009-.1-.142-.3-.291s-.535-.327-.883-.538a5.618,5.618,0,0,1-1.255-.814,3.242,3.242,0,0,1,1.4.57,4.28,4.28,0,0,1,.877.635C214.357,445.711,214.353,445.925,214.316,445.925Z" transform="translate(-211.875 -444.269)" fill="#292e76"/>
          </g>
          <g id="Group_560" data-name="Group 560" transform="translate(21.067 61.073)">
            <path id="Path_1137" data-name="Path 1137" d="M231.469,423.178a2.382,2.382,0,0,1-1.165-.173,21.384,21.384,0,0,1-2.568-1.175c-.981-.495-1.862-.955-2.493-1.3a4.841,4.841,0,0,1-1-.612,4.805,4.805,0,0,1,1.086.435c.654.3,1.55.734,2.531,1.228s1.85.98,2.508,1.239S231.474,423.133,231.469,423.178Z" transform="translate(-224.246 -419.91)" fill="#292e76"/>
          </g>
        </g>
        <g id="Group_562" data-name="Group 562" transform="translate(285.579 206.081)" opacity="0.18">
          <path id="Path_1138" data-name="Path 1138" d="M388.66,301.117a1.008,1.008,0,0,1,.566-.773,1.578,1.578,0,0,1,1.48.018,3.3,3.3,0,0,1,1.285,2.169,6.565,6.565,0,0,0,.846,2.43,1.092,1.092,0,0,0,.634.469c.472.09.878-.313,1.2-.671a7.383,7.383,0,0,1,3.315-2.36,3.225,3.225,0,0,1,3.636,1.208,4.889,4.889,0,0,1,.482,2.154q.606,8.93,1.212,17.859a23.369,23.369,0,0,1-.424,8.262c-.715,2.4-7.587,9.348-10.87,9.348" transform="translate(-388.66 -300.168)" fill="#6e70af"/>
        </g>
        <g id="Group_574" data-name="Group 574" transform="translate(261.147 207.607)">
          <g id="Group_563" data-name="Group 563" transform="translate(2.637 3.132)">
            <path id="Path_1139" data-name="Path 1139" d="M374.977,400.721,373.4,432.753l-4.585,21.385-2.152-.592,1.8-21.391-3.587-22.04-3.189,20.544L354.6,454.219l-1.893.065,3.395-23.454-.93-28.548Z" transform="translate(-352.708 -344.421)" fill="#f7c898"/>
            <path id="Path_1140" data-name="Path 1140" d="M383.341,311.6l-2.116,16.4L371,337.873l-9.163.134-3.326,5.779,1.335,5.226a17.61,17.61,0,0,1-.957,11.48l-2.12,6.471,20.019,2.3c.658-15.2.35-16.44-2.133-25.083l11.82-15.314.033-17.845Z" transform="translate(-354.309 -309.101)" fill="#e73860"/>
            <path id="Path_1141" data-name="Path 1141" d="M378.168,309.776c-.994.544-7.415,16.939-7.415,16.939l.371,10.485-5.991,1.089V326.274L375.6,307.851Z" transform="translate(-357.6 -307.851)" fill="#262668"/>
          </g>
          <g id="Group_564" data-name="Group 564" transform="translate(0 20.697)">
            <path id="Path_1142" data-name="Path 1142" d="M374.585,338.813l1.831,4.727a2.037,2.037,0,0,1-1.173,2.638l-.623.238.3,3.465-4.827.418-.848-9.777Z" transform="translate(-356.583 -337.608)" fill="#f7c898"/>
            <path id="Path_1143" data-name="Path 1143" d="M372.048,346.953s1.849-1.608,1.692-3.045c0,0-2.727-1.135-1.987-1.928s2.316.608,2.316.608-1.087-2.089-.846-2.675c0,0,3.533-.312,2.781-2.344a5.43,5.43,0,0,0-3.3-.74c-2.235.067-3.134,3.93-3.134,3.93Z" transform="translate(-356.712 -336.825)" fill="#1b1d1e"/>
            <path id="Path_1144" data-name="Path 1144" d="M364.7,337.1a6.748,6.748,0,0,0-8.462,7.093c.128,1.508.751,3.083.162,4.476a5.437,5.437,0,0,1-1.463,1.794,30.882,30.882,0,0,0-4.716,4.9,8.983,8.983,0,0,0-1.784,6.408,5.352,5.352,0,0,0,1.152,2.62c.329.387.723.719,1.012,1.137,1.032,1.491.452,3.575,1.085,5.275.812,2.182,3.369,3.157,5.685,3.391,3.178.323,6.6-.35,8.946-2.523s3.214-6.042,1.41-8.678c-.893-1.3-2.411-2.452-2.262-4.025a8.459,8.459,0,0,1,1.255-2.639,4.45,4.45,0,0,0-1.278-5.3c-.578-.439-1.263-.731-1.8-1.222a1.744,1.744,0,0,1-.575-1.944c.247-.5.824-.772,1.083-1.267a2.164,2.164,0,0,0-.628-2.268,4.119,4.119,0,0,1-1.3-2.091,3.326,3.326,0,0,1,.54-1.8l1.749-3.326" transform="translate(-348.357 -336.846)" fill="#1b1d1e"/>
          </g>
          <path id="Path_1145" data-name="Path 1145" d="M355.834,403.3l-1.446,11.164,10.779,1.738.461-4.416.6,4.309H377.01L375.854,405.6Z" transform="translate(-350.732 -342.303)" fill="#444294"/>
          <path id="Path_1146" data-name="Path 1146" d="M352.824,487.467s6.532-.012,7.338.966-7.66.142-7.66.142Z" transform="translate(-349.99 -375.447)" fill="#262668"/>
          <path id="Path_1147" data-name="Path 1147" d="M374.826,487.82s6.428-1.162,7.395-.343-7.515,1.491-7.515,1.491Z" transform="translate(-358.733 -375.336)" fill="#262668"/>
          <g id="Group_565" data-name="Group 565" transform="translate(11.951 67.931)">
            <path id="Path_1148" data-name="Path 1148" d="M375.193,417.583c-.037.073-1.59-.658-3.553-1.437s-3.593-1.319-3.569-1.4a4.119,4.119,0,0,1,1.1.244c.669.193,1.583.5,2.571.891s1.861.8,2.479,1.12A4.143,4.143,0,0,1,375.193,417.583Z" transform="translate(-368.071 -414.741)" fill="#292e76"/>
          </g>
          <g id="Group_566" data-name="Group 566" transform="translate(3.592 71.019)">
            <path id="Path_1149" data-name="Path 1149" d="M365.332,421.556a12.558,12.558,0,0,1-1.611-.3c-.99-.2-2.364-.447-3.892-.658s-2.918-.364-3.923-.487a9.664,9.664,0,0,1-1.623-.252,9.271,9.271,0,0,1,1.642.054c1.011.073,2.407.2,3.943.407a35.9,35.9,0,0,1,3.9.742A7.619,7.619,0,0,1,365.332,421.556Z" transform="translate(-354.282 -419.835)" fill="#292e76"/>
          </g>
          <g id="Group_567" data-name="Group 567" transform="translate(15.075 72.162)">
            <path id="Path_1150" data-name="Path 1150" d="M384.075,421.788a9.589,9.589,0,0,1-1.59.131c-.982.052-2.338.119-3.834.211s-2.851.195-3.833.265a9.685,9.685,0,0,1-1.594.067,8.346,8.346,0,0,1,1.574-.265c.978-.122,2.333-.255,3.834-.348s2.862-.129,3.847-.129A8.382,8.382,0,0,1,384.075,421.788Z" transform="translate(-373.224 -421.72)" fill="#292e76"/>
          </g>
          <g id="Group_568" data-name="Group 568" transform="translate(21.644 64.099)">
            <path id="Path_1151" data-name="Path 1151" d="M387.91,410.25c.013.031-.224.177-.663.252a2.874,2.874,0,0,1-2.956-1.409c-.217-.389-.253-.664-.221-.674s.144.23.388.566a3.137,3.137,0,0,0,2.767,1.319A4.277,4.277,0,0,1,387.91,410.25Z" transform="translate(-384.06 -408.419)" fill="#292e76"/>
          </g>
          <g id="Group_569" data-name="Group 569" transform="translate(23.979 66.331)">
            <path id="Path_1152" data-name="Path 1152" d="M388.785,419.837a53.808,53.808,0,0,1-.851-7.736,53.833,53.833,0,0,1,.851,7.736Z" transform="translate(-387.912 -412.101)" fill="#292e76"/>
          </g>
          <g id="Group_570" data-name="Group 570" transform="translate(6.165 90.19)">
            <path id="Path_1153" data-name="Path 1153" d="M358.528,451.476a13.118,13.118,0,0,1,2.56.7,4.218,4.218,0,0,1-2.56-.7Z" transform="translate(-358.527 -451.458)" fill="#cb9b70"/>
          </g>
          <g id="Group_571" data-name="Group 571" transform="translate(6.106 89.92)">
            <path id="Path_1154" data-name="Path 1154" d="M361.25,451.132a3.771,3.771,0,0,1-2.819-.089,6.931,6.931,0,0,1,1.41.036A6.835,6.835,0,0,1,361.25,451.132Z" transform="translate(-358.43 -451.013)" fill="#cb9b70"/>
          </g>
          <g id="Group_572" data-name="Group 572" transform="translate(18.967 90.396)">
            <path id="Path_1155" data-name="Path 1155" d="M382.294,451.829a4.89,4.89,0,0,1-2.65.41,3.974,3.974,0,0,1,1.311-.3A3.931,3.931,0,0,1,382.294,451.829Z" transform="translate(-379.644 -451.798)" fill="#cb9b70"/>
          </g>
          <g id="Group_573" data-name="Group 573" transform="translate(19.211 89.769)">
            <path id="Path_1156" data-name="Path 1156" d="M381.753,450.788c.028.074-.337.279-.8.488s-.865.341-.9.271.311-.318.786-.529S381.727,450.716,381.753,450.788Z" transform="translate(-380.047 -450.764)" fill="#cb9b70"/>
          </g>
          <path id="Path_1157" data-name="Path 1157" d="M384.407,307.742a19.129,19.129,0,0,0,2.777-1.7c-.121-.556-1.622.135-1.622.135s2.294-2.394,1.941-2.73c-.216-.206-1.621,1.011-1.621,1.011s.885-1.426.608-1.734-1.581,1.376-1.581,1.376.484-1.079.2-1.342c-.193-.181-2.74,4.142-2.74,4.142Z" transform="translate(-361.752 -302.685)" fill="#f7c898"/>
          <path id="Path_1158" data-name="Path 1158" d="M400.674,308.6a19.1,19.1,0,0,1-1.037-3.087c.45-.349,1.157,1.144,1.157,1.144s-.222-3.308.264-3.339c.3-.019.595,1.815.595,1.815s.236-1.661.646-1.72.33,2.07.33,2.07.321-1.138.705-1.159c.264-.015-.558,4.935-.558,4.935Z" transform="translate(-368.545 -302.934)" fill="#f7c898"/>
        </g>
        <g id="Group_608" data-name="Group 608" transform="translate(204.464 263.326)">
          <g id="Group_599" data-name="Group 599" transform="translate(25.534 1.062)">
            <path id="Path_1159" data-name="Path 1159" d="M296.975,414.321l7.374-17.972s20.451,6.889,20.235,7.319-8.924,18.223-8.924,18.223Z" transform="translate(-296.975 -396.349)" fill="#f0eff8"/>
            <g id="Group_575" data-name="Group 575" transform="translate(4.66 2.974)">
              <path id="Path_1160" data-name="Path 1160" d="M310.944,401.255a.532.532,0,0,1-.062.124l-.2.34-.745,1.242c-.626,1.051-1.468,2.516-2.337,4.168s-1.6,3.178-2.1,4.29l-.6,1.319-.165.358a.513.513,0,0,1-.067.121.535.535,0,0,1,.041-.132l.141-.368c.125-.319.315-.777.56-1.339.487-1.124,1.2-2.659,2.071-4.315s1.727-3.115,2.376-4.154c.324-.52.592-.936.784-1.22l.223-.326A.509.509,0,0,1,310.944,401.255Z" transform="translate(-304.662 -401.255)" fill="#292e76"/>
            </g>
            <g id="Group_576" data-name="Group 576" transform="translate(9.184 2.605)">
              <path id="Path_1161" data-name="Path 1161" d="M323.728,406.105a.459.459,0,0,1-.124-.039l-.348-.138c-.3-.124-.732-.307-1.265-.539-1.065-.463-2.527-1.12-4.13-1.874s-3.043-1.462-4.077-1.987c-.518-.263-.935-.478-1.222-.631l-.328-.179a.463.463,0,0,1-.11-.071.43.43,0,0,1,.122.046l.34.155,1.241.59,4.094,1.949,4.111,1.911,1.246.578c.133.065.245.119.336.162A.469.469,0,0,1,323.728,406.105Z" transform="translate(-312.124 -400.647)" fill="#292e76"/>
            </g>
            <g id="Group_577" data-name="Group 577" transform="translate(14.073 6.194)">
              <path id="Path_1162" data-name="Path 1162" d="M328.169,406.566a.887.887,0,0,1-.076.169c-.062.12-.143.276-.242.469-.224.42-.534,1-.914,1.717-.774,1.446-1.847,3.444-2.977,5.677s-2.082,4.293-2.73,5.8c-.325.753-.582,1.366-.755,1.791l-.2.487a.981.981,0,0,1-.08.167.88.88,0,0,1,.053-.177l.179-.5c.157-.432.4-1.051.714-1.81.626-1.519,1.565-3.586,2.7-5.824s2.22-4.227,3.017-5.662l.952-1.7c.11-.189.2-.341.266-.456A.909.909,0,0,1,328.169,406.566Z" transform="translate(-320.19 -406.566)" fill="#292e76"/>
            </g>
            <g id="Group_578" data-name="Group 578" transform="translate(10.089 4.328)">
              <path id="Path_1163" data-name="Path 1163" d="M325.062,408.626c-.016.036-2.591-1.086-5.751-2.5s-5.709-2.6-5.694-2.633,2.59,1.086,5.751,2.5S325.079,408.59,325.062,408.626Z" transform="translate(-313.618 -403.488)" fill="#292e76"/>
            </g>
            <g id="Group_579" data-name="Group 579" transform="translate(10.012 6.268)">
              <path id="Path_1164" data-name="Path 1164" d="M315.81,407.725c-.024.035-.5-.276-1.141-.558s-1.188-.43-1.179-.471a4.36,4.36,0,0,1,2.32,1.029Z" transform="translate(-313.49 -406.688)" fill="#292e76"/>
            </g>
            <g id="Group_580" data-name="Group 580" transform="translate(9.516 6.655)">
              <path id="Path_1165" data-name="Path 1165" d="M315.065,408.338c-.025.035-.507-.3-1.167-.57s-1.232-.39-1.225-.432a4.005,4.005,0,0,1,2.392,1Z" transform="translate(-312.673 -407.327)" fill="#292e76"/>
            </g>
            <g id="Group_581" data-name="Group 581" transform="translate(9.402 7.156)">
              <path id="Path_1166" data-name="Path 1166" d="M314.831,409.312a13.038,13.038,0,0,1-1.171-.581,12.85,12.85,0,0,1-1.176-.573,5.938,5.938,0,0,1,2.347,1.154Z" transform="translate(-312.485 -408.153)" fill="#292e76"/>
            </g>
            <g id="Group_582" data-name="Group 582" transform="translate(13.75 7.264)">
              <path id="Path_1167" data-name="Path 1167" d="M320.042,408.44c0-.008.047-.039.148-.07a.866.866,0,0,1,.45-.016.615.615,0,0,1,.464.489.53.53,0,0,1-.121.4.84.84,0,0,1-.353.264.76.76,0,0,1-.442.042.689.689,0,0,1-.359-.209.6.6,0,0,1-.118-.661.554.554,0,0,1,.344-.3c.106-.028.167-.007.166,0s-.059.012-.147.052a.537.537,0,0,0-.272.285.493.493,0,0,0,.122.532.587.587,0,0,0,.654.127.569.569,0,0,0,.4-.521.517.517,0,0,0-.363-.409.934.934,0,0,0-.412-.027C320.1,408.437,320.045,408.449,320.042,408.44Z" transform="translate(-319.656 -408.331)" fill="#292e76"/>
            </g>
            <g id="Group_583" data-name="Group 583" transform="translate(15.415 8.241)">
              <path id="Path_1168" data-name="Path 1168" d="M325.036,411.081a5.714,5.714,0,0,1-1.362-.463,5.634,5.634,0,0,1-1.272-.672,10.558,10.558,0,0,1,1.328.543A10.339,10.339,0,0,1,325.036,411.081Z" transform="translate(-322.402 -409.943)" fill="#292e76"/>
            </g>
            <g id="Group_584" data-name="Group 584" transform="translate(15.143 8.618)">
              <path id="Path_1169" data-name="Path 1169" d="M323.158,411.147a1.231,1.231,0,0,1-.653-.179c-.341-.163-.57-.369-.548-.4a11.891,11.891,0,0,1,1.2.578Z" transform="translate(-321.954 -410.564)" fill="#292e76"/>
            </g>
            <g id="Group_585" data-name="Group 585" transform="translate(11.907 8.887)">
              <path id="Path_1170" data-name="Path 1170" d="M316.617,414.057a.623.623,0,0,1,.1.031l.272.1,1,.383,3.472,1.355-.079.027,1.811-3.076.063-.107.036.1-4.8-1.744.066-.022-1.944,2.95a5.366,5.366,0,0,1,.472-.836c.321-.524.794-1.27,1.378-2.176l.024-.037.042.015,4.811,1.717.08.029-.043.073-.063.106-1.822,3.07-.029.048-.051-.021-3.486-1.428-.978-.409-.255-.111A.38.38,0,0,1,316.617,414.057Z" transform="translate(-316.616 -411.008)" fill="#292e76"/>
            </g>
            <g id="Group_586" data-name="Group 586" transform="translate(10.398 12.828)">
              <path id="Path_1171" data-name="Path 1171" d="M314.513,417.617c0-.008.047-.039.148-.07a.873.873,0,0,1,.45-.016.615.615,0,0,1,.464.489.53.53,0,0,1-.121.4.844.844,0,0,1-.353.264.765.765,0,0,1-.442.041.691.691,0,0,1-.36-.209.6.6,0,0,1-.118-.661.552.552,0,0,1,.344-.3c.107-.029.167-.008.165,0s-.058.012-.147.052a.539.539,0,0,0-.272.286.5.5,0,0,0,.122.532.589.589,0,0,0,.655.126.569.569,0,0,0,.4-.521.517.517,0,0,0-.363-.409.934.934,0,0,0-.412-.027C314.572,417.614,314.516,417.627,314.513,417.617Z" transform="translate(-314.127 -417.509)" fill="#292e76"/>
            </g>
            <g id="Group_587" data-name="Group 587" transform="translate(12.063 13.804)">
              <path id="Path_1172" data-name="Path 1172" d="M319.508,420.259a5.682,5.682,0,0,1-1.362-.463,5.624,5.624,0,0,1-1.271-.672,10.9,10.9,0,0,1,1.327.543A10.391,10.391,0,0,1,319.508,420.259Z" transform="translate(-316.874 -419.12)" fill="#292e76"/>
            </g>
            <g id="Group_588" data-name="Group 588" transform="translate(11.792 14.181)">
              <path id="Path_1173" data-name="Path 1173" d="M317.63,420.324a1.225,1.225,0,0,1-.654-.179c-.34-.163-.57-.369-.547-.4a12.058,12.058,0,0,1,1.2.578Z" transform="translate(-316.426 -419.741)" fill="#292e76"/>
            </g>
            <g id="Group_589" data-name="Group 589" transform="translate(8.555 14.45)">
              <path id="Path_1174" data-name="Path 1174" d="M311.088,423.234a.6.6,0,0,1,.1.032l.272.1,1,.383,3.472,1.354-.079.027,1.811-3.076.063-.107.036.1-4.8-1.743.066-.022c-1.1,1.671-1.71,2.595-1.944,2.95a5.381,5.381,0,0,1,.472-.836c.322-.524.794-1.27,1.378-2.175l.024-.038.042.015,4.811,1.717.08.029-.043.073-.063.107-1.822,3.069-.029.048-.051-.021-3.486-1.428-.978-.409-.254-.111A.335.335,0,0,1,311.088,423.234Z" transform="translate(-311.087 -420.185)" fill="#292e76"/>
            </g>
            <g id="Group_590" data-name="Group 590" transform="translate(16.274 7.53)">
              <path id="Path_1175" data-name="Path 1175" d="M331.018,408.77c.035.018-1.548,3.212-3.536,7.134s-3.627,7.087-3.662,7.07,1.549-3.211,3.537-7.133S330.983,408.752,331.018,408.77Z" transform="translate(-323.819 -408.77)" fill="#292e76"/>
            </g>
            <g id="Group_591" data-name="Group 591" transform="translate(20.689 9.244)">
              <path id="Path_1176" data-name="Path 1176" d="M334.789,412.887A27.159,27.159,0,0,1,331.1,411.6a26.946,26.946,0,0,1,3.687,1.286Z" transform="translate(-331.102 -411.597)" fill="#292e76"/>
            </g>
            <g id="Group_592" data-name="Group 592" transform="translate(21.899 8.625)">
              <path id="Path_1177" data-name="Path 1177" d="M333.987,413.441a7.577,7.577,0,0,1-.881-2.864c.04-.007.188.647.432,1.435S334.025,413.425,333.987,413.441Z" transform="translate(-333.098 -410.577)" fill="#292e76"/>
            </g>
            <g id="Group_593" data-name="Group 593" transform="translate(21.722 12.402)">
              <path id="Path_1178" data-name="Path 1178" d="M332.845,418.069a2.871,2.871,0,1,1,.115-.626A1.476,1.476,0,0,1,332.845,418.069Z" transform="translate(-332.806 -416.807)" fill="#292e76"/>
            </g>
            <g id="Group_594" data-name="Group 594" transform="translate(21.226 12.957)">
              <path id="Path_1179" data-name="Path 1179" d="M333.433,417.8a4.547,4.547,0,0,1-.723.075,4.343,4.343,0,0,1-.721.092,1.846,1.846,0,0,1,1.445-.167Z" transform="translate(-331.988 -417.723)" fill="#292e76"/>
            </g>
            <g id="Group_595" data-name="Group 595" transform="translate(7.864 3.408)">
              <path id="Path_1180" data-name="Path 1180" d="M309.948,401.973a10.019,10.019,0,0,1,1.168.538,10.31,10.31,0,0,1,1.159.558,6.422,6.422,0,0,1-2.326-1.1Z" transform="translate(-309.947 -401.97)" fill="#292e76"/>
            </g>
            <g id="Group_596" data-name="Group 596" transform="translate(7.928 1.195)">
              <path id="Path_1181" data-name="Path 1181" d="M312.171,398.321a33.153,33.153,0,0,1-2.117,3.757,33.172,33.172,0,0,1,2.117-3.757Z" transform="translate(-310.052 -398.32)" fill="#292e76"/>
            </g>
            <g id="Group_597" data-name="Group 597" transform="translate(8.103 2.161)">
              <path id="Path_1182" data-name="Path 1182" d="M310.469,400.869c-.039,0-.09-.207-.114-.471s-.013-.48.025-.484.09.207.115.471S310.507,400.865,310.469,400.869Z" transform="translate(-310.342 -399.914)" fill="#292e76"/>
            </g>
            <g id="Group_598" data-name="Group 598" transform="translate(7.606 2.536)">
              <path id="Path_1183" data-name="Path 1183" d="M311.087,400.547a2.353,2.353,0,0,1-.769.223,2.3,2.3,0,0,1-.8.084,2.356,2.356,0,0,1,.769-.222A2.292,2.292,0,0,1,311.087,400.547Z" transform="translate(-309.522 -400.532)" fill="#292e76"/>
            </g>
          </g>
          <path id="Path_1184" data-name="Path 1184" d="M330.65,422.171a19.1,19.1,0,0,1,1.108-3.063c.569.007.195,1.616.195,1.616s1.882-2.729,2.282-2.451c.246.17-.663,1.791-.663,1.791s1.219-1.154,1.576-.945-1.029,1.826-1.029,1.826.958-.69,1.273-.469c.216.153-3.505,3.517-3.505,3.517Z" transform="translate(-284.702 -403.911)" fill="#f7c898"/>
          <path id="Path_1185" data-name="Path 1185" d="M330.65,422.171a19.1,19.1,0,0,1,1.108-3.063c.569.007.195,1.616.195,1.616s1.882-2.729,2.282-2.451c.246.17-.663,1.791-.663,1.791s1.219-1.154,1.576-.945-1.029,1.826-1.029,1.826.958-.69,1.273-.469c.216.153-3.505,3.517-3.505,3.517Z" transform="translate(-284.702 -403.911)" fill="#f7c898"/>
          <g id="Group_600" data-name="Group 600" transform="translate(24.776 87.161)">
            <path id="Path_1186" data-name="Path 1186" d="M297.359,538.373l.088,2.154-1.562.24-.063-2.376Z" transform="translate(-295.764 -538.373)" fill="#f7c898"/>
            <path id="Path_1187" data-name="Path 1187" d="M295.847,541.908s6.428-1.163,7.394-.343-7.515,1.49-7.515,1.49Z" transform="translate(-295.726 -539.515)" fill="#262668"/>
          </g>
          <g id="Group_601" data-name="Group 601" transform="translate(0 78.285)">
            <path id="Path_1188" data-name="Path 1188" d="M259.387,525.034l-1.794,1.195-1.017-1.209,2-1.288Z" transform="translate(-255.533 -523.731)" fill="#f7c898"/>
            <path id="Path_1189" data-name="Path 1189" d="M255.9,525.856s4.335,4.887,4.137,6.138-5.18-5.645-5.18-5.645Z" transform="translate(-254.856 -524.568)" fill="#262668"/>
          </g>
          <path id="Path_1190" data-name="Path 1190" d="M284.64,449.974s5.4,4.987,5.493,23.479-6.36,30.763-6.36,30.763h-4.547S286.683,481,278.3,465.6a60.291,60.291,0,0,1-17.648,31.662l-3.591-3.816s10.947-13.753,11.035-30.952c.017-3.407.587-5.131-.6-8.945Z" transform="translate(-255.726 -416.403)" fill="#ffc53b"/>
          <g id="Group_602" data-name="Group 602" transform="translate(27.03 66.507)">
            <path id="Path_1191" data-name="Path 1191" d="M303.583,504.444c-.007.083-.941.057-2.076.2s-2.037.382-2.063.3a6.654,6.654,0,0,1,4.139-.5Z" transform="translate(-299.444 -504.303)" fill="#141839"/>
          </g>
          <g id="Group_603" data-name="Group 603" transform="translate(27.166 65.144)">
            <path id="Path_1192" data-name="Path 1192" d="M304.259,502.062c.019.03-.186.211-.577.416a4.9,4.9,0,0,1-3.358.392c-.427-.109-.669-.239-.657-.272a7.5,7.5,0,0,0,4.592-.536Z" transform="translate(-299.667 -502.055)" fill="#141839"/>
          </g>
          <g id="Group_604" data-name="Group 604" transform="translate(12.742 60.466)">
            <path id="Path_1193" data-name="Path 1193" d="M277.992,497.156a3.669,3.669,0,0,1-2.1-2.818c.08-.024.333.736.917,1.508S278.038,497.086,277.992,497.156Z" transform="translate(-275.874 -494.338)" fill="#141839"/>
          </g>
          <g id="Group_605" data-name="Group 605" transform="translate(12.015 62.104)">
            <path id="Path_1194" data-name="Path 1194" d="M277.117,498.7c-.041.008-.1-.144-.3-.292s-.535-.327-.883-.538a5.615,5.615,0,0,1-1.256-.813,3.255,3.255,0,0,1,1.4.57,4.349,4.349,0,0,1,.877.635C277.158,498.481,277.154,498.7,277.117,498.7Z" transform="translate(-274.676 -497.04)" fill="#141839"/>
          </g>
          <g id="Group_606" data-name="Group 606" transform="translate(19.516 47.336)">
            <path id="Path_1195" data-name="Path 1195" d="M294.271,475.947a2.373,2.373,0,0,1-1.165-.172,21.314,21.314,0,0,1-2.569-1.175c-.981-.494-1.862-.955-2.493-1.3a4.811,4.811,0,0,1-1-.614,4.9,4.9,0,0,1,1.085.435c.654.3,1.549.735,2.531,1.228s1.85.98,2.509,1.24S294.275,475.9,294.271,475.947Z" transform="translate(-287.048 -472.68)" fill="#141839"/>
          </g>
          <path id="Path_1196" data-name="Path 1196" d="M269.466,417.947l3.16,24.473,19.879.257-2.97-18.435s9.826,9.326,14.111,5.46,4.981-7.711,4.981-7.711l-2.335-1.6s-3.551,6.76-6.778,4.634c-3.628-2.389-11.74-12.154-19.389-10.942C275.058,414.881,270.247,412.046,269.466,417.947Z" transform="translate(-260.609 -402.227)" fill="#211b4e"/>
          <g id="Group_607" data-name="Group 607" transform="translate(2.589)">
            <path id="Path_1197" data-name="Path 1197" d="M285.354,396.583l1.831,4.727a2.036,2.036,0,0,1-1.172,2.638l-.623.238.3,3.465-4.826.419-.849-9.777Z" transform="translate(-267.353 -395.379)" fill="#f7c898"/>
            <path id="Path_1198" data-name="Path 1198" d="M282.817,404.725s1.849-1.609,1.692-3.046c0,0-2.727-1.135-1.987-1.927s2.317.607,2.317.607-1.088-2.088-.847-2.676c0,0,3.534-.311,2.781-2.344a5.429,5.429,0,0,0-3.295-.74c-2.235.067-3.134,3.93-3.134,3.93Z" transform="translate(-267.482 -394.596)" fill="#1b1d1e"/>
            <path id="Path_1199" data-name="Path 1199" d="M275.473,394.867a6.749,6.749,0,0,0-8.462,7.094c.128,1.508.751,3.081.162,4.476a5.438,5.438,0,0,1-1.463,1.793,30.886,30.886,0,0,0-4.716,4.9,8.98,8.98,0,0,0-1.783,6.408,5.348,5.348,0,0,0,1.152,2.62c.329.388.723.72,1.012,1.137,1.032,1.492.452,3.576,1.085,5.275.812,2.182,3.369,3.157,5.685,3.392,3.178.322,6.6-.352,8.946-2.522s3.214-6.042,1.41-8.678c-.893-1.3-2.412-2.452-2.262-4.026a8.465,8.465,0,0,1,1.255-2.639,4.45,4.45,0,0,0-1.279-5.3c-.577-.439-1.263-.73-1.8-1.221a1.743,1.743,0,0,1-.575-1.944c.246-.5.824-.772,1.082-1.267a2.165,2.165,0,0,0-.627-2.269,4.105,4.105,0,0,1-1.3-2.09,3.321,3.321,0,0,1,.539-1.8q.876-1.663,1.75-3.326" transform="translate(-259.127 -394.617)" fill="#1b1d1e"/>
          </g>
        </g>
        <path id="Path_1200" data-name="Path 1200" d="M462.795,146.373h67.8s-3.6-18.544-21.54-14.036c0,0-13.308-37.522-26.3,0,0,0-11.155-18.592-19.963-18.708C443.758,113.38,438.187,146.373,462.795,146.373Z" transform="translate(-125.802 -20.632)" fill="#97ceeb"/>
        <path id="Path_1201" data-name="Path 1201" d="M176.2,92.02h63.751s-3.386-17.436-20.253-13.2c0,0-12.513-35.281-24.727,0,0,0-10.489-17.481-18.771-17.59C158.3,61,153.062,92.02,176.2,92.02Z" transform="translate(-13.335 0)" fill="#97ceeb"/>
        <path id="Path_1202" data-name="Path 1202" d="M411.567,78.849h36.48s-1.937-9.977-11.59-7.552c0,0-7.16-20.189-14.149,0,0,0-6-10-10.742-10.065C401.325,61.1,398.328,78.849,411.567,78.849Z" transform="translate(-108.619 0)" fill="#cddfee"/>
        <path id="Path_1203" data-name="Path 1203" d="M145.76,156.58h19.42s-1.031-5.311-6.17-4.02c0,0-3.812-10.748-7.532,0,0,0-3.2-5.325-5.718-5.358C140.307,147.13,138.711,156.58,145.76,156.58Z" transform="translate(-5.579 -33.852)" fill="#92d0f2"/>
        <path id="Path_1204" data-name="Path 1204" d="M343.97,143.721h36.48s-1.937-9.977-11.589-7.551c0,0-7.161-20.189-14.149,0,0,0-6-10-10.742-10.066C333.729,125.971,330.73,143.721,343.97,143.721Z" transform="translate(-82.001 -25.545)" fill="#cddfee"/>
        <g id="Group_612" data-name="Group 612" transform="translate(126.884 229.088)">
          <path id="Path_1205" data-name="Path 1205" d="M154,412.456c-7.044,5.343-18.451.475-24.769-4.995a6.682,6.682,0,0,1-2.216-3.645c-1.437-6.854,9.392-8.542,9.708-14.684.139-2.689-1.887-4.918-3.38-7.158-3.409-5.115-4.373-11.581-3.652-17.685s3.009-11.916,5.672-17.456c2.179-4.532,6.069-9.654,10.978-8.565,3.154.7,5.178,3.837,6.1,6.935a27.908,27.908,0,0,1,.232,13.194c-.375,1.931-.877,3.937-.357,5.834,1.33,4.858,8.145,5.957,10.617,10.346,2.516,4.469-.486,9.908-3.265,14.218-2.378,3.688-3.838,5.976-2.279,9.126a6.935,6.935,0,0,0,1.725,2.161C160.61,401.371,163.417,405.312,154,412.456Z" transform="translate(-126.884 -338.119)" fill="#55ae71"/>
          <g id="Group_609" data-name="Group 609" transform="translate(18.967 34.029)">
            <path id="Path_1206" data-name="Path 1206" d="M158.313,451.5c-.078,0-.141-12.818-.141-28.625s.063-28.628.141-28.628.141,12.815.141,28.628S158.39,451.5,158.313,451.5Z" transform="translate(-158.172 -394.251)" fill="#292e76"/>
          </g>
          <g id="Group_610" data-name="Group 610" transform="translate(6.959 62.206)">
            <path id="Path_1207" data-name="Path 1207" d="M149.59,449.371a2.158,2.158,0,0,1-.458-.322l-1.186-.949c-1-.805-2.378-1.911-3.927-3.1s-2.974-2.244-4.007-3l-1.221-.9a2.149,2.149,0,0,1-.429-.359,2.166,2.166,0,0,1,.49.269c.306.188.741.472,1.274.831,1.064.718,2.51,1.746,4.064,2.941s2.917,2.33,3.883,3.175c.483.423.869.771,1.129,1.018A2.175,2.175,0,0,1,149.59,449.371Z" transform="translate(-138.363 -440.73)" fill="#292e76"/>
          </g>
          <g id="Group_611" data-name="Group 611" transform="translate(18.449 40.731)">
            <path id="Path_1208" data-name="Path 1208" d="M165.738,405.307a14.342,14.342,0,0,1-1.2,1.553l-2.987,3.668-2.963,3.688a14.427,14.427,0,0,1-1.268,1.5,1.889,1.889,0,0,1,.274-.453c.191-.28.476-.679.836-1.167.719-.976,1.739-2.3,2.9-3.744s2.25-2.712,3.054-3.619c.4-.454.733-.816.968-1.062A1.969,1.969,0,0,1,165.738,405.307Z" transform="translate(-157.317 -405.306)" fill="#292e76"/>
          </g>
        </g>
        <g id="Group_613" data-name="Group 613" transform="translate(428.144 287.852)">
          <path id="Path_1209" data-name="Path 1209" d="M623.827,435.053" transform="translate(-623.827 -435.053)" fill="none" stroke="#5f609a" strokeMiterlimit="10" strokeWidth="0.05"/>
        </g>
        <g id="Group_619" data-name="Group 619" transform="translate(422.832 226.845)">
          <g id="Group_615" data-name="Group 615" transform="translate(22.66 31.476)">
            <path id="Path_1210" data-name="Path 1210" d="M669.219,432.325c-4.358,3.305-11.413.293-15.322-3.089a4.142,4.142,0,0,1-1.371-2.256c-.889-4.24,5.811-5.284,6.006-9.084.085-1.663-1.168-3.043-2.091-4.428a16.33,16.33,0,0,1-2.259-10.94,34.581,34.581,0,0,1,3.509-10.8c1.348-2.8,3.754-5.972,6.791-5.3,1.951.433,3.2,2.373,3.771,4.29a17.276,17.276,0,0,1,.144,8.162,8.008,8.008,0,0,0-.221,3.609c.823,3.006,5.039,3.685,6.568,6.4,1.557,2.763-.3,6.129-2.019,8.8-1.471,2.281-2.374,3.7-1.409,5.646a4.279,4.279,0,0,0,1.067,1.336C673.308,425.468,675.043,427.906,669.219,432.325Z" transform="translate(-652.444 -386.34)" fill="#72bd87"/>
            <g id="Group_614" data-name="Group 614" transform="translate(10.462 5.291)">
              <path id="Path_1211" data-name="Path 1211" d="M669.843,452.32c-.078,0-.141-12.817-.141-28.625s.063-28.628.141-28.628.141,12.815.141,28.628S669.921,452.32,669.843,452.32Z" transform="translate(-669.702 -395.067)" fill="#292e76"/>
            </g>
          </g>
          <path id="Path_1212" data-name="Path 1212" d="M642.182,408.755c-7.044,5.343-18.45.475-24.768-4.994a6.681,6.681,0,0,1-2.216-3.645c-1.437-6.855,9.392-8.542,9.708-14.685.139-2.688-1.887-4.918-3.38-7.158-3.408-5.115-4.373-11.581-3.653-17.685s3.009-11.917,5.673-17.456c2.178-4.532,6.07-9.654,10.978-8.565,3.154.7,5.178,3.837,6.095,6.935a27.906,27.906,0,0,1,.232,13.194c-.375,1.931-.877,3.936-.357,5.834,1.33,4.858,8.145,5.957,10.617,10.346,2.517,4.468-.486,9.908-3.265,14.218-2.378,3.688-3.837,5.976-2.279,9.127a6.949,6.949,0,0,0,1.726,2.159C648.791,397.671,651.6,401.613,642.182,408.755Z" transform="translate(-615.065 -334.418)" fill="#55ae71"/>
          <g id="Group_616" data-name="Group 616" transform="translate(18.967 34.028)">
            <path id="Path_1213" data-name="Path 1213" d="M646.494,447.8c-.078,0-.141-12.817-.141-28.626s.063-28.628.141-28.628.141,12.815.141,28.628S646.572,447.8,646.494,447.8Z" transform="translate(-646.353 -390.55)" fill="#292e76"/>
          </g>
          <g id="Group_617" data-name="Group 617" transform="translate(7.882 62.206)">
            <path id="Path_1214" data-name="Path 1214" d="M639.294,445.671a2.149,2.149,0,0,1-.458-.323l-1.186-.949c-1-.8-2.379-1.911-3.927-3.1s-2.973-2.244-4.007-3l-1.221-.9a2.159,2.159,0,0,1-.428-.359,2.129,2.129,0,0,1,.49.269c.306.189.741.472,1.273.831,1.064.718,2.511,1.747,4.064,2.941s2.916,2.329,3.883,3.174c.483.422.869.771,1.129,1.019A2.18,2.18,0,0,1,639.294,445.671Z" transform="translate(-628.067 -437.03)" fill="#292e76"/>
          </g>
          <g id="Group_618" data-name="Group 618" transform="translate(18.449 40.731)">
            <path id="Path_1215" data-name="Path 1215" d="M653.919,401.607a14.537,14.537,0,0,1-1.2,1.553l-2.987,3.668-2.963,3.688a14.382,14.382,0,0,1-1.268,1.5,1.951,1.951,0,0,1,.275-.452c.191-.281.477-.68.836-1.167.719-.976,1.739-2.305,2.9-3.744s2.25-2.713,3.054-3.62c.4-.453.732-.816.967-1.061S653.9,401.594,653.919,401.607Z" transform="translate(-645.497 -401.606)" fill="#292e76"/>
          </g>
        </g>
        <g id="Group_620" data-name="Group 620" transform="translate(156.027 201.827)">
          <ellipse id="Ellipse_105" data-name="Ellipse 105" cx="12.814" cy="12.814" rx="12.814" ry="12.814" fill="#211b4e"/>
          <path id="Path_1216" data-name="Path 1216" d="M204.882,329.32l6.235,1.942-.465-6.409Z" transform="translate(-186.741 -305.634)" fill="#211b4e"/>
          <text id="_" data-name="¡" transform="translate(15.077 12.517) rotate(180)" fill="#f3eee6" fontSize="15" fontFamily="Helvetica"><tspan x="0" y="0">¡</tspan></text>
        </g>
        <g id="Group_623" data-name="Group 623" transform="translate(421.125 90.143)">
          <g id="Group_621" data-name="Group 621" transform="translate(0 1.267)">
            <path id="Path_1217" data-name="Path 1217" d="M612.255,116.815a10.5,10.5,0,0,1,1.4-.455l4.086-1.192c3.522-1.014,8.563-2.446,14.5-4.075l.3-.082.081.3c.961,3.529,2.012,7.387,3.112,11.426.053-.086-.444.771-.237.409l-.005,0-.011,0-.022.006-.045.012-.089.024-.18.048-.358.1-.714.192-1.414.381-2.773.746L624.6,126.07l-9.184,2.424-.25.066-.062-.245c-.884-3.486-1.613-6.357-2.147-8.462-.223-.922-.4-1.675-.548-2.273a3.88,3.88,0,0,1-.15-.765,6.466,6.466,0,0,1,.282.832c.181.6.408,1.364.687,2.3l2.368,8.24-.312-.179,9.155-2.533,5.275-1.442,2.771-.753,1.413-.384.714-.193.358-.1.179-.048.09-.024.045-.012.023-.006.011,0h.006c.21-.364-.279.494-.229.4-1.086-4.043-2.122-7.906-3.069-11.438l.378.217c-5.82,1.55-10.81,2.829-14.38,3.733l-4.2,1.048A15.23,15.23,0,0,1,612.255,116.815Z" transform="translate(-612.25 -111.011)" fill="#272669"/>
          </g>
          <g id="Group_622" data-name="Group 622" transform="translate(0.005 1.644)">
            <path id="Path_1218" data-name="Path 1218" d="M632.321,111.635a20.075,20.075,0,0,1-2.233,2.9c-1.444,1.738-3.473,4.109-5.745,6.7a3.112,3.112,0,1,1-.359.095h0l-.01,0-.019-.006-.038-.013-.076-.025-.153-.051-.3-.1-.6-.2-1.174-.4-2.216-.762-3.74-1.327a20.015,20.015,0,0,1-3.392-1.382,20.039,20.039,0,0,1,3.54.938l3.785,1.192,2.228.726,1.177.389.6.2.3.1.153.051.076.025.038.013.019.006.009,0,0,0c-.156.043.358-.094-.35.1,2.272-2.592,4.356-4.914,5.89-6.573A20.03,20.03,0,0,1,632.321,111.635Z" transform="translate(-612.257 -111.633)" fill="#272669"/>
          </g>
          <path id="Path_1219" data-name="Path 1219" d="M646.8,110.544A2.195,2.195,0,1,1,644.112,109,2.2,2.2,0,0,1,646.8,110.544Z" transform="translate(-624.158 -108.922)" fill="#f08922"/>
        </g>
        <g id="Group_626" data-name="Group 626" transform="translate(146.576 153.925)">
          <g id="Group_624" data-name="Group 624" transform="translate(0 0)">
            <path id="Path_1220" data-name="Path 1220" d="M162.669,214.138a10.456,10.456,0,0,1,1.392.3l4,1c3.443.872,8.365,2.135,14.13,3.671l.289.076-.078.29c-.919,3.423-1.924,7.167-2.976,11.085.086-.046-.749.429-.4.227l-.005,0-.011,0-.022-.005-.044-.012-.087-.024-.173-.047-.347-.094-.692-.187-1.369-.372-2.686-.73-5.114-1.4-8.873-2.456-.242-.067.068-.236c.962-3.351,1.755-6.111,2.336-8.133.264-.88.48-1.6.652-2.171a3.861,3.861,0,0,1,.248-.714,6.056,6.056,0,0,1-.17.835l-.546,2.257c-.508,2-1.2,4.736-2.043,8.054l-.173-.3,8.9,2.351,5.121,1.37,2.687.723,1.371.37.691.186.347.094.174.047.087.023.044.012.021.006.011,0,.005,0c.353-.2-.475.278-.389.225,1.067-3.914,2.086-7.655,3.018-11.075l.211.367c-5.629-1.546-10.432-2.912-13.863-3.9l-4.025-1.173A14.464,14.464,0,0,1,162.669,214.138Z" transform="translate(-159.366 -214.133)" fill="#272669"/>
          </g>
          <g id="Group_625" data-name="Group 625" transform="translate(3.3 0.002)">
            <path id="Path_1221" data-name="Path 1221" d="M184.259,219.4a19.47,19.47,0,0,1-3.288,1.34c-2.058.75-4.914,1.744-8.082,2.8a3.178,3.178,0,1,1-.347-.1l0,0-.007-.007-.013-.015-.026-.029-.052-.059-.1-.117-.2-.234-.4-.463-.788-.907-1.483-1.72-2.482-2.938a19.513,19.513,0,0,1-2.165-2.814,19.457,19.457,0,0,1,2.506,2.515L169.9,219.5l1.511,1.7.8.9.406.461.2.233.1.117.052.059L173,223l.013.015.007.007,0,0a2.878,2.878,0,1,1-.34-.09c3.168-1.06,6.046-1.985,8.142-2.626A19.423,19.423,0,0,1,184.259,219.4Z" transform="translate(-164.809 -214.137)" fill="#272669"/>
          </g>
        </g>
        <g id="Group_638" data-name="Group 638" transform="translate(418.155 159.246)">
          <g id="Group_627" data-name="Group 627" transform="translate(1.253 1.249)">
            <path id="Path_1222" data-name="Path 1222" d="M644.157,242.409a26.748,26.748,0,0,0-.631-4.188,16.507,16.507,0,0,0-1.924-4.55,17.064,17.064,0,0,0-20.492-7.251,16.006,16.006,0,0,0-4.3,2.321,15.577,15.577,0,0,0-3.595,3.614,16.937,16.937,0,0,0,0,20.107,15.587,15.587,0,0,0,3.6,3.616,16,16,0,0,0,4.3,2.321,17.116,17.116,0,0,0,20.493-7.252,16.492,16.492,0,0,0,1.923-4.55,26.737,26.737,0,0,0,.631-4.188,1.161,1.161,0,0,1,.011.279c0,.213-.01.484-.018.823a8.05,8.05,0,0,1-.112,1.333,12.179,12.179,0,0,1-.329,1.8,16.3,16.3,0,0,1-1.873,4.642,17.387,17.387,0,0,1-11.706,8.264,17.477,17.477,0,0,1-9.173-.73,16.4,16.4,0,0,1-4.429-2.369,15.96,15.96,0,0,1-3.7-3.706,17.373,17.373,0,0,1,0-20.671,15.986,15.986,0,0,1,3.7-3.706A16.4,16.4,0,0,1,620.958,226a17.475,17.475,0,0,1,9.172-.73,17.389,17.389,0,0,1,11.706,8.263,16.321,16.321,0,0,1,1.874,4.643,12.26,12.26,0,0,1,.329,1.8,8.05,8.05,0,0,1,.112,1.333c.007.339.013.61.018.823A1.165,1.165,0,0,1,644.157,242.409Z" transform="translate(-609.418 -224.972)" fill="#272669"/>
          </g>
          <g id="Group_628" data-name="Group 628">
            <path id="Path_1223" data-name="Path 1223" d="M644.592,241.6a1.384,1.384,0,0,1-.04-.3c-.018-.224-.04-.513-.069-.877a9.3,9.3,0,0,0-.167-1.416,13.62,13.62,0,0,0-.4-1.9,17.67,17.67,0,0,0-2.062-4.883,18.312,18.312,0,0,0-21.991-7.786,17.165,17.165,0,0,0-4.621,2.49,16.688,16.688,0,0,0-3.859,3.88,18.344,18.344,0,0,0-3.551,10.791,19.526,19.526,0,0,0,.94,5.762,17.685,17.685,0,0,0,2.609,5.033,20.779,20.779,0,0,0,1.788,2.118,20.453,20.453,0,0,0,2.071,1.763,17.165,17.165,0,0,0,4.622,2.491,18.364,18.364,0,0,0,21.993-7.788,17.7,17.7,0,0,0,2.061-4.883,13.6,13.6,0,0,0,.4-1.9,9.282,9.282,0,0,0,.167-1.415c.029-.364.051-.654.069-.877a1.4,1.4,0,0,1,.04-.3,1.319,1.319,0,0,1,.01.3c-.005.225-.012.516-.02.881a8.61,8.61,0,0,1-.122,1.43,13.154,13.154,0,0,1-.355,1.928,17.517,17.517,0,0,1-2.01,4.976,18.645,18.645,0,0,1-12.549,8.854,18.735,18.735,0,0,1-9.83-.784,17.563,17.563,0,0,1-4.746-2.539A17.132,17.132,0,0,1,611,252.672a18.142,18.142,0,0,1-2.684-5.162,19.988,19.988,0,0,1-.968-5.915A18.784,18.784,0,0,1,611,230.522a21.207,21.207,0,0,1,1.84-2.168,20.978,20.978,0,0,1,2.128-1.8,17.579,17.579,0,0,1,4.744-2.538,18.73,18.73,0,0,1,9.828-.784,18.642,18.642,0,0,1,12.55,8.853,17.485,17.485,0,0,1,2.011,4.977,13.147,13.147,0,0,1,.355,1.928,8.619,8.619,0,0,1,.122,1.429c.008.366.015.657.02.882A1.315,1.315,0,0,1,644.592,241.6Z" transform="translate(-607.351 -222.911)" fill="#272669"/>
          </g>
          <g id="Group_629" data-name="Group 629" transform="translate(18.501 1.438)">
            <path id="Path_1224" data-name="Path 1224" d="M638.113,228.863c-.135,0-.244-.8-.244-1.79s.109-1.79.244-1.79.243.8.243,1.79S638.247,228.863,638.113,228.863Z" transform="translate(-637.869 -225.284)" fill="#272669"/>
          </g>
          <g id="Group_630" data-name="Group 630" transform="translate(6.893 6.109)">
            <path id="Path_1225" data-name="Path 1225" d="M621.019,235.754c-.1.086-.7-.463-1.328-1.223s-1.058-1.448-.954-1.534.7.462,1.328,1.223S621.123,235.669,621.019,235.754Z" transform="translate(-618.722 -232.988)" fill="#272669"/>
          </g>
          <g id="Group_631" data-name="Group 631" transform="translate(28.615 27.831)">
            <path id="Path_1226" data-name="Path 1226" d="M656.851,271.586c-.1.085-.7-.463-1.329-1.223s-1.057-1.448-.954-1.534.7.462,1.329,1.223S656.954,271.5,656.851,271.586Z" transform="translate(-654.553 -268.82)" fill="#272669"/>
          </g>
          <g id="Group_632" data-name="Group 632" transform="translate(32.413 18.444)">
            <path id="Path_1227" data-name="Path 1227" d="M660.818,253.578c0-.134.8-.243,1.79-.243s1.79.109,1.79.243-.8.243-1.79.243S660.818,253.712,660.818,253.578Z" transform="translate(-660.818 -253.335)" fill="#272669"/>
          </g>
          <g id="Group_633" data-name="Group 633" transform="translate(1.416 18.444)">
            <path id="Path_1228" data-name="Path 1228" d="M609.687,253.578c0-.134.8-.243,1.789-.243s1.79.109,1.79.243-.8.243-1.79.243S609.687,253.712,609.687,253.578Z" transform="translate(-609.687 -253.335)" fill="#272669"/>
          </g>
          <g id="Group_634" data-name="Group 634" transform="translate(18.501 32.356)">
            <path id="Path_1229" data-name="Path 1229" d="M638.113,279.863c-.135,0-.244-.8-.244-1.79s.109-1.79.244-1.79.243.8.243,1.79S638.247,279.863,638.113,279.863Z" transform="translate(-637.869 -276.284)" fill="#272669"/>
          </g>
          <g id="Group_635" data-name="Group 635" transform="translate(5.521 27.424)">
            <path id="Path_1230" data-name="Path 1230" d="M619.224,268.164c.086.1-.463.7-1.223,1.328s-1.448,1.057-1.534.954.463-.7,1.224-1.328S619.139,268.06,619.224,268.164Z" transform="translate(-616.458 -268.148)" fill="#272669"/>
          </g>
          <g id="Group_636" data-name="Group 636" transform="translate(28.384 6.34)">
            <path id="Path_1231" data-name="Path 1231" d="M656.939,233.385c.085.1-.463.7-1.224,1.328s-1.448,1.057-1.534.954.462-.7,1.223-1.328S656.853,233.281,656.939,233.385Z" transform="translate(-654.172 -233.369)" fill="#272669"/>
          </g>
          <g id="Group_637" data-name="Group 637" transform="translate(17.509 9.239)">
            <path id="Path_1232" data-name="Path 1232" d="M644.214,250.461a11.537,11.537,0,0,1-2.2.152c-1.379.041-3.314.072-5.533.084h-.236v-.238c0-.731,0-1.5-.006-2.284,0-2.769.027-5.275.071-7.089a17.65,17.65,0,0,1,.172-2.935,17.59,17.59,0,0,1,.172,2.935c.044,1.814.071,4.32.071,7.089,0,.788,0,1.553-.007,2.284l-.236-.236c2.219.013,4.154.043,5.533.085A11.472,11.472,0,0,1,644.214,250.461Z" transform="translate(-636.233 -238.152)" fill="#272669"/>
          </g>
        </g>
        <g id="Group_639" data-name="Group 639" transform="translate(224.764 107.73)">
          <path id="Path_1233" data-name="Path 1233" d="M291.994,138.112a6.273,6.273,0,0,0-1.112.252,3.446,3.446,0,0,0-2.095,2.218,7.929,7.929,0,0,0-.131,2.257c.007.842.014,1.748.021,2.711a13.89,13.89,0,0,0,.047,1.467,3.291,3.291,0,0,0,3.27,2.815h.2l0,.2c-.009.887-.019,1.835-.029,2.808l-.343-.161,3.273-2.815.061-.052h.081l5.793,0h1.483a4.419,4.419,0,0,0,1.387-.135,3.263,3.263,0,0,0,2.33-2.929c.021-.434.007-.909.013-1.354q0-1.353.007-2.647a6.029,6.029,0,0,0-.241-2.409,3.367,3.367,0,0,0-1.468-1.643,3.85,3.85,0,0,0-2.061-.408l-2.046-.015-6.174-.071-1.678-.041a2.94,2.94,0,0,1-.586-.045,2.933,2.933,0,0,1,.586-.045l1.678-.041,6.174-.071,2.046-.014a4.2,4.2,0,0,1,2.236.427,3.736,3.736,0,0,1,1.653,1.817,3.592,3.592,0,0,1,.279,1.275c.015.433.007.849.011,1.283q0,1.293.007,2.647c0,.457.013.894-.007,1.38a3.693,3.693,0,0,1-2.634,3.326,4.817,4.817,0,0,1-1.512.154h-1.483l-5.793,0,.141-.052-3.285,2.8-.351.3.008-.46c.016-.972.032-1.921.048-2.808l.2.2a3.65,3.65,0,0,1-3.614-3.163,13.591,13.591,0,0,1-.031-1.516c.009-.963.015-1.869.022-2.711a7.969,7.969,0,0,1,.191-2.327,3.535,3.535,0,0,1,2.279-2.265A2.524,2.524,0,0,1,291.994,138.112Z" transform="translate(-288.343 -137.932)" fill="#272669"/>
        </g>
        <path id="Path_1234" data-name="Path 1234" d="M246.1,485.216l99.55,51.258H522.094l.424-17.049L443.239,487.3Z" transform="translate(-46.943 -166.954)" fill="#6e70af" opacity="0.06"/>
      </g>
    </svg>
    
    );
};

EventSvg.propTypes = {
    
};

export default EventSvg;