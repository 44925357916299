import React from "react";

import { Grid, Paper } from "@material-ui/core";
import Container from "@material-ui/core/Container";

class NotFound extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            {/* Chart */}
            <Grid item xs={12} md={8} lg={9}>
              <Paper>Not Found</Paper>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}

NotFound.propTypes = {};

export default NotFound;
