import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import { useToasts } from "../../ToastManager";
import { getUserData } from "../../Actions/EditProfileActions";
import NavContainer from "../NavContainer";
import { Header } from "../Layout";

const classes = (theme) => {
  return {
    childContainerCss: {
      flexGrow: 1,
      overflow: "auto",
      marginLeft: "240px",
      marginTop: "48px",
      width: `calc(100% - 240px)`,
      padding: theme.spacing(2, 2),
      minHeight: "calc(100vh - 84px)",
    },
    childContainerSecurityCss: {
      flexGrow: 1,
      overflow: "auto",
      marginTop: "48px",
      width: "100%",
      minHeight: "calc(100vh - 84px)",
    },
    childPaper: {
      padding: theme.spacing(2),
      height: "100%",
      minHeight: "inherit",
    },
  };
};

const PrivateRoute = (props) => {
  // eslint-disable-next-line no-shadow
  const { component: Component, auth, classes, paperType, ...rest } = props;
  const [loading, setLoading] = useState(false);
  const toastManager = useToasts();

  useEffect(() => {
    const { type, text, show } = props.notifications;
    if (show) toastManager.addToast({ type, text });
  }, [props.notifications]);

  useEffect(() => {
    props.getUserData();
  }, []);

  return auth.isAuthenticated === true ? (
    <Route
      {...rest}
      // eslint-disable-next-line no-shadow
      render={(props) => {
        const role = localStorage.getItem("role");
        return (
          <div>
            <Header loading={loading} />
            <NavContainer />
            <div>
              <div
                className={
                  role === "security"
                    ? classes.childContainerSecurityCss
                    : classes.childContainerCss
                }
              >
                {paperType && paperType === "noPaper" ? (
                  <Component {...props} setLoading={setLoading} />
                ) : (
                  <Paper className={classes.childPaper}>
                    <Component {...props} setLoading={setLoading} />
                  </Paper>
                )}
              </div>
            </div>
          </div>
        );
      }}
    />
  ) : (
    <Redirect to="/login" />
  );
};

PrivateRoute.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  auth: PropTypes.object.isRequired,
};
const mapStateToProp = (state) => {
  return {
    auth: state.auth,
    notifications: state.notifications.notification,
  };
};

// export default connect(mapStateToProp)(PrivateRoute);

export default connect(mapStateToProp, { getUserData })(
  withStyles(classes)(PrivateRoute)
);

// export default withStyles(classes)(PrivateRoute);
