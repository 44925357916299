/* eslint-disable react/no-unused-state */
/* eslint-disable no-param-reassign */
import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { capitalize } from '@material-ui/core'
import { Form } from 'react-formik-ui'
import InputField from '../../Components/Common/Form/InputField'
import { SelectField } from '../../Components/Common/Form'
import { addvisitorbywalkin } from '../../Actions/securityVisitorActions'
// import CompaniesDropdown from "../../Components/Common/Dropdowns/CompaniesDropdown";
// import CoworkersDropdown from "../../Components/Common/Dropdowns/coworkersDropdown";
import { getCoworker } from '../../Actions/coworkerActions'
import { getCompanies } from '../../Actions/companiesAction'

import SuccessPopup from './SuccessPopup'
import Header from '../../Components/Layout/Header'

const $quoteSym = ' '

const styles = (theme) => ({
  lightbg: {
    padding: '20px 30px',
    backgroundColor: '#fff',
  },
  main: {
    backgroundColor: '#ffffff',
  },
  label: {
    // fontSize: "12px",
    // fontWeight: "700"
    fontSize: '1rem',
    fontWeight: 'normal',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  button: {
    marginRight: '25px',
    width: '105px',
    height: '46px',
    color: '#151039',
    backgroundColor: '#dddddd',
    '&:hover': {
      color: '#151039',
      backgroundColor: '#d1d1d1!important',
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: '15px',
    },
  },
  Addbutton: {
    width: '105px',
    height: '46px',
    marginRight: '25px',
    backgroundColor: '#211b4e !important',
    '&:hover': {
      backgroundColor: '#151039 !important',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '8px',
      marginRight: '28px',
    },
  },
  visitor_date: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  visitor_duration: {
    display: 'flex',
  },
  formfooter: {
    margin: '24px 0px',
    [theme.breakpoints.down('sm')]: {
      margin: '24px 0px',
    },
  },
  subtitle: {
    fontSize: '18px',
    marginTop: '20px',
    marginBottom: '17px',
    [theme.breakpoints.down('sm')]: {
      margin: '24px 0px',
      paddingTop: '10px',
    },
  },
  meeting_details: {
    width: '100%',
    // padding: "14px"
  },
  coworker_visitor: {
    padding: '14px',
  },
  durationHour: {
    marginRight: '18px',
  },
  content_center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  screenheader: {
    fontSize: '16px',
    fontWeight: '600',
    position: 'relative',
    '&:after': {
      top: '24px',
      left: '0px',
      width: '24px',
      borderBottom: '3px solid #fb0',
      content: `'${$quoteSym}'`,
      position: 'absolute',
    },
  },
  input: {
    marginTop: '8px',
  },
  mainNewVisitor: {
    padding: '10px 40px',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 44px',
      paddingBottom: '11%',
    },
  },
})

const PurposOptions = [
  { value: 'official', label: 'Official' },
  { value: 'meeting', label: 'Meeting' },
  { value: 'interview', label: 'Interview' },
  { value: 'space_inquiry', label: 'Space Inquiry' },
  { value: 'accelerator_inquiry', label: 'Accelerator Inquiry' },
  { value: 'vendor_meeting', label: 'Vendor Meeting' },
]
const VisitorSchema = Yup.object().shape({
  visitor_name: Yup.string()
    .max(15, '')
    .matches(/^.[a-zA-Z0-9 ]+$/, 'Enter Valid Name')
    .trim()
    .required('This field is required.'),
  email: Yup.string().required().email('Enter valid email id'),
  contact: Yup.number()
    .typeError('Please input a number.')
    .required('This field is required.'),
  company_id: Yup.string()
    .typeError('Enter Company Name')
    .required('This field is required.'),
  // coworker_id: Yup.string().required("Please enter name of person meeting"),
  purpose: Yup.string().required('This field is required.'),
  no_of_visitors: Yup.number()
    .typeError('Please input a number.')
    .required('This field is required.'),
  visitor_company_name: Yup.string().trim().required('This field is required.'),
})

class NewVisitorForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      successPopup: false,
    }
  }

  state = {
    // eslint-disable-next-line react/no-unused-state
    errors: {},
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.companyData !== prevState.companyData) {
      return {
        companies: nextProps.companyData,
      }
    }
    return []
  }

  componentDidMount() {
    this.props.getCompanies('', '', 'company')
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.securityVisitor.securityVisitorWalkin !==
        this.props.securityVisitor.securityVisitorWalkin &&
      this.props.securityVisitor.securityVisitorWalkin.success
    ) {
      this.openSuccessPopup()
      // this.props.handleClose();
    }
  }

  openSuccessPopup = () => {
    this.setState({ successPopup: true })
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleClose = () => {
    this.props.history.push('/dashboard')
  }

  closeSuccessPopup = () => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    this.setState({ successPopup: !this.state.successPopup })
    this.props.history.push('/dashboard')
  }

  callCoworkersAPI = (name, valueObj) => {
    const companyId = valueObj.value
    this.setState({
      // eslint-disable-next-line react/no-unused-state
      company_data: companyId,
    })
    // this.state.company_data = companyId;
    this.props.getCoworker(companyId)
  }

  getCompaniesOptions = (companies) => {
    const FilterCompanyData = companies.filter(function (activeCompanydata) {
      return activeCompanydata.status !== 'inactive'
    })
    return FilterCompanyData
      ? FilterCompanyData.map((company) => {
          return {
            value: company._id,
            label: capitalize(company.display_name || company.company_name),
          }
        })
      : []
  }

  getInitialValues = (id) => {
    const { visitor } = this.props
    if (id) {
      return {
        visitor_name: visitor.name,
        visitor_company_name: visitor.visitor_company_name,
        contact: visitor.contact,
        email: visitor.email,
        no_of_visitors: visitor.no_of_visitors,
        // name_of_person_meeting: visitor.name_of_person_meeting,
        purpose: visitor.purpose,
        company_id: visitor.company_id,
        security_number: visitor.security_number,
      }
    }
    return this.props.location.state && this.props.location.state.visitor
      ? {
          visitor_name: this.props.location.state.visitor.visitor_name,
          visitor_company_name: this.props.location.state.visitor
            .visitor_company_name,
          contact: this.props.location.state.visitor.contact
            ? this.props.location.state.visitor.contact
            : this.props.location.state,
          email: this.props.location.state.visitor.email,
          // name_of_person_meeting: "",
          purpose: '',
          company_id: this.props.location.state.visitor.company_id,
          security_number: '',
          coworker_id: '',
        }
      : {
          visitor_name: '',
          visitor_company_name: '',
          contact: this.props.location.state,
          email: '',
          no_of_visitors: '',
          // name_of_person_meeting: "",
          purpose: '',
          company_id: '',
          security_number: '',
          coworker_id: '',
        }
  }

  inputEntered(inputText) {
    if (inputText.length >= 3) {
      this.setState({ options_show: true })
    } else {
      this.setState({ options_show: false })
    }
  }

  render() {
    const { classes } = this.props
    const { id, companies } = this.state

    return (
      <main>
        <Formik
          initialValues={this.getInitialValues(id)}
          enableReinitialize
          validationSchema={VisitorSchema}
          onSubmit={(values, { setErrors, setSubmitting, resetForm }) => {
            const companyVal = values.company_id ? values.company_id.value : ''
            values.name_of_person_company = companyVal
            const companyData = values.company_id ? values.company_id.value : ''
            values.company_id = companyData
            const memberVal = values.coworker_id
              ? values.coworker_id.value
              : null
            values.name_of_person_meeting = memberVal
            const valPurpose = values.purpose ? values.purpose.value : ''
            values.purpose = valPurpose
            values.type_of_invitation = 'walkin'
            this.props.addvisitorbywalkin(
              values,
              setErrors,
              setSubmitting,
              this.props.history
            )
            resetForm(this.getInitialValues())
          }}
        >
          {({
            values,
            setFieldValue,
            setFieldTouched,
            errors,
            touched,
            handleSubmit,
          }) => (
            <Form
              className={classes.form}
              onSubmit={handleSubmit}
              encType="multipart/form-data"
              autoComplete="off"
              style={{
                background: '#ffffff',
              }}
            >
              <Header />
              <div className={classes.mainNewVisitor}>
                <SuccessPopup
                  data={this.state.successPopup}
                  handleClose={this.closeSuccessPopup}
                />
                <Typography
                  variant="h6"
                  className={classes.subtitle}
                  style={{ marginTop: '8%' }}
                >
                  <span className={classes.screenheader} align="left">
                    My Details
                  </span>
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <InputField
                      className={classes.input}
                      label="Name"
                      name="visitor_name"
                      placeholder="Enter Name"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <InputField
                      className={classes.input}
                      label="Your Company Name"
                      name="visitor_company_name"
                      placeholder="Enter Company Name"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <InputField
                      className={classes.input}
                      name="contact"
                      label="Contact No"
                      placeholder="Enter Contact No"
                      type="tel"
                      max="10"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <InputField
                      className={classes.input}
                      name="email"
                      label="Email Id"
                      placeholder="Enter Email Id"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <InputField
                      className={classes.input}
                      name="no_of_visitors"
                      label="No. of Visitors"
                      number
                      placeholder="Enter no. of visitors"
                    />
                  </Grid>
                </Grid>
                <div>
                  <Typography
                    variant="h6"
                    className={classes.subtitle}
                    style={{ marginTop: '4%' }}
                  >
                    <span className={classes.screenheader} align="left">
                      Meeting Details
                    </span>
                  </Typography>

                  <Grid container spacing={3}>
                    <Grid item xs={6} sm={6}>
                      <SelectField
                        value={values.company_id}
                        name="company_id"
                        id="company_id"
                        label="Company"
                        onInputChange={(e) => this.inputEntered(e)}
                        onChange={setFieldValue}
                        placeholder="Enter Company Name"
                        openMenuOnClick={false}
                        onBlur={setFieldTouched}
                        multi={false}
                        error={errors.company_id}
                        touched={touched.company_id}
                        isClearable
                        options={
                          this.state.options_show
                            ? this.getCompaniesOptions(companies)
                            : []
                        }
                      />
                    </Grid>
                  </Grid>
                  {/* <Grid item xs={12} sm={12}>
                      <SelectField
                        value={values.company_id}
                        name="company_id"
                        id="company_id"
                        label="Company"
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        multi={false}
                        error={errors.company_id}
                        touched={touched.company_id}
                        // options={PurposOptions}
                      />
                      <CompaniesDropdown
                        values={values}
                        setFieldValue={(name, value) => {
                          setFieldValue(name, value);
                          this.callCoworkersAPI(name, value);
                        }}
                        setFieldTouched={setFieldTouched}
                        errors={errors.company_id}
                        touched={touched}
                        name="company_id"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SelectField
                        value={values.coworker_id}
                        name="coworker_id"
                        id="coworker_id"
                        label="Coworker"
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        multi={false}
                        error={errors.coworker_id}
                        touched={touched.coworker_id}
                        // options={PurposOptions}
                      />
                      <CoworkersDropdown
                        values={values}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        errors={errors.coworker_id}
                        touched={touched}
                        name="coworker_id"
                        onCompany
                      />
                    </Grid>
                     */}
                  <Grid item xs={6} sm={6}>
                    <SelectField
                      value={values.purpose}
                      name="purpose"
                      id="purpose"
                      label="Purpose"
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      multi={false}
                      error={errors.purpose}
                      touched={touched.purpose}
                      options={PurposOptions}
                    />
                  </Grid>

                  {/* <Grid item xs={6} sm={6}>
                      <InputField
                        className={classes.input}
                        name="security_number"
                        label="Visitor Id"
                        placeholder="Enter Visitor Id"
                        style={{ marginTop: "5%" }}
                      />
                    </Grid> */}
                  {/* TODO */}
                </div>
                <div className={classes.formfooter}>
                  <Grid
                    container
                    item
                    display="flex"
                    justify="flex-end"
                    alignItems="center"
                    direction="row"
                  >
                    <Button
                      color="grey"
                      onClick={this.handleClose}
                      className={classes.button}
                      variant="contained"
                    >
                      Cancel
                    </Button>

                    <Button
                      variant="contained"
                      className={classes.Addbutton}
                      color="primary"
                      type="submit"
                    >
                      {id ? 'Update' : 'Submit'}
                    </Button>
                  </Grid>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </main>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    securityVisitor: state.securityVisitor,
    errors: state.errors,
    companyData: state.company.companies,
  }
}

export default connect(mapStateToProps, {
  addvisitorbywalkin,
  getCoworker,
  getCompanies,
})(withStyles(styles)(NewVisitorForm))
