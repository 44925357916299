/* eslint-disable camelcase */
import React from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { Typography, Grid } from "@material-ui/core";
import classNames from "classnames";

const styles = () => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "12px"
  },
  mainTitle: {
    fontWeight: "600",
    fontSize: "20px",
    textAlign: "left",
    color: "#222222",
    lineHeight: "25px"
  },
  heading: {
    fontSize: "16px",
    color: "#222222",
    fontWeight: "500",
    margin: "4px 0px",
    marginBottom: "15px"
  },
  title: {
    fontSize: "13px",
    color: "#222222",
    fontWeight: "500",
    padding: "10px"
  },
  subTitle: {
    fontSize: "15px",
    color: "#222222",
    fontWeight: "500"
  },
  phone: {
    color: "#1592E6",
    fontWeight: "500"
  },
  description: {
    color: "#222222",
    fontSize: "13px",
    lineHeight: "18px"
  },
  generalDetails: {
    padding: "3% 0% 0%"
  },
  cardLayout: {
    border: "1px solid #EFEFEF",
    borderRadius: "5px",
    padding: "10px"
  },
  padding: {
    padding: "10px"
  }
});

function AccountingService(props) {
  const { classes } = props;

  return (
    <div className={classes.main}>
      <div className={classes.header}>
        <Typography align="left" variant="h1" className={classes.mainTitle}>
          Accounting Service
        </Typography>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Typography align="left" className={classes.description}>
            Accounts and Finance are at the core of any business. Having your
            primary functions in place will help you in the long run. We at DevX
            will help you effectively manage your accounting and compliance to
            help you gain credibility and peace of doing business hassle-free. A
            compliant business can stand a better chance of raising funds
            through investors and bank. Leave your account worries to us;
          </Typography>
          <Grid container spacing={2} className={classes.generalDetails}>
            <Grid item xs={12}>
              <Typography align="left" className={classes.subTitle}>
                Our services include;
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  Accounting <br />
                  Services
                  <br />
                  <br />
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  Taxation Services <br />
                  <br />
                  <br />
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  Auditing Services <br />
                  <br />
                  <br />
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  Dedicate Accounts/
                  <br />
                  Finance personnel <br />
                  (Full/Part-time)
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  Management Consulting (Finance & Accounting)
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  Project Financing
                  <br />
                  <br />
                  <br />
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography align="left" className={classes.description}>
                Our proactive, professional and flexible services will be
                tailor-made to suit your needs.
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2} className={classes.generalDetails}>
            <Typography
              align="left"
              className={classNames(classes.mainTitle, classes.padding)}
            >
              Why us?
            </Typography>
            <Grid item xs={12}>
              <Typography align="left" className={classes.description}>
                Our professional services in this digital age are upbeat with
                the most recent industry tools to aid in accounting and finance.
                <br />
                <br />
                We are a one-stop-shop for all your accounting and finance
                related requirements.
                <br />
                <br />
                Our team of experts can help you in your business growth by
                effectively managing your compliance and money.
                <br />
                <br />
                We help you make finance ready.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    loading: state.spaces.loading
  };
};

export default connect(
  mapStateToProps,
  {}
)(withStyles(styles)(AccountingService));
