import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { Grid, Button } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { getAnnouncement } from "../../Actions/AnnouncementActions";
import AddAnnouncement from "./AddAnnouncement";
import banner from "../../Assets/Announcement_banner.png";
import AnnouncementCard from "../../Components/Common/Cards/AnnouncementCard";

const $quoteSym = " ";
const styles = () => ({
  main: {
    backgroundColor: "#FCFDFE"
  },
  avatar: {
    height: "25px",
    width: "25px"
  },
  share: {
    margin: "10px 0px"
  },
  about: {
    margin: "10px 0px",
    fontWeight: 500,
    fontSize: "18px",
    paddingBottom: "5px",
    position: "relative",
    "&:after": {
      top: "31px",
      left: "1px",
      width: "24px",
      borderBottom: "3px solid #fb0",
      content: `'${$quoteSym}'`,
      position: "absolute"
    }
  },
  list_text: {
    fontSize: "13px",
    color: "#626262"
  },
  banner: { maxHeight: "300px" },
  addAnnouncement: {
    margin: "35px 0px"
  },
  noPostPaper: {
    height: "200px",
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center"
  },
  noPosts: {
    textAlign: "center",
    display: "block",
    // marginTop: "20%",
    color: "lightgrey"
  },
  backButton: {
    margin: "0% 0% 2% 0%"
  }
});
export class Announcement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // event: {},
      announcements: []
    };
    this.state.id = this.props.match.params.id;
  }

  componentDidMount() {
    this.props.getAnnouncement();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.announcements !== prevState.announcements) {
      return { announcements: nextProps.announcements };
    }
    return [];
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.announcement !== prevProps.announcement &&
      this.props.announcement.success
    ) {
      this.props.getAnnouncement();
    }
    if (
      this.props.delete_announcement !== prevProps.delete_announcement &&
      this.props.delete_announcement.success
    ) {
      this.props.getAnnouncement();
    }
    if (
      this.props.edit_announcement !== prevProps.edit_announcement &&
      this.props.edit_announcement.success
    ) {
      this.props.getAnnouncement();
    }
    if (
      this.props.announcement_comment !== prevProps.announcement_comment &&
      this.props.announcement_comment.success
    ) {
      this.props.getAnnouncement();
    }
  }

  render() {
    const { classes } = this.props;
    const { announcements } = this.state;
    return (
      <React.Fragment>
        <CssBaseline />
        <main className={classes.main}>
          <Button
            color="grey"
            onClick={() => this.props.history.push("/settings")}
            className={classes.backButton}
            variant="contained"
            size="small"
          >
            Back
          </Button>
          <div className={classes.main}>
            <div className={classes.banner}>
              <img
                src={banner}
                alt=""
                width="100%"
                className={classes.banner}
              />
            </div>

            <div className={classes.inner_section}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                className={classes.headerSpacing}
              >
                <Grid item sm={8} lg={8} justify="center">
                  <div className={classes.addAnnouncement}>
                    <AddAnnouncement id={this.state.id} />
                  </div>
                  {announcements && announcements.length <= 0 && (
                    <Paper className={classes.noPostPaper}>
                      <Typography variant="h6" className={classes.noPosts}>
                        No announcements to show!!
                      </Typography>
                    </Paper>
                  )}
                  {announcements &&
                    announcements.map(announcement => {
                      return (
                        <AnnouncementCard
                          id={announcement._id}
                          data={announcement}
                        />
                      );
                    })}
                </Grid>
              </Grid>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    event: state.events.event,
    event_participant: state.events.event_participant,
    announcements: state.announcements.announcements,
    announcement: state.announcements.announcement,
    delete_announcement: state.announcements.delete_announcement,
    edit_announcement: state.announcements.edit_announcement,
    announcement_comment: state.announcements.announcement_comment
  };
};

export default connect(mapStateToProps, {
  getAnnouncement
})(withStyles(styles)(Announcement));
