/* eslint-disable camelcase */
/* eslint-disable react/no-unused-state */
import React from "react";
import {
  Grid,
  InputAdornment,
  withStyles,
  Typography,
  Button,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { Form } from "react-formik-ui";

import FormLabel from "@material-ui/core/FormLabel";
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { CircularLoader } from "../../Components/Common/Spinner";
import {
  Instagram,
  Linkedin,
  Twitter,
  FacebookRoundIcon,
} from "../../Assets/index";
import { getRoletypes } from "../../Actions/roleTypeActions";
import { setEditorState } from "../../Utils";
import {
  BloodGroupOptions,
  IndustryOptions,
  ClienttypeOptions,
} from "../../Utils/dropDownData";
import {
  addCompany,
  updateCompany,
  getCompanies,
  getCompany,
} from "../../Actions/companiesAction";
import InputField from "../../Components/Common/Form/InputField";
import UploadField from "../../Components/Common/Form/UploadField";
import DatePickerField from "../../Components/Common/Form/DatePickerField";
import SelectField from "../../Components/Common/Form/SelectField";
import { updateCoworker } from "../../Actions/coworkerActions";
import RoleTypesDropdown from "../../Components/Common/Dropdowns/RoleTypesDropdown";
import RichEditorExample from "../../Components/Common/RichEditor";
import ServicelistDropDown from "../../Components/Common/Dropdowns/ServiceDropdown";

const $quoteSym = " ";

const styles = () => ({
  lightbg: {
    backgroundColor: "#fff",
    padding: "10px 22px",
  },
  label: {
    margin: "15px 0px 8px 0px",
    fontSize: "12px",
    fontWeight: "700",
  },
  Addbutton: {
    width: "148px",
    height: "46px",
    marginLeft: "-5%",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  margin: {
    margin: "17px 8px 8px",
    marginLeft: "0px",
    width: "100%",
  },
  social: {
    height: "20px",
    width: "20px",
  },
  datePickerInput: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    padding: "4px",
    "& > div": {
      marginBottom: "8px",
    },
  },
  formfooter: {
    margin: "30px 0px",
    marginRight: "-3%",
  },
  cancelbutton: {
    width: "148px",
    height: "46px",
    color: "#151039",
    backgroundColor: "#dddddd",
    marginRight: "20px",
    "&:hover": {
      color: "#151039",
      backgroundColor: "#d1d1d1!important",
    },
  },
  form: {
    marginLeft: "4px",
  },
  applicationHeader: {
    margin: "32px 8px 18px",
    paddingBottom: "5px",
    fontSize: "16px",
    fontWeight: "500",
    position: "relative",
    width: "100%",
    "&:after": {
      top: "31px",
      left: "1px",
      width: "24px",
      borderBottom: "3px solid #fb0",
      content: `'${$quoteSym}'`,
      position: "absolute",
    },
  },
  contract_main: {
    display: "block",
  },
  contract: {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px 10px",
  },
  durationHour: {
    marginRight: "18px",
  },
  uploadSection: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  displayLayout: {
    display: "flex",
  },
  objname: {
    marginTop: "31px",
  },
});
const phoneRegExp = /[1-9]{1}[0-9]{9}/;

const FreelancerSchema = Yup.object().shape({
  first_name: Yup.string().trim().required("This field is required."),
  last_name: Yup.string().trim().required("This field is required."),
  email: Yup.string()
    .email("Invalid email address")
    .required("This field is required."),
  contact_number: Yup.string()
    .matches(phoneRegExp, "Please enter valid number.")
    .min(10, "Please enter valid number.")
    .required("This field is required."),
  roletypeid: Yup.string().required("This field is required."),
  client_type: Yup.string().required("This field is required."),
  website: Yup.string().url("Invalid url address"),
  // banner_image: Yup.mixed().required("This field is required."),
  // eslint-disable-next-line func-names
  description: Yup.mixed().test("match", "This field is required", function () {
    const finalContent =
      this.parent &&
      this.parent.editorState.getCurrentContent().getPlainText() !== "";
    if (finalContent === true) {
      return true;
    }
    return false;
  }),
});

class FreelancerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id:
        this.props.match && this.props.match.params
          ? this.props.match.params.id
          : "",
      roletypes: [],
      companies: [],
      deletedKey: [],
      status: "",
    };
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    this.props.getCompanies();
    this.props.getRoletypes();
    if (this.state.id) {
      this.props.getCompany(this.state.id, "freelancer");
    }
  }

  shouldComponentUpdate() {
    if (this.state.deletedKey.length > 0) {
      return false;
    }
    return true;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.companies !== prevState.companies) {
      return { companies: nextProps.companies };
    }
    if (nextProps.company !== prevState.company) {
      return { company: nextProps.company };
    }
    if (nextProps.roletypes !== prevState.roletypes) {
      return { roletypes: nextProps.roletypes };
    }
    if (nextProps.coworker !== prevState.coworker) {
      return {
        coworker: nextProps.coworker,
        loading: nextProps.coworker.loading,
      };
    }
    if (nextProps.errors) {
      return { errors: nextProps.errors };
    }
    return [];
  }

  componentDidUpdate(prevProps) {
    if (prevProps.companies !== this.props.companies) {
      this.setState({
        companies: this.props.companies,
      });
    }
  }

  handleClose = () => {
    const path = "/freelancers";
    this.props.history.push(path);
  };

  handleClick = (type) => {
    this.setState({
      status: type,
    });
  };

  getAvailableCompanyOptions = (companies) => {
    return (
      companies &&
      companies.map((company) => {
        return {
          value: company._id,
          label: company.display_name || company.company_name,
        };
      })
    );
  };

  getAvailableRoletypesOptions = (roletypes) => {
    return roletypes
      ? roletypes.map((roletype) => {
          return {
            value: roletype._id,
            label: roletype.name,
          };
        })
      : [];
  };

  formatData = (values) => {
    const formated = { ...values };
    // formated.created_by = localStorage.getItem("user_id");
    // formated.space = localStorage.getItem("space_id");
    // formated.user_id = localStorage.setItem("userId");
    const val = formated.blood_group ? formated.blood_group.value : "";
    formated.blood_group = val;
    const val2 = formated.roletypeid ? formated.roletypeid.value : "";
    formated.roletypeid = val2;
    const service =
      formated.services && formated.services.map((skl) => skl && skl.label);
    formated.services = service;
    const industryVal = formated.industry ? formated.industry.value : "";
    formated.industry = industryVal;
    const clientType = formated.client_type ? formated.client_type.value : "";
    formated.client_type = clientType;
    formated.description = stateToHTML(values.editorState.getCurrentContent());
    delete formated.editorState;
    formated.type = "freelancer";
    if (formated.draft === true) formated.freelancer_status = "draft";
    else formated.freelancer_status = "active";
    delete formated.draft;
    return formated;
  };

  stripHtml = (html) => {
    // Create a new div element
    const temporalDivElement = document.createElement("div");
    // Set the HTML content with the providen
    temporalDivElement.innerHTML = html;
    // Retrieve the text property of the element (cross-browser support)
    return temporalDivElement.textContent || temporalDivElement.innerText || "";
  };

  getInitialValues = (id) => {
    const { company } = this.props;
    const { roletypes } = this.state;
    const check_description =
      this.stripHtml(company.description) === "fill description";
    const company_description =
      company.description === "fill description" || check_description
        ? ""
        : company.description;
    if (id) {
      const role = company && company.user && company.user.role;
      const bloodGroup = company && company.user && company.user.blood_group;
      const clientType =
        company && company.client_type ? company.client_type : "";
      const industry = company && company.industry;
      return {
        first_name: company.user && company.user.first_name,
        last_name: company.user && company.user.last_name,
        profile_pic: company.user && company.user.profile_pic,
        email: company.user && company.user.email,
        contact_number: company.user && company.user.contact_number,
        date_of_birth:
          company.user && company.user.date_of_birth === null
            ? new Date()
            : company.user && company.user.date_of_birth,
        blood_group: BloodGroupOptions.filter(
          (option) => option.value === bloodGroup
        )[0],
        // blood_group: { label: "A+", value: "a+" },
        industry: IndustryOptions.filter(
          (option) => option.value === industry
        )[0],
        client_type: ClienttypeOptions.filter(
          (option) => option.value === clientType
        )[0],
        services: company.services,
        website: company.website || "",
        facebook: company.facebook,
        linkedin: company.linkedin,
        twitter: company.twitter,
        instagram: company.instagram,
        title: company.title,
        description: company_description,
        editorState: setEditorState(company_description),
        contract_start_date: company.contract_start_date || new Date(),
        contract_end_date: company.contract_end_date || new Date(),
        contract_upload_contract: company.contract_upload_contract,
        roletypeid: this.getAvailableRoletypesOptions(roletypes).filter(
          (option) => option.value === role
          // coworker.setup.join().includes(option.value)
        )[0],
        banner_image: company.banner_image,
        // roletypeid: coworker.role
      };
    }
    return {
      first_name: "",
      last_name: "",
      profile_pic: "",
      email: "",
      contact_number: "",
      designation: "",
      date_of_birth: new Date(),
      blood_group: [],
      website: "",
      facebook: "",
      linkedin: "",
      twitter: "",
      instagram: "",
      banner_image: "",
      roletypeid: "",
      description: "",
      services: [],
      industry: "",
      title: "",
      client_type: "",
      contract_start_date: new Date(),
      contract_end_date: new Date(),
      contract_upload_contract: "",
      // eslint-disable-next-line new-cap
      editorState: new EditorState.createEmpty(),
    };
  };

  setDelImageUrl = (url) => {
    const { deletedKey } = this.state;
    this.setState({ deletedKey: deletedKey.push(url) });
  };

  render() {
    const { classes } = this.props;
    const { id, loading } = this.state;
    return (
      <React.Fragment>
        <div className={classes.lightbg}>
          <Typography variant="h6" gutterBottom style={{ marginLeft: "4px" }}>
            {id ? "Update" : "Add"} Freelancer
          </Typography>
          <Formik
            initialValues={this.getInitialValues(id)}
            enableReinitialize={!!id}
            validationSchema={FreelancerSchema}
            onSubmit={(values, { setErrors, setSubmitting }) => {
              const data = this.formatData(values);
              if (!this.state.id) {
                this.props.addCompany(
                  data,
                  this.props.history,
                  setErrors,
                  setSubmitting
                );
              } else {
                this.props.updateCompany(
                  this.state.id,
                  data,
                  this.props.history,
                  setSubmitting
                );
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              setFieldTouched,
              setFieldValue,
              handleSubmit,
              submitForm,
              handleBlur,
            }) => (
              <Form
                className={classes.form}
                onSubmit={handleSubmit}
                encType="multipart/form-data"
                autoComplete="off"
              >
                <Grid container spacing={2}>
                  <span className={classes.applicationHeader}>
                    Personal Information
                  </span>
                  <Grid item xs={6} sm={6}>
                    <InputField
                      name="first_name"
                      label="Full Name"
                      placeholder="Enter First Name"
                      max={15}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} style={{ marginTop: "16px" }}>
                    <InputField
                      name="last_name"
                      label=""
                      placeholder="Enter Last Name"
                      max={15}
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    className={classes.uploadSection}
                    spacing={2}
                    direction="column"
                  >
                    <Grid item xs={6} sm={6}>
                      <UploadField
                        label="Upload Profile Photo"
                        name="profile_pic"
                        id="profile_pic"
                        onChange={setFieldValue}
                        value={values.profile_pic}
                        error={errors.profile_pic}
                        folder="profile/picture"
                        delImageUrl={this.setDelImageUrl}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <InputField
                      name="email"
                      label="Email Id"
                      placeholder="johndoe@mail.com"
                      inputProps={{
                        readOnly: !!id,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <InputField
                      name="contact_number"
                      label="Contact No"
                      placeholder="91XXXXXXXX"
                      type="tel"
                      max="10"
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} className={classes.datePickerInput}>
                    <DatePickerField
                      name="date_of_birth"
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      value={values.date_of_birth}
                      disableFuture
                      label="Birth Date"
                    />
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <SelectField
                      value={values.blood_group}
                      name="blood_group"
                      id="blood_group"
                      label="Blood Group"
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      multi={false}
                      error={errors.blood_group}
                      touched={touched.blood_group}
                      options={BloodGroupOptions}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <InputField
                      name="website"
                      label="Website"
                      placeholder="http://www.example.com"
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <InputField
                      className={classes.margin}
                      name="linkedin"
                      label="Linkedin"
                      placeholder="Enter Linkedin Profile Link"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src={Linkedin}
                              alt=""
                              className={classes.social}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <InputField
                      className={classes.margin}
                      name="facebook"
                      label="Facebook"
                      placeholder="Enter Facebook Profile Link"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src={FacebookRoundIcon}
                              alt=""
                              className={classes.social}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <InputField
                      className={classes.margin}
                      name="twitter"
                      label="Twitter"
                      placeholder="Enter Twitter Profile Link"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src={Twitter}
                              alt=""
                              className={classes.social}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <InputField
                      className={classes.margin}
                      name="instagram"
                      label="Instagram"
                      placeholder="Enter Instagram Profile Link"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src={Instagram}
                              alt=""
                              className={classes.social}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <span className={classes.applicationHeader}>
                    Professional Information
                  </span>

                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={6}>
                      <UploadField
                        name="banner_image"
                        label="Upload Banner "
                        id="banner_image"
                        onChange={setFieldValue}
                        value={values.banner_image}
                        folder="freelancer/banner"
                        delImageUrl={() => this.setDelImageUrl}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} style={{ paddingBottom: "0px" }}>
                    <FormLabel className={classes.label} component="legend">
                      Description
                    </FormLabel>
                    <RichEditorExample
                      name="description"
                      editorState={values.editorState}
                      onChange={setFieldValue}
                      onBlur={handleBlur}
                      placeholder="Type your text"
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <SelectField
                      value={values.industry}
                      name="industry"
                      id="industry"
                      label="Industry"
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      multi={false}
                      error={errors.industry}
                      options={IndustryOptions}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <ServicelistDropDown
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      errors={errors}
                      touched={touched}
                      name="services"
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <InputField
                      name="title"
                      label="Title"
                      placeholder="Enter Title"
                      className={classes.objname}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <SelectField
                      value={values.client_type}
                      name="client_type"
                      id="client_type"
                      label="Type of Client"
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      multi={false}
                      error={errors.client_type}
                      options={ClienttypeOptions}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <RoleTypesDropdown
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      errors={errors}
                      touched={touched}
                      name="roletypeid"
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <UploadField
                      label="Upload Contract"
                      name="contract_upload_contract"
                      id="contract_upload_contract"
                      onChange={setFieldValue}
                      value={values.contract_upload_contract}
                      upload="company/contract"
                      fileType="doc"
                      folder="company/contract"
                      delImageUrl={this.setDelImageUrl}
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    className={classes.contract_main}
                    spacing={2}
                  >
                    <div className={classes.contract}>
                      <Grid item xs={6} sm={6} className={classes.durationHour}>
                        <DatePickerField
                          name="contract_start_date"
                          onChange={setFieldValue}
                          onBlur={setFieldTouched}
                          value={values.contract_start_date}
                          disableFuture={false}
                          label=" Contract Start Date"
                          style={{ width: "95%" }}
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <DatePickerField
                          name="contract_end_date"
                          onChange={setFieldValue}
                          onBlur={setFieldTouched}
                          value={values.contract_end_date}
                          disableFuture={false}
                          minDate={values.contract_start_date}
                          label="Contract End Date"
                        />
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
                <div className={classes.formfooter}>
                  <Grid container xs={12} sm={12}>
                    <Grid container xs={6}>
                      <Button
                        color="grey"
                        className={classes.cancelbutton}
                        variant="contained"
                        onClick={async () => {
                          setFieldValue("draft", true);
                          // setFieldValue("isInitialValid", true);
                          // isValid= true;
                          // const { formik } = this.context;
                          // formik.setSubmitting(true);
                          await Promise.resolve();
                          submitForm();
                        }}
                        // isInitialValid
                      >
                        Save Draft
                      </Button>
                    </Grid>
                    <Grid container xs={6} spacing={2} justify="flex-end">
                      <Grid item>
                        <Button
                          color="grey"
                          onClick={this.handleClose}
                          className={classes.cancelbutton}
                          variant="contained"
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          className={classes.Addbutton}
                          color="primary"
                          type="submit"
                          onClick={async () => {
                            setFieldValue("draft", false);
                          }}
                        >
                          {id ? "Update" : "Add"}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
                {loading && <CircularLoader />}
              </Form>
            )}
          </Formik>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companies: state.company.companies,
    company: state.company.company,
    auth: state.auth,
    errors: state.errors,
    roletypes: state.roletypes.roletypes,
    coworker: state.coworkers.coworker,
  };
};

export default connect(mapStateToProps, {
  addCompany,
  getCompanies,
  updateCompany,
  updateCoworker,
  getCompany,
  getRoletypes,
})(withStyles(styles)(withRouter(FreelancerForm)));
