import {
  ADD_NOTIFICATION,
  //   GET_NOTIFICATION,
  NOTIFICATION_LOADING,
  GET_NOTIFICATIONS,
  CREATE_NOTIFICATION,
  GET_SINGLE_NOTIFICATION,
  // NOTIFICATION_ERROR
} from "../Actions/types";

const initialState = {
  notification: {},
  loading: false,
  notifications: [],
  singleNotification: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      //   return Object.assign({}, state, {});
      return {
        ...state,
        notification: { ...action.payload, show: true },
      };
    case CREATE_NOTIFICATION:
      return {
        ...state,
        notification: { success: true, data: action.payload },
      };
    case GET_NOTIFICATIONS:
      return {
        ...state,
        loading: false,
        notifications: {
          data: action.payload && action.payload.data,
        },
      };
    case GET_SINGLE_NOTIFICATION:
      return {
        ...state,
        loading: false,
        singleNotification: {
          data: action.payload && action.payload.data,
        },
      };
    case NOTIFICATION_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};
