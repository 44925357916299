/* eslint-disable camelcase */
import React from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { Typography, Grid } from "@material-ui/core";
import { spacecapitalize } from "../../Utils/StringFunctions";
import DataNotFound from "../../Components/Common/DataNotFound";

const styles = () => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "12px",
  },
  mainTitle: {
    fontWeight: "600",
    fontSize: "20px",
    textAlign: "left",
    color: "#222222",
    margin: "0px",
    lineHeight: "25px",
  },
  title: {
    fontSize: "13px",
    color: "#222222",
    fontWeight: "500",
  },
  phone: {
    color: "#358CEE",
    paddingTop: "10px",
  },
  description: {
    color: "#222222",
    fontSize: "11px",
    lineHeight: "13px",
  },
  generalDetails: {
    // padding: "3% 0% 0%"
  },
  cardLayout: {
    border: "1px solid #EFEFEF",
    borderRadius: "5px",
    boxShadow: "0 9px 10px rgba(0, 0, 0, 0.1), 0 4px 0px rgba(0,0,0,0.05)",
    padding: "10px",
  },
});

function DevxTeam(props) {
  const { classes, data } = props;
  if (data === undefined || data.length <= 0) {
    return (
      <div className={classes.noData}>
        <DataNotFound
          type="visitors"
          text="No results found!"
          subtext=""
          paddingTop="22%"
        />
      </div>
    );
  }
  const renderRoleName = (name) => {
    if (name === "communitymanager")
      return (
        <Typography align="left" className={classes.title}>
          Community Manager
        </Typography>
      );
    // eslint-disable-next-line no-else-return
    else if (name === "it")
      return (
        <Typography align="left" className={classes.title}>
          IT Support
        </Typography>
      );
    else if (name === "admin")
      return (
        <Typography align="left" className={classes.title}>
          Admin
        </Typography>
      );
    else return "-";
  };
  console.log("dataaa", data.length);
  return (
    <div className={classes.main}>
      <div className={classes.header}>
        <Typography align="left" variant="h1" className={classes.mainTitle}>
          DevX&apos;s Team
        </Typography>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Grid container spacing={2}>
            {data &&
              data.map((element) => {
                return (
                  <Grid item xs={6} className={classes.generalDetails}>
                    <Grid className={classes.cardLayout}>
                      <Typography align="left" className={classes.title}>
                        {/* Community Manager */}
                        {/* {element.role &&
                          element.role.roletype_id &&
                          element.role.roletype_id.name} */}
                        {renderRoleName(
                          element.role &&
                            element.role.roletype_id &&
                            element.role.roletype_id.name
                        )}
                      </Typography>
                      <Typography align="left" className={classes.description}>
                        {spacecapitalize(
                          `${element.first_name} ${element.last_name}`
                        )}
                      </Typography>
                      <Typography align="left" className={classes.phone}>
                        {element.contact_number}
                      </Typography>
                      <Typography align="left" className={classes.description}>
                        {element.email}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.spaces.loading,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(DevxTeam));
