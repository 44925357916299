import React, { useState, useEffect } from "react";
import { withStyles, Typography, Grid } from "@material-ui/core";
import moment from "moment";
import booking from "../../Assets/NoBooking.png";

const $quoteSym = " ";
const styles = () => ({
  booking_section: {
    border: "1px solid #efefef",
    padding: "10px",
    height: "240px",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.03)",
    borderRadius: "4px",
    background: "#fff",
  },
  nobooking_cont: {
    color: "lightgrey",
    padding: "14px 0px 0px",
  },
  noBooking: {
    // paddingBottom: "20px",
    width: "300px",
    // height: "100%",
  },
  booking_name: {
    fontSize: "14px",
    lineHeight: "16px",
    color: "#626262",
    fontWeight: "400",
    textTransform: "capitalize",
  },
  screenheader: {
    fontSize: "16px",
    fontWeight: "600",
    position: "relative",
    "&:after": {
      top: "24px",
      left: "0px",
      width: "24px",
      borderBottom: "3px solid #fb0",
      content: `'${$quoteSym}'`,
      position: "absolute",
    },
  },
  bookingImage: {
    textAlign: "center",
    height: "200px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  booking_cont: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #efefef",
    padding: "15px 5px",
    color: "lightgrey",
    paddingLeft: "0px",
  },
  dateFonts: {
    fontSize: "12px",
  },
});

function Booking(props) {
  const { classes } = props;
  const [bookings, setBookings] = useState([]);

  useEffect(() => {
    if (props.bookings.data && props.bookings.data.length > 0) {
      setBookings(props.bookings.data);
    }
  }, [props.bookings]);

  if (bookings && bookings.length <= 0) {
    return (
      <Grid container className={classes.booking_section}>
        <Grid item xs={12} lg={12} sm={12}>
          <Typography
            variant="subtitle2"
            className={classes.nobooking_cont}
            align="center"
          >
            No Bookings!
          </Typography>
        </Grid>
        <Grid item xs={12} lg={12} sm={12} className={classes.bookingImage}>
          <img alt="logo" src={booking} className={classes.noBooking} />
        </Grid>
      </Grid>
    );
  }

  return (
    <div className={classes.booking_section}>
      <span className={classes.screenheader} align="left">
        Bookings
      </span>
      {bookings &&
        bookings.map((item, i) => {
          if (i > 2) {
            return "";
          }
          const endTime = moment(item.end).format("LT");
          const end = moment(endTime, "LT").add(1, "minutes").format("LT");
          return (
            <div className={classes.booking_cont}>
              <Typography
                variant="subtitle2"
                className={classes.booking_name}
                align="left"
              >
                {item.resource_id && item.resource_id.resource_name}
                <br />
                <span className={classes.dateFonts}>
                  {moment(item.start).format("LT")} to{" "}
                  {/* {moment(item.end).format("LT")} */}
                  {end}
                </span>
              </Typography>
              <Typography
                align="right"
                variant="subtitle1"
                className={classes.booking_name}
              >
                {moment(item.start).format("DD/MM/YYYY")}
              </Typography>
            </div>
          );
        })}
    </div>
  );
}

export default withStyles(styles)(Booking);
