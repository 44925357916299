/* eslint-disable camelcase */
import axios from "axios";
import {
  GET_RESOURCE_CATEGORY,
  RESOURCE_CATEGORY_LOADING,
  GET_ERRORS,
  ADD_RESOURCE_CATEGORY,
  ADD_RESOURCE_CATEGORY_ERROR,
  EDIT_RESOURCE_CATEGORY,
  EDIT_RESOURCE_CATEGORY_ERRORS,
  GET_SINGLE_RESOURCE_CATEGORY,
} from "./types";
import { showSuccess, showError } from "./ActionsHelper";
import { transformErrorsFromApi } from "./TransformError";

//  Loading
export const setResourcecategoryLoading = () => {
  return {
    type: RESOURCE_CATEGORY_LOADING,
  };
};

export const addresourcecategory = (
  userData,
  setErrors,
  history,
  setSubmitting
) => (dispatch) => {
  dispatch(setResourcecategoryLoading());
  axios
    .post("/api/resourcetype", userData)
    .then((result) => {
      dispatch({
        type: ADD_RESOURCE_CATEGORY,
        payload: result.data,
      });
      // history.push("/resource-categories");
      dispatch(showSuccess("Resource Category"));
      setSubmitting(false);
    })
    .catch((err) => {
      dispatch({
        type: ADD_RESOURCE_CATEGORY_ERROR,
        payload: err && err.response ? err.response.data.data : "",
      });
      dispatch(showError(err.response.data.data));
    });
};

export const getResourcecategory = () => (dispatch) => {
  const space_id = localStorage.getItem("space_id");
  dispatch(setResourcecategoryLoading());
  axios
    .get(`/api/resourcetype/${space_id}`)
    .then((res) => {
      dispatch({
        type: GET_RESOURCE_CATEGORY,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data,
      })
    );
};

//GET SINGLE RESOURCE TYPE
export const getSingleResourcecategory = (id) => (dispatch) => {
  dispatch(setResourcecategoryLoading());
  axios
    .get(`/api/resourcetype/getsingle/${id}`)
    .then((res) => {
      dispatch({
        type: GET_SINGLE_RESOURCE_CATEGORY,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data,
      })
    );
};

export const editResourceCategory = (
  resource_id,
  data,
  setErrors,
  setSubmitting
) => (dispatch) => {
  dispatch(setResourcecategoryLoading());
  axios
    .put(`/api/resourcetype/${resource_id}`, data)
    .then((result) => {
      dispatch({
        type: EDIT_RESOURCE_CATEGORY,
        payload: result.data,
      });
      dispatch(showSuccess("", "Resource Category edited successfully!"));
      setSubmitting(false);
    })
    .catch((err) => {
      const error = err.response;
      dispatch({ type: EDIT_RESOURCE_CATEGORY_ERRORS, payload: {} });
      dispatch(showError(err.response.data.data));
      setSubmitting(false);
      setErrors(transformErrorsFromApi(error));
    });
};
