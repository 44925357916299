import {
  CHECK_VISITOR,
  CREATE_VISITOR_BY_WALKIN,
  CREATE_VISITOR_BY_WALKTHROUGH,
  VISITOR_LOADING
} from "../Actions/types";

const initialState = {
  securityVisitor: {},
  securityVisitorWalkin: {},
  securityVisitorWalkthrough: {},
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CHECK_VISITOR:
      return {
        ...state,
        securityVisitor: { success: true, data: action.payload.data },
        securityVisitorWalkthrough: {},
        securityVisitorWalkin: {}
      };
    case CREATE_VISITOR_BY_WALKIN:
      return {
        ...state,
        securityVisitorWalkin: { success: true, data: action.payload.data }
      };
    case CREATE_VISITOR_BY_WALKTHROUGH:
      return {
        ...state,
        securityVisitorWalkthrough: { success: true, data: action.payload.data }
      };
    case VISITOR_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
}
