import {
  DOCUMENT_LIST_LOADING,
  GET_LIST_DOCUMENT,
  GET_ONE_LIST_DOCUMENT,
  LIST_DOCUMENT_ERROR,
  DOCUMENT_UPDATE_STATUS,
  ADD_DOCUMENT,
} from "../Actions/types";

const initialState = {
  loading: false,
  document: null,
  documents: [],
  get_one_documents:[], 
  document_status: {},
};

export default function (state = initialState, action) {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case DOCUMENT_LIST_LOADING:
      return {
        ...state,
        loading: true,
      };
    case LIST_DOCUMENT_ERROR:
      return {
        ...state,
        loading: false,
        document: {
          success: false,
          data: action.payload && action.payload.data,
        },
      };
    case GET_LIST_DOCUMENT:
      return {
        ...state,
        loading: false,
        documents: action.payload && action.payload.data,
      };
      case GET_ONE_LIST_DOCUMENT:
      return {
        ...state,
        loading: false,
        get_one_documents: action.payload && action.payload.data,
      };
    case DOCUMENT_UPDATE_STATUS:
      return {
        ...state,
        loading: false,
        document_status: action.payload && action.payload.data,
      };
      case ADD_DOCUMENT:
        return {
          ...state,
          loading: false,
          document: { success: true, data: action.payload }
        };
      default:
      return state;
  }
}
