import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { Typography, withStyles, Paper } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import AboutEventTab from './AboutEventTab'
import TeamTabs from './TeamTabs'

const styles = () => ({
  mainEventTabs: {
    paddingTop: '18px',
    paddingBottom: '15px',
    backgroundColor: '#FCFDFE',
  },
  noCoworker: {
    padding: '24px 24px 11px',
    color: 'lightgrey',
  },
  layout: {
    marginTop: '19px !important',
    padding: '20px 25px',
  },
})
function TabContainer(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      // eslint-disable-next-line jsx-a11y/aria-role
      role="tabcontainer"
      hidden={value !== index}
      id={`simple-TabContainer-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function TabsData(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-TabContainer-${index}`,
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}))

// overrides tab indicator style
const AntTabs = withStyles({
  indicator: {
    color: '#435bf2',
    backgroundColor: ' #4682B4',
    top: '38px',
  },
  root: {
    marginTop: '18px',
  },
})(Tabs)

// Selected tab container style
const AntTab = withStyles(() => ({
  selected: {
    color: ' #4682B4',
  },
}))((props) => <Tab disableRipple {...props} />)

function EventTabs(props) {
  const classes = useStyles()
  const { id, data, event } = props
  const [value, setValue] = React.useState(0)

  // eslint-disable-next-line no-shadow
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Paper
      className={classes.mainEventTabs}
      style={{ backgroundColor: '#FCFDFE' }}
    >
      <AntTabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <AntTab label="About" {...TabsData(0)} />
        <AntTab label="Team" {...TabsData(1)} />
      </AntTabs>

      <TabContainer value={value} index={0}>
        <AboutEventTab data={data} event={event} id={id} />
      </TabContainer>
      <TabContainer value={value} index={1}>
        <TeamTabs eventID={id} event={event} />
      </TabContainer>
    </Paper>
  )
}

export default withStyles(styles)(EventTabs)
