import {
  GET_PLAN_CATEGORY,
  ADD_PLAN_CATEGORY,
  PLAN_CATEGORY_LOADING,
  GET_SINGLE_PLAN_CATEGORY,
  PLAN_ERRORS,
  EDIT_PLAN_CATEGORY
} from "../Actions/types";

const initialState = {
  plan_category: null,
  plan_categories: null,
  loading: false,
  edit_plan_category: {},
  get_single_plan_category: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_PLAN_CATEGORY:
      return {
        ...state,
        loading: false,
        plan_categories: action.payload.data
      };
    case ADD_PLAN_CATEGORY:
      return {
        ...state,
        loading: false,
        plan_category: { success: true, data: action.payload.data }
      };
    case GET_SINGLE_PLAN_CATEGORY:
      return {
        ...state,
        loading: false,
        succes: true,
        get_single_plan_category: action.payload && action.payload.data
      };
    case EDIT_PLAN_CATEGORY:
      return {
        ...state,
        loading: false,
        edit_plan_category: {
          success: true,
          data: action.payload && action.payload.data
        }
      };
    case PLAN_CATEGORY_LOADING:
      return {
        ...state,
        loading: true
      };
    case PLAN_ERRORS:
      return {
        ...state,
        loading: false,
        plan_error: { errors: false }
      };
    default:
      return state;
  }
}
