/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import axios from 'axios'
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'
import {
  GET_ERRORS,
  SET_CURRENT_USER,
  LOGIN_ERR0RS,
  FORGET_PASSWORD_SUCCESS,
  RESET_PASSWORD_SUCCESS,
  CHECK_TOKEN_SUCCESS,
  CHECK_TOKEN_ERROR,
  CHANGE_PASSWORD_SUCCESS,
  USER_SIGNUP_LOADING,
  GET_USER_SIGNUP_LIST,
  USER_SIGNUP_ERROR,
  SIGNUP_CINFIRMATION,
  FORGET_PASSWORD_ERROR,
} from './types'
import setAuthToken from '../Utils/setAuthToken'
import { transformErrorsFromApi } from './TransformError'
import { showError, showSuccess } from './ActionsHelper'
// Profile Loading
export const setSignupUserLoading = () => {
  return {
    type: USER_SIGNUP_LOADING,
  }
}

// Register
export const registerUser = (userData, history) => (dispatch) => {
  axios
    .post('/api/signup', userData)
    // eslint-disable-next-line no-unused-vars
    .then((result) => history.push('/login'))
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }))
}
// Login User - Get user token
export const loginUser = (userData, setErrors, setSubmitting) => (dispatch) => {
  axios
    .post('/api/signin', userData)
    .then((result) => {
      // SAVE to local storage
      // eslint-disable-next-line no-shadow
      const userData = (result.data && result.data.data) || {}
      const { token } = userData
      const space_id = userData.space_id || '5cd3f59f2ede1627cc2132dc'
      const user_id = userData.userid
      const { company_id, access_spaces, is_admin, sys_user } = userData
      console.log('userData', userData)
      // Set token to local storage
      localStorage.setItem('jwtToken', token)
      if (access_spaces) {
        localStorage.setItem('space_ids', JSON.stringify(access_spaces))
        localStorage.setItem('space_id', access_spaces[0]._id)
        localStorage.setItem('space_name', access_spaces[0].space_title)
      } else {
        localStorage.setItem('space_id', space_id)
        localStorage.setItem('space_name', userData.space_title)
      }
      localStorage.setItem('user_id', user_id)
      localStorage.setItem('company_id', company_id)
      localStorage.setItem('space_menu_access', is_admin || sys_user)
      // Set Token to Auth Header
      setAuthToken(token)
      // Decode token to get user data
      const decoded = jwt_decode(token)
      const name = decoded.first_name
      const lastName = decoded.last_name
      localStorage.setItem('name', name)
      localStorage.setItem('lastname', lastName)
      // Set Current User
      // eslint-disable-next-line no-use-before-define
      dispatch(setCurrentUser(decoded))
      setSubmitting(false)
    })
    .catch((err) => {
      const error = err.response || {}
      if (error.status === 401) {
        dispatch({ type: LOGIN_ERR0RS, payload: error.data })
        setErrors(transformErrorsFromApi(error))
      } else {
        dispatch({ type: LOGIN_ERR0RS, payload: error.data })
        setSubmitting(false)
        setErrors(transformErrorsFromApi(error))
      }
    })
}
export const forgetPassword = (userData, setSubmitting, setErrors, history) => (
  dispatch
) => {
  axios
    .post('/api/forgot', userData)
    .then((result) => {
      dispatch({
        type: FORGET_PASSWORD_SUCCESS,
        payload: result.data,
      })
      history.push('/login')
      setSubmitting(false)
    })
    .catch((err) => {
      const error = err.response
      if (error.status === 401) {
        dispatch({ type: FORGET_PASSWORD_ERROR, payload: error.data })
        setErrors(transformErrorsFromApi(error))
      } else {
        dispatch({ type: FORGET_PASSWORD_ERROR, payload: error.data })
        setErrors(transformErrorsFromApi(error))
      }
    })
}

export const checkToken = (userData) => (dispatch) => {
  axios
    .post('/api/resetpassword/checktoken', userData)
    .then((result) => {
      dispatch({
        type: CHECK_TOKEN_SUCCESS,
        payload: result.data,
      })
    })
    .catch((err) => {
      const error = err.response || {}
      dispatch({
        type: CHECK_TOKEN_ERROR,
        payload: error.data || {},
      })

      dispatch(showError('There was some error while processing!'))
    })
}
export const resetPassword = (userData, setSubmitting, history) => (
  dispatch
) => {
  axios
    .post('/api/resetpassword', userData)
    .then((result) => {
      dispatch({
        type: RESET_PASSWORD_SUCCESS,
        payload: result.data,
      })
      history.push('/login')
      setSubmitting(false)
    })
    .catch((err) => {
      const error = err.response
      // dispatch({ type: RESET_PASSWORD_ERROR, payload: error });
      setSubmitting(false)
      dispatch(showError('There was some error while processing!'))
    })
}

// Set loggedin users
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  }
}

export const changePassword = (userData, history, setErrors) => (dispatch) => {
  axios
    .post('/api/changepassword', userData)
    .then((result) => {
      dispatch({
        type: CHANGE_PASSWORD_SUCCESS,
        payload: result.data,
      })
      // dispatch(showSuccess("", "Password Changed!!"));
      localStorage.removeItem('jwtToken')
      // remove Auth header
      setAuthToken(false)
      // Set current user to {} which will also set isAuthenticated to false
      dispatch(setCurrentUser({}))
      // history.push("/login");
      history.push({ pathname: `/login`, state: 'changePassword' })
    })
    .catch((err) => {
      const error = err.response
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.fields && err.response.data.fields,
      })
      // setSubmitting(false);
      setErrors(transformErrorsFromApi(error))

      // dispatch(showError("There was some error while processing!"));
    })
}

// Log user out
export const logoutUser = () => (dispatch) => {
  // remove token from local storage
  localStorage.removeItem('jwtToken')
  // remove Auth header
  setAuthToken(false)
  // Set current user to {} which will also set isAuthenticated to false
  dispatch(setCurrentUser({}))
}

// Get User Signup List
export const getUserSignupList = () => (dispatch) => {
  dispatch(setSignupUserLoading())
  axios
    .get(`/api/user/signuphistroy`)
    .then((res) => {
      dispatch({
        type: GET_USER_SIGNUP_LIST,
        payload: res.data,
      })
    })
    .catch((err) =>
      dispatch({
        type: USER_SIGNUP_ERROR,
        payload: err && err.res ? err.res.data : '',
      })
    )
}

// Signup Confirmaiton
export const AddSignupConfirmation = (data) => (dispatch) => {
  const { status } = data
  dispatch(setSignupUserLoading())
  axios
    .post('/api/user/signupaction', data)
    .then((result) => {
      dispatch({
        type: SIGNUP_CINFIRMATION,
        payload: result.data,
      })
      // dispatch(showSuccess("Signup Confirmation"));
      if (status === 'yes' || status === 'draft') {
        dispatch(showSuccess('Signup Confirmation'))
      } else {
        dispatch(showSuccess('', 'Signup Rejected Successfully!'))
      }
    })
    .catch((err) => {
      const error = err.response
      dispatch({ type: USER_SIGNUP_ERROR, payload: {} })
      // history.push("/co-workers");

      dispatch(showError('There was some error!'))
    })
}
