import {
  BOOKING_ANALYSIS_REPORT,
  REPORT_ANALYSIS_LOADING,
  REPORT_ANALYSIS_ERROR,
  COMPANY_RESOURCE_BOOKING_LOG,
  COMPANY_RESOURCE_BOOKING_ANALYSIS,
  VISITOR_ANALYSIS,
  CLIENTS_VISITOR_ANALYSIS,
  MONTHLY_CAFE_CONSUMPTION_ANALYSIS,
  COMPANY_PLAN_ANAYSIS,
  GET_SPACE_COMPANY,
  WALKTHROUGH_LOG,
  WALKTHROUGH_ANALYSIS,
  COWORKER_LOG,
  VISITOR_LOGS,
  ISSUE_TICKET_ANALYSIS,
} from '../Actions/types'

const initialState = {
  loading: false,
  reportAnalysis: {},
  reportAnalysisData: {},
  companyBookingLog: {},
  companyReosurceBookingAnalysis: {},
  visitorAnalysis: {},
  clientsVisitorAnalysis: {},
  monthlyCafeAnalysis: {},
  companyPlanAnalysis: {},
  allCompany: [],
  walkthroughLog: {},
  walkthroughAnalysis: {},
  coworkerLog: {},
  visitorLogs: {},
  issueTicketAnalysisData: {},
}

export default function (state = initialState, action) {
  switch (action.type) {
    case BOOKING_ANALYSIS_REPORT:
      return {
        ...state,
        reportAnalysis: { success: true, data: action.payload },
      }
    case COMPANY_RESOURCE_BOOKING_LOG:
      return {
        ...state,
        companyBookingLog: { success: true, data: action.payload },
      }
    case COMPANY_RESOURCE_BOOKING_ANALYSIS:
      return {
        ...state,
        companyReosurceBookingAnalysis: { success: true, data: action.payload },
      }
    case VISITOR_ANALYSIS:
      return {
        ...state,
        visitorAnalysis: { success: true, data: action.payload },
      }
    case VISITOR_LOGS:
      return {
        ...state,
        visitorLogs: { success: true, data: action.payload },
      }
    case CLIENTS_VISITOR_ANALYSIS:
      return {
        ...state,
        clientsVisitorAnalysis: { success: true, data: action.payload },
      }
    case MONTHLY_CAFE_CONSUMPTION_ANALYSIS:
      return {
        ...state,
        monthlyCafeAnalysis: { success: true, data: action.payload },
      }
    case COMPANY_PLAN_ANAYSIS:
      return {
        ...state,
        companyPlanAnalysis: { success: true, data: action.payload },
      }
    case WALKTHROUGH_LOG:
      return {
        ...state,
        walkthroughLog: { success: true, data: action.payload },
      }
    case WALKTHROUGH_ANALYSIS:
      return {
        ...state,
        walkthroughAnalysis: { success: true, data: action.payload },
      }
    case COWORKER_LOG:
      return {
        ...state,
        coworkerLog: { success: true, data: action.payload },
      }
    case ISSUE_TICKET_ANALYSIS:
      return {
        ...state,
        issueTicketAnalysisData: { success: true, data: action.payload },
      }
    case GET_SPACE_COMPANY:
      return {
        ...state,
        loading: false,
        allCompany: action.payload.data,
      }
    case REPORT_ANALYSIS_LOADING:
      return {
        ...state,
        loading: true,
      }
    case REPORT_ANALYSIS_ERROR:
      return {
        ...state,
        loading: false,
        reportAnalysisData: {
          success: false,
          data: action.payload && action.payload.data,
        },
      }
    default:
      return state
  }
}
