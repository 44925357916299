import withStyles from "@material-ui/core/styles/withStyles";
import React from "react";
import {
  Grid,
  Typography,
  CssBaseline,
  Avatar,
  Button,
} from "@material-ui/core";
import classNames from "classnames";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-formik-ui";
import * as Yup from "yup";
import TextareaField from "../../Components/Common/Form/TextareaField";
import avatar from "../../Assets/coworker3.png";
import OnboardingPopup from "./OnboardingPopup";

import ImageCarousel from "./ImageCarousel";
import {
  getOnboardingProcess,
  getOnboardingProcessPost,
  onBoardingProcessPost,
} from "../../Actions/companiesAction";
import { capitalize, spacecapitalize } from "../../Utils/StringFunctions";
import { checkPermission } from "../../Utils/checkPermission";

const moment = require("moment");

const styles = () => ({
  photo: {
    marginTop: "11%",
    width: "42px",
    height: "43px",
  },
  logo: {
    width: "100%",
    textAlign: "center",
  },
  addPostSection: {
    // padding: "5% 1%"
  },
  eventsubtitle: {
    fontSize: "14px",
    fontWeight: "normal",
    textAlign: "center",
    color: "#626262",
    padding: "18px 51px",
  },
  createPostSection: {
    paddingRight: "20px",
  },
  postSection: {
    backgroundColor: "#fff",
    borderRadius: "4px",
    // paddingTop: "8%"
  },
  historyTime: {
    marginLeft: "11px",
    color: "#ababab",
    fontWeight: "normal",
    paddingTop: "0px",
    fontSize: "12px",
    textTransform: "capitalize",
  },
  historyTitle: {
    fontSize: "14px",
    paddingBottom: "2%",
    fontWeight: "normal",
    color: "#626262",
    position: "relative",
    top: "2px",
    left: "38px",
    width: "91%",
    textAlign: "justify",
  },
  historyMain: {
    padding: "20px 20px 0px 0px",
    borderBottom: "1px solid #efefef",
    marginLeft: "15px",
  },
  comment_section: {
    border: "1px solid #efefef",
    textAlign: "right",
    position: "relative",
    height: "81px",
    // marginLeft: "10px",
    borderRadius: "5px",
  },
  input_field: {
    border: "1px solid #f5f5f5",
    height: "100%",
    position: "relative",
    width: "100%",
    padding: "6px 8px",
    margin: "0px",
    borderBottom: "none",
    fontFamily:
      "'-apple-system,BlinkMacSystemFont','Segoe UI','Roboto','Helvetica Neue','Arial,sans-serif','Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol'",
    color: "grey",
    fontSize: "14px",
    resize: "none",
  },
  comment_button: {
    textAlign: "right",
  },
  submitbutton: {
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
    margin: "22px 0px",
    // marginBottom: "35px",
    "&:disabled": {
      backgroundColor: "lightgrey !important",
    },
  },
  introduction: {
    border: "1px solid #efefef",
    borderRadius: "5px",
    margin: "2% 0%",
  },
  title: {
    color: "#4682b4",
    fontSize: "16px",
    fontWeight: "normal",
    padding: "1%",
  },
  imgSection: {
    display: "flex",
  },
  imgBox: {
    width: "50px",
    height: "50px",
    margin: "1% 1% 1% 0%",
  },
  sliderSection: {
    width: "100%",
    height: "100%",
  },
  imageSliderSection: {
    height: "105%",
  },
});

const commentSchema = Yup.object().shape({
  comment: Yup.string()
    .trim()
    .max(750, "Post length must be less than 700 characters")
    .required("This field is required."),
});

class OnboardingProcess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      processType: "",
      onboardingProcess: [],
      onboardingProcessPost: [],
      processID: "",
      imageModalOpen: false,
      imageArray: "",
    };
  }

  componentDidMount() {
    if (checkPermission("COMMON_PERMISSION_FOR_ADMIN_CM")) {
      this.props.getOnboardingProcess("active", this.props.companyID);
      this.props.getOnboardingProcessPost(this.props.companyID);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.createOnboardingProcessPost !==
        prevProps.createOnboardingProcessPost &&
      this.props.createOnboardingProcessPost.success
    ) {
      if (checkPermission("COMMON_PERMISSION_FOR_ADMIN_CM")) {
        this.props.getOnboardingProcess("active", this.props.companyID);
        this.props.getOnboardingProcessPost(this.props.companyID);
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.onboardingProcess !== prevState.onboardingProcess) {
      return {
        onboardingProcess: nextProps.onboardingProcess,
      };
    }
    if (nextProps.onboardingProcessPost !== prevState.onboardingProcessPost) {
      return {
        onboardingProcessPost: nextProps.onboardingProcessPost,
      };
    }

    return [];
  }

  handleClickOpen = (name, id, status) => {
    if (status === "no") {
      this.setState({
        modalOpen: true,
        processType: name,
        processID: id,
      });
    }
    return false;
  };

  handleImageModalOpen = (img) => {
    const images = [];
    images.push(img);
    // eslint-disable-next-line react/no-unused-state
    this.setState({ imageModalOpen: true, imageArray: images });
  };

  handleClose = () => {
    this.setState({
      // eslint-disable-next-line react/no-access-state-in-setstate
      modalOpen: !this.state.modalOpen,
    });
  };

  imageHandleClose = () => {
    this.setState({
      // eslint-disable-next-line react/no-access-state-in-setstate
      imageModalOpen: !this.state.imageModalOpen,
    });
  };

  render() {
    const { classes } = this.props;
    const { onboardingProcess, onboardingProcessPost } = this.state;

    return (
      <React.Fragment>
        <CssBaseline />
        <div>
          <main>
            <div>
              <Grid container direction="row" item lg={12} xs={12}>
                {onboardingProcess &&
                  onboardingProcess.map((process) => {
                    return (
                      <Grid item lg={3} xs={3}>
                        <Grid
                          className={classes.logo}
                          onClick={() =>
                            this.handleClickOpen(
                              process.process_name,
                              process._id,
                              process.poststatus
                            )
                          }
                        >
                          <img
                            className={classes.photo}
                            src={
                              process.poststatus === "yes"
                                ? process.icon_of_completion
                                : process.icon
                            }
                            alt=""
                          />
                          <Typography
                            variant="body1"
                            component="p"
                            className={classes.eventsubtitle}
                          >
                            {spacecapitalize(process.process_name)}
                          </Typography>
                        </Grid>
                      </Grid>
                    );
                  })}
                <Grid item lg={12} xs={12} className={classes.addPostSection}>
                  {onboardingProcessPost && onboardingProcessPost.length > 0 && (
                    <div className={classes.introduction}>
                      <Typography
                        className={classes.title}
                        variant="subtitle1"
                        component="p"
                      >
                        {onboardingProcessPost &&
                          onboardingProcessPost[0] &&
                          onboardingProcessPost[0].created_by &&
                          onboardingProcessPost[0].created_by.first_name}{" "}
                        {onboardingProcessPost &&
                          onboardingProcessPost[0] &&
                          onboardingProcessPost[0].created_by &&
                          onboardingProcessPost[0].created_by.last_name}
                        {/* Pratik, Community Manager */}
                        <span className={classes.historyTime}>
                          - On{" "}
                          {moment(
                            onboardingProcessPost &&
                              onboardingProcessPost[0] &&
                              onboardingProcessPost[0].createdAt
                          ).format("MMMM DD, hh:mm A")}
                        </span>
                      </Typography>
                    </div>
                  )}
                  <Grid item lg={12} xs={12}>
                    <Formik
                      initialValues={{ comment: "" }}
                      enableReinitialize
                      validationSchema={commentSchema}
                      onSubmit={(
                        values,
                        { setErrors, setSubmitting, resetForm }
                      ) => {
                        // eslint-disable-next-line no-param-reassign
                        values.company_id = this.props.companyID;
                        this.props.onBoardingProcessPost(
                          values,
                          setErrors,
                          this.props.history,
                          setSubmitting
                        );
                        resetForm(this.initialValues);
                      }}
                    >
                      {({ handleSubmit }) => (
                        <Form
                          onSubmit={handleSubmit}
                          className={classes.form}
                          encType="multipart/form-data"
                          autoComplete="off"
                        >
                          <div className={classes.comment_section}>
                            <TextareaField
                              name="comment"
                              className={classes.input_field}
                              placeHolder="Add Comment here.."
                            />
                          </div>
                          <div className={classes.comment_button}>
                            <Button
                              className={classes.submitbutton}
                              type="submit"
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={this.onSubmit}
                            >
                              Submit
                            </Button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </Grid>
                </Grid>
                {/* </Grid> */}
              </Grid>
            </div>

            <div className={classNames(classes.layout, classes.postSection)}>
              {onboardingProcessPost.map((data) => {
                return (
                  <Grid
                    container
                    item
                    lg={12}
                    xs={12}
                    className={classes.historyMain}
                  >
                    <Grid item>
                      {data.created_by && data.created_by.profile_pic ? (
                        <Avatar
                          className={classes.avatar}
                          src={data.created_by.profile_pic}
                        />
                      ) : (
                        <Avatar className={classes.avatar} src={avatar} />
                      )}
                    </Grid>
                    <Typography
                      className={classes.historyTitle}
                      variant="subtitle1"
                      component="p"
                    >
                      {capitalize(data.comment)}
                      {data.created_by && (
                        <span className={classes.historyTime}>
                          by {capitalize(data.created_by.first_name)}{" "}
                          {capitalize(data.created_by.last_name)}
                        </span>
                      )}
                      <span className={classes.historyTime}>
                        On {moment(data.createdAt).format("MMMM DD")}
                      </span>
                      <div className={classes.imgSection}>
                        {data.media &&
                          data.media.map((img) => {
                            return (
                              <Grid
                                style={{ margin: "1% 1% 1% 0%" }}
                                onClick={() =>
                                  this.handleImageModalOpen(data.media)
                                }
                              >
                                <img
                                  src={img}
                                  alt=""
                                  className={classes.imgBox}
                                ></img>
                              </Grid>
                            );
                          })}
                      </div>
                    </Typography>
                  </Grid>
                );
              })}
            </div>

            <OnboardingPopup
              data={this.state.modalOpen}
              close={this.handleClose}
              processType={this.state.processType}
              companyID={this.props.companyID}
              processID={this.state.processID}
            />
            <ImageCarousel
              data={this.state.imageModalOpen}
              close={this.imageHandleClose}
              imageArray={this.state.imageArray[0]}
            />
          </main>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    onboardingProcess: state.company.onboardingProcess,
    onboardingProcessPost: state.company.onboardingProcessPost,
    createOnboardingProcessPost: state.company.createOnboardingProcessPost,
  };
};

export default connect(mapStateToProps, {
  getOnboardingProcess,
  getOnboardingProcessPost,
  onBoardingProcessPost,
})(withStyles(styles)(OnboardingProcess));
