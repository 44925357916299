import axios from "axios";
import {
  GET_INVENTORY,
  INVENTORY_LOADING,
  GET_ERRORS,
  ADD_INVENTORY,
  ADD_INVENTORY_ERROR,
  GET_SINGLE_INVENTORY,
  EDIT_INVENTORY,
  INVENTORY_ERRORS
} from "./types";
import { showSuccess, showError } from "./ActionsHelper";
import { transformErrorsFromApi } from "./TransformError";

//  Loading
export const setInventoryLoading = () => {
  return {
    type: INVENTORY_LOADING
  };
};

export const addInventory = (
  userData,
  setErrors,
  history,
  setSubmitting
) => dispatch => {
  dispatch(setInventoryLoading());
  axios
    .post("/api/inventory", userData)
    .then(result => {
      dispatch({
        type: ADD_INVENTORY,
        payload: result.data
      });
      history.push("/inventories");
      dispatch(showSuccess("Inventory"));
      setSubmitting(false);
    })
    .catch(err => {
      dispatch({ type: ADD_INVENTORY_ERROR, payload: err.data });
      dispatch(showError("There was some error while add inventory"));
      setSubmitting(false);
      setErrors(transformErrorsFromApi(err));
    });
};

// eslint-disable-next-line no-unused-vars
export const getInventory = () => dispatch => {
  // eslint-disable-next-line no-shadow
  dispatch(setInventoryLoading());
  axios
    .get(`/api/inventory`)
    .then(res => {
      dispatch({
        type: GET_INVENTORY,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : ""
      })
    );
};

export const getSingleInventory = id => dispatch => {
  dispatch(setInventoryLoading());
  axios
    .get(`/api/inventory/getsingle/${id}`)
    .then(res => {
      dispatch({
        type: GET_SINGLE_INVENTORY,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: INVENTORY_ERRORS,
        payload: err.response && err.response.data
      })
    );
};

export const editInventory = (
  id,
  data,
  setErrors,
  history,
  setSubmitting
) => dispatch => {
  dispatch(setInventoryLoading());
  axios
    .put(`/api/inventory/update/${id}`, data)
    .then(result => {
      dispatch({
        type: EDIT_INVENTORY,
        payload: result.data
      });
      history.push("/inventories");
      dispatch(showSuccess("", "Inventory  edited successfully!"));
      setSubmitting(false);
    })
    .catch(err => {
      const error = err.response;
      dispatch({ type: INVENTORY_ERRORS, payload: {} });
      setSubmitting(false);
      setErrors(transformErrorsFromApi(error));
    });
};
