import axios from 'axios'
import { GET_PARTICIPANTS, PARTICIPANTS_LOADING, UPLOAD_EXCEL } from './types'
import { showSuccess, showError } from './ActionsHelper'
//  Loading
export const setParticipantsLoading = () => {
  return {
    type: PARTICIPANTS_LOADING,
  }
}

export const getParticipants = (id, search) => (dispatch) => {
  dispatch(setParticipantsLoading())
  let url = `/api/eventparticipants/${id}`
  if (search) {
    url = `/api/eventparticipants/${id}?q=${search}`
  }
  axios
    .get(url)
    .then((res) => {
      dispatch({
        type: GET_PARTICIPANTS,
        payload: res.data,
      })
    })
    .catch(() =>
      dispatch({
        type: GET_PARTICIPANTS,
        payload: {},
      })
    )
}

// eslint-disable-next-line camelcase
export const uploadexcel = (data) => (dispatch) => {
  const bodyFormData = new FormData()
  bodyFormData.append('event_id', data.event_id)
  bodyFormData.append('file', data.file)

  dispatch(setParticipantsLoading())
  axios
    .post('/api/eventparticipants/csvupload', bodyFormData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((result) => {
      dispatch({
        type: UPLOAD_EXCEL,
        payload: result.data,
      })
      if (result.data.alreadyregistercount === 0)
        dispatch(showSuccess('', 'Upload Excel Successfully!'))
    })
    .catch((err) => {
      const error = err.response
      dispatch({ type: UPLOAD_EXCEL, payload: error })
      dispatch(showError('There was some error!', data))
    })
}
