import React from "react";
import { Paper, Tabs, Tab, Typography, withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import FreelancerOnboardingProcess from "./FreelancerOnboardingProcess";
import FreelancerOnboardingFeedback from "./FreelancerOnboardingFeedback";

const styles = () => ({
  root: {
    paddingTop: "18px",
    paddingBottom: "15px"
  }
});
const AntTabs = withStyles({
  indicator: {
    color: "#435bf2",
    backgroundColor: " #4682B4",
    top: "38px"
  }
})(Tabs);

const AntTab = withStyles(() => ({
  selected: {
    color: " #4682B4"
  }
}))(props => <Tab disableRipple {...props} />);

function TabContainer(props) {
  return (
    <Typography
      component="div"
      style={{ padding: "0px 14px", marginTop: " 14px" }}
    >
      {props.children}
    </Typography>
  );
}

function FreelancerOnBoarding(props) {
  const { classes } = props;
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  return (
    <Paper className={classes.root} style={{ backgroundColor: "#FCFDFE" }}>
      <AntTabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <AntTab label="Onboarding Process" />
        <AntTab label="Onboarding Feedback" />
      </AntTabs>
      <TabContainer>
        {value === 0 && (
          <FreelancerOnboardingProcess companyID={props.companyID} />
        )}
        {value === 1 && (
          <FreelancerOnboardingFeedback companyID={props.companyID} company={props.company} />
        )}
      </TabContainer>
    </Paper>
  );
}

const mapStateToProps = state => {
  return {
    errors: state.errors
  };
};

export default connect(
  mapStateToProps,
  {}
)(withStyles(styles)(FreelancerOnBoarding));
