/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import React, { useEffect, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
  Avatar,
} from "@material-ui/core";
import { getTeamIcon, createTeam } from "../../Actions/eventActions";
import { getUsers } from "../../Actions/userActions";
import rightIcon from "../../Assets/icons/Icon_Accept Selected.png";
import { spacecapitalize } from "../../Utils/StringFunctions";
import { InputField, SelectField } from "../../Components/Common/Form";

const $quoteSym = " ";

const styles = () => ({
  createTeamForm: {
    width: 500,
    padding: "8px",
  },
  formContent: {
    height: "400px",
  },
  modelHeader: {
    paddingBottom: "5px",
    position: "relative",
    "&:after": {
      top: "48px",
      left: "24px",
      width: "24px",
      borderBottom: "3px solid #fb0",
      content: `'${$quoteSym}'`,
      position: "absolute",
    },
  },
  mainTitle: {
    fontWeight: 600,
    fontSize: "14px",
    letterSpacing: "0.02em",
    color: " #252631",
  },
  iconImage: {
    width: "46px",
    height: "46px",
    margin: "5px 0px 15px 0px",
  },
  TeamImage: {
    width: "44px",
    height: "44px",
    border: "1px solid #efefef",
    borderRadius: "50px",
  },
  IconMainSection: {
    border: "1px solid #efefef ",
    borderRadius: "5px",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.05)",
    textAlign: "center",
    padding: "12px",
  },
  selctedTeamIcon: {
    width: "19px",
    height: "19px",
    position: "relative",
    top: "-64px",
    right: "-4px",
  },
  errmessage: {
    display: "none",
  },
  newerrormessage: {
    color: "red",
  },
  icongrid: {
    height: "48px",
  },
  modalFooter: {
    padding: "40px 0px 8px",
    display: "flex",
    justifyContent: "flex-end",
  },
  cancelbutton: {
    marginRight: "20px",
    backgroundColor: "#dddddd",
    color: "#151039",
    "&:hover": {
      color: "#151039",
      backgroundColor: "#d1d1d1!important",
    },
  },
  addbutton: {
    color: "#fff",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
});
const CreateTeamSchema = Yup.object().shape({
  team_name: Yup.string()
    .trim()
    .max(75, "The text entered exceeds the maximum length.")
    .required("This field is required."),
  // team_members: Yup.string().required("This field is required."),
});

function CreateTeamModal(props) {
  const { classes, eventID, eventData } = props;
  const [allTeamIcon, setallTeamIcon] = useState([]); // all team icon
  const [allUser, setAllUser] = useState([]); // all user
  const [teamicon, setTeamicon] = useState(); // set selected team icon
  const [teamIconErrorMessage, setteamIconErrorMessage] = useState(false); // team icon error message

  const defaultUser = localStorage.getItem("user_id");
  // const defaultUserName = localStorage.getItem("name");
  // const defaultUserLastName = localStorage.getItem("lastname");
  // const selectedUser = [
  //   {
  //     value: defaultUser,
  //     label: `${defaultUserName}  ${defaultUserLastName}`,
  //     visited: true,
  //   },
  // ];

  useEffect(() => {
    props.getTeamIcon(eventID); // get team icon
  }, []);

  useEffect(() => {
    setallTeamIcon(props.team_icon);
  }, [props.team_icon]);

  useEffect(() => {
    props.getUsers("", "", eventID); // get all user
  }, []);

  useEffect(() => {
    setAllUser(props.users);
  }, [props.users]);

  const handleClose = () => {
    props.closeCreateModal();
    setTeamicon();
  };

  // for select team icon
  const SelectTeamIcon = (id) => {
    setTeamicon(id);
    setteamIconErrorMessage(false);
  };

  // when any event action perform the component has been updated
  useEffect(() => {
    if (props.register_team && props.register_team.success) {
      props.closeCreateModal();
    }
  }, [props.register_team]);

  const getAvailableAllUserOptions = (allUsers) => {
    return allUsers
      ? allUsers.map((user) => {
          const { first_name, last_name, company_id } = user;
          let visitedFlag;

          if (user._id === defaultUser) {
            visitedFlag = true;
          } else {
            visitedFlag = false;
          }
          return {
            value: user._id,
            label: spacecapitalize(
              `${first_name && first_name} ${last_name && last_name} - ${
                company_id && company_id.type === "freelancer"
                  ? "-"
                  : company_id && company_id.company_name
              }`
            ),
            visited: visitedFlag,
            isFixed: visitedFlag,
          };
        })
      : [];
  };

  // handle submit of create team
  const createTeamData = (values, setSubmitting) => {
    const memberValue =
      values &&
      values.team_members &&
      values.team_members.map((members) => members.value);
    values.team_members = memberValue;
    values.icon = teamicon;
    if (eventData.team_max < values.team_members.length) {
      return false;
      // eslint-disable-next-line no-else-return
    } else {
      props.createTeam(values, setSubmitting);
    }
    return null;
  };

  // show team icon error
  const showTeamIconError = () => {
    if (teamicon === undefined) {
      setteamIconErrorMessage(true);
    } else {
      setteamIconErrorMessage(false);
    }
  };

  const onChange = (e, option) => {
    if (option.removedValue && option.removedValue.isFixed) return false;
    return null;
    // this.setState({
    //   clients: e,
    // });
  };

  const multiSelectStyles = {
    multiValue: (base, state) => {
      return state.data.isFixed
        ? {
            ...base,
            backgroundColor: "gray",
          }
        : base;
    },
    multiValueLabel: (base, state) => {
      return state.data.isFixed
        ? {
            ...base,
            fontWeight: "bold",
            color: "white",
            paddingRight: 6,
          }
        : base;
    },
    multiValueRemove: (base, state) => {
      return state.data.isFixed
        ? {
            ...base,
            display: "none",
          }
        : base;
    },
    control: (base) => {
      return {
        ...base,
        borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
        borderWidth: "0 0 1px 0",
        boxShadow: "none",
        "&:hover": {
          borderWidth: "0 0 1px 0",
        },
        "&:focus": {
          border: "none",
          borderWidth: "0 0 1px 0",
        },
        background: "transparent",
      };
    },
  };

  return (
    <Dialog
      open={props.openCreateModal}
      onClose={props.closeCreateModal}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" className={classes.modelHeader}>
        Create a Team
      </DialogTitle>

      <Formik
        initialValues={{
          team_name: "",
          type: "create",
          team_members: [],
          event_id: eventID,
        }}
        validationSchema={CreateTeamSchema}
        onSubmit={(values, { setSubmitting }) => {
          createTeamData(values, setSubmitting);
        }}
        render={({
          errors,
          touched,
          setFieldValue,
          values,
          setFieldTouched,
        }) => (
          <Form className={classes.createTeamForm}>
            <DialogContent className={classes.formContent}>
              <div className={classes.IconMainSection}>
                <span className={classes.mainTitle}> Select an Icon</span>
                <Grid container>
                  {allTeamIcon &&
                    allTeamIcon.map((element) => {
                      return (
                        <Grid
                          key={element.id}
                          item
                          sm={2}
                          lg={2}
                          className={classes.icongrid}
                        >
                          <div className={classes.iconImage}>
                            <Avatar
                              onClick={() => SelectTeamIcon(element._id)}
                              src={element.icon}
                              alt=""
                              className={classes.TeamImage}
                            />
                          </div>
                          {teamicon === element._id ? (
                            <img
                              src={rightIcon}
                              alt=""
                              className={classes.selctedTeamIcon}
                            />
                          ) : (
                            ""
                          )}
                        </Grid>
                      );
                    })}
                </Grid>
              </div>
              <span
                className={
                  teamIconErrorMessage === true
                    ? classes.newerrormessage
                    : classes.errmessage
                }
              >
                This field is required.
              </span>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <InputField
                    name="team_name"
                    label="Team Name"
                    placeholder="Enter Team Name"
                    max={75}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <SelectField
                    defaultValue={getAvailableAllUserOptions(allUser).filter(
                      (val) => val.visited === true
                    )}
                    // value={values.team_members}
                    isClearable={
                      !getAvailableAllUserOptions(allUser).some(
                        (client) => client.visited
                      )
                    }
                    // openOnClick={false}
                    name="team_members"
                    id="team_members"
                    label="Team Members"
                    onChange={
                      (() => {
                        onChange();
                      },
                      setFieldValue)
                    }
                    onBlur={setFieldTouched}
                    multi
                    error={errors.team_members}
                    touched={touched.team_members}
                    options={getAvailableAllUserOptions(allUser)}
                    styles={multiSelectStyles}
                  />
                </Grid>
                {eventData.team_min > values.team_members.length ? (
                  <span className={classes.newerrormessage}>
                    Please select minimum {eventData.team_min} members.
                  </span>
                ) : (
                  ""
                )}
                {values.team_members.length > 1 ? (
                  <span className={classes.newerrormessage}>
                    Please select maximum {eventData.team_max} members.
                  </span>
                ) : (
                  ""
                )}
              </Grid>
              <Grid className={classes.modalFooter}>
                <Button
                  onClick={handleClose}
                  color="primary"
                  className={classes.cancelbutton}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  className={classes.addbutton}
                  type="submit"
                  variant="contained"
                  onClick={showTeamIconError}
                >
                  Add
                </Button>
              </Grid>
            </DialogContent>
          </Form>
        )}
      />
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    errors: state.errors,
    team_icon: state.events.team_icon,
    users: state.manageuser.users.data,
    register_team: state.events.register_team,
  };
};

export default connect(mapStateToProps, { getTeamIcon, getUsers, createTeam })(
  withStyles(styles)(CreateTeamModal)
);
