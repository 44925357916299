import React, { useState } from "react";
import { Menu, MenuItem } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import DeleteAlert from "./DeleteAlert";

export default function Settings(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showConfirmDel, setShowConfirmDel] = useState(false);
  const {
    editMethod,
    isDelete,
    isEdit,
    isCancel,
    deleteMethod,
    message,
    type,
  } = props;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.preventDefault();
    event.stopPropagation();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleEdit = (event) => {
    // calling parent edit method
    editMethod();
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);
    if (type) props.typeValue(type);
  };

  const handleDelete = (event) => {
    // call delete method here
    setShowConfirmDel(true);
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton aria-label="settings">
        <MoreVertIcon onClick={handleClick} />
      </IconButton>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {isEdit && <MenuItem onClick={handleEdit}>Edit</MenuItem>}
        {isDelete && <MenuItem onClick={handleDelete}>Delete</MenuItem>}
        {isCancel && <MenuItem onClick={handleDelete}>Cancel</MenuItem>}
      </Menu>

      <DeleteAlert
        message={message}
        open={showConfirmDel}
        setOpen={setShowConfirmDel}
        alert={deleteMethod}
      />
    </div>
  );
}
