/* eslint-disable no-nested-ternary */
import React from "react";
import { withRouter } from "react-router-dom";
import {
  StepLabel,
  Stepper,
  Step,
  Paper,
  Button,
  Typography,
  Grid,
  colors,
  withStyles,
} from "@material-ui/core";
import { ChevronLeft } from "react-feather";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-formik-ui";
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { CircularLoader } from "../../Components/Common/Spinner";
import {
  StrengthOptions,
  ClienttypeOptions,
  IndustryOptions,
} from "../../Utils/dropDownData";
import { setEditorState } from "../../Utils";
import { Step1Validations, Step2Validations } from "./Validations";
import { getAvailablePlans } from "../../Actions/planActions";
import {
  addCompany,
  getCompany,
  updateCompany,
  getCompanies,
} from "../../Actions/companiesAction";
import { steps } from "./steps";
import Step1 from "./Step1";
import Step2 from "./Step2";

const styles = (theme) => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginBottom: theme.spacing(3),
    // padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginBottom: theme.spacing(6),
      // padding: theme.spacing(3)
    },
  },
  formfooter: {
    marginTop: "10px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  button: {
    width: "148px",
    height: "46px",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
    marginRight: "24px",
  },
  cancelbutton: {
    width: "148px",
    height: "46px",
    color: "#151039",
    backgroundColor: "#dddddd",
    marginRight: "12px",
    "&:hover": {
      color: "#151039",
      backgroundColor: "#d1d1d1!important",
    },
  },
  buttonProgress: {
    color: colors.green[500],
    position: "absolute",
    top: "50%",
    right: "30%",
    marginTop: -12,
    marginLeft: -12,
  },
  error_section: {
    textAlign: "center",
  },
  mainFormTitle: {
    margin: "0px 0px 0.35em 1.2em",
  },
  stepper: {
    width: "100%",
  },
  step: {
    width: "40%",
  },
});

function getStepContent(me, step, values, setFieldValue, setFieldTouched) {
  switch (step) {
    case 0:
      return (
        <Step1
          data={me.state.data}
          companies_list={me.state.companies_list}
          auth={me.props.auth}
          hello={me.props}
          values={values}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
        />
      );
    case 1:
      return (
        <Step2
          values={values}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          data={me.state.data}
        />
      );
    default:
      return null;
  }
}

class CompanyForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      data: {},
      company: {},
      apicall: true,
      id:
        this.props.match && this.props.match.params
          ? this.props.match.params.id
          : "",
    };
    // this.state.id = this.props.match.params.id;
    this.handleChange = this.handleChange.bind(this);
    this.handleBackCompany = this.handleBackCompany.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    this.props.getAvailablePlans();
    if (this.state.id) {
      this.props.getCompany(this.state.id);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.create_company !== this.props.create_company) {
      if (this.props.create_company.success) {
        this.props.getCompanies();
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.company !== prevState.company) {
      return {
        company: nextProps.company,
        // eslint-disable-next-line no-unneeded-ternary
        apicall:
          // eslint-disable-next-line no-unneeded-ternary
          typeof nextProps.company.success === "undefined" ? true : false,
      };
    }
    if (nextProps.company !== prevState.company) {
      return { company: nextProps.company, loading: nextProps.company.loading };
    }
    return [];
  }

  formatData = (oldData) => {
    const data = { ...oldData };
    Object.keys(data).map((param) => {
      if (["industry", "employee_strength", "client_type"].includes(param)) {
        const val = data[param] ? data[param].value : "";
        data[param] = val;
      }

      if (["services"].includes(param)) {
        data[param] = data[param] ? data[param].map((item) => item.label) : [];
      }
      return null;
    });
    data.created_by = localStorage.getItem("user_id");

    // eslint-disable-next-line no-unused-vars
    let CreditTotal = 0;
    // eslint-disable-next-line no-unused-vars
    let PricePerSeatTotal = 0;
    // eslint-disable-next-line no-unused-vars
    let SeatsTotal = 0;
    // eslint-disable-next-line no-unused-vars
    let SubTotalPriceDisplay = 0;
    let SubTotalCreditsDisplay = 0;
    let SelectedSeats = 0;

    // eslint-disable-next-line no-unused-expressions
    data.plan &&
      // eslint-disable-next-line array-callback-return
      data.plan.map((plan) => {
        CreditTotal += Number(plan.credit_per_seat);
        PricePerSeatTotal += Number(plan.price_per_seat);
        SeatsTotal += Number(plan.seat_per_cabin);
        SubTotalPriceDisplay +=
          Number(plan.price_per_seat) * Number(plan.seat_per_cabin);
        SubTotalCreditsDisplay +=
          Number(plan.credit_per_seat) * Number(plan.available_seat);
      });

    data.total_credits = SubTotalCreditsDisplay;
    SelectedSeats = data.available_seat;
    // data.seat_per_cabin = data.available_seat;
    data.seat_per_cabin = SelectedSeats;
    if (data.draft === true) data.status = "draft";
    else data.status = "active";
    delete data.draft;
    return data;
  };

  addCompany = (stepData, setErrors, setSubmitting) => {
    const data = { ...this.state.data, ...stepData };
    const company = {
      ...data,
      space: localStorage.getItem("space_id"),
    };

    if (this.state.id) {
      this.props.updateCompany(
        this.state.id,
        this.formatData(company),
        this.props.history,
        setSubmitting
      );
    } else {
      this.props.addCompany(
        this.formatData(company),
        this.props.history,
        setErrors,
        setSubmitting
      );
    }
  };

  handleBack = () => {
    this.setState((state) => ({
      activeStep: state.activeStep - 1,
    }));
  };

  handleClose = () => {
    const { activeStep } = this.state;
    if (activeStep === 0) {
      const path = "/companies";
      this.props.history.push(path);
    } else {
      this.setState({
        activeStep: 0,
      });
    }
  };

  handleBackCompany = () => {
    const path = "/companies";
    this.props.history.push(path);
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  renderNavButtons = (setFieldValue, submitForm) => {
    const buttonText = this.state.id ? "Update" : "Add";
    const { activeStep } = this.state;
    const { classes } = this.props;
    let returnComponent = "";
    let draftComponent = "";
    if (activeStep !== 0) {
      returnComponent = (
        <Grid xs={2} spacing={2}>
          <Button
            onClick={this.handleBack}
            color="primary"
            className={classes.cancelbutton}
          >
            Back
          </Button>
        </Grid>
      );
    } else {
      returnComponent = (
        <Grid xs={2} spacing={2}>
          <Button
            onClick={this.handleClose}
            color="primary"
            className={classes.cancelbutton}
          >
            Cancel
          </Button>
        </Grid>
      );
    }
    draftComponent = (
      <Grid
        xs={8}
        spacing={2}
        justify="flex-end"
        style={{ paddingLeft: "25px" }}
      >
        {activeStep === 1 ? (
          <Button
            color="grey"
            className={classes.cancelbutton}
            variant="contained"
            onClick={async () => {
              setFieldValue("draft", true);
              await Promise.resolve();
              submitForm();
            }}
          >
            Save Draft
          </Button>
        ) : (
          ""
        )}
      </Grid>
    );

    return [
      draftComponent,
      returnComponent,
      <Grid xs={2} spacing={2} justify="flex-end">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.button}
        >
          {/* On update restricting to step 2 for v1 only */}
          {this.state.id
            ? activeStep === steps.length - 1
              ? `${buttonText} Company`
              : "Next"
            : activeStep === steps.length - 1
            ? `${buttonText} Company`
            : "Next"}
        </Button>
      </Grid>,
    ];
  };

  geIntialValues = (id) => {
    const { company } = this.props;

    if (id) {
      return {
        company_name: company.company_name,
        logo: company.logo,
        display_name: company.display_name,
        industry: IndustryOptions.filter(
          (option) => option.value === company.industry
        ),
        employee_strength: StrengthOptions.filter(
          (option) => option.value === company.employee_strength
        ),
        client_type: ClienttypeOptions.filter(
          (option) => option.value === company.client_type
        ),
        contract_start_date: company.contract_start_date,
        contract_end_date: company.contract_end_date,
        contract_upload_contract: "",
        title: company.title,
        banner_image: company.banner_image,
        company_profile: company.company_profile,
        services: company.services,
        website: company.website,
        linkedin: company.linkedin,
        facebook: company.facebook,
        twitter: company.twitter,
        instagram: company.instagram,
        plan: "",
        selectedPlans: [],
        editorState: setEditorState(company.description),
      };
    }
    return {
      company_name: "",
      logo: "",
      description: "",
      display_name: "",
      industry: "",
      employee_strength: "",
      client_type: "",
      contract_start_date: new Date(),
      contract_end_date: new Date(),
      contract_upload_contract: "",
      title: "",
      banner_image: "",
      company_profile: "",
      services: "",
      website: "",
      linkedin: "",
      facebook: "",
      twitter: "",
      instagram: "",
      plan: "",
      selectedPlans: [],
      // eslint-disable-next-line new-cap
      editorState: new EditorState.createEmpty(),
    };
  };

  render() {
    const { activeStep, id, loading, apicall } = this.state;
    let companyValidations = {};
    const { classes, company } = this.props;
    if (activeStep === 0) {
      companyValidations = Step1Validations;
    } else {
      companyValidations = Step2Validations;
    }

    return (
      <React.Fragment>
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography variant="h6" className={classes.mainFormTitle}>
              {id ? "Update" : "Add"} Company
            </Typography>
            <span className={classes.stepper}>
              <Stepper activeStep={activeStep} className={classes.step}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </span>
            <React.Fragment>
              {typeof company.success !== "undefined" &&
                !company.success &&
                apicall && (
                  <div className={classes.error_section}>
                    <Typography variant="h5" gutterBottom>
                      There was some error. Please Contact Administrator.
                    </Typography>
                    <Button
                      onClick={this.handleBackCompany}
                      className={classes.button}
                    >
                      Back
                    </Button>
                  </div>
                )}
              {activeStep === steps.length && company.success ? (
                <React.Fragment>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item align="center">
                      <Typography variant="h5" gutterBottom>
                        {this.state.id
                          ? "Company Updated Successfully."
                          : "Company Created Successfully."}
                      </Typography>
                      <Button
                        variant="contained"
                        color="default"
                        className={classes.button}
                        href="/companies/"
                      >
                        <ChevronLeft className={classes.icon} />
                        VIEW ALL COMPANIES
                      </Button>
                    </Grid>
                  </Grid>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Formik
                    // eslint-disable-next-line no-unneeded-ternary
                    enableReinitialize={activeStep === 0 ? true : false}
                    initialValues={this.geIntialValues(id)}
                    validationSchema={companyValidations}
                    onSubmit={(values, { setSubmitting, setErrors }) => {
                      // eslint-disable-next-line no-param-reassign
                      const submitValues = { ...values };
                      submitValues.description = stateToHTML(
                        values.editorState.getCurrentContent()
                      );
                      delete submitValues.editorState;
                      /* On update restricting to step 2 for v1 only */
                      if (activeStep === 1 && this.state.id) {
                        this.addCompany(submitValues, setErrors, setSubmitting);
                        // this.props.getCompanies();
                        this.setState((state) => ({
                          activeStep: state.activeStep - 1,
                        }));
                      }
                      // if (activeStep === 2) {
                      //   this.addCompany(values, setErrors, setSubmitting);
                      //   this.props.getCompanies();
                      // }
                      // if (activeStep === 2) {
                      //   this.handleBackCompany();
                      // }
                      else if (activeStep !== 1) {
                        this.setState((state) => ({
                          activeStep: state.activeStep + 1,
                        }));
                      } else if (activeStep === 1) {
                        this.addCompany(submitValues, setErrors, setSubmitting);
                        this.handleBackCompany();
                      }
                    }}
                  >
                    {({
                      values,
                      setFieldValue,
                      setFieldTouched,
                      errors,
                      submitForm,
                    }) => (
                      <Form
                        className={classes.form}
                        onSubmit={this.onSubmit}
                        encType="multipart/form-data"
                        autoComplete="off"
                      >
                        {getStepContent(
                          this,
                          activeStep,
                          values,
                          setFieldValue,
                          setFieldTouched,

                          this.state.companies_list
                        )}
                        <Typography as="h5" style={{ color: "red" }}>
                          {errors.generic}
                        </Typography>
                        {loading && <CircularLoader />}

                        <Grid className={classes.formfooter}>
                          <Grid container xs={12} sm={12}>
                            {this.renderNavButtons(setFieldValue, submitForm)}
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </React.Fragment>
              )}
            </React.Fragment>
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.company.company,
    auth: state.auth,
    errors: state.errors,
    plans: state.plans,
    create_company: state.company.create_company,
  };
};

export default connect(mapStateToProps, {
  addCompany,
  getCompany,
  updateCompany,
  getAvailablePlans,
  getCompanies,
})(withStyles(styles)(withRouter(CompanyForm)));
