/* eslint-disable react/no-access-state-in-setstate */
import React from "react";
import Avatar from "@material-ui/core/Avatar";
import { Button, InputAdornment, IconButton } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import { Lock } from "react-feather";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Formik } from "formik";
import { Form } from "react-formik-ui";
import { FormikTextField } from "formik-material-fields";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {
  loginUser,
  changePassword,
  logoutUser,
} from "../../Actions/authAction";

const styles = (theme) => ({
  lightbg: {
    padding: "20px 30px 0px",
    backgroundColor: "#fff",
    height: "87vh",
  },
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 450,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
    color: "#ffffff",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  textFieldHelper: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginTop: 0,
  },
});

const ValidationSchema = Yup.object().shape({
  password: Yup.string().required("This field is required"),
  new_password: Yup.string().required("This field is required"),
  confirm_password: Yup.string()
    .required("This field is required")
    .when("new_password", {
      is: (val) => !!(val && val.length > 0),
      then: Yup.string().oneOf(
        [Yup.ref("new_password")],
        "New Password and Confirm Password must be same"
      ),
    }),
});

class ChangePassword extends React.Component {
  state = {
    currentPass: true,
    newPassword: true,
    confirmPassword: true,
  };

  componentDidMount() {
    // let { auth } = this.props;
    // if (auth.isAuthenticated) {
    //   this.redirectLogin(auth.hasRole);
    // }
  }

  componentDidUpdate() {
    // if (prevProps.auth !== this.props.auth) {
    //   if (
    //     this.props.auth &&
    //     this.props.auth.user &&
    //     this.props.auth.user.data &&
    //     this.props.auth.user.data.success
    //   )
    //     this.props.logoutUser();
    // }
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <div className={classes.lightbg}>
          <main className={classes.main}>
            <CssBaseline />
            <Paper className={classes.paper}>
              <Avatar className={classes.avatar}>
                <Lock color="black" />
              </Avatar>
              <Typography
                component="h1"
                variant="h5"
                gutterBottom
                style={{ fontWeight: "500" }}
              >
                Change your password
              </Typography>
              <Formik
                initialValues={{
                  password: "",
                  new_password: "",
                  confirm_password: "",
                }}
                enableReinitialize
                validationSchema={ValidationSchema}
                onSubmit={(values, { setErrors }) => {
                  // eslint-disable-next-line no-param-reassign
                  values.current_password = values.password;
                  this.props.changePassword(
                    values,
                    this.props.history,
                    setErrors
                  );
                }}
              >
                {({ handleSubmit }) => (
                  <Form
                    onSubmit={handleSubmit}
                    className={classes.form}
                    encType="multipart/form-data"
                    autoComplete="off"
                  >
                    <FormControl required fullWidth>
                      <FormikTextField
                        name="password"
                        label="Current Password"
                        type={this.state.currentPass ? "password" : "text"}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        className={classes.inputField}
                        InputProps={{
                          classes: {
                            input: classes.multilineColor,
                          },
                          endAdornment: (
                            <InputAdornment position="start">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => {
                                  this.setState({
                                    currentPass: !this.state.currentPass,
                                  });
                                }}
                                // onMouseDown={handleMouseDownPassword}
                              >
                                {this.state.currentPass ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        placeholder="Enter Current Password"
                      />
                      <FormikTextField
                        name="new_password"
                        label="New Password"
                        type={this.state.newPassword ? "password" : "text"}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        className={classes.inputField}
                        InputProps={{
                          classes: {
                            input: classes.multilineColor,
                          },
                          endAdornment: (
                            <InputAdornment position="start">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => {
                                  this.setState({
                                    newPassword: !this.state.newPassword,
                                  });
                                }}
                                // onMouseDown={handleMouseDownPassword}
                              >
                                {this.state.newPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        placeholder="Enter New Password"
                      />
                      <FormikTextField
                        name="confirm_password"
                        label="Confirm Password"
                        type={this.state.confirmPassword ? "password" : "text"}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        className={classes.inputField}
                        InputProps={{
                          classes: {
                            input: classes.multilineColor,
                          },
                          endAdornment: (
                            <InputAdornment position="start">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => {
                                  this.setState({
                                    confirmPassword: !this.state
                                      .confirmPassword,
                                  });
                                }}
                                // onMouseDown={handleMouseDownPassword}
                              >
                                {this.state.confirmPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        placeholder="Enter Confirm Password"
                      />
                    </FormControl>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      className={classes.submit}
                    >
                      Submit
                    </Button>
                  </Form>
                )}
              </Formik>
            </Paper>
          </main>
        </div>
      </React.Fragment>
    );
  }
}

ChangePassword.propType = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  loginUser,
  changePassword,
  logoutUser,
})(withStyles(styles)(ChangePassword));
