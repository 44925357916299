/* eslint-disable camelcase */
import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import AdminInventoryTabs from "./AdminInventoryTabs";

const styles = () => ({
  main: {
    padding: "24px",
  },
});
export class AdminManageArea extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.main}>
        <Button
          color="grey"
          onClick={() => this.props.history.push("/settings")}
          className={classes.backButton}
          variant="contained"
          size="small"
        >
          Back
        </Button>
        <AdminInventoryTabs history={this.props.history} />
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

export default connect(
  mapStateToProps,
  {}
)(withStyles(styles)(AdminManageArea));
