/* eslint-disable react/no-unescaped-entities */
import React from "react";
import {
  Button,
  Grid,
  CssBaseline,
  Typography,
  Paper,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { ThemeProvider } from "@material-ui/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import { FormikTextField } from "formik-material-fields";
import * as Yup from "yup";
import { createMuiTheme } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import LoginImage from "../../Assets/login.png";
import { loginUser, forgetPassword } from "../../Actions/authAction";

const styles = (theme) => {
  return {
    paper: {
      display: "flex",
      flexDirection: "column",
      height: "-webkit-fill-available",
      padding: "24% 22% ",
      backgroundColor: "#fff",
    },
    container: {
      width: "100%",
    },
    form: {
      width: "100%",
      marginTop: theme.spacing(1),
    },

    login: {
      backgroundImage: `url(${LoginImage})`,
      width: "100%",
      // height: "657px",
      height: "100vh",
      backgroundSize: "cover",
      backgroundPosition: "50%",
    },
    formfooter: {
      // display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-end",
      marginTop: "5%",
    },
    mainTitle: {
      fontSize: "24px",
      lineHeight: "1.75rem",
      fontWeight: 600,
      // marginBottom: "1rem",
      color: "#110f24",
    },
    subTitle: {
      fontSize: "14px",
      fontWeight: 400,
      letterSpacing: ".3px",
      // lineHeight: "2rem",
      marginBottom: "1rem",
      color: "#ABABAB",
      paddingTop: "5px",
    },
    multilineColor: {
      height: "50px",
      padding: "0px 10px",
      marginBottom: "-7px",
      // backgroundColor: "#f6f6f9"
    },
    submitbutton: {
      backgroundColor: "#211b4e !important",
      "&:hover": {
        backgroundColor: "#151039 !important",
      },
      textTransform: "initial",
    },
    forgetPassword: {
      "&:hover": {
        backgroundColor: "#fff !important",
      },
      fontSize: "12px",
      textTransform: "initial",
      marginLeft: "-2%",
    },
    LoginButton: {
      textTransform: "initial",
      backgroundColor: "lightgrey",
      marginLeft: "6%",
    },
  };
};
const theme = createMuiTheme({
  overrides: {
    MuiInputLabel: {
      // Name of the component âš›ï¸ / style sheet
      root: {
        "&$error": {
          // increase the specificity for the pseudo class
          color: "rgba(0,0,0,0.54)",
        },
      },
    },
    MuiInputBase: {
      input: {
        paddingLeft: "10px !important",
        fontSize: "14px",
      },
    },
  },
});

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("This field is required."),
  password: Yup.string().required("This field is required."),
});
const ForgetPasswordSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email address").required("Required"),
});
class LoginPage extends React.Component {
  state = {
    // errors: {},
    showForgetPass: false,
    ShowPass: true,
  };

  componentDidMount() {
    const { auth } = this.props;
    if (auth.isAuthenticated) {
      this.redirectLogin(auth.hasRole);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.auth !== prevState.auth) {
      return { auth: nextProps.auth };
    }
    if (nextProps.user && nextProps.user.success !== prevState.user.success) {
      return { user: nextProps.user };
    }

    return {};
  }

  redirectLogin() {
    this.props.history.push("/dashboard");
  }

  forgetPassword() {
    const { classes, auth } = this.props;
    if (auth.user.success) {
      return (
        <React.Fragment>
          <Typography component="h5" variant="h5" className={classes.mainTitle}>
            We just emailed you a link,
          </Typography>
          <Typography
            component="p"
            variant="body1"
            className={classes.subTitle}
          >
            Please check your email and click the secure link. If you don’t see
            our email, check your spam folder.
          </Typography>
        </React.Fragment>
      );
    }
    return ({ errors }) => (
      <Form className={classes.form}>
        <ThemeProvider theme={theme}>
          <FormikTextField
            name="email"
            label="Email ID"
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            className={classes.inputField}
            InputProps={{
              classes: {
                input: classes.multilineColor,
              },
            }}
            placeholder="Enter your Email ID"
          />
          <Typography as="h5" style={{ color: "red" }}>
            {errors.generic}
          </Typography>
          <div className={classes.formfooter}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className={classes.submitbutton}
            >
              Send
            </Button>
            <Button
              onClick={() => {
                this.setState({ showForgetPass: false });
              }}
              className={classes.LoginButton}
            >
              Login
            </Button>
          </div>
        </ThemeProvider>
      </Form>
    );
  }

  renderForm() {
    const { showForgetPass } = this.state;
    const { classes } = this.props;

    if (showForgetPass) {
      return this.forgetPassword();
    }
    return ({ errors }) => (
      <Form className={classes.form}>
        <ThemeProvider theme={theme}>
          <FormikTextField
            name="email"
            label="Email ID"
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            className={classes.inputField}
            InputProps={{
              classes: {
                input: classes.multilineColor,
              },
            }}
            placeholder="johndoe@mail.com"
          />
          <FormikTextField
            name="password"
            label="Password"
            type={this.state.ShowPass ? "password" : "text"}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            className={classes.inputField}
            InputProps={{
              classes: {
                input: classes.multilineColor,
              },
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      // eslint-disable-next-line react/no-access-state-in-setstate
                      this.setState({ ShowPass: !this.state.ShowPass });
                    }}
                    // onMouseDown={handleMouseDownPassword}
                  >
                    {this.state.ShowPass ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            placeholder="Enter Password"
          />
          <Typography as="h5" style={{ color: "red" }}>
            {errors.generic}
          </Typography>
          <div className={classes.formfooter}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className={classes.submitbutton}
            >
              Submit
            </Button>
          </div>
          <Button
            onClick={() => {
              this.setState({ showForgetPass: true });
            }}
            className={classes.forgetPassword}
          >
            Forgot Password?
          </Button>
        </ThemeProvider>
      </Form>
    );
  }

  render() {
    const { classes } = this.props;
    const { auth, showForgetPass } = this.state;
    if (auth.isAuthenticated) {
      this.redirectLogin(auth.hasRole);
    }

    return (
      <div className={classes.container}>
        <CssBaseline />
        <Grid container>
          <Grid item xs={12} sm={6} md={6} component={Paper}>
            <div className={classes.paper}>
              <div className={classes.loginContent}>
                {!showForgetPass ? (
                  <span>
                    <Typography
                      component="h5"
                      variant="h5"
                      className={classes.mainTitle}
                    >
                      Welcome to DevX!
                    </Typography>
                    <Typography
                      component="p"
                      variant="body1"
                      className={classes.subTitle}
                    >
                      {this.props.location.state &&
                      this.props.location.state === "changePassword" ? (
                        <span>
                          Your Password has been changed successfully.
                          <br />
                          Login with your new password.
                        </span>
                      ) : (
                        "Enter your details below."
                      )}
                    </Typography>
                  </span>
                ) : (
                  <span>
                    {!auth.user.success && (
                      <React.Fragment>
                        <Typography
                          component="h5"
                          variant="h5"
                          className={classes.mainTitle}
                        >
                          First, let's find your account
                        </Typography>
                        <Typography
                          component="p"
                          variant="body1"
                          className={classes.subTitle}
                        >
                          Enter your email address and we will send you a link
                          to reset your password.
                        </Typography>
                      </React.Fragment>
                    )}
                  </span>
                )}
                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  validationSchema={
                    showForgetPass ? ForgetPasswordSchema : LoginSchema
                  }
                  onSubmit={(payload, { setErrors, setSubmitting }) => {
                    // eslint-disable-next-line no-unused-expressions
                    showForgetPass
                      ? this.props.forgetPassword(
                          payload,
                          setSubmitting,
                          setErrors,
                          this.props.history
                        )
                      : this.props.loginUser(payload, setErrors, setSubmitting);
                  }}
                >
                  {this.renderForm()}
                </Formik>
              </div>
            </div>
          </Grid>
          <Grid
            container
            sm={6}
            md={6}
            className={classes.image}
            direction="column"
          >
            <div className={classes.login} />
          </Grid>
        </Grid>
      </div>
    );
  }
}

LoginPage.propType = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  forgetPassword: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { loginUser, forgetPassword })(
  withStyles(styles)(LoginPage)
);
