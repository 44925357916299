/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { User, Mail, Bell } from 'react-feather'
import MenuIcon from '@material-ui/icons/Menu'
import { connect } from 'react-redux'
import { withRouter, NavLink } from 'react-router-dom'
import clsx from 'clsx'
import { AccountCircle } from '@material-ui/icons'
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined'
import {
  AppBar,
  Toolbar,
  IconButton,
  Badge,
  Typography,
  Menu,
  MenuItem,
} from '@material-ui/core'

import MoreVertIcon from '@material-ui/icons/MoreVert'
import DevxLogo from '../../Assets/Whitelogo.png'
import { getUserData, getNotification } from '../../Actions/EditProfileActions'
import SpaceMenu from './SpaceMenu'
import { RenderMenu } from './userMenu'
import { clearCurrentProfile } from '../../Actions/profileAction'
import { logoutUser } from '../../Actions/authAction'
import RenderNotificationMenu from './NotificationModal'
import { capitalize } from '../../Utils/StringFunctions'
import { Loader } from '../Common/Spinner'

// const DEVXSTORE = "/devx-store";

const drawerWidth = 240

const styles = (theme) => ({
  root: {
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: 36,
  },
  title: {
    display: 'none',
    color: theme.palette.primary.main,
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    width: drawerWidth,
  },
  spaceMenu: {
    flexGrow: 1,
    color: 'white',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(10),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#211b4e',
    color: theme.palette.primary.main,
  },
  appBarShift: {
    width: '100%',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButtonHidden: {
    display: 'none',
  },
  systemLoader: {
    width: 'calc(100% - 240px)',
    right: 0,
    position: 'absolute',
    top: '48px',
  },
  devxlogo: {
    // height: "40%",
    width: '100px',
    display: 'none',
    color: theme.palette.primary.main,
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    marginLeft: '3.2%',
  },
  profileIcon: {
    color: '#fff',
    borderRadius: '50px',
    height: '30px',
    width: '30px ',
  },
  verticalIcon: {
    marginTop: '-2.6%',
    color: 'white',
    // left: "13%"
  },
  ImageAlign: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'stretch',
    alignContent: 'left',
  },
  item: {
    padding: '2px 2px 2px 2px',
  },
  itemActionable: {
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  naviIcons: {
    color: 'white',
    marginLeft: '150px',
  },
  notificationIcon: {
    color: '#fff',
  },
  notificationCount: {
    backgroundColor: '#f8c562',
    borderRadius: '50px',
    padding: '2px 6px',
    fontSize: '14px',
    color: '#fff',
    position: 'relative',
    top: '-7px',
    left: '-10px',
  },
})
class Header extends React.Component {
  state = {
    anchorEl: null,
    mobileMoreAnchorEl: null,
    open: true,
    anchorElNotification: null,
  }

  componentDidMount() {
    // this.props.getUserData();
    this.props.getNotification()
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user !== prevState.user) {
      return { user: nextProps.user }
    }

    if (nextProps.allNotification !== prevState.allNotification) {
      return { allNotification: nextProps.allNotification }
    }
    return []
  }

  handleDrawerOpen = () => {
    this.setState({ open: true })
  }

  handleDrawerClose = () => {
    this.setState({ open: false })
  }

  handleProfileMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleMenuClose = () => {
    this.setState({ anchorEl: null })
    this.setState({ securityAnchorEl: null })
    // this.handleMobileMenuClose();
  }

  handleMobileMenuOpen = (event) => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget })
  }

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null })
  }

  logout = () => {
    this.props.logoutUser()
    this.props.clearCurrentProfile()
    this.props.history.push('/')
  }

  viewVisitors = () => {
    this.props.history.push('/visitors')
  }

  handleNotificationClose = () => {
    this.setState({ anchorElNotification: null })
  }

  handleClick(event) {
    this.anchorEl(event.currentTarget)
  }

  render() {
    const {
      anchorEl,
      mobileMoreAnchorEl,
      open,
      securityAnchorEl,
      user,
      anchorElNotification,
    } = this.state
    const { classes, loading, allNotification } = this.props
    const isMenuOpen = Boolean(anchorEl)
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)
    const isSecurityMenuOpen = Boolean(securityAnchorEl)
    const isNotificationOpen = Boolean(anchorElNotification)
    const viewSpaceMenu = localStorage.getItem('space_menu_access')

    const role = localStorage.getItem('role')
    let username = ''
    if (role !== 'cafe' && role !== 'admin' && role !== 'security') {
      username = `${user.first_name && capitalize(user.first_name)} ${
        user.last_name && capitalize(user.last_name)
      }`
    }

    const renderMobileMenu = (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={this.handleMenuClose}
      >
        <MenuItem onClick={this.handleMobileMenuClose}>
          <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <Mail />
            </Badge>
          </IconButton>
          <p>Messages</p>
        </MenuItem>
        <MenuItem onClick={this.handleMobileMenuClose}>
          <IconButton color="inherit">
            <Badge badgeContent={11} color="secondary">
              <Bell />
            </Badge>
          </IconButton>
          <p>Notifications</p>
        </MenuItem>
        <MenuItem onClick={this.handleProfileMenuOpen}>
          <IconButton color="inherit">
            <User />
          </IconButton>
          <p>Profile</p>
        </MenuItem>
      </Menu>
    )

    const comp = (
      <React.Fragment>
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, open && classes.appBarShift)}
        >
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
              className={clsx(
                classes.menuButton,
                open && classes.menuButtonHidden
              )}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              // component="h1"
              variant="h6"
              noWrap
              className={classes.title}
            >
              <div className={classes.ImageAlign}>
                <NavLink to="/dashboard">
                  <img src={DevxLogo} alt="" className={classes.devxlogo} />
                </NavLink>
                {role === 'security' && (
                  <IconButton
                    color="inherit"
                    className={classes.verticalIcon}
                    onClick={(e) => {
                      this.setState({ securityAnchorEl: e.currentTarget })
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                )}
              </div>
              <Menu
                id="simple-menu"
                anchorEl={securityAnchorEl}
                keepMounted
                open={isSecurityMenuOpen}
                onClose={this.handleMenuClose}
              >
                <MenuItem
                  component={NavLink}
                  to="/visitors"
                  onClick={this.props.handleMenuClose}
                >
                  {' '}
                  Visitors
                </MenuItem>
              </Menu>
            </Typography>

            <div className={classes.spaceMenu}>
              {/* {checkPermission('space_menu') && <SpaceMenu edge="start" />} */}
              {(viewSpaceMenu === 'true' || viewSpaceMenu === 'yes') && (
                <SpaceMenu edge="start" />
              )}
            </div>

            {/* add devx store */}
            {/* <Grid item xs={1} md={2}>
              <ListItem
                button
                dense
                component={Link}
                to="/devx-store"
                key="devx-store"
                className={classNames(classes.itemActionable)}
                onClick={(event) =>
                  this.handleListItemClick(event, DEVXSTORE)
                }
              >
                <ListItemIcon className={classes.naviIcons}>
                  <Store size={20} />
                </ListItemIcon>
                <ListItemText
                  classes={{
                    primary: classes.itemPrimary,
                  }}
                />
              </ListItem>
            </Grid> */}
            {role !== 'cafe' && role !== 'security' ? (
              <IconButton
                color="inherit"
                onClick={(e) => {
                  this.setState({ anchorElNotification: e.currentTarget })
                  this.props.getNotification()
                }}
              >
                <NotificationsNoneOutlinedIcon
                  className={classes.notificationIcon}
                />
                {/* Notification count Design Release 
                <span className={classes.notificationCount}> 3</span>  */}
              </IconButton>
            ) : (
              ''
            )}

            <IconButton
              color="inherit"
              onClick={(e) => {
                this.setState({ anchorEl: e.currentTarget })
              }}
            >
              {user && user.profile_pic ? (
                <img
                  src={user.profile_pic}
                  alt=""
                  width="100%"
                  height="200px"
                  className={classes.profileIcon}
                />
              ) : (
                <AccountCircle className={classes.profileIcon} />
              )}
            </IconButton>

            <RenderMenu
              anchorEl={this.state.anchorEl}
              isMenuOpen={isMenuOpen}
              handleMenuClose={this.handleMenuClose}
              logout={this.logout}
              username={username}
              userpin={
                user && user.user_id && user.user_id.pin ? user.user_id.pin : ''
              }
            />
            <RenderNotificationMenu
              anchorElNotification={this.state.anchorElNotification}
              isNotificationOpen={isNotificationOpen}
              handleNotificationClose={this.handleNotificationClose}
              // logout={this.logout}
              // username={username}
              // userpin={
              //   user && user.user_id && user.user_id.pin ? user.user_id.pin : ""
              // }
              data={allNotification}
            />
          </Toolbar>
          {loading && <Loader className={classes.systemLoader} />}
        </AppBar>
        {renderMobileMenu}
      </React.Fragment>
    )
    return comp
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  user: state.user.user,
  allNotification: state.user.notificationData,
})

export default connect(mapStateToProps, {
  logoutUser,
  clearCurrentProfile,
  getUserData,
  getNotification,
})(withStyles(styles, { withTheme: true })(withRouter(Header)))
