import {
  LIST_LOADING,
  GET_LIST_AMBASSDOR,
  GET_ONE_LIST_AMBASSDOR,
  LIST_ERROR,
  ADD_CONFIRMATION,
  UPDATE_STATUS,
  APPLY_NOW,
} from '../Actions/types'

const initialState = {
  loading: false,
  community_embassador: null,
  community_embassadors: [],
  community_one_embassadors: [],
  community_embassador_status: {},
  applynow: {},
}

export default function (state = initialState, action) {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case LIST_LOADING:
      return {
        ...state,
        loading: true,
      }
    case LIST_ERROR:
      return {
        ...state,
        loading: false,
        community_embassador: {
          success: false,
          data: action.payload && action.payload.data,
        },
      }
    case GET_LIST_AMBASSDOR:
      return {
        ...state,
        loading: false,
        community_embassadors: action.payload && action.payload.data,
      }
    case GET_ONE_LIST_AMBASSDOR:
      return {
        ...state,
        loading: false,
        community_one_embassadors: action.payload && action.payload.data,
      }
    case UPDATE_STATUS:
      return {
        ...state,
        loading: false,
        community_embassador_status: action.payload && action.payload.data,
      }
    case ADD_CONFIRMATION:
      return {
        ...state,
        loading: false,
        community_embassador: { success: true, data: action.payload },
      }
    case APPLY_NOW:
      return {
        ...state,
        loading: false,
        applynow: action.payload && action.payload,
      }
    default:
      return state
  }
}
