/* eslint-disable camelcase */
import axios from 'axios'
import {
  BOOKING_ANALYSIS_REPORT,
  COMPANY_RESOURCE_BOOKING_LOG,
  COMPANY_RESOURCE_BOOKING_ANALYSIS,
  REPORT_ANALYSIS_ERROR,
  REPORT_ANALYSIS_LOADING,
  VISITOR_LOGS,
  VISITOR_ANALYSIS,
  CLIENTS_VISITOR_ANALYSIS,
  COMPANY_PLAN_ANAYSIS,
  WALKTHROUGH_LOG,
  // WALKTHROUGH_ANALYSIS,
  MONTHLY_CAFE_CONSUMPTION_ANALYSIS,
  GET_SPACE_COMPANY,
  WALKTHROUGH_ANALYSIS,
  COWORKER_LOG,
  ISSUE_TICKET_ANALYSIS,
} from './types'
import { showSuccess, showError } from './ActionsHelper'

// Report Bug Loading
export const setReportAnalysisLoading = () => {
  return {
    type: REPORT_ANALYSIS_LOADING,
  }
}

export const addBookingAnalysis = (userData, setSubmitting) => (dispatch) => {
  dispatch(setReportAnalysisLoading())
  axios
    .post('/api/reports/booking/analysis', userData)
    .then((result) => {
      dispatch({
        type: BOOKING_ANALYSIS_REPORT,
        payload: result.data,
      })
      if (result.data.data.length <= 0) {
        dispatch(showSuccess('', 'No data available for selected date.'))
      } else {
        dispatch(
          showSuccess('', 'Booking Analysis Report Downloaded successfully!')
        )
      }
      setSubmitting(false)
    })
    .catch((err) => {
      dispatch({
        type: REPORT_ANALYSIS_ERROR,
        payload: err && err.response ? err.response.data.data : '',
      })
      dispatch(
        showError(
          err.response && err.response.data && err.response.data.data
            ? err.response.data.data
            : 'An error while exporting report'
        )
      )
    })
}

export const companyResourceBookingLog = (userData, setSubmitting) => (
  dispatch
) => {
  dispatch(setReportAnalysisLoading())
  axios
    .post('/api/reports/booking/resource/company/log', userData)
    .then((result) => {
      dispatch({
        type: COMPANY_RESOURCE_BOOKING_LOG,
        payload: result.data,
      })
      if (result.data.data.length <= 0) {
        dispatch(showSuccess('', 'No data available for selected date.'))
      } else {
        dispatch(
          showSuccess('', 'Company resource booking downloaded successfully!')
        )
      }
      setSubmitting(false)
    })
    .catch((err) => {
      dispatch({
        type: REPORT_ANALYSIS_ERROR,
        payload: err && err.response ? err.response.data.data : '',
      })
      dispatch(
        showError(
          err.response && err.response.data && err.response.data.data
            ? err.response.data.data
            : 'An error while exporting report'
        )
      )
    })
}

export const companyResourceBookingAnalysis = (userData, setSubmitting) => (
  dispatch
) => {
  dispatch(setReportAnalysisLoading())
  axios
    .post('/api/reports/booking/company/resource/analysis', userData)
    .then((result) => {
      dispatch({
        type: COMPANY_RESOURCE_BOOKING_ANALYSIS,
        payload: result.data,
      })
      if (result.data.data.length <= 0) {
        dispatch(showSuccess('', 'No data available for selected date.'))
      } else {
        dispatch(
          showSuccess('', 'Company Resource Booking Downloaded successfully!')
        )
      }
      setSubmitting(false)
    })
    .catch((err) => {
      dispatch({
        type: REPORT_ANALYSIS_ERROR,
        payload: err && err.response ? err.response.data.data : '',
      })
      dispatch(
        showError(
          err.response && err.response.data && err.response.data.data
            ? err.response.data.data
            : 'An error while exporting report'
        )
      )
    })
}

export const visitorLogsData = (userData, setSubmitting) => (dispatch) => {
  dispatch(setReportAnalysisLoading())
  axios
    .post('/api/reports/visitors/logs', userData)
    .then((result) => {
      dispatch({
        type: VISITOR_LOGS,
        payload: result.data,
      })
      if (result.data.data.length <= 0) {
        dispatch(showSuccess('', 'No data available for selected date.'))
      } else {
        dispatch(showSuccess('', 'Visitor logs Downloaded successfully!'))
      }
      setSubmitting(false)
    })
    .catch((err) => {
      dispatch({
        type: REPORT_ANALYSIS_ERROR,
        payload: err && err.response ? err.response.data.data : '',
      })
      dispatch(
        showError(
          err.response && err.response.data && err.response.data.data
            ? err.response.data.data
            : 'An error while exporting report'
        )
      )
    })
}

export const visitorAnalysisData = (userData, setSubmitting) => (dispatch) => {
  dispatch(setReportAnalysisLoading())
  axios
    .post('/api/reports/visitors/analysis', userData)
    .then((result) => {
      dispatch({
        type: VISITOR_ANALYSIS,
        payload: result.data,
      })
      if (result.data.data.length <= 0) {
        dispatch(showSuccess('', 'No data available for selected date.'))
      } else {
        dispatch(showSuccess('', 'Visitor Analysis Downloaded successfully!'))
      }
      setSubmitting(false)
    })
    .catch((err) => {
      dispatch({
        type: REPORT_ANALYSIS_ERROR,
        payload: err && err.response ? err.response.data.data : '',
      })
      dispatch(
        showError(
          err.response && err.response.data && err.response.data.data
            ? err.response.data.data
            : 'An error while exporting report'
        )
      )
    })
}

export const clientsVisitorAnalysisData = (userData, setSubmitting) => (
  dispatch
) => {
  dispatch(setReportAnalysisLoading())
  axios
    .post('/api/reports/visitors/client/analysis', userData)
    .then((result) => {
      dispatch({
        type: CLIENTS_VISITOR_ANALYSIS,
        payload: result.data,
      })
      if (result.data.data.length <= 0) {
        dispatch(showSuccess('', 'No data available for selected date.'))
      } else {
        dispatch(
          showSuccess('', 'Client Visitor Analysis Downloaded successfully!')
        )
      }
      setSubmitting(false)
    })
    .catch((err) => {
      dispatch({
        type: REPORT_ANALYSIS_ERROR,
        payload: err && err.response ? err.response.data.data : '',
      })
      dispatch(
        showError(
          err.response && err.response.data && err.response.data.data
            ? err.response.data.data
            : 'An error while exporting report'
        )
      )
    })
}

export const monthlyCafeConsumptionAnalysis = (userData, setSubmitting) => (
  dispatch
) => {
  dispatch(setReportAnalysisLoading())
  axios
    .post('/api/reports/cafe/consumption/analysis', userData)
    .then((result) => {
      dispatch({
        type: MONTHLY_CAFE_CONSUMPTION_ANALYSIS,
        payload: result.data,
      })
      if (result.data.data.length <= 0) {
        dispatch(showSuccess('', 'No data available for selected date.'))
      } else {
        dispatch(
          showSuccess(
            '',
            'Monthly cafe consumption analysis downloaded successfully!'
          )
        )
      }
      setSubmitting(false)
    })
    .catch((err) => {
      dispatch({
        type: REPORT_ANALYSIS_ERROR,
        payload: err && err.response ? err.response.data.data : '',
      })
      dispatch(
        showError(
          err.response && err.response.data && err.response.data.data
            ? err.response.data.data
            : 'An error while exporting report'
        )
      )
    })
}

export const companyPlanAnalysisData = (userData, setSubmitting) => (
  dispatch
) => {
  dispatch(setReportAnalysisLoading())
  axios
    .post('/api/reports/company/plan/analysis', userData)
    .then((result) => {
      dispatch({
        type: COMPANY_PLAN_ANAYSIS,
        payload: result.data,
      })
      if (result.data.data.length <= 0) {
        dispatch(showSuccess('', 'No data available for selected date.'))
      } else {
        dispatch(
          showSuccess('', 'Company plan Analysis Downloaded successfully!')
        )
      }
      setSubmitting(false)
    })
    .catch((err) => {
      dispatch({
        type: REPORT_ANALYSIS_ERROR,
        payload: err && err.response ? err.response.data.data : '',
      })
      dispatch(
        showError(
          err.response && err.response.data && err.response.data.data
            ? err.response.data.data
            : 'An error while exporting report'
        )
      )
    })
}

export const walkthroughLogData = (userData, setSubmitting) => (dispatch) => {
  dispatch(setReportAnalysisLoading())
  axios
    .post('/api/reports/walkthrough/logs', userData)
    .then((result) => {
      dispatch({
        type: WALKTHROUGH_LOG,
        payload: result.data,
      })
      if (result.data.data.length <= 0) {
        dispatch(showSuccess('', 'No data available for selected date.'))
      } else {
        dispatch(showSuccess('', 'Walkthrough Log Downloaded successfully!'))
      }
      setSubmitting(false)
    })
    .catch((err) => {
      dispatch({
        type: REPORT_ANALYSIS_ERROR,
        payload: err && err.response ? err.response.data.data : '',
      })
      dispatch(
        showError(
          err.response && err.response.data && err.response.data.data
            ? err.response.data.data
            : 'An error while exporting report'
        )
      )
    })
}

export const walkthroughAnalysisData = (userData, setSubmitting) => (
  dispatch
) => {
  dispatch(setReportAnalysisLoading())
  axios
    .post('/api/reports/walkthrough/analysis', userData)
    .then((result) => {
      dispatch({
        type: WALKTHROUGH_ANALYSIS,
        payload: result.data,
      })
      if (result.data.data.length <= 0) {
        dispatch(showSuccess('', 'No data available for selected date.'))
      } else {
        dispatch(
          showSuccess('', 'Walkthrough Analysis Downloaded successfully!')
        )
      }
      setSubmitting(false)
    })
    .catch((err) => {
      dispatch({
        type: REPORT_ANALYSIS_ERROR,
        payload: err && err.response ? err.response.data.data : '',
      })
      dispatch(
        showError(
          err.response && err.response.data && err.response.data.data
            ? err.response.data.data
            : 'An error while exporting report'
        )
      )
    })
}

export const getMultipleSpaceCompany = (spaceId) => (dispatch) => {
  dispatch(setReportAnalysisLoading())
  axios
    .post(`/api/company/by/multiple/spaces`, { space_ids: spaceId })
    .then((result) => {
      dispatch({
        type: GET_SPACE_COMPANY,
        payload: result.data,
      })
    })
    .catch((err) => {
      dispatch({
        type: REPORT_ANALYSIS_ERROR,
        payload: err && err.response ? err.response.data : '',
      })
    })
}

export const coworkerLogsData = (userData, setSubmitting) => (dispatch) => {
  dispatch(setReportAnalysisLoading())
  axios
    .post('/api/reports/coworker/logs', userData)
    .then((result) => {
      dispatch({
        type: COWORKER_LOG,
        payload: result.data,
      })
      if (result.data.data.length <= 0) {
        dispatch(showSuccess('', 'No data available for selected date.'))
      } else {
        dispatch(showSuccess('', 'Coworker Log Downloaded successfully!'))
      }
      setSubmitting(false)
    })
    .catch((err) => {
      dispatch({
        type: REPORT_ANALYSIS_ERROR,
        payload: err && err.response ? err.response.data.data : '',
      })
      dispatch(
        showError(
          err.response && err.response.data && err.response.data.data
            ? err.response.data.data
            : 'An error while exporting report'
        )
      )
    })
}

export const issueTicketAnalysis = (userData, setSubmitting) => (dispatch) => {
  dispatch(setReportAnalysisLoading())
  axios
    .post('/api/reports/helpdesk/tickets/logs', userData)
    .then((result) => {
      dispatch({
        type: ISSUE_TICKET_ANALYSIS,
        payload: result.data,
      })
      if (result.data.data.length <= 0) {
        dispatch(showSuccess('', 'No data available for selected date.'))
      } else {
        dispatch(
          showSuccess('', 'Issue Ticket Analysis Downloaded successfully!')
        )
      }
      setSubmitting(false)
    })
    .catch((err) => {
      dispatch({
        type: REPORT_ANALYSIS_ERROR,
        payload: err && err.response ? err.response.data.data : '',
      })
      dispatch(
        showError(
          err.response && err.response.data && err.response.data.data
            ? err.response.data.data
            : 'An error while exporting report'
        )
      )
    })
}
