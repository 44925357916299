/* eslint-disable consistent-return */
import React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Paper,
  Typography,
  CssBaseline,
  withStyles,
} from "@material-ui/core";
import classNames from "classnames";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import CreateIcon from "@material-ui/icons/Create";
import defaultLogo from "../../Assets/default_logo.png";
import { getPartner } from "../../Actions/partnerActions";
import {
  Instagram,
  Linkedin,
  Twitter,
  FacebookRoundIcon,
  FacebookGrey,
  InstagramGrey,
  TwitterGrey,
  LinkedinGrey,
  Website,
  WebsiteGrey,
} from "../../Assets/index";
import { checkPermission } from "../../Utils/checkPermission";
import { capitalize, spacecapitalize } from "../../Utils/StringFunctions";
import NameTruncate from "../../Components/Common/NameTruncate";
import SocialButton from "../../Components/Common/SocialButton";

const moment = require("moment");

const $quoteSym = " ";
const styles = (theme) => {
  const borderRadius = theme.shape.borderRadius * 4;
  return {
    heroContent: {
      maxWidth: 600,
      margin: "0 auto",
      padding: `${theme.spacing(15)}px 0 ${theme.spacing(15)}px`,
    },
    orangeAvatar: {
      color: "#fff",
      backgroundColor: "white",
      width: "70%",
      height: 87,
      fontSize: "36px",
      position: "relative",
      top: "-30px",
      left: "10%",
      border: "1px solid",
      borderRadius: "4px",
      objectFit: "contain",
    },
    banner: { maxHeight: "300px" },
    eventTitle: {
      margin: "14px 0px 0px -8px",
      paddingBottom: "8px",
    },
    eventsubtitle: {
      fontSize: "12px",
      color: "grey",
      paddingTop: "4px",
    },
    eventsubtitle1: {
      color: "#bfbfbf",
      fontSize: "14px",
      textTransform: "capitalize",
      lineHeight: 1.3,
    },
    eventbasicdetail: {
      backgroundColor: "#fff",
      position: "relative",
    },
    iconContainer: {
      marginTop: "10px",
      justifyContent: "flex-end",
      paddingRight: "16px",
    },
    social: {
      height: "26px",
      width: "26px",
      marginRight: "14px",
    },
    mainabout: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },

    applicationHeader: {
      margin: "20px",
      paddingBottom: "5px",
      fontSize: "16px",
      fontWeight: "600",
      position: "relative",
      "&:after": {
        top: "31px",
        left: "1px",
        width: "24px",
        borderBottom: "3px solid #fb0",
        content: `'${$quoteSym}'`,
        position: "absolute",
      },
    },
    offerHeader: {
      paddingBottom: "5px",
      fontSize: "20px",
      fontWeight: "600",
      position: "relative",
      wordWrap: "break-word",
      width: "100%",
    },
    aboutinnerdetail: {
      padding: "15px 32px 15px 1px",
      marginLeft: "15px",
    },

    aboutinnercontent: {
      padding: "25px 0px 15px 10px",
      marginLeft: "10px",
    },
    button: {
      margin: "0px 10px 0px 0px",
      borderRadius: "20px",
      backgroundColor: "#efefef",
      color: "#626262",
      cursor: "default",
      "&:hover": {
        backgroundColor: "#efefef",
        color: "#626262",
      },
    },
    offerimage: {
      minHeight: "170px",
      height: "300px",
      width: "100%",
      backgroundColor: "whitesmoke",
    },
    offercontainer: {
      paddingTop: "4%",
      paddingBottom: "4%",
    },
    offerdescription: {
      fontSize: "14px",
      color: "#626262",
      textAlign: "justify",
      marginTop: "0%",
      "& *": {
        whiteSpace: "pre-wrap",
      },
    },
    headerspacing: {
      // paddingTop: "15px"
    },
    blankdisplay: {
      color: "#c0c0c0",
      textAlign: "center",
      background: "#f0f0f0",
      height: "300px",
    },
    imagedisplay: {
      color: "#c0c0c0",
      textAlign: "center",
      height: "300px",
    },
    presentationTitle: {
      color: "lightgrey",
      fontSize: "22px",
      fontWeight: "600",
      paddingTop: "14px",
    },
    downloadbutton: {
      margin: theme.spacing(1),
      fontSize: 10,
      border: "1px solid #4682B4",
      borderRadius: `${borderRadius}px`,
      backgroundColor: "#fff",
      color: "#4682B4",
      "&:hover": {
        backgroundColor: "#f7f2ff !important",
        color: "#4682B4",
        border: "1px solid #4682B4",
      },
      "&:disabled": {
        border: " 1px solid rgba(0, 0, 0, 0.26)",
      },
    },
    downloadIcon: {
      height: "35%",
      width: "35%",
      padding: "8px",
    },
    media: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "0px",
    },
    downloadSection: {
      textAlign: "center",
      position: "relative",
      top: "-25%",
      right: "0%",
    },
    paperDownload: {
      height: 140,
      width: 230,
      padding: theme.spacing(2),
      textAlign: "center",
      backgroundColor: "#fff",
      border: "1px solid #efefef",
      borderRadius: "5px",
      marginTop: "17px",
    },
    offerDiv: {
      // marginLeft: "-2.8%"
    },
    eventmaintitle: {
      fontSize: "18px",
    },
    edit_link: {
      textDecoration: "none",
      color: "grey",
      border: "1px solid #d1d1d1",
      borderRadius: "56px 38px 54px 100px",
      borderTop: "none",
      borderRight: "none",
      backgroundColor: "#d1d1d1",
      padding: "2px 4px 1px 5px",
      // position: "absolute",
      top: "-2px",
      right: "-4px",
    },
    editSection: {
      display: "flex",
      justifyContent: "space-between",
    },
    partnerContent: {
      fontSize: "14px",
      lineHeight: 1.3,
    },
  };
};

class ViewPartner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      partner: [],
    };
    this.state.id = this.props.match.params.id;
  }

  componentDidMount() {
    this.props.getPartner(this.state.id);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.partner !== prevState.partner) {
      return { partner: nextProps.partner };
    }
    return [];
  }

  componentDidUpdate(prevProps) {
    if (prevProps.partner !== this.props.partner) {
      this.setState({
        partner: this.props.partner,
      });
    }
  }

  render() {
    const { classes } = this.props;
    const { partner } = this.state;
    const { offers } = partner;

    let categories =
      partner.categories &&
      partner.categories.map((val) => {
        return spacecapitalize(val);
      });
    categories = categories && categories.join(" | ");

    const renderOffers = () => {
      let i = 0;
      return (
        offers &&
        // eslint-disable-next-line array-callback-return
        offers.map(() => {
          if (i < offers.length) {
            const key = i;
            i += 2;
            return (
              <div className={classes.offerDiv}>
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  className={classes.offercontainer}
                  justify="space-evenly"
                >
                  <Grid
                    item
                    xs={5}
                    className={
                      offers[key].offer_banner
                        ? classes.imagedisplay
                        : classes.blankdisplay
                    }
                  >
                    {offers[key].offer_banner ? (
                      <img
                        className={classes.offerimage}
                        src={offers[key].offer_banner}
                        alt=""
                      />
                    ) : (
                      <span
                        className={classes.offerHeader}
                        style={{ height: "100%", top: "40%" }}
                      >
                        {capitalize(offers[key].offers_title)}
                      </span>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <div className={classes.editSection}>
                      <span className={classes.offerHeader}>
                        <NameTruncate
                          text={capitalize(offers[key].offers_title)}
                          amount={40}
                        />
                      </span>

                      {checkPermission("PARTNEROFFERS_UPDATE") && (
                        <Link
                          className={classes.edit_link}
                          to={`/partner-offer/edit/${offers[key]._id}`}
                        >
                          <CreateIcon fontSize="small" />
                        </Link>
                      )}
                    </div>
                    <span>
                      {`Valid Till ${moment(offers[key].valid_upto).format(
                        "DD/MM/YYYY"
                      )}`}
                    </span>
                    <div className={classes.headerspacing}>
                      <div
                        className={classes.offerdescription}
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: offers[key].offer_description,
                        }}
                      />
                    </div>
                  </Grid>

                  {offers[key + 1] && (
                    <React.Fragment>
                      <Grid
                        container
                        direction="row-reverse"
                        spacing={4}
                        className={classes.offercontainer}
                        justify="space-evenly"
                      >
                        <Grid
                          item
                          xs={5}
                          className={
                            offers[key + 1].offer_banner
                              ? classes.imagedisplay
                              : classes.blankdisplay
                          }
                        >
                          {offers[key + 1].offer_banner ? (
                            <img
                              className={classes.offerimage}
                              src={offers[key + 1].offer_banner}
                              alt=""
                            />
                          ) : (
                            <span
                              className={classes.offerHeader}
                              style={{ height: "100%", top: "40%" }}
                            >
                              {capitalize(offers[key + 1].offers_title)}
                            </span>
                          )}
                        </Grid>
                        <Grid item xs={5}>
                          <div className={classes.editSection}>
                            <span className={classes.offerHeader}>
                              <NameTruncate
                                text={spacecapitalize(
                                  offers[key + 1].offers_title
                                )}
                                amount={40}
                              />
                            </span>

                            {checkPermission("PARTNER_UPDATE") && (
                              <Link
                                className={classes.edit_link}
                                to={`/partner-offer/edit/${
                                  offers[key + 1]._id
                                }`}
                              >
                                <CreateIcon fontSize="small" />
                              </Link>
                            )}
                          </div>
                          <span>
                            {`Valid Till ${moment(
                              offers[key + 1].valid_upto
                            ).format("DD/MM/YYYY")}`}
                          </span>
                          <div className={classes.headerspacing}>
                            <div
                              className={classes.offerdescription}
                              // eslint-disable-next-line react/no-danger
                              dangerouslySetInnerHTML={{
                                __html: offers[key + 1].offer_description,
                              }}
                            />
                          </div>
                        </Grid>{" "}
                      </Grid>
                    </React.Fragment>
                  )}
                </Grid>
              </div>
            );
          }
        })
      );
    };

    return (
      <React.Fragment>
        <CssBaseline />
        <main>
          {/* <div
            style={{
              // background: `url(${partner.company_banner})  center center no-repeat fixed`,
              // backgroundSize: "cover",
              backgroundImage: `url(${partner.company_banner})`,
              backgroundSize: "contain",
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
              height: "300px"
            }}
          ></div> */}
          <div className={classes.banner}>
            <img
              src={partner.banner || partner.company_banner}
              alt=""
              width="100%"
              height="50%"
              className={classes.banner}
            />
          </div>
          <div className={classNames(classes.layout, classes.cardGrid)}>
            {/* End hero unit */}
            <div className={classes.eventbasicdetail}>
              <Grid
                container
                item
                lg={12}
                xs={12}
                style={{ paddingLeft: "20px", paddingRight: "20px" }}
              >
                <Grid item lg={2} xs={2}>
                  <div className={classes.logo}>
                    <img
                      className={classes.orangeAvatar}
                      src={
                        partner.company_logo
                          ? partner.company_logo
                          : defaultLogo
                      }
                      alt="logo"
                    />
                  </div>
                </Grid>
                <Grid item lg={6} xs={6}>
                  <div className={classes.eventTitle}>
                    <Typography
                      variant="body1"
                      component="p"
                      className={classes.eventmaintitle}
                    >
                      {spacecapitalize(partner.company_name)}
                    </Typography>
                    <Typography
                      className={classes.eventsubtitle1}
                      variant="subtitle1"
                      component="p"
                    >
                      {categories}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={4} lg={4}>
                  <Grid
                    container
                    className={classes.iconContainer}
                    justify="center"
                    spacing={2}
                  >
                    <Grid item>
                      <Paper className={classes.paper}>
                        {partner.linkedin ? (
                          <SocialButton
                            type={Linkedin}
                            url={partner.linkedin}
                          />
                        ) : (
                          <SocialButton type={LinkedinGrey} disable />
                        )}
                      </Paper>
                    </Grid>
                    <Grid item>
                      <Paper className={classes.paper}>
                        {partner.facebook ? (
                          <SocialButton
                            type={FacebookRoundIcon}
                            url={partner.facebook}
                          />
                        ) : (
                          <SocialButton type={FacebookGrey} disable />
                        )}
                      </Paper>
                    </Grid>
                    <Grid item>
                      <Paper className={classes.paper}>
                        {partner.twitter ? (
                          <SocialButton type={Twitter} url={partner.twitter} />
                        ) : (
                          <SocialButton type={TwitterGrey} disable />
                        )}
                      </Paper>
                    </Grid>
                    <Grid item>
                      <Paper className={classes.paper}>
                        {partner.instagram ? (
                          <SocialButton
                            type={Instagram}
                            url={partner.instagram}
                          />
                        ) : (
                          <SocialButton type={InstagramGrey} disable />
                        )}
                      </Paper>
                    </Grid>
                    <Grid item>
                      <Paper className={classes.paper}>
                        {partner.website ? (
                          <SocialButton type={Website} url={partner.website} />
                        ) : (
                          <SocialButton type={WebsiteGrey} disable />
                        )}
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
          <div className={classes.mainabout}>
            <div className={classes.heroUnit}>
              <span className={classes.applicationHeader}>About</span>
            </div>
            <Grid
              className={classes.aboutinnerdetail}
              container
              item
              xs={12}
              lg={12}
              spacing={2}
            >
              <Grid item xs={12} lg={12}>
                <div
                  className={classes.partnerContent}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: partner.description }}
                />
              </Grid>
              {/* TODO : IN NEXT RELEASE  AS PER DISCUSSION WITH VAIDEHI AND PARTHSIR */}
              {/* <Grid item xs={5} lg={5}>
                <Grid container className={classes.root} spacing={2}>
                  <Grid item xs={12}>
                    <Grid
                      container
                      className={classes.demo}
                      justify="center"
                      spacing={2}
                    >
                      <Grid item>
                        <Paper className={classes.paperDownload}>
                          {partner.presentation && (
                            <Typography
                              className={classNames(
                                classes.presentationTitle,
                                classes.media
                              )}
                              component="p"
                            >
                              <img
                                src={PDF}
                                alt=""
                                className={classes.downloadIcon}
                              />
                              <img
                                src={PPT}
                                alt=""
                                className={classes.downloadIcon}
                              />
                            </Typography>
                          )}
                          {!partner.presentation && (
                            <Typography
                              className={classNames(
                                classes.presentationTitle,
                                classes.media
                              )}
                              component="p"
                            >
                              <img
                                src={PDF_BW}
                                alt=""
                                className={classes.downloadIcon}
                              />
                              <img
                                src={PPT_BW}
                                alt=""
                                className={classes.downloadIcon}
                              />
                            </Typography>
                          )}
                        </Paper>
                        <div className={classes.downloadSection}>
                          {partner.presentation && (
                            <a
                              style={{ textDecoration: "none" }}
                              href={partner.presentation}
                              // eslint-disable-next-line react/jsx-no-target-blank
                              target="_blank"
                              // className={classes.downloadbutton}
                              download
                            >
                              <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                className={classes.downloadbutton}
                              >
                                Presentation Download
                              </Button>
                            </a>
                          )}
                          {!partner.presentation && (
                            <Button
                              size="small"
                              variant="contained"
                              color="primary"
                              className={classes.downloadbutton}
                              disabled
                            >
                              Presentation not Available
                            </Button>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid> */}
            </Grid>
            {partner.services && partner.services.length > 0 && (
              <div className={classes.heroUnit}>
                <span className={classes.applicationHeader}>Services</span>
              </div>
            )}
            <div className={classes.aboutinnercontent}>
              {partner.services &&
                partner.services.map((el) => {
                  return (
                    <Button
                      variant="contained"
                      size="small"
                      className={classes.button}
                    >
                      {capitalize(el && el.label)}
                    </Button>
                  );
                })}
            </div>

            {checkPermission("PARTNEROFFERS_VIEW") &&
              offers &&
              offers.length > 0 && (
                <div className={classes.heroUnit}>
                  <span className={classes.applicationHeader}>Offers</span>
                  {renderOffers(offers)}
                </div>
              )}
          </div>
        </main>
      </React.Fragment>
    );
  }
}

ViewPartner.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    partner: state.partners.partner,
  };
};

export default connect(mapStateToProps, { getPartner })(
  withStyles(styles)(ViewPartner)
);
