import React, { useState } from "react";
import {
  Card,
  CardActions,
  CardContent,
  Typography,
  withStyles,
  Paper,
  Grid,
  Button,
} from "@material-ui/core";
import { MapPin } from "react-feather";
import { Link } from "react-router-dom";
import CreateIcon from "@material-ui/icons/Create";
import classNames from "classnames";
import { connect } from "react-redux";
import { FacebookRoundIcon, FacebookGrey } from "../../../Assets/index";
import { capitalize, spacecapitalize } from "../../../Utils/StringFunctions";
// import SpocDetailsPopup from "Screens/Events/SpocDetailsPopup";
import { checkPermission } from "../../../Utils/checkPermission";
import { eventParticipants } from "../../../Actions/eventActions";
import NameTruncate from "../NameTruncate";
import SelectTeamOptionModal from "../../../Screens/Events/SelectTeamOptionModal";

const moment = require("moment");

const styles = (theme) => {
  const borderRadius = theme.shape.borderRadius * 4;

  return {
    card: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      boxShadow: "0px 0px 20px rgba(0,0,0,0.05)",
      transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
      backgroundColor: "#FCFDFE",
      position: "relative",
      border: "1px solid #f3f3f3",
      "&:hover": {
        transform: "scale(1.01)",
        boxShadow:
          "rgb(178 183 187 / 6%) 0px 2px 4px 6px, rgb(212 219 226 / 60%) 0px 2px 10px 0px",
      },
    },
    cardContent: {
      flexGrow: 1,
      "& p": {
        color: theme.palette.font.head1,
        textAlign: "left",
        fontSize: "12px",
      },
      "& h2": {
        color: "#191919",
        fontSize: "14px",
      },
      "& h4": {
        color: theme.palette.font.head2,
        fontWeight: "normal",
      },
    },
    containerPaper: {
      border: `1px solid ${theme.palette.border.color}`,
    },
    image: {
      minHeight: "170px",
      height: "200px",
      width: "100%",
      objectFit: "fill",
      backgroundColor: "whitesmoke",
    },
    iconContainer: {
      // marginTop: "10px"
    },
    cardFooter: {
      display: "flex",
      margin: " 0 auto",
      alignItems: "flex-end",
      flexGrow: 1,
      "& a": {
        textDecoration: "none",
      },
    },
    social: {
      height: "20px",
      width: "20px",
      marginRight: "5px",
    },
    avatar: {
      height: "25px",
      width: "25px",
      borderRadius: "50%",
    },
    typo: {
      fontSize: "12px",
      margin: "-4px 2px 0px 0px",
    },
    event_title: {
      fontWeight: "bold",
      minHeight: "24px",
    },
    people_attending: {
      fontSize: "12px",
      margin: "-1px 2px 0px 0px",
      fontStyle: "italic",
    },
    designation: {
      fontSize: "13px",
    },
    highlight: {
      color: "#211b4e !important",
      fontWeight: 500,
    },
    avataricon: {
      margin: "16px 0px",
    },
    start_title: {
      color: "#ABABAB !important",
    },
    date: {
      color: "#646464 !important",
      fontWeight: 400,
      textTransform: "capitalize",
      fontSize: "14px !important",
    },
    edit_link: {
      textDecoration: "none",
      color: "grey",
      border: "1px solid #d1d1d1",
      borderRadius: "56px 38px 54px 100px",
      borderTop: "none",
      borderRight: "none",
      backgroundColor: "#d1d1d1",
      padding: "2px 4px 1px 5px",
      position: "absolute",
      top: "-2px",
      right: "-4px",
    },
    edit_company: {
      display: "flex",
      justifyContent: "flex-end",
    },
    editbutton: {
      height: "39px",
      width: "41px",
      backgroundColor: "#41bdff",
      margin: "6px 10px 0px",
    },
    paidhighlight: {
      color: "#FF6D00 !important",
      fontWeight: 500,
    },
    viewbutton: {
      margin: theme.spacing(1),
      fontSize: 10,
      border: "1px solid #959595",
      borderRadius: `${borderRadius}px`,
      backgroundColor: "#fff",
      color: "#959595",
      "&:hover": {
        backgroundColor: "#fff",
      },
    },

    registerbutton: {
      margin: theme.spacing(1),
      fontSize: 10,
      border: "1px solid #211b4e",
      borderRadius: `${borderRadius}px`,
      backgroundColor: "#ffffff",
      color: "#211b4e",
      "&:hover": {
        backgroundColor: "#ffffff",
      },
      "&:disabled": {
        backgroundColor: "lightgrey !important",
        border: "1px solid lightgrey",
      },
    },
    feedbackButton: {
      margin: theme.spacing(1),
      fontSize: 10,
      border: "1px solid #FFCA28",
      borderRadius: `${borderRadius}px`,
      backgroundColor: "#FFCA28",
      color: "#ffffff",
      "&:hover": {
        backgroundColor: "#FFCA28",
      },
      "&:disabled": {
        backgroundColor: "lightgrey !important",
        border: "1px solid lightgrey",
      },
    },
    time_duration: {
      color: "#646464 !important",
      fontWeight: 400,
    },
    dateFonts: {
      fontSize: "12px",
    },
    eventCategory: {
      display: "flex",
      justifyContent: "space-between",
      padding: "10px 0px",
    },
    facebookButton: {
      marginLeft: "6px",
    },
    locationIcon: {
      position: "relative",
      top: "2px",
    },
  };
};

function EventCard(props) {
  const { classes, data, history, view, handleClickOpen } = props;
  const [modalOpen, setmodalOpen] = useState(false);
  // const [spocModalOpen, setSpocModalOpen] = useState(false);
  // const [resendLink, setResendLink] = useState(false);
  // const [disabledLink, setDisabledLink] = useState(false);
  const [eventID, seteventID] = useState("");

  const eventTitle = Number(35);
  const titleName = Number(20);
  const buildingName = Number(30);
  const handleCardClick = () => {
    const path = `/event/${data._id}`;
    // eslint-disable-next-line no-unused-expressions
    if (view !== "security") {
      history.push(path);
    }
  };
  const handlejoinbutton = (eventid) => {
    handleClickOpen(eventid);
  };

  const handleClose = () => {
    setmodalOpen(false);
    seteventID("");
  };

  // const handleSpocModalClose = () => {
  //   setSpocModalOpen(false);
  // };

  const registerEvent = (id, eventHistory) => {
    if (data.event_type === "individual") {
      props.eventParticipants(id, eventHistory);
    } else {
      setmodalOpen(true);
      seteventID(id);
    }

    return null;
  };

  // const sendFeedback = () => {
  //   setSpocModalOpen(true);
  // };

  // const resendFeedbackLink = (flag) => {
  //   if (data.feedback_status === "yes") {
  //     setResendLink(true);
  //   } else {
  //     setDisabledLink(true);
  //   }
  // };

  const viewAttendees = (id) => {
    if (data.event_type === "individual") {
      props.history.push(`/events/attendees/${id}`);
    } else {
      return props.history.push(`/events/teamattendees/${id}`);
    }
    return null;
  };

  const a = moment().format("LLL");
  const b = moment(data.start_time).format("LLL");
  const currentDate = moment(a);
  const createdDate = moment(b);
  const remainingHours = currentDate.diff(createdDate, "minutes");

  const endTime = moment(data.end_time).format("LLL");
  const endTimeDate = moment(endTime);
  const registerRemainingHours = currentDate.diff(endTimeDate, "minutes");

  return (
    <React.Fragment>
      <Card className={classes.card}>
        <Paper className={classes.containerPaper}>
          {checkPermission("EVENT_UPDATE") &&
            // checkPermission("COWORKER_ADMIN_VIEW") &&
            remainingHours <= 0 && (
              <div className={classes.edit_company}>
                <Link
                  className={classes.edit_link}
                  to={`/event/edit/${data._id}`}
                >
                  <CreateIcon fontSize="small" />
                </Link>
              </div>
            )}
          {checkPermission("COWORKER_ADMIN_EDIT_VIEW") &&
            checkPermission("EVENT_UPDATE") &&
            remainingHours >= 0 && (
              <div className={classes.edit_company}>
                {/* <Link
                className={classes.edit_link}
                to={`/event/edit/${data._id}`}
              >
                <CreateIcon fontSize="small" />
              </Link> */}
              </div>
            )}
          {!checkPermission("COWORKER_ADMIN_VIEW") && remainingHours <= 0 && (
            <div className={classes.edit_company}>
              <Link
                className={classes.edit_link}
                to={`/event/edit/${data._id}`}
              >
                {checkPermission("COWORKER_ADMIN_EDIT_VIEW") && (
                  <CreateIcon fontSize="small" />
                )}
              </Link>
            </div>
          )}

          <div className={classes.profileImage}>
            <div>
              {view !== "security" ? (
                <Link to={`/event/${data._id}`}>
                  <img
                    src={
                      data.display_banner ? data.display_banner : data.banner
                    }
                    alt=""
                    className={classes.image}
                  />
                </Link>
              ) : (
                <img
                  src={data.display_banner}
                  alt=""
                  className={classes.image}
                />
              )}
            </div>
          </div>
          <CardContent
            className={classes.cardContent}
            onClick={handleCardClick}
          >
            <div direction="row">
              <Typography component="h2" className={classes.event_title}>
                <NameTruncate
                  text={capitalize(data.event_name)}
                  amount={eventTitle}
                />
              </Typography>
            </div>
            <Grid container direction="row" justify="flex-start">
              <Grid item>
                <Typography
                  component="p"
                  variant="subtitle1"
                  className={classes.typo}
                >
                  <MapPin size={12} className={classes.locationIcon} />
                  <NameTruncate
                    amount={titleName}
                    text={capitalize(
                      data.space_id && data.space_id.space_title
                    )}
                  />
                  <NameTruncate
                    amount={buildingName}
                    text={capitalize(
                      data.space_id && data.space_id.building_name
                    )}
                  />
                </Typography>
              </Grid>
            </Grid>
            {/* <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
              <Grid
                container
                className={classes.iconContainer}
                justify="flex-end"
                spacing={2}
              >
                <Grid item style={{ height: "10px" }}>
                  
                </Grid>
              </Grid>
            </Grid>
          </Grid> */}
            <Grid container className={classes.eventCategory}>
              <Grid item xs={12} md={8}>
                {data.category_type === "paid" && (
                  <Typography component="h2" className={classes.paidhighlight}>
                    <NameTruncate
                      text={spacecapitalize(data.event_category)}
                      amount={15}
                    />{" "}
                    - {spacecapitalize(data.category_type)}
                  </Typography>
                )}
                {data.category_type === "free" && (
                  <Typography component="h2" className={classes.highlight}>
                    <NameTruncate
                      text={spacecapitalize(data.event_category)}
                      amount={15}
                    />{" "}
                    - {spacecapitalize(data.category_type)}
                  </Typography>
                )}
                <Typography
                  component="p"
                  variant="subtitle2"
                  className={classes.people_attending}
                >
                  {data.total_attendees}{" "}
                  {data.event_type === "individual" ? (
                    " People"
                  ) : (
                    <>{data.total_attendees <= 1 ? "Team" : "Teams"}</>
                  )}{" "}
                  {remainingHours >= 0 ? "attended" : "attending"}
                </Typography>
              </Grid>

              <Grid item xs={6} md={2}>
                {view !== "security" && data.facebook ? (
                  <Button
                    href={data.facebook}
                    target="_blank"
                    variant="contained"
                    className={classes.facebookButton}
                  >
                    <img
                      src={FacebookRoundIcon}
                      alt=""
                      className={classes.social}
                    />
                    View
                  </Button>
                ) : (
                  <Button
                    href={data.facebook}
                    target="_blank"
                    variant="contained"
                    className={classes.facebookButton}
                  >
                    <img src={FacebookGrey} alt="" className={classes.social} />
                    View
                  </Button>
                )}
              </Grid>
              <Grid item xs={2} md={2} />
            </Grid>
            {/* <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
              <Grid
                container
                className={classes.avataricon}
                justify="flex-start"
                spacing={0}
              >
                <Grid item>
                  <Paper className={classes.paper}>
                    <img src={Avatar1} alt="" className={classes.avatar} />
                  </Paper>
                </Grid>
                <Grid item>
                  <Paper className={classes.paper}>
                    <img src={Avatar2} alt="" className={classes.avatar} />
                  </Paper>
                </Grid>
                <Grid item>
                  <Paper className={classes.paper}>
                    <img src={Avatar3} alt="" className={classes.avatar} />
                  </Paper>
                </Grid>
                <Grid item>
                  <Paper className={classes.paper}>
                    <img src={Avatar5} alt="" className={classes.avatar} />
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid> */}
            {/* TODO :  As per discussion with akshil ( in release -2.0) */}
            <Grid container direction="row" justify="space-between">
              <Grid item>
                <Typography
                  component="p"
                  variant="subtitle2"
                  className={classes.start_title}
                ></Typography>
                <Typography
                  component="p"
                  variant="subtitle2"
                  className={classes.date}
                >
                  {moment(data.start_date).format("LL")}
                </Typography>
                <Typography
                  component="p"
                  variant="subtitle2"
                  className={classNames(
                    classes.time_duration,
                    classes.dateFonts,
                    classes.date
                  )}
                >
                  {moment(data.start_time).format("LT")} &nbsp;to&nbsp;
                  {moment(data.end_time).format("LT")}
                </Typography>
              </Grid>
              <Grid item>
                {view !== "security" && (
                  <Typography
                    component="p"
                    variant="subtitle2"
                    className={classes.start_title}
                  >
                    Happening at
                  </Typography>
                )}
                {view !== "security" && (
                  <Typography
                    component="p"
                    variant="subtitle2"
                    className={classes.date}
                  >
                    <NameTruncate
                      text={spacecapitalize(data.venue) || "-"}
                      amount={15}
                    />
                  </Typography>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Paper>

        <CardActions className={classes.cardFooter}>
          {checkPermission("EVENT_PARTICIPANT_VIEW") && (
            // <Link to={`/events/attendees/${data._id}`}>
            <Button
              variant="contained"
              className={classes.viewbutton}
              size="small"
              onClick={() => viewAttendees(data._id)}
            >
              View Attendees
            </Button>
            // </Link>
          )}
          {view !== "security" &&
            checkPermission("EVENT_PARTICIPANT_CREATE") &&
            registerRemainingHours < 0 && (
              <Button
                disabled={data.participant === "yes"}
                variant="contained"
                size="small"
                className={classes.registerbutton}
                onClick={() => registerEvent(data._id, history)}
                // onClick={() => props.eventParticipants(data._id, history)}
              >
                {data.participant === "yes" ? `Registered` : `Register`}
              </Button>
            )}
          {view !== "security" &&
            checkPermission("EVENT_PARTICIPANT_CREATE") &&
            registerRemainingHours >= 0 && (
              <Button
                variant="contained"
                size="small"
                className={classes.registerbutton}
                onClick={() => registerEvent(data._id, history)}
                disabled
              >
                {data.participant === "yes" ? `Registered` : `Register`}
              </Button>
            )}
          {/* {checkPermission("EVENT_CREATE") && registerRemainingHours >= 0 && (
            <Button
              variant="contained"
              size="small"
              className={classes.feedbackButton}
              onClick={() => sendFeedback()}
              disabled={disabledLink}
            >
              {data.feedback_status === "yes"
                ? "Resend Feedback Link"
                : "Send Feedback Link"}
              
            </Button>
          )} */}
          {view === "security" && (
            <Button
              variant="contained"
              size="small"
              className={classes.registerbutton}
              onClick={() => handlejoinbutton(data._id)}
            >
              Join
            </Button>
          )}
        </CardActions>
      </Card>
      <SelectTeamOptionModal
        close={handleClose}
        openModal={modalOpen}
        eventID={eventID}
        eventData={data}
      />
      {/* <SpocDetailsPopup
        data={spocModalOpen}
        eventId={data._id}
        handleClose={handleSpocModalClose}
        resendFlag={resendFeedbackLink}
        spocDetails={data.feedback_data}
        resendLinkFlag={data.feedback_status === "yes"}
      /> */}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    errors: state.errors,
  };
};
export default connect(mapStateToProps, { eventParticipants })(
  withStyles(styles)(EventCard)
);
