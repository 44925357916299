import axios from "axios";
import {
  GET_TICKETS,
  TICKETS_LOADING,
  ADD_TICKET,
  GET_TICKET,
  TICKET_ERROR,
  CREATE_HISTORY,
  CHANGE_STATUS,
  GET_ASSIGNTO_USER
} from "./types";
import { showSuccess } from "./ActionsHelper";
import { transformErrorsFromApi } from "./TransformError";

export const setTicketsLoading = () => {
  return {
    type: TICKETS_LOADING
  };
};

export const getTickets = search => dispatch => {
  dispatch(setTicketsLoading());
  let url = "/api/helpdeskticket";
  if (search) {
    url = `/api/helpdeskticket?q=${search}`;
  }
  axios
    .get(url)
    .then(res => {
      dispatch({
        type: GET_TICKETS,
        payload: res.data
      });
    })
    .catch(() => {
      dispatch({
        type: TICKET_ERROR,
        payload: {}
      });
    });
};
export const getTicket = id => dispatch => {
  dispatch(setTicketsLoading());
  axios
    .get(`/api/helpdeskticket/${id}`)
    .then(res => {
      dispatch({
        type: GET_TICKET,
        payload: res.data
      });
    })
    .catch(() =>
      dispatch({
        type: TICKET_ERROR,
        payload: {}
      })
    );
};
export const addTicket = (
  userData,
  setErrors,
  setSubmitting,
  history
) => dispatch => {
  axios
    .post("/api/helpdeskticket", userData)
    .then(result => {
      dispatch({
        type: ADD_TICKET,
        payload: result.data
      });

      history.push("/helpdesk");
      dispatch(showSuccess("Ticket"));
      setSubmitting(false);
    })
    .catch(err => {
      const error = err.response || {};
      dispatch({ type: TICKET_ERROR, payload: error.data });
      setSubmitting(false);
      setErrors(transformErrorsFromApi(error));
    });
};
export const createHistory = (userData, setErrors) => dispatch => {
  axios
    .post("/api/helpdeskticket/history", userData)
    .then(result => {
      dispatch({
        type: CREATE_HISTORY,
        payload: result.data
      });

      dispatch(showSuccess("History log"));
    })
    .catch(err => {
      const error = err.response || {};
      dispatch({ type: TICKET_ERROR, payload: error.data });
      setErrors(transformErrorsFromApi(error));
    });
};
export const changeStatus = (id, userData, setSubmitting) => dispatch => {
  axios
    .put(`/api/helpdeskticket/${id}`, userData)
    .then(result => {
      dispatch({
        type: CHANGE_STATUS,
        payload: result.data
      });
      dispatch(showSuccess("", "Status updated successfully!"));
      setSubmitting(false);
    })
    .catch(err =>
      dispatch({
        type: TICKET_ERROR,
        // payload: err.response.data
        payload: ((err || {}).response || {}).data || "Error unexpected"
      })
    );
};
export const getAssignTo = sysuser => dispatch => {
  dispatch(setTicketsLoading());
  let url = "/api/user";
  if (sysuser) {
    url = `/api/user?q=${sysuser}`;
  }
  axios
    .get(url)
    .then(res => {
      dispatch({
        type: GET_ASSIGNTO_USER,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: TICKET_ERROR,
        payload: ((err || {}).response || {}).data || "Error unexpected"
      });
    });
};
