/* eslint-disable camelcase */
import React from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { Typography, Grid } from "@material-ui/core";

const styles = () => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "12px",
  },
  mainTitle: {
    fontWeight: "600",
    fontSize: "20px",
    textAlign: "left",
    color: "#222222",
    margin: "0px",
    lineHeight: "25px",
  },
  heading: {
    fontSize: "16px",
    color: "#222222",
    fontWeight: "500",
  },
  description: {
    color: "#222222",
    fontSize: "13px",
    lineHeight: "18px",
  },
  generalDetails: {
    padding: "3% 0% 0%",
  },
});

function FireExit(props) {
  const { classes } = props;

  return (
    <div className={classes.main}>
      <div className={classes.header}>
        <Typography align="left" variant="h1" className={classes.mainTitle}>
          Fire Exit Plan
        </Typography>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Typography align="left" className={classes.heading}>
            General
          </Typography>
          <Grid className={classes.generalDetails}>
            <Typography align="left" className={classes.description}>
              All the co-workers are requested to park their vehicles at the
              Basement area of The First.
              <br />
              <br />
              All the vehicles should be parked in their respective area only as
              suggested by the Security guard.
              <br />
              <br />
              Parking slots for A AND B Block are as follows:
              <br />
              Upper level (B-1): 33, 93, 42, 83, 84, 43, 44, 94, 95, 96, 97, 19,
              20, 98
              <br />
              Lower Level (B-3):35-A,28,9
              <br />
              <br />
              Parking slots for C AND D Block are as follows:
              <br />
              Upper Level (B-1): 79,71,72,72A,73
              {/* <br /> Middle Level (B-2): 61,62
                <br />
                Lower Level (B-3): 55A,57 */}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.spaces.loading,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(FireExit));
