import React from "react";
import { withStyles, Grid, Paper, Typography } from "@material-ui/core";

import ReportBugForm from "./ReportBugForm";
import { ReactComponent as BugImage } from "../../Assets/ReportError.svg";

const ReportBug = (props) => {
  const { classes } = props;
  return (
    <main className={classes.root}>
      <Grid direction="row" container spacing={3}>
        <Grid item xs={8} md={6} lg={6}>
          <Paper className={classes.paper}>
            <Typography
              align="center"
              variant="h1"
              className={classes.mainTitle}
            >
              <div>Help us make DevX Collab better for you!</div>
            </Typography>
            <Typography align="left" className={classes.description}>
              Kindly help us know if you come across any errors in DevX Collab.
              Error can be in form of UI fixes, product level logic error,
              system crash by doing specific actions, content mistakes. Kindly
              note to make any new suggestion or enhancements in the product you
              can go to suggest a feature module and can help us with your
              suggestions. This section is only to report an error.
            </Typography>
            <Typography
              className={`${classes.basicFontStyle} ${classes.question}`}
            >
              What do you get?
            </Typography>
            <Typography className={classes.basicFontStyle}>
              10 merchandise points for each error you report
            </Typography>
            <Typography className={classes.basicFontStyle}>
              Better experience of the product.
            </Typography>
            <Typography className={classes.basicFontStyle}>
              Your name in the product release document for helping us know a
              specific error.
            </Typography>
            <BugImage className={classes.imageStyle} />
          </Paper>
        </Grid>
        <Grid item xs={8} md={6} lg={6}>
          <Paper className={`${classes.paper} ${classes.container}`}>
            <ReportBugForm />
          </Paper>
        </Grid>
      </Grid>
    </main>
  );
};

const styles = () => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: "20px",
    height: "100vh",
  },
  mainTitle: {
    fontWeight: "600",
    fontSize: "40px",
    textAlign: "left",
    color: "#222222",
    margin: "0px",
    lineHeight: "45px",
    marginBottom: "20px",
  },
  description: {
    color: "#222222",
    fontSize: "13px",
    lineHeight: "18px",
    marginBottom: "20px",
  },
  question: {
    fontWeight: "600",
    marginBottom: "10px !important",
  },
  basicFontStyle: {
    color: "#222222",
    fontSize: "13px",
    lineHeight: "18px",
    textAlign: "left",
    marginBottom: "15px",
  },
  imageStyle: {
    width: "100%",
  },
  container: {
    minHeight: "60%",
  },
});

export default withStyles(styles)(ReportBug);
