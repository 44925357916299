/* eslint-disable react/no-unescaped-entities */
import React from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Typography, Button } from "@material-ui/core";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import avatar from "../../Assets/group.png";
import { addFeedback } from "../../Actions/feedbackActions";
import { getvisitors } from "../../Actions/visitorActions";
import { getSelfBookings } from "../../Actions/bookingActions";
import { CircularLoader } from "../../Components/Common/Spinner";
import Booking from "./Booking";
import Visitors from "./DashboardVisitors";
import Events from "./DashboardEvents";
import LifeDevx from "./LifeDevx";
import Security from "./security";
import Header from "../../Components/Layout/Header";
import { checkPermission } from "../../Utils/checkPermission";

// import BookingUser from "./BookingUser";

const styles = () => ({
  main: {
    padding: "24px",
  },
  main_content: {
    backgroundColor: "#f7f2ff",
    display: "flex",
    borderRadius: "4px",
  },
  admin_name: {
    color: "#211b4e",
    fontWeight: 600,
    fontSize: "20px",
    textTransform: "capitalize",
    paddingTop: "8%",
    marginBottom: "-3px",
  },
  inner_cont: {
    color: "#626262",
    fontSize: "14px",
    fontWeight: "600",
  },
  content: {
    color: "#626262",
    fontWeight: "normal",
    fontSize: "12px",
  },
  submitbutton: {
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
    left: "-6px",
  },
  help_section: {
    border: "1px solid #efefef",
    textAlign: "right",
    position: "relative",
    height: "123px",
    marginLeft: "16px",
    borderRadius: "4px",
    // borderTopLeft: "1px solid #efefef"
  },
  input_field: {
    height: "80px",
    borderStyle: "none",
    borderColor: "Transparent",
    overflow: " auto",
    position: "relative",
    width: "100%",
    padding: "9px 8px",
    margin: "2px 0px 0px 0px",
    fontFamily: "sans-serif",
    resize: "none",
    "&:focus": {
      outline: "none",
    },
    "&::placeholder": {
      fontFamily: "sans-serif",
      color: "#ABABAB",
    },
    // "&::input": {
    //   border: "none"
    // }
  },

  leftSpaceAlign: {
    marginTop: "10px",
    marginBottom: "-4%",
    // width: 507px;
    // height: 268px;
  },
  boxshading: {
    borderRadius: "5px",
    background: "#fff",
    border: "1px solid #efefef",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.03)",
  },
  headerSpacing: {
    // marginLeft: "10px"
  },
  adminNameCenter: {
    display: "flex",
    alignItems: "center",
  },
});

const headLines = [
  "Create a community of innovative minds and grow together!",
  "Space, Cafeteria & Event bookings at your fingertips!",
  "Always stay on top of the community events & happenings!",
  "Collaborate and co-create with a community of entrepreneurs!",
  "Share updates and stay connected with the community!",
];

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      description: "",
      role: localStorage.getItem("role"),
      // open: true,
      // noDataBoth: false
      // noEventData: false
      headlines: headLines[Math.floor(Math.random() * headLines.length)],
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.props.getvisitors("dashboard");
    this.props.getSelfBookings();
  }

  componentDidUpdate(prevProps) {
    if (this.state.role !== "security") {
      if (prevProps.loading !== this.props.loading) {
        // this.props.setLoading(this.props.loading);
        this.setState({
          loading: this.props.loading,
        });
      }
      if (prevProps.bookingLoading !== this.props.bookingLoading) {
        // this.props.setLoading(this.props.bookingLoading);
        this.setState({
          bookingLoading: this.props.bookingLoading,
        });
      }
    }
  }
  // handleDrawerOpen = () => {
  //   this.setState({ open: true });
  // };

  // handleDrawerClose = () => {
  //   this.setState({ open: false });
  // };

  onSubmit = (e) => {
    e.preventDefault();
    // eslint-disable-next-line prefer-destructuring
    const description = this.state.description;
    this.props.addFeedback({ description });
    this.setState({
      description: "",
    });
    // conslo
  };

  onChangeDescription = (e) => {
    this.setState({
      description: e.target.value,
    });
  };

  // bookingData = data => {
  //   this.setState({
  //     bookingAllData: data
  //   });
  // };

  // visitorData = visitoralldata => {
  //   this.setState({
  //     visitoralldata
  //   });
  // };

  render() {
    const { classes } = this.props;
    const { loading, bookingLoading } = this.state;
    // let noDataBoth = false;
    // if (
    //   this.props.visitors &&
    //   this.props.visitors.visitors.length <= 0 &&
    //   this.props.bookings.data &&
    //   this.props.bookings.data.length <= 0
    // ) {
    //   noDataBoth = true;
    // }

    if (this.state.role === "cafe") {
      return <Redirect to="/order-status" />;
    }
    if (this.state.role === "security") {
      return (
        <Grid container spacing={12}>
          {/* <Header /> */}
          <Grid item sm={12} md={12} xs={12}>
            <Header />
            <Security />
          </Grid>
        </Grid>
      );
    }
    // if (this.state.role === "booking") {
    //   return (
    //     <Grid container spacing={12}>
    //       <Header />
    //       <Grid item sm={12} md={12} xs={12}>
    //         <BookingUser />
    //       </Grid>
    //     </Grid>
    //   );
    // }
    return (
      <div className={classes.root}>
        <CssBaseline />
        <div className={classes.main}>
          <div className={classes.admin_detail_section}>
            <Grid container className={classes.headerSpacing}>
              <Grid
                item
                xs={12}
                sm={10}
                md={9}
                className={classes.main_content}
              >
                <img alt="logo" src={avatar} className={classes.group} />
                <div>
                  <Typography
                    variant="subtitle1"
                    className={classes.admin_name}
                    gutterBottom
                  >
                    Hello {localStorage.getItem("name")}!
                  </Typography>
                  {/* <Typography
                      variant="subtitle2"
                      className={classes.inner_cont}
                    >
                      Do you know?
                    </Typography> */}
                  {/* AS per discussion with parth sir */}

                  <Typography
                    variant="subtitle2"
                    className={classes.content}
                    gutterBottom
                  >
                    {/* Let's create a community of innovative minds and grow
                    together. */}
                    {this.state.headlines}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={6} sm={2} md={3}>
                <div className={classes.help_section}>
                  <textarea
                    rows="10"
                    cols="10"
                    type="text"
                    placeholder="Help us enhance your experience"
                    className={classes.input_field}
                    value={this.state.description}
                    onChange={this.onChangeDescription}
                    name="description"
                  />

                  <Button
                    className={classes.submitbutton}
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={this.onSubmit}
                  >
                    Submit
                  </Button>
                </div>
              </Grid>

              <Grid container className={classes.leftSpaceAlign} spacing={2}>
                {/* {this.state.role !== "it" && noDataBoth === false && ( */}
                {this.state.role !== "it" && (
                  <Grid item sm={4} md={6} xs={6}>
                    {checkPermission("BOOKING_VIEW") && bookingLoading ? (
                      <CircularLoader />
                    ) : (
                      <Booking bookings={this.props.bookings} />
                    )}
                  </Grid>
                )}
                {this.state.role !== "it" && (
                  <Grid item sm={4} md={6} xs={6}>
                    {checkPermission("VISITOR_VIEW") && loading && (
                      <CircularLoader />
                    )}
                    <Visitors visitors={this.props.visitors} />
                  </Grid>
                )}

                <Grid item sm={4} md={12} xs={6}>
                  {this.state.role !== "it" && (
                    <Events history={this.props.history} />
                  )}
                </Grid>

                <Grid item sm={12} xs={12} md={12}>
                  <LifeDevx />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errors: state.errors,
    visitors: state.visitors,
    bookings: state.bookingData.selfBookings,
    loading: state.visitors.loading,
    bookingLoading: state.bookingData.loading,
  };
};

export default connect(mapStateToProps, {
  addFeedback,
  getvisitors,
  getSelfBookings,
})(withStyles(styles)(Dashboard));
