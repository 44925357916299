import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { Search } from "react-feather";

const styles = () => {
  return {
    image: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "center"
    },
    maintext: {
      color: "lightgrey"
    },
    headerSpacing: {
      marginTop: "15%"
    }
  };
};

const DataNotFound = props => {
  const { text, classes } = props;
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.headerSpacing}
    >
      <Grid item className={classes.image}>
        <Search size={45} />
      </Grid>
      <Grid item justify="center">
        <Typography variant="h6" className={classes.maintext}>
          {text}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(DataNotFound);
