import React, { useState } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import classNames from "classnames";
import FormControl from "@material-ui/core/FormControl";
import { connect } from "react-redux";
import { capitalize } from "../../Utils/StringFunctions";
import {
  AdminHelpdeskStatusTypes,
  HelpdeskStatusTypes,
  closedStatusTypes,
} from "./Types/HelpdeskStatusType";
import { changeStatus } from "../../Actions/helpdeskActions";
// eslint-disable-next-line import/no-cycle
import ChangeStatusPopup from "../../Screens/Helpdesk/ChangeStatusPopup";
import { checkPermission } from "../../Utils/checkPermission";

const styles = (theme) => {
  return {
    open: {
      color: "#55A3F1 !important",
      backgroundColor: "#fff !important",
    },
    inprocess: {
      color: "#4CAF50 !important",
      backgroundColor: "#fff !important",
    },
    pending: {
      color: "#FFC312 !important",
      backgroundColor: "#fff !important",
    },
    onhold: {
      color: "#26B52B !important",
      backgroundColor: "#fff !important",
    },
    closed: {
      color: "#4DCEDB !important",
      backgroundColor: "#fff !important",
    },
    reopen: {
      color: "#4DCEDB !important",
      backgroundColor: "#fff !important",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 90,
    },
    statusDropdown: {
      color: "black",
      border: `1px solid ${theme.palette.border.color}`,
      borderRadius: "4px",
      height: "33px",
      // padding: "13px 12px 13px",
      fontSize: "12px",
      // paddingLeft: "10px",
    },
    icon: {
      color: "#000",
      right: "8px",
      top: "5px",
    },
  };
};

const HelpdeskStatus = (props) => {
  const [selected, setselected] = useState(props.selectedItem || "");
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line camelcase
  const [ticket_id, setticket_id] = useState(props.ticket_id);
  const [description] = useState(props.description);
  const { classes } = props;
  const callStatusChangeAPI = (el) => {
    setOpen(true);
    setselected(el.target.value);
    setticket_id(ticket_id);
  };

  const role = localStorage.getItem("role");
  const handleClose = () => {
    setOpen(false);
    setselected(props.selectedItem);
  };
  return (
    <React.Fragment>
      <FormControl variant="filled" className={classes.formControl}>
        {checkPermission("HELPDESK_TICKET_STATUS_UPDATE") ? (
          <Select
            className={classNames(
              classes[props.selectedItem],
              classes.statusDropdown
            )}
            value={props.selectedItem}
            onChange={callStatusChangeAPI}
            name="name"
            disableUnderline
            style={{ color: "black" }}
            // eslint-disable-next-line camelcase
            data={ticket_id}
            variant="standard"
            classes={{
              icon: classes.icon,
            }}
          >
            {(props.selectedItem === "closed" || props.selectedItem === "qc_fail" || props.selectedItem === "open" || props.selectedItem === "inprocess" || props.selectedItem === "onhold" || props.selectedItem === "pending") &&
              (role === "admin" || role === "communitymanager") &&
              AdminHelpdeskStatusTypes.map((element) => {
                return (
                  <MenuItem value={element.value} key={element.value}>
                    {capitalize(element.label)}
                  </MenuItem>
                );
              })}
            {props.selectedItem === "qc_pass" &&
              (role === "admin" || role === "communitymanager") &&
              closedStatusTypes.map((element) => {
                return (
                  <MenuItem value={element.value} key={element.value}>
                    {capitalize(element.label)}
                  </MenuItem>
                );
              })}
            {props.selectedItem !== "closed" &&
              role !== "admin" &&
              HelpdeskStatusTypes.map((element) => {
                return (
                  <MenuItem value={element.value} key={element.value}>
                    {capitalize(element.label)}
                  </MenuItem>
                );
              })}

            {props.selectedItem === "qc_pass" &&
              role !== "admin" &&
              closedStatusTypes.map((element) => {
                return (
                  <MenuItem value={element.value} key={element.value}>
                    {capitalize(element.label)}
                  </MenuItem>
                );
              })}
            {props.selectedItem === "reopen" && (
              <MenuItem value="reopen" key="reopen" selectedItem>
                Reopen
              </MenuItem>
            )}
          </Select>
        ) : (
          <Select
            className={classNames(
              classes[props.selectedItem],
              classes.statusDropdown
            )}
            value={props.selectedItem}
            onChange={callStatusChangeAPI}
            name="name"
            disableUnderline
            style={{ color: "black" }}
            // eslint-disable-next-line camelcase
            data={ticket_id}
            variant="standard"
            classes={{
              icon: classes.icon,
            }}
          >
            {selected === "closed" &&
              closedStatusTypes.map((element) => {
                return (
                  <MenuItem value={element.value} key={element.value}>
                    {capitalize(element.label)}
                  </MenuItem>
                );
              })}
          </Select>
        )}
        <ChangeStatusPopup
          data={selected !== props.selectedItem && open}
          close={handleClose}
          // eslint-disable-next-line camelcase
          ticket_id={ticket_id}
          selected={selected}
          description={description}
        />
      </FormControl>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    errors: state.errors,
    selected: state.helpdesk.selected,
  };
};
export default connect(mapStateToProps, { changeStatus })(
  withStyles(styles)(HelpdeskStatus)
);
