/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import React, { Component } from "react";
import { Formik, Form } from "formik";
import {
  Dialog,
  DialogContent,
  Grid,
  DialogTitle,
  Button,
} from "@material-ui/core";
import * as Yup from "yup";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { CircularLoader } from "../../Components/Common/Spinner";
import { getSpaceCompanyList } from "../../Actions/companiesAction";
import { getCoworker } from "../../Actions/coworkerActions";
import {
  createAccount,
  getCreditAccounts,
} from "../../Actions/CreditAccountActions";
import { capitalize } from "../../Utils/StringFunctions";
import SelectField from "../../Components/Common/Form/SelectField";

const $quoteSym = " ";

const styles = () => ({
  club_form_modal: {
    width: "500px",
  },
  button: {
    width: "10%",
    color: "white",
    padding: "5px 16px",
    borderRadius: "4px",
    textDecoration: "none",
    fontSize: "0.875rem",
    fontWeight: "500",
    boxShadow: "rgba(137, 163, 169, 0.3) 0px 3px 5px 2px",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  applicationHeader: {
    marginTop: "-2px",
    top: "10px",
    padding: "16px 24px 10px 24px",
    "&:after": {
      width: "24px",
      borderBottom: "3px solid #fb0",
      content: `'${$quoteSym}'`,
      position: "absolute",
    },
  },
  clubActions: {
    padding: "40px 0px 8px",
    display: "flex",
    justifyContent: "flex-end",
  },
  clubbutton: {
    display: "contents",
  },
  uploadClubBanner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  clubdescription: {
    marginTop: "-9px",
  },
  cancelButton: {
    marginRight: "20px",
    backgroundColor: "#dddddd",
    color: "#151039",
    "&:hover": {
      color: "#151039",
      backgroundColor: "#d1d1d1!important",
    },
  },
});
const ClubSchema = Yup.object().shape({
  company_id: Yup.string().required("This field is required."),
  user_id: Yup.string().required("This field is required."),
});

// eslint-disable-next-line react/prefer-stateless-function
class AddCreditAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const spaceId = localStorage.getItem("space_id");
    this.props.getSpaceCompanyList(spaceId);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.companyCoworkers !== this.props.companyCoworkers) {
      this.setState({
        companyCoworkers: this.props.companyCoworkers,
      });
    }
    if (prevProps.companies !== this.props.companies) {
      this.setState({
        companies: this.props.companies,
      });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.companies !== prevState.companies) {
      return { companies: nextProps.companies };
    }
    // get_credit
    if (nextProps.companyCoworkers !== prevState.companyCoworkers) {
      return { companyCoworkers: nextProps.companyCoworkers };
    }
    return [];
  }

  getCoworkersOptions = (companyCoworkers) => {
    return companyCoworkers
      ? companyCoworkers.map((companyCoworker) => {
          const { user_id, first_name, last_name } = companyCoworker;
          return {
            value: user_id ? user_id._id : "",
            label: capitalize(
              `${first_name && first_name} ${last_name && last_name}`
            ),
          };
        })
      : [];
  };

  getCompanyOptions = (companies) => {
    // eslint-disable-next-line func-names
    const FilterCompanyData = companies.filter(function (activeCompanydata) {
      return activeCompanydata.status !== "inactive";
    });
    return FilterCompanyData
      ? FilterCompanyData.map((company) => {
          return {
            value: company._id,
            label: capitalize(company.display_name || company.company_name),
          };
        })
      : [];
  };

  callComapnyAPI = (value) => {
    this.props.getCoworker(value.value);
  };

  render() {
    const {
      classes,
      open,
      handleClose,
      companyCoworkers,
      companies,
    } = this.props;
    const { loading } = this.state;
    const getInitialValues = () => {
      return {
        company_id: "",
        user_id: "",
      };
    };

    return (
      <div className={classes.clubbutton}>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="plan-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Formik
            initialValues={getInitialValues()}
            validationSchema={ClubSchema}
            onSubmit={(values, { setErrors, setSubmitting }) => {
              const val1 = values.company_id ? values.company_id.value : "";

              values.company_id = val1;
              const val2 = values.user_id ? values.user_id.value : "";
              values.user_id = val2;

              this.props.createAccount(
                values,
                setErrors,
                this.props.history,
                setSubmitting
              );

              this.props.getCreditAccounts();
              this.props.handleClose();
            }}
          >
            {({ setFieldValue, values, setFieldTouched, errors, touched }) => (
              <Form className={classes.club_form_modal}>
                <DialogTitle
                  id="plan-dialog-title"
                  className={classes.applicationHeader}
                >
                  Add Credit Account
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={2}>
                    {/* {checkPermission("COMPANY_IN_COWORKER") && ( */}
                    <Grid item xs={12} sm={12}>
                      <SelectField
                        value={values.company_id}
                        name="company_id"
                        id="company_id"
                        label="Company Name"
                        onChange={(name, value) => {
                          setFieldValue(name, value);
                          this.callComapnyAPI(value);
                          setFieldValue("user_id", "");
                        }}
                        // onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        multi={false}
                        error={errors.company_id}
                        touched={touched.company_id}
                        options={this.getCompanyOptions(companies)}
                      />
                    </Grid>
                    {/* )} */}

                    <Grid item xs={12} sm={12}>
                      <SelectField
                        value={values.user_id}
                        name="user_id"
                        id="user_id"
                        label="Co-worker Name"
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        error={errors.user_id}
                        touched={touched.user_id}
                        options={this.getCoworkersOptions(companyCoworkers)}
                      />
                    </Grid>
                  </Grid>

                  <Grid className={classes.clubActions}>
                    <Button
                      onClick={this.props.handleClose}
                      color="lightgrey"
                      variant="contained"
                      className={classes.cancelButton}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      className={classes.button}
                    >
                      Add
                    </Button>
                  </Grid>
                </DialogContent>
                {loading && <CircularLoader />}
              </Form>
            )}
          </Formik>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyCoworkers: state.coworkers.companyCoworkers,
    companies: state.company.spaceCompany,
  };
};
export default connect(mapStateToProps, {
  getSpaceCompanyList,
  getCoworker,
  createAccount,
  getCreditAccounts,
})(withStyles(styles)(AddCreditAccount));
