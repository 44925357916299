/* eslint-disable camelcase */
import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import AdminCompanyTabs from "./AdminCompanyTabs";

const styles = () => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
});
export class adminCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.main}>
        <AdminCompanyTabs history={this.props.history} />
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, {})(withStyles(styles)(adminCompany));
