/* eslint-disable camelcase */
import axios from "axios";
import {
  GET_ERRORS,
  GET_ROLES,
  ROLE_LOADING,
  ADD_ROLE,
  ROLE_ERRORS,
  GET_ROLE,
  GET_ROLES_POLICIES,
  UPDATE_ROLE
} from "./types";
import { showSuccess, showError } from "./ActionsHelper";
import { transformErrorsFromApi } from "./TransformError";

//  Loading
export const setroleLoading = () => {
  return {
    type: ROLE_LOADING
  };
};

export const getRoles = () => dispatch => {
  dispatch(setroleLoading());
  axios
    .get(`/api/roletype`)
    .then(result => {
      dispatch({
        type: GET_ROLES,
        payload: result.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : ""
      });
    });
};

export const addRole = (
  userData,
  setErrors,
  history,
  setSubmitting
) => dispatch => {
  dispatch(setroleLoading());
  axios
    .post(`/api/roletype`, userData)
    .then(result => {
      dispatch({
        type: ADD_ROLE,
        payload: result.data
      });
      history.push("/roles");
      dispatch(showSuccess("Role"));
      setSubmitting(false);
    })
    .catch(err => {
      const error = err.response || {};
      dispatch({ type: ROLE_ERRORS, payload: error.data });
      dispatch(showError("There was some error while add role "));
      setSubmitting(false);
      setErrors(transformErrorsFromApi(error));
    });
};

export const getPolicies = () => dispatch => {
  dispatch(setroleLoading());
  axios
    .get(`/api/roletype/policy`)
    .then(result => {
      dispatch({
        type: GET_ROLES_POLICIES,
        payload: result.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : ""
      });
    });
};

export const getRole = id => dispatch => {
  dispatch(setroleLoading());
  axios
    .get(`/api/roletype/${id}`)
    .then(res => {
      dispatch({
        type: GET_ROLE,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ROLE,
        payload: {}
      })
    );
};

export const updateRole = (
  id,
  data,
  setErrors,
  history,
  setSubmitting
) => dispatch => {
  dispatch(setroleLoading());
  axios
    .put(`/api/roletype/update/${id}`, data)
    .then(res => {
      dispatch({
        type: UPDATE_ROLE,
        payload: res.data
      });
      history.push("/roles");
      dispatch(showSuccess(" ", "Role Updated Successfully!"));
      // setSubmitting(false);
    })
    .catch(err => {
      const error = err.response;
      dispatch({ type: ROLE_ERRORS, payload: error });
      setSubmitting(false);
      setErrors(transformErrorsFromApi(error));
    });
};
