import React, { useEffect, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  DialogActions,
  Button,
} from "@material-ui/core";
import TeamImage from "../../Assets/group_icon.png";
import { getOneTeam, createTeam } from "../../Actions/eventActions";
import { spacecapitalize } from "../../Utils/StringFunctions";

const $quoteSym = " ";

const styles = () => ({
  registerTeammodal: {
    width: 500,
    padding: "34px 24px",
    overflow: "hidden",
  },
  modelHeader: {
    paddingBottom: "5px",
    position: "relative",
    "&:after": {
      top: "48px",
      left: "24px",
      width: "24px",
      borderBottom: "3px solid #fb0",
      content: `'${$quoteSym}'`,
      position: "absolute",
    },
  },
  teamMainSection: {
    display: "flex",
    justifyContent: "space-between",
  },
  mainTitle: {
    fontWeight: 600,
    fontSize: "16px",
    letterSpacing: "0.02em",
    color: "#222",
  },
  layout: {
    width: "56px",
    height: "56px",
    // padding: "10px",
  },
  teamIcon: {
    width: "56px",
    height: "56px",
    border: "1px solid #efefef",
    borderRadius: "50px",
  },
  teamDetailSection: { padding: "10px 0px" },
  TeamName: {
    fontWeight: 600,
    fontSize: "16px",
    letterSpacing: "0.02em",
    textAlign: "left",
    color: "#222",
    padding: "15px 0px 0px",
    marginLeft: "-10px",
  },

  modalFooter: {
    padding: "8px 24px",
  },
  cancelbutton: {
    marginRight: "20px",
    backgroundColor: "#dddddd",
    color: "#151039",
    "&:hover": {
      color: "#151039",
      backgroundColor: "#d1d1d1!important",
    },
  },
  addbutton: {
    color: "#fff",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  memberDetail: {
    margin: "29px 0px 0px",
  },
  members: {
    fontWeight: 600,
    fontSize: "12px",
    textAlign: "left",
    color: "#ababab",
  },
  createdByName: {
    fontWeight: 600,
    fontSize: "16px",
    textAlign: "left",
    color: "#211b4e",
  },
  otherMember: {
    fontSize: "16px",
    color: "#626262",
  },
});

function JoinTeamDetailModal(props) {
  const { classes, teamID } = props;
  const [singleTeam, setsingleTeam] = useState({});
  // const [updateTeamid, setupdateTeamid] = useState({});

  // useEffect(() => {
  //   setupdateTeamid(teamID);
  // });
  useEffect(() => {
    if (props.register_team && props.register_team.success) {
      props.close();
    }
  }, [props.register_team]);

  useEffect(() => {
    if (teamID) {
      props.getOneTeam(teamID);
    }
  }, [props.teamID]);

  useEffect(() => {
    setsingleTeam(props.single_team);
  }, [props.single_team]);
  // getOneTeam

  const joinTeam = () => {
    const values = {};
    values.event_id =
      singleTeam && singleTeam.event_id && singleTeam.event_id._id;
    values.team_id = singleTeam && singleTeam._id;
    values.type = "join";
    props.createTeam(values);
    props.close();
  };
  return (
    <Dialog
      open={props.openModal}
      onClose={props.close}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" className={classes.modelHeader}>
        {props.type === "event" ? "Team Detail " : "Join a Team"}
      </DialogTitle>
      <DialogContent className={classes.registerTeammodal}>
        <Grid container spacing={2}>
          {/* <div className={classes.teamMainSection}> */}
          <Grid item xs={4} sm={2} md={2} className={classes.layout}>
            <img
              alt="logo"
              src={
                singleTeam && singleTeam.icon && singleTeam.icon.icon
                  ? singleTeam.icon.icon
                  : TeamImage
              }
              className={classes.teamIcon}
            />
          </Grid>
          <Grid item xs={7} sm={7} md={7}>
            <Typography component="h2" className={classes.TeamName}>
              {spacecapitalize(singleTeam && singleTeam.team_name)}
            </Typography>
          </Grid>
          {/* </div> */}
        </Grid>
        <div className={classes.memberDetail}>
          <Typography
            variant="caption"
            display="block"
            gutterBottom
            className={classes.members}
          >
            Members
          </Typography>
          {/* <Typography
            variant="subtitle2"
            className={classes.createdByName}
            gutterBottom
          >
            {spacecapitalize(
              `${singleTeam &&
                singleTeam.created_by &&
                singleTeam.created_by.first_name} ${singleTeam &&
                singleTeam.created_by &&
                singleTeam.created_by.last_name}`
            )}
          </Typography> */}
          {singleTeam &&
            singleTeam.team_members &&
            singleTeam.team_members.map((element) => {
              return (
                <Typography
                  variant="subtitle1"
                  className={classes.otherMember}
                  gutterBottom
                >
                  {spacecapitalize(
                    `${element && element.first_name} ${
                      element && element.last_name
                    }`
                  )}
                  &nbsp; - &nbsp;
                  {spacecapitalize(
                    `${
                      element &&
                      element.company_id &&
                      element.company_id.type === "freelancer"
                        ? "-"
                        : element.company_id.company_name
                    } `
                  )}
                </Typography>
              );
            })}
        </div>
      </DialogContent>
      <DialogActions className={classes.modalFooter}>
        <Button
          onClick={() => {
            props.close();
          }}
          color="primary"
          className={classes.cancelbutton}
        >
          Cancel
        </Button>
        {props.type === "event" ? (
          ""
        ) : (
          <Button
            color="primary"
            className={classes.addbutton}
            type="submit"
            variant="contained"
            onClick={joinTeam}
          >
            Join / Confirm
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    errors: state.errors,
    single_team: state.events.single_team,
    register_team: state.events.register_team,
  };
};

export default connect(mapStateToProps, { getOneTeam, createTeam })(
  withStyles(styles)(JoinTeamDetailModal)
);
