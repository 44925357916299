import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

const styles = () => {
  const $quoteSym = " ";

  return {
    screenheader: {
      margin: "20px",
      //   borderBottom: "3px solid orange",
      paddingBottom: "5px",
      position: "relative",
      "&:after": {
        top: "24px",
        left: "0px",
        width: "24px",
        borderBottom: "3px solid #fb0",
        content: `'${$quoteSym}'`,
        position: "absolute"
      }
    }
  };
};

const ScreenHeader = props => {
  const { type, classes } = props;

  return (
    <Grid container>
      <Grid item style={{ marginBottom: "7px", marginLeft: "1%" }}>
        <span className={classes.screenheader}>{type}</span>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(ScreenHeader);
