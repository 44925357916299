import React from "react";
import { Editor, RichUtils } from "draft-js";
import { Grid } from "@material-ui/core";
import { ErrorMessage } from "formik";
import withStyles from "@material-ui/core/styles/withStyles";

const MAX_LENGTH = 2000;
const styles = () => ({
  selectContainer: {
    marginTop: "6px",
    paddingTop: "6px"
  },
  error: {
    color: "#f53b57",
    fontSize: "0.75rem",
    fontWeight: "400",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ],
    textAlign: "left",
    lineHeight: "1em",
    marginTop: "7px"
  }
});
// eslint-disable-next-line import/prefer-default-export
class RichEditorExample extends React.Component {
  state = {
    editorValidated: false
  };

  onChange = editorState => {
    this.props.onChange("editorState", editorState);
  };

  // eslint-disable-next-line react/no-string-refs
  focus = () => this.refs.editor.focus();

  handleKeyCommand = command => {
    const { editorState } = this.props;
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  };

  _handleBeforeInput = () => {
    const currentContent = this.props.editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText("").length;
    if (currentContentLength >= MAX_LENGTH - 1) {
      return "handled";
    }
    return null;
  };

  _handlePastedText = pastedText => {
    const currentContent = this.props.editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText("").length;
    if (currentContentLength + pastedText.length > MAX_LENGTH) {
      return "handled";
    }
    return null;
  };

  onTab = e => {
    const maxDepth = 4;
    this.onChange(RichUtils.onTab(e, this.props.editorState, maxDepth));
  };

  toggleBlockType = blockType => {
    this.onChange(RichUtils.toggleBlockType(this.props.editorState, blockType));
  };

  toggleInlineStyle = inlineStyle => {
    this.onChange(
      RichUtils.toggleInlineStyle(this.props.editorState, inlineStyle)
    );
  };

  render() {
    const { editorState, classes } = this.props;
    const { editorValidated } = this.state;
    // If the user changes block type before entering any text, we can
    // either style the placeholder or hide it. Let's just hide it now.
    let className = "RichEditor-editor";
    const contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
      if (
        contentState
          .getBlockMap()
          .first()
          .getType() !== "unstyled"
      ) {
        className += " RichEditor-hidePlaceholder";
      }
    }
    return (
      <div style={{ margin: "1rem 0" }}>
        <div className="RichEditor-root">
          <BlockStyleControls
            editorState={editorState}
            onToggle={this.toggleBlockType}
          />
          <InlineStyleControls
            editorState={editorState}
            onToggle={this.toggleInlineStyle}
          />
          <Grid className={className} onClick={this.focus}>
            <Editor
              // eslint-disable-next-line no-use-before-define
              blockStyleFn={getBlockStyle}
              // eslint-disable-next-line no-use-before-define
              customStyleMap={styleMap}
              editorState={editorState}
              handleKeyCommand={this.handleKeyCommand}
              handleBeforeInput={this._handleBeforeInput}
              handlePastedText={this._handlePastedText}
              onChange={this.onChange}
              onTab={this.onTab}
              placeholder={this.props.placeholder}
              onFocus={() => {
                this.setState({ editorValidated: true });
              }}
              onBlur={() => {
                this.setState({
                  editorValidated:
                    this.props.editorState
                      .getCurrentContent()
                      .getPlainText() !== ""
                });
              }}
              // eslint-disable-next-line react/no-string-refs
              ref="editor"
              spellCheck
            />
          </Grid>
        </div>
        {editorValidated === true ? (
          ""
        ) : (
          <div className={classes.error}>
            <ErrorMessage
              name={this.props.name}
              key={this.props.name}
              component="div"
            />
          </div>
        )}
      </div>
    );
  }
}
// Custom overrides for "code" style.
const styleMap = {
  CODE: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2
  }
};
function getBlockStyle(block) {
  switch (block.getType()) {
    case "blockquote":
      return "RichEditor-blockquote";
    default:
      return null;
  }
}
class StyleButton extends React.Component {
  constructor() {
    super();
    this.onToggle = e => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }

  render() {
    let className = "RichEditor-styleButton";
    if (this.props.active) {
      className += " RichEditor-activeButton";
    }
    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <span className={className} onMouseDown={this.onToggle}>
        {this.props.label}
      </span>
    );
  }
}
const BLOCK_TYPES = [
  { label: "H1", style: "header-one" },
  { label: "H2", style: "header-two" },
  { label: "H3", style: "header-three" },
  { label: "H4", style: "header-four" },
  { label: "H5", style: "header-five" },
  { label: "H6", style: "header-six" },
  { label: "Blockquote", style: "blockquote" },
  { label: "UL", style: "unordered-list-item" },
  { label: "OL", style: "ordered-list-item" }
  // { label: "Code Block", style: "code-block" }
];
const BlockStyleControls = props => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();
  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map(type => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};
const INLINE_STYLES = [
  { label: "Bold", style: "BOLD" },
  { label: "Italic", style: "ITALIC" },
  { label: "Underline", style: "UNDERLINE" },
  { label: "Monospace", style: "CODE" }
];
const InlineStyleControls = props => {
  const currentStyle = props.editorState.getCurrentInlineStyle();
  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map(type => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

export default withStyles(styles)(RichEditorExample);
