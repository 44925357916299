import React, { useState } from "react";
import { withStyles, FormControl, MenuItem, Select } from "@material-ui/core";
import classNames from "classnames";
import { connect } from "react-redux";
import { changeParticipantStatus } from "../../Actions/eventActions";
import AttendesType from "./Types/AttendesType";

const styles = (theme) => {
  return {
    registered: {
      backgroundColor: "#fff",
      color: "#4CAF50 !important",
      borderColor: "#4CAF50 !important",
    },
    unattended: {
      backgroundColor: "#fff",
      color: "#F44336 !important",
      borderColor: "#F44336 !important",
    },
    attended: {
      borderColor: "#959595 !important",
      backgroundColor: "#fff",
      color: "#959595 !important",
    },
    backout: {
      backgroundColor: "#fff",
      color: "#F39c12 !important",
      borderColor: "#F39c12 !important",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 50,
    },
    statusDropdown: {
      color: "white",
      border: `1px solid ${theme.palette.border.color}`,
      borderRadius: `${theme.shape.borderRadius * 4}px`,
      height: "33px",
      padding: "15px 12px 13px",
      fontSize: "12px",
    },
  };
};
const AttendesStatus = (props) => {
  const [selected, setSelected] = useState(props.selectedItem);
  const eventId = props.id;

  const callStatusChangeAPI = (el) => {
    props.changeParticipantStatus(eventId, {
      status: el.target.value,
    });
    setSelected(el.target.value);
  };

  const { classes } = props;
  return (
    <React.Fragment>
      <FormControl variant="filled" className={classes.formControl}>
        <Select
          className={classNames(classes[selected], classes.statusDropdown)}
          value={selected}
          onChange={callStatusChangeAPI}
          variant="standard"
          name="name"
          disableUnderline
        >
          {AttendesType.map((element) => {
            return (
              <MenuItem value={element.value} key={element.value}>
                {element.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    errors: state.errors,
    selected: state.participants.participants.selected,
  };
};
export default connect(mapStateToProps, { changeParticipantStatus })(
  withStyles(styles)(AttendesStatus)
);
