/* eslint-disable no-use-before-define */
import axios from "axios";
import {
  GET_COWORKER_SKILLS,
  COWORKER_SKILLS_ERRORS,
  COWORKER_SKILLS_LOADING
} from "./types";

// Profile Loading
export const setCoworkerSkillsLoading = () => {
  return {
    type: COWORKER_SKILLS_LOADING
  };
};

export const getCoworkerSkills = () => dispatch => {
  dispatch(setCoworkerSkillsLoading());
  axios
    .get("/api/coworkerskills/")
    .then(result => {
      dispatch({
        type: GET_COWORKER_SKILLS,
        payload: result.data
      });
    })
    .catch(err =>
      dispatch({
        type: COWORKER_SKILLS_ERRORS,
        payload: err && err.res ? err.res.data : ""
      })
    );
};
