/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react'
import {
  Grid,
  CardActions,
  CardContent,
  withStyles,
  Paper,
  Avatar,
  Typography,
  Card,
} from '@material-ui/core'
import Green from '@material-ui/core/colors/green'
import CreateIcon from '@material-ui/icons/Create'
import { Link } from 'react-router-dom'
import {
  Instagram,
  Linkedin,
  Twitter,
  FacebookRoundIcon,
  FacebookGrey,
  InstagramGrey,
  TwitterGrey,
  LinkedinGrey,
} from '../../../Assets/index'
import avatar from '../../../Assets/coworker3.png'
import CoworkerStatus from '../CoworkerStatus'
import CardFooterViewMore from '../CardFooterViewMore'
import SocialButton from '../SocialButton'
import { checkPermission } from '../../../Utils/checkPermission'
import NameTruncate from '../NameTruncate'
import { spacecapitalize } from '../../../Utils/StringFunctions'
import BadgesDetailModal from '../../../Screens/Co-Workers/BadgesDetailModal'

const moment = require('moment')

const styles = (theme) => {
  const borderRadius = theme.shape.borderRadius * 4

  return {
    cardGrid: {
      padding: `${theme.spacing(8)}px 0`,
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      border: '1px solid #EFEFEF',
      boxShadow: '0px 0px 20px rgba(0,0,0,0.05)',
      transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
      backgroundColor: '#FCFDFE',
      position: 'relative',
      '&:hover': {
        transform: 'scale(1.01)',
        boxShadow:
          'rgb(178 183 187 / 6%) 0px 2px 4px 6px, rgb(212 219 226 / 60%) 0px 2px 10px 0px',
      },
    },
    cardCoworker: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      border: '1px solid #EFEFEF',
      boxShadow: '0px 0px 20px rgba(0,0,0,0.05)',
      transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
      backgroundColor: '#FCFDFE',
      position: 'relative',
      // "&:hover": {
      //   transform: "scale(1.01)",
      //   boxShadow:
      //     "rgba(34, 36, 38, 0.08) 0px 2px 4px 6px, rgba(34, 36, 38, 0.15) 0px 2px 10px 0px"
      // }
    },
    cardMedia: {
      paddingTop: '56.25%', // 16:9
    },
    cardContent: {
      flexGrow: 1,
      textAlign: 'center',
      '& p': {
        color: theme.palette.font.head1,
      },
      '& h2': {
        color: '#82A3FE',
      },
      '& h4': {
        color: theme.palette.font.head2,
        fontWeight: 'normal',
      },
      '&:last-child': {
        paddingBottom: '10px',
      },

      paddingTop: '10px',
    },
    containerPaper: {
      // border: `1px solid ${theme.palette.border.color}`
    },
    profileImage: {
      display: 'flex',
      flexDirection: 'row',
      height: 50,
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '36px',
    },
    Userimage: {
      minWidth: 60,
      height: 60,
    },
    avatar: {
      width: 60,
      height: 60,
      position: 'relative',
    },
    coworker3: {
      width: 61,
      height: 61,
      top: '-2px',
      position: 'relative',
    },
    status: {
      color: 'white',
      backgroundColor: Green[700],
      padding: '5px 8px',
      fontSize: 14,
    },
    image: {
      height: 'auto',
    },
    coworkerInfo: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      justify: 'center',
      alignItems: 'center',
      '& p': {
        fontSize: '14px',
      },
    },
    teamAvtar: {
      display: 'flex',
      flexDirection: 'row',
    },
    footerContainer: {
      marginTop: '10px',
      color: theme.palette.font.head1,
      fontSize: '12px',
    },
    footerHeader: {
      fontSize: '20px',
    },
    iconContainer: {
      marginTop: '1px',
      marginBottom: '3px',
    },
    cardFooter: {
      justifyContent: 'space-around',
      '& a': {
        textDecoration: 'none',
      },
      '& button': {
        margin: theme.spacing(1),
        fontSize: 10,
        borderRadius: `${borderRadius}px`,
      },
    },
    customBadge: {
      position: 'absolute',

      color: 'white',
      padding: '2px 10px',
      right: '11px',
      '-webkitClipPath':
        'polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 7% 50%, 0% 0%)',
      clipPath: 'polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 7% 50%, 0% 0%)',
      fontSize: '10px',
      fontWeight: 'bold',
    },
    active: {
      backgroundColor: theme.palette.applications.active,
    },
    inactive: {
      backgroundColor: theme.palette.applications.inactive,
    },
    trial: {
      backgroundColor: theme.palette.applications.trial,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    typo: {
      fontSize: '11px',
    },
    designation: {
      fontSize: '12px',
      fontWeight: '400',
    },
    socialPaper: {
      minHeight: '45px',
    },
    localBoldFonts: {
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    edit_link: {
      textDecoration: 'none',
      color: 'grey',
      border: '1px solid #d1d1d1',
      borderRadius: '56px 38px 54px 100px',
      borderTop: 'none',
      borderRight: 'none',
      backgroundColor: '#d1d1d1',
      padding: '2px 4px 1px 5px',
      position: 'absolute',
      top: '-2px',
      right: '-4px',
    },
    skills: {
      paddingTop: '3px',
      color: '#ababab',
      fontSize: '12px',
    },
    coworkerName: {
      marginTop: 10,
      textTransform: 'capitalize',
      color: '#4D7CFE ',
      fontWeight: '600',
    },
    coworkerDetail: {
      color: '#ababab',
      fontSize: '12px',
    },
    contentDataFonts: {
      fontSize: '12px',
    },
    badgesNumber: {
      background: '#FFC312',
      padding: '0px 6px',
      color: '#fff',
      borderRadius: '60px',
      position: 'relative',
      top: '-16px',
      right: '-42px',
      cursor: 'pointer',
    },
  }
}

function CoworkerCard(props) {
  const { classes, data, user } = props
  const [modalOpen, setmodalOpen] = useState(false)
  const role = localStorage.getItem('role')
  const handleClose = () => {
    setmodalOpen(false)
  }
  // const badgesModalOpen = () => {
  //   setmodalOpen(true);
  // };

  const renderUpdateCoworker = () => {
    if (
      user &&
      user.company_id &&
      user.company_id.type === 'freelancer' &&
      checkPermission('COWORKER_UPDATE') &&
      role === 'coworkeradmin'
    ) {
      return ''
    } else if (
      checkPermission('COWORKER_UPDATE') &&
      user &&
      user.company_id &&
      user.company_id.type === 'company' &&
      role === 'coworkeradmin'
    ) {
      return (
        <div>
          <Link
            className={classes.edit_link}
            to={`/co-worker/edit/${data._id}`}
          >
            <CreateIcon fontSize="small" />
          </Link>
        </div>
      )
    } else if (
      data.user_id &&
      data.user_id.status !== 'inactive' &&
      checkPermission('COWORKER_UPDATE') &&
      role === 'admin'
    ) {
      return (
        <div>
          <Link
            className={classes.edit_link}
            to={`/co-worker/edit/${data._id}`}
          >
            <CreateIcon fontSize="small" />
          </Link>
        </div>
      )
    } else if (
      checkPermission('COWORKER_UPDATE') &&
      role === 'communitymanager'
    ) {
      return (
        <div>
          <Link
            className={classes.edit_link}
            to={`/co-worker/edit/${data._id}`}
          >
            <CreateIcon fontSize="small" />
          </Link>
        </div>
      )
    }
  }

  const renderCoworkerStatus = () => {
    if (
      user &&
      user.company_id &&
      user.company_id.type === 'freelancer' &&
      checkPermission('COWORKER_STATUS_CHANGE') &&
      role === 'coworkeradmin'
    ) {
      return ''
    } else if (
      checkPermission('COWORKER_STATUS_CHANGE') &&
      user &&
      user.company_id &&
      user.company_id.type === 'company' &&
      role === 'coworkeradmin'
    ) {
      return (
        <div>
          <CardActions className={classes.cardFooter}>
            <CoworkerStatus
              selectedItem={data.user_id ? data.user_id.status : ''}
              id={data.user_id ? data.user_id._id : ''}
              key={data.user_id ? data.user_id._id : ''}
              changeStatus={props.changeStatus}
            />
          </CardActions>
        </div>
      )
    } else if (checkPermission('COWORKER_STATUS_CHANGE') && role === 'admin') {
      return (
        <div>
          <CardActions className={classes.cardFooter}>
            <CoworkerStatus
              selectedItem={data.user_id ? data.user_id.status : ''}
              id={data.user_id ? data.user_id._id : ''}
              key={data.user_id ? data.user_id._id : ''}
              changeStatus={props.changeStatus}
            />
          </CardActions>
        </div>
      )
    } else if (role === 'communitymanager') {
      return (
        <div>
          <CardActions className={classes.cardFooter}>
            <CoworkerStatus
              selectedItem={data.user_id ? data.user_id.status : ''}
              id={data.user_id ? data.user_id._id : ''}
              key={data.user_id ? data.user_id._id : ''}
              changeStatus={props.changeStatus}
            />
          </CardActions>
        </div>
      )
    }
  }
  return (
    <Card
      className={
        checkPermission('COWORKER_ADMIN_VIEW')
          ? classes.card
          : classes.cardCoworker
      }
    >
      <Paper className={classes.containerPaper}>
        {renderUpdateCoworker()}

        <div className={classes.profileImage}>
          <div className={classes.Userimage}>
            {data.profile_pic ? (
              <Avatar className={classes.avatar} src={data.profile_pic} />
            ) : (
              <Avatar className={classes.coworker3} src={avatar} />
            )}
            {/* {data.user_id &&
            data.user_id.user_badge &&
            data.user_id.user_badge.length > 0 ? (
              <span
                onClick={() => badgesModalOpen()}
                className={classes.badgesNumber}
              >
                {data.user_id &&
                  data.user_id.user_badge &&
                  data.user_id.user_badge.length}
              </span>
            ) : (
              ""
            )} */}
          </div>
        </div>
        <div className={classes.image} direction="row">
          <Typography
            component="h2"
            align="center"
            className={classes.coworkerName}
          >
            {data.first_name} {data.last_name}
          </Typography>
        </div>
        <div style={{ clear: 'both' }} />
        <Grid
          container
          alignItems="center"
          alignContent="center"
          direction="row"
          justify="center"
        >
          <Grid item>
            <Typography
              component="p"
              variant="subtitle2"
              className={classes.designation}
            >
              <NameTruncate
                text={spacecapitalize(data.designation)}
                amount={20}
              />
              @
              {data.company_id && (
                <NameTruncate
                  text={
                    data.company_id.display_name || data.company_id.company_name
                  }
                  amount={20}
                />
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          alignContent="center"
          direction="row"
          justify="center"
        >
          {checkPermission('COWORKER_ADMIN_VIEW') ? (
            <Grid item>
              <Typography
                component="p"
                variant="subtitle1"
                className={classes.typo}
              >
                {data.contact_number} | {data.user_id && data.user_id.email}
              </Typography>
            </Grid>
          ) : (
            <Grid item>
              <Typography
                component="p"
                variant="subtitle1"
                className={classes.typo}
              >
                {data.user_id && data.user_id.email}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12}>
            <Grid
              container
              className={classes.iconContainer}
              justify="center"
              spacing={2}
            >
              <Grid item>
                <Paper className={classes.socialPaper}>
                  {data.linkedin ? (
                    <SocialButton type={Linkedin} url={data.linkedin} />
                  ) : (
                    <SocialButton type={LinkedinGrey} disable />
                  )}
                </Paper>
              </Grid>
              <Grid item>
                <Paper className={classes.socialPaper}>
                  {data.facebook ? (
                    <SocialButton
                      type={FacebookRoundIcon}
                      url={data.facebook}
                    />
                  ) : (
                    <SocialButton type={FacebookGrey} disable />
                  )}
                </Paper>
              </Grid>
              <Grid item>
                <Paper className={classes.socialPaper}>
                  {data.twitter ? (
                    <SocialButton type={Twitter} url={data.twitter} />
                  ) : (
                    <SocialButton type={TwitterGrey} disable />
                  )}
                </Paper>
              </Grid>
              <Grid item>
                <Paper className={classes.socialPaper}>
                  {data.instagram ? (
                    <SocialButton type={Instagram} url={data.instagram} />
                  ) : (
                    <SocialButton type={InstagramGrey} disable />
                  )}
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Paper className={classes.containerPaper}>
        <CardContent className={classes.cardContent}>
          <Grid
            container
            className={classes.coworkerInfo}
            spacing={2}
            alignItems="center"
            alignContent="space-between"
            direction="row"
            justify="center"
          >
            <Grid item xs={4} spacing={3} className={classes.eventParticipant}>
              {data.skills && data.skills.length ? (
                <Typography variant="h3" component="p">
                  <span className={classes.contentDataFonts}>
                    <CardFooterViewMore text={data} isSmallFont />
                  </span>
                </Typography>
              ) : (
                <Typography variant="h3" component="p">
                  N.A.
                </Typography>
              )}
              <Typography variant="subtitle2" className={classes.skills}>
                Skill Sets
              </Typography>
            </Grid>

            <Grid item xs={4} spacing={3} className={classes.eventDate}>
              {data.blood_group ? (
                <Typography
                  variant="subtitle2"
                  component="p"
                  className={classes.localBoldFonts}
                >
                  {data.blood_group}
                </Typography>
              ) : (
                <Typography variant="h3" component="p">
                  N.A
                </Typography>
              )}
              <Typography
                variant="subtitle2"
                className={classes.coworkerDetail}
              >
                Blood Group
              </Typography>
            </Grid>

            <Grid item xs={4} spacing={3} className={classes.eventDate}>
              {data.date_of_birth ? (
                <Typography
                  variant="subtitle2"
                  component="p"
                  className={classes.localBoldFonts}
                >
                  {moment(data.date_of_birth).format('DD/MM/YYYY')}
                </Typography>
              ) : (
                <Typography variant="h3" component="p">
                  N.A.
                </Typography>
              )}
              <Typography
                variant="subtitle2"
                className={classes.coworkerDetail}
              >
                DOB
              </Typography>
            </Grid>
            {/* </div> */}
          </Grid>
        </CardContent>
      </Paper>
      {/* {user && user.company_id && user.company_id.type === "freelancer"
        ? ""
        : checkPermission("COWORKER_STATUS_CHANGE") && (
            <CardActions className={classes.cardFooter}>
              <CoworkerStatus
                selectedItem={data.user_id ? data.user_id.status : ""}
                id={data.user_id ? data.user_id._id : ""}
                changeStatus={props.changeStatus}
              />
            </CardActions>
          )} */}
      {renderCoworkerStatus()}
      <BadgesDetailModal
        close={handleClose}
        data={modalOpen}
        // eventID={data._id}
        coworkerData={data}
      />
    </Card>
  )
}
export default withStyles(styles)(CoworkerCard)
