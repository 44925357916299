/* eslint-disable no-shadow */
/* eslint-disable camelcase */
import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import { Formik, Form } from "formik";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import * as Yup from "yup";
import { CircularLoader } from "../../Components/Common/Spinner";
import { checkPermission } from "../../Utils/checkPermission";
import InputField from "../../Components/Common/Form/InputField";
import ScreenHeader from "../../Components/Common/ScreenHeader";
import { UploadField } from "../../Components/Common/Form";
import {
  createArea,
  getArea,
  getSingleArea,
  updateArea,
} from "../../Actions/spaceActions";
// import nodata from "../../Assets/nodata.png";
import Pagination from "../../Components/Common/Table/TablePagination";
import DataNotFound from "../../Components/Common/DataNotFound";

const $quoteSym = " ";
const styles = () => ({
  add_area_button: {
    width: "100%",
    textAlign: "right",
  },
  area_form_modal: {
    width: "500px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  planCategoryButton: {
    color: "#fff",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  ScreenHeader: {
    marginLeft: "-20px",
    width: "100%",
  },
  cancelbutton: {
    marginRight: "20px",
    backgroundColor: "#dddddd",
    color: "#151039",
    "&:hover": {
      color: "#151039",
      backgroundColor: "#d1d1d1!important",
    },
  },
  addbutton: {
    color: "#fff",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  modelHeader: {
    paddingBottom: "5px",
    position: "relative",
    "&:after": {
      top: "48px",
      left: "24px",
      width: "24px",
      borderBottom: "3px solid #fb0",
      content: `'${$quoteSym}'`,
      position: "absolute",
    },
  },
  modalFooter: {
    padding: "8px 24px",
  },
  table: {
    marginTop: "20px",
  },
  editbutton: {
    borderRadius: "50px",
  },
  editicon: {
    fontSize: "16px",
    marginRight: "6px",
  },
});

const AreaSchema = Yup.object().shape({
  name: Yup.string().trim().required("This field is required."),
  photo: Yup.string().required("This field is required."),
  total_photos: Yup.number()
    .typeError("Please input a number.")
    .required("This field is required."),
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F8FAFF",
    color: theme.palette.common.black,
    borderBottom: "none",
    padding: "8px 40px 8px 16px",
  },
  body: {
    fontSize: 14,
    textTransform: "capitalize",
    borderBottom: "none",
  },
}))(TableCell);

export class AddAreaTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      all_area: [],
      page: 0,
      rowsPerPage: 10,
      deletedKey: [],
    };
  }

  state = {
    modalOpen: false,
  };

  componentDidMount() {
    this.props.getArea();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.all_area !== prevState.all_area) {
      return { all_area: nextProps.all_area };
    }
    return [];
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading !== this.props.loading) {
      this.props.setLoading(this.props.loading);
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        loading: this.props.loading,
      });
    }
    if (
      this.props.add_area !== prevProps.add_area &&
      this.props.add_area.success
    ) {
      this.props.getArea();
    }
    if (
      this.props.update_area !== prevProps.update_area &&
      this.props.update_area.success
    ) {
      this.handleClose();
    }
  }

  handleClickOpen = (id) => {
    this.setState({
      id,
      modalOpen: true,
    });
    this.props.getSingleArea(id);
  };

  addOpen = () => {
    this.setState({
      id: "",
      modalOpen: true,
    });
  };

  handleClose = () => {
    this.setState({
      modalOpen: false,
    });
    this.props.getArea();
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  setDelImageUrl = (url) => {
    const { deletedKey } = this.state;
    deletedKey.push(url);
    this.setState({ deletedKey });
  };

  render() {
    const { classes, all_area, single_area, loading } = this.props;
    const { id, page, rowsPerPage, deletedKey } = this.state;
    const itemLength = all_area ? all_area.length : 0;

    if (loading) {
      return <CircularLoader />;
    }

    if (all_area && all_area.length <= 0) {
      return (
        <DataNotFound
          type="nodata"
          text="You don't have any Area Listed!!"
          subtext=""
          paddingTop="0%"
        />
      );
    }

    const getInitialValues = (id) => {
      if (id) {
        return {
          name: single_area && single_area.name,
          photo: single_area && single_area.photo,
          total_photos: single_area && single_area.total_photos,
          deleted_key: deletedKey,
        };
      }
      return {
        name: "",
        photo: "",
        total_photos: "",
      };
    };

    return (
      <div className={classes.main}>
        <div className={classes.header}>
          <div className={classes.ScreenHeader}>
            <ScreenHeader type="Manage Area" />
          </div>
          <Dialog
            open={this.state.modalOpen}
            onClose={this.handleClose}
            aria-labelledby="plan-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <Formik
              initialValues={getInitialValues(id)}
              enableReinitialize
              validationSchema={AreaSchema}
              onSubmit={(values, { setSubmitting }) => {
                if (id) {
                  this.props.updateArea(id, values, setSubmitting);
                } else {
                  this.props.createArea(values, setSubmitting);
                }
                this.handleClose();
              }}
            >
              {({ values, setFieldValue }) => (
                <Form className={classes.area_form_modal}>
                  <DialogTitle
                    id="plan-dialog-title"
                    className={classes.modelHeader}
                  >
                    {id ? "Update Area" : "Add Area"}
                  </DialogTitle>
                  <DialogContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <InputField
                          name="name"
                          label="Name"
                          placeholder="Enter Name"
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <InputField
                          name="total_photos"
                          label="No. of Photos"
                          placeholder="Enter Total No. of Photos"
                          //   type="number"
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <UploadField
                          label="Upload  Photo"
                          name="photo"
                          id="photo"
                          onChange={setFieldValue}
                          value={values.photo}
                          folder="area/photo"
                          delImageUrl={this.setDelImageUrl}
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions className={classes.modalFooter}>
                    <Button
                      onClick={this.handleClose}
                      color="primary"
                      className={classes.cancelbutton}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      className={classes.addbutton}
                      type="submit"
                      variant="contained"
                    >
                      {id ? "Edit" : "Add"}
                    </Button>
                  </DialogActions>
                  {loading && <CircularLoader />}
                </Form>
              )}
            </Formik>
          </Dialog>
          <div className={classes.add_area_button}>
            {checkPermission("PLAN_CATEGORY_CREATE") && (
              <Button
                onClick={() => this.addOpen()}
                variant="contained"
                color="primary"
                className={classes.planCategoryButton}
              >
                Add Area
              </Button>
            )}
          </div>
        </div>
        <div className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <StyledTableCell>Sr. No.</StyledTableCell>
                <StyledTableCell align="left">Area Name</StyledTableCell>
                <StyledTableCell align="left">Total Photos</StyledTableCell>
                <StyledTableCell align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {all_area &&
                all_area.length > 0 &&
                all_area
                  // eslint-disable-next-line no-shadow
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, id) => {
                    return (
                      <TableRow className={classes.root1}>
                        <StyledTableCell component="th" scope="row">
                          {page * rowsPerPage + id + 1}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {item.name}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {item.total_photos}
                        </StyledTableCell>
                        <StyledTableCell>
                          <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            className={classes.editbutton}
                            onClick={() => this.handleClickOpen(item._id)}
                          >
                            <CreateIcon
                              size="small"
                              className={classes.editicon}
                            />
                            Edit
                          </Button>
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
          {all_area && all_area.length > 0 && (
            <Pagination
              count={itemLength}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.spaces.loading,
    all_area: state.spaces.all_area,
    add_area: state.spaces.add_area,
    single_area: state.spaces.single_area,
    update_area: state.spaces.update_area,
  };
};

export default connect(mapStateToProps, {
  createArea,
  getArea,
  getSingleArea,
  updateArea,
})(withStyles(styles)(AddAreaTab));
