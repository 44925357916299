import { GET_ASSOCIATES } from "../Actions/types";

const initialState = {
  associates: [],
  loading: false
};
export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ASSOCIATES:
      return {
        ...state,
        associates: action.payload
      };
    default:
      return state;
  }
}
