/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import CssBaseline from '@material-ui/core/CssBaseline'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import classNames from 'classnames'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

// eslint-disable-next-line import/no-duplicates
import { withStyles } from '@material-ui/core/styles'
import { Globe, Smile, Coffee, FileText } from 'react-feather'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
// eslint-disable-next-line import/no-duplicates
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import Badge from '@material-ui/core/Badge'
import { logoutUser } from '../../Actions/authAction'
import { clearCurrentProfile } from '../../Actions/profileAction'
import { getCurrentCompany } from '../../Actions/companiesAction'
import { checkPermission } from '../../Utils/checkPermission'
import dashboardIcon from '../../Assets/dashboard.png'
import companyIcon from '../../Assets/community.png'
import freelanceImage from '../../Assets/freelance-01.png'
import coworkerIcon from '../../Assets/co-workers.png'
import bookingIcon from '../../Assets/bookings.png'
import visitorIcon from '../../Assets/visitors.png'
import eventIcon from '../../Assets/events.png'
import partnerIcon from '../../Assets/partners.png'
import cafeteriaIcon from '../../Assets/cafeteria.png'
import helpdeskIcon from '../../Assets/helpdesk.png'
import clubIcon from '../../Assets/clubs.png'
import alliedserviceIcon from '../../Assets/allied-services.png'
import ambassadorIcon from '../../Assets/ambassadors.png'

const COMPANIES = '/companies'
const DASHBOARD = '/dashboard'
const EVENTS = '/events'
const PARTNERS = '/partners'
const COWORKERS = '/co-workers'
const BOOKINGS = '/bookings'
const VISITORS = '/visitors'
const CAFETERIA = '/cafeteria'
const FOODCATEGORY = 'FOODCATEGORY'
const ORDERSTATUS = 'ORDERSTATUS'
const ALLCLUBS = '/clubs'
const HELPDESK = '/helpdesk'
const FOODITEMS = '/food-items'
const FREELANCER = '/freelancer'
const ALLIEDSERVICES = '/allied-services'
const COMMUNITYAMBASSADOR = '/Community-Ambassador'
const CREDITACCOUNT = '/credit-accounts'
const styles = (theme) => ({
  categoryHeader: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  categoryHeaderPrimary: {
    color: theme.palette.primary.dark,
    padding: '0px 18px',
  },
  item: {
    padding: '12px 7px 12px 34px',
    // marginTop: "-8px"
  },
  itemCategory: {
    paddingTop: 16,
  },
  firebase: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.white,
  },
  itemActionable: {
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  itemActiveItem: {
    color: '#4fc3f7',
  },
  itemPrimary: {
    color: 'rgba(0, 0, 0, 0.95)',
    fontSize: theme.typography.fontSize,
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  drawerPaper: {
    marginTop: theme.spacing(5),
  },
  naviIcons: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  freelancerImage: {
    width: '23px',
    height: '23px',
  },
  cafeteriaImage: {
    width: '23px',
    height: '23px',
  },
})
const theme = createMuiTheme({
  overrides: {
    MuiList: {
      padding: {
        padding: '0px !important',
      },
    },
    MuiDrawer: {
      paper: {
        marginTop: '64px',
        height: '90% !important',
      },
    },
  },
})
const StyledBadge = withStyles(() => ({
  badge: {
    right: -25,
    top: 13,
    fontSize: '10px',
    height: '12px',
  },
}))(Badge)

class Navigator extends React.Component {
  state = {
    mobileOpen: false,
    // eslint-disable-next-line react/no-unused-state
    selectedIndex: this.props.location.pathname,
  }

  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
  }

  componentDidMount() {
    if (this.props.auth.user.role === 'admin') {
      const { id } = this.props.auth.user
      this.props.getCurrentCompany(id)
    }
  }

  handleClick = () => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    this.setState({ open: !this.state.open })
  }

  handleDrawerToggle = () => {
    this.setState((state) => ({ mobileOpen: !state.mobileOpen }))
  }

  logout = () => {
    this.props.logoutUser()
    this.props.clearCurrentCompany()
  }

  handleListItemClick = (event, index) => {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ selectedIndex: index })
  }

  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1
  }

  render() {
    const role = localStorage.getItem('role')
    const { classes } = this.props
    const { isAuthenticated, user } = this.props.auth
    // eslint-disable-next-line no-unused-vars
    const { policy, name: roleType } =
      user.policy && user.policy[0] ? user.policy[0].roletype_id : ''

    const drawer = (
      <div>
        <ThemeProvider theme={theme}>
          <List
            position="absolute"
            component="nav"
            aria-label="Main mailbox folders"
          >
            {checkPermission('COWORKER_VIEW') &&
              role !== 'security' &&
              role !== 'cafe' && (
                <ListItem
                  button
                  dense
                  component={Link}
                  to="/dashboard"
                  key={DASHBOARD}
                  selected={this.activeRoute(DASHBOARD)}
                  className={classNames(classes.item, classes.itemActionable)}
                  onClick={(event) =>
                    this.handleListItemClick(event, DASHBOARD)
                  }
                >
                  {/* <ListItemIcon className={classes.naviIcons}>
                    <Grid size={20} />
                  </ListItemIcon> */}
                  <ListItemIcon className={classes.naviIcons}>
                    <img
                      src={dashboardIcon}
                      alt=""
                      size={20}
                      className={classes.freelancerImage}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Dashboard"
                    classes={{
                      primary: classes.itemPrimary,
                    }}
                  />
                </ListItem>
              )}
            {checkPermission('COMPANY_VIEW') && role !== 'cafe' && (
              <ListItem
                button
                dense
                component={Link}
                to={
                  role !== 'coworkeradmin' && role !== 'coworker'
                    ? '/companies'
                    : '/community'
                }
                key="companies"
                selected={this.activeRoute(COMPANIES)}
                // selected={this.state.selectedIndex === COMPANIES}
                className={classNames(classes.item, classes.itemActionable)}
                onClick={(event) => this.handleListItemClick(event, COMPANIES)}
              >
                {/* <ListItemIcon className={classes.naviIcons}>
                  <Briefcase size={20} />
                </ListItemIcon> */}
                <ListItemIcon className={classes.naviIcons}>
                  <img
                    src={companyIcon}
                    alt=""
                    size={20}
                    className={classes.freelancerImage}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={roleType === 'admin' ? 'Company' : 'Community'}
                  classes={{
                    primary: classes.itemPrimary,
                  }}
                />
              </ListItem>
            )}
            {checkPermission('COMPANY_VIEW') &&
              role !== 'coworkeradmin' &&
              role !== 'coworker' &&
              role !== 'cafe' && (
                <ListItem
                  button
                  dense
                  component={Link}
                  to="/freelancers"
                  key="freelancer"
                  selected={this.activeRoute(FREELANCER)}
                  // selected={this.state.selectedIndex === FREELANCER}
                  className={classNames(classes.item, classes.itemActionable)}
                  onClick={(event) =>
                    this.handleListItemClick(event, FREELANCER)
                  }
                >
                  <ListItemIcon className={classes.naviIcons}>
                    <img
                      src={freelanceImage}
                      alt=""
                      size={20}
                      className={classes.freelancerImage}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Freelancer"
                    classes={{
                      primary: classes.itemPrimary,
                    }}
                  />
                </ListItem>
              )}
            {checkPermission('COWORKER_VIEW') &&
              role !== 'security' &&
              role !== 'cafe' && (
                <ListItem
                  button
                  dense
                  component={Link}
                  to="/co-workers"
                  key="co-workers"
                  selected={this.activeRoute(COWORKERS)}
                  // selected={this.state.selectedIndex === COWORKERS}
                  className={classNames(classes.item, classes.itemActionable)}
                  onClick={(event) =>
                    this.handleListItemClick(event, COWORKERS)
                  }
                >
                  {/* <ListItemIcon className={classes.naviIcons}>
                    <People size={20} />
                  </ListItemIcon> */}
                  <ListItemIcon className={classes.naviIcons}>
                    <img
                      src={coworkerIcon}
                      alt=""
                      size={20}
                      className={classes.freelancerImage}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Co-workers"
                    classes={{
                      primary: classes.itemPrimary,
                    }}
                  />
                </ListItem>
              )}
            {checkPermission('BOOKING_VIEW') && (
              <ListItem
                button
                dense
                component={Link}
                to="/bookings"
                key="bookings"
                selected={this.activeRoute(BOOKINGS)}
                // selected={this.state.selectedIndex === BOOKINGS}
                className={classNames(classes.item, classes.itemActionable)}
                onClick={(event) => this.handleListItemClick(event, BOOKINGS)}
              >
                {/* <ListItemIcon className={classes.naviIcons}>
                  <CalendarToday />
                </ListItemIcon> */}
                <ListItemIcon className={classes.naviIcons}>
                  <img
                    src={bookingIcon}
                    alt=""
                    size={20}
                    className={classes.freelancerImage}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Booking"
                  classes={{
                    primary: classes.itemPrimary,
                  }}
                />
              </ListItem>
            )}
            {checkPermission('VISITOR_VIEW') && (
              <ListItem
                button
                dense
                component={Link}
                to="/visitors"
                key="visitors"
                selected={this.activeRoute(VISITORS)}
                // selected={this.state.selectedIndex === VISITORS}
                className={classNames(classes.item, classes.itemActionable)}
                onClick={(event) => this.handleListItemClick(event, VISITORS)}
              >
                {/* <ListItemIcon className={classes.naviIcons}>
                  <PersonPin />
                </ListItemIcon> */}
                <ListItemIcon className={classes.naviIcons}>
                  <img
                    src={visitorIcon}
                    alt=""
                    size={20}
                    className={classes.freelancerImage}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Visitors"
                  classes={{
                    primary: classes.itemPrimary,
                  }}
                />
              </ListItem>
            )}
            {checkPermission('EVENT_VIEW') && (
              <ListItem
                button
                dense
                component={Link}
                to="/events"
                key="events"
                selected={this.activeRoute(EVENTS)}
                className={classNames(classes.item, classes.itemActionable)}
                onClick={(event) => this.handleListItemClick(event, EVENTS)}
              >
                {/* <ListItemIcon className={classes.naviIcons}>
                  <Calendar size={20} />
                </ListItemIcon> */}
                <ListItemIcon className={classes.naviIcons}>
                  <img
                    src={eventIcon}
                    alt=""
                    size={20}
                    className={classes.freelancerImage}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Events"
                  classes={{
                    primary: classes.itemPrimary,
                  }}
                />
              </ListItem>
            )}
            {checkPermission('PARTNER_VIEW') && (
              <ListItem
                button
                dense
                component={Link}
                to="/partners"
                key="partners"
                // selected={this.state.selectedIndex === PARTNERS}
                selected={this.activeRoute(PARTNERS)}
                className={classNames(classes.item, classes.itemActionable)}
                onClick={(event) => this.handleListItemClick(event, PARTNERS)}
              >
                {/* <ListItemIcon className={classes.naviIcons}>
                  <LinearScale size={20} />
                </ListItemIcon> */}
                <ListItemIcon className={classes.naviIcons}>
                  <img
                    src={partnerIcon}
                    alt=""
                    size={20}
                    className={classes.freelancerImage}
                  />
                </ListItemIcon>
                <StyledBadge
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  badgeContent="BETA"
                  color="error"
                >
                  <ListItemText
                    primary="Partners"
                    classes={{
                      primary: classes.itemPrimary,
                    }}
                  />
                </StyledBadge>
              </ListItem>
            )}
            {role === 'cafe' && checkPermission('ORDER_CAFE_STATUS') && (
              <ListItem
                button
                dense
                component={Link}
                to="/order-status"
                key="order-status"
                // selected={this.state.selectedIndex === ORDERSTATUS}
                selected={this.activeRoute(ORDERSTATUS)}
                className={classNames(classes.item, classes.itemActionable)}
                onClick={(event) =>
                  this.handleListItemClick(event, ORDERSTATUS)
                }
              >
                <ListItemIcon className={classes.naviIcons}>
                  <FileText size={20} />
                </ListItemIcon>
                <ListItemText
                  primary="Order Status"
                  classes={{
                    primary: classes.itemPrimary,
                  }}
                />
              </ListItem>
            )}
            {role === 'cafe' && checkPermission('FOODCATEGORY_VIEW') && (
              <ListItem
                button
                dense
                component={Link}
                to="/food-categories"
                key="foodcategory"
                // selected={this.state.selectedIndex === FOODCATEGORY}
                selected={this.activeRoute(FOODCATEGORY)}
                className={classNames(classes.item, classes.itemActionable)}
                onClick={(event) =>
                  this.handleListItemClick(event, FOODCATEGORY)
                }
              >
                <ListItemIcon className={classes.naviIcons}>
                  <Coffee size={20} />
                </ListItemIcon>
                <ListItemText
                  primary="Food Category"
                  classes={{
                    primary: classes.itemPrimary,
                  }}
                />
              </ListItem>
            )}
            {role === 'cafe' && checkPermission('FOODITEM_VIEW') && (
              <ListItem
                button
                dense
                component={Link}
                to="/food-items"
                key="fooditems"
                // selected={this.state.selectedIndex === FOODITEMS}
                selected={this.activeRoute(FOODITEMS)}
                className={classNames(classes.item, classes.itemActionable)}
                onClick={(event) => this.handleListItemClick(event, FOODITEMS)}
              >
                <ListItemIcon className={classes.naviIcons}>
                  <Smile size={20} />
                </ListItemIcon>
                <ListItemText
                  primary="Food Items"
                  classes={{
                    primary: classes.itemPrimary,
                  }}
                />
              </ListItem>
            )}

            {(checkPermission('ORDER_CREATE') ||
              checkPermission('CAFETERIA_MENU')) &&
              role !== 'security' && (
                <ListItem
                  button
                  dense
                  component={Link}
                  to="/cafeteria"
                  key="cafeteria"
                  // selected={this.state.selectedIndex === CAFETERIA}
                  selected={this.activeRoute(CAFETERIA)}
                  className={classNames(classes.item, classes.itemActionable)}
                  onClick={(event) =>
                    this.handleListItemClick(event, CAFETERIA)
                  }
                >
                  {/* <ListItemIcon className={classes.naviIcons}>
                    <Fastfood />
                  </ListItemIcon> */}
                  <ListItemIcon className={classes.naviIcons}>
                    <img
                      src={cafeteriaIcon}
                      alt=""
                      size={20}
                      className={classes.cafeteriaImage}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Cafeteria"
                    classes={{
                      primary: classes.itemPrimary,
                    }}
                  />
                </ListItem>
              )}
            {checkPermission('HELPDESK_TICKET_VIEW') && (
              <ListItem
                button
                dense
                component={Link}
                to="/helpdesk"
                key="helpdesk"
                // selected={this.state.selectedIndex === HELPDESK}
                selected={this.activeRoute(HELPDESK)}
                className={classNames(classes.item, classes.itemActionable)}
                onClick={(event) => this.handleListItemClick(event, HELPDESK)}
              >
                {/* <ListItemIcon className={classes.naviIcons}>
                  <Help />
                </ListItemIcon> */}
                <ListItemIcon className={classes.naviIcons}>
                  <img
                    src={helpdeskIcon}
                    alt=""
                    size={20}
                    className={classes.freelancerImage}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Helpdesk"
                  classes={{
                    primary: classes.itemPrimary,
                  }}
                />
              </ListItem>
            )}

            {checkPermission('GROUP_ALL') && (
              <ListItem
                button
                dense
                component={Link}
                to="/clubs"
                key="clubs"
                // selected={this.state.selectedIndex === ALLCLUBS}
                selected={this.activeRoute(ALLCLUBS)}
                className={classNames(classes.item, classes.itemActionable)}
                onClick={(event) => this.handleListItemClick(event, ALLCLUBS)}
              >
                {/* <ListItemIcon className={classes.naviIcons}>
                  <Trello size={20} />
                </ListItemIcon> */}
                <ListItemIcon className={classes.naviIcons}>
                  <img
                    src={clubIcon}
                    alt=""
                    size={20}
                    className={classes.freelancerImage}
                  />
                </ListItemIcon>
                <StyledBadge
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  badgeContent="BETA"
                  color="error"
                >
                  <ListItemText
                    primary="Clubs"
                    classes={{
                      primary: classes.itemPrimary,
                    }}
                  />
                </StyledBadge>
              </ListItem>
            )}
            {role !== 'cafe' && role !== 'it' && (
              <ListItem
                button
                dense
                component={Link}
                to="/allied-services"
                key="allied-services"
                // selected={this.state.selectedIndex === ALLCLUBS}
                selected={this.activeRoute(ALLIEDSERVICES)}
                className={classNames(classes.item, classes.itemActionable)}
                onClick={(event) =>
                  this.handleListItemClick(event, ALLIEDSERVICES)
                }
              >
                {/* <ListItemIcon className={classes.naviIcons}>
                  <Bell size={20} />
                </ListItemIcon> */}
                <ListItemIcon className={classes.naviIcons}>
                  <img
                    src={alliedserviceIcon}
                    alt=""
                    size={20}
                    className={classes.freelancerImage}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Allied Services"
                  classes={{
                    primary: classes.itemPrimary,
                  }}
                />
              </ListItem>
            )}
            {role !== 'cafe' &&
              role !== 'it' &&
              checkPermission('COMMUNITY_AMBASSADOR_VIEW') && (
                <ListItem
                  button
                  dense
                  component={Link}
                  to="/community-ambassador"
                  key="Community-Ambassador"
                  // selected={this.state.selectedIndex === ALLCLUBS}
                  selected={this.activeRoute(COMMUNITYAMBASSADOR)}
                  className={classNames(classes.item, classes.itemActionable)}
                  onClick={(event) =>
                    this.handleListItemClick(event, COMMUNITYAMBASSADOR)
                  }
                >
                  {/* <ListItemIcon className={classes.naviIcons}>
                    <img src={knowledgeIcon} alt="" size={20} />
                  </ListItemIcon> */}
                  <ListItemIcon className={classes.naviIcons}>
                    <img
                      src={ambassadorIcon}
                      alt=""
                      size={20}
                      className={classes.freelancerImage}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Ambassadors"
                    classes={{
                      primary: classes.itemPrimary,
                    }}
                  />
                </ListItem>
              )}
            {role === 'cafe' && (
              <ListItem
                button
                dense
                component={Link}
                to="/credit-accounts"
                key="creditaccount"
                // selected={this.state.selectedIndex === FOODITEMS}
                selected={this.activeRoute(CREDITACCOUNT)}
                className={classNames(classes.item, classes.itemActionable)}
                onClick={(event) =>
                  this.handleListItemClick(event, CREDITACCOUNT)
                }
              >
                <ListItemIcon className={classes.naviIcons}>
                  <Smile size={20} />
                </ListItemIcon>
                <ListItemText
                  primary="Credit Accounts"
                  classes={{
                    primary: classes.itemPrimary,
                  }}
                />
              </ListItem>
            )}
          </List>
        </ThemeProvider>
      </div>
    )

    const drawerWidth = 240

    const drawerLogin = (
      <div>
        <div className={classes.toolbar} />
        <Divider />
        <List>
          <ListItem button key="login">
            <ListItemIcon>
              <Globe />
            </ListItemIcon>
            <Link to="login">
              <ListItemText primary="Login" />
            </Link>
          </ListItem>
          <ListItem button key="register">
            <ListItemIcon>
              <Globe />
            </ListItemIcon>
            <Link to="register">
              <ListItemText primary="Register" />
            </Link>
          </ListItem>
        </List>
      </div>
    )

    return (
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <CssBaseline />
          <nav className={classes.drawer}>
            <Hidden smUp implementation="css">
              <Drawer
                container={this.props.container}
                variant="permanent"
                anchor="left"
                open={this.state.mobileOpen}
                onClose={this.handleDrawerToggle}
                // classes={{
                //   paper: classes.drawerPaper,
                // }}
                PaperProps={{ style: { width: drawerWidth } }}
              >
                {/* <Divider /> */}
                {isAuthenticated ? drawer : drawerLogin}
              </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
              <Drawer
                // classes={{
                //   paper: classes.drawerPaper,
                // }}
                variant="permanent"
                PaperProps={{ style: { width: drawerWidth } }}
              >
                {drawer}
                {/* {isAuthenticated ? drawer : drawerLogin} */}
              </Drawer>
            </Hidden>
          </nav>
        </div>
      </ThemeProvider>
    )
  }
}

Navigator.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
  // Injected by the documentation to work in an iframe.
  // You won't need it on your project.
  container: PropTypes.object,
  // logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  clearCurrentProfile: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  company: state.company,
})

export default connect(mapStateToProps, {
  logoutUser,
  clearCurrentProfile,
  getCurrentCompany,
})(withStyles(styles)(withRouter(Navigator)))
