import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  TableBody,
  TableHead,
  TableRow,
  Table,
  Paper,
  withStyles,
  CssBaseline,
  Grid,
  Button,
  AppBar,
  Toolbar,
} from "@material-ui/core";
import SearchBar from "../../Components/Common/SearchBar";
import Cell from "../../Components/Common/Table/TableCell";
import Header from "../../Components/Common/Table/TableHeader";
import NoRecordsFound from "../../Components/Common/NoRecordsFound";
import Pagination from "../../Components/Common/Table/TablePagination";
import { checkPermission } from "../../Utils/checkPermission";
import { capitalize, spacecapitalize } from "../../Utils/StringFunctions";
import { getParticipants } from "../../Actions/participantActions";
import AttendesStatus from "../../Components/Common/AttendesStatus";
import DataNotFound from "../../Components/Common/DataNotFound";
import ScreenHeader from "../../Components/Common/ScreenHeader";
import EventImportExcel from "./EventImportExcel";

const moment = require("moment");

const styles = (theme) => ({
  paper: {
    padding: "28px 20px 14px 24px",
    flexGrow: 1,
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      padding: "28px 20px 14px 24px",
    },
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  appbar: {
    backgroundColor: "#ffffff",
    paddingTop: "15px",
  },
  table: {
    minWidth: 650,
  },
  descriptiontable: {
    fontSize: "12px",
    borderBottom: "none",
  },
  root1: {
    "&:nth-child(even)": {
      backgroundColor: "#FCFDFE",
    },
  },
  importexcel: {
    color: "white",
    borderRadius: "4px",
    textDecoration: "none",
    fontSize: "0.875rem",
    fontWeight: "500",
    marginRight: "4%",
    marginTop: "-25px",
    boxShadow: "rgba(137, 163, 169, 0.3) 0px 3px 5px 2px",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
});

export class EventAttendesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      participants: [], // participants data
      page: 0, // default table page
      rowsPerPage: 10, // rows per page
      importExcelModalOpen: false,
      search: "",
      isSearch: false,
    };
    this.state.id = this.props.match.params.id; // event  id
  }

  componentDidMount() {
    this.props.getParticipants(this.state.id); // get event participants
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.participants !== prevState.participants) {
      return { participants: nextProps.participants.data };
    }
    return [];
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  importExcelModalOpen = () => {
    this.setState({
      importExcelModalOpen: true,
    });
  };

  importExcelModalClose = () => {
    this.setState({
      importExcelModalOpen: false,
    });
    this.props.getParticipants(this.state.id);
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  // search event attendees
  searchAttendees = (search, isSearch) => {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ isSearch: true, search });
    this.props.getParticipants(this.state.id, search, isSearch);
  };

  // Attendees table header
  renderHead = () => {
    return (
      <TableHead>
        <TableRow>
          <Header>Sr. No.</Header>
          <Header>Date</Header>
          <Header>Name</Header>
          <Header>Company Name</Header>
          <Header>Contact No</Header>
          <Header>Type</Header>
          <Header>Status</Header>
        </TableRow>
      </TableHead>
    );
  };

  // Attendees table body
  renderBody = () => {
    const { classes } = this.props;
    const { participants, page, rowsPerPage } = this.state;
    return (
      <TableBody>
        {participants &&
          participants
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, key) => (
              <TableRow className={classes.root1}>
                <Cell>{Number(key) + 1}</Cell>
                <Cell className={classes.descriptiontable}>
                  {row && moment(row.createdAt).format("DD/MM/YYYY")}
                </Cell>
                <Cell className={classes.descriptiontable}>
                  {row.user_id && capitalize(row.user_id.first_name)}{" "}
                  {row.user_id && capitalize(row.user_id.last_name)}
                </Cell>
                <Cell className={classes.descriptiontable}>
                  {/* {row.type_of_user === "coworker"
                    ? row.company_id &&
                      row.company_id.company_name &&
                      capitalize(row.company_id.company_name)
                    : row.company_name && capitalize(row.company_name)} */}
                  {row.company_id &&
                  row.company_id.type === "freelancer" &&
                  row.type_of_user === "coworker"
                    ? "-"
                    : row.company_id &&
                      row.company_id.company_name &&
                      capitalize(row.company_id.company_name)}
                  {row.type_of_user === "guestuser" &&
                    spacecapitalize(row.company_name)}
                </Cell>
                <Cell className={classes.descriptiontable}>
                  {row.user_id && row.user_id.contact_number}
                </Cell>
                <Cell className={classes.descriptiontable}>
                  {row.type_of_user === "guestuser"
                    ? "Guest User"
                    : row.type_of_user}
                </Cell>
                <Cell className={classes.descriptiontable}>
                  <AttendesStatus
                    selectedItem={row.status}
                    value={row.status}
                    id={row._id}
                    key={row._id}
                    event_id={row.event_id}
                  />
                </Cell>
              </TableRow>
            ))}
      </TableBody>
    );
  };

  // render Header Button
  renderHeaderButton = () => {
    const { classes } = this.props;
    return (
      <Grid
        container
        justify="flex-end"
        alignItems="flex-end"
        style={{ marginTop: "25px" }}
      >
        {checkPermission("EVENT_PARTICIPANT_CREATE") && (
          <Button
            to="#"
            onClick={this.importExcelModalOpen}
            className={classes.importexcel}
            style={{ padding: "5px 16px", marginRight: "5px" }}
          >
            Import Excel
          </Button>
        )}
        <Button
          href="https://cowoker-production-release-upload.s3.ap-south-1.amazonaws.com/eventparticipation-sample.csv"
          target="_blank"
          variant="contained"
          className={classes.importexcel}
          style={{ padding: "5px 16px", marginRight: "5px" }}
        >
          Sample CSV
        </Button>
      </Grid>
    );
  };

  render() {
    const { classes } = this.props;
    const { participants, isSearch, search } = this.state;
    const itemLength = participants ? participants.length : 0;

    return (
      <React.Fragment>
        {participants && participants.length <= 0 && !isSearch ? (
          <div>
            <div className={classes.header}>{this.renderHeaderButton()}</div>
            <DataNotFound text="No Participants Found" />
          </div>
        ) : (
          <div>
            <CssBaseline />
            <div className={classes.header}>
              <ScreenHeader type="All Attendees" />
              {this.renderHeaderButton()}
            </div>
            <div className={classes.searchSection}>
              <AppBar position="static" className={classes.appbar}>
                <Toolbar>
                  <SearchBar
                    type="Search Attendees"
                    className={classes.searchBox}
                    onSearch={this.searchAttendees}
                  />
                  {/* <div className={classes.sortby}>
                <SortBy selectedItem="SortBy" style={{ color: "#049b04" }} />
              </div> */}
                </Toolbar>
              </AppBar>
            </div>
            {participants && participants.length <= 0 && isSearch && (
              <NoRecordsFound
                text={`No records found for your text "${search}"`}
              />
            )}
            <CssBaseline />
            <main className={classes.layout}>
              <Paper className={classes.paper}>
                <div>
                  {participants && participants.length > 0 && (
                    <Table className={classes.table}>
                      {this.renderHead()}
                      {this.renderBody()}
                    </Table>
                  )}
                </div>
              </Paper>
              {participants && participants.length > 0 && (
                <Pagination
                  count={itemLength}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              )}
            </main>
          </div>
        )}
        <EventImportExcel
          modalOpen={this.state.importExcelModalOpen}
          handleClose={this.importExcelModalClose}
          eventID={this.state.id}
          dialogVisible={this.state.importExcelModalOpen}
        />
      </React.Fragment>
    );
  }
}
EventAttendesList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    participants: state.participants.participants,
  };
};

export default connect(mapStateToProps, { getParticipants })(
  withStyles(styles)(EventAttendesList)
);
