import React from "react";
import {
  Button,
  Grid,
  CircularProgress,
  CssBaseline,
  Typography,
  Paper,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import { FormikTextField } from "formik-material-fields";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import LoginImage from "../../Assets/login.png";
import { resetPassword, checkToken } from "../../Actions/authAction";

const styles = (theme) => {
  return {
    root: {
      maxHeight: "100vh",
    },
    paper: {
      display: "flex",
      flexDirection: "column",
      height: "-webkit-fill-available",
      padding: "24% 22% ",
      backgroundColor: "#fff",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: "#f50057",
    },
    form: {
      width: "100%",
      marginTop: theme.spacing(1),
    },
    redirectlink: {
      textDecoration: "none",
      color: "#3f51b5",
    },
    login: {
      backgroundImage: `url(${LoginImage})`,
      width: "100%",
      height: "100vh",
      backgroundSize: "cover",
      backgroundPosition: "50%",
    },
    formfooter: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-end",
      marginTop: "2%",
    },
    mainTitle: {
      fontSize: "28px",
      lineHeight: "1.75rem",
      fontWeight: 600,
      // marginBottom: "1rem",
      color: "#110f24",
    },
    subTitle: {
      fontSize: "14px",
      fontWeight: 400,
      letterSpacing: ".3px",
      lineHeight: "2rem",
      marginBottom: "1rem",
      color: "#ABABAB",
    },
    multilineColor: {
      height: "50px",
      padding: "0px 10px",
      // backgroundColor: "#f6f6f9"
    },
    submitbutton: {
      backgroundColor: "#211b4e !important",
      "&:hover": {
        backgroundColor: "#151039 !important",
      },
      "&:disabled": {
        backgroundColor: "lightgrey !important",
      },
    },
    progress: {
      display: "flex",
      justifyContent: "center",
      width: "50%",
    },
    loginButton: {
      marginTop: "14px",
    },
  };
};
const theme = createMuiTheme({
  overrides: {
    MuiInputLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        "&$error": {
          // increase the specificity for the pseudo class
          color: "rgba(0,0,0,0.54)",
        },
      },
    },
    MuiInputBase: {
      input: {
        paddingLeft: "10px !important",
        fontSize: "14px",
      },
    },
  },
});

const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string().required("Required"),
  confirmpassword: Yup.string().when("password", {
    is: (val) => !!(val && val.length > 0),
    then: Yup.string().oneOf([Yup.ref("password")], " Password didn't match"),
  }),
});

class NewPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // errors: {},
      // id: "",
      checkTokenData: {},
      isSubmit: false,
      NewPassword: true,
      ConfirmPassword: true,
      // isConfirm: false,
      // values: {}
    };
  }

  componentDidMount() {
    // check token api call
    const { id } = this.props.match.params || "";
    const data = { token: id };
    this.props.checkToken(data);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.checkTokenData &&
      nextProps.checkTokenData !== prevState.checkTokenData
    ) {
      return { checkTokenData: nextProps.checkTokenData };
    }

    return {};
  }

  validateReenterPassword = () => {
    let error;

    return error;
  };

  renderForm() {
    const { classes } = this.props;
    const { checkTokenData } = this.state;
    const { loading, errors, success } = checkTokenData;
    const { isSubmit } = this.state;
    if (loading !== false) {
      return (
        <div className={classes.progress}>
          <CircularProgress />
        </div>
      );
    }
    if (isSubmit) {
      return (
        <div>
          <Typography
            component="h5"
            variant="h5"
            className={classes.mainTitle}
            marginBottom
          >
            Success! Your Password has been changed!
          </Typography>
          <div className={classes.loginButton}>
            <Link to="/login">
              <Button
                variant="contained"
                color="primary"
                className={classes.submitbutton}
              >
                Login
              </Button>
            </Link>
          </div>
        </div>
      );
    }
    if (success) {
      // eslint-disable-next-line no-shadow
      return ({ values }) => (
        <Form className={classes.form}>
          <ThemeProvider theme={theme}>
            <FormikTextField
              name="password"
              label="Password"
              type={this.state.NewPassword ? "password" : "text"}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              className={classes.inputField}
              InputProps={{
                classes: {
                  input: classes.multilineColor,
                },
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        // eslint-disable-next-line react/no-access-state-in-setstate
                        this.setState({ NewPassword: !this.state.NewPassword });
                      }}
                      // onMouseDown={handleMouseDownPassword}
                    >
                      {this.state.NewPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              placeholder="Enter your Password"
            />
            <FormikTextField
              name="confirmpassword"
              label="Confirm Password"
              type={this.state.ConfirmPassword ? "password" : "text"}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              className={classes.inputField}
              InputProps={{
                classes: {
                  input: classes.multilineColor,
                },
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        // eslint-disable-next-line react/no-access-state-in-setstate
                        this.setState({
                          // eslint-disable-next-line react/no-access-state-in-setstate
                          ConfirmPassword: !this.state.ConfirmPassword,
                        });
                      }}
                      // onMouseDown={handleMouseDownPassword}
                    >
                      {this.state.ConfirmPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              placeholder="Confirm Password"
            />

            <div className={classes.formfooter}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className={classes.submitbutton}
                disabled={values.password !== values.confirmpassword}
              >
                Submit
              </Button>
            </div>
          </ThemeProvider>
        </Form>
      );
    }
    if (errors.token) {
      return (
        <div>
          <Typography
            component="p"
            variant="body1"
            className={classes.subTitle}
          >
            Your token is expired.
          </Typography>
          <Link to="/login">
            <Button
              variant="contained"
              color="primary"
              className={classes.submitbutton}
            >
              Resend link
            </Button>
          </Link>
        </div>
      );
    }
    return (
      <div>
        <Typography component="p" variant="body1" className={classes.subTitle}>
          There was some issue verifying your token.
        </Typography>
        <Link to="/login">
          <Button
            variant="contained"
            color="primary"
            className={classes.submitbutton}
          >
            Resend link
          </Button>
        </Link>
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    const { id } = this.props.match.params || "";
    const { isSubmit } = this.state;
    return (
      <div>
        <CssBaseline />
        <Grid container>
          <Grid item xs={12} sm={6} md={6} component={Paper}>
            <div className={classes.paper}>
              <div className={classes.loginContent}>
                {!isSubmit && (
                  <span>
                    <Typography
                      component="h5"
                      variant="h5"
                      className={classes.mainTitle}
                    >
                      Reset Password
                    </Typography>
                    <Typography
                      component="p"
                      variant="body1"
                      className={classes.subTitle}
                    >
                      Enter your details below.
                    </Typography>
                  </span>
                )}

                <Formik
                  initialValues={{
                    password: "",
                    token: id,
                    // confirmpassword: ""
                  }}
                  validationSchema={ResetPasswordSchema}
                  onSubmit={(payload, { setErrors, setSubmitting }) => {
                    this.props.resetPassword(payload, setErrors, setSubmitting);
                    this.setState({ isSubmit: true });
                  }}
                >
                  {this.renderForm()}
                </Formik>
              </div>
            </div>
          </Grid>
          <Grid
            container
            sm={6}
            md={6}
            className={classes.image}
            direction="column"
          >
            <div className={classes.login} />
          </Grid>
        </Grid>
      </div>
    );
  }
}

NewPassword.propType = {
  resetPassword: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  user: state.auth.user,
  checkTokenData: state.auth.checkToken,
  loading: state.auth.loading,
});

export default connect(mapStateToProps, { resetPassword, checkToken })(
  withStyles(styles)(NewPassword)
);
