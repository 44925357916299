/* eslint-disable camelcase */
import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import { Formik, Form } from "formik";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import * as Yup from "yup";
import CreateIcon from "@material-ui/icons/Create";
import { CircularLoader } from "../../Components/Common/Spinner";
import {
  addplancategory,
  getPlancategory,
  getSinglePlancategory,
  editPlanCategory,
} from "../../Actions/plancategoryActions";
import { checkPermission } from "../../Utils/checkPermission";
import RadioField from "../../Components/Common/Form/RadioField";
import InputField from "../../Components/Common/Form/InputField";
import ScreenHeader from "../../Components/Common/ScreenHeader";
import nodata from "../../Assets/nodata.png";

const $quoteSym = " ";
const styles = () => ({
  main: {
    padding: "24px",
  },
  plancontainer: {
    backgroundColor: "#fff",
  },
  label: {
    margin: "15px 0px 8px 0px",
    fontSize: "12px",
    fontWeight: "700",
  },
  table: {
    marginTop: "20px",
  },
  add_plan_button: {
    width: "100%",
    textAlign: "right",
  },
  plan_form_modal: {
    width: "500px",
  },
  plan_table: {
    height: "500px",
  },
  editbutton: {
    borderRadius: "50px",
  },
  editicon: {
    fontSize: "16px",
    marginRight: "6px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  planCategoryButton: {
    color: "#fff",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  ScreenHeader: {
    marginLeft: "-20px",
    width: "100%",
  },
  cancelbutton: {
    marginRight: "20px",
    backgroundColor: "#dddddd",
    color: "#151039",
    "&:hover": {
      color: "#151039",
      backgroundColor: "#d1d1d1!important",
    },
  },
  addbutton: {
    color: "#fff",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  modelHeader: {
    paddingBottom: "5px",
    position: "relative",
    "&:after": {
      top: "48px",
      left: "24px",
      width: "24px",
      borderBottom: "3px solid #fb0",
      content: `'${$quoteSym}'`,
      position: "absolute",
    },
  },
  modalFooter: {
    padding: "8px 24px",
  },
  root1: {
    "&:nth-child(even)": {
      backgroundColor: "#FCFDFE",
    },
  },
  backButton: {
    margin: "0% 0% 2% 0%",
  },
});
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F8FAFF",
    color: theme.palette.common.black,
    borderBottom: "none",
    padding: "8px 40px 8px 16px",
  },
  body: {
    fontSize: 14,
    textTransform: "capitalize",
    borderBottom: "none",
  },
}))(TableCell);
const SharedOptions = [
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
];
const PlanSchema = Yup.object().shape({
  category_name: Yup.string().trim().required("This field is required."),
  credit_per_seat: Yup.number().required("This field is required."),
  shared: Yup.string().required("This field is required."),
});
export class Adminplan extends Component {
  constructor(props) {
    super(props);
    this.state = { plan_categories: [], id: "" };
  }

  state = {
    modalOpen: false,
  };

  componentDidMount() {
    const space_id = localStorage.getItem("space_id");
    this.props.getPlancategory(space_id);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.plan_categories !== prevState.plan_categories) {
      return { plan_categories: nextProps.plan_categories };
    }
    return [];
  }

  componentDidUpdate(prevProps) {
    if (prevProps.plan_categories !== this.props.plan_categories) {
      let plan_categories = this.props.plan_categories || [];
      plan_categories = plan_categories.map((x, id) => {
        return Object.assign({}, x, {
          idx: id + 1,
        });
      });
      this.setState({
        plan_categories,
        // rowData: plan_categories
      });
    }
    if (
      this.props.edit_plan_category !== prevProps.edit_plan_category &&
      this.props.edit_plan_category.success
    ) {
      this.handleClose();
      const space_id = localStorage.getItem("space_id");
      this.props.getPlancategory(space_id);
    }
    if (
      this.props.plan_category !== prevProps.plan_category &&
      this.props.plan_category.success
    ) {
      this.handleClose();
      const space_id = localStorage.getItem("space_id");
      this.props.getPlancategory(space_id);
    }
    if (prevProps.loading !== this.props.loading) {
      this.setState({
        loading: this.props.loading,
      });
    }
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.showLoadingOverlay();
  };

  handleClickModalOpen = (id) => {
    this.setState({
      id,
      modalOpen: true,
    });
    this.props.getSinglePlancategory(id);
  };

  handleClickOpen = () => {
    this.setState({
      modalOpen: true,
      id: "",
    });
  };

  handleClose = () => {
    this.setState({
      modalOpen: false,
    });
    const space_id = localStorage.getItem("space_id");
    this.props.getPlancategory(space_id);
  };

  render() {
    const { classes, get_single_plan_category } = this.props;
    const { loading, id } = this.state;
    let showData = true;
    if (this.state.plan_categories === null) {
      showData = false;
    }
    if (loading) {
      return <CircularLoader />;
    }
    const space_id = localStorage.getItem("space_id");
    // eslint-disable-next-line no-shadow
    const getInitialValues = (id) => {
      if (id) {
        return {
          category_name: get_single_plan_category.category_name,
          credit_per_seat: get_single_plan_category.credit_per_seat,
          shared: get_single_plan_category.shared,
        };
      }
      return {
        category_name: "",
        credit_per_seat: "",
        shared: "yes",
      };
    };
    return (
      <div className={classes.main}>
        <div className={classes.header}>
          {showData && (
            <div className={classes.ScreenHeader}>
              <ScreenHeader type="All Plan Categories" />
            </div>
          )}
          <Dialog
            open={this.state.modalOpen}
            onClose={this.handleClose}
            aria-labelledby="plan-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <Formik
              initialValues={getInitialValues(id)}
              enableReinitialize
              validationSchema={PlanSchema}
              onSubmit={(values, { setErrors, setSubmitting }) => {
                // eslint-disable-next-line no-param-reassign
                values.created_by = "5cde64595cafe71468f0c483";
                // eslint-disable-next-line no-param-reassign
                values.space = localStorage.getItem("space_id");
                if (id) {
                  this.props.editPlanCategory(
                    id,
                    values,
                    setErrors,
                    setSubmitting
                  );
                } else {
                  this.props.addplancategory(
                    values,
                    setErrors,
                    this.props.history,
                    setSubmitting
                  );
                  this.props.getPlancategory(space_id);
                  this.props.history.push("/manage-plans");
                  this.handleClose();
                }
              }}
            >
              {() => (
                <Form className={classes.plan_form_modal}>
                  <DialogTitle
                    id="plan-dialog-title"
                    className={classes.modelHeader}
                  >
                    {id ? "Edit Plan Category" : "Add Plan Category"}
                  </DialogTitle>
                  <DialogContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <InputField
                          name="category_name"
                          label="Category"
                          placeholder="Dedicated Desk"
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <InputField
                          name="credit_per_seat"
                          label="Credit Per Seat"
                          placeholder="Enter Total Seats"
                          type="number"
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <RadioField
                          name="shared"
                          label="Shared"
                          options={SharedOptions}
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions className={classes.modalFooter}>
                    <Button
                      onClick={this.handleClose}
                      color="primary"
                      className={classes.cancelbutton}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      className={classes.addbutton}
                      type="submit"
                      variant="contained"
                    >
                      {id ? "Edit" : "Add"}
                    </Button>
                  </DialogActions>
                  {loading && <CircularLoader />}
                </Form>
              )}
            </Formik>
          </Dialog>
          {showData && (
            <div className={classes.add_plan_button}>
              {checkPermission("PLAN_CATEGORY_CREATE") && (
                <Button
                  onClick={this.handleClickOpen}
                  variant="contained"
                  color="primary"
                  className={classes.planCategoryButton}
                >
                  Add Plan Category
                </Button>
              )}
            </div>
          )}
        </div>
        {!showData && (
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.headerSpacing}
          >
            <Grid item justify="center">
              <Grid item sm={12} lg={12}>
                <img src={nodata} alt="" width="76%" />
              </Grid>
              <div style={{ marginLeft: "80px", marginTop: "20px" }}>
                {checkPermission("PLAN_CATEGORY_CREATE") && (
                  <Button
                    onClick={this.handleClickOpen}
                    variant="contained"
                    color="primary"
                    className={classes.planCategoryButton}
                  >
                    Add Plan Category
                  </Button>
                )}
              </div>
            </Grid>
          </Grid>
        )}
        <div className={classes.root}>
          {showData && (
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Sr. No.</StyledTableCell>
                  <StyledTableCell align="left">Category</StyledTableCell>
                  <StyledTableCell align="left">
                    Credit Per Seat
                  </StyledTableCell>
                  <StyledTableCell align="left">Shared</StyledTableCell>
                  <StyledTableCell align="left" />
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.plan_categories &&
                  // eslint-disable-next-line no-shadow
                  this.state.plan_categories.map((item, id) => {
                    return (
                      <TableRow className={classes.root1}>
                        <StyledTableCell component="th" scope="row">
                          {id + 1}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {item.category_name}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {item.credit_per_seat}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {item.shared}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            className={classes.editbutton}
                            onClick={() => this.handleClickModalOpen(item._id)}
                          >
                            <CreateIcon
                              size="small"
                              className={classes.editicon}
                            />{" "}
                            Edit
                          </Button>
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    plan_categories: state.plan_categories.plan_categories,
    loading: state.plan_categories.loading,
    get_single_plan_category: state.plan_categories.get_single_plan_category,
    edit_plan_category: state.plan_categories.edit_plan_category,
    plan_category: state.plan_categories.plan_category,
  };
};

export default connect(mapStateToProps, {
  addplancategory,
  getPlancategory,
  getSinglePlancategory,
  editPlanCategory,
})(withStyles(styles)(Adminplan));
