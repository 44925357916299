import React from "react";
import { Typography, Card, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { updateOrderStatus } from "../../../Actions/OrderActions";
import { getallFoodItem } from "../../../Actions/foodItemActions";

const styles = (theme) => {
  return {
    card: {
      display: "flex",
      flexDirection: "column",
      height: "310px",
      // boxShadow: "0 9px 10px rgba(0, 0, 0, 0.1), 0 4px 0px rgba(0,0,0,0.05)",
      boxShadow: (props) =>
        props.type === "addOrder"
          ? "none"
          : "0 9px 10px rgba(0, 0, 0, 0.1), 0 4px 0px rgba(0,0,0,0.05)",
      transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
      backgroundColor: "transparent",
      position: "relative",
      "&:hover": {
        transform: "scale(1.01)",
        boxShadow: (props) =>
          props.type === "addOrder"
            ? "none"
            : "rgba(34, 36, 38, 0.08) 0px 2px 4px 6px, rgba(34, 36, 38, 0.15) 0px 2px 10px 0px",
      },
    },
    containerPaper: {
      backgroundColor: (props) =>
        props.type === "addOrder" ? "transparent" : "none",
      // border: `1px solid ${theme.palette.border.color}`,
      border: (props) =>
        props.type === "addOrder"
          ? "none"
          : `1px solid ${theme.palette.border.color}`,
    },
    productImage: {
      width: "100%",
      height: "210px",
      borderTopLeftRadius: "5px",
      borderTopRightRadius: "5px",
      position: "absolute",
    },
    cardimage: {
      height: "212px",
      width: "100%",
    },
    productDetail: {
      padding: "4px 15px 16px",
    },
    productName: {
      fontWeight: 600,
      fontSize: " 14px",
      lineHeight: "24px",
      color: " #333",
      marginRight: "15px",
      textTransform: "capitalize",
    },
    productDescription: {
      fontWeight: "normal",
      fontSize: " 12px",
      lineHeight: "18px",
      color: " #626262",
      textTransform: "capitalize",
    },
    productMainData: {
      display: "flex",
      justifyContent: "space-between",
    },
    icon: {
      marginRight: "10px",
    },
    productPrice: {
      color: "#000",
      fontSize: "14px",
      lineHeight: "24px",
      fontWeight: 600,
      paddingTop: "2%",
    },
    cardMiddle: {
      display: "flex",
      justifyContent: "space-between",
    },
    settingIcon: {
      display: "flex",
      justifyContent: "flex-end",
    },
    cardFooter: {
      textAlign: "center",
    },
    joinbutton: {
      margin: theme.spacing(1),
      fontSize: 10,
      border: "1px solid #EFEFEF",
      borderRadius: "5px",
      backgroundColor: "#fff",
      color: "#222222",
      "&:hover": {
        backgroundColor: "#fff",
      },
    },
  };
};

class MerchandiseProductCard extends React.Component {
  constructor(props) {
    super(props);
    const statusView = false;
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      statusView,
    };
  }

  orderStatus = () => {
    const id = this.props.data._id;
    let values = "";
    if (this.props.foodstatus === "available") {
      values = "unavailable";
    } else {
      values = "available";
    }
    const apiData = { status: values };
    this.props.updateOrderStatus(id, apiData);
    this.props.getallFoodItem();
  };

  addItem = (data) => {
    this.props.addOrder(data);
  };

  render() {
    const { classes, data, type } = this.props;
    // eslint-disable-next-line no-unused-vars
    let statusView = false;

    if (this.props.match.path === "/food-items") {
      statusView = true;
    }
    return (
      <Card className={classes.card}>
        <div className={classes.containerPaper}>
          <div className={classes.cardimage}>
            <img className={classes.productImage} src={data.image} alt="Food" />
          </div>
          <div className={classes.productDetail}>
            <div className={classes.productMainData} direction="row">
              <Typography
                component="h2"
                align="left"
                className={classes.productName}
              >
                {data.product_name}
                {/* <NameTruncate text={data.food_name} amount={22} /> */}
              </Typography>
              <Typography
                component="p"
                variant="subtitle2"
                className={classes.productPrice}
              >
                ₹ {data.price}
              </Typography>
            </div>
            <div className={classes.cardMiddle}>
              <Typography
                component="p"
                variant="subtitle2"
                className={classes.productDescription}
              >
                {data.description}
              </Typography>
            </div>
          </div>
          {type === "addOrder" ? (
            <div className={classes.cardFooter}>
              <Button
                size="small"
                variant="contained"
                className={classes.joinbutton}
                onClick={() => this.addItem(data)}
              >
                Add to Cart
              </Button>
            </div>
          ) : (
            ""
          )}
        </div>
      </Card>
    );
  }
}
MerchandiseProductCard.propTypes = {};
const mapStateToProps = () => {
  return {};
};
export default connect(mapStateToProps, { updateOrderStatus, getallFoodItem })(
  withStyles(styles)(withRouter(MerchandiseProductCard))
);
