/* eslint-disable camelcase */
import React from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { Typography, Grid } from "@material-ui/core";
import classNames from "classnames";

const styles = () => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "12px",
  },
  mainTitle: {
    fontWeight: "600",
    fontSize: "20px",
    textAlign: "left",
    color: "#222222",
    lineHeight: "25px",
  },
  heading: {
    fontSize: "16px",
    color: "#222222",
    fontWeight: "500",
    margin: "4px 0px",
    marginBottom: "15px",
  },
  title: {
    fontSize: "13px",
    color: "#222222",
    fontWeight: "500",
    padding: "10px",
  },
  subTitle: {
    fontSize: "15px",
    color: "#222222",
    fontWeight: "500",
  },
  phone: {
    color: "#1592E6",
    fontWeight: "500",
  },
  description: {
    color: "#222222",
    fontSize: "13px",
    lineHeight: "18px",
  },
  generalDetails: {
    padding: "3% 0% 0%",
  },
  cardLayout: {
    border: "1px solid #EFEFEF",
    borderRadius: "5px",
    padding: "10px",
  },
  padding: {
    padding: "10px",
  },
});

function InteriorDesign(props) {
  const { classes } = props;

  return (
    <div className={classes.main}>
      <div className={classes.header}>
        <Typography align="left" variant="h1" className={classes.mainTitle}>
          Interior Designing
        </Typography>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Typography align="left" className={classes.description}>
            We create an innovative design layout that inspires you day-in and
            day-out. Through our skilfulness, we ensure that each expectation
            spelt out to us are collectively visible in what we design for our
            clients. Our team of expert designers and project handlers will
            ensure that we turn your dream into reality throughout whatever it
            takes attitude in fulfilling the clients requirement. Our wide array
            of services that focus on service standards, exceptional support and
            innovative design stands us aloof from our competitors.
          </Typography>
          <Grid container spacing={2} className={classes.generalDetails}>
            <Grid item xs={12}>
              <Typography align="left" className={classes.subTitle}>
                Our services include;
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  Institutional Design (Schools & <br />
                  Universities)
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  Residential Projects (Apartments & <br /> Bungalows)
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  Corporate Offices (Traditional & <br />
                  Co-working)
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  Hospitality Projects (Restaurants & <br />
                  Cafe)
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2} className={classes.generalDetails}>
            <Typography
              align="left"
              className={classNames(classes.mainTitle, classes.padding)}
            >
              How do we do?
            </Typography>
            <Grid item xs={12}>
              <Typography align="left" className={classes.description}>
                We meet the client to understand their requirement and agree
                upon the scope of work and estimated client budget.
                <br />
                <br />
                Based on the scope, the team prepares options of various ideas
                that can be incorporated through a presentation.
                <br />
                <br />
                On approval, the team moves ahead to final designing and
                budgeting with the client as actuals along with providing 3D s.
                <br />
                <br />
                The last and final stage is about installation and making the
                site ready as decided.
                <br />
                <br />
                We dont just end here, we also provide post-installation
                services like snag list, complete cleaning, checklist etc
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2} className={classes.generalDetails}>
            <Typography
              align="left"
              className={classNames(classes.mainTitle, classes.padding)}
            >
              Why us?
            </Typography>
            <Grid item xs={12}>
              <Typography align="left" className={classes.description}>
                The more we put our heart into designing, it turns out to be
                extravagantly beautiful.
                <br />
                <br />
                Use of quality products in building your dream project.
                <br />
                <br />
                Our team of experts have mastered the art of interior designing
                and turnkey delivery in their respective specialized domain.
                <br />
                <br />
                Our post-delivery support separates us from our competitors.
                <br />
                <br />
                Personalized touch and relationship-building to understand
                client need.
                <br />
                <br />
                Cost-effective vibrant ideas delivered to your doorstep.
                <br />
                <br />
                Our offices are the benchmark we have set for ourselves to
                surpass with each project.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.spaces.loading,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(InteriorDesign));
