/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable react/no-unused-state */
import React from "react";
import { Grid, withStyles, Button } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { Form } from "react-formik-ui";
import FormLabel from "@material-ui/core/FormLabel";
import { CircularLoader } from "../../Components/Common/Spinner";
import {
  addKnowedgeCenterInformation,
  updateKnowedgeCenterInformation,
  getSingleKnowledgeCenterInformation,
} from "../../Actions/knowledgeCenterActions";
import InputField from "../../Components/Common/Form/InputField";
// import UploadField from "../../Components/Common/Form/UploadField";
import { checkPermission } from "../../Utils/checkPermission";
import TextareaField from "../../Components/Common/Form/TextareaField";

const $quoteSym = " ";

const styles = (theme) => ({
  lightbg: {
    backgroundColor: "#fff",
    padding: "10px 22px",
  },
  label: {
    margin: "15px 0px 8px 0px",
    fontSize: "12px",
    fontWeight: "700",
  },
  Addbutton: {
    width: "148px",
    height: "46px",
    marginLeft: "-5%",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  margin: {
    margin: theme.spacing(1),
    marginLeft: "0px",
    width: "100%",
  },
  social: {
    height: "20px",
    width: "20px",
  },
  datePickerInput: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    padding: "4px",
    "& > div": {
      marginBottom: "8px",
    },
  },
  formfooter: {
    margin: "30px 0px",
    marginRight: "-3%",
  },
  cancelbutton: {
    width: "148px",
    height: "46px",
    color: "#151039",
    backgroundColor: "#dddddd",
    marginRight: "20px",
    "&:hover": {
      color: "#151039",
      backgroundColor: "#d1d1d1!important",
    },
  },
  form: {
    marginLeft: "4px",
  },
  applicationHeader: {
    margin: "32px 8px 18px",
    paddingBottom: "5px",
    fontSize: "16px",
    fontWeight: "500",
    position: "relative",
    width: "100%",
    "&:after": {
      top: "31px",
      left: "1px",
      width: "24px",
      borderBottom: "3px solid #fb0",
      content: `'${$quoteSym}'`,
      position: "absolute",
    },
  },
  descriptionLabel: {
    margin: "1px 0px 8px 0px",
    fontSize: "14px",
    color: "#222",
    fontWeight: 500,
    LineHeight: "20px",
  },
  input_field: {
    border: "1px solid #949494",
    height: "81%",
    position: "relative",
    width: "100%",
    padding: "6px 8px",
    margin: "0px",
    color: "gray",
    fontFamily:
      "'-apple-system,BlinkMacSystemFont','Segoe UI','Roboto','Helvetica Neue','Arial,sans-serif','Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol'",
    // color: "currentColor",
    fontSize: "14px",
    background: "white",
  },
  backButton: {
    margin: "0% 0% 2% 0%",
  },
  ButtonMain: {
    display: "flex",
    justifyContent: "space-between",
  },
  formButton: {
    display: "flex",
    justifyContent: "space-between",
  },
});

const subSchema = {
  name_number: Yup.string(),
  address: Yup.string(),
};
const KnowledgeCenterSchema = Yup.object().shape({
  general_parking: Yup.string().required("This field is required."),
  car_parking: Yup.string().required("This field is required."),
  bike_parking: Yup.string().required("This field is required."),
  fire_station_name: Yup.string().required("This field is required."),
  fire_station_address: Yup.string().required("This field is required."),
  police_station_name: Yup.string().required("This field is required."),
  police_station_address: Yup.string().required("This field is required."),
  post_office_name_number: Yup.string().required("This field is required."),
  post_office_address: Yup.string().required("This field is required."),
  hospital1: Yup.string().required("This field is required."),
  hospital_address1: Yup.string().required("This field is required."),
  hospital2: Yup.string().required("This field is required."),
  hospital_address2: Yup.string().required("This field is required."),
  hospital3: Yup.string().required("This field is required."),
  hospital_address3: Yup.string().required("This field is required."),
  hospital4: Yup.string().required("This field is required."),
  hospital_address4: Yup.string().required("This field is required."),
  hospital_one: Yup.object().shape(subSchema),
  hospital_two: Yup.object().shape(subSchema),
  hospital_three: Yup.object().shape(subSchema),
  hospital_four: Yup.object().shape(subSchema),
  fire_station: Yup.object().shape(subSchema),
  police_station: Yup.object().shape(subSchema),
  post_office: Yup.object().shape(subSchema),
});

class KnowledgeCenterForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id:
        this.props.match && this.props.match.params
          ? this.props.match.params.id
          : "",
      companies: [],
      deletedKey: [],
      status: "",
      edit: false,
    };
  }

  componentDidMount() {
    this.props.getSingleKnowledgeCenterInformation();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.single_knowledge_center_info !==
      prevState.single_knowledge_center_info
    ) {
      return {
        single_knowledge_center_info: nextProps.single_knowledge_center_info,
      };
    }
    return [];
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.addKnowledgeInfo !== prevProps.addKnowledgeInfo &&
      this.props.addKnowledgeInfo.success
    ) {
      this.setState({
        edit: false,
      });
    }
    if (
      this.props.updateKnowledgeInfo !== prevProps.updateKnowledgeInfo &&
      this.props.updateKnowledgeInfo.success
    ) {
      this.setState({
        edit: false,
      });
    }
  }

  editInformation = (flag) => {
    this.setState({
      edit: flag,
    });
    this.props.getSingleKnowledgeCenterInformation();
  };

  getInitialValues = (edit) => {
    const { single_knowledge_center_info } = this.props;

    if ((edit === false && single_knowledge_center_info) || edit === true) {
      return {
        general_parking:
          single_knowledge_center_info &&
          single_knowledge_center_info.parking &&
          single_knowledge_center_info.parking.general_parking,
        car_parking:
          single_knowledge_center_info &&
          single_knowledge_center_info.parking &&
          single_knowledge_center_info.parking.car_parking,
        bike_parking:
          single_knowledge_center_info &&
          single_knowledge_center_info.parking &&
          single_knowledge_center_info.parking.bike_parking,
        fire_exit_plan:
          single_knowledge_center_info &&
          single_knowledge_center_info.fire_exit_plan,
        fire_station_name:
          single_knowledge_center_info &&
          single_knowledge_center_info.emergency &&
          single_knowledge_center_info.emergency.fire_station &&
          single_knowledge_center_info.emergency.fire_station.name_number,
        fire_station_address:
          single_knowledge_center_info &&
          single_knowledge_center_info.emergency &&
          single_knowledge_center_info.emergency.fire_station &&
          single_knowledge_center_info.emergency.fire_station.address,
        police_station_name:
          single_knowledge_center_info &&
          single_knowledge_center_info.emergency &&
          single_knowledge_center_info.emergency.police_station &&
          single_knowledge_center_info.emergency.police_station.name_number,
        police_station_address:
          single_knowledge_center_info &&
          single_knowledge_center_info.emergency &&
          single_knowledge_center_info.emergency.police_station &&
          single_knowledge_center_info.emergency.police_station.address,
        post_office_name_number:
          single_knowledge_center_info &&
          single_knowledge_center_info.emergency &&
          single_knowledge_center_info.emergency.post_office &&
          single_knowledge_center_info.emergency.post_office.name_number,
        post_office_address:
          single_knowledge_center_info &&
          single_knowledge_center_info.emergency &&
          single_knowledge_center_info.emergency.post_office &&
          single_knowledge_center_info.emergency.post_office.address,
        hospital1:
          single_knowledge_center_info &&
          single_knowledge_center_info.emergency &&
          single_knowledge_center_info.emergency.hospital_one &&
          single_knowledge_center_info.emergency.hospital_one.name_number,
        hospital_address1:
          single_knowledge_center_info &&
          single_knowledge_center_info.emergency &&
          single_knowledge_center_info.emergency.hospital_one &&
          single_knowledge_center_info.emergency.hospital_one.address,
        hospital2:
          single_knowledge_center_info &&
          single_knowledge_center_info.emergency &&
          single_knowledge_center_info.emergency.hospital_two &&
          single_knowledge_center_info.emergency.hospital_two.name_number,
        hospital_address2:
          single_knowledge_center_info &&
          single_knowledge_center_info.emergency &&
          single_knowledge_center_info.emergency.hospital_two &&
          single_knowledge_center_info.emergency.hospital_two.address,
        hospital3:
          single_knowledge_center_info &&
          single_knowledge_center_info.emergency &&
          single_knowledge_center_info.emergency.hospital_three &&
          single_knowledge_center_info.emergency.hospital_three.name_number,
        hospital_address3:
          single_knowledge_center_info &&
          single_knowledge_center_info.emergency &&
          single_knowledge_center_info.emergency.hospital_three &&
          single_knowledge_center_info.emergency.hospital_three.address,
        hospital4:
          single_knowledge_center_info &&
          single_knowledge_center_info.emergency &&
          single_knowledge_center_info.emergency.hospital_four &&
          single_knowledge_center_info.emergency.hospital_four.name_number,
        hospital_address4:
          single_knowledge_center_info &&
          single_knowledge_center_info.emergency &&
          single_knowledge_center_info.emergency.hospital_four &&
          single_knowledge_center_info.emergency.hospital_four.address,
      };
    }
    return {
      general_parking: "",
      car_parking: "",
      bike_parking: "",
      fire_exit_plan: "",
      hospital_one: {},
      hospital_two: {},
      hospital_three: {},
      hospital_four: {},
      fire_station: {},
      post_office: {},
      police_station: {},
      fire_station_name: "",
      fire_station_address: "",
      police_station_name: "",
      police_station_address: "",
      post_office_name_number: "",
      post_office_address: "",
      hospital1: "",
      hospital_address1: "",
      hospital2: "",
      hospital_address2: "",
      hospital3: "",
      hospital_address3: "",
      hospital4: "",
      hospital_address4: "",
    };
  };

  setDelImageUrl = (url) => {
    const { deletedKey } = this.state;
    this.setState({ deletedKey: deletedKey.push(url) });
  };

  render() {
    const { classes, single_knowledge_center_info } = this.props;
    const { loading } = this.state;

    return (
      <React.Fragment>
        <div className={classes.lightbg}>
          <div className={classes.ButtonMain}>
            <Button
              color="grey"
              onClick={() => this.props.history.push("/settings")}
              className={classes.backButton}
              variant="contained"
              size="small"
            >
              Back
            </Button>
            {single_knowledge_center_info ? (
              <div className={classes.formButton}>
                {checkPermission("KNOWLEDGE_CENTER_UPDATE") && (
                  <Button
                    onClick={() => this.editInformation(true)}
                    className={classes.Addbutton}
                    variant="contained"
                    color="primary"
                    size="small"
                  >
                    Edit
                  </Button>
                )}
              </div>
            ) : (
              <div className={classes.formButton}>
                {checkPermission("KNOWLEDGE_CENTER_CREATE") && (
                  <Button
                    onClick={() => this.editInformation(true)}
                    className={classes.Addbutton}
                    variant="contained"
                    color="primary"
                    size="small"
                  >
                    Add
                  </Button>
                )}
              </div>
            )}
          </div>
          <Formik
            initialValues={this.getInitialValues(this.state.edit)}
            enableReinitialize
            validationSchema={KnowledgeCenterSchema}
            onSubmit={(values, { setErrors, setSubmitting }) => {
              const hospital1 = {
                name_number: values.hospital1,
                address: values.hospital_address1,
              };
              values.hospital_one = hospital1;
              const hospital2 = {
                name_number: values.hospital2,
                address: values.hospital_address2,
              };
              values.hospital_two = hospital2;
              const hospital3 = {
                name_number: values.hospital3,
                address: values.hospital_address3,
              };
              values.hospital_three = hospital3;
              const hospital4 = {
                name_number: values.hospital4,
                address: values.hospital_address4,
              };
              values.hospital_four = hospital4;

              const fireStation = {
                name_number: values.fire_station_name,
                address: values.fire_station_address,
              };
              values.fire_station = fireStation;
              const policeStation = {
                name_number: values.police_station_name,
                address: values.police_station_address,
              };
              values.police_station = policeStation;
              const postOffice = {
                name_number: values.post_office_name_number,
                address: values.post_office_address,
              };
              values.post_office = postOffice;
              delete values.hospital1;
              delete values.hospital_address1;
              delete values.hospital2;
              delete values.hospital_address2;
              delete values.hospital3;
              delete values.hospital_address3;
              delete values.hospital4;
              delete values.hospital_address4;
              delete values.fire_station_name;
              delete values.fire_station_address;
              delete values.police_station_name;
              delete values.police_station_address;
              delete values.post_office_name_number;
              delete values.post_office_address;
              if (
                single_knowledge_center_info === undefined &&
                this.state.edit === true
              ) {
                this.props.addKnowedgeCenterInformation(
                  values,
                  setErrors,
                  setSubmitting,
                  this.props.history
                );
              } else {
                this.props.updateKnowedgeCenterInformation(
                  values,
                  setSubmitting,
                  this.props.history
                );
              }
            }}
          >
            {({ handleSubmit }) => (
              <Form
                className={classes.form}
                onSubmit={handleSubmit}
                encType="multipart/form-data"
                autoComplete="off"
              >
                <Grid container spacing={2}>
                  <span className={classes.applicationHeader}>
                    Parking Policy
                  </span>
                  <Grid item xs={12} sm={12}>
                    <FormLabel
                      className={classes.descriptionLabel}
                      component="legend"
                    >
                      General Parking
                    </FormLabel>
                    <TextareaField
                      name="general_parking"
                      className={classes.input_field}
                      rows="5"
                      placeholder="Enter Policy Details"
                      disabled={this.state.edit === false}
                    ></TextareaField>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormLabel
                      className={classes.descriptionLabel}
                      component="legend"
                    >
                      Car Parking
                    </FormLabel>
                    <TextareaField
                      name="car_parking"
                      className={classes.input_field}
                      rows="5"
                      placeholder="Enter Policy Details"
                      disabled={this.state.edit === false}
                    ></TextareaField>
                  </Grid>{" "}
                  <Grid item xs={12} sm={12}>
                    <FormLabel
                      className={classes.descriptionLabel}
                      component="legend"
                    >
                      Bike Parking
                    </FormLabel>
                    <TextareaField
                      name="bike_parking"
                      className={classes.input_field}
                      rows="5"
                      placeholder="Enter Policy Details"
                      disabled={this.state.edit === false}
                    ></TextareaField>
                  </Grid>
                  <span className={classes.applicationHeader}>
                    Emergency Numbers
                  </span>
                  <Grid item xs={12} sm={12}>
                    <FormLabel
                      className={classes.descriptionLabel}
                      component="legend"
                    >
                      Hospital 1
                    </FormLabel>
                    <InputField
                      name="hospital1"
                      placeholder="Enter Name & Number"
                      disabled={this.state.edit === false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <InputField
                      name="hospital_address1"
                      placeholder="Enter Address"
                      disabled={this.state.edit === false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormLabel
                      className={classes.descriptionLabel}
                      component="legend"
                    >
                      Hospital 2
                    </FormLabel>
                    <InputField
                      name="hospital2"
                      placeholder="Enter Name & Number"
                      disabled={this.state.edit === false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <InputField
                      name="hospital_address2"
                      placeholder="Enter Address"
                      disabled={this.state.edit === false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormLabel
                      className={classes.descriptionLabel}
                      component="legend"
                    >
                      Hospital 3
                    </FormLabel>
                    <InputField
                      name="hospital3"
                      placeholder="Enter Name & Number"
                      disabled={this.state.edit === false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <InputField
                      name="hospital_address3"
                      placeholder="Enter Address"
                      disabled={this.state.edit === false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormLabel
                      className={classes.descriptionLabel}
                      component="legend"
                    >
                      Hospital 4
                    </FormLabel>
                    <InputField
                      name="hospital4"
                      placeholder="Enter Name & Number"
                      disabled={this.state.edit === false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <InputField
                      name="hospital_address4"
                      placeholder="Enter Address"
                      disabled={this.state.edit === false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormLabel
                      className={classes.descriptionLabel}
                      component="legend"
                    >
                      Fire Station
                    </FormLabel>
                    <InputField
                      name="fire_station_name"
                      placeholder="Enter Name & Number"
                      disabled={this.state.edit === false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <InputField
                      name="fire_station_address"
                      placeholder="Enter Address"
                      disabled={this.state.edit === false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormLabel
                      className={classes.descriptionLabel}
                      component="legend"
                    >
                      Post Office
                    </FormLabel>
                    <InputField
                      name="post_office_name_number"
                      placeholder="Enter Name & Number"
                      disabled={this.state.edit === false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <InputField
                      name="post_office_address"
                      placeholder="Enter Address"
                      disabled={this.state.edit === false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormLabel
                      className={classes.descriptionLabel}
                      component="legend"
                    >
                      Police Station
                    </FormLabel>
                    <InputField
                      name="police_station_name"
                      placeholder="Enter Name & Number"
                      disabled={this.state.edit === false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <InputField
                      name="police_station_address"
                      placeholder="Enter Address"
                      disabled={this.state.edit === false}
                    />
                  </Grid>
                  {/* <Grid
                    item
                    xs={12}
                    sm={12}
                    container
                    display="flex"
                    justify="center"
                    alignItems="center"
                  >
                    <span className={classes.applicationHeader}>
                      Fire Exit Plan
                    </span>
                    <Grid item xs={6} sm={6}>
                      <UploadField
                        label="Upload PDF document"
                        name="fire_exit_plan"
                        id="fire_exit_plan"
                        fileType="doc"
                        onChange={setFieldValue}
                        value={values.fire_exit_plan}
                        folder="knowledgeCenter/doc"
                        delImageUrl={this.setDelImageUrl}
                        disabled={this.state.edit === false}
                      />
                    </Grid>
                  </Grid> */}
                </Grid>
                <div className={classes.formfooter}>
                  <Grid
                    container
                    item
                    display="flex"
                    justify="flex-end"
                    alignItems="center"
                    direction="row"
                  >
                    <Grid xs={6} sm={2}>
                      <Button
                        className={classes.Addbutton}
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </div>
                {loading && <CircularLoader />}
              </Form>
            )}
          </Formik>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    single_knowledge_center_info:
      state.knowledgeCenterData.single_knowledge_center_info,
    addKnowledgeInfo: state.knowledgeCenterData.addKnowledgeInfo,
    updateKnowledgeInfo: state.knowledgeCenterData.updateKnowledgeInfo,
  };
};

export default connect(mapStateToProps, {
  addKnowedgeCenterInformation,
  updateKnowedgeCenterInformation,
  getSingleKnowledgeCenterInformation,
})(withStyles(styles)(withRouter(KnowledgeCenterForm)));
