/* eslint-disable no-shadow */
/* eslint-disable camelcase */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { getSlot, getArea, createTask } from "../../Actions/spaceActions";
import ScreenHeader from "../../Components/Common/ScreenHeader";
import NameTruncate from "../../Components/Common/NameTruncate";

const styles = (theme) => ({
  add_area_button: {
    width: "100%",
    textAlign: "right",
  },
  area_form_modal: {
    width: "500px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "18px",
  },
  planCategoryButton: {
    color: "#fff",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  ScreenHeader: {
    marginLeft: "-20px",
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  addbutton: {
    color: "#000",
    backgroundColor: "lightgrey !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  mainSlot: {
    display: "flex",
    justifyContent: "space-between",
  },
  areaSection: {
    display: "flex",
    flexDirection: "row",
  },
  areaImage: {
    width: "70px",
    height: "70px",
  },
  areaName: {
    fontSize: "14px",
    color: "#626262",
    textTransform: "capitalize",
    padding: "4px",
  },
  areaCheck: {
    position: "relative",
    top: "-57px",
    right: "11px",
    backgroundColor: "#fff",
  },
});

const theme = createMuiTheme({
  overrides: {
    MuiExpansionPanelDetails: {
      // Name of the component ⚛️ / style sheet
      root: {
        padding: "8px",
      },
    },
    MuiCheckbox: {
      root: {
        padding: "0px",
      },
    },
  },
});

function SupervisorTab(props) {
  const { classes } = props;
  const [expanded, setExpanded] = React.useState(false);
  const [allSlot, setallSlot] = useState([]);
  const [allArea, setallArea] = useState([]);
  const [selectedItems, setselectedItems] = useState([]);
  const [slotID, setslotID] = useState([]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    props.getSlot();
  }, []);

  useEffect(() => {
    setallSlot(props.all_slot);
  }, [props.all_slot]);

  useEffect(() => {
    props.getArea();
  }, []);

  useEffect(() => {
    setallArea(props.all_area);
  }, [props.all_area]);

  const handleChangeImage = (i, id) => (event) => {
    if (event.target.checked) {
      selectedItems.push(event.target.value);
    } else {
      for (let i = 0; i < selectedItems.length; i + 1) {
        if (selectedItems[i] === event.target.value) {
          selectedItems.splice(i, 1);
        }
      }
    }
    setselectedItems(selectedItems);
    setslotID(id);
  };
  const createTaskSubmit = () => {
    const values = {};
    values.area_id = selectedItems;
    values.slot_id = slotID;
    props.createTask(values);
  };

  // useEffect(() => {
  //   // Update the document title using the browser API
  //   document.title = `You clicked ${count} times`;
  // });

  // if (
  //   this.props.create_task !== prevProps.create_task &&
  //   this.props.create_task.success
  // ) {
  //   this.props.getArea();
  // }
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.main}>
        <div className={classes.header}>
          <div className={classes.ScreenHeader}>
            <ScreenHeader type="Update Area" />
          </div>
        </div>

        <Grid container spacing={2}>
          <Grid item xs={11} sm={11}>
            {allSlot &&
              allSlot.map((element, i) => {
                return (
                  <ExpansionPanel
                    expanded={expanded === i}
                    onChange={handleChange(i)}
                  >
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={i - "content"}
                      id={i - "header"}
                      className={classes.mainSlot}
                    >
                      <Typography className={classes.heading}>
                        {moment(element.start_time).format("LT")} to&nbsp;
                        {moment(element.end_time).format("LT")}
                      </Typography>

                      <Button
                        className={classes.addbutton}
                        type="submit"
                        variant="contained"
                        onClick={createTaskSubmit}
                        disabled={selectedItems.length === 0}
                      >
                        Update
                      </Button>
                    </ExpansionPanelSummary>
                    <div className={classes.areaSection}>
                      <Grid container>
                        {allArea &&
                          allArea.map((item, i) => {
                            return (
                              <Grid item xs={2} sm={2}>
                                <ExpansionPanelDetails>
                                  <div className={classes.areaContent}>
                                    <img
                                      className={classes.areaImage}
                                      src={item.photo}
                                      alt=""
                                    />
                                    <FormControlLabel
                                      className={classes.areaCheck}
                                      control={
                                        <Checkbox
                                          checked={selectedItems[i]}
                                          onChange={handleChangeImage(
                                            i,
                                            element._id
                                          )}
                                          value={item._id}
                                          color="primary"
                                        />
                                      }
                                      label={
                                        item.food_item &&
                                        item.food_item.food_name
                                      }
                                    />
                                    <br />
                                    <span className={classes.areaName}>
                                      <NameTruncate
                                        text={item.name}
                                        amount={9}
                                      />
                                    </span>
                                  </div>
                                </ExpansionPanelDetails>
                              </Grid>
                            );
                          })}
                      </Grid>
                    </div>
                  </ExpansionPanel>
                );
              })}
          </Grid>
        </Grid>
      </div>
    </ThemeProvider>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.spaces.loading,
    all_slot: state.spaces.all_slot,
    all_area: state.spaces.all_area,
    create_task: state.spaces.create_task,
  };
};

export default connect(mapStateToProps, { getSlot, getArea, createTask })(
  withStyles(styles)(SupervisorTab)
);
