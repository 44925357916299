/* eslint-disable camelcase */
import React, { Component } from 'react'
import classNames from 'classnames'
import {
  Grid,
  Paper,
  Toolbar,
  AppBar,
  CssBaseline,
  Button,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import FeaturedVideoIcon from '@material-ui/icons/FeaturedVideo'
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList'
import ScreenHeader from '../../Components/Common/ScreenHeader'
import CafeteriaCard from '../../Components/Common/Cards/CafeteriaCard'
import CafeteriaItemCard from '../../Components/Common/Cards/CafeteriaItemCard'
import SearchBar from '../../Components/Common/SearchBar'
import { getallFoodItem } from '../../Actions/foodItemActions'
import DataNotFound from '../../Components/Common/DataNotFound'
import { checkPermission } from '../../Utils'
import NoRecordsFound from '../../Components/Common/NoRecordsFound'
import { CircularLoader } from '../../Components/Common/Spinner'
import FoodItem from './AddFoodItem'

const styles = (theme) => {
  return {
    paper: {
      padding: theme.spacing(2),
      flexGrow: 1,
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        padding: theme.spacing(3),
      },
    },
    layout: {
      width: 'auto',
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      backgroundColor: 'white',
      [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    appbar: {
      backgroundColor: '#ffffff',
    },
    searchSection: {
      margin: '16px 0px 0px',
    },
    availableSection: {
      padding: '9px 0px',
    },
    filterButton: {
      borderRadius: '50px',
      marginLeft: '10px',
      color: '#211b4e',
      border: '1px solid #211b4e',
      '&:hover': {
        backgroundColor: '#211b4e',
        color: '#fff',
      },
    },
    cafeteriaCardContainer: {
      margin: '4px 0',
    },
    cafeteriaItemCardContainer: {
      margin: '4px 15px',
    },
    addfooditem: {
      color: '#fff',
      padding: '7px 16px',
      borderRadius: '4px',
      textDecoration: 'none',
      fontSize: '0.875rem',
      fontWeight: '500',
      marginLeft: '18px',

      backgroundColor: '#211b4e !important',
      '&:hover': {
        backgroundColor: '#151039 !important',
      },
    },
    addFoodItem1: {
      width: '100%',
      textAlign: 'right',
      marginRight: '22px',
    },
    disableAddOrder: {
      color: '#fff',
      padding: '7px 16px',
      backgroundColor: '#ABABAB',
      textDecoration: 'none',
      borderRadius: '4px',
      marginTop: '12px',
      marginBottom: '9px',
      '&:hover': {
        backgroundColor: '#b7b5b5',
      },
    },
    addorderButton: {
      width: '100%',
      textAlign: 'right',
      marginRight: '21px',
      marginTop: '8px',
      marginBottom: '6px',
    },
    foodbutton: {
      color: '#fff',
      padding: '7px 16px',
      lineHeight: 1.43,
      borderRadius: '4px',
      textDecoration: 'none',
      fontSize: '0.875rem',
      fontWeight: '500',
      marginLeft: '18px',
      backgroundColor: '#211b4e !important',
      '&:hover': {
        backgroundColor: '#151039 !important',
      },
    },
    selectedTab: {
      backgroundColor: '#211b4e',
      color: '#fff',
    },
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0px',
      // ...theme.mixins.toolbar,
    },
    activeView: {
      fill: '#211b4e',
      fontSize: '40px',
      padding: '0px 0px 8px 8px',
      marginTop: '10px',
    },
    inactiveView: {
      fill: 'grey',
      fontSize: '40px',
      padding: '0px 0px 8px 8px',
      marginTop: '10px',
    },
    mainHeader: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    filterDataSection: {
      display: 'flex',
    },
  }
}

export class Cafeteria extends Component {
  constructor(props) {
    super(props)
    // const isOrderView = false;

    this.state = {
      food_items: [],
      // page: 0,
      // noMoreData: false,
      orderData: [],
      status: 'available',
      isSearch: false,
      search: '',
      modalOpen: false,
      additem: true,
      displayView: 'text',
      // isOrderView
    }
  }

  componentDidMount() {
    this.props.getallFoodItem()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.food_items.loading !== this.props.food_items.loading) {
      this.props.setLoading(this.props.food_items.loading)

      this.setState({
        loading: this.props.food_items.loading,
      })
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.food_items !== prevState.food_items) {
      return {
        food_items: nextProps.food_items.food_items,
      }
    }
    return []
  }

  searchCafeteria = (search, isSearch) => {
    this.setState({ isSearch: true, search })
    this.props.getallFoodItem(search, isSearch)
  }

  handleClickStatus = (status) => {
    this.setState({
      status,
    })
  }

  handleClickOpen = (itemadd) => {
    this.setState({
      modalOpen: true,
    })
    this.setState({
      additem: itemadd,
    })
  }

  handleClose = () => {
    this.setState({
      modalOpen: false,
    })
    this.setState({
      additem: true,
    })
  }

  handleModelOpen = (editingCafeItem) => {
    this.setState({ modalOpen: true, editingCafeItem })
  }

  addOrder = (data) => {
    const { orderData } = this.state
    const data_ids = []
    if (orderData.length === 0) {
      orderData.push(data)
      this.setState({
        orderData,
      })
    } else {
      orderData.map((d) => {
        data_ids.push(d._id)
        return null
      })
      if (data_ids.includes(data._id)) {
        // eslint-disable-next-line func-names
        const filtered = orderData.filter(function (value) {
          return value.quantity !== 0
        })
        this.setState({
          orderData: filtered,
        })
      } else {
        orderData.push(data)
        this.setState({
          orderData,
        })
      }
    }
    return null
  }

  //  filter items based on status
  filteredItems = () => {
    const { food_items } = this.state
    const data =
      food_items &&
      food_items.filter((item) => item.status === this.state.status)
    return data
  }

  setDisplayView = (view) => {
    this.setState({ displayView: view })
  }

  render() {
    const { classes } = this.props
    const {
      orderData,
      isSearch,
      search,
      loading,
      editingCafeItem,
      displayView,
      food_items,
    } = this.state

    let isOrderView = false
    let disableAddOrder = false

    const itemData = this.filteredItems()
    const role = localStorage.getItem('role')

    if (this.state.orderData.length <= 0) {
      disableAddOrder = true
    }
    if (this.props.match.path === '/food-items') {
      isOrderView = true
    }
    if (this.props.match.path === '/cafeteria') {
      if (this.state.status === 'unavailable') {
        this.setState({
          status: 'available',
        })
      }
    }

    // TODO : add check permission for (add food item)
    if (
      (food_items && food_items.length <= 0 && !isSearch) ||
      this.props.food_items.error === 'No data found'
    ) {
      return (
        <DataNotFound
          type="food_items"
          text="You don't have any food items Listed!!"
          subComponent={
            <div
              className={classes.DataNotFound}
              style={{ marginTop: '6% !important' }}
            >
              {this.props.match.path === '/food-items' && (
                <div className={classes.addFoodItem1}>
                  <Button
                    onClick={() => this.handleClickOpen(false)}
                    className={classes.foodbutton}
                    size="medium"
                    variant="contained"
                    color="primary"
                  >
                    Add Food Item
                  </Button>
                  <FoodItem
                    open={this.state.modalOpen}
                    handleClose={this.handleClose}
                    editingCafeItem={editingCafeItem}
                    additem={this.state.additem}
                  />
                </div>
              )}
              <FoodItem getallFoodItem={this.props.getallFoodItem} />
            </div>
          }
        />
      )
    }

    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.header}>
          <ScreenHeader type="Cafeteria" />
          {checkPermission('FOODITEM_CREATE') &&
            this.props.match.path === '/food-items' && (
              <div className={classes.addFoodItem1}>
                <Button
                  onClick={() => this.handleClickOpen(false)}
                  className={classes.foodbutton}
                  size="medium"
                  variant="contained"
                  color="primary"
                >
                  Add Food Item
                </Button>
                <FoodItem
                  open={this.state.modalOpen}
                  handleClose={this.handleClose}
                  editingCafeItem={editingCafeItem}
                  additem={this.state.additem}
                />
              </div>
            )}
          {checkPermission('FOODITEM_CREATE') && isOrderView && (
            <FoodItem getallFoodItem={this.props.getallFoodItem} />
          )}
          {checkPermission('FOODITEM_VIEW') && !isOrderView && (
            <div className={classes.addorderButton}>
              <Link
                className={classNames(
                  disableAddOrder
                    ? classes.disableAddOrder
                    : classes.addfooditem
                )}
                to={
                  orderData.length > 0 && {
                    pathname: '/view-orders',
                    state: { data: this.state.orderData },
                  }
                }
                disabled={disableAddOrder}
              >
                View Cart
              </Link>
            </div>
          )}
        </div>

        <div className={classes.searchSection}>
          <AppBar position="static" className={classes.appbar}>
            <Toolbar className={classes.mainHeader}>
              <SearchBar
                type="Search"
                value={this.state.search}
                className={classes.searchBox}
                onSearch={this.searchCafeteria}
              />
              <div className={classes.filterDataSection}>
                <Grid className={classes.toolbarIcon}>
                  {role === 'cafe' && (
                    // <div className={classes.toolbarIcon}>
                    // <IconButton aria-label="settings">
                    <FeaturedVideoIcon
                      onClick={() => this.setDisplayView('image')}
                      className={
                        displayView === 'image'
                          ? classes.activeView
                          : classes.inactiveView
                      }
                    ></FeaturedVideoIcon>
                    // </IconButton>
                    // </div>
                  )}
                  {role === 'cafe' && (
                    // <div className={classes.toolbarIcon}>
                    // <IconButton aria-label="setting">
                    <FeaturedPlayListIcon
                      onClick={() => this.setDisplayView('text')}
                      className={
                        displayView === 'text'
                          ? classes.activeView
                          : classes.inactiveView
                      }
                    ></FeaturedPlayListIcon>
                    // </IconButton>
                    // </div>
                  )}
                </Grid>

                {checkPermission('FOODITEM_UPDATE') && isOrderView && (
                  <div className={classes.availableSection}>
                    <Button
                      size="small"
                      variant="outlined"
                      color="primary"
                      onClick={() => this.handleClickStatus('available')}
                      className={classNames(
                        this.state.status === 'available' &&
                          classes.selectedTab,
                        classes.filterButton
                      )}
                    >
                      Available
                    </Button>

                    <Button
                      size="small"
                      variant="outlined"
                      color="primary"
                      onClick={() => this.handleClickStatus('unavailable')}
                      className={classNames(
                        this.state.status === 'unavailable' &&
                          classes.selectedTab,
                        classes.filterButton
                      )}
                    >
                      Unavailable
                    </Button>
                  </div>
                )}
              </div>
            </Toolbar>
          </AppBar>
        </div>
        {food_items && food_items.length <= 0 && isSearch && (
          <NoRecordsFound text={`No records found for your text "${search}"`} />
        )}

        <main className={classes.layout}>
          {loading && <CircularLoader />}
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              {itemData && itemData.length === 0 && !isSearch && (
                <NoRecordsFound text="No items found" />
              )}
              {/* {itemData &&
                itemData.map((element) => {
                  return (
                    <Grid
                      item
                      lg={4}
                      xs={12}
                      sm={6}
                      key={element.id}
                      className={classes.cafeteriaCardContainer}
                    >
                      <CafeteriaCard
                        data={element}
                        addOrder={this.addOrder}
                        foodstatus={this.state.status}
                        isOrderView={isOrderView}
                        food_items={this.state.food_items}
                        openEditModal={this.handleModelOpen}
                      />
                    </Grid>
                  );
                })} */}
              {itemData &&
                itemData.map((element) => {
                  return displayView === 'text' && role === 'cafe' ? (
                    <Grid
                      item
                      lg={2}
                      xs={12}
                      sm={4}
                      key={element.id}
                      className={classes.cafeteriaItemCardContainer}
                    >
                      <CafeteriaItemCard
                        data={element}
                        addOrder={this.addOrder}
                        foodstatus={this.state.status}
                        isOrderView={isOrderView}
                        food_items={this.state.food_items}
                        openEditModal={this.handleModelOpen}
                      />
                    </Grid>
                  ) : (
                    <Grid
                      item
                      lg={4}
                      xs={12}
                      sm={6}
                      key={element.id}
                      className={classes.cafeteriaCardContainer}
                    >
                      <CafeteriaCard
                        data={element}
                        addOrder={this.addOrder}
                        foodstatus={this.state.status}
                        isOrderView={isOrderView}
                        food_items={this.state.food_items}
                        openEditModal={this.handleModelOpen}
                      />
                    </Grid>
                  )
                })}
            </Grid>
          </Paper>
        </main>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    food_items: state.food_items,
    loading: state.food_items.loading,
    error: state.food_items.error,
  }
}
export default connect(mapStateToProps, { getallFoodItem })(
  withStyles(styles)(withRouter(Cafeteria))
)
