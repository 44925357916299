import React, { createRef, useEffect, useState } from "react";
import { Typography, Card, CardContent, Popover } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import moment from "moment";
import classNames from "classnames";
import { createMuiTheme } from "@material-ui/core/styles";
import Settings from "../../Components/Common/Settings";
import { capitalize } from "../../Utils/StringFunctions";
import { checkPermission } from "../../Utils/checkPermission";
import {
  getBookings,
  deleteBooking,
  getSelfBookings,
} from "../../Actions/bookingActions";
import BookingForm from "./BookingForm";

// eslint-disable-next-line no-unused-vars
const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop); // General scroll to element function

// eslint-disable-next-line no-unused-vars
const styles = (theme) => ({
  BookingsListContainer: {},
  card: {
    cursor: "pointer",
    border: "1px solid #EFEFEF",
    marginBottom: "14px",
    borderLeft: "3px solid #FFA726",
  },
  selected: { background: "#d6d6ef" },
  booking_detail: {
    boxShadow: "0 5px 15px rgba(0, 0, 0, 0.25)",
    padding: "7px 8px 9px",
    background: "#f3f3f3",
    border: "1px solid #dddddd",
    color: "#211b4e",
    width: "100%",
    borderRadius: "5px",
  },
  bookigListTitle: {
    fontSize: "14px",
    margin: "10px 0px",
  },
  noBookingText: {
    color: "lightgrey",
  },
  title: {
    fontSize: "14px",
    marginBottom: "0px",
    color: "rgba(0, 0, 0, 0.87) !important",
  },
  bookingTime: {
    fontSize: "12px",
    color: "rgba(0, 0, 0, 0.54) !important",
  },
  bookingDate: {
    fontSize: "12px",
    color: "#211b4e",
  },
  bookingList: {
    padding: "4px 16px 14px",
    "&:last-child": {
      paddingBottom: "12px",
    },
  },
  bookingPopover: {
    background: "transperant",
  },
  poptitle: {
    fontSize: "14px",
    fontWeight: 600,
    textTransform: "capitalize",
  },
  popDate: {
    fontSize: "12px",
  },
  popTime: {
    fontSize: "12px",
  },
  MuiPopover: {
    backgroundColor: "transparent",
  },
  mainpopuptitle: {
    fontWeight: 500,
  },
  settingDiv: {
    display: "flex",
    justifyContent: "flex-end",
  },
});

// eslint-disable-next-line no-unused-vars
const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        "&:before": {
          /* tricky doubly-quoted empty string so mui parses it as truly empty */
          content: '""',
          display: "block",
          width: "0",
          height: "0",
          position: "absolute",
          borderLeft: "10px solid transparent",
          borderRight: "10px solid transparent",
          /* border color should probably match whatever your tooltip color is */
          borderTop: "10px solid #000",
          left: "calc(50% - 10px)",
          top: "98%",
        },
      },
    },
  },
});

const BookingsList = (props) => {
  const { classes, events, selected, selectedMonth, selectedYear } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [popoverId, setPopoverId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [bookingId] = useState(null);
  const role = localStorage.getItem("role");
  let refs = {};
  // eslint-disable-next-line no-shadow
  function handleClick(event, popoverId) {
    refs = {};
    setPopoverId(popoverId);
    setAnchorEl(event.currentTarget);
    event.preventDefault();
    event.stopPropagation();
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  refs =
    events &&
    events.reduce((acc, value) => {
      acc[value.id] = createRef();
      return acc;
    }, {});
  // }, []);

  useEffect(() => {
    if (selected) {
      if (refs[selected].current) {
        refs[selected].current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  }, [props.selected]);

  // eslint-disable-next-line no-shadow
  const onBookingDelete = (event, id) => {
    props.deleteBooking(id);
  };

  const renderCancelBooking = (booking, remaininghours) => {
    if (role === "coworkeradmin") {
      return (
        <div className={classes.settingIcon}>
          {remaininghours <= -30 ? (
            <Settings
              isCancel
              deleteMethod={(event) => {
                onBookingDelete(event, booking.id);
              }}
              message="Are you sure want to delete this booking?"
            />
          ) : (
            ""
          )}
        </div>
      );
      // eslint-disable-next-line no-else-return
    } else {
      return (
        <div className={classes.settingIcon}>
          {remaininghours <= 0 ? (
            <Settings
              isCancel
              deleteMethod={(event) => {
                onBookingDelete(event, booking.id);
              }}
              message="Are you sure want to delete this booking?"
            />
          ) : (
            ""
          )}
        </div>
      );
    }
  };

  function handleClickClose() {
    if (role === "coworker" || role === "coworkeradmin") {
      props.getSelfBookings();
    } else {
      props.getBookings();
    }
    setModalOpen(false);
  }

  // eslint-disable-next-line no-shadow
  const bookingCard = (refs) => {
    let noDataFound = true;
    const eventsContent =
      events &&
      events.map((booking) => {
        const selectedClass =
          booking.id === selected ? "selected" : "notSelected";
        const popupClass =
          booking.id === popoverId ? "selected" : "notSelected";
        // remove delete option for past bookings
        const a = moment().format("LLL");
        const b = moment(booking.start).format("LLL");
        const currentDate = moment(a);
        const bookingDate = moment(b);
        const remaininghours = currentDate.diff(bookingDate, "minutes");

        const Month = booking.start.getMonth() + 1;
        const year = booking.start.getYear();
        const endTime = moment(booking.end).format("LT");
        const end = moment(endTime, "LT").add(1, "minutes").format("LT");
        if (selectedMonth === Month && selectedYear === year) {
          noDataFound = false;
          return (
            <Card
              className={classNames(classes.card, classes[selectedClass])}
              raised
              ref={refs[booking.id]}
              key={booking.id}
            >
              {/* <Button>Open</Button> */}
              <Popover
                id={id}
                open={open && popoverId === booking.id}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                className={classes.MuiPopover}
              >
                <div
                  className={classNames(
                    classes.card,
                    classes[popupClass],
                    classes.booking_detail
                  )}
                  raised
                  ref={refs[booking.id]}
                  key={booking.id}
                >
                  <Typography className={classes.poptitle}>
                    {booking.resource}
                  </Typography>
                  {/* <Typography>Resource :- {booking.resource}</Typography> */}
                  <Typography className={classes.popDate}>
                    Date :- {moment(booking.start).format("DD/MM/YYYY")}
                  </Typography>
                  <Typography className={classes.popTime}>
                    Time :- {moment(booking.start).format("LT")} to{" "}
                    {/* {moment(booking.end).format("LT")} */}
                    {end}
                  </Typography>
                </div>
              </Popover>
              <CardContent
                aria-describedby={id}
                className={classes.bookingList}
                onClick={(e) => {
                  handleClick(e, booking.id);
                }}
              >
                <div className={classes.settingDiv}>
                  {/* <div
                  className={classes.editSection}
                  raised
                  ref={refs[booking.id]}
                  key={booking.id}
                >
                  {checkPermission("BOOKING_UPDATE") && (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <Button
                      className={classes.edit_link}
                      // to="#"
                      onClick={e => handleClickOpen(e, booking.id)}
                    >
                      <CreateIcon fontSize="small" />
                    </Button>
                  )}
                </div> */}
                  <Typography
                    variant="button"
                    display="block"
                    gutterBottom
                    align="right"
                    className={classes.bookingDate}
                    style={{ paddingTop: "6%" }}
                  >
                    {moment(booking.start).format("DD/MM/YYYY")}
                  </Typography>
                  {/* {checkPermission("BOOKING_DELETE") ? (
                    <div className={classes.settingIcon}>
                      {remaininghours <= 0 ? (
                        <Settings
                          isCancel
                          deleteMethod={(event) => {
                            onBookingDelete(event, booking.id);
                          }}
                          message="Are you sure want to delete this booking?"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )} */}
                  {checkPermission("BOOKING_DELETE")
                    ? renderCancelBooking(booking, remaininghours)
                    : ""}
                </div>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                >
                  {capitalize(booking.resource)}
                </Typography>
                <Typography variant="subtitle1" className={classes.bookingTime}>
                  {moment(booking.start).format("LT")} to{" "}
                  {/* {moment(booking.end).format("LT")} */}
                  {end}
                </Typography>
                <Typography variant="subtitle1" className={classes.bookingTime}>
                {((checkPermission("COWORKER_ADMIN_VIEW")) ||(role === "communitymanager")) &&
                    `${booking.firstname && capitalize(booking.firstname)} ${
                      booking.lastname && capitalize(booking.lastname)
                    } ${
                      booking.companyname
                        ? `- ${capitalize(booking.companyname)}`
                        : ""
                    }`}
                </Typography>
              </CardContent>
            </Card>
          );
        }
        return null;
      });

    return (
      <React.Fragment>
        {eventsContent}
        {noDataFound && (
          <Typography variant="caption" className={classes.noBookingText}>
            No Bookings Found.
          </Typography>
        )}
      </React.Fragment>
    );
  };
  return (
    <div className={classes.BookingsListContainer}>
      {role === "coworker" || role === "coworkeradmin" ? (
        <Typography
          variant="h6"
          gutterBottom
          className={classes.bookigListTitle}
        >
          My Booking
        </Typography>
      ) : (
        <Typography
          variant="h6"
          gutterBottom
          className={classes.bookigListTitle}
        >
          Bookings for{" "}
          {moment()
            .month(selectedMonth - 1)
            .format("MMMM")}
        </Typography>
      )}
      {/* <Typography variant="h6" gutterBottom className={classes.bookigListTitle}>
        Bookings for{" "}
        {moment()
          .month(selectedMonth - 1)
          .format("MMMM")}
      </Typography> */}
      <BookingForm
        modalOpen={modalOpen}
        id={bookingId}
        handleClose={handleClickClose}
      />
      {bookingCard(refs)}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    bookings: state.bookingData,
  };
};

export default connect(mapStateToProps, {
  getBookings,
  getSelfBookings,
  deleteBooking,
})(withStyles(styles)(BookingsList));
