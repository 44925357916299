import {
  ALL_ANNOUNCEMENT,
  ANNOUNCEMENT_LOADING,
  ADD_ANNOUNCEMENT,
  ANNOUNCEMENT_ERROR,
  EDIT_ANNOUNCEMENT,
  DELETE_ANNOUNCEMENT,
  SINGLE_ANNOUNCEMENT,
  CREATE_ANNOUNCEMENT_COMMENT
} from "../Actions/types";

const initialState = {
  announcements: null,
  loading: false,
  announcement: {},
  delete_announcement: {},
  single_announcement: {},
  announcement_comment: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ALL_ANNOUNCEMENT:
      return {
        ...state,
        loading: false,
        announcements: action.payload && action.payload.data
      };
    case SINGLE_ANNOUNCEMENT:
      return {
        ...state,
        loading: false,
        single_announcement: action.payload && action.payload.data
      };

    case ANNOUNCEMENT_ERROR:
      return {
        ...state,
        announcements: { errors: false }
      };
    case ADD_ANNOUNCEMENT:
      return {
        ...state,
        announcement: {
          success: true,
          data: action.payload && action.payload.data
        }
      };
    case CREATE_ANNOUNCEMENT_COMMENT:
      return {
        ...state,
        announcement_comment: {
          success: true,
          data: action.payload && action.payload.data
        }
      };
    case EDIT_ANNOUNCEMENT:
      return {
        ...state,
        edit_announcement: {
          success: true,
          data: action.payload && action.payload.data
        }
      };
    case DELETE_ANNOUNCEMENT:
      return {
        ...state,
        delete_announcement: {
          success: true,
          data: action.payload && action.payload.data
        }
      };
    case ANNOUNCEMENT_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
}
