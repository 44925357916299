import {
  GET_EVENTS,
  GET_EVENT,
  CREATE_EVENT,
  EVENTS_LOADING,
  EVENT_PARTICIPANT,
  EVENT_ERROR,
  GET_EVENT_DISCUSSION,
  GET_ALL_TEAM,
  GET_TEAM_ICON,
  TEAM_ERROR,
  GET_ONE_TEAM,
  CREATE_TEAM,
  GET_TEAM_ERROR,
} from "../Actions/types";

const initialState = {
  events: null,
  loading: false,
  discussion: null,
  event: {},
  event_participant: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_EVENTS: {
      const { eventType } = action;
      return {
        ...state,
        loading: false,
        noMoreData: !!(
          action.payload.data &&
          action.payload.data.length &&
          action.payload.data.length <= 0
        ),
        [eventType || "events"]: action.reset
          ? action.payload.data
          : action.payload.data,
        // events: action.reset ? action.payload.data : action.payload.data
      };
    }
    case GET_EVENT:
      return {
        loading: false,
        ...state,
        event: action.payload && action.payload.data,
      };

    case CREATE_EVENT:
      return {
        ...state,
        event: { success: true, data: action.payload },
      };
    case EVENTS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case EVENT_PARTICIPANT:
      return {
        ...state,
        loading: false,
        event_participant: { success: true, data: action.payload },
      };
    case EVENT_ERROR:
      return {
        ...state,
        loading: false,
        event: { success: false, data: action.payload && action.payload.data },
      };
    case GET_EVENT_DISCUSSION:
      return {
        loading: false,
        ...state,
        discussion: action.payload && action.payload.data,
      };
    case CREATE_TEAM:
      return {
        ...state,
        register_team: { success: true, data: action.payload },
      };
    case GET_ALL_TEAM:
      return {
        ...state,
        loading: false,
        all_team: action.payload && action.payload.data,
      };
    case GET_TEAM_ICON:
      return {
        ...state,
        loading: false,
        team_icon: action.payload && action.payload.data,
      };
    case GET_ONE_TEAM:
      return {
        ...state,
        loading: false,
        single_team: action.payload && action.payload.data,
      };
    case TEAM_ERROR:
      return {
        ...state,
        loading: false,
        team: { success: false, data: action.payload && action.payload.data },
      };
    case GET_TEAM_ERROR:
      return {
        ...state,
        loading: false,
        all_team: action.payload,
      };
    default:
      return state;
  }
}
