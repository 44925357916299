import {
  GET_VISITORS,
  VISITOR_LOADING,
  ADD_VISITOR,
  GET_SINGLE_WALKTHROUGH_VISITORS,
  ADD_WALKTHROUGH_VISITOR_DETAIL,
  CHANGE_VISITOR_TYPE,
} from '../Actions/types'

const initialState = {
  visitors: [],
  visitor: {},
  loading: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_VISITORS:
      return {
        ...state,
        loading: false,
        visitors: action.payload.data,
      }
    case GET_SINGLE_WALKTHROUGH_VISITORS:
      return {
        ...state,
        loading: false,
        single_walkthrough_visitor: action.payload && action.payload.data,
      }
    case ADD_VISITOR:
      return {
        ...state,
        loading: false,
        visitor: { success: true, data: action.payload.data },
      }
    case ADD_WALKTHROUGH_VISITOR_DETAIL:
      return {
        ...state,
        loading: false,
        add_walkthrough_detail: { success: true, data: action.payload.data },
      }
    case CHANGE_VISITOR_TYPE:
      return {
        ...state,
        loading: false,
        changeVisitorData: { success: true, data: action.payload.data },
      }
    case VISITOR_LOADING:
      return {
        ...state,
        loading: true,
      }
    default:
      return state
  }
}
