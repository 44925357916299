import React, { Component } from "react";
import { Dialog, DialogContent, Grid, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import classNames from "classnames";
import { capitalize, spacecapitalize } from "../../Utils/StringFunctions";

const styles = () => ({
  clubbutton: {
    display: "contents",
  },
  club_form_modal: {
    width: "500px",
  },
  coworkerInfo: {
    paddingTop: "18px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    justify: "left",
    alignItems: "left",
    "& p": {
      fontSize: "14px",
    },
  },
  skills: {
    paddingTop: "3px",
    color: "#ababab",
    fontSize: "12px",
    fontWeight: "400",
  },
  coworkerName: {
    textTransform: "capitalize",
    color: "#222222 !important",
    fontWeight: "500",
    textAlign: "left",
  },
  headerText: {
    fontSize: "16px",
  },
  alignLeft: {
    textAlign: "left",
  },
  commentBox: {
    backgroundColor: "#F3F3F3",
    padding: "10px",
    margin: "10px 0px",
  },
  titleText: {
    color: "#222222",
    fontWeight: "500",
    paddingBottom: "10px",
  },
  companyName: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#222222 !important",
    textAlign: "left",
  },
  fieldValue: {
    color: "#626262",
    fontSize: "14px",
    fontWeight: "500",
  },
  title: {
    fontSize: "12px",
    paddingTop: "10px",
    paddingBottom: "0px",
  },
});

const theme = createMuiTheme({
  overrides: {
    MuiDialogContent: {
      root: {
        width: "500px",
        height: "350px",
      },
    },
  },
});
// eslint-disable-next-line react/prefer-stateless-function
class RefferalCardDetailPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, data, close, detailData } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <div className={classes.club_form_modal}>
          <Dialog
            open={data}
            onClose={close}
            aria-labelledby="plan-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <Grid container spacing={2}>
                <Grid spacing={3}>
                  <Typography
                    className={classNames(
                      classes.coworkerName,
                      classes.headerText
                    )}
                  >
                    {capitalize(detailData && detailData.name)} |{" "}
                    <span className={classes.companyName}>
                      {detailData.company_name
                        ? capitalize(detailData.company_name)
                        : "-"}
                    </span>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className={classes.coworkerInfo} spacing={2}>
                <Grid xs={6} sm={6} className={classes.alignLeft}>
                  <Typography variant="subtitle2" className={classes.skills}>
                    Contact Details
                  </Typography>
                  <Typography
                    variant="h3"
                    component="p"
                    className={classes.fieldValue}
                  >
                    {detailData.contact_number}
                  </Typography>
                </Grid>

                <Grid xs={6} sm={6} className={classes.alignLeft}>
                  <Typography variant="subtitle2" className={classes.skills}>
                    Email ID
                  </Typography>
                  <Typography
                    variant="h3"
                    component="p"
                    className={classes.fieldValue}
                  >
                    {/* <NameTruncate text={detailData.email} amount={13} /> */}
                    {detailData.email ? detailData.email : "-"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className={classes.coworkerInfo} spacing={2}>
                <Grid xs={6} sm={6} className={classes.alignLeft}>
                  <Typography variant="subtitle2" className={classes.skills}>
                    Location
                  </Typography>
                  <Typography
                    variant="h3"
                    component="p"
                    className={classes.fieldValue}
                  >
                    {detailData.location
                      ? capitalize(detailData.location)
                      : "-"}
                  </Typography>
                </Grid>

                <Grid xs={6} sm={6} className={classes.alignLeft}>
                  <Typography variant="subtitle2" className={classes.skills}>
                    Product
                  </Typography>
                  <Typography
                    variant="h3"
                    component="p"
                    className={classes.fieldValue}
                  >
                    {detailData.product
                      ? spacecapitalize(detailData.product)
                      : "-"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className={classes.coworkerInfo} spacing={2}>
                <Grid xs={12} sm={12} className={classes.alignLeft}>
                  <Typography
                    variant="subtitle2"
                    className={classNames(classes.titleText, classes.title)}
                  >
                    Comments
                  </Typography>

                  {detailData.comments &&
                    detailData.comments.map((item) => (
                      <Grid className={classes.commentBox}>
                        <Typography
                          variant="h3"
                          component="p"
                          className={classes.titleText}
                        >
                          {capitalize(item.first_name)}{" "}
                          {capitalize(item.last_name)}
                        </Typography>
                        <Typography variant="h3" component="p">
                          {item.comment}
                        </Typography>
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        </div>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    referFriend: state.referFriend.referFriend,
  };
};
export default connect(mapStateToProps)(
  withStyles(styles)(RefferalCardDetailPopup)
);
