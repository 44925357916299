/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable camelcase */
import React from 'react'
import { connect } from 'react-redux'
import withStyles from '@material-ui/core/styles/withStyles'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import socketIOClient from 'socket.io-client'
import {
  Button,
  CssBaseline,
  AppBar,
  Toolbar,
  Paper,
  Grid,
} from '@material-ui/core'
import ScreenHeader from 'Components/Common/ScreenHeader'
import SearchBar from 'Components/Common/SearchBar'
import { getDeliveredPending, acceptRejectOrder } from 'Actions/OrderActions'
import { getallFoodItem } from 'Actions/foodItemActions'
import DataNotFound from 'Components/Common/DataNotFound'
import NoRecordsFound from 'Components/Common/NoRecordsFound'
import Music from 'Assets/mp3/buzzer_music.mp3'
import DeliveredOrder from './DeliveredOrder'
import PendingOrder from './PendingOrder'

// const beep = new UIfx(
//   // "https://cdn.glitch.com/35252802-b02a-4d63-9536-c72e10d1998c%2Fbeep.mp3?1558053587340",
//   Music,
//   {
//     volume: 0.4,
//     throttleMs: 100,
//   }
// );

const api_url = process.env.REACT_APP_api_url

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    flexGrow: 1,
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      padding: theme.spacing(3),
    },
  },
  main: {
    padding: '24px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  active: {
    backgroundColor: '#211b4e',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#211b4e',
      color: '#fff',
    },
  },
  inActive: {
    color: '#211b4e',
  },
  statusButton: {
    borderRadius: '50px',
    marginRight: '10px',
    border: '1px solid #211b4e',
    height: '30px',
    padding: '1px 12px',
    '&:hover': {
      backgroundColor: '#211b4e',
      color: '#fff',
    },
  },
  appbar: {
    backgroundColor: '#ffffff',
  },
  searchSection: {
    margin: '24px 0px 0px',
  },
  pending: {
    backgroundColor: '#4d7cfe',
    color: '#fff',
  },
})

class OrderStatus extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      status: 'pending',
      pendingActive: 'active',
      deliveredActive: 'inActive',
      rejectedActive: 'inActive',
      isSearch: false,
      search: '',
      endpoint: api_url,
      orderDataFromSocket: '',
      newOrder: 'false',
      play: false,
    }
  }

  componentDidMount() {
    this.connectNow()
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.get_order !== prevState.get_order) {
      return { get_order: nextProps.get_order }
    }
    if (
      nextProps.mainOrderStatusData !== prevState.mainOrderStatusData &&
      nextProps.mainOrderStatusData.success
    ) {
      return null
    }
    return {}
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.mainOrderStatusData !== prevProps.mainOrderStatusData &&
      this.props.mainOrderStatusData.success
    ) {
      this.props.getDeliveredPending(this.state.status)
    }
    if (prevProps.loading !== this.props.loading) {
      this.props.setLoading(this.props.loading)
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        loading: this.props.loading,
      })
    }
    if (
      this.state.orderDataFromSocket.length !==
        prevState.orderDataFromSocket.length &&
      this.state.orderDataFromSocket.length >
        prevState.orderDataFromSocket.length
    ) {
      this.setState({ play: true })
    }
  }

  handleClickStatus = (status) => {
    if (status === 'delivered') {
      this.setState(
        {
          status,
          deliveredActive: 'active',
          pendingActive: 'inActive',
          rejectedActive: 'inActive',
        },

        async () => {
          await this.props.getDeliveredPending(this.state.status)
        }
      )
    } else if (status === 'pending') {
      this.setState(
        {
          status,
          pendingActive: 'active',
          deliveredActive: 'inActive',
          rejectedActive: 'inActive',
        },
        () => {
          this.props.getDeliveredPending(this.state.status)
        }
      )
    } else if (status === 'reject') {
      this.setState(
        {
          status,
          rejectedActive: 'active',
          deliveredActive: 'inActive',
          pendingActive: 'inActive',
        },
        () => {
          this.props.getDeliveredPending(this.state.status)
        }
      )
    }
  }

  searchCafeteria = (search, isSearch) => {
    this.setState({
      isSearch: true,
      search,
    })
    this.props.getDeliveredPending(this.state.status, search, isSearch)
  }

  connectNow = () => {
    const { endpoint, orderDataFromSocket } = this.state
    const token = localStorage.getItem('jwtToken')
    const space_id = localStorage.getItem('space_id')
    const socket = socketIOClient(endpoint, {
      transportOptions: {
        polling: {
          extraHeaders: {
            authorization: token,
            space_id,
          },
        },
      },
      query: 'status=pending',
      path: '/socket/',
    })
    socket.emit('initial_data')
    socket.on('get_data', (orderData) => {
      if (orderDataFromSocket.length !== orderData.data.length) {
        this.setState({ newOrder: true, play: false })
      } else {
        this.setState({ newOrder: false })
      }
      this.setState({ orderDataFromSocket: orderData.data })
    })
  }

  headerRender = () => {
    const { classes } = this.props
    const {
      get_order,
      isSearch,
      search,
      orderDataFromSocket,
      newOrder,
    } = this.state
    let { lastArray } = []
    if (newOrder) {
      // eslint-disable-next-line prefer-destructuring
      lastArray = orderDataFromSocket[0]
    } else {
      lastArray = []
    }
    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.header}>
          <ScreenHeader type="Order Status" />
          <Button
            variant="outlined"
            color="primary"
            className={classNames(
              classes[this.state.pendingActive],
              classes.statusButton
            )}
            onClick={() => this.handleClickStatus('pending')}
          >
            Pending
          </Button>

          <Button
            variant="outlined"
            color="primary"
            className={classNames(
              classes[this.state.deliveredActive],
              classes.statusButton
            )}
            onClick={() => this.handleClickStatus('delivered')}
          >
            Delivered
          </Button>

          <Button
            variant="outlined"
            color="primary"
            className={classNames(
              classes[this.state.rejectedActive],
              classes.statusButton
            )}
            onClick={() => this.handleClickStatus('reject')}
          >
            Rejected
          </Button>
        </div>
        <div className={classes.searchSection}>
          <AppBar position="static" className={classes.appbar}>
            <Toolbar>
              <SearchBar
                type="Search"
                className={classes.searchBox}
                onSearch={this.searchCafeteria}
                value={this.state.search}
              />
            </Toolbar>
          </AppBar>
        </div>
        {orderDataFromSocket && orderDataFromSocket.length <= 0 && isSearch && (
          <>
            {search !== '' && get_order.length !== 1 && (
              <NoRecordsFound
                text={`No records found for your text "${search}"`}
              />
            )}
          </>
        )}
        <div className={classes.main} />

        <main className={classes.layout}>
          <Paper className={classes.paper}>
            {/* Hero unit */}
            <Grid container spacing={2}>
              {this.state.status === 'pending' &&
                orderDataFromSocket &&
                orderDataFromSocket.map((element) => {
                  return (
                    <Grid item lg={4} xs={12} sm={6} key={element.id}>
                      {element === lastArray ? (
                        <PendingOrder
                          data={element}
                          acceptRejectOrder={this.props.acceptRejectOrder}
                          newOrderData
                        />
                      ) : (
                        <PendingOrder
                          data={element}
                          acceptRejectOrder={this.props.acceptRejectOrder}
                          newOrderData={false}
                        />
                      )}
                    </Grid>
                  )
                })}
              {this.state.status === 'reject' && (
                <>
                  {get_order === 'No data found ' ? (
                    <>
                      {(!isSearch || search === '') && (
                        <NoRecordsFound text="No records found" />
                      )}
                    </>
                  ) : (
                    get_order &&
                    get_order.map((element) => {
                      return (
                        <Grid item lg={4} xs={12} sm={6} key={element.id}>
                          <PendingOrder
                            data={element}
                            acceptRejectOrder={this.props.acceptRejectOrder}
                            newOrderData={false}
                          />
                        </Grid>
                      )
                    })
                  )}
                </>
              )}
            </Grid>
            {this.state.status === 'delivered' && (
              <DeliveredOrder data={get_order} />
            )}
          </Paper>
          {this.state.play && (
            <audio ref={this.myRef} autoPlay preload="auto">
              <source src={Music} type="audio/mpeg" />
            </audio>
          )}
        </main>
      </React.Fragment>
    )
  }

  render() {
    const { classes } = this.props
    const { orderDataFromSocket } = this.state
    this.myRef = React.createRef()

    if (orderDataFromSocket && orderDataFromSocket.length <= 0) {
      if (this.state.status === 'delivered' || this.state.status === 'reject') {
        return (
          <div>
            {this.headerRender()}
            {this.state.status === 'reject' &&
            orderDataFromSocket &&
            orderDataFromSocket.length === 0
              ? ''
              : // <NoRecordsFound text="No records found" />
                ''}
          </div>
        )
      }
      return (
        <DataNotFound
          type="event"
          text="You don't have any Orders !!"
          subComponent={
            <div className={classes.DataNotFound}>
              <Button
                variant="outlined"
                color="primary"
                className={classNames(
                  classes[this.state.deliveredActive],
                  classes.statusButton
                )}
                onClick={() => this.handleClickStatus('delivered')}
              >
                Delivered Orders
              </Button>
            </div>
          }
        />
      )
    }
    return this.headerRender()
  }
}
OrderStatus.propTypes = {}
const mapStateToProps = (state) => {
  return {
    get_order: state.get_all_order.get_order,
    mainOrderStatusData: state.get_all_order.mainOrderStatusData,
    loading: state.get_all_order.loading,
  }
}
export default connect(mapStateToProps, {
  getDeliveredPending,
  acceptRejectOrder,
  getallFoodItem,
})(withStyles(styles)(withRouter(OrderStatus)))
