/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from "react";
import { withStyles, MenuItem, Select, FormControl } from "@material-ui/core";
import classNames from "classnames";
import { connect } from "react-redux";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import avatar from "../../Assets/coworker3.png";
import { capitalize } from "../../Utils/StringFunctions";
import { createHistory } from "../../Actions/helpdeskActions";

const styles = () => ({
  profileImage: {
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    marginRight: "10px",
    position: "relative",
    top: "4px",
  },
});

const theme = createMuiTheme({
  overrides: {
    MuiSelect: {
      // Name of the component ⚛️ / style sheet
      icon: {
        top: "18px",
      },
      selectMenu: {
        display: "flex",
        alignItems: "center",
      },
      select: {
        paddingTop: "17px",
      },
    },
    MuiInputBase: {
      inputSelect: {
        paddingTop: "17px",
      },
    },
  },
});
const AssignTo = (props) => {
  const { setErrors, classes, getAssignTo, assignToData } = props;

  // eslint-disable-next-line no-unused-vars
  const [assignTo, setAssignTo] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    getAssignTo("sysuser");
  }, []);

  useEffect(() => {
    setAssignTo(props.assignToData);
    setSelectedValue(selectedValue);
  }, [props.assignToData]);

  const callStatusChangeAPI = (el) => {
    // eslint-disable-next-line no-shadow
    const selected = el.target.value;
    const id = props.ticket_id;

    props.createHistory(
      { user_id: selected, ticket_id: id },
      setErrors,
      props.history
    );
    setSelected(el.target.value);
  };
  const assignToArray = [];
  // eslint-disable-next-line no-restricted-syntax
  // eslint-disable-next-line guard-for-in
  for (const key in assignToData) {
    assignToArray.push({
      id: assignToData[key]._id,
      name: `${assignToData[key].first_name} ${assignToData[key].last_name}`,
      profile: `${assignToData[key] && assignToData[key].profile_pic}`,
    });
  }

  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <FormControl variant="filled" className={classes.formControl}>
          <Select
            className={classNames(
              classes[props.selectedItem],
              classes.statusDropdown
            )}
            value={props.selectedItem}
            onChange={callStatusChangeAPI}
            name="name"
            disableUnderline
            label="select.."
            variant="standard"
          >
            <MenuItem
              value="selectuser"
              selectedItem
              disabled
              className={classes.selectText}
            >
              Select user
            </MenuItem>
            {assignToArray &&
              assignToArray.map((element) => {
                return (
                  <MenuItem value={element.id} key={element.id}>
                    {element.profile === "undefined" ||
                    element.profile === "" ? (
                      <img
                        alt="profilepic"
                        src={avatar}
                        className={classes.profileImage}
                      />
                    ) : (
                      <img
                        alt="profilepic"
                        src={element.profile}
                        className={classes.profileImage}
                      ></img>
                    )}
                    {capitalize(element.name)}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </React.Fragment>
    </ThemeProvider>
  );
};
const mapStateToProps = (state) => {
  return {
    errors: state.errors,
    selected: state.helpdesk.selected,
  };
};
export default connect(mapStateToProps, { createHistory })(
  withStyles(styles)(AssignTo)
);
