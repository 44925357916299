import {
  KNOWLEDGE_CENTER_ERRORS,
  KNOWLEDGE_CENTER_LOADING,
  ADD_KNOWLEDGE_CENTER_INFORMATION,
  GET_SINGLE_KNOWLEDGE_CENTER_INFO,
  GET_TEAM_DATA,
  UPDATE_KNOWLEDGE_CENTER_INFORMATION,
} from "../Actions/types";

const initialState = {
  addKnowledgeInfo: {},
  loading: false,
  knowledgeCenterData: {},
  updateKnowledgeInfo: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case KNOWLEDGE_CENTER_ERRORS:
      return {
        ...state,
        loading: false,
        visitors: action.payload && action.payload.data,
      };
    case GET_SINGLE_KNOWLEDGE_CENTER_INFO:
      return {
        ...state,
        loading: false,
        single_knowledge_center_info: action.payload && action.payload.data,
      };
    case GET_TEAM_DATA:
      return {
        ...state,
        loading: false,
        allTeam: action.payload && action.payload.data,
      };
    case ADD_KNOWLEDGE_CENTER_INFORMATION:
      return {
        ...state,
        loading: false,
        addKnowledgeInfo: { success: true, data: action.payload.data },
      };
    case UPDATE_KNOWLEDGE_CENTER_INFORMATION:
      return {
        ...state,
        loading: false,
        updateKnowledgeInfo: { success: true, data: action.payload.data },
      };
    case KNOWLEDGE_CENTER_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
