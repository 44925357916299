/* eslint-disable no-use-before-define */
import axios from "axios";
import {
  GET_COWORKERS,
  GET_COWORKERS_COMPANY,
  COWORKER_LOADING,
  COWORKER_ERROR,
  CREATE_COWORKER_SUCCESS,
  UPDATE_COWORKER_STATUS,
  UPDATE_COWORKER,
  GET_SINGLE_COWORKER,
  GET_ALL_COWORKERS,
} from "./types";
import { showSuccess, showError } from "./ActionsHelper";
import { transformErrorsFromApi } from "./TransformError";

export const getCoworkers = (page, search) => (dispatch) => {
  dispatch(setCoworkersLoading());
  let url = "/api/coworker";

  if (search) {
    url = `/api/coworker?q=${search}`;
  }
  axios
    .get(url)
    .then((res) => {
      dispatch({
        type: GET_COWORKERS,
        reset: page ? page === 0 : false,
        payload: res.data,
      });
    })
    // eslint-disable-next-line no-unused-vars
    .catch((err) =>
      dispatch({
        type: COWORKER_ERROR,
        payload: err && err.response ? err.response.data : "",
      })
    );
};

export const getallCoworkers = () => (dispatch) => {
  dispatch(setCoworkersLoading());
  axios
    .get(`/api/coworker/`)
    .then((res) => {
      dispatch({
        type: GET_ALL_COWORKERS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: COWORKER_ERROR,
        payload: err && err.res ? err.res.data : "",
      })
    );
};

export const getCoworker = (id, role) => (dispatch) => {
  dispatch(setCoworkersLoading());

  let url = `/api/coworker/${id}`

  if (role === 'admin') {
    url = `/api/coworker/${id}?type=booking`
  }
  axios
    .get(url)
    .then((res) => {
      dispatch({
        type: GET_COWORKERS_COMPANY,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: COWORKER_ERROR,
        payload: err && err.res ? err.res.data : "",
      })
    );
};
export const createCoworker = (data, setErrors, setSubmitting, history) => (
  dispatch
) => {
  dispatch(setCoworkersLoading());
  axios
    .post("/api/coworker", data)
    .then((result) => {
      dispatch({
        type: CREATE_COWORKER_SUCCESS,
        payload: result.data,
      });
      history.push("/co-workers");
      dispatch(showSuccess("Co-worker"));
      setSubmitting(false);
    })
    .catch((err) => {
      dispatch({
        type: COWORKER_ERROR,
        payload: err && err.response ? err.response.data.data : "",
      });
      dispatch(showError(err.response.data.data));
    });
  // .catch(err => {
  //   const error = err.response;
  //   dispatch({ type: COWORKER_ERROR, payload: error.data });
  //   setSubmitting(false);
  //   setErrors(transformErrorsFromApi(error));
  //   // history.push("/co-workers");
  //   // dispatch(showError("There was some error while adding coworker!"));
  // });
};
export const updateCoworkerStatus = (id, userData, setSubmitting) => (
  dispatch
) => {
  // dispatch(setCoworkersLoading());

  axios
    .put(`/api/user/changestatus/${id}`, userData)
    .then((result) => {
      dispatch({
        type: UPDATE_COWORKER_STATUS,
        payload: result.data,
      });
      dispatch(showSuccess("", "Status updated successfully!"));
      setSubmitting(false);
    })
    .catch((err) => {
      dispatch({
        type: COWORKER_ERROR,
        // payload: err.response.data
        payload: ((err || {}).response || {}).data || "Error unexpected",
      });
    });
};
export const updateCoworker = (id, data, setErrors, setSubmitting, history) => (
  dispatch
) => {
  dispatch(setCoworkersLoading());
  axios
    .put(`/api/coworker/${id}`, data)

    .then((res) => {
      dispatch({
        type: UPDATE_COWORKER,
        payload: res.data,
      });
      history.push("/co-workers");
      dispatch(showSuccess(" ", "Co-worker updated successfully!"));
      setSubmitting(false);
    })
    .catch((err) => {
      const error = err.response;
      dispatch({ type: COWORKER_ERROR, payload: error.data });
      setSubmitting(false);
      setErrors(transformErrorsFromApi(error));
    });
};
export const getSingleCoworker = (id) => (dispatch) => {
  dispatch(setCoworkersLoading());
  axios
    .get(`/api/coworker/getone/${id}`)
    .then((res) => {
      dispatch({
        type: GET_SINGLE_COWORKER,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: COWORKER_ERROR,
        payload: err && err.res ? err.res.data : "",
      })
    );
};
// Profile Loading
export const setCoworkersLoading = () => {
  return {
    type: COWORKER_LOADING,
  };
};
