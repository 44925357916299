import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { Dialog, DialogContent } from "@material-ui/core";
import Carousel from "re-carousel";
import Buttons from "../../Components/Common/buttons";

const styles = () => ({});

class ImageCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // errors: {}
    };
  }

  render() {
    const { classes } = this.props;

    return (
      <Dialog
        open={this.props.data}
        onClose={this.props.close}
        aria-labelledby="form-dialog-title"
        maxWidth="700px"
      >
        <DialogContent
          className={classes.popup}
          style={{
            height: "400px",
            width: "700px",
            maxWidth: "700px",
            overflow: "hidden",
            padding: "0px",
          }}
        >
          <Carousel widgets={[Buttons]} arrows IndicatorDots>
            {this.props.imageArray &&
              this.props.imageArray.map((tile) => (
                <img
                  alt="logo"
                  src={tile}
                  //   className={classes.group}
                  width="100%"
                  height="400px"
                />
              ))}
          </Carousel>
        </DialogContent>
      </Dialog>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    errors: state.errors,
  };
};
export default connect(mapStateToProps, {})(withStyles(styles)(ImageCarousel));
