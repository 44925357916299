import React, { useEffect, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { Grid, Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import { getAllTeam } from "../../Actions/eventActions";
import TeamCard from "../../Components/Common/Cards/TeamCard";
import DataNotFound from "../../Components/Common/DataNotFound";

const $quoteSym = " ";

const styles = () => ({
  formContent: {
    width: 500,
    padding: "34px 24px",
  },
  modelHeader: {
    paddingBottom: "5px",
    position: "relative",
    "&:after": {
      top: "48px",
      left: "24px",
      width: "24px",
      borderBottom: "3px solid #fb0",
      content: `'${$quoteSym}'`,
      position: "absolute",
    },
  },
  team_card: {
    marginBottom: "25px",
  },
  teamCardSection: {
    width: "18%",
    marginRight: "18px",
  },
});

function JoinTeamModal(props) {
  const { classes, eventID } = props;
  const [joinTeamData, setjoinTeamData] = useState([]);

  useEffect(() => {
    props.getAllTeam(eventID, "available ");
  }, []);

  useEffect(() => {
    setjoinTeamData(props.teamData);
  }, [props.teamData]);

  useEffect(() => {
    if (props.register_team && props.register_team.success) {
      props.closeJoinModal();
    }
  }, [props.register_team]);

  useEffect(() => {
    // console.log("props data", props.openJoinModal);
    if (props.openJoinModal === false) {
      // props.getAllTeam(eventID, "available ");
      setjoinTeamData(props.teamData);
    }
  }, [props.teamData]);
  return (
    <React.Fragment>
      <Dialog
        open={props.openJoinModal}
        onClose={props.closeJoinModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className={classes.modelHeader}>
          Join a Team
        </DialogTitle>
        <DialogContent className={classes.formContent}>
          <div className={classes.teamMainSection}>
            {/* <DataNotFound text="No Participants Found" /> */}
            <Grid container spacing={2}>
              {(joinTeamData && joinTeamData.length <= 0) ||
              joinTeamData === undefined ||
              joinTeamData === "No data found" ? (
                <DataNotFound text="No Team Found" />
              ) : (
                joinTeamData &&
                joinTeamData.map((element) => {
                  return (
                    <>
                      <Grid key={element.id} item sm={4} lg={4}>
                        <div className={classes.team_card}>
                          {props.eventData.team_max >=
                            element.total_team_member && (
                            <TeamCard data={element} type="join" />
                          )}
                        </div>
                      </Grid>
                    </>
                  );
                })
              )}
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  // console.log("state dataaa :::", state.events);
  return {
    errors: state.errors,
    teamData: state.events.all_team,
    register_team: state.events.register_team,
  };
};

export default connect(mapStateToProps, { getAllTeam })(
  withStyles(styles)(JoinTeamModal)
);
