/* eslint-disable camelcase */
import React from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
  Grid,
} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import rule1 from "../../Assets/rule1.png";
import rule2 from "../../Assets/rule2.png";
import rule3 from "../../Assets/rule3.png";
import rule4 from "../../Assets/rule4.png";

const styles = () => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "18px",
  },
  mainTitle: {
    fontWeight: "600",
    fontSize: "20px",
    textAlign: "left",
    color: "#222222",
    margin: "0px",
    lineHeight: "25px",
  },
  heading: {
    fontSize: "16px",
    color: "#222222",
    fontWeight: "500",
    margin: "4px 15px",
  },
  description: {
    color: "#222222",
    fontSize: "13px",
    lineHeight: "18px",
  },
  mainSlot: {
    display: "flex",
    justifyContent: "space-between",
  },
  areaSection: {
    display: "flex",
    flexDirection: "row",
  },
});

const theme = createMuiTheme({
  overrides: {
    MuiPaper: {
      elevation1: {
        boxShadow: "none",
      },
    },
    MuiExpansionPanelDetails: {
      // Name of the component ⚛️ / style sheet
      root: {
        padding: "8px",
        border: "none",
      },
    },
    MuiCheckbox: {
      root: {
        padding: "0px",
      },
    },
    MuiExpansionPanel: {
      rounded: {
        border: "none",
        borderBottom: "1px solid #EFEFEF",
        borderRadius: "0px",
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        padding: "0 8px 0 8px",
      },
    },
  },
});

function HouseRules(props) {
  const { classes } = props;
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.main}>
        <div className={classes.header}>
          <Typography align="left" variant="h1" className={classes.mainTitle}>
            House-Rules
          </Typography>
        </div>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <ExpansionPanel
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.mainSlot}
              >
                <img
                  src={rule1}
                  alt="rules 1"
                  className={classes.setupImg}
                ></img>
                <Typography className={classes.heading}>
                  General Rules
                </Typography>
              </ExpansionPanelSummary>
              <div className={classes.areaSection}>
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <ExpansionPanelDetails>
                      <Typography align="left" className={classes.description}>
                        Please be responsible and switch-off the computer,
                        lights, fans, or any other electronic device that is not
                        in use.
                        <br />
                        <br /> Please keep noise and distractions to a minimum;
                        also maintain a decent cell phone volume & ringtone to
                        maintain the office decorum.
                        <br />
                        <br /> We expect you to keep the work area tidy. It is
                        your office after all!
                        <br />
                        <br /> Do not show any creativity on the assets. You can
                        use a board to create your ideas and write your
                        thoughts. <br />
                        <br />
                        Treating the housekeeping staff right and their work
                        with respect will help improve our humanity. <br />
                        <br />
                        Please do not make the common area your favourite place
                        to spend time day dreaming. It is meant for brief
                        conversations.
                        <br />
                        <br /> Smoking or chewing the tobacco inside the
                        premises is strictly prohibited – Remember it&apos;s
                        injurious for health too.
                      </Typography>
                    </ExpansionPanelDetails>
                  </Grid>
                </Grid>
              </div>
            </ExpansionPanel>
          </Grid>

          <Grid item xs={12} sm={12}>
            <ExpansionPanel
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.mainSlot}
              >
                <img
                  src={rule2}
                  alt="rules 2"
                  className={classes.setupImg}
                ></img>
                <Typography className={classes.heading}>Lounge Area</Typography>
              </ExpansionPanelSummary>
              <div className={classes.areaSection}>
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <ExpansionPanelDetails>
                      <Typography align="left" className={classes.description}>
                        It is for quick business conversation with your guests.
                        <br />
                        <br /> Please try and avoid internal team meetings,
                        telephonic conversations, video calls, Meetings with
                        guests over 4 members, watching videos and playing
                        games, documentation work with guests or employees.
                        <br />
                        <br /> Having food even dry snacks is prohibited.
                        <br />
                        <br /> No parcel pickups or delivery packages are
                        allowed inside the premises. <br />
                        <br />
                        Avoid phone calls for any long conversation at passage
                        or any other common areas – Remember we have a phone
                        booth.
                      </Typography>
                    </ExpansionPanelDetails>
                  </Grid>
                </Grid>
              </div>
            </ExpansionPanel>
          </Grid>
          <Grid item xs={12} sm={12}>
            <ExpansionPanel
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.mainSlot}
              >
                <img
                  src={rule3}
                  alt="rules 3"
                  className={classes.setupImg}
                ></img>
                <Typography className={classes.heading}>
                  Visitors Policy
                </Typography>
              </ExpansionPanelSummary>
              <div className={classes.areaSection}>
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <ExpansionPanelDetails>
                      <Typography align="left" className={classes.description}>
                        All the visitors are required to wait at lounge area and
                        the co-workers would be requested to attend them
                        personally.
                        <br />
                        <br /> Security guard will not drop any guests or
                        visitors to any co-workers door step.
                        <br />
                        <br /> Wearing a guest card is compulsory for all the
                        guests. No one would be allowed into the premises
                        without the guest card.
                        <br />
                        <br /> Any sort of misbehaviour from the visitors or
                        guests would be unaccepted.
                      </Typography>
                    </ExpansionPanelDetails>
                  </Grid>
                </Grid>
              </div>
            </ExpansionPanel>
          </Grid>
          <Grid item xs={12} sm={12}>
            <ExpansionPanel
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.mainSlot}
              >
                <img
                  src={rule4}
                  alt="rules 4"
                  className={classes.setupImg}
                ></img>
                <Typography className={classes.heading}>
                  Private Cabin Policy
                </Typography>
              </ExpansionPanelSummary>
              <div className={classes.areaSection}>
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <ExpansionPanelDetails>
                      <Typography align="left" className={classes.description}>
                        Food is strictly not allowed inside the cabins except
                        dry snack, tea, coffee, and water. In case of any such
                        incident one will have to pay a penalty.
                        <br />
                        <br /> Locking the cabin is the responsibility of all
                        respective co-workers.
                        <br />
                        <br /> Always keeping the cabin door closed would create
                        a secured environment.
                      </Typography>
                    </ExpansionPanelDetails>
                  </Grid>
                </Grid>
              </div>
            </ExpansionPanel>
          </Grid>
        </Grid>
      </div>
    </ThemeProvider>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.spaces.loading,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(HouseRules));
