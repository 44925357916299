/* eslint-disable camelcase */
import React from "react";
import { Grid, withStyles, Typography, Button } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { Form } from "react-formik-ui";
import { CircularLoader } from "../../Components/Common/Spinner";
import { getInventoryCategory } from "../../Actions/InventoryCategoryActions";
import { spacecapitalize } from "../../Utils/StringFunctions";
import InputField from "../../Components/Common/Form/InputField";
import UploadField from "../../Components/Common/Form/UploadField";
import DatePickerField from "../../Components/Common/Form/DatePickerField";
import MonthYearPickerField from "../../Components/Common/Form/MonthYearPicker";
import SelectField from "../../Components/Common/Form/SelectField";
import {
  addInventory,
  getSingleInventory,
  editInventory,
} from "../../Actions/InventoryActions";

const styles = () => ({
  lightbg: {
    backgroundColor: "#fff",
    padding: "10px 22px",
  },
  Addbutton: {
    width: "148px",
    height: "46px",
    marginLeft: "-5%",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  formfooter: {
    margin: "30px 15px",
    marginRight: "-3%",
  },
  cancelbutton: {
    width: "148px",
    height: "46px",
    color: "#151039",
    backgroundColor: "#dddddd",
    marginRight: "20px",
    "&:hover": {
      color: "#151039",
      backgroundColor: "#d1d1d1!important",
    },
  },
  form: {
    marginLeft: "4px",
  },
  datePicker: {
    marginTop: "14px",
  },
  objname: {
    marginTop: "31px",
  },
});

const InventorySchema = Yup.object().shape({
  object_name: Yup.string()
    // .matches(/^.[a-zA-Z0-9 ]+$/, "Enter Valid Name")
    .trim()
    .required("This field is required."),
  objectcategory_id: Yup.string().required("This field is required."),
  price: Yup.number()
    .typeError("Please input a number.")
    .required("This field is required."),
  unit: Yup.number()
    .typeError("Please input a number.")
    .required("This field is required."),
  supplier: Yup.string().required("This field is required."),
});

class InventoryForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id:
        this.props.match && this.props.match.params
          ? this.props.match.params.id
          : "",
      deletedKey: [],
    };
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    this.props.getInventoryCategory();
    if (this.state.id) {
      this.props.getSingleInventory(this.state.id);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.inventory_categories !== prevState.inventory_categories) {
      return { inventory_categories: nextProps.inventory_categories };
    }

    if (nextProps.get_single_inventory !== prevState.get_single_inventory) {
      return { get_single_inventory: nextProps.get_single_inventory };
    }
    if (nextProps.errors) {
      return { errors: nextProps.errors };
    }
    return [];
  }

  shouldComponentUpdate() {
    if (this.state.deletedKey.length > 0) {
      return false;
    }
    return true;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading !== this.props.loading) {
      this.props.setLoading(this.props.loading);
      this.setState({
        loading: this.props.loading,
      });
    }
    if (prevProps.inventory_categories !== this.props.inventory_categories) {
      this.setState({
        inventory_categories: this.props.inventory_categories,
      });
    }
  }

  handleClose = () => {
    const path = "/inventories";
    this.props.history.push(path);
  };

  getInitialValues = (id) => {
    const { get_single_inventory } = this.props;
    const { inventory_categories } = this.state;
    if (id && get_single_inventory) {
      return {
        objectcategory_id:
          get_single_inventory.objectcategory_id &&
          this.getAllCategoryOptions(inventory_categories).filter(
            (option) =>
              option.value === get_single_inventory.objectcategory_id._id
          )[0],
        object_name: get_single_inventory.object_name,
        manufacturer: get_single_inventory.manufacturer,
        model_no: get_single_inventory.model_no,
        price: get_single_inventory.price,
        unit: get_single_inventory.unit,
        supplier: get_single_inventory.supplier,
        document: get_single_inventory.document,
        purchase_date: get_single_inventory.purchase_date,
        warranty_period: get_single_inventory.warranty_period,
        expiry_date: get_single_inventory.expiry_date,
      };
    }
    return {
      objectcategory_id: "",
      object_name: "",
      manufacturer: "",
      model_no: "",
      price: "",
      unit: "",
      supplier: "",
      document: "",
      purchase_date: new Date(),
      warranty_period: new Date(),
      expiry_date: new Date(),
    };
  };

  getAllCategoryOptions = (inventorycategories) => {
    return inventorycategories
      ? inventorycategories.map((category) => {
          return {
            value: category._id,
            label: spacecapitalize(category.object_category),
          };
        })
      : [];
  };

  setDelImageUrl = (url) => {
    const { deletedKey } = this.state;
    this.setState({ deletedKey: deletedKey.push(url) });
  };

  render() {
    const { classes, inventory_categories } = this.props;
    const { id, loading } = this.state;
    return (
      <React.Fragment>
        <div className={classes.lightbg}>
          <Typography variant="h6" gutterBottom style={{ marginLeft: "4px" }}>
            {id ? "Update" : "Add"} Inventory
          </Typography>
          <Formik
            initialValues={this.getInitialValues(id)}
            enableReinitialize
            validationSchema={InventorySchema}
            onSubmit={(values, { setErrors, setSubmitting }) => {
              const resourceCategory =
                values && values.objectcategory_id
                  ? values.objectcategory_id.value
                  : "";
              // eslint-disable-next-line no-param-reassign
              values.objectcategory_id = resourceCategory;

              if (!this.state.id) {
                this.props.addInventory(
                  values,
                  setErrors,
                  this.props.history,
                  setSubmitting
                );
              } else {
                this.props.editInventory(
                  this.state.id,
                  values,
                  setErrors,
                  this.props.history,
                  setSubmitting
                );
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              setFieldTouched,
              setFieldValue,
              handleSubmit,
            }) => (
              <Form
                className={classes.form}
                onSubmit={handleSubmit}
                encType="multipart/form-data"
                autoComplete="off"
              >
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6}>
                    <SelectField
                      value={values.objectcategory_id}
                      name="objectcategory_id"
                      id="objectcategory_id"
                      label="Category"
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      error={errors.objectcategory_id}
                      touched={touched.objectcategory_id}
                      options={this.getAllCategoryOptions(inventory_categories)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <InputField
                      name="object_name"
                      label="Name"
                      placeholder="Enter Object Name"
                      className={classes.objname}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <InputField
                      name="manufacturer"
                      label="Manufacturer"
                      placeholder="Enter Manufacturer"
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <InputField
                      name="model_no"
                      label="Code/Model No."
                      placeholder="Enter Code No"
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <InputField
                      name="price"
                      label="Price"
                      placeholder="Enter Price"
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <InputField
                      name="unit"
                      label="No. of Units"
                      placeholder="Enter No. of Units"
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <InputField
                      name="supplier"
                      label="Supplier"
                      placeholder="Enter Supplier"
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <UploadField
                      label="Upload Document"
                      name="document"
                      id="document"
                      fileType="doc"
                      onChange={setFieldValue}
                      value={values.document}
                      folder="inventory/document"
                      delImageUrl={this.setDelImageUrl}
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} className={classes.datePicker}>
                    <DatePickerField
                      name="purchase_date"
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      value={values.purchase_date}
                      label="Purchase Date"
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} className={classes.datePicker}>
                    <MonthYearPickerField
                      label="Warranty Period"
                      name="warranty_period"
                      value={values.warranty_period}
                      onBlur={setFieldTouched}
                      onChange={setFieldValue}
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} className={classes.datePicker}>
                    <DatePickerField
                      name="expiry_date"
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      value={values.expiry_date}
                      disablePast
                      label="Expiry Date"
                    />
                  </Grid>
                </Grid>

                <div className={classes.formfooter}>
                  <Grid
                    container
                    item
                    display="flex"
                    justify="flex-end"
                    alignItems="center"
                    direction="row"
                  >
                    <Grid xs={6} sm={2}>
                      <Button
                        color="grey"
                        onClick={this.handleClose}
                        className={classes.cancelbutton}
                        variant="contained"
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid xs={6} sm={2}>
                      <Button
                        variant="contained"
                        className={classes.Addbutton}
                        color="primary"
                        type="submit"
                      >
                        {id ? "Update" : "Add"}
                      </Button>
                    </Grid>
                  </Grid>
                  {loading && <CircularLoader />}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errors: state.errors,
    loading: state.inventory.loading,
    inventory_categories: state.inventoryCategory.inventory_categories,
    get_single_inventory: state.inventory.get_single_inventory,
    edit_inventory: state.inventory.edit_inventory,
  };
};

export default connect(mapStateToProps, {
  getInventoryCategory,
  addInventory,
  editInventory,
  getSingleInventory,
})(withStyles(styles)(withRouter(InventoryForm)));
