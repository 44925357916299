/* eslint-disable no-return-assign */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { X } from "react-feather";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
  Typography,
} from "@material-ui/core";
import {
  placeOrder,
  getUserMerchandisePoint,
} from "../../Actions/merchandiseActions";
import { spacecapitalize } from "../../Utils/StringFunctions";
import InputField from "../../Components/Common/Form/InputField";

const $quoteSym = " ";

const styles = () => ({
  createIconForm: {
    width: 500,
    padding: "8px",
  },
  formContent: {
    // height: "464px",
  },
  modelHeader: {
    paddingBottom: "5px",
    position: "relative",
    "&:after": {
      top: "48px",
      left: "24px",
      width: "24px",
      borderBottom: "3px solid #fb0",
      content: `'${$quoteSym}'`,
      position: "absolute",
    },
  },
  modalFooter: {
    padding: "40px 0px 8px",
    width: "100%",
  },
  addbutton: {
    color: "#fff",
    width: "100%",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  orderCard: {
    display: "flex",
    margin: "22px 0px",
  },
  productImage: {
    width: "100%",
    borderRadius: "5px",
  },
  productContent: {
    padding: "0px 10px",
  },
  prodName: {
    fontWeight: 500,
    fontSize: "22px",
    color: "#222",
    lineHeight: "18px",
  },
  prodDescription: {
    fontWeight: "normal",
    fontSize: "14px",
    color: "#626262",
    marginTop: "10px",
  },
  prodPrice: {
    fontWeight: 500,
    fontSize: "23px",
    color: "#222",
    marginTop: "10px",
  },
  prodDetail: {
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "24px",
    color: "#222",
  },
  item: {
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "24px",
    color: " #626262",
  },
  prodTotal: {
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "21px",
    color: "#222",
  },
  prodFinalDetail: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  CancelButton: {
    position: "relative",
    top: "-92%",
    right: "-79%",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  orderImage: {
    height: "224px",
  },
});
const addWalkthroughSchema = Yup.object().shape({
  quantity: Yup.string().required("This field is required."),
});

class PlaceOrderDetailModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      Quantity: 1,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.addOrder !== prevProps.addOrder &&
      this.props.addOrder.success
    ) {
      this.props.closeOrderDetailModal();
      this.setState({
        items: [],
      });
    }
  }

  getInitialValues = (detailInserted) => {
    if (detailInserted === "yes") {
      return {
        quantity: "",
      };
    }
    return {
      quantity: "1",
    };
  };

  handleClose = () => {
    this.props.closeOrderDetailModal();
    this.setState({
      items: [],
    });
  };

  productTotalPrice = () => {
    let totalItem = 0;
    this.state.items.map((item) => {
      // eslint-disable-next-line operator-assignment
      return (totalItem = totalItem + item.quantity * item.price);
    });
    // return totalItem;
  };

  onCancelOrder = (element) => {
    const index = this.state.items.indexOf(element);
    if (index > -1) {
      this.state.items.splice(index, 1);
    }
    // this.state.items.splice(id);
  };

  render() {
    const { classes, singleVisitor, data, points } = this.props;
    const { items, Quantity } = this.state;
    function add(selectedItem, Items) {
      const found = selectedItem.some(
        (el) => el.merchandise === Items.merchandise
      );
      if (!found) {
        selectedItem.push(Items);
      }
      return selectedItem;
    }

    return (
      <Dialog
        open={this.props.openOrderDetailModal}
        onClose={this.props.closeOrderDetailModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className={classes.modelHeader}>
          Product Detail
        </DialogTitle>

        <Formik
          initialValues={this.getInitialValues(
            singleVisitor && singleVisitor.details_inserted
          )}
          enableReinitialize
          validationSchema={addWalkthroughSchema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            this.props.placeOrder({ items }, setErrors, setSubmitting);
          }}
          render={() => (
            <Form className={classes.createIconForm}>
              <DialogContent className={classes.formContent}>
                <Grid container spacing={2}>
                  {data &&
                    data.map((element) => {
                      const merchandise = element._id;
                      const quantity = Quantity;
                      const { price } = element;
                      const Items = { merchandise, quantity, price };
                      add(items, Items);
                      return (
                        <Grid key={element.id} className={classes.orderCard}>
                          <Grid
                            item
                            lg={6}
                            xs={6}
                            sm={6}
                            className={classes.orderImage}
                          >
                            <img
                              className={classes.productImage}
                              src={element.image}
                              alt="img"
                              height="200px"
                            />
                            <Button
                              className={classes.CancelButton}
                              onClick={() => this.onCancelOrder(element)}
                            >
                              <X size={24} className={classes.icons} />
                            </Button>
                          </Grid>
                          <Grid
                            item
                            lg={6}
                            xs={6}
                            sm={6}
                            className={classes.productContent}
                          >
                            <Typography
                              component="h4"
                              variant="subtitle1"
                              className={classes.prodName}
                            >
                              {spacecapitalize(element.product_name)}
                            </Typography>
                            <Typography
                              component="p"
                              variant="subtitle2"
                              className={classes.prodDescription}
                            >
                              {spacecapitalize(element.description)}
                            </Typography>{" "}
                            <Typography
                              component="h6"
                              variant="subtitle1"
                              className={classes.prodPrice}
                            >
                              Rs. {element.price}
                            </Typography>
                            {items.map((item) => {
                              return (
                                <div>
                                  {element._id === item.merchandise && (
                                    <InputField
                                      name="quantity"
                                      label="Quantity"
                                      placeholder="Enter Total Seats"
                                      type="number"
                                      max="5"
                                      min="1"
                                      // onChange={(value) => {
                                      //   this.setState({
                                      //     Quantity: item.quantity,
                                      //   });
                                      //   const NewArray = [...items];
                                      //   item.quantity = value;
                                      //   this.setState({
                                      //     items: NewArray,
                                      //   });
                                      // }}
                                    />
                                  )}
                                </div>
                              );
                            })}
                          </Grid>
                        </Grid>
                      );
                    })}
                </Grid>
                <div>
                  <Typography
                    component="h6"
                    variant="subtitle1"
                    className={classes.prodDetail}
                  >
                    Price Detail
                    <span className={classes.item}>( {items.length} Item)</span>
                  </Typography>
                </div>
                <div className={classes.prodFinalDetail}>
                  <div>
                    <Typography
                      variant="subtitle1"
                      className={classes.prodTotal}
                    >
                      Product Total
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      variant="subtitle1"
                      className={classes.prodTotal}
                    >
                      Rs.{this.productTotalPrice()}
                    </Typography>
                  </div>
                </div>

                <div className={classes.prodFinalDetail}>
                  <div>
                    <Typography
                      component="h6"
                      variant="subtitle1"
                      className={classes.prodTotal}
                    >
                      Redeem Points
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      component="h6"
                      variant="subtitle1"
                      className={classes.prodTotal}
                    >
                      {points}
                    </Typography>
                  </div>
                </div>
                <div className={classes.prodFinalDetail}>
                  <div>
                    <Typography
                      component="h6"
                      variant="subtitle1"
                      className={classes.prodTotal}
                    >
                      Order Total
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      component="h6"
                      variant="subtitle1"
                      className={classes.prodTotal}
                    >
                      Rs.
                      {points + this.productTotalPrice()}
                    </Typography>
                  </div>
                </div>

                <Grid className={classes.modalFooter}>
                  <Button
                    color="primary"
                    className={classes.addbutton}
                    type="submit"
                    variant="contained"
                  >
                    Place Order
                  </Button>
                </Grid>
              </DialogContent>
            </Form>
          )}
        />
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errors: state.errors,
    addOrder: state.allMerchandiseOrder.addOrder,
  };
};

export default connect(mapStateToProps, {
  placeOrder,
  getUserMerchandisePoint,
})(withStyles(styles)(PlaceOrderDetailModal));
