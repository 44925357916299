/* eslint-disable no-param-reassign */
import React from "react";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form } from "react-formik-ui";
import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Button,
  Typography,
  InputAdornment,
} from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import InputField from "../../Components/Common/Form/InputField";
import { addCreditTopup, getCreditTopup } from "../../Actions/companiesAction";
import { CircularLoader } from "../../Components/Common/Spinner";

const moment = require("moment");

const styles = () => ({
  noHistory: {
    padding: "24px 24px 11px",
    color: "lightgrey",
  },
  lightbg: {
    padding: "20px 26px",
    marginBottom: "25px",
    background: "#fff",
    // border: "1px solid #EFEFEF",
    marginTop: "40px",
  },
  table: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    color: "red",
  },
  add_button: {
    margin: "38px 0px",
    backgroundColor: "#4682B4",
    color: "#fff",
    float: "right",
    "&:hover": {
      backgroundColor: "#232f3e",
    },
  },
  currentYear: {
    fontSize: "14px",
    color: "#4682B4",
  },
  currentdateSection: {
    width: "100%",
  },
});
const CredittopupSchema = Yup.object().shape({
  credits: Yup.number()
    .typeError("Please input a number.")
    .required("This field is required."),
});
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "hsl(206, 80%, 97%)",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
    textTransform: "capitalize",
  },
}))(TableCell);

class FreelancerCreditTopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const companyId = this.props.companyID;
    this.props.getCreditTopup(companyId);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading !== this.props.loading) {
      // this.props.setLoading(this.props.loading);
      this.setState({
        loading: this.props.loading,
      });
    }
    if (prevProps.add_credit_topup !== this.props.add_credit_topup) {
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        add_credit_topup: this.props.add_credit_topup,
      });
      if (this.props.add_credit_topup.success) {
        this.props.getCreditTopup(this.props.companyID);
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.credit_topup_history !== prevState.credit_topup_history) {
      return {
        credit_topup_history: nextProps.credit_topup_history,
      };
    }
    return [];
  }

  render() {
    const { classes, company } = this.props;
    const { loading } = this.state;
    if (loading) {
      return <CircularLoader />;
    }
    return (
      <React.Fragment>
        <div className={classes.lightbg}>
          <Formik
            initialValues={{
              company_id: this.props.companyID,
              credits: "",
            }}
            enableReinitialize
            validationSchema={CredittopupSchema}
            onSubmit={(values, { setErrors, setSubmitting }) => {
              values.type = "freelancer";
              this.props.addCreditTopup(values, setSubmitting, setErrors);
            }}
          >
            {({ handleSubmit }) => (
              <Form
                className={classes.form}
                onSubmit={handleSubmit}
                encType="multipart/form-data"
                autoComplete="off"
              >
                {company &&
                company.plan_details &&
                company.plan_details.length === 0 ? (
                  ""
                ) : (
                  <Grid
                    container
                    spacing={2}
                    display="flex"
                    alignItems="flex-start"
                  >
                    <Grid item xs={4} sm={3}>
                      <div className={classes.creditTopUpSection}>
                        <InputField
                          name="credits"
                          label="Credits"
                          placeholder="Enter Credits"
                          max={2}
                          className={classes.margin}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.currentdateSection}
                              >
                                <Typography
                                  variant="h6"
                                  className={classes.currentYear}
                                >
                                  {moment(Date()).format("YYYY MMMM")}
                                </Typography>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </Grid>
                    <Button
                      variant="contained"
                      type="submit"
                      className={classes.add_button}
                      // onClick={this.selectClickPlan}
                    >
                      Add
                    </Button>
                  </Grid>
                )}
              </Form>
            )}
          </Formik>
          {this.props.credit_topup_history &&
          this.props.credit_topup_history.length !== 0 ? (
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">No</StyledTableCell>
                  <StyledTableCell align="left">Year</StyledTableCell>
                  <StyledTableCell align="left">Month</StyledTableCell>
                  <StyledTableCell align="left">Credit Top up</StyledTableCell>
                  <StyledTableCell align="left">User</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.credit_topup_history &&
                  this.props.credit_topup_history.length !== 0 &&
                  this.props.credit_topup_history.map((row, key) => (
                    <TableRow>
                      <StyledTableCell>{key + 1} </StyledTableCell>
                      <StyledTableCell>
                        {moment(row.createdAt).format("YYYY")}
                      </StyledTableCell>
                      <StyledTableCell>
                        {moment(row.createdAt).format("MMMM ")}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row.transaction_credits}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row.created_by &&
                          row.created_by.first_name.concat(
                            " ",
                            row.created_by.last_name
                          )}
                      </StyledTableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          ) : (
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item align="center" className={classes.noHistory}>
                <span>No credit Top up Found.</span>
              </Grid>
            </Grid>
          )}
          {loading && <CircularLoader />}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.company.loading,
    add_credit_topup: state.company.add_credit_topup,
    credit_topup_history: state.company.credit_topup_history,
  };
};

export default connect(mapStateToProps, { addCreditTopup, getCreditTopup })(
  withStyles(styles)(withRouter(FreelancerCreditTopup))
);
