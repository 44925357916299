import React, { Component } from "react";
import { Button, Grid } from "@material-ui/core";
import * as Yup from "yup";
import FormLabel from "@material-ui/core/FormLabel";
import { Formik, Form, FieldArray } from "formik";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { connect } from "react-redux";
import { setEditorState } from "../../Utils";
import DatePickerField from "../../Components/Common/Form/DatePickerField";
import {
  createPartneroffers,
  getPartnerOffer,
  updatePartnerOffer,
} from "../../Actions/partneroffersActions";
import { InputField, UploadField } from "../../Components/Common/Form";
import PartnerDropdown from "../../Components/Common/Dropdowns/PartnerDropdown";
import RichEditorExample from "../../Components/Common/RichEditor";

const styles = () => ({
  mainoffer: {
    padding: "30px 18px",
  },
  remove_icon: {
    display: "flex",
    justifyContent: "flex-end",
  },
  formfooter: {
    paddingTop: "13px",
    marginRight: "-1%",
  },
  cancelbutton: {
    width: "148px",
    height: "46px",
    marginRight: "20px",
    backgroundColor: "#dddddd",
    color: "#151039",
    "&:hover": {
      color: "#151039",
      backgroundColor: "#d1d1d1!important",
    },
  },
  Addbutton: {
    width: "148px",
    height: "46px",
    color: "#fff",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  label: {
    paddingLeft: "6px",
  },
  selectPartner: {
    paddingLeft: "15px !important",
  },
  dropinsidetext: {
    marginTop: "30px",
  },
});

const PartnerSchema = Yup.object().shape({
  offers_title: Yup.string()
    .max(15, "")
    .trim()
    .required("This field is required."),
  partner_id: Yup.string().trim().required("This field is required."),
});

class PartnerOffers extends Component {
  constructor(props) {
    super(props);
    this.state = { partners: [], deletedKey: [] };
    this.handleClose = this.handleClose.bind(this);
    this.state.id = this.props.match.params.id;
  }

  componentDidMount() {
    if (this.state.id) {
      this.props.getPartnerOffer(this.state.id);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.partners !== prevState.partners) {
      return { partners: nextProps.partners };
    }
    if (nextProps.partneroffers !== prevState.partneroffers) {
      return { partneroffers: nextProps.partneroffers };
    }
    return [];
  }

  shouldComponentUpdate() {
    if (this.state.deletedKey.length > 0) {
      return false;
    }
    return true;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.partneroffers !== this.props.partneroffers) {
      this.setState({
        partneroffers: this.props.partneroffers,
      });
    }
  }

  getAvailablePartnersOptions = (partners) => {
    return partners
      ? partners.map((partner) => {
          return {
            value: partner._id,
            label: partner.company_name,
          };
        })
      : [];
  };

  handleClose = () => {
    const path = "/partners";
    this.props.history.push(path);
  };

  setDelImageUrl = (url) => {
    const { deletedKey } = this.state;
    this.setState({ deletedKey: deletedKey.push(url) });
  };

  formatDataAndCallAPI = (data, setErrors, setSubmitting) => {
    const values = { ...data };

    values.offer_description = stateToHTML(
      values.editorState.getCurrentContent()
    );

    const partnerValue = values.partner_id ? values.partner_id.value : "";
    values.partner_id = partnerValue;
    const offersTitle = values.offers_title ? values.offers_title : "";
    values.offers_title = offersTitle;
    const offerBanner = values.offer_banner ? values.offer_banner : "";
    values.offer_banner = offerBanner;
    values.valid_upto = values.valid_upto ? values.valid_upto : "";
    delete values.editorState;
    if (!this.state.id) {
      this.props.createPartneroffers(
        values,
        setErrors,
        setSubmitting,
        this.props.history
      );
    } else {
      this.props.updatePartnerOffer(
        this.state.id,
        values,
        setErrors,
        setSubmitting,
        this.props.history
      );
    }
  };

  getInitialValues = (id) => {
    const { partneroffers, partners } = this.props;
    if (id && partneroffers) {
      return {
        partner_id:
          partneroffers.partner_id &&
          this.getAvailablePartnersOptions(partners).filter(
            (option) => option.value === partneroffers.partner_id
          )[0],
        offers_title: partneroffers.offers_title,
        offer_banner: partneroffers.offer_banner,
        // eslint-disable-next-line new-cap
        offer_description: new EditorState.createEmpty(),
        editorState: setEditorState(partneroffers.offer_description),
        valid_upto: partneroffers.valid_upto,
      };
    }
    return {
      partner_id: "",
      offers_title: "",
      // eslint-disable-next-line new-cap
      editorState: new EditorState.createEmpty(),
      // eslint-disable-next-line new-cap
      offer_description: new EditorState.createEmpty(),
      offer_banner: "",
    };
  };

  render() {
    const { classes } = this.props;
    const { id } = this.state;

    return (
      <React.Fragment>
        {/* <ScreenHeader type="Partner Offers" style={{ marginLeft: "5px" }} /> */}
        <Typography variant="h6" gutterBottom style={{ marginLeft: "25px" }}>
          {id ? "Update" : "Add"} Partner Offer
        </Typography>
        <Formik
          initialValues={this.getInitialValues(id)}
          enableReinitialize
          validationSchema={PartnerSchema}
          onSubmit={(values, { setErrors, setSubmitting }) => {
            this.formatDataAndCallAPI(values, setErrors, setSubmitting);
          }}
        >
          {({
            values,
            errors,
            touched,
            setFieldTouched,
            handleBlur,
            setFieldValue,
          }) => (
            <Form>
              <FieldArray
                name="offers"
                render={() => (
                  <div className={classes.mainoffer}>
                    <Grid container spacing={2}>
                      <Grid container item xs={12} sm={12}>
                        <FormLabel className={classes.label} component="legend">
                          Exclusive Offers
                        </FormLabel>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={6}
                        className={classes.selectPartner}
                      >
                        <PartnerDropdown
                          values={values}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          errors={errors}
                          touched={touched}
                          name="partner_id"
                        />
                        {/* <SelectField
                          value={values.partner_id}
                          name="partner_id"
                          label="Select partner"
                          setFieldTouched={setFieldTouched}
                          errors={errors.partner_id}
                          touched={touched.partner_id}
                          onChange={setFieldValue}
                          onBlur={setFieldTouched}
                          options={this.getAvailablePartnersOptions(partners)}
                        /> */}
                      </Grid>

                      <Grid
                        item
                        xs={6}
                        sm={6}
                        className={classes.selectPartner}
                      >
                        <InputField
                          name="offers_title"
                          label="Title"
                          placeholder="Enter Offer Title"
                          className={classes.dropinsidetext}
                          // value={values.offers_title}
                        />
                      </Grid>

                      <Grid container className={classes.remove_icon}>
                        <Grid container item xs={12} sm={12} spacing={2}>
                          <Grid item xs={6} sm={6}>
                            <DatePickerField
                              name="valid_upto"
                              onChange={setFieldValue}
                              onBlur={setFieldTouched}
                              value={values.valid_upto}
                              disablePast
                              label="Valid Upto"
                            />
                          </Grid>
                          <Grid item xs={6} sm={6}>
                            <UploadField
                              label="Upload Offer Banner"
                              name="offer_banner"
                              id="offer_banner"
                              onChange={setFieldValue}
                              value={values.offer_banner}
                              error={errors.offer_banner}
                              folder="partners/offers/banner"
                              delImageUrl={this.setDelImageUrl}
                              // handledeletebanner={this.handledeletebanner}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            style={{ paddingBottom: "0px" }}
                          >
                            <RichEditorExample
                              name="offer_description"
                              editorState={values.editorState}
                              onChange={setFieldValue}
                              onBlur={handleBlur}
                              placeholder="Brief Description"
                              // value={values.offer_description}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                )}
              />
              <div className={classes.formfooter}>
                <Grid
                  container
                  item
                  display="flex"
                  justify="flex-end"
                  alignItems="center"
                  direction="row"
                >
                  <Grid xs={6} sm={2}>
                    <Button
                      color="primary"
                      onClick={this.handleClose}
                      className={classes.cancelbutton}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid xs={6} sm={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      className={classes.Addbutton}
                    >
                      {id ? "Update" : "Add"} Offer
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Form>
          )}
        </Formik>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    partners: state.partners.partners,
    partneroffers: state.partneroffers.partneroffers,
  };
};

export default connect(mapStateToProps, {
  createPartneroffers,
  getPartnerOffer,
  updatePartnerOffer,
})(withStyles(styles)(PartnerOffers));
