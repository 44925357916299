/* eslint-disable camelcase */
import axios from "axios";
import {
  PLAN_ERROR,
  GET_AVAIL_PLANS,
  PLANS_LOADING,
  ADD_PLAN,
  GET_PLANS,
  EDIT_PLAN,
  GET_SINGLE_PLAN,
} from "./types";
import { showSuccess, showError } from "./ActionsHelper";
import { transformErrorsFromApi } from "./TransformError";

// Plans Loading
export const setPlansLoading = () => {
  return {
    type: PLANS_LOADING,
  };
};

export const getAvailablePlans = () => (dispatch) => {
  const space_id = localStorage.getItem("space_id");
  dispatch(setPlansLoading());
  axios
    .get(`/api/plan/available/${space_id}`)
    .then((result) => {
      dispatch({
        type: GET_AVAIL_PLANS,
        payload: result.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: PLAN_ERROR,
        payload: err && err.response ? err.response.data : "",
      });
    });
};

export const getPlans = () => (dispatch) => {
  const space_id = localStorage.getItem("space_id");
  dispatch(setPlansLoading());
  axios
    .get(`/api/plan/${space_id}`)
    .then((result) => {
      dispatch({
        type: GET_PLANS,
        payload: result.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: PLAN_ERROR,
        payload: err && err.response ? err.response.data : "",
      });
    });
};

export const addplan = (userData, setErrors, history, setSubmitting) => (
  dispatch
) => {
  dispatch(setPlansLoading());
  axios
    .post("/api/plan", userData)
    .then((result) => {
      dispatch({
        type: ADD_PLAN,
        payload: result.data,
      });
      history.push("/manage-plans");
      dispatch(showSuccess("Plan "));
      setSubmitting(false);
    })
    .catch((err) => {
      dispatch({ type: PLAN_ERROR, payload: err.data });
      dispatch(showError("There was some error while add plan "));
      setSubmitting(false);
      setErrors(transformErrorsFromApi(err));
    });
};

//GET SINGLE PLAN
export const getSinglePlan = (id) => (dispatch) => {
  dispatch(setPlansLoading());
  axios
    .get(`/api/plan/getsingle/${id}`)
    .then((res) => {
      dispatch({
        type: GET_SINGLE_PLAN,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: PLAN_ERROR,
        payload: err.response && err.response.data,
      })
    );
};

export const editPlan = (plan_id, data, setErrors, setSubmitting) => (
  dispatch
) => {
  dispatch(setPlansLoading());
  axios
    .put(`/api/plan/${plan_id}`, data)
    .then((result) => {
      dispatch({
        type: EDIT_PLAN,
        payload: result.data,
      });
      dispatch(showSuccess("", "Plan edited successfully!"));
      setSubmitting(false);
    })
    .catch((err) => {
      const error = err.response;
      dispatch({ type: PLAN_ERROR, payload: {} });
      setSubmitting(false);
      setErrors(transformErrorsFromApi(error));
    });
};
