import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import {
  Dialog,
  DialogContent,
  IconButton,
  Grid,
  Typography,
  Button,
  Hidden,
  AppBar,
  Toolbar,
} from "@material-ui/core";
import { ChevronsRight } from "react-feather";
// eslint-disable-next-line import/no-extraneous-dependencies
import clsx from "clsx";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import DevxLogo from "../../Assets/Whitelogo.png";
import {
  getEventFeedbackProcess,
  getEventFeedbackQuestions,
  eventFeedbackAnswer,
} from "../../Actions/EventFeedbackActions";
import FeedbackRating from "../../Assets/feedback_rating.png";
import ArrangementPopup from "./ArrangementPopup";
import CommunityTeamPopup from "./CommunityTeamPopup";
import OverallSatisfactionPopup from "./OverallSatisfactionPopup";
import ThankyouPopup from "./ThankyouPopup";
import { spacecapitalize } from "../../Utils/StringFunctions";

const styles = (theme) => ({
  thankyou_modal: {
    width: 450,
    // height: 550,
    padding: "35px 0px 0px",
    overflow: "auto",
    marginTop: "-30px",
  },

  mainTitle: {
    fontWeight: "600",
    fontSize: "40px",
    textAlign: "center",
    color: "#000000",
    margin: "0px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
  },
  subText: {
    margin: "10px 0px 0px",
    fontSize: "22px",
    color: "#000000",
    textAlign: "center",
    fontWeight: "400",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
  },
  descText: {
    fontSize: "13px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
  },
  mobileLayout: {
    [theme.breakpoints.down("xs")]: {
      background: "#ffffff",
      padding: "30px 0px 0px",
    },
  },
  layout: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 45px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 45px",
    },
  },
  feedbackRating: {
    width: "100%",
    paddingTop: "8%",
  },
  root: {
    padding: "0px !important",
    backgroundColor: "rgba(0,0,0,0)",
  },
  subTextLayout: {
    padding: "10px 30px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
    },
  },
  button: {
    marginTop: "15px",
    background: "#211b4e !important",
    padding: "5px 20px",
    textTransform: "capitalize",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "left",
      background: "#ffffff !important",
      color: "blue",
      padding: "0px",
      cursor: "pointer",
    },
  },
  devxlogo: {
    height: "40%",
    width: "40%",
    display: "block",
    color: theme.palette.primary.main,
    marginLeft: "3.2%",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#211b4e",
    color: theme.palette.primary.main,
  },
  appBarShift: {
    width: "100%",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    position: "relative",
    overflow: "hidden",
  },
  paper: {
    top: "5% !important",
  },
});

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      // Name of the component ⚛️ / style sheet
      contained: {
        boxShadow: "none",
      },
    },
  },
});

class FeedbackPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      feedbackType: "helpus",
      answerSheetData: "",
      callApi: false,
    };
  }

  componentDidMount() {
    // this.props.getEventFeedbackProcess();
    this.props.getEventFeedbackQuestions();
    // this.props.eventFeedbackAnswer();
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  answerSheetData = (answerSheet) => {
    const answerArray = [];
    answerArray.push(answerSheet);
    this.setState({ answerSheetData: answerSheet });
    this.callAnswerApi();
    // return answerArray;
  };

  callAnswerApi = () => {
    const { callApi, answerSheetData } = this.state;
    if (callApi) {
      this.props.eventFeedbackAnswer(answerSheetData);
    }
  };

  GetParameterValues = (param) => {
    const url = window.location.href
      .slice(window.location.href.indexOf("?") + 1)
      .split("&");
    for (let i = 0; i < url.length; i++) {
      const urlparam = url[i].split("=");
      if (urlparam[0] === param) {
        return spacecapitalize(decodeURI(urlparam[1]));
      }
    }
    return null;
  };

  openModal = (type) => {
    this.setState({ feedbackType: type });
    if (type === "thankyou") {
      this.setState({ callApi: true }, () =>
        this.props.eventFeedbackAnswer({
          answersheet: this.state.answerSheetData,
          token: window.location.pathname
            .split("/")
            .slice(-1)[0]
            .replace('"', ""),
        })
      );
    }
  };

  renderBodyHelpus = () => {
    const { classes } = this.props;
    return (
      <Grid>
        <Grid container className={classes.layout}>
          <Grid item xs={12} sm={12} md={12}>
            <Hidden only={["xs", "sm"]}>
              <Typography
                align="center"
                variant="h1"
                className={classes.mainTitle}
              >
                Hi, {this.GetParameterValues("name")}
              </Typography>
            </Hidden>
            <Hidden only={["lg", "md"]}>
              <Typography
                align="center"
                variant="h1"
                className={classes.mainTitle}
              >
                <div>Hi,</div>
                <div> {this.GetParameterValues("name")}</div>
              </Typography>
            </Hidden>
          </Grid>
          <Grid item xs={12} sm={12} md={12} className={classes.subTextLayout}>
            <div className={classes.subText}>
              <Typography align="center" variant="p">
                Help us to improve.
              </Typography>
            </div>
            <div className={classes.descText}>
              <Typography align="center" variant="p">
                Feedback shall take 3 to 5 minutes which will help us to know
                where we can improve to serve you better in future.
              </Typography>
            </div>

            <Button
              className={classes.button}
              type="button"
              variant="contained"
              color="primary"
              size="small"
              onClick={() => this.openModal("arrangement")}
            >
              Let&apos;s Go
              <ChevronsRight
                size={15}
                style={{ marginLeft: "5px" }}
              ></ChevronsRight>
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <img
            alt="logo"
            src={FeedbackRating}
            className={classes.feedbackRating}
          />
        </Grid>
      </Grid>
    );
  };

  processQuestions = (id) => {
    const { eventFeedbackQuestions } = this.props;

    const feedbackQuestionsArray =
      eventFeedbackQuestions &&
      Object.keys(eventFeedbackQuestions).map((key) => {
        return eventFeedbackQuestions[key];
      });

    return (
      feedbackQuestionsArray &&
      feedbackQuestionsArray.find((obj) => {
        return obj[0] && obj[0].process_id && obj[0].process_id._id === id;
      })
    );
  };

  displayContent = () => {
    const { classes, eventFeedbackQuestions } = this.props;
    const { feedbackType } = this.state;

    const feedbackQuestionsArray =
      eventFeedbackQuestions &&
      Object.keys(eventFeedbackQuestions).map((key) => {
        return eventFeedbackQuestions[key];
      });

    // const arrangementProcessId =
    //   eventFeedbackProcess &&
    //   eventFeedbackProcess.find((obj) => {
    //     if (obj.process_name === "Arrangement") {
    //       return obj._id;
    //     }
    //     return null;
    //   });
    const arrangementId = Object.keys(eventFeedbackQuestions)[0];

    // const communityProcessId =
    //   eventFeedbackProcess &&
    //   eventFeedbackProcess.find((obj) => {
    //     if (obj.process_name === "Community Team Support") {
    //       return obj._id;
    //     }
    //     return null;
    //   });
    const communityId = Object.keys(eventFeedbackQuestions)[1];

    // const overallProcessId =
    //   eventFeedbackProcess &&
    //   eventFeedbackProcess.find((obj) => {
    //     if (obj.process_name === "Overall Satisfaction") {
    //       return obj._id;
    //     }
    //     return null;
    //   });
    const overallId = Object.keys(eventFeedbackQuestions)[2];
    return (
      <Grid className={classes.mobileLayout}>
        {feedbackType === "arrangement" && (
          <ArrangementPopup
            openModal={this.openModal}
            processId={arrangementId}
            questionsData={feedbackQuestionsArray[0]}
            answerData={this.answerSheetData}
          ></ArrangementPopup>
        )}
        {feedbackType === "community" && (
          <CommunityTeamPopup
            openCommunityModal={this.openModal}
            processId={communityId}
            questionsData={feedbackQuestionsArray[1]}
            answerArray={this.state.answerSheetData}
            answerData={this.answerSheetData}
          ></CommunityTeamPopup>
        )}
        {feedbackType === "overall" && (
          <OverallSatisfactionPopup
            openOverallModal={this.openModal}
            processId={overallId}
            questionsData={feedbackQuestionsArray[2]}
            answerArray={this.state.answerSheetData}
            answerData={this.answerSheetData}
          ></OverallSatisfactionPopup>
        )}
        {feedbackType === "thankyou" && <ThankyouPopup></ThankyouPopup>}

        {feedbackType === "helpus" && this.renderBodyHelpus()}
      </Grid>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <Hidden only={["xs"]}>
          <Dialog
            open={this.props.modalOpen}
            aria-labelledby="feedback-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.root}
            overlayStyle={{ backgroundColor: "transparent" }}
            BackdropProps={{
              classes: {
                root: classes.root,
              },
            }}
            PaperProps={{
              classes: {
                root: classes.paper,
              },
            }}
          >
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={this.props.handleClose}
            ></IconButton>

            <DialogContent className={classes.thankyou_modal}>
              {this.displayContent()}
            </DialogContent>
          </Dialog>
        </Hidden>
        <Hidden only={["lg", "md"]}>
          <Grid>
            <AppBar
              position="fixed"
              className={clsx(classes.appBar, classes.appBarShift)}
            >
              <Toolbar className={classes.toolbar}>
                <Typography
                  component="h1"
                  variant="h6"
                  noWrap
                  className={classes.title}
                >
                  <div className={classes.ImageAlign}>
                    <img src={DevxLogo} alt="" className={classes.devxlogo} />
                  </div>
                </Typography>
              </Toolbar>
            </AppBar>
            {this.displayContent()}
          </Grid>
        </Hidden>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errors: state.errors,
    eventFeedbackProcess: state.eventFeedback.eventFeedback,
    loading: state.eventFeedback.loading,
    eventFeedbackQuestions: state.eventFeedback.eventFeedbackQuestions,
  };
};

export default connect(mapStateToProps, {
  getEventFeedbackProcess,
  getEventFeedbackQuestions,
  eventFeedbackAnswer,
})(withStyles(styles)(FeedbackPopup));
