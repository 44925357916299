/* eslint-disable camelcase */
import React from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { Typography, Grid } from "@material-ui/core";
import classNames from "classnames";

const styles = () => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "12px",
  },
  mainTitle: {
    fontWeight: "600",
    fontSize: "20px",
    textAlign: "left",
    color: "#222222",
    lineHeight: "25px",
  },
  heading: {
    fontSize: "16px",
    color: "#222222",
    fontWeight: "500",
    margin: "4px 0px",
    marginBottom: "15px",
  },
  title: {
    fontSize: "13px",
    color: "#222222",
    fontWeight: "500",
    padding: "10px",
  },
  subTitle: {
    fontSize: "15px",
    color: "#222222",
    fontWeight: "500",
  },
  phone: {
    color: "#1592E6",
    fontWeight: "500",
  },
  description: {
    color: "#222222",
    fontSize: "13px",
    lineHeight: "18px",
  },
  generalDetails: {
    padding: "3% 0% 0%",
  },
  cardLayout: {
    border: "1px solid #EFEFEF",
    borderRadius: "5px",
    padding: "10px",
  },
  padding: {
    padding: "10px",
  },
});

function CorporateInnovation(props) {
  const { classes } = props;

  return (
    <div className={classes.main}>
      <div className={classes.header}>
        <Typography align="left" variant="h1" className={classes.mainTitle}>
          Corporate Innovation
        </Typography>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Typography align="left" className={classes.description}>
            The buzz word that has caught the attention of Corporates,
            Investors, Government and other stakeholders; Innovation. Many large
            corporations are stuck and tend to have a longer turnaround time
            when responding to the change. At DevX, we help such corporation
            adapt to the changing needs by understanding their vision and the
            current market condition for them as well as the industry. Through
            open innovation program, we infuse new energy in the giant
            corporations to mitigate the risk of competition eating away their
            business.
          </Typography>
          <Grid container spacing={2} className={classes.generalDetails}>
            <Grid item xs={12}>
              <Typography align="left" className={classes.subTitle}>
                Let&apos;s dig deep into the services we offer;
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  Open Innovation <br />
                  through Hackathons
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  Organizing multi-city startup pitch and finding the best
                  innovation in the industry.
                  <br />
                  <br />
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  Hunting startups for a personalized Corporate Accelerator
                  Program to fast pace the change.
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  Setting up innovation and makers labSetting up innovation and
                  makers lab
                  <br />
                  <br />
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2} className={classes.generalDetails}>
            <Typography
              align="left"
              className={classNames(classes.mainTitle, classes.padding)}
            >
              Why us?
            </Typography>
            <Grid item xs={12}>
              <Typography align="left" className={classes.description}>
                An experienced and dedicated team of entrepreneurs helping you
                find the right match.
                <br />
                <br />
                Understanding the client requirement and suggesting the best
                route to achieve the goals.
                <br />
                <br />
                Established ecosystem connects for rapid implementation of the
                program.
                <br />
                <br />
                International connects to invite stakeholders from abroad for
                the multi-nation program.
                <br />
                <br />
                Designing and executing the accelerator program with last-mile
                support into corporate implementation.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.spaces.loading,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withStyles(styles)(CorporateInnovation));
