import React from "react";
import { Typography, Paper, Button } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
// import PropTypes from "prop-types";
import { Formik } from "formik";
import { Form } from "react-formik-ui";

import InputField from "../../Components/Common/Form/InputField";
import RadioField from "../../Components/Common/Form/RadioField";
import CheckboxField from "../../Components/Common/Form/CheckboxField";
import SelectField from "../../Components/Common/Form/SelectField";
import DatePickerField from "../../Components/Common/Form/DatePickerField";
import UploadField from "../../Components/Common/Form/UploadField";
import TimePickerField from "../../Components/Common/Form/TimePickerField";

const radioOptions = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
];

const checkBoxOptions = [
  { label: "Morning", value: "morning" },
  { label: "Evenig", value: "evenig" },
];

const selectOptions = [
  { value: "Food", label: "Food" },
  { value: "Being Fabulous", label: "Being Fabulous" },
  { value: "Ken Wheeler", label: "Ken Wheeler" },
  { value: "ReasonML", label: "ReasonML" },
  { value: "Unicorns", label: "Unicorns" },
  { value: "Kittens", label: "Kittens" },
];

const styles = (theme) => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 700,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
});

// eslint-disable-next-line react/prefer-stateless-function
class ComponentDemo extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div>
        <main className={classes.layout}>
          <Typography variant="h4" className={classes.title}>
            New Form
          </Typography>
          <Paper className={classes.paper}>
            <div>
              <Formik
                initialValues={{
                  name: "",
                  gender: "",
                  availibility: [],
                  selectoptions: [],
                  date: new Date(),
                  file: "",
                  start_time: new Date(),
                }}
                enableReinitialize
                onSubmit={() => {}}
              >
                {({
                  values,
                  setFieldValue,
                  setFieldTouched,
                  errors,
                  touched,
                }) => (
                  <Form>
                    <InputField name="name" label="Name" />

                    <RadioField
                      name="gender"
                      label="Gender"
                      options={radioOptions}
                    />

                    <CheckboxField
                      name="availibility"
                      label="Availibity"
                      options={checkBoxOptions}
                    />

                    <SelectField
                      value={values.selectoptions}
                      name="selectoptions"
                      id="selectoptions"
                      label="Select one or more value"
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      multi
                      error={errors.topics}
                      touched={touched.topics}
                      options={selectOptions}
                    />

                    <DatePickerField
                      name="date"
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      value={values.date}
                      disableFuture
                      label="Birth Date"
                    />
                    <TimePickerField
                      name="start_time"
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      value={values.start_time}
                      label="start time"
                    />
                    <UploadField
                      label="Upload file"
                      name="file"
                      id="file"
                      onChange={setFieldValue}
                      value={values}
                    />

                    <Button type="submit" variant="contained">
                      Submit
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          </Paper>
        </main>
      </div>
    );
  }
}

ComponentDemo.propTypes = {
  // classes: PropTypes.object.isRequired,
};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, {})(withStyles(styles)(ComponentDemo));
