/* eslint-disable no-param-reassign */
import React from "react";
import { Grid, Typography, withStyles, Button } from "@material-ui/core";
import { EditorState } from "draft-js";
import { Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import moment from "moment";
import { stateToHTML } from "draft-js-export-html";
import InputField from "../../Components/Common/Form/InputField";
import {
  SelectField,
  DatePickerField,
  TimePickerField,
} from "../../Components/Common/Form";
import { addvisitor } from "../../Actions/visitorActions";
import { CircularLoader } from "../../Components/Common/Spinner";
import { getUnbookedResources } from "../../Actions/resourceActions";
import { registerBooking } from "../../Actions/bookingActions";
import { checkPermission } from "../../Utils/checkPermission";

import "../company.css";

const styles = () => ({
  lightbg: {
    // padding: “24px”,
    backgroundColor: "#fff",
    padding: "10px 22px",
  },
  label: {
    margin: "15px 0px 8px 0px",
    fontSize: "12px",
    fontWeight: "400",
    color: "rgba(0, 0, 0, 0.54)",
  },
  button: {
    width: "148px",
    height: "46px",
    backgroundColor: "#dddddd",
    color: "#151039",
    "&:hover": {
      color: "#151039",
      backgroundColor: "#d1d1d1!important",
    },
  },
  Addbutton: {
    width: "148px",
    height: "46px",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  visitor_date: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
  },
  visitor_duration: {
    display: "flex",
    marginTop: "-5px",
  },
  formfooter: {
    margin: "24px 15px",
    marginRight: "-2%",
  },
  subtitle: {
    fontSize: "18px",
  },
  meeting_details: {
    width: "100%",
    padding: "14px",
  },
  coworker_visitor: {
    padding: "14px",
  },
  durationHour: {
    marginRight: "18px",
  },
  form: {
    marginLeft: "4px",
  },
  bookingDate: {
    marginBottom: "7px",
  },
  dropinsidetext: {
    marginTop: "30px",
  },
});
const HourOptions = [
  { value: 0, label: 0 },
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
  { value: 6, label: 6 },
  { value: 7, label: 7 },
  { value: 8, label: 8 },
  { value: 9, label: 9 },
  { value: 10, label: 10 },
  { value: 11, label: 11 },
  { value: 12, label: 12 },
];
const AllMinuteOptions = [
  { value: 0, label: 0 },
  { value: 1, label: 30 },
];
const MinuteOptions = [{ value: 1, label: 30 }];

const PurposOptions = [
  { value: "official", label: "Official" },
  { value: "meeting", label: "Meeting" },
  { value: "interview", label: "Interview" },
  // { value: "space_inquiry", label: "Space Inquiry" },
  { value: "accelerator_inquiry", label: "Accelerator Inquiry" },
  { value: "vendor", label: "Vendor" },
];
const phoneRegExp = /[1-9]{1}[0-9]{9}/;

const VisitorSchema = Yup.object().shape({
  visitor_name: Yup.string()
    .matches(/^.[a-zA-Z0-9 ]+$/, "Enter Valid Name")
    .max(15, "")
    .trim()
    .required("This field is required."),
  visitor_company_name: Yup.string()
    .max(15, "")
    .trim()
    .required("This field is required."),
  hour: Yup.string().required("This field is required."),
  minute: Yup.string().required("This field is required."),
  email: Yup.string()
    .required()
    .email("Enter valid email id")
    .required("This field is required."),
  contact: Yup.string()
    .matches(phoneRegExp, "Please enter valid number.")
    .required("This field is required."),
  time: Yup.string().required("This field is required."),
});

class VisitorForm extends React.Component {
  state = {
    // eslint-disable-next-line react/no-unused-state
    resources: [],
    resourceOptions: [],
    isResourcesVisible: false,
    values: {},
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.unbooked !== prevState.unbooked) {
      const resourceOptions =
        (nextProps.unbooked &&
          nextProps.unbooked.map((resource) => {
            return {
              label: `${resource.resource_name} || ${resource.pax} seats`,
              value: resource._id,
            };
          })) ||
        [];
      return { unbooked: nextProps.unbooked, resourceOptions };
    }
    return [];
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visitor !== this.props.visitor) {
      if (
        this.props.visitor.success &&
        !checkPermission("COWORKER_ADMIN_VIEW")
      ) {
        this.addBooking(this.state.values);
      }
    }
  }

  callResourcesAPi = (values) => {
    if (
      values.date &&
      !(
        Number(values.hour && values.hour.value) === 0 &&
        Number(values.minute && values.minute.label) === 0
      ) &&
      checkPermission("RESOURCE_VIEW")
    ) {
      const data = {};
      // TODO : this is not fixed thing, please make sure to change it.
      // use moment to convert it to date object
      const startdatetime = `${moment(values.date).format(
        "YYYY-MM-DD"
      )} ${moment(values.time).format("HH:mm")}:00`;
      data.booking_date = moment(startdatetime).format();

      data.duration_in_minutes =
        Number(values.hour && values.hour.value) * 60 +
        Number(values.minute && values.minute.label);
      this.props.getUnbookedResources(data);

      this.setState({ isResourcesVisible: true });
    } else {
      this.setState({ isResourcesVisible: false });
    }
  };

  addBooking = (values, setErrors, setSubmitting) => {
    const bookingData = {};
    const resourceValue = values.resources && values.resources.value;
    // bookingData.resources = resourceValue;
    bookingData.resource_id = resourceValue;
    const bdate = `${moment(values.date).format("YYYY-MM-DD")} ${moment(
      values.time
    ).format("HH:mm:ss")}`;
    bookingData.booking_date = moment(bdate).utc().format();
    bookingData.time = values.time;

    bookingData.duration_in_minutes =
      Number(values.hour && values.hour.label) * 60 +
      Number(values.minute && values.minute.label);
    if (checkPermission("SECURITY_SPECIAL_VIEW")) {
      const companyVal = values.company_id ? bookingData.company_id.value : "";
      bookingData.company_id = companyVal;
    } else {
      bookingData.company_id = localStorage.getItem("company_id");
    }

    if (resourceValue) {
      this.props.registerBooking(
        bookingData,
        setErrors,
        setSubmitting,
        this.props.history
      );
    }
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  renderOtherGrid = (
    classes,
    values,
    setFieldValue,
    setFieldTouched,
    errors,
    touched
  ) => {
    const { resourceOptions, isResourcesVisible } = this.state;
    return (
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6}>
          <InputField
            name="visitor_name"
            label="Name"
            placeholder="Enter Full Name"
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <InputField
            name="visitor_company_name"
            label="Company"
            placeholder="Enter Company Name"
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <InputField
            name="contact"
            label="Contact"
            placeholder="91XXXXXXXX"
            type="tel"
            max="10"
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <InputField
            name="email"
            label="Email"
            placeholder="johndoe@mail.com"
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          {/* <FormLabel component="legend" className={classes.label}>
            Date
          </FormLabel> */}
          <div className={classes.visitor_date}>
            <Grid item xs={12} sm={12} className={classes.durationHour}>
              <DatePickerField
                name="date"
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                value={values.date}
                disableFuture={false}
                disablePast
                label="Enter Date"
                className={classes.dropinsidetext}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <TimePickerField
                name="time"
                ampm={false}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                value={values.time}
                label="Enter Time"
                className={classes.dropinsidetext}
              />
            </Grid>
          </div>
        </Grid>
        <Grid item xs={6} sm={6}>
          <div className={classes.duration} style={{ marginTop: "15px" }}>
            {/* <FormLabel className={classes.label} component="legend">
              Duration
            </FormLabel> */}
            <div className={classes.visitor_duration}>
              <Grid item xs={6} sm={6} className={classes.durationHour}>
                <SelectField
                  value={values.hour}
                  name="hour"
                  id="hour"
                  label="Hour"
                  onChange={(name, value) => {
                    setFieldValue(name, value);
                    this.callResourcesAPi({ ...values, hour: value });
                  }}
                  onBlur={setFieldTouched}
                  multi={false}
                  error={errors.hour}
                  options={HourOptions}
                />
              </Grid>
              {values.hour.value === 0 ? (
                <Grid item xs={6} sm={6}>
                  <SelectField
                    value={values.minute}
                    name="minute"
                    id="minute"
                    label="Minute"
                    onChange={(name, value) => {
                      this.callResourcesAPi({
                        ...values,
                        minute: value,
                      });
                      setFieldValue(name, value);
                    }}
                    onBlur={setFieldTouched}
                    multi={false}
                    error={errors.minute}
                    options={MinuteOptions}
                  />
                </Grid>
              ) : (
                <Grid item xs={6} sm={6}>
                  <SelectField
                    value={values.minute}
                    name="minute"
                    id="minute"
                    label="Minute"
                    onChange={(name, value) => {
                      this.callResourcesAPi({
                        ...values,
                        minute: value,
                      });
                      setFieldValue(name, value);
                    }}
                    onBlur={setFieldTouched}
                    multi={false}
                    error={errors.minute}
                    options={AllMinuteOptions}
                  />
                </Grid>
              )}
            </div>
          </div>
        </Grid>
        <Grid item xs={6} sm={6}>
          <SelectField
            value={values.purpose}
            name="purpose"
            id="purpose"
            label="Purpose"
            onChange={setFieldValue}
            onBlur={setFieldTouched}
            multi={false}
            error={errors.purpose}
            touched={touched.purpose}
            options={PurposOptions}
          />
        </Grid>
        {!checkPermission("COWORKER_ADMIN_VIEW") && (
          <Grid item xs={6} sm={6}>
            <SelectField
              value={values.resources}
              name="resources"
              id="resources"
              label="Resources"
              onChange={setFieldValue}
              onBlur={setFieldTouched}
              multi={false}
              error={errors.resources}
              touched={touched.resources}
              options={resourceOptions}
              placeholder="Please select date and duration to load availlable resources."
              disabled={!isResourcesVisible}
            />
          </Grid>
        )}
      </Grid>
    );
  };

  render() {
    const { classes } = this.props;
    const { loading } = this.state;
    return (
      <React.Fragment>
        <div className={classes.lightbg}>
          <Typography variant="h6" gutterBottom style={{ marginLeft: "4px" }}>
            Add New Visitor
          </Typography>
          <Formik
            initialValues={{
              visitor_name: "",
              visitor_company_name: "",
              contact: "",
              email: "",
              name_of_person_meeting: "",
              purpose: "",
              security_number: "",
              type_of_invitation: "",
              checkin: new Date(),
              date: new Date(),
              time: new Date(),
              status: "inpremise",
              hour: "",
              minute: "",
              // eslint-disable-next-line new-cap
              editorState: new EditorState.createEmpty(),
            }}
            validationSchema={VisitorSchema}
            onSubmit={(values, { setErrors, setSubmitting }) => {
              this.setState({ values });
              const bookingData = {};
              console.log("values :", moment(values.date).format("DD/MM/YYYY"));
              const submitValues = { ...values };

              const hourVal = values.hour && values.hour.value;
              const minuteVal = values.minute && values.minute.label;
              submitValues.my_details_duration_in_mins =
                Number(hourVal) * 60 + Number(minuteVal);
              bookingData.booking_date = values.date;
              const resourceValue = values.resources
                ? values.resources.value
                : "";
              // eslint-disable-next-line no-param-reassign
              bookingData.resources = resourceValue;
              bookingData.resource_id = resourceValue;
              bookingData.time = values.time;
              bookingData.hour = values.hour;
              bookingData.minute = values.minute;
              bookingData.member = [];
              bookingData.duration_in_minutes =
                Number(values.hour && values.hour) * 60 +
                Number(values.minute && values.minute);

              bookingData.company_id = localStorage.getItem("company_id");

              if (checkPermission("SECURITY_SPECIAL_VIEW")) {
                const companyVal = values.company_id
                  ? values.company_id.value
                  : "";
                submitValues.company_id = companyVal;
              } else {
                submitValues.company_id = localStorage.getItem("company_id");
              }
              if (checkPermission("COWORKER_ADMIN_VIEW")) {
                delete submitValues.company_id;
              }
              const valPurpose = values.purpose ? values.purpose.value : "";
              submitValues.purpose = valPurpose;

              submitValues.type_of_invitation = checkPermission(
                "SECURITY_SPECIAL_VIEW"
              )
                ? (values.type_of_invitation = "walkin")
                : (values.type_of_invitation = "bycoworker");

              submitValues.comments = stateToHTML(
                values.editorState.getCurrentContent()
              );
              submitValues.date = moment(values.date).format();

              delete submitValues.editorState;
              console.log("data is : ", submitValues.date);
              this.props.addvisitor(
                submitValues,
                setErrors,
                setSubmitting,
                this.props.history
              );
              // this.props.registerBooking(
              //   bookingData,
              //   setErrors,
              //   setSubmitting,
              //   this.props.history,
              //   false
              // );
            }}
          >
            {({
              values,
              errors,
              touched,
              setFieldTouched,
              handleBlur,
              setFieldValue,
              handleSubmit,
            }) => (
              <form
                onSubmit={handleSubmit}
                className={classes.form}
                encType="multipart/form-data"
                autoComplete="off"
              >
                {this.renderOtherGrid(
                  classes,
                  values,
                  setFieldValue,
                  setFieldTouched,
                  errors,
                  touched,
                  handleBlur
                )}
                <div className={classes.formfooter}>
                  <Grid
                    container
                    item
                    display="flex"
                    justify="flex-end"
                    alignItems="center"
                    direction="row"
                  >
                    <Grid xs={6} sm={2}>
                      <Button
                        color="primary"
                        onClick={() => this.props.history.push("/visitors")}
                        className={classes.button}
                        style={{ right: "-5px" }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid xs={6} sm={2}>
                      <Button
                        className={classes.Addbutton}
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        Add
                      </Button>
                    </Grid>
                  </Grid>
                  {loading && <CircularLoader />}
                </div>
              </form>
            )}
          </Formik>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    visitor: state.visitors.visitor,
    errors: state.errors,
    unbooked: state.resources.unbooked,
  };
};

export default connect(mapStateToProps, {
  addvisitor,
  getUnbookedResources,
  registerBooking,
})(withStyles(styles)(VisitorForm));
