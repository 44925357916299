import React from "react";

const PartnerSvg = () => {
  return (

    <svg xmlns="http://www.w3.org/2000/svg" xmlnsxlink="http://www.w3.org/1999/xlink" width="430" height="400" viewBox="90 -30 500 500" >
  <defs>
    <clipPath id="clip-Add_Partners">
      <rect width="500" height="500"/>
    </clipPath>
  </defs>
  <g id="Add_Partners" data-name="Add Partners" clipPath="url(#clip-Add_Partners)">
    <rect width="600" height="400" fill="#fff"/>
    <g id="Group_815" data-name="Group 815">
      <g id="Group_752" data-name="Group 752">
        <ellipse id="Ellipse_106" data-name="Ellipse 106" cx="173.4" cy="13.283" rx="173.4" ry="13.283" transform="translate(77.07 370.384)" fill="#d1e1e8"/>
        <path id="Path_1" data-name="Path 1" d="M400.918,198.742c-8.924-33.974-23.395-68.951-52.378-88.8-38.854-26.6-90.88-18.333-136.94-8.539-27.185,5.781-54.821,11.9-78.949,25.7S88.017,163.781,84,191.281c-.776,5.31-.88,10.968,1.5,15.776,1.7,3.438,4.545,6.169,6.629,9.39,9.433,14.576,1.353,33.581.467,50.92-1.161,22.722,12.053,45.552,32.333,55.863,15.859,8.064,34.359,8.59,52.15,8.675a1733.015,1733.015,0,0,0,175.6-8.084,301.108,301.108,0,0,0,35.49-39.03c5.84-7.681,11.369-15.755,14.689-24.814C410,240.492,406.19,218.814,400.918,198.742Z" transform="translate(-6.502 11.974)" fill="#f5f5f5"/>
        <path id="Path_2" data-name="Path 2" d="M850.219,744.7q-7.508.794-15.022,1.521-3.027,2.793-6.127,5.5Z" transform="translate(-489.022 -410.426)" fill="#f5f5f5"/>
        <path id="Path_1645" data-name="Path 1645" d="M1161.358,1134.45s17.857.337,18.2,1.924c0,0,.2.563-1.231.495a51.976,51.976,0,0,0-6.592.107,9.365,9.365,0,0,0,.339,1.582,1.989,1.989,0,0,1-.434,2.141c-.566.4-2.829,1.8-2.829,1.8a1.157,1.157,0,0,1-.664-.009,11.277,11.277,0,0,1-1.856-1.349,3.706,3.706,0,0,1-1.377-1.462,3.185,3.185,0,0,0-2.943-1.551Z" transform="translate(-913.097 -897.584)" fill="#f7987f"/>
        <g id="Group_721" data-name="Group 721" transform="translate(130.808 298.448)">
          <path id="Path_1646" data-name="Path 1646" d="M503.58,1947.737s.65-.235.615-.791-2.085-5.44-2.085-5.44l-5.637-7.282s-1.8-.006-3.383,8.716l.443.209,1.9-3.471S495.734,1945.182,503.58,1947.737Z" transform="translate(-493.09 -1855.239)" fill="#0c2e66"/>
          <path id="Path_1647" data-name="Path 1647" d="M529.85,1505.235c-7.3,6.61-11.93,31.877-16.852,48.855-1.665,5.744-2.545,10.078-2.545,10.078.45,5.512,3.153.587,3.153.587,8.137-16.321,25.53-57.174,25.53-57.174s1.8-2.882,2.358-8.84c.462-4.907-10.668-13.911-10.668-13.911Z" transform="translate(-507.401 -1484.829)" fill="#f7987f"/>
          <path id="Path_1648" data-name="Path 1648" d="M513.93,1929.633s2.492,7.007,2.865,8.356c.239.863-5.541-1.987-6.342-8.276C510.453,1929.713,513.381,1926.634,513.93,1929.633Z" transform="translate(-507.401 -1850.373)" fill="#f7987f"/>
        </g>
        <g id="Group_722" data-name="Group 722" transform="translate(181.882 318.831)">
          <path id="Path_1649" data-name="Path 1649" d="M871.087,1966.674s.445-.528.137-.992-4.527-3.667-4.527-3.667l-8.524-3.485s-1.565.9,1.432,9.241l.487-.042-.091-3.955S863.015,1968.388,871.087,1966.674Z" transform="translate(-844.744 -1895.657)" fill="#0c2e66"/>
          <path id="Path_1650" data-name="Path 1650" d="M793.524,1600.8s5.38,44.075,6.5,62.277c0,0,.124,5.617-3.024,1.07,0,0-1.406-4.192-2.838-10-4.234-17.164-12.872-41.354-9.853-50.732Z" transform="translate(-783.683 -1600.804)" fill="#f7987f"/>
          <path id="Path_1651" data-name="Path 1651" d="M862.43,1947.611s5.664,4.82,6.661,5.8c.639.628-5.792,1.053-9.633-3.991C859.459,1949.42,860.454,1945.288,862.43,1947.611Z" transform="translate(-846.141 -1886.073)" fill="#f7987f"/>
        </g>
        <path id="Path_1652" data-name="Path 1652" d="M589.394,1314.288s-18.159,63.707-13.476,63.748c15.254.13,16.588-2.057,29.716-6.28,7.03-2.262,13.017,5.966,17.943,3.076,0,0,.151-40.044-4.6-60.544Z" transform="translate(-429.916 -1045.814)" fill="#211b4e"/>
        <path id="Path_1653" data-name="Path 1653" d="M742.134,771.277s1.913,10.892,3.169,14.134c2.709,6.989-.14,15.183-4.028,18-3.325,2.413-10.413,1.112-17.269-1.357C724.006,802.057,736.017,772.8,742.134,771.277Z" transform="translate(-552.613 -598.241)" fill="#000719"/>
        <path id="Path_1654" data-name="Path 1654" d="M819.151,945.613s2.015.327,4.3,3.227,21.355,30.194,21.355,30.194a8.049,8.049,0,0,1-3.654,6.852l-20.42-20.614S817.592,949.419,819.151,945.613Z" transform="translate(-630.677 -741.936)" fill="#211b4e"/>
        <path id="Path_1655" data-name="Path 1655" d="M547.255,1068.021s-6.1,11.718-7.3,12.06c-6.271,1.81-3.134-7.476-3.134-7.476l7.9-19.055Z" transform="translate(-397.683 -830.903)" fill="#f7987f"/>
        <path id="Path_1656" data-name="Path 1656" d="M703.734,849.691c-2.23-.911-4.246-1.9-4.282-2.492,0,0-.775-7.854-1.232-12.4-.136-1.366-8.36-6-8.36-6s-.278,13.782-2.407,18.522c0,0-2.444.764-6.15,2V862.2h22.431Z" transform="translate(-517.415 -645.652)" fill="#de7f65"/>
        <path id="Path_1657" data-name="Path 1657" d="M727.505,840.414c-.071,1.632-.2,3.985-.435,6.45,1.826,3.73,6.623,12.51,10.218,9.966a.492.492,0,0,1-.113-.25s-.522-5.293-.962-9.7l-8.022-6.7Z" transform="translate(-555.139 -655.033)" fill="#c86350"/>
        <path id="Path_1658" data-name="Path 1658" d="M659.776,998.336s.517,2.284-.817,2.651c-22.1,6.057-29.788-1.8-29.788-1.8-.161-1.049,3.682-17.26,3.682-17.26-.472-5.661-.454-12.819-4.806-22.226-2.766-5.979-3.147-16.427-3.147-16.427-.384-5.885,16.057-9.094,16.057-9.094,4.493,9.251,9.588,9.587,9.588,9.587,6.211-.937,2.595-9.37,2.595-9.37,4.1.742,6.334,1.538,7.3,2.556,2.211,2.32,3.421,18.207,3.421,18.207s3.636,2.434-3,9.254a44.206,44.206,0,0,1-1.9,13.477C657.411,982.847,659.776,998.336,659.776,998.336Z" transform="translate(-470.921 -732.513)" fill="#b7cfec"/>
        <path id="Path_1659" data-name="Path 1659" d="M640.958,934.18s-16.441,3.208-16.057,9.094c0,0,.381,10.448,3.147,16.427,4.351,9.407,4.333,16.565,4.806,22.226,0,0-5.9,20.482-5.742,21.531,0,0,1.642,2.589,12.418,2.665,0,0,8.79-37.579,6.875-57.065Z" transform="translate(-470.921 -732.513)" fill="#211b4e"/>
        <path id="Path_1660" data-name="Path 1660" d="M784.25,935.417c4.1.742,6.334,1.538,7.3,2.556,2.211,2.32,6.89,16.94,6.89,16.94s3.383,5.724-3.257,12.541a87.961,87.961,0,0,1-2.994,13.108c-1.549,4.956,1.158,22.11,1.158,22.11s.516,2.284-.816,2.649a72.907,72.907,0,0,1-8.573,1.819s6.108-44.792,4.388-54.726A136.763,136.763,0,0,0,784.25,935.417Z" transform="translate(-602.03 -733.533)" fill="#211b4e"/>
        <path id="Path_1661" data-name="Path 1661" d="M724.4,780.682a3.136,3.136,0,0,1-2.274.336c-1.334-.179-8.539-3.78-10.424-8.954,0,0-1.958.542-3.049-1.862s-.378-6,2.476-3.951c0,0-1.849-10.887,2.895-12.057s13.781-3.776,15.022,3.966a62.79,62.79,0,0,1,.178,12.965A19.591,19.591,0,0,1,724.4,780.682Z" transform="translate(-539.561 -583.026)" fill="#f7987f"/>
        <path id="Path_1662" data-name="Path 1662" d="M712.736,735.758a8.39,8.39,0,0,0-1.879-6.65c-3.559-4.057-15.278-7.794-20.758,1.452-3.995,6.739-.583,16.789,2.449,19.255,2.085,1.694,1.18,3.278,1.159,4.628a.72.72,0,0,0,.982.772,36.916,36.916,0,0,0,.756-5.9c-.044-.113-.09-.224-.132-.339,0,0-1.958.542-3.049-1.862-1.077-2.369-.4-5.891,2.35-4.036a2.966,2.966,0,0,0,.78.686c.576.277,8.571-4.67,13.108-12.529A10.418,10.418,0,0,1,712.736,735.758Z" transform="translate(-523.17 -559.941)" fill="#000719"/>
        <path id="Path_1663" data-name="Path 1663" d="M666.081,818.205s3.583,28.112-11.215,22.015l-5.637,2.335a21.777,21.777,0,0,1,5.069-6.408c3.376-2.868,3.949-11.793,2.1-17.943Z" transform="translate(-490.979 -636.922)" fill="#000719"/>
        <path id="Path_1664" data-name="Path 1664" d="M535.2,1169.977s11.572,18.985,20.555,31.587l2.636-.567s-12.729-30.275-16.345-34.929C539.146,1162.336,533.864,1165.686,535.2,1169.977Z" transform="translate(-396.814 -922.315)" fill="#f7987f"/>
        <path id="Path_1665" data-name="Path 1665" d="M651.341,1361.079s4.508,2.7,4.749,3.407a.762.762,0,0,1-.289.982,1.384,1.384,0,0,0-.9.165s1.445,2.811.573,3.437a2.687,2.687,0,0,1-1.5.682c-.45.082-2.894,1-3.221.474s-2.554-4.747-1.833-7.477Z" transform="translate(-490.607 -1084.381)" fill="#f7987f"/>
        <path id="Path_1666" data-name="Path 1666" d="M531.281,998.771c13.754-33.645,17.913-34.8,17.913-34.8,5.893,5.892,1.865,21.17,1.865,21.17L539.572,1000.7l.055.12,12.776,28.29s.065,3.2-4.618,3.538c0,0-16.519-26.27-17.243-28.32C529.628,1001.744,531.281,998.771,531.281,998.771Z" transform="translate(-392.925 -757.069)" fill="#211b4e"/>
        <path id="Path_1667" data-name="Path 1667" d="M979.4,1131.2s-36.586-1.466-38.328.911-3.294,7.965,3.8,7.245,34.48-3.941,34.48-3.941A12.65,12.65,0,0,0,979.4,1131.2Z" transform="translate(-730.319 -894.54)" fill="#211b4e"/>
        <path id="Path_1668" data-name="Path 1668" d="M1232.524,966.861s-10.591-2.144-12.887-1.424c0,0-.594.317.468,1.031s6.057,2.09,6.6,2.672c0,0,.623.863.561,1.031a19.745,19.745,0,0,0-3.693,1.4c-.558.391.212.87.752.945s2.712-.4,4.136-.311a16.047,16.047,0,0,0,4.456-.229C1233.524,971.7,1232.524,966.861,1232.524,966.861Z" transform="translate(-960.996 -758.155)" fill="#f7987f"/>
        <path id="Path_1669" data-name="Path 1669" d="M1294.373,971.4l-4.195-.181a11.864,11.864,0,0,0,.538,6.32l3.7.591Z" transform="translate(-1019.106 -763.045)" fill="#95c9e1"/>
        <path id="Path_1670" data-name="Path 1670" d="M1352.207,886.014s-4.451,2.9-6.765,4.568-14.787,10.278-14.787,10.278l-24.418-.122s-1.189,2.788-.384,7.806c0,0,21.6,2.8,23.629,2.983,4.229.4,6.912.528,10.4-1.024,6.126-2.73,15.606-6.989,15.606-6.989Z" transform="translate(-1031.966 -692.813)" fill="#211b4e"/>
        <path id="Path_1671" data-name="Path 1671" d="M1716.5,1809.482a6.78,6.78,0,0,1,1.1,6.318c-2.026,6.574-1.067,7.7-.767,8.168s6.64-4.9,9.116-8.575c2.185-3.24.8-3.953-.26-5a36.007,36.007,0,0,1-4.2-5.145Z" transform="translate(-1370.536 -1450.485)" fill="#95c9e1"/>
        <path id="Path_1672" data-name="Path 1672" d="M1708.509,1842.748s-3.032,8.8-2.069,11.563c0,0,.9,2.175,3.8.664,4.328-2.253,8.214-7.7,9.083-10.241a9.85,9.85,0,0,1,1.081-1.582c.38-.34,2.4-2.318,2.233-3.206,0,0-2.518-4.027-5.266-5.426a11.763,11.763,0,0,1-4.1,5.22s.432-1.663-.385-1.543l-3.411.977S1707.971,1838.905,1708.509,1842.748Z" transform="translate(-1362.221 -1474.613)" fill="#08122a"/>
        <path id="Path_1673" data-name="Path 1673" d="M1586.839,1903.474a6.779,6.779,0,0,1-2.458,5.924c-5.237,4.46-5.03,5.922-5.03,6.481s8.232-.573,12.293-2.348c3.583-1.564,2.8-2.906,2.461-4.358a36.221,36.221,0,0,1-.784-6.594Z" transform="translate(-1257.624 -1530.709)" fill="#95c9e1"/>
        <path id="Path_1674" data-name="Path 1674" d="M1563.781,1234.927l-2.846,46.268s24.361,57.667,25.7,61.466c0,0,7.6-.447,10.285-9.611l-13.851-57.444,4.908-38.221Z" transform="translate(-1242.445 -980.401)" fill="#1b1643"/>
        <path id="Path_1675" data-name="Path 1675" d="M1614.526,1207.524s2.16,15.248.69,22.04-5.96,30.015-5.96,30.015-.95,7.524-.95,12.113-6.194,57.254-6.194,57.254a16.136,16.136,0,0,1-11.906,0s-3.726-63.556-3.726-68.941c0-7.71,7.681-55.319,7.681-55.319Z" transform="translate(-1263.5 -955.475)" fill="#211b4e"/>
        <path id="Path_1676" data-name="Path 1676" d="M1560.935,802.989c.229-2.074.523-9.457.895-9.976,0,0,7.366-2.019,7.914-3.192.841-1.8,2.014-6.847,2.336-12.154.079-1.322,8.835-6.9,8.835-6.9s-.2,12.7,2.7,18.447c0,0,9.778,1.427,11.976,3.626a6.419,6.419,0,0,1,1.328,3.133Z" transform="translate(-1242.445 -597.824)" fill="#f48570"/>
        <path id="Path_1677" data-name="Path 1677" d="M1613.589,817.463c5.246,2.012,11.361-5.042,10.851-9.536-.007-.125-.012-.246-.019-.37-.005-.08-.009-.161-.012-.24-.007-.141-.012-.275-.019-.41,0-.056,0-.112-.007-.167-.007-.151-.012-.295-.017-.438,0-.032,0-.063,0-.1q-.008-.236-.015-.457c0-.009,0-.015,0-.024v0h-.82l-8.467,4.155A45.383,45.383,0,0,1,1613.589,817.463Z" transform="translate(-1285.845 -626.633)" fill="#f17462"/>
        <path id="Path_1678" data-name="Path 1678" d="M1537.126,1222.25s13.544,1.086,38.14-1.418" transform="translate(-1222.821 -968.784)" fill="#1b203e"/>
        <path id="Path_1679" data-name="Path 1679" d="M1576.1,875.783s-10.295,5.107-11.293,4.966c-.911-.129-2.749-1.9-4.074-3.826l-2.721,1.047-6.492,61.941s12.419,8.728,32.307.341Z" transform="translate(-1234.687 -684.38)" fill="#b7cfec"/>
        <path id="Path_1680" data-name="Path 1680" d="M1604.453,942.174l2.846-.063s3.019,12.852-.7,31.739c-1.093,5.544-2.051,9-2.051,9s-3.586-2.793-3.53-10.281S1605.212,952.342,1604.453,942.174Z" transform="translate(-1275.481 -739.05)" fill="#000719"/>
        <path id="Path_1681" data-name="Path 1681" d="M1614.036,904.62s.678,4.423,1.293,5.318a8.6,8.6,0,0,0,2.514.28l1.867-5.6-3.374-1.705-.735-.239Z" transform="translate(-1286.213 -706.546)" fill="#000719"/>
        <path id="Path_1682" data-name="Path 1682" d="M1601.728,883.535l-5.415,4.932v-7.7l2.591-1.606Z" transform="translate(-1271.605 -687.167)" fill="#95c9e1"/>
        <path id="Path_1683" data-name="Path 1683" d="M1627.123,882.571l9.162,6.692,1.339-11.269A68.7,68.7,0,0,1,1627.123,882.571Z" transform="translate(-1297 -686.202)" fill="#95c9e1"/>
        <path id="Path_1684" data-name="Path 1684" d="M1678.834,872.234a1.153,1.153,0,0,1,.713-.461c2.125.1,6.5,1.993,13.022,4.177,0,0,2.522-.2,2.4,11.707-.125,12.273,1.851,34.377.167,56.66,0,0-5.488,7.21-16.553,7.21C1678.582,951.526,1674.811,893.506,1678.834,872.234Z" transform="translate(-1338.093 -681.074)" fill="#211b4e"/>
        <path id="Path_1685" data-name="Path 1685" d="M1607.825,1066.724l-18.28-1.071v-25.848l18.28,1.344Z" transform="translate(-1266.027 -819.574)" fill="#d20068"/>
        <path id="Path_1686" data-name="Path 1686" d="M1545.9,879.164l-6.825,1.062c-4.669-1.062-7.068,62.633-7.068,62.633,1.295,3.821,4.243,8.065,9.072,8.568C1541.083,951.427,1538.087,905.249,1545.9,879.164Z" transform="translate(-1218.605 -687.166)" fill="#211b4e"/>
        <path id="Path_1687" data-name="Path 1687" d="M1578.537,710.753a52.957,52.957,0,0,0-1.406,12.138c.161,6.593,2.786,9,5.059,9.714,0,0,.539.419,2.7.37,2.655-.057,7.873-3.277,9.875-8.2,0,0,1.372.615,2.511-1.661s.954-6-1.87-4.121c0,0,2.291-10.234-2.242-11.531S1580.012,703.334,1578.537,710.753Z" transform="translate(-1255.787 -544.304)" fill="#f7987f"/>
        <path id="Path_1688" data-name="Path 1688" d="M1595.872,676.276c4.406,8.6-.894,15.12-3.8,19.578-1.227,1.881-.712,4.033-.976,3.948-.548-1.841-.832-2.208-.678-3.766-.019-.37.336-.713.38-.82,0,0,.969.72,2.516-1.958,1.1-1.9.658-5.665-1.632-3.705a2.826,2.826,0,0,1-.776.634c-.564.244-2.1-3.231-1.211-5.382a10.237,10.237,0,0,0,.229-4.357s-5.375,1.973-9.8,1.512c0,0-2.86-.308-4.221-2.2a9.636,9.636,0,0,0-1.756,3.154s-3.958-6.583-.264-10.545a7.139,7.139,0,0,1,6.921-2.217c.1.058,2.255.244,5.5.765C1591.167,671.7,1593.752,672.138,1595.872,676.276Z" transform="translate(-1251.82 -514.742)" fill="#000719"/>
        <path id="Path_1689" data-name="Path 1689" d="M1576.354,716.8s-.722,6.755.14,9.163A28.63,28.63,0,0,1,1577.9,720Z" transform="translate(-1254.92 -553.337)" fill="#000719"/>
        <path id="Path_1690" data-name="Path 1690" d="M1596.29,1125.073s-5.3,1.643-6.763,1.635-5.095-2.618-5.29-3.229-1.022-2.344-.351-3.114.863-.159,1.418.281,4.406.449,4.406.449-2.3-1.252-2.034-1.725,2.868.244,3.45.367a46.364,46.364,0,0,1,5,1.481Z" transform="translate(-1261.149 -885.024)" fill="#f7987f"/>
        <path id="Path_1691" data-name="Path 1691" d="M1651.58,1122.436l-3.353,1.052s-1.118,3-.391,4.967l4.023-.328Z" transform="translate(-1313.871 -887.682)" fill="#95c9e1"/>
        <path id="Path_1692" data-name="Path 1692" d="M1680.382,896.533a22.992,22.992,0,0,0-4.218,14.884l7.71,16.429-21.616,8.067s-1.283,4.482,0,6.683c0,0,27.316-3.52,31.507-7.375,2.009-1.848,2.129-5.109,1.961-6.835S1689.238,896.875,1680.382,896.533Z" transform="translate(-1325.489 -701.483)" fill="#211b4e"/>
        <path id="Path_1693" data-name="Path 1693" d="M1558.3,1940.182s-7.278,5.8-7.948,8.651c0,0-.408,2.318,2.85,2.6,4.862.419,11.063-2.094,13.16-3.772a9.682,9.682,0,0,1,1.76-.753c.5-.086,3.269-.671,3.6-1.51,0,0,.033-4.751-1.534-7.4a11.763,11.763,0,0,1-6.26,2.207s1.257-1.173.5-1.509l-3.4-1.006S1559.906,1936.651,1558.3,1940.182Z" transform="translate(-1233.707 -1559.528)" fill="#08122a"/>
        <path id="Path_1694" data-name="Path 1694" d="M1063.62,872.431s-4.5,2.137-5.511,3.607-6.189,17.832-8.778,25.306,8.415,1,8.415,1l4.944-9.533Z" transform="translate(-820.434 -681.617)" fill="#8db7e2"/>
        <path id="Path_1695" data-name="Path 1695" d="M1141.619,1148.312l-12.292-1.391.69-5.066-23.24,2.578-4.941,133.936s2.472,3.237,9.678-.587l12.382-90.964,16.311,92.051a13.477,13.477,0,0,0,10.611-1.236Z" transform="translate(-864.037 -903.688)" fill="#211b4e"/>
        <path id="Path_1696" data-name="Path 1696" d="M1164.16,774.417l31.4-1.467a6.783,6.783,0,0,0-1.291-3.346c-2.24-2.389-13.126-7.728-13.126-7.728-2.854-6.144-1.461-18.769-1.461-18.769s-11.28,4.557-11.409,5.947c-.517,5.575-.807,11.123-1.752,12.988a4.424,4.424,0,0,1-2,1.168Z" transform="translate(-915.406 -575.023)" fill="#f48570"/>
        <path id="Path_1697" data-name="Path 1697" d="M1191.687,774.386c-.053-.987-.075-1.961-.08-2.9-.267.131.687-1.765-.067-1.652l-.344-.987-1.247.463-9.56,4.87c-.123,1.37-.336,2.68-.562,3.927a25,25,0,0,1-.788,4.521h0C1187.623,782.254,1190.832,777.676,1191.687,774.386Z" transform="translate(-927.67 -596.242)" fill="#f17462"/>
        <path id="Path_1698" data-name="Path 1698" d="M1120.652,853.513l6.67-2.987c1.331,2.077,2.464,4.068,3.42,4.235,1.043.182,11.8-4.864,11.8-4.864s14.5,3.049,16.478,4.02V866.9l-4.117,48.017c-24.867,4.815-35.824-1.065-35.824-1.065s-2.835-45.8-1.291-53.793C1118.509,856.3,1118.6,854.314,1120.652,853.513Z" transform="translate(-876.801 -663.044)" fill="#b7cfec"/>
        <path id="Path_1699" data-name="Path 1699" d="M1121.737,1159.011a29.314,29.314,0,0,0,4.707,0c2.251-.221,6.267-.575,6.046-1.413l-1.9-.473h-10.019Z" transform="translate(-879.481 -916.274)" fill="#f7987f"/>
        <path id="Path_1700" data-name="Path 1700" d="M1037.629,1121.095c-3.491-1.063-11.35-4.833-16.284-4.391,0,0-5.562,8.015-.677,10.356,2.447,1.172,14.369.165,17.557-.456Z" transform="translate(-795.479 -882.927)" fill="#8db7e2"/>
        <path id="Path_1701" data-name="Path 1701" d="M1163.579,917.8l3,.029s2.743,13.632-1.816,33.387c-1.338,5.8-2.464,9.4-2.464,9.4s-3.679-3.063-3.367-10.944S1164.031,928.529,1163.579,917.8Z" transform="translate(-911.078 -719.011)" fill="#000719"/>
        <path id="Path_1702" data-name="Path 1702" d="M1176.253,877.7s1.144,5.291,1.762,6.253a9.048,9.048,0,0,0,2.637.38l3.2-6.654-3.227-1.293-1.307.209Z" transform="translate(-925.374 -684.873)" fill="#000719"/>
        <path id="Path_1703" data-name="Path 1703" d="M1162.754,855.536l-6.594,3.8,1.4-7.512,2.37-.988Z" transform="translate(-908.813 -663.819)" fill="#95c9e1"/>
        <path id="Path_1704" data-name="Path 1704" d="M1199.365,854.543l10.193,4.757.6-9.4S1205.29,852.117,1199.365,854.543Z" transform="translate(-944.424 -663.044)" fill="#95c9e1"/>
        <path id="Path_1705" data-name="Path 1705" d="M1130.083,693.513a53.1,53.1,0,0,0,2.834,11.939c2.411,6.17,5.715,7.539,8.108,7.437,0,0,.653.211,2.672-.574,2.488-.965,6.309-5.8,6.513-11.128,0,0,1.505.11,1.8-2.428s-1.158-5.989-3.179-3.249c0,0-1.347-10.448-6.072-10.119S1128.929,686,1130.083,693.513Z" transform="translate(-887.236 -527.444)" fill="#f7987f"/>
        <path id="Path_1706" data-name="Path 1706" d="M1140.69,670.393s.672-.051.984-2.439c.283-2.182-1.321-5.572-2.812-2.939a2.863,2.863,0,0,1-.515.865c-.449.425-3.093-2.327-2.989-4.665a10.254,10.254,0,0,0-1.277-4.191s-6.379,3.793-8.471,3.954a9.381,9.381,0,0,1-5.068-.266,15.463,15.463,0,0,0-.505,4.052s-4.1-5.232-1.754-10.142c2.036-4.263,5.326-4.689,5.437-4.669s2.57-.655,5.459-1.168c4.862-.861,5.376-.487,8.387,1.571,8.012,5.473,6.058,14.694,4.737,19.876a37.579,37.579,0,0,0-.992,5.015Z" transform="translate(-876.996 -496.923)" fill="#000719"/>
        <path id="Path_1707" data-name="Path 1707" d="M1118.733,710.576s1.443,7.956,3.082,9.934a28.784,28.784,0,0,1-.717-6.111Z" transform="translate(-877.964 -548.209)" fill="#000719"/>
        <path id="Path_1708" data-name="Path 1708" d="M1322.872,1869.177s2.083,8.3,1.375,11.653a25.021,25.021,0,0,0-.088,7.5l3.354.443a16.483,16.483,0,0,1,2.295,2.273c.342.408,2.759,2.4,8.034,1.875,2.363-.237.629-3.884-.394-5.385s-5.169-5.827-5.609-8.121-.891-9.623-.891-9.623Z" transform="translate(-1046.223 -1503.177)" fill="#1b203e"/>
        <path id="Path_1709" data-name="Path 1709" d="M1081.142,1869.177s-2.084,8.3-1.375,11.653a25.053,25.053,0,0,1,.088,7.5l-3.354.443a16.463,16.463,0,0,0-2.295,2.273c-.341.408-2.759,2.4-8.034,1.875-2.364-.237-.63-3.884.394-5.385s5.168-5.827,5.609-8.121.89-9.623.89-9.623Z" transform="translate(-833.664 -1503.177)" fill="#1b203e"/>
        <path id="Path_1710" data-name="Path 1710" d="M1250.2,868.538s6.217.246,8.522,4.409c2.471,4.463,11.94,43.368,10.705,47.253s-19.191,2.534-35.775-1.118c0,0-4.585-2.174-1.841-9.236l.872-.059,22.86-1.529s-7.944-21.539-9.356-24.365" transform="translate(-970.445 -678.408)" fill="#9cbdd3"/>
        <path id="Path_1711" data-name="Path 1711" d="M1237.627,1111.29c-.209,1.171-.764,1.913-1.745,1.593a3.26,3.26,0,0,1-2.144-2.377,11.042,11.042,0,0,1,.05-4.891c.162-.8.328-1.338.328-1.338,1.127-.541,2.055.275,2.691,1.588v0A10,10,0,0,1,1237.627,1111.29Z" transform="translate(-972.542 -872.574)" fill="#6594ca"/>
        <path id="Path_1712" data-name="Path 1712" d="M1145.777,1107.179l-3.889-.784c-1.168.671-2.668-.358-2.668.7a1.717,1.717,0,0,1-1.06,1.483h-7.193s-2.033-1.882-2.209-2.146a2.993,2.993,0,0,1-.233-1.725,2.073,2.073,0,0,1,.9-1.366l-3.118,1.076c-.163.109-3.355,4.428-3.355,4.428h-.9c-.131-1.03,2.472-5.151,2.472-5.151s7.02-3.931,8.52-4.46,3.835.869,5.6,1.547a15.133,15.133,0,0,0,3.294.719c1.611.206,3.019.251,3.019.251v0A10,10,0,0,1,1145.777,1107.179Z" transform="translate(-880.692 -868.463)" fill="#f7987f"/>
        <path id="Path_1713" data-name="Path 1713" d="M1067.9,1152.715a.94.94,0,0,1-.939.94H1026.35a.94.94,0,0,1-.939-.94h0a.94.94,0,0,1,.939-.94h40.608a.94.94,0,0,1,.939.94Z" transform="translate(-801.044 -911.864)" fill="#2f84c7"/>
        <path id="Path_1714" data-name="Path 1714" d="M1061.189,1152.715a.941.941,0,0,0-.94-.94h-33.9a.94.94,0,0,0,0,1.881h33.9A.941.941,0,0,0,1061.189,1152.715Z" transform="translate(-801.044 -911.864)" fill="#5198d3"/>
        <path id="Path_1715" data-name="Path 1715" d="M1028.607,1031.27H993.946l-7.137-25.305h34.661Z" transform="translate(-769.227 -791.681)" fill="#2f84c7"/>
        <path id="Path_1716" data-name="Path 1716" d="M1026.994,1031.27H993.946l-7.137-25.305h33.046Z" transform="translate(-769.227 -791.681)" fill="#5198d3"/>
        <path id="Path_1717" data-name="Path 1717" d="M1173.106,1148.369a.442.442,0,0,1-.442.442h-5.355a.44.44,0,0,1-.44-.442h0a.44.44,0,0,1,.44-.442h5.355a.442.442,0,0,1,.442.442Z" transform="translate(-917.639 -908.692)" fill="#2f84c7"/>
        <path id="Path_1718" data-name="Path 1718" d="M1063.444,1148.369a.441.441,0,0,1-.442.442h-5.354a.441.441,0,0,1-.442-.442h0a.441.441,0,0,1,.442-.442H1063a.441.441,0,0,1,.442.442Z" transform="translate(-827.25 -908.692)" fill="#2f84c7"/>
      </g>
    </g>
  </g>
</svg>

       
  );
};

PartnerSvg.propTypes = {};

export default PartnerSvg;
