import {
  GET_ALL_CLUBS,
  CREATE_CLUB,
  ALL_CLUBS_LOADING,
  ALL_CLUBS_ERRORS,
  GET_SINGLE_CLUB,
  ALL_POST,
  POST_LOADING,
  ADD_POST,
  POST_ERROR,
  CREATE_COMMENT,
  COMMENT_ERROR,
  CREATE_CLUB_CATEGORY,
  GET_CLUB_CATEGORY,
  CLUB_CATEGORY_LOADING,
  CLUB_LIST,
  JOIN_CLUB,
  EDIT_POST,
  DELETE_CLUB,
  DELETE_POST,
  EDIT_CLUB,
  DELETE_POST_ERROR,
} from "../Actions/types";

const initialState = {
  club: null,
  loading: false,
  clubs: [],
  get_single_club: {},
  post: {},
  comment: {},
  join_status: {},
  deleteClub: {},
  createClub: {},
  editClub: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_CLUBS:
      return {
        ...state,
        loading: false,
        allclubs: action.payload && action.payload.data,
      };
    case GET_SINGLE_CLUB:
      return {
        loading: false,
        get_single_club: action.payload && action.payload.data,
      };
    case CREATE_CLUB:
      return {
        ...state,
        loading: false,
        createClub: { success: true, data: action.payload.data },
      };
    case ALL_CLUBS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case ALL_CLUBS_ERRORS:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ALL_POST:
      return {
        ...state,
        loading: false,
        posts: action.payload.data,
      };
    case POST_ERROR:
      return {
        ...state,
        posts: { errors: false },
      };
    case ADD_POST:
      return {
        ...state,
        loading: false,
        post: {
          success: true,
          data: action.payload && action.payload.data,
        },
      };
    case POST_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CREATE_COMMENT:
      return {
        ...state,
        loading: false,
        comment: {
          success: true,
          data: action.payload && action.payload.data,
        },
      };
    case COMMENT_ERROR:
      return {
        ...state,
        loading: false,
        posts: { errors: false },
      };
    case GET_CLUB_CATEGORY:
      return {
        ...state,
        loading: false,
        club_categories: action.payload.data,
      };
    case CREATE_CLUB_CATEGORY:
      return {
        ...state,
        loading: false,
        club_category: { success: true, data: action.payload.data },
      };
    case CLUB_CATEGORY_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CLUB_LIST:
      return {
        ...state,
        loading: false,
        clublist: action.payload.data,
      };
    case JOIN_CLUB:
      return {
        ...state,
        loading: false,
        join_status: action.payload && action.payload.data,
      };
    case EDIT_POST:
      return {
        ...state,
        loading: false,
        post: {
          success: true,
          data: action.payload && action.payload.data,
        },
      };
    case DELETE_CLUB:
      return {
        ...state,
        loading: false,
        deleteClub: {
          success: true,
          data: action.payload && action.payload.data,
        },
      };
    case DELETE_POST:
      return {
        ...state,
        loading: false,
        post: {
          success: true,
          data: action.payload && action.payload.data,
        },
      };
    case EDIT_CLUB:
      return {
        ...state,
        loading: false,
        editClub: {
          success: true,
          data: action.payload && action.payload.data,
        },
      };
    case DELETE_POST_ERROR:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
