/* eslint-disable react/no-unused-state */
import React from "react";
import moment from "moment";
import { Button, Paper, Typography, Card } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getallFoodItem } from "../../../Actions/foodItemActions";
import NameTruncate from "../NameTruncate";
import { checkPermission } from "../../../Utils/checkPermission";
import BookingForm from "../../../Screens/Booking/BookingForm";

const styles = (theme) => {
  return {
    cardGrid: {
      padding: `${theme.spacing(8)}px 0`,
    },
    card: {
      // height: "287px",
      display: "flex",
      flexDirection: "column",
      boxShadow: "0px 0px 20px rgba(0,0,0,0.05)",
      transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
      backgroundColor: "#FCFDFE",
      position: "relative",
      "&:hover": {
        transform: "scale(1.01)",
        boxShadow:
          "rgb(178 183 187 / 6%) 0px 2px 4px 6px, rgb(212 219 226 / 60%) 0px 2px 10px 0px",
      },
    },
    containerPaper: {
      border: `1px solid ${theme.palette.border.color}`,
    },
    resourceImage: {
      width: "100%",
      height: "140px",
      borderTopLeftRadius: "5px",
      borderTopRightRadius: "5px",
      position: "absolute",
    },
    cardimage: {
      height: "140px",
      width: "100%",
    },
    cardDetail: {
      padding: "4px 15px 14px",
    },
    resourceName: {
      fontWeight: "bold",
      fontSize: " 14px",
      lineHeight: "24px",
      color: " #333",
      marginRight: "15px",
      textTransform: "capitalize",
    },
    resourceSection: {
      display: "flex",
      justifyContent: "flex-start",
    },
    icon: {
      marginRight: "10px",
    },
    resourceDetail: {
      color: "#626262",
      fontSize: "14px",
      lineHeight: "24px",
      fontWeight: 600,
      padding: "2% 0 6% 0",
    },
    bookButton: {
      borderRadius: "50px",
      borderColor: "#211b4e !important",
      color: "#211b4e ",
    },
  };
};

class BookResourceCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      startdate: "",
      resource_id: "",
    };
  }

  handleClickOpen = (data, id) => {
    const currentDate = new Date();
    const nextMonthDate = currentDate.setMonth(currentDate.getMonth() + 1);
    const maxDateFormat = moment(nextMonthDate).endOf("month");
    if (data >= new Date().setHours(0, 0, 0, 0) && data <= maxDateFormat) {
      this.setState({
        modalOpen: true,
        startdate: data,
        resource_id: id,
      });
    }
  };

  handleClose = () => {
    // this.props.getBookings();
    this.setState({
      modalOpen: false,
    });
    return null;
  };

  render() {
    const { classes, data } = this.props;
    // eslint-disable-next-line no-unused-vars
    const date = new Date();
    return (
      <Card className={classes.card}>
        <Paper className={classes.containerPaper}>
          <div className={classes.profileImage}>
            <div className={classes.cardimage}>
              <img
                className={classes.resourceImage}
                src={data.image}
                alt="Food"
              />
            </div>
          </div>
          <div className={classes.cardDetail}>
            <div className={classes.resourceSection} direction="row">
              <Typography
                component="h2"
                align="left"
                className={classes.resourceName}
              >
                <NameTruncate text={data.resource_name} amount={22} />
              </Typography>
            </div>
            <div className={classes.cardMiddle}>
              <Typography
                component="p"
                variant="subtitle2"
                className={classes.resourceDetail}
              >
                Pax: {data.pax} pax | Credit Per Half Hour:{" "}
                {data.credit_per_thirty_mins}
              </Typography>
              {checkPermission("BOOKING_CREATE") && (
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  className={classes.bookButton}
                  onClick={() => this.handleClickOpen(date, data._id)}
                >
                  Book
                </Button>
              )}
            </div>
            {this.state.modalOpen && (
              <BookingForm
                modalOpen={this.state.modalOpen}
                handleClose={this.handleClose}
                bookingdate={this.state.startdate}
                resourceID={this.state.resource_id}
              />
            )}
          </div>
        </Paper>
      </Card>
    );
  }
}
BookResourceCard.propTypes = {};
const mapStateToProps = () => {
  return {};
};
export default connect(mapStateToProps, { getallFoodItem })(
  withStyles(styles)(withRouter(BookResourceCard))
);
