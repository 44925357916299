/* eslint-disable camelcase */
import React from "react";
import { connect } from "react-redux";
import * as Yup from "yup";
import { withRouter } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import { Formik, Form } from "formik";
import { Button, Typography, Grid } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { checkVisitor } from "../../Actions/securityVisitorActions";
import SecurityVisitor from "../../Assets/svgs/SecurityVisitor";
import { getvisitor } from "../../Actions/visitorActions";
import { getEvents } from "../../Actions/eventActions";
import Walkthrough from "../Visitor/Walkthrough";
import "../company.css";
import SelectField from "../../Components/Common/Form/SelectField";
import SuccessPopup from "../Visitor/SuccessPopup";
import InputField from "../../Components/Common/Form/InputField";

const styles = (theme) => ({
  formfooter: {
    margin: "24px 15px",
  },
  visitor: {
    textAlign: "center",
    color: "#4B2222",
    fontSize: "25px",
    fontWeight: "700",
    marginTop: "2%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "6%",
    },
  },
  visitor_header: {
    textAlign: "center",
    color: "#4B2222",
    fontSize: "42px",
    fontWeight: "400",
  },
  subtitle: {
    textAlign: "center",
    color: "#2155B5",
    fontSize: "17px",
    fontWeight: "400",
    marginTop: "10px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
    },
  },
  button: {
    marginTop: "20px",
    marginBottom: "50px",
    background: "#211b4e !important",
  },
  main_form: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "normal",
    fontSize: "14px",
    textAlign: "left",
  },
  mainVisitor: {
    marginTop: "24px",
  },
  mainButton: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  mainSvg: {
    width: "879px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "380px",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      width: "700PX",
      marginTop: "11%",
    },
  },
  main: {
    paddingTop: "4%",
    background: "#ffffff",
    overflowY: "hidden",
    // height: "100vh"
  },
  purposeSelect: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "5px",
    },
  },
});
const phoneRegExp = /[1-9]{1}[0-9]{9}/;

const SecuritySchema = Yup.object().shape({
  contact_number: Yup.string()
    .matches(phoneRegExp, "Please enter valid number.")
    // .min(10, "Please enter valid number.")
    .required("This field is required."),
  purpose: Yup.string().required("This field is required."),
});
const PurposeOptionsWithEvent = [
  { value: "walkthrough", label: "I am here for space inquiry" },
  { value: "walkin", label: "I am here to meet someone" },
  { value: "for_attend_event", label: "I am here for an event" },
];

const PurposeOptionsWithoutEvent = [
  { value: "walkthrough", label: "I am here for space inquiry" },
  { value: "walkin", label: "I am here to meet someone" },
];

class Security extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      successPopup: false,
      contact: "",
      purpose: "",
      events: [],
    };
    this.state.id = this.props.match.params.id;
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.props.getEvents("", "", "today");
  }

  componentDidUpdate(prevProps) {
    const { securityVisitor } = this.props || {};
    const { securityVisitor: visitorData } = securityVisitor;
    if (
      prevProps.securityVisitor.securityVisitor !== visitorData &&
      visitorData.data
    ) {
      let vdata;

      if (visitorData.data === "Status updated") {
        this.openSuccessPopup();
      } else {
        if (visitorData.data !== "No data found") {
          vdata = visitorData.data;
        } else {
          vdata = this.state.contact;
        }
        const purposeVal = this.state.purposeValue.value
          ? this.state.purposeValue.value
          : "";

        if (purposeVal === "walkthrough") {
          this.handleClickOpen();
        } else if (purposeVal === "for_attend_event") {
          // this.handleClickOpen();
          this.props.history.push({
            pathname: "/VisitorEvent",
            state: vdata,
          });
        } else {
          this.props.history.push({
            pathname: "/new-visitor",
            state: vdata,
          });
        }
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.visitors !== prevState.visitors) {
      return { visitors: nextProps.visitors };
    }
    if (nextProps.events !== prevState.events) {
      return { events: nextProps.events };
    }

    return [];
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleClickOpen = () => {
    this.setState({
      modalOpen: true,
    });
  };

  handleClose = () => {
    this.setState({
      modalOpen: false,
    });
  };

  openSuccessPopup = () => {
    this.setState({ successPopup: true });
  };

  closeSuccessPopup = () => {
    this.setState({ successPopup: false });
    this.props.getvisitor();
  };

  handleSuccess = () => {
    this.closeSuccessPopup();
    this.props.history.push("/Dashboard");
  };

  render() {
    const { classes } = this.props;
    const { events } = this.state;
    return (
      <React.Fragment>
        <div className={classes.root}>
          <CssBaseline />
          <div className={classes.main}>
            <div>
              <Grid container spacing={24}>
                <Grid item xs={12} sm={12} md={12}>
                  <div>
                    <Typography
                      variant="subtitle2"
                      className={classes.visitor}
                      gutterBottom
                    >
                      Hi, Visitor
                    </Typography>
                    <Typography variant="h2" className={classes.visitor_header}>
                      Welcome to DevX
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.subtitle}
                      gutterBottom
                    >
                      Hope you have an amazing time!
                    </Typography>
                  </div>
                </Grid>
              </Grid>
              <Formik
                initialValues={{
                  purpose: [],
                  contact_number: "",
                }}
                validationSchema={SecuritySchema}
                onSubmit={(values, { setErrors, setSubmitting, resetForm }) => {
                  const data = { ...values };
                  data.purpose = data.purpose ? data.purpose.value : "";

                  const contact_number = data.contact_number
                    ? data.contact_number
                    : "";
                  data.contact = contact_number;
                  // this.state.values = values;
                  this.setState(
                    {
                      purposeValue: values.purpose,
                      contact: values.contact_number,
                      purpose: values.purpose.value,
                    },
                    () => {
                      this.props.checkVisitor(
                        data,
                        setErrors,
                        setSubmitting,
                        this.props.history
                      );
                    }
                  );
                  resetForm(this.initialValues);
                }}
              >
                {({ values, setFieldValue, setFieldTouched }) => (
                  <Form className={classes.mainVisitor}>
                    <Grid container spacing={24}>
                      <Grid item xs={12} sm={12} className={classes.main_form}>
                        <Grid
                          item
                          xs={4}
                          sm={5}
                          md={3}
                          className={classes.purposeSelect}
                        >
                          {events.today && events.today.length <= 0 ? (
                            <SelectField
                              value={values.purpose}
                              name="purpose"
                              id="purpose"
                              label="Purpose"
                              onChange={setFieldValue}
                              onBlur={setFieldTouched}
                              multi={false}
                              options={PurposeOptionsWithoutEvent}
                              placeholder="Select Purpose"
                            />
                          ) : (
                            <SelectField
                              value={values.purpose}
                              name="purpose"
                              id="purpose"
                              label="Purpose"
                              onChange={setFieldValue}
                              onBlur={setFieldTouched}
                              multi={false}
                              options={PurposeOptionsWithEvent}
                              placeholder="Select Purpose"
                            />
                          )}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} className={classes.main_form}>
                        <Grid item xs={4} sm={5} md={3}>
                          <InputField
                            label="Contact Number"
                            name="contact_number"
                            placeholder="Enter Contact Number"
                            style={{ width: "100%" }}
                            type="tel"
                            max="10"
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} className={classes.main_form}>
                        <Grid
                          item
                          xs={4}
                          sm={5}
                          md={3}
                          className={classes.mainButton}
                        >
                          <Button
                            className={classes.button}
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="small"
                          >
                            Submit
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} className={classes.mainSvg}>
                        <SecurityVisitor className={classes.group} />
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </div>

            <SuccessPopup
              data={this.state.successPopup}
              handleClose={this.closeSuccessPopup}
            />
            <Walkthrough
              data={this.state.modalOpen}
              handleClose={this.handleClose}
              contact={this.state.contact}
              purpose={this.state.purpose}
              history={this.props.history}
              handleCloseButton={this.handleClose}
              visitordata={this.props.securityVisitor.securityVisitor.data}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    securityVisitor: state.securityVisitor,
    visitors: state.visitors.visitors,
    events: state.events,
  };
};

export default connect(mapStateToProps, {
  checkVisitor,
  getvisitor,
  getEvents,
})(withStyles(styles)(withRouter(Security)));
