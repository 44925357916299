/* eslint-disable no-use-before-define */
import axios from "axios";
import { ADD_FEEDBACK, FEEDBACK_ERRORS, FEEDBACK_LOADING } from "./types";
import { showSuccess } from "./ActionsHelper";

export const addFeedback = data => dispatch => {
  dispatch(setFeedbackLoading());
  axios
    .post("/api/feedback", { ...data })
    .then(result => {
      dispatch({
        type: ADD_FEEDBACK,
        payload: result.data
      });
      dispatch(showSuccess(" ", "Thank you for your feedback!"));
    })
    .catch(err => {
      const error = err.response;
      dispatch({ type: FEEDBACK_ERRORS, payload: error.data });
    });
};
// Profile Loading
export const setFeedbackLoading = () => {
  return {
    type: FEEDBACK_LOADING
  };
};
