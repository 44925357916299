/* eslint-disable no-use-before-define */
import axios from "axios";
import {
  ADD_SUGGEST,
  LIST_SUGGEST_ERROR,
  UPDATE_SUGGEST_STATUS,
  LIKE_SUGGEST,
  LIKE_SUGGEST_ERROR,
  GET_ALL_SUGGESTION,
  LIST_SUGGEST_LOADING,
} from "./types";
import { showSuccess, showError } from "./ActionsHelper";

export const setLoading = () => {
  return {
    type: LIST_SUGGEST_LOADING,
  };
};

export const addSuggest = (data, setErrors, setSubmitting, history) => (
  dispatch
) => {
  dispatch(setLoading());
  axios
    .post("/api/suggestfeature", data)
    .then((result) => {
      dispatch({
        type: ADD_SUGGEST,
        payload: result.data,
      });
      history.push("/suggest-feature");
      dispatch(showSuccess("", "Suggestion submitted successfully!"));
      setSubmitting(false);
    })
    .catch((err) => {
      dispatch({
        type: LIST_SUGGEST_ERROR,
        payload: err && err.response ? err.response.data.data : "",
      });
      //dispatch(showError(err.response.data.data));
    });
};
export const updateSuggestStatus = (id, data) => (dispatch) => {
  dispatch(setLoading());

  axios
    .put(`/api/suggestfeature/update/${id}`, data)
    .then((result) => {
      dispatch({
        type: UPDATE_SUGGEST_STATUS,
        payload: result.data,
      });
      dispatch(showSuccess("", "Status updated successfully!"));
    })
    .catch((err) => {
      dispatch({
        type: LIST_SUGGEST_ERROR,
        payload: err && err.response && err.response.data,
      });
    });
};

export const getsuggest = () => (dispatch) => {
  dispatch(setLoading());
  axios
    .get(`/api/suggestfeature`)
    .then((res) => {
      dispatch({
        type: GET_ALL_SUGGESTION,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: LIST_SUGGEST_ERROR,
        payload: err && err.res ? err.res.data : "",
      })
    );
};

export const likeSuggestion = (userData) => (dispatch) => {
  dispatch(setLoading());
  axios
    .post(`/api/suggestfeature/like/dislike`, userData)
    .then((result) => {
      dispatch({
        type: LIKE_SUGGEST,
        payload: result.data,
      });
    })
    .catch((err) => {
      const error = err.response || {};
      dispatch({ type: LIKE_SUGGEST_ERROR, payload: error.data });
      dispatch(showError("There was some error while liking post "));
    });
};
