const checkPermission = module => {
  const role = localStorage.getItem("role");
  const policies = JSON.parse(localStorage.getItem("policies"));
  if (
    role === "admin" &&
    ![
      "SECURITY_SPECIAL_VIEW",
      "ORDER_CAFE_STATUS_UPDATE",
      "ORDER_CAFE_STATUS",
      "FOODCATEGORY_VIEW"
    ].includes(module)
  ) {
    return true;
  }

  return policies && policies.find(o => o.policy_name === module);
};

// eslint-disable-next-line import/prefer-default-export
export { checkPermission };
