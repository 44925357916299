import React from "react";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { connect } from "react-redux";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  Paper,
  Button,
  AppBar,
  Toolbar,
} from "@material-ui/core";
import SearchBar from "../../Components/Common/SearchBar";
import NoRecordsFound from "../../Components/Common/NoRecordsFound";
import DataNotFound from "../../Components/Common/DataNotFound";
import ScreenHeader from "../../Components/Common/ScreenHeader";
import { CircularLoader } from "../../Components/Common/Spinner";
import Pagination from "../../Components/Common/Table/TablePagination";
import Cell from "../../Components/Common/Table/TableCell";
import TableHeader from "../../Components/Common/Table/TableHeader";
import { capitalize, spacecapitalize } from "../../Utils/StringFunctions";
import AddCreditAccount from "./AddCreditAccount";
import { getCreditAccounts } from "../../Actions/CreditAccountActions";

const styles = (theme) => ({
  root: {
    minHeight: "inherit",
    backgroundColor: "#fff",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    backgroundColor: "white",
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    padding: "17px 22px 17px 24px",
    flexGrow: 1,
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      padding: "17px 22px 17px 24px",
    },
  },
  datanotfound_security: {
    marginBottom: theme.spacing(6),
    padding: theme.spacing(2),
    flexGrow: 1,
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  heroUnit: {
    margin: "20px 0",
  },
  applicationHeader: {
    margin: "20px",
    borderBottom: "3px solid orange",
    paddingBottom: "5px",
  },
  main: {
    margin: "20px 30px",
  },
  addvisitor: {
    color: "#fff",
    padding: "7px 16px",
    borderRadius: "4px",
    textDecoration: "none",
    fontSize: "0.875rem",
    fontWeight: "500",
    marginLeft: "18px",
    boxShadow: "rgba(137, 163, 169, 0.3) 0px 3px 5px 2px",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  DataNotFound: {
    marginTop: "10%",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  table: {
    marginTop: "0px",
  },
  groupSvg: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "6%",
    backgroundColor: "#ffffff",
  },
  maintext: {
    color: "#626262",
  },
  subtext: {
    color: "#ABABAB",
  },
  backButton: {
    margin: "5% 0% 1% 2.4%",
    [theme.breakpoints.down("sm")]: {
      margin: "7% 0% 2% 3%",
    },
  },
  otherTitle: {
    padding: "12px 0px",
    backgroundColor: "#fff",
  },
  visitorName: {
    fontWeight: 550,
  },
  overrides: {
    // Style sheet name
    ".MuiTableCell-root": {
      // Some CSS
      padding: "14px 12% 10px 8px",
    },
  },
  visitor_contact: {
    fontSize: 12,
    color: "#929292",
  },
  root1: {
    "&:nth-child(even)": {
      backgroundColor: "#FCFDFE",
    },
  },
  searchSection: {
    margin: "24px 0px 8px",
  },
  appbar: {
    backgroundColor: "#ffffff",
  },
  button: {
    width: "20%",
    marginRight: "1.4%",
    color: "white",
    padding: "5px 16px",
    borderRadius: "4px",
    textDecoration: "none",
    fontSize: "0.875rem",
    fontWeight: "500",
    boxShadow: "rgba(137, 163, 169, 0.3) 0px 3px 5px 2px",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
});

class AdminCreditAccount extends React.Component {
  state = {
    // eslint-disable-next-line react/no-unused-state
    page: 0,
    rowsPerPage: 10,
    search: "",
    isSearch: false,
    creditAccount: [],
    modalOpen: false,
    // addAccount: true,
  };

  componentDidMount() {
    this.props.getCreditAccounts();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.creditAccount !== this.props.creditAccount) {
      let creditAccount = this.props.creditAccount || [];
      creditAccount = creditAccount.map((x, id) => {
        return Object.assign({}, x, {
          // eslint-disable-next-line no-plusplus
          idx: id + 1,
        });
      });
      this.setState({
        creditAccount,
      });
    }
    if (
      this.props.creatAccount !== prevProps.creatAccount &&
      this.props.creatAccount.success
    ) {
      this.props.getCreditAccounts();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.creditAccount !== prevState.creditAccount) {
      return {
        creditAccount: nextProps.creditAccount,
      };
    }

    return [];
  }

  handleClose = () => {
    this.props.history.push("/dashboard");
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  searchAccount = (search, isSearch) => {
    this.setState({ isSearch: true, search });
    this.props.getCreditAccounts(this.state.page, search, isSearch);
  };

  handleClickOpen = () => {
    this.setState({
      modalOpen: true,
    });
  };

  handleClose = () => {
    this.setState({
      modalOpen: false,
    });
  };

  handleClick = (id, name, companyName) => {
    this.props.history.push({
      pathname: `credit-account/${id}`,
      state: { id, name, companyName },
    });
  };

  renderHead = () => {
    return (
      <TableHead>
        <TableRow>
          <TableHeader>Sr. No.</TableHeader>
          <TableHeader>Name</TableHeader>
          <TableHeader>Mobile Number</TableHeader>
          <TableHeader>Company Name</TableHeader>
          <TableHeader>Bill Amount</TableHeader>
          <TableHeader>Paid Amount</TableHeader>
          <TableHeader>Balance Amount</TableHeader>
        </TableRow>
      </TableHead>
    );
  };

  renderBody = () => {
    const { classes } = this.props;
    const { creditAccount, page, rowsPerPage } = this.state;

    return (
      <TableBody>
        {creditAccount &&
          creditAccount
            //   // Pagination
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((item, id) => {
              return (
                <TableRow className={classes.root1}>
                  <Cell width="9%">{page * rowsPerPage + id + 1}</Cell>
                  <Cell
                    width="17%"
                    cursor
                    color
                    // eslint-disable-next-line no-unused-vars
                    onClick={(event) =>
                      this.handleClick(
                        item.user_id,
                        item.first_name.concat(" ", item.last_name),
                        item.company_name
                      )
                    }
                  >
                    {capitalize(item.first_name)} {capitalize(item.last_name)}
                  </Cell>
                  <Cell>{item.contact_number}</Cell>
                  <Cell>{spacecapitalize(item.company_name)}</Cell>
                  <Cell>{item.bill_amount}</Cell>
                  <Cell>{item.paid_amount}</Cell>
                  <Cell>{item.paid_amount - item.bill_amount}</Cell>
                </TableRow>
              );
            })}
      </TableBody>
    );
  };

  render() {
    // const { type, text, subtext, subComponent } = this.props;
    const { classes } = this.props;
    const { creditAccount, loading, isSearch, search } = this.state;
    const itemLength = creditAccount ? creditAccount.length : 0;

    if (loading) {
      return <CircularLoader />;
    }

    if (creditAccount && creditAccount.length <= 0 && !isSearch) {
      return (
        <DataNotFound
          type="creditAccount"
          text="You don't have any Credit Account !!"
          subtext=""
          paddingTop="8%"
          subComponent={
            <div>
              <Button
                onClick={() => this.handleClickOpen()}
                variant="contained"
                color="primary"
                className={classes.button}
                style={{ width: "100%" }}
              >
                Add Credit Account
              </Button>
              <AddCreditAccount
                open={this.state.modalOpen}
                handleClose={this.handleClose}
                // addAccount={this.state.addAccount}
              />
            </div>
          }
        />
      );
    }

    return (
      <div className={classes.root}>
        <div>
          <div className={classes.header}>
            <ScreenHeader type="Credit Account" />
            <Button
              onClick={() => this.handleClickOpen()}
              variant="contained"
              color="primary"
              className={classes.button}
            >
              Add Credit Account
            </Button>
            <AddCreditAccount
              open={this.state.modalOpen}
              handleClose={this.handleClose}
              // editingClub={editingClub}
              // addAccount={this.state.addAccount}
            />
          </div>

          <div className={classes.searchSection}>
            <AppBar position="static" className={classes.appbar}>
              <Toolbar>
                <SearchBar
                  type="Search Credit Account"
                  className={classes.searchBox}
                  onSearch={this.searchAccount}
                />
              </Toolbar>
            </AppBar>
          </div>
          {creditAccount && creditAccount.length <= 0 && isSearch && (
            <NoRecordsFound
              text={`No records found for your text "${search}"`}
            />
          )}
        </div>
        <Paper className={classes.paper}>
          <div>
            {creditAccount && creditAccount.length > 0 && (
              <Table className={classes.table}>
                {this.renderHead()}
                {this.renderBody()}
              </Table>
            )}
          </div>
          <CssBaseline />
        </Paper>
        {creditAccount && creditAccount.length > 0 && (
          <Pagination
            count={itemLength}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        )}
      </div>
    );
  }
}

AdminCreditAccount.propTypes = {};

const mapStateToProps = (state) => {
  return {
    creditAccount: state.CreditAccount.creditAccounts,
    creatAccount: state.CreditAccount.creditAccount,
  };
};

export default connect(mapStateToProps, { getCreditAccounts })(
  withStyles(styles)(AdminCreditAccount)
);
