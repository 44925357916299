/* eslint-disable react/no-unused-state */
/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
import React from 'react'
import { Grid } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import * as Yup from 'yup'
import FormLabel from '@material-ui/core/FormLabel'
import { Form } from 'react-formik-ui'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import { green, red, orange } from '@material-ui/core/colors'
import RadioGroup from '@material-ui/core/RadioGroup'
import UploadField from '../../Components/Common/Form/UploadField'
import IssueDepartmentDropdown from '../../Components/Common/Dropdowns/IssueDepartmentDropdown'
// import RadioField from "../../Components/Common/Form/RadioField";
import { addTicket } from '../../Actions/helpdeskActions'
import IssueCategoryDropdown from '../../Components/Common/Dropdowns/IssueCategoryDropdown'
import IssueTitleDropdown from '../../Components/Common/Dropdowns/IssueTitleDropdown'
import TextareaField from '../../Components/Common/Form/TextareaField'
import { SelectField } from '../../Components/Common/Form'
import { getCompanies } from '../../Actions/companiesAction'
import { getCoworker } from '../../Actions/coworkerActions'
import { spacecapitalize, capitalize } from '../../Utils/StringFunctions'
import { checkPermission } from '../../Utils/checkPermission'

const styles = () => ({
  lightbg: {
    padding: '20px 30px 35px',
    backgroundColor: '#fff',
  },
  label: {
    margin: '15px 0px 8px 0px',
    fontSize: '13px',
    fontWeight: '400',
  },
  securitybutton: {
    width: '148px',
  },
  button: {
    width: '148px',
    height: '46px',
    backgroundColor: '#dddddd',
    color: '#151039',
    '&:hover': {
      color: '#151039',
      backgroundColor: '#d1d1d1!important',
    },
  },
  Addbutton: {
    width: '148px',
    height: '46px',
    backgroundColor: '#211b4e !important',
    '&:hover': {
      backgroundColor: '#151039 !important',
    },
  },
  remove_icon: {
    position: 'relative',
  },
  social: {
    height: '20px',
    width: '20px',
  },
  formfooter: {
    paddingTop: '25px',
    marginRight: '-2%',
  },
  form: {
    marginLeft: '-4px',
  },
  input_field: {
    border: '1px solid #949494',
    height: '81%',
    position: 'relative',
    width: '100%',
    padding: '6px 8px',
    margin: '0px',
    // borderBottom: "none",
    fontFamily:
      "'-apple-system,BlinkMacSystemFont','Segoe UI','Roboto','Helvetica Neue','Arial,sans-serif','Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol'",
    color: 'currentColor',
    fontSize: '14px',
  },
})

const HelpdeskSchema = Yup.object().shape({
  helpdeskdepartment: Yup.string().required('This field is required.'),
  helpdeskcategory: Yup.string().required('This field is required.'),
  issuetitle: Yup.string().required('This field is required.'),
  severity_level: Yup.string().required('This field is required.'),
  // description: Yup.string().required("This field is required.")
})

const LowRadio = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />)
const MediumRadio = withStyles({
  root: {
    color: orange[400],
    '&$checked': {
      color: orange[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />)
const HighRadio = withStyles({
  root: {
    color: red[400],
    '&$checked': {
      color: red[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />)

// const securityLevelOptions = [
//   { label: "Low", value: "low" },
//   { label: "Medium", value: "medium" },
//   { label: "High", value: "high" },
// ];

class HelpdeskForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      // value: "",
      // isSearch: false,
      id:
        this.props.match && this.props.match.params
          ? this.props.match.params.id
          : '',
      deletedKey: [],
      severityLevel: 'low',
      companies: [],
      companyCoworkers: [],
    }
    this.handleClose = this.handleClose.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    this.props.getCompanies('', '', 'company')
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.companyData !== prevState.companyData) {
      return {
        companies: nextProps.companyData,
      }
    }
    if (nextProps.coworkerData !== prevState.coworkerData) {
      return {
        companyCoworkers: nextProps.coworkerData,
      }
    }

    return []
  }

  handleClose = () => {
    const path = '/helpdesk'
    this.props.history.push(path)
  }

  onEditorStateChange = (html, name) => {
    this.setState({
      [name]: html,
    })
  }

  getInitialValues = (id) => {
    const { helpdesk } = this.props
    if (id) {
      return {
        helpdeskdepartment: helpdesk.department,
        helpdeskcategory: helpdesk.category,
        issuetitle: helpdesk.issue_title,
        description: helpdesk.description,
        issue_photo: helpdesk.issue_photo,
        severity_level: helpdesk.severity_level,
        editorState: '',
      }
    }
    return {
      helpdeskdepartment: '',
      helpdeskcategory: '',
      issuetitle: '',
      issue_photo: '',
      description: '',
      company_id: '',
      coworker_id: '',
      severity_level: 'low',
      // eslint-disable-next-line new-cap
    }
  }

  setDelImageUrl = (url) => {
    const { deletedKey } = this.state
    this.setState({ deletedKey: deletedKey.push(url) })
  }

  getCompaniesOptions = (companies) => {
    const FilterCompanyData = companies.filter(function (activeCompanydata) {
      return activeCompanydata.status !== 'inactive'
    })
    return FilterCompanyData
      ? FilterCompanyData.map((company) => {
          return {
            value: company._id,
            label: capitalize(company.display_name || company.company_name),
          }
        })
      : []
  }

  getCoworkerOptions = (coworker) => {
    const FilterCompanyData = coworker.filter(function (activeCompanydata) {
      return activeCompanydata.status !== 'inactive'
    })
    return FilterCompanyData
      ? FilterCompanyData.map((item) => {
          return {
            value: item._id,
            label: spacecapitalize(`${item.first_name} ${item.last_name}`),
          }
        })
      : []
  }

  handleCompanyChange = (id) => {
    if (id) {
      this.props.getCoworker(id)
    }
  }

  handleChange(event) {
    this.setState({
      severityLevel: event.target.value,
    })
  }

  render() {
    const { classes, coworkerData } = this.props
    const { id, companies } = this.state
    console.log(`coworkers`, localStorage.getItem('role'))
    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom style={{ marginLeft: '25px' }}>
          Help Desk
        </Typography>

        <div className={classes.lightbg}>
          <Formik
            initialValues={this.getInitialValues(id)}
            enableReinitialize
            validationSchema={HelpdeskSchema}
            onSubmit={(values, { setErrors, setSubmitting }) => {
              const submitValues = { ...values }
              const departmentVal = submitValues.helpdeskdepartment
                ? submitValues.helpdeskdepartment.value
                : ''
              submitValues.helpdeskdepartment_id = departmentVal
              const categoryVal = submitValues.helpdeskcategory
                ? submitValues.helpdeskcategory.value
                : ''
              submitValues.helpdeskcategory_id = categoryVal
              const issueTitleVal = submitValues.issuetitle
                ? submitValues.issuetitle.value
                : ''
              const companyData = submitValues.company_id
                ? submitValues.company_id.value
                : ''
              submitValues.company_id = companyData
              const coworkerValue = submitValues.coworker_id
                ? submitValues.coworker_id.value
                : ''
              submitValues.coworker_id = coworkerValue

              submitValues.company_id
                ? (submitValues.company_id = companyData)
                : delete submitValues.company_id
              submitValues.coworker_id
                ? (submitValues.coworker_id = companyData)
                : delete submitValues.coworker_id
              submitValues.issuetitle_id = issueTitleVal
              submitValues.severity_level = this.state.severityLevel
              submitValues.status = 'open'
              delete submitValues.editorState
              // console.log("valuesss", submitValues)
              this.props.addTicket(
                submitValues,
                setErrors,
                setSubmitting,
                this.props.history
              )
            }}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleSubmit,
              setFieldTouched,
            }) => (
              <Form
                onSubmit={handleSubmit}
                className={classes.form}
                encType="multipart/form-data"
                autoComplete="off"
              >
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6}>
                    <IssueDepartmentDropdown
                      values={values}
                      onChange={(name, value) => {
                        setFieldValue(name, value)
                        setFieldValue('helpdeskcategory', '')
                        setFieldValue('issuetitle', '')
                      }}
                      errors={errors.helpdeskdepartment}
                      touched={touched}
                      name="helpdeskdepartment"
                    />
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <IssueCategoryDropdown
                      values={values}
                      onChange={(name, value) => {
                        setFieldValue(name, value)
                        setFieldValue('issuetitle', '')
                      }}
                      errors={errors}
                      touched={touched}
                      name="helpdeskcategory"
                      noIntialListing
                      departmentData={values.helpdeskdepartment}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <IssueTitleDropdown
                      values={values}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      name="issuetitle"
                      noIntialListing
                      departmentData={values.helpdeskdepartment}
                      categoryData={values.helpdeskcategory}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <UploadField
                      label="Upload Photo"
                      name="issue_photo"
                      id="issue_photo"
                      onChange={setFieldValue}
                      value={values.issue_photo}
                      error={errors.issue_photo}
                      folder="helpdesk/issues"
                      delImageUrl={this.setDelImageUrl}
                    />
                  </Grid>
                  {checkPermission('COMMON_PERMISSION_FOR_ADMIN_CM') && (
                    <Grid item xs={6} sm={6}>
                      <SelectField
                        value={values.company_id}
                        name="company_id"
                        id="company_id"
                        label="Company"
                        onChange={(name, value) => {
                          setFieldValue(name, value)
                          this.handleCompanyChange(value.value)
                          setFieldValue('coworker_id', '')
                        }}
                        placeholder="Select Company Name"
                        openMenuOnClick
                        onBlur={setFieldTouched}
                        multi={false}
                        error={errors.company_id}
                        touched={touched.company_id}
                        options={this.getCompaniesOptions(companies)}
                      />
                    </Grid>
                  )}
                  {checkPermission('COMMON_PERMISSION_FOR_ADMIN_CM') && (
                    <Grid item xs={6} sm={6}>
                      <SelectField
                        value={values.coworker_id}
                        name="coworker_id"
                        id="coworker_id"
                        label="Coworker"
                        onChange={(name, value) => {
                          setFieldValue(name, value)
                        }}
                        openMenuOnClick
                        placeholder="Select Coworker Name"
                        onBlur={setFieldTouched}
                        multi={false}
                        error={errors.coworker_id}
                        touched={touched.coworker_id}
                        options={
                          coworkerData
                            ? this.getCoworkerOptions(coworkerData)
                            : []
                        }
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={12}>
                    <FormLabel className={classes.label} component="legend">
                      Description
                    </FormLabel>
                    <TextareaField
                      name="description"
                      className={classes.input_field}
                      rows="5"
                      placeholder="Add Comments"
                      maxlength="1000"
                    />
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <FormLabel className={classes.label} component="legend">
                      Severity Level
                    </FormLabel>
                    {/* <RadioField
                      name="severity_level"
                      // label="Severity Level"
                      id="severity_level"
                      options={securityLevelOptions}
                      RadioGroupProps={{
                        defaultValue: "free",
                      }}
                      style={{ marginTop: "0px" }}
                    /> */}
                    <RadioGroup row aria-label="position" name="severity_level">
                      <FormControlLabel
                        checked={this.state.severityLevel === 'low'}
                        onChange={this.handleChange}
                        value="low"
                        control={<LowRadio />}
                        label="Low"
                      />
                      <FormControlLabel
                        checked={this.state.severityLevel === 'medium'}
                        onChange={this.handleChange}
                        value="medium"
                        control={<MediumRadio />}
                        label="Medium"
                      />
                      <FormControlLabel
                        checked={this.state.severityLevel === 'high'}
                        onChange={this.handleChange}
                        value="high"
                        control={<HighRadio />}
                        label="High"
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>

                <div className={classes.formfooter}>
                  <Grid
                    container
                    item
                    display="flex"
                    justify="flex-end"
                    alignItems="center"
                    direction="row"
                  >
                    <Grid xs={6} sm={2}>
                      <Button
                        color="primary"
                        onClick={this.handleClose}
                        className={classes.button}
                        style={{ right: '-7px' }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid xs={6} sm={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        className={classes.Addbutton}
                      >
                        Create Ticket
                      </Button>
                    </Grid>
                  </Grid>
                  {/* {loading && <CircularLoader />} */}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  console.log(`state`, state)
  return {
    // ticket: state.tickets.ticket,
    auth: state.auth,
    errors: state.errors,
    companyData: state.company.companies,
    coworkerData: state.coworkers.companyCoworkers,
  }
}

export default connect(mapStateToProps, {
  addTicket,
  getCompanies,
  getCoworker,
})(withStyles(styles)(withRouter(HelpdeskForm)))
