import React, { useEffect, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { SelectField } from "../Form";
import { getAllIssueTitle } from "../../../Actions/issueTitleActions";
import { spacecapitalize } from "../../../Utils/StringFunctions";

const styles = () => ({});

const IssueTitleDropDown = (props) => {
  const {
    values,
    setFieldValue,
    setFieldTouched,
    errors,
    touched,
    noIntialListing,
    departmentData,
    categoryData,
  } = props;
  const [allIssueTitle, setallissueTitle] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);

  const isUpperCase = (str) => {
    return str === str.toUpperCase();
  };

  // eslint-disable-next-line no-shadow
  const getAvailableOptions = (allIssueTitle) => {
    return allIssueTitle
      ? // eslint-disable-next-line no-shadow
        allIssueTitle &&
          // eslint-disable-next-line no-shadow
          allIssueTitle.map((allIssueTitle) => {
            if (isUpperCase(allIssueTitle.issuetitle_name)) {
              return {
                value: allIssueTitle._id,
                label: allIssueTitle.issuetitle_name,
              };
            }
            return {
              value: allIssueTitle._id,
              label: spacecapitalize(allIssueTitle.issuetitle_name),
            };
          })
      : [];
  };
  useEffect(() => {
    if (!noIntialListing) {
      props.getAllIssueTitle();
    }
  }, []);

  useEffect(() => {
    if (
      noIntialListing &&
      props.departmentData &&
      props.departmentData.value !== "" &&
      props.categoryData &&
      props.categoryData.value !== ""
    ) {
      props.getAllIssueTitle(departmentData.value, categoryData.value);
    }
  }, [props.categoryData && props.categoryData.value]);

  useEffect(() => {
    setallissueTitle(props.allIssueTitle);
    if (values.helpdeskcategory && values.helpdeskdepartment) {
      // eslint-disable-next-line no-unused-expressions
      allIssueTitle &&
        allIssueTitle.map((title) => {
          if (title._id === values.issuetitle._id) {
            selectedValue.push({
              value: allIssueTitle._id,
              label: spacecapitalize(allIssueTitle.issuetitle_name),
            });
          }
          return null;
        });

      setSelectedValue(selectedValue);
    }
  }, [props.allIssueTitle]);

  useEffect(() => {
    setSelectedValue(values.issuetitle);
  }, [props.values]);

  const changeDropDown = (name, value) => {
    setSelectedValue(value);
    setFieldValue(name, value);
  };
  return (
    <SelectField
      value={selectedValue}
      name="issuetitle"
      id="issuetitle"
      label="Issue Title"
      onChange={changeDropDown}
      onBlur={setFieldTouched}
      error={errors.issuetitle}
      touched={touched.issuetitle}
      options={getAvailableOptions(allIssueTitle)}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    errors: state.errors,
    allIssueTitle: state.all_issue_title.all_issue_title,
  };
};

export default connect(mapStateToProps, { getAllIssueTitle })(
  withStyles(styles)(IssueTitleDropDown)
);
