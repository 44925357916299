import React, { useState } from "react";
import { MenuItem, withStyles, FormControl } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import { connect } from "react-redux";
import classNames from "classnames";
import { capitalize } from "../../Utils/StringFunctions";
import { updateCompanyStatus } from "../../Actions/companiesAction";
import CompanyStatusType from "./Types/CompanyStatus";
import DeleteAlert from "./DeleteAlert";

const styles = (theme) => {
  return {
    active: {
      backgroundColor: "#fff",
      color: "#4CAF50 !important",
      borderColor: "#4CAF50 !important",
    },
    inactive: {
      backgroundColor: "#fff",
      color: "#F44336 !important",
      borderColor: "#F44336 !important",
    },
    draft: {
      backgroundColor: "#fff",
      color: "#FFC312 !important",
      borderColor: "#FFC312 !important",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 50,
    },
    statusDropdown: {
      color: "#4CAF50",
      border: `1px solid ${"#4CAF50"}`,
      borderRadius: `${theme.shape.borderRadius * 4}px`,
      // padding: "0px 5px 3px 16px",
      height: "25px",
      padding: "13px 11px 13px 13px",
      fontSize: "10px",
      fontWeight: "500",
    },
    icon: {
      color: "#000",
      right: "10px",
      top: "1px",
    },
    inactiveStatus: {
      backgroundColor: "#fff",
      color: "#F44336 !important",
      borderColor: "#F44336 !important",
      border: `1px solid #05c46b`,
      borderRadius: "50px",
      height: "25px",
      padding: "4px 11px 13px 13px",
      fontSize: "10px",
      fontWeight: "500",
    },
  };
};
const CompanyStatus = (props) => {
  const [selected, setSelected] = useState(props.selectedItem);
  const [showConfirmDel, setshowConfirmDel] = useState(false);
  const { classes, id, selectedItem } = props;
  const handleDeletePlan = () => {
    // if (selected === "inactive") {
    props.updateCompanyStatus({
      status: "inactive",
      company_id: id,
    });
    setSelected("inactive");
    // }
  };

  const callStatusChangeAPI = (el) => {
    if (el.target.value === "inactive") {
      setshowConfirmDel(true);
    } else {
      props.updateCompanyStatus({
        status: el.target.value,
        company_id: id,
      });
      setSelected(el.target.value);
    }
  };

  return (
    <React.Fragment>
      <FormControl variant="filled" className={classes.formControl}>
        {selected === "inactive" ? (
          <React.Fragment>
            <div className={classes.inactiveStatus}>Inactive</div>
          </React.Fragment>
        ) : (
          <Select
            className={classNames(
              classes[selectedItem],
              classes.statusDropdown
            )}
            value={selectedItem}
            // value={selected}
            // onChange={handleDelete}
            onChange={callStatusChangeAPI}
            name="name"
            disableUnderline
            variant="standard"
            classes={{
              icon: classes.icon,
            }}
          >
            {CompanyStatusType(selectedItem) &&
              CompanyStatusType(selectedItem).map((element) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <MenuItem value={element} key={props.key}>
                    {/* {capitalize(element.toString())} */}

                    {capitalize(element)}
                  </MenuItem>
                );
              })}
          </Select>
        )}
      </FormControl>
      <DeleteAlert
        message="Are you sure you want to inactivate company?"
        open={showConfirmDel}
        setOpen={setshowConfirmDel}
        alert={handleDeletePlan}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    errors: state.errors,
    selected: state.selected,
  };
};

export default connect(mapStateToProps, { updateCompanyStatus })(
  withStyles(styles)(CompanyStatus)
);
