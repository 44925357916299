import {
  PLANS_LOADING,
  GET_AVAIL_PLANS,
  ADD_PLAN,
  PLAN_ERROR,
  GET_PLANS,
  GET_SINGLE_PLAN,
  EDIT_PLAN
} from "../Actions/types";

const initialState = {
  plans: null,
  plan: null,
  loading: false,
  get_single_plan: {},
  edit_plan: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_AVAIL_PLANS:
      return {
        ...state,
        loading: false,
        plans: action.payload && action.payload.data
      };
    case ADD_PLAN:
      return {
        ...state,
        loading: false,
        plan: { success: true, data: action.payload && action.payload.data }
      };
    case PLANS_LOADING:
      return {
        ...state,
        loading: true
      };
    case PLAN_ERROR:
      return {
        ...state,
        loading: false,
        plan: { success: false, data: action.payload && action.payload.data }
      };
    case GET_PLANS:
      return {
        ...state,
        loading: false,
        all_plans: action.payload && action.payload.data
      };
    case GET_SINGLE_PLAN:
      return {
        ...state,
        loading: false,
        get_single_plan: action.payload && action.payload.data
      };
    case EDIT_PLAN:
      return {
        ...state,
        loading: false,
        edit_plan: {
          success: true,
          data: action.payload && action.payload.data
        }
      };
    default:
      return state;
  }
}
