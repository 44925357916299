import React from "react";

const CafeteriaSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsxlink="http://www.w3.org/1999/xlink" width='100%' height='100%' viewBox="0 0 250 300">
    <defs>
      <linearGradient id="linear-gradient" x1="-3.316" y1="0.5" x2="-2.316" y2="0.5" gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#3076a1"/>
        <stop offset="1" stopColor="#639c38"/>
      </linearGradient>
      <linearGradient id="linear-gradient-2" x1="-12.985" y1="0.5" x2="-11.985" y2="0.5" xlinkhref="#linear-gradient"/>
      <linearGradient id="linear-gradient-3" x1="-10.88" y1="0.5" x2="-9.88" y2="0.5" xlinkhref="#linear-gradient"/>
      <clipPath id="clip-Cafeteria">
        <rect width="500" height="500"/>
      </clipPath>
    </defs>
    <g id="Cafeteria" clipPath="url(#clip-Cafeteria)">
      <rect width="500" height="500" fill="#fff"/>
      <g id="Group_866" data-name="Group 866">
        <g id="Group_865" data-name="Group 865" transform="translate(-946.468 -925.094)">
          <path id="Path_1781" data-name="Path 1781" d="M1330.032,1585.845c0,8.354-64.374,15.126-143.782,15.126s-143.782-6.772-143.782-15.126,64.374-15.126,143.782-15.126S1330.032,1577.491,1330.032,1585.845Z" transform="translate(0 -313.966)" fill="#d1e1e8"/>
          <g id="Group_823" data-name="Group 823" transform="translate(1224.734 1091.758)">
            <g id="Group_816" data-name="Group 816" transform="translate(47.34 45.98)">
              <path id="Path_1782" data-name="Path 1782" d="M1647.112,1358.246c-3.42-15.635,3.972-19.536,11.121-23.309,5.842-3.083,11.361-6,11.067-15.21-.39-12.215,7.931-16.659,15.272-20.58,5.462-2.917,10.621-5.673,11.391-11.23,1.852-13.374,14.187-30,14.311-30.162l.766.573c-.123.164-12.312,16.592-14.13,29.72-.836,6.041-6.2,8.908-11.887,11.943-7.442,3.974-15.138,8.085-14.767,19.706.313,9.812-5.731,13-11.576,16.086-7.147,3.772-13.9,7.334-10.633,22.259Z" transform="translate(-1646.25 -1257.754)" fill="url(#linear-gradient)"/>
            </g>
            <g id="Group_817" data-name="Group 817" transform="translate(41.712 31.075)">
              <path id="Path_1783" data-name="Path 1783" d="M1641.695,1329.971q-.246,0-.494-.018c-3.092-.229-6.123-2.578-9.538-7.391l-.214-.3.239-.284c.055-.066,5.53-6.738,3.519-19.263a58.47,58.47,0,0,0-1.6-6.956c-.735-2.621-1.22-4.353-.407-5.735,1.032-1.754,3.822-2.673,11.683-4.545,8.371-1.993,11.169-11.424,13.639-19.745,2.325-7.836,4.527-15.228,11.04-15,5.985.229,15.074-9.15,23.094-17.416,7.441-7.671,14.464-14.9,18.954-14.755a3.58,3.58,0,0,1,2.7,1.343c4,4.768.269,12-3.343,18.994-3.081,5.967-5.991,11.6-4.118,15.32,2.354,4.671,2.683,8.9.952,12.221-2.048,3.934-6.822,6.47-14.19,7.537-6.737.976-7.465,8.325-8.237,16.1-.9,9.077-1.919,19.366-13.224,20.344-8.4.727-14.054,6.62-19.046,11.82C1649.14,1326.377,1645.689,1329.971,1641.695,1329.971Zm-9.045-7.674c3.112,4.309,5.934,6.5,8.622,6.7,3.757.279,7.182-3.289,11.148-7.419,5.109-5.322,10.9-11.353,19.654-12.11,10.513-.91,11.449-10.354,12.354-19.485.809-8.163,1.573-15.872,9.051-16.956,7.046-1.021,11.581-3.387,13.479-7.033,1.583-3.039,1.251-6.964-.958-11.348-2.093-4.153.926-10,4.123-16.189,3.468-6.716,7.053-13.661,3.46-17.94a2.628,2.628,0,0,0-2.006-1c-4.059-.135-10.945,6.951-18.233,14.466-8.448,8.709-17.18,17.71-23.571,17.711-.082,0-.163,0-.245,0-5.775-.215-7.775,6.52-10.088,14.314-2.543,8.57-5.425,18.282-14.334,20.4-7.108,1.693-10.219,2.636-11.08,4.1-.607,1.032-.189,2.522.5,4.992a59.355,59.355,0,0,1,1.623,7.062C1638.021,1314.2,1633.732,1320.841,1632.65,1322.3Z" transform="translate(-1631.449 -1218.558)" fill="#55ae71"/>
            </g>
            <g id="Group_818" data-name="Group 818" transform="translate(22.277 8.754)">
              <path id="Path_1784" data-name="Path 1784" d="M1610.373,1290.32q-.168,0-.336-.015c-5.071-.462-8.97-11.57-12.741-22.313a107.044,107.044,0,0,0-5.123-13c-1.276-2.4-2.9-4.9-4.467-7.312-4.177-6.437-8.5-13.092-7.091-18.932.739-3.072,2.945-5.629,6.742-7.817,8.31-4.789,7.032-7.494,4.231-13.419-2.056-4.349-4.871-10.305-4.979-19.678-.047-4.028-.14-7.511-.222-10.583-.265-9.878-.4-14.836,1.711-16.63.873-.743,2.1-.934,3.844-.6,2.991.569,4.284,2.984,5.654,5.541,1.747,3.262,3.727,6.959,9.7,8.211,5.608,1.173,11,2.773,12.491,6.5,1.047,2.621.056,6.067-3.03,10.536a3.424,3.424,0,0,0-.785,2.817c.489,1.8,3.283,3.1,6.519,4.614,4.605,2.148,10.336,4.822,11.959,10.359,1.308,4.463-.205,10.029-4.627,17.017-10.459,16.532-10.039,27.743-9.668,37.635.275,7.33.535,14.253-3.886,21.989C1614.322,1288.653,1612.388,1290.32,1610.373,1290.32Zm-19.931-129.5a2.559,2.559,0,0,0-1.727.531c-1.76,1.5-1.624,6.6-1.374,15.875.082,3.076.176,6.563.223,10.6.106,9.163,2.87,15.01,4.888,19.28,2.779,5.879,4.456,9.428-4.618,14.657-3.557,2.05-5.615,4.409-6.289,7.212-1.309,5.44,2.9,11.921,6.964,18.188,1.579,2.434,3.212,4.95,4.508,7.383a107.477,107.477,0,0,1,5.182,13.131c3.5,9.971,7.467,21.272,11.925,21.677,1.7.152,3.491-1.388,5.319-4.587,4.285-7.5,4.042-13.976,3.76-21.478-.376-10.031-.8-21.4,9.816-38.183,4.266-6.742,5.743-12.053,4.517-16.236-1.5-5.122-6.784-7.587-11.445-9.762-3.452-1.61-6.433-3-7.038-5.23a4.3,4.3,0,0,1,.921-3.612c2.89-4.185,3.847-7.337,2.929-9.637-1.325-3.316-6.708-4.853-11.8-5.918-6.413-1.342-8.6-5.42-10.351-8.7-1.313-2.452-2.447-4.569-4.989-5.052A7.094,7.094,0,0,0,1590.442,1160.819Z" transform="translate(-1580.343 -1159.863)" fill="#55ae71"/>
            </g>
            <g id="Group_819" data-name="Group 819" transform="translate(37.443 26.424)">
              <path id="Path_1785" data-name="Path 1785" d="M1635.188,1325.045l-.912-.289c5.113-16.158-4.134-52.408-6.891-61.231-1.355-4.339-.1-10.87,1.109-17.185,1.564-8.157,3.182-16.591-.943-19.753-7.746-5.938-7.338-19.676-7.317-20.258l.956.035c0,.137-.409,13.827,6.942,19.464,4.594,3.522,2.919,12.251,1.3,20.693-1.189,6.2-2.419,12.61-1.135,16.719C1631.706,1274.151,1640.288,1308.926,1635.188,1325.045Z" transform="translate(-1620.224 -1206.329)" fill="url(#linear-gradient-2)"/>
            </g>
            <path id="Path_1786" data-name="Path 1786" d="M1535.5,1236.875c-2.128-5.4-10.44-4.583-9.676,3.056s6.111,15.787-2.037,28.773,10.44,15.532,17.06,23.68,8.912,19.352,14.514,26.481,9.421,24.19,14,17.824.27-14.644.779-26.1,10.708-18.713.509-26.1-20.456-9.648-17.089-26.3C1555.053,1250.841,1542.124,1253.681,1535.5,1236.875Z" transform="translate(-1521.762 -1196.684)" fill="#72bd87"/>
            <g id="Group_820" data-name="Group 820" transform="translate(16.839 59.694)">
              <path id="Path_1787" data-name="Path 1787" d="M1604.232,1375.6c-.056-.338-5.719-33.9-18.4-43.1-12.929-9.379-15.277-18.045-19.793-38.477l.934-.207c4.46,20.18,6.774,28.736,19.42,37.91,13,9.428,18.549,42.323,18.779,43.719Z" transform="translate(-1566.043 -1293.816)" fill="#fff"/>
            </g>
            <path id="Path_1788" data-name="Path 1788" d="M1620.408,1277.118s3.767-9.315-3.088-22.558-12.406-11.205,4.121-21.392.1-42.274,14.172-46.348,26.292-56.01,36.989-49.4-3.566,32.08,4.074,39.72,8.659,17.362-7.13,25.234,11.2,37.922-10.187,47.09S1644.1,1294.583,1620.408,1277.118Z" transform="translate(-1576.819 -1136.842)" fill="#55ae71"/>
            <g id="Group_821" data-name="Group 821" transform="translate(49.759 30.297)">
              <path id="Path_1789" data-name="Path 1789" d="M1652.976,1336.371c-.024-.118-2.324-12.047,4.944-27.46,7.4-15.7,10.948-32.866,9.479-45.917-.8-7.067,3.732-9.831,8.111-12.5,4.311-2.632,8.769-5.354,8.522-12.316-.222-6.291,2.44-21.037,2.554-21.662l.941.171c-.028.152-2.757,15.269-2.539,21.457.265,7.522-4.434,10.391-8.98,13.167-4.313,2.633-8.385,5.12-7.659,11.581,1.487,13.219-2.089,30.577-9.564,46.431-7.124,15.109-4.9,26.747-4.871,26.863Z" transform="translate(-1652.611 -1216.511)" fill="#fff"/>
            </g>
            <path id="Path_1790" data-name="Path 1790" d="M1664.423,1353.069c4.8-4.462,11.157-5.106,9.834,1.513s-5.862,8.51-3.593,12.671,6.051,8.32-.378,13.427-10.023,5.673-11.914,15.317-1.513,16.231-9.834,16.642-5.106-11.725-2.459-17.209a18.194,18.194,0,0,0,1.324-14.476c-2.647-8.218-7.754-12.655,3.026-15.448S1661.185,1356.076,1664.423,1353.069Z" transform="translate(-1597.014 -1268.732)" fill="#fff"/>
            <g id="Group_822" data-name="Group 822" transform="translate(52.094 88.898)">
              <path id="Path_1791" data-name="Path 1791" d="M1658.783,1431.785c-.027-1.18-.6-28.963,4.6-33.639,5.307-4.777,6.327-13.217,6.462-17.823.177-6,7.93-9.563,8.26-9.713l.393.872c-.075.034-7.538,3.467-7.7,8.869-.171,5.813-1.486,13.742-6.779,18.506-4.873,4.386-4.288,32.621-4.281,32.906Z" transform="translate(-1658.752 -1370.611)" fill="url(#linear-gradient-3)"/>
            </g>
            <path id="Path_1792" data-name="Path 1792" d="M1650.67,1501.66l-8.62,32.7h-19.263l-8.62-32.7Z" transform="translate(-1579.028 -1362.927)" fill="#f8d6c5"/>
          </g>
          <path id="Path_1793" data-name="Path 1793" d="M1337.244,1270.146v-1.117h-17.335v1.117l7.548,4.372.852,12.507-2.731.872v.425h6v-.425l-2.731-.872.852-12.507Z" transform="translate(-171.936 -127.003)" fill="#e6e3df"/>
          <g id="Group_824" data-name="Group 824" transform="translate(1260.698 1198.87)">
            <path id="Path_1794" data-name="Path 1794" d="M1626.02,1483.211l-9.686-64.418,1.865-.288,9.686,64.418Z" transform="translate(-1616.334 -1418.506)" fill="#343140"/>
          </g>
          <g id="Group_825" data-name="Group 825" transform="translate(1248.87 1198.87)">
            <path id="Path_1795" data-name="Path 1795" d="M1594.917,1483.211l-9.686-64.418,1.865-.288,9.686,64.418Z" transform="translate(-1585.231 -1418.506)" fill="#343140"/>
          </g>
          <g id="Group_826" data-name="Group 826" transform="translate(1198.432 1198.87)">
            <path id="Path_1796" data-name="Path 1796" d="M1454.463,1483.211l-1.865-.287,9.686-64.418,1.865.288Z" transform="translate(-1452.598 -1418.506)" fill="#343140"/>
          </g>
          <path id="Path_1797" data-name="Path 1797" d="M1583.042,1303.105l7.587-67.284s-13.271-1.463-21.29-1.1l-12.052,68.387Z" transform="translate(-319.044 -105.704)" fill="#4e4864"/>
          <rect id="Rectangle_303" data-name="Rectangle 303" width="25.754" height="6.491" transform="translate(1238.244 1193.344)" fill="#e2d2ca"/>
          <path id="Path_1798" data-name="Path 1798" d="M1506.423,1300.5l-4.025-64.118s33.586-3.074,54.215-.424Z" transform="translate(-285.028 -105.839)" fill="#4e4864"/>
          <path id="Path_1799" data-name="Path 1799" d="M1542.357,1543.672s-.308,10.748,1.227,11.394,7.344-3.09,9.905-6.787-3.21-12.3-4.157-17.044c-.4-1.987-4.911-5.561-7.112-4.537a10.748,10.748,0,0,0-3.807,3.648S1544.013,1534.184,1542.357,1543.672Z" transform="translate(-307.347 -286.573)" fill="#000719"/>
          <g id="Group_827" data-name="Group 827" transform="translate(1231.066 1240.125)">
            <path id="Path_1800" data-name="Path 1800" d="M1538.413,1530.641a59.276,59.276,0,0,1,2.886,6.513c.98,2.846,1.163,10.727,1.78,11.273s5.485-1.908,6.134-4.855-1.324-8.837-3.2-11.934a19.227,19.227,0,0,0-3.79-4.646S1538.9,1527.638,1538.413,1530.641Z" transform="translate(-1538.413 -1526.993)" fill="#de9673"/>
          </g>
          <path id="Path_1801" data-name="Path 1801" d="M1487.94,1311.375s.243,4.726-2.314,7.871-19.754,11.782-19.629,16.772,3.276,12.8,4.408,14.78,5.308,10.2,7.478,15.013,14.717,27.829,17.169,33.017.944-20.754.944-20.754l-12.547-36.791,29.315-28.559S1511.531,1304.421,1487.94,1311.375Z" transform="translate(-262.469 -151.525)" fill="#de9673"/>
          <path id="Path_1802" data-name="Path 1802" d="M1539.705,1318.846s-.566,3.71.943,5.283,8.462,10.74,4.78,19.749c-3.381,8.271-9.937,8.113-9.937,8.113h-10.147s2.946,14.888,1.995,24.7-5.427,32.214-5.521,33.347-4.29,4.671-5.516,2.878c0,0-.114-13.49-.774-18.584s-3.471-25.471-4.32-28.489-5.849-12.169-5.849-13.113-4.884-15.712,5.776-19.486S1525.563,1318.925,1539.705,1318.846Z" transform="translate(-286.171 -157.875)" fill="#eea886"/>
          <path id="Path_1803" data-name="Path 1803" d="M1512.446,1571.633s-7,8.163-6.212,9.631,7.653,2.215,11.97.952,6.96-11.056,7.486-15.867c.313-2.868-.32-7.412-2.676-8a10.753,10.753,0,0,0-5.255.443S1519.7,1565.3,1512.446,1571.633Z" transform="translate(-287.367 -306.188)" fill="#000719"/>
          <g id="Group_828" data-name="Group 828" transform="translate(1222.832 1251.572)">
            <path id="Path_1804" data-name="Path 1804" d="M1524.321,1558.127a59.267,59.267,0,0,1-1.852,6.879c-1.028,2.829-5.841,9.071-5.7,9.885s5.465,1.965,7.823.082,4.528-7.7,5.014-11.293a19.232,19.232,0,0,0-.024-6S1526.59,1556.1,1524.321,1558.127Z" transform="translate(-1516.761 -1557.094)" fill="#eea886"/>
          </g>
          <path id="Path_1805" data-name="Path 1805" d="M1521.656,1337.528c-3.192,8.381-9.937,8.113-9.937,8.113h-10.147s-4.233-6.708-14.6-7.86a30.651,30.651,0,0,0-7.817.186l-.106.015c-13.771,2.039-11.112,7.094-11.07,7.227-1.144-2.795-2.346-7.239-2.057-9.631,1.321-10.9,17.071-13.187,19.629-16.331s2.314-7.871,2.314-7.871c23.591-6.955,24.824,1.35,24.824,1.35,1.034-.143,3.684-1.669,4.811-1.676,0,0,1.181,2.578,2.013,4.906C1520.252,1318.008,1525.12,1328.433,1521.656,1337.528Z" transform="translate(-262.399 -151.525)" fill="#d44040"/>
          <path id="Path_1806" data-name="Path 1806" d="M1498.563,1180.243a10.854,10.854,0,0,0-1.515-.375,42.737,42.737,0,0,0-6.079,2.089c-8.4,3.222-22.466,9.041-22.466,9.041s-2.624,10.734-4.049,14.958v0a5.921,5.921,0,0,1-.709,1.645c-.9.808-13.825.538-22.173,0s-8.5-.425-8.5-.425-1.334-5.195,1.631-5.822,16.834-1.113,18.987-1.562,3.593.179,4.22-1.974c.33-1.132.983-5.067,1.748-8.919.319-1.611.66-3.2,1.006-4.577v0a15.858,15.858,0,0,1,1.195-3.556c.991-1.692,5.371-5.812,8.6-8.733.007,0,.007-.007.007-.007,1.741-1.57,3.151-2.8,3.522-3.111,1.061-.9,9.539-3.325,9.539-3.325s5.437,1.266,8.774,1.993c.809.174,1.492.319,1.948.408.724.141,2.483.712,4.625,1.448l0,0C1499.71,1171.25,1499.25,1175.882,1498.563,1180.243Z" transform="translate(-241.88 -62.899)" fill="#eea886"/>
          <g id="Group_829" data-name="Group 829" transform="translate(1235.482 1064.094)">
            <path id="Path_1807" data-name="Path 1807" d="M1551.607,1065.539s3.834-3.625,8.71.608,3.648,9.344,4.388,13.908-2.028,15.474.5,19.621.816,6.882.816,6.882-12.877,6.024-15.148-14.628S1551.607,1065.539,1551.607,1065.539Z" transform="translate(-1550.026 -1064.094)" fill="#000719"/>
          </g>
          <g id="Group_830" data-name="Group 830" transform="translate(1218.603 1090.624)">
            <path id="Path_1808" data-name="Path 1808" d="M1531.54,1141.084s.275,2.5,1.245,3.439,8.992,2.409,11.005,4.845-.922,15.947-1.622,19.588-4.289,11.383-4.053,14a69.413,69.413,0,0,0,2.441,11.426c.77,1.981,3.192,10.46,3.745,12.077,0,0-18.984,6.74-33.07,2.868,0,0,.176-13.536-.247-16.3s-2.118-12.789-2.157-15.623-4.5-5.414-2.8-10.548c1.493-4.507,8.8-13.417,10.14-15.172s4.717-5.188,4.717-5.188,1.751-4.626,1.092-7.466l9.28-5.175A57.523,57.523,0,0,0,1531.54,1141.084Z" transform="translate(-1505.641 -1133.858)" fill="#d44040"/>
          </g>
          <g id="Group_831" data-name="Group 831" transform="translate(1235.34 1105.813)">
            <path id="Path_1809" data-name="Path 1809" d="M1570.429,1174.31c-.968-.115-4.614-2.112-6.2,2.667s-.972,10.383-.543,12.981,1.379,15.2,1.4,17.326-.991,3.361-2.9,6.265-11.626,21.485-12.529,22.693l4.627,1.983s16.571-19.56,17.888-22.543,1.943-5.735,2.168-11.435,1.814-19.869,1.563-21.993S1575.536,1174.916,1570.429,1174.31Z" transform="translate(-1549.653 -1173.8)" fill="#eea987"/>
          </g>
          <g id="Group_832" data-name="Group 832" transform="translate(1248.252 1104.755)">
            <path id="Path_1810" data-name="Path 1810" d="M1583.607,1190.987s8.334-1.482,13.624.12c0,0,3.468-17.75-6.443-19.942C1582.685,1169.371,1583.83,1184.679,1583.607,1190.987Z" transform="translate(-1583.607 -1171.019)" fill="#d44040"/>
          </g>
          <g id="Group_833" data-name="Group 833" transform="translate(1230.49 1089.054)">
            <path id="Path_1811" data-name="Path 1811" d="M1552.158,1141.966s-5.433,9.412-12.3,9.855.4-7.882.4-7.882.269-.97.724-2.381a10.751,10.751,0,0,0,.361-2.139h0a13.369,13.369,0,0,0,0-2.943l9.474-6.746S1550.328,1139.858,1552.158,1141.966Z" transform="translate(-1536.9 -1129.73)" fill="#eea987"/>
          </g>
          <g id="Group_834" data-name="Group 834" transform="translate(1223.653 1065.93)">
            <path id="Path_1812" data-name="Path 1812" d="M1531.614,1101.668c-1.635.248-6.269.575-10.481-9.446-5.146-12.243-.359-20,4.579-22.207s10.046-1.309,13.954,4.86c0,0,2.918,4.834,1.529,14.69S1533.365,1101.4,1531.614,1101.668Z" transform="translate(-1518.919 -1068.923)" fill="#eea987"/>
          </g>
          <g id="Group_835" data-name="Group 835" transform="translate(1222.697 1065.275)">
            <path id="Path_1813" data-name="Path 1813" d="M1529.787,1076.454s-6.861,1.745-11.481,12.66c0,0-5.227-11.094,1.662-17.911s13.283-3.285,15.649-1.329c0,0,2.883.113,3.962,5.338s6.029,12.333-1,17.547c0,0-.325-5.986-1.265-7.226S1534.189,1085.665,1529.787,1076.454Z" transform="translate(-1516.406 -1067.199)" fill="#000719"/>
          </g>
          <g id="Group_836" data-name="Group 836" transform="translate(1242.644 1080.273)">
            <path id="Path_1814" data-name="Path 1814" d="M1569,1107.722c.178-.973,1.063-1.52,1.773-.646s.8,4.764-.526,5.443S1568.643,1109.7,1569,1107.722Z" transform="translate(-1568.86 -1106.638)" fill="#eea987"/>
          </g>
          <g id="Group_837" data-name="Group 837" transform="translate(1234.572 1095.354)">
            <path id="Path_1815" data-name="Path 1815" d="M1555.22,1146.3a8.776,8.776,0,0,1-7.588,5.529,17.112,17.112,0,0,0,.361-2.139h0S1551.594,1150.161,1555.22,1146.3Z" transform="translate(-1547.632 -1146.297)" fill="#a77860"/>
          </g>
          <path id="Path_1816" data-name="Path 1816" d="M1519.191,1171.947s-11.063,3.516-13.429,13.29l4.268,1.577Z" transform="translate(-287.113 -66.839)" fill="#d44040"/>
          <g id="Group_838" data-name="Group 838" transform="translate(1226.491 1163.64)">
            <path id="Path_1817" data-name="Path 1817" d="M1537.827,1325.865s-1.389,3.394-2.244,3.78a20.71,20.71,0,0,0-2.639,1.644c-.717.482-3.062,1.174-3.633,1.9a26.354,26.354,0,0,1-1.537,3.143c-.535.561-1.743,1.588-1.293,1.965s1.751-.283,1.751-.283-.459.537-.186,1a1.313,1.313,0,0,0,1.243.8s-.143.8.183,1.111a1.737,1.737,0,0,0,1.408.556,1.484,1.484,0,0,0,.722.9,1.708,1.708,0,0,0,1.6-.1s.8.768,1.432.446a5.207,5.207,0,0,0,1.407-1.274,25.83,25.83,0,0,0,4.094-8.152,17.975,17.975,0,0,1,2.847-6.2Z" transform="translate(-1526.382 -1325.865)" fill="#eea886"/>
          </g>
          <path id="Path_1818" data-name="Path 1818" d="M1405.1,1577.222a21.268,21.268,0,0,0,3.6,4.229c1.531,1.094,9.625,5.542,11.01,5.76s8.75,1.021,9.188,3.5-5.542,3.719-12.833,3.355-17.427.927-18.375.063-1.458-6.48-.656-10.052a22.6,22.6,0,0,0,.438-6.745Z" transform="translate(-219.487 -317.996)" fill="#eea886"/>
          <path id="Path_1819" data-name="Path 1819" d="M1419.725,1603.49a14.253,14.253,0,0,1-8.969,1.532c-5.177-.8-12.148-1.9-14.1-1.241,0,0-.124,5.3,1.043,6.626s15.242-.145,16.258-.125,15.242,1.52,14.95-3.293C1428.913,1606.99,1430.3,1604.511,1419.725,1603.49Z" transform="translate(-219.499 -334.275)" fill="#000719"/>
          <path id="Path_1820" data-name="Path 1820" d="M1314.685,1577.748c-.729,3.137-13.562,3.137-14.984.584s-12.431-11.229-12.944-14.218a8.928,8.928,0,0,1,1.028-4.911,12.05,12.05,0,0,1,5.165-5.661c4.077-1.966,6.751,6.525,6.751,6.525a20.952,20.952,0,0,0,1.2,3.974c.584,1.02,4.959,8.751,6.709,9.332S1315.413,1574.613,1314.685,1577.748Z" transform="translate(-151.353 -303.138)" fill="#eea886"/>
          <path id="Path_1821" data-name="Path 1821" d="M1314.685,1587.455c-.729,3.137-13.562,3.137-14.984.584s-12.431-11.229-12.944-14.218a8.928,8.928,0,0,1,1.028-4.911c1.54.12,13.265,13.514,15.671,14.682a3.744,3.744,0,0,0,4.156-.512C1309.362,1583.665,1315.413,1584.32,1314.685,1587.455Z" transform="translate(-151.353 -312.845)" fill="#000719"/>
          <path id="Path_1822" data-name="Path 1822" d="M1284.094,1301.49s7.665-.57,9.815-2.085,16.626,4.335,16.891,5.677a3.812,3.812,0,0,1-.005,2l-28.443-.071Z" transform="translate(-148.661 -145.673)" fill="#eea987"/>
          <path id="Path_1823" data-name="Path 1823" d="M1333.974,1409.979l-11.552,25.989c-.808,1.822-1.563,3.519-2.222,5-.771,1.736-1.412,3.175-1.853,4.168-3.062,6.89-10.417,22.323-10.417,22.323s-6.7.288-7.027-4.525c0,0,6.29-10.909,6.29-18.235s.983-28.913,7.984-33.579C1323.713,1405.431,1329.257,1404.354,1333.974,1409.979Z" transform="translate(-160.157 -212.051)" fill="#eea987"/>
          <path id="Path_1824" data-name="Path 1824" d="M1166.127,1331.594a7.833,7.833,0,0,0,2.625,1.75c1.2.328,21.328,1.859,30.624,4.922s21.656,5.031,23.953,7.656,6.125,17.062,6.016,19.8,10.284,53,11.315,56.593c.547,1.906-8.034-.483-8.034-.483s-12.984-29.751-15.422-34.781c-4.48-9.242-4.7-20.562-6.344-22.312s-19.906-5.687-30.406-5.687-34.015.875-36.531-.548-18.266-11.812-11.7-25.7S1166.127,1331.594,1166.127,1331.594Z" transform="translate(-54.638 -162.491)" fill="#eea987"/>
          <path id="Path_1825" data-name="Path 1825" d="M1258.092,1119.612s-8.868,24.161-14.715,31.487-3.461-19.856-3.461-19.856S1250.059,1115.435,1258.092,1119.612Z" transform="translate(-122.063 -33.97)" fill="#000719"/>
          <path id="Path_1826" data-name="Path 1826" d="M1228.51,1082s3.3-18.466-17.112-17.358c-20.213,1.1-9.6,28.618-13.546,34.1s-8.284,10.044-9.291,15.781a29.559,29.559,0,0,0,2.542,17.866C1197.183,1145.051,1213.172,1076.236,1228.51,1082Z" transform="translate(-90.274 -0.312)" fill="#000719"/>
          <path id="Path_1827" data-name="Path 1827" d="M1235.481,1074.036c4-.244,12.9,1.657,14.4,12.241a33.011,33.011,0,0,1-.949,5.117c-.665,2.254,1.43,8.85.909,9.183a14.574,14.574,0,0,1-2.686.778s-2.243,7.263-4.783,7.233-12.178-1.894-16.819-10.836S1219.336,1075.021,1235.481,1074.036Z" transform="translate(-111.501 -6.149)" fill="#eea886"/>
          <path id="Path_1828" data-name="Path 1828" d="M1234.511,1141.561s-.583.887-1.344,2.231c-.384.689-.821,1.5-1.251,2.363-1.178,2.4-2.3,5.29-2.218,7.455,0,0-5.932.3-7.978-8.561,0,0,4.217-7.673,3.291-13.346-.92-5.594,9.229,9.454,9.494,9.851Z" transform="translate(-111.086 -41.127)" fill="#eea886"/>
          <path id="Path_1829" data-name="Path 1829" d="M1247.888,1083.524s-6.439,4.508-12.872,5.484-11.509,7.987-11.509,7.987-8.928-12.5-1.141-22.558S1249.135,1071.149,1247.888,1083.524Z" transform="translate(-109.51 -3.396)" fill="#000719"/>
          <path id="Path_1830" data-name="Path 1830" d="M1246.944,1116.609c-.051-1.248-1.463-3.1-3.486-1.372s.8,7.629,2.47,7.026S1247,1117.9,1246.944,1116.609Z" transform="translate(-124.122 -31.259)" fill="#eea886"/>
          <path id="Path_1831" data-name="Path 1831" d="M1185.575,1196.424c-2.664,4.93-5.851,4.328-6.8,8.267a30.686,30.686,0,0,0-.549,3.267,10.393,10.393,0,0,1-.9,3.138,26.523,26.523,0,0,1-2.223,3.746,30.879,30.879,0,0,0-1.975,3.358c-.07.145-.145.286-.215.426a7.707,7.707,0,0,0-.468,2.245c-.237,1.518-.442,3.186-.539,4.392-.252,3.229-.565,5.969-.565,5.969s-23.515,5.033-36.529-.549c0,0,7.766-13.672,11.157-18.155s8.31-30.075,9.075-35.436,8-15.786,14.129-15.566l8.073,5.667s5.4,14.236,6.932,16.421S1188.277,1191.429,1185.575,1196.424Z" transform="translate(-57.226 -60.378)" fill="#214361"/>
          <path id="Path_1832" data-name="Path 1832" d="M1199.132,1344.573s-4.026,27.274-6.049,28.243c-2.67,1.278-55.583,1.97-61.3-5.239s-6.475-20.4-1.867-28.3S1199.132,1344.573,1199.132,1344.573Z" transform="translate(-52.337 -168.977)" fill="#214361"/>
          <path id="Path_1833" data-name="Path 1833" d="M1350.155,1457.818c-.808,1.822-1.563,3.519-2.222,5-2.479-7.971-2.275-21.295-2.275-21.295A75.718,75.718,0,0,0,1350.155,1457.818Z" transform="translate(-187.891 -233.901)" fill="#a77860"/>
          <path id="Path_1834" data-name="Path 1834" d="M1233.819,1257.774a10.4,10.4,0,0,1-.9,3.138c-2.444.134-6.76.237-8.057-.613-1.835-1.211,4.263-18.392,4.263-18.392s-.4,11.082-.36,12.471S1233.819,1257.774,1233.819,1257.774Z" transform="translate(-112.817 -110.195)" fill="#14293b"/>
          <path id="Path_1835" data-name="Path 1835" d="M1205.175,1168.343c3.37.333,9.351,4.471,7.979,13.531s-1.276,14.365-1.967,16.713-1.863,13.23-1.863,13.23,6.181,2.145,9.858,3.57c7.025,2.723,18.207,3.986,18.207,3.986L1237,1225.9s-35.072.6-38.085-1.233-2.239-10.137-2.521-15.177.739-24.388.28-26.527S1196.389,1167.474,1205.175,1168.343Z" transform="translate(-95.363 -64.58)" fill="#eea987"/>
          <g id="Group_840" data-name="Group 840" transform="translate(1199.982 1163.392)">
            <g id="Group_839" data-name="Group 839" transform="translate(0)">
              <rect id="Rectangle_304" data-name="Rectangle 304" width="5.805" height="112.778" transform="translate(5.805 112.778) rotate(180)" fill="#c4b6af"/>
            </g>
          </g>
          <g id="Group_842" data-name="Group 842" transform="translate(1196.616 1274.25)">
            <g id="Group_841" data-name="Group 841" transform="translate(0 0)">
              <rect id="Rectangle_305" data-name="Rectangle 305" width="12.536" height="1.921" transform="translate(12.536 1.921) rotate(180)" fill="#c4b6af"/>
            </g>
          </g>
          <g id="Group_844" data-name="Group 844" transform="translate(1062.301 1215.838)">
            <g id="Group_843" data-name="Group 843" transform="translate(0 0)">
              <path id="Path_1836" data-name="Path 1836" d="M1112.241,1463.257l-14.439,60.2h-3.18l12.244-60.332Z" transform="translate(-1094.622 -1463.127)" fill="#343140"/>
            </g>
          </g>
          <g id="Group_846" data-name="Group 846" transform="translate(1078.053 1215.838)">
            <g id="Group_845" data-name="Group 845" transform="translate(0 0)">
              <path id="Path_1837" data-name="Path 1837" d="M1153.663,1463.257l-14.439,60.2h-3.18l12.244-60.332Z" transform="translate(-1136.044 -1463.127)" fill="#343140"/>
            </g>
          </g>
          <g id="Group_848" data-name="Group 848" transform="translate(1121.149 1215.838)">
            <g id="Group_847" data-name="Group 847" transform="translate(0 0)">
              <path id="Path_1838" data-name="Path 1838" d="M1249.37,1463.257l14.439,60.2h3.18l-12.244-60.332Z" transform="translate(-1249.37 -1463.127)" fill="#343140"/>
            </g>
          </g>
          <g id="Group_850" data-name="Group 850" transform="translate(1055.504 1136.978)">
            <g id="Group_849" data-name="Group 849" transform="translate(0 0)">
              <path id="Path_1839" data-name="Path 1839" d="M1156.058,1335.329h-62.215s-4.333-.1-7.235-12.936c-3.265-14.441-9.859-62.675-9.859-62.675s1.749-4.489,29.1-3.914l48.976,63.544S1156.5,1329.366,1156.058,1335.329Z" transform="translate(-1076.749 -1255.753)" fill="#4e4864"/>
            </g>
          </g>
          <g id="Group_852" data-name="Group 852" transform="translate(1122.615 1161.341)">
            <g id="Group_851" data-name="Group 851" transform="translate(0 0)">
              <rect id="Rectangle_306" data-name="Rectangle 306" width="160.54" height="4.101" transform="translate(160.54 4.101) rotate(180)" fill="#f8d6c5"/>
            </g>
          </g>
          <path id="Path_1840" data-name="Path 1840" d="M1243.515,1150.539s-.6.865-1.36,2.209a15.709,15.709,0,0,1-5.932-7.838,27,27,0,0,0,7.292,5.63Z" transform="translate(-120.074 -50.083)" fill="#a77860"/>
          <g id="Group_856" data-name="Group 856" transform="translate(1181.496 1134.523)">
            <g id="Group_855" data-name="Group 855" transform="translate(0 0)">
              <g id="Group_854" data-name="Group 854">
                <g id="Group_853" data-name="Group 853">
                  <path id="Path_1841" data-name="Path 1841" d="M1419.39,1253.233s-3.3-2.815-3.708-3.21-1.8-.621-3.4-.716a7.39,7.39,0,0,0-3.873.9,1.692,1.692,0,0,0-.342,1.288,3.938,3.938,0,0,0,.486,1.572,2.314,2.314,0,0,0,.242,1.884,2.04,2.04,0,0,0,.5,1.847,1.7,1.7,0,0,0,.357,1.547,9.851,9.851,0,0,1,1.905.489,19.247,19.247,0,0,0,6.195.223S1420.939,1257.858,1419.39,1253.233Z" transform="translate(-1408.062 -1249.298)" fill="#eea886"/>
                </g>
              </g>
            </g>
          </g>
          <path id="Path_1842" data-name="Path 1842" d="M1412.423,1238.365v-1.117h-17.335v1.117l7.548,4.373.852,12.506-2.731.872v.424h6v-.424l-2.731-.872.852-12.506Z" transform="translate(-218.526 -107.307)" fill="#e6e3df"/>
          <path id="Path_1843" data-name="Path 1843" d="M1413.193,1239.569h-15.53l7.688,4.386h0Z" transform="translate(-220.122 -108.745)" fill="#e6617c"/>
          <path id="Path_1844" data-name="Path 1844" d="M1338.015,1271.351h-15.53l7.688,4.386v0Z" transform="translate(-173.532 -128.441)" fill="#e6617c"/>
          <g id="Group_860" data-name="Group 860" transform="translate(1181.428 1135.426)">
            <g id="Group_859" data-name="Group 859" transform="translate(0 0)">
              <g id="Group_858" data-name="Group 858">
                <g id="Group_857" data-name="Group 857">
                  <path id="Path_1845" data-name="Path 1845" d="M1408.719,1251.68s4.068.358,4.166,1.144a1.241,1.241,0,0,1-.262,1.1l1.694,1.05s.855,1.413-.6,1.959c0,0,.905,1.235-.57,1.555,0,0,.317,1.1-.332,1.392a11.37,11.37,0,0,1-3.269-.064s-.549-.37-.357-1.547a2,2,0,0,1-.5-1.847,2.212,2.212,0,0,1-.242-1.884s-1.16-1.725-.145-2.86A3.717,3.717,0,0,1,1408.719,1251.68Z" transform="translate(-1407.883 -1251.671)" fill="#e3a080"/>
                </g>
              </g>
            </g>
          </g>
          <g id="Group_864" data-name="Group 864" transform="translate(1185.23 1135.219)">
            <g id="Group_863" data-name="Group 863" transform="translate(0 0)">
              <g id="Group_862" data-name="Group 862">
                <g id="Group_861" data-name="Group 861">
                  <path id="Path_1846" data-name="Path 1846" d="M1421.639,1251.129a12,12,0,0,0-3.467,2.735,1.068,1.068,0,0,0-.208,1.107c.191.409.9.658,1.995.166a8.272,8.272,0,0,1,2.742-.509S1423.663,1253.713,1421.639,1251.129Z" transform="translate(-1417.881 -1251.129)" fill="#eea886"/>
                </g>
              </g>
            </g>
          </g>
          <path id="Path_1847" data-name="Path 1847" d="M1194.643,1185.693s-1.558-19.151,9.282-20.181c13.242-1.258,7.7,22.627,7.7,22.627Z" transform="translate(-94.258 -62.821)" fill="#214361"/>
        </g>
      </g>
    </g>
  </svg>
  );
};

CafeteriaSvg.propTypes = {};

export default CafeteriaSvg;
