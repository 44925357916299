import {
  GET_BOOKINGS,
  GET_BOOKING,
  BOOKING_LOADING,
  SAVE_BOOKING,
  BOOKING_ERRORS,
  DELETE_BOOKING,
  COMPANY_CREDIT,
  GET_SELF_BOOKINGS
} from "../Actions/types";

const initialState = {
  bookings: {},
  loading: false,
  deleteBooking: {},
  booking: {},
  selfBookings: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_BOOKINGS:
      return {
        ...state,
        bookings: { success: true, data: action.payload.data },
        loading: false
      };
    case GET_SELF_BOOKINGS:
      return {
        ...state,
        selfBookings: { success: true, data: action.payload.data },
        loading: false
      };
    case GET_BOOKING:
      return {
        ...state,
        booking: { success: true, data: action.payload.data },
        loading: false
      };
    case BOOKING_LOADING:
      return {
        ...state,
        loading: true
      };
    case SAVE_BOOKING:
      return {
        ...state,
        loading: false,
        savedBooking: { success: true, data: action.payload.data }
      };
    case COMPANY_CREDIT:
      return {
        ...state,
        loading: false,
        get_credit: {
          success: true,
          data: action.payload && action.payload.data
        }
      };
    case BOOKING_ERRORS:
      return {
        ...state,
        loading: false,
        error: { success: false, data: action.payload.data }
      };
    case DELETE_BOOKING:
      return {
        ...state,
        loading: false,
        deleteBooking: {
          success: true,
          data: action.payload && action.payload.data
        }
      };
    default:
      return state;
  }
}
