/* eslint-disable camelcase */
import React from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { Typography, Grid } from "@material-ui/core";
import classNames from "classnames";

const styles = () => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "12px",
  },
  mainTitle: {
    fontWeight: "600",
    fontSize: "20px",
    textAlign: "left",
    color: "#222222",
    lineHeight: "25px",
  },
  heading: {
    fontSize: "16px",
    color: "#222222",
    fontWeight: "500",
    margin: "4px 0px",
    marginBottom: "15px",
  },
  title: {
    fontSize: "13px",
    color: "#222222",
    fontWeight: "500",
    // padding: "10px"
  },
  subTitle: {
    fontSize: "15px",
    color: "#222222",
    fontWeight: "500",
    padding: "10px",
  },
  phone: {
    color: "#1592E6",
    fontWeight: "500",
  },
  description: {
    color: "#222222",
    fontSize: "13px",
    lineHeight: "18px",
  },
  generalDetails: {
    padding: "2% 0% 0% 0%",
  },
  cardLayout: {
    border: "1px solid #EFEFEF",
    borderRadius: "5px",
    padding: "10px",
  },
  padding: {
    padding: "10px",
  },
});

function DigitalMarketing(props) {
  const { classes } = props;

  return (
    <div className={classes.main}>
      <div className={classes.header}>
        <Typography align="left" variant="h1" className={classes.mainTitle}>
          Digital Marketing Service
        </Typography>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Typography align="left" className={classes.description}>
            DevX has a team of dedicated online and offline marketers; the
            rockstars who specialize in their respective domains. We are
            technocrats who weave art and technology in compelling ways to craft
            a great marketing solution for our clients. Make your business Talk
            of the Town with are most tested but customized to client need,
            digital marketing solutions.
          </Typography>
          <Grid container spacing={2} className={classes.generalDetails}>
            <Typography align="left" className={classes.subTitle}>
              Our services include;
            </Typography>
            <Grid item className={classes.serviceLayout}>
              <Typography align="left" className={classes.title}>
                Smart SEO Service
              </Typography>
              <Typography align="left" className={classes.description}>
                We adhere to the most ethical Search Engine Optimization
                strategy to skyrocket your sales and take your website ranking
                to-the-top. SEO is not an expense but an investment to drive
                relevant traffic to your website that are potential leads for
                your sales leading to an increase in profitability. Trusted and
                credible information when shared through effective SEO will
                generate the business till perpetuity.
              </Typography>
            </Grid>
            <Grid item className={classes.serviceLayout}>
              <Typography align="left" className={classes.title}>
                Pay Per Click
              </Typography>
              <Typography align="left" className={classes.description}>
                Are you facing trouble in getting found over the web? Our Pay
                Per Click (PPC) Management Service will fetch you ready to buy
                customers for your product and services. Our team of highly
                efficient and ethical professionals who will craft your PPC
                campaign for the best Return on Investment on your marketing
                budget.
              </Typography>
            </Grid>
            <Grid item className={classes.serviceLayout}>
              <Typography align="left" className={classes.title}>
                Smart Social Media Marketing
              </Typography>
              <Typography align="left" className={classes.description}>
                Shout out to the right set of customers with our tailor-made
                Social Media Marketing strategy. We offer highly customized SMM
                strategy across various platforms like Facebook, Instagram,
                LinkedIn, Twitter, Pinterest etc. Stay on top for your
                competitors by outreaching them in terms of your social reach
                and outperforming them in the number game.
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2} className={classes.generalDetails}>
            <Typography
              align="left"
              className={classNames(classes.mainTitle, classes.padding)}
            >
              Why us?
            </Typography>
            <Grid item xs={12}>
              <Typography align="left" className={classes.description}>
                We are a value and ethics driven organization that focuses on
                maximizing client requirements. Our experts provide the best
                audience engaging lean digital marketing strategies that are
                ethical but never compromising on return on investment for the
                trust they put in us for developing their business. Never Give
                Up approach is what separates US from others and through our
                lean marketing strategies that deliver desired results within
                the given time.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.spaces.loading,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withStyles(styles)(DigitalMarketing));
