import { GET_APPLICATIONS, ADD_APPLICATION } from "../Actions/types";

const initialState = {
  applications: [],
  loading: false,
  createdApplication: []
};
export default function(state = initialState, action) {
  switch (action.type) {
    case GET_APPLICATIONS:
      return {
        ...state,
        applications: action.payload
      };
    case ADD_APPLICATION:
      return {
        ...state,
        createdApplication: action.payload
      };
    default:
      return state;
  }
}
