import withStyles from '@material-ui/core/styles/withStyles'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CssBaseline from '@material-ui/core/CssBaseline'
import { Typography, Grid, Button } from '@material-ui/core'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import { Form } from 'react-formik-ui'
import * as Yup from 'yup'
import TextareaField from '../../Components/Common/Form/TextareaField'
import {
  getOnboardingProcess,
  getOnboardingProcessRating,
  createRating,
  getClientFeedback,
  getClientFeedbackRating,
} from '../../Actions/companiesAction'
import FreelancerClientFeedback from './FreelancerClientFeedback'
import FreelancerStarStyle from './FreelancerStarStyle'
import { checkPermission } from '../../Utils/checkPermission'
import { capitalize } from '../../Utils/StringFunctions'
import _uniq from 'lodash/uniq';
import _size from 'lodash/size';
import _get from 'lodash/get';
const moment = require('moment')

const $quoteSym = ' '
const styles = () => {
  return {
    main: {
      padding: '0px',
      backgroundColor: '#ffffff',
    },
    applicationHeader: {
      margin: '20px 3px',
      paddingBottom: '5px',
      fontSize: '16px',
      fontWeight: '500',
      position: 'relative',
      '&:after': {
        top: '31px',
        left: '1px',
        width: '24px',
        borderBottom: '3px solid #fb0',
        content: `'${$quoteSym}'`,
        position: 'absolute',
      },
    },
    photo: {
      marginTop: '11%',
      width: '42px',
      height: '43px',
    },
    logo: {
      width: '100%',
      textAlign: 'center',
      flexGrow: 1,
    },
    eventsubtitle: {
      fontSize: '14px',
      fontWeight: 'normal',
      textAlign: 'center',
      color: '#626262',
    },
    comment_section: {
      border: '1px solid #efefef',
      textAlign: 'right',
      position: 'relative',
      height: '81px',
      borderRadius: '5px',
    },
    input_field: {
      border: '1px solid #f5f5f5',
      height: '100%',
      position: 'relative',
      width: '100%',
      padding: '6px 8px',
      margin: '0px',
      borderBottom: 'none',
      fontFamily:
        "'-apple-system,BlinkMacSystemFont','Segoe UI','Roboto','Helvetica Neue','Arial,sans-serif','Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol'",
      color: 'grey',
      fontSize: '14px',
      resize: 'none',
    },
    submitbutton: {
      backgroundColor: '#211b4e !important',
      '&:hover': {
        backgroundColor: '#151039 !important',
      },
      margin: '22px 0px',
      '&:disabled': {
        backgroundColor: 'lightgrey !important',
      },
    },
    layout: {
      border: '1px solid #EFEFEF',
      borderRadius: '5px',
      margin: '3% 1%',
      display: 'flex',
      flexDirection: 'column',
    },
    mainGrid: {
      justifyContent: 'center',
      marginTop: '3%',
    },
    introduction: {
      border: '1px solid #efefef',
      borderRadius: '5px',
      margin: '2% 0%',
    },
    title: {
      color: '#4682b4',
      fontSize: '16px',
      fontWeight: 'normal',
      padding: '1%',
    },
    historyTime: {
      marginLeft: '11px',
      color: '#ababab',
      fontWeight: 'normal',
      paddingTop: '0px',
      fontSize: '12px',
      textTransform: 'capitalize',
    },
    description: {
      fontSize: '14px',
      lineHeight: '20px',
      color: '#626262',
      fontWeight: 'normal',
    },
  }
}

const commentSchema = Yup.object().shape({
  comment: Yup.string()
    .trim()
    .max(750, 'Post length must be less than 700 characters')
    .required('This field is required.'),
})

class FreelancerOnboardingFeedback extends Component {
  constructor(props) {
    super(props)
    this.state = {
      process_rating_array: [],
      uniq_process_rating_id:[],
      required: false,
      reloadPage: false,
    }
  }

  componentDidMount() {
    if (checkPermission('COMMON_PERMISSION_FOR_ADMIN_CM')) {
      this.props.getOnboardingProcess()
      this.props.getOnboardingProcessRating(this.props.companyID)
      this.props.getClientFeedback(this.props.companyID)
      this.props.getClientFeedbackRating(this.props.companyID, 'rating')
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.createOnboardingProcessPost !==
        prevProps.createOnboardingProcessPost &&
      this.props.createOnboardingProcessPost.success
    ) {
      if (checkPermission('COMMON_PERMISSION_FOR_ADMIN_CM')) {
        this.props.getOnboardingProcessPost(this.props.companyID)
        this.props.getOnboardingProcessRating(this.props.companyID)
      }
    }
    if (this.state.reloadPage) {
      this.props.getOnboardingProcessRating(this.props.companyID)
      this.setState({ reloadPage: false })
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.onboardingProcess !== prevState.onboardingProcess) {
      return {
        onboardingProcess: nextProps.onboardingProcess,
      }
    }
    if (
      nextProps.onboardingProcessRating !== prevState.onboardingProcessRating
    ) {
      return {
        onboardingProcessRating: nextProps.onboardingProcessRating,
      }
    }
    if (nextProps.clientFeedback !== prevState.clientFeedback) {
      return {
        clientFeedback: nextProps.clientFeedback,
      }
    }
    if (nextProps.clientFeedbackRating !== prevState.clientFeedbackRating) {
      return {
        clientFeedbackRating: nextProps.clientFeedbackRating,
      }
    }

    return []
  }

  setSelectedStar = (val) => {
    this.setState((prevState) => {
      prevState.uniq_process_rating_id.push(_get(val,'process_id._id'))
      prevState.process_rating_array.push(val)
    })
  }

  render() {
    const { classes } = this.props
    const {
      onboardingProcess,
      onboardingProcessRating,
      clientFeedback,
    } = this.state
    const defaultSelected = (id) =>
      onboardingProcessRating &&
      onboardingProcessRating[0] &&
      onboardingProcessRating[0].process.find((obj) => {
        return obj.process_id && obj.process_id._id === id
      })

    return (
      <React.Fragment>
        <CssBaseline />
        <main className={classes.main}>
          <div className={classes.heroUnit}>
            <span className={classes.applicationHeader}>
              Community Manager Feedback
            </span>
          </div>
          <Grid
            container
            direction="row"
            item
            lg={12}
            xs={12}
            spacing={2}
            className={classes.mainGrid}
          >
            {onboardingProcess &&
              onboardingProcess.map((process) => {
                return (
                  <Grid item lg={2} xs={2} className={classes.layout}>
                    <div className={classes.logo}>
                      <img
                        className={classes.photo}
                        src={process.icon}
                        alt=""
                      />
                      <Typography
                        variant="body1"
                        component="p"
                        className={classes.eventsubtitle}
                      >
                        {process.process_name}
                      </Typography>
                    </div>

                    <FreelancerStarStyle
                      processId={process._id}
                      setSelectedStar={this.setSelectedStar}
                      defaultStar={defaultSelected(process._id)}
                    />
                  </Grid>
                )
              })}
            {this.state.required && (
              <Grid item lg={11}>
                <div style={{ textAlign: 'center', color: 'red' }}>
                  Rating is required
                </div>
              </Grid>
            )}
            {onboardingProcessRating && onboardingProcessRating.length > 0 ? (
              onboardingProcessRating.map((rating) => (
                <Grid item lg={11} xs={11}>
                  <Typography
                    variant="subtitle1"
                    component="p"
                    className={classes.description}
                  >
                    {rating.comment}
                  </Typography>
                  <div className={classes.introduction}>
                    <Typography
                      className={classes.title}
                      variant="subtitle1"
                      component="p"
                    >
                      By {capitalize(rating.created_by.first_name)}{' '}
                      {capitalize(rating.created_by.last_name)}
                      {/* Dipa Rajput, Community Manager */}
                      <span className={classes.historyTime}>
                        - On{' '}
                        {moment(rating.createdAt).format('MMMM DD, hh:mm A')}
                      </span>
                    </Typography>
                  </div>
                </Grid>
              ))
            ) : (
              <Grid item lg={11} xs={11}>
                <Formik
                  initialValues={{ comment: '' }}
                  enableReinitialize
                  validationSchema={commentSchema}
                  onSubmit={(values, { setErrors, setSubmitting }) => {
                    // eslint-disable-next-line no-param-reassign
                    values.company_id = this.props.companyID
                    // eslint-disable-next-line no-param-reassign
                    values.process = this.state.process_rating_array
                    if (onboardingProcess.length === _size(_uniq(this.state.uniq_process_rating_id))) {
                      this.props.createRating(
                        values,
                        this.props.history,
                        setErrors,
                        setSubmitting
                      )
                      // resetForm(this.initialValues);
                      this.setState({ required: false })
                      this.setState({ reloadPage: true })
                      // this.props.getOnboardingProcessRating(this.props.companyID);
                    } else {
                      this.setState({ required: true })
                    }
                  }}
                >
                  {({ handleSubmit }) => (
                    <Form
                      onSubmit={handleSubmit}
                      className={classes.form}
                      encType="multipart/form-data"
                      autoComplete="off"
                    >
                      <div className={classes.comment_section}>
                        <TextareaField
                          name="comment"
                          className={classes.input_field}
                          placeHolder="Add Comment here.."
                        />
                        <Button
                          className={classes.submitbutton}
                          type="submit"
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={this.onSubmit}
                        >
                          Submit
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Grid>
            )}
          </Grid>
          <FreelancerClientFeedback
            clientFeedback={clientFeedback || {}}
            onboardingProcess={onboardingProcess}
            companyID={this.props.companyID}
            clientFeedbackRating={this.props.clientFeedbackRating}
            company={this.props.company}
          />
        </main>
      </React.Fragment>
    )
  }
}

FreelancerOnboardingFeedback.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
}
const mapStateToProps = (state) => {
  return {
    onboardingProcess: state.company.onboardingProcess,
    createOnboardingProcessPost: state.company.createOnboardingProcessPost,
    onboardingProcessRating: state.company.onboardingProcessRating,
    clientFeedback: state.company.clientFeedback || {},
    clientFeedbackRating: state.company.clientFeedbackRating,
  }
}

export default connect(mapStateToProps, {
  getOnboardingProcess,
  getOnboardingProcessRating,
  createRating,
  getClientFeedback,
  getClientFeedbackRating,
})(withStyles(styles)(FreelancerOnboardingFeedback))
