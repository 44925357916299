/* eslint-disable camelcase */
import React from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { Typography, Grid } from "@material-ui/core";
import classNames from "classnames";

const styles = () => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "12px"
  },
  mainTitle: {
    fontWeight: "600",
    fontSize: "20px",
    textAlign: "left",
    color: "#222222",
    lineHeight: "25px"
  },
  heading: {
    fontSize: "16px",
    color: "#222222",
    fontWeight: "500",
    margin: "4px 0px",
    marginBottom: "15px"
  },
  title: {
    fontSize: "13px",
    color: "#222222",
    fontWeight: "500",
    padding: "10px"
  },
  subTitle: {
    fontSize: "15px",
    color: "#222222",
    fontWeight: "500"
  },
  phone: {
    color: "#1592E6",
    fontWeight: "500"
  },
  description: {
    color: "#222222",
    fontSize: "13px",
    lineHeight: "18px"
  },
  generalDetails: {
    padding: "3% 0% 0%"
  },
  cardLayout: {
    border: "1px solid #EFEFEF",
    borderRadius: "5px",
    padding: "10px"
  },
  padding: {
    padding: "10px"
  }
});

function WebsiteDesign(props) {
  const { classes } = props;

  return (
    <div className={classes.main}>
      <div className={classes.header}>
        <Typography align="left" variant="h1" className={classes.mainTitle}>
          Website Design
        </Typography>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Typography align="left" className={classes.description}>
            Are you looking for launching your website or looking forward to
            revamping the existing one? We can help you with the design and
            development of your website. Our innovative, creative and
            professional approach in designing comply with the standards and are
            SEO friendly. We try to invest our time in understanding the client
            requirement, targeted audience, look and feel and deliver the best
            possible options to select from the numerous options our expert team
            can pull out from the sleeves. Our goal is to help our client
            generate business from the internet.
          </Typography>
          <Grid container spacing={2} className={classes.generalDetails}>
            <Grid item xs={12}>
              <Typography align="left" className={classes.subTitle}>
                Outsource your website design and development for a creative and
                optimized website. We provide the following services;
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  Website Design,
                  <br /> Development and <br />
                  Maintenance<br/>
                  <br/>
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  CMS-based website <br />
                  like Wordpress, <br />
                  Joomla, Drupal etc.<br/>
                  <br/>
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  Responsive E-commerce website on Opencart, Magento
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  Content Writing and <br />
                  Graphic Designing <br />
                  for your website
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2} className={classes.generalDetails}>
            <Typography
              align="left"
              className={classNames(classes.mainTitle, classes.padding)}
            >
              Why us?
            </Typography>
            <Grid item xs={12}>
              <Typography align="left" className={classes.description}>
                We have a team of professionals having more than 5 years of
                industry experience. <br />
                <br />
                Client-centric approach, we are a partner in your growth.
                <br />
                <br />
                Wireframes and Options before we go ahead with the final build.
                <br />
                <br />A one-stop-shop for Design, Development, Maintenance,
                Digital Marketing, Graphic Designing etc. <br />
                <br />
                Quick turnaround time and affordable rates.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    loading: state.spaces.loading
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(WebsiteDesign));
