/* eslint-disable camelcase */
import React from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { Typography, Grid } from "@material-ui/core";
import classNames from "classnames";

const styles = () => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "12px"
  },
  mainTitle: {
    fontWeight: "600",
    fontSize: "20px",
    textAlign: "left",
    color: "#222222",
    lineHeight: "25px"
  },
  heading: {
    fontSize: "16px",
    color: "#222222",
    fontWeight: "500",
    margin: "4px 0px",
    marginBottom: "15px"
  },
  title: {
    fontSize: "13px",
    color: "#222222",
    fontWeight: "500",
    padding: "10px"
  },
  subTitle: {
    fontSize: "15px",
    color: "#222222",
    fontWeight: "500"
  },
  phone: {
    color: "#1592E6",
    fontWeight: "500"
  },
  description: {
    color: "#222222",
    fontSize: "13px",
    lineHeight: "18px"
  },
  generalDetails: {
    padding: "3% 0% 0%"
  },
  cardLayout: {
    border: "1px solid #EFEFEF",
    borderRadius: "5px",
    padding: "10px"
  },
  padding: {
    padding: "10px"
  }
});

function LegalService(props) {
  const { classes } = props;

  return (
    <div className={classes.main}>
      <div className={classes.header}>
        <Typography align="left" variant="h1" className={classes.mainTitle}>
          Legal Service
        </Typography>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Typography align="left" className={classes.description}>
            Innovative thinkers, Entrepreneurs, and pathbreakers require expert
            advice specifically for their growth, incorporation, compliance,
            funding options etc. We have an eminent team of legal experts to aid
            you in your journey for growth. The team has vast experience in
            managing problems faced by both startups and established businesses
            and help them resolve complex issues.
          </Typography>
          <Grid container spacing={2} className={classes.generalDetails}>
            <Grid item xs={12}>
              <Typography align="left" className={classes.subTitle}>
                Our services include;
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  Startup Support
                  <br />
                  <br />
                  <br />
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  Intellectual Property
                  <br />
                  <br />
                  <br />
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  Change in Business <br />
                  form
                  <br />
                  <br />
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  Registrations and <br />
                  Fillings
                  <br />
                  <br />
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  Legal Drafting
                  <br />
                  <br />
                  <br />
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  Accounting and
                  <br /> Compliance
                  <br />
                  <br />
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  Dedicated Accounts <br />
                  and Financial Resource <br />
                  (Part/Full time)
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2} className={classes.generalDetails}>
            <Typography
              align="left"
              className={classNames(classes.mainTitle, classes.padding)}
            >
              Why us?
            </Typography>
            <Grid item xs={12}>
              <Typography align="left" className={classes.description}>
                We keep all your information private and safe
                <br />
                <br /> We have one of the best industry rates with no hidden
                costs <br />
                <br />
                Our team of experts will provide the best solutions and services
                <br />
                <br />
                We are a one-stop solution for all your business needs
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    loading: state.spaces.loading
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(LegalService));
