import React from "react";

const HelpdeskSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="300" viewBox="0 0 350 374">
      <g id="Group_3161" data-name="Group 3161" transform="translate(0)">
        <g id="contact" transform="translate(0.968)">
          <g id="Group_3114" data-name="Group 3114">
            <path
              id="Path_6538"
              data-name="Path 6538"
              d="M357,106.208c0,22.742,18.093,42.268,43.934,50.724a92.28,92.28,0,0,0,28.708,4.484,94.756,94.756,0,0,0,16.253-1.385c32.292-5.608,56.39-27.575,56.39-53.823C502.285,75.718,469.761,51,429.642,51S357,75.718,357,106.208Z"
              transform="translate(-357 -51)"
              fill="#d6ece1"
            />
            <path
              id="Path_6539"
              data-name="Path 6539"
              d="M434.04,122.317a4.959,4.959,0,0,1,1.491-3.614,3.737,3.737,0,0,1,3.327-.449c-.871-.312-2.044-.673-3.535-1.225-5.266-1.968-6.752-3.534-7.15-3.082-.66.718,2.477,5.716,5.868,8.37Z"
              transform="translate(-362.422 -52.976)"
              fill="#eda472"
            />
            <path
              id="Path_6540"
              data-name="Path 6540"
              d="M415.812,101.837c1.676,4.638,6.235,5.421,6.718,10.8.159,1.693-.484,4.071-1.775,8.762a9.672,9.672,0,0,0,6.782,4.023,9.471,9.471,0,0,0,8.663-4.269c.009-.164.035-.407.077-.7a4.457,4.457,0,0,1,1.413-2.912,3.209,3.209,0,0,1,2.661-.564c.22.03.438.068.664.114,1.82.346,3.883,1.08,6.053.026,1.125-.529,2.689-1.724,4.339-7.474,1.458-5.092,2.892-10.042,1.552-16.728-1.12-5.558-2.262-11.267-7.729-14.244-6.681-3.648-14.889-.533-15.286-.384C419.813,82.3,413.085,94.342,415.812,101.837Z"
              transform="translate(-364.581 -51.814)"
              fill="#f2a887"
            />
            <path
              id="Path_6541"
              data-name="Path 6541"
              d="M424.063,118.376c-.825-.047-1.508-.923-2.928-4.287-1.5-3.547-2.358-6.438-3.615-9.216-1.865-4.122-2.508-3.454-3.466-6.1-1.867-5.152-.3-10.105.461-12.519.981-3.105,2.452-7.76,6.9-10.717,3.033-2.018,5.893-2.209,11.519-2.586a34.525,34.525,0,0,1,13.83,1.479,23.063,23.063,0,0,1,7.993,4.45c1.787,1.556,3.8,3.31,3.508,5.37-.257,1.818-2.216,3.231-3.966,3.756-4.4,1.321-7-3.118-10.158-2-4.347,1.532-3.661,11.374-6.849,11.755-1.581.19-2.416-2.209-4.324-1.894a4.177,4.177,0,0,0-2.97,4.3c.3,2.439,3.446,2.294,4.473,5.012,1.165,3.087-3.989,12.535-2.723,9.493a4.064,4.064,0,0,0-4.314.047,3.846,3.846,0,0,0-1.55,2.517Z"
              transform="translate(-367.414 -51.686)"
              fill="#010a1a"
            />
            <g
              id="Group_3113"
              data-name="Group 3113"
              transform="translate(52.405 19.622)"
            >
              <path
                id="Path_6542"
                data-name="Path 6542"
                d="M432.637,85.012c3.685-2.142,7.936,2.74,11.732,2.64,9.926-.26,5.523-10-.332-12.295-9.774-3.832-25.4-5-32.581,4.209-.448.574-1.291-.193-.846-.764,4.394-5.631,10.061-6.58,16.966-7.379,5.979-.692,16.335.751,21.122,4.995,2.414,2.141,3.905,6.948,1.989,9.777-1.465,2.165-4.276,2.684-6.663,2.556-3.3-.176-8.088-4.346-10.767-2.789-.634.368-1.257-.579-.621-.95Z"
                transform="translate(-410.485 -71.258)"
                fill="#010a1a"
              />
            </g>
            <path
              id="Path_6543"
              data-name="Path 6543"
              d="M431.888,119.344c.941,1.178,1.733,2.148,2.434,2.9a4.83,4.83,0,0,1,1.541-3.166,3.5,3.5,0,0,1,2.9-.612,27.963,27.963,0,0,1-2.839-.971c-.758-.306-1.35-.583-1.79-.8-3.624-1.752-5.02-3.031-5.352-2.709C428.242,114.511,431.271,118.571,431.888,119.344Z"
              transform="translate(-362.944 -52.978)"
              fill="#e66e52"
            />
            <path
              id="Path_6544"
              data-name="Path 6544"
              d="M414.968,159.19a92.283,92.283,0,0,0,28.708,4.484,94.754,94.754,0,0,0,16.253-1.385c.126.352.184.422.213.416.316-.066-1.864-9.084-3.874-19.371a20.991,20.991,0,0,0-1.937-5.811,20.508,20.508,0,0,0-3.012-4.391c-2.644-3.317-4.645-4.555-6.673-8.2a4.06,4.06,0,0,0-1.937-1.937c-2.1-.833-3.409,1.443-6.78,1.937-4.184.62-8.717-1.937-8.717-1.937-2.838,6.741-5.124,12.349-6.78,16.466a81.63,81.63,0,0,0-2.906,7.749A88.127,88.127,0,0,0,414.968,159.19Z"
              transform="translate(-371.034 -53.257)"
              fill="#f05757"
            />
          </g>
        </g>
        <g id="girl" transform="translate(54.24 2.9)">
          <g id="Group_3157" data-name="Group 3157" transform="translate(0)">
            <ellipse
              id="Ellipse_196"
              data-name="Ellipse 196"
              cx="77.993"
              cy="6.367"
              rx="77.993"
              ry="6.367"
              transform="translate(111.681 304.155)"
              fill="#f9f9f9"
            />
            <path
              id="Path_6545"
              data-name="Path 6545"
              d="M252,133c-4.247-.562-9.074,9.392-9.686,10.654a31.856,31.856,0,0,0-2.906,14.529c-.08,11.668,4.456,13.172,2.906,22.277a50.969,50.969,0,0,1-3.874,12.591c-1.479,3.008-2.553,4.235-2.906,6.78-.605,4.373,1.277,10.045,3.874,10.654,5.048,1.184,15.762-16.078,19.371-33.9C262.883,156.309,258.1,133.807,252,133Z"
              transform="translate(-50.534 -56.477)"
              fill="#010a1a"
            />
            <path
              id="Path_6546"
              data-name="Path 6546"
              d="M273.076,353.979c1.617.764,1.636,1.655,4.843,4.843,1.681,1.671,1.748,1.5,1.937,1.937.956,2.223-1.662,5.324-6.78,11.623-2.362,2.907-5.811,6.78-5.811,6.78h0c-5.489-.322-10.968-1-16.466-.968h0s.631-3.572,1.43-3.905l3.413-1.422,5.811-2.422h0c1.8-1.9,5.795-6.1,5.811-10.654,0-.579-.061-.991,0-1.937.117-1.823.155-3.185.969-3.874C269.451,352.948,271.653,353.306,273.076,353.979Z"
              transform="translate(-85.174 -63.403)"
              fill="#010a1a"
            />
            <path
              id="Path_6547"
              data-name="Path 6547"
              d="M387.9,366.74a23.97,23.97,0,0,1-.969,5.811h-.969c-1.8,0-29.363.833-30.461.866a15.7,15.7,0,0,1-1.5-2.8l1.937-2.906c2.064-2.966,8.166-2.118,14.528-3.874,7.641-2.108,9.1-6.163,12.591-4.843C385.869,360.057,387.813,363.793,387.9,366.74Z"
              transform="translate(-292.977 -63.573)"
              fill="#010a1a"
            />
            <path
              id="Path_6548"
              data-name="Path 6548"
              d="M226.746,370.592h0c1.038-.242-14.83-72.127-14.83-72.127a1.941,1.941,0,0,0-2.326-1.447h0a1.943,1.943,0,0,0-1.447,2.326S225.709,370.834,226.746,370.592Z"
              transform="translate(9.445 -61.632)"
              fill="#aaa"
            />
            <path
              id="Path_6549"
              data-name="Path 6549"
              d="M268.142,370.592h0c-1.037-.242,14.83-72.127,14.83-72.127a1.943,1.943,0,0,1,2.327-1.447h0a1.943,1.943,0,0,1,1.447,2.326S269.179,370.834,268.142,370.592Z"
              transform="translate(-108.669 -61.632)"
              fill="#aaa"
            />
            <g
              id="Group_3115"
              data-name="Group 3115"
              transform="translate(171.689 234.942)"
            >
              <rect
                id="Rectangle_1244"
                data-name="Rectangle 1244"
                width="0.968"
                height="53.53"
                transform="translate(0.464 26.517) rotate(-118.652)"
              />
            </g>
            <g
              id="Group_3116"
              data-name="Group 3116"
              transform="translate(176.836 233.983)"
            >
              <rect
                id="Rectangle_1245"
                data-name="Rectangle 1245"
                width="54.001"
                height="0.968"
                transform="translate(46.966 27.494) rotate(-150.426)"
              />
            </g>
            <path
              id="Path_6550"
              data-name="Path 6550"
              d="M294.436,206.654a22.534,22.534,0,0,0-3.874-6.78c-1.431-1.637-3.227-3.693-4.843-3.874-5.21-.585-13.992,17.732-17.434,38.743-4.019,24.527,2.716,31.5-3.874,37.774-7.257,6.913-17.807.723-47.46,6.78-8.2,1.675-13.411,2.781-13.56,4.843-.311,4.3,21.362,12.34,42.617,10.654,7.231-.573,34.253-2.426,45.523-21.308,3.991-6.688-.284-4.964,4.843-39.711C296.374,233.774,298.6,218.712,294.436,206.654Z"
              transform="translate(-56.169 -58.458)"
              fill="#aaa"
            />
            <path
              id="Path_6551"
              data-name="Path 6551"
              d="M334.635,259.991a5.9,5.9,0,0,0-3.874.968c-14.337,8.306-23.425,5.73-34.868,16.466-.315.3.015-.006-2.906,2.906-6.89,6.867-9.116,8.9-12.214,12.625-1.03,1.238-1.8,2.221-2.315,2.872C263.761,314.509,231,355.879,231,355.879a6.962,6.962,0,0,0,1.937,4.843,7.139,7.139,0,0,0,5.811,1.937c33.509-35.744,52.3-53.271,52.3-53.271,5.142-4.8,7.713-7.193,9.686-8.717a209.63,209.63,0,0,1,37.774-23.246c12.711-5.464,18.3-5.782,18.4-8.717C357.067,264.4,345.267,258.34,334.635,259.991Z"
              transform="translate(-142.859 -60.461)"
              fill="#f58a28"
            />
            <path
              id="Path_6552"
              data-name="Path 6552"
              d="M300.179,268.988c3.314,1.589,3.733,7.985,2.906,12.591a16.181,16.181,0,0,1-3.874,7.749c-10.968,12.529-38.913,6.41-41.648,5.811-2.321-.508-12.591-2.906-12.591-2.906,16.655,39.074,25.182,59.082,25.183,59.083-.1-.115-.23-.237-.35-.209-.529.123.538,2.865-.619,4.083-1.03,1.086-3.088.183-4.841,1.935l0,0c-14.225-21.359-25-37.348-31.963-46.491a43.662,43.662,0,0,1-7.749-16.466c-.875-3.819-1.744-7.848,0-11.623,1.706-3.689,5.422-6.072,14.529-8.717,16.557-4.808,31.487-4.829,40.68-4.843,10.869-.015,11.6,1.211,16.466,0A5.523,5.523,0,0,1,300.179,268.988Z"
              transform="translate(-82.251 -60.741)"
              fill="#fab217"
            />
            <path
              id="Path_6553"
              data-name="Path 6553"
              d="M268.44,207c1.472-.081,4.794,17.457,3.874,38.743-.916,21.194-1.514,35.034-11.623,43.586-7.929,6.706-18.2,7.054-30.025,7.749-18.742,1.1-34.08-3.661-27.12-3.874,20.151-.616,40.317-3.5,47.46-7.748C277.177,269.883,265.656,207.153,268.44,207Z"
              transform="translate(-30.172 -58.804)"
              fill="#e2e2e2"
            />
            <path
              id="Path_6554"
              data-name="Path 6554"
              d="M338.89,193.595c-2.152-1.075-5.208,5.712-18.4,16.466-6.39,5.208-15.5,11.623-15.5,11.623s-1.9-.941-1.937-.969c-10.964-8.082-23.513-13.786-34.868-21.308a48.649,48.649,0,0,0-4.843-9.686,20.327,20.327,0,0,0-2.905-3.874c-4.632-4.543-12.392-5.776-13.56-3.874-.752,1.222.918,4.235,2.906,5.811,1.429,1.134,2.685,1.256,3.874,2.906.8,1.114,1.344,2.6.969,2.906-.706.566-4.245-3.385-4.843-2.906-.757.607,4.043,7.638,3.874,7.749a12.6,12.6,0,0,1-1.937-2.906,18.38,18.38,0,0,0,5,4.11c.7.35,1.064.413,1.777.732a44.616,44.616,0,0,1,6.78,3.874c6.552,4.472,15.857,12.956,24.214,19.371,7.477,5.74,11.283,8.613,14.528,8.717,4.667.15,9.954-3.449,20.34-10.654,5.248-3.641,7.907-5.506,9.686-7.749C340.26,206.1,341.456,194.877,338.89,193.595Z"
              transform="translate(-141.302 -57.99)"
              fill="#fcc881"
            />
            <path
              id="Path_6555"
              data-name="Path 6555"
              d="M244.858,184.968c4.085.415,7.749,5.813,7.749,5.811-.2-.987-.374-2.161-.095-2.265.674-.251,4.826,5.475,5.906,11.951,1.314,7.883-2.945,10.757-4.843,24.214a67.549,67.549,0,0,1-1.281,7.36c-.809,3.383-1.626,5.311-2.122,8.041a32.289,32.289,0,0,0-.472,5.907c-.151,11.808,3.055,19.059,0,21.309a6,6,0,0,1-2.906.969,7,7,0,0,1-3.874-.969c-2.607-1.411-3.322-.693-10.654-2.906-8.3-2.5-12.443-3.756-13.56-5.811-1.7-3.125.976-5.336,2.906-14.529,1.772-8.446,2.752-12.7.969-17.434s-5.495-8.113-5.811-15.5a19.033,19.033,0,0,1,0-1.937c.225-4.344,2.735-8.444,7.748-16.466,2.047-3.274,4.843-7.748,4.843-7.749l2.906-.968h0s1.834,3.113,3.874,3.874C239.095,188.977,240.953,184.572,244.858,184.968Z"
              transform="translate(-30.804 -58.081)"
              fill="#423a64"
            />
            <path
              id="Path_6556"
              data-name="Path 6556"
              d="M221.086,164.962c.223,3.264.339,4.911.969,5.811,1.937,2.789,5.472,2.509,7.5,3.593a2.679,2.679,0,0,1,1.22,1.249c.63,1.356-.116,2.857-1.036,4.339-1.182,1.9-2.644,3.777-1.869,5.346,1.094,2.228,6.247,2.925,9.686.969,3.342-1.9,3.681-5.511,5.811-12.591,4.116-13.686,6.586-12.562,6.78-18.4.339-10.112-6.79-21.851-14.528-22.277-3.768-.213-6.983,2.305-7.749,2.906a9.765,9.765,0,0,0-1.211,1.153,17.5,17.5,0,0,0-3.632,6.6,27.434,27.434,0,0,0-.949,3.729s-.01.068-.019.145A85.162,85.162,0,0,0,221.086,164.962Z"
              transform="translate(-26.404 -56.477)"
              fill="#f2a887"
            />
            <path
              id="Path_6557"
              data-name="Path 6557"
              d="M224.663,136.989c-1.04,1.388,5.361,8.349,8.717,11.623,4.981,4.859,6.646,4.907,7.748,7.749.9,2.328.634,4.486-2.906,14.528-3.122,8.857-4.683,13.285-6.78,17.434-4.385,8.676-7.086,8.615-8.717,14.529-1.47,5.329-.755,9.81,0,14.529,1.811,11.335,6.43,15.779,3.874,22.277-1.131,2.878-3.095,4.694-2.906,4.843.3.238,6.111-3.777,7.749-9.686,2.342-8.448-6.177-12.34-4.843-22.277.747-5.561,4.166-9.94,7.749-14.529,4.18-5.354,6.1-5.484,8.717-9.686,3.471-5.581,3.677-11.139,3.874-16.466.276-7.462.76-18.861-6.78-27.12C234.372,138.4,225.664,135.653,224.663,136.989Z"
              transform="translate(-24.169 -56.593)"
              fill="#010a1a"
            />
            <path
              id="Path_6558"
              data-name="Path 6558"
              d="M335.669,207h0l-30.994,31.963h0c-9.769,1.772-44.549-2.907-44.549-2.907h0a21.144,21.144,0,0,0-5.811-3.874,18.24,18.24,0,0,0-6.78-.969,31.138,31.138,0,0,0-10.654,1.937c-2.314.837-3.874,1.242-3.874,1.937,0,.429.571.768.969.969,3.861,1.946,13.5,1.937,13.56,1.937,3.845,0,4.84.828,12.591,2.906,20.644,5.533,47.455,8.716,47.46,8.717h0s1.842-.919,1.937-.969a87.1,87.1,0,0,0,30.993-27.141C340.131,215.474,335.669,207,335.669,207Z"
              transform="translate(-128.396 -58.804)"
              fill="#fcc881"
            />
            <path
              id="Path_6559"
              data-name="Path 6559"
              d="M209.942,135.81c-.205-2.452,5.821-6.275,11.623-5.811,8.5.68,13.694,10.339,16.466,15.5,1.768,3.289,5.42,10.293,4.843,19.371-.453,7.126-2.976,7.086-4.843,15.5-3.124,14.074,3.158,17.7-.968,29.057-2.048,5.636-4.317,6.73-5.811,13.56a28.361,28.361,0,0,0,0,13.56c1.19,4.361,3.153,6.617,2.906,6.78-.631.415-12.976-9.412-11.623-19.371.7-5.167,4.744-7.792,8.717-18.4a11.981,11.981,0,0,0,.969-6.78c-.543-2.758-1.949-3.2-2.906-6.78-.825-3.09-.038-3.728-.969-5.811-1.337-2.99-3.551-3-4.843-4.843-3.209-4.578,5.884-11.357,5.811-22.277,0-.793-.2-10.381-6.78-16.466C216.92,137.4,210.154,138.348,209.942,135.81Z"
              transform="translate(-8.48 -56.382)"
              fill="#010a1a"
            />
            <g
              id="Group_3117"
              data-name="Group 3117"
              transform="translate(221.031 83.279)"
            >
              <path
                id="Path_6560"
                data-name="Path 6560"
                d="M212.125,140.08a36.753,36.753,0,0,1,10.418,18.309c1.819,8.024.118,15.023-1.819,22.813-1.35,5.427-1.082,10.079-.4,15.551.5,4.026.7,7.908-.663,11.793-3.321,9.437-15.158,18.734-8.431,29.386.25.4-.378.761-.628.366-3.742-5.927-1.709-12.429,1.608-18.07,2.585-4.4,5.927-8.486,7.263-13.5,1.4-5.253-.241-10.534-.566-15.8-.534-8.65,3.961-16.548,3.807-25.2a35.948,35.948,0,0,0-11.1-25.131c-.337-.326.177-.839.513-.513Z"
                transform="translate(-208.587 -139.976)"
                fill="#010a1a"
              />
            </g>
            <g
              id="Group_3118"
              data-name="Group 3118"
              transform="translate(196.03 123.987)"
            >
              <path
                id="Path_6561"
                data-name="Path 6561"
                d="M249.033,182.558c-5.326,10.9-17.9,20.032-15.4,33.465.084.457-.615.653-.7.194-2.547-13.728,10.03-22.875,15.477-34.026.2-.42.831-.052.627.367Z"
                transform="translate(-232.593 -182.005)"
                fill="#010a1a"
              />
            </g>
            <g
              id="Group_3119"
              data-name="Group 3119"
              transform="translate(213.903 114.203)"
            >
              <path
                id="Path_6562"
                data-name="Path 6562"
                d="M224.264,227.552c-1.635-4.416-.492-8.336,1.146-12.6a36.5,36.5,0,0,0,2.06-19.457c-1.124-8.062-2.5-16.562,2.849-23.462.286-.369.8.149.513.514-6.433,8.3-2.692,18.581-2.043,27.993a27.813,27.813,0,0,1-1.494,11.616c-1.808,5.085-4.374,9.686-2.33,15.2.163.439-.54.629-.7.193Z"
                transform="translate(-223.47 -171.904)"
                fill="#010a1a"
              />
            </g>
            <path
              id="Path_6563"
              data-name="Path 6563"
              d="M224.773,133.969c0-.408.968-.969.968-.969h0a53.956,53.956,0,0,1,5.811,1.937c3.762,1.679,5.768,4.878,7.264,7.264a24.317,24.317,0,0,1,3.39,10.17c.389,2.455.282,3.642-.484,4.358-1.05.982-3.156.919-3.874,0-.628-.8.107-2.111.484-3.39,1.662-5.629-3.234-11.8-3.874-12.591a19.438,19.438,0,0,0-7.749-5.811C226.566,134.882,224.78,134.517,224.773,133.969Z"
              transform="translate(-22.342 -56.478)"
              fill="#d0d0fc"
            />
            <path
              id="Path_6564"
              data-name="Path 6564"
              d="M244.45,164c.18.157-1.239,2.918-3.874,4.843-5.336,3.9-12.59,2.125-12.591,1.937,0-.161,5.323.994,10.654-1.937C242.3,166.83,244.254,163.829,244.45,164Z"
              transform="translate(-27.491 -57.452)"
              fill="#ededfc"
            />
            <g
              id="Group_3120"
              data-name="Group 3120"
              transform="translate(212.359 96.136)"
            >
              <ellipse
                id="Ellipse_197"
                data-name="Ellipse 197"
                cx="4.843"
                cy="6.78"
                rx="4.843"
                ry="6.78"
                transform="translate(0.242 0.242)"
                fill="#e4e4f9"
              />
              <path
                id="Path_6565"
                data-name="Path 6565"
                d="M227.335,167.294c2.8,0,5.085-3.15,5.085-7.022s-2.281-7.022-5.085-7.022-5.085,3.15-5.085,7.022S224.531,167.294,227.335,167.294Zm0-13.56c2.537,0,4.6,2.933,4.6,6.538s-2.064,6.538-4.6,6.538-4.6-2.933-4.6-6.538S224.8,153.734,227.335,153.734Z"
                transform="translate(-222.25 -153.25)"
                fill="#01007c"
              />
            </g>
            <path
              id="Path_6566"
              data-name="Path 6566"
              d="M247.866,171.153c-.051.533-.956.881-2.021.779s-1.887-.616-1.835-1.149.956-.881,2.021-.779S247.917,170.62,247.866,171.153Z"
              transform="translate(-46.412 -57.64)"
              fill="#9494e0"
            />
            <g
              id="Group_3135"
              data-name="Group 3135"
              transform="translate(142.464 78.459)"
            >
              <g id="Group_3122" data-name="Group 3122">
                <g id="Group_3121" data-name="Group 3121">
                  <path
                    id="Path_6567"
                    data-name="Path 6567"
                    d="M269.071,135h31.536a3.082,3.082,0,0,1,3.082,3.082v18.334a3.083,3.083,0,0,1-3.083,3.083H298.79v7.518a.908.908,0,0,1-.262.666.889.889,0,0,1-.614.248.878.878,0,0,1-.619-.256l-8.2-8.177H269.082A3.083,3.083,0,0,1,266,156.416V138.063A3.07,3.07,0,0,1,269.071,135Zm-.238,22.991H289.3a.875.875,0,0,1,.59.229l0,0,.031.03,6.982,6.981V158.87a.7.7,0,0,1,0-.082.88.88,0,0,1,.875-.8h2.7a1.324,1.324,0,0,0,1.324-1.325V138.21a1.324,1.324,0,0,0-1.324-1.325H268.832a1.325,1.325,0,0,0-1.325,1.325v18.459h0A1.326,1.326,0,0,0,268.833,157.991Z"
                    transform="translate(-266 -135)"
                    fill="#5a8ff9"
                  />
                </g>
              </g>
              <g
                id="Group_3124"
                data-name="Group 3124"
                transform="translate(23.766 6.407)"
              >
                <g id="Group_3123" data-name="Group 3123">
                  <path
                    id="Path_6568"
                    data-name="Path 6568"
                    d="M273.7,141.615h5.5a.915.915,0,0,1,.85.542.948.948,0,0,1-.859,1.342h-5.5a.915.915,0,0,1-.85-.542A.949.949,0,0,1,273.7,141.615Z"
                    transform="translate(-272.76 -141.615)"
                    fill="#5a8ff9"
                  />
                </g>
              </g>
              <g
                id="Group_3126"
                data-name="Group 3126"
                transform="translate(14.881 11.307)"
              >
                <g id="Group_3125" data-name="Group 3125">
                  <path
                    id="Path_6569"
                    data-name="Path 6569"
                    d="M273.9,146.674H288.1a.9.9,0,0,1,.859.565.943.943,0,0,1-.81,1.32H273.944a.9.9,0,0,1-.859-.565A.942.942,0,0,1,273.9,146.674Z"
                    transform="translate(-273.016 -146.674)"
                    fill="#5a8ff9"
                  />
                </g>
              </g>
              <g
                id="Group_3128"
                data-name="Group 3128"
                transform="translate(6.285 6.407)"
              >
                <g id="Group_3127" data-name="Group 3127">
                  <path
                    id="Path_6570"
                    data-name="Path 6570"
                    d="M284.028,141.615h12.981a.916.916,0,0,1,.85.542A.948.948,0,0,1,297,143.5H284.019a.916.916,0,0,1-.85-.542A.948.948,0,0,1,284.028,141.615Z"
                    transform="translate(-283.086 -141.615)"
                    fill="#5a8ff9"
                  />
                </g>
              </g>
              <g
                id="Group_3130"
                data-name="Group 3130"
                transform="translate(6.607 11.307)"
              >
                <g id="Group_3129" data-name="Group 3129">
                  <path
                    id="Path_6571"
                    data-name="Path 6571"
                    d="M293.565,146.674h3.42a.916.916,0,0,1,.851.542.949.949,0,0,1-.86,1.343h-3.42a.915.915,0,0,1-.85-.542A.948.948,0,0,1,293.565,146.674Z"
                    transform="translate(-292.624 -146.674)"
                    fill="#5a8ff9"
                  />
                </g>
              </g>
              <g
                id="Group_3132"
                data-name="Group 3132"
                transform="translate(20.828 16.584)"
              >
                <g id="Group_3131" data-name="Group 3131">
                  <path
                    id="Path_6572"
                    data-name="Path 6572"
                    d="M273.6,152.122h8.542a.914.914,0,0,1,.85.542.949.949,0,0,1-.859,1.342h-8.543a.915.915,0,0,1-.85-.542A.949.949,0,0,1,273.6,152.122Z"
                    transform="translate(-272.654 -152.122)"
                    fill="#5a8ff9"
                  />
                </g>
              </g>
              <g
                id="Group_3134"
                data-name="Group 3134"
                transform="translate(6.371 16.584)"
              >
                <g id="Group_3133" data-name="Group 3133">
                  <path
                    id="Path_6573"
                    data-name="Path 6573"
                    d="M286.567,152.122H297a.914.914,0,0,1,.85.542.949.949,0,0,1-.859,1.342H286.559a.915.915,0,0,1-.85-.542A.948.948,0,0,1,286.567,152.122Z"
                    transform="translate(-285.626 -152.122)"
                    fill="#5a8ff9"
                  />
                </g>
              </g>
            </g>
            <g
              id="Group_3150"
              data-name="Group 3150"
              transform="translate(50.366 8.722)"
            >
              <g id="Group_3137" data-name="Group 3137">
                <g id="Group_3136" data-name="Group 3136">
                  <path
                    id="Path_6574"
                    data-name="Path 6574"
                    d="M398.523,66.7V88.867A3.723,3.723,0,0,1,394.8,92.59H370.629l-9.906,9.876a1.059,1.059,0,0,1-.748.308,1.075,1.075,0,0,1-.742-.3,1.1,1.1,0,0,1-.316-.8V92.59h-2.195A3.723,3.723,0,0,1,353,88.867V66.723A3.723,3.723,0,0,1,356.723,63h38.09A3.708,3.708,0,0,1,398.523,66.7ZM396.7,89.172h0v-22.3a1.6,1.6,0,0,0-1.6-1.6H356.876a1.6,1.6,0,0,0-1.6,1.6V89.169a1.6,1.6,0,0,0,1.6,1.6h3.256a1.061,1.061,0,0,1,1.056.963.783.783,0,0,1,.006.1v7.688l8.432-8.431c.012-.014.026-.025.039-.037l0,0a1.06,1.06,0,0,1,.713-.276H395.1A1.6,1.6,0,0,0,396.7,89.172Z"
                    transform="translate(-353 -63)"
                    fill="#5a8ff9"
                  />
                </g>
              </g>
              <g
                id="Group_3139"
                data-name="Group 3139"
                transform="translate(7.907 7.739)"
              >
                <g id="Group_3138" data-name="Group 3138">
                  <path
                    id="Path_6575"
                    data-name="Path 6575"
                    d="M391.447,72.611a1.106,1.106,0,0,1-1.028.655h-6.644a1.146,1.146,0,0,1-1.038-1.621,1.106,1.106,0,0,1,1.028-.655h6.644A1.145,1.145,0,0,1,391.447,72.611Z"
                    transform="translate(-382.637 -70.99)"
                    fill="#5a8ff9"
                  />
                </g>
              </g>
              <g
                id="Group_3141"
                data-name="Group 3141"
                transform="translate(8.208 13.657)"
              >
                <g id="Group_3140" data-name="Group 3140">
                  <path
                    id="Path_6576"
                    data-name="Path 6576"
                    d="M390.815,78.7a1.1,1.1,0,0,1-1.038.681H372.618a1.139,1.139,0,0,1-.979-1.594,1.092,1.092,0,0,1,1.037-.682h17.16A1.14,1.14,0,0,1,390.815,78.7Z"
                    transform="translate(-371.556 -77.1)"
                    fill="#5a8ff9"
                  />
                </g>
              </g>
              <g
                id="Group_3143"
                data-name="Group 3143"
                transform="translate(19.987 7.739)"
              >
                <g id="Group_3142" data-name="Group 3142">
                  <path
                    id="Path_6577"
                    data-name="Path 6577"
                    d="M378.682,72.611a1.1,1.1,0,0,1-1.027.655H361.976a1.146,1.146,0,0,1-1.038-1.621,1.107,1.107,0,0,1,1.028-.655h15.678A1.146,1.146,0,0,1,378.682,72.611Z"
                    transform="translate(-360.838 -70.99)"
                    fill="#5a8ff9"
                  />
                </g>
              </g>
              <g
                id="Group_3145"
                data-name="Group 3145"
                transform="translate(31.146 13.657)"
              >
                <g id="Group_3144" data-name="Group 3144">
                  <path
                    id="Path_6578"
                    data-name="Path 6578"
                    d="M367.536,78.721a1.107,1.107,0,0,1-1.028.655h-4.131a1.146,1.146,0,0,1-1.039-1.621,1.106,1.106,0,0,1,1.028-.655H366.5A1.146,1.146,0,0,1,367.536,78.721Z"
                    transform="translate(-361.239 -77.1)"
                    fill="#5a8ff9"
                  />
                </g>
              </g>
              <g
                id="Group_3147"
                data-name="Group 3147"
                transform="translate(7.784 20.03)"
              >
                <g id="Group_3146" data-name="Group 3146">
                  <path
                    id="Path_6579"
                    data-name="Path 6579"
                    d="M391.455,85.3a1.1,1.1,0,0,1-1.027.655H380.111a1.145,1.145,0,0,1-1.037-1.621,1.1,1.1,0,0,1,1.027-.655h10.317A1.146,1.146,0,0,1,391.455,85.3Z"
                    transform="translate(-378.973 -83.68)"
                    fill="#5a8ff9"
                  />
                </g>
              </g>
              <g
                id="Group_3149"
                data-name="Group 3149"
                transform="translate(22.959 20.03)"
              >
                <g id="Group_3148" data-name="Group 3148">
                  <path
                    id="Path_6580"
                    data-name="Path 6580"
                    d="M375.714,85.3a1.1,1.1,0,0,1-1.027.655h-12.6a1.146,1.146,0,0,1-1.038-1.621,1.107,1.107,0,0,1,1.028-.655h12.6A1.146,1.146,0,0,1,375.714,85.3Z"
                    transform="translate(-360.945 -83.68)"
                    fill="#5a8ff9"
                  />
                </g>
              </g>
            </g>
            <g
              id="Group_3153"
              data-name="Group 3153"
              transform="translate(61.503 144.322)"
            >
              <g id="Group_3152" data-name="Group 3152">
                <g id="Group_3151" data-name="Group 3151">
                  <path
                    id="Path_6581"
                    data-name="Path 6581"
                    d="M387.088,223.49a1.85,1.85,0,0,1-.011,2.819c-.588.594-1.214,1.159-1.769,1.78a3.484,3.484,0,0,1-2.988,1.137,12.416,12.416,0,0,1-4.751-1.382,25.908,25.908,0,0,1-8.578-6.71,23.567,23.567,0,0,1-4.184-6.972,9.013,9.013,0,0,1-.7-3.88,3.155,3.155,0,0,1,.991-2.128c.663-.631,1.29-1.29,1.943-1.932a1.844,1.844,0,0,1,2.77,0c.528.516,1.045,1.044,1.567,1.567.507.511,1.012,1.012,1.519,1.523a1.839,1.839,0,0,1,.005,2.835c-.637.642-1.268,1.285-1.915,1.911a.4.4,0,0,0-.1.511,11.462,11.462,0,0,0,1.737,2.781,20.077,20.077,0,0,0,4.844,4.408c.4.251.849.436,1.268.665a.382.382,0,0,0,.533-.1c.637-.654,1.284-1.3,1.933-1.938a1.832,1.832,0,0,1,2.77,0Q385.534,221.934,387.088,223.49Z"
                    transform="translate(-364.092 -203.081)"
                    fill="#58dbce"
                  />
                  <path
                    id="Path_6582"
                    data-name="Path 6582"
                    d="M370.935,212.388a6.93,6.93,0,0,0-3.962-1.975l.283-2.019a9.013,9.013,0,0,1,7.625,7.4l-2.008.343A6.933,6.933,0,0,0,370.935,212.388Z"
                    transform="translate(-354.029 -203.17)"
                    fill="#58dbce"
                  />
                  <path
                    id="Path_6583"
                    data-name="Path 6583"
                    d="M374.442,215.273l-2.008.342a12.922,12.922,0,0,0-10.934-10.6l.284-2.019a14.967,14.967,0,0,1,12.658,12.273Z"
                    transform="translate(-348.289 -203)"
                    fill="#58dbce"
                  />
                </g>
              </g>
            </g>
            <path
              id="Path_6601"
              data-name="Path 6601"
              d="M449.653,239.837H402.4L377.331,204h47.253Z"
              transform="translate(-377.331 -58.71)"
              fill="#707070"
            />
            <g
              id="Group_3164"
              data-name="Group 3164"
              transform="translate(60.058)"
            >
              <g
                id="Group_3163"
                data-name="Group 3163"
                transform="translate(52.032)"
              >
                <path
                  id="Path_6584"
                  data-name="Path 6584"
                  d="M260.569,111.841a7.978,7.978,0,0,0,1.9-1.792,12.391,12.391,0,0,1,1.739-2.653.815.815,0,0,1,1.3.581c.16,2.121-2.381,4.446-4.28,4.977C260.389,113.309,259.826,112.285,260.569,111.841Z"
                  transform="translate(-191.864 -55.668)"
                  fill="#7ac8a9"
                />
                <path
                  id="Path_6585"
                  data-name="Path 6585"
                  d="M256.237,101.627a3.833,3.833,0,0,0,1.99-1.9,5.574,5.574,0,0,0,0-2.522.789.789,0,0,1,1.306-.68c1.23.877,1.168,2.627.484,3.842a4.78,4.78,0,0,1-3.49,2.591A.726.726,0,0,1,256.237,101.627Z"
                  transform="translate(-182.652 -55.325)"
                  fill="#7ac8a9"
                />
                <path
                  id="Path_6586"
                  data-name="Path 6586"
                  d="M257.392,90.9c1.056-1.437-.025-2.907-.616-4.317-.355-.847.787-1.126,1.217-.577,1.481,1.634,1.953,4.023.244,5.8A.622.622,0,0,1,257.392,90.9Z"
                  transform="translate(-182.264 -54.992)"
                  fill="#7ac8a9"
                />
                <path
                  id="Path_6587"
                  data-name="Path 6587"
                  d="M266.559,79.121a8.279,8.279,0,0,0-1.759-4.037c-.755-1.01-1.956-1.5-2.8-2.408a.505.505,0,0,1,.521-.815c3.129.346,4.8,4.487,4.9,7.1A.439.439,0,1,1,266.559,79.121Z"
                  transform="translate(-195.389 -54.555)"
                  fill="#7ac8a9"
                />
                <path
                  id="Path_6588"
                  data-name="Path 6588"
                  d="M272.5,63.566c-.608-.342-.4-1.425.3-1.186,2.476.686,4.387,2.869,5.614,5.007.222.372-.327.8-.549.43A16.3,16.3,0,0,0,272.5,63.566Z"
                  transform="translate(-216.346 -54.257)"
                  fill="#7ac8a9"
                />
                <path
                  id="Path_6589"
                  data-name="Path 6589"
                  d="M287.14,55.282a10.453,10.453,0,0,1,7.042,2.087c.519.446-.195,1.275-.713.829-1.852-1.411-4.27-1.326-6.346-1.849A.534.534,0,1,1,287.14,55.282Z"
                  transform="translate(-246.239 -54.035)"
                  fill="#7ac8a9"
                />
                <path
                  id="Path_6590"
                  data-name="Path 6590"
                  d="M303.287,56.048a11.564,11.564,0,0,1,3.3-1.315A13.022,13.022,0,0,1,309.92,54a.486.486,0,0,1,.073.965c-2.107.353-4.111,2.055-6.441,2.037A.526.526,0,0,1,303.287,56.048Z"
                  transform="translate(-278.23 -53.995)"
                  fill="#7ac8a9"
                />
                <path
                  id="Path_6591"
                  data-name="Path 6591"
                  d="M318.359,61.353a20.234,20.234,0,0,1,3-1.39c1.128-.473,2.152-1.034,3.37-1.612.564-.236,1.008.507.444.743-1.752,1.2-4.217,3.227-6.458,3.105C318.135,62.243,317.9,61.679,318.359,61.353Z"
                  transform="translate(-307.847 -54.13)"
                  fill="#7ac8a9"
                />
                <path
                  id="Path_6600"
                  data-name="Path 6600"
                  d="M325,68.82a34.473,34.473,0,0,1,6.677-10.492c.713-.829,1.957.241,1.348,1.159a40.283,40.283,0,0,0-5.057,8.819l4.137.851c1.186.3,2.937.36,3.6,1.474a.893.893,0,0,1-.285,1.379c-1.2.769-2.61.1-3.8-.2-1.883-.537-3.751-.88-5.621-1.225C325.045,70.659,324.676,69.621,325,68.82Z"
                  transform="translate(-324.886 -54.121)"
                  fill="#7ac8a9"
                />
              </g>
              <g
                id="Group_3162"
                data-name="Group 3162"
                transform="translate(0 55.375)"
              >
                <path
                  id="Path_6592"
                  data-name="Path 6592"
                  d="M384.9,113.835l.16-.211.08-.106.16-.212.24-.318c.16-.211.32-.423.48-.634h0a4.8,4.8,0,0,1,.9-.983.5.5,0,0,1,.794.6,4.758,4.758,0,0,0-.7,1.133h0l-.48.634-.24.318-.16.211h0l-.24.318a.566.566,0,0,1-.816.048A.644.644,0,0,1,384.9,113.835Z"
                  transform="translate(-382.828 -111.166)"
                  fill="#7ac8a9"
                />
                <path
                  id="Path_6593"
                  data-name="Path 6593"
                  d="M385.008,133.838c-.151-.594.788-.844,1.015-.351.361.911.549,1.853.909,2.765a27.941,27.941,0,0,0,1.176,2.809c.228.492-.482,1.234-.883.771C385.584,138.432,385.479,135.794,385.008,133.838Z"
                  transform="translate(-383.308 -111.86)"
                  fill="#7ac8a9"
                />
                <path
                  id="Path_6594"
                  data-name="Path 6594"
                  d="M376.578,148.573a9.894,9.894,0,0,0-1.734-2.27c-.5-.535.1-1.522.769-1.017a11.149,11.149,0,0,1,2.06,2.037,5.774,5.774,0,0,1,1.311,2.432c-.04.449-.348.856-.807.669C377.357,150.122,377.091,149.282,376.578,148.573Z"
                  transform="translate(-364.135 -112.234)"
                  fill="#7ac8a9"
                />
                <path
                  id="Path_6595"
                  data-name="Path 6595"
                  d="M362.175,154.954a.575.575,0,0,1,.692-.915c1.795,1.2,4.2,2.378,5.612,4.083.4.463-.291,1.378-.846,1.12C365.608,158.349,363.816,156.354,362.175,154.954Z"
                  transform="translate(-341.448 -112.51)"
                  fill="#7ac8a9"
                />
                <path
                  id="Path_6596"
                  data-name="Path 6596"
                  d="M350.752,160.333a17.664,17.664,0,0,0,2.947.469c.975.1,1.915-.152,2.754.324.383.289.188.942-.137,1.175a5.2,5.2,0,0,1-3.082-.091,26.878,26.878,0,0,1-2.982-.817C349.62,161.236,350.122,160.176,350.752,160.333Z"
                  transform="translate(-317.89 -112.711)"
                  fill="#7ac8a9"
                />
                <path
                  id="Path_6597"
                  data-name="Path 6597"
                  d="M337.99,160.887c.939-.249,1.974-.427,2.989-.778s2.09-.979,3.085-.707a.71.71,0,0,1,.283,1.014c-.615.815-1.918.949-2.856,1.2-1.111.279-2.241.385-3.275.562C337.354,162.325,337.147,161.209,337.99,160.887Z"
                  transform="translate(-293.569 -112.68)"
                  fill="#7ac8a9"
                />
                <path
                  id="Path_6598"
                  data-name="Path 6598"
                  d="M326.434,157.354a16.513,16.513,0,0,1,2.173-2.674,6.951,6.951,0,0,1,3.09-2.3c.69-.118,1.031.62.742,1.2a8.789,8.789,0,0,1-2.419,2.01c-.979.7-1.939,1.572-2.822,2.343C326.717,158.368,326.3,157.732,326.434,157.354Z"
                  transform="translate(-270.956 -112.461)"
                  fill="#7ac8a9"
                />
                <path
                  id="Path_6599"
                  data-name="Path 6599"
                  d="M326.523,153.9a44.082,44.082,0,0,0,1.674-7.371c-.749.394-1.591.716-2.416,1.212a25.879,25.879,0,0,1-3.97,2.274.959.959,0,0,1-1.125-1.49c.828-1.292,2.42-2.009,3.666-2.666,1.573-.889,3.164-1.6,4.832-2.422a1.085,1.085,0,0,1,1.527,1.155c-.874,3.338-1.461,6.891-3.252,9.855C327.189,155.2,326.329,154.552,326.523,153.9Z"
                  transform="translate(-263.397 -112.177)"
                  fill="#7ac8a9"
                />
                <g
                  id="Group_3154"
                  data-name="Group 3154"
                  transform="translate(0 8.24)"
                >
                  <path
                    id="Path_6602"
                    data-name="Path 6602"
                    d="M389.892,120.572a17.942,17.942,0,0,0-.569,6.9c.1.928-1.353.921-1.453,0a19,19,0,0,1,.621-7.282.727.727,0,0,1,1.4.385Z"
                    transform="translate(-387.758 -119.674)"
                    fill="#7ac8a9"
                  />
                </g>
              </g>
            </g>
            <g
              id="Group_3155"
              data-name="Group 3155"
              transform="translate(107.199 131.846)"
            >
              <path
                id="Path_6603"
                data-name="Path 6603"
                d="M341.135,191.017q-1.653,5.742-3.306,11.483a.727.727,0,0,1-1.4-.386q1.652-5.742,3.306-11.483a.727.727,0,0,1,1.4.387Z"
                transform="translate(-336.397 -190.119)"
                fill="#1f1fb2"
              />
            </g>
            <g
              id="Group_3156"
              data-name="Group 3156"
              transform="translate(110.229 121.155)"
            >
              <path
                id="Path_6604"
                data-name="Path 6604"
                d="M338.021,179.983q-1.5,5.669-2.994,11.337a.727.727,0,0,1-1.4-.387q1.5-5.669,2.994-11.336a.727.727,0,0,1,1.4.385Z"
                transform="translate(-333.6 -179.082)"
                fill="#1f1fb2"
              />
            </g>
            <path
              id="Path_6605"
              data-name="Path 6605"
              d="M233.1,181.272c1.772.436,4.223-1.027,4.911-1.433,2.528-1.511,4.378-4.155,3.874-4.843-.446-.61-2.237.988-5.811.969-1.6-.01-2.48-.339-3.158-.281a2.679,2.679,0,0,1,1.22,1.249C234.764,178.289,234.018,179.79,233.1,181.272Z"
              transform="translate(-29.767 -57.794)"
              fill="#e66e52"
            />
          </g>
        </g>
        <g id="furniture" transform="translate(0 179.721)">
          <rect
            id="Rectangle_1246"
            data-name="Rectangle 1246"
            width="6.367"
            height="60.484"
            rx="1.11"
            transform="translate(159.554 0) rotate(90)"
            fill="#707070"
          />
          <rect
            id="Rectangle_1247"
            data-name="Rectangle 1247"
            width="181.122"
            height="8.121"
            rx="4"
            transform="translate(0 4.307)"
            fill="#e2e2e2"
          />
          <path
            id="Path_6606"
            data-name="Path 6606"
            d="M343.717,267.759H480.885q14.807,101.623,15.681,101.421T483.975,263.764v-8.289c0-2.2-1.39-3.994-3.09-3.994H343.717c-1.7,0-3.09,1.8-3.09,3.994q-13.766,113.44-12.591,113.7Q329.15,369.426,343.717,267.759Z"
            transform="translate(-322.224 -237.023)"
            fill="#e2e2e2"
          />
        </g>
      </g>
    </svg>
  );
};

HelpdeskSvg.propTypes = {};

export default HelpdeskSvg;
