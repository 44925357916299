/* eslint-disable no-use-before-define */
import axios from 'axios'
import {
  GET_USERS,
  USER_LOADING,
  USER_ERROR,
  CREATE_USER_SUCCESS,
  UPDATE_USER,
  GET_SINGLE_USER,
  UPDATE_USER_STATUS,
} from './types'
import { showSuccess } from './ActionsHelper'
import { transformErrorsFromApi } from './TransformError'

export const getUsers = (search, sysuser, eventid) => (dispatch) => {
  dispatch(setUsersLoading())
  let url = '/api/user'

  if (search) {
    url = `/api/user?q=${search}`
  } else if (sysuser) {
    url = `/api/user?q=${sysuser}`
  } else if (eventid) {
    url = `/api/user?eventid=${eventid}`
  }

  axios
    .get(url)
    .then((res) => {
      dispatch({
        type: GET_USERS,
        // reset: page === 0,
        payload: res.data,
      })
    })
    // eslint-disable-next-line no-unused-vars
    .catch((err) =>
      dispatch({
        type: USER_ERROR,
        payload: {},
      })
    )
}

export const createUser = (data, setErrors, setSubmitting, history) => (
  dispatch
) => {
  dispatch(setUsersLoading())
  axios
    .post('/api/user', data)
    .then((result) => {
      dispatch({
        type: CREATE_USER_SUCCESS,
        payload: result.data,
      })
      history.push('/users')
      dispatch(showSuccess('User'))
      setSubmitting(false)
    })
    .catch((err) => {
      const error = err.response
      dispatch({ type: USER_ERROR, payload: error.data })
      setSubmitting(false)
      setErrors(transformErrorsFromApi(error))
      // history.push("/co-workers");
      // dispatch(showError("There was some error while adding coworker!"));
    })
}

export const updateUser = (id, data, setErrors, setSubmitting, history) => (
  dispatch
) => {
  dispatch(setUsersLoading())
  axios
    .put(`/api/user/${id}`, data)

    .then((res) => {
      dispatch({
        type: UPDATE_USER,
        payload: res.data,
      })
      history.push('/users')
      dispatch(showSuccess(' ', 'User Updated Successfully!'))
      setSubmitting(false)
    })
    .catch((err) => {
      const error = err.response
      dispatch({ type: USER_ERROR, payload: error && error.data })
      setSubmitting(false)
      setErrors(transformErrorsFromApi(error ? error : ''))
    })
}

export const getSingleUser = (id) => (dispatch) => {
  dispatch(setUsersLoading())
  axios
    .get(`/api/user/${id}`)
    .then((res) => {
      dispatch({
        type: GET_SINGLE_USER,
        payload: res.data,
      })
    })
    .catch((err) =>
      dispatch({
        type: USER_ERROR,
        payload: err && err.res ? err.res.data : '',
      })
    )
}

export const updateUserStatus = (id, userData, setSubmitting) => (dispatch) => {
  // dispatch(setCoworkersLoading());

  axios
    .put(`/api/user/changestatus/${id}`, userData)
    .then((result) => {
      dispatch({
        type: UPDATE_USER_STATUS,
        payload: result.data,
      })
      dispatch(showSuccess('', 'Status updated successfully!'))
      setSubmitting(false)
    })
    .catch((err) => {
      dispatch({
        type: USER_ERROR,
        // payload: err.response.data
        payload: ((err || {}).response || {}).data || 'Error unexpected',
      })
    })
}

// Profile Loading
export const setUsersLoading = () => {
  return {
    type: USER_LOADING,
  }
}
