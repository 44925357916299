import axios from "axios";
import {
  GET_ALL_ORDER,
  ADD_ORDER_LOADING,
  ADD_ORDER_ERRORS,
  ADD_ORDER,
  ORDER_DELIVERED_PENDING,
  ORDER_PROCESS_STATUS,
  ORDER_STATUS,
  MAIN_ORDER_STATUS_SUCCESS,
  MAIN_ORDER_STATUS_ERRORS,
  MY_ORDERS,
  MY_ORDERS_ACCOUNT_DETAILS,
  GET_ORDER_ERRORS,
} from "./types";
import { showSuccess, showError } from "./ActionsHelper";

//  Loading
export const setOrderLoading = () => {
  return {
    type: ADD_ORDER_LOADING,
  };
};

export const addOrder = (
  userData,

  history
) => (dispatch) => {
  dispatch(setOrderLoading());
  axios
    .post("/api/order/", userData)
    .then((result) => {
      dispatch({
        type: ADD_ORDER,
        payload: result.data,
      });
      history.push("/cafeteria");
      dispatch(showSuccess("Order "));
    })
    .catch((err) => {
      const error = err.response || {};
      dispatch({ type: ADD_ORDER_ERRORS, payload: error.data });
      dispatch(showError("There was some error while add Order"));
    });
};

export const getAllOrder = () => (dispatch) => {
  dispatch(setOrderLoading());
  axios
    .get(`/api/order/`)
    .then((res) => {
      dispatch({
        type: GET_ALL_ORDER,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: ADD_ORDER_ERRORS,
        payload: err.response.data,
      })
    );
};
export const getDeliveredPending = (status, search) => (dispatch) => {
  dispatch(setOrderLoading());
  let url = `/api/order?status=${status}`;
  if (search) {
    url = `/api/order?status=${status}&q=${search}`;
  }
  axios
    .get(url)
    .then((res) => {
      dispatch({
        type: ORDER_DELIVERED_PENDING,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ORDER_ERRORS,
        payload: err && err.response ? err.response : "",
      });
    });
};
export const updateOrderProcessStatus = (id, userData) => (dispatch) => {
  // dispatch(setOrderLoading());
  axios
    .put(`/api/order/changestatus/${id}`, userData)
    .then((result) => {
      dispatch({
        type: ORDER_PROCESS_STATUS,
        payload: result.data,
      });
      dispatch(showSuccess("", "Status updated successfully!"));
    })
    .catch((err) => {
      const error = err.response || {};
      dispatch({ type: ADD_ORDER_ERRORS, payload: error.data });
      dispatch(showError("There was some error while Update Order status"));
    });
};

export const updateOrderStatus = (id, userData) => (dispatch) => {
  dispatch(setOrderLoading());
  axios
    .put(`/api/fooditem/changestatus/${id}`, userData)
    .then((result) => {
      dispatch({
        type: ORDER_STATUS,
        payload: result.data,
      });
      dispatch(showSuccess("", "Status updated successfully!"));
    })
    .catch((err) => {
      const error = err.response || {};
      dispatch({ type: ADD_ORDER_ERRORS, payload: error.data });
      dispatch(showError("There was some error while Update Order"));
    });
};

// accept reject order
export const acceptRejectOrder = (id, data) => (dispatch) => {
  dispatch(setOrderLoading());
  axios
    // .put(`/api/order/${id}`, data)
    .post(`/api/order/updatestatus`, data)
    .then((result) => {
      dispatch({
        type: MAIN_ORDER_STATUS_SUCCESS,
        payload: result.data,
      });
      dispatch(showSuccess("", "Status updated successfully!"));
    })
    .catch((err) => {
      const error = err.response || {};
      dispatch({ type: MAIN_ORDER_STATUS_ERRORS, payload: error.data });
      dispatch(showError("There was some error while Update Order"));
    });
};

export const getMyOrders = () => (dispatch) => {
  dispatch(setOrderLoading());
  axios
    .get(`/api/order/myorder`)
    .then((res) => {
      dispatch({
        type: MY_ORDERS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: ADD_ORDER_ERRORS,
        payload: err.response && err.response.data,
      })
    );
};
export const getMyOrdersAccountDetails = () => (dispatch) => {
  dispatch(setOrderLoading());
  axios
    .get(`/api/order/myorder/accountdetails`)
    .then((res) => {
      dispatch({
        type: MY_ORDERS_ACCOUNT_DETAILS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: ADD_ORDER_ERRORS,
        payload: err.response && err.response.data,
      })
    );
};
