import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  getEvent,
  eventParticipants,
  getEventDiscussion
} from "../../Actions/eventActions";
import EventTabs from "./EventTabs";
import AboutEventTopDetail from "./AboutEventTopDetail";
import AboutEventTab from "./AboutEventTab";

const styles = () => ({
  main: {
    backgroundColor: "#FCFDFE"
  }
});
export class ViewaEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event: {}, // Single event object
      discussion: [] // Discussion(post) array
    };
    this.state.id = this.props.match.params.id; // event id
  }

  componentDidMount() {
    this.props.getEvent(this.state.id); // get single event
    this.props.getEventDiscussion(this.state.id); // get event Discussion(post)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.event !== prevState.event) {
      return { event: nextProps.event };
    }
    if (nextProps.discussion !== prevState.discussion) {
      return { discussion: nextProps.discussion };
    }
    return [];
  }

  // when any event action perform the component has been updated
  componentDidUpdate(prevProps) {
    if (prevProps.event !== this.props.event) {
      this.setState({
        event: this.props.event
      });
    }
    if (prevProps.discussion !== this.props.discussion) {
      this.setState({
        discussion: this.props.discussion
      });
      this.props.getEvent(this.state.id);
    }
    if (
      prevProps.discussion &&
      prevProps.discussion.success !== this.props.discussion.success &&
      this.props.discussion.success
    ) {
      this.props.getEvent(this.state.id);
    }
    if (prevProps.event_participant !== this.props.event_participant) {
      this.props.getEvent(this.state.id);
    }
    if (
      this.props.register_team !== prevProps.register_team &&
      this.props.register_team.success
    ) {
      this.props.getEvent(this.state.id);
    }
  }

  render() {
    const { classes } = this.props;
    const { event, discussion } = this.state;

    return (
      <React.Fragment>
        <CssBaseline />
        <main className={classes.main}>
          {/* About event top detail component */}
          <AboutEventTopDetail event={event} />
          <div className={classes.inner_section}>
            {/* Event tabs component */}
            {event.event_type === "team" ? (
              <EventTabs id={this.state.id} data={discussion} event={event} />
            ) : (
              <AboutEventTab
                data={discussion}
                event={event}
                id={this.state.id}
              />
            )}
          </div>
        </main>
      </React.Fragment>
    );
  }
}

ViewaEvent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    event: state.events.event,
    event_participant: state.events.event_participant,
    discussion: state.events.discussion,
    register_team: state.events.register_team
  };
};

export default connect(mapStateToProps, {
  getEvent,
  eventParticipants,
  getEventDiscussion
})(withStyles(styles)(ViewaEvent));
