/* eslint-disable no-cond-assign */
/* eslint-disable no-else-return */
/* eslint-disable react/prefer-stateless-function */
import React from 'react'
import { Grid, Menu } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'
import { createMuiTheme, withStyles } from '@material-ui/core/styles'
import orderImage from '../../Assets/notification/Notification_Cafe.png'
import visitorImage from '../../Assets/notification/Notification_Visitor.png'
import helpdeskImage from '../../Assets/notification/Notification_Helpdesk.png'
import bookingImage from '../../Assets/notification/Notification_Booking.png'
import nodata from '../../Assets/nodata.png'
import bannerImage from '../../Assets/company_default_banner.jpg'

const moment = require('moment')

const styles = () => ({
  notificationCard: {
    padding: '10px',
    boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.1)',
    borderRadius: '5px',
    display: 'flex',
    marginBottom: '10px',
  },
  eventCard: {
    padding: '10px',
    boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.1)',
    borderRadius: '5px',
    marginBottom: '10px',
  },
  otherCard: {
    padding: '10px',
    boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.1)',
    borderRadius: '5px',
    marginBottom: '10px',
  },
  notificationTitle: {
    fontSize: '12px',
    color: '#3A88EE',
  },
  cardContent: {
    color: '#626262',
    fontSize: '12px',
  },
  cardTime: {
    color: '#ABABAB',
    fontSize: '10px',
  },
  image: {
    padding: '12px 0px 0px 18px',
  },
  bannerimage: {
    width: '100%',
    // height: '180px',
    objectFit: 'contain',
    objectPosition: 'center',
  },
  time: {
    color: '#ABABAB',
    fontSize: '10px',
    padding: '4px 0px 0px 0px',
  },
  simpleText: {
    display: 'inline',
    margin: '0px',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#626262',
  },
  boldText: {
    display: 'inline',
    margin: '0px',
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 'bold',
    color: '#626262',
    textTransform: 'capitalize',
  },
  nodataSection: {
    textAlign: 'center',
  },
})

const theme = createMuiTheme({
  overrides: {
    MuiMenu: {
      // Name of the component  / style sheet
      paper: {
        width: '300px',
        padding: '10px',
        top: '45px !important',
        maxHeight: 'calc(100% - 196px) !important',
      },
    },
  },
})

function extract(string, props) {
  const { classes } = props
  const found = [] // an array to collect the strings that are found
  const rxp = /{([^}]+)}/g
  const str = string
  let curMatch
  let lastIndex = 0
  while ((curMatch = rxp.exec(str))) {
    found.push(
      <p className={classes.simpleText}>
        {str.substring(lastIndex, curMatch.index)}
      </p>
    )
    found.push(<p className={classes.boldText}>{curMatch[1]}</p>)
    lastIndex = curMatch.index + curMatch[0].length
  }
  found.push(
    <p className={classes.simpleText}>{str.substring(lastIndex, str.length)}</p>
  )
  return found
}
class RenderNotificationMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  renderTime = (data) => {
    const Time = moment(data).fromNow()
    return Time
  }

  renderSVG = (type) => {
    const { classes } = this.props
    switch (type) {
      case 'order':
        return <img src={orderImage} alt="" className={classes.image} />
      case 'visitor':
        return <img src={visitorImage} alt="" className={classes.image} />
      case 'helpdesk':
        return <img src={helpdeskImage} alt="" className={classes.image} />
      case 'booking':
        return <img src={bookingImage} alt="" className={classes.image} />

      default:
        return null
    }
  }

  renderCard = (data) => {
    const { classes } = this.props
    if (
      data.type === 'order' ||
      data.type === 'visitor' ||
      data.type === 'helpdesk' ||
      data.type === 'booking'
    ) {
      return (
        <div>
          <Grid item key={data.id} className={classes.notificationCard}>
            <Grid item lg={8} xs={8} sm={8}>
              <p className={classes.notificationTitle}>{data.title}</p>
              {extract(data.description, this.props)}
              <p className={classes.cardTime}>
                {this.renderTime(data.createdAt)}
              </p>
            </Grid>
            <Grid item lg={3} xs={3} sm={3}>
              {this.renderSVG(data.type)}
            </Grid>
          </Grid>
        </div>
      )
    } else if (
      data.type === 'event' ||
      data.type === 'company' ||
      data.type === 'club'
    )
      return (
        <div>
          <Grid item key={data.id} className={classes.eventCard}>
            <Grid item lg={12} xs={12} sm={12}>
              <p className={classes.notificationTitle}>{data.title}</p>
              {extract(data.description, this.props)}
              <p className={classes.time}>{this.renderTime(data.createdAt)}</p>
            </Grid>
            <Grid item lg={12} xs={12} sm={12}>
              <img
                src={data.banner ? data.banner : bannerImage}
                alt="img"
                className={classes.bannerimage}
              />
            </Grid>
          </Grid>
        </div>
      )
    else
      return (
        // <Grid item className={classes.otherCard}>
        <Grid item lg={12} xs={12} sm={12} className={classes.otherCard}>
          <p className={classes.notificationTitle}>{data.title}</p>
          {extract(data.description, this.props)}
          <p className={classes.cardTime}>{this.renderTime(data.createdAt)}</p>
        </Grid>
        // </Grid>
      )
  }

  render() {
    const {
      anchorElNotification,
      isNotificationOpen,
      data,
      classes,
    } = this.props

    return (
      <ThemeProvider theme={theme}>
        <Menu
          anchorEl={anchorElNotification}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={isNotificationOpen}
          onClose={this.props.handleNotificationClose}
        >
          {data && data.length <= 0 ? (
            <div className={classes.nodataSection}>
              <img src={nodata} alt="" width="50%" />
            </div>
          ) : (
            <Grid container spacing={2}>
              {data &&
                data.map((element) => {
                  return this.renderCard(element)
                })}
            </Grid>
          )}
        </Menu>
      </ThemeProvider>
    )
  }
}

export default withStyles(styles)(RenderNotificationMenu)
