import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Paper, Button } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { connect } from "react-redux";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { UploadLogoSvg } from "../../Assets/svgs/index";
import { capitalize } from "../../Utils/StringFunctions";
import { addReportBug, getReportBug } from "../../Actions/ReportBugListAction";
import Pagination from "../../Components/Common/Table/TablePagination";
import { CircularLoader } from "../../Components/Common/Spinner";
import ScreenHeader from "../../Components/Common/ScreenHeader";
import ReportStatus from "./ReportStatus";
import nodata from "../../Assets/nodata.png";

export class IssueReportList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // reportBug: [],
      page: 0,
      rowsPerPage: 10,
    };
  }

  componentDidMount() {
    this.props.getReportBug();
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  render() {
    const { classes, reportBug, loading } = this.props;
    const { page, rowsPerPage } = this.state;

    const itemLength = reportBug ? reportBug.length : 0;

    if (loading) {
      return <CircularLoader />;
    }
    if (reportBug && reportBug.length <= 0) {
      return (
        <main className={classes.rootheader}>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.headerSpacing}
          >
            <Grid item justify="center">
              <Grid item sm={12} lg={12}>
                <img src={nodata} alt="" width="76%" />
              </Grid>
            </Grid>
          </Grid>
        </main>
      );
    }
    return (
      <main className={classes.root}>
        <Paper className={classes.paper}>
          <Button
            color="grey"
            onClick={() => this.props.history.push("/settings")}
            className={classes.backButton}
            variant="contained"
            size="small"
          >
            Back
          </Button>
          <div className={classes.header}>
            <ScreenHeader type="Reported Errors" />
            <Grid
              container
              direction="row"
              justify="flex-end"
              className={classes.endSpacing}
            ></Grid>
          </div>
        </Paper>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead className={classes.myClassName}>
              <TableRow>
                <TableCell className={classes.removeBorder}>Sr. No.</TableCell>
                <TableCell align="left" className={classes.removeBorder}>
                  Date
                </TableCell>
                <TableCell align="left" className={classes.removeBorder}>
                  Module
                </TableCell>
                <TableCell
                  align="left"
                  className={`${classes.removeBorder} ${classes.onlydesc}`}
                >
                  Description
                </TableCell>
                <TableCell align="left" className={classes.removeBorder}>
                  Name
                </TableCell>
                <TableCell align="left" className={classes.removeBorder}>
                  Status
                </TableCell>
                <TableCell align="left" className={classes.removeBorder}>
                  Download
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reportBug.length > 0 &&
                reportBug
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow key={index} className={classes.tableRow}>
                      <TableCell className={classes.removeBorder}>
                        {page * rowsPerPage + index + 1}
                      </TableCell>
                      <TableCell className={classes.removeBorder}>
                        {moment(row.createdAt).format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell className={classes.removeBorder}>
                        {capitalize(row.module_name)}
                      </TableCell>
                      <TableCell
                        className={`${classes.removeBorder} ${classes.descbreak}`}
                      >
                        {row.description}
                      </TableCell>
                      <TableCell className={classes.removeBorder}>
                        {capitalize(row.created_by.first_name)}{" "}
                        {capitalize(row.created_by.last_name)}
                      </TableCell>
                      <TableCell className={classes.removeBorder}>
                        <ReportStatus
                          selectedItem={row.bug_status}
                          id={row._id}
                        />
                      </TableCell>
                      <TableCell className={classes.cellCenter}>
                        {row.bug_image ? (
                          <a
                            href={row.bug_image}
                            target="_blank"
                            className={classes.spacingLogo}
                            rel="noopener noreferrer"
                            download
                          >
                            <UploadLogoSvg />
                          </a>
                        ) : (
                          "-"
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        {reportBug && reportBug.length > 0 && (
          <Pagination
            count={itemLength}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        )}
      </main>
    );
  }
}

const useStyles = (theme) => ({
  table: {
    minWidth: 650,
    justifyContent: "center",
    alignItems: "left",
  },
  spacingLogo: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    height: "40px",
    width: "40px",
    margin: "auto",
    background: "whiteSmoke",
    transform: "rotate(180deg)",
  },
  cellCenter: {
    textAlign: "center",
    border: "none",
  },
  tableRow: {
    "&:nth-child(even)": {
      backgroundColor: "#FCFDFE",
    },
  },
  root: {
    minHeight: "inherit",
    backgroundColor: "#fff",
    padding: "24px",
    width: "100%",
    display: "inherit",
    alignItems: "inherit",
    textTransform: "initial",
    justifyContent: "inherit",
    position: "relative",
    paddingBottom: "5px",
  },
  rootheader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  header: {
    position: "relative",
    paddingBottom: "5px",
    marginLeft: "-20px",
    width: "100%",
  },
  endSpacing: {
    padding: "8px 21px 0px",
  },
  removeBorder: {
    border: "none",
  },
  descbreak: {
    wordBreak: "break-word",
    textAlign: "justify",
  },
  myClassName: {
    backgroundColor: "#F8FAFF",
    color: theme.palette.common.black,
    borderBottom: "none",
  },
  headerSpacing: {
    margin: "13% 0% 0% 3%",
  },
  backButton: {
    margin: "0% 0% 2% 0%",
  },
});

const mapStateToProps = (state) => {
  return {
    reportBug: state.reportBug.bugReports,
    loading: state.reportBug.loading,
  };
};

export default connect(mapStateToProps, {
  addReportBug,
  getReportBug,
})(withStyles(useStyles)(withRouter(IssueReportList)));
