import React from "react";
import { Grid, InputAdornment, withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Instagram,
  Linkedin,
  Twitter,
  FacebookRoundIcon,
} from "../../Assets/index";

import { InputField, UploadField } from "../../Components/Common/Form";
import ServicelistDropDown from "../../Components/Common/Dropdowns/ServiceDropdown";

const styles = () => ({
  lightbg: {
    padding: "20px 26px",
  },
  label: {
    margin: "8px 0px 8px 0px",
    fontSize: "12px",
    marginTop: "0px",
    fontWeight: "700",
  },
  social: {
    height: "20px",
    width: "20px",
  },
  uploadClubBanner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dropinsidetext: {
    marginTop: "30px",
  },
});

class Step2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      title: "",
      banner_image: "",
      companyProfile: "",
      services: [],
      website: "",
      linkedin: "",
      facebook: "",
      twitter: "",
      instagram: "",
      servicelist: [],
      src: "",
      imgsrc: "",
      deletedKey: [],
    };
  }

  onChange = (event, parentType) => {
    const parentState = `${parentType}Data`;

    const { id } = event.target;
    const { name } = event.target;
    // eslint-disable-next-line react/no-access-state-in-setstate
    const data = this.state[parentState];
    data[id][name] = event.target.value;
    this.setState({
      [parentState]: data,
    });
  };

  handleNext = () => {
    this.props.handleNext(this.state);
  };

  onEditorStateChange = (html) => {
    this.setState({
      html,
    });
  };

  setSrc = (name, val) => {
    this.setState({ banner_image: val });
  };

  setSrcImg = (name, val) => {
    this.setState({ imgsrc: val });
  };

  setDelImageUrl = (url) => {
    const { deletedKey } = this.state;
    this.setState({ deletedKey: deletedKey.push(url) });
  };

  render() {
    const { classes, values } = this.props;
    const { errors } = this.state;
    return (
      <React.Fragment>
        <div className={classes.lightbg}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <InputField
                name="title"
                label="Title"
                placeholder="Mention your Company's industry in one line"
              />
            </Grid>
            <Grid item xs={12} className={classes.uploadClubBanner}>
              <Grid item xs={6} sm={6}>
                <UploadField
                  label="Upload Banner"
                  name="banner_image"
                  id="banner_image"
                  onChange={this.props.setFieldValue}
                  value={values.banner_image}
                  error={errors.banner_image}
                  folder="company/bannerimage"
                  delImageUrl={() => this.setDelImageUrl}
                />
              </Grid>
            </Grid>

            <Grid item xs={6} sm={6}>
              <ServicelistDropDown
                values={values}
                setFieldValue={this.props.setFieldValue}
                setFieldTouched={this.props.setFieldTouched}
                errors={errors}
                touched={this.props.touched}
                name="services"
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <InputField
                name="website"
                label="Website"
                placeholder="http://www.example.com"
                className={classes.dropinsidetext}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <InputField
                className={classes.margin}
                name="linkedin"
                label="Linkedin"
                placeholder="Enter Linkedin Profile Link"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={Linkedin} alt="" className={classes.social} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <InputField
                className={classes.margin}
                name="facebook"
                label="Facebook"
                placeholder="Enter Facebook Profile Link"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        src={FacebookRoundIcon}
                        alt=""
                        className={classes.social}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <InputField
                className={classes.margin}
                name="twitter"
                label="Twitter"
                placeholder="Enter Twitter Profile Link"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={Twitter} alt="" className={classes.social} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <InputField
                className={classes.margin}
                name="instagram"
                label="Instagram"
                placeholder="Enter Instagram Profile Link"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={Instagram} alt="" className={classes.social} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errors: state.errors,
    // auth: state.auth
  };
};

export default connect(mapStateToProps)(withStyles(styles)(withRouter(Step2)));
