import React, { Component } from "react";
import { Formik, Form } from "formik";
import {
  Dialog,
  DialogContent,
  Grid,
  DialogTitle,
  Button,
} from "@material-ui/core";
import * as Yup from "yup";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { createReferFriend } from "../../Actions/ReferFriendActions";
import { InputField, SelectField } from "../../Components/Common/Form";
import { interestedProductsOptions } from "../../Utils/dropDownData";

const $quoteSym = " ";

const styles = () => ({
  club_form_modal: {
    width: "500px",
  },
  button: {
    // width: "10%",
    color: "white",
    padding: "5px 16px",
    borderRadius: "4px",
    textDecoration: "none",
    fontSize: "0.875rem",
    fontWeight: "500",
    boxShadow: "rgba(137, 163, 169, 0.3) 0px 3px 5px 2px",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  applicationHeader: {
    marginTop: "-2px",
    top: "10px",
    padding: "16px 24px 10px 24px",
    "&:after": {
      width: "24px",
      borderBottom: "3px solid #fb0",
      content: `'${$quoteSym}'`,
      position: "absolute",
    },
  },
  clubActions: {
    padding: "40px 0px 8px",
    display: "flex",
    justifyContent: "flex-end",
  },
  clubbutton: {
    display: "contents",
  },
  cancelButton: {
    marginRight: "20px",
    backgroundColor: "#dddddd",
    color: "#151039",
    "&:hover": {
      color: "#151039",
      backgroundColor: "#d1d1d1!important",
    },
  },
});
const phoneRegExp = /[1-9]{1}[0-9]{9}/;

const ReferFriendSchema = Yup.object().shape({
  name: Yup.string()
    .max(30, "Name length must be less than 30 characters")
    .trim()
    .required("This field is required"),
  contact_number: Yup.string()
    .matches(phoneRegExp, "Please enter valid number.")
    .required("This field is required."),
  email: Yup.string()
    .email("Invalid email address")
    .required("This field is required."),
  company_name: Yup.string().max(50, "test").trim(),
  location: Yup.string().max(50, "test").trim(),
  product: Yup.string().required("This field is required."),
});

// eslint-disable-next-line react/prefer-stateless-function
class AddReferFriend extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, open, handleClose } = this.props;
    const getInitialValues = () => {
      return {
        name: "",
        contact_number: "",
        product: [],
        email: "",
        company_name: "",
        location: "",
      };
    };

    return (
      <div className={classes.clubbutton}>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="plan-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Formik
            initialValues={getInitialValues()}
            validationSchema={ReferFriendSchema}
            onSubmit={(values, { setErrors, setSubmitting }) => {
              const val2 = values.product ? values.product.value : "";

              // eslint-disable-next-line no-param-reassign
              values.product = val2;

              this.props.createReferFriend(
                values,
                setErrors,
                this.props.history,
                setSubmitting
              );
              //   }
              //   this.props.getAllClubs();
              this.props.handleClose();
            }}
          >
            {({ setFieldValue, values, setFieldTouched, errors, touched }) => (
              <Form className={classes.club_form_modal}>
                <DialogTitle
                  id="plan-dialog-title"
                  className={classes.applicationHeader}
                >
                  Enter Information
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <InputField
                        name="name"
                        label="Name"
                        placeholder="Enter Name"
                        maxlength="30"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <InputField
                        name="contact_number"
                        label="Mobile No."
                        placeholder="Enter Mobile No."
                        type="tel"
                        max="10"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <InputField
                        name="email"
                        label="Email ID"
                        placeholder="Enter Email ID"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <InputField
                        name="company_name"
                        label="Company Name"
                        placeholder="Enter Company Name"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <InputField
                        name="location"
                        label="Location"
                        placeholder="Enter Location"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SelectField
                        value={values.product}
                        name="product"
                        id="product"
                        label="Product"
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        multi={false}
                        error={errors.product}
                        touched={touched.product}
                        options={interestedProductsOptions}
                      />
                    </Grid>
                  </Grid>

                  <Grid className={classes.clubActions}>
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      className={classes.button}
                    >
                      Refer a Friend
                    </Button>
                  </Grid>
                </DialogContent>
                {/* {loading && <CircularLoader />} */}
              </Form>
            )}
          </Formik>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    referFriend: state.referFriend.referFriend,
  };
};
export default connect(mapStateToProps, { createReferFriend })(
  withStyles(styles)(AddReferFriend)
);
