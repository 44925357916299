/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import React, { useState } from 'react'
import { Typography, withStyles, Grid, Button } from '@material-ui/core'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import { Form } from 'react-formik-ui'
import FormControl from '@material-ui/core/FormControl'
import NoFeedback from '../../Assets/NoFeedback.png'
import CoworkersDropdown from '../../Components/Common/Dropdowns/coworkersDropdown'
import { capitalize } from '../../Utils/StringFunctions'
import StarStyle from './StarStyle'
import ClientAnswers from './ClientAnswers'
import { clientFeedbackInvitation } from '../../Actions/companiesAction'

const moment = require('moment')

const $quoteSym = ' '
const styles = () => {
  return {
    feedbackSection: {
      marginTop: '5%',
    },
    applicationHeader: {
      margin: '20px 3px',
      paddingBottom: '5px',
      fontSize: '16px',
      fontWeight: '500',
      position: 'relative',
      '&:after': {
        top: '31px',
        left: '1px',
        width: '24px',
        borderBottom: '3px solid #fb0',
        content: `'${$quoteSym}'`,
        position: 'absolute',
      },
    },
    mainGrid: {
      justifyContent: 'center',
      marginTop: '3%',
    },
    maintext: {
      color: 'lightgrey',
    },
    clientRequestSection: {
      marginTop: '10%',
    },
    eventsubtitle: {
      fontSize: '14px',
      fontWeight: 'normal',
      textAlign: 'center',
      color: '#626262',
      // padding: "15px"
    },
    submitbutton: {
      backgroundColor: '#211b4e !important',
      '&:hover': {
        backgroundColor: '#151039 !important',
      },
      margin: '35px 0px 20px 10px',
      // marginBottom: "35px",
      '&:disabled': {
        backgroundColor: 'lightgrey !important',
      },
    },
    feedBackScore: {
      margin: '1%',
      display: 'flex',
      flexDirection: 'column',
    },
    yes: {
      background: '#D5EED3',
    },
    no: {
      background: '#F6E0E0',
    },
    notsure: {
      background: '#FBF7C9',
    },
    feedbackBadge: {
      padding: '5px 15px',
      margin: '10px 0px',
      borderRadius: '5px',
      color: '#626262',
      fontSize: '16px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    positiveCount: {
      fontSize: '20px',
      textAlign: 'left',
      color: '#6fa56b',
      marginTop: '-2px',
    },
    negativeCount: {
      fontSize: '20px',
      textAlign: 'left',
      color: '#ef6565',
      marginTop: '-2px',
    },
    feedbackCount: {
      fontSize: '20px',
      textAlign: 'left',
      color: '#eea410',
      marginTop: '-2px',
    },
    questionHeading: {
      display: 'flex',
      position: 'relative',
      '&:after': {
        content: `'${$quoteSym}'`,
        position: 'absolute',
        // z-index: -1,
        top: '25px',
        bottom: '0',
        left: '1%',
        height: '270px',
        borderLeft: '2px dashed #ababab',
      },
    },
    heading: {
      fontWeight: '500',
      fontSize: '14px',
      textAlign: 'left',
      color: '#626262',
      paddingLeft: '10px',
    },
    queAnsSection: {
      margin: '1% 0% 1% 4%',
      padding: '15px',
      border: '1px solid #efefef',
      borderRadius: '5px',
    },
    ansStyle: {
      borderRadius: '5px',
      textAlign: 'center',
      margin: '10px 3px',
      padding: '5px',
      color: '#ababab',
      fontSize: '12px',
      background: '#EFEFEF',
    },
    layout: {
      border: '1px solid #EFEFEF',
      borderRadius: '5px',
      margin: '3% 1%',
      display: 'flex',
      flexDirection: 'column',
    },
    starSection: {
      padding: '4%',
      display: 'flex',
      margin: '0 auto',
      alignItems: 'flex-end',
      flexGrow: 1,
    },
    logo: {
      width: '100%',
      textAlign: 'center',
      flexGrow: 1,
    },
    introduction: {
      border: '1px solid #efefef',
      borderRadius: '5px',
      margin: '2% 0%',
    },
    title: {
      color: '#4682b4',
      fontSize: '16px',
      fontWeight: 'normal',
      padding: '1%',
      textAlign: 'center',
    },
    historyTime: {
      marginLeft: '11px',
      color: '#ababab',
      fontWeight: 'normal',
      paddingTop: '0px',
      fontSize: '12px',
      textTransform: 'capitalize',
    },
    description: {
      fontSize: '14px',
      lineHeight: '20px',
      color: '#626262',
      fontWeight: 'normal',
    },
    greyStar: {
      size: '22',
      fill: '#E5E5E5',
      color: 'white',
      strokeWidth: '0',
    },
    yellowStar: {
      size: '22',
      fill: '#FFBB00',
      color: 'white',
      strokeWidth: '0',
    },
  }
}

function ClientFeedback(props) {
  const {
    classes,
    clientFeedback,
    onboardingProcess,
    companyID,
    company,
  } = props
  const [processRatingArray, setProcessRatingArray] = useState([])

  const setSelectedStar = (val) => {
    processRatingArray.push(val)
    setProcessRatingArray(processRatingArray)
  }
  const feedbackDataArray =
    props.clientFeedback &&
    Object.keys(props.clientFeedback).map(function (key) {
      return clientFeedback[key]
    })
  const name = feedbackDataArray && feedbackDataArray.slice(0)[0]
  const first_name = name && name.slice(0)[0].created_by.first_name
  const last_name = name && name.slice(0)[0].created_by.last_name
  const createdAt = name && name.slice(0)[0].createdAt
  const countArray = feedbackDataArray && feedbackDataArray.slice(-1)[0]
  const feedbackData =
    props.clientFeedback &&
    Object.keys(props.clientFeedback).map(function (key) {
      return clientFeedback[key]
    })

  const defaultSelected = (id) =>
    props.clientFeedbackRating &&
    props.clientFeedbackRating.find((obj) => {
      return obj.process_id && obj.process_id._id === id
    })
  console.log('xyz :', company && company.user && company.user.length >= 1)
  return (
    <Grid>
      <div className={classes.feedbackSection}>
        <span className={classes.applicationHeader}>Client Feedback</span>
      </div>
      {feedbackData && feedbackData.length > 0 ? (
        <Grid>
          <Grid
            container
            direction="row"
            item
            lg={12}
            xs={12}
            spacing={2}
            className={classes.mainGrid}
          >
            <Grid
              item
              lg={2}
              xs={2}
              direction="column"
              className={classes.feedBackScore}
            >
              <div className={classNames(classes.feedbackBadge, classes.yes)}>
                Yes
                <span className={classes.positiveCount}>
                  {countArray && countArray.yes}
                </span>
              </div>
              <div className={classNames(classes.feedbackBadge, classes.no)}>
                No
                <span className={classes.negativeCount}>
                  {countArray && countArray.no}
                </span>
              </div>
              <div
                className={classNames(classes.feedbackBadge, classes.notsure)}
              >
                NA
                <span className={classes.feedbackCount}>
                  {countArray && countArray.notsure}
                </span>
              </div>
            </Grid>

            {onboardingProcess &&
              onboardingProcess.map((process) => {
                return (
                  // <Grid>
                  <Grid item lg={2} xs={2} className={classes.layout}>
                    <div className={classes.logo}>
                      <img
                        className={classes.photo}
                        src={process.icon}
                        alt=""
                      />
                      <Typography
                        variant="body1"
                        component="p"
                        className={classes.eventsubtitle}
                      >
                        {process.process_name}
                      </Typography>
                    </div>
                    <StarStyle
                      processId={process._id}
                      setSelectedStar={setSelectedStar}
                      defaultStar={defaultSelected(process._id)}
                    />
                  </Grid>
                )
              })}
            <Grid item lg={11} xs={11}>
              <div className={classes.introduction}>
                <Typography
                  className={classes.title}
                  variant="subtitle1"
                  component="p"
                >
                  By {capitalize(first_name)} {capitalize(last_name)}
                  {/* Dipa Rajput, Community Manager */}
                  <span className={classes.historyTime}>
                    - On {moment(createdAt).format('MMMM DD, hh:mm A')}
                  </span>
                </Typography>
              </div>
            </Grid>
            <ClientAnswers
              processData={onboardingProcess}
              answerData={clientFeedback}
            ></ClientAnswers>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          direction="row"
          item
          lg={12}
          xs={12}
          spacing={2}
          className={classes.mainGrid}
        >
          <Grid>
            <img
              src={NoFeedback}
              alt=""
              className={classes.noFeedbackImg}
            ></img>
            <Grid item justify="center">
              <Typography variant="h6" className={classes.maintext}>
                Feedback not initiated
              </Typography>
            </Grid>
            <div className={classes.clientRequestSection}>
              <Grid item justify="center">
                <Typography className={classes.eventsubtitle}>
                  {company && company.user && company.user.length < 1
                    ? 'Request Feedback to client'
                    : 'Requested For Feedback'}
                </Typography>
              </Grid>
            </div>
          </Grid>
          <Grid item lg={12} xs={12} justify="center" direction="row">
            <Formik
              initialValues={{
                coworker_id: '',
              }}
              enableReinitialize
              // validationSchema={ValidationSchema}

              onSubmit={(values, { setErrors, setSubmitting }) => {
                values.coworker_id = values.coworker_id.value
                props.clientFeedbackInvitation(
                  values,
                  props.history,
                  setErrors,
                  setSubmitting
                )
              }}
            >
              {({
                values,
                errors,
                touched,
                setFieldTouched,
                setFieldValue,
                handleSubmit,
              }) => (
                <Form
                  onSubmit={handleSubmit}
                  className={classes.form}
                  encType="multipart/form-data"
                  autoComplete="off"
                >
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <FormControl
                      required
                      style={{ width: '30%', margin: '10px' }}
                    >
                      <CoworkersDropdown
                        values={values}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        errors={errors.coworker_id}
                        touched={touched}
                        name="coworker_id"
                        companyID={companyID}
                        // requstedClient= {company && company.user && company.user}
                      />
                    </FormControl>
                    <Button
                      className={classes.submitbutton}
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="small"
                      disabled={
                        company && company.user && company.user.length >= 1
                      }
                    >
                      Send
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
            {company &&
              company.user &&
              company.user.map((u) => {
                return (
                  <>
                    {u.feedbackstatus === 'pending' && (
                      <div className={classes.introduction}>
                        <Typography
                          className={classes.title}
                          variant="subtitle1"
                          component="p"
                        >
                          To {capitalize(u.first_name)}{' '}
                          {capitalize(u.last_name)}
                        </Typography>
                      </div>
                    )}
                  </>
                )
              })}
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}
const mapStateToProps = (state) => {
  return {
    errors: state.errors,
  }
}
export default connect(mapStateToProps, { clientFeedbackInvitation })(
  withStyles(styles)(ClientFeedback)
)
