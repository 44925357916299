import {
  CREATE_REPORT_BUG,
  GET_REPORT_BUG,
  REPORT_BUG_LOADING,
  REPORT_BUG_ERRORS
} from "../Actions/types";

const initialState = {
  bugReports: [],
  CREATE_REPORT_BUG: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_REPORT_BUG:
      return {
        ...state,
        loading: false,
        bugReports: action.payload && action.payload.data,
      };
    case CREATE_REPORT_BUG:
      return {
        ...state,
        loading: false,
        createReportBug: { success: true, data: action.payload.data }
      };
    case REPORT_BUG_LOADING:
      return {
        ...state,
        loading: true
      };
    case REPORT_BUG_ERRORS:
      return {
        ...state,
        error: { success: false, data: action.payload && action.payload.data },
        loading: false
      }
    default:
      return state;
  }
}