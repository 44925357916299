import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import InputField from "../../Components/Common/Form/InputField";
import {
  changeStatus,
  getTicket,
  getTickets,
} from "../../Actions/helpdeskActions";
import UploadField from "../../Components/Common/Form/UploadField";

const $quoteSym = " ";
const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  form_modal: {
    minWidth: "400px",
  },
  modalHeader: {
    paddingBottom: "5px",
    fontSize: "20px",
    fontWeight: "600",
    position: "relative",
    "&:after": {
      top: "46px",
      left: "26px",
      width: "24px",
      borderBottom: "3px solid #fb0",
      content: `'${$quoteSym}'`,
      position: "absolute",
    },
  },
  input_field: {
    border: "1px solid #f5f5f5",
    height: "81%",
    position: "relative",
    width: "100%",
    padding: "6px 8px",
    margin: "0px",
    // borderBottom: "none",
    fontFamily:
      "'-apple-system,BlinkMacSystemFont','Segoe UI','Roboto','Helvetica Neue','Arial,sans-serif','Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol'",
    color: "grey",
    fontSize: "14px",
  },
  eventsubtitle1: {
    fontWeight: "normal",
    fontSize: "14px",
    textAlign: "left",
    color: "#626262",
  },
  addButton: {
    color: "#fff",
    marginRight: "20px",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  cancelButton: {
    marginRight: "2px",
    backgroundColor: "#dddddd",
    color: "#151039",
    "&:hover": {
      color: "#151039",
      backgroundColor: "#d1d1d1!important",
    },
  },
  dialogContent: {},
});

class NotificationPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.status !== prevState.status) {
      return { status: nextProps.status };
    }
    return [];
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSuccess = () => {
    this.props.close();
  };

  render() {
    const { classes } = this.props;

    return (
      <Dialog
        open={this.props.data}
        onClose={this.props.close}
        aria-labelledby="form-dialog-title"
      >
        <Formik onSubmit={() => {}}>
          {({ setFieldValue, values }) => (
            <Form className={classes.form_modal}>
              <DialogTitle
                id="issue-category-dialog-title"
                className={classes.modalHeader}
              >
                Add Notification Content
              </DialogTitle>
              <DialogContent className={classes.dialogContent}>
                <Grid item xs={12} sm={12}>
                  <UploadField
                    name="banner"
                    label="Upload banner "
                    id="banner"
                    onChange={setFieldValue}
                    value={values.banner}
                    folder="events/banner"
                  />
                </Grid>
                <Grid item xs={12} sm={12} style={{ marginTop: "5px" }}>
                  <InputField
                    name="linkname"
                    label="URL Name"
                    placeholder="Enter Link"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <InputField
                    name="linkreference "
                    label="URL Reference "
                    placeholder="Enter Link Reference "
                  />
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.handleSuccess}
                  color="primary"
                  className={classes.cancelButton}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  className={classes.addButton}
                  type="submit"
                  variant="contained"
                >
                  Submit
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    status: state.helpdesk.status,
    errors: state.errors,
  };
};
export default connect(mapStateToProps, {
  changeStatus,
  getTicket,
  getTickets,
})(withStyles(styles)(NotificationPopup));
