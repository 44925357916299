export const GET_ERRORS = 'GET_ERRORS'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const LOGIN_ERR0RS = 'LOGIN_ERR0RS'
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION'

// SIGNUP CONFIRMATION
export const GET_USER_SIGNUP_LIST = 'GET_USER_SIGNUP_LIST'
export const USER_SIGNUP_ERROR = 'USER_SIGNUP_ERROR'
export const USER_SIGNUP_LOADING = 'USER_SIGNUP_LOADING'
export const SIGNUP_CINFIRMATION = 'SIGNUP_CINFIRMATION'

// PROFILE
export const GET_PROFILE = 'GET_PROFILE'
export const PROFILE_LOADING = 'PROFILE_LOADING'
export const PROFILE_NOT_FOUND = 'PROFILE_NOT_FOUND'
export const CLEAR_CURRENT_PROFILE = 'CLEAR_CURRENT_PROFILE'
export const GET_PROFILES = 'GET_PROFILES'

// COMPANY
export const ADD_COMPNAY = 'ADD_COMPNAY'
export const GET_COMPANY = 'GET_COMPANY'
export const COMPANY_LOADING = 'COMPANY_LOADING'
export const COMPANY_NOT_FOUND = 'COMPANY_NOT_FOUND'
export const CLEAR_CURRENT_COMPANY = 'CLEAR_CURRENT_COMPANY'
export const GET_COMPANIES = 'GET_COMPANIES'
export const GET_COMPANIES_LIST = 'GET_COMPANIES_LIST'
export const GET_COMPANY_USER = 'GET_COMPANY_USER'
export const COMPANY_ERRORS = 'COMPANY_ERRORS'
export const CREATE_ASSOCIATEPLAN = 'CREATE_ASSOCIATEPLAN'
export const ASSOCIATEPLAN_ERRORS = 'ASSOCIATEPLAN_ERRORS'
export const UPDATE_COMPANY = 'UPDATE_COMPANY'
export const UPDATE_COMPANY_STATUS = 'UPDATE_COMPANY_STATUS'
export const CREATE_COMPANY_SUCCESS = 'CREATE_COMPANY_SUCCESS'
export const CREDIT_HISTORY = 'CREDIT_HISTORY'
export const CREDIT_HISTORY_ERRORS = 'CREDIT_HISTORY_ERRORS'
export const DELETE_PLAN = 'DELETE_CLUB'
export const DELETE_PLAN_ERRORS = 'DELETE_CLUB_ERRORS'
export const COMPANY_CREDIT_TOPUP_ERROR = 'COMPANY_CREDIT_TOPUP_ERROR'
export const GET_COMPANIES_CREDIT_TOPUP = 'GET_COMPANIES_CREDIT_TOPUP'
export const ADD_CREDIT_TOPUP = 'ADD_CREDIT_TOPUP'
export const GET_SPACE_COMPANIES_LIST = 'GET_SPACE_COMPANIES_LIST'
export const GET_ONBOARDING_PROCESS = 'GET_ONBOARDING_PROCESS'
export const CREATE_ONBOARDING_PROCESS_POST = 'CREATE_ONBOARDING_PROCESS_POST'
export const GET_ONBOARDING_PROCESS_POST = 'GET_ONBOARDING_PROCESS_POST'
export const GET_ONBOARDING_PROCESS_RATING = 'GET_ONBOARDING_PROCESS_RATING'
export const CREATE_ONBOARDING_PROCESS_RATING =
  'CREATE_ONBOARDING_PROCESS_RATING'
export const GET_CLIENT_FEEDBACK = 'GET_CLIENT_FEEDBACK'
export const CLIENT_FEEDBACK_INVITATION = 'CLIENT_FEEDBACK_INVITATION'
export const GET_CLIENT_FEEDBACK_RATING = 'GET_CLIENT_FEEDBACK_RATING'
export const GET_EXIT_PROCESS = 'GET_EXIT_PROCESS'
export const CREATE_EXIT_PROCESS_POST = 'CREATE_EXIT_PROCESS_POST'
export const GET_EXIT_PROCESS_POST = 'GET_EXIT_PROCESS_POST'

// EVENT
export const GET_EVENT = 'GET_EVENT'
export const EVENT_LOADING = 'EVENT_LOADING'
export const EVENT_NOT_FOUND = 'EVENT_NOT_FOUND'
export const CLEAR_CURRENT_EVENT = 'CLEAR_CURRENT_EVENT'
export const GET_EVENTS = 'GET_EVENTS'
export const EVENTS_LOADING = 'EVENTS_LOADING'
export const CREATE_EVENT = 'CREATE_EVENT'
export const EVENT_PARTICIPANT = 'EVENT_PARTICIPANT'
export const UPLOAD_EXCEL = 'UPLOAD_EXCEL'
export const EVENT_ERROR = 'EVENT_ERROR'
export const CHANGE_PARTICIPANT_STATUS = 'CHANGE_PARTICIPANT_STATUS'
export const GET_EVENT_DISCUSSION = 'GET_EVENT_DISCUSSION'
export const EVENTFEEDBACK_LOADING = 'EVENTFEEDBACK_LOADING'
export const EVENTFEEDBACK_PROCESS = 'EVENTFEEDBACK_PROCESS'
export const EVENTFEEDBACK_QUESTIONS = 'EVENTFEEDBACK_QUESTIONS'
export const CREATE_EVENTFEEDBACK_ANSWER = 'CREATE_EVENTFEEDBACK_ANSWER'
export const SEND_EVENTFEEDBACK_LINK = 'SEND_EVENTFEEDBACK_LINK'

// APPLICATION
export const GET_APPLICATION = 'GET_APPLICATION'
export const APPLICATION_LOADING = 'APPLICATION_LOADING'
export const APPLICATION_NOT_FOUND = 'APPLICATION_NOT_FOUND'
export const CLEAR_CURRENT_APPLICATION = 'CLEAR_CURRENT_APPLICATION'
export const GET_APPLICATIONS = 'GET_APPLICATIONS'
export const APPLICATIONS_LOADING = 'APPLICATIONS_LOADING'
export const ADD_APPLICATION = 'ADD_APPLICATION'

// PARTICIPANTS
export const GET_PARTICIPANT = 'GET_PARTICIPANT'
export const PARTICIPANT_LOADING = 'PARTICIPANT_LOADING'
export const PARTICIPANTS_LOADING = 'PARTICIPANTS_LOADING'
export const PARTICIPANT_NOT_FOUND = 'PARTICIPANT_NOT_FOUND'
export const CLEAR_CURRENT_PARTICIPANT = 'CLEAR_CURRENT_PARTICIPANT'
export const GET_PARTICIPANTS = 'GET_PARTICIPANTS'

// PARTNERS
export const GET_PARTNER = 'GET_PARTNER'
export const ADD_PARTNER = 'ADD_PARTNER'
export const PARTNER_LOADING = 'PARTNER_LOADING'
export const PARTNERS_LOADING = 'PARTNERS_LOADING'
export const PARTNER_NOT_FOUND = 'PARTNER_NOT_FOUND'
export const CLEAR_CURRENT_PARTNER = 'CLEAR_CURRENT_PARTNER'
export const GET_PARTNERS = 'GET_PARTNERS'
export const PARTNER_ERROR = 'PARTNER_ERROR'
export const UPDATE_PARTNER = 'UPDATE_PARTNER'

// ASSOCIATES
export const GET_ASSOCIATE = 'GET_ASSOCIATE'
export const ASSOCIATE_LOADING = 'ASSOCIATE_LOADING'
export const ASSOCIATES_LOADING = 'ASSOCIATES_LOADING'
export const ASSOCIATE_NOT_FOUND = 'ASSOCIATE_NOT_FOUND'
export const CLEAR_CURRENT_ASSOCIATE = 'CLEAR_CURRENT_ASSOCIATE'
export const GET_ASSOCIATES = 'GET_ASSOCIATES'
export const ADD_ASSOCIATE = 'ADD_ASSOCIATE'

// COWORKER
export const GET_COWORKERS = 'GET_COWORKERS'
export const GET_COWORKERS_COMPANY = 'GET_COWORKERS_COMPANY'
export const COWORKER_LOADING = 'COWORKER_LOADING'
export const COWORKER_NOT_FOUND = 'COWORKER_NOT_FOUND'
export const GET_COWORKERS_LIST = 'GET_COWORKERS_LIST'
export const GET_COWORKER_USER = 'GET_COWORKER_USER'
export const CREATE_COWORKER_SUCCESS = 'CREATE_COWORKER_SUCCESS'
export const UPDATE_COWORKER_STATUS = 'UPDATE_COWORKER_STATUS'
export const UPDATE_COWORKER = 'UPDATE_COWORKER'
export const GET_SINGLE_COWORKER = 'GET_SINGLE_COWORKER'
export const COWORKER_ERROR = 'COWORKER_ERROR'
export const GET_ALL_COWORKERS = 'GET_ALL_COWORKERS'

// Bookings
export const GET_BOOKINGS = 'GET_BOOKINGS'
export const BOOKING_LOADING = 'BOOKING_LOADING'
export const BOOKING_ERRORS = 'BOOKING_ERRORS'
export const GET_BOOKING = 'GET_BOOKING'
export const SAVE_BOOKING = 'SAVE_BOOKING'
export const DELETE_BOOKING = 'DELETE_BOOKING'
export const DELETE_BOOKING_ERRORS = 'DELETE_BOOKING_ERRORS'
export const COMPANY_CREDIT = 'COMPANY_CREDIT'
export const GET_SELF_BOOKINGS = 'GET_SELF_BOOKINGS'

// PLANS
export const GET_AVAIL_PLANS = 'GET_AVAIL_PLANS'
export const PLANS_LOADING = 'PLANS_LOADING'
export const PLAN_ERROR = 'PLAN_ERROR'
export const GET_PLANS = 'GET_PLANS'

// COMMUNITY-AMBASSADOR
export const LIST_LOADING = 'LIST_LOADING'
export const GET_LIST_AMBASSDOR = 'GET_LIST_AMBASSDOR'
export const GET_ONE_LIST_AMBASSDOR = 'GET_ONE_LIST_AMBASSDOR'
export const LIST_ERROR = 'LIST_ERROR'
export const ADD_CONFIRMATION = 'ADD_CONFIRMATION'
export const UPDATE_STATUS = 'UPDATE_STATUS'
export const APPLY_NOW = 'APPLY_NOW'

//SUGGEST-FEATURE
export const ADD_SUGGEST = 'ADD_SUGGEST'
export const LIST_SUGGEST_ERROR = 'LIST_SUGGEST_ERROR'
export const UPDATE_SUGGEST_STATUS = 'UPDATE_SUGGEST_STATUS'
export const LIKE_SUGGEST = 'LIKE_SUGGEST'
export const LIKE_SUGGEST_ERROR = 'LIKE_SUGGEST_ERROR'
export const GET_ALL_SUGGESTION = 'GET_ALL_SUGGESTION'
export const LIST_SUGGEST_LOADING = 'LIST_SUGGEST_LOADING'
// SPACES
export const GET_SPACES = 'GET_SPACES'
export const SPACE_LOADING = 'SPACE_LOADING'
export const ADD_SPACE = 'ADD_SPACE'
export const GET_SPACE = 'GET_SPACE'
export const UPDATE_SPACE = 'UPDATE_SPACE'
export const SPACE_ERROR = 'SPACE_ERROR'
export const CREATE_AREA = 'CREATE_AREA'
export const AREA_ERROR = 'AREA_ERROR'
export const GET_AREA = 'GET_AREA'
export const GET_SLOT = 'GET_SLOT'
export const UPDATE_AREA = 'UPDATE_AREA'
export const GET_SINGLE_AREA = 'GET_SINGLE_AREA'
export const CREATE_TASK = 'CREATE_TASK'

// PLAN CATEGORY
export const GET_PLAN_CATEGORY = 'GET_PLAN_CATEGORY'
export const PLAN_CATEGORY_LOADING = 'PLAN_CATEGORY_LOADING'
export const ADD_PLAN_CATEGORY = 'ADD_PLAN_CATEGORY'
export const ADD_PLAN_CATEGORY_ERROR = 'ADD_PLAN_CATEGORY_ERROR'
export const GET_SINGLE_PLAN_CATEGORY = 'GET_SINGLE_PLAN_CATEGORY'
export const EDIT_PLAN_CATEGORY = 'EDIT_PLAN_CATEGORY'

// RESOURCE CATEGORY
export const GET_RESOURCE_CATEGORY = 'GET_RESOURCE_CATEGORY'
export const RESOURCE_CATEGORY_LOADING = 'RESOURCE_CATEGORY_LOADING'
export const ADD_RESOURCE_CATEGORY = 'ADD_RESOURCE_CATEGORY'
export const ADD_RESOURCE_CATEGORY_ERROR = 'ADD_RESOURCE_CATEGORY_ERROR'
export const GET_SINGLE_RESOURCE_CATEGORY = 'GET_SINGLE_RESOURCE_CATEGORY'
export const EDIT_RESOURCE_CATEGORY = 'EDIT_RESOURCE_CATEGORY'
export const EDIT_RESOURCE_CATEGORY_ERRORS = 'EDIT_RESOURCE_CATEGORY_ERRORS'

// PLAN
export const GET_PLAN = 'GET_PLAN'
export const PLAN_LOADING = 'PLAN_LOADING'
export const ADD_PLAN = 'ADD_PLAN'
export const PLAN_ERRORS = 'PLAN_ERRORS'
export const GET_SINGLE_PLAN = 'GET_SINGLE_PLAN'
export const EDIT_PLAN = 'EDIT_PLAN'
// RESOURCE
export const GET_AVAIL_RESOURCES = 'GET_RESOURCES'
export const RESOURCE_LOADING = 'RESOURCE_LOADING'
export const ADD_RESOURCE = 'ADD_PLAN'
export const RESOURCE_ERRORS = 'RESOURCE_ERRORS'
export const GET_UNBOOKED_RESOURCES = 'GET_UNBOOKED_RESOURCES'
export const GET_RESOURCES_ERRORS = 'GET_RESOURCES_ERRORS'
export const GET_SINGLE_RESOURCE = 'GET_SINGLE_RESOURCE'
export const EDIT_RESOURCE = 'EDIT_RESOURCE'
export const EDIT_RESOURCE_ERROR = 'EDIT_RESOURCE_ERROR'

// VISITOR

export const GET_VISITORS = 'GET_VISITORS'
export const VISITOR_LOADING = 'VISITOR_LOADING'
export const ADD_VISITOR = 'ADD_VISITOR'
export const VISITOR_ERRORS = 'VISITOR_ERRORS'
export const UPDATE_VISITOR = 'UPDATE_VISITOR'
export const GET_SINGLE_WALKTHROUGH_VISITORS = 'GET_SINGLE_WALKTHROUGH_VISITORS'
export const ADD_WALKTHROUGH_VISITOR_DETAIL = 'ADD_WALKTHROUGH_VISITOR_DETAIL'
export const CHANGE_VISITOR_TYPE = 'CHANGE_VISITOR_TYPE'

// ROLETYPE
export const GET_COWORKER_ROLETYPE = 'GET_COWORKER_ROLETYPE'
export const COWORKER_ROLETYPE_ERRORS = 'COWORKER_ROLETYPE_ERRORS'
export const ROLETYPE_LOADING = 'ROLETYPE_LOADING'

// SERVICES
export const GET_SERVICES = 'GET_SERVICES'
export const SERVICES_ERRORS = 'SERVICES_ERRORS'
export const SERVICES_LOADING = 'SERVICES_LOADING'

// FEEDBACK
export const ADD_FEEDBACK = 'ADD_FEEDBACK'
export const FEEDBACK_ERRORS = 'FEEDBACK_ERRORS'
export const FEEDBACK_LOADING = 'FEEDBACK_LOADING'

// PARTNER OFFERS
export const PARTNER_OFFERS_ERROR = 'CREATE_PARTNEROFFERS'
export const PARTNEROFFERS_ERRORS = 'PARTNEROFFERS_ERRORS'
export const PARTNEROFFERS_LOADING = 'PARTNEROFFERS_LOADING'
export const UPDATE_PARTNER_OFFER = 'UPDATE_PARTNER_OFFER'
export const GET_PARTNER_OFFER = 'GET_PARTNER_OFFER'

// COWORKER SKILLS
export const GET_COWORKER_SKILLS = 'GET_COWORKER_SKILLS'
export const COWORKER_SKILLS_ERRORS = 'COWORKER_SKILLS_ERRORS'
export const COWORKER_SKILLS_LOADING = 'COWORKER_SKILLS_LOADING'

// PARTNER SERVICES
export const GET_PARTNER_SERVICES = 'GET_PARTNER_SERVICES'
export const PARTNER_SERVICES_ERRORS = 'PARTNER_SERVICES_ERRORS'
export const PARTNER_SERVICES_LOADING = 'COWORKER_SKILLS_LOADING'

// FOOD CATEGORY
export const GET_FOOD_CATEGORY = 'GET_FOOD_CATEGORY'
export const FOOD_CATEGORY_LOADING = 'FOOD_CATEGORY_LOADING'
export const ADD_FOOD_CATEGORY = 'ADD_FOOD_CATEGORY'
export const FOOD_CATEGORY_ERROR = 'FOOD_CATEGORY_ERROR'
export const GET_SINGLE_FOOD_CATEGORY = 'GET_SINGLE_FOOD_CATEGORY'
export const EDIT_FOOD_CATEGORY = 'EDIT_FOOD_CATEGORY'

// FOOD ITEM
export const FOOD_ITEM_LOADING = 'FOOD_ITEM_LOADING'
export const ADD_FOOD_ITEM = 'ADD_FOOD_ITEM'
export const FOOD_ITEM_ERRORS = 'FOOD_ITEM_ERRORS'
export const GET_ALL_FOOD_ITEM = 'GET_ALL_FOOD_ITEM'
export const EDIT_FOOD_ITEM = 'EDIT_FOOD_ITEM'
export const EDIT_FOOD_ITEM_ERRORS = 'EDIT_FOOD_ITEM_ERRORS'

// FOOD ORDER
export const CREATE_FOOD_ORDER = 'CREATE_FOOD_ORDER'
export const FOOD_ORDER_ERRORS = 'FOOD_ORDER_ERRORS'
export const FOOD_ORDER_LOADING = 'FOOD_ORDER_LOADING'
export const GET_ALL_FOOD_ORDER = 'GET_ALL_FOOD_ORDER'
export const ADD_FOOD_ORDER = 'ADD_FOOD_ORDER'

// Clubs
export const CREATE_CLUB = 'CREATE_CLUB'
export const GET_ALL_CLUBS = 'GET_ALL_CLUBS'
export const ALL_CLUBS_LOADING = 'ALL_CLUBS_LOADING'
export const ALL_CLUBS_ERRORS = 'ALL_CLUBS_ERRORS'
export const CLUB_LIST = 'CLUB_LIST'
export const GET_SINGLE_CLUB = 'GET_SINGLE_CLUB'
export const DELETE_CLUB = 'DELETE_CLUB'
export const DELETE_CLUB_ERRORS = 'DELETE_CLUB_ERRORS'
export const EDIT_CLUB = 'EDIT_CLUB'
export const EDIT_CLUB_ERRORS = 'EDIT_CLUB_ERRORS'

// POST
export const ALL_POST = 'ALL_POST'
export const ADD_POST = 'ADD_POST'
export const POST_ERROR = 'POST_ERROR'
export const POST_LOADING = 'POST_LOADING'
export const EDIT_POST = 'EDIT_POST'
export const EDIT_POST_ERROR = 'EDIT_POST_ERROR'
export const DELETE_POST = 'DELETE_POST'
export const DELETE_POST_ERROR = 'DELETE_POST_ERROR'

// CLUB CATEGORY
export const CREATE_CLUB_CATEGORY = 'CREATE_CLUB_CATEGORY'
export const GET_CLUB_CATEGORY = 'GET_CLUB_CATEGORY'
export const CLUB_CATEGORY_LOADING = 'CLUB_CATEGORY_LOADING'
export const ERROR_CLUB_CATEGORY = 'ERROR_CLUB_CATEGORY'
export const JOIN_CLUB = 'JOIN_CLUB'
export const ERROR_GET_CLUB_CATEGORY = 'ERROR_GET_CLUB_CATEGORY'

// LIKE POST
export const LIKE_POST = 'LIKE_POST'
export const LIKE_POST_ERROR = 'LIKE_POST_ERROR'

// COMMENT POST
export const CREATE_COMMENT = 'CREATE_COMMENT'
export const COMMENT_ERROR = 'COMMENT_ERROR'

// ISSUE DEPARTMENT
export const ISSUE_DEPARTMENT_ERROR = 'ISSUE_DEPARTMENT_ERROR'
export const ISSUE_DEPARTMENT_LOADING = 'ISSUE_DEPARTMENT_LOADING'
export const ADD_ISSUE_DEPARTMENT = 'ADD_ISSUE_DEPARTMENT'
export const GET_ALL_ISSUE_DEPARTMENT = 'GET_ALl_ISSUE_DEPARTMENT'
export const GET_SINGLE_ISSUE_DEPARTMENT = 'GET_SINGLE_ISSUE_DEPARTMENT'
export const EDIT_ISSUE_DEPARTMENT = 'EDIT_ISSUE_DEPARTMENT'
export const EDIT_ISSUE_DEPARTMENT_ERRORS = 'EDIT_ISSUE_DEPARTMENT_ERRORS'

// ISSUE CATEGORY
export const ISSUE_CATEGORY_ERROR = 'ISSUE_CATEGORY_ERROR'
export const ISSUE_CATEGORY_LOADING = 'ISSUE_CATEGORY_LOADING'
export const ADD_ISSUE_CATEGORY = 'ADD_ISSUE_CATEGORY'
export const GET_ALL_ISSUE_CATEGORY = 'GET_ALl_ISSUE_CATEGORY'
export const GET_SINGLE_ISSUE_CATEGORY = 'GET_SINGLE_ISSUE_CATEGORY'
export const EDIT_ISSUE_CATEGORY = 'EDIT_ISSUE_CATEGORY'
export const EDIT_ISSUE_CATEGORY_ERRORS = 'EDIT_ISSUE_CATEGORY_ERRORS'

// ISSUE TITLE
export const ISSUE_TITLE_ERROR = 'ISSUE_TITLE_ERROR'
export const ISSUE_TITLE_LOADING = 'ISSUE_TITLE_LOADING'
export const ADD_ISSUE_TITLE = 'ADD_ISSUE_TITLE'
export const GET_ALL_ISSUE_TITLE = 'GET_ALL_ISSUE_TITLE'
export const GET_SINGLE_ISSUE_TITLE = 'GET_SINGLE_ISSUE_TITLE'
export const EDIT_ISSUE_TITLE = 'EDIT_ISSUE_TITLE'
export const EDIT_ISSUE_TITLE_ERRORS = 'EDIT_ISSUE_TITLE_ERRORS'

// HELPDESK TICKETS
export const GET_TICKETS = 'GET_TICKETS'
export const ADD_TICKET = 'ADD_TICKET'
export const GET_TICKET = 'GET_TICKET'
export const TICKETS_LOADING = 'TICKETS_LOADING'
export const TICKET_ERROR = 'TICKET_ERROR'
export const CREATE_HISTORY = 'CREATE_HISTORY'
export const CHANGE_STATUS = 'CHANGE_STATUS'
export const GET_ASSIGNTO_USER = 'GET_ASSIGNTO_USER'

// ADD ORDER
export const ADD_ORDER_LOADING = 'ADD_ORDER_LOADING'
export const ADD_ORDER_ERRORS = 'ADD_ORDER_ERRORS'
export const ADD_ORDER = 'ADD_ORDER_'
export const GET_ALL_ORDER = 'GET_ALL_ORDER'
export const ORDER_DELIVERED_PENDING = 'ORDER_DELIVERED_PENDING'
export const ORDER_PROCESS_STATUS = 'ORDER_PROCESS_STATUS'
export const ORDER_STATUS = 'ORDER_STATUS'
export const MAIN_ORDER_STATUS_ERRORS = 'MAIN_ORDER_STATUS_ERRORS'
export const MAIN_ORDER_STATUS_SUCCESS = 'MAIN_ORDER_STATUS_SUCCESS'
export const MY_ORDERS = 'MY_ORDERS'
export const MY_ORDERS_ACCOUNT_DETAILS = 'MY_ORDERS_ACCOUNT_DETAILS'
export const GET_ORDER_ERRORS = 'GET_ORDER_ERRORS'

// SECURITY VISITOR
export const CHECK_VISITOR = 'CHECK_VISITOR'
export const CREATE_VISITOR_BY_WALKIN = 'CREATE_VISITOR_BY_WALKIN'
export const CREATE_VISITOR_BY_WALKTHROUGH = 'CREATE_VISITOR_BY_WALKTHROUGH'

// FORGET-PASSWORD
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS'
export const FORGET_PASSWORD_ERROR = 'FORGET_PASSWORD_ERROR'

// RESET-PASSWORD
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR'
export const RESET_PASSWORD_LOADING = 'RESET_PASSWORD_LOADING'

// RESET-PASSWORD-CHECK-TOKEN
export const CHECK_TOKEN_SUCCESS = 'CHECK_TOKEN'
export const CHECK_TOKEN_ERROR = 'CHECK_TOKEN_ERROR'
export const CHECK_TOKEN_LOADING = 'CHECK_TOKEN_LOADING'

// USER_DATA
export const USER_DATA = 'USER_DATA'
export const USER_DATA_ERROR = 'USER_DATA_ERROR'
export const USER_DATA_LOADING = 'USER_DATA_LOADING'
export const UPDATE_USER_STATUS = 'UPDATE_USER_STATUS'

// EDIT_PROFILE
export const EDIT_PROFILE = 'EDIT_PROFILE'
export const EDIT_PROFILE_ERROR = 'EDIT_PROFILE_ERROR'
export const EDIT_PROFILE_LOADING = 'EDIT_PROFILE_LOADING'

// CHANGE_PASSWORD
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR'
export const CHANGE_PASSWORD_LOADING = 'CHANGE_PASSWORD_LOADING'

// ROLE
export const ADD_ROLE = 'ADD_ROLE'
export const GET_ROLES = 'GET_ROLES'
export const GET_ROLES_POLICIES = 'GET_ROLES_POLICIES'
export const ROLE_ERRORS = 'ROLE_ERRORS'
export const ROLE_LOADING = 'ROLE_LOADING'
export const GET_ALL_ROLES = 'GET_ALL_ROLES'
export const GET_ROLE = 'GET_ROLE'
export const UPDATE_ROLE = 'UPDATE_ROLE'

// USER
export const GET_USERS = 'GET_USERS'
export const USER_LOADING = 'USER_LOADING'
export const USER_ERROR = 'USER_ERROR'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const UPDATE_USER = 'UPDATE_USER'
export const GET_SINGLE_USER = 'GET_SINGLE_USER'

// NOTIFICATION
export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION'
export const NOTIFICATION_LOADING = 'NOTIFICATION_LOADING'
export const NOTIFICATION_ERROR = 'NOTIFICATION_ERROR'
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS'
export const GET_NOTIFICATIONS_ERROR = 'GET_NOTIFICATIONS_ERROR'
export const GET_SINGLE_NOTIFICATION = 'GET_SINGLE_NOTIFICATION'

//ANNOUNCEMENT
export const ANNOUNCEMENT_LOADING = 'ANNOUNCEMENT_LOADING'
export const ALL_ANNOUNCEMENT = 'ALL_ANNOUNCEMENT'
export const ADD_ANNOUNCEMENT = 'ADD_ANNOUNCEMENT'
export const LIKE_ANNOUNCEMENT = 'LIKE_ANNOUNCEMENT'
export const LIKE_ANNOUNCEMENT_ERROR = 'LIKE_ANNOUNCEMENT_ERROR'
export const CREATE_ANNOUNCEMENT_COMMENT = 'CREATE_ANNOUNCEMENT_COMMENT'
export const ANNOUNCEMENT_COMMENT_ERROR = 'ANNOUNCEMENT_COMMENT_ERROR'
export const ANNOUNCEMENT_ERROR = 'ANNOUNCEMENT_ERROR'
export const EDIT_ANNOUNCEMENT = 'EDIT_ANNOUNCEMENT'
export const EDIT_ANNOUNCEMENT_ERROR = 'EDIT_ANNOUNCEMENT_ERROR'
export const DELETE_ANNOUNCEMENT = 'DELETE_ANNOUNCEMENT'
export const SINGLE_ANNOUNCEMENT = 'SINGLE_ANNOUNCEMENT'

// EVENT TEAM MEMBER
export const GET_ALL_TEAM = 'GET_ALL_TEAM'
export const TEAM_ERROR = 'TEAM_ERROR'
export const GET_TEAM_ICON = 'GET_TEAM_ICON'
export const GET_ONE_TEAM = 'GET_ONE_TEAM'
export const CREATE_TEAM = 'CREATE_TEAM'
export const GET_TEAM_ERROR = 'GET_TEAM_ERROR'

// INVENTORY CATEGORY
export const GET_INVENTORY_CATEGORY = 'GET_INVENTORY_CATEGORY'
export const INVENTORY_CATEGORY_LOADING = 'INVENTORY_CATEGORY_LOADING'
export const ADD_INVENTORY_CATEGORY = 'ADD_INVENTORY_CATEGORY'
export const ADD_INVENTORY_CATEGORY_ERROR = 'ADD_INVENTORY_CATEGORY_ERROR'
export const GET_SINGLE_INVENTORY_CATEGORY = 'GET_SINGLE_INVENTORY_CATEGORY'
export const EDIT_INVENTORY_CATEGORY = 'EDIT_INVENTORY_CATEGORY'
export const INVENTORY_ERRORS = 'INVENTORY_ERRORS'

// INVENTORY
export const GET_INVENTORY = 'GET_INVENTORY'
export const INVENTORY_LOADING = 'INVENTORY_LOADING'
export const ADD_INVENTORY = 'ADD_INVENTORY'
export const ADD_INVENTORY_ERROR = 'ADD_INVENTORY_ERROR'
export const GET_SINGLE_INVENTORY = 'GET_SINGLE_INVENTORY'
export const EDIT_INVENTORY = 'EDIT_INVENTORY'

// KNOWLEDGE CENTER INFORMATION
export const ADD_KNOWLEDGE_CENTER_INFORMATION =
  'ADD_KNOWLEDGE_CENTER_INFORMATION'
export const KNOWLEDGE_CENTER_ERRORS = 'KNOWLEDGE_CENTER_ERRORS'
export const UPDATE_KNOWLEDGE_CENTER_INFORMATION =
  'UPDATE_KNOWLEDGE_CENTER_INFORMATION'
export const KNOWLEDGE_CENTER_LOADING = 'KNOWLEDGE_CENTER_LOADING'
export const GET_SINGLE_KNOWLEDGE_CENTER_INFO =
  'GET_SINGLE_KNOWLEDGE_CENTER_INFO'
export const GET_TEAM_DATA = 'GET_TEAM_DATA'

// ALLIED SERVICES
export const CREATE_ALLIED_SERVICES = 'CREATE_ALLIED_SERVICES'
export const ALLIED_SERVICES_ERRORS = 'ALLIED_SERVICES_ERRORS'
export const ALLIED_SERVICES_LOADING = 'ALLIED_SERVICES_LOADING'
export const GET_ALL_ALLIED_SERVICES = 'GET_ALL_ALLIED_SERVICES'

//REPORT A BUG
export const CREATE_REPORT_BUG = 'CREATE_REPORT_BUG'
export const GET_REPORT_BUG = 'GET_REPORT_BUG'
export const REPORT_BUG_LOADING = 'REPORT_BUG_LOADING'
export const REPORT_BUG_ERRORS = 'REPORT_BUG_ERRORS'
export const UPDATE_REPORT_STATUS = 'UPDATE_REPORT_STATUS'
export const LIST_REPORT_LOADING = 'LIST_REPORT_LOADING'

// CREDIT ACCOUNTS
export const CREATE_CREDIT_ACCOUNT = 'CREATE_CREDIT_ACCOUNT'
export const CREDIT_ACCOUNT_ERRORS = 'CREDIT_ACCOUNT_ERRORS'
export const CREDIT_ACCOUNT_LOADING = 'CREDIT_ACCOUNT_LOADING'
export const GET_ALL_CREDIT_ACCOUNT = 'GET_ALL_CREDIT_ACCOUNT'
export const GET_CREDIT_ACCOUNT = 'GET_CREDIT_ACCOUNT'
export const PAYNOW = 'PAYNOW'

// MERCHANDISE MODULE
export const MERCHANDISE_ERROR = 'MERCHANDISE_ERROR'
export const GET_MERCHANDISE_ALL_ORDER = 'GET_MERCHANDISE_ALL_ORDER'
export const ADD_MERCHANDISE_PRODUCT = 'ADD_MERCHANDISE_PRODUCT'
export const MERCHANDISE_LOADING = 'MERCHANDISE_LOADING'
export const GET_MERCHANDISE_PRODUCT = 'GET_MERCHANDISE_PRODUCT'
export const ADD_MERCHANDISE_POINT = 'ADD_MERCHANDISE_POINT'
export const GET_MERCHANDISE_POINT = 'GET_MERCHANDISE_POINT'
export const UPDATE_MERCHANDISE_ORDER_STATUS = 'UPDATE_MERCHANDISE_ORDER_STATUS'

// REFER A FRIEND
export const CREATE_REFER_FRIEND = 'CREATE_REFER_FRIEND'
export const REFER_FRIEND_LOADING = 'REFER_FRIEND_LOADING'
export const REFER_FRIEND_ERROR = 'REFER_FRIEND_ERROR'
export const GET_REFER_FRIENDS = 'GET_REFER_FRIENDS'
export const GET_SINGLE_REFER_FRIEND = 'GET_SINGLE_REFER_FRIEND'
export const CREATE_REFER_FRIEND_COMMENT = 'CREATE_REFER_FRIEND_COMMENT'
export const PLACE_ORDER = 'PLACE_ORDER'
export const GET_USER_MERCHANDISE_POINT = 'GET_USER_MERCHANDISE_POINT'

// DOCUMENT
export const DOCUMENT_LIST_LOADING = 'DOCUMENT_LIST_LOADING'
export const ADD_DOCUMENT = 'ADD_DOCUMENT'
export const GET_LIST_DOCUMENT = 'GET_LIST_DOCUMENT'
export const DOCUMENT_UPDATE_STATUS = 'DOCUMENT_UPDATE_STATUS'
export const GET_ONE_LIST_DOCUMENT = 'GET_ONE_LIST_DOCUMENT'
export const LIST_DOCUMENT_ERROR = 'LIST_DOCUMENT_ERROR'

// NOTIFICATION
export const NOTIFICATION = 'NOTIFICATION'
export const NOTIFICATION_GET_ERROR = 'NOTIFICATION_GET_ERROR'

// REPORT ANALYTICS
export const REPORT_ANALYSIS_LOADING = 'REPORT_ANALYSIS_LOADING'
export const BOOKING_ANALYSIS_REPORT = 'BOOKING_ANALYSIS_REPORT'
export const COMPANY_RESOURCE_BOOKING_LOG = 'COMPANY_RESOURCE_BOOKING_LOG'
export const COMPANY_RESOURCE_BOOKING_ANALYSIS =
  'COMPANY_RESOURCE_BOOKING_ANALYSIS'
export const REPORT_ANALYSIS_ERROR = 'REPORT_ANALYSIS_ERROR'
export const GET_SPACE_RESOURCES = 'GET_SPACE_RESOURCES'
export const VISITOR_LOGS = 'VISITOR_LOGS'
export const VISITOR_ANALYSIS = 'VISITOR_ANALYSIS'
export const CLIENTS_VISITOR_ANALYSIS = 'CLIENTS_VISITOR_ANALYSIS'
export const COMPANY_PLAN_ANAYSIS = 'COMPANY_PLAN_ANAYSIS'
export const WALKTHROUGH_LOG = 'WALKTHROUGH_LOG'
export const WALKTHROUGH_ANALYSIS = 'WALKTHROUGH_ANALYSIS'
export const MONTHLY_CAFE_CONSUMPTION_ANALYSIS =
  'MONTHLY_CAFE_CONSUMPTION_ANALYSIS'
export const GET_SPACE_COMPANY = 'GET_SPACE_COMPANY'
export const COWORKER_LOG = 'COWORKER_LOG'
export const ISSUE_TICKET_ANALYSIS = 'ISSUE_TICKET_ANALYSIS'
