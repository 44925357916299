import React from "react";
import PropTypes from "prop-types";
import { FormikTextField } from "formik-material-fields";
import withStyles from "@material-ui/core/styles/withStyles";
import { createMuiTheme } from "@material-ui/core/styles";
// eslint-disable-next-line import/no-extraneous-dependencies
import { ThemeProvider } from "@material-ui/styles";

const theme = createMuiTheme({
  overrides: {
    MuiInputLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        "&$error": {
          // increase the specificity for the pseudo class
          color: "rgba(0,0,0,0.54)",
        },
      },
    },
    MuiInputBase: {
      input: {
        paddingLeft: "10px !important",
        fontSize: "14px",
        color:"lite"
      },
    },
  },
});

const styles = {
  label: {
    fontSize: "15px",
    fontWeight: "400",
    color: "#252631",
    marginBottom: "1rem",
  },
};
const InputField = ({ name, label, disabled, max, min, type, ...rest }) => {
  if (max && type === "number") {
    // debugger;
  }
  return (
    <ThemeProvider theme={theme}>
      <FormikTextField
        name={name}
        label={label}
        margin="normal"
        fullWidth
        disabled={disabled}
        InputLabelProps={{ shrink: true }}
        // inputProps={
        //   (type !== "number" ? { maxLength: max || 100 } : { max },
        //   type === "number" ? { min, max } : "")
        // }
        inputProps={
          type !== "number" ? { maxLength: max || 100 } : { max, min }
        }
        type={type}
        {...rest}
      />
    </ThemeProvider>
  );
};

InputField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default withStyles(styles)(InputField);
