/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from "react";
import axios from "axios";
import { Fab, Grid, withStyles } from "@material-ui/core";
import { ClearRounded, InsertDriveFile } from "@material-ui/icons";
import { ErrorMessage } from "formik";
import classNames from "classnames";
import { UploadLogoSvg } from "../../../Assets/svgs/index";
import UploadImage from "../../../Assets/UploadImage.png";

class Thumb extends React.Component {
  state = {
    loading: false,
    // thumb: undefined
  };

  componentWillReceiveProps() {
    // if (!nextProps.file) {
    //   return;
    // }
    // this.setState({ loading: true }, () => {
    //   let reader = new FileReader();
    //   reader.onloadend = () => {
    //     this.setState({ loading: false, thumb: reader.result });
    //   };
    //   reader.readAsDataURL(nextProps.file);
    // });
  }

  render() {
    const { file, classes, fileType } = this.props;
    const { loading } = this.state;
    if (!file) {
      return null;
    }

    if (loading) {
      return <p>loading...</p>;
    }

    return (
      <React.Fragment>
        {fileType === "doc" ? (
          <span>
            <InsertDriveFile className="img-thumbnail mt-2" />
            {file.name}
          </span>
        ) : (
          <img
            src={file}
            alt={file.name}
            className="img-thumbnail mt-2"
            height={50}
            width="auto"
          />
        )}
        <Fab className={classes.fab_icon} color="primary" aria-label="Add">
          <ClearRounded
            className={classes.delete_icon}
            onClick={this.props.handleClick}
          />
        </Fab>
      </React.Fragment>
    );
  }
}
const styles = (theme) => ({
  form_control: {
    display: "block",
    marginTop: "10px",
    marginBottom: "10px",
    border: "none",
  },
  fab_icon: {
    width: "29px",
    height: "29px",
    position: "relative",
    top: "-44px",
    left: "-16px",
    minHeight: "17px",
  },
  delete_icon: {
    width: "19px",
    height: "19px",
  },
  error: {
    color: "#f53b57",
    fontSize: "0.75rem",
    fontWeight: "400",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ],
    marginTop: "-5px",
    textAlaign: "left",
    lineHeight: "1em",
  },
  uploadLogo: { padding: theme.spacing(2) },
  spacingLogo: {
    padding: theme.spacing(2),
    borderRadius: "50%",
    background: "whiteSmoke",
    width: "47px",
    height: "48px",
  },
  uploadImage: {
    background: "none",
  },
  editProfile: {
    borderRadius: "50%",
  },
});
class UploadField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // key: "",
      img_visible: this.props.multiple
        ? this.props.value.length > 0
        : this.props.value,
      uploadImageUrl: [],
      imageResizeUrl: "",
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.value &&
      prevProps.value !== this.props.value &&
      this.props.value.length > 0
    ) {
      this.setState({ img_visible: true });
    }
  }

  handleChange = (value) => {
    let imgUrl;
    const { files } = value.currentTarget;
    const { multiple, imageResize } = this.props;

    if (multiple) {
      imgUrl = [];
    }
    // const callApis = new Promise((resolve, reject) => {
    // promise to upload all the files on the server first and then set state and send data to parent component
    if (files) {
      Array.from(files).map((file) => {
        const bodyFormData = new FormData();
        bodyFormData.append("type", this.props.folder);
        bodyFormData.append("file", file);
        // if (e.target.files && e.target.files.length > 0) {
        //   const reader = new FileReader();
        //   reader.addEventListener("load", () =>
        //     this.setState({ src: reader.result })
        //   );
        //   reader.readAsDataURL(e.target.files[0]);
        // }
        axios
          .post("/api/upload", bodyFormData)
          // eslint-disable-next-line consistent-return
          .then((res) => {
            // this.setState({ key: res.data.data.key });
            // var limit = 5;
            // $(document).ready(function(){
            //     $('#image').change(function(){
            //         var files = $(this)[0].files;
            //         if(files.length > limit){
            //             alert("You can select max "+limit+" images.");
            //             $('#image').val('');
            //             return false;
            //         }else{
            //             return true;
            //         }
            //     });
            // });
            if (multiple) {
              if (imgUrl.length < 10) {
                imgUrl.push(res.data.data.imageUrl);
              } else {
                return null;
              }
            } else {
              imgUrl = res.data.data.imageUrl;
            }
            this.setState(
              {
                img_visible: true,
                uploadImageUrl: imgUrl,
                // key: res.data.data.key
              },
              () => {
                this.props.onChange(this.props.name, imgUrl);
              }
            );
            if (imageResize) {
              const reader = new FileReader();
              reader.addEventListener("load", () => {
                this.setState({ imageResizeUrl: reader.result }, () => {
                  this.props.onChange(this.props.name, reader.result);
                });
              });
              reader.readAsDataURL(files[0]);
              // imgUrl = res.data.data.imageUrl;
            }
          })
          .catch((err) => {
            return err;
          });
        return null;
      });
    }
  };

  handleClick = (url) => {
    const { multiple } = this.props;
    const { uploadImageUrl } = this.state;

    const imageDataArray = this.props.data || uploadImageUrl;

    let filteredArray = "";
    // var key = this.state.key;
    // const key = url && url.substring(url.lastIndexOf("/") + 1);
    // eslint-disable-next-line no-unused-expressions
    this.props.delImageUrl(url);

    const dataArray = this.props.data || "";
    for (let i = 0; i < dataArray.length; i++) {
      if (dataArray[i] === url) {
        dataArray.splice(i, 1);
      }
    }
    const key = url && new URL(url).pathname;
    if (key) {
      axios
        .post("/api/deleteimage", { key })
        .then(() => {})
        .catch(() => {});
      if (dataArray.length > 0) {
        this.setState({ img_visible: true });
      } else {
        this.setState({ img_visible: false });
      }
      if (multiple) {
        const filteredData =
          imageDataArray && imageDataArray.filter((item) => item !== url);
        this.setState({ uploadImageUrl: filteredData });
        if (filteredData.length === 0) {
          filteredArray = "";
          this.setState({ img_visible: false });
        } else {
          filteredArray = filteredData;
        }
      } else {
        filteredArray = "";
        this.setState({ img_visible: false });
      }
      this.props.onChange(this.props.name, filteredArray);
    }
  };

  render() {
    const {
      classes,
      type,
      multiple,
      fileType,
      thumbContainerClass,
    } = this.props;

    let { uploadImageUrl } = this.state;
    const { imageResizeUrl } = this.state;

    let imageList;
    if (this.props.imageResize) {
      imageList = imageResizeUrl;
    }
    if (this.props.value === "") {
      uploadImageUrl = [];
    }
    if (uploadImageUrl.length > 0) {
      imageList = uploadImageUrl;
    } else {
      imageList = this.props.value;
    }

    let acceptedFIles = "*";
    if (!fileType) {
      acceptedFIles = "image/*";
    } else if (fileType === "doc") {
      acceptedFIles = "application/vnd.ms-powerpoint,  application/pdf";
    }
    return (
      <div className="form-group" style={{ paddingTop: "13px" }}>
        {!this.state.img_visible && (
          <div className="image_input">
            <Grid container alignItems="center" direction="row">
              <Grid className={classes.spacingLogo}>
                <label>
                  {type === "club" ? (
                    <img
                      src={UploadImage}
                      alt=" icon"
                      className={classes.uploadImage}
                    />
                  ) : (
                    <UploadLogoSvg />
                  )}
                  <input
                    type="file"
                    style={{ display: "none" }}
                    id={this.props.id}
                    name={this.props.name}
                    onChange={this.handleChange}
                    className={classes.form_control}
                    multiple={multiple}
                    accept={acceptedFIles}
                  />
                </label>
              </Grid>
              <Grid spacing={2} className={classes.uploadLogo}>
                <label htmlFor={this.props.id}>{this.props.label}</label>
              </Grid>
            </Grid>
            {/* <input
              id={this.props.id}
              name={this.props.name}
              type="file"
              onChange={this.handleChange}
              className={classes.form_control}
              style={{ color: "transparent" }}
            /> */}
          </div>
        )}
        <div className={classes.error}>
          <ErrorMessage name={this.props.name} />
        </div>

        {this.state.img_visible && (
          <div className={classNames("impage_preview", thumbContainerClass)}>
            {multiple ? (
              imageList &&
              imageList.map((url) => {
                return (
                  <Thumb
                    file={url}
                    classes={classes}
                    handleClick={() => this.handleClick(url)}
                  />
                );
              })
            ) : (
              <Thumb
                file={this.props.value}
                classes={classes}
                handleClick={() => this.handleClick(this.props.value)}
                fileType={this.props.fileType}
              />
            )}
          </div>
        )}
        {this.state.img_visible && this.props.value === "" && (
          <div className="image_input">
            <Grid container alignItems="center" direction="row">
              <Grid className={classes.spacingLogo}>
                <label>
                  {/* {type === "club" ? (
                    <img
                      src={UploadImage}
                      alt=" icon"
                      className={classes.uploadImage}
                    />
                  ) : ( */}
                  <UploadLogoSvg />
                  {/* )} */}
                  <input
                    type="file"
                    style={{ display: "none" }}
                    id={this.props.id}
                    name={this.props.name}
                    onChange={this.handleChange}
                    className={classes.form_control}
                    multiple={multiple}
                    accept={acceptedFIles}
                  />
                </label>
              </Grid>
              <Grid spacing={2} className={classes.uploadLogo}>
                <label htmlFor={this.props.id}>{this.props.label}</label>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(UploadField);
