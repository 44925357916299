import React from "react";
import {
  Typography,
  Grid,
  withStyles,
  Paper,
  CardContent,
} from "@material-ui/core";
import { connect } from "react-redux";
import classNames from "classnames";
import {
  Instagram,
  Linkedin,
  Twitter,
  FacebookRoundIcon,
  FacebookGrey,
  InstagramGrey,
  TwitterGrey,
  LinkedinGrey,
} from "../../Assets/index";
import { checkPermission } from "../../Utils/checkPermission";
import { spacecapitalize, capitalize } from "../../Utils/StringFunctions";
import { eventParticipants } from "../../Actions/eventActions";
import bannerImage from "../../Assets/company_default_banner.jpg";
import defaultLogo from "../../Assets/default_logo.png";
import SocialButton from "../../Components/Common/SocialButton";

const moment = require("moment");

const styles = () => ({
  logo: {
    minWidth: "70%",
  },
  orangeAvatar: {
    color: "#fff",
    backgroundColor: "#ffffff",
    width: "60%",
    height: 95,
    fontSize: "36px",
    position: "relative",
    top: "-30px",
    left: "10%",
    border: "1px solid",
    borderRadius: "4px",
    objectFit: "contain",
  },
  FreelancerTitle: {
    margin: "11px 0px 0px -8px",
    paddingBottom: "8px",
  },
  eventbasicdetail: {
    backgroundColor: "#fff",
    position: "relative",
  },
  iconContainer: {
    marginTop: "10px",
    justifyContent: "flex-end",
    paddingRight: "16px",
  },
  social: {
    height: "26px",
    width: "26px",
    marginRight: "14px",
  },
  freelancerInfo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    textAlign: "center",
  },
  monthly_credit: {
    display: "flex",
    justifyContent: "space-between",
  },
  status: {
    textTransform: "capitalize",
    fontSize: "14px",
    fontWeight: "600",
    color: "#4caf50",
  },
  freelancercriticaldetails: {
    marginTop: "-21px",
    marginRight: "20px",
  },
  freelancermaintitle: {
    fontSize: "24px",
    fontWeight: "600",
    color: "#626262",
    textTransform: "capitalize",
    lineHeight: 1.3,
  },
  freelancersubtitle1: {
    color: "#bfbfbf",
    fontSize: "16px",
    textTransform: "capitalize",
    lineHeight: 1.3,
  },
  freelancersubtitle2: {
    color: "#435bf2",
    fontSize: "16px",
  },
  monthlyCredits: {
    marginRight: "67px",
  },
  innertitle: {
    fontSize: "12px",
    color: "#ababab",
  },
  innercontent: {
    fontSize: "16px",
    color: "#626262",
  },
  credit: {
    fontSize: "12px",
    color: "#626262",
  },
  totalCredits: {
    display: "grid",
  },
  websiteName: {
    textDecoration: "none",
    color: "#4682B4",
    fontSize: "14px",
    "& active": {
      color: "#4682B4",
    },
  },
  fontSize: {
    fontSize: "18px",
  },
  subFont: {
    fontSize: "14px",
  },
  banner: { maxHeight: "300px" },
});

function AboutFreelancerTopDetail(props) {
  const { classes, company } = props;

  return (
    <React.Fragment>
      <main>
        <div className={classes.banner}>
          <img
            src={company.banner_image ? company.banner_image : bannerImage}
            alt=""
            width="100%"
            height="50%"
            className={classes.banner}
          />
        </div>
        <div className={classNames(classes.layout, classes.cardGrid)}>
          {/* End hero unit */}
          <div className={classes.eventbasicdetail}>
            <Grid
              container
              item
              lg={12}
              xs={12}
              style={{ paddingLeft: "20px", paddingRight: "20px" }}
            >
              <Grid item lg={2} xs={2}>
                <div className={classes.logo}>
                  <img
                    className={classes.orangeAvatar}
                    src={
                      company && company.user && company.user.profile_pic
                        ? company && company.user && company.user.profile_pic
                        : defaultLogo
                    }
                    alt="logo"
                  />
                </div>
              </Grid>
              <Grid item lg={5} xs={5}>
                <div className={classes.FreelancerTitle}>
                  <Typography
                    variant="body1"
                    component="p"
                    className={classNames(
                      classes.freelancermaintitle,
                      classes.fontSize
                    )}
                  >
                    {" "}
                    {capitalize(company.user && company.user.first_name)}{" "}
                    {capitalize(company.user && company.user.last_name)}
                    {/* {spacecapitalize(company.user && company.user.first_name)} */}
                  </Typography>
                  <Typography
                    className={classNames(
                      classes.freelancersubtitle1,
                      classes.subFont
                    )}
                    variant="subtitle1"
                    component="p"
                    style={{ paddingTop: "3px" }}
                  >
                    {spacecapitalize(company.industry)}
                  </Typography>
                  <Typography
                    className={classNames(
                      classes.freelancersubtitle2,
                      classes.subFont
                    )}
                    variant="subtitle1"
                    component="p"
                  >
                    <a
                      href={company.website}
                      // eslint-disable-next-line react/jsx-no-target-blank
                      target="_blank"
                      className={classes.websiteName}
                      style={{ textDecoration: "none" }}
                    >
                      {company.website}
                    </a>
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={5} lg={5}>
                <Grid container className={classes.iconContainer} spacing={2}>
                  <Grid item>
                    <Paper className={classes.paper}>
                      {company.linkedin ? (
                        <SocialButton type={Linkedin} url={company.linkedin} />
                      ) : (
                        <SocialButton type={LinkedinGrey} disable />
                      )}
                    </Paper>
                  </Grid>
                  <Grid item>
                    <Paper className={classes.paper}>
                      {company.facebook ? (
                        <SocialButton
                          type={FacebookRoundIcon}
                          url={company.facebook}
                        />
                      ) : (
                        <SocialButton type={FacebookGrey} disable />
                      )}
                    </Paper>
                  </Grid>
                  <Grid item>
                    <Paper className={classes.paper}>
                      {company.twitter ? (
                        <SocialButton type={Twitter} url={company.twitter} />
                      ) : (
                        <SocialButton type={TwitterGrey} disable />
                      )}
                    </Paper>
                  </Grid>
                  <Grid item>
                    <Paper className={classes.paper}>
                      {company.instagram ? (
                        <SocialButton
                          type={Instagram}
                          url={company.instagram}
                        />
                      ) : (
                        <SocialButton type={InstagramGrey} disable />
                      )}
                    </Paper>
                  </Grid>
                </Grid>
                {checkPermission("COMPANY_CRITICAL_DETAILS") && (
                  <Paper className={classes.containerPaper}>
                    <CardContent className={classes.cardContent}>
                      <div className={classes.freelancerInfo}>
                        <div className={classes.freelancercriticaldetails}>
                          <Typography
                            variant="subtitle2"
                            component="p"
                            className={classes.innertitle}
                          >
                            Seats
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            component="p"
                            className={classes.innercontent}
                          >
                            {company.total_seats}
                          </Typography>
                        </div>
                        <div className={classes.monthlyCredits}>
                          <Typography
                            variant="subtitle2"
                            component="p"
                            className={classes.innertitle}
                            style={{ paddingLeft: "12px" }}
                          >
                            Monthly credit
                          </Typography>
                          <div className={classes.monthly_credit}>
                            <Grid
                              item
                              xs={2}
                              lg={2}
                              className={classes.totalCredits}
                            >
                              <Typography
                                variant="subtitle1"
                                component="p"
                                className={classes.innercontent}
                              >
                                {company.total_credits}
                              </Typography>
                              <Typography
                                variant="caption"
                                className={classes.credit}
                              >
                                Total
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              lg={2}
                              className={classes.totalCredits}
                            >
                              <Typography variant="subtitle1" component="p">
                                {parseFloat(company.available_credits).toFixed(
                                  2
                                )}
                              </Typography>
                              <Typography
                                variant="caption"
                                className={classes.credit}
                              >
                                Remaining
                              </Typography>
                            </Grid>
                          </div>
                        </div>
                        <div className={classes.contractDate}>
                          <Typography
                            variant="subtitle2"
                            component="p"
                            className={classes.innertitle}
                          >
                            Contract expiry
                          </Typography>

                          <Typography
                            variant="subtitle1"
                            component="p"
                            className={classes.innercontent}
                          >
                            {moment(company.contract_end_date).format(
                              "DD/MM/YYYY"
                            )}
                          </Typography>
                          <Typography
                            variant="caption"
                            className={classes.status}
                          >
                            {company.status}
                          </Typography>
                        </div>
                      </div>
                    </CardContent>
                  </Paper>
                )}
              </Grid>
            </Grid>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    errors: state.errors,
  };
};

export default connect(mapStateToProps, { eventParticipants })(
  withStyles(styles)(AboutFreelancerTopDetail)
);
