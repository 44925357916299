import React from "react";

const MyOrderSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsxlink="http://www.w3.org/1999/xlink" width="350.099" height="272.911" viewBox="0 0 350.099 272.911">
  <defs>
    <linearGradient id="linear-gradient" x1="-3.316" y1="0.5" x2="-2.316" y2="0.5" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#3076a1"/>
      <stop offset="1" stop-color="#639c38"/>
    </linearGradient>
    <linearGradient id="linear-gradient-2" x1="-12.985" y1="0.5" x2="-11.985" y2="0.5" xlinkhref="#linear-gradient"/>
    <linearGradient id="linear-gradient-3" x1="-10.88" y1="0.5" x2="-9.88" y2="0.5" xlinkhref="#linear-gradient"/>
  </defs>
  <g id="Group_3165" data-name="Group 3165" transform="translate(-75.236 -104)">
    <path id="Path_1" data-name="Path 1" d="M400.918,198.742c-8.924-33.974-23.395-68.951-52.378-88.8-38.854-26.6-90.88-18.333-136.94-8.539-27.185,5.781-54.821,11.9-78.949,25.7S88.017,163.781,84,191.281c-.776,5.31-.88,10.968,1.5,15.776,1.7,3.438,4.545,6.169,6.629,9.39,9.433,14.576,1.353,33.581.467,50.92-1.161,22.722,12.053,45.552,32.333,55.863,15.859,8.064,34.359,8.59,52.15,8.675a1733.015,1733.015,0,0,0,175.6-8.084,301.108,301.108,0,0,0,35.49-39.03c5.84-7.681,11.369-15.755,14.689-24.814C410,240.492,406.19,218.814,400.918,198.742Z" transform="translate(-6.502 11.974)" fill="#f5f5f5"/>
    <g id="Group_866" data-name="Group 866" transform="translate(-20.764 -15)">
      <g id="Group_865" data-name="Group 865" transform="translate(96 139)">
        <path id="Path_1781" data-name="Path 1781" d="M1368.734,1587.88c0,9.478-73.037,17.161-163.133,17.161s-163.133-7.683-163.133-17.161,73.037-17.161,163.133-17.161S1368.734,1578.4,1368.734,1587.88Z" transform="translate(-1042.468 -1352.131)" fill="#d1e1e8"/>
        <g id="Group_823" data-name="Group 823" transform="translate(206.796 31.388)">
          <g id="Group_816" data-name="Group 816" transform="translate(53.712 52.169)">
            <path id="Path_1782" data-name="Path 1782" d="M1647.228,1371.77c-3.881-17.74,4.507-22.166,12.618-26.446,6.628-3.5,12.89-6.8,12.556-17.258-.443-13.859,9-18.9,17.328-23.349,6.2-3.309,12.05-6.436,12.924-12.742,2.1-15.174,16.1-34.034,16.237-34.222l.869.65c-.139.186-13.969,18.826-16.032,33.72-.949,6.854-7.039,10.107-13.487,13.55-8.444,4.509-17.176,9.173-16.754,22.358.355,11.132-6.5,14.752-13.134,18.251-8.109,4.28-15.768,8.322-12.064,25.255Z" transform="translate(-1646.25 -1257.754)" fill="url(#linear-gradient)"/>
          </g>
          <g id="Group_817" data-name="Group 817" transform="translate(47.325 35.257)">
            <path id="Path_1783" data-name="Path 1783" d="M1643.074,1344.965c-.186,0-.372-.007-.56-.021-3.508-.26-6.947-2.925-10.822-8.386l-.243-.343.271-.322c.063-.075,6.274-7.645,3.993-21.856a66.328,66.328,0,0,0-1.815-7.892c-.834-2.974-1.384-4.938-.462-6.507,1.171-1.99,4.336-3.033,13.255-5.156,9.5-2.262,12.672-12.962,15.474-22.4,2.638-8.891,5.137-17.277,12.526-17.017,6.791.259,17.1-10.381,26.2-19.76,8.443-8.7,16.411-16.9,21.505-16.741a4.062,4.062,0,0,1,3.068,1.524c4.542,5.409.305,13.615-3.793,21.55-3.5,6.77-6.8,13.165-4.672,17.382,2.671,5.3,3.044,10.094,1.081,13.866-2.324,4.463-7.74,7.341-16.1,8.552-7.644,1.108-8.47,9.445-9.345,18.271-1.021,10.3-2.177,21.973-15,23.081-9.525.824-15.945,7.511-21.61,13.411C1651.521,1340.888,1647.605,1344.965,1643.074,1344.965Zm-10.262-8.707c3.53,4.889,6.732,7.378,9.782,7.6,4.263.316,8.149-3.731,12.648-8.417,5.8-6.038,12.368-12.881,22.3-13.74,11.928-1.032,12.99-11.747,14.017-22.108.918-9.261,1.785-18.008,10.269-19.238,7.994-1.159,13.139-3.843,15.292-7.979,1.8-3.448,1.42-7.9-1.088-12.875-2.374-4.712,1.051-11.345,4.678-18.368,3.934-7.62,8-15.5,3.926-20.354a2.982,2.982,0,0,0-2.276-1.138c-4.605-.153-12.418,7.887-20.687,16.413-9.585,9.881-19.492,20.093-26.743,20.094-.093,0-.185,0-.278-.005-6.552-.244-8.822,7.4-11.446,16.241-2.885,9.723-6.155,20.743-16.263,23.15-8.065,1.92-11.594,2.991-12.571,4.651-.688,1.17-.214,2.862.571,5.664a67.364,67.364,0,0,1,1.842,8.013C1638.9,1327.073,1634.039,1334.606,1632.812,1336.258Z" transform="translate(-1631.449 -1218.558)" fill="#55ae71"/>
          </g>
          <g id="Group_818" data-name="Group 818" transform="translate(25.275 9.933)">
            <path id="Path_1784" data-name="Path 1784" d="M1614.414,1307.878c-.127,0-.254-.005-.381-.017-5.753-.524-10.177-13.128-14.456-25.316-2.029-5.782-3.947-11.243-5.813-14.748-1.448-2.719-3.288-5.554-5.068-8.3-4.74-7.3-9.64-14.854-8.046-21.48.839-3.485,3.341-6.387,7.649-8.869,9.428-5.434,7.978-8.5,4.8-15.225-2.333-4.934-5.527-11.692-5.65-22.326-.053-4.57-.159-8.522-.252-12.008-.3-11.207-.452-16.832,1.941-18.868.991-.843,2.377-1.059,4.362-.682,3.394.646,4.861,3.386,6.415,6.287,1.982,3.7,4.229,7.9,11.01,9.316,6.363,1.331,12.484,3.146,14.172,7.374,1.188,2.974.064,6.884-3.437,11.954a3.885,3.885,0,0,0-.89,3.2c.554,2.042,3.725,3.522,7.4,5.235,5.225,2.437,11.727,5.471,13.568,11.754,1.484,5.063-.233,11.379-5.25,19.307-11.867,18.757-11.39,31.477-10.969,42.7.312,8.317.607,16.172-4.409,24.948C1618.895,1305.986,1616.7,1307.878,1614.414,1307.878ZM1591.8,1160.948a2.9,2.9,0,0,0-1.96.6c-2,1.7-1.842,7.494-1.559,18.012.093,3.49.2,7.447.253,12.025.12,10.4,3.256,17.03,5.546,21.875,3.153,6.67,5.056,10.7-5.24,16.629-4.036,2.326-6.37,5-7.135,8.183-1.485,6.172,3.286,13.525,7.9,20.636,1.792,2.761,3.645,5.616,5.115,8.377,1.905,3.579,3.836,9.077,5.879,14.9,3.971,11.313,8.472,24.135,13.53,24.595,1.931.173,3.961-1.575,6.034-5.2,4.861-8.507,4.586-15.857,4.266-24.368-.427-11.381-.911-24.28,11.137-43.322,4.84-7.649,6.516-13.675,5.125-18.422-1.7-5.811-7.7-8.608-12.986-11.076-3.917-1.827-7.3-3.405-7.985-5.933a4.875,4.875,0,0,1,1.045-4.1c3.279-4.748,4.365-8.324,3.323-10.934-1.5-3.763-7.611-5.506-13.388-6.715-7.277-1.523-9.754-6.149-11.745-9.866-1.49-2.782-2.777-5.184-5.661-5.732A8.049,8.049,0,0,0,1591.8,1160.948Z" transform="translate(-1580.343 -1159.863)" fill="#55ae71"/>
          </g>
          <g id="Group_819" data-name="Group 819" transform="translate(42.483 29.981)">
            <path id="Path_1785" data-name="Path 1785" d="M1637.2,1341.022l-1.035-.328c5.8-18.332-4.691-59.462-7.818-69.472-1.537-4.923-.116-12.333,1.258-19.5,1.775-9.255,3.61-18.823-1.07-22.412-8.788-6.738-8.325-22.324-8.3-22.984l1.085.04c-.006.156-.464,15.688,7.877,22.083,5.212,4,3.312,13.9,1.475,23.478-1.349,7.033-2.744,14.307-1.287,18.969C1633.252,1283.278,1642.988,1322.733,1637.2,1341.022Z" transform="translate(-1620.224 -1206.329)" fill="url(#linear-gradient-2)"/>
          </g>
          <path id="Path_1786" data-name="Path 1786" d="M1537.353,1237.342c-2.415-6.129-11.845-5.2-10.978,3.467s6.933,17.912-2.311,32.645,11.845,17.623,19.356,26.867,10.112,21.956,16.467,30.045,10.689,27.445,15.889,20.223.306-16.615.884-29.615,12.149-21.231.577-29.608-23.208-10.946-19.388-29.842C1559.534,1253.188,1544.864,1256.41,1537.353,1237.342Z" transform="translate(-1521.762 -1191.742)" fill="#72bd87"/>
          <g id="Group_820" data-name="Group 820" transform="translate(19.105 67.728)">
            <path id="Path_1787" data-name="Path 1787" d="M1609.371,1386.607c-.063-.384-6.489-38.465-20.872-48.9-14.669-10.642-17.333-20.474-22.456-43.656l1.06-.235c5.06,22.9,7.685,32.6,22.033,43.012,14.745,10.7,21.045,48.019,21.306,49.6Z" transform="translate(-1566.043 -1293.816)" fill="#fff"/>
          </g>
          <path id="Path_1788" data-name="Path 1788" d="M1621.727,1296s4.274-10.569-3.5-25.594-14.075-12.713,4.676-24.271.117-47.963,16.079-52.586,29.831-63.548,41.967-56.045-4.045,36.4,4.623,45.065,9.824,19.7-8.09,28.63,12.713,43.025-11.558,53.427S1648.612,1315.812,1621.727,1296Z" transform="translate(-1572.272 -1136.842)" fill="#55ae71"/>
          <g id="Group_821" data-name="Group 821" transform="translate(56.456 34.374)">
            <path id="Path_1789" data-name="Path 1789" d="M1653.025,1352.5c-.027-.134-2.637-13.668,5.609-31.156,8.4-17.814,12.421-37.289,10.755-52.1-.9-8.018,4.235-11.154,9.2-14.188,4.891-2.986,9.949-6.074,9.669-13.974-.252-7.137,2.769-23.868,2.9-24.577l1.068.194c-.031.172-3.128,17.324-2.881,24.345.3,8.534-5.031,11.79-10.189,14.939-4.893,2.987-9.514,5.809-8.689,13.139,1.687,15-2.37,34.692-10.852,52.68-8.083,17.142-5.554,30.347-5.527,30.479Z" transform="translate(-1652.611 -1216.511)" fill="#fff"/>
          </g>
          <path id="Path_1790" data-name="Path 1790" d="M1667.281,1353.527c5.45-5.062,12.659-5.793,11.157,1.716s-6.651,9.656-4.077,14.376,6.866,9.44-.429,15.234-11.371,6.437-13.517,17.379-1.717,18.416-11.157,18.882-5.793-13.3-2.79-19.525a20.642,20.642,0,0,0,1.5-16.424c-3-9.323-8.8-14.359,3.433-17.527S1663.606,1356.94,1667.281,1353.527Z" transform="translate(-1590.799 -1257.84)" fill="#fff"/>
          <g id="Group_822" data-name="Group 822" transform="translate(59.105 100.862)">
            <path id="Path_1791" data-name="Path 1791" d="M1658.787,1440.018c-.03-1.339-.679-32.861,5.216-38.166,6.021-5.42,7.179-15,7.332-20.222.2-6.809,9-10.85,9.372-11.02l.446.99c-.085.039-8.553,3.933-8.733,10.062-.194,6.595-1.686,15.591-7.691,21-5.529,4.976-4.865,37.012-4.857,37.335Z" transform="translate(-1658.752 -1370.611)" fill="url(#linear-gradient-3)"/>
          </g>
          <path id="Path_1792" data-name="Path 1792" d="M1655.583,1501.66l-9.78,37.1h-21.855l-9.78-37.1Z" transform="translate(-1574.299 -1344.256)" fill="#f8d6c5"/>
        </g>
        <path id="Path_1793" data-name="Path 1793" d="M1339.577,1270.3v-1.267h-19.668v1.267l8.564,4.961.967,14.19-3.1.989v.483h6.805v-.483l-3.1-.989.967-14.19Z" transform="translate(-1200.204 -1180.608)" fill="#e6e3df"/>
        <g id="Group_824" data-name="Group 824" transform="translate(247.6 152.914)">
          <path id="Path_1794" data-name="Path 1794" d="M1627.323,1491.92l-10.989-73.087,2.116-.327,10.989,73.088Z" transform="translate(-1616.334 -1418.506)" fill="#343140"/>
        </g>
        <g id="Group_825" data-name="Group 825" transform="translate(234.18 152.914)">
          <path id="Path_1795" data-name="Path 1795" d="M1596.22,1491.92l-10.989-73.087,2.116-.327,10.989,73.088Z" transform="translate(-1585.231 -1418.506)" fill="#343140"/>
        </g>
        <g id="Group_826" data-name="Group 826" transform="translate(176.954 152.914)">
          <path id="Path_1796" data-name="Path 1796" d="M1454.714,1491.92l-2.116-.326,10.989-73.088,2.116.327Z" transform="translate(-1452.598 -1418.506)" fill="#343140"/>
        </g>
        <path id="Path_1797" data-name="Path 1797" d="M1586.508,1312.317l8.609-76.339s-15.057-1.66-24.155-1.251l-13.673,77.591Z" transform="translate(-1335.164 -1161.068)" fill="#4e4864"/>
        <rect id="Rectangle_303" data-name="Rectangle 303" width="29.22" height="7.364" transform="translate(222.124 146.646)" fill="#e2d2ca"/>
        <path id="Path_1798" data-name="Path 1798" d="M1506.965,1309.33l-4.567-72.747s38.106-3.488,61.511-.481Z" transform="translate(-1303.957 -1161.192)" fill="#4e4864"/>
        <path id="Path_1799" data-name="Path 1799" d="M1542.888,1545.98s-.349,12.195,1.392,12.928,8.332-3.506,11.239-7.7-3.642-13.953-4.717-19.338c-.45-2.254-5.571-6.31-8.069-5.147a12.193,12.193,0,0,0-4.32,4.139S1544.767,1535.216,1542.888,1545.98Z" transform="translate(-1324.433 -1327)" fill="#000719"/>
        <g id="Group_827" data-name="Group 827" transform="translate(213.98 199.722)">
          <path id="Path_1800" data-name="Path 1800" d="M1538.413,1531.132a67.246,67.246,0,0,1,3.274,7.39c1.111,3.229,1.319,12.17,2.02,12.791s6.223-2.165,6.96-5.509-1.5-10.026-3.634-13.54a21.815,21.815,0,0,0-4.3-5.271S1538.968,1527.724,1538.413,1531.132Z" transform="translate(-1538.413 -1526.993)" fill="#de9673"/>
        </g>
        <path id="Path_1801" data-name="Path 1801" d="M1490.893,1311.749s.276,5.363-2.625,8.931-22.413,13.368-22.271,19.029,3.717,14.521,5,16.769,6.023,11.575,8.485,17.034,16.7,31.574,19.479,37.461,1.071-23.547,1.071-23.547l-14.235-41.743,33.26-32.4S1517.659,1303.858,1490.893,1311.749Z" transform="translate(-1283.261 -1203.105)" fill="#de9673"/>
        <path id="Path_1802" data-name="Path 1802" d="M1544.478,1318.846s-.642,4.21,1.07,5.994,9.6,12.186,5.423,22.407c-3.836,9.384-11.274,9.2-11.274,9.2h-11.513s3.342,16.892,2.263,28.023-6.157,36.55-6.264,37.835-4.867,5.3-6.259,3.265c0,0-.129-15.305-.878-21.085s-3.938-28.9-4.9-32.324-6.636-13.807-6.636-14.878-5.541-17.827,6.554-22.108S1528.432,1318.935,1544.478,1318.846Z" transform="translate(-1305.005 -1208.931)" fill="#eea886"/>
        <path id="Path_1803" data-name="Path 1803" d="M1513.29,1573.445s-7.94,9.262-7.048,10.927,8.683,2.513,13.581,1.08,7.9-12.543,8.494-18c.355-3.254-.364-8.409-3.036-9.076a12.2,12.2,0,0,0-5.962.5S1521.521,1566.257,1513.29,1573.445Z" transform="translate(-1306.103 -1344.995)" fill="#000719"/>
        <g id="Group_828" data-name="Group 828" transform="translate(204.638 212.71)">
          <path id="Path_1804" data-name="Path 1804" d="M1525.338,1558.266a67.249,67.249,0,0,1-2.1,7.8c-1.167,3.21-6.627,10.292-6.473,11.216s6.2,2.23,8.875.093,5.137-8.74,5.689-12.813a21.818,21.818,0,0,0-.028-6.8S1527.913,1555.964,1525.338,1558.266Z" transform="translate(-1516.761 -1557.094)" fill="#eea886"/>
        </g>
        <path id="Path_1805" data-name="Path 1805" d="M1529.162,1341.421c-3.621,9.508-11.274,9.205-11.274,9.205h-11.513s-4.8-7.611-16.562-8.917a34.78,34.78,0,0,0-8.868.211l-.12.017c-15.624,2.314-12.608,8.049-12.56,8.2-1.3-3.171-2.662-8.213-2.333-10.928,1.5-12.369,19.368-14.962,22.27-18.529s2.626-8.931,2.626-8.931c26.766-7.891,28.165,1.532,28.165,1.532,1.173-.162,4.18-1.894,5.459-1.9,0,0,1.34,2.925,2.284,5.566C1527.569,1319.274,1533.092,1331.1,1529.162,1341.421Z" transform="translate(-1283.197 -1203.105)" fill="#d44040"/>
        <path id="Path_1806" data-name="Path 1806" d="M1507.417,1182.215a12.332,12.332,0,0,0-1.719-.425,48.5,48.5,0,0,0-6.9,2.37c-9.534,3.656-25.49,10.258-25.49,10.258s-2.977,12.179-4.594,16.971v0a6.717,6.717,0,0,1-.8,1.866c-1.019.917-15.686.61-25.157,0s-9.644-.482-9.644-.482-1.514-5.894,1.851-6.606,19.1-1.262,21.542-1.772,4.076.2,4.788-2.24c.375-1.284,1.116-5.749,1.984-10.119.362-1.828.749-3.635,1.141-5.192v0a18,18,0,0,1,1.355-4.034c1.124-1.92,6.094-6.594,9.761-9.908.008,0,.008-.008.008-.008,1.975-1.781,3.575-3.171,4-3.529,1.2-1.019,10.822-3.773,10.822-3.773s6.169,1.436,9.955,2.261c.918.2,1.693.362,2.211.463.822.16,2.817.808,5.247,1.642l0,0C1508.719,1172.012,1508.2,1177.267,1507.417,1182.215Z" transform="translate(-1264.372 -1121.798)" fill="#eea886"/>
        <g id="Group_829" data-name="Group 829" transform="translate(218.991)">
          <path id="Path_1807" data-name="Path 1807" d="M1551.82,1065.733s4.35-4.113,9.882.689,4.139,10.6,4.979,15.78-2.3,17.557.57,22.262.926,7.808.926,7.808-14.609,6.834-17.186-16.6S1551.82,1065.733,1551.82,1065.733Z" transform="translate(-1550.026 -1064.094)" fill="#000719"/>
        </g>
        <g id="Group_830" data-name="Group 830" transform="translate(199.84 30.1)">
          <path id="Path_1808" data-name="Path 1808" d="M1535.026,1142.057s.313,2.835,1.413,3.9,10.2,2.734,12.486,5.5-1.046,18.093-1.84,22.224-4.866,12.914-4.6,15.882a78.747,78.747,0,0,0,2.77,12.964c.874,2.248,3.622,11.868,4.249,13.7,0,0-21.538,7.647-37.521,3.254,0,0,.2-15.358-.28-18.489s-2.4-14.51-2.447-17.725-5.107-6.143-3.177-11.967c1.694-5.113,9.98-15.223,11.5-17.214s5.352-5.886,5.352-5.886,1.986-5.248,1.239-8.471l10.529-5.872A65.26,65.26,0,0,0,1535.026,1142.057Z" transform="translate(-1505.641 -1133.858)" fill="#d44040"/>
        </g>
        <g id="Group_831" data-name="Group 831" transform="translate(218.83 47.334)">
          <path id="Path_1809" data-name="Path 1809" d="M1573.225,1174.379c-1.1-.13-5.235-2.4-7.035,3.026s-1.1,11.78-.616,14.728,1.565,17.246,1.589,19.658-1.124,3.813-3.294,7.108-13.191,24.377-14.215,25.747l5.25,2.25s18.8-22.193,20.3-25.577,2.2-6.506,2.46-12.974,2.058-22.543,1.773-24.953S1579.019,1175.066,1573.225,1174.379Z" transform="translate(-1549.653 -1173.8)" fill="#eea987"/>
        </g>
        <g id="Group_832" data-name="Group 832" transform="translate(233.479 46.134)">
          <path id="Path_1810" data-name="Path 1810" d="M1583.607,1193.674s9.456-1.681,15.458.136c0,0,3.934-20.139-7.31-22.626C1582.561,1169.149,1583.86,1186.517,1583.607,1193.674Z" transform="translate(-1583.607 -1171.019)" fill="#d44040"/>
        </g>
        <g id="Group_833" data-name="Group 833" transform="translate(213.327 28.319)">
          <path id="Path_1811" data-name="Path 1811" d="M1554.212,1143.613s-6.164,10.679-13.96,11.182.456-8.943.456-8.943.3-1.1.822-2.7a12.2,12.2,0,0,0,.41-2.427h0a15.173,15.173,0,0,0,0-3.339l10.749-7.654S1552.135,1141.221,1554.212,1143.613Z" transform="translate(-1536.9 -1129.73)" fill="#eea987"/>
        </g>
        <g id="Group_834" data-name="Group 834" transform="translate(205.569 2.083)">
          <path id="Path_1812" data-name="Path 1812" d="M1533.322,1106.075c-1.855.281-7.113.652-11.891-10.718-5.838-13.89-.407-22.694,5.2-25.2s11.4-1.485,15.832,5.514c0,0,3.311,5.484,1.735,16.667S1535.309,1105.775,1533.322,1106.075Z" transform="translate(-1518.919 -1068.923)" fill="#eea987"/>
        </g>
        <g id="Group_835" data-name="Group 835" transform="translate(204.485 1.34)">
          <path id="Path_1813" data-name="Path 1813" d="M1531.588,1077.7s-7.785,1.979-13.026,14.364c0,0-5.93-12.587,1.886-20.321s15.071-3.727,17.755-1.507c0,0,3.271.128,4.495,6.056s6.84,13.993-1.131,19.909c0,0-.368-6.792-1.435-8.2S1536.582,1088.15,1531.588,1077.7Z" transform="translate(-1516.406 -1067.199)" fill="#000719"/>
        </g>
        <g id="Group_836" data-name="Group 836" transform="translate(227.117 18.356)">
          <path id="Path_1814" data-name="Path 1814" d="M1569.023,1107.868c.2-1.1,1.207-1.724,2.011-.733s.9,5.406-.6,6.175S1568.613,1110.107,1569.023,1107.868Z" transform="translate(-1568.86 -1106.638)" fill="#eea987"/>
        </g>
        <g id="Group_837" data-name="Group 837" transform="translate(217.958 35.467)">
          <path id="Path_1815" data-name="Path 1815" d="M1556.241,1146.3a9.957,9.957,0,0,1-8.609,6.273,19.421,19.421,0,0,0,.41-2.427h0S1552.127,1150.681,1556.241,1146.3Z" transform="translate(-1547.632 -1146.297)" fill="#a77860"/>
        </g>
        <path id="Path_1816" data-name="Path 1816" d="M1521,1171.947s-12.552,3.989-15.237,15.078l4.843,1.79Z" transform="translate(-1305.87 -1125.413)" fill="#d44040"/>
        <g id="Group_838" data-name="Group 838" transform="translate(208.789 112.944)">
          <path id="Path_1817" data-name="Path 1817" d="M1539.368,1325.865s-1.576,3.851-2.546,4.289a23.467,23.467,0,0,0-2.994,1.866c-.813.547-3.475,1.332-4.122,2.156-.265.337-1.137,2.929-1.743,3.567s-1.978,1.8-1.468,2.229,1.986-.321,1.986-.321-.521.609-.21,1.139a1.49,1.49,0,0,0,1.41.9s-.163.909.208,1.261a1.97,1.97,0,0,0,1.6.63,1.684,1.684,0,0,0,.819,1.021,1.938,1.938,0,0,0,1.812-.11s.912.871,1.625.506a5.908,5.908,0,0,0,1.6-1.445,29.308,29.308,0,0,0,4.645-9.249,20.391,20.391,0,0,1,3.23-7.03Z" transform="translate(-1526.382 -1325.865)" fill="#eea886"/>
        </g>
        <path id="Path_1818" data-name="Path 1818" d="M1406.24,1577.222s2.35,3.558,4.088,4.8,10.92,6.288,12.492,6.535,9.928,1.158,10.424,3.971-6.288,4.22-14.561,3.806-19.772,1.052-20.848.071-1.655-7.352-.745-11.405a25.64,25.64,0,0,0,.5-7.652Z" transform="translate(-1243.829 -1355.828)" fill="#eea886"/>
        <path id="Path_1819" data-name="Path 1819" d="M1422.83,1603.49a16.171,16.171,0,0,1-10.176,1.738c-5.874-.911-13.783-2.153-15.992-1.408,0,0-.14,6.017,1.184,7.518s17.293-.165,18.446-.141,17.293,1.725,16.962-3.736C1433.254,1607.461,1434.826,1604.649,1422.83,1603.49Z" transform="translate(-1243.839 -1370.762)" fill="#000719"/>
        <path id="Path_1820" data-name="Path 1820" d="M1318.452,1581.046c-.827,3.559-15.387,3.559-17,.662s-14.1-12.74-14.686-16.132a10.13,10.13,0,0,1,1.167-5.572,13.672,13.672,0,0,1,5.86-6.423c4.626-2.231,7.659,7.4,7.659,7.4a23.771,23.771,0,0,0,1.365,4.509c.662,1.157,5.626,9.928,7.612,10.588S1319.278,1577.489,1318.452,1581.046Z" transform="translate(-1181.322 -1342.197)" fill="#eea886"/>
        <path id="Path_1821" data-name="Path 1821" d="M1318.452,1589.951c-.827,3.559-15.387,3.559-17,.662s-14.1-12.74-14.686-16.132a10.131,10.131,0,0,1,1.167-5.572c1.747.137,15.05,15.333,17.78,16.658a4.248,4.248,0,0,0,4.716-.581C1312.413,1585.65,1319.278,1586.394,1318.452,1589.951Z" transform="translate(-1181.322 -1351.102)" fill="#000719"/>
        <path id="Path_1822" data-name="Path 1822" d="M1284.328,1301.8s8.7-.646,11.136-2.366,18.863,4.918,19.164,6.441a4.324,4.324,0,0,1-.006,2.273l-32.271-.08Z" transform="translate(-1178.852 -1197.736)" fill="#eea987"/>
        <path id="Path_1823" data-name="Path 1823" d="M1338.424,1410.479l-13.106,29.487c-.917,2.067-1.773,3.993-2.521,5.674-.875,1.969-1.6,3.6-2.1,4.729-3.474,7.817-11.819,25.328-11.819,25.328s-7.6.326-7.972-5.134c0,0,7.137-12.377,7.137-20.689s1.116-32.8,9.058-38.1C1326.783,1405.319,1333.073,1404.1,1338.424,1410.479Z" transform="translate(-1189.399 -1258.633)" fill="#eea987"/>
        <path id="Path_1824" data-name="Path 1824" d="M1170.9,1332.307a8.885,8.885,0,0,0,2.978,1.985c1.365.372,24.2,2.11,34.746,5.584s24.571,5.708,27.177,8.687,6.949,19.358,6.825,22.46,11.668,60.129,12.838,64.209c.62,2.163-9.115-.548-9.115-.548s-14.731-33.755-17.5-39.462c-5.083-10.486-5.336-23.329-7.2-25.315s-22.585-6.453-34.5-6.453-38.593.993-41.447-.621-20.724-13.4-13.278-29.161S1170.9,1332.307,1170.9,1332.307Z" transform="translate(-1092.594 -1213.166)" fill="#eea987"/>
        <path id="Path_1825" data-name="Path 1825" d="M1260.6,1119.707s-10.061,27.413-16.7,35.725-3.927-22.528-3.927-22.528S1251.49,1114.968,1260.6,1119.707Z" transform="translate(-1154.451 -1095.259)" fill="#000719"/>
        <path id="Path_1826" data-name="Path 1826" d="M1233.944,1084.346s3.75-20.952-19.415-19.694c-22.933,1.244-10.893,32.47-15.369,38.693s-9.4,11.4-10.542,17.906a33.537,33.537,0,0,0,2.885,20.27C1198.4,1155.879,1216.541,1077.8,1233.944,1084.346Z" transform="translate(-1125.286 -1064.38)" fill="#000719"/>
        <path id="Path_1827" data-name="Path 1827" d="M1237.243,1074.039c4.537-.276,14.632,1.88,16.335,13.889a37.449,37.449,0,0,1-1.077,5.806c-.755,2.557,1.623,10.041,1.031,10.419a16.534,16.534,0,0,1-3.047.883s-2.545,8.241-5.427,8.207-13.818-2.149-19.083-12.294S1218.926,1075.156,1237.243,1074.039Z" transform="translate(-1144.76 -1069.735)" fill="#eea886"/>
        <path id="Path_1828" data-name="Path 1828" d="M1236.232,1143.055s-.661,1.007-1.525,2.531c-.436.781-.931,1.7-1.419,2.682-1.337,2.727-2.606,6-2.516,8.458,0,0-6.73.338-9.052-9.713,0,0,4.785-8.705,3.733-15.143-1.044-6.347,10.471,10.727,10.771,11.177Z" transform="translate(-1144.38 -1101.824)" fill="#eea886"/>
        <path id="Path_1829" data-name="Path 1829" d="M1251.752,1085.4s-7.305,5.115-14.6,6.222-13.058,9.062-13.058,9.062-10.129-14.182-1.3-25.593S1253.167,1071.361,1251.752,1085.4Z" transform="translate(-1142.934 -1067.209)" fill="#000719"/>
        <path id="Path_1830" data-name="Path 1830" d="M1247.508,1116.888c-.058-1.416-1.66-3.52-3.955-1.557s.909,8.656,2.8,7.972S1247.568,1118.351,1247.508,1116.888Z" transform="translate(-1156.34 -1092.771)" fill="#eea886"/>
        <path id="Path_1831" data-name="Path 1831" d="M1192.407,1201.121c-3.023,5.594-6.638,4.91-7.713,9.379a34.834,34.834,0,0,0-.622,3.707,11.8,11.8,0,0,1-1.026,3.56,30.082,30.082,0,0,1-2.522,4.25,35.034,35.034,0,0,0-2.241,3.81c-.079.165-.165.324-.244.483a8.741,8.741,0,0,0-.531,2.547c-.269,1.722-.5,3.615-.611,4.983-.286,3.664-.641,6.772-.641,6.772s-26.68,5.71-41.445-.623c0,0,8.812-15.511,12.659-20.6s9.428-34.123,10.3-40.205,9.08-17.911,16.03-17.66l9.16,6.43s6.124,16.152,7.865,18.631S1195.473,1195.454,1192.407,1201.121Z" transform="translate(-1094.968 -1119.486)" fill="#214361"/>
        <path id="Path_1832" data-name="Path 1832" d="M1208.85,1345.625s-4.568,30.944-6.863,32.044c-3.029,1.45-63.063,2.235-69.545-5.944s-7.346-23.14-2.119-32.1S1208.85,1345.625,1208.85,1345.625Z" transform="translate(-1090.483 -1219.116)" fill="#214361"/>
        <path id="Path_1833" data-name="Path 1833" d="M1350.761,1460.011c-.917,2.067-1.773,3.993-2.521,5.674-2.812-9.044-2.581-24.16-2.581-24.16A85.911,85.911,0,0,0,1350.761,1460.011Z" transform="translate(-1214.842 -1278.678)" fill="#a77860"/>
        <path id="Path_1834" data-name="Path 1834" d="M1235.072,1259.909a11.8,11.8,0,0,1-1.026,3.561c-2.772.152-7.67.269-9.142-.7-2.082-1.374,4.837-20.867,4.837-20.867s-.458,12.573-.409,14.149S1235.072,1259.909,1235.072,1259.909Z" transform="translate(-1145.968 -1165.188)" fill="#14293b"/>
        <path id="Path_1835" data-name="Path 1835" d="M1206.363,1168.349c3.823.378,10.61,5.073,9.053,15.352s-1.448,16.3-2.232,18.962-2.114,15.011-2.114,15.011,7.013,2.434,11.185,4.051c7.97,3.089,20.658,4.522,20.658,4.522l-.438,7.405s-39.792.675-43.21-1.4-2.54-11.5-2.86-17.22.839-27.67.317-30.1S1196.394,1167.363,1206.363,1168.349Z" transform="translate(-1129.955 -1123.341)" fill="#eea987"/>
        <g id="Group_840" data-name="Group 840" transform="translate(178.713 112.662)">
          <g id="Group_839" data-name="Group 839" transform="translate(0)">
            <rect id="Rectangle_304" data-name="Rectangle 304" width="6.586" height="127.956" transform="translate(6.586 127.956) rotate(180)" fill="#c4b6af"/>
          </g>
        </g>
        <g id="Group_842" data-name="Group 842" transform="translate(174.894 238.439)">
          <g id="Group_841" data-name="Group 841" transform="translate(0 0)">
            <rect id="Rectangle_305" data-name="Rectangle 305" width="14.223" height="2.179" transform="translate(14.223 2.179) rotate(180)" fill="#c4b6af"/>
          </g>
        </g>
        <g id="Group_844" data-name="Group 844" transform="translate(22.502 172.167)">
          <g id="Group_843" data-name="Group 843" transform="translate(0 0)">
            <path id="Path_1836" data-name="Path 1836" d="M1114.612,1463.274l-16.382,68.3h-3.609l13.892-68.452Z" transform="translate(-1094.622 -1463.127)" fill="#343140"/>
          </g>
        </g>
        <g id="Group_846" data-name="Group 846" transform="translate(40.374 172.167)">
          <g id="Group_845" data-name="Group 845" transform="translate(0 0)">
            <path id="Path_1837" data-name="Path 1837" d="M1156.034,1463.274l-16.382,68.3h-3.608l13.892-68.452Z" transform="translate(-1136.044 -1463.127)" fill="#343140"/>
          </g>
        </g>
        <g id="Group_848" data-name="Group 848" transform="translate(89.27 172.167)">
          <g id="Group_847" data-name="Group 847" transform="translate(0 0)">
            <path id="Path_1838" data-name="Path 1838" d="M1249.37,1463.274l16.382,68.3h3.608l-13.892-68.452Z" transform="translate(-1249.37 -1463.127)" fill="#343140"/>
          </g>
        </g>
        <g id="Group_850" data-name="Group 850" transform="translate(14.791 82.693)">
          <g id="Group_849" data-name="Group 849" transform="translate(0 0)">
            <path id="Path_1839" data-name="Path 1839" d="M1166.731,1346.039h-70.588s-4.916-.113-8.208-14.677c-3.7-16.384-11.186-71.11-11.186-71.11s1.985-5.093,33.016-4.441l55.568,72.1S1167.229,1339.273,1166.731,1346.039Z" transform="translate(-1076.749 -1255.753)" fill="#4e4864"/>
          </g>
        </g>
        <g id="Group_852" data-name="Group 852" transform="translate(90.933 110.336)">
          <g id="Group_851" data-name="Group 851" transform="translate(0 0)">
            <rect id="Rectangle_306" data-name="Rectangle 306" width="182.145" height="4.653" transform="translate(182.145 4.653) rotate(180)" fill="#f8d6c5"/>
          </g>
        </g>
        <path id="Path_1840" data-name="Path 1840" d="M1244.5,1151.3s-.679.981-1.543,2.506a17.824,17.824,0,0,1-6.73-8.893,30.643,30.643,0,0,0,8.273,6.387Z" transform="translate(-1152.626 -1110.041)" fill="#a77860"/>
        <g id="Group_856" data-name="Group 856" transform="translate(157.739 79.908)">
          <g id="Group_855" data-name="Group 855" transform="translate(0 0)">
            <g id="Group_854" data-name="Group 854">
              <g id="Group_853" data-name="Group 853">
                <path id="Path_1841" data-name="Path 1841" d="M1420.915,1253.762s-3.748-3.194-4.207-3.642-2.037-.7-3.856-.812a8.385,8.385,0,0,0-4.394,1.019,1.919,1.919,0,0,0-.388,1.461,4.467,4.467,0,0,0,.552,1.784,2.626,2.626,0,0,0,.275,2.137,2.314,2.314,0,0,0,.572,2.1,1.934,1.934,0,0,0,.405,1.755,11.178,11.178,0,0,1,2.162.555,21.836,21.836,0,0,0,7.028.253S1422.672,1259.01,1420.915,1253.762Z" transform="translate(-1408.062 -1249.298)" fill="#eea886"/>
              </g>
            </g>
          </g>
        </g>
        <path id="Path_1842" data-name="Path 1842" d="M1414.756,1238.515v-1.268h-19.668v1.268l8.563,4.961.967,14.189-3.1.99v.482h6.805v-.482l-3.1-.99.967-14.189Z" transform="translate(-1242.947 -1162.538)" fill="#e6e3df"/>
        <path id="Path_1843" data-name="Path 1843" d="M1415.284,1239.57h-17.62l8.723,4.976h0Z" transform="translate(-1244.411 -1163.858)" fill="#e6617c"/>
        <path id="Path_1844" data-name="Path 1844" d="M1340.1,1271.351h-17.621l8.723,4.976v0Z" transform="translate(-1201.669 -1181.927)" fill="#e6617c"/>
        <g id="Group_860" data-name="Group 860" transform="translate(157.662 80.932)">
          <g id="Group_859" data-name="Group 859" transform="translate(0 0)">
            <g id="Group_858" data-name="Group 858">
              <g id="Group_857" data-name="Group 857">
                <path id="Path_1845" data-name="Path 1845" d="M1408.832,1251.682s4.615.406,4.727,1.3a1.408,1.408,0,0,1-.3,1.245l1.922,1.191s.97,1.6-.681,2.223c0,0,1.027,1.4-.646,1.765,0,0,.36,1.244-.377,1.579s-3.708-.073-3.708-.073-.623-.419-.405-1.755a2.272,2.272,0,0,1-.572-2.1,2.509,2.509,0,0,1-.275-2.137s-1.316-1.957-.164-3.245A4.194,4.194,0,0,1,1408.832,1251.682Z" transform="translate(-1407.883 -1251.671)" fill="#e3a080"/>
              </g>
            </g>
          </g>
        </g>
        <g id="Group_864" data-name="Group 864" transform="translate(161.975 80.698)">
          <g id="Group_863" data-name="Group 863" transform="translate(0 0)">
            <g id="Group_862" data-name="Group 862">
              <g id="Group_861" data-name="Group 861">
                <path id="Path_1846" data-name="Path 1846" d="M1422.144,1251.129a13.616,13.616,0,0,0-3.934,3.1,1.212,1.212,0,0,0-.236,1.255c.216.464,1.026.747,2.263.188a9.384,9.384,0,0,1,3.111-.577S1424.442,1254.06,1422.144,1251.129Z" transform="translate(-1417.881 -1251.129)" fill="#eea886"/>
              </g>
            </g>
          </g>
        </g>
        <path id="Path_1847" data-name="Path 1847" d="M1194.653,1188.415s-1.767-21.729,10.531-22.9c15.025-1.428,8.74,25.672,8.74,25.672Z" transform="translate(-1128.941 -1121.727)" fill="#214361"/>
      </g>
    </g>
  </g>
</svg>
 );
};

MyOrderSvg.propTypes = {};

export default MyOrderSvg;
