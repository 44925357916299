import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Grid, Typography } from "@material-ui/core";
import Thankyou from "../../Assets/Thank-you-Illustration.png";

const styles = () => ({
  mainTitle: {
    fontWeight: "600",
    fontSize: "40px",
    textAlign: "center",
    color: "#000000",
    margin: "0px",
  },
  subText: {
    fontSize: "16px",
    color: "#000000",
    textAlign: "center",
    fontWeight: "600",
    paddingBottom: "35px",
  },
  layout: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 45px",
  },
  feedbackRating: {
    width: "300px",
    paddingTop: "10% 0% 5%",
  },
  root: {
    padding: "0px !important",
  },
  subTextLayout: {
    padding: "0px 35px",
    textAlign: "center",
  },
  button: {
    marginTop: "20px",
    background: "#211b4e !important",
    padding: "5px 20px",
  },
  imagelayout: {
    display: "flex",
    justifyContent: "center",
  },
});

const ThankyouPopup = (props) => {
  const { classes } = props;

  return (
    <Grid container className={classes.layout}>
      <Grid item xs={12} sm={12} md={12} className={classes.imagelayout}>
        <img alt="logo" src={Thankyou} className={classes.feedbackRating} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} className={classes.subTextLayout}>
        <div className={classes.mainTitle}>
          <Typography align="center" variant="p">
            Thank You !
          </Typography>
        </div>
        <div className={classes.subText}>
          <Typography align="center" variant="p">
            Thank you for sharing your thoughts. We appreciate your feedback!
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};
export default withStyles(styles)(ThankyouPopup);
