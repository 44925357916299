import {
  GET_USERS,
  USER_LOADING,
  USER_ERROR,
  CREATE_USER_SUCCESS,
  UPDATE_USER,
  GET_SINGLE_USER,
  UPDATE_USER_STATUS,
} from '../Actions/types'

const initialState = {
  users: [],
  loading: false,
  error: {},
  userStatus: {},
  erStatus: {},
}
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        loading: false,
        users: action.payload,
      }

    case USER_ERROR:
      return {
        ...state,
        error: { success: false, errors: action.payload },
      }
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: { success: true, data: action.payload },
      }
    case UPDATE_USER_STATUS:
      return {
        ...state,
        userStatus: { success: true, data: action.payload },
      }

    case USER_LOADING:
      return {
        ...state,
        loading: true,
      }
    case UPDATE_USER:
      return {
        ...state,
        loading: false,
        user: { success: true, data: action.payload.data },
      }
    case GET_SINGLE_USER:
      return {
        ...state,
        loading: false,
        user: action.payload.data,
      }
    default:
      return state
  }
}
