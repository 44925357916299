import React from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { Typography, Grid } from "@material-ui/core";

const styles = () => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "12px",
  },
  mainTitle: {
    fontWeight: "600",
    fontSize: "20px",
    textAlign: "left",
    color: "#222222",
    lineHeight: "25px",
  },
  heading: {
    fontSize: "16px",
    color: "#222222",
    fontWeight: "500",
    margin: "4px 0px",
    marginBottom: "15px",
  },
  title: {
    fontSize: "13px",
    color: "#222222",
    fontWeight: "500",
    padding: "10px",
  },
  subTitle: {
    fontSize: "15px",
    color: "#222222",
    fontWeight: "500",
  },
  phone: {
    color: "#1592E6",
    fontWeight: "500",
  },
  description: {
    color: "#222222",
    fontSize: "13px",
    lineHeight: "18px",
  },
  desctext: {
    color: "#222222",
    fontSize: "14px",
    lineHeight: "18px",
  },
  generalDetails: {
    padding: "3% 0% 0%",
  },
  cardLayout: {
    border: "1px solid #EFEFEF",
    borderRadius: "5px",
    padding: "10px",
  },
  padding: {
    padding: "10px",
  },
});

function AboutAmassador(props) {
  const { classes } = props;

  return (
    <div className={classes.main}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Typography align="left" className={classes.desctext}>
            If you are a Rockstar into planning and organizing events and
            activities, here is an opportunity to showcase your
            skills.Let&apos;s join hands together in co-creating and bettering
            the community efforts.
            <br />
            <br />
            We would love to invite students from various colleges to be the
            community ambassadors and get themselves an exposure in our effort
            keep the community connected
            <br />
          </Typography>
          <Grid container spacing={2} className={classes.generalDetails}>
            <Grid item xs={12}>
              <Typography align="left" className={classes.subTitle}>
                What do we need?
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  Weekly 2 to 4 hours of support
                  <br />
                  for planning and execution
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  Your innovative ideas to
                  <br />
                  make events better
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  Efforts to bring more participation from the Community
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  Onground coordinator on the day of event
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2} className={classes.generalDetails}>
            <Grid item xs={12}>
              <Typography align="left" className={classes.subTitle}>
                What&apos;s in it for you?
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  Quarterly gift hampers from Devx
                  <br />
                  <br />
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  Recognition in Devx&apos;s platforms like Collab Station,
                  website and social media
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  Opportunity to showcase your talent by doing anchoring in
                  events
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  Quarterly one day outing with team of Community Managers
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.spaces.loading,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(AboutAmassador));
