import {
  GET_MERCHANDISE_ALL_ORDER,
  GET_MERCHANDISE_PRODUCT,
  MERCHANDISE_LOADING,
  ADD_MERCHANDISE_PRODUCT,
  GET_USER_MERCHANDISE_POINT,
  ADD_MERCHANDISE_POINT,
  GET_MERCHANDISE_POINT,
  UPDATE_MERCHANDISE_ORDER_STATUS,
  PLACE_ORDER,
} from "../Actions/types";

const initialState = {
  allProduct: [],
  addMerchandisePoint: {},
  loading: false,
  allMerchandiseOrder: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_MERCHANDISE_ALL_ORDER:
      return {
        ...state,
        loading: false,
        allMerchandiseOrder: action.payload && action.payload.data,
      };
    case GET_MERCHANDISE_PRODUCT:
      return {
        ...state,
        loading: false,
        allProduct: action.payload && action.payload.data,
      };
    case ADD_MERCHANDISE_PRODUCT:
      return {
        ...state,
        loading: false,
        addProduct: { success: true, data: action.payload.data },
      };
    case ADD_MERCHANDISE_POINT:
      return {
        ...state,
        loading: false,
        merchandisePointAdd: { success: true, data: action.payload.data },
      };
    case UPDATE_MERCHANDISE_ORDER_STATUS:
      return {
        ...state,
        orderStatus: { success: true, data: action.payload },
      };
    case GET_MERCHANDISE_POINT:
      return {
        ...state,
        loading: false,
        allMerchandisePoint: action.payload && action.payload.data,
      };
    case PLACE_ORDER:
      return {
        ...state,
        loading: false,
        addOrder: { success: true, data: action.payload.data },
      };
    case GET_USER_MERCHANDISE_POINT:
      return {
        ...state,
        loading: false,
        userRedeemPoint: action.payload && action.payload.data,
      };
    case MERCHANDISE_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
