/* eslint-disable camelcase */
import React from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { Typography, Grid } from "@material-ui/core";
import DataNotFound from "../../Components/Common/DataNotFound";

const styles = () => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "12px",
  },
  mainTitle: {
    fontWeight: "600",
    fontSize: "20px",
    textAlign: "left",
    color: "#222222",
    margin: "0px",
    lineHeight: "25px",
  },
  heading: {
    fontSize: "16px",
    color: "#222222",
    fontWeight: "500",
  },
  description: {
    color: "#222222",
    fontSize: "13px",
    lineHeight: "18px",
  },
  generalDetails: {
    padding: "3% 0% 0%",
    whiteSpace: "pre-line",
  },
  mainSlot: {
    display: "flex",
    justifyContent: "space-between",
  },
  areaSection: {
    display: "flex",
    flexDirection: "row",
  },
});

function ParkingPolicy(props) {
  const { classes, data } = props;
  if (data === undefined) {
    return (
      <div className={classes.noData}>
        <DataNotFound
          type="visitors"
          text="No results found!"
          subtext=""
          paddingTop="22%"
        />
      </div>
    );
  }

  return (
    <div className={classes.main}>
      <div className={classes.header}>
        <Typography align="left" variant="h1" className={classes.mainTitle}>
          Parking Policy
        </Typography>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Typography align="left" className={classes.heading}>
            General
          </Typography>
          <Grid className={classes.generalDetails}>
            <Typography align="left" className={classes.description}>
              {data && data.parking && data.parking.general_parking}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography align="left" className={classes.heading}>
            Car Parking
          </Typography>
          <Grid className={classes.generalDetails}>
            <Typography align="left" className={classes.description}>
              {data && data.parking && data.parking.car_parking}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography align="left" className={classes.heading}>
            Bike Parking
          </Typography>
          <Grid className={classes.generalDetails}>
            <Typography align="left" className={classes.description}>
              {data && data.parking && data.parking.bike_parking}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.spaces.loading,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(ParkingPolicy));
