import {
  GET_COWORKERS,
  COWORKER_ERROR,
  GET_COWORKERS_COMPANY,
  CREATE_COWORKER_SUCCESS,
  UPDATE_COWORKER_STATUS,
  COWORKER_LOADING,
  UPDATE_COWORKER,
  GET_SINGLE_COWORKER,
  GET_ALL_COWORKERS,
} from '../Actions/types'

const initialState = {
  coworkers: [],
  loading: false,
  coworker: {},
  noMoreData: false,
  coworkerStatus: {},
}
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_COWORKERS:
      return {
        ...state,
        loading: false,
        noMoreData: action.payload.data.length <= 0,
        coworkers: action.reset ? action.payload.data : action.payload.data,
      }
    case GET_ALL_COWORKERS:
      return {
        ...state,
        loading: false,
        all_coworkers: action.payload.data,
      }
    case GET_COWORKERS_COMPANY:
      return {
        ...state,
        loading: false,
        companyCoworkers: action.payload.data,
      }
    case COWORKER_ERROR:
      return {
        ...state,
        error: { success: false, errors: action.payload },
      }
    case CREATE_COWORKER_SUCCESS:
      return {
        ...state,
        loading: false,
        coworker: { success: true, data: action.payload },
      }
    case UPDATE_COWORKER_STATUS:
      return {
        ...state,
        coworkerStatus: { success: true, data: action.payload },
      }
    case COWORKER_LOADING:
      return {
        ...state,
        loading: true,
      }
    case UPDATE_COWORKER:
      return {
        ...state,
        loading: false,
        coworker: { success: true, data: action.payload.data },
      }
    case GET_SINGLE_COWORKER:
      return {
        ...state,
        loading: false,
        coworker: action.payload.data,
      }
    default:
      return state
  }
}
