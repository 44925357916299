/* eslint-disable no-param-reassign */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { CssBaseline, Button, Grid, Avatar } from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form } from "react-formik-ui";
import classNames from "classnames";
import CloseIcon from "@material-ui/icons/Close";
import { getUserData } from "../../Actions/EditProfileActions";
import avatar from "../../Assets/coworker3.png";
import InputField from "../../Components/Common/Form/InputField";
import UploadField from "../../Components/Common/Form/UploadField";
import { addPost, getPosts, editPost } from "../../Actions/postActions";
import { getEventDiscussion } from "../../Actions/eventActions";

const styles = () => ({
  main: {
    border: "1px solid #efefef",
    backgroundColor: "#fff",
  },
  addPost: {
    padding: "20px 19px 20px",
    boxShadow: "0 9px 10px rgba(0, 0, 0, 0.08), 0 4px 0px rgba(0,0,0,0.05)",
  },
  editPost: {
    padding: "20px 19px 20px",
  },
  avatar: {
    height: "35px",
    width: "35px",
    marginBottom: "9px",
    fontSize: "40px",
    borderRadius: "50px",
  },
  postbutton: {
    border: "1px solid #211b4e",
    lineHeight: "1.222",
    marginTop: "24px",
    color: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#f7f2ff !important",
    },
  },
  uploadPost: {
    padding: "0px !important",
  },
  postFooter: {
    textAlign: "right",
    padding: "0px 11px 0px",
  },
  imageIcon: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  imageDiv: {
    marginTop: "2.5%",
    paddingRight: "1%",
  },
  closeIcon: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "-2%",
  },
});
const AddPostSchema = Yup.object().shape({
  description: Yup.string().max(
    700,
    "Post length must be less than 700 characters"
  ),
});

class AddPost extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: true, deletedKey: [] };
  }

  componentDidMount() {
    // this.props.getUserData();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user !== prevState.user) {
      return { user: nextProps.user };
      // eslint-disable-next-line no-else-return
    } else if (nextProps.discussion !== prevState.discussion) {
      return {
        discussion: nextProps.discussion,
      };
    } else {
      return [];
    }
  }

  shouldComponentUpdate() {
    if (this.state.deletedKey.length > 0) {
      return false;
    }
    return true;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.discussion !== this.props.discussion) {
      this.setState({
        discussion: this.props.discussion,
      });
    }
    if (prevProps.post !== this.props.post) {
      if (this.props.type === "event") {
        this.props.getEventDiscussion(this.props.id);
      }
    }
  }

  handleClose = () => {
    this.setState({ open: false }, () => {
      this.props.handleEditClose();
    });
  };

  setDelImageUrl = (url) => {
    const keyArray = [];
    this.setState({ deletedKey: keyArray.push(url) });
  };

  render() {
    const { classes, postData, type } = this.props;
    const { user, deletedKey } = this.state;
    const getInitialValues = () => {
      if (postData) {
        return {
          description: postData.description,
          media: postData.media,
          deleted_key: deletedKey,
          club_id: "",
          type,
        };
      }
      return {
        description: "",
        media: "",
        club_id: "",
        type,
      };
    };

    return (
      <React.Fragment>
        <CssBaseline />
        {this.state.open && (
          <main>
            <div className={classes.main}>
              <Formik
                initialValues={getInitialValues()}
                enableReinitialize
                validationSchema={AddPostSchema}
                onSubmit={(values, { setErrors, setSubmitting, resetForm }) => {
                  if (this.props.type === "club") {
                    values.club_id = this.props.id;
                  } else {
                    values.event_id = this.props.id;
                  }
                  values.media = values.media !== "" ? values.media : [];

                  values.type = this.props.type
                    ? this.props.type
                    : localStorage.getItem("type");
                  // delete values.media;

                  if (postData) {
                    this.props.editPost(
                      postData._id,
                      values,
                      setErrors,
                      setSubmitting,
                      this.props.history
                    );
                  } else {
                    this.props.addPost(
                      values,
                      setErrors,
                      setSubmitting,
                      this.props.history
                    );
                    // this.props.getEventDiscussion(this.props.id);
                  }

                  resetForm(this.initialValues);
                }}
              >
                {({ values, setFieldValue, handleSubmit }) => (
                  <Form
                    className={classes.form}
                    onSubmit={handleSubmit}
                    encType="multipart/form-data"
                    autoComplete="off"
                  >
                    <div
                      className={classNames(
                        postData ? classes.editPost : classes.addPost
                      )}
                    >
                      {postData && (
                        <div className={classes.closeIcon}>
                          <Button>
                            <CloseIcon onClick={this.handleClose} />
                          </Button>
                        </div>
                      )}
                      <Grid container className={classes.root}>
                        {this.props.tab !== "images" && (
                          <Grid
                            item
                            sm={12}
                            lg={12}
                            className={classes.imageIcon}
                          >
                            <div className={classes.imageDiv}>
                              {user && user.profile_pic ? (
                                <img
                                  src={user.profile_pic}
                                  alt=""
                                  width="100%"
                                  height="200px"
                                  className={classes.avatar}
                                />
                              ) : (
                                <Avatar
                                  className={classes.avatar}
                                  src={avatar}
                                />
                              )}
                            </div>
                            <InputField
                              name="description"
                              placeholder="Write a post..."
                              multiline
                              rowsMax={5}
                              max={700}
                            />
                          </Grid>
                        )}
                        <Grid
                          item
                          sm={8}
                          lg={10}
                          className={classes.uploadPost}
                        >
                          {/* <label>Upload</label> */}
                          {postData ? (
                            <UploadField
                              label="Upload Photo"
                              name="media"
                              id="media"
                              onChange={setFieldValue}
                              value={values.media}
                              data={postData.media}
                              multiple
                              folder={
                                this.props.type === "club"
                                  ? "clubs/posts"
                                  : "event/posts"
                              }
                              delImageUrl={this.setDelImageUrl}
                            />
                          ) : (
                            <UploadField
                              label="Upload Photo"
                              name="media"
                              id="media"
                              onChange={setFieldValue}
                              value={values.media}
                              data=""
                              multiple
                              folder={
                                this.props.type === "club"
                                  ? "clubs/posts"
                                  : "event/posts"
                              }
                              delImageUrl={this.setDelImageUrl}
                            />
                          )}
                        </Grid>
                        <Grid
                          xs={8}
                          sm={4}
                          lg={2}
                          className={classes.postFooter}
                        >
                          <Button
                            className={classes.postbutton}
                            variant="outlined"
                            type="submit"
                            disabled={
                              values.description.trim() === "" && !values.media
                            }
                          >
                            {postData ? `Edit` : `Post`}
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </main>
        )}
      </React.Fragment>
    );
  }
}

AddPost.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    discussion: state.events.discussion,
    post: state.clubs.post,
  };
};

export default connect(mapStateToProps, {
  addPost,
  getPosts,
  getUserData,
  editPost,
  getEventDiscussion,
})(withStyles(styles)(AddPost));
