import React from "react";

const CompanySvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="300" height="400" viewBox="0 0 308.5 200">
    <defs>
      <linearGradient id="linear-gradient" x1="0.279" y1="0.22" x2="0.71" y2="0.876" gradientUnits="objectBoundingBox">
        <stop offset="0"/>
        <stop offset="1" stopOpacity="0.502"/>
      </linearGradient>
      <linearGradient id="linear-gradient-2" x1="0.216" y1="1.299" x2="0.727" y2="0.312" gradientUnits="objectBoundingBox">
        <stop offset="0"/>
        <stop offset="1" stopOpacity="0.102"/>
      </linearGradient>
      <linearGradient id="linear-gradient-3" x1="0.181" y1="-1.646" x2="0.181" y2="-2.032" gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#fe768a" stopOpacity="0"/>
        <stop offset="0.14" stopColor="#fe768a" stopOpacity="0.165"/>
        <stop offset="0.458" stopColor="#fe768a" stopOpacity="0.518"/>
        <stop offset="0.717" stopColor="#fe768a" stopOpacity="0.776"/>
        <stop offset="0.904" stopColor="#fe768a" stopOpacity="0.937"/>
        <stop offset="1" stopColor="#fe768a"/>
      </linearGradient>
      <linearGradient id="linear-gradient-4" x1="0.181" y1="-1.601" x2="0.181" y2="-2.093" gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#ffcd5e" stopOpacity="0"/>
        <stop offset="0.092" stopColor="#ffcd5e" stopOpacity="0.055"/>
        <stop offset="0.268" stopColor="#ffcd5e" stopOpacity="0.204"/>
        <stop offset="0.509" stopColor="#ffcd5e" stopOpacity="0.439"/>
        <stop offset="0.801" stopColor="#ffcd5e" stopOpacity="0.765"/>
        <stop offset="1" stopColor="#ffcd5e"/>
      </linearGradient>
    </defs>
    <g id="Group_719" data-name="Group 719" transform="translate(65.654 184.792)">
      <g id="Group_710" data-name="Group 710" transform="translate(12.959 -74.05)">
        <path id="Path_1566" data-name="Path 1566" d="M204.413,339.415l-7.063,4.078v-3.329l7.063-4.078Z" transform="translate(-176.885 -203.748)" fill="#c1c1cc"/>
        <path id="Path_1482" data-name="Path 1482" d="M150.774,211.934,152,206.382c-.5-2.5-1.009-4.663-1.009-4.663l-3.4,8.9Z" transform="translate(-183.648 -222.01)" fill="#e3e6ec"/>
        <path id="Path_1475" data-name="Path 1475" d="M133.268,278.041V230.364a6.147,6.147,0,0,0-3.074-5.323h0l-6.006,3.467V283.72h0a5.143,5.143,0,0,0,5.143,0l.985-.569A5.9,5.9,0,0,0,133.268,278.041Z" transform="translate(-186.829 -218.84)" fill="#4e4864"/>
        <g id="Group_643" data-name="Group 643" transform="translate(-71.104 -12.295)">
          <path id="Path_1476" data-name="Path 1476" d="M157.557,213.341l-6.62-3.817a4.179,4.179,0,0,0-.684-.4l-.011,0h0a4.028,4.028,0,0,0-3.712.183l-23.728,13.7a12.125,12.125,0,0,0-6.065,10.5v7.482a4.053,4.053,0,0,0,2.536,3.775h0l6.111,3.568,10.32-11.224,10.884-6.285a12.127,12.127,0,0,0,6.063-10.5v-1.645Z" transform="translate(-116.737 -208.758)" fill="#ff5252"/>
          <path id="Path_1462" data-name="Path 1462" d="M276.948,258.753Z" transform="translate(-110.415 -206.199)" opacity="0.2" fill="url(#linear-gradient)"/>
          <g id="Group_642" data-name="Group 642" transform="translate(101.665 -98.447)">
            <path id="Path_1463" data-name="Path 1463" d="M222.768,230.681V183a6.147,6.147,0,0,0-3.074-5.324h0l-6.006,3.467v55.213h0a5.15,5.15,0,0,0,5.145,0l.985-.569A5.9,5.9,0,0,0,222.768,230.681Z" transform="translate(-205.225 -114.535)" fill="#0d2a38"/>
            <g id="Group_641" data-name="Group 641" transform="translate(0 44.651)">
              <path id="Path_1464" data-name="Path 1464" d="M247.058,165.982l-6.62-3.818a4.183,4.183,0,0,0-.685-.394l-.01-.006h0a4.028,4.028,0,0,0-3.712.184l-23.728,13.7a12.129,12.129,0,0,0-6.065,10.5v7.481a4.056,4.056,0,0,0,2.535,3.776h0l6.111,3.567,10.32-11.224,10.883-6.284a12.128,12.128,0,0,0,6.063-10.5v-1.646Z" transform="translate(-206.238 -161.398)" fill="#ff5252"/>
              <path id="Path_1465" data-name="Path 1465" d="M218.442,200.7,242.171,187a12.126,12.126,0,0,0,6.062-10.5v-7.482a4.08,4.08,0,0,0-6.12-3.534l-23.728,13.7a12.125,12.125,0,0,0-6.065,10.5v7.482A4.081,4.081,0,0,0,218.442,200.7Z" transform="translate(-205.411 -160.919)" fill="#b33939"/>
            </g>
            <path id="Path_1466" data-name="Path 1466" d="M245.6,153.489s4.228,3.58,6.535,13.323,5.766,23.912,5.766,23.912l-8.4,2.8Z" transform="translate(-200.889 -117.823)" fill="#ff5a71"/>
            <path id="Path_1467" data-name="Path 1467" d="M218.235,237.687s-.438-28.557-1.1-37.783-2.635-27.679,0-33.171,9.665-8.567,14.937-11.2,15.378-6.81,17.575-1.758,8.127,61.118,8.127,61.118Z" transform="translate(-204.938 -118.107)" fill="#ff808c"/>
            <path id="Path_1468" data-name="Path 1468" d="M238.9,166.108s.224,4.268-3.3,4.515-6.895-2.224-8.954-5.684a32.255,32.255,0,0,1-2.9-6.161l2.851-3.471,15.05,2.548s4.224,3.789,3.029,8.253C243.777,169.474,238.9,166.108,238.9,166.108Z" transform="translate(-203.858 -117.575)" fill="#fff"/>
            <path id="Path_1469" data-name="Path 1469" d="M228.422,143.52l-2.452,15.453h0a20.35,20.35,0,0,0,10.369,7.968l2.262.769h0a19.612,19.612,0,0,0,2.747-10.011v-4.284Z" transform="translate(-203.556 -119.177)" fill="#e69c83"/>
            <path id="Path_1470" data-name="Path 1470" d="M241.6,211.357l-12.41-7.165s-2.474-32.031-5.6-36.15-6.784-3.954-7.757,1.976,1.827,39.718,3.309,42.183,18.616,10.373,18.616,10.373Z" transform="translate(-204.962 -116.228)" fill="#ff5a71"/>
            <path id="Path_1471" data-name="Path 1471" d="M235.039,124.768a7.025,7.025,0,0,0-7.688-2.306c-4.322,1.4-4.19,5.932-4.871,12.192s-.186,8-2,9.271-4.678-.382-4.678-.382,1.8,6.517,6.676,7.133,7.272-.776,9.309-6.752S235.039,124.768,235.039,124.768Z" transform="translate(-204.938 -122.09)" fill="#100f0f"/>
            <path id="Path_1472" data-name="Path 1472" d="M249.382,135.629v16.8c0,1.647-2.141,7.085-5.436,9.061s-8.8-2.006-11.312-4.683-5.078-8.5-5.078-8.5a3.844,3.844,0,0,1-3.792-2.883c-.906-3.048.082-4.366,1.4-4.614s12.026-5.189,12.026-5.189S249.773,135.4,249.382,135.629Z" transform="translate(-203.904 -120.263)" fill="#ffad91"/>
            <path id="Path_1473" data-name="Path 1473" d="M252.354,140.734s1.482-8.317-3.008-11.368c-4.8-3.263-4.562-5.6-12.31-5.932a12.418,12.418,0,0,0-12.768,10.051,36.81,36.81,0,0,0-.35,9.671,2.646,2.646,0,0,1,2.354.588,9.349,9.349,0,0,1,1.369,1.932l2.6,1.5.86-6.278,2.859-2.172s1.37,4.733,3.127,5.017S252.354,140.734,252.354,140.734Z" transform="translate(-203.858 -121.909)" fill="#23201f"/>
          </g>
          <path id="Path_1477" data-name="Path 1477" d="M128.941,248.056l23.729-13.7a12.128,12.128,0,0,0,6.063-10.5v-7.482a4.081,4.081,0,0,0-6.121-3.535l-23.728,13.7a12.126,12.126,0,0,0-6.063,10.5v7.482A4.08,4.08,0,0,0,128.941,248.056Z" transform="translate(-115.91 -208.279)" fill="#b33939"/>
          <path id="Path_1474" data-name="Path 1474" d="M350.106,394.188v-136.2L143.044,138.445v136.2Z" transform="translate(-113.162 -218.314)" fill="#e3e6ec"/>
        </g>
        <path id="Path_1479" data-name="Path 1479" d="M128.734,285.047s-.438-28.557-1.1-37.784-2.636-27.678,0-33.17,9.664-8.567,14.937-11.2,15.377-6.81,17.574-1.757,8.127,61.116,8.127,61.116Z" transform="translate(-186.541 -222.412)" fill="#211b4e"/>
        <path id="Path_1490" data-name="Path 1490" d="M267.729,197.423,277.3,191.9v13.6l-9.571,5.526Z" transform="translate(-167.32 -223.345)" fill="#ffcd5e"/>
        <path id="Path_1480" data-name="Path 1480" d="M136.469,204.86s5.163,5.6,6.81,9.194,9.995,23.692,9.995,23.692,1.428-22.516,1.1-26.03a99.974,99.974,0,0,0-1.867-10Z" transform="translate(-185.16 -222.01)" fill="#fff"/>
        <path id="Path_1481" data-name="Path 1481" d="M136.469,189.633V206.5l5.711,4.658,6.92,1.1,2.745-5.163v-6.15Z" transform="translate(-185.16 -223.652)" fill="#e69c83"/>
        <path id="Path_1483" data-name="Path 1483" d="M147.233,213.678l1.867-3.437-12.631-5.758a62.719,62.719,0,0,1,5.807,7.39Z" transform="translate(-185.16 -221.634)" fill="#e3e6ec"/>
        <path id="Path_1478" data-name="Path 1478" d="M156.1,200.849s4.228,3.58,6.535,13.323,5.767,23.911,5.767,23.911l-8.4,2.8Z" transform="translate(-182.492 -222.128)" fill="#191343"/>
        <path id="Path_1489" data-name="Path 1489" d="M152.617,394.188v-136.2L359.68,138.445v136.2Z" transform="translate(-182.965 -230.609)" fill="#c1c1cc"/>
        <path id="Path_1486" data-name="Path 1486" d="M145.945,212.989l1.867,2.384-1.368,8.039,5.782,14.368.569-15.485-2.775-7.361L151,210.87l-3.185-1.318Z" transform="translate(-183.872 -220.945)" fill="#b33939"/>
        <path id="Path_1484" data-name="Path 1484" d="M159.882,180.12v16.8c0,1.648-2.142,7.085-5.438,9.062s-10.123-.742-12.64-3.419-3.749-9.762-3.749-9.762a3.846,3.846,0,0,1-3.793-2.883c-.906-3.048.082-4.365,1.4-4.613s12.026-5.19,12.026-5.19S160.272,179.9,159.882,180.12Z" transform="translate(-185.507 -224.959)" fill="#ffad91"/>
        <path id="Path_1485" data-name="Path 1485" d="M135.106,187.293s2.225-.953,2.561.78c.275,1.423.436,5.461.436,5.461l2.056,1.441v-5.643a7.077,7.077,0,0,0,3.212-1.647c1.4-1.319,1.812-4.283,1.812-4.283a46.868,46.868,0,0,0,8.321,0c4.53-.412,7.166-.825,8.073-3.3S158.2,167.751,149.3,168s-14.292,5.931-15.342,9.72.3,10.295.3,10.295Z" transform="translate(-185.555 -226.593)" fill="#23201f"/>
        <path id="Path_1492" data-name="Path 1492" d="M276.423,273.562l-37.448-21.621,108.968-62.913,37.448,21.621Z" transform="translate(-171.228 -223.734)" fill="#fcfefe"/>
        <path id="Path_1488" data-name="Path 1488" d="M152.1,258.716l-12.411-7.164s-2.474-32.033-5.6-36.151-6.783-3.954-7.757,1.978,1.827,39.718,3.309,42.183,18.616,10.372,18.616,10.372Z" transform="translate(-186.565 -220.534)" fill="#191343"/>
        <path id="Path_1568" data-name="Path 1568" d="M204.413,358.85l-7.063,4.078V359.6l7.063-4.078Z" transform="translate(-176.885 -201.107)" fill="#c1c1cc"/>
        <path id="Path_1487" data-name="Path 1487" d="M110.127,277.039l98.095-56.8,37.448,21.621-98.095,56.8Z" transform="translate(-188.74 -219.493)" fill="#fcfefe"/>
        <g id="Group_645" data-name="Group 645" transform="translate(165.991 -9.657)">
          <path id="Path_1493" data-name="Path 1493" d="M325.907,215.519a9.741,9.741,0,0,0,3.936,2.851c2.208.7,6.049,1.469,7.391-.739s.329-4.239-2.188-5.767-7.68-.611-9.14.968C324.913,213.907,325.907,215.519,325.907,215.519Z" transform="translate(-325.465 -211.08)" fill="#d9dae4"/>
          <path id="Path_1494" data-name="Path 1494" d="M330.322,217.348a9.752,9.752,0,0,1-3.936-2.851,3,3,0,0,1-.376-1.958c-.033.032-.073.061-.1.094-.994,1.076,0,2.688,0,2.688a9.753,9.753,0,0,0,3.936,2.851c2.208.7,6.049,1.469,7.391-.739a4.4,4.4,0,0,0,.3-.619C336.066,218.732,332.449,218.023,330.322,217.348Z" transform="translate(-325.465 -210.882)" fill="#ada4b1"/>
        </g>
        <path id="Path_1491" data-name="Path 1491" d="M271.942,279.863l108.968-62.935v-8.865L271.942,270.975Z" transform="translate(-166.748 -221.148)" fill="#c1c1cc"/>
        <path id="Path_1556" data-name="Path 1556" d="M158.391,302.017V244.392l-10.874-5.876v57.627l-37.391-21.588v78.569l37.448,21.621v0l10.816,5.845,37.449,21.621V323.638Z" transform="translate(-188.74 -217.009)" fill="#d9dae4"/>
        <g id="Group_646" data-name="Group 646" transform="translate(134.279 -2.639)">
          <path id="Path_1495" data-name="Path 1495" d="M329.262,221.473l-4.395-2.538a2.935,2.935,0,0,0-2.936,0l-20.46,11.812-3.923,1.31v1.483l.008,0a.837.837,0,0,0,.419.709l5.024,2.9a2.935,2.935,0,0,0,2.936,0l23.955-13.831a.847.847,0,0,0,.428-.741v-1.456Z" transform="translate(-297.546 -217.085)" fill="#ada4b1"/>
          <path id="Path_1496" data-name="Path 1496" d="M297.974,231.484l23.956-13.832a2.941,2.941,0,0,1,2.936,0l5.023,2.9a.854.854,0,0,1,0,1.48l-23.955,13.831a2.935,2.935,0,0,1-2.936,0l-5.024-2.9A.854.854,0,0,1,297.974,231.484Z" transform="translate(-297.546 -217.259)" fill="#d9dae4"/>
        </g>
        <path id="Path_1552" data-name="Path 1552" d="M325.332,380.587l10.874-5.876v-136.2l-10.874,5.876Z" transform="translate(-159.492 -217.009)" fill="#c1c1cc"/>
        <g id="Group_663" data-name="Group 663" transform="translate(112.959 -63.878)">
          <path id="Path_1497" data-name="Path 1497" d="M329.286,165.536l-2.976-1.755-.007.008a2.543,2.543,0,0,0-2.708-.1l-42.557,24.571a4.518,4.518,0,0,0-2.26,3.914v34.278a2.537,2.537,0,0,0,1.182,2.151l-.007.009,3.082,1.8,2.372-3.328L325.2,204.115a4.518,4.518,0,0,0,2.26-3.913V168.1Z" transform="translate(-278.778 -163.346)" fill="#ada4b1"/>
          <g id="Group_647" data-name="Group 647" transform="translate(17.094 44.834)">
            <path id="Path_1498" data-name="Path 1498" d="M295.943,208.936v10.706a2.98,2.98,0,0,0,1.489,2.58l3.7,2.133a2.759,2.759,0,0,0,2.759,0l9.359-5.949v1.286a2.879,2.879,0,0,1-1.438,2.491l-7.329,4.236a3.935,3.935,0,0,1-3.942,0l-3.85-2.222a5.723,5.723,0,0,1-2.86-4.955v-11.83Z" transform="translate(-293.827 -202.191)" fill="#ada4b1"/>
            <path id="Path_1499" data-name="Path 1499" d="M312.52,218.213l-3.656-2.111a2.985,2.985,0,0,1-1.489-2.582v-10.7l-11.684,6.745v10.7a2.982,2.982,0,0,0,1.49,2.582l3.7,2.133a2.756,2.756,0,0,0,2.755,0l8.888-5.132A.945.945,0,0,0,312.52,218.213Z" transform="translate(-293.574 -202.816)" fill="#c1c1cc"/>
          </g>
          <path id="Path_1500" data-name="Path 1500" d="M281.292,193.65v31.792l48.683-28.108V167.4a2.578,2.578,0,0,0-3.865-2.233l-42.558,24.571A4.523,4.523,0,0,0,281.292,193.65Z" transform="translate(-278.436 -163.147)" fill="#4e4864"/>
          <path id="Path_1501" data-name="Path 1501" d="M283.481,193.323v25.418L326.4,194.212V168.543Z" transform="translate(-278.139 -162.64)" fill="#ffcd5e"/>
          <path id="Path_1502" data-name="Path 1502" d="M281.292,224.2a2.578,2.578,0,0,0,3.867,2.232l42.557-24.571a4.514,4.514,0,0,0,2.259-3.913v-5.7l-48.683,28.107Z" transform="translate(-278.436 -159.419)" fill="#d9dae4"/>
          <g id="Group_648" data-name="Group 648" transform="translate(10.725 25.31)">
            <path id="Path_1503" data-name="Path 1503" d="M292.305,205.346,288.22,207.7V196.863l4.085-2.359Z" transform="translate(-288.22 -184.422)" fill="#8bd368"/>
            <path id="Path_1504" data-name="Path 1504" d="M298.2,201.57l-4.087,2.359v-7.712l4.087-2.359Z" transform="translate(-287.419 -184.509)" fill="#fe768a"/>
            <path id="Path_1505" data-name="Path 1505" d="M304.154,198.782l-4.087,2.359V187.987l4.087-2.359Z" transform="translate(-286.609 -185.628)" fill="#34ace0"/>
          </g>
          <g id="Group_650" data-name="Group 650" transform="translate(33.42 21.8)">
            <g id="Group_649" data-name="Group 649">
              <path id="Path_1506" data-name="Path 1506" d="M320.152,183.436,308.2,190.322v-.9l11.953-6.886Z" transform="translate(-308.199 -182.539)" fill="#f7f1e3"/>
            </g>
          </g>
          <g id="Group_652" data-name="Group 652" transform="translate(33.42 13.41)">
            <g id="Group_651" data-name="Group 651">
              <path id="Path_1507" data-name="Path 1507" d="M317.908,177.4l-9.708,5.59v-2.245l9.708-5.59Z" transform="translate(-308.199 -175.152)" fill="#f7f1e3"/>
            </g>
          </g>
          <g id="Group_654" data-name="Group 654" transform="translate(33.42 16.44)">
            <g id="Group_653" data-name="Group 653">
              <path id="Path_1508" data-name="Path 1508" d="M320.152,178.717,308.2,185.6v-.9l11.953-6.886Z" transform="translate(-308.199 -177.82)" fill="#f7f1e3"/>
            </g>
          </g>
          <g id="Group_656" data-name="Group 656" transform="translate(33.42 19.092)">
            <g id="Group_655" data-name="Group 655">
              <path id="Path_1509" data-name="Path 1509" d="M320.152,181.052,308.2,187.938v-.9l11.953-6.886Z" transform="translate(-308.199 -180.154)" fill="#f7f1e3"/>
            </g>
          </g>
          <g id="Group_658" data-name="Group 658" transform="translate(33.42 24.452)">
            <g id="Group_657" data-name="Group 657">
              <path id="Path_1510" data-name="Path 1510" d="M320.152,185.77,308.2,192.657v-.9l11.953-6.886Z" transform="translate(-308.199 -184.873)" fill="#f7f1e3"/>
            </g>
          </g>
          <g id="Group_660" data-name="Group 660" transform="translate(33.42 27.018)">
            <g id="Group_659" data-name="Group 659">
              <path id="Path_1511" data-name="Path 1511" d="M320.152,188.03,308.2,194.916v-.9l11.953-6.886Z" transform="translate(-308.199 -187.132)" fill="#f7f1e3"/>
            </g>
          </g>
          <g id="Group_662" data-name="Group 662" transform="translate(33.42 29.67)">
            <g id="Group_661" data-name="Group 661">
              <path id="Path_1512" data-name="Path 1512" d="M320.152,190.364,308.2,197.251v-.9l11.953-6.886Z" transform="translate(-308.199 -189.466)" fill="#f7f1e3"/>
            </g>
          </g>
        </g>
        <path id="Path_1550" data-name="Path 1550" d="M143.044,139.148l10.874-5.876L360.981,252.82,350.106,258.7Z" transform="translate(-184.266 -231.312)" fill="#fcfefe"/>
        <path id="Path_1553" data-name="Path 1553" d="M360.981,139.148l-10.874-5.876L143.044,252.82l10.874,5.876Z" transform="translate(-184.266 -231.312)" fill="#fcfefe"/>
        <g id="Group_670" data-name="Group 670" transform="translate(144.939 -60.676)">
          <path id="Path_1513" data-name="Path 1513" d="M342.616,207.657l-17.634-11.541a12.125,12.125,0,0,0-4.378,5.77,14.061,14.061,0,0,0-.578,5.77l16.913,11.059Z" transform="translate(-305.164 -162.096)" fill="#443d7a"/>
          <path id="Path_1514" data-name="Path 1514" d="M326.7,196.7s-1.247-3.357-3.994-4.4a6.905,6.905,0,0,0-6.586.709c-1.761,1.106-2.493,1.529-2.493,1.529s-5.1-2.956-6.027-2.238.39,2.392,2.147,3.9,4.925,4.253,4.925,4.253-3.128.108-3.258,1.881,8.327,2.936,12.171,1.971S326.7,196.7,326.7,196.7Z" transform="translate(-306.883 -162.685)" fill="#ffad91"/>
          <path id="Path_1515" data-name="Path 1515" d="M330.175,181.08s-1.446,15.322.747,18.946,7.137,4.415,7.137,4.415l.836,6.291,13.976-4.742s-3.139-21.285-10.2-24.909A12.742,12.742,0,0,0,330.175,181.08Z" transform="translate(-303.836 -164.358)" fill="#ffad91"/>
          <path id="Path_1516" data-name="Path 1516" d="M335.73,196.5l-1.228-.709s.216-8.181-.553-9.938-4.063-1.574-4.722-4.577-.544-11.274.77-12.41a1.539,1.539,0,0,1,2.284,0s1.379-2.6,2.875-2.564a1.44,1.44,0,0,1,1.5,1.428,8.159,8.159,0,0,1,3.116-1.428c1.318-.11,2.2,1.428,2.2,1.428s1.867-2.1,3.186-1.434.44,2.569.44,2.569a18.937,18.937,0,0,1,12.96,9.737c4.394,8.458-.588,23.726-3.075,26.911s-9.775,3.734-12.3,1.756-5.162-8.017-5.162-8.017,2.526.543,2.526-3.462-1.986-6.533-3.465-6.2S335.73,196.5,335.73,196.5Z" transform="translate(-303.955 -166.166)" fill="#23201f"/>
          <g id="Group_664" data-name="Group 664" transform="translate(23.245 165.219)">
            <path id="Path_1517" data-name="Path 1517" d="M333.012,311.784,333,311.8a2.235,2.235,0,0,0-2.149.217,8.284,8.284,0,0,0-3.458,6.606,2.948,2.948,0,0,0,1.3,2.826l-.009.02,5.39,3.171,4.52-9.58Z" transform="translate(-327.396 -311.617)" fill="#0a202a"/>
            <path id="Path_1518" data-name="Path 1518" d="M339.112,317.456a8.281,8.281,0,0,1-3.458,6.606c-1.909,1.1-3.459-.067-3.459-2.615a8.284,8.284,0,0,1,3.459-6.606C337.563,313.738,339.112,314.909,339.112,317.456Z" transform="translate(-326.744 -311.236)" fill="#4e4864"/>
            <path id="Path_1519" data-name="Path 1519" d="M336.719,318.318a3.162,3.162,0,0,1-1.321,2.524c-.729.421-1.322-.025-1.322-1a3.167,3.167,0,0,1,1.322-2.525C336.129,316.9,336.719,317.343,336.719,318.318Z" transform="translate(-326.488 -310.864)" fill="#3b364b"/>
          </g>
          <g id="Group_665" data-name="Group 665" transform="translate(59.741 165.219)">
            <path id="Path_1520" data-name="Path 1520" d="M366.139,311.784l.007.018a2.234,2.234,0,0,1,2.149.217,8.278,8.278,0,0,1,3.458,6.606,2.948,2.948,0,0,1-1.3,2.826l.008.02-5.39,3.171-4.519-9.58Z" transform="translate(-359.385 -311.617)" fill="#3b364b"/>
            <path id="Path_1521" data-name="Path 1521" d="M359.525,317.456a8.282,8.282,0,0,0,3.459,6.606c1.909,1.1,3.458-.067,3.458-2.615a8.283,8.283,0,0,0-3.458-6.606C361.074,313.738,359.525,314.909,359.525,317.456Z" transform="translate(-359.525 -311.236)" fill="#4e4864"/>
            <path id="Path_1522" data-name="Path 1522" d="M361.406,318.318a3.166,3.166,0,0,0,1.322,2.524c.73.421,1.321-.025,1.321-1a3.163,3.163,0,0,0-1.321-2.525C362,316.9,361.406,317.343,361.406,318.318Z" transform="translate(-359.269 -310.864)" fill="#3b364b"/>
          </g>
          <g id="Group_666" data-name="Group 666" transform="translate(28.956 146.462)">
            <path id="Path_1523" data-name="Path 1523" d="M338.3,295.25l.008.018a1.982,1.982,0,0,1,1.907.192,7.356,7.356,0,0,1,3.072,5.868,2.62,2.62,0,0,1-1.155,2.509l.008.017-4.787,2.816-4.013-8.506Z" transform="translate(-332.299 -295.104)" fill="#3b364b"/>
            <path id="Path_1524" data-name="Path 1524" d="M332.423,300.288a7.358,7.358,0,0,0,3.07,5.868c1.7.978,3.071-.061,3.071-2.322a7.354,7.354,0,0,0-3.071-5.867C333.8,296.987,332.423,298.027,332.423,300.288Z" transform="translate(-332.423 -294.765)" fill="#4e4864"/>
            <path id="Path_1525" data-name="Path 1525" d="M334.093,301.054a2.815,2.815,0,0,0,1.173,2.242c.649.374,1.175-.023,1.175-.887a2.811,2.811,0,0,0-1.175-2.243C334.619,299.791,334.093,300.19,334.093,301.054Z" transform="translate(-332.196 -294.435)" fill="#3b364b"/>
          </g>
          <g id="Group_667" data-name="Group 667" transform="translate(56.387 146.462)">
            <path id="Path_1526" data-name="Path 1526" d="M361.559,295.25l-.008.018a1.984,1.984,0,0,0-1.908.192,7.355,7.355,0,0,0-3.07,5.868,2.618,2.618,0,0,0,1.155,2.509l-.009.017,4.787,2.816,4.013-8.506Z" transform="translate(-356.572 -295.104)" fill="#3b364b"/>
            <path id="Path_1527" data-name="Path 1527" d="M366.975,300.288a7.355,7.355,0,0,1-3.07,5.868c-1.7.978-3.07-.061-3.07-2.322a7.353,7.353,0,0,1,3.07-5.867C365.6,296.987,366.975,298.027,366.975,300.288Z" transform="translate(-355.993 -294.765)" fill="#4e4864"/>
            <path id="Path_1528" data-name="Path 1528" d="M364.851,301.054a2.815,2.815,0,0,1-1.173,2.242c-.647.374-1.173-.023-1.173-.887a2.813,2.813,0,0,1,1.173-2.243C364.325,299.791,364.851,300.19,364.851,301.054Z" transform="translate(-355.766 -294.435)" fill="#3b364b"/>
          </g>
          <path id="Path_1529" data-name="Path 1529" d="M332.477,295.288l.675,2.8,11.337,7.318,1.635-3.461Z" transform="translate(-303.46 -148.618)" fill="#3b364b"/>
          <path id="Path_1530" data-name="Path 1530" d="M351.491,301.3l-3.13-4.558-12.808-3.968-3.075,2.855,13.685,6.961Z" transform="translate(-303.46 -148.959)" fill="#4e4864"/>
          <path id="Path_1531" data-name="Path 1531" d="M367.552,295.288l-.674,2.8-11.337,7.318-1.635-3.461Z" transform="translate(-300.548 -148.618)" fill="#3b364b"/>
          <path id="Path_1532" data-name="Path 1532" d="M349.181,301.3l3.13-4.558,12.808-3.968,3.075,2.855-13.686,6.961Z" transform="translate(-301.19 -148.959)" fill="#4e4864"/>
          <path id="Path_1533" data-name="Path 1533" d="M327.874,314.678l1.4,3.1,4.241,3.075,18.561-12.191v-5.822Z" transform="translate(-304.086 -147.591)" fill="#3b364b"/>
          <path id="Path_1534" data-name="Path 1534" d="M347.381,300.28l-19.507,14.746,4.861,3.542,19.342-13.181V300.28Z" transform="translate(-304.086 -147.939)" fill="#4e4864"/>
          <path id="Path_1535" data-name="Path 1535" d="M373.383,314.678l-1.4,3.1-4.241,3.075-18.562-12.191v-5.822Z" transform="translate(-301.19 -147.591)" fill="#3b364b"/>
          <path id="Path_1536" data-name="Path 1536" d="M353.876,300.28l19.507,14.746-4.86,3.542-19.343-13.181V300.28Z" transform="translate(-301.19 -147.939)" fill="#4e4864"/>
          <path id="Path_1537" data-name="Path 1537" d="M349.818,309.73h0a5.327,5.327,0,0,1-5.327-5.327v-24.96h10.654V304.4A5.327,5.327,0,0,1,349.818,309.73Z" transform="translate(-301.827 -150.771)" fill="#4e4864"/>
          <path id="Path_1538" data-name="Path 1538" d="M330.506,293.653a8.25,8.25,0,0,1,2.232,7.68c-1.143,4.579-4.721,4.079-8.777,6.123s-11.865,8.616-15.506,7.581-.1-6.644,3.842-10.692,6.789-6.888,6.789-6.888Z" transform="translate(-306.932 -148.84)" fill="#11384a"/>
          <g id="Group_668" data-name="Group 668" transform="translate(19.818 93.833)">
            <path id="Path_1539" data-name="Path 1539" d="M374.36,266.831l-18.4-10.623a7.107,7.107,0,0,0-7.105,0l-16.522,9.538-7.952-.206v7.365h0a4.4,4.4,0,0,0,2.224,3.852l24.874,14.361a7.107,7.107,0,0,0,7.105,0l22.25-12.845a4.406,4.406,0,0,0,2.224-3.853v-7.364Z" transform="translate(-324.379 -247.891)" fill="#b33939"/>
            <path id="Path_1540" data-name="Path 1540" d="M326.6,262.57l22.25-12.846a7.107,7.107,0,0,1,7.105,0l24.874,14.361a4.448,4.448,0,0,1,0,7.7l-22.25,12.847a7.107,7.107,0,0,1-7.105,0L326.6,270.275A4.449,4.449,0,0,1,326.6,262.57Z" transform="translate(-324.379 -248.772)" fill="#ff5252"/>
          </g>
          <path id="Path_1541" data-name="Path 1541" d="M347.216,243.086l5.622,8.787s-19.383-3.63-27.489-6.3c-7.507-2.467-2.735,15.71-1.637,20.119s16.687,10.339,27.125,12.1a32.68,32.68,0,0,0,23.618-5.481c4.394-3.087,5.932-12.313,3.516-16.816S347.216,243.086,347.216,243.086Z" transform="translate(-304.992 -155.712)" fill="#3b364b"/>
          <path id="Path_1542" data-name="Path 1542" d="M331.649,270.808l-7.692-4.615,7.3,35.218a9.08,9.08,0,0,1-7.287,6.5c-2.892.574-6.651-1.906-6.651-1.906s-12.026-52.2-9.72-58.132,13.637-5.4,22.087-1.286,19.859,8.206,19.859,8.206Z" transform="translate(-306.881 -155.662)" fill="#3b364b"/>
          <path id="Path_1543" data-name="Path 1543" d="M321.708,229.79s-2.135-4.029-3.447-3.835-.678,1.914-.678,1.914-2.671-2.059-3.884-2.3a6.55,6.55,0,0,0-5.824,3.4c-1.311,2.622,1.8,5.291,4.416,6.7a10.84,10.84,0,0,0,4.934,1.343Z" transform="translate(-306.846 -158.098)" fill="#ffb142"/>
          <path id="Path_1544" data-name="Path 1544" d="M329.858,213.892l7.822-6.838,17.547-5.6s9.683,2.351,13.408,11.554,8.159,48.149,8.159,48.149-10.445,9.162-23.468,9.6c-10.54.356-14.3-6.979-13.671-12.5S332.607,218.974,329.858,213.892Z" transform="translate(-303.816 -161.37)" fill="#211b4e"/>
          <path id="Path_1545" data-name="Path 1545" d="M329.926,233.565s-1.577-17.111,1.035-20.19c3.831-4.519,10.245-.07,9.912,10.826-.178,5.841.066,21.644-4.194,22.179s-21.248-6.543-21.578-7.531,1.647-7.483,5.6-7.366A35.828,35.828,0,0,1,329.926,233.565Z" transform="translate(-305.826 -159.988)" fill="#443d7a"/>
          <g id="Group_669" data-name="Group 669" transform="translate(42.125 60.207)">
            <path id="Path_1546" data-name="Path 1546" d="M384.836,223.755l-6.62-3.818a4.184,4.184,0,0,0-.685-.394l-.009-.006h0a4.025,4.025,0,0,0-3.712.184l-23.729,13.7a12.128,12.128,0,0,0-6.062,10.5V251.4a4.053,4.053,0,0,0,2.534,3.776h0l6.11,3.568,10.321-11.225,10.883-6.284a12.127,12.127,0,0,0,6.065-10.5v-1.646Z" transform="translate(-344.017 -219.17)" fill="#ff5252"/>
            <path id="Path_1547" data-name="Path 1547" d="M356.22,258.469l23.728-13.7a12.125,12.125,0,0,0,6.065-10.5v-7.482a4.081,4.081,0,0,0-6.121-3.534l-23.729,13.7a12.128,12.128,0,0,0-6.062,10.5v7.482A4.081,4.081,0,0,0,356.22,258.469Z" transform="translate(-343.19 -218.691)" fill="#b33939"/>
          </g>
          <path id="Path_1548" data-name="Path 1548" d="M374.068,282.108V234.431A6.149,6.149,0,0,0,371,229.107h0l-6.006,3.468v55.213h0a5.15,5.15,0,0,0,5.145,0l.985-.569A5.9,5.9,0,0,0,374.068,282.108Z" transform="translate(-299.041 -157.612)" fill="#4e4864"/>
          <path id="Path_1549" data-name="Path 1549" d="M356.232,202.195l-1.045-2.1-18.938,4.131-1.345,4.841Z" transform="translate(-303.13 -161.555)" fill="#443d7a"/>
        </g>
        <path id="Path_1555" data-name="Path 1555" d="M276.423,263.448l60.381,81V377.2l-97.829-56.635Z" transform="translate(-171.228 -213.62)" opacity="0.2" fill="url(#linear-gradient-2)"/>
        <path id="Path_1551" data-name="Path 1551" d="M325.332,380.587l10.874-5.876v-136.2l-10.874,5.876Z" transform="translate(-159.492 -217.009)" fill="#aaa69d"/>
        <path id="Path_1557" data-name="Path 1557" d="M185.585,320.249l98.094-56.8v8.884l-65.969,38.25v69.669l-32.125,18.565Z" transform="translate(-178.484 -213.62)" fill="#c1c1cc"/>
        <g id="Group_672" data-name="Group 672" transform="translate(21.319 61.241)">
          <path id="Path_1561" data-name="Path 1561" d="M229.818,277.708l-4.395-2.538a2.941,2.941,0,0,0-2.936,0l-20.46,11.812-3.925,1.311v1.482l.008,0a.841.841,0,0,0,.42.71l5.024,2.9a2.941,2.941,0,0,0,2.936,0l23.956-13.832a.844.844,0,0,0,.427-.739v-1.456Z" transform="translate(-198.102 -273.321)" fill="#ada4b1"/>
          <path id="Path_1562" data-name="Path 1562" d="M198.53,287.72l23.956-13.831a2.935,2.935,0,0,1,2.936,0l5.024,2.9a.853.853,0,0,1,0,1.479L206.491,292.1a2.935,2.935,0,0,1-2.936,0l-5.024-2.9A.854.854,0,0,1,198.53,287.72Z" transform="translate(-198.102 -273.495)" fill="#d9dae4"/>
        </g>
        <g id="Group_671" data-name="Group 671" transform="translate(53.033 54.222)">
          <path id="Path_1559" data-name="Path 1559" d="M226.464,271.755a9.736,9.736,0,0,0,3.935,2.85c2.208.7,6.048,1.469,7.391-.738s.329-4.239-2.187-5.767-7.681-.611-9.14.967C225.469,270.143,226.464,271.755,226.464,271.755Z" transform="translate(-226.022 -267.316)" fill="#d9dae4"/>
          <path id="Path_1560" data-name="Path 1560" d="M230.878,273.585a9.75,9.75,0,0,1-3.936-2.852,3,3,0,0,1-.376-1.958c-.033.033-.073.063-.1.094-1,1.077,0,2.689,0,2.689a9.736,9.736,0,0,0,3.935,2.85c2.208.7,6.048,1.469,7.391-.738a4.462,4.462,0,0,0,.3-.619C236.622,274.969,233,274.259,230.878,273.585Z" transform="translate(-226.022 -267.118)" fill="#ada4b1"/>
        </g>
        <path id="Path_1637" data-name="Path 1637" d="M167.926,294.308l16.515-9.535,20.121,11.616-16.515,9.535Z" transform="translate(-180.884 -210.722)" fill="#fdfcf9"/>
        <path id="Path_1558" data-name="Path 1558" d="M152.617,301.215l98.095-56.8,37.448,21.621-98.094,56.8Z" transform="translate(-182.965 -216.207)" fill="#fcfefe"/>
        <path id="Path_1563" data-name="Path 1563" d="M189.554,323.582l23.66-13.661V326.2l-23.66,13.66Z" transform="translate(-177.945 -207.304)" fill="#d9dae4"/>
        <path id="Path_1554" data-name="Path 1554" d="M238.975,193.831v136.2l98.094,56.635v-136.2Z" transform="translate(-171.228 -223.082)" fill="#e3e6ec"/>
        <path id="Path_1565" data-name="Path 1565" d="M189.554,342.915l23.66-13.661v16.283L189.554,359.2Z" transform="translate(-177.945 -204.677)" fill="#d9dae4"/>
        <path id="Path_1564" data-name="Path 1564" d="M204.413,319.979l-7.063,4.078v-3.329l7.063-4.078Z" transform="translate(-176.885 -206.39)" fill="#c1c1cc"/>
        <path id="Path_1569" data-name="Path 1569" d="M229.842,221.772l-2.976-1.755-.007.008a2.541,2.541,0,0,0-2.708-.1L181.593,244.5a4.517,4.517,0,0,0-2.259,3.913V282.69a2.534,2.534,0,0,0,1.182,2.15l-.007.01,3.082,1.8,2.372-3.327,39.8-22.975a4.521,4.521,0,0,0,2.259-3.914V224.331Z" transform="translate(-179.334 -219.582)" fill="#ada4b1"/>
        <g id="Group_673" data-name="Group 673" transform="translate(17.094 44.834)">
          <path id="Path_1570" data-name="Path 1570" d="M196.5,265.171v10.707a2.975,2.975,0,0,0,1.49,2.578l3.694,2.134a2.764,2.764,0,0,0,2.759,0l9.358-5.95v1.287a2.875,2.875,0,0,1-1.436,2.49l-7.33,4.238a3.941,3.941,0,0,1-3.942,0l-3.85-2.222a5.724,5.724,0,0,1-2.86-4.955V263.648Z" transform="translate(-194.383 -258.427)" fill="#ada4b1"/>
          <path id="Path_1571" data-name="Path 1571" d="M213.076,274.448l-3.655-2.111a2.982,2.982,0,0,1-1.491-2.581v-10.7L196.246,265.8v10.7a2.979,2.979,0,0,0,1.491,2.582l3.7,2.134a2.756,2.756,0,0,0,2.755,0l8.888-5.132A.946.946,0,0,0,213.076,274.448Z" transform="translate(-194.13 -259.052)" fill="#c1c1cc"/>
        </g>
        <path id="Path_1572" data-name="Path 1572" d="M181.848,249.885v31.793l48.684-28.108V223.634a2.578,2.578,0,0,0-3.867-2.233l-42.558,24.571A4.519,4.519,0,0,0,181.848,249.885Z" transform="translate(-178.992 -219.382)" fill="#4e4864"/>
        <path id="Path_1573" data-name="Path 1573" d="M184.038,249.559v25.418l42.92-24.528V224.779Z" transform="translate(-178.695 -218.876)" fill="#968bff"/>
        <path id="Path_1574" data-name="Path 1574" d="M181.848,280.436a2.578,2.578,0,0,0,3.867,2.232L228.273,258.1a4.519,4.519,0,0,0,2.259-3.913v-5.7l-48.684,28.108Z" transform="translate(-178.992 -215.654)" fill="#d9dae4"/>
        <g id="Group_709" data-name="Group 709" transform="translate(7.589 12.698)">
          <path id="Path_1575" data-name="Path 1575" d="M208.55,242.294s-2.79,7.836-6.489,9.354c-1.789.735-4.673-1.363-7.077.32-2.658,1.862-3.53,4.488-5.361,6.06-2.444,2.1-3.425.7-3.425.7v6.81l22.352-12.9Z" transform="translate(-185.99 -229.193)" fill="url(#linear-gradient-3)"/>
          <path id="Path_1576" data-name="Path 1576" d="M208.55,247.474c-3.079,1.357-4.447-6.867-6.429-3.619s-3.425,12.313-6.625,14.28-4.6-3.415-5.768-4.12-3.53,1.557-3.53,1.557v9.854l22.352-12.9Z" transform="translate(-185.99 -229.085)" fill="url(#linear-gradient-4)"/>
          <path id="Path_1577" data-name="Path 1577" d="M186.2,255.571s2.358-2.263,3.53-1.557,2.568,6.087,5.768,4.12,4.641-11.031,6.625-14.28,3.35,4.976,6.429,3.619" transform="translate(-185.99 -229.085)" fill="none" stroke="#cc8e35" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.366"/>
          <g id="Group_674" data-name="Group 674" transform="translate(22.353 9.047)">
            <path id="Path_1578" data-name="Path 1578" d="M205.9,255.185a.208.208,0,0,1-.208-.209V238.933a.208.208,0,1,1,.416,0v16.044A.208.208,0,0,1,205.9,255.185Z" transform="translate(-205.693 -238.725)" fill="#f7f1e3"/>
          </g>
          <g id="Group_675" data-name="Group 675" transform="translate(0 21.952)">
            <path id="Path_1579" data-name="Path 1579" d="M186.223,266.546a.208.208,0,0,1-.208-.208V250.295a.208.208,0,1,1,.416,0v16.044A.208.208,0,0,1,186.223,266.546Z" transform="translate(-186.015 -250.086)" fill="#f7f1e3"/>
          </g>
          <g id="Group_676" data-name="Group 676" transform="translate(0 12.893)">
            <path id="Path_1580" data-name="Path 1580" d="M187.28,259.417a1.82,1.82,0,0,1-.237-.015,1.454,1.454,0,0,1-.992-.535.208.208,0,0,1,.341-.24,1.051,1.051,0,0,0,.717.363,3.283,3.283,0,0,0,2.4-1.1,12.79,12.79,0,0,0,1.982-2.385,14.034,14.034,0,0,1,3.4-3.686c1.477-1.038,3.114-.716,4.555-.432a4.82,4.82,0,0,0,2.561.089c3.574-1.466,6.345-9.154,6.372-9.233a.209.209,0,0,1,.393.14c-.116.324-2.864,7.941-6.608,9.478a5.044,5.044,0,0,1-2.8-.065c-1.427-.281-2.9-.57-4.237.362a13.507,13.507,0,0,0-3.294,3.587,13.031,13.031,0,0,1-2.051,2.459A4.1,4.1,0,0,1,187.28,259.417Z" transform="translate(-186.015 -242.111)" fill="#f7f1e3"/>
          </g>
          <g id="Group_679" data-name="Group 679" transform="translate(6.264 12.663)">
            <g id="Group_677" data-name="Group 677" transform="translate(0 5.672)">
              <path id="Path_1581" data-name="Path 1581" d="M191.737,263.362a.209.209,0,0,1-.208-.209V247.11a.208.208,0,1,1,.416,0v16.044A.209.209,0,0,1,191.737,263.362Z" transform="translate(-191.529 -246.902)" fill="#f7f1e3"/>
            </g>
            <g id="Group_678" data-name="Group 678" transform="translate(9.824)">
              <path id="Path_1582" data-name="Path 1582" d="M200.386,258.368a.208.208,0,0,1-.208-.208V242.117a.208.208,0,0,1,.416,0V258.16A.208.208,0,0,1,200.386,258.368Z" transform="translate(-200.178 -241.909)" fill="#f7f1e3"/>
            </g>
          </g>
          <g id="Group_684" data-name="Group 684" transform="translate(2.642 20.569)">
            <g id="Group_681" data-name="Group 681">
              <g id="Group_680" data-name="Group 680">
                <path id="Path_1583" data-name="Path 1583" d="M189.752,250.071l-1.412.815v-1.2l1.412-.815Z" transform="translate(-188.34 -248.869)" fill="#f7f1e3"/>
              </g>
            </g>
            <g id="Group_683" data-name="Group 683" transform="translate(0 1.24)">
              <g id="Group_682" data-name="Group 682">
                <path id="Path_1584" data-name="Path 1584" d="M190.954,250.442l-2.614,1.509v-.481l2.614-1.509Z" transform="translate(-188.34 -249.961)" fill="#f7f1e3"/>
              </g>
            </g>
          </g>
          <g id="Group_689" data-name="Group 689" transform="translate(10.633 17.964)">
            <g id="Group_686" data-name="Group 686">
              <g id="Group_685" data-name="Group 685">
                <path id="Path_1585" data-name="Path 1585" d="M196.788,247.777l-1.412.815v-1.2l1.412-.815Z" transform="translate(-195.375 -246.575)" fill="#f7f1e3"/>
              </g>
            </g>
            <g id="Group_688" data-name="Group 688" transform="translate(0 1.241)">
              <g id="Group_687" data-name="Group 687">
                <path id="Path_1586" data-name="Path 1586" d="M197.99,248.148l-2.614,1.509v-.48l2.614-1.509Z" transform="translate(-195.375 -247.667)" fill="#f7f1e3"/>
              </g>
            </g>
          </g>
          <g id="Group_694" data-name="Group 694" transform="translate(18.505 9.574)">
            <g id="Group_691" data-name="Group 691">
              <g id="Group_690" data-name="Group 690">
                <path id="Path_1587" data-name="Path 1587" d="M203.717,240.391l-1.412.815V240l1.412-.815Z" transform="translate(-202.305 -239.189)" fill="#f7f1e3"/>
              </g>
            </g>
            <g id="Group_693" data-name="Group 693" transform="translate(0 1.241)">
              <g id="Group_692" data-name="Group 692">
                <path id="Path_1588" data-name="Path 1588" d="M204.919,240.762l-2.614,1.509v-.48l2.614-1.509Z" transform="translate(-202.305 -240.281)" fill="#f7f1e3"/>
              </g>
            </g>
          </g>
          <g id="Group_696" data-name="Group 696" transform="translate(26.267)">
            <g id="Group_695" data-name="Group 695">
              <path id="Path_1589" data-name="Path 1589" d="M218.848,233.005l-9.708,5.59v-2.244l9.708-5.591Z" transform="translate(-209.139 -230.761)" fill="#f7f1e3"/>
            </g>
          </g>
          <g id="Group_698" data-name="Group 698" transform="translate(26.267 3.03)">
            <g id="Group_697" data-name="Group 697">
              <path id="Path_1590" data-name="Path 1590" d="M221.092,234.326l-11.953,6.886v-.9l11.953-6.886Z" transform="translate(-209.139 -233.428)" fill="#f7f1e3"/>
            </g>
          </g>
          <g id="Group_700" data-name="Group 700" transform="translate(26.267 5.682)">
            <g id="Group_699" data-name="Group 699">
              <path id="Path_1591" data-name="Path 1591" d="M221.092,236.661l-11.953,6.886v-.9l11.953-6.886Z" transform="translate(-209.139 -235.763)" fill="#f7f1e3"/>
            </g>
          </g>
          <g id="Group_702" data-name="Group 702" transform="translate(26.267 8.39)">
            <g id="Group_701" data-name="Group 701">
              <path id="Path_1592" data-name="Path 1592" d="M221.092,239.045l-11.953,6.886v-.9l11.953-6.886Z" transform="translate(-209.139 -238.147)" fill="#f7f1e3"/>
            </g>
          </g>
          <g id="Group_704" data-name="Group 704" transform="translate(26.267 11.042)">
            <g id="Group_703" data-name="Group 703">
              <path id="Path_1593" data-name="Path 1593" d="M221.092,241.379l-11.953,6.886v-.9l11.953-6.886Z" transform="translate(-209.139 -240.481)" fill="#f7f1e3"/>
            </g>
          </g>
          <g id="Group_706" data-name="Group 706" transform="translate(26.267 13.608)">
            <g id="Group_705" data-name="Group 705">
              <path id="Path_1594" data-name="Path 1594" d="M221.092,243.639l-11.953,6.886v-.9l11.953-6.886Z" transform="translate(-209.139 -242.741)" fill="#f7f1e3"/>
            </g>
          </g>
          <g id="Group_708" data-name="Group 708" transform="translate(26.267 16.26)">
            <g id="Group_707" data-name="Group 707">
              <path id="Path_1595" data-name="Path 1595" d="M221.092,245.973l-11.953,6.886v-.9l11.953-6.886Z" transform="translate(-209.139 -245.075)" fill="#f7f1e3"/>
            </g>
          </g>
        </g>
        <path id="Path_1567" data-name="Path 1567" d="M189.554,362.525l23.786-13.733v16.369l-23.786,13.733Z" transform="translate(-177.945 -202.021)" fill="#d9dae4"/>
      </g>
      <path id="Path_1634" data-name="Path 1634" d="M159.5,253.877l9.571-5.526v13.6l-9.571,5.525Z" transform="translate(-169.071 -289.722)" fill="#ffcd5e"/>
      <g id="Group_717" data-name="Group 717" transform="translate(44.027 -69.581)">
        <path id="Path_1596" data-name="Path 1596" d="M241.674,272.323s-1.779-3.893-5.238-4.862-7.926-.494-8.117.283,1.267,1.384,2.452,1.514,2.466.4,2.847,1.253-2.781.593-3.308,1.119-.527.988,1.252,1.713,4.807,2.366,6.192,2.3S241.674,272.323,241.674,272.323Z" transform="translate(-203.747 -217.618)" fill="#ffad91"/>
        <path id="Path_1597" data-name="Path 1597" d="M252.2,275.082s11.117-21.32,14.95-20.051,6.138,6.87,4,12.308-10.756,18.865-15.016,19.4-20.405-6.056-20.735-7.044,1.647-7.483,5.6-7.366S252.2,275.082,252.2,275.082Z" transform="translate(-202.788 -219.241)" fill="#8f81ff"/>
        <path id="Path_1598" data-name="Path 1598" d="M244.356,342.98a6,6,0,0,1,3.089,4.931c.11,3.432-2.493,3.792-4.926,6.028s-6.588,8.386-9.343,8.386-1.391-4.63.562-8.246,3.383-6.169,3.383-6.169Z" transform="translate(-203.297 -207.28)" fill="#e3e6ec"/>
        <g id="Group_711" data-name="Group 711" transform="translate(22.528 162.907)">
          <path id="Path_1599" data-name="Path 1599" d="M232.132,367.1l-.008.02a2.234,2.234,0,0,0-2.149.216,8.282,8.282,0,0,0-3.457,6.608,2.949,2.949,0,0,0,1.3,2.826l-.009.019,5.39,3.171,4.52-9.579Z" transform="translate(-226.518 -366.932)" fill="#3b364b"/>
          <path id="Path_1600" data-name="Path 1600" d="M238.233,372.769a8.285,8.285,0,0,1-3.458,6.608c-1.909,1.1-3.459-.068-3.459-2.615a8.286,8.286,0,0,1,3.459-6.608C236.684,369.053,238.233,370.223,238.233,372.769Z" transform="translate(-225.866 -366.55)" fill="#4e4864"/>
          <path id="Path_1601" data-name="Path 1601" d="M235.84,373.63a3.166,3.166,0,0,1-1.321,2.526c-.73.421-1.322-.026-1.322-1a3.164,3.164,0,0,1,1.322-2.525C235.248,372.211,235.84,372.658,235.84,373.63Z" transform="translate(-225.61 -366.179)" fill="#3b364b"/>
        </g>
        <g id="Group_712" data-name="Group 712" transform="translate(59.023 162.907)">
          <path id="Path_1602" data-name="Path 1602" d="M265.259,367.1l.009.02a2.232,2.232,0,0,1,2.148.216,8.283,8.283,0,0,1,3.458,6.608,2.949,2.949,0,0,1-1.3,2.826l.008.019-5.39,3.171-4.519-9.579Z" transform="translate(-258.506 -366.932)" fill="#3b364b"/>
          <path id="Path_1603" data-name="Path 1603" d="M258.646,372.769a8.288,8.288,0,0,0,3.458,6.608c1.911,1.1,3.459-.068,3.459-2.615a8.282,8.282,0,0,0-3.459-6.608C260.2,369.053,258.646,370.223,258.646,372.769Z" transform="translate(-258.646 -366.55)" fill="#4e4864"/>
          <path id="Path_1604" data-name="Path 1604" d="M260.527,373.63a3.166,3.166,0,0,0,1.321,2.526c.732.421,1.322-.026,1.322-1a3.161,3.161,0,0,0-1.322-2.525C261.119,372.211,260.527,372.658,260.527,373.63Z" transform="translate(-258.39 -366.179)" fill="#3b364b"/>
        </g>
        <g id="Group_713" data-name="Group 713" transform="translate(28.237 144.15)">
          <path id="Path_1605" data-name="Path 1605" d="M237.417,350.566l.008.018a1.981,1.981,0,0,1,1.907.191,7.356,7.356,0,0,1,3.07,5.867,2.616,2.616,0,0,1-1.154,2.509l.008.018-4.787,2.817-4.013-8.507Z" transform="translate(-231.42 -350.419)" fill="#3b364b"/>
          <path id="Path_1606" data-name="Path 1606" d="M231.544,355.6a7.353,7.353,0,0,0,3.07,5.868c1.7.979,3.072-.06,3.072-2.322a7.354,7.354,0,0,0-3.072-5.867C232.918,352.3,231.544,353.341,231.544,355.6Z" transform="translate(-231.544 -350.08)" fill="#4e4864"/>
          <path id="Path_1607" data-name="Path 1607" d="M233.214,356.368a2.812,2.812,0,0,0,1.173,2.243c.649.374,1.175-.024,1.175-.887a2.809,2.809,0,0,0-1.175-2.243C233.739,355.106,233.214,355.5,233.214,356.368Z" transform="translate(-231.317 -349.75)" fill="#3b364b"/>
        </g>
        <g id="Group_714" data-name="Group 714" transform="translate(55.668 144.15)">
          <path id="Path_1608" data-name="Path 1608" d="M260.678,350.566l-.007.018a1.983,1.983,0,0,0-1.908.191,7.356,7.356,0,0,0-3.07,5.867,2.616,2.616,0,0,0,1.154,2.509l-.008.018,4.787,2.817,4.013-8.507Z" transform="translate(-255.693 -350.419)" fill="#3b364b"/>
          <path id="Path_1609" data-name="Path 1609" d="M266.1,355.6a7.353,7.353,0,0,1-3.07,5.868c-1.7.979-3.07-.06-3.07-2.322a7.353,7.353,0,0,1,3.07-5.867C264.721,352.3,266.1,353.341,266.1,355.6Z" transform="translate(-255.114 -350.08)" fill="#4e4864"/>
          <path id="Path_1610" data-name="Path 1610" d="M263.972,356.368a2.815,2.815,0,0,1-1.173,2.243c-.649.374-1.173-.024-1.173-.887a2.809,2.809,0,0,1,1.173-2.243C263.446,355.106,263.972,355.5,263.972,356.368Z" transform="translate(-254.887 -349.75)" fill="#3b364b"/>
        </g>
        <path id="Path_1611" data-name="Path 1611" d="M231.6,350.6l.676,2.8,11.336,7.318,1.636-3.461Z" transform="translate(-203.3 -206.244)" fill="#3b364b"/>
        <path id="Path_1612" data-name="Path 1612" d="M250.611,356.615l-3.13-4.558-12.808-3.968-3.075,2.855,13.686,6.961Z" transform="translate(-203.3 -206.586)" fill="#4e4864"/>
        <path id="Path_1613" data-name="Path 1613" d="M266.673,350.6,266,353.4l-11.336,7.318-1.635-3.461Z" transform="translate(-200.387 -206.244)" fill="#3b364b"/>
        <path id="Path_1614" data-name="Path 1614" d="M248.3,356.615l3.13-4.558,12.808-3.968,3.075,2.855L253.629,357.9Z" transform="translate(-201.029 -206.586)" fill="#4e4864"/>
        <path id="Path_1615" data-name="Path 1615" d="M227,369.992l1.4,3.1,4.24,3.075L251.2,363.978v-5.821Z" transform="translate(-203.925 -205.218)" fill="#3b364b"/>
        <path id="Path_1616" data-name="Path 1616" d="M246.5,355.595,227,370.34l4.86,3.542L251.2,360.7v-5.107Z" transform="translate(-203.925 -205.566)" fill="#4e4864"/>
        <path id="Path_1617" data-name="Path 1617" d="M272.5,369.992l-1.4,3.1-4.24,3.075L248.3,363.978v-5.821Z" transform="translate(-201.029 -205.218)" fill="#3b364b"/>
        <path id="Path_1618" data-name="Path 1618" d="M253,355.595,272.5,370.34l-4.86,3.542L248.3,360.7v-5.107Z" transform="translate(-201.029 -205.566)" fill="#4e4864"/>
        <path id="Path_1619" data-name="Path 1619" d="M248.939,365.044h0a5.328,5.328,0,0,1-5.327-5.327V334.757h10.654v24.959A5.327,5.327,0,0,1,248.939,365.044Z" transform="translate(-201.667 -208.398)" fill="#4e4864"/>
        <path id="Path_1620" data-name="Path 1620" d="M238.5,297.317s-5.489-1.675-10.542,0-5.4,7.165-3.578,14.085S238.5,354.98,238.5,354.98s5.1-.52,6.85-2.623-2.978-13.3-3.486-19.382S238.5,297.317,238.5,297.317Z" transform="translate(-204.418 -213.587)" fill="#e69c83"/>
        <path id="Path_1621" data-name="Path 1621" d="M237.389,348.572a6,6,0,0,1,3.089,4.93c.11,3.432-2.493,3.793-4.926,6.029s-6.589,8.386-9.344,8.386-1.39-4.63.565-8.246,3.382-6.17,3.382-6.17Z" transform="translate(-204.243 -206.52)" fill="#e3e6ec"/>
        <path id="Path_1622" data-name="Path 1622" d="M231.3,302.236s-5.489-1.674-10.543,0-5.4,7.166-3.578,14.086S231.3,359.9,231.3,359.9s5.1-.52,6.848-2.623-2.977-13.3-3.485-19.382S231.3,302.236,231.3,302.236Z" transform="translate(-205.395 -212.919)" fill="#ffad91"/>
        <g id="Group_715" data-name="Group 715" transform="translate(19.1 91.52)">
          <path id="Path_1623" data-name="Path 1623" d="M273.48,322.146l-18.4-10.624a7.109,7.109,0,0,0-7.106,0l-16.521,9.539-7.952-.206v7.364h0a4.406,4.406,0,0,0,2.224,3.853L250.6,346.434a7.109,7.109,0,0,0,7.106,0l22.25-12.846a4.409,4.409,0,0,0,2.224-3.853v-7.364Z" transform="translate(-223.5 -303.205)" fill="#b33939"/>
          <path id="Path_1624" data-name="Path 1624" d="M225.724,317.884l22.249-12.845a7.1,7.1,0,0,1,7.106,0l24.874,14.36a4.448,4.448,0,0,1,0,7.7L257.7,339.95a7.107,7.107,0,0,1-7.105,0l-24.874-14.361A4.448,4.448,0,0,1,225.724,317.884Z" transform="translate(-223.5 -304.086)" fill="#ff5252"/>
        </g>
        <path id="Path_1625" data-name="Path 1625" d="M246.089,298.572l-7.26-1.437-9.862,5.693c-6.844,3.952-7.481,13.947-6.383,18.355s16.688,10.339,27.125,12.1a32.675,32.675,0,0,0,23.618-5.478c4.394-3.089,5.932-12.314,3.516-16.817S246.089,298.572,246.089,298.572Z" transform="translate(-204.583 -213.511)" fill="#8f81ff"/>
        <path id="Path_1626" data-name="Path 1626" d="M268.793,258.7s6.91,4.286,6.668,9.421.035,22.443.007,29.385,1.31,20.783-1.556,23.418-17.363,8.348-27.029,3.735-9.752-10.367-9.488-14.41,5.12-4.262,5.436-10.632-2.91-8.129-2.5-14.5-2.329-10.7,2.5-15.531S268.793,258.7,268.793,258.7Z" transform="translate(-202.521 -218.734)" fill="#9d96ff"/>
        <path id="Path_1627" data-name="Path 1627" d="M220.829,285.1s-2.137-4.029-3.447-3.834-.679,1.913-.679,1.913-2.669-2.059-3.883-2.3a6.554,6.554,0,0,0-5.825,3.4c-1.31,2.622,1.8,5.291,4.418,6.7a10.829,10.829,0,0,0,4.934,1.342Z" transform="translate(-206.685 -215.725)" fill="#ffad91"/>
        <path id="Path_1628" data-name="Path 1628" d="M231.026,289.252s11.117-21.321,14.95-20.052,6.14,6.871,4,12.308-10.756,18.865-15.016,19.4-20.407-6.054-20.736-7.044,1.647-7.483,5.6-7.365S231.026,289.252,231.026,289.252Z" transform="translate(-205.666 -217.315)" fill="#8f81ff"/>
        <path id="Path_1629" data-name="Path 1629" d="M240.968,238.257s-1.879,16.942,1.439,19.606a21.984,21.984,0,0,0,6.3,3.621S247.064,238.257,240.968,238.257Z" transform="translate(-202.098 -221.513)" fill="#ffad91"/>
        <path id="Path_1630" data-name="Path 1630" d="M245.234,226.422s2.416-3.954,9.226-2.636,15.158,7.688,16.475,16.475-1.3,15.494,1.244,22.572,4.853,9.226,2.216,16.146-14.828,13.674-19.77,10.873-6.92-6.754-8.238-13.674.324-18.026-.329-22.078a90.09,90.09,0,0,0-3.338-11.532s-4.734.165-4.57-6.261S241.774,226.586,245.234,226.422Z" transform="translate(-202.41 -223.516)" fill="#23201f"/>
        <g id="Group_716" data-name="Group 716" transform="translate(41.406 57.895)">
          <path id="Path_1631" data-name="Path 1631" d="M283.957,279.07l-6.62-3.818a4.1,4.1,0,0,0-.684-.394l-.011-.007h0a4.024,4.024,0,0,0-3.712.183l-23.728,13.7a12.127,12.127,0,0,0-6.065,10.5v7.483a4.054,4.054,0,0,0,2.535,3.775h0l6.11,3.568L262.1,302.837l10.883-6.284a12.126,12.126,0,0,0,6.063-10.5V284.4Z" transform="translate(-243.137 -274.484)" fill="#ff5252"/>
          <path id="Path_1632" data-name="Path 1632" d="M255.341,313.783l23.728-13.7a12.125,12.125,0,0,0,6.065-10.5V282.1a4.081,4.081,0,0,0-6.121-3.534l-23.728,13.7a12.125,12.125,0,0,0-6.065,10.5v7.483A4.081,4.081,0,0,0,255.341,313.783Z" transform="translate(-242.31 -274.005)" fill="#b33939"/>
        </g>
        <path id="Path_1633" data-name="Path 1633" d="M273.189,337.422V289.745a6.146,6.146,0,0,0-3.074-5.323h0l-6,3.467V343.1h0a5.142,5.142,0,0,0,5.143,0l.986-.569A5.9,5.9,0,0,0,273.189,337.422Z" transform="translate(-198.881 -215.239)" fill="#4e4864"/>
      </g>
      <path id="Path_1635" data-name="Path 1635" d="M165.577,264.577l9.571-5.525v13.6l-9.571,5.525Z" transform="translate(-168.245 -288.268)" fill="#968bff"/>
      <g id="Group_718" data-name="Group 718" transform="translate(4.753 2.524)">
        <path id="Path_1638" data-name="Path 1638" d="M172.11,293.836l11.851-6.842.953.55-11.851,6.842Z" transform="translate(-172.11 -286.994)" fill="#84817a"/>
        <path id="Path_1639" data-name="Path 1639" d="M174.438,295.114l9.844-5.68.953.55-9.844,5.68Z" transform="translate(-171.794 -286.662)" fill="#84817a"/>
        <path id="Path_1640" data-name="Path 1640" d="M176.631,296.283l9.844-5.68.953.55-9.843,5.68Z" transform="translate(-171.496 -286.504)" fill="#84817a"/>
        <path id="Path_1641" data-name="Path 1641" d="M178.839,297.562l9.844-5.68.953.55-9.844,5.68Z" transform="translate(-171.196 -286.33)" fill="#84817a"/>
        <path id="Path_1642" data-name="Path 1642" d="M181.016,298.825l9.844-5.68.953.55-9.844,5.68Z" transform="translate(-170.9 -286.158)" fill="#84817a"/>
        <path id="Path_1643" data-name="Path 1643" d="M183.209,300.088l9.844-5.68.953.551-9.844,5.68Z" transform="translate(-170.602 -285.986)" fill="#84817a"/>
        <path id="Path_1644" data-name="Path 1644" d="M185.339,301.367l9.844-5.68.953.55-9.844,5.68Z" transform="translate(-170.313 -285.813)" fill="#84817a"/>
      </g>
      <path id="Path_1636" data-name="Path 1636" d="M227.12,225.383l9.571-5.526v13.6l-9.571,5.526Z" transform="translate(-159.881 -293.595)" fill="#fe768a"/>
    </g>
  </svg>
  
      );
};

CompanySvg.propTypes = {};

export default CompanySvg;
