/* eslint-disable no-shadow */
/* eslint-disable camelcase */
import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  Grid,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CreateIcon from "@material-ui/icons/Create";
import { CircularLoader } from "../../Components/Common/Spinner";
import { checkPermission } from "../../Utils/checkPermission";
import InputField from "../../Components/Common/Form/InputField";
import {
  addIssueCategory,
  getAllIssueCategory,
  getSingleIssueCategory,
  editIssueCategory,
} from "../../Actions/issueCategoryActions";
import ScreenHeader from "../../Components/Common/ScreenHeader";
import IssueDepartmentDropdown from "../../Components/Common/Dropdowns/IssueDepartmentDropdown";

const $quoteSym = " ";
const styles = () => ({
  root: {
    backgroundColor: "#fff",
  },
  table: {
    minWidth: 650,
    marginTop: "20px",
  },
  addIssueCategory: {
    width: "100%",
    textAlign: "right",
  },
  category_form_modal: {
    width: "500px",
  },
  form_modal: {
    minWidth: "500px",
  },
  main: {
    padding: "24px",
  },
  editbutton: {
    borderRadius: "50px",
  },
  editicon: {
    fontSize: "16px",
    marginRight: "6px",
  },
  modalHeader: {
    paddingBottom: "5px",
    fontSize: "20px",
    fontWeight: "600",
    position: "relative",
    "&:after": {
      top: "46px",
      left: "24px",
      width: "24px",
      borderBottom: "3px solid #fb0",
      content: `'${$quoteSym}'`,
      position: "absolute",
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  pageHeader: {
    margin: "0px -19px",
    width: "100%",
  },
  button: {
    color: "#fff",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  dialogContent: {
    marginTop: "16px",
  },
  addButton: {
    color: "#fff",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  cancelButton: {
    marginRight: "15px",
    backgroundColor: "#dddddd",
    color: "#151039",
    "&:hover": {
      color: "#151039",
      backgroundColor: "#d1d1d1!important",
    },
  },
  root1: {
    "&:nth-child(even)": {
      backgroundColor: "#FCFDFE",
    },
  },
  backButton: {
    margin: "0% 0% 2% 0%",
  },
  modalFooter: {
    padding: "8px 0px",
    display: "flex",
    justifyContent: "flex-end",
  },
});
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F8FAFF",
    color: theme.palette.common.black,
    borderBottom: "none",
    padding: "8px 40px 8px 16px",
  },
  body: {
    fontSize: 14,
    textTransform: "capitalize",
    borderBottom: "none",
  },
}))(TableCell);
const IssueCategorySchema = Yup.object().shape({
  helpdeskdepartment: Yup.string().required("This field is required."),
  helpdeskcategory_name: Yup.string()
    .trim()
    .required("This field is required."),
});

class IssueCategory extends Component {
  constructor(props) {
    super(props);
    this.state = { all_issue_category: [], id: "" };
  }

  state = {
    modalOpen: false,
  };

  componentDidMount() {
    this.props.getAllIssueCategory();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.all_issue_category !== prevState.all_issue_category) {
      return { all_issue_category: nextProps.all_issue_category };
    }
    return [];
  }

  componentDidUpdate(prevProps) {
    if (prevProps.all_issue_category !== this.props.all_issue_category) {
      let all_issue_category = this.props.all_issue_category || [];
      all_issue_category = all_issue_category.map((x, id) => {
        return Object.assign({}, x, {
          idx: id + 1,
        });
      });
      this.setState({
        all_issue_category,
        // rowData: all_issue_category
      });
    }

    if (
      this.props.issue_category !== prevProps.issue_category &&
      this.props.issue_category.success
    ) {
      this.props.getAllIssueCategory();
    }

    if (prevProps.loading !== this.props.loading) {
      this.props.setLoading(this.props.loading);
      this.setState({
        loading: this.props.loading,
      });
    }
  }

  handleClickOpen = () => {
    this.setState({
      modalOpen: true,
      id: "",
    });
  };

  handleClickModalOpen = (id) => {
    this.setState({
      id,
      modalOpen: true,
    });
    this.props.getSingleIssueCategory(id);
  };

  handleClose = () => {
    this.setState(
      {
        modalOpen: false,
      },
      () => this.props.getAllIssueCategory()
    );
  };

  onBackButton = (e) => {
    e.preventDefault();
    this.props.history.goBack();
  };

  render() {
    const { classes, single_issue_category } = this.props;
    const { loading, id } = this.state;
    if (loading) {
      return <CircularLoader />;
    }

    const getInitialValues = (id) => {
      if (id) {
        return {
          helpdeskcategory_name: single_issue_category.helpdeskcategory_name,
          helpdeskdepartment:
            single_issue_category.helpdeskdepartment &&
            single_issue_category.helpdeskdepartment[0],
        };
      }
      return {
        helpdeskcategory_name: "",
        helpdeskdepartment: [],
      };
    };

    return (
      <div className={classes.main}>
        <Button
          color="grey"
          onClick={() => this.props.history.push("/settings")}
          className={classes.backButton}
          variant="contained"
          size="small"
        >
          Back
        </Button>
        <div className={classes.header}>
          <div className={classes.pageHeader}>
            <ScreenHeader type="All Issue Categories" />
          </div>
          <Dialog
            open={this.state.modalOpen}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
          >
            <Formik
              initialValues={getInitialValues(id)}
              validationSchema={IssueCategorySchema}
              onSubmit={(values, { setErrors, setSubmitting }) => {
                const val2 = values.helpdeskdepartment
                  ? values.helpdeskdepartment.value
                  : "";
                // eslint-disable-next-line no-param-reassign
                values.helpdeskdepartment = val2;
                if (id) {
                  this.props.editIssueCategory(
                    id,
                    values,
                    setErrors,
                    setSubmitting
                  );
                } else {
                  this.props.addIssueCategory(
                    values,
                    setErrors,
                    this.props.history,
                    setSubmitting
                  );
                }

                this.handleClose();
                this.props.getAllIssueCategory();
              }}
            >
              {({
                setFieldValue,
                values,
                setFieldTouched,
                errors,
                touched,
              }) => (
                <Form className={classes.category_form_modal}>
                  <DialogTitle
                    id="issue-category-dialog-title"
                    className={classes.modalHeader}
                  >
                    {id ? "Edit Issue Category" : "Add Issue Category"}
                  </DialogTitle>
                  <DialogContent className={classes.dialogContent}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <IssueDepartmentDropdown
                          values={values}
                          onChange={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          errors={errors}
                          touched={touched}
                          name="helpdeskdepartment"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <InputField
                          name="helpdeskcategory_name"
                          label="Issue Category"
                          placeholder="Enter Issue Category"
                        />
                      </Grid>
                    </Grid>
                    <Grid className={classes.modalFooter}>
                      <Button
                        onClick={this.handleClose}
                        color="primary"
                        className={classes.cancelButton}
                      >
                        Cancel
                      </Button>
                      <Button
                        color="primary"
                        className={classes.addButton}
                        type="submit"
                        variant="contained"
                      >
                        {id ? "Edit" : "Add"}
                      </Button>
                    </Grid>
                  </DialogContent>

                  {loading && <CircularLoader />}
                </Form>
              )}
            </Formik>
          </Dialog>
          <div className={classes.addIssueCategory}>
            {checkPermission("HELPDESK_CATEGORY_CREATE") && (
              <Button
                onClick={this.handleClickOpen}
                className={classes.button}
                size="medium"
                variant="contained"
                color="primary"
              >
                Add Issue Category
              </Button>
            )}
          </div>
        </div>
        <div className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <StyledTableCell>Sr. No.</StyledTableCell>
                <StyledTableCell align="left">Department</StyledTableCell>
                <StyledTableCell align="left">Category</StyledTableCell>
                <StyledTableCell align="left" />
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.all_issue_category &&
                this.state.all_issue_category.map((item, id) => {
                  return (
                    <TableRow className={classes.root1}>
                      <StyledTableCell component="th" scope="row">
                        {id + 1}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {item &&
                          item.helpdeskdepartment &&
                          item.helpdeskdepartment.map((helpdeskdepartment) => {
                            return helpdeskdepartment.helpdeskdepartment_name;
                          })}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {item.helpdeskcategory_name}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <Button
                          variant="outlined"
                          size="small"
                          color="primary"
                          className={classes.editbutton}
                          onClick={() => this.handleClickModalOpen(item._id)}
                        >
                          <CreateIcon
                            size="small"
                            className={classes.editicon}
                          />
                          Edit
                        </Button>
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    all_issue_category: state.all_issue_category.all_issue_category,
    loading: state.all_issue_category.loading,
    issue_category: state.all_issue_category.issue_category,
    single_issue_category: state.all_issue_category.single_issue_category,
    edit_issue_category: state.all_issue_category.edit_issue_category,
  };
};
export default connect(mapStateToProps, {
  addIssueCategory,
  getAllIssueCategory,
  getSingleIssueCategory,
  editIssueCategory,
})(withStyles(styles)(withRouter(IssueCategory)));
