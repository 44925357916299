import React from "react";
import { TimePicker } from "material-ui-pickers";
import { withStyles } from "@material-ui/core/styles";

const styles = () => ({
  input: {
    fontSize: "inherit",
    paddingLeft: "7px"
  }
});

class TimePickerField extends React.Component {
  handleChange = value => {
    // this is going to call setFieldValue and manually update values.topcis
    this.props.onChange(this.props.name, value);
  };

  render() {
    const { classes, ...other } = this.props;
    return (
      <TimePicker
        {...other}
        name={this.props.name}
        label={this.props.label}
        onChange={this.handleChange}
        value={this.props.value}
        ampm
        InputProps={{ className: classes.input }}
        style={{ width: "100%", top: "1px" }}
      />
    );
  }
}

export default withStyles(styles)(TimePickerField);
