import React, { useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Grid, Typography, Button } from "@material-ui/core";
import CommunityTeam from "../../Assets/Community-Team-Support.png";
import StarStyle from "../Companies/StarStyle";
import AnswerOptions from "./AnswerOptions";

const styles = (theme) => ({
  subText: {
    margin: "10px 0px 10px",
    fontSize: "22px",
    color: "#000000",
    textAlign: "center",
    fontWeight: "600",
  },
  layout: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 15px",
  },
  thankyouSvg: {
    width: "130px",
    height: "auto",
  },
  root: {
    padding: "0px !important",
  },
  button: {
    marginTop: "20px",
    background: "#211b4e !important",
    padding: "5px 20px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      borderRadius: "0px",
      cursor: "pointer",
      position: "fixed",
      bottom: "0",
      "&:disabled": {
        backgroundColor: "lightgrey !important",
        border: "1px solid lightgrey",
        position: "fixed",
        bottom: "0",
      },
    },
    "&:disabled": {
      backgroundColor: "lightgrey !important",
      border: "1px solid lightgrey",
    },
  },
  queAnsSection: {
    padding: "8px",
    border: "1px solid #efefef",
    borderRadius: "5px",
    margin: "10px 0px",
    boxShadow: "0 9px 10px rgba(0, 0, 0, 0.1), 0 1px 0px rgba(0,0,0,0.05)",
  },
  description: {
    fontSize: "14px",
    lineHeight: "20px",
    color: "#626262",
    fontWeight: "normal",
  },
  buttonLayout: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: "15px",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "0px",
    },
  },
});

const CommunityTeamPopup = (props) => {
  const { classes, questionsData, answerArray } = props;
  const [answerSheet, setAnswerSheet] = useState(answerArray);
  // const [setAnswer] = useState("");
  // const [setRating] = useState("");

  const [checkLength] = useState(questionsData.length + answerArray.length);
  const setSelectedStar = (val) => {
    // const ratingVal = val.rating;
    return val.rating;
    // setRating(ratingVal);
  };

  const openCommunityModal = () => {
    props.openCommunityModal("overall");
  };

  const setAnswerData = (questionId, ans, type) => {
    // setAnswer(ans);
    const answerdataArray = {
      process_id: props.processId,
      question_id: questionId,
      answer: type === "option" ? ans : "",
      rating: type === "rating" ? ans : 0,
      type,
    };
    const index = answerSheet.findIndex((x) => x.question_id === questionId);

    if (index === -1) {
      answerSheet.push(answerdataArray);
    }
    // answerSheet.push(answerdataArray);
    setAnswerSheet(answerSheet);
    props.answerData(answerSheet);
  };

  return (
    <Grid>
      <Grid container className={classes.layout}>
        <Grid item xs={12} sm={12} md={12} className={classes.layout}>
          <img alt="logo" src={CommunityTeam} className={classes.thankyouSvg} />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Typography align="center" variant="h1" className={classes.subText}>
            Community Team Support
          </Typography>
        </Grid>
        <Grid item lg={12} xs={12}>
          {questionsData &&
            questionsData.map((que, count) =>
              que.type === "option" ? (
                <Grid className={classes.queAnsSection} key={que._id}>
                  <Grid>
                    <Typography
                      className={classes.description}
                      variant="subtitle1"
                      component="p"
                    >
                      Q{count + 1}. {que.questions}
                    </Typography>
                    <Grid style={{ display: "flex" }}>
                      <AnswerOptions
                        questionId={que._id}
                        setAnswerData={setAnswerData}
                      ></AnswerOptions>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid className={classes.queAnsSection}>
                  <Grid>
                    <Typography
                      className={classes.description}
                      variant="subtitle1"
                      component="p"
                    >
                      Q{count + 1}. {que.questions}
                    </Typography>
                    <StarStyle
                      eventFeedback
                      setSelectedStar={setSelectedStar}
                      processId={props.processId}
                      questionId={que._id}
                      setRatingAnswer={setAnswerData}
                    />
                  </Grid>
                </Grid>
              )
            )}
        </Grid>
      </Grid>

      <Grid className={classes.buttonLayout}>
        <Button
          className={classes.button}
          type="button"
          variant="contained"
          color="primary"
          size="small"
          disabled={answerSheet.length < checkLength}
          onClick={openCommunityModal}
        >
          Next
        </Button>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(CommunityTeamPopup);
