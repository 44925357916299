import React from "react";

const SecurityVisitor = () => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="800px"  viewBox="0 0 871.475 502">
  <defs>
    <clipPath id="clip-path">
      <path id="Path_166" data-name="Path 166" d="M842.525,420.354c-38.824,2.428-79.369.361-118.033.361H182.041c-28.95,0-58.122-.009-86.956,1.046-10.929.4-22.257.378-32.339-2.232-7.053-1.827-13.71-5.246-16.789-9.618-3.961-5.626,3.126-10.186.634-15.752-1.5-3.34-7.484-5.66-12.15-7.125-13.626-4.279-21.847-14.905-13.346-23.51,3.887-3.936,10.548-6.615,17.618-8.176,14.76-3.261,31.574-3.223,47.052-3.2,14.636.022,29.226,1.165,43.842,1.165H800.876c7.662,0,15.327.031,22.989,0,8.358-.034,21.241-.474,24,6.067,2.581,6.123-10.365,10.839-11.231,16.735-2.428,16.518,51.814,9.637,52.479,28.445.15,4.253-4.413,8.174-10.254,10.484s-12.815,3.269-19.679,3.962Q850.916,419.839,842.525,420.354Z" transform="translate(-17.639 -352.143)" fill="#a1e3dd"/>
    </clipPath>
    <linearGradient id="linear-gradient" x1="0.137" y1="-0.391" x2="0.769" y2="1.476" gradientUnits="objectBoundingBox">
      <stop offset="0.265" stopColor="#371b85" stopOpacity="0.914"/>
      <stop offset="0.555" stopColor="#f26d5a" stopOpacity="0"/>
    </linearGradient>
    <linearGradient id="linear-gradient-2" x1="0.646" y1="-0.24" x2="0.343" y2="1.355" xlinkHref="#linear-gradient"/>
    <linearGradient id="linear-gradient-3" x1="0.727" y1="-0.676" x2="0.253" y2="1.585" xlinkHref="#linear-gradient"/>
    <linearGradient id="linear-gradient-4" x1="0.485" y1="-0.328" x2="0.589" y2="1.922" gradientUnits="objectBoundingBox">
      <stop offset="0" stopColor="#201b59" stopOpacity="0.914"/>
      <stop offset="0.555" stopColor="#2d3a8d" stopOpacity="0"/>
    </linearGradient>
    <linearGradient id="linear-gradient-5" x1="0.485" y1="-0.328" x2="0.589" y2="1.922" xlinkHref="#linear-gradient-4"/>
    <clipPath id="clip-path-2">
      <path id="Path_283" data-name="Path 283" d="M357.332,357.237a3.1,3.1,0,0,1,1.483-2.455c1.31-.685,3.057-.113,4.582-.368,4.282-.715,4.249-6.05,4.162-8.988q-.084-2.8-.282-5.6a2.829,2.829,0,0,0-.368-1.434,2.539,2.539,0,0,0-2.666-.741,4.858,4.858,0,0,0-3.259,4c-.332,1.714-.17,3.5-.674,5.177a2.862,2.862,0,0,1-.417.9,2.262,2.262,0,0,1-.937.714,2.025,2.025,0,0,1-1.74-.021c-.591-.339-.493-.765-.49-1.28a14.127,14.127,0,0,0-.177-1.873c-.182-1.4-.339-2.813-.487-4.221-.291-2.768-.543-5.539-.867-8.3a8.667,8.667,0,0,0-1.018-3.707c-3.067-4.915-7.741-.6-8.17,2.987-.976,8.154,3.781,15.843,3.383,23.936a2.2,2.2,0,0,1-.211,1c-.716,1.274-3,1.051-4.063.316-1.658-1.152-1.065-3.647-1.066-5.148a3.273,3.273,0,0,0-1.769-3.184,3.854,3.854,0,0,0-4.329,1.331c-1.108,1.55-.985,4.2-.818,5.93a6.75,6.75,0,0,0,1.271,3.566,5.164,5.164,0,0,0,3.719,1.966c.886.048,1.9-.12,2.568.353.818.577.524,1.632.557,2.513.064,1.751,1.735,3.246,3.685,3.982a21.052,21.052,0,0,0,6.293.972c1.818-2.6,1.939-5.6,2.034-8.514A27.734,27.734,0,0,1,357.332,357.237Z" transform="translate(-337.027 -326.822)" fill="rgba(0,0,0,0)"/>
    </clipPath>
  </defs>
  <g id="Group_343" data-name="Group 343" transform="translate(43.475 -535)">
    <path id="Path_161" data-name="Path 161" d="M701.787,524.755a272.2,272.2,0,0,0,21.41-19.835c18.043-18.672,32.886-39.814,42.522-62.364,4.034-9.445,3.911-16.932,4.987-26.514,1.248-11.1,6.918-21.809,10.792-32.434,15.91-43.638,31.977-87.666,38.419-133.075,5.307-37.409,11.724-87.942-41.828-102.384-24.853-6.7-52.065-3.39-77.211,2.522s-49.5,14.361-75.271,18.024c-36.935,5.248-75.152.349-110.715-9.407-91.052-24.975-177.005-83.913-278.9-66.164-47.022,8.191-87.962,33.706-116.229,65.755S75,229.154,65.124,267.936c-20.742,81.474-24.024,188.444,40.5,256.819Z" transform="translate(-59.43 468.914)" fill="#ddeaec" opacity="0.54"/>
    <path id="Path_162" data-name="Path 162" d="M760.277,127.6c-10.306-19.877-26.828-37.6-48.625-47.135-40.343-17.639-86.821-16.234-129.561-5.642-46.48,11.518-89.672,32.194-125.073,59.87-19.033,14.881-35.908,31.788-56.74,44.876-36.437,22.893-82.612,32.523-128.066,35.47-33.816,2.191-68.681,1.1-100.527,10.858-38.832,11.891-68.865,38.935-88.655,69.276C68.418,317.582,58.757,341.9,50.437,366.366c-6.724,19.774-7.122,40-11.268,60.31-6.08,29.785-7.715,61.856-1.644,92.469H774.337q5.184-6.615,10.092-13.372c24.09-33.271,39.926-70.241,49.311-108.238,8.9-36.04,20.112-79.809,10.92-116.206-1.427-5.652-3.352-11.308-6.967-16.233-11.95-16.27-38.808-20.631-52.86-35.682-15.239-16.326-11-39.395-12.441-60.064A108.51,108.51,0,0,0,760.277,127.6Z" transform="translate(-62.092 468)" fill="#e4f5f3" opacity="0.56"/>
    <path id="Path_163" data-name="Path 163" d="M783.865,423.169c17.358-7.813,30.574-17.863,35.977-32.167,8.672-22.953,8.773-46.646,8.837-70.129.064-22.828.116-45.714-3.622-68.464-2.911-17.719-8.808-40.273-20.733-56.724-7.03-9.7-19.533-18.439-36.382-22.6-8.7-2.15-19.342-2.915-27.287.043-4.051,1.508-6.962,3.817-10.617,5.627-10.6,5.244-27.758,5.474-38.769.519-7.225-3.251-11.462-8.145-15.761-12.793-11.927-12.9-26.383-25.343-46.316-34.442-57.367-26.188-122.247-2.655-169.767,20.439-23.273,11.309-46.408,23.437-66.2,36.776-17.255,11.629-30.631,25.16-47.755,37.012-34.643,23.978-75.524,45.009-120.95,62.112-27.136,10.217-56.177,19.191-79.115,32.351-16.815,9.646-29.795,21.242-41.529,33.1-32.07,32.4-51.725,81.829,17.6,104.162,21.565,6.947,46.377,9.811,70.918,11.638,49.8,3.706,100.242,3.494,150.2.97,25.614-1.294,51.053-3.292,76.468-5.49,17.327-1.5,34.453-.526,51.559-2.122,46.645-4.352,91.55-7.391,138.9-8.745,39.654-1.134,73.4-2.582,110.866-11.674C743.269,437,765.808,431.3,783.865,423.169Z" transform="translate(-103.027 564.551)" fill="#d5e0e2" opacity="0.54"/>
    <path id="Path_164" data-name="Path 164" d="M522.213,420.354c-23.748,2.428-48.549.361-72.2.361H118.2c-17.709,0-35.552-.009-53.19,1.046-6.685.4-13.615.378-19.781-2.232-4.314-1.827-8.386-5.246-10.27-9.618-2.423-5.626,1.912-10.186.388-15.752-.915-3.34-4.578-5.66-7.432-7.125-8.335-4.279-13.363-14.905-8.164-23.51,2.378-3.936,6.452-6.615,10.777-8.176,9.029-3.261,19.313-3.223,28.781-3.2,8.953.022,17.877,1.165,26.818,1.165H496.736c4.687,0,9.375.031,14.062,0,5.113-.034,12.993-.474,14.679,6.067,1.578,6.123-6.34,10.839-6.87,16.735-1.485,16.518,31.694,9.637,32.1,28.445.092,4.253-2.7,8.174-6.272,10.484s-7.839,3.269-12.037,3.962C529.03,419.561,525.632,420,522.213,420.354Z" transform="translate(101.267 602.049)" fill="#f0f0f0"/>
    <g id="Group_225" data-name="Group 225" transform="translate(-43.475 954.193)">
      <path id="Path_165" data-name="Path 165" d="M842.525,420.354c-38.824,2.428-79.369.361-118.033.361H182.041c-28.95,0-58.122-.009-86.956,1.046-10.929.4-22.257.378-32.339-2.232-7.053-1.827-13.71-5.246-16.789-9.618-3.961-5.626,3.126-10.186.634-15.752-1.5-3.34-7.484-5.66-12.15-7.125-13.626-4.279-21.847-14.905-13.346-23.51,3.887-3.936,10.548-6.615,17.618-8.176,14.76-3.261,31.574-3.223,47.052-3.2,14.636.022,29.226,1.165,43.842,1.165H800.876c7.662,0,15.327.031,22.989,0,8.358-.034,21.241-.474,24,6.067,2.581,6.123-10.365,10.839-11.231,16.735-2.428,16.518,51.814,9.637,52.479,28.445.15,4.253-4.413,8.174-10.254,10.484s-12.815,3.269-19.679,3.962Q850.916,419.839,842.525,420.354Z" transform="translate(-17.639 -352.143)" fill="#a1e3dd"/>
      <g id="Group_224" data-name="Group 224" clipPath="url(#clip-path)">
        <g id="Group_222" data-name="Group 222" transform="translate(-16.542 8.723)">
          <line id="Line_23" data-name="Line 23" x2="885.316" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="0.75"/>
          <line id="Line_24" data-name="Line 24" x2="885.316" transform="translate(0 8.135)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="0.75"/>
          <line id="Line_25" data-name="Line 25" x2="885.316" transform="translate(0 16.269)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="0.75"/>
          <line id="Line_26" data-name="Line 26" x2="885.316" transform="translate(0 24.404)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="0.75"/>
          <line id="Line_27" data-name="Line 27" x2="885.316" transform="translate(0 32.539)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="0.75"/>
        </g>
        <g id="Group_223" data-name="Group 223" transform="translate(-16.542 49.397)">
          <line id="Line_28" data-name="Line 28" x2="885.316" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="0.75"/>
          <line id="Line_29" data-name="Line 29" x2="885.316" transform="translate(0 8.135)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="0.75"/>
          <line id="Line_30" data-name="Line 30" x2="885.316" transform="translate(0 16.269)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="0.75"/>
          <line id="Line_31" data-name="Line 31" x2="885.316" transform="translate(0 24.404)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="0.75"/>
          <line id="Line_32" data-name="Line 32" x2="885.316" transform="translate(0 32.539)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="0.75"/>
        </g>
      </g>
    </g>
    <g id="Group_226" data-name="Group 226" transform="translate(557.322 831.802)">
      <rect id="Rectangle_22" data-name="Rectangle 22" width="2.415" height="53.275" transform="translate(25.257 122.639) rotate(180)" fill="#110809"/>
      <path id="Path_167" data-name="Path 167" d="M444.57,363.539c0-7.868-13.92-11.167-20.876-11.179-8.278-.016-20.874,3.036-20.874,11.179" transform="translate(-399.404 -229.72)" fill="rgba(0,0,0,0)" stroke="#110809" strokeMiterlimit="10" strokeWidth="2"/>
      <line id="Line_33" data-name="Line 33" y1="13.067" transform="translate(24.057 122.855)" fill="none" stroke="#110809" strokeMiterlimit="10" strokeWidth="2"/>
      <path id="Path_168" data-name="Path 168" d="M399.842,364.2a3.416,3.416,0,1,0,3.416-3.416A3.416,3.416,0,0,0,399.842,364.2Z" transform="translate(-399.842 -228.482)" fill="#110809"/>
      <path id="Path_169" data-name="Path 169" d="M417.89,364.2a3.416,3.416,0,1,0,3.416-3.416A3.416,3.416,0,0,0,417.89,364.2Z" transform="translate(-397.188 -228.482)" fill="#110809"/>
      <path id="Path_170" data-name="Path 170" d="M435.938,364.2a3.416,3.416,0,1,0,3.416-3.416A3.416,3.416,0,0,0,435.938,364.2Z" transform="translate(-394.533 -228.482)" fill="#110809"/>
      <path id="Path_171" data-name="Path 171" d="M428.171,296.926v-39.2a12.284,12.284,0,0,1,12.284-12.283H453.98a12.283,12.283,0,0,1,12.284,12.283V301.55a17.649,17.649,0,0,1-17.6,17.6h-44.1a2.07,2.07,0,0,1-2.07-2.07h0a2.07,2.07,0,0,1,2.07-2.07h5.521A18.08,18.08,0,0,0,428.171,296.926Z" transform="translate(-399.451 -245.445)" fill="#f7fae6"/>
    </g>
    <g id="Group_227" data-name="Group 227" transform="translate(231.935 824.828)">
      <rect id="Rectangle_23" data-name="Rectangle 23" width="2.415" height="53.275" transform="translate(36.907 69.365)" fill="#110809"/>
      <path id="Path_172" data-name="Path 172" d="M130.993,357.46c0-7.867,13.92-11.166,20.876-11.179,8.28-.016,20.876,3.036,20.876,11.179" transform="translate(-113.996 -223.641)" fill="rgba(0,0,0,0)" stroke="#110809" strokeMiterlimit="10" strokeWidth="2"/>
      <line id="Line_34" data-name="Line 34" y1="13.067" transform="translate(38.107 122.855)" fill="none" stroke="#110809" strokeMiterlimit="10" strokeWidth="2"/>
      <path id="Path_173" data-name="Path 173" d="M171.245,358.12a3.416,3.416,0,1,1-3.416-3.416A3.417,3.417,0,0,1,171.245,358.12Z" transform="translate(-109.08 -222.402)" fill="#110809"/>
      <path id="Path_174" data-name="Path 174" d="M153.2,358.12a3.416,3.416,0,1,1-3.416-3.416A3.416,3.416,0,0,1,153.2,358.12Z" transform="translate(-111.735 -222.402)" fill="#110809"/>
      <circle id="Ellipse_8" data-name="Ellipse 8" cx="3.416" cy="3.416" r="3.416" transform="translate(13.927 132.301)" fill="#110809"/>
      <path id="Path_175" data-name="Path 175" d="M154.268,290.847v-39.2a12.284,12.284,0,0,0-12.284-12.284H128.458a12.283,12.283,0,0,0-12.283,12.284v43.821a17.648,17.648,0,0,0,17.6,17.6h44.1a2.071,2.071,0,0,0,2.07-2.07h0a2.072,2.072,0,0,0-2.07-2.07h-5.521A18.08,18.08,0,0,1,154.268,290.847Z" transform="translate(-116.175 -239.366)" fill="#f7fae6"/>
    </g>
    <g id="Group_228" data-name="Group 228" transform="translate(284.011 740.238)">
      <path id="Path_176" data-name="Path 176" d="M239.939,213.011a20.168,20.168,0,0,0,1.293-3.313c1.081-3.7,1.037-7.836,3.185-11.034,1.148.122,1.569,1.56,1.664,2.711a79.988,79.988,0,0,1-.323,12.867,8.436,8.436,0,0,1-2.571,6.209c-1.519,1.827-5.46,8.772-8.113,7.8-1.892-.693-2.625-4.877-2.076-6.522.814-2.447,2.528-3.236,4.206-4.856A14.168,14.168,0,0,0,239.939,213.011Z" transform="translate(-151.095 -160.762)" fill="#ff9575"/>
      <path id="Path_177" data-name="Path 177" d="M202.548,367.627a2.48,2.48,0,0,0,.375.476c1.994,1.974,6.089,1.554,8.823,1.566a69.016,69.016,0,0,0,11.613-.606,5.42,5.42,0,0,0,3.351-1.48c1.858-2.16-1.108-3.967-2.784-4.4-1.721-.44-3.53-.555-5.29-.855a10.192,10.192,0,0,1-4.865-1.923,6.279,6.279,0,0,1-2.124-3.577c-.3-1.483-.157-3.241-1.239-4.259a3.92,3.92,0,0,0-4.118-.289,7.114,7.114,0,0,0-3.087,3.856c-.891,2.418-.688,5.178-.961,7.68C202.1,365.113,201.9,366.562,202.548,367.627Z" transform="translate(-155.614 -138.233)" fill="#110809"/>
      <path id="Path_178" data-name="Path 178" d="M220.094,309.531q-.134-.955-.29-1.908c-1.207-7.348-3.825-14.061-5.6-21.2-2.45-9.85-4.911-19.625-8.144-29.26-1.328-3.956-2.366-8.929-4.4-12.551-1.871-3.331-4.406-3.06-8.27-2.335-2.166.4-4.466.879-6.04,2.419a10.621,10.621,0,0,0-2.174,3.7,69.5,69.5,0,0,0-4.9,22.179c-.4,5.358.733,9.37,2.013,14.375,1.448,5.656,1.422,10.8,4.674,15.959,2.19,3.474,5.185,6.529,6.478,10.428,1.287,3.881,1.1,8.052,2.391,11.959,1.136,3.437,2.789,6.675,4.212,10,1.609,3.759,1.792,7.953,1.95,12.039q.6,15.336,1.193,30.672l14.209-2.994a2.886,2.886,0,0,0,1.737-.808,2.929,2.929,0,0,0,.372-2.049c-.774-12.491.71-24.995,1.293-37.5A125.2,125.2,0,0,0,220.094,309.531Z" transform="translate(-158.836 -154.416)" fill="#0e398a"/>
      <path id="Path_179" data-name="Path 179" d="M162.024,371.68a2.52,2.52,0,0,0,.375.475c1.994,1.975,6.089,1.555,8.823,1.567a69.253,69.253,0,0,0,11.613-.606,5.433,5.433,0,0,0,3.351-1.48c1.858-2.16-1.108-3.967-2.784-4.4-1.721-.44-3.53-.555-5.29-.856a10.18,10.18,0,0,1-4.865-1.923,6.28,6.28,0,0,1-2.124-3.575c-.3-1.483-.157-3.242-1.239-4.259a3.918,3.918,0,0,0-4.118-.29,7.118,7.118,0,0,0-3.087,3.856c-.891,2.418-.688,5.178-.961,7.681C161.576,369.165,161.378,370.616,162.024,371.68Z" transform="translate(-161.574 -137.637)" fill="#110809"/>
      <path id="Path_180" data-name="Path 180" d="M210.58,192.453c.217-.057.431-.12.641-.2a7.171,7.171,0,0,0,4.043-4.275A14.816,14.816,0,0,0,216,182a39.853,39.853,0,0,1-.138-4.545c.107-1.544.5-3.118.108-4.615-1-3.806-5.66-4.279-8.919-4.175-3.884.124-8.4,2.388-10.466,5.766a6.427,6.427,0,0,0-.545,5.98,7.562,7.562,0,0,0,3.759,3.378l-6.863,7.784c-2.051,2.326-4.6,4.435-5.376,7.575-.452,1.84,1.98,2.338,2.884,1a11.2,11.2,0,0,1,2.238-2.307,2.561,2.561,0,0,0,2.5,2.314,2.151,2.151,0,0,0,2.787,1.853c4.7-1.688,6.258-7.564,11.081-9.155C209.555,192.691,210.072,192.588,210.58,192.453Z" transform="translate(-157.76 -165.176)" fill="#ff9575"/>
      <path id="Path_181" data-name="Path 181" d="M197.753,204.759c.693-2.525,4.134-7.659,2.864-9.949-1.052-1.9-3.508-2.66-5.65-2.321a13.8,13.8,0,0,0-5.784,2.842,72.515,72.515,0,0,0-22.93,28.835,23.272,23.272,0,0,0-2.276,7.529A27.193,27.193,0,0,0,165,239.98q4.052,17.6,8.1,35.2c2.908,1.229,6.319-1.113,7.433-4.066s.592-6.238.1-9.356q-.9-5.8-1.584-11.621c-.494-4.249-.843-8.8,1.184-12.566.891-1.656,2.223-3.111,2.7-4.93.305-1.159.242-2.4.634-3.531.933-2.685,3.918-4.241,5.584-6.436a50.95,50.95,0,0,0,5.5-9.276A58.481,58.481,0,0,0,197.753,204.759Z" transform="translate(-161.228 -161.681)" fill="#f02b66"/>
      <path id="Path_182" data-name="Path 182" d="M211.782,225.355a39.941,39.941,0,0,1,2.6-12.665c1.4-4.012,3.687-7.828,4.662-11.94a4.243,4.243,0,0,0,.133-1.827,4.135,4.135,0,0,0-1.908-2.36c-5.5-3.808-13.665-5.529-20.264-5.234-9.654.431-16.722,8.886-17.8,18.165-.576,4.961.259,9.974,1.31,14.857s2.319,9.749,2.627,14.734c.21,3.4-.039,6.8-.155,10.2-.108,3.161-.814,6.6,2.611,8.156,8.314,3.785,17.146,1.717,21.556-6.44,2.05-3.79,3.005-8.063,3.816-12.294A72.28,72.28,0,0,0,211.782,225.355Z" transform="translate(-159.009 -161.845)" fill="#f02b66"/>
      <path id="Path_183" data-name="Path 183" d="M237.969,219.414,227.335,229.63a17.452,17.452,0,0,1-3.423,2.771c-1.124-4.714-1.949-9.555-3.195-14.23-1.124-4.22-.83-8.641-1.631-12.913a11.285,11.285,0,0,0-2.171-5.176c-1.111-1.433-1.666-3.243-3.422-4.08-.442-.21-.922-.333-1.358-.555-1.081-.549-1.945-1.711-3.157-1.672a.95.95,0,0,0-.623.217,1.071,1.071,0,0,0-.249.407,7.38,7.38,0,0,0-.309,2.513,12.584,12.584,0,0,1-1.006,5.342,24.892,24.892,0,0,0-2.036,4.347,34.692,34.692,0,0,0-2,11.884c.108,2.7,1.654,4.079,2.526,6.624.929,2.714,1.114,5.639,1.67,8.439a33.894,33.894,0,0,0,8.689,16.714,6.227,6.227,0,0,0,2.537,1.822c7.677,2.357,13.862-7.6,18.04-12.336a54.27,54.27,0,0,0,10.4-17.684A45.285,45.285,0,0,0,240.8,216.7Q239.385,218.056,237.969,219.414Z" transform="translate(-155.52 -161.481)" fill="#f02b66"/>
      <path id="Path_184" data-name="Path 184" d="M193.835,236.371a13.782,13.782,0,0,0-4.019-1.024c-.855-.081-2.774-.7-3.528-.171-.829.577-1.056,2.8-1.541,3.751-1.438,2.841-3.774,5.135-5.9,7.458a81.8,81.8,0,0,0-8.859,11.589,21.916,21.916,0,0,0-3.339,7.453c-1.12,5.9,2.142,10.956,2.248,16.713.108,5.808-3.2,11.1-4.322,16.8-1.649,8.392,1.53,16.976,1.342,25.526-.1,4.4-1.085,8.732-1.839,13.072-2.482,14.283-2.435,28.87-2.381,43.366l9.735-.968a7.115,7.115,0,0,0,3.317-.892,6.488,6.488,0,0,0,1.7-1.925c6.3-9.64,4.274-21.408,6.678-32.351q2.86-13.022,5.665-26.046c.7-3.258,1.87-6.425,2.623-9.716,1.457-6.364,2.282-12.525,4.086-18.786,2.012-6.983,4.575-14.454,5.562-21.606.243-1.753,5.592-22.529,7.729-21.207C203.518,244.147,199.371,238.683,193.835,236.371Z" transform="translate(-161.559 -155.423)" fill="#2155b5"/>
      <path id="Path_185" data-name="Path 185" d="M220.942,174.831c1.652-1.469,2.8-3.423,2-5.723a5.52,5.52,0,0,0-6.129-3.391c-1.807.359-3.344,1.573-5.134,2-3.26.782-6.723-1.151-9.978-.344a6.168,6.168,0,0,0-3.52,2.405c-.878,1.245-1.268,2.781-2.15,4.023-1.419,2-3.79,2.85-5.985,3.7-1.8.691-4.946,1.429-5.965,3.2a6.557,6.557,0,0,0-.794,4.164,8.132,8.132,0,0,1,.2,1.7c-.091,1.358-1.218,2.371-2.065,3.436-1.753,2.206-2.443,5.412-1.137,7.908a9.483,9.483,0,0,0,4.181,3.712,10.628,10.628,0,0,0,3.786,1.3,14.593,14.593,0,0,0,12.151-4.107,14.215,14.215,0,0,0,3.991-10.059c-.032-1.824-.319-3.938.995-5.2,1.06-1.021,2.282-1.178,2.6-2.8.224-1.139-.4-2.424.216-3.478a3.669,3.669,0,0,1,2.27-1.608,8.875,8.875,0,0,1,3.933.18c2.466.514,4.4.805,6.366-.869Z" transform="translate(-158.922 -165.622)" fill="#1c0029"/>
      <path id="Path_186" data-name="Path 186" d="M205.73,180.717c-.018-.4-.073-.794-.07-1.192a4.132,4.132,0,0,0-.044-1.161,1.764,1.764,0,0,0-2.119-1.042,3.489,3.489,0,0,0-1.967,1.621c-.946,1.482-.908,3.926.977,4.706,1.427.591,3.086-.958,3.215-2.362A4.131,4.131,0,0,0,205.73,180.717Z" transform="translate(-155.787 -163.909)" fill="#ff9575"/>
      <path id="Path_187" data-name="Path 187" d="M189.171,256.265c-2.234-1.24-8.761-5.428-8.764-5.428-.307-.1-4.37,6.3-4.88,9.424a4.549,4.549,0,0,0,.8,3.719,3.923,3.923,0,0,0,1.941,1.167,6.142,6.142,0,0,0,4.821-.919c2.028-1.294,5-5.85,6.078-7.963" transform="translate(-159.534 -153.089)" fill="rgba(0,0,0,0)" stroke="#051433" strokeMiterlimit="10" strokeWidth="1" opacity="0.6"/>
      <path id="Path_188" data-name="Path 188" d="M208.707,183.062a2.556,2.556,0,0,0,1.122,1.569,1.591,1.591,0,0,0,1.841-.15,1.892,1.892,0,0,0,.412-.641,3.466,3.466,0,0,0,.266-1.161c0-.032,0-.072-.033-.081s-.039.059-.01.045" transform="translate(-154.642 -163.125)" fill="#fff"/>
    </g>
    <g id="Group_243" data-name="Group 243" transform="translate(156.594 682.43)">
      <path id="Path_189" data-name="Path 189" d="M162.459,126.152c.065-2.018-.274-4.182-.369-6.127a19.989,19.989,0,0,0-.291-2.94,1.945,1.945,0,0,0-.255-.72,2.2,2.2,0,0,0-.97-.683,2.582,2.582,0,0,0-2.442-.223,2.438,2.438,0,0,0-.608.677,13.185,13.185,0,0,0-1.862,4.9c-.228,1.1-.108,3.817-1.152,4.41.58-.329-.314-4.927-1.708-4.509a.915.915,0,0,0-.571.795,4.065,4.065,0,0,0,.087,1.03c.351,2.563-1.2,5.06-1.058,7.642.1,1.709.9,3.271,1.153,4.953a25.037,25.037,0,0,1,.287,5.424c-.1,1.3.017,5.372-1.411,6.114a8.613,8.613,0,0,1,6.014-.7l1.142-7.349a22.181,22.181,0,0,1,1.1-4.758c.833-2.118,2.322-3.983,2.74-6.257A11.5,11.5,0,0,0,162.459,126.152Z" transform="translate(-35.675 -115.226)" fill="#ff9575"/>
      <g id="Group_229" data-name="Group 229" transform="translate(77.151 259.692)">
        <path id="Path_190" data-name="Path 190" d="M132.874,364.892c.236.206.483.4.736.582,2.735,1.967,6.074,2.711,9.214,3.766,1.656.556,6.253,1.878,4.175,4.324-2.122,2.5-7.021,1.965-9.864,1.728-3.1-.259-6.184-.894-9.288-.715-1.971.114-3.951.554-5.9.242-6.81-1.086-3.286-10.441-3.271-15.006.016-4.458-.138-8.992,1.024-13.338.513-1.916,1.471-3.963,3.333-4.643a6.9,6.9,0,0,1,4.263.337c1.412.567,1.674,1.686,2.076,3.134a47.288,47.288,0,0,1,1.453,13.93,8.871,8.871,0,0,0,.306,3.262A5.873,5.873,0,0,0,132.874,364.892Z" transform="translate(-117.753 -341.62)" fill="#111314"/>
      </g>
      <g id="Group_231" data-name="Group 231" transform="translate(66.141 43.884)">
        <path id="Path_191" data-name="Path 191" d="M112.193,176.922c.077.278.155.553.236.828a17.609,17.609,0,0,1,.373,3.3c.178,1.918.267,3.886.619,5.782.454,2.448,6.743,13.4,9.1,13.358,2.589-.053,4.635-10.66,4.195-13.184-.6-3.435-2.423-10.234-2.432-10.279a8.981,8.981,0,0,0-4.132-5.133c-2.151-1.067-5.17-.849-7.486-.6a1.912,1.912,0,0,0-1.054.341c-.554.463-.447,1.328-.291,2.034C111.59,174.566,111.871,175.751,112.193,176.922Z" transform="translate(-107.707 -150.937)" fill="#ff9575"/>
        <path id="Path_192" data-name="Path 192" d="M124.762,180.416a.194.194,0,0,0,.033-.164c-.375-1.471-.655-2.517-.658-2.534-3.279-8.946-12.54-6.314-11.872.132.062.6.184,1.968.326,3.52l1.071.762A7.879,7.879,0,0,0,124.762,180.416Z" transform="translate(-107.556 -150.764)" fill="url(#linear-gradient)"/>
        <g id="Group_230" data-name="Group 230">
          <path id="Path_193" data-name="Path 193" d="M123.024,159.923c-1.765-.673-4.759,1.848-6.6,2.319-1.788.641-4.6,1.468-5.722,2.986-2.38,3.214-1.242,8.13.927,11.4.364.548,1.153,1.963,1.569,2.471,3.328,4.056,8.51,3.8,11.007,2.85,2.984-1.133,4.329-3.913,4.4-7.573.013-.658-.231-2.767-.3-3.421C127.855,166.947,124.881,160.63,123.024,159.923Z" transform="translate(-107.975 -152.552)" fill="#ff9575"/>
          <path id="Path_194" data-name="Path 194" d="M125.439,153.574a6.157,6.157,0,0,0-1.09.553c-1.97,1.155-4.385,1.117-6.619,1.592-3.323.709-6.677,2.4-8.44,5.426-2.166,3.715-.766,8.408.356,12.266a3.882,3.882,0,0,0,.286.767c1.368,2.542,3.212.4,3.473-1.543.163-1.21,0-2.451.205-3.656a5.864,5.864,0,0,1,5.854-4.852c2.594.186,5.71-.553,7.027-3.045a8.425,8.425,0,0,0,.5-6.624,1.585,1.585,0,0,0-.5-.8A1.116,1.116,0,0,0,125.439,153.574Z" transform="translate(-108.155 -153.483)" fill="#151a36"/>
          <path id="Path_195" data-name="Path 195" d="M126.536,169.25c-.009.143-.023.284-.044.421a.632.632,0,0,1-.329-.138c-1.348-1.875-1.541-4.326-2.817-6.223-.537-.8-1.433-.814-1.9-1.451a2.747,2.747,0,0,1,.669-3.353,2.087,2.087,0,0,1,2.745.371,3.215,3.215,0,0,1,.443.857,20.2,20.2,0,0,1,.961,5.841A30.92,30.92,0,0,1,126.536,169.25Z" transform="translate(-106.249 -152.796)" fill="#151a36"/>
          <path id="Path_196" data-name="Path 196" d="M112.951,173.686a2.47,2.47,0,0,1-1.19,1.148,1.832,1.832,0,0,1-1.629-.075,4.753,4.753,0,0,1-1.515-2.847,2.453,2.453,0,0,1,.923-2.2C112.016,167.81,114.026,171.616,112.951,173.686Z" transform="translate(-108.09 -151.172)" fill="#ff9575"/>
          <path id="Path_197" data-name="Path 197" d="M126.957,167.983l.006.061a4.606,4.606,0,0,1,0,1.358,1.565,1.565,0,0,1-.733,1.1c-.819.437-1.964-.5-2.325-1.193a3.378,3.378,0,0,1,.031-2.978,1.681,1.681,0,0,1,1.7-.982,1.536,1.536,0,0,1,1,.923,4.571,4.571,0,0,1,.283,1.381Z" transform="translate(-105.887 -151.742)" fill="#ff9575"/>
        </g>
      </g>
      <path id="Path_198" data-name="Path 198" d="M141.559,188.385a1.922,1.922,0,0,0-1.348-.735q-2.282-.525-4.521-1.225a31.321,31.321,0,0,0-4.092-1.343,8.921,8.921,0,0,1-1.1-.224,6.96,6.96,0,0,1-.8-.322q-.731-.334-1.465-.665a6.232,6.232,0,0,1-1.073,4.682,6.545,6.545,0,0,1-5.91,1.983,6.084,6.084,0,0,1-4.774-3.174,31.567,31.567,0,0,1-1.446-3.189,10.228,10.228,0,0,0-3.031.486c-1.582.34-3.151.741-4.716,1.149-2.387.623-4.774,1.26-7.138,1.964-2.563,2.06.083,5.672-.274,7.252-1.067,4.716-.458,61.762-.458,61.762l38.406-.622q.344-5.186.661-10.375.7-11.23,1.3-22.464c.38-7.186.752-14.377.915-21.572.031-1.371.034-2.74.048-4.111a25.964,25.964,0,0,1,.009-3.259c.01-.073.021-.148.032-.223a34.781,34.781,0,0,0,.919-4.953A1.644,1.644,0,0,0,141.559,188.385Z" transform="translate(-43.357 -105.13)" fill="#fad100"/>
      <g id="Group_233" data-name="Group 233" transform="translate(65.156 138.03)">
        <g id="Group_232" data-name="Group 232">
          <path id="Path_199" data-name="Path 199" d="M142.517,260.774q-.258-4.835-.57-9.663c-.158-2.471-.38-5.043-1.658-7.162-1.723-2.857-6.816-5.642-9.775-7.215-4.207-2.238-9.523-1.068-13.235,1.7-3.9,2.914-6.343,7.41-7.867,12.035a42.674,42.674,0,0,0-.833,23.655c1.047,4.144,3.424,8.733,3.541,13.023.141,5.213-1.932,9.642.236,14.851,1.1,2.633,3,4.864,4.147,7.472,1.778,4.034,1.632,8.618,1.454,13.023q-1.056,26.152-2.112,52.3h19.189a.243.243,0,0,0,.008-.027q2.24-15.121,4.1-30.293,1.707-13.927,3.093-27.888C144.088,297.944,143.523,279.52,142.517,260.774Z" transform="translate(-107.296 -235.558)" fill="#d4004a"/>
        </g>
      </g>
      <g id="Group_234" data-name="Group 234" transform="translate(14.941 267.614)">
        <path id="Path_200" data-name="Path 200" d="M77.67,361.808a2.089,2.089,0,0,0-.022,1.061c.28.977,1.773,1.754,2.435,2.589,1.229,1.55,3.322,2.1,5.287,2.329,1.778.2,5.542-.033,6.561,1.9a2.119,2.119,0,0,1,.189,1.17c-.429,4.975-8.993,3.769-12.242,3.554l-9.187-.613c-4.419-.295-8.409-2.14-6.816-7.326a24.469,24.469,0,0,1,1.255-3.064l3.507-7.559a16.455,16.455,0,0,1,3.739-5.77c2.1-1.82,7.32-2.6,8.447.82a5.753,5.753,0,0,1-.432,3.627l-1.609,4.6A26.334,26.334,0,0,0,77.67,361.808Z" transform="translate(-63.519 -348.527)" fill="#111314"/>
      </g>
      <g id="Group_236" data-name="Group 236" transform="translate(17.533 135.271)">
        <g id="Group_235" data-name="Group 235">
          <path id="Path_201" data-name="Path 201" d="M129.009,239.817a4.313,4.313,0,0,0-.839-2.568,14.126,14.126,0,0,0-1.836-1.262c-1.764-1.39-.97-2.153-3.851-2.581a24.146,24.146,0,0,0-6.545-.06c-3.8.483-7.813,1.85-10.475,4.726-4.714,5.092-6.893,12.249-7.607,19.017-.96,9.087.24,18.311-.863,27.381-.652,5.361-2.1,10.592-3,15.917-.506,3-.857,6.089-2.225,8.8a42.362,42.362,0,0,1-3.565,5.215c-5.441,7.679-5.445,17-8.494,25.609-3.546,10.013-9.925,23.321-13.935,33.154.639.249,1.2.45,1.468.568,1.474.642,2.974,1.217,4.464,1.82,2.943,1.193,5.793,2.595,8.642,4,6.6-11.481,16.41-26.146,23.25-37.415,6.786-11.182,13.634-20.931,17.437-33.716C127.592,286.389,131.273,262.8,129.009,239.817Z" transform="translate(-65.779 -233.152)" fill="#f02b66"/>
        </g>
      </g>
      <path id="Path_202" data-name="Path 202" d="M121.332,227.2c.072-.348.14-.693.2-1.037,1.021-5.858,1.638-11.779,2.107-17.705.234-2.966.446-5.935.621-8.9.147-2.514.6-4.979.769-7.5.149-2.175.328-4.492,1.814-6.177a.765.765,0,0,0,.338,0c4.156-.912,7.716-4.752,10.5-8.75a1.752,1.752,0,0,1,1.355-.084,2.762,2.762,0,0,1,1.445,2.169c.593,2.789.016,5.624.2,8.436.166,2.516.476,4.616.42,7.159-.063,2.792.375,5.405-.785,8.049-1.332,3.033-1.188,6.069-1.09,9.361.552,18.436,1.929,36.869,3.71,55.157.084.362-.42.547-.791.567a99.992,99.992,0,0,1-13.638-.525,5.82,5.82,0,0,1-2.389-.549,4.62,4.62,0,0,1-1.541-1.591c-2.46-3.812-2.44-8.109-2.685-12.448q-.434-7.676-.866-15.35c-.111-1.971-.424-4.007-.38-5.977A24.164,24.164,0,0,1,121.332,227.2Z" transform="translate(-40.177 -106.148)" fill="#2155b5"/>
      <path id="Path_203" data-name="Path 203" d="M96.465,224.5c-1.551,12.432-3.847,24.76-5.372,37.2-.135,1.106-.8,2.852.227,3.707a3.926,3.926,0,0,0,1.937.688,97.919,97.919,0,0,0,14.75,1.251c3.73.034,9.187.681,12.523-1.38,3.158-1.951,3.99-6.093,4.528-9.767a145.112,145.112,0,0,0,1.664-15.395,43.3,43.3,0,0,0-.2-7.258c-.319-2.428-.083-4.9-.481-7.318a27.834,27.834,0,0,0-1.409-4.771,178.634,178.634,0,0,1-5.494-18.567c-1.451-6.1-2.294-12.278-3.472-18.412a.253.253,0,0,0-.06-.14.264.264,0,0,0-.175-.049c-2-.056-3.859.49-5.831.625-1.836.126-3.689-.048-5.517.165a7.02,7.02,0,0,0-4.837,2.347c-1.8,2.335-1.753,5.885-1.625,8.68.118,2.595.61,5.21.537,7.809C97.966,210.8,97.317,217.67,96.465,224.5Z" transform="translate(-44.573 -105.068)" fill="#2155b5"/>
      <g id="Group_239" data-name="Group 239" transform="translate(23.524 164.008)">
        <path id="Path_204" data-name="Path 204" d="M81.849,272.383c.038-2.214-1.175-4.291-2.285-6.162a2.146,2.146,0,0,0-.512-.645.77.77,0,0,0-.777-.125,1,1,0,0,0-.379.39c-1.348,2.131-.077,3.212,1.3,4.716.51.557,1.06,1.28.808,1.992a2.94,2.94,0,0,1-.783.964c-.444.46-.765,2.27.413,1.713A3.214,3.214,0,0,0,81.849,272.383Z" transform="translate(-70.072 -257.147)" fill="#ff9575"/>
        <path id="Path_205" data-name="Path 205" d="M83,278.141c-.018-1.672.2-3.918-1-5.265-1.073-1.2-3.035-1.545-4.549-1.667-4.187-.338-5.831,2.221-6.434,6.018-.159,1,1.4,1.2,1.559.2a6.624,6.624,0,0,1,1.32-3.707c1.06-1.1,2.657-1.075,4.074-.869,1.441.21,2.833.579,3.3,2.144a13.293,13.293,0,0,1,.179,3.374C81.458,279.371,83.013,279.141,83,278.141Z" transform="translate(-71.002 -256.296)" fill="#111314"/>
        <g id="Group_238" data-name="Group 238" transform="translate(2.134)">
          <g id="Group_237" data-name="Group 237">
            <path id="Path_206" data-name="Path 206" d="M80.98,265.237a26.056,26.056,0,0,0-.646,3.176,20.386,20.386,0,0,0,.421,4.614c.131,1.178.354,2.741-.655,3.62a2.839,2.839,0,0,1-1.863.629,5.155,5.155,0,0,1-4.557-2.846,9.993,9.993,0,0,1-.8-3.731c-.221-3.544,1.779-6.585,3.224-9.711.392-.849.935-2.13,1.828-2.576a2.266,2.266,0,0,1,2.98,1.343,6.854,6.854,0,0,1,.366,4.033C81.2,264.274,81.093,264.756,80.98,265.237Z" transform="translate(-72.862 -258.204)" fill="#ff9575"/>
          </g>
        </g>
      </g>
      <g id="Group_240" data-name="Group 240" transform="translate(0 181.484)">
        <rect id="Rectangle_24" data-name="Rectangle 24" width="51.879" height="33.294" rx="1" transform="translate(4.173 0.179) rotate(7.2)" fill="#0e398a"/>
        <path id="Path_207" data-name="Path 207" d="M105.04,281.226,78.5,291.1a.911.911,0,0,1-.83-.1L54.418,274.831a.767.767,0,0,1,.6-1.383l49.79,6.289A.767.767,0,0,1,105.04,281.226Z" transform="translate(-49.968 -273.44)" fill="#2155b5"/>
        <path id="Path_208" data-name="Path 208" d="M76.012,288.49c.059-.947-1.411-1.139-1.469-.186q-.086,1.389-.3,2.762c-.149.943,1.32,1.128,1.469.186Q75.927,289.878,76.012,288.49Z" transform="translate(-47.004 -271.346)" fill="#2155b5"/>
      </g>
      <g id="Group_241" data-name="Group 241" transform="translate(25.773 80.262)">
        <path id="Path_209" data-name="Path 209" d="M99.379,188.463A27.521,27.521,0,0,0,89.3,205.9a25.386,25.386,0,0,1-1.124,5.495,24.356,24.356,0,0,1-2.716,4.453,35.225,35.225,0,0,0-5.5,13.6c-.973,5.534-.609,11.247-1.715,16.755-1.895,9.432-4.464,18.271-5.282,27.914.874.2,10.375,1.93,10.173,2.51,1.435-4.12,3.008-8.219,4.345-12.368,1.055-3.277,2.48-6.131,3.827-9.326,2.033-4.819,1.241-10.659,4.082-15.05,1.1-1.708,2.684-3.058,3.875-4.709,3.678-5.094,3.143-12.184,6.151-17.7,1.67-3.065,4.36-5.5,5.926-8.616,2.791-5.56,1.284-11.85.25-17.629-.811-4.534-3.174-7.487-8-5.295A21.936,21.936,0,0,0,99.379,188.463Z" transform="translate(-72.963 -185.196)" fill="#2155b5"/>
      </g>
      <g id="Group_242" data-name="Group 242" transform="translate(80.939 123.911)">
        <path id="Path_210" data-name="Path 210" d="M124.3,224.872a1.624,1.624,0,1,1-1.623-1.623A1.623,1.623,0,0,1,124.3,224.872Z" transform="translate(-121.055 -223.249)" fill="#111314"/>
        <path id="Path_211" data-name="Path 211" d="M124.3,233.665a1.624,1.624,0,1,1-1.623-1.623A1.624,1.624,0,0,1,124.3,233.665Z" transform="translate(-121.055 -221.956)" fill="#111314"/>
      </g>
      <path id="Path_212" data-name="Path 212" d="M117.637,173.352a3.142,3.142,0,0,0,6.208-.968Z" transform="translate(-40.619 -106.819)" fill="#fff"/>
      <path id="Path_213" data-name="Path 213" d="M153.5,134.755c-1.707,4.865-1.863,11.177-2.756,16.162a20.559,20.559,0,0,1-1.248,4.747c-.9,2.031-2.436,3.72-3.448,5.7-1.945,3.806-1.808,8.346-3.3,12.353-2.381,6.4-8.562,10.645-11.724,16.7-3.238,6.2-3.375,14.088-2.559,21.41.295,2.641.712,5.208,1.154,7.6,1.139,6.164,12.07-5.686,13.819-7.545,5.707-6.063,10.944-12.986,12.851-21.248.533-2.311.794-4.677,1.261-7,.5-2.483,1.233-4.912,1.878-7.361,2.682-10.2,5.2-21.4,4.981-31.982-.061-2.91-.214-5.815-.281-8.721A56.148,56.148,0,0,1,153.5,134.755Z" transform="translate(-39.089 -112.353)" fill="#2155b5"/>
      <path id="Path_214" data-name="Path 214" d="M110.986,184.36c-.089.64-.215,2.659-.236,2.864q-.206,2.017-.414,4.033l-.8,7.8-.141,1.375,5.078,1.334-3.923,4.661,12.672,19.32c-1.017-4.982-2.019-9.966-3.113-14.934-1.015-4.615-2.288-9.128-3.441-13.7Q115,190.5,113.315,183.889a11.8,11.8,0,0,0-2.284.278A.5.5,0,0,0,110.986,184.36Z" transform="translate(-41.832 -105.127)" fill="#0e398a"/>
      <path id="Path_215" data-name="Path 215" d="M128.572,190.921q-.437-3.073-.762-6.158a7.249,7.249,0,0,1-2.888-.834c.045.734-.169,2.8-.188,3.067q-.126,1.733-.372,3.449c-.312,2.171-.837,4.3-1.231,6.44-.808,4.4-.794,8.938-1,13.422-.12,2.577-.217,5.157-.319,7.736q-.077,1.934-.162,3.867-.045,1-.094,2.007c-.02.388.114,1.389-.088,1.7l7.924-19.194-4.368-4.661a12.712,12.712,0,0,0,2.654-.716c.582-.156,1.167-.3,1.745-.47.313-.089.635-.114.727-.4a5.736,5.736,0,0,0-.335-1.713Q129.109,194.7,128.572,190.921Z" transform="translate(-40.056 -105.121)" fill="#0e398a"/>
    </g>
    <g id="Group_250" data-name="Group 250" transform="translate(493.712 741.752)">
      <path id="Path_216" data-name="Path 216" d="M372.525,366.914a8,8,0,0,1-1.047,1.738c-2.754,3.449-7.774,3.789-11.777,5.645-1.818.843-4.467,3.278-2.685,5.357,1.233,1.44,4.343,1.31,6.027,1.188a170.439,170.439,0,0,0,17.756-2.217,3.1,3.1,0,0,0,1.527-.569,3.14,3.14,0,0,0,.754-2.341q.31-8.13.11-16.269c0-.008-7.472-.189-8.177.125-1.562.7-1.335,1.988-1.518,3.5A13.375,13.375,0,0,1,372.525,366.914Z" transform="translate(-342.617 -138.637)" fill="#110809"/>
      <path id="Path_217" data-name="Path 217" d="M412.283,372.92a7.19,7.19,0,0,1-1.6,1.27,48.417,48.417,0,0,1-8.231,3.96c-2.183.806-4.6,1.581-5.883,3.519-2.922,4.394,3.094,5.465,6,5.256a170.434,170.434,0,0,0,17.756-2.219,3.106,3.106,0,0,0,1.527-.568,3.147,3.147,0,0,0,.754-2.341q.31-8.132.109-16.27c0,.064-7.626-.3-8.27.125-1.225.812-.633,2.547-.746,3.8A5.619,5.619,0,0,1,412.283,372.92Z" transform="translate(-336.827 -137.747)" fill="#110809"/>
      <g id="Group_245" data-name="Group 245" transform="translate(25.665 96.453)">
        <g id="Group_244" data-name="Group 244">
          <path id="Path_218" data-name="Path 218" d="M417.654,272.764a70.744,70.744,0,0,0-5-18.735c-.321-.769-.943-2.817-1.925-3-.559-.1-2.439,1.382-3.037,1.651a30.149,30.149,0,0,1-3.8,1.357c-2.858.848-5.809,1.4-8.61,2.439-2.156.8-5.82,2.16-6.793,4.341q-2.03,4.554-3.775,9.228c-1.046,2.8-2.6,5.749-3.119,8.681-.451,2.553-.449,4.93-1.319,7.509-3.5,10.364-9.676,19.8-11.962,30.5a78.074,78.074,0,0,0-1.362,13.84,520.717,520.717,0,0,0,1.477,53.977h15.133c.465-3.313.93-7.788,1.395-11.1a59.959,59.959,0,0,1,2.24-10.943c.691-2.037,1.6-4,2.159-6.078,1.668-6.211.146-13.227,3.164-18.906.745-1.4,1.738-2.653,2.519-4.033a27.558,27.558,0,0,0,2.566-7.394c1.753-7.506,2.931-15.293,6.551-22.1,2.051-3.858,4.822-5.232,7.749-8.043,2.309-2.22,4.12-6.754,4.826-9.813A40.358,40.358,0,0,0,417.654,272.764Z" transform="translate(-366.762 -251.028)" fill="#d4004a"/>
        </g>
      </g>
      <path id="Path_219" data-name="Path 219" d="M354.447,186.254a14.121,14.121,0,0,1-.367-6.353,37.818,37.818,0,0,0-.193-8.959,6.828,6.828,0,0,0-.673-2.4c-.6-1.081-2.415-2.159-3.581-1.288a3.415,3.415,0,0,0-.936,1.286,8.128,8.128,0,0,0-1.148,3.707c.06,1.265.454,2.643-.008,3.887-.411,1.106-1.038-.239-1.18-.849-.127-.548-.812-1.99-1.577-1.648-.857.383-.081,2.922.107,3.6.756,2.7,2.246,5.178,2.661,7.979.218,1.472.118,2.973.267,4.455.255,2.534,1.181,4.909,1.6,7.416a46.759,46.759,0,0,1,.6,5.271c.05.925.069,1.85.159,2.771a35.161,35.161,0,0,0,1.085,5.293q.735,2.832,1.468,5.664c.443,1.71,1,3.258,1.585,4.9a30.371,30.371,0,0,0,5.115,9.132c2.15,2.712,3.656,6.759,7.708,6.714a26.752,26.752,0,0,0,6.5-1.355,21.719,21.719,0,0,0,6.76-3.316,19.688,19.688,0,0,0,7.166-9.959,7.5,7.5,0,0,0,.29-4.175c-1.028-3.667-5.267-4.11-8.423-3.626l-5.361.824c-1.716.263-4.054,1.956-5.079-.24-2.316-4.961-5.653-9.5-8.253-14.327-2.052-3.8-3.4-7.96-5.217-11.878C355.156,187.985,354.77,187.119,354.447,186.254Z" transform="translate(-344.388 -166.942)" fill="#ff9575"/>
      <g id="Group_247" data-name="Group 247" transform="translate(59.448 93.451)">
        <g id="Group_246" data-name="Group 246">
          <path id="Path_220" data-name="Path 220" d="M431.866,280.155a32.974,32.974,0,0,0-6.558-23.53c-3.157-4.1-7.592-7.558-12.737-8.125-18.725-2.066-16.764,32.024-15.762,43.179.8,8.9,2.248,17.74,2.915,26.657.461,6.18-.06,12.366-.013,18.523.046,6.262,2.544,12.663,3.8,18.8.523,2.564,3.758,24.7,4.885,35.675h15.935q3.856-29.474,5.416-59.188c.354-6.782.644-13.649.785-20.4.148-7.006-1.542-13.861-.555-20.954C430.469,287.222,431.463,283.734,431.866,280.155Z" transform="translate(-396.213 -248.411)" fill="#f02b66"/>
        </g>
      </g>
      <path id="Path_221" data-name="Path 221" d="M391.984,204.282c-2.733-.666-6.191.022-8.658.473-3.93.718-7.642,2.192-11.465,3.284-.2.073-.409.139-.612.211-.246.088-.494.18-.74.282q-.043.669-.085,1.34c.746,7.134,1.917,14.213,3,21.3a39.247,39.247,0,0,0,18.515-15.451,17.183,17.183,0,0,0,1.687-3.984,9.893,9.893,0,0,0,.006-1.264C393.491,208.338,392.586,206.345,391.984,204.282Z" transform="translate(-340.558 -161.491)" fill="#2155b5"/>
      <path id="Path_222" data-name="Path 222" d="M384.755,271.5c4.049-1.013-4.659-34.183-5.3-37.319a89.313,89.313,0,0,1-2-13.02c-.153-2.822.026-5.849,1.687-8.134a14.482,14.482,0,0,1,4.253-3.485c8.174-5.111,15.231-11.785,25.368-12.247a4.228,4.228,0,0,1,1.731.18,4.589,4.589,0,0,1,2.145,2.641c2.374,5.234,3.3,10.949,5.722,16.058a85.212,85.212,0,0,1,5.587,16.067c2.906,11.532,5.555,23.213,6.807,35.058-1.5-3.121-17.9.483-21.476.9Q396.992,269.642,384.755,271.5Z" transform="translate(-339.53 -162.48)" fill="#2155b5"/>
      <path id="Path_223" data-name="Path 223" d="M412.145,201.13c1.848,2.082,2.792,5.313,3.387,8.173,1.589,7.636,3.185,15.35,3.04,23.149a104.633,104.633,0,0,1-1.487,13.815q-1.889,12.383-3.781,24.765c-.63,4.128-1.27,8.254-1.89,12.384-.311,2.066-.211,5.716-1.691,7.422a5.065,5.065,0,0,1-3.391,1.284q-8.944,1-17.957,1.121c-.847-3.114,1.627-6.351,4.637-7.516s6.366-.795,9.57-.408q-1.3-15.41-2.592-30.82c-1.036-7.25.526-14.85-1.081-21.984s-2.044-14.441-2.681-21.8c-.208-2.386-.365-4.981.959-6.977,1.16-1.749,3.208-2.65,5.15-3.446,2.041-.836,4.24-1.671,6.4-1.217A6.2,6.2,0,0,1,412.145,201.13Z" transform="translate(-337.943 -162.234)" fill="#ff9575"/>
      <path id="Path_224" data-name="Path 224" d="M394.841,230.092a106.946,106.946,0,0,1,24.927-1.669,131.913,131.913,0,0,0-2.608-18.692c-.795-3.729-1.888-7.656-4.729-10.2-4.918-4.4-15.45-3.243-17.832,3.41C391.821,210.7,393.363,222.277,394.841,230.092Z" transform="translate(-337.229 -162.533)" fill="#2155b5"/>
      <g id="Group_249" data-name="Group 249" transform="translate(36.276 10.438)">
        <path id="Path_225" data-name="Path 225" d="M394.954,196.192c.264,1.5,1.57,8.155,1.7,9.518.225,2.389-2.716,14.213-4.918,14.793-2.416.635-7.114-8.665-7.372-11.123-.35-3.345-.453-10.132-.458-10.177C384.585,190.039,393.871,190.038,394.954,196.192Z" transform="translate(-374.851 -173.709)" fill="#ff9575"/>
        <path id="Path_226" data-name="Path 226" d="M393.467,192.621c-.521-.528-1.331-.552-2.478-.616a6.289,6.289,0,0,0-4.088.961,7.183,7.183,0,0,0-2.993,6.223c.085.931.1,1.776.229,2.59a5.527,5.527,0,0,0,1,2.46,16.449,16.449,0,0,0,2.826,2.807,4.728,4.728,0,0,0,1.708,1.066,4.588,4.588,0,0,0,3.277-.773c5.193-2.806,1.925-9.019,1.113-13.369A2.5,2.5,0,0,0,393.467,192.621Z" transform="translate(-374.854 -173.696)" fill="url(#linear-gradient-2)"/>
        <g id="Group_248" data-name="Group 248">
          <path id="Path_227" data-name="Path 227" d="M381.184,183.066c1.459-1.09,4.9.459,6.732.411,1.827.123,4.651.148,6.1,1.26,3.056,2.353,3.3,7.214,2.15,10.823-.192.6-.551,2.124-.8,2.706a9.663,9.663,0,0,1-9.458,5.55c-3.067-.263-5.049-2.486-6.077-5.864-.185-.607-.516-2.627-.621-3.253C378.555,190.858,379.648,184.213,381.184,183.066Z" transform="translate(-375.57 -175.065)" fill="#ff9575"/>
          <path id="Path_228" data-name="Path 228" d="M378.384,176.831a4.187,4.187,0,0,1,4.622-.166c1.442,1.068,2.029,2.332,3.98,2.226,1.866-.1,3.094-.871,5.012-.122,3.072,1.2,4.985,4.576,5.35,7.728a39.727,39.727,0,0,1-.094,7.636c-.063.133-.439.3-.545-.223a22.483,22.483,0,0,0-.669-3.526,1.634,1.634,0,0,0-1.331-1.107c-.518-.029-1.122.256-1.51-.089a1.444,1.444,0,0,1-.328-.693,3.4,3.4,0,0,0-2.643-2.3c-2.833-.457-5.391.578-8.194.555-4.615-.038-8.4-6.089-4.219-9.518A5.425,5.425,0,0,1,378.384,176.831Z" transform="translate(-376.012 -176.041)" fill="#110809"/>
          <path id="Path_229" data-name="Path 229" d="M378.807,192.779c.054.537.125.43.4.177.993-2.745.084-7.409,2.171-7.94.156-.04.548-1.807.158-1.736a4.348,4.348,0,0,0-2.288,1.022C378.253,185.943,378.584,190.591,378.807,192.779Z" transform="translate(-375.636 -174.977)" fill="#110809"/>
          <path id="Path_230" data-name="Path 230" d="M392.479,191.892a1.662,1.662,0,1,0,1.646-2.064A1.9,1.9,0,0,0,392.479,191.892Z" transform="translate(-373.59 -174.014)" fill="#ff9575"/>
          <path id="Path_231" data-name="Path 231" d="M377.519,191.568a1.662,1.662,0,1,0,1.645-2.065A1.9,1.9,0,0,0,377.519,191.568Z" transform="translate(-375.79 -174.061)" fill="#ff9575"/>
        </g>
      </g>
      <path id="Path_232" data-name="Path 232" d="M388.566,194.412a3.03,3.03,0,0,1-6.014.741Z" transform="translate(-338.774 -162.901)" fill="#fff"/>
    </g>
    <g id="Group_254" data-name="Group 254" transform="translate(376.537 769.336)">
      <path id="Path_233" data-name="Path 233" d="M270.565,345.422c1.648,2.462,2.831,4.8,2.393,8.082-.645,4.828-4.585,8.028-5.624,12.715a2.862,2.862,0,0,0-.05,1.325,2.7,2.7,0,0,0,1.936,1.788c4.557,1.2,8.545-5.5,11.12-8.09l9.723-9.787a2.527,2.527,0,0,0-.26-3.478L278.95,336.766l-1.593-1.668c-2.643-2.764-6.6-3.07-8.78-.677-1.879,2.068-1.729,5.606.174,8.382C269.38,343.72,270,344.578,270.565,345.422Z" transform="translate(-238.561 -170.131)" fill="#110809"/>
      <g id="Group_251" data-name="Group 251" transform="translate(45.401 60.722)">
        <rect id="Rectangle_25" data-name="Rectangle 25" width="2.415" height="53.275" transform="translate(25.257 122.64) rotate(180)" fill="#110809"/>
        <path id="Path_234" data-name="Path 234" d="M326.544,362.019c0-7.867-13.92-11.166-20.875-11.179-8.28-.016-20.876,3.036-20.876,11.179" transform="translate(-281.378 -228.2)" fill="rgba(0,0,0,0)" stroke="#110809" strokeMiterlimit="10" strokeWidth="2"/>
        <line id="Line_35" data-name="Line 35" y1="13.067" transform="translate(24.057 122.855)" fill="none" stroke="#110809" strokeMiterlimit="10" strokeWidth="2"/>
        <path id="Path_235" data-name="Path 235" d="M281.816,362.679a3.416,3.416,0,1,0,3.416-3.416A3.416,3.416,0,0,0,281.816,362.679Z" transform="translate(-281.816 -226.961)" fill="#110809"/>
        <path id="Path_236" data-name="Path 236" d="M299.864,362.679a3.416,3.416,0,1,0,3.416-3.416A3.417,3.417,0,0,0,299.864,362.679Z" transform="translate(-279.162 -226.961)" fill="#110809"/>
        <path id="Path_237" data-name="Path 237" d="M317.913,362.679a3.416,3.416,0,1,0,3.416-3.416A3.416,3.416,0,0,0,317.913,362.679Z" transform="translate(-276.507 -226.961)" fill="#110809"/>
        <path id="Path_238" data-name="Path 238" d="M308.119,295.406v-39.2A12.285,12.285,0,0,1,320.4,243.925h13.525a12.284,12.284,0,0,1,12.284,12.284V300.03a17.648,17.648,0,0,1-17.6,17.6h-44.1a2.07,2.07,0,0,1-2.07-2.07h0a2.07,2.07,0,0,1,2.07-2.07h5.521A18.08,18.08,0,0,0,308.119,295.406Z" transform="translate(-281.723 -243.925)" fill="#fff"/>
      </g>
      <path id="Path_239" data-name="Path 239" d="M266.405,361.642c-.528,2.917-1.3,5.416-3.9,7.473-3.821,3.021-8.877,2.581-12.884,5.226a2.882,2.882,0,0,0-.957.917,2.707,2.707,0,0,0,.147,2.633c2.442,4.031,9.964,1.991,13.617,1.919l13.792-.273a2.525,2.525,0,0,0,2.232-2.68v-15.6l.014-2.308c.024-3.824-2.611-6.8-5.837-6.591-2.789.179-5.14,2.826-5.7,6.145C266.737,359.6,266.586,360.643,266.405,361.642Z" transform="translate(-241.332 -167.257)" fill="#110809"/>
      <g id="Group_252" data-name="Group 252" transform="translate(3.228 10.61)">
        <path id="Path_240" data-name="Path 240" d="M290.794,263.879c-2.641,2.574-5.742,4.4-9.645,4.1-2.914-.225-5.545-1.75-8.062-3.238-5.288-3.127-8.658-8.517-11.946-13.5-4.542-6.892-10.422-13.113-13.425-20.917-1.481-3.85-2.522-7.126-2.212-11.327.3-4.089,1.336-8.222.42-12.308-.307-1.374-1.877-5.582.13-6.34,1.84-.7,3.571,2.3,3.963,3.609a23.544,23.544,0,0,1,.6,4.834c.588,7.018,3.509,16.652,9.265,21.168,1.865,1.464,4.2,2.194,6.183,3.492,3.9,2.551,6.059,7,9.257,10.383a4.84,4.84,0,0,0,2.831,1.742c3.3.344,3.767-3.579,5.053-5.563,2.489-3.838,8.966-8.457,13.719-7.4a8.361,8.361,0,0,1,6.312,6.909,16.312,16.312,0,0,1-.207,4.288,17.809,17.809,0,0,1-5.08,11.143C295.808,257.687,293.553,261.19,290.794,263.879Z" transform="translate(-245.051 -200.239)" fill="#ff9575"/>
        <path id="Path_241" data-name="Path 241" d="M289.2,269.065h0c.015-.054.024-.11.039-.165Z" transform="translate(-238.558 -190.141)" fill="#7a483a"/>
        <path id="Path_242" data-name="Path 242" d="M297.278,230.767l-.18.855h0Z" transform="translate(-237.396 -195.749)" fill="#7a483a"/>
        <path id="Path_243" data-name="Path 243" d="M289.346,268.5c-.03.165-.071.325-.112.484l.131-.622C289.357,268.4,289.354,268.45,289.346,268.5Z" transform="translate(-238.553 -190.22)" fill="#7a483a"/>
        <path id="Path_244" data-name="Path 244" d="M301.1,230.209c-.039,2.782-.3,5.482-.307,6.995a24.439,24.439,0,0,1-.468,5.6,22.457,22.457,0,0,1-1.792,4.607c-2.215,4.537-4.776,9.424-8.114,13.228a74.609,74.609,0,0,1-4.478-6.071c-1.955-3.23-3.824-6.509-5.7-9.787-1.034-1.811-4.234-5.162-3.65-7.178.421-1.453,5.423-5.063,6.646-6.593,2.828-3.538,8.729-9.894,13.641-10.27C300.576,220.461,301.171,225.456,301.1,230.209Z" transform="translate(-240.421 -197.225)" fill="#fad100"/>
      </g>
      <path id="Path_245" data-name="Path 245" d="M245.249,298.422a10.35,10.35,0,0,1,2.644-6.9c3.517-3.877,9.188-4.733,14.385-5.353l21.158-2.529c12.063-1.441,25-2.733,35.73,2.955,7.365,3.9,9.9,12.947,2.825,18.685-3.938,3.192-9.167,4.172-14.16,5.048l-21.382,3.751c-6.289,1.1-13.615,3.439-19.4-.47-1.533-1.036-2.853-2.427-4.569-3.118-4.889-1.966-13.846-3.026-16.4-8.351A8.681,8.681,0,0,1,245.249,298.422Z" transform="translate(-241.793 -177.544)" fill="#0e398a"/>
      <path id="Path_246" data-name="Path 246" d="M255.225,287a27.841,27.841,0,0,1,6.8-.7c6.631.057,11.405,6.773,7.97,12.959-.745,1.34-1.853,2.542-2.1,4.055a7.714,7.714,0,0,0,.852,4.076c5.807,14.154,14.711,29.411,16.313,44.673-4.2,2.106-16.631,3.387-19.07-.878q-3.243-5.675-6.488-11.348a58.55,58.55,0,0,0-4.431-6.967c-2.081-2.677-4.615-4.978-6.7-7.654a29.877,29.877,0,0,1-4.766-27.05C245.714,291.652,249.8,288.319,255.225,287Z" transform="translate(-242.236 -176.97)" fill="#0e398a"/>
      <path id="Path_247" data-name="Path 247" d="M268.9,304.191c8.085-11.229,25.219-9.639,31.782-22.764a5.9,5.9,0,0,1,.72-1.217c1.008-1.18,2.782-1.227,4.335-1.222,8.8.032,17.524-1.285,26.172-2.78,5.141-.89,5.067-.094,6.917,4.686a40.805,40.805,0,0,1,2.933,11.057c.466,5.247-.922,10.932-4.847,14.443a24.687,24.687,0,0,1-7.49,4.1,346.746,346.746,0,0,1-39.369,13.08,28.977,28.977,0,0,1-7.65,1.3,15.385,15.385,0,0,1-12.551-6.88C266.038,312.111,266.371,307.709,268.9,304.191Z" transform="translate(-238.595 -178.502)" fill="#2155b5"/>
      <path id="Path_248" data-name="Path 248" d="M289.829,225.265a12.832,12.832,0,0,1,3.078-3.244c4.329-3.274,10.537-2.97,15.666-3.136,5.373-.174,11.189.232,16.215,2.272,5.149,2.092,6.222,4.282,6.374,9.377.2,6.782,2.234,13.615,2.994,20.385a181.571,181.571,0,0,1,1.126,21.622c-.029,3.839-.171,7.68-.454,11.509-.318,4.294-.7,4.322-4.909,4.908-9.85,1.37-22.984,4.226-32.749,1.448-.516-17.468-6.406-34.3-8.858-51.606C287.591,233.715,287.356,229.078,289.829,225.265Z" transform="translate(-235.532 -186.892)" fill="#fad100"/>
      <path id="Path_249" data-name="Path 249" d="M307.783,232.7a8.985,8.985,0,0,0,3.765-2.833c4.011-5.223.182-8.781-.047-14.071-.166-3.85,2.5-7.7,1.266-11.351-.242-.717-.948-1.5-1.624-1.161a1.509,1.509,0,0,0-.536.564c-3.368,5.077-7.84,4.662-13.633,5.708.748-.135.926,12.541.754,13.4a19.874,19.874,0,0,0-.512,2.945,7.418,7.418,0,0,0,5.583,7.206A8.163,8.163,0,0,0,307.783,232.7Z" transform="translate(-234.186 -189.193)" fill="#ff9575"/>
      <path id="Path_250" data-name="Path 250" d="M311.341,211.329l-12.739.623,1.045,9.407h0c4.257,2.241,14.018,1.592,11.694-3.057Z" transform="translate(-233.946 -187.997)" fill="url(#linear-gradient-3)"/>
      <path id="Path_251" data-name="Path 251" d="M302.022,219.47a6.811,6.811,0,0,0,4.913-1.945c1.336-1.148,2.443-2.538,3.722-3.751a14.029,14.029,0,0,0,2.19-2.286,10.283,10.283,0,0,0,1.276-3.986c.3-1.725.836-3.293.442-5.072a9.9,9.9,0,0,0-1.231-2.8q-1.122-1.917-2.419-3.725c-1.741-2.425-4.624-4.5-7.766-3.325a6.405,6.405,0,0,0-3.374,3.149c-.944,1.87-1.077,4.05-1.9,5.974-.273.634-.617,1.233-.926,1.85-1.281,2.567-2.326,5.821-1.663,8.691a9.95,9.95,0,0,0,4,6.239A5.773,5.773,0,0,0,302.022,219.47Z" transform="translate(-234.465 -190.804)" fill="#ff9575"/>
      <path id="Path_252" data-name="Path 252" d="M317.31,200.327c-1.882-.747-1.925-1.758-2.443-3.593a7.642,7.642,0,0,0-3.613-4.439,10.85,10.85,0,0,0-5.652-1.3,9.808,9.808,0,0,0-4.5,1.12,6.273,6.273,0,0,0-2.978,3.47,3.119,3.119,0,0,0-.072,1.9c.5,1.514,2.712,2.128,4.032,2.649a43.555,43.555,0,0,1,4.5,1.9c2.81,1.49,4.179,3.78,2.77,6.869-.521,1.141-.686,3.134-2.56,2.653a2.835,2.835,0,0,1-1.31-1.066,8.185,8.185,0,0,0-2.681-2.139,7.041,7.041,0,0,0-3.394-.463,14.082,14.082,0,0,1-3.083.114c-.4-.087-.257-.731-.781-.72-.328.007-.489.4-.567.715l-.878,3.571c-.493,2.007-.978,4.7-.07,6.648a7.162,7.162,0,0,0,3.5,3.31c2.861,1.375,6.935,1.7,9.654-.19,2.021-1.407,2.544-3.651,4.234-5.227,1.491-1.39,4.436-1.606,5.273-3.618.515-1.234-.128-2.72.373-3.964C318.2,205.7,322.9,202.542,317.31,200.327Zm-20.77,13.912a1.218,1.218,0,0,1-.254-.426,2.585,2.585,0,0,1,1.03-2.736,4.19,4.19,0,0,1,5.074.015c.894.718,1.971,1.7,2.075,2.908a1.759,1.759,0,0,1-.537,1.474,1.84,1.84,0,0,1-1.979.142c-1.006-.5-1.266-1.647-2.552-1.191-.762.27-.832.575-1.647.395A2.253,2.253,0,0,1,296.54,214.239Z" transform="translate(-234.695 -190.989)" fill="#110809"/>
      <path id="Path_253" data-name="Path 253" d="M309.2,206.28a3.974,3.974,0,0,0-.41.491,8.068,8.068,0,0,0-1.351,3.252,3.455,3.455,0,0,0,1.8.381,2.653,2.653,0,0,0,1.169-.617,4.605,4.605,0,0,0,1.568-2.876,1.45,1.45,0,0,0-.163-1.034,1.274,1.274,0,0,0-1.365-.356A2.789,2.789,0,0,0,309.2,206.28Z" transform="translate(-232.646 -188.86)" fill="#ff9575"/>
      <path id="Path_254" data-name="Path 254" d="M302.938,211.273a4.383,4.383,0,0,1-3.724.291,3.545,3.545,0,0,1-2.393-2.79" transform="translate(-234.208 -188.373)" fill="#fff"/>
      <g id="Group_253" data-name="Group 253" transform="translate(54.21 36.231)">
        <path id="Path_255" data-name="Path 255" d="M336.306,277.814c.779-.086,1.569-.14,2.372-.156-2.465-3.126-5.668-5.615-8.087-8.777-2.717-3.552-2.433-7.091-3.715-11.074-1.06-3.289-3.14-6.186-4.171-9.531-2.51-8.134.54-22.74,12.316-19.17a13.875,13.875,0,0,1,7.692,6.742,41.271,41.271,0,0,1,3.5,9.851l5.664,21.587c.846,3.223,1.477,6.533,2.262,9.733,1.1,4.491,3.836,9.525-2.53,11.325a56.934,56.934,0,0,1-11.8,1.612c-7.439.491-14.922,1.276-22.373,1.475-4.527.122-8.866-1.3-13.327-1.8-3.881-.437-7.613.929-11.415.286-1.6-.271-3.3-1.449-3.2-3.064a3.417,3.417,0,0,1,1.385-2.277c2.836-2.356,6.977-2.355,10.547-1.441a7.762,7.762,0,0,0,2.467.4,8.116,8.116,0,0,0,2.482-.8c5.156-2.168,9.772-.463,14.876-1.254S331.124,278.385,336.306,277.814Z" transform="translate(-289.495 -221.695)" fill="#ff9575"/>
        <path id="Path_256" data-name="Path 256" d="M314.434,245.175c-.083-.27-.163-.539-.243-.809-1.18-3.978-2.137-8.159-1.452-12.251s3.363-8.093,7.358-9.211a12.2,12.2,0,0,1,7.831.84c6.109,2.521,10.555,7.783,13.414,13.6,1.356,2.761,3.176,6.027,1.708,8.231-1.81,2.715-23.532,13.041-22.644,14.888A108.13,108.13,0,0,1,314.434,245.175Z" transform="translate(-286.111 -222.574)" fill="#fad100"/>
      </g>
      <path id="Path_257" data-name="Path 257" d="M270.171,302.007c-5.055,9.483-2.708,21.127-3.69,31.356q-1.874,19.5-4.874,38.866a61.961,61.961,0,0,1,19.536,1.57c3.655-12.479,7.325-25.042,8.663-37.976.408-3.949.6-7.965,1.808-11.749,1.458-4.588,4.35-8.686,5.3-13.407,1.206-6-3.932-15.8-10.425-16.541C280.988,293.5,272.843,297,270.171,302.007Z" transform="translate(-239.388 -175.83)" fill="#2155b5"/>
    </g>
    <g id="Group_255" data-name="Group 255" transform="translate(324.324 835.002)">
      <path id="Rectangle_26" data-name="Rectangle 26" d="M0,0H61.443a0,0,0,0,1,0,0V2.147a0,0,0,0,1,0,0H1.524A1.524,1.524,0,0,1,0,.624V0A0,0,0,0,1,0,0Z" transform="translate(82.546 36.449) rotate(180)" fill="#fff"/>
      <path id="Path_258" data-name="Path 258" d="M207.265,282.536h61.4l-10.95-32.428a2.873,2.873,0,0,0-2.758-1.873H199.61a2.711,2.711,0,0,0-2.759,3.46Z" transform="translate(-196.718 -248.235)" fill="#fff5f5"/>
      <rect id="Rectangle_27" data-name="Rectangle 27" width="61.692" height="2.147" transform="translate(72.239 36.449) rotate(180)" fill="#c9cdd1"/>
      <path id="Path_259" data-name="Path 259" d="M225.074,263.224a2.534,2.534,0,0,0,2.871,1.97,2.5,2.5,0,0,0,2.5-2.455,2.832,2.832,0,0,0-2.86-2.524.467.467,0,0,0-.233.062.461.461,0,0,0-.241,0A2.687,2.687,0,0,0,225.074,263.224Zm2.271-2.162a.53.53,0,0,0,.116-.046.5.5,0,0,0,.1.014,2.062,2.062,0,0,1,1.733.931c.809,1.41-.327,2.379-1.526,2.43A1.679,1.679,0,0,1,227.346,261.062Z" transform="translate(-192.552 -246.473)" fill="#c9cdd1"/>
    </g>
    <g id="Group_257" data-name="Group 257" transform="translate(486.579 805.008)">
      <path id="Path_260" data-name="Path 260" d="M355.015,233.735l-.006-.073c-.153-2.163,1.355-4.256.934-6.383a.822.822,0,0,0-.329-.585c-.339-.192-.747.107-.99.412-1.488,1.867-1.342,4.329-1.065,6.557.3,2.4-.878,4.339-1.257,6.684-.265,1.637.039,3.113-.3,4.7a10.067,10.067,0,0,0-.032,5.442,6.3,6.3,0,0,1,.551,2.416,10.149,10.149,0,0,1-1.2,2.771c-1.032,2.5.392,5.647-1.029,7.95-.148.24-.369.484-.649.469-.34-.017-.53-.39-.647-.709-1.6-4.383-3.211-9.329-2.563-14.051.141-1.034.406-2.051.476-3.091a16.68,16.68,0,0,0-.134-2.869q-.353-3.374-.707-6.748a2.417,2.417,0,0,0-.334-1.181.833.833,0,0,0-1.082-.292,1.112,1.112,0,0,0-.379.766c-.393,2.28.475,4.611.27,6.917a37.668,37.668,0,0,1-.811,3.8c-.545,2.811.346,6.473.874,9.265.381,1.986.559,4.025.842,6.027.154,1.079.611,2.126.726,3.187.088.818-.592,3.515-1.388,1.607a5.468,5.468,0,0,1-.41-2.481,7.071,7.071,0,0,0,.062-1.337,1.292,1.292,0,0,0-.7-1.072c-.423-.175-.937-.014-1.346-.221-.474-.241-.593-.85-.658-1.377a10.119,10.119,0,0,1,.167-4.748,8.744,8.744,0,0,0,.514-1.428c.218-1.211-.756-1.744-.976-2.782-.208-.978.55-1.827.671-2.759a1.733,1.733,0,0,0-.973-1.75,1.363,1.363,0,0,1-.549-.315.956.956,0,0,1-.018-.873,16.226,16.226,0,0,1,.652-1.693,1.471,1.471,0,0,0-2.424-1.617,2.915,2.915,0,0,0-.482,2.15,86.766,86.766,0,0,0,1.31,13.712,32.351,32.351,0,0,0,3.113,8.915c.7,1.3,1.717,2.462,2.335,3.766.89,1.881,1.482,4.521,4.229,3.961a6.911,6.911,0,0,0,3.707-2.76c1.473-1.826,2.81-4.3,1.794-6.41a15.5,15.5,0,0,1-.915-1.678,4.425,4.425,0,0,1-.142-1.569,32.244,32.244,0,0,1,.685-5.517c.445-2.085,1.334-4.557.708-6.694-.533-1.817-1.148-3.251-.668-5.255.492-2.051,2.074-3.836,2.018-5.944C356.446,237.133,355.173,235.54,355.015,233.735Z" transform="translate(-338.149 -221.418)" fill="#006149"/>
      <g id="Group_256" data-name="Group 256" transform="translate(3.986)">
        <path id="Path_261" data-name="Path 261" d="M354.7,276.807a10.912,10.912,0,0,0,3.813-9.309c-.215-1.661-.771-3.6.364-4.829.38-.413.908-.676,1.261-1.114.856-1.066.351-2.639-.258-3.863-1.078-2.165-1.672-4.293-.181-6.35,1.151-1.588,3.1-2.7,3.516-4.75.555-2.744-2.234-5.714-.843-8.143a15.874,15.874,0,0,1,1.51-1.752,4.22,4.22,0,0,0,.627-4,3.86,3.86,0,0,0-2.022-2.251c-1.3-.395-1.3.219-1.734,1.477a18.525,18.525,0,0,0-1,7.169c.218,3.661-1.227,7.719-1.814,11.339-.319,1.965-.694,3.922-1.087,5.873a37.165,37.165,0,0,1-.78,3.634c-.18.585-1.069,1.747-1.024,2.282-.4-4.721,1.99-9.212,2.6-13.912a2.034,2.034,0,0,0-.111-1.232,7.789,7.789,0,0,0-.627-.755c-.956-1.327.306-4.417.5-5.974.342-2.686.459-5.416.658-8.117.242-3.268.7-6.414.314-9.724a.545.545,0,0,0-.128-.349.375.375,0,0,0-.508.069,1.024,1.024,0,0,0-.2.527c-.764,4.78-.973,9.665-1.724,14.481-.816,5.24-1.4,10.488-1.99,15.754-.569,5.083-1.812,10.126-2.413,15.231-.131,1.115-.512,2.513-1.625,2.651-.99.122-1.744-.945-1.867-1.934-.333-2.652,1.956-4.829,2.946-7.311a10.174,10.174,0,0,0,.358-5.764c-.4-1.955-1.154-4.178.023-5.788.474-.648,1.211-1.091,1.585-1.8a4.057,4.057,0,0,0,.349-1.744,39.2,39.2,0,0,0-.172-5.625c-.167-1.624-.775-3.379-.637-5,.272-3.179,1.155-6.286.981-9.521-.15-2.794-2.368,1.544-2.432,2.672-.084,1.477.172,2.969-.033,4.435-.233,1.655-1.05,3.268-.787,4.919a1.376,1.376,0,0,1,.017.647c-.068.208-.317.373-.506.265a.5.5,0,0,1-.19-.265c-1.1-2.8,1.521-7.776-.8-10.1-1.838,2.277-1.395,7.487-1.27,10.27.319,7.117,1.528,14.336.169,21.416a2.328,2.328,0,0,1-.621,1.382,1.694,1.694,0,0,1-2.274-.412,5.851,5.851,0,0,1-.957-2.362,3.746,3.746,0,0,0-1.233-2.2,3.49,3.49,0,0,0-.75,2.879,17.825,17.825,0,0,0,.848,2.954,16.529,16.529,0,0,1,.769,5.208c-.026,1.847-.351,3.769.322,5.49,1.129,2.886,6.116,5.613,9.037,4.439A7.9,7.9,0,0,0,354.7,276.807Z" transform="translate(-341.644 -222.087)" fill="#40a876"/>
      </g>
      <path id="Path_262" data-name="Path 262" d="M338.169,262.117l24.1.408-6.344,20.558-13.766.19Z" transform="translate(-338.169 -216.199)" fill="#0e398a"/>
    </g>
    <g id="Group_258" data-name="Group 258" transform="translate(137.975 871.529)">
      <rect id="Rectangle_28" data-name="Rectangle 28" width="477.489" height="4.897" transform="translate(0)" fill="#fff"/>
      <rect id="Rectangle_29" data-name="Rectangle 29" width="4.648" height="127.831" transform="translate(428.68 2.486)" fill="#fff"/>
      <path id="Path_263" data-name="Path 263" d="M371.268,409.049h-4.648l30.01-126.8h4.648Z" transform="translate(14.619 -279.76)" fill="#fff"/>
      <path id="Path_264" data-name="Path 264" d="M113.975,409.049h4.648l-30.01-126.8H83.965Z" transform="translate(-26.952 -279.76)" fill="#fff"/>
      <rect id="Rectangle_30" data-name="Rectangle 30" width="4.648" height="127.831" transform="translate(40.539 2.486)" fill="#fff"/>
    </g>
    <g id="Group_259" data-name="Group 259" transform="translate(103.483 862.394)">
      <path id="Path_265" data-name="Path 265" d="M69.133,385.351q-.141,1.117-.243,2.245c-.236,2.647-.712,5.7-3,7.046-2.052,1.21-4.837.414-6.5-1.288a12.337,12.337,0,0,1-2.876-6.428,34.056,34.056,0,0,1,1.458-18.118c1.313-3.574,5.053-9.009,4.626-12.868-.451-4.072-5.248-6.439-5.435-10.8-.206-4.849,4.16-8.946,6.668-12.661a8.771,8.771,0,0,0,1.748-4.462c.2-5.7-8.044-8.585-8.6-14.259-.684-7.005,9-9.818,9.092-16.5.052-3.605-3.14-7.368-2.824-11.657a18.888,18.888,0,0,1,4.891-11.51c1.152-1.227,2.806-2.373,4.4-1.843s2.246,2.473,2.223,4.156-.484,3.379-.143,5.028c1.025,4.942,4.427,6.721,4,12.344a25.6,25.6,0,0,1-2.776,9.289c-5.086,10.019-3.563,8.849,2.514,17.51a14.037,14.037,0,0,1,0,15.7c-1.856,2.647-4.753,4.877-5.078,8.094a8.94,8.94,0,0,0,.874,4.321A25.554,25.554,0,0,1,76,353.666c.449,2.448-.221,4.946-.886,7.345C72.848,369.2,70.186,377.028,69.133,385.351Z" transform="translate(-37.005 -272.115)" fill="#138f70"/>
      <path id="Path_266" data-name="Path 266" d="M51.334,373.527q-.68-4.685-1.362-9.37a26.527,26.527,0,0,0-2.051-8.055c-1.1-2.221-2.811-4.105-3.819-6.371-2.182-4.907-.716-10.6.905-15.716,1.124-3.554,2.318-7.371,1.215-10.932a26.2,26.2,0,0,0-2.722-5.172c-1.973-3.41-5.644-11.54-3.336-15.572,3.929-6.863,7.7-.328,7.979,4.371a19.042,19.042,0,0,0,4.734,11.435c1.6,1.8,3.6,3.375,4.484,5.62,2.337,5.936-4.294,11.98-3.768,18.338.419,5.062,5.157,8.621,7.059,13.334,3.512,8.705,5.957,21.371,3.528,30.577a6.921,6.921,0,0,1-2.44,4.178c-2.187,1.464-5.341.3-6.931-1.8s-2.017-4.826-2.4-7.43Q51.875,377.246,51.334,373.527Z" transform="translate(-39.427 -268.107)" fill="#47ba9b"/>
      <path id="Path_267" data-name="Path 267" d="M64.7,405.239h0a17.035,17.035,0,0,1-16.909-14.964l-2.623-21.4H83.855l-2.213,21.107A17.036,17.036,0,0,1,64.7,405.239Z" transform="translate(-38.583 -257.885)" fill="#2155b5"/>
      <path id="Path_268" data-name="Path 268" d="M68.266,368.853c.334-3.237.031-6.726,1.722-9.507,2.239-3.681,7.529-5.286,8.462-9.493.911-4.117-3.033-8.537-1.2-12.332,1.538-3.182,6.278-3.784,7.735-7,1.284-2.84-.65-6.008-1.159-9.083a10.124,10.124,0,0,1,4.245-9.825,3.546,3.546,0,0,1,2.092-.739c2.015.106,2.777,2.871,2.07,4.762s-2.248,3.392-2.871,5.311c-1.677,5.169,3.6,11.815-.184,15.72-1.123,1.161-2.789,1.648-3.967,2.752-3.922,3.675-.028,10.645-2.354,15.489-1.558,3.245-5.545,4.724-7.243,7.9-1.1,2.053-1.068,4.493-1.436,6.792a11.124,11.124,0,0,1-3.242,6.659c-1.983,1.762-4.788,2.574-5.271-.513C65.418,374.124,68.061,370.833,68.266,368.853Z" transform="translate(-35.57 -266.416)" fill="#47ba9b"/>
      <path id="Path_269" data-name="Path 269" d="M50.531,382.639c4.355-1.014,8.724-1.966,12.985-3.143q9.832-2.715,19.591-5.675l.24-.093.509-4.857H45.168l1.792,14.621q1.781-.439,3.571-.853" transform="translate(-38.583 -257.885)" fill="url(#linear-gradient-4)"/>
      <path id="Path_270" data-name="Path 270" d="M86.673,372.086H44.116L41.795,362.8H88.221Z" transform="translate(-39.079 -258.777)" fill="#2155b5"/>
    </g>
    <g id="Group_261" data-name="Group 261" transform="translate(601.944 876.454)">
      <path id="Path_271" data-name="Path 271" d="M419.046,366.862c-1.287-4.991-3.013-10.217-1.61-15.177.935-3.306,3.188-6.122,4.105-9.434,1.494-5.387-.75-11.186.232-16.69.934-5.234,4.633-9.509,6.626-14.439,2.622-6.489,2.189-13.846,4.41-20.483a10,10,0,0,1,3.691-5.488c1.9-1.2,4.783-1.067,6.043.8a6.121,6.121,0,0,1,.732,3.714c-.564,13.8-11.748,26.191-9.7,39.847.582,3.886,2.236,7.806,1.258,11.612-.968,3.772-4.3,6.465-5.9,10.013-1.755,3.881-1.287,8.36-.718,12.58s1.183,8.648-.327,12.63c-.785,2.067-7.246,9.2-9.51,5.492-.268-.439,1.715-5.648,1.775-6.632A27.255,27.255,0,0,0,419.046,366.862Z" transform="translate(-392.719 -284.372)" fill="#138f70"/>
      <g id="Group_260" data-name="Group 260" transform="translate(0 11.291)">
        <path id="Path_272" data-name="Path 272" d="M417.018,371.428c0-.107,0-.215-.01-.321-.166-3.288-2.462-6.07-4.934-8.244-4.1-3.6-7.988-12.979-8.706-18.432-.493-3.75.146-7.563-.14-11.333-.442-5.828-3.046-11.236-4.786-16.815a58.044,58.044,0,0,1-2.615-15.894c-.05-2.129.221-4.637,2.053-5.724a4.227,4.227,0,0,1,4.827.812,9.838,9.838,0,0,1,2.478,4.55c2.511,8.331,1.565,17.578,5.165,25.5,1.644,3.614,4.21,6.884,4.947,10.786.8,4.243-.677,8.714.381,12.9,1.77,7.013,8.628,10.99,9.189,18.579a12.135,12.135,0,0,0,.3,2.542,13.345,13.345,0,0,0,2,3.388c1.882,2.857,4.882,9.138,1.825,11.639-5.58,4.567-12.136-5.1-12.239-9.866C416.724,374.136,417.032,372.785,417.018,371.428Z" transform="translate(-395.821 -294.215)" fill="#47ba9b"/>
        <path id="Path_273" data-name="Path 273" d="M430.089,356.549a17.913,17.913,0,0,1,2.264-5.415c2.1-2.971,6-4.673,7.1-8.141a24.4,24.4,0,0,0,.539-4.476,9.661,9.661,0,0,1,3.878-6.783,7.181,7.181,0,0,1,7.6-.421c2.972,1.8,3.878,5.628,4.44,9.057,1.749,10.658,2.639,22.21-2.546,31.685a2.889,2.889,0,0,1-.929,1.141c-1.208.741-2.7-.466-3.367-1.718-3.192-6.024.795-14-2.353-20.044-.249-.478-.624-.988-1.163-1.012a1.473,1.473,0,0,0-1.085.6c-1.6,1.794-1.793,4.517-3.377,6.324-1.428,1.631-3.745,2.239-5.229,3.817a11.069,11.069,0,0,0-2.216,4.892c-.842,3.154-1.754,15.806-6.418,10.317C423.161,371.579,428.624,361.526,430.089,356.549Z" transform="translate(-391.419 -288.891)" fill="#47ba9b"/>
        <path id="Path_274" data-name="Path 274" d="M425.858,407.057h-.651a17.978,17.978,0,0,1-17.844-15.791L404.5,367.9h41.656l-2.417,23.053A17.977,17.977,0,0,1,425.858,407.057Z" transform="translate(-394.545 -283.378)" fill="#2155b5"/>
        <path id="Path_275" data-name="Path 275" d="M424.254,379.339q10.583-2.923,21.094-6.11l.258-.1.548-5.23H404.5l1.929,15.741C412.378,382.175,418.41,380.953,424.254,379.339Z" transform="translate(-394.545 -283.378)" fill="url(#linear-gradient-5)"/>
        <path id="Path_276" data-name="Path 276" d="M449.188,371.36H403.366l-2.5-10h49.987Z" transform="translate(-395.079 -284.339)" fill="#2155b5"/>
      </g>
    </g>
    <g id="Group_266" data-name="Group 266" transform="translate(588.898 819.718)">
      <g id="Group_265" data-name="Group 265">
        <g id="Group_264" data-name="Group 264" clipPath="url(#clip-path-2)">
          <g id="Group_263" data-name="Group 263">
            <g id="Group_262" data-name="Group 262">
              <path id="Path_277" data-name="Path 277" d="M357.332,357.237a3.1,3.1,0,0,1,1.483-2.455c1.31-.685,3.057-.113,4.582-.368,4.282-.715,4.249-6.05,4.162-8.988q-.084-2.8-.282-5.6a2.829,2.829,0,0,0-.368-1.434,2.539,2.539,0,0,0-2.666-.741,4.858,4.858,0,0,0-3.259,4c-.332,1.714-.17,3.5-.674,5.177a2.862,2.862,0,0,1-.417.9,2.262,2.262,0,0,1-.937.714,2.025,2.025,0,0,1-1.74-.021c-.591-.339-.493-.765-.49-1.28a14.127,14.127,0,0,0-.177-1.873c-.182-1.4-.339-2.813-.487-4.221-.291-2.768-.543-5.539-.867-8.3a8.667,8.667,0,0,0-1.018-3.707c-3.067-4.915-7.741-.6-8.17,2.987-.976,8.154,3.781,15.843,3.383,23.936a2.2,2.2,0,0,1-.211,1c-.716,1.274-3,1.051-4.063.316-1.658-1.152-1.065-3.647-1.066-5.148a3.273,3.273,0,0,0-1.769-3.184,3.854,3.854,0,0,0-4.329,1.331c-1.108,1.55-.985,4.2-.818,5.93a6.75,6.75,0,0,0,1.271,3.566,5.164,5.164,0,0,0,3.719,1.966c.886.048,1.9-.12,2.568.353.818.577.524,1.632.557,2.513.064,1.751,1.735,3.246,3.685,3.982a21.052,21.052,0,0,0,6.293.972c1.818-2.6,1.939-5.6,2.034-8.514A27.734,27.734,0,0,1,357.332,357.237Z" transform="translate(-337.027 -326.822)" fill="#138f70"/>
              <path id="Path_278" data-name="Path 278" d="M355.8,327.25c2.183,13.523,4.065,27.288,1.377,40.75" transform="translate(-341.266 -326.954)" fill="rgba(0,0,0,0)" stroke="#47ba9b" strokeMiterlimit="10" strokeWidth="0.5"/>
              <path id="Path_279" data-name="Path 279" d="M352.736,327.117a31.706,31.706,0,0,0-1.222,7.626c.026,4.9,2.338,9.579,3.245,14.418,1.014,5.407.263,10.918-.489,16.353" transform="translate(-340.298 -326.913)" fill="rgba(0,0,0,0)" stroke="#47ba9b" strokeMiterlimit="10" strokeWidth="0.5"/>
              <path id="Path_280" data-name="Path 280" d="M359.207,356.029c2.637-.336,5.788-.676,7.455-2.562a2.7,2.7,0,0,0,.555-.881,12.25,12.25,0,0,0,.214-4.045c-.006-1.26.027-3.472.027-5.107" transform="translate(-342.035 -331.955)" fill="rgba(0,0,0,0)" stroke="#47ba9b" strokeMiterlimit="10" strokeWidth="0.5"/>
              <path id="Path_281" data-name="Path 281" d="M353.184,369.867c-2.37.345-4.992.654-7.035-.384a5.379,5.379,0,0,1-2.546-2.855,9.468,9.468,0,0,1-.525-3.564,21.376,21.376,0,0,1,.375-3.911" transform="translate(-338.393 -336.812)" fill="rgba(0,0,0,0)" stroke="#47ba9b" strokeMiterlimit="10" strokeWidth="0.5"/>
              <path id="Path_282" data-name="Path 282" d="M359.244,357.643a47.266,47.266,0,0,0,8.791-2.027,4.7,4.7,0,0,0,1.638-.807,3.333,3.333,0,0,0,.868-1.513c.415-1.222.59-9.592.132-11.636" transform="translate(-342.043 -331.406)" fill="rgba(0,0,0,0)" stroke="#47ba9b" strokeMiterlimit="10" strokeWidth="0.5"/>
            </g>
          </g>
        </g>
      </g>
      <path id="Path_284" data-name="Path 284" d="M344.739,382.059l17.748-.378-.968,7.914a7.818,7.818,0,0,1-7.762,6.87h0a7.82,7.82,0,0,1-7.71-6.536Z" transform="translate(-338.769 -344.352)" fill="#fad100"/>
    </g>
    <g id="Group_267" data-name="Group 267" transform="translate(428.599 695.286)">
      <circle id="Ellipse_9" data-name="Ellipse 9" cx="16.269" cy="16.269" r="16.269" transform="translate(0 23.008) rotate(-45)" fill="#80b2ba"/>
      <path id="Path_285" data-name="Path 285" d="M307.006,137.711c0,.775.774,12.4.774,12.4l9.3-6.2" transform="translate(-284.772 -124.774)" fill="rgba(0,0,0,0)" stroke="#fff" strokeMiterlimit="10" strokeWidth="1"/>
    </g>
    <g id="Group_269" data-name="Group 269" transform="translate(399.748 829.856)">
      <path id="Path_286" data-name="Path 286" d="M262.472,271.639l8.688-5.312h42.256a7.847,7.847,0,0,0,7.825-7.869v-6.841a7.847,7.847,0,0,0-7.825-7.868H270.3a7.846,7.846,0,0,0-7.824,7.868Z" transform="translate(-262.472 -243.749)" fill="rgba(0,0,0,0)" stroke="#fff" strokeMiterlimit="10" strokeWidth="1"/>
      <g id="Group_268" data-name="Group 268" transform="translate(19.48 9.629)">
        <path id="Path_287" data-name="Path 287" d="M282.755,253.8a1.651,1.651,0,1,1-1.651-1.66A1.655,1.655,0,0,1,282.755,253.8Z" transform="translate(-279.454 -252.143)" fill="#fff"/>
        <path id="Path_288" data-name="Path 288" d="M291.39,253.8a1.651,1.651,0,1,1-1.651-1.66A1.655,1.655,0,0,1,291.39,253.8Z" transform="translate(-278.184 -252.143)" fill="#fff"/>
        <path id="Path_289" data-name="Path 289" d="M300.025,253.8a1.651,1.651,0,1,1-1.651-1.66A1.655,1.655,0,0,1,300.025,253.8Z" transform="translate(-276.914 -252.143)" fill="#fff"/>
      </g>
    </g>
    <g id="Group_271" data-name="Group 271" transform="translate(507.824 719.456)">
      <path id="Path_290" data-name="Path 290" d="M356.69,175.4l8.688-5.312h42.256a7.848,7.848,0,0,0,7.825-7.869v-6.841a7.848,7.848,0,0,0-7.825-7.869h-43.12a7.847,7.847,0,0,0-7.824,7.869Z" transform="translate(-356.69 -147.504)" fill="rgba(0,0,0,0)" stroke="#fff" strokeMiterlimit="10" strokeWidth="1"/>
      <g id="Group_270" data-name="Group 270" transform="translate(19.48 9.63)">
        <path id="Path_291" data-name="Path 291" d="M376.973,157.559a1.651,1.651,0,1,1-1.651-1.66A1.655,1.655,0,0,1,376.973,157.559Z" transform="translate(-373.672 -155.899)" fill="#fff"/>
        <path id="Path_292" data-name="Path 292" d="M385.608,157.559a1.651,1.651,0,1,1-1.651-1.66A1.655,1.655,0,0,1,385.608,157.559Z" transform="translate(-372.402 -155.899)" fill="#fff"/>
        <path id="Path_293" data-name="Path 293" d="M394.243,157.559a1.651,1.651,0,1,1-1.651-1.66A1.655,1.655,0,0,1,394.243,157.559Z" transform="translate(-371.132 -155.899)" fill="#fff"/>
      </g>
    </g>
    <g id="Group_273" data-name="Group 273" transform="translate(352.102 751.995)">
      <path id="Path_294" data-name="Path 294" d="M279.7,203.761l-8.688-5.312H228.759a7.847,7.847,0,0,1-7.824-7.869v-6.84a7.847,7.847,0,0,1,7.824-7.869H271.88a7.847,7.847,0,0,1,7.824,7.869Z" transform="translate(-220.935 -175.871)" fill="rgba(0,0,0,0)" stroke="#fff" strokeMiterlimit="10" strokeWidth="1"/>
      <g id="Group_272" data-name="Group 272" transform="translate(16.178 9.629)">
        <path id="Path_295" data-name="Path 295" d="M252.308,185.926a1.651,1.651,0,1,0,1.652-1.661A1.656,1.656,0,0,0,252.308,185.926Z" transform="translate(-232.499 -184.265)" fill="#fff"/>
        <path id="Path_296" data-name="Path 296" d="M243.674,185.926a1.651,1.651,0,1,0,1.651-1.661A1.655,1.655,0,0,0,243.674,185.926Z" transform="translate(-233.769 -184.265)" fill="#fff"/>
        <path id="Path_297" data-name="Path 297" d="M235.039,185.926a1.651,1.651,0,1,0,1.651-1.661A1.655,1.655,0,0,0,235.039,185.926Z" transform="translate(-235.039 -184.265)" fill="#fff"/>
      </g>
    </g>
    <g id="Group_274" data-name="Group 274" transform="translate(227.154 698.612)">
      <path id="Path_298" data-name="Path 298" d="M112.007,132.857a67.034,67.034,0,0,1,3.025,13.105" transform="translate(-112.007 -128.815)" fill="rgba(0,0,0,0)" stroke="#fff" strokeMiterlimit="10" strokeWidth="1"/>
      <path id="Path_299" data-name="Path 299" d="M122.825,129.333l-2.516,14.618" transform="translate(-110.786 -129.333)" fill="rgba(0,0,0,0)" stroke="#fff" strokeMiterlimit="10" strokeWidth="1"/>
      <path id="Path_300" data-name="Path 300" d="M134.131,135.5a21.15,21.15,0,0,0-4.56,6.536" transform="translate(-109.424 -128.426)" fill="rgba(0,0,0,0)" stroke="#fff" strokeMiterlimit="10" strokeWidth="1"/>
    </g>
  </g>
</svg>


       
  );
};

SecurityVisitor.propTypes = {};

export default SecurityVisitor;
