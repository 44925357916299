import axios from "axios";
import {
  KNOWLEDGE_CENTER_ERRORS,
  KNOWLEDGE_CENTER_LOADING,
  ADD_KNOWLEDGE_CENTER_INFORMATION,
  UPDATE_KNOWLEDGE_CENTER_INFORMATION,
  GET_SINGLE_KNOWLEDGE_CENTER_INFO,
  GET_TEAM_DATA,
} from "./types";
import { showSuccess, showError } from "./ActionsHelper";
import { transformErrorsFromApi } from "./TransformError";

//  Loading
export const setKnowledgeCenterLoading = () => {
  return {
    type: KNOWLEDGE_CENTER_LOADING,
  };
};

export const addKnowedgeCenterInformation = (
  userData,
  setErrors,
  setSubmitting,
  history
) => (dispatch) => {
  dispatch(setKnowledgeCenterLoading());
  axios
    .post("/api/space/knowledgecenter", userData)
    .then((result) => {
      dispatch({
        type: ADD_KNOWLEDGE_CENTER_INFORMATION,
        payload: result.data,
      });
      history.push("/settings");
      dispatch(showSuccess("Information "));
      setSubmitting(false);
    })
    .catch((err) => {
      dispatch({ type: KNOWLEDGE_CENTER_ERRORS, payload: err.data });
      dispatch(showError("There was some error while add information."));
      history.push("/settings");
      setSubmitting(false);
      setErrors(transformErrorsFromApi(err));
    });
};
export const updateKnowedgeCenterInformation = (
  userData,
  setSubmitting,
  history
) => (dispatch) => {
  // dispatch(setKnowledgeCenterLoading());
  axios
    .put(`/api/space/update/knowledgecenter`, userData)
    .then((result) => {
      dispatch({
        type: UPDATE_KNOWLEDGE_CENTER_INFORMATION,
        payload: result.data,
      });
      dispatch(showSuccess("", "Information updated successfully!"));
      history.push("/settings");
      setSubmitting(false);
    })
    .catch((err) =>
      dispatch({
        type: KNOWLEDGE_CENTER_ERRORS,
        // payload: err.response.data
        payload: ((err || {}).response || {}).data || "Error unexpected",
      })
    );
};

export const getSingleKnowledgeCenterInformation = () => (dispatch) => {
  dispatch(setKnowledgeCenterLoading());
  axios
    .get(`/api/space/getone/knowledgecenter`)
    .then((result) => {
      dispatch({
        type: GET_SINGLE_KNOWLEDGE_CENTER_INFO,
        payload: result.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: KNOWLEDGE_CENTER_ERRORS,
        payload: err && err.response ? err.response.data : "",
      });
    });
};

export const getTeamData = () => (dispatch) => {
  dispatch(setKnowledgeCenterLoading());
  axios
    .get(`/api/user/sysuser/knowledgecenter`)
    .then((result) => {
      dispatch({
        type: GET_TEAM_DATA,
        payload: result.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: KNOWLEDGE_CENTER_ERRORS,
        payload: err && err.response ? err.response.data : "",
      });
    });
};
