const getAllocatedBookingSlotsInRange = (date, totalSlots, type) => {
  const dateTime = [];
  const previous = date;

  if (type === "start") {
    /* the logic for start is ->, 
     1) make it round about 30
        2) add both slots ie. if slot is 15:14 add 15:00 and 15:30 to cutdownlist 
          3) loop through slots and add all the slots after that
     */
    const remainder = 0 - (date.minute() % 30); // to divide it in 30 mins slots

    if (remainder === 0) {
      dateTime.push(previous.format("HH:mm"));
      if (totalSlots !== 1) {
        dateTime.push(previous.add(30, "minutes").format("HH:mm"));
      }
    } else {
      dateTime.push(previous.add(remainder, "minutes").format("HH:mm"));
      dateTime.push(previous.add(30, "minutes").format("HH:mm"));
    }

    const iterateSlots = totalSlots - 1;
    // eslint-disable-next-line no-unused-vars
    // eslint-disable-next-line array-callback-return
    Array.from(Array(iterateSlots)).map(() => {
      dateTime.push(previous.add(30, "minutes").format("HH:mm"));
    });

    // if (remainder === 0 && totalSlots > 1 && totalSlots % 2 !== 0) {
    if (remainder === 0 && totalSlots > 1) {
      // fix for 10:00 - 11:30
      dateTime.pop();
    }

    // }
  } else {
    /** revised logic, just add the first slot */
    const lastSlot = date;
    // lastSlot.add(30 * totalSlots, 'minutes');
    const endtimeRemainder = 0 - (lastSlot.minute() % 30);
    lastSlot.add(endtimeRemainder, "minutes").format("HH:mm");
    lastSlot.add(30, "minutes");
    dateTime.push(lastSlot.format("HH:mm"));

    return dateTime;
  }

  return dateTime;
};

// eslint-disable-next-line import/prefer-default-export
export { getAllocatedBookingSlotsInRange };
