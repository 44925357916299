import {
  CREDIT_ACCOUNT_ERRORS,
  CREDIT_ACCOUNT_LOADING,
  GET_ALL_CREDIT_ACCOUNT,
  CREATE_CREDIT_ACCOUNT,
  GET_CREDIT_ACCOUNT,
  PAYNOW,
} from "../Actions/types";

const initialState = {
  creditAccounts: [],
  creditAccount: {},
  loading: false,
  getSingleCreditAccount: {},
  paynow: {},
  //   edit_plan: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CREDIT_ACCOUNT_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CREDIT_ACCOUNT_ERRORS:
      return {
        ...state,
        loading: false,
        creditAccount: {
          success: false,
          data: action.payload && action.payload.data,
        },
      };
    case CREATE_CREDIT_ACCOUNT:
      return {
        ...state,
        loading: false,
        creditAccount: {
          success: true,
          data: action.payload && action.payload.data,
        },
      };

    case GET_ALL_CREDIT_ACCOUNT:
      return {
        ...state,
        loading: false,
        creditAccounts: action.payload && action.payload.data,
      };
    case GET_CREDIT_ACCOUNT:
      return {
        ...state,
        loading: false,
        getSingleCreditAccount: action.payload && action.payload.data,
      };
    case PAYNOW:
      return {
        ...state,
        loading: false,
        paynow: {
          success: true,
          data: action.payload && action.payload.data,
        },
      };
    // case EDIT_PLAN:
    //   return {
    //     ...state,
    //     loading: false,
    //     edit_plan: {
    //       success: true,
    //       data: action.payload && action.payload.data,
    //     },
    //   };
    default:
      return state;
  }
}
