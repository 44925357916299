import {
  GET_RESOURCE_CATEGORY,
  ADD_RESOURCE_CATEGORY,
  RESOURCE_CATEGORY_LOADING,
  EDIT_RESOURCE_CATEGORY_ERRORS,
  EDIT_RESOURCE_CATEGORY,
  GET_SINGLE_RESOURCE_CATEGORY
} from "../Actions/types";

const initialState = {
  resource_category: null,
  resource_categories: null,
  loading: false,
  edit_resource_category: {},
  single_resource_category: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_RESOURCE_CATEGORY:
      return {
        ...state,
        loading: false,
        resource_categories: action.payload && action.payload.data
      };
    case GET_SINGLE_RESOURCE_CATEGORY:
      return {
        ...state,
        loading: false,
        single_resource_category: action.payload && action.payload.data
      };
    case ADD_RESOURCE_CATEGORY:
      return {
        ...state,
        loading: false,
        resource_category: { success: true, data: action.payload.data }
      };
    case EDIT_RESOURCE_CATEGORY:
      return {
        ...state,
        loading: false,
        edit_resource_category: {
          success: true,
          data: action.payload && action.payload.data
        }
      };
    case EDIT_RESOURCE_CATEGORY_ERRORS:
      return {
        ...state,
        loading: false,
        resource_error: { errors: false }
      };

    case RESOURCE_CATEGORY_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
}
