/* eslint-disable no-shadow */
/* eslint-disable camelcase */
import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CreateIcon from "@material-ui/icons/Create";
import { CircularLoader } from "../../Components/Common/Spinner";
import InputField from "../../Components/Common/Form/InputField";
import {
  addIssueDepartment,
  getAllIssueDepartment,
  getSingleIssueDepartment,
  editIssueDepartment,
} from "../../Actions/issueDepartmentActions";
import { checkPermission } from "../../Utils/checkPermission";
import ScreenHeader from "../../Components/Common/ScreenHeader";

const $quoteSym = " ";
const styles = () => ({
  root: {
    backgroundColor: "#fff",
  },
  table: {
    minWidth: 650,
    marginTop: "20px",
  },
  addIssueDepartment: {
    width: "100%",
    textAlign: "right",
  },
  department_form_modal: {
    width: "500px",
  },
  form_modal: {
    minWidth: "500px",
  },
  main: {
    padding: "24px",
  },
  editbutton: {
    borderRadius: "50px",
  },
  editicon: {
    fontSize: "16px",
    marginRight: "6px",
  },
  modalHeader: {
    paddingBottom: "5px",
    fontSize: "20px",
    fontWeight: "600",
    position: "relative",
    "&:after": {
      top: "46px",
      left: "24px",
      width: "24px",
      borderBottom: "3px solid #fb0",
      content: `'${$quoteSym}'`,
      position: "absolute",
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  pageHeader: {
    margin: "0px -19px",
    width: "100%",
  },
  button: {
    color: "#fff",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  addButton: {
    color: "#fff",
    marginRight: "20px",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  cancelButton: {
    marginRight: "25px",
    backgroundColor: "#dddddd",
    color: "#151039",
    "&:hover": {
      color: "#151039",
      backgroundColor: "#d1d1d1!important",
    },
  },
  root1: {
    "&:nth-child(even)": {
      backgroundColor: "#FCFDFE",
    },
  },
  backButton: {
    margin: "0% 0% 2% 0%",
  },
});
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F8FAFF",
    color: theme.palette.common.black,
    borderBottom: "none",
    padding: "8px 40px 8px 16px",
  },
  body: {
    fontSize: 14,
    textTransform: "capitalize",
    borderBottom: "none",
  },
}))(TableCell);
const FoodCategorySchema = Yup.object().shape({
  helpdeskdepartment_name: Yup.string()
    .trim()
    .required("This field is required."),
});

class IssueDepartment extends Component {
  constructor(props) {
    super(props);
    this.state = { all_issue_department: [], id: "" };
  }

  state = {
    modalOpen: false,
  };

  componentDidMount() {
    this.props.getAllIssueDepartment();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.all_issue_department !== prevState.all_issue_department) {
      return { all_issue_department: nextProps.all_issue_department };
    }
    return [];
  }

  componentDidUpdate(prevProps) {
    if (prevProps.all_issue_department !== this.props.all_issue_department) {
      let all_issue_department = this.props.all_issue_department || [];
      all_issue_department = all_issue_department.map((x, id) => {
        return Object.assign({}, x, {
          idx: id + 1,
        });
      });
      this.setState({
        all_issue_department,
        // rowData: all_issue_department
      });
    }
    if (
      this.props.issue_department !== prevProps.issue_department &&
      this.props.issue_department.success
    ) {
      this.props.getAllIssueDepartment();
    }

    if (prevProps.loading !== this.props.loading) {
      this.props.setLoading(this.props.loading);
      this.setState({
        loading: this.props.loading,
      });
    }
  }

  handleClickOpen = () => {
    this.setState({
      modalOpen: true,
      id: "",
    });
  };

  handleClickModalOpen = (id) => {
    this.setState({
      id,
      modalOpen: true,
    });
    this.props.getSingleIssueDepartment(id);
  };

  handleClose = () => {
    this.setState(
      {
        modalOpen: false,
      },
      () => this.props.getAllIssueDepartment()
    );
  };

  onBackButton = (e) => {
    e.preventDefault();
    this.props.history.goBack();
  };

  render() {
    const { classes, single_issue_department } = this.props;
    const { loading, id } = this.state;
    if (loading) {
      return <CircularLoader />;
    }
    const getInitialValues = (id) => {
      if (id) {
        return {
          helpdeskdepartment_name:
            single_issue_department.helpdeskdepartment_name,
        };
      }
      return {
        helpdeskdepartment_name: "",
      };
    };
    return (
      <div className={classes.main}>
        <Button
          color="grey"
          onClick={() => this.props.history.push("/settings")}
          className={classes.backButton}
          variant="contained"
          size="small"
        >
          Back
        </Button>
        <div className={classes.header}>
          <div className={classes.pageHeader}>
            <ScreenHeader type="All Issue Department" />
          </div>
          <Dialog
            open={this.state.modalOpen}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
          >
            <Formik
              initialValues={getInitialValues(id)}
              validationSchema={FoodCategorySchema}
              onSubmit={(values, { setErrors, setSubmitting }) => {
                if (id) {
                  this.props.editIssueDepartment(
                    id,
                    values,
                    setErrors,
                    setSubmitting
                  );
                } else {
                  this.props.addIssueDepartment(
                    values,
                    setErrors,
                    this.props.history,
                    setSubmitting
                  );
                }

                this.handleClose();
                this.props.getAllIssueDepartment();
              }}
            >
              {() => (
                <Form className={classes.department_form_modal}>
                  <DialogTitle
                    id="issue-department-dialog-title"
                    className={classes.modalHeader}
                  >
                    {id ? "Edit Issue Department" : "Add Issue Department"}
                  </DialogTitle>
                  <DialogContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <InputField
                          name="helpdeskdepartment_name"
                          label="Issue Department"
                          placeholder="Enter Department"
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={this.handleClose}
                      color="primary"
                      className={classes.cancelButton}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      className={classes.addButton}
                      type="submit"
                      variant="contained"
                    >
                      {id ? "Edit" : "Add"}
                    </Button>
                  </DialogActions>
                  {loading && <CircularLoader />}
                </Form>
              )}
            </Formik>
          </Dialog>
          <div className={classes.addIssueDepartment}>
            {checkPermission("HELPDESK_DEPARTMENT_CREATE") && (
              <Button
                onClick={this.handleClickOpen}
                className={classes.button}
                size="medium"
                variant="contained"
                color="primary"
              >
                Add Issue Department
              </Button>
            )}
          </div>
        </div>
        <div className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <StyledTableCell>Sr. No.</StyledTableCell>
                <StyledTableCell align="left">Department</StyledTableCell>
                <StyledTableCell align="left" />
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.all_issue_department &&
                this.state.all_issue_department.map((item, id) => {
                  return (
                    <TableRow className={classes.root1}>
                      <StyledTableCell component="th" scope="row">
                        {id + 1}
                      </StyledTableCell>

                      <StyledTableCell align="left">
                        {item.helpdeskdepartment_name}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <Button
                          variant="outlined"
                          size="small"
                          color="primary"
                          className={classes.editbutton}
                          onClick={() => this.handleClickModalOpen(item._id)}
                        >
                          <CreateIcon
                            size="small"
                            className={classes.editicon}
                          />
                          Edit
                        </Button>
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    all_issue_department: state.all_issue_department.all_issue_department,
    loading: state.all_issue_department.loading,
    issue_department: state.all_issue_department.issue_department,
    single_issue_department: state.all_issue_department.single_issue_department,
    edit_issue_department: state.all_issue_department.edit_issue_department,
  };
};
export default connect(mapStateToProps, {
  addIssueDepartment,
  getAllIssueDepartment,
  getSingleIssueDepartment,
  editIssueDepartment,
})(withStyles(styles)(withRouter(IssueDepartment)));
