import {
  GET_COMPANIES,
  GET_COMPANY,
  GET_ERRORS,
  COMPANY_LOADING,
  COMPANY_ERRORS,
  ASSOCIATEPLAN_ERRORS,
  UPDATE_COMPANY,
  UPDATE_COMPANY_STATUS,
  CREATE_COMPANY_SUCCESS,
  CREDIT_HISTORY,
  CREDIT_HISTORY_ERRORS,
  ADD_CREDIT_TOPUP,
  DELETE_PLAN,
  CREATE_ASSOCIATEPLAN,
  COMPANY_CREDIT_TOPUP_ERROR,
  GET_COMPANIES_CREDIT_TOPUP,
  GET_SPACE_COMPANIES_LIST,
  GET_ONBOARDING_PROCESS,
  CREATE_ONBOARDING_PROCESS_POST,
  GET_ONBOARDING_PROCESS_POST,
  GET_ONBOARDING_PROCESS_RATING,
  CREATE_ONBOARDING_PROCESS_RATING,
  GET_CLIENT_FEEDBACK,
  ADD_COMPNAY,
  GET_CLIENT_FEEDBACK_RATING,
  GET_EXIT_PROCESS,
  CREATE_EXIT_PROCESS_POST,
  GET_EXIT_PROCESS_POST,
} from '../Actions/types'

const initialState = {
  companies: [],
  loading: false,
  company: {},
  companyStatus: {},
  noMoreData: false,
  servicelist: [],
  create_company: {},
  deletePlan: {},
  associateplan: {},
  add_credit_topup: {},
  credit_topup_history: [],
  spaceCompany: [],
  onboardingProcess: [],
  createOnboardingProcessPost: {},
  onboardingProcessPost: [],
  onboardingProcessRating: [],
  createOnboardingProcessRating: {},
  clientFeedback: [],
  clientFeedbackRating: [],
  exitProcess: [],
  createExitProcess: {},
  getExitProcessPost: [],
}
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_COMPANIES:
      return {
        ...state,
        loading: false,
        noMoreData: action.payload.data.length <= 0,
        companies: action.reset ? action.payload.data : action.payload.data,
      }
    case GET_COMPANY:
      return {
        ...state,
        loading: false,
        company: action.payload && action.payload.data,
      }
    case ADD_COMPNAY:
      return {
        ...state,
        loading: false,
        add_company: {
          success: true,
          data: action.payload && action.payload.data,
        },
      }
    case GET_SPACE_COMPANIES_LIST:
      return {
        ...state,
        loading: false,
        spaceCompany: action.payload && action.payload.data,
      }
    case GET_COMPANIES_CREDIT_TOPUP:
      return {
        ...state,
        loading: false,
        credit_topup_history: action.payload && action.payload.data,
      }
    case COMPANY_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_ERRORS:
      return {
        ...state,
        loading: false,
        company: {
          success: false,
          data: action.payload && action.payload.data,
        },
      }
    case COMPANY_ERRORS:
      return {
        ...state,
        loading: false,
        company: {
          success: false,
          data: action.payload && action.payload.data,
        },
      }
    case CREATE_ASSOCIATEPLAN:
      return {
        ...state,
        loading: false,
        associateplan: {
          success: true,
          data: action.payload && action.payload.data,
        },
      }
    case ASSOCIATEPLAN_ERRORS:
      return {
        ...state,
        loading: false,
        company: {
          success: false,
          data: action.payload && action.payload.data,
        },
      }
    case UPDATE_COMPANY:
      return {
        ...state,
        update_company: {
          success: false,
          data: action.payload && action.payload.data,
        },
      }
    case UPDATE_COMPANY_STATUS:
      return {
        ...state,
        companyStatus: {
          success: false,
          data: action.payload && action.payload.data,
        },
      }
    case CREATE_COMPANY_SUCCESS:
      return {
        ...state,
        create_company: {
          success: true,
          data: action.payload && action.payload,
        },
      }
    case CREDIT_HISTORY:
      return {
        ...state,
        credit_history: action.payload && action.payload.data,
      }
    case ADD_CREDIT_TOPUP:
      return {
        ...state,
        loading: false,
        add_credit_topup: {
          success: true,
          data: action.payload && action.payload,
        },
      }
    case COMPANY_CREDIT_TOPUP_ERROR:
      return {
        ...state,
        loading: false,
        add_credit_topup_error: {
          success: true,
          data: action.payload && action.payload.data,
        },
      }
    case CREDIT_HISTORY_ERRORS:
      return {
        ...state,
        company: {
          success: false,
          data: action.payload && action.payload.data,
        },
      }
    case DELETE_PLAN:
      return {
        ...state,
        loading: false,
        deletePlan: {
          success: true,
          data: action.payload && action.payload.data,
        },
      }
    case GET_ONBOARDING_PROCESS:
      return {
        ...state,
        loading: false,
        onboardingProcess: action.payload && action.payload.data,
      }
    case GET_EXIT_PROCESS:
      return {
        ...state,
        loading: false,
        exitProcess: action.payload && action.payload.data,
      }
    case CREATE_ONBOARDING_PROCESS_POST:
      return {
        ...state,
        loading: false,
        createOnboardingProcessPost: {
          success: true,
          data: action.payload && action.payload,
        },
      }
    case CREATE_EXIT_PROCESS_POST:
      return {
        ...state,
        loading: false,
        createExitProcess: {
          success: true,
          data: action.payload && action.payload,
        },
      }

    case GET_ONBOARDING_PROCESS_POST:
      return {
        ...state,
        loading: false,
        onboardingProcessPost: action.payload && action.payload.data,
      }
    case GET_EXIT_PROCESS_POST:
      return {
        ...state,
        loading: false,
        getExitProcessPost: action.payload && action.payload.data,
      }
    case GET_ONBOARDING_PROCESS_RATING:
      return {
        ...state,
        loading: false,
        onboardingProcessRating: action.payload && action.payload.data,
      }
    case CREATE_ONBOARDING_PROCESS_RATING:
      return {
        ...state,
        loading: false,
        createOnboardingProcessRating: {
          success: true,
          data: action.payload && action.payload,
        },
      }
    case GET_CLIENT_FEEDBACK:
      return {
        ...state,
        loading: false,
        clientFeedback: action.payload && action.payload.data,
      }
    case GET_CLIENT_FEEDBACK_RATING:
      return {
        ...state,
        loading: false,
        clientFeedbackRating: action.payload && action.payload.data,
      }

    default:
      return state
  }
}
