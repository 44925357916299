/* eslint-disable camelcase */
import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Dialog,
  DialogContent,
  Grid,
  DialogTitle,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import CreateIcon from "@material-ui/icons/Create";
import { CircularLoader } from "../../Components/Common/Spinner";
import Cell from "../../Components/Common/Table/TableCell";
import Header from "../../Components/Common/Table/TableHeader";
import {
  InputField,
  UploadField,
  SelectField,
} from "../../Components/Common/Form";
import { checkPermission } from "../../Utils/checkPermission";
import {
  addresourcecategory,
  getResourcecategory,
} from "../../Actions/resourcecategoryActions";
import { getCompanies } from "../../Actions/companiesAction";
import { capitalize } from "../../Utils/StringFunctions";
import {
  addresource,
  getAvailableResources,
  getSingleResource,
  editResource,
} from "../../Actions/resourceActions";
import ScreenHeader from "../../Components/Common/ScreenHeader";
import resource_default_image from "../../Assets/resource_default_image.png";
import nodata from "../../Assets/nodata.png";

const $quoteSym = " ";
const styles = () => ({
  table: {
    marginTop: "20px",
  },
  addResourcebutton: {
    width: "100%",
    textAlign: "right",
  },
  resource_form_modal: {
    width: "500px",
  },
  main: {
    padding: "24px",
  },
  editbutton: {
    borderRadius: "50px",
  },
  editicon: {
    fontSize: "16px",
    marginRight: "6px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  Resourcebutton: {
    color: "#fff",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  new_Resourcebutton: {
    color: "#fff",
    backgroundColor: "#211b4e !important",
    // textAlign: "center",
    marginLeft: "70px",
    marginTop: "20px",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  ScreenHeader: {
    marginLeft: "-20px",
    width: "100%",
  },
  cancelbutton: {
    marginRight: "20px",
    backgroundColor: "#dddddd",
    color: "#151039",
    "&:hover": {
      color: "#151039",
      backgroundColor: "#d1d1d1!important",
    },
  },
  addbutton: {
    color: "#fff",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  modelHeader: {
    paddingBottom: "5px",
    position: "relative",
    "&:after": {
      top: "48px",
      left: "24px",
      width: "24px",
      borderBottom: "3px solid #fb0",
      content: `'${$quoteSym}'`,
      position: "absolute",
    },
  },
  modalFooter: {
    padding: "8px 0px",
    display: "flex",
    justifyContent: "flex-end",
  },
  root1: {
    "&:nth-child(even)": {
      backgroundColor: "#FCFDFE",
    },
  },
  backButton: {
    margin: "0% 0% 2% 0%",
  },
  resourceImage: {
    width: "30%",
    height: "34px",
  },
  imageSection: {
    padding: "0px",
    width: "25%",
  },
});

const ResourceSchema = Yup.object().shape({
  resource_type_id: Yup.string().required("This field is required."),
  resource_name: Yup.string().trim().required("This field is required."),
  pax: Yup.number()
    .typeError("Please input a number.")
    .required("This field is required."),
  image: Yup.string().required("This field is required."),
  credit_per_thirty_mins: Yup.number()
    .typeError("Please input a number.")
    .required("This field is required."),
});

export class AddResources extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      deletedKey: [],
    };
  }

  state = {
    modalOpen: false,
  };

  componentDidMount() {
    this.props.getResourcecategory();
    this.props.getCompanies();
    this.props.getAvailableResources();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.resource_categories !== prevState.resource_categories) {
      return { resource_categories: nextProps.resource_categories };
    }
    if (nextProps.resources !== prevState.resources) {
      return { resources: nextProps.resources };
    }
    if (nextProps.companies !== prevState.companies) {
      return { companies: nextProps.companies };
    }

    return [];
  }

  shouldComponentUpdate() {
    if (this.state.deletedKey.length > 0) {
      return false;
    }
    return true;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.resource_categories !== this.props.resource_categories) {
      this.setState({
        resource_categories: this.props.resource_categories,
      });
    }

    if (prevProps.resources !== this.props.resources) {
      let resources = this.props.resources || [];
      resources = resources.map((x, id) => {
        return Object.assign({}, x, {
          idx: id + 1,
        });
      });
      this.setState({
        resources,
      });
    }
    if (
      this.props.edit_resource !== prevProps.edit_resource &&
      this.props.edit_resource.success
    ) {
      this.handleClose();
    }
    if (prevProps.resource !== this.props.resource) {
      if (this.props.resource.success) {
        this.props.getAvailableResources();
      }
    }

    if (prevProps.loading !== this.props.loading) {
      this.setState({
        loading: this.props.loading,
      });
    }
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.showLoadingOverlay();
  };

  getAvailableResourceOptions = (resource_categories) => {
    return resource_categories
      ? // eslint-disable-next-line no-shadow
        resource_categories.map((resource_categories) => {
          return {
            value: resource_categories._id,
            label: resource_categories.name,
          };
        })
      : [];
  };

  getCompanyOptions = (companies, get_single_resource) => {
    if (get_single_resource) {
      // eslint-disable-next-line consistent-return
      const companydata = [];
      companies.filter((company) => {
        if (get_single_resource.company_id.includes(company._id)) {
          return companydata.push({
            value: company._id,
            label: capitalize(company.display_name || company.company_name),
          });
        }
        return null;
      });
      return companydata;
    }
    const FilterCompanyData = companies.filter((activeCompanydata) => {
      return activeCompanydata.status !== "inactive";
    });
    return FilterCompanyData
      ? FilterCompanyData.map((company) => {
          return {
            value: company._id,
            label: capitalize(company.display_name || company.company_name),
          };
        })
      : [];
  };

  handleClickModalOpen = (id) => {
    this.setState({
      id,
      modalOpen: true,
    });
    this.props.getSingleResource(id);
  };

  handleClickOpen = () => {
    this.setState({
      modalOpen: true,
      id: "",
    });
  };

  setDelImageUrl = (url) => {
    const { deletedKey } = this.state;
    this.setState({ deletedKey: deletedKey.push(url) });
  };

  handleClose = () => {
    this.setState({
      modalOpen: false,
    });
    this.props.getResourcecategory();
    this.props.getAvailableResources();
  };

  renderHead = () => {
    return (
      <TableHead>
        <TableRow>
          <Header width="8%">Sr. No.</Header>
          <Header width="18%">Category</Header>
          <Header width="15%">Resource Name</Header>
          <Header width="15%">Resource Image</Header>
          <Header width="10%">Total Seats</Header>
          <Header width="10%">Credit Per Half-Hour</Header>
          <Header width="10%"> </Header>
        </TableRow>
      </TableHead>
    );
  };

  renderBody = () => {
    const { classes } = this.props;
    return (
      <TableBody>
        {this.state.resources &&
          this.state.resources.map((item, id) => {
            return (
              <TableRow className={classes.root1}>
                <Cell width="8%">{id + 1}</Cell>
                <Cell width="18%">
                  {item.resource_type_id &&
                    capitalize(item.resource_type_id.name)}
                </Cell>
                <Cell width="18">{capitalize(item.resource_name)}</Cell>
                <Cell width="15%" className={classes.imageSection}>
                  <img
                    src={item.image ? item.image : resource_default_image}
                    alt=""
                    className={classes.resourceImage}
                  />
                </Cell>
                <Cell width="10%">{item.pax}</Cell>
                <Cell width="10%">{item.credit_per_thirty_mins}</Cell>
                <Cell align="right" width="10%">
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    className={classes.editbutton}
                    onClick={() => this.handleClickModalOpen(item._id)}
                  >
                    <CreateIcon size="small" className={classes.editicon} />{" "}
                    Edit
                  </Button>
                </Cell>
              </TableRow>
            );
          })}
      </TableBody>
    );
  };

  render() {
    const { classes, get_single_resource } = this.props;
    const { resource_categories, loading, id, companies } = this.state;
    let showData = true;
    if (this.state.resources && this.state.resources.length <= 0) {
      showData = false;
    }
    // eslint-disable-next-line no-shadow
    const getInitialValues = (id) => {
      if (id) {
        return {
          resource_type_id:
            get_single_resource.resource_type_id &&
            this.getAvailableResourceOptions(resource_categories).filter(
              (option) =>
                option.value === get_single_resource.resource_type_id._id
            )[0],
          resource_name: get_single_resource.resource_name,
          pax: get_single_resource.pax,
          image: get_single_resource.image,
          credit_per_thirty_mins: get_single_resource.credit_per_thirty_mins,
          company_id:
            get_single_resource.company_id &&
            this.getCompanyOptions(companies, get_single_resource),
        };
      }
      return {
        resource_type_id: "",
        resource_name: "",
        pax: "",
        image: "",
        credit_per_thirty_mins: "",
        company_id: [],
      };
    };
    return (
      <div className={classes.main}>
        <div className={classes.header}>
          {showData && (
            <div className={classes.ScreenHeader}>
              <ScreenHeader type="All Resources" />
            </div>
          )}
          <Dialog
            open={this.state.modalOpen}
            onClose={this.handleClose}
            aria-labelledby="resource-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <Formik
              initialValues={getInitialValues(id)}
              enableReinitialize
              validationSchema={ResourceSchema}
              onSubmit={(values, { setErrors, setSubmitting }) => {
                const companyid = [];
                const companydata = values.company_id;
                if (values.company_id.length !== 0) {
                  // eslint-disable-next-line array-callback-return
                  companydata.map((data) => {
                    if (data.value) {
                      companyid.push(data.value);
                    } else {
                      companyid.push(data);
                    }
                  });
                }

                // eslint-disable-next-line no-param-reassign
                values.company_id = companyid;
                // eslint-disable-next-line no-param-reassign
                values.created_by = "5cde64595cafe71468f0c483";
                // eslint-disable-next-line no-param-reassign
                values.space = localStorage.getItem("space_id");
                // eslint-disable-next-line no-param-reassign
                values.resource_type_id = values.resource_type_id.value;

                // unnecessary code commented
                // const resourceName = get_single_resource.resource_name;

                // if (resourceName === values.resource_name) {
                //   delete values.resource_name;
                // }
                if (id) {
                  this.props.editResource(id, values, setErrors, setSubmitting);
                } else {
                  this.props.addresource(
                    values,
                    setErrors,
                    this.props.history,
                    setSubmitting
                  );
                }
                this.handleClose();
              }}
            >
              {({ setFieldValue, values }) => (
                <Form className={classes.resource_form_modal}>
                  <DialogTitle
                    id="resource-dialog-title"
                    className={classes.modelHeader}
                  >
                    {id ? "Edit Resource" : "Add Resource "}
                  </DialogTitle>
                  <DialogContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <SelectField
                          options={this.getAvailableResourceOptions(
                            resource_categories
                          )}
                          name="resource_type_id"
                          id="resource_type_id"
                          placeholder="Select Resouce"
                          label="Resource"
                          isMulti={false}
                          value={values.resource_type_id}
                          onChange={setFieldValue}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <InputField
                          name="resource_name"
                          label="Resource Name"
                          placeholder="Conference Room 1"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <SelectField
                          options={this.getCompanyOptions(companies)}
                          name="company_id"
                          id="company_id"
                          placeholder="Select Company"
                          label="Company"
                          multi="true"
                          value={values.company_id}
                          onChange={setFieldValue}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <UploadField
                          label="Upload Resource Image"
                          name="image"
                          id="image"
                          onChange={setFieldValue}
                          value={values.image}
                          folder="resources"
                          delImageUrl={this.setDelImageUrl}
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <InputField
                          name="pax"
                          label="Seats"
                          placeholder="Enter Total Seats"
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <InputField
                          name="credit_per_thirty_mins"
                          label="Credit per half-hour"
                          placeholder="Enter Credit Per Half-Hour"
                        />
                      </Grid>
                    </Grid>
                    <Grid className={classes.modalFooter}>
                      <Button
                        onClick={this.handleClose}
                        color="primary"
                        className={classes.cancelbutton}
                      >
                        Cancel
                      </Button>
                      <Button
                        color="primary"
                        className={classes.addbutton}
                        type="submit"
                        variant="contained"
                      >
                        {id ? "Edit" : "Add"}
                      </Button>
                    </Grid>
                  </DialogContent>
                  {loading && <CircularLoader />}
                </Form>
              )}
            </Formik>
          </Dialog>
          {showData && (
            <div className={classes.addResourcebutton}>
              {checkPermission("RESOURCE_CREATE") && (
                <Button
                  onClick={this.handleClickOpen}
                  variant="contained"
                  color="primary"
                  className={classes.Resourcebutton}
                >
                  Add Resources
                </Button>
              )}
            </div>
          )}
        </div>
        <div className={classes.root}>
          {showData && (
            <Table className={classes.table}>
              {this.renderHead()}
              {this.renderBody()}
            </Table>
          )}
          {!showData && (
            <>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                className={classes.headerSpacing}
              >
                <Grid item justify="center">
                  <Grid item sm={12} lg={12}>
                    <img src={nodata} alt="" width="76%" />
                  </Grid>
                  <div className={classes.add_new_resource}>
                    {checkPermission("RESOURCE_CREATE") && (
                      <Button
                        onClick={this.handleClickOpen}
                        variant="contained"
                        color="primary"
                        className={classes.new_Resourcebutton}
                      >
                        Add Resources
                      </Button>
                    )}
                  </div>
                </Grid>
              </Grid>
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    resource_categories: state.resource_categories.resource_categories,
    resources: state.resources.resources,
    resource: state.resources.resource,
    loading: state.resources.loading,
    get_single_resource: state.resources.get_single_resource,
    edit_resource: state.resources.edit_resource,
    companies: state.company.companies,
  };
};

export default connect(mapStateToProps, {
  addresourcecategory,
  getResourcecategory,
  addresource,
  getAvailableResources,
  getSingleResource,
  editResource,
  getCompanies,
})(withStyles(styles)(AddResources));
