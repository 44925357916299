/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unused-state */
import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { connect } from 'react-redux'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from '@material-ui/core'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import UploadField from '../../Components/Common/Form/UploadField'
import TextareaField from '../../Components/Common/Form/TextareaField'
import onboardingPopup from '../../Assets/onboardingPopup.png'
import { spacecapitalize } from '../../Utils/StringFunctions'
import { exitProcessPost } from '../../Actions/companiesAction'

const styles = () => ({
  form_modal: {
    width: '500px',
    // height: "500px"
  },
  input_field: {
    border: '1px solid #f5f5f5',
    height: '81%',
    position: 'relative',
    width: '100%',
    padding: '6px 8px',
    margin: '0px',
    // borderBottom: "none",
    fontFamily:
      "'-apple-system,BlinkMacSystemFont','Segoe UI','Roboto','Helvetica Neue','Arial,sans-serif','Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol'",
    color: 'grey',
    fontSize: '14px',
  },
  eventsubtitle1: {
    fontWeight: 'normal',
    fontSize: '14px',
    textAlign: 'left',
    color: '#626262',
  },
  addButton: {
    color: '#fff',
    marginRight: '20px',
    backgroundColor: '#211b4e !important',
    '&:hover': {
      backgroundColor: '#151039 !important',
    },
  },
  cancelButton: {
    marginRight: '25px',
    backgroundColor: '#dddddd',
    color: '#151039',
    '&:hover': {
      color: '#151039',
      backgroundColor: '#d1d1d1!important',
    },
  },
  logo: {
    width: '100%',
    textAlign: 'center',
  },
  uploadMedia: {
    display: 'flex',
    justifyContent: 'center',
  },
  eventsubtitle: {
    fontSize: '16px',
    fontWeight: 'normal',
    textAlign: 'center',
    color: '#222',
  },
})

const popupSchema = Yup.object().shape({
  media1: Yup.string().required('This field is required.'),
})
class ExitProcessPopup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      deletedKey: [],
      subProcessMedia: [],
      legalDoc: [],
      // errors: {}
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.status !== prevState.status) {
      return { status: nextProps.status }
    }
    return []
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSuccess = () => {
    this.props.close()
  }

  setDelImageUrl = () => {
    // const { deletedKey } = this.state;
    // this.setState({ deletedKey: deletedKey.push(url) });
  }

  render() {
    const { classes, processData } = this.props
    // console.log('processData', processData)
    return (
      <Dialog
        open={this.props.data}
        onClose={this.props.close}
        aria-labelledby="form-dialog-title"
      >
        <Formik
          initialValues={{ media: '', media1: '' }}
          enableReinitialize
          validationSchema={
            processData && processData.has_subprocess === true
              ? popupSchema
              : ''
          }
          onSubmit={(values, { setErrors, setSubmitting }) => {
            values.company_id = this.props.companyID
            values.process_id = processData._id
            delete values.media1
            this.state.subProcessMedia.map((image) => {
              return image.map((finalMedia) => {
                return this.setState({
                  legalDoc: [...this.state.legalDoc, finalMedia],
                })
              })
            })
            values.media =
              processData && processData.has_subprocess === true
                ? this.state.legalDoc
                : values.media
            // console.log('legalMedia', this.state.legalDoc)
            // console.log('values', values)
            this.props.exitProcessPost(
              values,
              setErrors,
              this.props.history,
              setSubmitting
            )
            this.handleSuccess()
          }}
        >
          {({ setFieldValue, values }) => (
            <Form className={classes.form_modal}>
              {/* <DialogTitle id="issue-category-dialog-title"></DialogTitle>s */}
              <DialogContent className={classes.dialogContent}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} className={classes.logo}>
                    <img
                      className={classes.photo}
                      src={onboardingPopup}
                      alt=""
                    />
                    <Typography
                      variant="body1"
                      component="p"
                      className={classes.eventsubtitle}
                    >
                      {spacecapitalize(processData.process_name)}
                    </Typography>
                  </Grid>
                  {processData && processData.has_subprocess === true ? (
                    processData &&
                    processData.subprocess.map((item, id) => {
                      // console.log('iddd', id)
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          className={classes.uploadMedia}
                        >
                          <UploadField
                            label={`Upload ${item}`}
                            name="media1"
                            id="media"
                            // onChange={setFieldValue}
                            onChange={(name, value) => {
                              setFieldValue(name, value)
                              const newStateArray = []
                              newStateArray.push(value[0])
                              // console.log('value', value)
                              this.setState({
                                subProcessMedia: [
                                  ...this.state.subProcessMedia,
                                  newStateArray,
                                ],
                              })
                            }}
                            value={values.media[id] || []}
                            data=""
                            multiple
                            folder="exitprocess/posts"
                            delImageUrl={() => this.setDelImageUrl()}
                          />
                        </Grid>
                      )
                    })
                  ) : (
                    <Grid item xs={12} sm={12} className={classes.uploadMedia}>
                      <UploadField
                        label="Upload Photo"
                        name="media"
                        id="media"
                        onChange={setFieldValue}
                        value={values.media || []}
                        data=""
                        multiple
                        folder="exitprocess/posts"
                        delImageUrl={() => this.setDelImageUrl()}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12} sm={12}>
                    <Typography
                      className={classes.eventsubtitle1}
                      variant="subtitle1"
                      component="p"
                    >
                      <TextareaField
                        name="comment"
                        className={classes.input_field}
                        rows="3"
                        placeholder="Add Comment here.."
                      />
                    </Typography>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.handleSuccess}
                  color="primary"
                  className={classes.cancelButton}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  className={classes.addButton}
                  type="submit"
                  variant="contained"
                >
                  Confirm
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    errors: state.errors,
  }
}
export default connect(mapStateToProps, { exitProcessPost })(
  withStyles(styles)(ExitProcessPopup)
)
