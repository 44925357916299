import withStyles from '@material-ui/core/styles/withStyles'
import React from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Typography,
  CssBaseline,
  Button,
  Avatar,
  Paper,
} from '@material-ui/core'
import Select from '@material-ui/core/Select'
import classNames from 'classnames'
import { connect } from 'react-redux'
import MenuItem from '@material-ui/core/MenuItem'
import { getTicket, createHistory, getAssignTo } from 'Actions/helpdeskActions'
import { capitalize, spacecapitalize } from 'Utils/StringFunctions'
import { Formik } from 'formik'
import TextareaField from 'Components/Common/Form/TextareaField'
import { checkPermission } from 'Utils/checkPermission'
import { Form } from 'react-formik-ui'
import helpdeskPhoto from 'Assets/issue.jpg'
import avatar from 'Assets/coworker3.png'
import AssignTo from '../../Components/Common/AssignTo'
import ChangeStatusPopup from './ChangeStatusPopup'
import {
  AdminHelpdeskStatusTypes,
  HelpdeskStatusTypes,
  closedStatusTypes,
} from '../../Components/Common/Types/HelpdeskStatusType'

const moment = require('moment')

const styles = () => ({
  mainsection: {
    // backgroundColor: "#FAFAFA",
    // padding: theme.spacing(2, 2)
    height: '93vh',
  },
  helpdeskMain: {
    padding: '17px 17px 17px',
    backgroundColor: '#fff',
    borderRadius: '4px',
    // height: '50vh',
  },
  helpdesk_photo: {
    marginTop: '11%',
    width: '86%',
    height: '100px',
    borderRadius: '5px',
  },
  logo: {
    width: '100%',
  },
  eventTitle: {
    paddingBottom: '8px',
  },
  eventsubtitle: {
    fontSize: '12px',
    // color: "grey",
    paddingTop: '18px',
    fontWeight: '600',
    textAlign: 'left',
    color: '#333',
    paddingBottom: '5px',
  },
  status: {
    textTransform: 'capitalize',
    fontSize: '14px',
    fontWeight: '600',
    color: '#4caf50',
  },
  eventsubtitle1: {
    fontSize: '14px',
    // color: "grey",
    fontWeight: 'normal',
    textAlign: 'left',
    color: '#626262',
    lineHeight: '17px',
  },
  descriptionMain: {
    paddingTop: '20px',
  },
  eventsubtitle2: {
    color: '#ababab',
    fontSize: '12px',
    lineHeight: '1.3',
  },
  comment_section: {
    border: '1px solid #efefef',
    textAlign: 'right',
    position: 'relative',
    height: '81px',
    marginLeft: '10px',
    borderRadius: '10px',
  },
  submitbutton: {
    backgroundColor: '#211b4e !important',
    '&:hover': {
      backgroundColor: '#151039 !important',
    },
    marginTop: '6px',
    marginBottom: '35px',
    '&:disabled': {
      backgroundColor: 'lightgrey !important',
    },
  },
  statusbutton: {
    borderRadius: '5px',
    fontWeight: '600',
    fontSize: '11px',
    textAlign: 'center',
    // color: "#fff",
    marginTop: '5%',
    width: '98px',
    height: '28px',
  },
  input_field: {
    border: '1px solid #f5f5f5',
    height: '100%',
    position: 'relative',
    width: '100%',
    padding: '6px 8px',
    margin: '0px',
    borderBottom: 'none',
    fontFamily:
      "'-apple-system,BlinkMacSystemFont','Segoe UI','Roboto','Helvetica Neue','Arial,sans-serif','Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol'",
    color: 'grey',
    fontSize: '14px',
    resize: 'none',
  },
  downArrow: {
    position: 'relative',
    top: '1px',
    left: '3px',
  },
  useIcon: {
    position: 'absolute',
    marginTop: '15px',
    width: '32px',
    height: '32px',
    opacity: '0.7',
  },
  useIconHistory: {
    width: '32px',
    height: '32px',
    marginRight: '17px',
    position: 'absolute',
    opacity: '0.7',
  },
  issuedescription: {
    margin: '0px 4% 0px 0px',
    color: '#626262',
    whiteSpace: 'pre-line',
  },
  createPostSection: {
    paddingRight: '20px',
  },
  postSection: {
    backgroundColor: '#fff',
    marginTop: '2%',
    paddingTop: '1%',
    borderRadius: '4px',
  },
  historyTime: {
    marginLeft: '11px',
    color: '#ababab',
    fontWeight: 'normal',
    paddingTop: '0px',
    fontSize: '12px',
    textTransform: 'capitalize',
  },
  historyTitle: {
    fontSize: '14px',
    // color: "grey",
    paddingBottom: '2%',
    fontWeight: 'normal',
    // textAlign: "left",
    color: '#626262',
    position: 'relative',
    top: '2px',
    left: '38px',
    width: '91%',
    textAlign: 'justify',
  },
  historyMain: {
    padding: '20px 20px 0px 0px',
    borderBottom: '1px solid #efefef',
    marginLeft: '15px',
  },
  high: {
    color: '#F44336',
  },
  medium: {
    color: '#FFC312',
  },
  low: {
    color: '#4CAF50',
  },
  open: {
    color: '#55A3F1',
    border: '1px solid #55A3F1',
    backgroundColor: '#ffffff',
  },
  Closed: {
    color: ' #4CAF50',
    border: '1px solid #4CAF50',
    backgroundColor: '#ffffff',
  },
  Pending: {
    color: '#FFC312',
    border: '1px solid #FFC312',
    backgroundColor: '#ffffff',
  },
  Inprocess: {
    color: '#4CAF50',
    border: '1px solid #4CAF50',
    backgroundColor: '#ffffff',
  },
  OnBoard: {
    color: '#26B52B',
    border: '1px solid #26B52B',
    backgroundColor: '#ffffff',
  },
  margintop: {
    marginTop: '8%',
  },
  comment: {
    fontSize: '12px',
  },
  statusDropdown: {
    marginTop: '26%',
    marginLeft: '12px',
    backgroundColor: '#fff',
    border: 'none',
    fontSize: '14px',
    color: 'black',
  },
  marginLeft: {
    marginLeft: '40px',
    paddingTop: '3px',
  },
})

class HelpdeskDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false,
      ticket: {},
      assignTo: [],
      selected: '',
      // assignUser: []
    }
    this.state.id = this.props.match.params.id
  }

  componentDidMount() {
    this.props.getAssignTo('sysuser')
    this.props.getTicket(this.state.id)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // if (nextProps.ticket !== prevState.ticket) {
    //   return { ticket: nextProps.ticket };
    // } else if (nextProps.ticketHistory !== prevState.ticketHistory) {
    //   return { ticketHistory: nextProps.ticketHistory };
    // } else
    if (nextProps.assignTo !== prevState.assignTo) {
      return { assignTo: nextProps.assignTo }
    }
    return []
  }

  componentDidUpdate(prevProps) {
    if (prevProps.ticket !== this.props.ticket) {
      this.setState({
        ticket: this.props.ticket,
        selected: this.props.ticket.status,
      })
    }

    if (prevProps.ticketHistory !== this.props.ticketHistory) {
      // this.setState({
      //   ticketHistory: this.props.ticketHistory
      // });
      this.props.getTicket(this.state.id)
    }

    if (prevProps.status !== this.props.status) {
      // this.setState({
      //   status: this.props.status
      // });
      this.props.getTicket(this.state.id)
    }

    if (prevProps.assignTo !== this.props.assignTo) {
      this.setState({
        assignTo: this.props.assignTo,
      })
    }
    // if (
    //   prevProps.helpdesk.ticketHistory != this.props.helpdesk.ticketHistory &&
    //   this.props.helpdesk.ticketHistory.success
    // ) {
    // }
  }

  handleClickOpen = (el) => {
    this.setState({
      modalOpen: true,
    })
    this.setState({ selected: el.target.value })
  }

  handleClose = () => {
    this.setState({
      // eslint-disable-next-line react/no-access-state-in-setstate
      modalOpen: !this.state.modalOpen,
      selected: this.state.ticket.status,
    })
  }

  render() {
    const { classes } = this.props
    const { ticket, selected } = this.state
    const a = moment().format('YYYY,MM,DD')
    const b = moment(ticket.createdAt).format('YYYY,MM,DD')
    const currentdate = moment(a)
    const createddate = moment(b)
    let diffdate
    if (ticket.status === 'closed' || ticket.status === 'revoked') {
      const updatedDate = moment(ticket.closed_at).format('YYYY,MM,DD')
      diffdate = moment(updatedDate).diff(createddate, 'days')
    } else {
      diffdate = currentdate.diff(createddate, 'days')
    }
    const role = localStorage.getItem('role')
    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.mainsection}>
          <main>
            <div className={classNames(classes.layout, classes.cardGrid)}>
              {/* End hero unit */}
              <div className={classes.eventbasicdetail}>
                <Grid
                  container
                  item
                  lg={12}
                  xs={12}
                  className={classes.helpdeskMain}
                >
                  <Grid item lg={2} xs={2}>
                    <div className={classes.logo}>
                      {ticket.issue_photo ? (
                        <img
                          className={classes.helpdesk_photo}
                          src={ticket.issue_photo}
                          alt=""
                        />
                      ) : (
                        <img
                          className={classes.helpdesk_photo}
                          src={helpdeskPhoto}
                          alt=""
                        />
                      )}
                    </div>
                  </Grid>
                  <Grid item lg={2} xs={2}>
                    <div className={classes.eventTitle}>
                      <Typography
                        variant="body1"
                        component="p"
                        className={classes.eventsubtitle}
                      >
                        Ticket Id
                      </Typography>
                      <Typography
                        className={classes.eventsubtitle1}
                        variant="subtitle1"
                        component="p"
                        style={{ textTransform: 'uppercase' }}
                      >
                        {ticket.ticket_number}
                      </Typography>
                      <Typography
                        className={classNames(
                          classes.eventsubtitle,
                          classes.margintop
                        )}
                        variant="subtitle1"
                        component="p"
                      >
                        Raised By
                      </Typography>
                      <Typography
                        className={classes.eventsubtitle1}
                        variant="subtitle1"
                        component="p"
                      >
                        {spacecapitalize(
                          ticket.created_by &&
                            `${ticket.created_by.first_name} ${ticket.created_by.last_name}`
                        )}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item lg={2} xs={2}>
                    <div className={classes.eventTitle}>
                      <Typography
                        variant="body1"
                        component="p"
                        className={classes.eventsubtitle}
                      >
                        Registerd On
                      </Typography>
                      <Typography
                        className={classes.eventsubtitle1}
                        variant="subtitle1"
                        component="p"
                      >
                        {moment(ticket.createdAt).format('DD/MM/YYYY')}
                      </Typography>
                      {/* open days  */}

                      <Typography
                        className={classNames(
                          classes.eventsubtitle,
                          classes.margintop
                        )}
                        variant="subtitle1"
                        component="p"
                      >
                        Company
                      </Typography>
                      <Typography
                        className={classes.eventsubtitle2}
                        variant="subtitle2"
                        component="p"
                      >
                        {capitalize(
                          ticket.created_by &&
                            ticket.created_by.company_id &&
                            ticket.created_by.company_id.type === 'freelancer'
                            ? '-'
                            : `${
                                (ticket.created_by &&
                                ticket.created_by.company_id
                                  ? ticket.created_by.company_id.display_name
                                  : ticket.company_id &&
                                    ticket.company_id.company_name) ||
                                (ticket.created_by &&
                                ticket.created_by.company_id
                                  ? ticket.created_by.company_id.company_name
                                  : ticket.company_id &&
                                    ticket.company_id.company_name)
                              } `
                        )}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item lg={2} xs={2}>
                    <div className={classes.eventTitle}>
                      <Typography
                        variant="body1"
                        component="p"
                        className={classes.eventsubtitle}
                      >
                        Department
                      </Typography>
                      <Typography
                        className={classes.eventsubtitle1}
                        variant="subtitle1"
                        component="p"
                      >
                        {capitalize(
                          ticket.helpdeskdepartment_id &&
                            ticket.helpdeskdepartment_id.helpdeskdepartment_name
                        )}
                      </Typography>
                      <Typography
                        className={classNames(
                          classes.eventsubtitle,
                          classes.margintop
                        )}
                        variant="subtitle1"
                        component="p"
                      >
                        Category
                      </Typography>
                      <Typography
                        className={classes.eventsubtitle1}
                        variant="subtitle1"
                        component="p"
                      >
                        {capitalize(
                          ticket.helpdeskcategory_id &&
                            ticket.helpdeskcategory_id.helpdeskcategory_name
                        )}
                      </Typography>
                    </div>
                  </Grid>
                  {/* here here */}

                  <Grid item lg={1} xs={1}>
                    <div className={classes.eventTitle}>
                      <Typography
                        variant="body1"
                        component="p"
                        className={classes.eventsubtitle}
                      >
                        Priority
                      </Typography>
                      <Typography
                        className={classNames(
                          classes[ticket.severity_level],
                          classes.eventsubtitle1
                        )}
                        variant="subtitle1"
                        component="p"
                      >
                        {capitalize(ticket.severity_level)}
                      </Typography>
                      <Typography
                        className={classNames(
                          classes.eventsubtitle,
                          classes.margintop
                        )}
                        style={{ marginTop: '17%' }}
                        variant="subtitle1"
                        component="p"
                      >
                        Days
                      </Typography>
                      <Typography
                        className={classes.eventsubtitle1}
                        variant="subtitle1"
                        component="p"
                      >
                        {diffdate}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item lg={2} xs={2}>
                    <div className={classes.ticketPhoto}>
                      {checkPermission('SYS_USER_VIEW') ? (
                        ''
                      ) : (
                        <Typography
                          className={classes.eventsubtitle}
                          variant="subtitle1"
                          component="p"
                          style={{
                            position: 'relative',
                            // marginLeft: "40px",
                            paddingBottom: '0px',
                          }}
                        >
                          Assigned To
                        </Typography>
                      )}
                      {checkPermission('SYS_USER_VIEW') ? (
                        <Typography
                          className={classNames(
                            classes.eventsubtitle1,
                            !ticket.assigned_to ? classes.marginLeft : ''
                          )}
                          variant="subtitle1"
                          component="p"
                        >
                          {ticket.assigned_to && (
                            <AssignTo
                              selectedItem={
                                ticket.assigned_to && ticket.assigned_to._id
                              }
                              getAssignTo={this.props.getAssignTo}
                              ticket_id={ticket._id}
                              assignToData={this.props.assignTo}
                            />
                          )}
                          {!ticket.assigned_to && (
                            <AssignTo
                              selectedItem="selectuser"
                              getAssignTo={this.props.getAssignTo}
                              ticket_id={ticket._id}
                              assignToData={this.props.assignTo}
                            />
                          )}
                        </Typography>
                      ) : (
                        <Typography
                          className={classes.eventsubtitle1}
                          variant="subtitle1"
                          component="p"
                        >
                          {ticket.assigned_to
                            ? `${capitalize(
                                ticket.assigned_to.first_name
                              )} ${capitalize(ticket.assigned_to.last_name)}`
                            : 'Yet to be assigned'}
                        </Typography>
                      )}
                      {checkPermission('HELPDESK_TICKET_STATUS_UPDATE') &&
                        ticket.status !== 'revoked' && (
                          <Select
                            className={classNames(classes.statusDropdown)}
                            value={selected}
                            onChange={this.handleClickOpen}
                            name="name"
                            disableUnderline
                            // eslint-disable-next-line camelcase
                            variant="standard"
                            classes={{
                              icon: classes.icon,
                            }}
                          >
                            {ticket.status === 'closed' &&
                              (role === 'admin' ||
                                role === 'communitymanager') &&
                              closedStatusTypes.map((element) => {
                                return (
                                  <MenuItem
                                    value={element.value}
                                    key={element.value}
                                  >
                                    {capitalize(element.label)}
                                  </MenuItem>
                                )
                              })}
                            {ticket.status !== 'closed' &&
                              role === 'admin' &&
                              AdminHelpdeskStatusTypes.map((element) => {
                                return (
                                  <MenuItem
                                    value={element.value}
                                    key={element.value}
                                  >
                                    {capitalize(element.label)}
                                  </MenuItem>
                                )
                              })}
                            {ticket.status !== 'closed' &&
                              role !== 'admin' &&
                              HelpdeskStatusTypes.map((element) => {
                                return (
                                  <MenuItem
                                    value={element.value}
                                    key={element.value}
                                  >
                                    {capitalize(element.label)}
                                  </MenuItem>
                                )
                              })}
                            {ticket.status === 'reopen' && (
                              <MenuItem
                                value="reopen"
                                label="Reopen"
                                key="reopen"
                                selectedItem
                              >
                                Reopen
                              </MenuItem>
                            )}
                          </Select>
                        )}
                      {checkPermission('HELPDESK_TICKET_STATUS_UPDATE') &&
                        ticket.status === 'revoked' && (
                          <Typography
                            className={classNames(classes.statusDropdown)}
                            variant="subtitle1"
                            component="p"
                          >
                            {capitalize(ticket.status)}
                          </Typography>
                        )}
                      {checkPermission(
                        'HELPDESK_TICKET_STATUS_UPDATE_BYCOWORKER'
                      ) &&
                      !checkPermission('HELPDESK_TICKET_STATUS_UPDATE') &&
                      ticket.status === 'closed' ? (
                        <Select
                          className={classNames(classes.statusDropdown)}
                          value={selected}
                          onChange={this.handleClickOpen}
                          name="name"
                          disableUnderline
                          // eslint-disable-next-line camelcase
                          variant="standard"
                          classes={{
                            icon: classes.icon,
                          }}
                        >
                          {ticket.status === 'closed' &&
                            closedStatusTypes.map((element) => {
                              return (
                                <MenuItem
                                  value={element.value}
                                  key={element.value}
                                >
                                  {capitalize(element.label)}
                                </MenuItem>
                              )
                            })}

                          {ticket.status === 'reopen' && (
                            <MenuItem
                              value="reopen"
                              label="Reopen"
                              key="reopen"
                              selectedItem
                            >
                              Reopen
                            </MenuItem>
                          )}
                        </Select>
                      ) : (
                        !checkPermission('HELPDESK_TICKET_STATUS_UPDATE') && (
                          <Typography
                            className={classNames(classes.eventsubtitle)}
                            style={{ marginTop: '10%' }}
                            variant="subtitle1"
                            component="p"
                          >
                            Status
                          </Typography>
                        )
                      )}
                      {checkPermission(
                        'HELPDESK_TICKET_STATUS_UPDATE_BYCOWORKER'
                      ) &&
                        !checkPermission('HELPDESK_TICKET_STATUS_UPDATE') &&
                        ticket.status !== 'closed' && (
                          <Typography
                            className={classes.eventsubtitle1}
                            variant="subtitle1"
                            component="p"
                          >
                            {capitalize(ticket.status)}
                          </Typography>
                        )}
                    </div>
                  </Grid>
                  <Grid
                    container
                    item
                    lg={12}
                    xs={12}
                    className={classes.descriptionMain}
                    style={{ marginTop: '-16px' }}
                  >
                    <Grid item lg={12} xs={12}>
                      <Typography
                        className={classes.eventsubtitle}
                        variant="subtitle1"
                      >
                        Title
                      </Typography>
                      <Typography
                        className={classes.eventsubtitle1}
                        variant="subtitle1"
                        component="p"
                      >
                        {capitalize(
                          ticket.issuetitle_id &&
                            ticket.issuetitle_id.issuetitle_name
                        )}
                      </Typography>
                    </Grid>

                    <Grid item lg={12} xs={12}>
                      <Typography
                        className={classes.eventsubtitle}
                        variant="subtitle1"
                      >
                        Description
                      </Typography>
                      <Typography
                        className={classes.eventsubtitle1}
                        variant="subtitle1"
                        component="p"
                      >
                        <div className={classes.issuedescription}>
                          {/* {ticket.description} */}
                          {ticket.description ? (
                            <div
                              className={classes.offerdescription}
                              // eslint-disable-next-line react/no-danger
                              dangerouslySetInnerHTML={{
                                __html: ticket.description,
                              }}
                            />
                          ) : (
                            '-'
                          )}
                        </div>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
            <div>
              <Paper style={{ height: '40vh' }}>
                <div
                  className={classNames(classes.layout, classes.postSection)}
                >
                  <Grid
                    className={classes.createPostSection}
                    container
                    item
                    lg={12}
                    xs={12}
                  >
                    <Grid item lg={8} xs={8}>
                      <Formik
                        initialValues={{ title: '' }}
                        enableReinitialize
                        onSubmit={(
                          values,
                          { setErrors, setSubmitting, resetForm }
                        ) => {
                          // eslint-disable-next-line no-param-reassign
                          values.ticket_id = ticket._id
                          this.props.createHistory(
                            values,
                            setErrors,
                            setSubmitting,
                            this.props.history
                          )
                          resetForm(this.initialValues)
                        }}
                      >
                        {({ values, handleSubmit }) => (
                          <Form
                            onSubmit={handleSubmit}
                            className={classes.form}
                            encType="multipart/form-data"
                            autoComplete="off"
                          >
                            <div className={classes.comment_section}>
                              <TextareaField
                                name="title"
                                className={classes.input_field}
                              />
                              {/* <Button
                            className={classes.submitbutton}
                            variant="contained"
                            type="submit"
                            size="small"
                            disabled={!values.title}
                          >
                            Post
                          </Button> */}
                              <Button
                                className={classes.submitbutton}
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={this.onSubmit}
                                disabled={!values.title}
                              >
                                Post
                              </Button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </Grid>
                  </Grid>
                  <div style={{ paddingTop: '50px' }}> </div>
                  {ticket.history &&
                    ticket.history.map((data) => {
                      return (
                        <Grid
                          container
                          item
                          lg={12}
                          xs={12}
                          className={classes.historyMain}
                        >
                          <Grid item>
                            {data.created_by && data.created_by.profile_pic ? (
                              <Avatar
                                className={classes.avatar}
                                src={data.created_by.profile_pic}
                              />
                            ) : (
                              <Avatar className={classes.avatar} src={avatar} />
                            )}
                          </Grid>
                          <Typography
                            className={classes.historyTitle}
                            variant="subtitle1"
                            component="p"
                          >
                            {capitalize(data.title)}
                            {data.created_by && (
                              <span className={classes.historyTime}>
                                by {capitalize(data.created_by.first_name)}{' '}
                                {capitalize(data.created_by.last_name)}
                              </span>
                            )}
                            <span className={classes.historyTime}>
                              On {moment(data.createdAt).format('MMMM DD')}
                            </span>
                            {data.comment && (
                              <div className={classes.comment}>
                                {`"${data.comment}"`}
                              </div>
                            )}
                          </Typography>
                        </Grid>
                      )
                    })}

                  {/* <CompanyTabs company={this.state.company} id={this.state.id} /> */}
                </div>
              </Paper>
            </div>
            {checkPermission('HELPDESK_TICKET_STATUS_UPDATE') ? (
              <ChangeStatusPopup
                data={this.state.modalOpen}
                close={this.handleClose}
                data_ticket={this.state.ticket}
                ticket_id={this.state.ticket._id}
                description={ticket.description}
                selected={this.state.selected}
              />
            ) : (
              <ChangeStatusPopup
                data={false}
                close={this.handleClose}
                data_ticket={this.state.ticket}
                ticket_id={this.state.ticket._id}
                description={ticket.description}
                selected={this.state.selected}
              />
            )}
            {checkPermission('HELPDESK_TICKET_STATUS_UPDATE_BYCOWORKER') &&
              !checkPermission('HELPDESK_TICKET_STATUS_UPDATE') && (
                <ChangeStatusPopup
                  data={this.state.modalOpen}
                  close={this.handleClose}
                  data_ticket={this.state.ticket}
                  ticket_id={this.state.ticket._id}
                  description={ticket.description}
                  selected={this.state.selected}
                />
              )}
          </main>
        </div>
      </React.Fragment>
    )
  }
}

HelpdeskDetail.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
  return {
    ticket: state.helpdesk.ticket,
    ticketHistory: state.helpdesk.ticketHistory,
    status: state.helpdesk.status,
    assignTo: state.helpdesk.assignTo,
  }
}

export default connect(mapStateToProps, {
  getTicket,
  createHistory,
  getAssignTo,
})(withStyles(styles)(HelpdeskDetail))
