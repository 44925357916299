/* eslint-disable camelcase */
import axios from "axios";
import {
  CREATE_REPORT_BUG,
  GET_REPORT_BUG,
  REPORT_BUG_LOADING,
  REPORT_BUG_ERRORS,
  UPDATE_REPORT_STATUS,
  LIST_REPORT_LOADING,
} from "./types";

import { showSuccess, showError } from "./ActionsHelper";

export const setLoading = () => {
  return {
    type: LIST_REPORT_LOADING
  };
};

// Report Bug Loading
export const setReportBugLoading = () => {
  return {
    type: REPORT_BUG_LOADING,
  };
};
export const getReportBug = () => (dispatch) => {
  dispatch(setReportBugLoading());
  axios
    .get(`/api/reportbug`)
    .then((result) => {
      dispatch({
        type: GET_REPORT_BUG,
        payload: result.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: REPORT_BUG_ERRORS,
        payload: err && err.response ? err.response.data : "",
      });
    });
};

export const addReportBug = (userData, setSubmitting) => (dispatch) => {
dispatch(setReportBugLoading());
  axios
    .post("/api/reportbug", userData)
    .then((result) => {
      dispatch({
        type: CREATE_REPORT_BUG,
        payload: result.data,
      });
      dispatch(showSuccess("", "Suggestion submitted successfully!"));
      setSubmitting(false);
    })
    .catch((err) => {
      dispatch({
        type: REPORT_BUG_ERRORS,
        payload: err && err.response ? err.response.data.data : "",
      }); 
      dispatch(
        showError(err.response && err.response.data && err.response.data.data)
      );
    });
};

export const updateReportStatus = (id, data, setSubmitting) => (
  dispatch
) => {
  dispatch(setLoading());

  axios
    .put(`/api/reportBug/update/${id}`, data)
    .then((result) => {
      dispatch({
        type: UPDATE_REPORT_STATUS,
        payload: result.data,
      });
      dispatch(showSuccess("", "Status updated successfully!"));
      setSubmitting(false);
    })
    .catch((err) => {
      dispatch({
        type: REPORT_BUG_ERRORS,
        // payload: err.response.data
        payload: ((err || {}).response || {}).data || "Error unexpected",
      });
    });
}; 