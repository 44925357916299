/* eslint-disable react/no-unused-state */
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  Paper,
  Button,
  AppBar,
  Toolbar,
} from '@material-ui/core'
import Box from '@material-ui/core/Box'
import {
  addvisitor,
  getvisitors,
  getvisitor,
  changeVisitorType,
} from '../../Actions/visitorActions'
import SearchBar from '../../Components/Common/SearchBar'
import NoRecordsFound from '../../Components/Common/NoRecordsFound'
import DataNotFound from '../../Components/Common/DataNotFound'
import AddFormButton from '../../Components/Common/AddFormButton'
import VisitorStatus from '../../Components/Common/VisitorStatus'
import ScreenHeader from '../../Components/Common/ScreenHeader'
import { CircularLoader } from '../../Components/Common/Spinner'
import Pagination from '../../Components/Common/Table/TablePagination'
import Cell from '../../Components/Common/Table/TableCell'
import TableHeader from '../../Components/Common/Table/TableHeader'
import Header from '../../Components/Layout/Header'
import { checkPermission } from '../../Utils/checkPermission'
import { spacecapitalize } from '../../Utils/StringFunctions'
import DeleteAlert from '../../Components/Common/DeleteAlert'

const moment = require('moment')

const styles = (theme) => ({
  root: {
    minHeight: 'inherit',
    backgroundColor: '#fff',
    width: '100%',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    backgroundColor: 'white',
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    padding: '17px 22px 17px 24px',
    flexGrow: 1,
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      padding: '17px 22px 17px 24px',
    },
  },
  datanotfound_security: {
    marginBottom: theme.spacing(6),
    padding: theme.spacing(2),
    flexGrow: 1,
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  heroUnit: {
    margin: '20px 0',
  },
  applicationHeader: {
    margin: '20px',
    borderBottom: '3px solid orange',
    paddingBottom: '5px',
  },
  main: {
    margin: '20px 30px',
  },
  addvisitor: {
    color: '#fff',
    padding: '7px 16px',
    borderRadius: '4px',
    textDecoration: 'none',
    fontSize: '0.875rem',
    fontWeight: '500',
    marginLeft: '18px',
    boxShadow: 'rgba(137, 163, 169, 0.3) 0px 3px 5px 2px',
    backgroundColor: '#211b4e !important',
    '&:hover': {
      backgroundColor: '#151039 !important',
    },
  },
  DataNotFound: {
    marginTop: '10%',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  table: {
    marginTop: '0px',
  },
  groupSvg: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '6%',
    backgroundColor: '#ffffff',
  },
  maintext: {
    color: '#626262',
  },
  subtext: {
    color: '#ABABAB',
  },
  backButton: {
    margin: '5% 0% 1% 2.4%',
    [theme.breakpoints.down('sm')]: {
      margin: '7% 0% 2% 3%',
    },
  },
  otherTitle: {
    padding: '12px 0px',
    backgroundColor: '#fff',
  },
  visitorName: {
    fontWeight: 550,
  },
  overrides: {
    // Style sheet name
    '.MuiTableCell-root': {
      // Some CSS
      padding: '14px 12% 10px 8px',
    },
  },
  visitor_contact: {
    fontSize: 12,
    color: '#929292',
  },
  root1: {
    '&:nth-child(even)': {
      backgroundColor: '#FCFDFE',
    },
  },
  searchSection: {
    margin: '24px 0px 8px',
  },
  appbar: {
    backgroundColor: '#ffffff',
  },
  firstletter: {
    textTransform: 'capitalize',
  },
  moveButton: {
    borderRadius: '50px',
    color: '#211b4e',
    border: '1px solid #959595',
    // margin: '8px',
    fontSize: '12px',
    backgroundColor: '#fff',
  },
})

class Visitors extends React.Component {
  state = {
    // eslint-disable-next-line react/no-unused-state
    open: true,
    page: 0,
    rowsPerPage: 10,
    search: '',
    isSearch: false,
    showConfirmDel: false,
    visitorId: '',
    type: '',
  }

  componentDidMount() {
    this.props.getvisitor()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visitors !== this.props.visitors) {
      let visitors = this.props.visitors || []
      visitors = visitors.map((x, id) => {
        return Object.assign({}, x, {
          // eslint-disable-next-line no-plusplus
          idx: id + 1,
        })
      })
      this.setState({
        visitors,
      })
    }
    const role = localStorage.getItem('role')
    if (role !== 'security') {
      if (prevProps.visitors.loading !== this.props.visitors.loading) {
        // this.props.setLoading(this.props.loading);
        this.setState({
          loading: this.props.visitors.loading,
        })
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.visitors !== prevState.visitors) {
      return {
        visitors: nextProps.visitors,
      }
    }

    return []
  }

  handleClose = () => {
    this.props.history.push('/dashboard')
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage })
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    })
  }

  searchVisitors = (search, isSearch) => {
    this.setState({ isSearch: true, search })
    this.props.getvisitor('', this.state.page, search, isSearch)
  }

  handleMoveVisitorPopup = (data) => {
    this.setState({
      visitorId: data,
      showConfirmDel: true,
    })
  }

  handleMoveVisitor = () => {
    const data = {}
    const id = this.state.visitorId
    data.type_of_invitation = 'walkthrough'
    this.props.changeVisitorType(id, data)
  }

  renderHead = () => {
    return (
      <TableHead>
        <TableRow>
          <TableHeader width="5%">Sr. No.</TableHeader>
          <TableHeader>Name</TableHeader>
          {checkPermission('SECURITY_SPECIAL_VIEW') && (
            <TableHeader width="15%">Purpose</TableHeader>
          )}
          {checkPermission('COMMON_PERMISSION_FOR_ADMIN_CM') && (
            <TableHeader width="15%">Company Name</TableHeader>
          )}
          <TableHeader width="15%">Email ID</TableHeader>
          <TableHeader>Scheduled</TableHeader>
          <TableHeader>Check-In</TableHeader>
          <TableHeader>Check-Out</TableHeader>
          <TableHeader>No. of Visitors</TableHeader>
          <TableHeader>Status</TableHeader>
          {checkPermission('COMMON_PERMISSION_FOR_ADMIN_CM') && (
            <TableHeader>Move To</TableHeader>
          )}
        </TableRow>
      </TableHead>
    )
  }

  renderBody = () => {
    const { classes } = this.props
    const { visitors, page, rowsPerPage } = this.state

    return (
      <TableBody className={classes.firstletter}>
        {visitors &&
          visitors
            //   // Pagination
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((item, id) => {
              console.log('item', item)
              return (
                <TableRow key={id} className={classes.root1}>
                  <Cell width="9%">{page * rowsPerPage + id + 1}</Cell>
                  <Cell>
                    {item.visitor && item.visitor.visitor_name}
                    <br />
                    <span className={classes.visitor_contact}>
                      {item.visitor && item.visitor.contact}
                    </span>
                    <br />
                  </Cell>
                  {checkPermission('SECURITY_SPECIAL_VIEW') ? (
                    <Cell width>
                      {item.type_of_invitation === 'walkthrough' ? (
                        <div className={classes.visitorName}>
                          {item.type_of_invitation}
                        </div>
                      ) : (
                        ''
                      )}
                      {item.type_of_invitation === 'walkin' ? (
                        <div>
                          <span className={classes.visitorName}>
                            {item.type_of_invitation}
                          </span>
                          <br />
                          {item.name_of_person_meeting && (
                            <span>
                              {item.name_of_person_meeting.first_name}
                              {item.name_of_person_meeting.last_name}
                            </span>
                          )}
                        </div>
                      ) : (
                        ''
                      )}
                      {item.type_of_invitation === 'bycoworker' ? (
                        <div>
                          <div className={classes.visitorName}>
                            Meeting {item.name_of_person_meeting && ':'}
                          </div>
                          <div className={classes.Name}>
                            {item.name_of_person_meeting &&
                              item.name_of_person_meeting.first_name}{' '}
                            {item.name_of_person_meeting &&
                              item.name_of_person_meeting.last_name}
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </Cell>
                  ) : (
                    ''
                  )}
                  {checkPermission('COMMON_PERMISSION_FOR_ADMIN_CM') && (
                    <Cell>
                      {item.company_id ? (
                        <>
                          {spacecapitalize(
                            item.company_id && item.company_id.company_name
                          )}
                        </>
                      ) : (
                        '-'
                      )}
                    </Cell>
                  )}
                  <Cell>
                    {item.visitor && item.visitor.email ? (
                      <span style={{ textTransform: 'none' }}>
                        {item.visitor.email}
                      </span>
                    ) : (
                      '-'
                    )}
                    <br />
                    {item.visitor && item.visitor.visitor_company_name ? (
                      <>
                        {spacecapitalize(
                          item.visitor && item.visitor.visitor_company_name
                        )}
                      </>
                    ) : (
                      '-'
                    )}
                  </Cell>
                  <Cell style={{ justifyContent: 'center' }}>
                    {item.type_of_invitation === 'bycoworker' ? (
                      <span>
                        {moment(item.date).format('DD/MM/YYYY')}
                        <br />
                        {moment(item.time).format('LT')}
                      </span>
                    ) : (
                      '-'
                    )}
                  </Cell>
                  <Cell style={{ justifyContent: 'center' }}>
                    {item.checkin && item.checkin ? (
                      <span>
                        {moment(item.date).format('DD/MM/YYYY')}
                        <br />
                        {moment(item.checkin).format('LT')}
                      </span>
                    ) : (
                      '-'
                    )}
                  </Cell>
                  <Cell style={{ justifyContent: 'center' }}>
                    {item.checkout && item.checkout ? (
                      <span>
                        {moment(item.date).format('DD/MM/YYYY')}
                        <br />
                        {item.checkout.toString().toUpperCase()}
                      </span>
                    ) : (
                      '-'
                    )}
                  </Cell>
                  <Cell>
                    {item && item.no_of_visitors ? (
                      <span style={{ textTransform: 'none' }}>
                        {item.no_of_visitors}
                      </span>
                    ) : (
                      '-'
                    )}
                  </Cell>
                  {checkPermission('SECURITY_SPECIAL_VIEW') ? (
                    <Cell>
                      <VisitorStatus
                        selectedItem={item.status}
                        id={item._id}
                        key={item._id}
                        // changeStatus={this.props.changeStatus}
                      />
                    </Cell>
                  ) : (
                    <Cell>{item.status}</Cell>
                  )}
                  {checkPermission('COMMON_PERMISSION_FOR_ADMIN_CM') &&
                    (item.type_of_invitation === 'walkthrough' ? (
                      ''
                    ) : (
                      <Cell>
                        <Button
                          variant="outlined"
                          size="small"
                          color="primary"
                          className={classes.moveButton}
                          onClick={() => this.handleMoveVisitorPopup(item._id)}
                        >
                          Walkthrough
                        </Button>
                      </Cell>
                    ))}
                </TableRow>
              )
            })}
      </TableBody>
    )
  }

  render() {
    // const { type, text, subtext, subComponent } = this.props;
    const { classes } = this.props
    const { visitors, loading, isSearch, search } = this.state
    const role = localStorage.getItem('role')
    const itemLength = visitors ? visitors.length : 0

    console.log('visitors::::::', visitors)

    if (loading) {
      return <CircularLoader />
    }

    if (visitors && visitors.length <= 0 && !isSearch) {
      if (role !== 'security') {
        return (
          <DataNotFound
            type="visitors"
            text="You don't have any visitors today!!"
            subtext=""
            paddingTop="8%"
            subComponent={
              <div className={classes.DataNotFound}>
                <Header />
                {checkPermission('VISITOR_CREATE') &&
                !checkPermission('SECURITY_SPECIAL_VIEW') ? (
                  <Link to="/visitors/add" className={classes.addvisitor}>
                    Add Visitors
                  </Link>
                ) : (
                  ''
                )}
              </div>
            }
          />
        )
      }
      return (
        <Box width="100%" height="100%" bgcolor="#ffffff">
          <Button
            color="grey"
            onClick={this.handleClose}
            className={classes.backButton}
            variant="contained"
            size="small"
          >
            Back
          </Button>
          <DataNotFound
            type="visitors"
            text="You don't have any visitors today!!"
            subtext=""
            subComponent={
              <div
                className={classes.DataNotFound}
                style={{ backgroundColor: '#ffffff' }}
              >
                <Header />
              </div>
            }
          />
        </Box>
      )
    }

    return (
      <div className={classes.root}>
        <Header />
        <div>
          {checkPermission('SECURITY_SPECIAL_VIEW') && (
            <div>
              <Button
                color="grey"
                onClick={this.handleClose}
                className={classes.backButton}
                variant="contained"
                size="small"
              >
                Back
              </Button>
              <div className={classes.otherTitle}>
                <ScreenHeader type="All Visitors" />
              </div>
            </div>
          )}

          {checkPermission('VISITOR_VIEW') &&
            !checkPermission('SECURITY_SPECIAL_VIEW') && (
              <div className={classes.header}>
                <ScreenHeader type="All Visitors" />
                <AddFormButton type="Visitor" url="/visitors/add" />
              </div>
            )}
          <div className={classes.searchSection}>
            <AppBar position="static" className={classes.appbar}>
              <Toolbar>
                <SearchBar
                  type="Search Visitors"
                  className={classes.searchBox}
                  value={this.state.search}
                  onSearch={this.searchVisitors}
                />
                {/* <div className={classes.sortby}>
                <SortBy selectedItem="SortBy" style={{ color: "#049b04" }} />
              </div> */}
              </Toolbar>
            </AppBar>
          </div>
          {visitors && visitors.length <= 0 && isSearch && (
            <NoRecordsFound
              text={`No records found for your text "${search}"`}
            />
          )}
        </div>
        <Paper className={classes.paper}>
          <div>
            {checkPermission('VISITOR_VIEW') &&
              visitors &&
              visitors.length > 0 && (
                <Table className={classes.table}>
                  {this.renderHead()}
                  {this.renderBody()}
                </Table>
              )}
          </div>
          <CssBaseline />
        </Paper>

        {visitors && visitors.length > 0 && (
          <Pagination
            count={itemLength}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        )}
        <DeleteAlert
          message="Are you sure want to move visitor in to walkthrough?"
          open={this.state.showConfirmDel}
          setOpen={(data) => this.setState({ showConfirmDel: data })}
          alert={this.handleMoveVisitor}
        />
      </div>
    )
  }
}

Visitors.propTypes = {}

const mapStateToProps = (state) => {
  return {
    visitors: state.visitors.visitors,
    // loading: state.visitors.loading,
    errors: state.errors,
  }
}

export default connect(mapStateToProps, {
  addvisitor,
  getvisitors,
  getvisitor,
  changeVisitorType,
})(withStyles(styles)(Visitors))
