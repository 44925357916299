/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { connect } from 'react-redux'
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  Paper,
  Button,
  AppBar,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import VisibleIcon from '@material-ui/icons/Visibility'
import { ExportToCsv } from 'export-to-csv'
import {
  addvisitor,
  getvisitors,
  changeVisitorType,
} from '../../Actions/visitorActions'
import SearchBar from '../../Components/Common/SearchBar'
import NoRecordsFound from '../../Components/Common/NoRecordsFound'
import DataNotFound from '../../Components/Common/DataNotFound'
import ScreenHeader from '../../Components/Common/ScreenHeader'
import { CircularLoader } from '../../Components/Common/Spinner'
import Pagination from '../../Components/Common/Table/TablePagination'
import Cell from '../../Components/Common/Table/TableCell'
import TableHeader from '../../Components/Common/Table/TableHeader'
import Header from '../../Components/Layout/Header'
import AddWalkthroughDetailModal from './AddWalkthroughDetailModal'
import avatar from '../../Assets/coworker3.png'
import { checkPermission } from '../../Utils/checkPermission'
import { capitalize, spacecapitalize } from '../../Utils/StringFunctions'
import CSV from '../../Assets/icons/CSV_logo.png'
import DeleteAlert from '../../Components/Common/DeleteAlert'

const moment = require('moment')

const styles = (theme) => ({
  root: {
    minHeight: 'inherit',
    backgroundColor: '#fff',
  },
  paper: {
    padding: '17px 22px 17px 24px',
    flexGrow: 1,
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      padding: '17px 22px 17px 24px',
    },
  },
  addvisitor: {
    color: '#fff',
    padding: '7px 16px',
    borderRadius: '4px',
    textDecoration: 'none',
    fontSize: '0.875rem',
    fontWeight: '500',
    marginLeft: '18px',
    boxShadow: 'rgba(137, 163, 169, 0.3) 0px 3px 5px 2px',
    backgroundColor: '#211b4e !important',
    '&:hover': {
      backgroundColor: '#151039 !important',
    },
  },
  DataNotFound: {
    marginTop: '10%',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  table: {
    marginTop: '0px',
  },
  subtext: {
    color: '#ABABAB',
  },
  backButton: {
    margin: '5% 0% 1% 2.4%',
    [theme.breakpoints.down('sm')]: {
      margin: '7% 0% 2% 3%',
    },
  },
  visitor_contact: {
    fontSize: 12,
    color: '#929292',
  },
  root1: {
    '&:nth-child(even)': {
      backgroundColor: '#FCFDFE',
    },
  },
  searchSection: {
    margin: '24px 0px 8px',
  },
  appbar: {
    backgroundColor: '#ffffff',
  },
  editbutton: {
    borderRadius: '50px',
  },
  memberName: {
    color: '#fff',
    fontSize: '12px',
    textTransform: 'capitalize',
  },
  userImage: {
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    margin: '6px 2px 6px 0px',
  },
  defaultuserImage: {
    width: '34px',
    height: '34px',
    borderRadius: '50%',
    marginTop: '3px',
  },
  capitalize: {
    textTransform: 'uppercase',
  },
  editicon: {
    marginRight: '5px',
  },
  exportCsv: {
    border: 'none',
    marginLeft: '10px',
    width: '22px',
    display: 'flex',
    cursor: 'pointer',
  },
  moveButton: {
    borderRadius: '50px',
    color: '#211b4e',
    border: '1px solid #959595',
    // margin: '8px',
    fontSize: '12px',
    backgroundColor: '#fff',
  },
})

class MyWalkthroughDetail extends React.Component {
  state = {
    // eslint-disable-next-line react/no-unused-state
    open: true,
    page: 0,
    rowsPerPage: 10,
    search: '',
    isSearch: false,
    walkthroughID: '',
    infoArray: '',
    showConfirmDel: false,
    visitorId: '',
    type: '',
  }

  componentDidMount() {
    this.props.getvisitors('', '', '')
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visitors !== this.props.visitors) {
      let visitors = this.props.visitors || []
      visitors = visitors.map((x, id) => {
        return Object.assign({}, x, {
          // eslint-disable-next-line no-plusplus
          idx: id + 1,
        })
      })
      this.setState({
        visitors,
      })
    }
    if (
      this.props.add_walkthrough_detail !== prevProps.add_walkthrough_detail &&
      this.props.add_walkthrough_detail.success === true
    ) {
      this.props.getvisitors('', '', '')
    }
    if (
      this.props.changeVisitorData !== prevProps.changeVisitorData &&
      this.props.changeVisitorData.success === true
    ) {
      this.props.getvisitors('', '', '')
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.visitors !== prevState.visitors) {
      return {
        visitors: nextProps.visitors,
      }
    }

    return []
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage })
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    })
  }

  searchVisitors = (search, isSearch) => {
    this.setState({ isSearch: true, search })
    this.props.getvisitors('', '', '', search, isSearch)
    if (search === '') {
      this.props.getvisitors('', '', '')
    }
  }

  renderHead = () => {
    return (
      <TableHead>
        <TableRow>
          <TableHeader width="5%">Sr. No.</TableHeader>
          <TableHeader width="8%">Date </TableHeader>
          <TableHeader width="10%">Name</TableHeader>
          <TableHeader width="10%">Company Name</TableHeader>
          <TableHeader width="8%">Email ID</TableHeader>
          <TableHeader width="8%">Check-In</TableHeader>
          <TableHeader width="8%">Check-Out</TableHeader>
          <TableHeader width="8%">Attended By </TableHeader>
          <TableHeader width="8%">Status</TableHeader>
          <TableHeader width="5%" />
          {checkPermission('COMMON_PERMISSION_FOR_ADMIN_CM') && (
            <TableHeader width="5%">Move to</TableHeader>
          )}
        </TableRow>
      </TableHead>
    )
  }

  toolTipTitle = (user) => {
    const { classes } = this.props
    return (
      <div>
        <Typography variant="subtitle2" className={classes.memberName}>
          {user && `${user.first_name} ${user.last_name}`}
        </Typography>
      </div>
    )
  }

  handleMoveVisitorPopup = (data) => {
    this.setState({
      visitorId: data,
      showConfirmDel: true,
    })
  }

  handleMoveVisitor = () => {
    const data = {}
    const id = this.state.visitorId
    data.type_of_invitation = 'walkin'
    this.props.changeVisitorType(id, data)
  }

  renderBody = () => {
    const { classes } = this.props
    const { visitors, page, rowsPerPage } = this.state

    return (
      <TableBody>
        {visitors &&
          visitors
            //   // Pagination
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((item, id) => {
              return (
                <TableRow key={id} className={classes.root1}>
                  <Cell width="5%">{page * rowsPerPage + id + 1}</Cell>
                  <Cell width="9%">
                    {' '}
                    {moment(item.checkin).format('DD/MM/YYYY ')}
                  </Cell>
                  <Cell width="10%" capitalize>
                    {spacecapitalize(item.visitor && item.visitor.visitor_name)}
                    <br />
                    <span className={classes.visitor_contact}>
                      {item.visitor && item.visitor.contact}
                    </span>
                    <br />
                  </Cell>
                  <Cell width="10%">
                    {item.visitor && item.visitor.visitor_company_name ? (
                      <>
                        {spacecapitalize(
                          item.visitor && item.visitor.visitor_company_name
                        )}
                      </>
                    ) : (
                      '-'
                    )}
                  </Cell>
                  <Cell width="10%">
                    {item.visitor && item.visitor.email ? (
                      <span style={{ textTransform: 'none' }}>
                        {item.visitor.email}
                      </span>
                    ) : (
                      '-'
                    )}
                  </Cell>
                  <Cell width="8%" style={{ justifyContent: 'center' }}>
                    {item.checkin ? (
                      <span>{moment(item.checkin).format('LT')}</span>
                    ) : (
                      '-'
                    )}
                  </Cell>
                  <Cell width="8%" style={{ justifyContent: 'center' }}>
                    {item.checkout ? (
                      <span className={classes.capitalize}>
                        {item.checkout}
                        <br />
                      </span>
                    ) : (
                      '-'
                    )}
                  </Cell>
                  <Cell width="9%">
                    {item.attend_by && item.attend_by.profile_pic ? (
                      <div className={classes.assignToAlignment}>
                        <Tooltip title={this.toolTipTitle(item.attend_by)}>
                          <img
                            src={item.attend_by && item.attend_by.profile_pic}
                            alt="avatar"
                            className={classes.userImage}
                          />
                        </Tooltip>
                      </div>
                    ) : (
                      <div className={classes.assignToAlignment}>
                        <Tooltip title={this.toolTipTitle(item.attend_by)}>
                          <img
                            src={avatar}
                            alt="avatar"
                            className={classes.defaultuserImage}
                          />
                        </Tooltip>
                      </div>
                    )}
                  </Cell>
                  <Cell width="9%">{capitalize(item.status)}</Cell>
                  <Cell width="10%" align="right">
                    {item.details_inserted === 'yes' ? (
                      <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        className={classes.editbutton}
                        onClick={() =>
                          this.handleOpen(
                            item.visitor && item.visitor._id,
                            item
                          )
                        }
                      >
                        <VisibleIcon
                          size="small "
                          className={classes.editicon}
                        />
                        View
                      </Button>
                    ) : (
                      checkPermission('WALKTHROUGH_CREATE') && (
                        <Button
                          variant="outlined"
                          size="small"
                          color="primary"
                          className={classes.editbutton}
                          onClick={() =>
                            this.handleOpen(
                              item.visitor && item.visitor._id,
                              item
                            )
                          }
                        >
                          <AddIcon size="small" className={classes.editicon} />
                          Add
                        </Button>
                      )
                    )}
                  </Cell>
                  {checkPermission('COMMON_PERMISSION_FOR_ADMIN_CM') && (
                    <Cell>
                      <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        className={classes.moveButton}
                        onClick={() => this.handleMoveVisitorPopup(item._id)}
                      >
                        Visitor
                      </Button>
                    </Cell>
                  )}
                </TableRow>
              )
            })}
      </TableBody>
    )
  }

  handleOpen = (id, data) => {
    // let { dataArray } = "";
    // (dataArray = {
    //   name: data.visitor && data.visitor.visitor_name,
    //   contact: data.visitor && data.visitor.contact,
    //   company_name: data.visitor && data.visitor.visitor_company_name,
    //   email: data.visitor && data.visitor.email,
    // }),
    this.setState({
      AddWalkthroughDetailModalOpen: true,
      walkthroughID: id,
      infoArray: {
        name: data.visitor && data.visitor.visitor_name,
        contact: data.visitor && data.visitor.contact,
        company_name: data.visitor && data.visitor.visitor_company_name,
        email: data.visitor && data.visitor.email,
      },
    })
    // props.close();
  }

  handleClose = () => {
    this.setState({ AddWalkthroughDetailModalOpen: false, walkthroughID: '' })
  }

  handleExportCsv = () => {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      //title: 'My Walkthrough Data',
      filename: 'My Walkthrough Data',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      // headers: ['Name', 'Email ID', 'Contact No.', 'Date of Birth'],
    }
    const csvExporter = new ExportToCsv(options)
    const data = this.state.visitors.map((item) => {
      return {
        Date: moment(item.checkin).format('DD/MM/YYYY '),
        Name: capitalize(item.visitor.visitor_name),
        'Contact Number': item.visitor.contact ? item.visitor.contact : '-',
        Email: item.visitor.email ? item.visitor.email : '-',
        'Company Name': capitalize(
          item.visitor.visitor_company_name
            ? item.visitor.visitor_company_name
            : '-'
        ),
        'Space Name': spacecapitalize(localStorage.getItem('space_name')),
      }
    })

    csvExporter.generateCsv(data)
  }

  render() {
    const { classes } = this.props
    const { visitors, loading, isSearch, search } = this.state
    const itemLength = visitors ? visitors.length : 0
    if (loading) {
      return <CircularLoader />
    }

    if (visitors === undefined && !isSearch) {
      return (
        <DataNotFound
          width="348px"
          type="visitors"
          text="No Results Found!"
          subtext=""
          paddingTop="6%"
        />
      )
    }

    return (
      <div className={classes.root}>
        <Header />
        <div>
          <div className={classes.header}>
            <ScreenHeader type="My Walkthroughs" />
            {/* <AddFormButton type="Walkthrough" url="/visitors/add" /> */}
          </div>

          <div className={classes.searchSection}>
            <AppBar position="static" className={classes.appbar}>
              <Toolbar>
                <SearchBar
                  type="Search Walkthroughs"
                  className={classes.searchBox}
                  onSearch={this.searchVisitors}
                />
                {checkPermission('COMMON_PERMISSION_FOR_ADMIN_CM') && (
                  <div className={classes.otherHeader}>
                    <div>
                      <img
                        src={CSV}
                        size={10}
                        className={classes.exportCsv}
                        onClick={() => this.handleExportCsv()}
                        alt="img"
                      />
                    </div>
                  </div>
                )}
              </Toolbar>
            </AppBar>
          </div>

          {visitors && visitors.length <= 0 && isSearch && (
            <NoRecordsFound
              text={`No records found for your text "${search}"`}
            />
          )}
        </div>
        <Paper className={classes.paper}>
          <div>
            {checkPermission('VISITOR_VIEW') &&
              visitors &&
              visitors.length > 0 && (
                <Table className={classes.table}>
                  {this.renderHead()}
                  {this.renderBody()}
                </Table>
              )}
          </div>
          <CssBaseline />
        </Paper>
        {visitors && visitors.length > 0 && (
          <Pagination
            count={itemLength}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        )}
        <AddWalkthroughDetailModal
          openCreateModal={this.state.AddWalkthroughDetailModalOpen}
          closeCreateModal={this.handleClose}
          visitorID={this.state.walkthroughID ? this.state.walkthroughID : ''}
          walkThroughInfo={this.state.infoArray}
          //  eventData={eventData}
        />
        <DeleteAlert
          message="Are you sure want to move walkthrough in to visitor?"
          open={this.state.showConfirmDel}
          setOpen={(data) => this.setState({ showConfirmDel: data })}
          alert={this.handleMoveVisitor}
        />
      </div>
    )
  }
}

MyWalkthroughDetail.propTypes = {}

const mapStateToProps = (state) => {
  return {
    visitors: state.visitors.visitors.finaldata,
    loading: state.visitors.loading,
    add_walkthrough_detail: state.visitors.add_walkthrough_detail,
    changeVisitorData: state.visitors.changeVisitorData,
    errors: state.errors,
  }
}

export default connect(mapStateToProps, {
  addvisitor,
  getvisitors,
  changeVisitorType,
})(withStyles(styles)(MyWalkthroughDetail))
