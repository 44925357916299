import React from "react";
import Card from "@material-ui/core/Card";
import {
  CardActions,
  CardContent,
  Button,
  Typography,
  Paper,
  Grid,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import CreateIcon from "@material-ui/icons/Create";
import {
  Instagram,
  Linkedin,
  Twitter,
  FacebookRoundIcon,
  FacebookGrey,
  InstagramGrey,
  TwitterGrey,
  LinkedinGrey,
  Website,
  WebsiteGrey,
} from "../../../Assets/index";
import defaultLogo from "../../../Assets/default_logo.png";
import defaultBanner from "../../../Assets/partner_banner.jpg";
import firstCapitalize from "../../../Utils/StringFunctions";
import { checkPermission } from "../../../Utils/checkPermission";
import SocialButton from "../SocialButton";
import NameTruncate from "../NameTruncate";

const styles = (theme) => {
  const borderRadius = theme.shape.borderRadius * 4;

  return {
    card: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      boxShadow: "0px 0px 20px rgba(0,0,0,0.05)",
      transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
      backgroundColor: "#FCFDFE",
      position: "relative",
      "&:hover": {
        transform: "scale(1.01)",
        boxShadow:
          "rgb(178 183 187 / 6%) 0px 2px 4px 6px, rgb(212 219 226 / 60%) 0px 2px 10px 0px",
      },
    },
    cardContent: {
      flexGrow: 1,
      padding: "10px",
      paddingTop: "0px",
      paddingBottom: "0px",
      "& p": {
        color: theme.palette.font.head1,
      },
      "& h2": {
        color: "#82A3FE",
      },
      "& h4": {
        color: theme.palette.font.head2,
        fontWeight: "normal",
      },
    },
    containerPaper: {
      border: `1px solid ${theme.palette.border.color}`,
    },
    profileImage: {
      display: "flex",
      flexDirection: "row",
      height: 120,
      justifyContent: "center",
      alignItems: "center",
      marginTop: "12%",
    },
    avatar: {
      width: "100%",
      height: "200px",
      // objectFit: "contain",
      backgroundColor: "whitesmoke",
    },
    partnerInfo: {
      display: "flex",
      flexDirection: "row",
      justify: "center",
      alignItems: "center",
      flexWrap: "wrap",
      "& p": {
        fontSize: "12px",
      },
    },
    iconContainer: {
      // marginTop: "10px"
      paddingTop: "0px",
      paddingBottom: "0px",
    },
    cardFooter: {
      justifyContent: "space-around",
      "& a": {
        textDecoration: "none",
      },
      "& button": {
        margin: theme.spacing(1),
        fontSize: 10,
        border: "1px solid #211b4e",
        borderRadius: `${borderRadius}px`,
        backgroundColor: "#ffffff",
        color: "#211b4e",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "#ffffff",
        },
      },
    },
    button: {
      margin: "0px 10px 0px 0px",
      borderRadius: "20px",
      backgroundColor: "#efefef",
      color: "#626262",
      cursor: "default",
      "&:hover": {
        backgroundColor: "#efefef",
        color: "#626262",
      },
    },
    social: {
      height: "20px",
      width: "20px",
    },
    orangeAvatar: {
      color: "#fff",
      backgroundColor: "white",
      width: "55px",
      height: "55px",
      fontSize: "36px",
      position: "relative",
      marginLeft: "10px",
      filter: `drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.3))`,
      padding: "5px",
      borderRadius: "4px",
      objectFit: "contain",
    },
    socialIcon: {
      // padding: "5px"
    },
    alignment: {
      fontSize: "10px",
      paddingTop: "15px",
      margin: "2px",
      color: "#211b4e",
      // border: "1px solid rgb(33, 27, 78)",
    },
    partnerTitle: {
      marginTop: "20px",
      marginLeft: "10px",
      paddingTop: "16px",
    },
    logo: {
      paddingTop: "23px",
    },
    companyName: {
      display: "flex",
      color: "#111111",
      fontSize: "14px",
      marginLeft: "5%",
      marginTop: "10px",
    },
    editbutton: {
      height: "39px",
      width: "41px",
      backgroundColor: "#41bdff",
      margin: "7px -1px 0px",
    },
    edit_link: {
      textDecoration: "none",
      color: "grey",
      border: "1px solid #d1d1d1",
      borderRadius: "56px 38px 54px 100px",
      borderTop: "none",
      borderRight: "none",
      backgroundColor: "#d1d1d1",
      padding: "2px 4px 1px 5px",
      position: "absolute",
      top: "-2px",
      right: "-4px",
    },

    viewMoreService: {
      textDecoration: "none",
      margin: theme.spacing(1),
      fontSize: 10,
      marginLeft: "0px",
      marginBottom: "0px",
      borderRadius: `${borderRadius}px`,
      backgroundColor: "#fff",
      color: "#747474",
      padding: "0px !important",
      "&:hover": {
        backgroundColor: "#fff",
        color: "#747474",
      },
    },
    labelFonts: {
      fontSize: "12px",
    },
    websiteIcon: {
      position: "relative",
      top: "8px",
    },
    aboutinnercontent: {
      // paddingTop: "15px",
    },
    noServiceData: {
      minHeight: "91px",
    },
  };
};

function PartnerCard(props) {
  const { classes, data, history } = props;
  const handleCardClick = () => {
    const path = `/partner/${data._id}`;
    history.push(path);
  };
  return (
    <div>
      <Card className={classes.card}>
        {checkPermission("PARTNER_UPDATE") && (
          <Link className={classes.edit_link} to={`/partner/edit/${data._id}`}>
            <CreateIcon fontSize="small" />
          </Link>
        )}
        <Paper className={classes.containerPaper}>
          <Grid className={classes.profileImage} onClick={handleCardClick}>
            <img
              className={classes.avatar}
              src={
                // eslint-disable-next-line no-nested-ternary
                data.company_banner
                  ? data.company_banner
                  : data.banner
                  ? data.banner
                  : defaultBanner
              }
              alt="logo"
            />
          </Grid>

          <div className={classes.eventbasicdetail}>
            <Grid
              container
              item
              lg={12}
              xs={12}
              justify="flex-start"
              onClick={handleCardClick}
            >
              <Grid item lg={2} xs={2}>
                <Grid item className={classes.logo}>
                  {data.company_logo ? (
                    <img
                      className={classes.orangeAvatar}
                      src={data.company_logo}
                      alt=" "
                    />
                  ) : (
                    <img
                      className={classes.orangeAvatar}
                      src={defaultLogo}
                      alt=""
                    />
                  )}
                </Grid>
              </Grid>

              <Grid item lg={10} xs={10}>
                <Grid item className={classes.partnerTitle}>
                  <Typography
                    variant="subtitle2"
                    component="p"
                    className={classes.companyName}
                  >
                    <NameTruncate
                      text={firstCapitalize(data.company_name)}
                      amount={26}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </div>
          <CardContent
            className={classes.cardContent}
            onClick={handleCardClick}
          >
            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={12} lg={12}>
                <Grid
                  container
                  className={classes.iconContainer}
                  justify="flex-end"
                >
                  <Grid item className={classes.socialIcon}>
                    <Paper className={classes.paper}>
                      {data.linkedin ? (
                        <SocialButton type={Linkedin} url={data.linkedin} />
                      ) : (
                        <SocialButton type={LinkedinGrey} disable />
                      )}
                    </Paper>
                  </Grid>
                  <Grid item className={classes.socialIcon}>
                    <Paper className={classes.paper}>
                      {data.facebook ? (
                        <SocialButton
                          type={FacebookRoundIcon}
                          url={data.facebook}
                        />
                      ) : (
                        <SocialButton type={FacebookGrey} disable />
                      )}
                    </Paper>
                  </Grid>
                  <Grid item className={classes.socialIcon}>
                    <Paper className={classes.paper}>
                      {data.twitter ? (
                        <SocialButton type={Twitter} url={data.twitter} />
                      ) : (
                        <SocialButton type={TwitterGrey} disable />
                      )}
                    </Paper>
                  </Grid>
                  <Grid item className={classes.socialIcon}>
                    <Paper className={classes.paper}>
                      {data.instagram ? (
                        <SocialButton type={Instagram} url={data.instagram} />
                      ) : (
                        <SocialButton type={InstagramGrey} disable />
                      )}
                    </Paper>
                  </Grid>
                  <Grid item className={classes.socialIcon}>
                    <Paper className={classes.paper}>
                      {data.website ? (
                        <SocialButton type={Website} url={data.website} />
                      ) : (
                        <SocialButton type={WebsiteGrey} disable />
                      )}
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>

          {/* {data.services.length > 0 ? (
            <CardContent className={classes.cardContent}>
              <Typography
                variant="subtitle2"
                component="p"
                className={classes.labelFonts}
              >
                Services
              </Typography>
              <div className={classes.partnerInfo}>
                {data.services && (
                  <div className={classes.alignment}>
                    <Button
                      variant="contained"
                      size="small"
                      className={classes.button}
                    >
                      {data.services &&
                        data.services[0].label &&
                        capitalize(data.services[0].label)}
                    </Button>
                  </div>
                )}
                {data.services && data.services.length > 1 ? (
                  <Link
                    to={`/partner/${data._id}`}
                    style={{
                      textDecoration: "none",
                      marginLeft: "-10px",
                      paddingTop: "15px",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.viewMoreService}
                      size="small"
                    >
                      ...View more
                    </Button>
                  </Link>
                ) : (
                  ""
                )}
              </div> */}
          {/* <Typography
                variant="subtitle2"
                component="p"
                className={classes.labelFonts}
              >
                Services
              </Typography> */}
          {/* <div className={classes.aboutinnercontent}>
                {data.services &&
                  data.services.map((el) => {
                    return (
                      <Button
                        variant="contained"
                        size="small"
                        className={classes.button}
                      >
                        <CardFooterViewMore
                          text={el && el.label}
                          isSmallFont
                          isButton
                        />
                      </Button>
                    );
                  })}
              </div> */}
          {/* </CardContent>
          ) : (
            <div className={classes.noServiceData}></div>
          )} */}
        </Paper>
        {checkPermission("PARTNER_VIEW") && (
          <CardActions className={classes.cardFooter}>
            <Link to={`/partner/${data._id}`}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                size="small"
              >
                View Partner
              </Button>
            </Link>
          </CardActions>
        )}
      </Card>
    </div>
  );
}
export default withStyles(styles)(PartnerCard);
