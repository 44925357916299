/* eslint-disable camelcase */
import axios from "axios";
import {
  GET_ALL_CLUBS,
  CREATE_CLUB,
  ALL_CLUBS_LOADING,
  ALL_CLUBS_ERRORS,
  GET_SINGLE_CLUB,
  JOIN_CLUB,
  DELETE_CLUB,
  DELETE_CLUB_ERRORS,
  EDIT_CLUB,
  EDIT_CLUB_ERRORS,
} from "./types";
import { showSuccess, showError } from "./ActionsHelper";
import { transformErrorsFromApi } from "./TransformError";

export const setAllClubsLoading = () => {
  return {
    type: ALL_CLUBS_LOADING,
  };
};
export const getAllClubs = (page, search) => (dispatch) => {
  dispatch(setAllClubsLoading());
  let url = "/api/club";

  if (search) {
    url = `/api/club?q=${search}`;
  }

  axios
    .get(url)
    .then((res) => {
      dispatch({
        type: GET_ALL_CLUBS,
        reset: page === 0,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: ALL_CLUBS_ERRORS,
        payload: err && err.response ? err.response.data : "",
      })
    );
};
export const getSingleClubs = (id) => (dispatch) => {
  // dispatch(setAllClubsLoading());
  axios
    .get(`/api/club/${id}`)
    .then((result) => {
      dispatch({
        type: GET_SINGLE_CLUB,
        payload: result.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: ALL_CLUBS_ERRORS,
        payload: err && err.response ? err.response.data : "",
      });
    });
};

export const createClub = (data, setErrors, history, setSubmitting) => (
  dispatch
) => {
  dispatch(setAllClubsLoading());
  axios
    .post("/api/club", data)
    .then((result) => {
      dispatch({
        type: CREATE_CLUB,
        payload: result.data,
      });
      dispatch(showSuccess("Club"));
      setSubmitting(false);
    })
    .catch((err) => {
      dispatch({
        type: ALL_CLUBS_ERRORS,
        payload: err && err.response ? err.response.data.data : "",
      });
      dispatch(showError(err.response.data.data));
    });
};
// eslint-disable-next-line camelcase
export const joinClub = (club_id, join_status, history, redirect) => (
  dispatch
) => {
  const data = {};
  // eslint-disable-next-line camelcase
  data.club_id = club_id;
  // eslint-disable-next-line no-use-before-define
  dispatch(setAllClubsLoading());
  // data.user_id = localStorage.getItem("user_id");
  data.status = join_status;
  axios
    .post("/api/club/join", data)
    .then((result) => {
      dispatch({
        type: JOIN_CLUB,
        payload: result.data,
      });
      if (redirect) {
        history.push("/clubs");
      }
      if (join_status === "yes")
        dispatch(showSuccess("", "You joined in a club!"));
      else dispatch(showSuccess("", "You unjoined a club!"));
    })
    .catch((err) => {
      const error = err.response;
      dispatch({ type: ALL_CLUBS_ERRORS, payload: error.data });
      dispatch(showError("There was some error joining in a club!"));
    });
};

export const deleteClub = (id) => (dispatch) => {
  dispatch(setAllClubsLoading());
  axios
    .delete(`/api/club/delete/${id}`)
    .then((result) => {
      dispatch({
        type: DELETE_CLUB,
        payload: result.data,
      });
      dispatch(showSuccess("", "Club deleted successfully!"));
    })

    .catch((err) => {
      dispatch({
        type: DELETE_CLUB_ERRORS,
        payload: err && err.response ? err.response.data : "",
      });
    });
};

export const editClub = (id, data, setErrors, setSubmitting) => (dispatch) => {
  dispatch(setAllClubsLoading());
  axios
    .put(`/api/club/${id}`, data)
    .then((result) => {
      dispatch({
        type: EDIT_CLUB,
        payload: result.data,
      });
      dispatch(showSuccess("", "Club edited successfully!"));
      setSubmitting(false);
    })
    .catch((err) => {
      const error = err.response;
      dispatch({ type: EDIT_CLUB_ERRORS, payload: {} });
      setSubmitting(false);
      setErrors(transformErrorsFromApi(error));
    });
};
