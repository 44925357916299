import React, { useEffect, useState } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { connect } from 'react-redux'
import { Grid } from '@material-ui/core'
import { getAllTeam } from '../../Actions/eventActions'
import TeamCard from '../../Components/Common/Cards/TeamCard'
import DataNotFound from '../../Components/Common/DataNotFound'
import ScreenHeader from '../../Components/Common/ScreenHeader'

const $quoteSym = ' '

const styles = () => ({
  formContent: {
    width: 500,
    padding: '34px 24px',
  },
  modelHeader: {
    paddingBottom: '5px',
    position: 'relative',
    '&:after': {
      top: '48px',
      left: '24px',
      width: '24px',
      borderBottom: '3px solid #fb0',
      content: `'${$quoteSym}'`,
      position: 'absolute',
    },
  },
  team_card: {
    marginBottom: '25px',
  },
  teamCardSection: {
    width: '18%',
    marginRight: '18px',
  },
})

function TeamTabs(props) {
  const { classes, eventID } = props
  const [allTeam, setAllTeam] = useState([]) //  all team data

  useEffect(() => {
    props.getAllTeam(eventID) // get all team
  }, [])

  useEffect(() => {
    setAllTeam(props.all_team)
  }, [props.all_team])
  console.log('props', props)
  console.log('allTeam condition', allTeam === undefined)
  return (
    <React.Fragment>
      <div className={classes.teamMainSection}>
        <Grid container spacing={2}>
          <ScreenHeader type=" All Teams" />
          {allTeam === undefined ? (
            <DataNotFound text="No Team Found" />
          ) : (
            allTeam &&
            allTeam.map((element) => {
              return (
                <Grid key={element.id} item sm={4} lg={3} md={3}>
                  <div className={classes.team_card}>
                    <TeamCard data={element} footer="no" />
                  </div>
                </Grid>
              )
            })
          )}
        </Grid>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    errors: state.errors,
    all_team: state.events.all_team,
    register_team: state.events.register_team,
  }
}

export default connect(mapStateToProps, { getAllTeam })(
  withStyles(styles)(TeamTabs)
)
