import React from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router-dom";
import {
  CssBaseline,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Cell from "../../Components/Common/Table/TableCell";
import Header from "../../Components/Common/Table/TableHeader";
import Pagination from "../../Components/Common/Table/TablePagination";

const moment = require("moment");

const styles = () => ({
  main: {
    padding: "24px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  statusButton: {
    borderRadius: "50px",
    marginRight: "10px",
    color: "#4d7cfe",
    border: "1px solid #4d7cfe",
    height: "30px",
    padding: "1px 12px",
    "&:hover": {
      backgroundColor: "#4d7cfe",
      color: "#fff",
    },
  },
  root1: {
    "&:nth-child(even)": {
      backgroundColor: "#FCFDFE",
    },
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F8FAFF",
    color: theme.palette.common.black,
    borderBottom: "none",
  },
  body: {
    fontSize: 14,
    textTransform: "capitalize",
    borderBottom: "none",
  },
}))(TableCell);

const SubTableRow = withStyles(() => ({
  body: {
    fontSize: 12,
    borderBottom: "none",
  },
}))(TableRow);

const SubStyledTableCell = withStyles(() => ({
  body: {
    fontSize: 12,
    color: "gray",
    fontWeight: "bold",
  },
}))(StyledTableCell);

class OrderHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // plan_categories: [],
      viewAccordion: {},
      page: 0,
      rowsPerPage: 10,
    };
  }

  accordionOpen = (id) => {
    const { viewAccordion } = this.state;
    viewAccordion[id] = viewAccordion[id] ? !viewAccordion[id] : true;
    this.setState({
      viewAccordion,
    });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  renderHead = () => {
    return (
      <TableHead>
        <TableRow>
          <Header>Sr. No.</Header>
          <Header>Order Number</Header>
          <Header>Date</Header>
          <Header>Bill in INR</Header>
        </TableRow>
      </TableHead>
    );
  };

  renderBody = () => {
    const { classes } = this.props;
    const { page, rowsPerPage } = this.state;
    return (
      <TableBody>
        {this.props.data &&
          this.props.data
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((item, id) => {
              return (
                <React.Fragment>
                  <TableRow className={classes.root1}>
                    <Cell>{page * rowsPerPage + id + 1}</Cell>

                    <Cell onClick={() => this.accordionOpen(id)} cursor>
                      #{item.order_number}
                    </Cell>

                    <Cell>
                      {moment(item.createdAt).format("DD/MM/YYYY")}
                      {/* {item.createdAt} */}
                    </Cell>

                    <Cell>
                      Rs.
                      {item &&
                        item.orderitem &&
                        item.orderitem.reduce(
                          // eslint-disable-next-line no-shadow
                          (sum, item) =>
                            sum + Number(item.price) * Number(item.quantity),
                          0
                        )}
                    </Cell>
                  </TableRow>

                  {this.state.viewAccordion[id] === true &&
                    item.orderitem &&
                    item.orderitem.map((fooddata) => {
                      return (
                        <SubTableRow>
                          <SubStyledTableCell />
                          <SubStyledTableCell align="left">
                            {fooddata.food_item.food_name} {"         "}
                            Quantity: {fooddata.quantity}
                          </SubStyledTableCell>
                          <SubStyledTableCell />
                          <SubStyledTableCell>
                            Rs.
                            {fooddata.price}
                          </SubStyledTableCell>
                        </SubTableRow>
                      );
                    })}
                </React.Fragment>
              );
            })}
      </TableBody>
    );
  };

  render() {
    const { classes } = this.props;
    const itemLength = this.props.data ? this.props.data.length : 0;

    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.header}>
          {/* <div className={classes.main}>delivered order</div> */}
          <Table className={classes.table}>
            {this.renderHead()}
            {this.renderBody()}
          </Table>
        </div>
        <Pagination
          count={itemLength}
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </React.Fragment>
    );
  }
}
OrderHistory.propTypes = {};
const mapStateToProps = () => {
  return {};
};
export default connect(
  mapStateToProps,
  {}
)(withStyles(styles)(withRouter(OrderHistory)));
