import React, { useState, useEffect } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Typography, Grid } from "@material-ui/core";
import NoVisitor from "../../Assets/NoVisitors.png";

const moment = require("moment");

const $quoteSym = " ";
const styles = () => ({
  visitors_section: {
    border: "1px solid #efefef",
    padding: "10px",
    height: "240px",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.03)",
    borderRadius: "4px",
    background: "#fff",
  },
  visitor_cont: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #efefef",
    padding: "15px 5px",
    paddingLeft: "0px",
  },
  visitor_name: {
    fontSize: "14px",
    lineHeight: "16px",
    color: "#626262",
    fontWeight: "400",
    textTransform: "capitalize",
  },
  screenheader: {
    fontSize: "16px",
    fontWeight: "600",
    position: "relative",
    "&:after": {
      top: "24px",
      left: "0px",
      width: "24px",
      borderBottom: "3px solid #fb0",
      content: `'${$quoteSym}'`,
      position: "absolute",
    },
  },
  visitorImage: {
    textAlign: "center",
    height: "200px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  noVisitor_cont: {
    color: "lightgrey",
    padding: "14px 0px 0px",
  },
  noVisitor: {
    // height: "50%",
    // paddingBottom: "20px",
    width: "300px",
  },
  visitorCompanyName: {
    fontSize: "12px",
  },
});

function Visitors(props) {
  const { classes } = props;
  const [visitors, setVisitors] = useState([]);

  useEffect(() => {
    if (props.visitors.visitors && props.visitors.visitors.length > 0) {
      setVisitors(props.visitors.visitors);
    }
  }, [props.visitors]);

  if (visitors && visitors.length <= 0) {
    return (
      <Grid container className={classes.visitors_section}>
        <Grid item xs={12} lg={12} sm={12}>
          <Typography
            variant="subtitle2"
            className={classes.noVisitor_cont}
            align="center"
          >
            No Visitors!
          </Typography>
        </Grid>
        <Grid item className={classes.visitorImage} xs={12} lg={12} sm={12}>
          <img alt="logo" src={NoVisitor} className={classes.noVisitor} />
        </Grid>
      </Grid>
    );
  }
  return (
    <div className={classes.visitors_section}>
      <span className={classes.screenheader} align="left">
        Visitors
      </span>
      {visitors &&
        visitors.map((visitor, i) => {
          if (i > 2) {
            return "";
          }
          return (
            <div className={classes.visitor_cont}>
              <Typography
                variant="subtitle2"
                className={classes.visitor_name}
                align="left"
              >
                {visitor.visitor && visitor.visitor.visitor_name}
                <br />
                <span className={classes.visitorCompanyName}>
                  ({visitor.visitor && visitor.visitor.visitor_company_name})
                </span>
              </Typography>
              <Typography
                align="right"
                variant="subtitle1"
                className={classes.visitor_name}
              >
                {moment(visitor.date).format("LT")}
              </Typography>
            </div>
          );
        })}
    </div>
  );
}

export default withStyles(styles)(Visitors);
