import withStyles from '@material-ui/core/styles/withStyles'
import React from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Typography,
  CssBaseline,
  Button,
  Avatar,
  Paper,
} from '@material-ui/core'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { getSingleReferFriend, createComment } from 'Actions/ReferFriendActions'
import { capitalize, spacecapitalize } from 'Utils/StringFunctions'
import { Formik } from 'formik'
import TextareaField from 'Components/Common/Form/TextareaField'
import { Form } from 'react-formik-ui'
import avatar from 'Assets/coworker3.png'
import NameTruncate from 'Components/Common/NameTruncate'
import { checkPermission } from 'Utils/checkPermission'

const moment = require('moment')

const styles = () => ({
  helpdeskMain: {
    padding: '17px 17px 17px',
    backgroundColor: '#fff',
    borderRadius: '4px',
    // height: "33vh",
  },
  eventTitle: {
    paddingBottom: '8px',
  },
  eventsubtitle: {
    fontSize: '12px',
    // color: "grey",
    paddingTop: '18px',
    fontWeight: '600',
    textAlign: 'left',
    color: '#333',
    paddingBottom: '5px',
  },
  eventsubtitle1: {
    fontSize: '14px',
    // color: "grey",
    fontWeight: 'normal',
    textAlign: 'left',
    color: '#626262',
    lineHeight: '17px',
  },
  comment_section: {
    border: '1px solid #efefef',
    textAlign: 'right',
    position: 'relative',
    height: '81px',
    marginLeft: '10px',
    borderRadius: '10px',
  },
  submitbutton: {
    backgroundColor: '#211b4e !important',
    '&:hover': {
      backgroundColor: '#151039 !important',
    },
    marginTop: '6px',
    marginBottom: '35px',
    '&:disabled': {
      backgroundColor: 'lightgrey !important',
    },
  },
  input_field: {
    border: '1px solid #f5f5f5',
    height: '100%',
    position: 'relative',
    width: '100%',
    padding: '6px 8px',
    margin: '0px',
    borderBottom: 'none',
    fontFamily:
      "'-apple-system,BlinkMacSystemFont','Segoe UI','Roboto','Helvetica Neue','Arial,sans-serif','Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol'",
    color: 'grey',
    fontSize: '14px',
    resize: 'none',
  },
  createPostSection: {
    paddingRight: '20px',
  },
  postSection: {
    backgroundColor: '#fff',
    marginTop: '2%',
    paddingTop: '1%',
    borderRadius: '4px',
  },
  historyTime: {
    marginLeft: '11px',
    color: '#ababab',
    fontWeight: 'normal',
    paddingTop: '0px',
    fontSize: '12px',
    textTransform: 'capitalize',
  },
  historyTitle: {
    fontSize: '14px',
    // color: "grey",
    paddingBottom: '2%',
    fontWeight: 'normal',
    // textAlign: "left",
    color: '#626262',
    position: 'relative',
    top: '2px',
    left: '38px',
    width: '91%',
    textAlign: 'justify',
  },
  historyMain: {
    padding: '20px 20px 0px 0px',
    borderBottom: '1px solid #efefef',
    marginLeft: '15px',
  },
  comment: {
    fontSize: '12px',
  },
  rootpaper: {
    height: '67vh',
  },
})

class HelpdeskDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      referFriend: {},
    }
    this.state.id = this.props.match.params.id
  }

  componentDidMount() {
    this.props.getSingleReferFriend(this.state.id)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.referFriend !== this.props.referFriend) {
      this.setState({
        referFriend: this.props.referFriend,
      })
    }
    if (prevProps.referFriendComment !== this.props.referFriendComment) {
      this.props.getSingleReferFriend(this.state.id)
    }
  }

  render() {
    const { classes } = this.props
    const { referFriend } = this.state

    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.mainsection}>
          <main>
            <div className={classNames(classes.layout, classes.cardGrid)}>
              {/* End hero unit */}
              <div className={classes.eventbasicdetail}>
                <Grid
                  container
                  item
                  lg={12}
                  xs={12}
                  className={classes.helpdeskMain}
                >
                  <Grid item lg={2} xs={2}>
                    <div className={classes.eventTitle}>
                      <Typography
                        variant="body1"
                        component="p"
                        className={classes.eventsubtitle}
                      >
                        Name
                      </Typography>
                      <Typography
                        className={classes.eventsubtitle1}
                        variant="subtitle1"
                        component="p"
                      >
                        {capitalize(referFriend.name)}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item lg={2} xs={2}>
                    <div className={classes.eventTitle}>
                      <Typography
                        className={classNames(classes.eventsubtitle)}
                        variant="subtitle1"
                        component="p"
                      >
                        Contact Number
                      </Typography>
                      <Typography
                        className={classes.eventsubtitle1}
                        variant="subtitle1"
                        component="p"
                      >
                        {referFriend.contact_number}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item lg={2} xs={2}>
                    <div className={classes.eventTitle}>
                      <Typography
                        variant="body1"
                        component="p"
                        className={classes.eventsubtitle}
                      >
                        Company Name
                      </Typography>
                      <Typography
                        className={classes.eventsubtitle1}
                        variant="subtitle1"
                        component="p"
                      >
                        {referFriend.company_name
                          ? capitalize(referFriend.company_name)
                          : '-'}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item lg={2} xs={2}>
                    <div className={classes.eventTitle}>
                      <Typography
                        className={classNames(classes.eventsubtitle)}
                        variant="subtitle1"
                        component="p"
                      >
                        Location
                      </Typography>
                      <Typography
                        className={classes.eventsubtitle1}
                        variant="subtitle1"
                        component="p"
                      >
                        {referFriend.location
                          ? capitalize(referFriend.location)
                          : '-'}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item lg={2} xs={2}>
                    <div className={classes.eventTitle}>
                      <Typography
                        variant="body1"
                        component="p"
                        className={classes.eventsubtitle}
                      >
                        Email
                      </Typography>
                      <Typography
                        className={classes.eventsubtitle1}
                        variant="subtitle1"
                        component="p"
                      >
                        <NameTruncate
                          text={referFriend.email ? referFriend.email : '-'}
                          amount={13}
                        />
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item lg={2} xs={2}>
                    <div className={classes.eventTitle}>
                      <Typography
                        className={classNames(classes.eventsubtitle)}
                        variant="subtitle1"
                        component="p"
                      >
                        Product
                      </Typography>
                      <Typography
                        className={classes.eventsubtitle1}
                        variant="subtitle1"
                        component="p"
                      >
                        {referFriend.product
                          ? spacecapitalize(referFriend.product)
                          : '-'}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
            <Paper className={classes.rootpaper}>
              {checkPermission('REFER_FRIEND_COMMENT_UPDATE') && (
                <div
                  className={classNames(classes.layout, classes.postSection)}
                >
                  <Grid
                    className={classes.createPostSection}
                    container
                    item
                    lg={12}
                    xs={12}
                  >
                    <Grid item lg={8} xs={8}>
                      <Formik
                        initialValues={{ comment: '' }}
                        enableReinitialize
                        onSubmit={(values, { setErrors, resetForm }) => {
                          // eslint-disable-next-line no-param-reassign
                          this.props.createComment(
                            referFriend._id,
                            values,
                            setErrors
                            // setSubmitting,
                            // this.props.history
                          )
                          resetForm(this.initialValues)
                        }}
                      >
                        {({ values, handleSubmit }) => (
                          <Form
                            onSubmit={handleSubmit}
                            className={classes.form}
                            encType="multipart/form-data"
                            autoComplete="off"
                          >
                            <div className={classes.comment_section}>
                              <TextareaField
                                name="comment"
                                className={classes.input_field}
                              />

                              <Button
                                className={classes.submitbutton}
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={this.onSubmit}
                                disabled={!values.comment}
                              >
                                Post
                              </Button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </Grid>
                  </Grid>
                  <div style={{ paddingTop: '50px' }}> </div>
                  {referFriend.comments &&
                    referFriend.comments.map((data) => {
                      return (
                        <Grid
                          container
                          item
                          lg={12}
                          xs={12}
                          className={classes.historyMain}
                        >
                          <Grid item>
                            {data.created_by && data.created_by.profile_pic ? (
                              <Avatar
                                className={classes.avatar}
                                src={data.created_by.profile_pic}
                              />
                            ) : (
                              <Avatar className={classes.avatar} src={avatar} />
                            )}
                          </Grid>
                          <Typography
                            className={classes.historyTitle}
                            variant="subtitle1"
                            component="p"
                          >
                            {capitalize(data.comment)}
                            <span className={classes.historyTime}>
                              by {capitalize(data.first_name)}{' '}
                              {capitalize(data.last_name)}
                            </span>
                            <span className={classes.historyTime}>
                              On {moment(data.createdAt).format('MMMM DD')}
                            </span>
                            {/* {data.comment && (
                          <div className={classes.comment}>
                            {`"${data.comment}"`}
                          </div>
                        )} */}
                          </Typography>
                        </Grid>
                      )
                    })}
                </div>
              )}
            </Paper>
          </main>
        </div>
      </React.Fragment>
    )
  }
}

HelpdeskDetail.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
  return {
    referFriend: state.referFriend.referFriend,
    referFriendComment: state.referFriend.referFriendComments,
  }
}

export default connect(mapStateToProps, {
  getSingleReferFriend,
  createComment,
})(withStyles(styles)(HelpdeskDetail))
