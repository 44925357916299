/* eslint-disable camelcase */
import React from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { Typography, Grid } from "@material-ui/core";
import community_heroes from "../../Assets/community_heroes.svg";
import cheer_boosters from "../../Assets/cheer_boosters.svg";
import people_charmer from "../../Assets/people_charmer.svg";
import chatterbox from "../../Assets/chatterbox.svg";
import champion from "../../Assets/champion.svg";
import foodie from "../../Assets/foodie.svg";
import question_mark from "../../Assets/question_mark.svg";
import quick_learner from "../../Assets/quick_learner.svg";
import mr_ms_dependable from "../../Assets/mr_ms_dependable.svg";
import search_engine from "../../Assets/search_engine.svg";
import budding_star from "../../Assets/budding_star.svg";

const styles = () => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "12px",
  },
  mainTitle: {
    fontWeight: "600",
    fontSize: "20px",
    textAlign: "left",
    color: "#222222",
    margin: "0px",
    lineHeight: "25px",
  },
  title: {
    fontSize: "13px",
    color: "#222222",
    fontWeight: "500",
  },
  phone: {
    color: "#358CEE",
    paddingTop: "10px",
  },
  description: {
    color: "#222222",
    fontSize: "11px",
    lineHeight: "13px",
    textAlign: "center",
  },
  generalDetails: {
    // padding: "3% 0% 0%"
  },
  cardLayout: {
    border: "1px solid #EFEFEF",
    borderRadius: "5px",
    boxShadow: "0 9px 10px rgba(0, 0, 0, 0.1), 0 4px 0px rgba(0,0,0,0.05)",
    padding: "10px",
  },
  badgesImage: {
    height: "200px",
    width: "200px",
  },
});

function Badges(props) {
  const { classes } = props;

  return (
    <div className={classes.main}>
      <div className={classes.header}>
        <Typography align="left" variant="h1" className={classes.mainTitle}>
          Badges
        </Typography>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Grid container spacing={2}>
            <Grid item xs={6} className={classes.generalDetails}>
              <Grid className={classes.cardLayout}>
                <img
                  src={chatterbox}
                  alt="badges_icon"
                  className={classes.badgesImage}
                ></img>
                <Typography align="left" className={classes.description}>
                  When you have 50+ posts in the Clubs section
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={6} className={classes.generalDetails}>
              <Grid className={classes.cardLayout}>
                <img
                  src={cheer_boosters}
                  alt="badges_icon"
                  className={classes.badgesImage}
                ></img>
                <Typography align="left" className={classes.description}>
                  When you have 500+ likes on the various posts
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={6} className={classes.generalDetails}>
              <Grid className={classes.cardLayout}>
                <img
                  src={foodie}
                  alt="badges_icon"
                  className={classes.badgesImage}
                ></img>
                <Typography align="left" className={classes.description}>
                  When you order 20 unique items from cafe
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={6} className={classes.generalDetails}>
              <Grid className={classes.cardLayout}>
                <img
                  src={mr_ms_dependable}
                  alt="badges_icon"
                  className={classes.badgesImage}
                ></img>
                <Typography align="left" className={classes.description}>
                  When you Spent more than 500 Hours in meeting
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={6} className={classes.generalDetails}>
              <Grid className={classes.cardLayout}>
                <img
                  src={people_charmer}
                  alt="badges_icon"
                  className={classes.badgesImage}
                ></img>
                <Typography align="left" className={classes.description}>
                  When you have Invited more than 50 Visitors
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={6} className={classes.generalDetails}>
              <Grid className={classes.cardLayout}>
                <img
                  src={question_mark}
                  alt="badges_icon"
                  className={classes.badgesImage}
                ></img>
                <Typography align="left" className={classes.description}>
                  When you have attended more than 10 events
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={6} className={classes.generalDetails}>
              <Grid className={classes.cardLayout}>
                <img
                  src={search_engine}
                  alt="badges_icon"
                  className={classes.badgesImage}
                ></img>
                <Typography align="left" className={classes.description}>
                  When you have attended More than 25 events
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={6} className={classes.generalDetails}>
              <Grid className={classes.cardLayout}>
                <img
                  src={budding_star}
                  alt="badges_icon"
                  className={classes.badgesImage}
                ></img>
                <Typography align="left" className={classes.description}>
                  When you won More than 3 events
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={6} className={classes.generalDetails}>
              <Grid className={classes.cardLayout}>
                <img
                  src={quick_learner}
                  alt="badges_icon"
                  className={classes.badgesImage}
                ></img>
                <Typography align="left" className={classes.description}>
                  When you won more than 5 events
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={6} className={classes.generalDetails}>
              <Grid className={classes.cardLayout}>
                <img
                  src={champion}
                  alt="badges_icon"
                  className={classes.badgesImage}
                ></img>
                <Typography align="left" className={classes.description}>
                  When you won more than 10 events
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={6} className={classes.generalDetails}>
              <Grid className={classes.cardLayout}>
                <img
                  src={community_heroes}
                  alt="badges_icon"
                  className={classes.badgesImage}
                ></img>
                <Typography align="left" className={classes.description}>
                  A badge for the event ambassador (Space Ambassador)
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.spaces.loading,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(Badges));
