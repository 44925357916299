import React, { useState } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import classNames from "classnames";
import FormControl from "@material-ui/core/FormControl";
import { connect } from "react-redux";
import { updateMerchandiseOrderStatus } from "../../Actions/merchandiseActions";
import { capitalize } from "../../Utils/StringFunctions";
import MerchandiseStatusType from "./Types/MerchandiseOrderStatus";

const styles = (theme) => {
  return {
    delivered: {
      backgroundColor: "#fff",
      color: "#4CAF50",
      borderColor: "#4CAF50",
    },
    pending: {
      backgroundColor: "#FFF",
      color: "#FFC312",
      borderColor: "#FFC312",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 50,
    },
    statusDropdown: {
      border: `1px solid`,
      borderRadius: `${theme.shape.borderRadius * 4}px`,
      height: "25px",
      padding: "12px 11px 13px 13px",
      fontSize: "10px",
      fontWeight: "500",
    },
    icon: {
      color: "#000",
      right: "10px",
      top: "1px",
    },
    deliveredStatus: {
      backgroundColor: "#fff",
      color: "#4CAF50 !important",
      borderColor: "#4CAF50 !important",
      border: `1px solid #4CAF50`,
      borderRadius: "50px",
      height: "25px",
      padding: "4px 11px 13px 13px",
      fontSize: "10px",
      fontWeight: "500",
    },
  };
};
const MerchandiseOrderStatus = (props) => {
  const [selected, setSelected] = useState(props.selectedItem);
  const { classes, id } = props;
  const callStatusChangeAPI = (el) => {
    // eslint-disable-next-line no-shadow
    props.updateMerchandiseOrderStatus(id, {
      status: el.target.value,
    });
    setSelected(el.target.value);
  };
  return (
    <React.Fragment>
      <FormControl variant="filled" className={classes.formControl}>
        {selected === "delivered" ? (
          <React.Fragment>
            <div className={classes.deliveredStatus}>Delivered</div>
          </React.Fragment>
        ) : (
          <Select
            className={classNames(classes[selected], classes.statusDropdown)}
            value={selected}
            variant="standard"
            onChange={callStatusChangeAPI}
            name="name"
            disableUnderline
            classes={{
              icon: classes.icon,
            }}
          >
            {MerchandiseStatusType.map((element) => {
              return (
                <MenuItem value={element} key={element}>
                  {capitalize(element)}
                </MenuItem>
              );
            })}
          </Select>
        )}
      </FormControl>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    errors: state.errors,
    selected: state.coworkers.selected,
  };
};
export default connect(mapStateToProps, { updateMerchandiseOrderStatus })(
  withStyles(styles)(MerchandiseOrderStatus)
);
