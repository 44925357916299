/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react'
import {
  Grid,
  CardActions,
  CardContent,
  withStyles,
  Paper,
  Avatar,
  Typography,
  Card,
  Button,
} from '@material-ui/core'
import Green from '@material-ui/core/colors/green'
import CreateIcon from '@material-ui/icons/Create'
import { Link } from 'react-router-dom'
import {
  Instagram,
  Linkedin,
  Twitter,
  FacebookRoundIcon,
  FacebookGrey,
  InstagramGrey,
  TwitterGrey,
  LinkedinGrey,
} from '../../../Assets/index'
import { checkPermission } from '../../../Utils'
import avatar from '../../../Assets/coworker3.png'
import CompanyStatus from '../CompanyStatus'
import SocialButton from '../SocialButton'
import NameTruncate from '../NameTruncate'
import { spacecapitalize } from '../../../Utils/StringFunctions'
import BadgesDetailModal from '../../../Screens/Co-Workers/BadgesDetailModal'

const moment = require('moment')

const styles = (theme) => {
  const borderRadius = theme.shape.borderRadius * 4

  return {
    cardGrid: {
      padding: `${theme.spacing(8)}px 0`,
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      border: '1px solid #EFEFEF',
      boxShadow: '0px 0px 20px rgba(0,0,0,0.05)',
      transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
      backgroundColor: '#FCFDFE',
      position: 'relative',
      '&:hover': {
        transform: 'scale(1.01)',
        boxShadow:
          'rgb(178 183 187 / 6%) 0px 2px 4px 6px, rgb(212 219 226 / 60%) 0px 2px 10px 0px',
      },
    },
    cardCoworker: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      border: '1px solid #EFEFEF',
      boxShadow: '0px 0px 20px rgba(0,0,0,0.05)',
      transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
      backgroundColor: '#FCFDFE',
      position: 'relative',
    },
    cardContent: {
      flexGrow: 1,
      textAlign: 'center',
      '& p': {
        color: theme.palette.font.head1,
      },
      '& h2': {
        color: '#82A3FE',
      },
      '& h4': {
        color: theme.palette.font.head2,
        fontWeight: 'normal',
      },
      '&:last-child': {
        paddingBottom: '10px',
      },

      paddingTop: '10px',
    },
    profileImage: {
      display: 'flex',
      flexDirection: 'row',
      height: 50,
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '36px',
    },
    Userimage: {
      minWidth: 60,
      height: 60,
    },
    avatar: {
      width: 60,
      height: 60,
      position: 'relative',
    },
    coworker3: {
      width: 61,
      height: 61,
      top: '-2px',
      position: 'relative',
    },
    status: {
      color: 'white',
      backgroundColor: Green[700],
      padding: '5px 8px',
      fontSize: 14,
    },
    image: {
      height: 'auto',
    },
    coworkerInfo: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      justify: 'center',
      alignItems: 'center',
      '& p': {
        fontSize: '12px',
      },
    },
    iconContainer: {
      marginTop: '1px',
      marginBottom: '3px',
    },
    cardFooter: {
      justifyContent: 'center',
      '& a': {
        textDecoration: 'none',
      },
      '& button': {
        margin: theme.spacing(1),
        fontSize: 10,
        border: '1px solid #959595',
        borderRadius: `${borderRadius}px`,
        backgroundColor: '#fff',
        color: '#959595',
        '&:hover': {
          backgroundColor: '#fff',
        },
      },
    },
    typo: {
      fontSize: '11px',
    },
    socialPaper: {
      minHeight: '45px',
    },
    localBoldFonts: {
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    edit_link: {
      textDecoration: 'none',
      color: 'grey',
      border: '1px solid #d1d1d1',
      borderRadius: '56px 38px 54px 100px',
      borderTop: 'none',
      borderRight: 'none',
      backgroundColor: '#d1d1d1',
      padding: '2px 4px 1px 5px',
      position: 'absolute',
      top: '-2px',
      right: '-4px',
    },
    skills: {
      paddingTop: '3px',
      color: '#ababab',
      fontSize: '12px',
    },
    coworkerName: {
      marginTop: 10,
      textTransform: 'capitalize',
      color: '#4D7CFE ',
      fontWeight: '600',
    },
    coworkerDetail: {
      color: '#ababab',
      fontSize: '12px',
      // paddingTop: "5px  ",
    },
    contentDataFonts: {
      fontSize: '12px',
    },
    badgesNumber: {
      background: '#FFC312',
      padding: '0px 6px',
      color: '#fff',
      borderRadius: '60px',
      position: 'relative',
      top: '-16px',
      right: '-42px',
      cursor: 'pointer',
    },
  }
}

function FreelancerCard(props) {
  const { classes, data, history } = props
  const [modalOpen, setmodalOpen] = useState(false)

  const handleClose = () => {
    setmodalOpen(false)
  }
  // const badgesModalOpen = () => {
  //   setmodalOpen(true);
  // };

  const handleCardClick = () => {
    const path = `/freelancer/${data._id}`
    if (history) history.push(path)
  }
  return (
    <Card
      className={
        checkPermission('COWORKER_ADMIN_VIEW')
          ? classes.card
          : classes.cardCoworker
      }
    >
      <Paper className={classes.containerPaper}>
        {data.status !== 'inactive' && checkPermission('COMPANY_UPDATE') && (
          <Link
            className={classes.edit_link}
            to={`/freelancer/edit/${data._id}`}
          >
            <CreateIcon fontSize="small" />
          </Link>
        )}
        <div className={classes.profileImage}>
          <div className={classes.Userimage}>
            {data.user && data.user.profile_pic ? (
              <Avatar
                className={classes.avatar}
                src={data.user && data.user.profile_pic}
              />
            ) : (
              <Avatar className={classes.coworker3} src={avatar} />
            )}
            {/* {data.user &&
            data.user.user_badge &&
            data.user.user_badge.length > 0 ? (
              <span
                onClick={() => badgesModalOpen()}
                className={classes.badgesNumber}
              >
                {data.user &&
                  data.user.user_badge &&
                  data.user.user_badge.length}
              </span>
            ) : (
              ""
            )} */}
          </div>
        </div>
        <div className={classes.image} direction="row">
          <Typography
            component="h2"
            align="center"
            className={classes.coworkerName}
          >
            {spacecapitalize(
              `${data.user && data.user.first_name}  ${
                data.user && data.user.last_name
              }`
            )}
          </Typography>
        </div>
        <div style={{ clear: 'both' }} />
        <Grid
          container
          alignItems="center"
          alignContent="center"
          direction="row"
          justify="center"
          onClick={handleCardClick}
        >
          <Grid item>
            <Typography
              component="p"
              variant="subtitle1"
              className={classes.typo}
            >
              {data.user && data.user.contact_number} |{' '}
              {data.user && data.user.email}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          className={classes.root}
          spacing={2}
          onClick={handleCardClick}
        >
          <Grid item xs={12}>
            <Grid
              container
              className={classes.iconContainer}
              justify="center"
              spacing={2}
            >
              <Grid item>
                <Paper className={classes.socialPaper}>
                  {data.linkedin ? (
                    <SocialButton type={Linkedin} url={data.linkedin} />
                  ) : (
                    <SocialButton type={LinkedinGrey} disable />
                  )}
                </Paper>
              </Grid>
              <Grid item>
                <Paper className={classes.socialPaper}>
                  {data.facebook ? (
                    <SocialButton
                      type={FacebookRoundIcon}
                      url={data.facebook}
                    />
                  ) : (
                    <SocialButton type={FacebookGrey} disable />
                  )}
                </Paper>
              </Grid>
              <Grid item>
                <Paper className={classes.socialPaper}>
                  {data.twitter ? (
                    <SocialButton type={Twitter} url={data.twitter} />
                  ) : (
                    <SocialButton type={TwitterGrey} disable />
                  )}
                </Paper>
              </Grid>
              <Grid item>
                <Paper className={classes.socialPaper}>
                  {data.instagram ? (
                    <SocialButton type={Instagram} url={data.instagram} />
                  ) : (
                    <SocialButton type={InstagramGrey} disable />
                  )}
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      {checkPermission('COMPANY_CRITICAL_DETAILS') && (
        <Paper className={classes.containerPaper}>
          <CardContent className={classes.cardContent}>
            <Grid
              container
              className={classes.coworkerInfo}
              spacing={2}
              alignItems="center"
              alignContent="space-between"
              direction="row"
              justify="center"
              onClick={handleCardClick}
            >
              <Grid
                item
                xs={4}
                spacing={3}
                className={classes.eventParticipant}
              >
                {data.client_type ? (
                  <Typography variant="subtitle2" component="p">
                    {/* {spacecapitalize(data.client_type)} */}
                    <NameTruncate
                      text={spacecapitalize(data.client_type)}
                      amount={9}
                    />
                  </Typography>
                ) : (
                  <Typography variant="h3" component="p">
                    N.A.
                  </Typography>
                )}
                <Typography variant="subtitle2" className={classes.skills}>
                  Type of Client
                </Typography>
              </Grid>

              <Grid item xs={4} spacing={3} className={classes.eventDate}>
                {data.available_credits ? (
                  <Typography
                    variant="subtitle2"
                    component="p"
                    className={classes.localBoldFonts}
                  >
                    {parseFloat(data.available_credits).toFixed(2)}
                  </Typography>
                ) : (
                  <Typography variant="h3" component="p">
                    N.A
                  </Typography>
                )}
                <Typography
                  variant="subtitle2"
                  className={classes.coworkerDetail}
                >
                  Monthly Credits
                </Typography>
              </Grid>

              <Grid item xs={4} spacing={3} className={classes.eventDate}>
                {data.contract_end_date ? (
                  <Typography
                    variant="subtitle2"
                    component="p"
                    className={classes.localBoldFonts}
                  >
                    {moment(data.contract_end_date).format('DD/MM/YYYY')}
                  </Typography>
                ) : (
                  <Typography variant="h3" component="p">
                    N.A.
                  </Typography>
                )}
                <Typography
                  variant="subtitle2"
                  className={classes.coworkerDetail}
                >
                  Contract Expiry
                </Typography>
              </Grid>
              {/* </div> */}
            </Grid>
          </CardContent>
        </Paper>
      )}

      <CardActions className={classes.cardFooter}>
        <Link to={`/freelancer/${data._id}`}>
          <Button
            size="small"
            variant="contained"
            color="primary"
            className={classes.button}
          >
            View More
          </Button>
        </Link>
        {(checkPermission('COMPANY_STATU_VIEW') ||
          localStorage.getItem('role') === 'communitymanager') && (
          <CompanyStatus
            selectedItem={data.status}
            id={data._id}
            key={data._id}
            changeStatus={props.changeStatus}
          />
        )}
      </CardActions>
      <BadgesDetailModal
        close={handleClose}
        data={modalOpen}
        type="freelancer"
        coworkerData={data}
      />
    </Card>
  )
}
export default withStyles(styles)(FreelancerCard)
