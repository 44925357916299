const Theme = {
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    useNextVariants: true,
    h5: {
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shadows: Array(25).fill('none'),
  palette: {
    primary: {
      light: '#fff',
      main: '#4682B4',
      dark: '#232f3e',
    },
    secondary: {
      main: '#dd8f00',
    },
    applied: {
      main: '#55A3F1',
    },
    error: {
      main: '#f53b57',
    },
    table: {
      header: {
        main: '#D2FFFF',
      },
    },
    applications: {
      applied: '#55A3F1',
      shortlisted: '#26B52B',
      rejected: '#FF7474',
      backout: '#7148FE',
      attended: '#FEC90E',
      winners: '#4DCEDB',
      active: '#4CAF50',
      inactive: '#F44336',
      trial: '#FFC312',
    },
    border: {
      color: '#F8FAFB',
    },
    font: {
      head1: '#747474',
      head2: '#9B9B9B',
    },
    background: {
      light: '#F5FAFD',
    },
  },
  shape: {
    borderRadius: 4,
  },
  overrides: {
    MuiButton: { root: { color: 'yellow' } },
  },
}

export default Theme
