import {
  GET_COWORKER_ROLETYPE,
  COWORKER_ROLETYPE_ERRORS,
  ROLETYPE_LOADING,
  GET_ALL_ROLES,
} from "../Actions/types";

const initialState = {
  roletype: null,
  loading: false,
  roletypes: [],
  roles: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_COWORKER_ROLETYPE:
      return {
        ...state,
        loading: false,
        roletypes: action.payload.data,
      };
    case GET_ALL_ROLES:
      return {
        ...state,
        loading: false,
        roles: action.payload.data,
      };
    case COWORKER_ROLETYPE_ERRORS:
      return {
        ...state,
        roletype_error: { errors: false },
      };
    case ROLETYPE_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
