/* eslint-disable no-param-reassign */
import React from "react";
import {
  Grid,
  withStyles,
  Button,
  Checkbox,
  Typography,
  FormControlLabel,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import InputField from "../../Components/Common/Form/InputField";
import { CircularLoader } from "../../Components/Common/Spinner";
import {
  getPolicies,
  addRole,
  getRoles,
  getRole,
  updateRole,
} from "../../Actions/rolesActions";
import { capitalize, spacecapitalize } from "../../Utils/StringFunctions";
import PolicyInfo from "../../Components/Common/PolicyInfo";
import ScreenHeader from "../../Components/Common/ScreenHeader";

const styles = () => ({
  lightbg: {
    // padding: “24px”,
    backgroundColor: "#fff",
    padding: "10px 22px",
  },
  label: {
    margin: "15px 0px 8px 0px",
    fontSize: "12px",
    fontWeight: "400",
    color: "rgba(0, 0, 0, 0.54)",
  },
  button: {
    width: "148px",
    height: "46px",
    backgroundColor: "#dddddd",
    color: "#151039",
    "&:hover": {
      color: "#151039",
      backgroundColor: "#d1d1d1!important",
    },
  },
  ScreenHeader: {
    marginTop: "5px",
    marginLeft: "-25px",
    width: "100%",
  },
  Addbutton: {
    width: "148px",
    height: "46px",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
    "&:disabled": {
      backgroundColor: "lightgrey !important",
    },
  },
  formfooter: {
    margin: "24px 15px",
    marginRight: "-2%",
  },
  form: {
    marginLeft: "4px",
  },
  policyDiv: {
    display: "flex",
  },
  title: {
    fontSize: "16px",
    fontWeight: "normal",
    textAlign: "left",
    color: "#626262",
    lineHeight: "17px",
    margin: "2% 0%",
  },
  subtitle: {
    fontSize: "15px",
    // color: "grey",
    fontWeight: "normal",
    textAlign: "left",
    color: "#626262",
    lineHeight: "17px",
    padding: "1% 0",
  },
  policyTitle: {
    fontSize: "14px",
  },
  infoIcon: {
    position: "relative",
    top: "4px",
    left: "-13px",
  },
});

class AddRole extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItems: [],
      id:
        this.props.match && this.props.match.params
          ? this.props.match.params.id
          : "",
    };
  }

  componentDidMount() {
    this.props.getPolicies();
    if (this.state.id) {
      this.props.getRole(this.state.id);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading !== this.props.loading) {
      this.props.setLoading(this.props.loading);
      this.setState({
        loading: this.props.loading,
      });
    }
    if (prevProps.role !== this.props.role) {
      const selectedItemsIds = [];

      // eslint-disable-next-line no-unused-expressions
      (this.props.role &&
        this.props.role.policy &&
        this.props.role.policy.map((policy) => {
          selectedItemsIds.push(policy._id);
          return null;
        })) ||
        [];
      this.setState({
        role: this.props.role,
        selectedItems: selectedItemsIds || [],
      });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.role !== prevState.role) {
      return { role: nextProps.role };
    }
    return [];
  }

  handleChange = () => (event) => {
    const { selectedItems } = this.state;
    if (event.target.checked) {
      if (selectedItems.indexOf(event.target.value) === -1) {
        selectedItems.push(event.target.value);
      }
    } else {
      const index = selectedItems.indexOf(event.target.value);
      if (index !== -1) {
        selectedItems.splice(index, 1);
      }
    }
    this.setState({ selectedItems });
  };

  geIntialValues = (id) => {
    const { role } = this.props;
    if (id) {
      return {
        name: role.name,
        policy: role.policy,
      };
    }
    return {
      name: "",
      policy: [],
    };
  };

  render() {
    const { classes, policies } = this.props;
    const { loading, selectedItems, id } = this.state;
    return (
      <React.Fragment>
        <div className={classes.lightbg}>
          <div className={classes.ScreenHeader}>
            <ScreenHeader type={id ? "Update Role" : "Add Role"} />
          </div>
          <Formik
            enableReinitialize
            initialValues={this.geIntialValues(id)}
            onSubmit={(values, { setErrors, setSubmitting }) => {
              values.policy = selectedItems;

              if (id) {
                this.props.updateRole(
                  id,
                  values,
                  setErrors,
                  this.props.history,
                  setSubmitting
                );
              } else {
                this.props.addRole(
                  values,
                  setErrors,
                  this.props.history,
                  setSubmitting
                );
              }
            }}
          >
            {() => (
              <Form className={classes.form} autoComplete="off">
                <Grid item xs={6} sm={6}>
                  <InputField
                    name="name"
                    label="Role Name"
                    placeholder="Enter Role Name"
                  />
                </Grid>
                <Grid
                  container
                  item
                  // display="flex"
                  // justify="flex-end"
                  // alignItems="center"
                  // direction="row"
                >
                  <Grid item xs={12} sm={12}>
                    <Typography variant="h6" className={classes.title}>
                      Policies
                    </Typography>
                    {loading && <CircularLoader />}
                  </Grid>
                  {policies &&
                    Object.keys(policies).map((key) => {
                      return (
                        <Grid container item display="flex" direction="row">
                          <Grid item xs={12} sm={12}>
                            <Typography
                              variant="h6"
                              className={classes.subtitle}
                            >
                              {capitalize(key)}
                            </Typography>
                          </Grid>
                          <Grid container display="flex" direction="row">
                            {policies[key] &&
                              policies[key].map((policy) => {
                                return (
                                  <Grid item xs={4} sm={4}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={
                                            selectedItems.includes(policy._id)
                                            // ? selectedItems[policy._id]
                                            // : selectedItems[policy._id]
                                          }
                                          // checked={selectedItems[policy._id]}
                                          onChange={this.handleChange(policy)}
                                          value={policy._id}
                                          name="policy"
                                          color="primary"
                                          className={classes.policyTitle}
                                        />
                                      }
                                      label={spacecapitalize(
                                        policy.policy_name
                                      )}
                                    />
                                    <PolicyInfo
                                      text={policy && policy.description}
                                    />
                                  </Grid>
                                );
                              })}
                          </Grid>
                        </Grid>
                      );
                    })}
                </Grid>

                <div className={classes.formfooter}>
                  <Grid
                    container
                    item
                    display="flex"
                    justify="flex-end"
                    alignItems="center"
                    direction="row"
                  >
                    <Grid item xs={6} sm={2}>
                      <Button
                        color="primary"
                        onClick={() => this.props.history.push("/roles")}
                        className={classes.button}
                        style={{ right: "-5px" }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item xs={6} sm={2}>
                      <Button
                        className={classes.Addbutton}
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={
                          id
                            ? selectedItems.length === 2
                            : selectedItems.length === 0
                        }
                      >
                        {id ? "Update" : "Add"}
                      </Button>
                    </Grid>
                  </Grid>
                  {loading && <CircularLoader />}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errors: state.errors,
    policies: state.roles.policies,
    loading: state.roles.loading,
    role: state.roles.role,
  };
};

export default connect(mapStateToProps, {
  getPolicies,
  addRole,
  getRoles,
  getRole,
  updateRole,
})(withStyles(styles)(AddRole));
