import React from "react";
import { LinearProgress } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import spinner from "./spinner.gif";

function Spinner() {
  return (
    <div>
      <img
        src={spinner}
        alt="Loading..."
        style={{
          width: "50px",
          margin: "auto",
          display: "block",
          marginTop: "20%"
        }}
      />
    </div>
  );
}

const Loader = props => (
  <div
    style={{
      textAlign: "center"
    }}
    className={props.className}
  >
    <LinearProgress color="secondary" />
  </div>
);

const CircularLoader = () => (
  <div style={{ display: "flex", justifyContent: "center", marginTop: "20%" }}>
    <CircularProgress style={{ color: "rgba(0, 0, 0, 0.87)" }} />
  </div>
);

export { Loader, Spinner, CircularLoader };
