import {
  GET_ALL_FOOD_ITEM,
  FOOD_ITEM_LOADING,
  ADD_FOOD_ITEM,
  FOOD_ITEM_ERRORS,
} from "../Actions/types";

const initialState = {
  food_items: null,
  loading: false,
  food__item: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_FOOD_ITEM:
      return {
        ...state,
        loading: false,
        food_items: action.payload.data,
      };
    case FOOD_ITEM_ERRORS:
      return {
        ...state,
        loading: false,
        error: action.payload && action.payload.data,
      };
    case ADD_FOOD_ITEM:
      return {
        ...state,
        loading: false,
        food__item: {
          success: true,
          data: action.payload && action.payload.data,
        },
      };
    case FOOD_ITEM_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
