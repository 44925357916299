/* eslint-disable no-param-reassign */
import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import * as Yup from 'yup'
import {
  Button,
  Dialog,
  Typography,
  Grid,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import InputField from '../../Components/Common/Form/InputField'
import { addvisitorbywalkthrough } from '../../Actions/securityVisitorActions'
import thankyou from '../../Assets/thankyou.png'
import { capitalize, spacecapitalize } from '../../Utils/StringFunctions'

const $quoteSym = ' '

const styles = (theme) => ({
  root: {
    padding: '0px',
  },
  label: {
    fontSize: '12px',
    fontWeight: '700',
  },
  formfooter: {
    margin: '24px 15px',
  },
  subtitle: {
    fontSize: '18px',
    marginBottom: '20px',
  },
  coworker_visitor: {
    padding: '14px',
  },
  content_center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  screenheader: {
    fontSize: '16px',
    fontWeight: '600',
    position: 'relative',
    '&:after': {
      top: '24px',
      left: '0px',
      width: '24px',
      borderBottom: '3px solid #fb0',
      content: `'${$quoteSym}'`,
      position: 'absolute',
    },
  },
  form_modal: {
    width: '500px',
  },
  thankyou_modal: {
    width: 500,
    height: 430,
    padding: '15px 0px',
    overflow: 'hidden',
  },
  thankyou: {
    fontFamily: 'Dancing Script',
    fontWeight: 'bold',
    fontSize: '67px',
    textAlign: 'center',
    color: '#512da8',
    margin: '0px',
    marginTop: '-18px',
    paddingBottom: '6px',
  },
  innercont: {
    margin: '10px 0px 0px',
  },
  layout: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cancelbutton: {
    color: '#151039',
    backgroundColor: '#dddddd',
    marginRight: '10px',
    '&:hover': {
      color: '#151039',
      backgroundColor: '#d1d1d1!important',
    },
  },
  bookbutton: {
    marginRight: '15px',
    backgroundColor: '#211b4e !important',
    '&:hover': {
      backgroundColor: '#151039 !important',
    },
  },
  applicationHeader: {
    padding: '0px !important',
  },
  inputBox: {
    padding: '5px 12px !important',
  },
  modalFooter: {
    marginTop: '10px',
  },
  thankyouSvg: {
    width: '334px',
    marginBottom: '10px',
  },
})
const phoneRegExp = /[1-9]{1}[0-9]{9}/

const WalkthroughSchema = Yup.object().shape({
  visitor_name: Yup.string()
    .max(15, '')
    .matches(/^.[a-zA-Z0-9 ]+$/, 'Enter Valid Name')
    .trim()
    .required('This field is required.'),
  email: Yup.string()
    .required('This field is required.')
    .email('Enter valid email id'),
  contact: Yup.string()
    .matches(phoneRegExp, 'Please enter valid number.')
    .required('This field is required.'),
  no_of_visitors: Yup.number()
    .typeError('Please input a number.')
    .required('This field is required.'),
  visitor_company_name: Yup.string().trim().required('This field is required.'),
})

const eventSchema = Yup.object().shape({
  first_name: Yup.string()
    .max(15, '')
    .matches(/^.[a-zA-Z0-9 ]+$/, 'Enter Valid Name')
    .required('This field is required.'),
  last_name: Yup.string()
    .max(15, '')
    .matches(/^.[a-zA-Z0-9 ]+$/, 'Enter Valid Name')
    .required('This field is required.'),
  email: Yup.string()
    .required('This field is required.')
    .email('Enter valid email id'),
  contact: Yup.number()
    .typeError('Please input a number.')
    .required('This field is required.'),
})

class Walkthrough extends React.Component {
  unsubscribe = null

  constructor(props) {
    super(props)
    this.state = {
      securityVisitor: {},
    }
  }

  // eslint-disable-next-line consistent-return
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.securityVisitor !== prevState.securityVisitor) {
      return {
        securityVisitor: nextProps.securityVisitor,
      }
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  getInitialValues = (id) => {
    const { visitor } = this.props
    if (id) {
      return {
        visitor_name: visitor.name,
        visitor_company_name: visitor.visitor_company_name,
        contact: visitor.contact,
        email: visitor.email,
        no_of_visitors: visitor.no_of_visitors,
      }
    }
    return this.props.visitordata && this.props.visitordata.visitor
      ? {
          visitor_name: spacecapitalize(
            this.props.visitordata.visitor.visitor_name
          ),
          visitor_company_name: this.props.visitordata.visitor
            .visitor_company_name,
          contact: this.props.visitordata.visitor.contact
            ? this.props.visitordata.visitor.contact
            : this.props.contact,
          email: this.props.visitordata.visitor.email,
          // this.props.visitordata.visitor.visitor_name.indexOf(' ') >= 0

          first_name:
            this.props.visitordata.visitor.visitor_name.indexOf(' ') >= 0
              ? capitalize(
                  this.props.visitordata.visitor.visitor_name
                    .split(' ')
                    .slice(0, -1)
                    .join(' ')
                )
              : capitalize(this.props.visitordata.visitor.visitor_name),
          last_name:
            this.props.visitordata.visitor.visitor_name.indexOf(' ') >= 0
              ? capitalize(
                  this.props.visitordata.visitor.visitor_name
                    .split(' ')
                    .slice(-1)
                    .join(' ')
                )
              : '',
        }
      : {
          visitor_name: '',
          visitor_company_name: '',
          contact: this.props.contact,
          email: '',
          no_of_visitors: '',
        }
  }

  renderSuccess() {
    const { classes } = this.props
    return (
      <DialogContent className={classes.thankyou_modal}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} className={classes.layout}>
            <img alt="logo" src={thankyou} className={classes.thankyouSvg} />
          </Grid>
          {/* <ThankyouSvg /> */}
          <Grid item xs={12} sm={12} md={12} className={classes.layout}>
            <Typography
              align="center"
              variant="h1"
              className={classes.thankyou}
            >
              Thank you !
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} className={classes.layout}>
            <div className={classes.innercont}>
              <Typography align="center" variant="p">
                Hope you have an amazing day!!
              </Typography>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    )
  }

  render() {
    const { classes, purpose } = this.props
    const { id, securityVisitor } = this.state

    return (
      <Dialog
        open={this.props.data}
        onClose={
          purpose === 'for_attend_event'
            ? this.props.handleCloseButton
            : this.props.handleClose
        }
        aria-labelledby="plan-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.root}
      >
        <DialogTitle
          className={classes.applicationHeader}
          id="plan-dialog-title"
        />
        {securityVisitor.securityVisitorWalkthrough.success && (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={this.props.handleCloseButton}
          >
            <CloseIcon />
          </IconButton>
        )}
        {securityVisitor.securityVisitorWalkthrough.success ? (
          this.renderSuccess()
        ) : (
          <Formik
            initialValues={this.getInitialValues(id)}
            enableReinitialize
            validationSchema={
              this.props.purpose === 'walkthrough'
                ? WalkthroughSchema
                : eventSchema
            }
            onSubmit={(values, { setErrors, setSubmitting, resetForm }) => {
              values.purpose = this.props.purpose
              values.contact_number = values.contact
              values.event_id = this.props.eventid
              values.company_name = values.visitor_company_name
              values.type_of_invitation =
                this.props.purpose === 'walkthrough' ? 'walkthrough' : 'event'
              // console.log("valuesssss",values)
              this.props.addvisitorbywalkthrough(
                values,
                setErrors,
                setSubmitting,
                this.props.history
              )
              resetForm(this.initialValues)
            }}
          >
            {({
              // eslint-disable-next-line no-unused-vars
              values,
              handleSubmit,
            }) => (
              <form
                className={(classes.form, classes.form_modal)}
                onSubmit={handleSubmit}
                encType="multipart/form-data"
                autoComplete="off"
              >
                <DialogContent style={{ overflow: 'hidden' }}>
                  <Typography variant="h6" className={classes.subtitle}>
                    <span className={classes.screenheader} align="left">
                      My Details
                    </span>
                  </Typography>
                  <Grid container spacing={3}>
                    {this.props.purpose === 'walkthrough' && (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        className={classes.inputBox}
                        style={{ border: 'none' }}
                      >
                        <InputField
                          name="visitor_name"
                          label="Name"
                          placeholder="Enter Name"
                        />
                      </Grid>
                    )}
                    {this.props.purpose === 'for_attend_event' && (
                      <Grid item xs={6} sm={6}>
                        <InputField
                          name="first_name"
                          label="Full Name"
                          placeholder="Enter First Name"
                          max={15}
                        />
                      </Grid>
                    )}
                    {this.props.purpose === 'for_attend_event' && (
                      <Grid
                        item
                        xs={6}
                        sm={6}
                        style={{
                          marginTop: '16px',
                        }}
                      >
                        <InputField
                          name="last_name"
                          label=""
                          placeholder="Enter Last Name"
                          max={15}
                        />
                      </Grid>
                    )}
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      className={classes.inputBox}
                      style={{ border: 'none' }}
                    >
                      <InputField
                        name="visitor_company_name"
                        label="Your Company Name"
                        placeholder="Enter Company Name"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      className={classes.inputBox}
                      style={{ border: 'none' }}
                    >
                      <InputField
                        name="contact"
                        label="Contact No"
                        placeholder="Enter Contact No"
                        type="tel"
                        max="10"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      className={classes.inputBox}
                      style={{ border: 'none' }}
                    >
                      <InputField
                        name="email"
                        label="Email Id"
                        placeholder="Enter Email Id"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      className={classes.inputBox}
                      style={{ border: 'none' }}
                    >
                      <InputField
                        name="no_of_visitors"
                        label="No. of Visitors"
                        placeholder="Enter no. of visitors"
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions className={classes.modalFooter}>
                  <Button
                    onClick={this.props.handleClose}
                    color="primary"
                    className={classes.cancelbutton}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    type="submit"
                    variant="contained"
                    className={classes.bookbutton}
                  >
                    Add
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        )}
      </Dialog>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    securityVisitor: state.securityVisitor,
    errors: state.errors,
  }
}

export default connect(mapStateToProps, { addvisitorbywalkthrough })(
  withStyles(styles)(Walkthrough)
)
