import {
  SET_CURRENT_USER,
  LOGIN_ERR0RS,
  FORGET_PASSWORD_ERROR,
  FORGET_PASSWORD_SUCCESS,
  CHECK_TOKEN_ERROR,
  CHECK_TOKEN_SUCCESS,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  USER_SIGNUP_LOADING,
  GET_USER_SIGNUP_LIST,
  USER_SIGNUP_ERROR,
  SIGNUP_CINFIRMATION
} from "../Actions/types";
import isEmpty from "../Utils/isEmpty";

const initialState = {
  isAuthenticated: false,
  user: {},
  hasRole: "",
  checkToken: {},
  loading: false,
  errors: {},
  signup_confirmation: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER: {
      const { policy, name: roleType } =
        action.payload.policy && action.payload.policy[0]
          ? action.payload.policy[0].roletype_id
          : "";
      localStorage.setItem("role", roleType);
      localStorage.setItem("policies", JSON.stringify(policy));

      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: { sucess: true, ...action.payload },
        hasRole: action.payload.role
      };
    }
    case LOGIN_ERR0RS:
      return {
        ...state,
        user: {
          sucess: false,
          errors: action.payload
        }
      };
    case FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        user: { success: true, data: action.payload }
      };
    case FORGET_PASSWORD_ERROR:
      return {
        ...state,
        user: {
          success: false,
          errors: action.payload
        }
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        user: { success: true, data: action.payload }
      };
    case CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        user: {
          success: false,
          errors: action.payload
        }
      };
    case CHECK_TOKEN_SUCCESS:
      return {
        ...state,
        checkToken: { success: true, data: action.payload, loading: false }
      };
    case CHECK_TOKEN_ERROR:
      return {
        ...state,
        checkToken: {
          success: false,
          errors: action.payload.data ? action.payload.data.fields : {},
          loading: false
        }
      };
    case USER_SIGNUP_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_USER_SIGNUP_LIST:
      return {
        ...state,
        loading: false,
        user_Signup_list: action.payload && action.payload.data
      };
    case SIGNUP_CINFIRMATION:
      return {
        ...state,
        loading: false,

        signup_confirmation: {
          success: true,
          data: action.payload && action.payload.data
        }
      };
    case USER_SIGNUP_ERROR:
      return {
        ...state,
        error: { success: false, errors: action.payload }
      };
    default:
      return state;
  }
}
