/* eslint-disable func-names */
/* eslint-disable no-else-return */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-param-reassign */
import React from 'react'
import { FormLabel, Grid } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Form } from 'react-formik-ui'
import { ExportToCsv } from 'export-to-csv'
import {
  addBookingAnalysis,
  getMultipleSpaceCompany,
  companyResourceBookingLog,
  companyResourceBookingAnalysis,
  visitorAnalysisData,
  clientsVisitorAnalysisData,
  companyPlanAnalysisData,
  monthlyCafeConsumptionAnalysis,
  walkthroughLogData,
  walkthroughAnalysisData,
  coworkerLogsData,
  visitorLogsData,
  issueTicketAnalysis,
} from '../../Actions/ReportAnalyticsActions'
import SelectField from '../../Components/Common/Form/SelectField'
import {
  reportCategoryOptions,
  reportOptions,
  visitorOptions,
  walkthroughOptions,
  companyOptions,
  coworkerOptions,
  cafeteriaOptions,
  helpdeskOptions,
  reportTypeData,
} from '../../Utils/dropDownData'
import { getSpaces } from '../../Actions/spaceActions'
import { getSpaceResource } from '../../Actions/resourceActions'
import { getUsers } from '../../Actions/userActions'
import { capitalize, spacecapitalize } from '../../Utils/StringFunctions'
import { DatePickerField } from '../../Components/Common/Form'

const moment = require('moment')

const styles = () => ({
  lightbg: {
    padding: '20px 30px 35px',
    backgroundColor: '#fff',
  },
  label: {
    margin: '15px 0px 8px 0px',
    fontSize: '13px',
    fontWeight: '400',
  },
  securitybutton: {
    width: '148px',
  },
  button: {
    width: '148px',
    height: '46px',
    backgroundColor: '#dddddd',
    color: '#151039',
    '&:hover': {
      color: '#151039',
      backgroundColor: '#d1d1d1!important',
    },
  },
  Addbutton: {
    width: '148px',
    height: '46px',
    backgroundColor: '#211b4e !important',
    '&:hover': {
      backgroundColor: '#151039 !important',
    },
  },
  remove_icon: {
    position: 'relative',
  },
  formfooter: {
    paddingTop: '25px',
    marginRight: '-2%',
  },
  form: {
    marginLeft: '-4px',
  },
  dateLabel: {
    fontSize: '12px',
    fontWeight: 'normal',
  },
})

const reportSchema = Yup.object().shape({
  reportcategory: Yup.string().required('This field is required.'),
  spaceids: Yup.string().required('This field is required.'),
  reportname: Yup.string().required('This field is required.'),
  resourceid: Yup.string().test(
    'match',
    'This field is  required',
    function (value) {
      const { reporttypefinal } = this.parent
      if (
        (reporttypefinal === 'bookinganalytics' ||
          reporttypefinal === 'bookinglog') &&
        !value
      ) {
        return false
      }
      return true
    }
  ),
  client_type: Yup.string().test(
    'match',
    'This field is  required',
    function (value) {
      const { reporttypefinal } = this.parent
      if (
        (reporttypefinal === 'bookinglog' ||
          reporttypefinal === 'clientvisitor') &&
        !value
      ) {
        return false
      }
      return true
    }
  ),
  clientids: Yup.string().test(
    'match',
    'This field is  required',
    function (value) {
      const { reporttypefinal } = this.parent
      if (
        (reporttypefinal === 'bookinglog' ||
          reporttypefinal === 'clientvisitor') &&
        !value
      ) {
        return false
      }
      return true
    }
  ),
  start_date: Yup.string().test(
    'match',
    'This field is required',
    function (value) {
      const { reporttypefinal } = this.parent

      if (
        moment().diff(moment(value), 'days') === 0 &&
        !(
          reporttypefinal === 'coworkerlog' || reporttypefinal === 'companyplan'
        )
      ) {
        return false
      }
      return true
    }
  ),
  // end_date: Yup.string().test(
  //   'match',
  //   'End date is greater than start date.',
  //   function (value) {
  //     const { start_date, reporttypefinal } = this.parent
  //     if (
  //       moment().diff(moment(value), 'days') >=
  //         moment().diff(moment(start_date), 'days') &&
  //       !(
  //         reporttypefinal === 'coworkerlog' || reporttypefinal === 'companyplan'
  //       )
  //     ) {
  //       return false
  //     }
  //     return true
  //   }
  // ),
  coworker_status: Yup.string().test(
    'match',
    'This field is required',
    function (value) {
      const { reporttypefinal } = this.parent
      if (reporttypefinal === 'coworkerlog' && !value) {
        return false
      }
      return true
    }
  ),
})

class ReportList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      allspaces: [],
      allResources: [],
      dropDown1: '',
      spaceData: [],
      clientType: 'all',
      reportType: '',
      userType: '',
      allSystemUser: [],
      finalReportData: [],
      spaceList: [],
      coworker_status: '',
    }
    this.handleClose = this.handleClose.bind(this)
  }

  componentDidMount() {
    this.props.getSpaces()
    this.props.getUsers('', 'sysuser')
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.spaces !== prevState.spaces) {
      const allspaces =
        (nextProps.spaces &&
          nextProps.spaces.map((element) => {
            return {
              label: capitalize(element.space_title),
              value: element._id,
            }
          })) ||
        []
      return { spaces: nextProps.spaces, allspaces }
    }
    if (nextProps.resources !== prevState.resources) {
      const allResources =
        (nextProps.resources &&
          nextProps.resources.map((element) => {
            return {
              label: capitalize(element.resource_name),
              value: element._id,
            }
          })) ||
        []
      return { resources: nextProps.resources, allResources }
    }
    if (nextProps.companies !== prevState.companies) {
      return { companies: nextProps.companies }
    }
    if (nextProps.systemUsers.data !== prevState.systemUsers) {
      return {
        systemUsers: nextProps.systemUsers.data,
      }
    }
    return []
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.reportAnalysis !== prevProps.reportAnalysis &&
      this.props.reportAnalysis.success
    ) {
      this.exportSuccessData(this.props.reportAnalysis.data.data)
    }

    if (
      this.props.companyBookingLog !== prevProps.companyBookingLog &&
      this.props.companyBookingLog.success
    ) {
      this.exportSuccessData(this.props.companyBookingLog.data.data)
    }
    if (
      this.props.companyReosurceBookingAnalysis !==
        prevProps.companyReosurceBookingAnalysis &&
      this.props.companyReosurceBookingAnalysis.success
    ) {
      this.exportSuccessData(
        this.props.companyReosurceBookingAnalysis.data.data
      )
    }
    if (
      this.props.visitorAnalysis !== prevProps.visitorAnalysis &&
      this.props.visitorAnalysis.success
    ) {
      this.exportSuccessData(this.props.visitorAnalysis.data.data)
    }

    if (
      this.props.clientsVisitorAnalysis !== prevProps.clientsVisitorAnalysis &&
      this.props.clientsVisitorAnalysis.success
    ) {
      this.exportSuccessData(this.props.clientsVisitorAnalysis.data.data)
    }

    if (
      this.props.companyPlanAnalysis !== prevProps.companyPlanAnalysis &&
      this.props.companyPlanAnalysis.success
    ) {
      this.exportSuccessData(this.props.companyPlanAnalysis.data.data)
    }
    if (
      this.props.monthlyCafeAnalysis !== prevProps.monthlyCafeAnalysis &&
      this.props.monthlyCafeAnalysis.success
    ) {
      this.exportSuccessData(this.props.monthlyCafeAnalysis.data.data)
    }

    if (
      this.props.walkthroughLog !== prevProps.walkthroughLog &&
      this.props.walkthroughLog.success
    ) {
      this.exportSuccessData(this.props.walkthroughLog.data.data)
    }

    if (
      this.props.walkthroughAnalysis !== prevProps.walkthroughAnalysis &&
      this.props.walkthroughAnalysis.success
    ) {
      this.exportSuccessData(this.props.walkthroughAnalysis.data.data)
    }
    if (
      this.props.coworkerLog !== prevProps.coworkerLog &&
      this.props.coworkerLog.success
    ) {
      this.exportSuccessData(this.props.coworkerLog.data.data)
    }
    if (
      this.props.visitorLogs !== prevProps.visitorLogs &&
      this.props.visitorLogs.success
    ) {
      this.exportSuccessData(this.props.visitorLogs.data.data)
    }

    if (
      this.props.issueTicketAnalysisData !==
        prevProps.issueTicketAnalysisData &&
      this.props.issueTicketAnalysisData.success
    ) {
      this.exportSuccessData(this.props.issueTicketAnalysisData.data.data)
    }
  }

  getAvailableCompanyOptions = (companies, status) => {
    // eslint-disable-next-line func-names
    if (status === 'active') {
      const filterCompanyData = companies.filter(function (activeCompanydata) {
        return activeCompanydata.status !== 'inactive'
      })
      return filterCompanyData
        ? filterCompanyData.map((company) => {
            return {
              label: capitalize(company.company_name),
              value: company._id,
            }
          })
        : []
    } else if (status === 'inactive') {
      const filterCompanyData = companies.filter(function (activeCompanydata) {
        return activeCompanydata.status !== 'active'
      })
      return filterCompanyData
        ? filterCompanyData.map((company) => {
            return {
              label: capitalize(company.company_name),
              value: company._id,
            }
          })
        : []
    } else {
      return companies
        ? companies.map((company) => {
            return {
              label: capitalize(company.company_name),
              value: company._id,
            }
          })
        : []
    }
  }

  getAvailableSystemUsersOptions = (systemUsers, status) => {
    // eslint-disable-next-line func-names
    if (status === 'active') {
      const filterUserData = systemUsers.filter(function (activeUserData) {
        return activeUserData.status !== 'inactive'
      })
      return filterUserData
        ? filterUserData.map((element) => {
            return {
              label: spacecapitalize(`${element.role.roletype_id.name}`),
              value: element._id,
            }
          })
        : []
    } else if (status === 'inactive') {
      const filterUserData = systemUsers.filter(function (activeUserData) {
        // console.log(`activeUserData`, activeUserData)
        return activeUserData.status !== 'active'
      })
      // console.log(`filterComanyData`, filterUserData)
      return filterUserData
        ? filterUserData.map((element) => {
            return {
              label: spacecapitalize(`${element.role.roletype_id.name} `),
              value: element._id,
            }
          })
        : []
    } else {
      return systemUsers
        ? systemUsers.map((element) => {
            return {
              label: spacecapitalize(`${element.role.roletype_id.name}`),
              value: element._id,
            }
          })
        : []
    }
  }

  exportSuccessData = (exportReportData) => {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      filename: this.state.reportType.label,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    }
    const csvExporter = new ExportToCsv(options)
    // if add api succes then export this data
    if (exportReportData.length === 0) {
      return []
    } else {
      // eslint-disable-next-line no-lonely-if
      if (this.state.reportType.value === 'bookinganalytics') {
        const data =
          exportReportData &&
          exportReportData.map((item, key) => {
            return {
              'Sr. No.': key + 1,
              'Space Name': spacecapitalize(item.space),
              'Resource Name': spacecapitalize(item.resource_name),
              Days: item.days,
              'Hours Used': item.hours,
              'Average Daily Use': parseFloat(
                item.days / parseFloat(item.hours).toFixed(2)
              ).toFixed(2),
            }
          })
        csvExporter.generateCsv(data)
      } else if (this.state.reportType.value === 'bookinglog') {
        const data =
          exportReportData &&
          exportReportData.map((item, key) => {
            return {
              'Sr. No.': key + 1,
              'Space Name': spacecapitalize(item.space.space_title),
              'Resource Name': spacecapitalize(item.resource_id.resource_name),
              Date: moment(item.createdAt).format('l'),
              'Start Time': moment(item.start).format('LT'),
              'End Time': moment(item.end).format('LT'),
              'Credit Used': parseFloat(item.credits_used_for_bookings).toFixed(
                2
              ),
            }
          })
        csvExporter.generateCsv(data)
      } else if (this.state.reportType.value === 'bookingresource') {
        const data =
          exportReportData &&
          exportReportData.map((item, key) => {
            return {
              'Sr. No.': key + 1,
              'Space Name': spacecapitalize(item.space_name),
              'Client Name': spacecapitalize(item.company_name),
              'Total Usage (in Hours)': item.total_usage_in_hours,
              'Total Credit': item.total_credits,
              'Used Credits': item.used_credits,
              Difference: item.difference,
            }
          })
        csvExporter.generateCsv(data)
      } else if (this.state.reportType.value === 'visitoranalysis') {
        const data =
          exportReportData &&
          exportReportData.map((item, key) => {
            return {
              'Sr. No.': key + 1,
              'Space Name': spacecapitalize(item.space_name),
              'Total Visitors': item.total_visitors,
              'Total Hours Spend': item.total_hours,
            }
          })
        csvExporter.generateCsv(data)
      } else if (this.state.reportType.value === 'clientvisitor') {
        const data =
          exportReportData &&
          exportReportData.map((item, key) => {
            return {
              'Sr. No.': key + 1,
              'Space Name': spacecapitalize(item.space_name),
              'Client Name': spacecapitalize(item.client_name),
              'Total Visitors Entry': item.total_visitor_entry,
              // 'Total Unique Visitors': item.total_unique_visitors
              //   ? item.total_unique_visitors
              //   : '-',
              'Total Hours Spend': item.total_hours,
            }
          })
        csvExporter.generateCsv(data)
      } else if (this.state.reportType.value === 'companyplan') {
        const data =
          exportReportData &&
          exportReportData.map((item, key) => {
            return {
              'Sr. No.': key + 1,
              'Space Name': spacecapitalize(item.space_name),
              'Client Name': spacecapitalize(item.company_name),
              'No of Active Co-Workers': item.active_coworker
                ? item.active_coworker
                : '',
              'Cabin Name': spacecapitalize(
                item.cabin_name ? item.cabin_name : ''
              ),
              'Total Pax': item.total_pax ? item.total_pax : '',
              'Assign Seats': item.assign_seats ? item.assign_seats : '',
              'Price Per Seat': item.price_per_seat ? item.price_per_seat : '',
              'Total Price': item.total_prices ? item.total_prices : '',
              'Total Credits': item.total_credits ? item.total_credits : '',
            }
          })
        csvExporter.generateCsv(data)
      } else if (this.state.reportType.value === 'cafereport') {
        const data =
          exportReportData &&
          exportReportData.map((item, key) => {
            return {
              'Sr. No.': key + 1,
              'Space Name': spacecapitalize(item.space_name),
              'Total Numbers of Orders': item.total_number_of_orders,
              'Total Order Value': item.total_order_value,
            }
          })
        csvExporter.generateCsv(data)
      } else if (this.state.reportType.value === 'walkthroughlogs') {
        const data =
          exportReportData &&
          exportReportData.map((item, key) => {
            return {
              'Sr. No.': key + 1,
              'Space Name': spacecapitalize(item.space_name),
              Date: moment(item.date).format('DD / MM / YYYY'),
              Name: spacecapitalize(item.name ? item.name : ''),
              'Company Name': spacecapitalize(
                item.company_name ? item.company_name : ''
              ),
              'Email ID': item.email_id ? item.email_id : '',
              'Check-In': moment(item.checkin).format('DD / MM / YYYY'),
              'Check-Out': item.checkout
                ? moment(item.checkout).format('DD / MM / YYYY')
                : '',
              'Attended By': item.attended_by ? item.attended_by : '',
              'Date Added': item.data_added,
            }
          })
        csvExporter.generateCsv(data)
      } else if (this.state.reportType.value === 'walkthroughanalysis') {
        const data =
          exportReportData &&
          exportReportData.map((item, key) => {
            return {
              'Sr. No.': key + 1,
              'Space Name': spacecapitalize(item.space_name),
              'Total Walkthrough': item.total_walkthrough,
              'Total Data Collected': item.data_collected,
            }
          })
        csvExporter.generateCsv(data)
      } else if (this.state.reportType.value === 'coworkerlog') {
        const data =
          exportReportData &&
          exportReportData.map((item, key) => {
            return {
              'Sr. No.': key + 1,
              Name: spacecapitalize(`${item.first_name} ${item.last_name}`),
              'Contact Number': item.contact_number,
              DOB: moment(item.date_of_birth).format('DD / MM / YYYY'),
              Email: item.user_id ? item.user_id.email : '-',
              'Company Name':
                item.company_id && item.company_id.company_name
                  ? item.company_id.company_name
                  : '-',
              Status:
                item.user_id && item.user_id.status ? item.user_id.status : '-',
            }
          })
        csvExporter.generateCsv(data)
      } else if (this.state.reportType.value === 'visitorlogs') {
        const data =
          exportReportData &&
          exportReportData.map((item, key) => {
            return {
              'Sr. No.': key + 1,
              'Space Name': item.space.space_title
                ? capitalize(item.space.space_title)
                : '',
              Name: item.visitor.visitor_name
                ? capitalize(item.visitor.visitor_name)
                : '',
              'Company Name': item.visitor.visitor_company_name
                ? capitalize(item.visitor.visitor_company_name)
                : '',
              'Email ID': item.visitor.email ? item.visitor.email : '',
              Scheduled: moment(item.date).format('DD/MM/YYYY'),
              'Check-In': moment(item.checkin).format('LT'),
              'Check-Out': item.checkout ? item.checkout : '',
              'No. of Visitors': item.no_of_visitors ? item.no_of_visitors : '',
              Status: capitalize(item.status),
              // 'Total Hours in Date Range':
              // item.checkin.getTime()-item.checkout.getTime(),
            }
          })
        csvExporter.generateCsv(data)
      } else if (this.state.reportType.value === 'ticketanalysis') {
        const data =
          exportReportData &&
          exportReportData.map((item, key) => {
            return {
              'Sr. No.': key + 1,
              'Space Name': item.Space ? capitalize(item.Space) : '',
              'Issue Title': item.Title ? capitalize(item.Title) : '',
              Category: item.Category ? capitalize(item.Category) : '',
              Department: item.Department ? item.Department : '',
              Comment: item.Comment ? item.Comment : '',
              'Created At': item.Date
                ? moment(item.Date).format('DD/MM/YYYY')
                : '',
              Description: item.Description ? item.Description : '',
              Statu: item.Status ? capitalize(item.Status) : '',
              'Close Tickets': item.Closed
                ? moment(item.Closed).format('DD/MM/YYYY')
                : '',
              'Average TAT': item.Tat ? item.Tat : '',
            }
          })
        csvExporter.generateCsv(data)
      }
    }
  }

  handleClose = () => {
    const path = '/settings'
    this.props.history.push(path)
  }

  getCategoryOptions = () => {
    switch (this.state.dropDown1.value) {
      case 'bookingreports':
        return reportOptions
      case 'visitorreports':
        return visitorOptions
      case 'walkthroughreports':
        return walkthroughOptions
      case 'companyreports':
        return companyOptions
      case 'coworkerreports':
        return coworkerOptions
      case 'cafeteriareports':
        return cafeteriaOptions
      case 'helpdeskreports':
        return helpdeskOptions
      default:
        return reportOptions
    }
  }

  handleSpaceData = (spaceData) => {
    const spaceList = spaceData && spaceData.map((el) => el.value)
    this.props.getSpaceResource(spaceList)
    this.setState({ spaceData: spaceList })
  }

  handleSubmitData = (data, setSubmitting) => {
    const values = {}
    const spaceList = data.spaceids && data.spaceids.map((el) => el.value)
    values.space_ids = spaceList
    const resourceList =
      data.resourceid && data.resourceid.map((el) => el.value)
    values.resource_ids = resourceList
    values.start_date = moment(data.start_date).format('YYYY-MM-DD')
    values.end_date = moment(data.end_date).format('YYYY-MM-DD')
    const compnayData = data.clientids && data.clientids.map((el) => el.value)
    values.space_ids = spaceList
    values.company_ids = compnayData
    if (this.state.reportType.value === 'coworkerlog') {
      values.coworker_status = data.coworker_status.value
    }
    switch (this.state.reportType.value) {
      case 'bookinganalytics':
        return this.props.addBookingAnalysis(values, setSubmitting)
      case 'bookinglog':
        return this.props.companyResourceBookingLog(values, setSubmitting)
      case 'bookingresource':
        return this.props.companyResourceBookingAnalysis(values, setSubmitting)
      case 'visitoranalysis':
        return this.props.visitorAnalysisData(values, setSubmitting)
      case 'clientvisitor':
        return this.props.clientsVisitorAnalysisData(values, setSubmitting)
      case 'companyplan':
        return this.props.companyPlanAnalysisData(values, setSubmitting)
      case 'cafereport':
        return this.props.monthlyCafeConsumptionAnalysis(values, setSubmitting)
      case 'walkthroughlogs':
        return this.props.walkthroughLogData(values, setSubmitting)
      case 'walkthroughanalysis':
        return this.props.walkthroughAnalysisData(values, setSubmitting)
      case 'coworkerlog':
        return this.props.coworkerLogsData(values, setSubmitting)
      case 'visitorlogs':
        return this.props.visitorLogsData(values, setSubmitting)
      case 'ticketanalysis':
        return this.props.issueTicketAnalysis(values, setSubmitting)
      default:
        return []
    }
  }

  render() {
    const { classes, companies } = this.props
    const { allspaces, allResources, reportType, clientType } = this.state
    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom style={{ marginLeft: '25px' }}>
          Report Analytics
        </Typography>

        <div className={classes.lightbg}>
          <Formik
            initialValues={{
              reportcategory: [],
              reportname: [],
              spaceids: [],
              resourceid: [],
              start_date: new Date(),
              end_date: new Date(),
              client_type: [],
              clientids: [],
              user_type: [],
              system_user: [],
              coworker_status: [],
              reporttypefinal: '',
            }}
            onSubmit={(values, { setErrors, setSubmitting, resetForm }) => {
              // const submitValues = { ...values }
              this.handleSubmitData(values, setSubmitting)
              resetForm(this.initialValues)
            }}
            validationSchema={reportSchema}
          >
            {({
              values,
              errors,
              setFieldValue,
              setFieldTouched,
              touched,
              handleSubmit,
            }) => (
              <Form
                onSubmit={handleSubmit}
                className={classes.form}
                encType="multipart/form-data"
                autoComplete="off"
              >
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6}>
                    <SelectField
                      value={values.reportcategory}
                      name="reportcategory"
                      id="reportcategory"
                      label="Report Category"
                      onChange={(name, value) => {
                        setFieldValue(name, value)
                        this.setState({ dropDown1: value })
                        setFieldValue('reportname', '')
                        setFieldValue('spaceids', '')
                        setFieldValue('resourceid', '')
                      }}
                      multi={false}
                      onBlur={setFieldTouched}
                      touched={touched.reportcategory}
                      error={errors.reportcategory}
                      options={reportCategoryOptions}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <SelectField
                      value={values.reportname}
                      name="reportname"
                      id="reportname"
                      label="Reports"
                      onChange={(name, value) => {
                        setFieldValue(name, value)
                        setFieldValue('reporttypefinal', value.value)
                        this.setState({
                          reportType: value,
                        })
                      }}
                      onBlur={setFieldTouched}
                      multi={false}
                      touched={touched.reportname}
                      error={errors.reportname}
                      options={this.getCategoryOptions()}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <SelectField
                      value={values.spaceids}
                      name="spaceids"
                      id="spaceids"
                      label="Select Space"
                      onChange={(name, value) => {
                        setFieldValue(name, value)
                        this.handleSpaceData(value)
                      }}
                      onBlur={setFieldTouched}
                      multi
                      touched={touched.spaceids}
                      error={errors.spaceids}
                      options={allspaces}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <SelectField
                      value={values.resourceid}
                      name="resourceid"
                      id="resourceid"
                      label="Select Resource"
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      multi
                      touched={touched.resourceid}
                      error={errors.resourceid}
                      disabled={
                        !(
                          reportType.value === 'bookinganalytics' ||
                          reportType.value === 'bookinglog'
                        )
                      }
                      options={allResources}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <SelectField
                      value={values.client_type}
                      name="client_type"
                      id="client_type"
                      label="Select Client Type"
                      onChange={(name, value) => {
                        setFieldValue(name, value)
                        this.setState({ clientType: value.value })
                        this.props.getMultipleSpaceCompany(this.state.spaceData)
                      }}
                      onBlur={setFieldTouched}
                      multi={false}
                      disabled={
                        !(
                          reportType.value === 'bookinglog' ||
                          reportType.value === 'clientvisitor'
                        )
                      }
                      touched={touched.client_type}
                      error={errors.client_type}
                      options={reportTypeData}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <SelectField
                      value={values.clientids}
                      name="clientids"
                      id="clientids"
                      label="Select Client"
                      onChange={(name, value) => {
                        setFieldValue(name, value)
                      }}
                      onBlur={setFieldTouched}
                      multi
                      disabled={
                        !(
                          reportType.value === 'bookinglog' ||
                          reportType.value === 'clientvisitor'
                        )
                      }
                      touched={touched.clientids}
                      error={errors.clientids}
                      options={this.getAvailableCompanyOptions(
                        companies,
                        clientType
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <FormLabel className={classes.dateLabel} component="span">
                      Start Date
                    </FormLabel>
                    <DatePickerField
                      name="start_date"
                      onChange={(name, value) => {
                        setFieldValue(name, value)
                      }}
                      value={values.start_date}
                      disableFuture
                      className={classes.bookingDate}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <FormLabel className={classes.dateLabel} component="span">
                      End Date
                    </FormLabel>
                    <DatePickerField
                      name="end_date"
                      onChange={(name, value) => {
                        setFieldValue(name, value)
                      }}
                      value={values.end_date}
                      disableFuture
                      className={classes.bookingDate}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <SelectField
                      value={values.coworker_status}
                      name="coworker_status"
                      id="coworker_status"
                      label="Select Coworker Staus"
                      onChange={(name, value) => {
                        setFieldValue(name, value)
                        this.setState({ coworker_status: value.value })
                      }}
                      onBlur={setFieldTouched}
                      multi={false}
                      disabled={!(reportType.value === 'coworkerlog')}
                      touched={touched.coworker_status}
                      error={errors.coworker_status}
                      options={reportTypeData}
                    />
                  </Grid>
                  {/* <Grid item xs={6} sm={6}>
                    <SelectField
                      value={values.user_type}
                      name="user_type"
                      id="user_type"
                      label="Select User Type"
                      onChange={(name, value) => {
                        setFieldValue(name, value)
                        this.setState({ userType: value.value })
                        setFieldValue('system_user', '')
                      }}
                      onBlur={setFieldTouched}
                      disabled={!(reportType.value === 'userticket')}
                      multi={false}
                      touched={touched.user_type}
                      error={errors.user_type}
                      options={reportTypeData}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <SelectField
                      value={values.system_user}
                      name="system_user"
                      id="system_user"
                      label="Select System User"
                      onChange={(name, value) => {
                        setFieldValue(name, value)
                        this.setState({ userType: value.value })
                      }}
                      disabled={!(reportType.value === 'userticket')}
                      onBlur={setFieldTouched}
                      multi={false}
                      touched={touched.system_user}
                      error={errors.system_user}
                      options={this.getAvailableSystemUsersOptions(
                        systemUsers.data,
                        userType
                      )}
                    />
                  </Grid> */}
                </Grid>

                <div className={classes.formfooter}>
                  <Grid
                    container
                    item
                    display="flex"
                    justify="flex-end"
                    alignItems="center"
                    direction="row"
                  >
                    <Grid xs={6} sm={2}>
                      <Button
                        color="primary"
                        onClick={this.handleClose}
                        className={classes.button}
                        style={{ right: '-7px' }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid xs={6} sm={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        className={classes.Addbutton}
                        // onClick={() =>
                        //   this.exportReportData(values, setSubmitting)
                        // }
                      >
                        Export CSV
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  // console.log('state daataaa::::', state)
  return {
    auth: state.auth,
    spaces: state.spaces.spaces,
    resources: state.resources.allresources,
    companies: state.reportAnalysisData.allCompany,
    reportAnalysis: state.reportAnalysisData.reportAnalysis,
    systemUsers: state.manageuser.users,
    companyBookingLog: state.reportAnalysisData.companyBookingLog,
    companyReosurceBookingAnalysis:
      state.reportAnalysisData.companyReosurceBookingAnalysis,
    visitorAnalysis: state.reportAnalysisData.visitorAnalysis,
    clientsVisitorAnalysis: state.reportAnalysisData.clientsVisitorAnalysis,
    monthlyCafeAnalysis: state.reportAnalysisData.monthlyCafeAnalysis,
    companyPlanAnalysis: state.reportAnalysisData.companyPlanAnalysis,
    walkthroughLog: state.reportAnalysisData.walkthroughLog,
    walkthroughAnalysis: state.reportAnalysisData.walkthroughAnalysis,
    coworkerLog: state.reportAnalysisData.coworkerLog,
    visitorLogs: state.reportAnalysisData.visitorLogs,
    issueTicketAnalysisData: state.reportAnalysisData.issueTicketAnalysisData,
  }
}

export default connect(mapStateToProps, {
  addBookingAnalysis,
  getSpaces,
  getSpaceResource,
  getMultipleSpaceCompany,
  getUsers,
  companyResourceBookingLog,
  companyResourceBookingAnalysis,
  visitorAnalysisData,
  clientsVisitorAnalysisData,
  companyPlanAnalysisData,
  monthlyCafeConsumptionAnalysis,
  walkthroughLogData,
  walkthroughAnalysisData,
  coworkerLogsData,
  visitorLogsData,
  issueTicketAnalysis,
})(withStyles(styles)(withRouter(ReportList)))
