import * as Yup from "yup";

const Step1Validations = Yup.object().shape({
  company_name: Yup.string()
    .max(15, "")
    // .matches(/[a-zA-Z]+/gi, "Enter Valid Name")
    // .matches(/^[a-zA-Z0-9 ]+$/, "Enter Valid Name")
    .trim()
    .required("This field is required."),
  display_name: Yup.string()
    .max(20, "")
    .matches(/^.[a-zA-Z0-9 ]+$/, "Enter Valid Name")
    .trim()
    .required("This field is required."),
  // logo: Yup.mixed().required("This field is required."),
  industry: Yup.string().required("This field is required."),
  employee_strength: Yup.string().required("This field is required."),
  client_type: Yup.string().required("This field is required."),
  // eslint-disable-next-line func-names
  description: Yup.mixed().test("match", "This field is required", function () {
    const finalContent =
      this.parent &&
      this.parent.editorState.getCurrentContent().getPlainText() !== "";
    if (finalContent === true) {
      return true;
    }
    return false;
  }),
});
const Step2Validations = Yup.object().shape({
  // title: Yup.string()
  //   .max(50, "")
  //   .required("This field is required."),
  website: Yup.string().url(),
});

export { Step1Validations, Step2Validations };
