import axios from "axios";
import {
  GET_ALL_ISSUE_DEPARTMENT,
  ISSUE_DEPARTMENT_LOADING,
  ISSUE_DEPARTMENT_ERROR,
  ADD_ISSUE_DEPARTMENT,
  GET_ERRORS,
  EDIT_ISSUE_DEPARTMENT,
  EDIT_ISSUE_DEPARTMENT_ERRORS,
  GET_SINGLE_ISSUE_DEPARTMENT
} from "./types";
import { showSuccess, showError } from "./ActionsHelper";
import { transformErrorsFromApi } from "./TransformError";
//  Loading
export const setIssueDepartmentLoading = () => {
  return {
    type: ISSUE_DEPARTMENT_LOADING
  };
};

export const addIssueDepartment = (
  userData,
  setErrors,
  history,
  setSubmitting
) => dispatch => {
  dispatch(setIssueDepartmentLoading());
  axios
    .post("/api/helpdeskdepartment/", userData)
    .then(result => {
      dispatch({
        type: ADD_ISSUE_DEPARTMENT,
        payload: result.data
      });
      history.push("/issue-department");
      dispatch(showSuccess("Issue Department"));
      setSubmitting(false);
    })
    .catch(err => {
      dispatch({
        type: ISSUE_DEPARTMENT_ERROR,
        payload: err && err.response ? err.response.data.data : ""
      });
      dispatch(showError(err.response.data.data));
    });
};

export const getAllIssueDepartment = () => dispatch => {
  dispatch(setIssueDepartmentLoading());
  axios
    .get(`/api/helpdeskdepartment/`)
    .then(res => {
      dispatch({
        type: GET_ALL_ISSUE_DEPARTMENT,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: ISSUE_DEPARTMENT_ERROR,
        payload: err.response && err.response.data
      })
    );
};

//GET SINGLE RESOURCE TYPE
export const getSingleIssueDepartment = id => dispatch => {
  dispatch(setIssueDepartmentLoading());
  axios
    .get(`/api/helpdeskdepartment/${id}`)
    .then(res => {
      dispatch({
        type: GET_SINGLE_ISSUE_DEPARTMENT,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data
      })
    );
};

export const editIssueDepartment = (
  id,
  data,
  setErrors,
  setSubmitting
) => dispatch => {
  dispatch(setIssueDepartmentLoading());
  axios
    .put(`/api/helpdeskdepartment/${id}`, data)
    .then(result => {
      dispatch({
        type: EDIT_ISSUE_DEPARTMENT,
        payload: result.data
      });
      dispatch(showSuccess("", "Issue Department edited successfully!"));
      setSubmitting(false);
    })
    .catch(err => {
      const error = err.response;
      dispatch({ type: EDIT_ISSUE_DEPARTMENT_ERRORS, payload: {} });
      setSubmitting(false);
      setErrors(transformErrorsFromApi(error));
    });
};
