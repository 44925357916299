/* eslint-disable camelcase */
import React from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { Typography, Grid } from "@material-ui/core";
import classNames from "classnames";

const styles = () => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "12px",
  },
  mainTitle: {
    fontWeight: "600",
    fontSize: "20px",
    textAlign: "left",
    color: "#222222",
    lineHeight: "25px",
  },
  heading: {
    fontSize: "16px",
    color: "#222222",
    fontWeight: "500",
    margin: "4px 0px",
    marginBottom: "15px",
  },
  title: {
    fontSize: "13px",
    color: "#222222",
    fontWeight: "500",
    padding: "10px",
  },
  subTitle: {
    fontSize: "13px",
    color: "#222222",
    fontWeight: "500",
    margin: "4% 0% 2%",
  },
  phone: {
    color: "#1592E6",
    fontWeight: "500",
  },
  description: {
    color: "#222222",
    fontSize: "13px",
    lineHeight: "18px",
  },
  generalDetails: {
    padding: "3% 0% 0%",
  },
  cardLayout: {
    border: "1px solid #EFEFEF",
    borderRadius: "5px",
    padding: "10px",
  },
  padding: {
    padding: "10px",
  },
});

function HrConsulting(props) {
  const { classes } = props;

  return (
    <div className={classes.main}>
      <div className={classes.header}>
        <Typography align="left" variant="h1" className={classes.mainTitle}>
          HR Consulting & Recruitment Services
        </Typography>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Typography align="left" className={classes.description}>
            Visualize the glimpse of our efforts in the performance of your
            team. DevX, offers end to end{" "}
            <span className={classes.phone}>
              HR consulting services pan India
            </span>{" "}
            focusing on varied services like Recruitment, Training and
            Development, HR compliance etc. We will help you build a team that
            will build your business. A right HR consulting partner can
            accelerate your growth by building a strong team. Our domain
            expertise with the transparent and qualitative value system that
            drives the client delivery. We help you invest in the most important
            asset of your business wisely.
          </Typography>
          <Grid container spacing={2} className={classes.generalDetails}>
            <Typography align="left" className={classes.title}>
              We have a strong focus in providing the right candidate in the
              following roles;
            </Typography>
            <Grid item xs={4}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  CXOs and Senior <br />
                  Level Management.
                  <br />
                  <br />
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  Head Hunting and <br />
                  Strategic Hiring
                  <br />
                  <br />
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  Middle-Level <br />
                  Management
                  <br />
                  <br />
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  CXOs and Senior <br />
                  Level Management.
                  <br />
                  <br />
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  Head Hunting and <br />
                  Strategic Hiring
                  <br />
                  <br />
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  Middle-Level <br />
                  Management
                  <br />
                  <br />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.generalDetails}>
            <Typography align="left" className={classes.title}>
              We provide the following services apart from HR recruitment
              services across India;
            </Typography>
            <Grid item xs={4}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  Training and <br />
                  Development
                  <br />
                  <br />
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  Payroll <br />
                  Management
                  <br />
                  <br />
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  Performance <br />
                  Appraisal <br />
                  <br />
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  Career
                  <br />
                  Counselling
                  <br />
                  <br />
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.description}>
                  HR support services like employee <br />
                  engagement activities, staff grievances <br />
                  <br />
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2} className={classes.generalDetails}>
            <Typography
              align="left"
              className={classNames(classes.mainTitle, classes.padding)}
            >
              Why us?
            </Typography>
            <Grid item xs={12}>
              <Typography align="left" className={classes.description}>
                Our ability to build a long-lasting client relationship by
                providing High-quality HR consulting services at door-step
                through sustained efforts makes us stand out from the
                competitors.
              </Typography>

              <Typography align="left" className={classes.subTitle}>
                Your trusted business partners for many reasons;
              </Typography>
              <Typography align="left" className={classes.description}>
                Our experience in building teams and community.
                <br />
                <br /> No credit point charges for recruitment interviews.
                <br />
                <br />
                Prompt, Efficient, Robust paramount service delivery.
                <br />
                <br />
                Stringent quality measures and meticulous quality standards.
                <br />
                <br />
                With our rigorous referral checks, screening process, we assure
                you the highest level of accountability and candidate option
                support. We aim to surpass our set benchmarks.
              </Typography>
              <Typography align="left" className={classes.subTitle}>
                Focus on the core activity of your business and leave your
                staffing needs to us.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.spaces.loading,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(HrConsulting));
