import {
  GET_PARTICIPANTS,
  UPLOAD_EXCEL,
  PARTICIPANTS_LOADING
} from "../Actions/types";

const initialState = {
  participants: {},
  participants_data: {},
  loading: false
};
export default function(state = initialState, action) {
  switch (action.type) {
    case GET_PARTICIPANTS:
      return {
        ...state,
        loading: false,
        participants: action.payload
      };
    case UPLOAD_EXCEL:
      return {
        ...state,
        loading: false,
        participants_data: action.payload
      };
    case PARTICIPANTS_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
}
