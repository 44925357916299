import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { Grid, Button } from "@material-ui/core";
import { CircularLoader } from "../../Components/Common/Spinner";
import DataNotFound from "../../Components/Common/DataNotFound";
// eslint-disable-next-line import/no-cycle
import SuggestForm from "./suggestForm";
import SuggestPost from "./suggestPost";
import { getsuggest } from "../../Actions/SuggestFeatureAction";

const styles = () => ({
  noPostPaper: {
    height: "200px",
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
  },
  suggestbutton: {
    margin: "0px 5px 10px 0px",
    // padding:"5px 16px",
    color: "#fff",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  divsuggestbutton: {
    width: "100%",
    textAlign: "right",
    // padding:"5px 16px",
  },
});

export class SuggestDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submit: false,
      suggestFeature: [],
    };
  }

  componentDidMount() {
    this.props.getsuggest();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.suggestFeature !== this.props.suggestFeature) {
      let suggestFeature = this.props.suggestFeature || [];
      suggestFeature = suggestFeature.map((x, id) => {
        return Object.assign({}, x, {
          idx: id + 1,
        });
      });
      this.setState({
        suggestFeature,
      });
    }
    if (
      this.props.suggest !== prevProps.suggest &&
      this.props.suggest.success
    ) {
      this.props.getsuggest();
    }
    // if (prevProps.like_suggesion !== this.props.like_suggesion) {
    //   this.props.getsuggest();
    // }
    // if (
    //   this.props.like_suggesion !== prevProps.like_suggesion &&
    //   this.props.like_suggesion.success
    // ) {
    //   this.props.getsuggest();
    // }

    // if (prevProps.loading !== this.props.loading) {
    //   this.props.setLoading(this.props.loading);
    //   this.setState({
    //     loading: this.props.loading,
    //   });
    // }
  }

  submitForm = () => {
    this.setState({ submit: true });
  };

  changeSubmit = () => {
    this.setState({ submit: false });
  };

  filteredSuggestFeature = () => {
    const { suggestFeature } = this.state;
    const data =
      suggestFeature &&
      suggestFeature.filter(
        (item) =>
          item.feature_status === "approve_for_poll" ||
          item.feature_status === "in_development"
      );
    return data;
  };

  render() {
    const { classes } = this.props;
    const { submit, loading } = this.state;

    const approvePollData = this.filteredSuggestFeature();

    if (loading) {
      return <CircularLoader />;
    }
    return (
      <div className={classes.main}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            {!submit && (
              <>
                <div className={classes.divsuggestbutton}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.suggestbutton}
                    onClick={this.submitForm}
                  >
                    Suggest a Feature
                  </Button>
                </div>
                <Grid item xs={12} sm={12}>
                  {approvePollData && approvePollData.length <= 0 ? (
                    <DataNotFound
                      type="visitors"
                      text="No results found!"
                      subtext=""
                      paddingTop="8%"
                    />
                  ) : (
                    approvePollData &&
                    approvePollData.length > 0 &&
                    approvePollData.map((item) => {
                      return <SuggestPost post={item} />;
                    })
                  )}
                </Grid>
              </>
            )}
          </Grid>

          {submit && <SuggestForm submit={this.changeSubmit} />}
        </Grid>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    suggestFeature: state.suggestfeature.all_suggesion,
    loading: state.suggestfeature.loading,
    suggest: state.suggestfeature.all_suggesion,
    like_suggesion: state.suggestfeature.like_suggesion,
  };
};

export default connect(mapStateToProps, {
  getsuggest,
})(withStyles(styles)(SuggestDetail));
