/* eslint-disable camelcase */
import React from 'react'
import { withStyles, Grid, Paper, Typography } from '@material-ui/core'
import { connect } from 'react-redux'
import Parking from '../../Assets/parking.png'
import HouseRules from '../../Assets/houserules.png'
import EmergencyNo from '../../Assets/emergencyno.png'
import FaqsImage from '../../Assets/faqs.png'
import DevxTeam from '../../Assets/devxteam.png'
import badge from '../../Assets/badges.png'

import ImageTextLayout from './ImageTextLayout'
import ParkingPolicy from './ParkingPolicy'
import HouseRulesPolicy from './HouseRules'
import Faqs from './Faqs'
import EmergencyNumber from './EmergencyNo'
import FireExitPlan from './FireExit'
import DevxsTeam from './DevxTeam'
import Badges from './Badges'
import {
  getSingleKnowledgeCenterInformation,
  getTeamData,
} from '../../Actions/knowledgeCenterActions'

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
  },
  mainGrid: {
    height: '90vh',
    overflowY: 'scroll',
  },
  paper: {
    padding: '20px',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: '100%',
  },
  TextLayout: {
    paddingBottom: '3%',
  },
  mainTitle: {
    fontWeight: '600',
    fontSize: '40px',
    textAlign: 'left',
    color: '#222222',
    margin: '0px',
    lineHeight: '45px',
  },
  descText: {
    fontSize: '14px',
    textAlign: 'left',
    color: '#626262',
    marginTop: '5px',
  },
  knowledgeLayout: {
    padding: '5% 0% 0%',
    display: 'flex',
  },
  contentLayout: {
    margin: '0px 25px 0px 0px',
  },
  imageDiv: {
    border: '1px solid #EFEFEF',
    borderRadius: '5px',
  },
  setupImg: {
    padding: '10%',
  },
  subTitle: {
    margin: '0% 0% 10%',
    textAlign: 'center',
    fontWeight: '600',
    lineHeight: '18px',
    fontSize: '16px',
  },
})

class AdminKnowledgeCentre extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      knowledgeCategory: 'parking',
    }
  }

  componentDidMount() {
    this.props.getSingleKnowledgeCenterInformation()
    this.props.getTeamData()
  }

  setKnowledgeCategory = (category) => {
    this.setState({ knowledgeCategory: category })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.single_knowledge_center_info !==
      prevState.single_knowledge_center_info
    ) {
      return {
        single_knowledge_center_info: nextProps.single_knowledge_center_info,
      }
    }
    if (nextProps.allTeam !== prevState.allTeam) {
      return {
        allTeam: nextProps.allTeam,
      }
    }

    return []
  }

  render() {
    const { classes, single_knowledge_center_info, allTeam } = this.props
    const { knowledgeCategory } = this.state
    return (
      <React.Fragment>
        <main className={classes.root}>
          <Grid container spacing={3} >
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <Grid className={classes.TextLayout}>
                  <Typography
                    align="center"
                    variant="h1"
                    className={classes.mainTitle}
                  >
                    <div>DevX</div>
                    <div>Knowledge Centre </div>
                  </Typography>
                  <div className={classes.descText}>
                    <Typography align="center" variant="p">
                      This section will help you know more about DevX and
                      surroundings.
                    </Typography>
                  </div>
                </Grid>
                <Grid className={classes.knowledgeLayout}>
                  <ImageTextLayout
                    image={Parking}
                    text="Parking"
                    knowledge
                    name="parking"
                    setCategory={this.setKnowledgeCategory}
                    category={knowledgeCategory}
                  ></ImageTextLayout>
                  <ImageTextLayout
                    image={HouseRules}
                    text="House-rules"
                    knowledge
                    name="houserules"
                    setCategory={this.setKnowledgeCategory}
                    category={knowledgeCategory}
                  ></ImageTextLayout>
                  <ImageTextLayout
                    image={EmergencyNo}
                    text="Emergency Number"
                    name="emergencyno"
                    knowledge
                    setCategory={this.setKnowledgeCategory}
                    category={knowledgeCategory}
                  ></ImageTextLayout>
                </Grid>
                <Grid className={classes.knowledgeLayout}>
                  <ImageTextLayout
                    image={FaqsImage}
                    text="FAQs"
                    knowledge
                    name="faqs"
                    setCategory={this.setKnowledgeCategory}
                    category={knowledgeCategory}
                  ></ImageTextLayout>
                  {/* <ImageTextLayout
                    image={FireExit}
                    text="Fire Exit Plan"
                    knowledge
                    name="fireexit"
                    setCategory={this.setKnowledgeCategory}
                    category={knowledgeCategory}
                  ></ImageTextLayout> */}
                  <ImageTextLayout
                    image={DevxTeam}
                    text="DevX's Team"
                    name="devxteam"
                    knowledge
                    setCategory={this.setKnowledgeCategory}
                    category={knowledgeCategory}
                  ></ImageTextLayout>
                  {/* </Grid>
                <Grid className={classes.knowledgeLayout}> */}
                  <ImageTextLayout
                    image={badge}
                    text="Badges"
                    knowledge
                    name="badges"
                    setCategory={this.setKnowledgeCategory}
                    category={knowledgeCategory}
                  ></ImageTextLayout>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper
                className={classes.paper}
                style={{ height: '89vh', overflowY: 'scroll' }}
              >
                {knowledgeCategory === 'parking' && (
                  <ParkingPolicy
                    data={single_knowledge_center_info}
                  ></ParkingPolicy>
                )}
                {knowledgeCategory === 'houserules' && (
                  <HouseRulesPolicy></HouseRulesPolicy>
                )}
                {knowledgeCategory === 'faqs' && <Faqs></Faqs>}
                {knowledgeCategory === 'emergencyno' && (
                  <EmergencyNumber
                    data={single_knowledge_center_info}
                  ></EmergencyNumber>
                )}
                {knowledgeCategory === 'fireexit' && (
                  <FireExitPlan></FireExitPlan>
                )}
                {knowledgeCategory === 'devxteam' && (
                  <DevxsTeam data={allTeam}></DevxsTeam>
                )}
                {knowledgeCategory === 'badges' && <Badges></Badges>}
              </Paper>
            </Grid>
          </Grid>
        </main>
      </React.Fragment>
    )
  }
}

AdminKnowledgeCentre.propTypes = {}

const mapStateToProps = (state) => {
  return {
    single_knowledge_center_info:
      state.knowledgeCenterData.single_knowledge_center_info,
    allTeam: state.knowledgeCenterData.allTeam,
  }
}

export default connect(mapStateToProps, {
  getSingleKnowledgeCenterInformation,
  getTeamData,
})(withStyles(styles)(AdminKnowledgeCentre))
