import axios from 'axios'
import { showSuccess } from './ActionsHelper'
import {
  LIST_LOADING,
  GET_LIST_AMBASSDOR,
  GET_ONE_LIST_AMBASSDOR,
  LIST_ERROR,
  ADD_CONFIRMATION,
  UPDATE_STATUS,
  APPLY_NOW,
} from './types'

export const setLoading = () => {
  return {
    type: LIST_LOADING,
  }
}

export const getCommunityAmbassdor = () => (dispatch) => {
  dispatch(setLoading())
  axios
    .get(`/api/communityambassador`)
    .then((result) => {
      dispatch({
        type: GET_LIST_AMBASSDOR,
        payload: result.data,
      })
    })
    .catch((err) => {
      dispatch({
        type: LIST_ERROR,
        payload: err && err.response ? err.response.data : '',
      })
    })
}
export const getoneCommunityAmbassdor = (id) => (dispatch) => {
  dispatch(setLoading())
  let url = `/api/communityambassador/getone`
  if (id) {
    url = `/api/communityambassador/getone?id=${id}`
  }
  axios
    .get(url)
    .then((result) => {
      dispatch({
        type: GET_ONE_LIST_AMBASSDOR,
        payload: result.data,
      })
    })
    .catch((err) => {
      dispatch({
        type: LIST_ERROR,
        payload: err && err.response ? err.response.data : '',
      })
    })
}
export const AddConfirmation = (id, data, setErrors, setSubmitting) => (
  dispatch
) => {
  dispatch(setLoading())
  axios
    .put(`/api/communityambassador/${id}`, data)
    .then((result) => {
      dispatch({
        type: ADD_CONFIRMATION,
        payload: result.data,
      })
      if (result.data.data.ambassador_approve_status === 'reject') {
        dispatch(showSuccess('', 'User rejected successfully!'))
      } else {
        dispatch(showSuccess('Confirmation', ''))
      }
      if (setSubmitting) {
        setSubmitting(false)
      }
    })
    .catch((err) => {
      dispatch({
        type: LIST_ERROR,
        payload: {},
      })
      setSubmitting(false)
    })
}
export const updateStatus = (id, data, setSubmitting) => (dispatch) => {
  dispatch(setLoading())
  axios
    .put(`/api/communityambassador/changestatus/${id}`, data)
    .then((result) => {
      dispatch({
        type: UPDATE_STATUS,
        payload: result.data,
      })
      dispatch(showSuccess('', 'Status updated successfully!'))
      setSubmitting(false)
    })
    .catch((err) =>
      dispatch({
        type: LIST_ERROR,
        payload: {},
      })
    )
}
export const applyNow = () => (dispatch) => {
  dispatch(setLoading())
  axios
    .post(`/api/communityambassador`)
    .then((result) => {
      dispatch({
        type: APPLY_NOW,
        payload: result.data,
      })
    })
    .catch((err) => {
      dispatch({
        type: LIST_ERROR,
        payload: err && err.response ? err.response.data : '',
      })
    })
}
