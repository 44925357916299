import React from 'react'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import Carousel from 're-carousel'
import img4 from '../../Assets/img4.png'
import img3 from '../../Assets/img3.png'
import slide6 from '../../Assets/img3_1.jpg'
import slide2 from '../../Assets/img4_1.jpg'
import slide8 from '../../Assets/img5_1.jpg'
import slide3 from '../../Assets/img6_1.jpg'
import slide4 from '../../Assets/img7_1.jpeg'
import slide5 from '../../Assets/img8_1.png'
import slide1 from '../../Assets/img11_1.jpg'
import slide7 from '../../Assets/img12_1.jpg'

const $quoteSym = ' '
const styles = () => ({
  sliderSection: {
    padding: '10px',
    width: '100%',
    paddingLeft: '0.1%',
    paddingTop: '0px',
    marginTop: '-1%',
  },
  screenheader: {
    fontSize: '16px',
    fontWeight: '600',
    position: 'relative',
    '&:after': {
      top: '24px',
      left: '0px',
      width: '24px',
      borderBottom: '3px solid #fb0',
      content: `'${$quoteSym}'`,
      position: 'absolute',
    },
  },
  lifedevx: {
    height: '450px',
    margin: '16px 0px',
  },
})

function LifeDevx(props) {
  const { classes } = props
  return (
    <div className={classes.sliderSection}>
      <span className={classes.screenheader}>Life @ DevX</span>
      <div className={classes.lifedevx}>
        <Carousel loop auto>
          <img
            alt="logo"
            src={slide1}
            className={classes.group}
            width="100%"
            height="453px"
          />
          <img
            alt="logo"
            src={slide2}
            className={classes.group}
            width="100%"
            height="453px"
          />
          <img
            alt="logo"
            src={slide3}
            className={classes.group}
            width="100%"
            height="453px"
          />
          <img
            alt="logo"
            src={slide4}
            className={classes.group}
            width="100%"
            height="453px"
          />
          <img
            alt="logo"
            src={slide5}
            className={classes.group}
            width="100%"
            height="453px"
          />
          <img
            alt="logo"
            src={slide6}
            className={classes.group}
            width="100%"
            height="453px"
          />
          <img
            alt="logo"
            src={slide7}
            className={classes.group}
            width="100%"
            height="453px"
          />
          <img
            alt="logo"
            src={slide8}
            className={classes.group}
            width="100%"
            height="453px"
          />
          <img
            alt="logo"
            src={img4}
            className={classes.group}
            width="100%"
            height="453px"
          />
          <img
            alt="logo"
            src={img3}
            className={classes.group}
            width="100%"
            height="453px"
          />
        </Carousel>
      </div>
    </div>
  )
}

export default connect()(withStyles(styles)(LifeDevx))
