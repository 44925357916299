// eslint-disable-next-line import/prefer-default-export
export const moduleList = [
  { value: "community", label: "Community" },
  { value: "co-workers", label: "Co-workers" },
  { value: "freelancers", label: "Freelancers" },
  { value: "cafeteria", label: "Cafeteria" },
  { value: "partners", label: "Partners" },
  { value: "clubs", label: "Clubs" },
  { value: "events", label: "Events" },
  { value: "booking", label: "Booking" },
  { value: "other", label: "Other" },
];
