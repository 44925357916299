import React from "react";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";

import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Button,
} from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { capitalize } from "../../Utils/StringFunctions";
import SelectField from "../../Components/Common/Form/SelectField";
import { getCreditHistory } from "../../Actions/companiesAction";

const moment = require("moment");

const styles = () => ({
  noHistory: {
    padding: "24px 24px 11px",
    color: "lightgrey",
  },
  lightbg: {
    padding: "20px 26px",
    marginBottom: "25px",
    background: "#fff",
    border: "1px solid #EFEFEF",
    marginTop: "40px",
  },
  table: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    color: "red",
  },
  topbar: {
    marginTop: "40px",
    marginLeft: "30%",
    marginRight: "30%",
    padding: "10px",
    width: "412.8px",
    height: "99px",
    background: "#fff",
    border: "1px solid #EFEFEF",
    textAlign: "center",
    color: "#626262",
  },
  add_button: {
    margin: "25px 0px",
    backgroundColor: "#38B11B",
    color: "#fff",
    float: "right",
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "hsl(206, 80%, 97%)",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

class FreelancerCreditHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: "",
    };
  }

  componentDidMount() {
    const data = {
      company_id: this.props.companyID,
      month: moment(),
    };
    if (localStorage.getItem("role") === "admin") {
      this.props.getCreditHistory(data);
    }
    this.setState({
      selectedValue: {
        label: moment().format("MMMM, YYYY"),
        value: moment().format("MMMM, YYYY"),
      },
    });
  }

  setmonth = (callbackmethod, month) => {
    const data = {
      company_id: this.props.companyID,
      month: moment(month.value).add(1, "month").utc().format(),
    };
    this.props.getCreditHistory(data);
    this.setState({
      selectedValue: {
        label: month.label,
        value: month.value,
      },
    });
  };

  render() {
    const { classes } = this.props;

    const dateStart = moment(new Date(2019, 9, 1));
    const dateEnd = moment();
    const timeValues = [];

    while (
      dateEnd > dateStart ||
      dateStart.format("M") === dateEnd.format("M")
    ) {
      timeValues.push({
        label: dateEnd.format("MMMM, YYYY"),
        value: dateEnd.format("MMMM, YYYY"),
      });
      dateEnd.subtract(1, "month");
    }

    return (
      <React.Fragment>
        <div
          style={{
            width: "100%",
          }}
        >
          <Grid
            container
            spacing={1}
            alignItems="center"
            justify="center"
            className={classes.topbar}
          >
            <Grid item xs={4} sm={4}>
              {this.props.credit_history &&
              this.props.credit_history.total_credits
                ? parseFloat(this.props.credit_history.total_credits).toFixed(2)
                : 0}
              <br />
              Total Credits
            </Grid>
            <Grid item xs={4} sm={4}>
              {this.props.credit_history &&
              this.props.credit_history.usedcredits
                ? parseFloat(this.props.credit_history.usedcredits).toFixed(2)
                : 0}
              <br />
              Used Credits
            </Grid>
            <Grid item xs={4} sm={4}>
              {this.props.credit_history
                ? parseFloat(
                    this.props.credit_history.total_credits -
                      this.props.credit_history.usedcredits
                  ).toFixed(2)
                : 0}
              <br />
              Difference
            </Grid>
          </Grid>
        </div>
        <div className={classes.lightbg}>
          <Grid container spacing={2}>
            <Grid item xs={4} sm={4}>
              <SelectField
                value={this.state.selectedValue}
                name="month"
                id="month"
                label="Select Month "
                onChange={(e, data) => {
                  this.setmonth(this.props.setFieldValue, data);
                }}
                multi={false}
                options={timeValues}
              />
            </Grid>
            <Grid item xs={8} sm={8}>
              <Button
                variant="contained"
                className={classes.add_button}
                onClick={this.selectClickPlan}
              >
                Download Invoice
              </Button>
            </Grid>
          </Grid>

          {this.props.credit_history &&
          this.props.credit_history.histroy.length !== 0 ? (
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">No</StyledTableCell>
                  <StyledTableCell align="left">Date / Time</StyledTableCell>
                  <StyledTableCell align="left">Cabin Name</StyledTableCell>
                  <StyledTableCell align="left">Used Credits</StyledTableCell>
                  <StyledTableCell align="left">Booked By</StyledTableCell>
                  <StyledTableCell align="left">Meeting Title</StyledTableCell>
                  <StyledTableCell align="left">Status</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.credit_history &&
                  this.props.credit_history.histroy.length !== 0 &&
                  this.props.credit_history.histroy.map((row, key) => (
                    <TableRow>
                      <StyledTableCell> {key + 1} </StyledTableCell>
                      <StyledTableCell>
                        {" "}
                        {`${moment(row.booking_date).format(
                          "DD/MM/YYYY"
                        )}, ${moment(row.booking_date).format("hh:mm a")}`}{" "}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row.resource_id &&
                          capitalize(row.resource_id.resource_name)}
                      </StyledTableCell>
                      <StyledTableCell>
                        {parseFloat(row.credits_used_for_bookings).toFixed(2)}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row.created_by &&
                          capitalize(
                            `${row.created_by.first_name} ${row.created_by.last_name}`
                          )}
                      </StyledTableCell>
                      <StyledTableCell>{capitalize(row.title)}</StyledTableCell>
                      <StyledTableCell>
                        {capitalize(row.status)}
                      </StyledTableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          ) : (
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item align="center" className={classes.noHistory}>
                <span>No History Found.</span>
              </Grid>
            </Grid>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errors: state.errors,
    credit_history: state.companies.credit_history,
  };
};

export default connect(mapStateToProps, {
  getCreditHistory,
})(withStyles(styles)(withRouter(FreelancerCreditHistory)));
