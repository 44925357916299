/* eslint-disable camelcase */
import React from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { Typography, Grid } from "@material-ui/core";
import hospital from "../../Assets/hospital.png";
import firestation from "../../Assets/firestation.png";
import postoffice from "../../Assets/postoffice.png";
import DataNotFound from "../../Components/Common/DataNotFound";

const styles = () => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "12px",
  },
  mainTitle: {
    fontWeight: "600",
    fontSize: "20px",
    textAlign: "left",
    color: "#222222",
    margin: "0px",
    lineHeight: "25px",
  },
  heading: {
    fontSize: "16px",
    color: "#222222",
    fontWeight: "500",
    margin: "4px 0px",
    marginBottom: "15px",
  },
  title: {
    fontSize: "13px",
    color: "#222222",
    fontWeight: "500",
    paddingBottom: "10px",
  },
  phone: {
    color: "#358CEE",
  },
  description: {
    color: "#222222",
    fontSize: "11px",
    lineHeight: "18px",
  },
  generalDetails: {
    // padding: "3% 0% 0%"
  },
  mainSlot: {
    display: "flex",
    justifyContent: "space-between",
  },
  areaSection: {
    display: "flex",
    flexDirection: "row",
  },
  cardLayout: {
    border: "1px solid #EFEFEF",
    borderRadius: "5px",
    boxShadow: "0 9px 10px rgba(0, 0, 0, 0.1), 0 4px 0px rgba(0,0,0,0.05)",
    padding: "10px",
  },
});

function EmergencyNo(props) {
  const { classes, data } = props;
  if (data === undefined) {
    return (
      <div className={classes.noData}>
        <DataNotFound
          type="visitors"
          text="No results found!"
          subtext=""
          paddingTop="22%"
        />
      </div>
    );
  }
  return (
    <div className={classes.main}>
      <div className={classes.header}>
        <Typography align="left" variant="h1" className={classes.mainTitle}>
          Emergency Numbers
        </Typography>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Typography align="left" className={classes.heading}>
            <img
              src={hospital}
              alt="rules 1"
              className={classes.setupImg}
            ></img>{" "}
            Hospital
          </Typography>

          <Grid container spacing={2}>
            {data && data.emergency && data.emergency.hospital_one ? (
              <Grid item xs={6} className={classes.generalDetails}>
                <Grid className={classes.cardLayout}>
                  <Typography align="left" className={classes.title}>
                    <span className={classes.phone}>
                      {data &&
                        data.emergency &&
                        data.emergency.hospital_one &&
                        data.emergency.hospital_one.name_number}
                    </span>
                  </Typography>
                  <Typography align="left" className={classes.description}>
                    {data &&
                      data.emergency &&
                      data.emergency.hospital_one &&
                      data.emergency.hospital_one.address}
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              ""
            )}
            {data && data.emergency && data.emergency.hospital_two ? (
              <Grid item xs={6} className={classes.generalDetails}>
                <Grid className={classes.cardLayout}>
                  <Typography align="left" className={classes.title}>
                    <span className={classes.phone}>
                      {data &&
                        data.emergency &&
                        data.emergency.hospital_two &&
                        data.emergency.hospital_two.name_number}
                    </span>
                  </Typography>
                  <Typography align="left" className={classes.description}>
                    {data &&
                      data.emergency &&
                      data.emergency.hospital_two &&
                      data.emergency.hospital_two.address}
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              ""
            )}
            {data && data.emergency && data.emergency.hospital_three ? (
              <Grid item xs={6} className={classes.generalDetails}>
                <Grid className={classes.cardLayout}>
                  <Typography align="left" className={classes.title}>
                    <span className={classes.phone}>
                      {data &&
                        data.emergency &&
                        data.emergency.hospital_three &&
                        data.emergency.hospital_three.name_number}
                    </span>
                  </Typography>
                  <Typography align="left" className={classes.description}>
                    {data &&
                      data.emergency &&
                      data.emergency.hospital_three &&
                      data.emergency.hospital_three.address}
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              ""
            )}
            {data && data.emergency && data.emergency.hospital_four ? (
              <Grid item xs={6} className={classes.generalDetails}>
                <Grid className={classes.cardLayout}>
                  <Typography align="left" className={classes.title}>
                    <span className={classes.phone}>
                      {" "}
                      {data &&
                        data.emergency &&
                        data.emergency.hospital_four &&
                        data.emergency.hospital_four.name_number}
                    </span>
                  </Typography>
                  <Typography align="left" className={classes.description}>
                    {data &&
                      data.emergency &&
                      data.emergency.hospital_four &&
                      data.emergency.hospital_four.address}
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        {data && data.emergency && data.emergency.fire_station ? (
          <Grid item xs={12} sm={12}>
            <Typography align="left" className={classes.heading}>
              <img
                src={firestation}
                alt="rules 1"
                className={classes.setupImg}
              ></img>{" "}
              Fire Station
            </Typography>
            <Grid item xs={6} className={classes.generalDetails}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.title}>
                  <span className={classes.phone}>
                    {data &&
                      data.emergency &&
                      data.emergency.fire_station &&
                      data.emergency.fire_station.name_number}
                  </span>
                </Typography>
                <Typography align="left" className={classes.description}>
                  {data &&
                    data.emergency &&
                    data.emergency.fire_station &&
                    data.emergency.fire_station.address}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          ""
        )}
        {data && data.emergency && data.emergency.post_office ? (
          <Grid item xs={12} sm={12}>
            <Typography align="left" className={classes.heading}>
              <img
                src={postoffice}
                alt="rules 1"
                className={classes.setupImg}
              ></img>{" "}
              Post Office
            </Typography>
            <Grid item xs={6} className={classes.generalDetails}>
              <Grid className={classes.cardLayout}>
                <Typography align="left" className={classes.title}>
                  {/* Vastrapur PO:{" "} */}
                  <span className={classes.phone}>
                    {" "}
                    {data &&
                      data.emergency &&
                      data.emergency.post_office &&
                      data.emergency.post_office.name_number}
                  </span>
                </Typography>
                <Typography align="left" className={classes.description}>
                  {data &&
                    data.emergency &&
                    data.emergency.post_office &&
                    data.emergency.post_office.address}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          ""
        )}
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.spaces.loading,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(EmergencyNo));
