/* eslint-disable react/no-unused-state */
import withStyles from "@material-ui/core/styles/withStyles";
import React from "react";
import PropTypes from "prop-types";
import { CssBaseline } from "@material-ui/core";
import { connect } from "react-redux";
import { getCompany } from "../../Actions/companiesAction";
import { getAvailablePlans } from "../../Actions/planActions";
import FreelancerTabs from "./FreelancerTab";
import AboutFreelancerTopDetail from "./AboutFreelancerTopDetail";

const styles = () => ({});

class ViewFreelancer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.state.id = this.props.match.params.id;
  }

  componentDidMount() {
    this.props.getCompany(this.state.id, "freelancer");
    if (localStorage.getItem("role") === "admin") {
      this.props.getAvailablePlans();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.company !== prevState.company) {
      return { company: nextProps.company };
    }
    return [];
  }

  componentDidUpdate(prevProps) {
    if (prevProps.comapny !== this.props.comapny) {
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        comapny: this.props.comapny,
      });
    }
    if (prevProps.add_credit_topup !== this.props.add_credit_topup) {
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        add_credit_topup: this.props.add_credit_topup,
      });
      if (this.props.add_credit_topup.success) {
        this.props.getCompany(this.state.id);
        this.props.getAvailablePlans();
      }
    }
    if (prevProps.deletePlan !== this.props.deletePlan) {
      if (this.props.deletePlan.success) {
        this.props.getCompany(this.state.id);
        this.props.getAvailablePlans();
      }
    }
    if (this.props.associateplan.success) {
      this.setState({
        loading: this.props.loading,
      });
      this.props.getCompany(this.state.id);
      this.props.getAvailablePlans();
    }
    if (prevProps.loading !== this.props.loading) {
      this.setState({
        loading: this.props.loading,
      });
    }
  }

  handleChange = (event, value) => {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ value });
  };

  render() {
    const { classes } = this.props;
    const { company } = this.state;
    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.mainsection}>
          <AboutFreelancerTopDetail company={company} />
          <FreelancerTabs
            company={this.state.company}
            id={this.state.id}
            plans={this.state.plans}
          />
        </div>
      </React.Fragment>
    );
  }
}

ViewFreelancer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    company: state.company.company,
    loading: state.company.loading,
    add_credit_topup: state.company.add_credit_topup,
    deletePlan: state.company.deletePlan,
    associateplan: state.company.associateplan,
    plans: state.plans,
  };
};

export default connect(mapStateToProps, { getCompany, getAvailablePlans })(
  withStyles(styles)(ViewFreelancer)
);
