import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { Grid, Paper, AppBar, Toolbar, Button } from "@material-ui/core";
import SearchBar from "../../Components/Common/SearchBar";
import NoRecordsFound from "../../Components/Common/NoRecordsFound";
import DataNotFound from "../../Components/Common/DataNotFound";
import { CircularLoader } from "../../Components/Common/Spinner";
import { getAllClubs, createClub } from "../../Actions/AllClubActions";
import ScreenHeader from "../../Components/Common/ScreenHeader";
import ClubCard from "../../Components/Common/Cards/ClubCard";
import AddClub from "./AddClub";
import { getClubCategory } from "../../Actions/ClubCategoryAction";

const styles = (theme) => ({
  ScreenHeader: {
    marginLeft: "-20px",
    width: "100%",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginRight: "6px",
    marginLeft: "-2px",
  },
  appbar: {
    backgroundColor: "#ffffff",
    margin: "16px 0px 4px",
  },
  root: {
    width: "100%",
    padding: "0px",
  },
  paper: {
    // marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    flexGrow: 1,
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      // marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  button: {
    // width: "10%",
    marginRight: "1.4%",
    color: "white",
    padding: "5px 16px",
    borderRadius: "4px",
    textDecoration: "none",
    fontSize: "0.875rem",
    fontWeight: "500",
    boxShadow: "rgba(137, 163, 169, 0.3) 0px 3px 5px 2px",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
});

export class AllClubs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clubs: [],
      page: 0,
      noMoreData: false,
      isSearch: false,
      search: "",
      // eslint-disable-next-line react/no-unused-state
      join_status: "no",
      modalOpen: false,
      addclub: true,
    };
  }

  // eslint-disable-next-line react/sort-comp
  searchClub = (search, isSearch) => {
    // eslint-disable-next-line object-shorthand
    this.setState({ isSearch: true, search: search });
    this.props.getAllClubs(this.state.page, search, isSearch);
  };

  componentDidMount() {
    this.props.getAllClubs(this.state.page);
    this.props.getClubCategory();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.noMoreData !== prevState.noMoreData) {
      return {
        noMoreData: nextProps.noMoreData,
      };
    }
    if (nextProps.clubs !== prevState.clubs) {
      return { clubs: nextProps.clubs, loading: nextProps.loading };
    }
    return [];
  }

  componentDidUpdate(prevProps) {
    if (prevProps.clubs !== this.props.clubs) {
      this.setState({
        clubs: this.props.clubs,
      });
    }
    if (prevProps.loading !== this.props.loading) {
      this.props.setLoading(this.props.loading);
      // this.setState({
      //   loading: this.props.loading
      // });
    }
    if (prevProps.join_status !== this.props.join_status) {
      this.props.getAllClubs();
    }
    if (prevProps.deleteClub !== this.props.deleteClub) {
      this.props.getAllClubs();
    }
    if (prevProps.editClub !== this.props.editClub) {
      this.props.getAllClubs();
    }
    if (this.props.createClub !== prevProps.createClub)
      if (this.props.createClub && this.props.createClub.success) {
        this.props.getAllClubs();
      }
  }

  handleChange(e) {
    const { target } = e;
    const { name } = target;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.showLoadingOverlay();
  };

  handleClickOpen = (clubadd) => {
    this.setState({
      modalOpen: true,
    });
    this.setState({
      addclub: clubadd,
    });
  };

  handleClose = () => {
    this.setState({
      modalOpen: false,
    });
    this.setState({
      addclub: true,
    });
    this.props.getAllClubs();
  };

  handleModelOpen = (editingClub) => {
    this.setState({ modalOpen: true, editingClub });
  };

  render() {
    const { classes, error, clublist } = this.props;
    const { clubs, isSearch, search, editingClub } = this.state;

    // const id = localStorage.getItem("space_id");
    if (this.props.loading) {
      return <CircularLoader />;
    }
    if (
      (clubs && clubs.length <= 0 && !isSearch) ||
      (error && error.data === "No data found")
    ) {
      return (
        <DataNotFound
          type="allclubs"
          text="You don't have any club!!"
          subtext=""
          paddingTop="19%"
          subComponent={
            <div>
              <Button
                onClick={() => this.handleClickOpen(false)}
                variant="contained"
                color="primary"
                className={classes.button}
              >
                Add&nbsp;Club
              </Button>
              <AddClub
                open={this.state.modalOpen}
                handleClose={this.handleClose}
                addclub={this.state.addclub}
                clublist={clublist}
              />
            </div>
          }
        />
      );
    }
    return (
      <React.Fragment>
        <div className={classes.header}>
          <ScreenHeader type="All Clubs " />
          <Button
            onClick={() => this.handleClickOpen(false)}
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Add&nbsp;Club
          </Button>
          <AddClub
            open={this.state.modalOpen}
            handleClose={this.handleClose}
            editingClub={editingClub}
            addclub={this.state.addclub}
            clublist={clublist}
          />
        </div>
        <div className={classes.root}>
          <AppBar position="static" className={classes.appbar}>
            <Toolbar>
              <SearchBar type="Search Clubs" onSearch={this.searchClub} />
              <div className={classes.grow} />
            </Toolbar>
          </AppBar>
        </div>
        {clubs && clubs.length <= 0 && isSearch && (
          <NoRecordsFound text={`No records found for your text "${search}"`} />
        )}
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            {/* Hero unit */}
            <Grid container spacing={2}>
              {clubs &&
                clubs.map((element) => {
                  return (
                    <Grid item lg={4} xs={12} sm={6} key={element._id}>
                      <ClubCard
                        data={element}
                        history={this.props.history}
                        openEditModal={this.handleModelOpen}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    clubs: state.clubs.allclubs,
    loading: state.clubs.loading,
    noMoreData: state.clubs.noMoreData,
    join_status: state.clubs.join_status,
    deleteClub: state.clubs.deleteClub,
    editClub: state.clubs.editClub,
    createClub: state.clubs.createClub,
    error: state.clubs.error,
    clublist: state.club_categories.club_categories,
  };
};

export default connect(mapStateToProps, {
  getAllClubs,
  createClub,
  getClubCategory,
})(withStyles(styles)(AllClubs));
