import React from "react";
import Linkify from "linkifyjs/react";

const LinkifyString = (str) => {
  var options = {
    /* … */
  };

  return (
    <Linkify tagName="p" options={options}>
      {str}
    </Linkify>
  );
};

export default LinkifyString;
