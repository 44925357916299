/* eslint-disable react/no-unused-state */
import React from "react";
import {
  withStyles,
  Paper,
  Button,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { connect } from "react-redux";
import DataNotFound from "../../Components/Common/DataNotFound";
import { CircularLoader } from "../../Components/Common/Spinner";
import { capitalize, spacecapitalize } from "../../Utils/StringFunctions";
import { getMerchandisePoint } from "../../Actions/merchandiseActions";
import Cell from "../../Components/Common/Table/TableCell";
import TableHeader from "../../Components/Common/Table/TableHeader";
import Pagination from "../../Components/Common/Table/TablePagination";
import AddMerchandisePointModal from "./AddMerchandisePointModal";

const moment = require("moment");

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    flexGrow: 1,
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      padding: theme.spacing(3),
    },
  },
  coworkerCardContainer: {
    margin: "4px 0px",
  },
  addcoworker: {
    color: "#fff",
    padding: "7px 16px",
    borderRadius: "4px",
    textDecoration: "none",
    fontSize: "0.875rem",
    fontWeight: "500",
    marginLeft: "18px",
    boxShadow: "rgba(137, 163, 169, 0.3) 0px 3px 5px 2px",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  buttonSection: {
    display: "flex",
    padding: "24px 29px 0px 0px",
    justifyContent: "flex-end",
  },
});

class ViewMerchandisePoint extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addPointModalOpen: false,
      page: 0,
      rowsPerPage: 10,
    };
  }

  componentDidMount() {
    this.props.getMerchandisePoint();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.merchandisePointAdd !== prevProps.merchandisePointAdd &&
      this.props.merchandisePointAdd.success
    ) {
      this.props.getMerchandisePoint();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.noMoreData !== prevState.noMoreData) {
      return {
        noMoreData: nextProps.noMoreData,
      };
    }
    if (nextProps.allMerchandisePoint !== prevState.allMerchandisePoint) {
      return {
        allMerchandisePoint: nextProps.allMerchandisePoint,
      };
    }
    return [];
  }

  handleOpen = () => {
    this.setState({ addPointModalOpen: true });
  };

  handleClose = () => {
    this.setState({ addPointModalOpen: false });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  renderHead = () => {
    return (
      <TableHead>
        <TableRow>
          <TableHeader width="5%">Sr. No.</TableHeader>
          <TableHeader width="9%">Date </TableHeader>
          <TableHeader width="10%">Company Name</TableHeader>
          <TableHeader width="10%"> Name</TableHeader>
          <TableHeader width="8%">Points</TableHeader>
          <TableHeader width="8%">Remarks</TableHeader>
        </TableRow>
      </TableHead>
    );
  };

  renderBody = () => {
    const { classes, allMerchandisePoint } = this.props;
    const { page, rowsPerPage } = this.state;

    return (
      <TableBody>
        {allMerchandisePoint &&
          allMerchandisePoint
            //   // Pagination
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((item, id) => {
              return (
                <TableRow key={id} className={classes.root1}>
                  <Cell width="5%">{page * rowsPerPage + id + 1}</Cell>
                  <Cell width="9%">
                    {moment(item.createdAt).format("DD/MM/YYYY ")}
                  </Cell>
                  <Cell width="10%" capitalize>
                    {capitalize(
                      `${
                        item.user_id &&
                        item.user_id.company_id &&
                        item.user_id.company_id.company_name
                      }`
                    )}
                  </Cell>
                  <Cell width="10%">
                    {spacecapitalize(
                      `${item.user_id && item.user_id.first_name} ${
                        item.user_id && item.user_id.last_name
                      }`
                    )}
                  </Cell>
                  <Cell width="10%">{item.amount}</Cell>
                  <Cell width="8%" style={{ justifyContent: "center" }}>
                    {capitalize(item.note)}
                  </Cell>
                </TableRow>
              );
            })}
      </TableBody>
    );
  };

  render() {
    const { classes, allMerchandisePoint } = this.props;
    const { loading } = this.state;

    const itemLength = allMerchandisePoint ? allMerchandisePoint.length : 0;

    if (loading) {
      return <CircularLoader />;
    }

    // if (allMerchandisePoint && allMerchandisePoint.length <= 0) {
    //   return (
    //     <DataNotFound
    //       type="coworker"
    //       text="No history found!!"
    //       subComponent={
    //         <div className={classes.DataNotFound}>
    //           {/* {checkPermission("COWORKER_CREATE") && (
    //             <Link to="/co-worker/add" className={classes.addcoworker}>
    //               Add Co-worker
    //             </Link>
    //           )} */}
    //         </div>
    //       }
    //     />
    //   );
    // }
    return (
      <React.Fragment>
        <main className={classes.layout}>
          <div className={classes.buttonSection}>
            <Button
              color="primary"
              size="small"
              onClick={this.handleOpen}
              className={classes.addcoworker}
            >
              Add Merchandise Point
            </Button>
          </div>

          {allMerchandisePoint && allMerchandisePoint.length <= 0 && (
            <>
              <DataNotFound
                type="coworker"
                text="No history found!!"
                subComponent={<div className={classes.DataNotFound}></div>}
              />
            </>
          )}

          <main className={classes.layout}>
            <Paper className={classes.paper}>
              <div>
                {allMerchandisePoint && allMerchandisePoint.length > 0 && (
                  <Table className={classes.table}>
                    {this.renderHead()}
                    {this.renderBody()}
                  </Table>
                )}
              </div>
            </Paper>
          </main>
          {allMerchandisePoint && allMerchandisePoint.length > 0 && (
            <Pagination
              count={itemLength}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          )}
        </main>
        <AddMerchandisePointModal
          openAddModal={this.state.addPointModalOpen}
          closeAddModal={this.handleClose}
          visitorID={this.state.walkthroughID ? this.state.walkthroughID : ""}
          //  eventData={eventData}
        />
      </React.Fragment>
    );
  }
}

ViewMerchandisePoint.propTypes = {};

const mapStateToProps = (state) => {
  return {
    allMerchandisePoint: state.allMerchandiseOrder.allMerchandisePoint,
    // loading: state.coworkers.loading,
    noMoreData: state.coworkers.noMoreData,
    merchandisePointAdd: state.allMerchandiseOrder.merchandisePointAdd,
  };
};

export default connect(mapStateToProps, { getMerchandisePoint })(
  withStyles(styles)(ViewMerchandisePoint)
);
