/* eslint-disable react/require-default-props */
import React from "react";
import propTypes from "prop-types";
import { ChevronLeft, ChevronRight } from "react-feather";

const styles = {
  wrapper: {
    position: "absolute",
    width: "100%",
    zIndex: "100",
    bottom: "0",
    textAlign: "center",
  },
  btn: {
    width: "40px",
    height: "40px",
    cursor: "pointer",
    userSelect: "none",
    position: "absolute",
    bottom: "200",
    font: "16px/30px sans-serif",
    // color: "rgba(255,255,255,0.8)",
    color: "#aeaeae",
    "&:hover": {
      color: "white",
    },
  },
  left: {
    left: "0",
  },
  right: {
    right: "0",
  },
};

export default function Buttons(props) {
  const prevBtnStyle = Object.assign({}, styles.btn, styles.left);
  const nextBtnStyle = Object.assign({}, styles.btn, styles.right);
  const { index, total, loop, prevHandler, nextHandler } = props;
  return (
    <div style={styles.wrapper}>
      {(loop || index !== 0) && (
        // <div style={prevBtnStyle} onClick={prevHandler}>
        //   ◀
        // </div>
        <ChevronLeft
          style={prevBtnStyle}
          size={18}
          onClick={prevHandler}
        ></ChevronLeft>
      )}
      {(loop || index !== total - 1) && (
        // <div style={nextBtnStyle} onClick={nextHandler}>
        //   ▶
        // </div>
        <ChevronRight
          style={nextBtnStyle}
          size={18}
          onClick={nextHandler}
        ></ChevronRight>
      )}
    </div>
  );
}

Buttons.propTypes = {
  index: propTypes.number.isRequired,
  total: propTypes.number.isRequired,
  prevHandler: propTypes.func,
  nextHandler: propTypes.func,
};
