import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { checkPermission } from "../../Utils/checkPermission";
import { getSpaces } from "../../Actions/spaceActions";
import SpaceCard from "../../Components/Common/Cards/SpaceCard";
import ScreenHeader from "../../Components/Common/ScreenHeader";

const styles = (theme) => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    backgroundColor: "white",
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    flexGrow: 1,
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  cardContent: {
    flexGrow: 1,
    textAlign: "left",
    padding: "0px",
    paddingTop: "5px",
  },
  containerPaper: {
    border: `1px solid ${theme.palette.border.color}`,
  },
  profileImage: {
    display: "flex",
    flexDirection: "row",
    height: 425,
    justifyContent: "center",
  },
  fab: {
    margin: "25px",
    width: 150,
    height: 150,
    backgroundColor: "#f5f5f5",
  },
  button: {
    fontSize: "1.4375rem",
    color: "#4682B4",
  },
  image: {
    marginTop: "40px",
  },
  backButton: {
    margin: "0% 0% 2% 0%",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  ScreenHeader: {
    marginLeft: "0px",
    width: "100%",
  },
  addSpacebutton: {
    width: "100%",
    textAlign: "right",
    marginRight: "30px",
  },
  Spacebutton: {
    color: "#fff",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
});

class AdminSpaces extends React.Component {
  constructor(props) {
    super(props);
    this.state = { spaces: [] };
  }

  componentDidMount() {
    this.props.getSpaces();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.spaces !== prevState.spaces) {
      return { spaces: nextProps.spaces };
    }
    return [];
  }

  componentDidUpdate(prevProps) {
    if (prevProps.spaces !== this.props.spaces) {
      this.setState({
        spaces: this.props.spaces,
      });
    }
  }

  render() {
    const { classes } = this.props;
    const { spaces } = this.state;
    return (
      <React.Fragment>
        <CssBaseline />

        <main className={classes.layout}>
          <Button
            color="grey"
            onClick={() => this.props.history.push("/settings")}
            className={classes.backButton}
            variant="contained"
            size="small"
          >
            Back
          </Button>
          <div className={classes.header}>
            <div className={classes.ScreenHeader}>
              <ScreenHeader type="All Spaces" />
            </div>

            <div className={classes.addSpacebutton}>
              {checkPermission("SPACE_CREATE") && (
                <Button
                  component={Link}
                  to="/space/add"
                  variant="contained"
                  color="primary"
                  className={classes.Spacebutton}
                >
                  Add Space
                </Button>
              )}
            </div>
          </div>
          <Paper className={classes.paper}>
            {/* Hero unit */}
            <Grid container spacing={2}>
              {spaces &&
                spaces.map((element) => {
                  return (
                    <Grid item xs={3} sm={4} lg={4} key={element.id}>
                      <SpaceCard data={element} />
                    </Grid>
                  );
                })}
            </Grid>
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    spaces: state.spaces.spaces,
  };
};

export default connect(mapStateToProps, { getSpaces })(
  withStyles(styles)(AdminSpaces)
);
