/* eslint-disable no-unused-vars */
import axios from "axios";
import {
  GET_EVENTS,
  EVENTS_LOADING,
  GET_EVENT,
  CREATE_EVENT,
  EVENT_PARTICIPANT,
  EVENT_ERROR,
  CHANGE_PARTICIPANT_STATUS,
  GET_EVENT_DISCUSSION,
  TEAM_ERROR,
  GET_ALL_TEAM,
  GET_TEAM_ICON,
  GET_ONE_TEAM,
  CREATE_TEAM,
  GET_TEAM_ERROR,
} from "./types";
import { showSuccess, showError } from "./ActionsHelper";
import { transformErrorsFromApi } from "./TransformError";

// Profile Loading
export const setEventsLoading = () => {
  return {
    type: EVENTS_LOADING,
  };
};

export const getEvents = (page, search, type) => (dispatch) => {
  dispatch(setEventsLoading());
  let url = `/api/event?type=${type}`;
  if (search) {
    url = `/api/event?q=${search}&type=${type}`;
  }

  axios
    .get(url)
    .then((res) => {
      dispatch({
        type: GET_EVENTS,
        reset: page ? page === 0 : false,
        payload: res.data,
        eventType: type,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_EVENTS,
        payload: err && err.response ? err.response.data : "",
      })
    );
};

export const getEvent = (id) => (dispatch) => {
  dispatch(setEventsLoading());
  axios
    .get(`/api/event/getone/${id}`)
    .then((res) => {
      dispatch({
        type: GET_EVENT,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_EVENT,
        payload: {},
      })
    );
};

export const registerEvent = (data, setErrors, setSubmitting, history) => (
  dispatch
) => {
  dispatch(setEventsLoading());
  axios
    .post("/api/event", data)
    .then((result) => {
      dispatch({
        type: CREATE_EVENT,
        payload: result.data,
      });
      history.push("/events");
      dispatch(showSuccess("Event"));
    })
    .catch((err) => {
      const error = err.response;
      dispatch({ type: EVENT_ERROR, payload: error.data });
      dispatch(showError(err.response.data.data));
      setSubmitting(false);
      // setErrors(transformErrorsFromApi(error));
    });
};

// eslint-disable-next-line camelcase
export const eventParticipants = (event_id, history, redirect) => (
  dispatch
) => {
  const data = {};
  // eslint-disable-next-line camelcase
  data.event_id = event_id;
  // dispatch(setEventsLoading());
  data.user_id = localStorage.getItem("user_id");
  data.company_id = localStorage.getItem("company_id");

  axios
    .post("/api/eventparticipants/", data)
    .then((result) => {
      dispatch({
        type: EVENT_PARTICIPANT,
        payload: result.data,
      });
      if (redirect) {
        history.push("/events");
      }
      dispatch(showSuccess("Registration"));
    })
    .catch((err) => {
      const error = err.response;
      dispatch({ type: EVENT_ERROR, payload: error.data });
      dispatch(showError("There was some error registering in an event!"));
    });
};

export const updateEvent = (id, data, setErrors, setSubmitting, history) => (
  dispatch
) => {
  dispatch(setEventsLoading());
  axios
    .put(`/api/event/${id}`, data)
    .then((res) => {
      history.push("/events");
      dispatch(showSuccess(" ", "Event updated successfully!"));
    })
    .catch((err) => {
      const error = err.response;
      // dispatch({ type: EVENT_ERROR, payload: error.data });
      setSubmitting(false);
      setErrors(transformErrorsFromApi(error));
    });
};

export const changeParticipantStatus = (id, userData, setSubmitting) => (
  dispatch
) => {
  dispatch(setEventsLoading());
  axios

    .put(`/api/eventparticipants/${id}`, userData)
    .then((result) => {
      dispatch({
        type: CHANGE_PARTICIPANT_STATUS,
        payload: result.data,
      });
      dispatch(showSuccess("", "Status updated successfully!"));
      setSubmitting(false);
    })
    .catch((err) =>
      dispatch({
        type: EVENT_ERROR,
        // payload: err.response.data
        payload: ((err || {}).response || {}).data || "Error unexpected",
      })
    );
};

export const getEventDiscussion = (id) => (dispatch) => {
  dispatch(setEventsLoading());
  axios
    .get(`/api/event/discussion/getpost/${id}`)
    .then((res) => {
      dispatch({
        type: GET_EVENT_DISCUSSION,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_EVENT_DISCUSSION,
        payload: {},
      })
    );
};

export const getAllTeam = (id, type, myteam) => (dispatch) => {
  // dispatch(setEventsLoading());
  let url = `/api/eventparticipants/eventteam/team/${id}`;
  if (type) {
    url = `/api/eventparticipants/eventteam/team/${id}?q=${type}`;
  } else if (myteam) {
    url = `/api/eventparticipants/eventteam/team/${id}?q=${myteam}`;
  }
  axios
    .get(url)
    .then((res) => {
      dispatch({
        type: GET_ALL_TEAM,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_TEAM_ERROR,
        payload: err && err.response ? err.response.data.data : "",
      })
    );
};

export const getOneTeam = (id) => (dispatch) => {
  // dispatch(setEventsLoading());
  axios
    .get(`/api/eventparticipants/getone/${id}`)
    .then((res) => {
      dispatch({
        type: GET_ONE_TEAM,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: TEAM_ERROR,
        payload: {},
      })
    );
};

export const getTeamIcon = (id) => (dispatch) => {
  // dispatch(setEventsLoading());
  axios
    .get(`/api/eventparticipants/availabeicon/${id}`)
    .then((res) => {
      dispatch({
        type: GET_TEAM_ICON,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: TEAM_ERROR,
        payload: {},
      })
    );
};

export const createTeam = (data, setSubmitting) => (dispatch) => {
  const { type } = data.type;
  axios
    .post(`/api/eventparticipants/createteam`, data)
    .then((result) => {
      dispatch({
        type: CREATE_TEAM,
        payload: result.data,
      });
      // history.push("/events");
      dispatch(showSuccess("Registration"));
    })
    .catch((err) => {
      const error = err.response;
      dispatch({ type: TEAM_ERROR, payload: error && error.data });

      if (type === "create") {
        setSubmitting(false);
      } else return null;
      dispatch(showError("There was some error create a team in an event!"));
      // setErrors(transformErrorsFromApi(error));
    });
};
