/* eslint-disable no-param-reassign */
import React from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { EditorState } from "draft-js";
import { Grid, FormLabel } from "@material-ui/core";
import { InputField, UploadField } from "../../Components/Common/Form";
import RichEditorExample from "../../Components/Common/RichEditor";

const styles = () => ({
  main: {
    backgroundColor: "#fff",
  },
  label: {
    margin: "15px 0px 8px 0px",
    fontSize: "12px",
    fontWeight: "700",
  },
  descriptionLabel: {
    margin: "15px 0px 8px 0px",
    fontSize: "12px",
    fontWeight: "400",
    color: "rgba(0, 0, 0, 0.54)",
  },
});

const categorySchema = Yup.object().shape({
  // description:Yup.string().required("This field is required."),
});
const CustomPushNotification = (props) => {
  const { classes } = props;

  return (
    <React.Fragment>
      <div className={classes.main}>
        <div className={classes.header}>
          <Formik
            initialValues={{
              banner: "",
              button_link: "",
              button_label: "",
              // eslint-disable-next-line new-cap
              editorState: new EditorState.createEmpty(),
              description: "",
            }}
            validationSchema={categorySchema}
            // eslint-disable-next-line no-unused-vars
            onSubmit={(values) => {
              // eslint-disable-next-line no-param-reassign
            }}
          >
            {({ values, setFieldValue }) => (
              <Form className={classes.plan_form_modal}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <UploadField
                      name="banner"
                      label="Upload banner "
                      id="banner"
                      onChange={setFieldValue}
                      value={values.banner}
                      folder="events/banner"
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <InputField
                      name="button_link"
                      label="Button Link"
                      placeholder="Enter Link"
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <InputField
                      name="button_label"
                      label="Button Label"
                      placeholder="Button Label"
                      maxLength="50"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormLabel
                      className={classes.descriptionLabel}
                      component="legend"
                    >
                      Content
                    </FormLabel>
                    <RichEditorExample
                      name="description"
                      editorState={values.editorState}
                      onChange={setFieldValue}
                      placeholder="Type your text"
                    />
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = () => {
  return {};
};

export default connect(
  mapStateToProps,
  {}
)(withStyles(styles)(CustomPushNotification));
