import {
  GET_FOOD_CATEGORY,
  ADD_FOOD_CATEGORY,
  FOOD_CATEGORY_LOADING,
  FOOD_CATEGORY_ERROR,
  GET_SINGLE_FOOD_CATEGORY,
  EDIT_FOOD_CATEGORY
} from "../Actions/types";

const initialState = {
  food_category: null,
  all_food_category: null,
  loading: false,
  edit_food_category: {},
  single_food_category: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_FOOD_CATEGORY:
      return {
        ...state,
        loading: false,
        all_food_category: action.payload && action.payload.data
      };
    case ADD_FOOD_CATEGORY:
      return {
        ...state,
        loading: false,
        food_category: {
          success: true,
          data: action.payload && action.payload.data
        }
      };
    case FOOD_CATEGORY_LOADING:
      return {
        ...state,
        loading: true
      };
    case FOOD_CATEGORY_ERROR:
      return {
        ...state,
        loading: false,
        food_category: {
          success: false,
          data: action.payload && action.payload.data
        }
      };
    case GET_SINGLE_FOOD_CATEGORY:
      return {
        ...state,
        loading: false,
        single_food_category: action.payload && action.payload.data
      };
    case EDIT_FOOD_CATEGORY:
      return {
        ...state,
        loading: false,
        edit_food_category: {
          success: true,
          data: action.payload && action.payload.data
        }
      };
    default:
      return state;
  }
}
