/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/no-unused-state */
/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
import React from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router-dom";
import {
  Button,
  CssBaseline,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Formik, Form } from "formik";
import { getCreditAccounts } from "../../Actions/CreditAccountActions";
import { capitalize } from "../../Utils/StringFunctions";
import SelectField from "../../Components/Common/Form/SelectField";
import ScreenHeader from "../../Components/Common/ScreenHeader";
import { addOrder } from "../../Actions/OrderActions";

const $quoteSym = " ";
const styles = () => ({
  main: {
    padding: "24px",
    paddingTop: "0px",
  },
  table: {
    marginTop: "30px",
  },
  pageHeader: {
    marginLeft: "-26px",
  },
  applicationHeader: {
    margin: "20px 0px",
    paddingBottom: "5px",
    fontSize: "0.875rem",
    fontWeight: "400",
    lineHeight: "1.43",
    position: "relative",
    "&:after": {
      top: "22px",
      left: "1px",
      width: "24px",
      borderBottom: "3px solid #fb0",
      content: `'${$quoteSym}'`,
      position: "absolute",
    },
  },
  addOrderButton: {
    color: "#fff",
    padding: "7px 16px",
    borderRadius: "4px",
    textDecoration: "none",
    fontSize: "0.875rem",
    fontWeight: "500",
    marginLeft: "18px",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  confirmOrder: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "50px",
  },
  total: {
    color: "blue",
  },
  totalPrice: {
    fontWeight: "600",
  },
  root1: {
    "&:nth-child(even)": {
      backgroundColor: "#FCFDFE",
    },
    verticalAlign: "baseline",
  },
  cancelButton: {
    width: "130px",
    height: "40px",
  },
  customize: {
    textTransform: "capitalize",
    color: "blue",
    fontSize: "11px",
    lineHeight: "15px",
  },
  customizeOptionText: {
    color: "#222222",
    fontSize: "12px",
  },
  pointer: {
    cursor: "pointer",
  },
});
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F8FAFF",
    color: theme.palette.common.black,
    borderBottom: "none",
    padding: "8px 40px 8px 6px",
  },
  body: {
    fontSize: 14,
    textTransform: "capitalize",
    borderBottom: "none",
    padding: "14px 10px 14px 8px",
  },
}))(TableCell);

class ViewAllOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      selectedItems: [],
      cheeseTotal: 0,
      butterTotal: 0,
      updatedArray: [],
      creditAccounts: [],
      creditAccount: "",
      itemID: "",
      customizeOptionDisplay: false,
    };
  }

  componentDidMount() {
    if (localStorage.getItem("role") === "cafe") {
      this.props.getCreditAccounts();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.creditAccount !== prevState.creditAccount) {
      const creditAccounts =
        (nextProps.creditAccount &&
          nextProps.creditAccount.map((account) => {
            return {
              value: account.user_id,
              label: `${account.first_name && capitalize(account.first_name)} ${
                account.last_name && capitalize(account.last_name)
              }`,
            };
          })) ||
        [];
      return { creditAccounts };
    }
    return [];
  }

  setFieldValue = (name, val) => {
    this.setState({ creditAccount: val });
  };

  openOptions = (id) => {
    const { customizeOptionDisplay } = this.state;
    this.setState({ itemID: id });
    this.setState({
      customizeOptionDisplay: !customizeOptionDisplay,
    });
  };
  // detailOpen = () => {
  //   this.setState({
  //     orderDetail: !this.state.orderDetail
  //   });
  // };

  SubTotal = () => {
    const { total } = this.state;
    const { quantity } = this.props.location.state.data;
    const { price } = this.props.location.state.data;
    this.setState({
      total: total + quantity * price,
    });
  };

  confirmOrder = () => {
    const values = this.props.location.state.data;
    let copyData = {};
    copyData = values;
    copyData.map((item) => {
      // eslint-disable-next-line no-param-reassign
      item.food_item = item._id;
      delete item.is_jain;
      delete item.is_cheese;
      delete item.is_butter;
      this.state.updatedArray.map((data) => {
        if (data.id === item._id) {
          data.name.map((type) => {
            if (type === "is_cheese") {
              item.is_cheese = true;
            } else if (type === "is_butter") {
              item.is_butter = true;
            } else if (type === "is_jain") {
              item.is_jain = true;
            }
            return null;
          });
        }
        return null;
      });
      return null;
    });
    const apiData = {
      order: copyData,
      user_id: this.state.creditAccount.value,
    };
    this.props.addOrder(apiData, this.props.history);
  };

  mergeObject = () => {
    const { selectedItems } = this.state;
    const output = [];

    selectedItems.forEach(function (item) {
      const existing = output.filter(function (v) {
        return v.id === item.id;
      });
      if (existing.length) {
        const existingIndex = output.indexOf(existing[0]);
        output[existingIndex].name = output[existingIndex].name.concat(
          item.name
        );
      } else {
        if (typeof item.name === "string") {
          // eslint-disable-next-line no-param-reassign
          item.name = [item.name];
        }
        output.push(item);
      }
    });
    this.setState({ updatedArray: output });
  };

  handleChangeJain = (event, type, itemVal) => {
    this.setState({
      index: this.state.selectedItems.findIndex((obj) => obj.id === itemVal),
    });
    const { selectedItems } = this.state;
    if (event.target.checked) {
      if (type === "jain") {
        selectedItems.push({
          id: itemVal._id,
          name: event.target.name,
          checked: event.target.checked || false,
        });
      } else {
        return null;
      }
    } else {
      const index = selectedItems.findIndex((v) => v.id === event.target.value);
      if (index !== -1) {
        selectedItems.splice(index, 1);
      }
    }
    this.setState({ selectedItems });
    this.mergeObject();
    return null;
  };

  handleChange = (event, type, itemVal) => {
    const { selectedItems } = this.state;
    let { cheeseTotal, butterTotal } = this.state;
    if (event.target.checked) {
      if (selectedItems.some((item) => item.id !== event.target.value)) {
        if (type === "cheese") {
          cheeseTotal +=
            Number(itemVal.cheese_price) * Number(itemVal.quantity);
          this.setState({ cheeseTotal });
          selectedItems.push({
            id: event.target.value,
            name: event.target.name,
          });
        } else if (type === "jain") {
          // cheeseTotal += 0;
          // this.setState({ cheeseTotal });
          selectedItems.push({
            id: itemVal._id,
            name: event.target.name,
          });
        } else {
          butterTotal +=
            Number(itemVal.butter_price) * Number(itemVal.quantity);
          this.setState({ butterTotal });
          selectedItems.push({
            id: event.target.value,
            name: event.target.name,
          });
        }
      } else if (type === "cheese") {
        cheeseTotal += Number(itemVal.cheese_price) * Number(itemVal.quantity);
        this.setState({ cheeseTotal });
        selectedItems.push({
          id: event.target.value,
          name: event.target.name,
        });
      } else if (type === "jain") {
        // cheeseTotal += 0;
        // this.setState({ cheeseTotal });
        selectedItems.push({
          id: itemVal._id,
          name: event.target.name,
        });
      } else {
        butterTotal += Number(itemVal.butter_price) * Number(itemVal.quantity);
        this.setState({ butterTotal });
        selectedItems.push({
          id: event.target.value,
          name: event.target.name,
        });
      }
    } else {
      if (type === "cheese") {
        cheeseTotal -= Number(itemVal.cheese_price) * Number(itemVal.quantity);
        this.setState({ cheeseTotal });
        // eslint-disable-next-line no-empty
      } else if (type === "jain") {
      } else {
        butterTotal -= Number(itemVal.butter_price) * Number(itemVal.quantity);
        this.setState({ butterTotal });
      }

      const index = selectedItems.findIndex((v) => v.id === event.target.value);
      if (index !== -1) {
        selectedItems.splice(index, 1);
      }
    }
    this.setState({ selectedItems });
    this.mergeObject();
  };

  checkedbox = (id, value) => {
    // const index = this.state.selectedItems.filter((v) => v.id === id);
    return this.state.selectedItems.some((item) => {
      if (item.id === id) {
        return item.name.includes(value);
        // eslint-disable-next-line no-else-return
      } else {
        return false;
      }
    });
  };

  render() {
    const { classes } = this.props;
    const { cheeseTotal, butterTotal, customizeOptionDisplay } = this.state;
    const role = localStorage.getItem("role");
    let total = 0;
    // const check = this.state.selectedItems[this.state.index].checked
    return (
      <React.Fragment>
        <CssBaseline />

        <div className={classes.main}>
          <div className={classes.header}>
            <div className={classes.pageHeader}>
              <ScreenHeader type="View All Orders" />
              {/* <span className={classes.applicationHeader}>View All Order</span> */}
            </div>
          </div>
          <div className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Sr. No.</StyledTableCell>
                  <StyledTableCell align="left">Food Item</StyledTableCell>
                  <StyledTableCell align="left">Quantity</StyledTableCell>
                  <StyledTableCell align="left">Price</StyledTableCell>
                  <StyledTableCell align="left">Total</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.location.state &&
                  this.props.location.state.data &&
                  this.props.location.state.data.map((item, id) => {
                    total += item.quantity * item.price;

                    return (
                      <TableRow className={classes.root1}>
                        <StyledTableCell component="th" scope="row">
                          {id + 1}
                        </StyledTableCell>
                        <StyledTableCell align="left" onClick={this.detailOpen}>
                          {item.food_name}
                          <Formik
                            enableReinitialize
                            // initialValues={this.geIntialValues(id)}
                            onSubmit={() => {}}
                          >
                            {() => (
                              <Form className={classes.form} autoComplete="off">
                                {(item.is_jain ||
                                  item.is_cheese ||
                                  item.is_butter) && (
                                  <Grid
                                    onClick={() => this.openOptions(item._id)}
                                    className={classes.pointer}
                                  >
                                    <span className={classes.customize}>
                                      Customize
                                    </span>
                                  </Grid>
                                )}
                                {customizeOptionDisplay &&
                                  item._id === this.state.itemID && (
                                    <Grid className={classes.foodType}>
                                      {item.is_jain && (
                                        // <RadioField
                                        //   name="is_jain"
                                        //   id="is_jain"
                                        //   options={foodTypeOptions}
                                        //   color="primary"
                                        //   onChange={(e) =>
                                        //     this.handleChangeJain(e, "jain", item)
                                        //   }
                                        //   className={classes.customizeOptionText}
                                        // />
                                        <FormControlLabel
                                          className={
                                            classes.customizeOptionText
                                          }
                                          control={
                                            <Checkbox
                                              name="is_jain"
                                              checked={this.checkedbox(
                                                item._id,
                                                "is_jain"
                                              )}
                                              onChange={(e) =>
                                                this.handleChange(
                                                  e,
                                                  "jain",
                                                  item
                                                )
                                              }
                                              value={item._id}
                                              color="primary"
                                            />
                                          }
                                          label="Jain"
                                        />
                                      )}
                                      {item.is_cheese && (
                                        <Grid>
                                          <FormControlLabel
                                            className={
                                              classes.customizeOptionText
                                            }
                                            control={
                                              <Checkbox
                                                name="is_cheese"
                                                checked={this.checkedbox(
                                                  item._id,
                                                  "is_cheese"
                                                )}
                                                onChange={(e) =>
                                                  this.handleChange(
                                                    e,
                                                    "cheese",
                                                    item
                                                  )
                                                }
                                                value={item._id}
                                                color="primary"
                                              />
                                            }
                                            label="Cheese"
                                          />
                                          <span>Rs. {item.cheese_price}</span>
                                        </Grid>
                                      )}
                                      {item.is_butter && (
                                        <Grid>
                                          <FormControlLabel
                                            className={
                                              classes.customizeOptionText
                                            }
                                            control={
                                              <Checkbox
                                                name="is_butter"
                                                checked={this.checkedbox(
                                                  item._id,
                                                  "is_butter"
                                                )}
                                                onChange={(e) =>
                                                  this.handleChange(
                                                    e,
                                                    "butter",
                                                    item
                                                  )
                                                }
                                                value={item._id}
                                                color="primary"
                                              />
                                            }
                                            label="Butter"
                                          />
                                          <span>Rs. {item.butter_price}</span>
                                        </Grid>
                                      )}
                                    </Grid>
                                  )}
                              </Form>
                            )}
                          </Formik>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {item.quantity}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          Rs. {item.price}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          Rs.&nbsp;
                          {Number(item.quantity) * Number(item.price)}
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
                <TableRow style={{ borderTop: " 1px solid #efefef" }}>
                  <StyledTableCell
                    className={classes.total}
                    align="center"
                    colSpan={4}
                  >
                    Sub Total
                  </StyledTableCell>
                  <StyledTableCell className={classes.totalPrice} align="left">
                    Rs.&nbsp;{total + cheeseTotal + butterTotal}
                  </StyledTableCell>
                </TableRow>
              </TableBody>
            </Table>
            {role === "cafe" && (
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="flex-end"
              >
                <Grid item xs={4} sm={4}>
                  <SelectField
                    value={this.state.creditAccount}
                    name="credit_account"
                    id="credit_account"
                    label="Credit Account"
                    onChange={this.setFieldValue}
                    // onBlur={setFieldTouched}
                    // multi={false}
                    // error={errors.credit_account}
                    // touched={touched.credit_account}
                    options={this.state.creditAccounts}
                  />
                </Grid>
              </Grid>
            )}
          </div>
          <div className={classes.confirmOrder}>
            <Button
              variant="contained"
              color="grey"
              className={classes.cancelButton}
              onClick={this.props.history.goBack}
            >
              Cancel
            </Button>
            <Button
              size="small"
              variant="contained"
              color="primary"
              className={classes.addOrderButton}
              onClick={this.confirmOrder}
            >
              Confirm Order
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
ViewAllOrder.propTypes = {};
const mapStateToProps = (state) => {
  return {
    creditAccount: state.CreditAccount.creditAccounts,
  };
};
export default connect(mapStateToProps, { addOrder, getCreditAccounts })(
  withStyles(styles)(withRouter(ViewAllOrder))
);
