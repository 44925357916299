import React from "react";
import { Grid, withStyles, Button, FormLabel } from "@material-ui/core";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { EditorState } from "draft-js";
import { getSpaceCompanyList } from "../../Actions/companiesAction";
import InputField from "../../Components/Common/Form/InputField";
import { SelectField } from "../../Components/Common/Form";
import { CircularLoader } from "../../Components/Common/Spinner";
import RadioField from "../../Components/Common/Form/RadioField";
import { getSpaces } from "../../Actions/spaceActions";
import {
  createNotification,
  getSingleNotification,
} from "../../Actions/settingNotificationActions";
import ScreenHeader from "../../Components/Common/ScreenHeader";
import DateTimePickerField from "../../Components/Common/Form/DateTimePicker";
import TextareaField from "../../Components/Common/Form/TextareaField";
import "../company.css";
import PopupNotification from "./PopupNotification";
import CustomPushNotification from "./CustomPushNotification";
import EmailNotification from "./NewEmailNotification";
import { capitalize, spacecapitalize } from "../../Utils/StringFunctions";

const styles = (theme) => ({
  lightbg: {
    backgroundColor: "#fff",
    padding: "10px 22px",
  },
  descriptionLabel: {
    margin: "2px 0px 8px 0px",
    fontSize: "12px",
    fontWeight: "400",
    color: "rgba(0, 0, 0, 0.54)",
  },
  button: {
    width: "148px",
    height: "46px",
    backgroundColor: "#dddddd",
    color: "#151039",
    "&:hover": {
      color: "#151039",
      backgroundColor: "#d1d1d1!important",
    },
  },
  Addbutton: {
    width: "148px",
    height: "46px",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  margin: {
    margin: theme.spacing(1),
    color: "#979493",
  },
  formfooter: {
    margin: "24px 15px",
    marginRight: "-2%",
  },
  ScreenHeader: {
    marginLeft: "-25px",
  },
  form: {
    marginLeft: "4px",
  },
  dropinsidetext: {
    marginTop: "30px",
  },
  input_field: {
    border: "1px solid #949494",
    height: "81%",
    position: "relative",
    width: "100%",
    padding: "6px 8px",
    margin: "0px",
    // borderBottom: "none",
    fontFamily:
      "'-apple-system,BlinkMacSystemFont','Segoe UI','Roboto','Helvetica Neue','Arial,sans-serif','Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol'",
    color: "currentColor",
    fontSize: "14px",
  },
});

const NotificationTypeOptions = [
  { value: "sms", label: "SMS" },
  { value: "email", label: "Email" },
  // { value: "popup", label: "PopUp Notification" },
  // { value: "custompush", label: "Custom Push Notification" }
];

const TimeOptions = [
  { label: "Now", value: "now" },
  { label: "Schedule", value: "schedule" },
];

const SmsNotificationSchema = Yup.object().shape({
  title: Yup.string().max(15, "").required("This field is required."),
  space: Yup.string().required("This field is required."),
  type: Yup.object().required("This field is required."),
  description: Yup.string().required("This field is required"),
});
const EmailNotificationSchema = Yup.object().shape({
  title: Yup.string().max(15, "").required("This field is required."),
  space: Yup.string().required("This field is required."),
  type: Yup.object().required("This field is required."),
  subject: Yup.string().required("This field is required"),
  description: Yup.string().required("This field is required"),
  // emailcontent:Yup.mixed().test(
  //   "match",
  //   "This field is required",
  //   function check() {
  //     const finalContent =
  //       this.parent &&
  //       this.parent.editorState.getCurrentContent().getPlainText() !== "";
  //     if (finalContent === true) {
  //       return true;
  //     }
  //     return false;
  //   }
  // )
});
const NewEmailNotificationSchema = Yup.object().shape({
  title: Yup.string().max(15, "").required("This field is required."),
  space: Yup.string().required("This field is required."),
  type: Yup.object().required("This field is required."),
  subject: Yup.string().required("This field is required"),
  description: Yup.string().required("This field is required"),
  link: Yup.string().required("This field is required"),
});
class NotificationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allspaces: [],
      allcompanies: [],
      checkType: "sms",
      deletedKey: [],
      temp_name: "bulk_email_test_without_link",
      id:
        this.props.history &&
        this.props.history.location &&
        this.props.history.location.params
          ? this.props.history.location.params.notificationID
          : "",
    };
  }

  componentDidMount() {
    this.props.getSpaces();
    const { id } = this.state;
    if (id) {
      this.props.getSingleNotification(id);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.singleNotification !== prevState.singleNotification) {
      return {
        singleNotification: nextProps.singleNotification,
      };
    }

    if (nextProps.spaces !== prevState.spaces) {
      const allspaces =
        (nextProps.spaces &&
          nextProps.spaces.map((element) => {
            return {
              label: capitalize(element.space_title),
              value: element._id,
            };
          })) ||
        [];
      return { spaces: nextProps.spaces, allspaces };
    }
    if (nextProps.spaceCompany !== prevState.spaceCompany) {
      const allcompanies =
        (nextProps.spaceCompany &&
          nextProps.spaceCompany.map((company) => {
            return {
              value: company._id,
              label: capitalize(company.company_name),
            };
          })) ||
        [];
      return { spaceCompany: nextProps.compaspaceCompanynies, allcompanies };
    }
    return [];
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  setDelImageUrl = (url) => {
    const { deletedKey } = this.state;
    this.setState({ deletedKey: deletedKey.push(url) });
  };

  callComapnyAPI = (value) => {
    if (value.length === 1) {
      this.props.getSpaceCompanyList(value[0].value);
    }
  };

  renderNotificationGrid = (
    classes,
    values,
    setFieldValue,
    setFieldTouched,
    errors,
    touched
  ) => {
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6}>
            <InputField
              name="title"
              label="Title"
              placeholder="Enter Title"
              className={classes.dropinsidetext}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <SelectField
              value={values.space}
              name="space"
              id="space"
              label="Select Space"
              onChange={(name, value) => {
                setFieldValue("space", value);
                this.callComapnyAPI(value);
                setFieldValue("company_id", "");
              }}
              multi
              error={errors.space}
              options={this.state.allspaces}
            />
          </Grid>
          {values.space && values.space.length > 1 ? (
            ""
          ) : (
            <Grid item xs={6} sm={6}>
              <SelectField
                value={values.company_id}
                name="company_id"
                id="company_id"
                label="Company"
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                multi
                error={errors.company_id}
                touched={touched.company_id}
                options={this.state.allcompanies}
              />
            </Grid>
          )}
          <Grid item xs={6} sm={6}>
            <Grid container>
              <Grid item xs={6} sm={6}>
                <RadioField
                  name="time"
                  label="Time"
                  id="time"
                  options={TimeOptions}
                  // RadioGroupProps={{
                  //   checked: values.time ? values.time : "now"
                  // }}
                />
              </Grid>
              <Grid item xs={6} sm={6} style={{ marginTop: "12px" }}>
                {values.time === "schedule" && (
                  <span>
                    <FormLabel className={classes.dateLabel} component="span">
                      Date
                    </FormLabel>
                    <DateTimePickerField
                      name="date"
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                      }}
                      value={values.date}
                      disableFuture={false}
                      disablePast
                      maxDate={new Date()}
                    />
                  </span>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6}>
            <SelectField
              value={values.type}
              name="type"
              id="type"
              label="Type"
              onChange={(name, value) => {
                this.setState({ checkType: value.value });
                setFieldValue(name, value);
              }}
              onBlur={setFieldTouched}
              multi={false}
              error={errors.type}
              touched={touched.type}
              options={NotificationTypeOptions}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          {values.type.value === "popup" && <PopupNotification />}
          <Grid item xs={12} sm={12}>
            {values.type.value === "email" && (
              <EmailNotification
                // delImageUrl={() => this.setDelImageUrl}
                values={values}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                tempname={this.state.temp_name}
                settemp_name={(name) => {
                  this.setState({ temp_name: name });
                }}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={12}>
            {values.type.value === "custompush" && <CustomPushNotification />}
          </Grid>
          {(values.type.value === "sms" || values.type.value === "email") && (
            <Grid item xs={12} sm={12}>
              <FormLabel
                className={classes.descriptionLabel}
                component="legend"
              >
                Content
              </FormLabel>
              <TextareaField
                name="description"
                className={classes.input_field}
                rows="5"
              ></TextareaField>
            </Grid>
          )}
        </Grid>
      </div>
    );
  };

  getInitialValues = (id) => {
    const { singleNotification } = this.state;
    if (id) {
      return {
        title: singleNotification && singleNotification.title,
        space: singleNotification && singleNotification.space,
        company_id: singleNotification && singleNotification.company_id,
        type: {
          value: singleNotification && singleNotification.type_of_notification,
          label: spacecapitalize(
            singleNotification && singleNotification.type_of_notification
          ),
        },
        subject: singleNotification && singleNotification.subject,
        banner: singleNotification && singleNotification.banner,
        description: singleNotification && singleNotification.description,
        link: singleNotification && singleNotification.link,
        button_label: "",
        time: "now",
        date: new Date(),
        temp_name: singleNotification && singleNotification.temp_name,
        emailcontent: "",
        // editorState: setEditorState(
        //   singleNotification && singleNotification.description
        // )
      };
    }
    return {
      title: "",
      space: "",
      type: { value: "sms", label: "SMS" },
      subject: "",
      banner: "",
      link: "",
      time: "now",
      date: new Date(),
      description: "",
      temp_name: this.state.temp_name,
      emailcontent: "",
      // eslint-disable-next-line new-cap
      editorState: new EditorState.createEmpty(),
      // eslint-disable-next-line new-cap
    };
  };

  render() {
    const { classes } = this.props;
    const { loading, id, checkType } = this.state;

    return (
      <React.Fragment>
        <div className={classes.lightbg}>
          <div className={classes.ScreenHeader}>
            <ScreenHeader type="Add New Notification" />
          </div>
          <Formik
            initialValues={this.getInitialValues(id)}
            enableReinitialize={!!id}
            validationSchema={
              // eslint-disable-next-line no-nested-ternary
              checkType === "sms"
                ? SmsNotificationSchema
                : this.state.temp_name === "bulk_email_test_with_link"
                ? NewEmailNotificationSchema
                : EmailNotificationSchema
            }
            onSubmit={(values, { setErrors, setSubmitting }) => {
              const submitValues = { ...values };
              submitValues.temp_name =
                values.type && values.type.value === "email"
                  ? this.state.temp_name
                  : "";
              submitValues.banner =
                values.banner ||
                `https://coworker-upload.s3.ap-south-1.amazonaws.com/notification/banner/1600680304349`;
              // console.log("values :",submitValues)
              // eslint-disable-next-line react/no-unused-state
              this.setState({ values });
              // const htmlComments =
              //   submitValues.editorState &&
              //   draftToHtml(
              //     convertToRaw(values.editorState.getCurrentContent())
              //   );
              submitValues.description = values.description;
              submitValues.type_of_notification =
                values.type && values.type.value;
              delete submitValues.editorState;
              if (submitValues.company_id === "") {
                delete submitValues.company_id;
              }
              this.props.createNotification(
                submitValues,
                setErrors,
                setSubmitting,
                this.props.history
              );
            }}
          >
            {({
              values,
              errors,
              touched,
              setFieldTouched,
              setFieldValue,
              handleSubmit,
            }) => (
              <form
                onSubmit={handleSubmit}
                className={classes.form}
                encType="multipart/form-data"
                autoComplete="off"
              >
                {this.renderNotificationGrid(
                  classes,
                  values,
                  setFieldValue,
                  setFieldTouched,
                  errors,
                  touched
                )}

                <div className={classes.formfooter}>
                  <Grid
                    container
                    item
                    display="flex"
                    justify="flex-end"
                    alignItems="center"
                    direction="row"
                  >
                    <Grid xs={6} sm={2}>
                      <Button
                        color="primary"
                        onClick={() =>
                          this.props.history.push("/notifications")
                        }
                        className={classes.button}
                        style={{ right: "-5px" }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid xs={6} sm={2}>
                      <Button
                        className={classes.Addbutton}
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                  {loading && <CircularLoader />}
                </div>
              </form>
            )}
          </Formik>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errors: state.errors,
    spaces: state.spaces.spaces,
    spaceCompany: state.company.spaceCompany,
    singleNotification: state.notifications.singleNotification.data,
  };
};

export default connect(mapStateToProps, {
  getSpaces,
  getSpaceCompanyList,
  createNotification,
  getSingleNotification,
})(withStyles(styles)(NotificationForm));
