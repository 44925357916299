import {
  ALLIED_SERVICES_LOADING,
  CREATE_ALLIED_SERVICES,
  GET_ALL_ALLIED_SERVICES,
  ALLIED_SERVICES_ERRORS,
} from "../Actions/types";

const initialState = {
  alliedServices: [],
  loading: false,
  createAlliedService: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_ALLIED_SERVICES: {
      return {
        ...state,
        loading: false,
        alliedServices: action.payload.data,
      };
    }
 
    case CREATE_ALLIED_SERVICES:
      return {
        ...state,
        createAlliedService: { success: true, data: action.payload },
      };

    case ALLIED_SERVICES_LOADING:
      return {
        ...state,
        loading: true,
      };

    case ALLIED_SERVICES_ERRORS:
      return {
        ...state,
        loading: false,
        alliedServices: {
          success: false,
          data: action.payload && action.payload.data,
        },
      };

    default:
      return state;
  }
}
