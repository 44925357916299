import React from 'react'
import { Paper, withStyles, Button } from '@material-ui/core'
import { connect } from 'react-redux'
import { getCoworker } from '../../Actions/coworkerActions'
import AdminCompanies from './AdminCompanies'
import AdminFreelancer from '../Freelancer/AdminFreelancer'

const styles = (theme) => ({
  root: {
    paddingBottom: '15px',
  },
  noCoworker: {
    padding: '24px 24px 11px',
    color: 'lightgrey',
  },
  layout: {
    marginTop: '19px !important',
    padding: '20px 25px',
  },
  paper: {
    // backgroundColor: "#fcfdfe",
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  activeView: {
    margin: theme.spacing(1),
    backgroundColor: '#211b4e',
    color: 'white',
    fontSize: '10px',
    borderRadius: `${theme.shape.borderRadius * 4}px`,
    '&:hover': {
      backgroundColor: '#211b4e',
      color: 'white',
    },
  },
  // inactive view for non-slected tabs
  inactiveView: {
    margin: theme.spacing(1),
    fontSize: '10px',
    color: '#959595',
    backgroundColor: '#fff',
    border: '1px solid #959595',
    borderRadius: `${theme.shape.borderRadius * 4}px`,
    '&:hover': {
      backgroundColor: 'white',
      color: '#959595',
    },
  },
})

function AdminCompanyTabs(props) {
  const { classes, history } = props
  const [isOpen, setisOpen] = React.useState(true)

  return (
    <Paper className={classes.root}>
      <div style={{ textAlign: 'right' }}>
        {/* <ScreenHeader type={isOpen ? "All Companies" : "All Freelancers"} /> */}
        <Button
          size="small"
          variant="contained"
          color="primary"
          // className={classes.button}
          className={isOpen ? classes.activeView : classes.inactiveView}
          onClick={() => {
            setisOpen(true)
          }}
        >
          Company
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          className={!isOpen ? classes.activeView : classes.inactiveView}
          // className={classes.button}
          onClick={() => {
            setisOpen(false)
          }}
        >
          Freelancer
        </Button>
      </div>
      {/* <TabContainer> */}
      {isOpen && <AdminCompanies history={history} />}
      {!isOpen && <AdminFreelancer history={history} />}
      {/* </TabContainer> */}
    </Paper>
  )
}

const mapStateToProps = (state) => {
  return {
    errors: state.errors,
  }
}

export default connect(mapStateToProps, { getCoworker })(
  withStyles(styles)(AdminCompanyTabs)
)
