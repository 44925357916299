/* eslint-disable camelcase */
import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter, Link } from "react-router-dom";
import { Table, TableBody, TableHead, TableRow } from "@material-ui/core";
import { CircularLoader } from "../../Components/Common/Spinner";
import DataNotFound from "../../Components/Common/DataNotFound";
import Cell from "../../Components/Common/Table/TableCell";
import TableHeader from "../../Components/Common/Table/TableHeader";
import ScreenHeader from "../../Components/Common/ScreenHeader";
import { getNotifications } from "../../Actions/settingNotificationActions";
import Pagination from "../../Components/Common/Table/TablePagination";
import { checkPermission } from "../../Utils/checkPermission";
import { capitalize } from "../../Utils/StringFunctions";

const $quoteSym = " ";
const moment = require("moment");

const styles = () => ({
  root: {
    backgroundColor: "#fff",
  },
  table: {
    minWidth: 650,
    marginTop: "20px",
  },
  resource_form_modal: {
    width: "500px",
  },
  form_modal: {
    minWidth: "500px",
  },
  main: {
    padding: "24px",
  },
  editbutton: {
    borderRadius: "50px",
  },
  editicon: {
    fontSize: "16px",
    marginRight: "6px",
  },
  clonebutton: {
    color: "#fff",
    backgroundColor: "#51bd51",
    borderColor: "#007bff",
    "&:hover": {
      backgroundColor: "#49a949",
      borderColor: "#0062cc",
      boxShadow: "none",
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "-20px",
  },
  add_resource: {
    width: "100%",
    textAlign: "right",
  },
  addcategorybutton: {
    color: "#fff",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  ScreenHeader: {
    marginLeft: "-20px",
    width: "100%",
  },
  cancelbutton: {
    marginRight: "20px",
    backgroundColor: "#dddddd",
    color: "#151039",
    "&:hover": {
      color: "#151039",
      backgroundColor: "#d1d1d1!important",
    },
  },
  addbutton: {
    color: "#fff",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  modelHeader: {
    paddingBottom: "5px",
    position: "relative",
    "&:after": {
      top: "48px",
      left: "24px",
      width: "24px",
      borderBottom: "3px solid #fb0",
      content: `'${$quoteSym}'`,
      position: "absolute",
    },
  },
  modalFooter: {
    padding: "8px 24px",
  },
  root1: {
    "&:nth-child(even)": {
      backgroundColor: "#FCFDFE",
    },
  },
  backButton: {
    margin: "0% 0% 4% 0%",
  },
});

export class NotificationView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 10,
    };
  }

  componentDidMount() {
    this.props.getNotifications();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading !== this.props.loading) {
      this.props.setLoading(this.props.loading);
      this.setState({
        loading: this.props.loading,
      });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.notifications !== prevState.notifications) {
      return {
        notifications: nextProps.notifications,
      };
    }
    return [];
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  cloneNotification = (id) => {
    const path = `/notification/add`;
    this.props.history.push({
      pathname: path,
      params: { notificationID: id },
    });
  };

  render() {
    const { classes } = this.props;
    const { loading, page, rowsPerPage } = this.state;

    const itemLength = this.props.notifications
      ? this.props.notifications.length
      : 0;

    if (loading) {
      return <CircularLoader />;
    }
    if (this.props.notifications && this.props.notifications.length <= 0) {
      return (
        <DataNotFound
          type="event"
          text="You don't have any notification!!"
          subtext=""
          subComponent={
            <div className={classes.add_resource}>
              {checkPermission("NOTIFICATION_CREATE") && (
                <Button
                  to="/notification/add"
                  component={Link}
                  className={classes.addcategorybutton}
                  size="medium"
                  variant="contained"
                  color="primary"
                >
                  Create Notification
                </Button>
              )}
            </div>
          }
        />
      );
    }
    return (
      <div className={classes.main}>
        <Button
          color="grey"
          onClick={() => this.props.history.push("/settings")}
          className={classes.backButton}
          variant="contained"
          size="small"
        >
          Back
        </Button>
        <div className={classes.header}>
          <div className={classes.ScreenHeader}>
            <ScreenHeader type="Notification History" />
          </div>

          {checkPermission("NOTIFICATION_CREATE") && (
            <div className={classes.add_resource}>
              <Button
                to="/notification/add"
                component={Link}
                className={classes.addcategorybutton}
                size="medium"
                variant="contained"
                color="primary"
              >
                Create Notification
              </Button>
            </div>
          )}
        </div>
        <div className={classes.root}>
          {this.props.notifications && this.props.notifications.length !== 0 && (
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableHeader>Sr. No.</TableHeader>
                  <TableHeader align="left">Date</TableHeader>
                  <TableHeader align="left">Title</TableHeader>
                  <TableHeader align="left">Schedule </TableHeader>
                  <TableHeader align="left">Category</TableHeader>
                  <TableHeader align="left">Create By</TableHeader>
                  <TableHeader align="left">Status</TableHeader>
                  <TableHeader align="left"></TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.notifications &&
                  this.props.notifications
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, key) => {
                      return (
                        <TableRow className={classes.root1}>
                          <Cell align="left" width="10px">
                            {page * rowsPerPage + key + 1}
                          </Cell>
                          <Cell align="left">
                            {moment(row.createdAt).format("DD/MM/YYYY")}
                          </Cell>
                          <Cell align="left">{capitalize(row.title)}</Cell>
                          <Cell align="left">
                            {moment(row.createdAt).format("LT")}
                          </Cell>
                          <Cell align="left">
                            {row.type_of_notification === "sms" ? (
                              "SMS"
                            ) : (
                              <span>
                                {capitalize(row.type_of_notification)}
                              </span>
                            )}
                          </Cell>
                          <Cell align="left">
                            {row.created_by && row.created_by.first_name}
                          </Cell>
                          <Cell align="left">Sent</Cell>
                          <Cell align="left">
                            <Button
                              variant="contained"
                              className={classes.clonebutton}
                              onClick={() => this.cloneNotification(row._id)}
                              params={{
                                notificationID: row._id ? row._id : "",
                              }}
                            >
                              Clone
                            </Button>
                          </Cell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          )}
          <Pagination
            count={itemLength}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.resource_categories.loading,
    notifications: state.notifications.notifications.data,
  };
};
export default connect(mapStateToProps, { getNotifications })(
  withStyles(styles)(withRouter(NotificationView))
);
