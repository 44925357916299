import axios from "axios";
import {
  GET_COWORKER_ROLETYPE,
  COWORKER_ROLETYPE_ERRORS,
  ROLETYPE_LOADING,
  GET_ALL_ROLES
} from "./types";

//  Loading
export const setRolesLoading = () => {
  return {
    type: ROLETYPE_LOADING
  };
};

export const getRoletypes = () => dispatch => {
  dispatch(setRolesLoading());
  axios
    .get(`/api/roletype/coworker`)
    .then(res => {
      dispatch({
        type: GET_COWORKER_ROLETYPE,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: COWORKER_ROLETYPE_ERRORS,
        payload: err && err.res ? err.res.data : ""
      })
    );
};

export const getAllRoles = () => dispatch => {
  dispatch(setRolesLoading());
  axios
    .get(`/api/roletype`)
    .then(res => {
      dispatch({
        type: GET_ALL_ROLES,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: COWORKER_ROLETYPE_ERRORS,
        payload: err && err.res ? err.res.data : ""
      })
    );
};
