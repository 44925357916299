import {
  GET_COWORKER_SKILLS,
  COWORKER_SKILLS_ERRORS,
  COWORKER_SKILLS_LOADING,
} from "../Actions/types";

const initialState = {
  loading: false,
  coworkerskills: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_COWORKER_SKILLS:
      return {
        ...state,
        loading: false,
        coworkerskills: action.payload.data,
      };
    case COWORKER_SKILLS_ERRORS:
      return {
        ...state,
        skills_error: { errors: false },
      };
    case COWORKER_SKILLS_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
