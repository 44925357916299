import React from "react";
import { withStyles } from "@material-ui/styles";
import Ahmedabad from "../../Assets/ahmedabad.jpg";

const styles = () => ({
  imgSize: {
    width: "100%",
    height: "auto",
  },
});

// eslint-disable-next-line react/prefer-stateless-function
class ViewPlan extends React.Component {
  render() {
    const { classes } = this.props;
    return <img src={Ahmedabad} alt="" className={classes.imgSize} />;
  }
}

export default withStyles(styles)(ViewPlan);
