import React from "react";

const ClubSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="380"
      // height="300"
      viewBox="10 0 400 250"
    >
      <g
        id="Group_1074"
        data-name="Group 1074"
        transform="translate(-103 -130)"
      >
        <g
          id="Group_955"
          data-name="Group 955"
          transform="translate(275.465 308.193)"
        >
          <g id="Group_953" data-name="Group 953">
            <path
              id="Path_1957"
              data-name="Path 1957"
              d="M645.515,575.173l16.32-5.476-4.139,28.2,1.23,24.464.047.164-.039,0,0,.053-3.194.213v0l-.119.01-6.49-21.95-3.1-23.272h0Z"
              transform="translate(-645.515 -569.697)"
              fill="#00143a"
            />
          </g>
          <g
            id="Group_954"
            data-name="Group 954"
            transform="translate(5.963 54.21)"
          >
            <path
              id="Path_1958"
              data-name="Path 1958"
              d="M668.4,737.765l3.306.011.764,2.812-.084.022.021,0-5.555,3.08-2.605.214L664,740.93l.716-.494Z"
              transform="translate(-664.002 -737.765)"
              fill="#000719"
            />
          </g>
        </g>
        <g
          id="Group_958"
          data-name="Group 958"
          transform="translate(261.902 310.703)"
        >
          <g id="Group_956" data-name="Group 956" transform="translate(0.867)">
            <path
              id="Path_1959"
              data-name="Path 1959"
              d="M606.156,580.616l3.459,48.579,3.193.237,3.961-26.064,6.313-25.89Z"
              transform="translate(-606.156 -577.478)"
              fill="#00143a"
            />
          </g>
          <g
            id="Group_957"
            data-name="Group 957"
            transform="translate(0 52.842)"
          >
            <path
              id="Path_1960"
              data-name="Path 1960"
              d="M607.769,741.3l-4.3,2.28.339,2.009,7.6-.924-.363-2.891Z"
              transform="translate(-603.466 -741.304)"
              fill="#000719"
            />
          </g>
        </g>
        <g
          id="Group_960"
          data-name="Group 960"
          transform="translate(254.949 257.983)"
        >
          <g id="Group_959" data-name="Group 959">
            <path
              id="Path_1961"
              data-name="Path 1961"
              d="M623.2,477.864c.341-8.212-6.994-34.327-6.994-34.327h0a46.339,46.339,0,0,1-1.721-7.452c-.252-1.782-5.59-22.028-5.237-22.054-3.677.269-10.767-.6-13.491,2.011-.078.074-.152.152-.223.233-2.124,2.422-3.226,7.325-4.231,10.343a81,81,0,0,0-3.024,12.15c-.605,3.6-.964,7.242-1.218,10.884-.218,3.132-.045,6.246-.152,9.327-.118,3.41-1.141,7.108-1.9,10.425a57.784,57.784,0,0,1-3.093,9.8,29.122,29.122,0,0,0,11.709,2.068c3.992.015,7.98-.37,11.971-.309,3.375.052,6.793.42,10.1-.257a34.84,34.84,0,0,0,7.506-2.839Z"
              transform="translate(-581.911 -414.031)"
              fill="#d44040"
            />
          </g>
        </g>
        <g
          id="Group_964"
          data-name="Group 964"
          transform="translate(274.081 263.168)"
        >
          <g id="Group_962" data-name="Group 962" transform="translate(26.038)">
            <g id="Group_961" data-name="Group 961">
              <path
                id="Path_1962"
                data-name="Path 1962"
                d="M724.71,434.179c.961.179,3.464-.491,4.039-1.374,1.327-2.039-1.821-1.411-2.959-1.439a6.227,6.227,0,0,0,.318-1.177c.046-.371-3.333.592-3.658.946-.782.85-.425,1.491-.354,2.565C722.968,433.839,723.839,434.016,724.71,434.179Z"
                transform="translate(-721.953 -430.106)"
                fill="#eea987"
              />
            </g>
          </g>
          <g
            id="Group_963"
            data-name="Group 963"
            transform="translate(0 0.213)"
          >
            <path
              id="Path_1963"
              data-name="Path 1963"
              d="M644.74,442.326l13.819,6.349L670.016,434.8l-3.38-2.785-7.573,7.63-9.135-8.878-8.7.377Z"
              transform="translate(-641.227 -430.767)"
              fill="#d34e4e"
            />
          </g>
        </g>
        <g
          id="Group_965"
          data-name="Group 965"
          transform="translate(255.746 260.227)"
        >
          <path
            id="Path_1964"
            data-name="Path 1964"
            d="M597.206,420.986l-2.368,1.829-10.457,18.3,7.283,2.8,4.295-15.873,1.867-4.108Z"
            transform="translate(-584.381 -420.986)"
            fill="#d34e4e"
          />
        </g>
        <g
          id="Group_966"
          data-name="Group 966"
          transform="translate(262.48 265.68)"
        >
          <path
            id="Path_1965"
            data-name="Path 1965"
            d="M616.79,445.113l-5.991-7.064a.446.446,0,0,0-.561-.1h0l-4.756,2.725a.445.445,0,0,0-.07.723l7.309,6.309a.445.445,0,0,0,.512.049Z"
            transform="translate(-605.258 -437.892)"
            fill="#474ebb"
          />
        </g>
        <g
          id="Group_967"
          data-name="Group 967"
          transform="translate(264.963 269.764)"
        >
          <path
            id="Path_1966"
            data-name="Path 1966"
            d="M616.406,455.5l1.329-4.3-1.812-.648-1.562,2.741-.715-.7-.689,3.816,1.793.928Z"
            transform="translate(-612.958 -450.554)"
            fill="#eea987"
          />
        </g>
        <g
          id="Group_969"
          data-name="Group 969"
          transform="translate(255.885 273.986)"
        >
          <g id="Group_968" data-name="Group 968">
            <path
              id="Path_1967"
              data-name="Path 1967"
              d="M593.836,463.645c-2.013,1.767-4.055,3.511-5.928,5.429-.846.866-3.53.814-3.035.814l3.979,5.456c3.1-1.034,5.179-4.489,7.8-9.2Z"
              transform="translate(-584.813 -463.645)"
              fill="#d34e4e"
            />
          </g>
        </g>
        <g
          id="Group_973"
          data-name="Group 973"
          transform="translate(264.598 243.451)"
        >
          <g
            id="Group_970"
            data-name="Group 970"
            transform="translate(0 1.214)"
          >
            <path
              id="Path_1968"
              data-name="Path 1968"
              d="M623.542,376.991c-1.871.062-4.347,1.285-5.071,3.142-.348.894-.173,1.791-.584,2.733-.8,1.86,1.118,5.061-1.4,6.064-1.3.515-2.752.07-4.072-.376a1.023,1.023,0,0,1-.442-.237.719.719,0,0,1-.056-.784,2.912,2.912,0,0,1,.513-.654c1.949-2.161,2.072-5.656,3.1-8.294s2.888-5.13,5.753-5.848a5.228,5.228,0,0,1,2.1,3.762Z"
              transform="translate(-611.826 -372.737)"
              fill="#000719"
            />
          </g>
          <g
            id="Group_971"
            data-name="Group 971"
            transform="translate(5.037 3.164)"
          >
            <path
              id="Path_1969"
              data-name="Path 1969"
              d="M630.1,392.478a6.1,6.1,0,0,1-2.643-4.89,12.208,12.208,0,0,1,1.273-5.582c2.622-5.851,10.026-2.967,11.039,2.519C640.651,389.307,634.871,395.412,630.1,392.478Z"
              transform="translate(-627.443 -378.783)"
              fill="#eea987"
            />
          </g>
          <g id="Group_972" data-name="Group 972" transform="translate(8.911)">
            <path
              id="Path_1970"
              data-name="Path 1970"
              d="M646.658,390.514a5.423,5.423,0,0,1-1.892-2.42c-.566-1.734.145-4.192.308-5.957.173-1.861.3-3.9-.763-5.433a4.423,4.423,0,0,0-2.808-1.682c-.774-.2-.874.09-1.432-.483a3.449,3.449,0,0,1-.617-2.082,3.316,3.316,0,0,1,1.436-2.479,4.65,4.65,0,0,1,3.943-.886c5.093,1.087,8.186,4.99,8.443,10.277.124,2.542-1.011,5.07-.82,7.533.118,1.521.8,2.677.812,4.269.029,4.132,1.005,9.637-.114,13.637a.583.583,0,0,1-.234.382c-.276.148-.559-.179-.7-.457l-2.523-4.867a7.734,7.734,0,0,1-.806-1.988c-.353-1.751.506-3.706-.316-5.292A7.181,7.181,0,0,0,646.658,390.514Z"
              transform="translate(-639.452 -368.975)"
              fill="#000719"
            />
          </g>
        </g>
        <rect
          id="Rectangle_312"
          data-name="Rectangle 312"
          width="63.431"
          height="1.13"
          transform="translate(201.032 230.347) rotate(-50.033)"
          fill="#474ebb"
        />
        <rect
          id="Rectangle_313"
          data-name="Rectangle 313"
          width="1.13"
          height="78.513"
          transform="translate(126.28 209.756) rotate(-72.492)"
          fill="#474ebb"
        />
        <circle
          id="Ellipse_124"
          data-name="Ellipse 124"
          cx="13.45"
          cy="13.45"
          r="13.45"
          transform="matrix(0.978, -0.208, 0.208, 0.978, 110.508, 198.902)"
          fill="#8ec3cb"
        />
        <rect
          id="Rectangle_314"
          data-name="Rectangle 314"
          width="1.13"
          height="81.363"
          transform="matrix(0.935, -0.355, 0.355, 0.935, 172.128, 153.78)"
          fill="#474ebb"
        />
        <rect
          id="Rectangle_315"
          data-name="Rectangle 315"
          width="1.13"
          height="73.887"
          transform="translate(173.796 154.188) rotate(-67.37)"
          fill="#474ebb"
        />
        <rect
          id="Rectangle_316"
          data-name="Rectangle 316"
          width="76.482"
          height="1.13"
          transform="matrix(0.897, -0.442, 0.442, 0.897, 240.53, 181.498)"
          fill="#474ebb"
        />
        <rect
          id="Rectangle_317"
          data-name="Rectangle 317"
          width="1.13"
          height="84.675"
          transform="translate(241.915 182.578) rotate(-58.42)"
          fill="#474ebb"
        />
        <rect
          id="Rectangle_318"
          data-name="Rectangle 318"
          width="84.448"
          height="1.13"
          transform="matrix(0.78, -0.626, 0.626, 0.78, 313.994, 226)"
          fill="#474ebb"
        />
        <circle
          id="Ellipse_125"
          data-name="Ellipse 125"
          cx="23.823"
          cy="23.823"
          r="23.823"
          transform="matrix(0.996, -0.09, 0.09, 0.996, 219.129, 157.188)"
          fill="#ebb044"
        />
        <circle
          id="Ellipse_126"
          data-name="Ellipse 126"
          cx="16.722"
          cy="16.722"
          r="16.722"
          transform="translate(362.358 157.941) rotate(-3.869)"
          fill="#474ebb"
        />
        <circle
          id="Ellipse_127"
          data-name="Ellipse 127"
          cx="18.029"
          cy="18.029"
          r="18.029"
          transform="translate(154.668 170.074) rotate(-84.086)"
          fill="#474ebb"
        />
        <circle
          id="Ellipse_128"
          data-name="Ellipse 128"
          cx="13.215"
          cy="13.215"
          r="13.215"
          transform="translate(291.173 152.371) rotate(-57.823)"
          fill="#d34e4e"
        />
        <circle
          id="Ellipse_129"
          data-name="Ellipse 129"
          cx="35.454"
          cy="35.454"
          r="35.454"
          transform="translate(263.102 218.303) rotate(-45)"
          fill="#8ec3cb"
        />
        <circle
          id="Ellipse_130"
          data-name="Ellipse 130"
          cx="20.03"
          cy="20.03"
          r="20.03"
          transform="matrix(0.992, -0.13, 0.13, 0.992, 176.93, 206.732)"
          fill="#d34e4e"
        />
        <g
          id="Group_989"
          data-name="Group 989"
          transform="translate(235.879 236.725)"
        >
          <path
            id="Path_1971"
            data-name="Path 1971"
            d="M526.048,353.92l.872-1.861c.318-.679-.649-3.067-.817-3.84-.1-.441-2.064.8-2.215,1.084a9.722,9.722,0,0,0-.084,2.508l-1.014.583.367,1.457Z"
            transform="translate(-522.789 -348.124)"
            fill="#eea987"
          />
        </g>
        <g
          id="Group_1003"
          data-name="Group 1003"
          transform="translate(196.15 242.115)"
        >
          <g
            id="Group_1001"
            data-name="Group 1001"
            transform="translate(0 2.111)"
          >
            <g
              id="Group_992"
              data-name="Group 992"
              transform="translate(13.81 67.254)"
            >
              <g id="Group_990" data-name="Group 990">
                <path
                  id="Path_1972"
                  data-name="Path 1972"
                  d="M457.915,582.478l.814,18.882-2.386,32.106-3.571.254-4.339-29.16-6-24.675Z"
                  transform="translate(-442.431 -579.885)"
                  fill="#474ebb"
                />
              </g>
              <g
                id="Group_991"
                data-name="Group 991"
                transform="translate(10.331 53.135)"
              >
                <path
                  id="Path_1973"
                  data-name="Path 1973"
                  d="M474.661,747.717h5.361l.222-1.771-1.922-1.327-3.862.274Z"
                  transform="translate(-474.461 -744.619)"
                  fill="#000719"
                />
              </g>
            </g>
            <g
              id="Group_995"
              data-name="Group 995"
              transform="translate(3.231 62.763)"
            >
              <g id="Group_993" data-name="Group 993">
                <path
                  id="Path_1974"
                  data-name="Path 1974"
                  d="M410.418,567.43l-.784,14.273,2.325,42.439,3.576.179,5.359-27.834,4.727-30.524Z"
                  transform="translate(-409.634 -565.962)"
                  fill="#474ebb"
                />
              </g>
              <g
                id="Group_994"
                data-name="Group 994"
                transform="translate(0.005 57.738)"
              >
                <path
                  id="Path_1975"
                  data-name="Path 1975"
                  d="M415.4,748.091l-5.219.219-.531-1.5,2.03-1.837,3.867.193Z"
                  transform="translate(-409.65 -744.969)"
                  fill="#000719"
                />
              </g>
            </g>
            <g
              id="Group_997"
              data-name="Group 997"
              transform="translate(0 19.923)"
            >
              <g id="Group_996" data-name="Group 996">
                <path
                  id="Path_1976"
                  data-name="Path 1976"
                  d="M424.422,434.34l-11.158-1.2s-6.207.663-10.419,15.4c-2.343,8.2-3.056,21.123-3.228,30.974.263,1.405,1.794,7.561,8.268,6.516,11.117-1.793,17.034-3.586,21.875-2.69,4.124.9,3.775-4.781,3.322-9.114l-1.643-13.407Z"
                  transform="translate(-399.617 -433.145)"
                  fill="#7fc5a3"
                />
              </g>
            </g>
            <g
              id="Group_1000"
              data-name="Group 1000"
              transform="translate(8.138)"
            >
              <g
                id="Group_998"
                data-name="Group 998"
                transform="translate(3.239 6.382)"
              >
                <path
                  id="Path_1977"
                  data-name="Path 1977"
                  d="M446.162,392.012c-2.812-.62-5.707-1.175-8.522-.618a2.352,2.352,0,0,0-1.613.869,2.451,2.451,0,0,0-.261.679c-.711,2.641-1.612,6.555.127,8.989a4.349,4.349,0,0,0,1.255,1.132,6.184,6.184,0,0,0,2.08.816,11.107,11.107,0,0,1-.63,1.918c.644.971,3.647,1.087,4.2.157q.047-1.208.094-2.416a5.957,5.957,0,0,0,1.05-.546,6.786,6.786,0,0,0,2.621-3.563c.535-1.563,1.287-3.048,1.776-4.641a2.244,2.244,0,0,0,.013-1.767,2.391,2.391,0,0,0-1.657-.889Z"
                  transform="translate(-434.889 -391.164)"
                  fill="#eea987"
                />
              </g>
              <g id="Group_999" data-name="Group 999">
                <path
                  id="Path_1978"
                  data-name="Path 1978"
                  d="M440.592,385.94c1.351-1.388,2.932-3.328,3.055-5.09a8.332,8.332,0,0,0-.917-4.7c-.764-1.177-2.717-1.824-3.879-2.507l-3.285-1.93a1.661,1.661,0,0,0-1.646-.212l-7.764,2.007c-.5.128-1.064.316-1.247.8a1.629,1.629,0,0,0,.021.923,38.157,38.157,0,0,1,1,4.078c.215,1.075.174,2.036.959,2.822.739.74,1.723,1.143,2.314,2.049a17.324,17.324,0,0,1,.926,2.146c1.516,3.2,7.652,2.344,9.775.3C440.118,386.417,440.352,386.188,440.592,385.94Z"
                  transform="translate(-424.848 -371.379)"
                  fill="#000719"
                />
              </g>
            </g>
          </g>
          <g
            id="Group_1002"
            data-name="Group 1002"
            transform="translate(18.527)"
          >
            <path
              id="Path_1979"
              data-name="Path 1979"
              d="M458.995,389.523l10.6-3.878,8.629-20.811,3.674.219L477.911,388.4l-5.4,5.045-15.454,7.614Z"
              transform="translate(-457.057 -364.834)"
              fill="#7fc5a3"
            />
          </g>
        </g>
        <g
          id="Group_1008"
          data-name="Group 1008"
          transform="translate(179.391 250.945)"
        >
          <g id="Group_1006" data-name="Group 1006">
            <g id="Group_1005" data-name="Group 1005">
              <path
                id="Path_1980"
                data-name="Path 1980"
                d="M348.288,396.768c-.517-.742-.907-3.107-.381-3.929,1.213-1.9,1.884,1,2.341,1.946a5.793,5.793,0,0,1,.871-.717c.3-.18.772,3.036.6,3.445-.418.983-1.1.927-2.028,1.277C349.238,398.107,348.757,397.44,348.288,396.768Z"
                transform="translate(-347.657 -392.209)"
                fill="#eea987"
              />
            </g>
          </g>
          <g
            id="Group_1007"
            data-name="Group 1007"
            transform="translate(1.345 3.939)"
          >
            <path
              id="Path_1981"
              data-name="Path 1981"
              d="M376.252,426.387l-17-3.312-7.425-16.165,3.2-2.49,8.018,11.273,8.706-.13,8.039.57Z"
              transform="translate(-351.826 -404.419)"
              fill="#7fc5a3"
            />
          </g>
        </g>
        <g
          id="Group_1010"
          data-name="Group 1010"
          transform="translate(160.462 260.592)"
        >
          <path
            id="Path_1982"
            data-name="Path 1982"
            d="M292.158,429.04l.955-1.82c.349-.664-.119-4.266-.253-5.046-.076-.445-2.488,1.876-2.652,2.156a9.725,9.725,0,0,0-.2,2.5l-1.04.537.3,1.471Z"
            transform="translate(-288.971 -422.118)"
            fill="#eea987"
          />
        </g>
        <g
          id="Group_1024"
          data-name="Group 1024"
          transform="translate(104.104 241.87)"
        >
          <g
            id="Group_1011"
            data-name="Group 1011"
            transform="translate(18.19 119.116)"
          >
            <path
              id="Path_1983"
              data-name="Path 1983"
              d="M170.921,735.571l-.283,2.2,4.116,2.765,2.482-1.917-1.917-2.482-4.116-2.765Z"
              transform="translate(-170.638 -733.371)"
              fill="#000719"
            />
          </g>
          <g
            id="Group_1013"
            data-name="Group 1013"
            transform="translate(17.688 53.792)"
          >
            <g id="Group_1012" data-name="Group 1012">
              <path
                id="Path_1984"
                data-name="Path 1984"
                d="M169.729,597.632,172.9,569.58l-3.819-31.4.859-6.68,17.06-.648.616,10.873-2.864,27.9L173.98,599.066Z"
                transform="translate(-169.084 -530.847)"
                fill="#00143a"
              />
            </g>
          </g>
          <g
            id="Group_1014"
            data-name="Group 1014"
            transform="translate(37.209 22.754)"
          >
            <path
              id="Path_1985"
              data-name="Path 1985"
              d="M229.6,434.619l6.087,1.243,7.838,8.213,5.224-7.266,3.962.261-8.256,16.57-10.374-5.306Z"
              transform="translate(-229.603 -434.619)"
              fill="#ffc53b"
            />
          </g>
          <g
            id="Group_1017"
            data-name="Group 1017"
            transform="translate(30.84 54.214)"
          >
            <g id="Group_1015" data-name="Group 1015">
              <path
                id="Path_1986"
                data-name="Path 1986"
                d="M227.144,534.877l.045,28.308-5.7,36.442-4.1-.041-2.254-33.7-5.276-33.731Z"
                transform="translate(-209.858 -532.156)"
                fill="#00143a"
              />
            </g>
            <g
              id="Group_1016"
              data-name="Group 1016"
              transform="translate(7.537 66.943)"
            >
              <path
                id="Path_1987"
                data-name="Path 1987"
                d="M233.226,741.918l4.413,2.262,2.24-2.2-2.2-2.24-4.435-.044Z"
                transform="translate(-233.226 -739.7)"
                fill="#000719"
              />
            </g>
          </g>
          <g
            id="Group_1019"
            data-name="Group 1019"
            transform="translate(15.895 22.567)"
          >
            <g id="Group_1018" data-name="Group 1018">
              <path
                id="Path_1988"
                data-name="Path 1988"
                d="M163.553,478.035c.039-1.227.107-2.25.117-2.616.088-3.291.381-6.574.793-9.842.369-2.928.835-10.2,1.336-13.107a62.193,62.193,0,0,1,2.9-10.628c2.59-5.542,1.79-6.054,13.169-7.7,4.987-.722,6.166,2.387,10.243,5.961.4.353.38,2.451.473,3.007.165.979.34,1.956.542,2.928.159.764.334,1.526.53,2.283h0s3.423,15.577,5.686,18.885,6.94,7.933-.882,10.757a28.007,28.007,0,0,1-4.224,1.1s-6.4-.711-9.687-.686a55.9,55.9,0,0,0-9.051.843,19.48,19.48,0,0,0-5.807,1.748c-1.7.888-2.927,2.194-4.652,3.114a.9.9,0,0,1-.528.152C163.517,484.1,163.471,480.617,163.553,478.035Z"
                transform="translate(-163.526 -434.038)"
                fill="#ffc53b"
              />
            </g>
          </g>
          <g
            id="Group_1022"
            data-name="Group 1022"
            transform="translate(18.853)"
          >
            <g
              id="Group_1020"
              data-name="Group 1020"
              transform="translate(6.065 11)"
            >
              <path
                id="Path_1989"
                data-name="Path 1989"
                d="M199.8,411.283a4.681,4.681,0,0,1-3.245.821.738.738,0,0,1-.656-.523,19.175,19.175,0,0,1-.556-2.514c-1.123-.045-2.458-.219-2.961-1.225a3.172,3.172,0,0,1-.25-1.027q-.278-2.177-.556-4.355a3.863,3.863,0,0,1,.006-1.508,3.246,3.246,0,0,1,1.813-1.843,7.9,7.9,0,0,1,6.574-.389,3.462,3.462,0,0,1,1.451,1.045,4.693,4.693,0,0,1,.655,2.667l.106,2.573c.041.987.014,2.131-.761,2.743-.411.325-.979.442-1.319.841C199.5,409.292,199.939,410.37,199.8,411.283Z"
                transform="translate(-191.499 -398.177)"
                fill="#eea987"
              />
            </g>
            <g id="Group_1021" data-name="Group 1021">
              <path
                id="Path_1990"
                data-name="Path 1990"
                d="M195.152,370.648a9.361,9.361,0,0,1,.385-1.2,18.23,18.23,0,0,0,1.047-2.513,2.362,2.362,0,0,0-.687-2.475,2.816,2.816,0,0,0-1.686-.386,10.9,10.9,0,0,0-5.507,1.581c-1.572.958-2.877,2.146-4.791,2.033a6.05,6.05,0,0,0-4.389,1.616c-1.625,1.211-4.027,2.118-5.4,3.544a5.093,5.093,0,0,0-1.163,2.159,7.679,7.679,0,0,0,2.365,7.769,10.96,10.96,0,0,0,1.585,1.089,8.228,8.228,0,0,0,3.822,1.488c-.3-1.068-2.288-4.111-1.742-5.229.868-1.774,3.934-2.191,5.64-2.344,1.86-.167,3.8.592,5.611.324a6.829,6.829,0,0,0,3.569-2.262C194.876,374.645,194.773,372.139,195.152,370.648Z"
                transform="translate(-172.695 -364.074)"
                fill="#000719"
              />
            </g>
          </g>
          <g
            id="Group_1023"
            data-name="Group 1023"
            transform="translate(0 10.651)"
          >
            <path
              id="Path_1991"
              data-name="Path 1991"
              d="M138.528,411.555l-8.953,2.3L118.795,397.1l-4.55,1.228,7.585,24.123,17.453,2.1Z"
              transform="translate(-114.245 -397.097)"
              fill="#ffc53b"
            />
          </g>
        </g>
        <path
          id="Path_1992"
          data-name="Path 1992"
          d="M282.474,410.2l4.127.9a.824.824,0,0,0,.952-.526l3.623-10.068a.825.825,0,0,0-.6-1.084l-4.99-1.105a.825.825,0,0,0-.975.591l-2.759,10.27A.825.825,0,0,0,282.474,410.2Z"
          transform="translate(-123.669 -145.388)"
          fill="#d34e4e"
        />
        <path
          id="Path_1993"
          data-name="Path 1993"
          d="M113.012,387.392l-1.556-1.83c-.568-.668-.508-3.406-.632-4.265-.071-.491,2.333.467,2.589.745a12.783,12.783,0,0,1,1.021,2.67l1.218.434.184,1.629Z"
          transform="translate(-7.822 -133.779)"
          fill="#eea987"
        />
        <path
          id="Path_1994"
          data-name="Path 1994"
          d="M166.106,259.47a11.92,11.92,0,0,0,8.655-2.946,27.609,27.609,0,0,0-1.092-3.3,7.7,7.7,0,0,0-3.678-3.985c.013-.02.029-.038.041-.058a6.882,6.882,0,0,0,.91-3.74,5.979,5.979,0,0,0-.977-3.515,3.809,3.809,0,0,0-6.735.825,8.378,8.378,0,0,0-.361,3.9,6.612,6.612,0,0,0,.778,2.69,10.169,10.169,0,0,0-2.7,2.553,7.127,7.127,0,0,0-1.36,2.458c-.265.64-.527,1.3-.755,2.03A11.9,11.9,0,0,0,166.106,259.47Z"
          transform="translate(-40.347 -38.32)"
          fill="#fff"
        />
        <path
          id="Path_1995"
          data-name="Path 1995"
          d="M310.045,86.373a15.755,15.755,0,0,0,11.439-3.894,36.516,36.516,0,0,0-1.444-4.361,10.179,10.179,0,0,0-4.861-5.268c.017-.026.038-.051.055-.077a9.1,9.1,0,0,0,1.2-4.943,7.9,7.9,0,0,0-1.291-4.646,5.034,5.034,0,0,0-8.9,1.091,11.074,11.074,0,0,0-.477,5.158,8.74,8.74,0,0,0,1.029,3.555,13.431,13.431,0,0,0-3.571,3.374,9.412,9.412,0,0,0-1.8,3.249c-.35.846-.7,1.722-1,2.684A15.733,15.733,0,0,0,310.045,86.373Z"
          transform="translate(-136.272 83.135)"
          fill="#fff"
        />
        <path
          id="Path_1996"
          data-name="Path 1996"
          d="M384.778,306.514a16.947,16.947,0,0,0,12.3-4.188,39.273,39.273,0,0,0-1.553-4.69,10.947,10.947,0,0,0-5.228-5.666c.018-.028.041-.055.059-.083a9.786,9.786,0,0,0,1.293-5.317,8.5,8.5,0,0,0-1.388-5,5.415,5.415,0,0,0-9.574,1.173,11.908,11.908,0,0,0-.513,5.547,9.4,9.4,0,0,0,1.107,3.824,14.445,14.445,0,0,0-3.84,3.629,10.125,10.125,0,0,0-1.933,3.494c-.376.91-.75,1.851-1.073,2.886A16.922,16.922,0,0,0,384.778,306.514Z"
          transform="translate(-186.408 -64.7)"
          fill="#fff"
        />
        <path
          id="Path_1997"
          data-name="Path 1997"
          d="M948.785,145.033a14.626,14.626,0,0,0,10.618-3.614,33.8,33.8,0,0,0-1.34-4.048,9.446,9.446,0,0,0-4.512-4.89c.016-.025.035-.047.051-.072a8.449,8.449,0,0,0,1.116-4.589,7.331,7.331,0,0,0-1.2-4.313,4.673,4.673,0,0,0-8.263,1.013,10.277,10.277,0,0,0-.443,4.787,8.115,8.115,0,0,0,.955,3.3,12.472,12.472,0,0,0-3.314,3.132,8.732,8.732,0,0,0-1.668,3.016c-.325.785-.647,1.6-.927,2.491A14.606,14.606,0,0,0,948.785,145.033Z"
          transform="translate(-569.455 42.16)"
          fill="#fff"
        />
        <path
          id="Path_1998"
          data-name="Path 1998"
          d="M521.206,162.878a20.454,20.454,0,0,0,14.85-5.055,47.393,47.393,0,0,0-1.874-5.661,13.211,13.211,0,0,0-6.31-6.838c.022-.034.049-.066.071-.1a11.813,11.813,0,0,0,1.561-6.417,10.252,10.252,0,0,0-1.675-6.031,6.535,6.535,0,0,0-11.556,1.416,14.375,14.375,0,0,0-.619,6.7,11.35,11.35,0,0,0,1.336,4.616,17.44,17.44,0,0,0-4.635,4.38,12.218,12.218,0,0,0-2.333,4.217c-.454,1.1-.905,2.235-1.3,3.484A20.424,20.424,0,0,0,521.206,162.878Z"
          transform="translate(-277.382 36.438)"
          fill="#fff"
        />
        <path
          id="Path_1999"
          data-name="Path 1999"
          d="M733.613,68.188a11.7,11.7,0,0,0,8.5-2.893,27.084,27.084,0,0,0-1.072-3.239,7.558,7.558,0,0,0-3.61-3.913c.013-.02.028-.038.04-.057a6.759,6.759,0,0,0,.893-3.672,5.869,5.869,0,0,0-.959-3.451,3.739,3.739,0,0,0-6.612.811,8.224,8.224,0,0,0-.354,3.831,6.491,6.491,0,0,0,.764,2.641,9.979,9.979,0,0,0-2.652,2.506,6.991,6.991,0,0,0-1.335,2.413c-.26.628-.518,1.279-.742,1.993A11.689,11.689,0,0,0,733.613,68.188Z"
          transform="translate(-424.894 91.027)"
          fill="#fff"
        />
        <path
          id="Path_2000"
          data-name="Path 2000"
          d="M714.322,278.118a33.065,33.065,0,0,0,24.006-8.173,76.622,76.622,0,0,0-3.03-9.151,21.36,21.36,0,0,0-10.2-11.055c.036-.055.08-.106.115-.162a19.1,19.1,0,0,0,2.523-10.374,16.579,16.579,0,0,0-2.708-9.751,10.565,10.565,0,0,0-18.682,2.29c-1.426,3.438-1.283,7.19-1,10.823a18.342,18.342,0,0,0,2.159,7.462,28.2,28.2,0,0,0-7.493,7.081c-2.374,3.064-2.276,3.206-3.771,6.818-.735,1.775-1.463,3.613-2.095,5.632A33.019,33.019,0,0,0,714.322,278.118Z"
          transform="translate(-402.994 -27.856)"
          fill="#fff"
        />
        <g
          id="Group_1040"
          data-name="Group 1040"
          transform="translate(360.915 335.001)"
        >
          <g
            id="Group_1037"
            data-name="Group 1037"
            transform="translate(0 25.628)"
          >
            <g id="Group_1036" data-name="Group 1036">
              <path
                id="Path_2001"
                data-name="Path 2001"
                d="M912.061,732.866a1.516,1.516,0,0,1,1.371-.588,1.55,1.55,0,0,1,1.152.868,2.946,2.946,0,0,1,.2.987c.144,1.739.131,5.867-2.185,6.462a1.724,1.724,0,0,1-2.038-1.316C910.166,737.962,910.745,734.314,912.061,732.866Z"
                transform="translate(-910.437 -732.263)"
                fill="#000719"
              />
            </g>
          </g>
          <g id="Group_1039" data-name="Group 1039" transform="translate(0.8)">
            <g id="Group_1038" data-name="Group 1038">
              <path
                id="Path_2002"
                data-name="Path 2002"
                d="M912.917,652.809l.121,10.606c-.141,4.711.594,9.448.453,14.159a11.945,11.945,0,0,0-.042,3.3c.267,1.05,1.12,1.236,2,.7,1.174-.713.984-2.377,1.18-3.591.537-3.336,1.167-6.656,1.728-9.989.275-1.633-.292-3.923-.168-5.576l1.713-8.888Z"
                transform="translate(-912.917 -652.809)"
                fill="#eea987"
              />
            </g>
          </g>
        </g>
        <g
          id="Group_1042"
          data-name="Group 1042"
          transform="translate(358.926 303.405)"
        >
          <g id="Group_1041" data-name="Group 1041">
            <path
              id="Path_2003"
              data-name="Path 2003"
              d="M919.179,569.311l-4.616,20.5-.981,19.364-7.319-.164-1.99-54.163,14.189.258Z"
              transform="translate(-904.273 -554.852)"
              fill="#7fc5a3"
            />
          </g>
        </g>
        <g
          id="Group_1050"
          data-name="Group 1050"
          transform="translate(346.069 303.799)"
        >
          <g
            id="Group_1047"
            data-name="Group 1047"
            transform="translate(2.85 32.691)"
          >
            <g
              id="Group_1044"
              data-name="Group 1044"
              transform="translate(1.298 23.82)"
            >
              <g id="Group_1043" data-name="Group 1043">
                <path
                  id="Path_2004"
                  data-name="Path 2004"
                  d="M879.123,731.811a1.487,1.487,0,0,1,1.391-.507,1.581,1.581,0,0,1,1.111.936,2.978,2.978,0,0,1,.155,1c.069,1.749-.121,5.879-2.455,6.338a1.753,1.753,0,0,1-1.975-1.437C877.016,736.8,877.75,733.183,879.123,731.811Z"
                  transform="translate(-877.27 -731.274)"
                  fill="#000719"
                />
              </g>
            </g>
            <g id="Group_1046" data-name="Group 1046">
              <g id="Group_1045" data-name="Group 1045">
                <path
                  id="Path_2005"
                  data-name="Path 2005"
                  d="M880.254,657.425l-.621,10.589c-.051,2.943-.443,5.863-.748,8.785-.283,2.715.158,5.543-1.261,8.012-.445.774-1.344,1.29-1.973.349-.484-.725-.2-1.934-.29-2.757-.379-3.357-.852-6.7-1.255-10.058-.2-1.645.476-3.905.431-5.562l-1.292-8.958Z"
                  transform="translate(-873.246 -657.425)"
                  fill="#eea987"
                />
              </g>
            </g>
          </g>
          <g id="Group_1049" data-name="Group 1049">
            <g id="Group_1048" data-name="Group 1048">
              <path
                id="Path_2006"
                data-name="Path 2006"
                d="M865.007,568.884l-.6,19.9,3.124,20.128,7.445.223-.329-21.784,3.94-18.065,1.323-11.825L868.1,556.072Z"
                transform="translate(-864.411 -556.072)"
                fill="#7fc5a3"
              />
            </g>
          </g>
        </g>
        <g
          id="Group_1052"
          data-name="Group 1052"
          transform="translate(348.898 262.76)"
        >
          <g id="Group_1051" data-name="Group 1051">
            <path
              id="Path_2007"
              data-name="Path 2007"
              d="M880.295,429.143l12.727-.3,7.379,13.724-2.623,12.855-.467,15.383-18.155.248-5.974-.97,1.586-16.437-.909-21.956Z"
              transform="translate(-873.183 -428.839)"
              fill="#474ebb"
            />
          </g>
        </g>
        <g
          id="Group_1054"
          data-name="Group 1054"
          transform="translate(358.926 263.706)"
        >
          <g id="Group_1053" data-name="Group 1053">
            <path
              id="Path_2008"
              data-name="Path 2008"
              d="M914.506,431.774l10.332,15.736-1.553,8.767-16,11.085-3.011-2.633,10.7-13.523-.415-8.546Z"
              transform="translate(-904.273 -431.774)"
              fill="#474ebb"
            />
          </g>
        </g>
        <g
          id="Group_1062"
          data-name="Group 1062"
          transform="translate(348.898 242.753)"
        >
          <g
            id="Group_1056"
            data-name="Group 1056"
            transform="translate(7.554 5.573)"
          >
            <g id="Group_1055" data-name="Group 1055">
              <path
                id="Path_2009"
                data-name="Path 2009"
                d="M897.829,386.952c2.506-5.34,9.6-2.473,10.581,2.628.637,3.327-2.3,7.24-5.739,7.805-.028.125-.4,1.208-.407,1.309a10.9,10.9,0,0,1-.309,1.528,16.925,16.925,0,0,1-3.229-.786.447.447,0,0,1-.286-.182,9.52,9.52,0,0,1,.539-1.883l.154-.713a5.8,5.8,0,0,1-2.517-4.579A10.758,10.758,0,0,1,897.829,386.952Z"
                transform="translate(-896.603 -384.092)"
                fill="#eea987"
              />
            </g>
          </g>
          <g id="Group_1061" data-name="Group 1061">
            <g
              id="Group_1058"
              data-name="Group 1058"
              transform="translate(0 2.927)"
            >
              <g id="Group_1057" data-name="Group 1057">
                <path
                  id="Path_2010"
                  data-name="Path 2010"
                  d="M887.28,376.9a2.928,2.928,0,0,1,.5,1.56,2.336,2.336,0,0,1-.046.971c-.408,1.23-1.649,1.029-2.659,1.331a6.476,6.476,0,0,0-3.152,2.172c-1.223,1.549-.984,3.738-.758,5.616.08.662.276,1.654-.286,2.177a2.163,2.163,0,0,1-2.316-.054c-.527-.335-.93-.893-1.531-1.062-.636-.178-1.541.012-1.779-.614a4.036,4.036,0,0,0-.142-.54c-.3-.55-1.257-.047-1.722-.462a.99.99,0,0,1-.088-1.09,2.564,2.564,0,0,0,.331-1.111,3.78,3.78,0,0,0-.349-.931,1.75,1.75,0,0,1,.44-1.823c.9-.837,2.42-.5,3.368-1.271a3.78,3.78,0,0,0,.969-1.637,3.318,3.318,0,0,1,1.022-1.6c.643-.454,1.482-.274,2.128-.824a11.914,11.914,0,0,1,1.073-1.039,7.692,7.692,0,0,1,2.217-.713A2.767,2.767,0,0,1,887.28,376.9Z"
                  transform="translate(-873.183 -375.889)"
                  fill="#000719"
                />
              </g>
            </g>
            <g
              id="Group_1060"
              data-name="Group 1060"
              transform="translate(10.849)"
            >
              <g id="Group_1059" data-name="Group 1059">
                <path
                  id="Path_2011"
                  data-name="Path 2011"
                  d="M913.743,380.1c-.163,1.829-1.282,3.7-.615,5.4.674,1.713,2.758,2.233,4.552,2.512.3.046,4.065.785,4.089.714a12.465,12.465,0,0,1,1.641-3.551,4.082,4.082,0,0,1,3.277-1.792.786.786,0,0,1-.413-.947,6.06,6.06,0,0,1,.469-1.047,2.571,2.571,0,0,0-.39-2.639c-1.211-1.4-3.707-1.141-4.706-2.707-.619-.971-.394-2.258-.652-3.389-.5-2.188-2.759-2.877-4.383-4.016a8.316,8.316,0,0,0-5.51-1.814c-2.654.153-4.878,2.6-4.141,5.2.944,3.343,6.129,3.622,6.744,6.978A4.047,4.047,0,0,1,913.743,380.1Z"
                  transform="translate(-906.817 -366.813)"
                  fill="#000719"
                />
              </g>
            </g>
          </g>
        </g>
        <g
          id="Group_1065"
          data-name="Group 1065"
          transform="translate(356.075 262.76)"
        >
          <g id="Group_1064" data-name="Group 1064">
            <path
              id="Path_2012"
              data-name="Path 2012"
              d="M904.33,429.07,901.1,456.63h-2.9l-2.764-27.791Z"
              transform="translate(-895.432 -428.839)"
              fill="#fff"
            />
          </g>
        </g>
        <g
          id="Group_1068"
          data-name="Group 1068"
          transform="translate(352.94 296.661)"
        >
          <g id="Group_1067" data-name="Group 1067">
            <path
              id="Path_2013"
              data-name="Path 2013"
              d="M891.619,535.683l.069,2.147-3.789,1.379-2.184-1.655,0-2.5,2.108-.118.066-.55.69-.444Z"
              transform="translate(-885.713 -533.944)"
              fill="#eea987"
            />
          </g>
        </g>
        <g
          id="Group_1069"
          data-name="Group 1069"
          transform="translate(326.81 249.089)"
        >
          <path
            id="Path_2014"
            data-name="Path 2014"
            d="M833.967,400.128l-7.176.39s-8.055,3.961-8.588,3.664-9.237-17.724-9.237-17.724l-4.263,1.384,5.329,19.935,5.151,5.326,14.566-2.424Z"
            transform="translate(-804.702 -386.457)"
            fill="#474ebb"
          />
        </g>
        <g
          id="Group_1070"
          data-name="Group 1070"
          transform="translate(326.81 244.006)"
        >
          <path
            id="Path_2015"
            data-name="Path 2015"
            d="M808.618,375.927v-2.055c0-.75-1.889-2.5-2.368-3.131-.275-.359-1.532,1.6-1.547,1.921-.026.552.769,1.734.988,2.307l-.671.959.95,1.163Z"
            transform="translate(-804.702 -370.697)"
            fill="#eea987"
          />
        </g>
      </g>
    </svg>
  );
};

ClubSvg.propTypes = {};

export default ClubSvg;
