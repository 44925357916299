import React, { useState } from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Select from '@material-ui/core/Select'
import classNames from 'classnames'
import FormControl from '@material-ui/core/FormControl'
import { connect } from 'react-redux'
import { updateCoworkerStatus } from '../../Actions/coworkerActions'
import { capitalize } from '../../Utils/StringFunctions'
import { checkPermission } from '../../Utils/checkPermission'
import CoworkerStatusType from './Types/CoworkerStatus'
import DeleteAlert from './DeleteAlert'

const styles = (theme) => {
  return {
    active: {
      backgroundColor: '#fff',
      color: '#4CAF50',
      borderColor: '#4CAF50',
    },
    inactive: {
      backgroundColor: '#fff',
      color: '#F44336',
      borderColor: '#F44336',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 50,
    },
    statusDropdown: {
      border: `1px solid`,
      borderRadius: `${theme.shape.borderRadius * 4}px`,
      height: '25px',
      padding: '12px 11px 13px 13px',
      fontSize: '10px',
      fontWeight: '500',
    },
    icon: {
      color: '#000',
      right: '10px',
      top: '1px',
    },
  }
}
const CoworkerStatus = (props) => {
  const [selected, setSelected] = useState(props.selectedItem || 'active')
  const [showConfirmDel, setshowConfirmDel] = useState(false)

  const { classes, id } = props

  const handleDeletePlan = () => {
    props.updateCoworkerStatus(id, {
      status: 'inactive',
    })
    setSelected('inactive')
  }
  const callStatusChangeAPI = (el) => {
    // eslint-disable-next-line no-shadow
    // props.updateCoworkerStatus(id, {
    //   status: el.target.value,
    // })
    // setSelected(el.target.value)
    if (el.target.value === 'inactive') {
      setshowConfirmDel(true)
    } else {
      props.updateCoworkerStatus(id, {
        status: el.target.value,
      })
      setSelected(el.target.value)
    }
  }
  return (
    <React.Fragment>
      <FormControl variant="filled" className={classes.formControl}>
        {checkPermission('COWORKER_STATUS_CHANGE') &&
          checkPermission('USER_UPDATE') && (
            <Select
              className={classNames(classes[selected], classes.statusDropdown)}
              value={selected}
              variant="standard"
              onChange={callStatusChangeAPI}
              name="name"
              disableUnderline
              classes={{
                icon: classes.icon,
              }}
            >
              {CoworkerStatusType.map((element) => {
                return (
                  <MenuItem value={element} key={props.key}>
                    {capitalize(element)}
                  </MenuItem>
                )
              })}
            </Select>
          )}
      </FormControl>
      <DeleteAlert
        message="Are you sure you want to inactivate co-worker?"
        open={showConfirmDel}
        setOpen={setshowConfirmDel}
        alert={handleDeletePlan}
      />
    </React.Fragment>
  )
}
const mapStateToProps = (state) => {
  return {
    errors: state.errors,
    selected: state.coworkers.selected,
  }
}
export default connect(mapStateToProps, { updateCoworkerStatus })(
  withStyles(styles)(CoworkerStatus)
)
