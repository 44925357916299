import React, { useState } from "react";
import {
  Grid,
  CardContent,
  withStyles,
  Paper,
  Typography,
  Card,
} from "@material-ui/core";
import classNames from "classnames";
import NameTruncate from "../../Components/Common/NameTruncate";
import { capitalize, spacecapitalize } from "../../Utils/StringFunctions";
import RefferalCardDetailPopup from "./RefferalCardDetailPopup";

const styles = (theme) => {
  return {
    card: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      border: "1px solid #EFEFEF",
      // margin : "1px",
      boxShadow: "0 9px 10px rgba(0, 0, 0, 0.1), 0 4px 0px rgba(0,0,0,0.05)",
      transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
      // backgroundColor: "#FCFDFE",
      position: "relative",
      "&:hover": {
        transform: "scale(1.01)",
        boxShadow:
          "rgba(34, 36, 38, 0.08) 0px 2px 4px 6px, rgba(34, 36, 38, 0.15) 0px 2px 10px 0px",
      },
    },

    cardContent: {
      flexGrow: 1,
      textAlign: "center",
      "& p": {
        color: theme.palette.font.head1,
      },
      "& h2": {
        color: "#82A3FE",
      },
      "& h4": {
        color: theme.palette.font.head2,
        fontWeight: "normal",
      },
      "&:last-child": {
        paddingBottom: "10px",
      },
      paddingTop: "10px",
    },

    coworkerInfo: {
      paddingTop: "13px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      justify: "left",
      alignItems: "left",
      "& p": {
        fontSize: "14px",
      },
    },
    skills: {
      paddingTop: "3px",
      color: "#ababab",
      fontSize: "12px",
      fontWeight: "400",
    },
    coworkerName: {
      textTransform: "capitalize",
      color: "#222222 !important",
      fontWeight: "500",
      textAlign: "left",
    },
    headerText: {
      fontSize: "16px",
    },
    alignLeft: {
      textAlign: "left",
    },
    fieldValue: {
      color: "#626262",
      fontSize: "12px",
      fontWeight: "500",
    },
    companyName: {
      fontSize: "16px",
      fontWeight: "400",
      color: "#222222 !important",
      textAlign: "left",
    },
    moreText: {
      color: "#1492E6",
      fontSize: "12px",
      fontWeight: "500",
      textAlign: "right",
      cursor: "pointer",
      paddingTop: "10px",
    },
  };
};

function RefferalCard(props) {
  const { classes, data } = props;
  const [modalOpen, setmodalOpen] = useState(false);
  const handleClose = () => {
    setmodalOpen(false);
  };
  const openPopupDetail = () => {
    setmodalOpen(true);
  };
  return (
    <Card className={classes.card}>
      <Paper className={classes.containerPaper}>
        <CardContent className={classes.cardContent}>
          <Grid container spacing={2}>
            <Grid spacing={3}>
              <Typography
                component="h2"
                className={classNames(classes.coworkerName, classes.headerText)}
              >
                {capitalize(data.name)} |{" "}
                <span className={classes.companyName}>
                  {data.company_name ? capitalize(data.company_name) : "-"}
                </span>
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.coworkerInfo} spacing={2}>
            <Grid xs={6} sm={6} className={classes.alignLeft}>
              <Typography variant="subtitle2" className={classes.skills}>
                Contact Details
              </Typography>
              <Typography
                variant="h3"
                component="p"
                className={classes.fieldValue}
              >
                {data.contact_number}
              </Typography>
            </Grid>

            <Grid xs={6} sm={6} className={classes.alignLeft}>
              <Typography variant="subtitle2" className={classes.skills}>
                Email ID
              </Typography>
              <Typography
                variant="h3"
                component="p"
                className={classes.fieldValue}
              >
                <NameTruncate text={data.email} amount={13} />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.coworkerInfo} spacing={2}>
            <Grid xs={6} sm={6} className={classes.alignLeft}>
              <Typography variant="subtitle2" className={classes.skills}>
                Location
              </Typography>
              <Typography
                variant="h3"
                component="p"
                className={classes.fieldValue}
              >
                {data.location ? capitalize(data.location) : "-"}
              </Typography>
            </Grid>

            <Grid xs={6} sm={6} className={classes.alignLeft}>
              <Typography variant="subtitle2" className={classes.skills}>
                Product
              </Typography>
              <Typography
                variant="h3"
                component="p"
                className={classes.fieldValue}
              >
                {data.product ? spacecapitalize(data.product) : "-"}
              </Typography>
            </Grid>
          </Grid>

          <Grid container className={classes.coworkerInfo} spacing={2}>
            {data.comments && data.comments.length > 0 && (
              <Grid xs={12} sm={12} className={classes.alignLeft}>
                <Typography variant="subtitle2" className={classes.skills}>
                  Comments
                </Typography>

                {data.comments.slice(0, 1).map((item) => (
                  <Typography
                    variant="h3"
                    component="p"
                    className={classes.fieldValue}
                  >
                    <NameTruncate text={item.comment} amount={55} />
                  </Typography>
                ))}
              </Grid>
            )}
          </Grid>

          {data.comments && data.comments.length > 0 && (
            <Grid
              className={classes.moreText}
              onClick={() => openPopupDetail()}
            >
              View More
            </Grid>
          )}

          <RefferalCardDetailPopup
            close={handleClose}
            data={modalOpen}
            // eventID={data._id}
            detailData={data}
          />
        </CardContent>
      </Paper>
    </Card>
  );
}
export default withStyles(styles)(RefferalCard);
