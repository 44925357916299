import axios from "axios";
import {
  GET_ALL_ISSUE_CATEGORY,
  ISSUE_CATEGORY_LOADING,
  ISSUE_CATEGORY_ERROR,
  ADD_ISSUE_CATEGORY,
  EDIT_ISSUE_CATEGORY,
  EDIT_ISSUE_CATEGORY_ERRORS,
  GET_SINGLE_ISSUE_CATEGORY,
  GET_ERRORS
} from "./types";
import { showSuccess, showError } from "./ActionsHelper";
import { transformErrorsFromApi } from "./TransformError";

//  Loading
export const setIssueCategoryLoading = () => {
  return {
    type: ISSUE_CATEGORY_LOADING
  };
};

export const addIssueCategory = (
  userData,
  setErrors,
  history,
  setSubmitting
) => dispatch => {
  dispatch(setIssueCategoryLoading());
  axios
    .post("/api/helpdeskcategory/", userData)
    .then(result => {
      dispatch({
        type: ADD_ISSUE_CATEGORY,
        payload: result.data
      });
      history.push("/issue-category");
      dispatch(showSuccess("Issue Category"));
      setSubmitting(false);
    })
    .catch(err => {
      dispatch({
        type: ISSUE_CATEGORY_ERROR,
        payload: err && err.response ? err.response.data.data : ""
      });
      dispatch(showError(err.response.data.data));
    });
};

export const getAllIssueCategory = department => dispatch => {
  dispatch(setIssueCategoryLoading());
  let url = "/api/helpdeskcategory/";
  if (department) {
    url = `/api/helpdeskcategory?department=${department}`;
  }
  axios
    .get(url)
    .then(res => {
      dispatch({
        type: GET_ALL_ISSUE_CATEGORY,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: ISSUE_CATEGORY_ERROR,
        payload: err.response && err.response.data
      })
    );
};

//GET SINGLE RESOURCE TYPE
export const getSingleIssueCategory = id => dispatch => {
  dispatch(setIssueCategoryLoading());
  axios
    .get(`/api/helpdeskcategory/${id}`)
    .then(res => {
      dispatch({
        type: GET_SINGLE_ISSUE_CATEGORY,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data
      })
    );
};

export const editIssueCategory = (
  id,
  data,
  setErrors,
  setSubmitting
) => dispatch => {
  dispatch(setIssueCategoryLoading());
  axios
    .put(`/api/helpdeskcategory/${id}`, data)
    .then(result => {
      dispatch({
        type: EDIT_ISSUE_CATEGORY,
        payload: result.data
      });
      dispatch(showSuccess("", "Issue Category edited successfully!"));
      setSubmitting(false);
    })
    .catch(err => {
      const error = err.response;
      dispatch({ type: EDIT_ISSUE_CATEGORY_ERRORS, payload: {} });
      setSubmitting(false);
      setErrors(transformErrorsFromApi(error));
    });
};
