/* eslint-disable no-shadow */
import React, { useEffect, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { SelectField } from "../Form";
import { getAllIssueDepartment } from "../../../Actions/issueDepartmentActions";
import { spacecapitalize, capitalize } from "../../../Utils/StringFunctions";

const styles = () => ({});

const IssueDepartmentDropDown = (props) => {
  const { values, setFieldTouched, errors, touched } = props;
  const [allIssueDepartment, setallissueDepartment] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);

  const isUpperCase = (str) => {
    return str === str.toUpperCase();
  };

  // eslint-disable-next-line no-shadow
  const getAvailableCompanyOptions = (allIssueDepartment) => {
    return allIssueDepartment
      ? // eslint-disable-next-line camelcase
        // eslint-disable-next-line no-shadow
        allIssueDepartment.map((allIssueDepartment) => {
          if (isUpperCase(allIssueDepartment.helpdeskdepartment_name)) {
            return {
              value: allIssueDepartment._id,
              label: allIssueDepartment.helpdeskdepartment_name,
            };
          }
          return {
            value: allIssueDepartment._id,
            label: spacecapitalize(allIssueDepartment.helpdeskdepartment_name),
          };
        })
      : [];
  };

  useEffect(() => {
    props.getAllIssueDepartment();
  }, []);

  useEffect(() => {
    setallissueDepartment(props.allIssueDepartment);
    if (values && values.helpdeskdepartment) {
      // eslint-disable-next-line no-unused-expressions
      props.allIssueDepartment &&
        props.allIssueDepartment.length > 0 &&
        props.allIssueDepartment.map((department) => {
          if (department._id === values.helpdeskdepartment) {
            selectedValue.push({
              value: department._id,
              label: capitalize(department.helpdeskdepartment_name),
            });
          }
          return null;
        });

      setSelectedValue(selectedValue);
    }
  }, [props.allIssueDepartment]);

  const changeDropDown = (name, value) => {
    setSelectedValue(value);
    props.onChange(name, value);
  };
  return (
    <SelectField
      value={selectedValue}
      name="helpdeskdepartment"
      id="helpdeskdepartment"
      label="Department"
      onChange={changeDropDown}
      onBlur={setFieldTouched}
      placeholder="Select..."
      error={errors.helpdeskdepartment}
      touched={touched.helpdeskdepartment}
      options={getAvailableCompanyOptions(allIssueDepartment)}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    errors: state.errors,
    allIssueDepartment: state.all_issue_department.all_issue_department,
  };
};

export default connect(mapStateToProps, { getAllIssueDepartment })(
  withStyles(styles)(IssueDepartmentDropDown)
);
