import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router-dom";
import {
  Card,
  CssBaseline,
  Typography,
  FormGroup,
  FormLabel,
  Button,
  Grid,
} from "@material-ui/core";
import OrderProcessStatus from "../../Components/Common/OrderProcessStatus";
import { capitalize } from "../../Utils/StringFunctions";

const moment = require("moment");

const styles = (theme) => ({
  main: {
    padding: "24px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    boxShadow: "0 9px 10px rgba(0, 0, 0, 0.1), 0 4px 0px rgba(0,0,0,0.05)",
    transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
    backgroundColor: "#FCFDFE",
    position: "relative",
    border: "1px solid #f3f3f3",
    "&:hover": {
      transform: "scale(1.01)",
      boxShadow:
        "rgba(34, 36, 38, 0.08) 0px 2px 4px 6px, rgba(34, 36, 38, 0.15) 0px 2px 10px 0px",
    },
  },
  newOrderCard: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    boxShadow: "0 9px 10px rgba(0, 0, 0, 0.1), 0 4px 0px rgba(0,0,0,0.05)",
    transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
    backgroundColor: "#ece9e9",
    position: "relative",
    border: "1px solid #f3f3f3",
    "&:hover": {
      transform: "scale(1.01)",
      boxShadow:
        "rgba(34, 36, 38, 0.08) 0px 2px 4px 6px, rgba(34, 36, 38, 0.15) 0px 2px 10px 0px",
    },
  },
  personName: {
    fontWeight: "600",
    fontSize: "18px",
    color: "#626262",
    textTransform: "capitalize",
  },
  orderMainDetail: {
    display: "flex",
    paddingBottom: "15px",
  },
  customerDetail: {
    margin: "16px",
    borderBottom: "1px solid #efefef",
  },
  orderCode: {
    color: "#626262",
    fontSize: "15px",
    fontWeight: "400",
  },
  orderStatus: {
    fontWeight: "normal",
    fontSize: "15px",
    paddingTop: "0.8%",
  },
  pending: {
    color: "#f44336",
  },
  inprocess: {
    color: "#FFC312",
  },
  prepared: {
    color: "#4CAF50",
  },
  orderItems: {
    display: "flex",
    margin: "16px",
    width: "auto",
  },
  formControl: {
    margin: theme.spacing(3),
  },
  orderQuantity: {
    padding: "9px",
  },
  cardFooter: {
    display: "flex",
    margin: " 0 auto",
    alignItems: "flex-end",
    flexGrow: 1,
    paddingBottom: "20px",
  },
  orderButton: {
    borderRadius: "50px",
    marginRight: "10px",
    color: "#fff",
    height: "30px",
    padding: "1px 12px",
    backgroundColor: "#f44336",
    "&:hover": {
      backgroundColor: "#f44336",
      color: "#fff",
    },
  },
  foodName: {
    textTransform: "capitalize",
    marginLeft: "0px",
    marginRight: "0px",
    padding: "10px",
  },
  customize: {
    textTransform: "capitalize",
    marginLeft: "10px",
    marginRight: "0px",
    color: "blue",
    fontSize: "12px",
    lineHeight: "15px",
  },
  Item: {
    textTransform: "capitalize",
    marginLeft: "9px",
    marginRight: "0px",
    padding: "5px",
    fontSize: 16,
  },
  foodNameText: { marginLeft: "3.6%", width: "100%" },
  rejectedfood: {
    textTransform: "capitalize",
    paddingTop: "2px",
  },
  quantity: {
    paddingRight: "30%",
  },
  spanItem: {
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: "3.6%",
    paddingRight: "3.6%",
  },
  spanQuantity: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 35px 0px 0px",
  },
  quantityAlignment: {
    display: "flex",
    alignItems: "center",
    fontSize: "15px",
  },
  spanLabel: {
    fontSize: "15px",
    marginBottom: "5%",
  },
  foodItem: {
    margin: "5% 2%",
  },
  acceptQuantity: {
    display: "flex",
    justifyContent: "space-between",
    padding: "8px 35px 8px 12px",
    textTransform: "capitalize",
  },
  statusButton: {
    borderRadius: "50px",
    borderColor: "#ABABAB",
    color: "#626262",
  },
  rejectorder: {
    color: "red",
  },
});

class PendingOrders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItems: [],
    };
  }

  handleChange = () => (event) => {
    const { selectedItems } = this.state;
    if (event.target.checked) {
      selectedItems.push(event.target.value);
    } else {
      for (let i = 0; i < selectedItems.length; i + 1) {
        if (selectedItems[i] === event.target.value) {
          selectedItems.splice(i, 1);
        }
      }
    }
    this.setState({ selectedItems });
  };

  handleAcceptReject = (id, type) => {
    // TODO - partial reject accept:
    // currently selected items are all items but logic to select deselect order is already implemented
    const allSelectedItems = [];
    this.props.data.orderitem.map((item) => {
      allSelectedItems.push(item.food_item._id);
      return null;
    });

    const data = { status: type, order_id: id, order: allSelectedItems };
    // call accept reject api
    this.props.acceptRejectOrder(id, data);
  };

  renderStatusComponent = (data) => {
    const { classes } = this.props;
    if (data.status === "reject") {
      return (
        <Grid
          container
          direction="row"
          justify="space-evenly"
          alignItems="center"
          className={classes.cardFooter}
        >
          <FormLabel className={classes.rejectorder}>Order Rejected</FormLabel>
        </Grid>
      );
    }
    if (data.order_process_status === "pending") {
      return (
        <Grid
          container
          direction="row"
          justify="space-evenly"
          alignItems="center"
          className={classes.cardFooter}
        >
          <Button
            variant="outlined"
            className={classes.statusButton}
            size="small"
            onClick={() =>
              this.handleAcceptReject(
                data._id,
                "accept",
                this.state.selectedItems
              )
            }
          >
            Accept Order
          </Button>
          <Button
            variant="outlined"
            className={classes.statusButton}
            size="small"
            onClick={() => this.handleAcceptReject(data._id, "reject")}
          >
            Reject Order
          </Button>
        </Grid>
      );
    }
    if (data.order_process_status === "reject") {
      return <Typography component="caption">Order Rejected</Typography>;
    }
    return (
      <div className={classes.cardFooter} align="center">
        <OrderProcessStatus
          selectedItem={data.order_process_status}
          id={data._id}
          key={data._id}
          changeStatus={this.props.changeStatus}
        />
      </div>
    );
  };

  renderFoodItemComponent = (data) => {
    const { classes } = this.props;
    // eslint-disable-next-line no-constant-condition
    if (data.order_process_status === "pending" || "inprocess" || "prepared") {
      return (
        data.orderitem &&
        data.orderitem.map((item) => {
          return data.status !== "reject" ? (
            <Grid>
              <span className={classes.spanQuantity}>
                {/* <FormControlLabel
                className={classes.foodName}
                control={
                  <Checkbox
                    checked={this.state.selectedItems[i]}
                    onChange={this.handleChange(i)}
                    value={item.food_item._id}
                    color="primary"
                  />
                }
                label={item.food_item && item.food_item.food_name}
              /> */}
                <FormLabel className={classes.foodName}>
                  {item.food_item && item.food_item.food_name}
                </FormLabel>

                <FormLabel className={classes.quantityAlignment}>
                  {item.quantity}
                </FormLabel>
              </span>
              <Grid className={classes.customize}>
                {item.order_preparation === "jain" ? "Jain" : ""}
              </Grid>
              <Grid className={classes.customize}>
                {item.is_cheese ? "Cheese" : ""}
              </Grid>
              <Grid className={classes.customize}>
                {item.is_butter ? "Butter" : ""}
              </Grid>
            </Grid>
          ) : (
            <span className={classes.spanQuantity}>
              <FormLabel className={classes.Item}>
                {item.food_item.food_name}
              </FormLabel>
              <FormLabel className={classes.quantityAlignment}>
                {item.quantity}
              </FormLabel>
            </span>
          );
        })
      );
    }
    return (
      data.orderitem &&
      data.orderitem.map((item) => {
        return (
          <span className={classes.acceptQuantity}>
            <FormLabel className={classes.fooditemAlignment}>
              {item.food_item && item.food_item.food_name}
            </FormLabel>
            <FormLabel className={classes.fooditemAlignment}>
              {item.order_preparation === "jain" ? "Jain" : ""}
            </FormLabel>
            <FormLabel className={classes.fooditemAlignment}>
              {item.is_cheese ? "Cheese" : ""}
            </FormLabel>
            <FormLabel className={classes.fooditemAlignment}>
              {item.is_butter ? "Butter" : ""}
            </FormLabel>
            <FormLabel className={classes.quantityAlignment}>
              {item.quantity}
            </FormLabel>
          </span>
        );
      })
    );
  };

  render() {
    const { classes, data, newOrderData } = this.props;
    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.header} />
        <Card className={newOrderData ? classes.newOrderCard : classes.card}>
          <div className={classes.customerDetail}>
            <Typography component="h2" className={classes.personName}>
              {data.created_by && data.created_by.first_name} |{" "}
              {data.created_by && data.created_by.contact_number}
              {/* {newOrderData ? "|NEW" : ""} */}
            </Typography>
            <Typography component="h2" className={classes.orderMainDetail}>
              {data.createdAt && moment(data.createdAt).format("DD/MM/YYYY")} |{" "}
              {data.createdAt && moment(data.createdAt).format("LT")}
            </Typography>
            <div className={classes.orderMainDetail}>
              <Typography variant="subtitle1" className={classes.orderCode}>
                #{data.order_number}
              </Typography>

              {data.status !== "reject" && (
                <Typography
                  variant="subtitle2"
                  className={classNames(
                    classes[data.order_process_status],
                    classes.orderStatus
                  )}
                >
                  &nbsp;| {capitalize(data.order_process_status)}
                </Typography>
              )}
            </div>
          </div>
          <FormGroup className={classes.foodItem}>
            <span className={classes.spanItem}>
              <FormLabel className={classes.spanLabel}>Items</FormLabel>
              <FormLabel className={classes.spanLabel}>Quantity </FormLabel>
            </span>
            {this.renderFoodItemComponent(data)}
          </FormGroup>
          {this.renderStatusComponent(data)}
        </Card>
      </React.Fragment>
    );
  }
}
PendingOrders.propTypes = {};
const mapStateToProps = () => {
  return {};
};
export default connect(
  mapStateToProps,
  {}
)(withStyles(styles)(withRouter(PendingOrders)));
