// these are the types we get from backend
export const ACTIVE = "active";
export const INACTIVE = "inactive";
export const DRAFT = "draft";

// these are the types we show on frontend
export const view = {
  ACTIVE: "active",
  INACTIVE: "inactive",
  DRAFT: "draft",
};

// eslint-disable-next-line consistent-return
export const showStatus = (status) => {
  switch (status) {
    case ACTIVE:
      return view.ACTIVE;

    default:
      break;
  }
};
