import React from "react";
import Grid from "@material-ui/core/Grid";
import { withRouter } from "react-router-dom";
import { Typography, withStyles, Button } from "@material-ui/core";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import {
  addSpace,
  getSpaces,
  getSpace,
  updateSpace,
} from "../../Actions/spaceActions";
import InputField from "../../Components/Common/Form/InputField";
import UploadField from "../../Components/Common/Form/UploadField";
import SelectField from "../../Components/Common/Form/SelectField";

const styles = () => ({
  button: {
    width: "148px",
    height: "46px",
    marginRight: "25px",
    backgroundColor: "#dddddd",
    color: "#151039",
    "&:hover": {
      color: "#151039",
      backgroundColor: "#d1d1d1!important",
    },
  },
  Addbutton: {
    width: "148px",
    height: "46px",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  formfooter: {
    margin: "24px 0px",
  },
  dropinsidetext: {
    marginTop: "30px",
  },
});

const CityOptions = [
  { value: "ahmedabad", label: "Ahmedabad" },
  { value: "baroda", label: "Baroda" },
  { value: "Hyderabad", label: "Hyderabad" },
  { value: "mumbai", label: "Mumbai" },
  { value: "Indore", label: "Indore" },
  { value: "Goa", label: "Goa" },
];
const SpaceSchema = Yup.object().shape({
  space_title: Yup.string()
    .max(15, "")
    .matches(/^.[a-zA-Z0-9 ]+$/, "Enter Valid Name")
    .trim()
    .required("Please enter space title"),
  // address_link:Yup.string()
  // .max(100,"")
  building_name: Yup.string().trim().required("This field is required."),
  area: Yup.string().trim().required("This field is required."),
  city: Yup.string().required("This field is required."),
  total_seats: Yup.number()
    .typeError("Please input a number.")
    .required("This field is required."),
  total_square_feet: Yup.number().typeError("Please input a number."),
});

class SpaceForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id:
        this.props.match && this.props.match.params
          ? this.props.match.params.id
          : "",
      // eslint-disable-next-line react/no-unused-state
      errors: {},
      deletedKey: [],
    };
    // this.state.id = this.props.match.params.id;
    this.handleChange = this.handleChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    if (this.state.id) {
      this.props.getSpace(this.state.id);
    }
  }

  shouldComponentUpdate() {
    if (this.state.deletedKey.length > 0) {
      return false;
    }
    return true;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.space !== this.props.space) {
      if (this.props.space.success) {
        this.props.getSpaces();
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.space !== prevState.space) {
      return { space: nextProps.space };
    }
    return {};
  }

  handleClose = () => {
    const path = "/spaces";
    this.props.history.push(path);
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  onEditorStateChange = (html) => {
    this.setState({
      // eslint-disable-next-line react/no-unused-state
      about: html,
    });
  };

  geIntialValues = (id) => {
    const { space } = this.props;

    if (id && space) {
      return {
        space_title: space.space_title,
        building_name: space.building_name,
        banner: space.banner,
        postal_address: space.postal_address,
        area: space.area,
        city: CityOptions.filter((option) => option.value === space.city)[0],
        total_seats: space.total_seats,
        total_square_feet: space.total_square_feet,
        address_link: space.address_link,
      };
    }
    return {
      space_title: "",
      building_name: "",
      banner: "",
      postal_address: "",
      area: "",
      city: [],
      total_seats: "",
      total_square_feet: "",
      address_link: "",
    };
  };

  setDelImageUrl = (url) => {
    const { deletedKey } = this.state;
    this.setState({ deletedKey: deletedKey.push(url) });
  };

  render() {
    const { classes } = this.props;
    const { id } = this.state;

    return (
      <React.Fragment>
        <div
          className={classes.lightbg}
          style={{ padding: "20px 30px", backgroundColor: "#fff" }}
        >
          <Typography
            variant="h6"
            gutterBottom
            style={{ marginBottom: "30px" }}
          >
            {id ? "Edit Space" : "Add Space"}
          </Typography>
          <Formik
            initialValues={this.geIntialValues(id)}
            enableReinitialize
            validationSchema={SpaceSchema}
            onSubmit={(values, { setErrors, setSubmitting }) => {
              // eslint-disable-next-line no-param-reassign
              values.created_by = "5ccfd7c8a9433000c8d7f261";
              const val = values.city ? values.city.value : "";
              // eslint-disable-next-line no-param-reassign
              values.city = val;
              if (id) {
                this.props.updateSpace(
                  id,
                  values,
                  setErrors,
                  setSubmitting,
                  this.props.history
                );
              } else {
                this.props.addSpace(
                  values,
                  setErrors,
                  setSubmitting,
                  this.props.history
                );
              }
            }}
          >
            {({ values, setFieldValue, setFieldTouched, touched, errors }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6}>
                    <InputField
                      name="space_title"
                      label="Space Title"
                      placeholder="Enter Space Name"
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <InputField
                      name="building_name"
                      label="Building Name"
                      placeholder="Enter Building Name"
                    />
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <UploadField
                      label="Upload Banner"
                      name="banner"
                      id="banner"
                      onChange={setFieldValue}
                      value={values.banner}
                      folder="space/banner"
                      delImageUrl={this.setDelImageUrl}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <InputField
                      name="postal_address"
                      label="Postal Address"
                      placeholder="Enter Postal Address"
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <InputField
                      name="area"
                      label="Area"
                      placeholder="Enter Building Area"
                      className={classes.dropinsidetext}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <SelectField
                      value={values.city}
                      name="city"
                      id="city"
                      label="City"
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      multi={false}
                      error={errors.city}
                      touched={touched.city}
                      options={CityOptions}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <InputField
                      name="total_seats"
                      label="Seats"
                      placeholder="Enter Total Seats"
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <InputField
                      name="total_square_feet"
                      label="Area"
                      placeholder="Enter Total Square Feet"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <InputField
                      name="address_link"
                      label="Address link"
                      placeholder="Enter Address Link"
                      maxLength="100"
                    />
                  </Grid>
                </Grid>
                <div className={classes.formfooter}>
                  <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    display="flex"
                    justify="flex-end"
                    alignItems="center"
                  >
                    <Button
                      color="primary"
                      onClick={this.handleClose}
                      className={classes.button}
                      style={{ right: "-12px" }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      className={classes.Addbutton}
                    >
                      {id ? "Edit Space" : "Add Space"}
                    </Button>
                  </Grid>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    space: state.spaces.space,
  };
};

export default connect(mapStateToProps, {
  addSpace,
  getSpaces,
  getSpace,
  updateSpace,
})(withStyles(styles)(withRouter(SpaceForm)));
