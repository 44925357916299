import {
  ADD_SUGGEST,
  LIST_SUGGEST_ERROR,
  UPDATE_SUGGEST_STATUS,
  LIKE_SUGGEST,
  LIKE_SUGGEST_ERROR,
  GET_ALL_SUGGESTION,
  LIST_SUGGEST_LOADING,
} from "../Actions/types";

const initialState = {
  all_suggestions: [],
  loading: false,
  suggesion: null,
  suggestions: [],
  all_suggesion: [],
  like_suggesion: [],
  suggesion_status: {},
};
export default function (state = initialState, action) {
  switch (action.type) {
    case LIST_SUGGEST_LOADING:
      return {
        ...state,
        loading: true,
      };
    case LIST_SUGGEST_ERROR:
      return {
        ...state,
        loading: false,
        suggesion: {
          success: false,
          data: action.payload && action.payload.data,
        },
      };
    case GET_ALL_SUGGESTION:
      return {
        ...state,
        loading: false,
        all_suggesion: action.payload && action.payload.data,
      };
    case UPDATE_SUGGEST_STATUS:
      return {
        ...state,
        loading: false,
        suggesion_status: {
          success: true,
          data: action.payload && action.payload.data,
        },
      };
    case ADD_SUGGEST:
      return {
        ...state,
        loading: false,
        suggestions: { success: true, data: action.payload },
      };
    case LIKE_SUGGEST:
      return {
        ...state,
        loading: false,
        like_suggesion: action.payload && action.payload.data,
      };
    case LIKE_SUGGEST_ERROR:
      return {
        ...state,
        loading: false,
        suggesion: action.payload && action.payload.data,
      };
    default:
      return state;
  }
}
