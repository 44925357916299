import React from "react";
import { withStyles, Grid, Paper, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import AboutAmassador from "./AboutAmbassador";
// eslint-disable-next-line import/no-named-as-default
import RocstarAmbassador from "./RocstarAmbassador";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: "20px",
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: "89vh",
    overflowY: "scroll",
    overflowX : "hidden"
  },
  mainpaper: {
    padding: "20px",
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: "89vh",
    overflowY: "scroll",
    overflowX : "hidden"
  },
  TextLayout: {
    paddingBottom: "3%",
  },
  mainTitle: {
    fontWeight: "600",
    fontSize: "40px",
    textAlign: "left",
    color: "#222222",
    margin: "0px",
    lineHeight: "45px",
  },
  descText: {
    fontSize: "15px",
    textAlign: "left",
    color: "#626262",
    marginTop: "5px",
  },
  AmbassadorLayout: {
    padding: "5% 0% 0%",
    display: "flex",
  },
  contentLayout: {
    margin: "0px 25px 0px 0px",
  },
  imageDiv: {
    border: "1px solid #EFEFEF",
    borderRadius: "5px",
  },
  setupImg: {
    padding: "10%",
  },
  divtitle: {
    fontSize: "25px",
  },
  subTitle: {
    margin: "0% 0% 10%",
    textAlign: "center",
    fontWeight: "600",
    lineHeight: "18px",
    fontSize: "16px",
  },
});

class AdminAmbassador extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <main className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Paper className={classes.mainpaper}>
                <Grid className={classes.TextLayout}>
                  <Typography
                    align="center"
                    variant="h1"
                    className={classes.mainTitle}
                  >
                    <div>Community Ambassador</div>
                  </Typography>
                </Grid>
                <Grid className={classes.Ambassadorayout}>
                  <AboutAmassador />
                </Grid>
                <Grid className={classes.Ambassadorayout}></Grid>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.paper}  >
                <Grid className={classes.TextLayout}>
                  <Typography
                    align="center"
                    variant="h1"
                    className={classes.mainTitle}
                  >
                    <Grid className={classes.divtitle}>
                      Our Rockstar Community Ambassador
                    </Grid>
                  </Typography>
                </Grid>
                <Grid>
                  <RocstarAmbassador />
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </main>
      </React.Fragment>
    );
  }
}

AdminAmbassador.propTypes = {};

const mapStateToProps = (state) => {
  return {
    communityEmbassdor: state.communityAmbassador.community_embassadors,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withStyles(styles)(AdminAmbassador));
