import React, { useEffect, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { SelectField } from "../Form";
import { getAllRoles } from "../../../Actions/roleTypeActions";
import { capitalize } from "../../../Utils/StringFunctions";

const styles = () => ({});

const RolesDropDown = (props) => {
  const { setFieldValue, setFieldTouched, errors, touched } = props;
  const [roletypes, setRoleTypes] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);

  // eslint-disable-next-line no-shadow
  const getAvailableRoletypesOptions = (roletypes) => {
    return roletypes
      ? roletypes.map((roletype) => {
          return {
            value: roletype._id,
            label: capitalize(roletype.name),
          };
        })
      : [];
  };

  useEffect(() => {
    props.getAllRoles();
  }, []);

  useEffect(() => {
    setRoleTypes(props.roletypes);
  }, [props.roletypes]);

  useEffect(() => {
    setSelectedValue(props.values.roletypeid);
  }, [props.values]);

  const changeDropDown = (name, values) => {
    setSelectedValue(values);
    setFieldValue(name, values);
  };

  return (
    <SelectField
      value={selectedValue}
      name="roletypeid"
      id="roletypeid"
      label="Role"
      onChange={changeDropDown}
      onBlur={setFieldTouched}
      error={errors.roletypeid}
      touched={touched.roletypeid}
      options={getAvailableRoletypesOptions(roletypes)}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    errors: state.errors,
    roletypes: state.roletypes.roles,
  };
};

export default connect(mapStateToProps, { getAllRoles })(
  withStyles(styles)(RolesDropDown)
);
