import React from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router-dom";
import { Button, CssBaseline } from "@material-ui/core";
import { addfooditem } from "../../Actions/foodItemActions";
import { checkPermission } from "../../Utils";

const styles = () => ({
  addOrderButton: {
    color: "#fff",
    backgroundColor: "#ABABAB",
    borderRadius: " 5px",
    marginTop: "12px",
    marginBottom: "9px",
    "&:hover": {
      backgroundColor: "#b7b5b5",
    },
  },
  foodQuantity: {
    display: "flex",
    border: "1px solid #eae4e4",
    borderRadius: "5px",
    height: "31px",
    margin: "10px 0px",
  },
  cardMiddle: {
    display: "flex",
    justifyContent: "space-between",
  },
  orderQuantity: {
    border: "none",
    color: "#222222",
    fontSize: "1.32rem",
    fontWeight: 400,
    lineHeight: "0.8",
    minWidth: "10px",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  quantity: {
    paddingTop: "6px",
    color: "#151039 ",
  },
});

class PlaceOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderbutton: false,
      quantity: 1,
    };
  }

  handleClickOpen = () => {
    this.setState(
      {
        orderbutton: true,
        quantity: 1,
      },
      () => {
        const { data } = this.props;
        const { quantity } = this.state;
        data.quantity = quantity;
        this.props.addOrder(data);
      }
    );
  };

  IncrementItem = () => {
    this.setState(
      (prevState) => {
        if (prevState.quantity < 10) {
          return {
            quantity: prevState.quantity + 1,
          };
        }
        return null;
      },
      () => {
        if (this.state.quantity > 10) {
          return null;
        }
        const { data } = this.props;
        const { quantity } = this.state;
        data.quantity = quantity;

        this.props.addOrder(data);
        return null;
      }
    );
  };

  DecreaseItem = () => {
    this.setState(
      (prevState) => {
        if (prevState.quantity > 1) {
          return {
            quantity: prevState.quantity - 1,
          };
        }
        this.setState({
          orderbutton: false,
          quantity: 0,
        });
        return null;
      },
      () => {
        const { data } = this.props;
        const { quantity } = this.state;
        data.quantity = quantity;
        this.props.addOrder(data);
      }
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <CssBaseline />
        <div style={{ display: "flex", justifyContent: "center" }}>
          {this.state.orderbutton === false && checkPermission("ORDER_CREATE") && (
            <Button
              size="small"
              variant="contained"
              color="primary"
              className={classes.addOrderButton}
              onClick={this.handleClickOpen}
            >
              Add
            </Button>
          )}
          {this.state.orderbutton === true && (
            <div className={classes.foodQuantity}>
              <span>
                <Button
                  variant="outlined"
                  className={classes.orderQuantity}
                  onClick={this.DecreaseItem}
                >
                  -
                </Button>
              </span>
              <span className={classes.quantity}>{this.state.quantity}</span>
              <span>
                <Button
                  variant="outlined"
                  className={classes.orderQuantity}
                  onClick={this.IncrementItem}
                >
                  +
                </Button>
              </span>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}
PlaceOrder.propTypes = {};
const mapStateToProps = () => {
  return {};
};
export default connect(mapStateToProps, { addfooditem })(
  withStyles(styles)(withRouter(PlaceOrder))
);
