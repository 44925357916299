const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}
export { capitalize }

const spacecapitalize = (s) => {
  if (typeof s !== 'string') return ''
  const string = s.replace(/_/g, ' ')
  const splitStr = string.toLowerCase().split(' ')
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  return splitStr.join(' ')
  // return string.charAt(0).toUpperCase() + string.substr(1).toLowerCase();
}
export { spacecapitalize }

const firstCapitalize = (s) => {
  if (typeof s !== 'string') return ''
  const string = s.replace(/_/g, ' ')
  return string
    .split(' ')
    .map((w) => w.substring(0, 1).toUpperCase() + w.substring(1))
    .join(' ')
}
export default firstCapitalize

const specialCharacterSeparate = (s) => {
  if (typeof s !== 'string') return ''
  const str = s.replace(/_/g, ' ')
  const string = str.replace(/&/g, ' & ')
  const splitStr = string.toLowerCase().split(' ')
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  return splitStr.join(' ')
  // return string.charAt(0).toUpperCase() + string.substr(1).toLowerCase();
}
export { specialCharacterSeparate }
