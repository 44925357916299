import React from "react";
import { withStyles } from "@material-ui/core";

const styles = () => {
  return {
    social: {
      height: "40px",
      width: "40px",
      padding: "8px"
    },
    disable: {
      opacity: "0.5"
    },
    disablesocial: {
      cursor: "auto",
      height: "40px",
      width: "40px",
      padding: "8px"
    }
  };
};
const SocialButton = props => {
  const { type, url, classes, disable } = props;

  if (disable) {
    return <img src={[type]} alt="" className={classes.disablesocial} />;
  }
  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a target="_blank" href={url}>
      <img src={[type]} alt="" className={classes.social} />
    </a>
  );
};

export default withStyles(styles)(SocialButton);
