import axios from "axios";
import {
  PARTNEROFFERS_LOADING,
  PARTNEROFFERS_ERRORS,
  PARTNER_OFFERS_ERROR,
  GET_PARTNER_OFFER
} from "./types";

import { showSuccess, showError } from "./ActionsHelper";
import { transformErrorsFromApi } from "./TransformError";

export const createPartneroffers = (
  data,
  setErrors,
  setSubmitting,
  history
) => dispatch => {
  dispatch(setPartneroffersLoading());
  axios
    .post("/api/partneroffers", data)
    .then(result => {
      dispatch({
        type: PARTNER_OFFERS_ERROR,
        payload: result.data
      });
      history.push("/partners");
      dispatch(showSuccess("Offer"));
      setSubmitting(false);
    })
    .catch(err => {
      const error = err.response;
      dispatch({ type: PARTNEROFFERS_ERRORS, payload: error.data });
      setSubmitting(false);
      // setErrors(transformErrorsFromApi(error));
      history.push("/partners");
      dispatch(showError("There was some error while adding partner offers!"));
    });
};
export const getPartnerOffer = id => dispatch => {
  dispatch(setPartneroffersLoading());
  let url = `/api/partneroffers/${id}`;
  // if (search) {
  //   url = `/api/partner?q=${search}`;
  // }
  axios
    .get(url)
    .then(res => {
      dispatch({
        type: GET_PARTNER_OFFER,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: PARTNER_OFFERS_ERROR,
        payload: {}
      });
    });
};

export const updatePartnerOffer = (
  id,
  data,
  setErrors,
  setSubmitting,
  history
) => dispatch => {
  dispatch(setPartneroffersLoading());
  axios
    .put(`/api/partneroffers/${id}`, data)
    .then(res => {
      history.push("/partners");
      dispatch(showSuccess(" ", "Partner offer updated successfully!"));
      setSubmitting(false);
    })
    .catch(err => {
      const error = err.response;
      // dispatch({ type: PARTNER_ERROR, payload: error.data });
      setSubmitting(false);
      setErrors(transformErrorsFromApi(error));
    });
};

//  Loading
export const setPartneroffersLoading = () => {
  return {
    type: PARTNEROFFERS_LOADING
  };
};
