import React from 'react'
import { withStyles, Grid, Paper, AppBar, Toolbar } from '@material-ui/core'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import SearchBar from '../../Components/Common/SearchBar'
import DataNotFound from '../../Components/Common/DataNotFound'
import NoRecordsFound from '../../Components/Common/NoRecordsFound'
import { CircularLoader } from '../../Components/Common/Spinner'
import FreelancerCard from '../../Components/Common/Cards/FreelancerCard'
import { getCompanies } from '../../Actions/companiesAction'
import { checkPermission } from '../../Utils/checkPermission'
import ScreenHeader from '../../Components/Common/ScreenHeader'
import AddFormButton from '../../Components/Common/AddFormButton'

const styles = (theme) => ({
  searchSection: {
    margin: '24px 0px 8px',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    backgroundColor: 'white',
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    // marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    flexGrow: 1,
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      // marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  applicationHeader: {
    margin: '20px',
    borderBottom: '3px solid orange',
    paddingBottom: '5px',
  },
  appbar: {
    backgroundColor: '#ffffff',
  },
  root: {
    width: '100%',
    padding: '0px',
  },
  button: {
    margin: theme.spacing(1),
    fontSize: '10px',
    backgroundColor: theme.palette.applications.active,
    color: '#ffffff',
  },
  typo: {
    fontSize: '14px',
  },
  coworkerCardContainer: {
    margin: '5px 0 0px',
  },
  addcoworker: {
    color: '#fff',
    padding: '7px 16px',
    borderRadius: '4px',
    textDecoration: 'none',
    fontSize: '0.875rem',
    fontWeight: '500',
    marginLeft: '18px',
    boxShadow: 'rgba(137, 163, 169, 0.3) 0px 3px 5px 2px',
    backgroundColor: '#211b4e !important',
    '&:hover': {
      backgroundColor: '#151039 !important',
    },
  },
  DataNotFound: {
    marginTop: '10px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  sortby: {
    marginRight: '-5px',
  },
  endSpacing: { padding: '8px 21px 0px' },
  totalCounts: {
    color: '#211b4e',
    fontWeight: '500',
    padding: '0px 26px',
    fontSize: '13px',
  },
})

class AdminFreelancer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      noMoreData: false,
      search: '',
      isSearch: false,
    }
  }

  componentDidMount() {
    this.props.getCompanies('', '', 'freelancer')
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.add_company !== prevProps.add_company &&
      this.props.add_company.success
    ) {
      this.props.getCompanies('', '', 'freelancer')
    }
    if (
      this.props.companyStatus !== prevProps.companyStatus &&
      this.props.companyStatus.success === false
    ) {
      this.props.getCompanies('', '', 'freelancer')
    }
    if (prevProps.loading !== this.props.loading) {
      this.props.setLoading(this.props.loading)
      this.setState({
        loading: this.props.loading,
      })
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.noMoreData !== prevState.noMoreData) {
      return {
        noMoreData: nextProps.noMoreData,
      }
    }
    if (nextProps.companyData !== prevState.companyData) {
      return {
        companyData: nextProps.companyData,
      }
    }
    return []
  }

  searchFreelancers = (search) => {
    this.setState({ isSearch: true, search })
    this.props.getCompanies(this.state.page, search, 'freelancer')
  }

  showDivChange = (event) => {
    const { page, noMoreData } = this.state
    if (!noMoreData) {
      this.props.getCompanies(page + 1)

      this.setState({
        page,
        // eslint-disable-next-line react/no-unused-state
        visibility: event.isIntersecting ? 'visible' : 'invisible',
      })
    }
  }

  render() {
    const { classes } = this.props
    const { isSearch, search, companyData } = this.state
    const { loading } = this.state
    const role = localStorage.getItem('role')
    if (loading) {
      return <CircularLoader />
    }

    if (companyData && companyData.length <= 0 && !isSearch) {
      return (
        <DataNotFound
          type="coworker"
          text="No freelancers found"
          subComponent={
            <div className={classes.DataNotFound}>
              {role === 'communitymanager' ||
              checkPermission('COMPANY_CREATE') ? (
                <Link to="/freelancer/add" className={classes.addcoworker}>
                  Add Freelancer
                </Link>
              ) : (
                ''
              )}
            </div>
          }
        />
      )
    }
    return (
      <React.Fragment>
        <div className={classes.header}>
          {/* {localStorage.getItem("role") === "admin" && ( */}
          <ScreenHeader type="All Freelancers" />
          {/* )} */}
          <AddFormButton type="Freelancer" url="/freelancer/add" />
        </div>
        <div className={classes.searchSection}>
          <AppBar position="static" className={classes.appbar}>
            <Toolbar>
              <SearchBar
                type="Search Freelancers"
                className={classes.searchBox}
                onSearch={this.searchFreelancers}
              />
            </Toolbar>
          </AppBar>
        </div>
        {(checkPermission('COWORKER_ADMIN_VIEW') ||localStorage.getItem('role') === 'communitymanager')&& (
          <span className={classes.totalCounts}>
            Total Freelancers: {companyData && companyData.length}
          </span>
        )}
        {companyData && companyData.length <= 0 && isSearch && (
          <NoRecordsFound text={`No records found for your text "${search}"`} />
        )}
        {checkPermission('COWORKER_VIEW') && (
          <main className={classes.layout}>
            <Paper className={classes.paper}>
              {/* Hero unit */}

              <Grid container spacing={2}>
                {companyData &&
                  companyData.map((element) => {
                    return (
                      <Grid
                        item
                        lg={4}
                        xs={12}
                        sm={6}
                        key={element.id}
                        className={classes.coworkerCardContainer}
                      >
                        <FreelancerCard
                          data={element}
                          history={this.props.history}
                        />
                      </Grid>
                    )
                  })}
              </Grid>
            </Paper>
          </main>
        )}
      </React.Fragment>
    )
  }
}

AdminFreelancer.propTypes = {}

const mapStateToProps = (state) => {
  return {
    coworkers: state.coworkers.coworkers,
    loading: state.coworkers.loading,
    noMoreData: state.coworkers.noMoreData,
    companyData: state.company.companies,
    add_company: state.companies.add_company,
    companyStatus: state.companies.companyStatus,
  }
}

export default connect(mapStateToProps, { getCompanies })(
  withStyles(styles)(AdminFreelancer)
)
