/* eslint-disable camelcase */
import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import { Table, TableBody, TableHead, TableRow, Grid } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import { spacecapitalize } from "../../Utils/StringFunctions";
import Pagination from "../../Components/Common/Table/TablePagination";
import Cell from "../../Components/Common/Table/TableCell";
import Header from "../../Components/Common/Table/TableHeader";
import { getInventory } from "../../Actions/InventoryActions";
import ScreenHeader from "../../Components/Common/ScreenHeader";
import AddFormButton from "../../Components/Common/AddFormButton";
import { CircularLoader } from "../../Components/Common/Spinner";
import nodata from "../../Assets/nodata.png";

const styles = () => ({
  table: {
    marginTop: "20px",
  },
  addResourcebutton: {
    width: "100%",
    textAlign: "right",
  },
  resource_form_modal: {
    width: "500px",
  },
  main: {
    padding: "24px",
  },
  editbutton: {
    borderRadius: "50px",
  },
  editicon: {
    fontSize: "16px",
    marginRight: "6px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    margin: "0px -20px",
  },
  Resourcebutton: {
    color: "#fff",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  root1: {
    "&:nth-child(even)": {
      backgroundColor: "#FCFDFE",
    },
  },
  resourceImage: {
    width: "30%",
    height: "34px",
  },
  imageSection: {
    padding: "0px",
    width: "25%",
  },
  backButton: {
    margin: "0% 0% 2% 0%",
  },
});

export class AdminInventory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inventories: [],
      page: 0,
      rowsPerPage: 10,
    };
  }

  componentDidMount() {
    this.props.getInventory();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.inventories !== prevState.inventories) {
      return { inventories: nextProps.inventories };
    }
    if (nextProps.resources !== prevState.resources) {
      return { resources: nextProps.resources };
    }

    return [];
  }

  componentDidUpdate(prevProps) {
    if (prevProps.inventories !== this.props.inventories) {
      this.setState({
        inventories: this.props.inventories,
      });
    }
    if (
      this.props.edit_inventory !== prevProps.edit_inventory &&
      this.props.edit_inventory.success
    ) {
      this.props.getInventory();
    }
    if (prevProps.inventory !== this.props.inventory) {
      if (this.props.inventory.success) {
        this.props.getInventory();
      }
    }
    if (prevProps.loading !== this.props.loading) {
      // this.props.setLoading(this.props.loading);
      this.setState({
        loading: this.props.loading,
      });
    }
  }

  renderHead = () => {
    return (
      <TableHead>
        <TableRow>
          <Header width="8%">Sr. No.</Header>
          <Header width="18%">Category</Header>
          <Header width="15%">Product Name</Header>
          <Header width="15%">Quantity</Header>
          <Header width="10%">Cost Per Unit</Header>
          <Header width="10%">Total Cost</Header>
          <Header width="10%" />
        </TableRow>
      </TableHead>
    );
  };

  renderBody = () => {
    const { classes, inventories } = this.props;
    const { page, rowsPerPage } = this.state;
    return (
      <TableBody>
        {inventories &&
          inventories
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((item, id) => {
              return (
                <TableRow className={classes.root1}>
                  <Cell width="8%">{id + 1}</Cell>
                  <Cell width="15%">
                    {spacecapitalize(
                      item.objectcategory_id &&
                        item.objectcategory_id.object_category
                    )}
                  </Cell>
                  <Cell width="18%">{spacecapitalize(item.object_name)}</Cell>
                  <Cell width="8%">{item.unit}</Cell>
                  <Cell width="13%">{item.price}</Cell>
                  <Cell width="12%">
                    {Number(item.unit) * Number(item.price)}
                  </Cell>
                  <Cell align="right" width="10%">
                    <Button
                      variant="outlined"
                      size="small"
                      color="primary"
                      className={classes.editbutton}
                      onClick={() =>
                        this.props.history.push(`/inventory/edit/${item._id}`)
                      }
                    >
                      <CreateIcon size="small" className={classes.editicon} />{" "}
                      Edit
                    </Button>
                  </Cell>
                </TableRow>
              );
            })}
      </TableBody>
    );
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  render() {
    const { classes, inventories } = this.props;
    const { loading } = this.state;
    let showData = true;
    const itemLength = this.props.inventories
      ? this.props.inventories.length
      : 0;
    if (inventories && inventories.length <= 0) {
      showData = false;
    }
    if (loading) {
      return <CircularLoader />;
    }
    return (
      <div className={classes.main}>
        {showData && (
          <>
            <div className={classes.header}>
              <ScreenHeader type="All Inventory" />
              <AddFormButton type="Inventory" url="/inventory/add" />
            </div>
            <div className={classes.root}>
              <Table className={classes.table}>
                {this.renderHead()}
                {this.renderBody()}
              </Table>
            </div>
            <Pagination
              count={itemLength}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </>
        )}
        {!showData && (
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.headerSpacing}
          >
            <Grid item justify="center">
              <Grid item sm={12} lg={12}>
                <img src={nodata} alt="" width="76%" />
              </Grid>
              <div style={{ marginRight: "180px", marginTop: "20px" }}>
                <AddFormButton type="Inventory" url="/inventory/add" />
              </div>
            </Grid>
          </Grid>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    inventories: state.inventory.inventories,
    loading: state.inventory.loading,
    inventory: state.inventory.inventory,
  };
};

export default connect(mapStateToProps, {
  getInventory,
})(withStyles(styles)(AdminInventory));
