/* eslint-disable func-names */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import React from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  DialogTitle,
  CssBaseline,
  FormLabel,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import InputField from "../../Components/Common/Form/InputField";
import { getFoodcategory } from "../../Actions/foodcategoryActions";
import {
  addfooditem,
  editCafe,
  getallFoodItem,
} from "../../Actions/foodItemActions";
import UploadField from "../../Components/Common/Form/UploadField";
import SelectField from "../../Components/Common/Form/SelectField";
import RadioField from "../../Components/Common/Form/RadioField";
// import { checkPermission } from "Utils";

const $quoteSym = " ";
const styles = () => ({
  root: {
    backgroundColor: "#fff",
  },
  table: {
    minWidth: 650,
    marginTop: "20px",
  },
  add_resource: {
    display: "flex",
    justifyContent: "flex-end",
  },
  foodItemModal: {
    width: "500px",
  },
  form_modal: {
    minWidth: "500px",
  },
  main: {
    padding: "24px",
  },
  editbutton: {
    borderRadius: "50px",
  },
  editicon: {
    fontSize: "16px",
    marginRight: "6px",
  },
  foodbutton: {
    color: "#fff",
    padding: "7px 16px",
    lineHeight: 1.43,
    borderRadius: "4px",
    textDecoration: "none",
    fontSize: "0.875rem",
    fontWeight: "500",
    marginLeft: "18px",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  modalHeader: {
    paddingBottom: "5px",
    fontSize: "20px",
    fontWeight: "600",
    position: "relative",
    marginBottom: "22px",
    "&:after": {
      top: "46px",
      left: "24px",
      width: "24px",
      borderBottom: "3px solid #fb0",
      content: `'${$quoteSym}'`,
      position: "absolute",
    },
  },
  addFoodItem: {
    width: "100%",
    textAlign: "right",
    marginRight: "22px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginLeft: "6px",
  },
  pageHeader: {
    margin: "0px -19px",
    width: "100%",
  },
  Cancelbutton: {
    marginRight: "20px",
    backgroundColor: "#dddddd",
    color: "#151039",
    "&:hover": {
      color: "#151039",
      backgroundColor: "#d1d1d1!important",
    },
  },
  Addbutton: {
    marginRight: "16px",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
});

const FoodItemSchema = Yup.object().shape({
  food_name: Yup.string().trim().required("This field is required."),
  price: Yup.number()
    .typeError("Please input a number.")
    .required("This field is required."),
  food_category: Yup.string().required("This field is required"),
  food_photo: Yup.string().required("This field is required"),
  food_type: Yup.string().required("This field is required"),
  is_jain: Yup.string().required("This field is required"),
  is_cheese: Yup.string().required("This field is required"),
  is_butter: Yup.string().required("This field is required"),
  cheese_price: Yup.number()
    .typeError("Please input a number")
    .test("match", "This field is required", function (value) {
      const { is_cheese } = this.parent;
      if (is_cheese === "yes") {
        if (!value) {
          return false;
        }
        return true;
      }
      return true;
    }),
  butter_price: Yup.number()
    .typeError("Please input a number")
    .test("match", "This field is required", function (value) {
      const { is_butter } = this.parent;
      if (is_butter === "yes") {
        if (!value) {
          return false;
        }
        return true;
      }
      return true;
    }),
});

const foodTypeOptions = [
  { value: "regular", label: "Regular" },
  { value: "ondemand", label: "On Demand" },
];
const availableFoodOptions = [
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
];

const getAvailableFoodCategoryOptions = (all_food_category) => {
  return all_food_category
    ? // eslint-disable-next-line no-shadow
      all_food_category.map((all_food_category) => {
        return {
          value: all_food_category._id,
          label: all_food_category.food_category,
        };
      })
    : [];
};
class FoodItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = { all_food_category: [], deletedKey: [] };
  }

  componentDidMount() {
    this.props.getFoodcategory();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.all_food_category !== prevState.all_food_category) {
      return { all_food_category: nextProps.all_food_category };
    }
    return [];
  }

  shouldComponentUpdate() {
    if (this.state.deletedKey.length > 0) {
      return false;
    }
    return true;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.all_food_category !== this.props.all_food_category) {
      this.setState({
        all_food_category: this.props.all_food_category,
      });
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  setDelImageUrl = (url) => {
    // const { deletedKey } = this.state;
    // this.setState({ deletedKey: deletedKey.push(url) });
  };

  render() {
    const {
      classes,
      open,
      handleClose,
      editingCafeItem,
      all_food_category,
      additem,
    } = this.props;
    let id;
    const getInitialValues = () => {
      if (editingCafeItem && additem) {
        id = editingCafeItem._id;
        const category = all_food_category.filter(
          (option) => option._id === editingCafeItem.food_category._id
        )[0];

        const initCat = { label: category.food_category, value: category._id };

        return {
          food_category: initCat,
          food_name: editingCafeItem.food_name,
          food_photo: editingCafeItem.food_photo,
          price: editingCafeItem.price,
          food_type: editingCafeItem.food_type,
          is_jain: editingCafeItem.is_jain === true ? "yes" : "no",
          is_cheese: editingCafeItem.is_cheese === true ? "yes" : "no",
          is_butter: editingCafeItem.is_butter === true ? "yes" : "no",
          cheese_price: editingCafeItem.cheese_price
            ? editingCafeItem.cheese_price
            : 0,
          butter_price: editingCafeItem.butter_price
            ? editingCafeItem.butter_price
            : 0,
        };
      }
      return {
        food_category: [],
        food_name: "",
        food_photo: "",
        price: "",
        food_type: "regular",
        is_jain: "no",
        is_cheese: "no",
        is_butter: "no",
        cheese_price: "",
        butter_price: "",
      };
    };
    return (
      <React.Fragment>
        <CssBaseline />
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <Formik
            initialValues={getInitialValues(editingCafeItem)}
            validationSchema={FoodItemSchema}
            onSubmit={(values, { setErrors, setSubmitting }) => {
              // eslint-disable-next-line no-param-reassign
              values.space = localStorage.getItem("space_id");
              const categoryValue = values.food_category
                ? values.food_category.value
                : "";

              // eslint-disable-next-line no-param-reassign
              values.food_category = categoryValue;
              values.is_jain = values.is_jain === "yes" ? true : false;
              values.is_cheese = values.is_cheese === "yes" ? true : false;
              values.is_butter = values.is_butter === "yes" ? true : false;

              if (editingCafeItem && additem) {
                this.props.editCafe(
                  id,
                  values,
                  setErrors,
                  setSubmitting,
                  this.props.history
                );
              } else {
                this.props.addfooditem(
                  values,
                  setErrors,
                  this.props.history,
                  setSubmitting
                );
              }
              this.props.getFoodcategory();
              this.props.getallFoodItem();

              handleClose();
            }}
          >
            {({ setFieldValue, values, setFieldTouched, errors }) => (
              <Form className={classes.foodItemModal}>
                <DialogTitle
                  id="food-item-dialog-title"
                  className={classes.modalHeader}
                >
                  {editingCafeItem && additem
                    ? `Edit Food Item`
                    : `Add Food Item`}
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <SelectField
                        value={values.food_category}
                        name="food_category"
                        id="food_category"
                        label="Select Food Category"
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        multi={false}
                        error={errors.food_category}
                        options={getAvailableFoodCategoryOptions(
                          all_food_category
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <InputField
                        name="food_name"
                        label="Enter Food Name"
                        placeholder="Enter Food Name"
                      />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <FormLabel
                        component="legend"
                        className={classes.label}
                        style={{
                          fontSize: "13px",
                          fontWeight: "400",
                          color: "rgba(0, 0, 0, 0.54)",
                        }}
                      >
                        Upload Photo
                      </FormLabel>
                      <UploadField
                        label="Upload Photo"
                        name="food_photo"
                        id="food_photo"
                        folder="cafe/items"
                        onChange={setFieldValue}
                        value={values.food_photo}
                        delImageUrl={this.setDelImageUrl}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <InputField
                        name="price"
                        label="Price in INR"
                        placeholder="Enter Price"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      lg={12}
                      className={classes.foodType}
                    >
                      <RadioField
                        name="food_type"
                        label="Type"
                        id="food_type"
                        options={foodTypeOptions}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      lg={12}
                      className={classes.foodType}
                    >
                      <RadioField
                        name="is_jain"
                        label="Available in Jain"
                        id="is_jain"
                        options={availableFoodOptions}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      lg={6}
                      className={classes.foodType}
                    >
                      <RadioField
                        name="is_cheese"
                        label="Available in Cheese"
                        id="is_cheese"
                        options={availableFoodOptions}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      lg={6}
                      className={classes.foodType}
                    >
                      <RadioField
                        name="is_butter"
                        label="Available in Butter"
                        id="is_butter"
                        options={availableFoodOptions}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <InputField
                        name="cheese_price"
                        label="Price in INR"
                        placeholder="Enter Price"
                      />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <InputField
                        name="butter_price"
                        label="Price in INR"
                        placeholder="Enter Price"
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleClose}
                    color="primary"
                    className={classes.Cancelbutton}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    className={classes.Addbutton}
                    type="submit"
                    variant="contained"
                  >
                    {editingCafeItem && additem ? `Edit` : `Add`}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
      </React.Fragment>
    );
  }
}
FoodItem.propTypes = {};
const mapStateToProps = (state) => {
  return {
    all_food_category: state.all_food_category.all_food_category,
  };
};
export default connect(mapStateToProps, {
  getFoodcategory,
  addfooditem,
  editCafe,
  getallFoodItem,
})(withStyles(styles)(withRouter(FoodItem)));
