/* eslint-disable camelcase */
import React, { useState } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  CssBaseline,
  Typography,
  CardContent,
  Card,
  CardHeader,
  Avatar,
  CardActions,
  Button,
  Grid,
} from "@material-ui/core";
import { ThumbsUp, ThumbsDown } from "react-feather";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import avatar from "../../Assets/coworker3.png";
import LinkifyString from "../../Utils/LinkifyString";
import { spacecapitalize, capitalize } from "../../Utils/StringFunctions";
import { likeSuggestion } from "../../Actions/SuggestFeatureAction";
import { checkPermission } from "../../Utils/checkPermission";

const theme = createMuiTheme({
  overrides: {
    MuiPaper: {
      // Name of the component ⚛️ / style sheet
      root: {
        // backgroundColor: "#ffffff"
      },
    },
    MuiDialogContent: {
      root: {
        "&:first-child": {
          paddingTop: "0px",
        },
        padding: "0px",
      },
    },
    MuiGridListTile: {
      tile: {
        display: "flex",
      },
      imgFullWidth: {
        height: "100%",
      },
      imgFullHeight: {
        width: "100%",
      },
    },
  },
});
const styles = () => ({
  icons: {
    position: "relative",
    top: "2px",
    margin: " 0px 10px",
    fontWeight: 600,
    fontSize: "15px",
    textAlign: "left",
    color: "#bfbfbf",
  },
  card: {
    // maxWidth: 500,
    border: "1px solid #efefef",
    backgroundColor: "#fff",
    marginBottom: "20px",
    boxShadow: "0 9px 10px rgba(0, 0, 0, 0.1), 0 4px 0px rgba(0,0,0,0.05)",
    transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
  },
  avatar: {
    height: "50px",
    width: "50px",
  },

  postDescription: {
    marginTop: "-2.5%",
    padding: "16px 16px 6px 16px",
    // marginBottom: "-16px"
  },
  postTypo: {
    paddingLeft: "1%",
    whiteSpace: "pre-line",
    textAlign: "left",
    color: "#222222",
  },
  cardActions: {
    marginLeft: "0.8%",
    paddingTop: "0px",
  },
  likeSection: {
    display: "flex",
    alignItems: "baseline",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  titleColor: {
    color: "#4d7cfe",
    textAlign: "left",
  },
  labelFonts: {
    fontSize: "10px",
    color: "#ABABAB",
    whiteSpace: "pre-line",
    textAlign: "left",
  },
});

function SuggestPost(props) {
  const { post, classes } = props;
  const [likeCounts, setLikeCounts] = useState(post.like_counts);
  const [disLikeCounts, setdisLikeCounts] = useState(post.dislike_counts);
  const [like, setlike] = useState(post.like);
  const [dislike, setDislike] = useState(post.dislike);
  let values = {};

  // useEffect(() => {
  //   props.getsuggest();
  // }, [props.like_suggesion]);
  // useEffect(() => {
  //   if (
  //     props.like_post !== props.like_post.success
  //   ) {
  //     props.likeSuggestion();
  //   }
  // })
  // const data = props.post && props.post.filter(data=>data.feature_status==="approve_for_pull")

  const onclickThumb = (type) => {
    if (type === "yes") {
      if (like === "yes") {
        setlike("no");
        setDislike("no");
        setLikeCounts(likeCounts - 1);
        values = {
          feature_id: post._id,
          like: "yes",
          remove: "like",
        };
      } else {
        setlike("yes");
        setDislike("no");

        setLikeCounts(likeCounts + 1);

        if (dislike === "yes") {
          setdisLikeCounts(post.dislike_counts - 1);
        }
        values = {
          feature_id: post._id,
          like: "yes",
        };
        setdisLikeCounts(post.dislike_counts);
      }
    } else {
      if (dislike === "yes") {
        setlike("no");
        setDislike("no");
        setdisLikeCounts(disLikeCounts - 1);
        values = {
          feature_id: post._id,
          like: "no",
          remove: "dislike",
        };
      } else {
        setlike("no");
        setDislike("yes");
        if (like === "yes") {
          setLikeCounts(likeCounts - 1);
        }

        setdisLikeCounts(disLikeCounts + 1);

        values = {
          feature_id: post._id,
          like: "no",
        };
      }

      setLikeCounts(post.like_counts);
    }
    props.likeSuggestion(values);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Card className={classes.card}>
        <CardHeader
          avatar={
            post.created_by && post.created_by.profile_pic ? (
              <Avatar
                className={classes.avatar}
                src={post.created_by.profile_pic}
              />
            ) : (
              <Avatar className={classes.avatar} src={avatar} />
            )
          }
          title={`${capitalize(
            post && post.created_by && post.created_by.first_name
          )} ${capitalize(
            post &&
              post.created_by &&
              post.created_by &&
              post.created_by.last_name
          )} `}
          subheader={
            post &&
            post.created_by &&
            post.created_by &&
            post.created_by.company_id
              ? capitalize(post.created_by.company_id.company_name)
              : ""
          }
          className={classes.titleColor}
        />

        <CardContent className={classes.postDescription}>
          <Grid container>
            <Grid item xs={6}>
              <Typography component="h2" className={classes.postTypo}>
                <Typography
                  variant="subtitle2"
                  component="p"
                  className={classes.labelFonts}
                >
                  Module
                </Typography>
                {capitalize(post.module_name)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography component="h2" className={classes.postTypo}>
                <Typography
                  variant="subtitle2"
                  component="p"
                  className={classes.labelFonts}
                >
                  Status
                </Typography>
                {spacecapitalize(post.feature_status)}
              </Typography>
            </Grid>
          </Grid>
          <Typography
            variant="body2"
            color="#626262"
            component="p"
            className={classes.postTypo}
          >
            <Typography
              variant="subtitle2"
              component="p"
              className={classes.labelFonts}
            >
              Suggestion
            </Typography>
            {LinkifyString(post.feature_description)}
          </Typography>
        </CardContent>
        {checkPermission("SUGGEST_FEATURE_LIKE_DISLIKE") && (
          <CardActions className={classes.cardActions}>
            <Button
              className={classes.likeSection}
              onClick={() => onclickThumb("yes")}
            >
              <ThumbsUp
                size={18}
                className={classes.icons}
                fill={like === "yes" ? "	#48AB35	" : "white"}
                color={like === "yes" ? "	#48AB35	" : "grey"}
              />
              {likeCounts}
            </Button>
            <Button
              className={classes.likeSection}
              onClick={() => onclickThumb("no")}
            >
              <ThumbsDown
                size={18}
                className={classes.icons}
                fill={dislike === "yes" ? "	#FB546B	" : "white"}
                color={dislike === "yes" ? "	#FB546B	" : "grey"}
              />
              {disLikeCounts}
            </Button>
          </CardActions>
        )}
      </Card>
    </ThemeProvider>
  );
}

const mapStateToProps = (state) => {
  return {
    like_post: state.suggestfeature.like_suggesion,
  };
};

export default connect(mapStateToProps, {
  likeSuggestion,
  // getsuggest,
})(withStyles(styles)(SuggestPost));
