/* eslint-disable camelcase */
import axios from "axios";
import {
  ALL_POST,
  POST_LOADING,
  ADD_POST,
  POST_ERROR,
  LIKE_POST,
  LIKE_POST_ERROR,
  CREATE_COMMENT,
  COMMENT_ERROR,
  EDIT_POST,
  EDIT_POST_ERROR,
  DELETE_POST,
  DELETE_POST_ERROR,
} from "./types";
import { showSuccess, showError } from "./ActionsHelper";
import { transformErrorsFromApi } from "./TransformError";

//  Loading
export const setPostLoading = () => {
  return {
    type: POST_LOADING,
  };
};

export const addPost = (userData, setErrors, setSubmitting) => (dispatch) => {
  dispatch(setPostLoading());
  axios
    .post("/api/post", userData)
    .then((result) => {
      dispatch({
        type: ADD_POST,
        payload: result.data,
      });
      dispatch(showSuccess("Post "));
      setSubmitting(false);
    })
    .catch((err) => {
      const error = err.response || {};
      dispatch({ type: POST_ERROR, payload: error.data });
      dispatch(
        showError(
          `${error && error.data && error.data.data}` ||
          "There was some error while adding  post "
        )
      );
      setSubmitting(false);
      setErrors(transformErrorsFromApi(error));
    });
};

export const getPosts = () => (dispatch) => {
  setPostLoading();
  axios
    .get(`/api/post`)
    .then((result) => {
      dispatch({
        type: ALL_POST,
        payload: result.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: POST_ERROR,
        payload: err && err.response ? err.response.data : "",
      });
    });
};
export const likePost = (userData) => (dispatch) => {
  dispatch(setPostLoading());
  axios
    .post("/api/post/like", userData)
    .then((result) => {
      dispatch({
        type: LIKE_POST,
        payload: result.data,
      });
    })
    .catch((err) => {
      const error = err.response || {};
      dispatch({ type: LIKE_POST_ERROR, payload: error.data });
      dispatch(showError("There was some error while liking post "));
    });
};

export const createComment = (userData) => (dispatch) => {
  dispatch(setPostLoading());
  axios
    .post("/api/post/comment", userData)
    .then((result) => {
      dispatch({
        type: CREATE_COMMENT,
        payload: result.data,
      });
      dispatch(showSuccess("", "You Commented on a Post!"));
    })
    .catch((err) => {
      const error = err.response || {};
      dispatch({ type: COMMENT_ERROR, payload: error.data });
      dispatch(showError(" Error while Commenting a post "));
    });
};

export const editPost = (id, userData, setErrors, setSubmitting) => (
  dispatch
) => {
  dispatch(setPostLoading());
  axios
    .put(`/api/post/editpost/${id}`, userData)
    .then((result) => {
      dispatch({
        type: EDIT_POST,
        payload: result.data,
      });
      dispatch(showSuccess("", "Post Edited Successfully!"));

      setSubmitting(false);
    })
    .catch((err) => {
      const error = err.response || {};
      dispatch({ type: EDIT_POST_ERROR, payload: error.data });
      dispatch(showError("There was some error while editing post "));
      // setSubmitting(false);
      setErrors(transformErrorsFromApi(error));
    });
};
export const deletePost = (id, type) => (dispatch) => {
  dispatch(setPostLoading());
  axios
    .delete(`/api/post/${id}`, { data: { type } })
    .then((result) => {
      dispatch({
        type: DELETE_POST,
        payload: result.data,
      });
      dispatch(showSuccess("", "Post deleted successfully!"));
    })

    .catch((err) => {
      dispatch({
        type: DELETE_POST_ERROR,
        payload: err && err.response ? err.response.data : "",
      });
    });
};
