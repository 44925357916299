import { ACTIVE, INACTIVE, DRAFT, view } from "./StatusTypes";

// eslint-disable-next-line consistent-return
const CompanyStatus = (CompanyType) => {
  const Active = [view.ACTIVE, view.INACTIVE, view.DRAFT];
  const Inactive = [view.INACTIVE];

  const Draft = [view.DRAFT, view.INACTIVE];

  switch (CompanyType) {
    case ACTIVE:
      return Active;
    case DRAFT:
      return Draft;
    case INACTIVE:
      return Inactive;

    default:
      break;
  }
};
export default CompanyStatus;
