/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import { ChevronRight } from "react-feather";
import JoinTeam from "../../Assets/join_team_icon.png";
import CreateTeam from "../../Assets/create_team_icon.png";
import CreateTeamModal from "./CreateTeamModal";
import JoinTeamModal from "./JoinTeamModal";

const $quoteSym = " ";

const styles = () => ({
  registerTeammodal: {
    width: 500,
    padding: "34px 24px",
    overflow: "hidden",
  },
  modelHeader: {
    paddingBottom: "5px",
    position: "relative",
    "&:after": {
      top: "48px",
      left: "24px",
      width: "24px",
      borderBottom: "3px solid #fb0",
      content: `'${$quoteSym}'`,
      position: "absolute",
    },
  },
  teamMainSection: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    border: "1px solid #efefef",
    marginBottom: "18px",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.05)",
  },
  mainTitle: {
    fontWeight: 600,
    fontSize: "16px",
    letterSpacing: "0.02em",
    color: "#222",
  },
  layout: {
    display: "flex",
    borderRight: "1px solid #efefef",
    padding: "10px",
    borderRadius: "5px",
    justifyContent: "center",
    // margin: "0px 10px 16px 0px"
  },
  eventDate: { padding: "10px 0px" },
  contentTeam: {
    width: "189px",
    fontSize: "12px",
    letterSpacing: "0.02em",
    textAlign: "left",
    color: "#626262",
  },
  rightIcon: {
    position: "relative",
    right: "-24px",
    cursor: "pointer",
  },
  alignContent: {
    display: "flex",
  },
});

function SelectTeamOptionModal(props) {
  const { classes, eventID, eventData } = props;
  const [createTeammodalOpen, setcreateTeammodalOpen] = useState(false);
  const [joinTeammodalOpen, setjoinTeammodalOpen] = useState(false);

  // console.log("event data in select team option", eventData);

  const handleOpen = () => {
    setcreateTeammodalOpen(true);
    // props.close();
  };

  const OpenJoinTeamModal = () => {
    setjoinTeammodalOpen(true);
  };

  useEffect(() => {
    if (props.register_team && props.register_team.success) {
      props.close();
    }
  }, [props.register_team]);

  const handleJoinModalClose = () => {
    setjoinTeammodalOpen(false);
  };

  const handleClose = () => {
    setcreateTeammodalOpen(false);
  };
  return (
    <Dialog
      open={props.openModal}
      onClose={props.close}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" className={classes.modelHeader}>
        Select Option
      </DialogTitle>
      <DialogContent className={classes.registerTeammodal}>
        <Grid container>
          <div className={classes.teamMainSection}>
            <Grid item xs={4} sm={4} md={4} className={classes.layout}>
              <img alt="logo" src={JoinTeam} className={classes.teamIcon} />
            </Grid>
            <Grid item xs={7} sm={7} md={7} className={classes.contentSection}>
              <div className={classes.eventDate} onClick={OpenJoinTeamModal}>
                <Typography
                  align="left"
                  variant="h6"
                  className={classes.mainTitle}
                  gutterBottom
                >
                  Join a Team
                </Typography>
                <div className={classes.alignContent}>
                  <Typography
                    variant="subtitle2"
                    component="p"
                    className={classes.contentTeam}
                  >
                    Click here and search for your team name to join.
                  </Typography>
                  <ChevronRight
                    size={26}
                    className={classes.rightIcon}
                    onClick={OpenJoinTeamModal}
                  />
                </div>
              </div>
            </Grid>
          </div>
          <div className={classes.teamMainSection}>
            <Grid item xs={4} sm={4} md={4} className={classes.layout}>
              <img alt="" src={CreateTeam} className={classes.teamIcon} />
            </Grid>
            <Grid item xs={7} sm={7} md={7} className={classes.contentSection}>
              <div className={classes.eventDate} onClick={handleOpen}>
                <Typography
                  align="left"
                  variant="h6"
                  className={classes.mainTitle}
                  gutterBottom
                >
                  Create a Team
                </Typography>
                <div className={classes.alignContent}>
                  <Typography
                    variant="subtitle2"
                    component="p"
                    className={classes.contentTeam}
                  >
                    Click here to create your new team and invite your Co-worker
                    to join.
                  </Typography>
                  <ChevronRight
                    size={26}
                    className={classes.rightIcon}
                    onClick={handleOpen}
                  />
                </div>
              </div>
            </Grid>
          </div>
        </Grid>
      </DialogContent>

      <CreateTeamModal
        openCreateModal={createTeammodalOpen}
        closeCreateModal={handleClose}
        eventID={eventID}
        eventData={eventData}
      />
      <JoinTeamModal
        openJoinModal={joinTeammodalOpen}
        closeJoinModal={handleJoinModalClose}
        eventID={eventID}
        eventData={eventData}
      />
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    register_team: state.events.register_team,
    errors: state.errors,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withStyles(styles)(SelectTeamOptionModal));
