import React, { useState } from "react";
import { FormControl, MenuItem, Select, withStyles } from "@material-ui/core";
import classNames from "classnames";
import { connect } from "react-redux";
import { capitalize } from "../../Utils/StringFunctions";
import {
  updateReportStatus,
  getReportBug,
} from "../../Actions/ReportBugListAction";
import { reportStatusType } from "../../Components/Common/Types/HelpdeskStatusType";

const ReportStatus = (props) => {
  const [selectedItem, setSelectedItem] = useState(
    props.selectedItem || "pending"
  );

  const callStatusChangeAPI = (el) => {
    props.updateReportStatus(
      props.id,
      {
        bug_status: el.target.value,
      },
      () => {
        setSelectedItem(el.target.value);
      }
    );
  };

  const { classes } = props;
  return (
    <React.Fragment>
      <FormControl variant="filled" className={classes.formControl}>
        <Select
          className={classNames(
            classes[props.selectedItem],
            classes.statusDropdown
          )}
          value={selectedItem}
          onChange={callStatusChangeAPI}
          name="name"
          disableUnderline
          style={{ color: "black" }}
          variant="standard"
          data={selectedItem}
          classes={{
            icon: classes.icon,
          }}
        >
          {reportStatusType.map((element) => {
            return (
              <MenuItem value={element.value} key={element.value}>
                {capitalize(element.label)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </React.Fragment>
  );
};

const styles = (theme) => {
  return {
    active: {
      color: "#55A3F1 !important",
      backgroundColor: "#fff !important",
    },
    inactive: {
      color: "#4CAF50 !important",
      backgroundColor: "#fff !important",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 90,
    },
    statusDropdown: {
      color: "black",
      border: `1px solid ${theme.palette.border.color}`,
      borderRadius: "4px",
      height: "33px",
      padding: "13px 12px 13px",
      fontSize: "12px",
      paddingLeft: "10px",
    },
    icon: {
      color: "#000",
      right: "8px",
      top: "5px",
    },
  };
};

export default connect(null, { updateReportStatus, getReportBug })(
  withStyles(styles)(ReportStatus)
);
