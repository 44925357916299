import {
  GET_INVENTORY,
  ADD_INVENTORY,
  INVENTORY_LOADING,
  GET_SINGLE_INVENTORY,
  EDIT_INVENTORY,
  INVENTORY_ERRORS
} from "../Actions/types";

const initialState = {
  inventory: null,
  inventories: null,
  loading: false,
  edit_inventory: {},
  get_single_inventory: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_INVENTORY:
      return {
        ...state,
        loading: false,
        inventories: action.payload.data
      };
    case ADD_INVENTORY:
      return {
        ...state,
        loading: false,
        inventory: { success: true, data: action.payload.data }
      };
    case GET_SINGLE_INVENTORY:
      return {
        ...state,
        loading: false,
        succes: true,
        get_single_inventory: action.payload && action.payload.data
      };
    case EDIT_INVENTORY:
      return {
        ...state,
        loading: false,
        edit_inventory: {
          success: true,
          data: action.payload && action.payload.data
        }
      };
    case INVENTORY_LOADING:
      return {
        ...state,
        loading: true
      };
    case INVENTORY_ERRORS:
      return {
        ...state,
        loading: false,
        inventory_error: { errors: false }
      };
    default:
      return state;
  }
}
