import React from "react";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import InputBase from "@material-ui/core/InputBase";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import { fade } from "@material-ui/core/styles";
import {
  Button,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
} from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ClearIcon from "@material-ui/icons/Clear";
import { capitalize } from "../../Utils/StringFunctions";
import {
  addAssociatePlan,
  deletePlan,
  getCompany,
} from "../../Actions/companiesAction";
import { getAvailablePlans } from "../../Actions/planActions";
import SelectField from "../../Components/Common/Form/SelectField";
import DeleteAlert from "../../Components/Common/DeleteAlert";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },

  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.common.white,
    border: "1px solid #e2e7eb",
    fontSize: 16,
    width: "40px",
    padding: "10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}))(InputBase);

const styles = () => ({
  iconButton: {
    position: "absolute",
    right: 0,
  },
  icon: {
    marginTop: "30px",
  },
  label: {
    margin: "15px 0px 8px 0px",
    fontSize: "12px",
    fontWeight: "700",
  },
  add_button: {
    margin: "25px 0px",
  },
  lightbg: {
    padding: "20px 26px",
    marginBottom: "25px",
  },
  addplan: {
    width: "100%",
  },
  disablebutton: {
    display: "none",
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "hsl(206, 80%, 97%)",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

class FreelancerPlanTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      client_category: "",
      selectedPlans: [],
      flag: false,
      plan: "",
      buttonvisible: false,
      showConfirmDel: false,
      deleteplan: "",
    };
  }

  componentDidMount() {
    const allPlans =
      Object.entries(this.props.allplans).length !== 0
        ? this.props &&
          this.props.allplans &&
          this.props.allplans.map((plan) => {
            const data = {
              available_seat: plan.assigned_seats,
              cabin_name: plan && plan.plan_id && plan.plan_id.cabin_name,
              category_id: plan.category_id,
              credit_per_seat: plan.credit_per_seat,
              price_per_seat: plan.price_per_seat,
              seat_per_cabin: plan.seat_per_cabin,
              share: plan.share,
              space: plan.space,
              status: plan.status,
              _id: plan._id,
              plan_id: plan && plan.plan_id && plan.plan_id._id,
            };
            return { plan: data, flag: true };
          })
        : [];
    this.setState({ selectedPlans: allPlans });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.associateplan !== this.props.associateplan) {
      this.setState({
        associateplan: this.props.associateplan,
      });
      if (this.props.associateplan.success) {
        this.setState({
          loading: this.props.loading,
        });
        this.props.getCompany(this.props.companyID);
        this.props.getAvailablePlans();
      }
    }
    if (prevProps.deletePlan !== this.props.deletePlan) {
      this.setState({
        deletePlan: this.props.deletePlan,
      });
      if (this.props.deletePlan.success) {
        this.setState({
          loading: this.props.loading,
        });
        this.props.getCompany(this.props.companyID);
      }
    }
    if (prevProps.loading !== this.props.loading) {
      this.setState({
        loading: this.props.loading,
      });
    }
    if (prevProps.allplans !== this.props.allplans) {
      const allPlans = this.props.allplans
        ? this.props.allplans.map((plan) => {
            const data = {
              available_seat: plan.assigned_seats,
              cabin_name: plan && plan.plan_id && plan.plan_id.cabin_name,
              category_id: plan.category_id,
              credit_per_seat: plan.credit_per_seat,
              price_per_seat: plan.price_per_seat,
              seat_per_cabin: plan.seat_per_cabin,
              share: plan.share,
              space: plan.space,
              status: plan.status,
              _id: plan._id,
              plan_id: plan && plan.plan_id && plan.plan_id._id,
            };
            return { plan: data, flag: true };
          })
        : [];
      this.setState({ selectedPlans: allPlans });
    }
  }

  getAvailablePlanOptions = (plans) => {
    const availablePlans = plans
      ? plans.map((plan) => {
          return { value: plan._id, label: capitalize(plan.cabin_name) };
        })
      : [];
    return availablePlans;
  };

  setAvailablePlan = (callBackMethod, data) => {
    this.setState({
      selectedPlan: data.value,
      flag: true,
    });

    // callBackMethod();
  };

  onEditorStateChange = (html) => {
    this.setState({
      html,
    });
  };

  // eslint-disable-next-line consistent-return
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.plans !== prevState.plans) {
      return { plans: nextProps.plans };
    }
  }

  handleRemoveLocation = (type, idx) => () => {
    this.setState({
      // eslint-disable-next-line react/no-access-state-in-setstate
      [type]: this.state[type].filter((s, sidx) => idx !== sidx),
    });
  };

  removeIcon = () => {
    this.setState({
      file: null,
      imagePreviewUrl: null,
    });
  };

  selectClickPlan = () => {
    const { selectedPlans, selectedPlan, plans } = this.state;

    if (!selectedPlan) return;
    let singlePlan = {};
    // eslint-disable-next-line no-unused-expressions
    plans.plans &&
      plans.plans.map((plan) => {
        if (plan._id === selectedPlan) {
          // eslint-disable-next-line no-param-reassign
          plan.static_available_seats = plan.available_seat;
          // eslint-disable-next-line no-param-reassign
          plan.static_seats = plan.credit_per_seat;
          singlePlan = { plan, flag: false };
        }
        return null;
      });
    selectedPlans.push(singlePlan);
    this.setState({ selectedPlans, buttonvisible: true });
  };

  handleNext = () => {
    this.props.handleNext(this.state);
  };

  handleInputChange = (e, id, name, plan = "plan") => {
    if (Number(e.target.value) > 0 || Number(e.target.value) === "") {
      const { selectedPlans } = this.state;
      selectedPlans[id][plan][name] = Number(e.target.value);
      this.setState({ selectedPlans });
    }
  };

  handleInputCreditChange = (
    e,
    id,
    name,
    plan = "plan",
    availableseat = "available_seat",
    creditperseat = "credit_per_seat"
  ) => {
    const { selectedPlans } = this.state;
    const credit = e.target.value / selectedPlans[id][plan][availableseat];

    selectedPlans[id][plan][creditperseat] = Number(credit);
    this.setState({ selectedPlans });
  };

  handleDelete = (id) => {
    this.setState({ showConfirmDel: true, deleteplan: id });
  };

  handleDeletePlan = () => {
    this.props.deletePlan(this.state.deleteplan);
  };

  handleClickAddPlan = () => {
    const arrPlan = [];
    // eslint-disable-next-line no-unused-vars
    const Plans = this.state.selectedPlans
      ? this.state.selectedPlans.filter((plan) => {
          const data = {
            plan_id: plan.plan._id,
            share: plan.plan.share,
            category_id: plan.plan.category_id,
            assigned_seats: Number(plan.plan.available_seat),
            seat_per_cabin: Number(plan.plan.seat_per_cabin),
            price_per_seat: Number(plan.plan.price_per_seat),
            total_price:
              Number(plan.plan.price_per_seat) *
              Number(plan.plan.available_seat),
            credit_per_seat: Number(plan.plan.credit_per_seat),
            total_credits:
              Number(plan.plan.available_seat) *
              Number(plan.plan.credit_per_seat),
          };
          if (plan.flag === false) return arrPlan.push(data);
          return null;
        })
      : [];

    const alldata = {
      company_id: this.props.companyID,
      plan: arrPlan,
    };
    this.props.addAssociatePlan(alldata);
    this.setState({ buttonvisible: false, selectPlan: null });
    this.props.getCompany(this.props.companyID);
    this.props.getAvailablePlans();
  };

  render() {
    const { classes, company } = this.props;
    const { plans, selectedPlans } = this.state;
    let CreditTotal = 0;
    let SeatsTotal = 0;
    let PricePerSeatTotal = 0;
    let SubTotalPriceDisplay = 0;
    let SubTotalCreditsDisplay = 0;
    let creditTotal = 0;
    let SeatPerCabin = 0;

    selectedPlans.map((plan) => {
      SeatPerCabin += Number(plan.plan.seat_per_cabin);
      CreditTotal += Number(parseFloat(plan.plan.credit_per_seat).toFixed(2));
      // CreditTotal += Number(plan.plan.credit_per_seat);
      // CreditTotal = 14;
      PricePerSeatTotal += Number(plan.plan.price_per_seat);
      SeatsTotal += Number(plan.plan.available_seat);
      SubTotalPriceDisplay +=
        Number(plan.plan.price_per_seat) * Number(plan.plan.available_seat);
      SubTotalCreditsDisplay +=
        Number(plan.plan.credit_per_seat) * Number(plan.plan.available_seat);
      creditTotal += !plan.flag
        ? Number(plan.plan.credit_per_seat) * Number(plan.plan.available_seat)
        : 0;
      return null;
    });
    return (
      <React.Fragment>
        <div className={classes.lightbg}>
          {company && company.status === "active" ? (
            <Grid container spacing={2}>
              <Grid item xs={4} sm={4}>
                <SelectField
                  value={this.state.selectPlan}
                  name="plan"
                  id="plan"
                  label="Plan "
                  onChange={(_e, data) =>
                    this.setAvailablePlan(this.props.setFieldValue, data)
                  }
                  multi={false}
                  options={this.getAvailablePlanOptions(plans.plans)}
                />
              </Grid>
              <Grid item xs={2} sm={2}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.add_button}
                  onClick={this.selectClickPlan}
                >
                  Associate
                </Button>
              </Grid>
            </Grid>
          ) : (
            ""
          )}

          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">No.</StyledTableCell>
                <StyledTableCell align="left">Cabin Name</StyledTableCell>
                <StyledTableCell align="left">Total Pax</StyledTableCell>
                <StyledTableCell align="left">Assign Seats</StyledTableCell>
                <StyledTableCell align="left">Price Per Seat</StyledTableCell>
                <StyledTableCell align="left">Total Price</StyledTableCell>
                <StyledTableCell align="left">Credit Per Seat</StyledTableCell>
                <StyledTableCell align="left">Total Credit</StyledTableCell>
                <StyledTableCell align="left"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedPlans &&
                selectedPlans.map((row, key) => (
                  <TableRow key={row.key}>
                    <StyledTableCell component="th" scope="row">
                      {key + 1}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {capitalize(row.plan.cabin_name)}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.plan.seat_per_cabin}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <FormControl className={classes.margin}>
                        {!row.flag ? (
                          <BootstrapInput
                            name="total"
                            value={row.plan.available_seat}
                            onChange={(e) => {
                              this.handleInputChange(e, key, "available_seat");
                            }}
                            type="number"
                            inputProps={{
                              max: row.plan.static_available_seats,
                              min: 1,
                            }}
                          />
                        ) : (
                          row.plan.available_seat
                        )}
                      </FormControl>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <FormControl className={classes.margin}>
                        {!row.flag ? (
                          <BootstrapInput
                            value={row.plan.price_per_seat}
                            type="number"
                            id="bootstrap-input"
                            onChange={(e) => {
                              this.handleInputChange(e, key, "price_per_seat");
                              this.setState({ selectedPlans });
                            }}
                            inputProps={{
                              readOnly: !!row.flag,
                              min: 1,
                            }}
                          />
                        ) : (
                          row.plan.price_per_seat
                        )}
                      </FormControl>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {Number(row.plan.price_per_seat) *
                        Number(row.plan.available_seat)}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <FormControl className={classes.margin}>
                        {!row.flag ? (
                          <BootstrapInput
                            type="number"
                            id="bootstrap-input"
                            onChange={(e) => {
                              this.handleInputChange(e, key, "credit_per_seat");
                              this.setState({ selectedPlans });
                            }}
                            inputProps={{
                              readOnly: !!row.flag,
                              min: 1,
                            }}
                            value={row.plan && row.plan.credit_per_seat}
                          >
                            {row.plan && row.plan.credit_per_seat}
                          </BootstrapInput>
                        ) : (
                          parseFloat(
                            row.plan && row.plan.credit_per_seat
                          ).toFixed(2)
                        )}
                      </FormControl>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {!row.flag ? (
                        <BootstrapInput
                          value={
                            Number(row.plan.available_seat) *
                            Number(row.plan.credit_per_seat)
                          }
                          type="number"
                          id="bootstrap-input"
                          onChange={(e) => {
                            this.handleInputCreditChange(e, key);
                            this.setState({ selectedPlans });
                          }}
                          inputProps={{
                            readOnly: !!row.flag,
                            min: 1,
                          }}
                        >
                          {Number(row.plan.available_seat) *
                            Number(row.plan.credit_per_seat)}
                        </BootstrapInput>
                      ) : (
                        Number(row.plan.available_seat) *
                        Number(row.plan.credit_per_seat)
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.flag && (
                        <IconButton
                          aria-label="delete"
                          onClick={() =>
                            this.handleDelete({
                              plan_id: row.plan && row.plan.plan_id,
                              company_id: this.props.companyID,
                            })
                          }
                        >
                          <ClearIcon style={{ fontSize: "15px" }} />
                        </IconButton>
                      )}
                    </StyledTableCell>
                  </TableRow>
                ))}
              {selectedPlans.length > 0 && (
                <TableRow>
                  <StyledTableCell rowSpan={3} />
                  <StyledTableCell>Subtotal</StyledTableCell>
                  <StyledTableCell>{SeatPerCabin}</StyledTableCell>
                  <StyledTableCell>{SeatsTotal}</StyledTableCell>
                  <StyledTableCell>{PricePerSeatTotal}</StyledTableCell>
                  <StyledTableCell>{SubTotalPriceDisplay}</StyledTableCell>
                  <StyledTableCell>
                    {parseFloat(CreditTotal).toFixed(2)}
                  </StyledTableCell>
                  <StyledTableCell>{SubTotalCreditsDisplay}</StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>

          <span
            className={
              this.state.buttonvisible ? classes.addplan : classes.disablebutton
            }
          >
            <Button
              variant="contained"
              color="primary"
              className={classes.add_button}
              onClick={() => this.handleClickAddPlan(creditTotal)}
              style={{ float: "right" }}
            >
              Add Plan
            </Button>
          </span>
        </div>
        <DeleteAlert
          message="Are you sure want to delete this plan?"
          open={this.state.showConfirmDel}
          setOpen={(data) => this.setState({ showConfirmDel: data })}
          alert={this.handleDeletePlan}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errors: state.errors,
    associateplan: state.company.associateplan,
    deletePlan: state.company.deletePlan,
    loading: state.company.loading,
    plans: state.plans,
  };
};

export default connect(mapStateToProps, {
  addAssociatePlan,
  deletePlan,
  getCompany,
  getAvailablePlans,
})(withStyles(styles)(withRouter(FreelancerPlanTab)));
