/* eslint-disable no-unused-expressions */
import React from "react";
import { withStyles, Grid, Typography } from "@material-ui/core";
import classNames from "classnames";

const styles = () => {
  return {
    contentLayout: {
      // margin: "0px 15px 0px 0px"
    },
    imageDiv: {
      border: "1px solid #EFEFEF",
      borderRadius: "5px",
      width: "145px",
      height: "135px",
    },
    imageKnowlegeDiv: {
      border: "1px solid #EFEFEF",
      borderRadius: "5px",
      width: "145px",
      height: "120px",
    },
    boxShadow: {
      boxShadow: "0 9px 10px rgba(0, 0, 0, 0.1), 0 4px 0px rgba(0,0,0,0.05)",
    },
    noBoxShadow: {
      boxShadow: "none",
    },
    setupImg: {
      padding: "8%",
      // height: "100px"
      height: "90px",
      width: "120px",
    },
    subTitle: {
      margin: "0% 0% 3%",
      textAlign: "center",
      fontWeight: "600",
      lineHeight: "18px",
      fontSize: "13px",
    },
    // alliedSetupImg: {
    //   padding: "8%",
    //   height: "85px",
    //   width: "120px"
    // }
    // alliedSubTitle: {
    //   margin: "0% 0% 3%",
    //   textAlign: "center",
    //   fontWeight: "600",
    //   lineHeight: "18px",
    //   fontSize: "13px"
    // }
  };
};

const ImageTextLayout = (props) => {
  const { classes, image, text, knowledge, name, category } = props;

  // eslint-disable-next-line no-shadow
  const setCategoryVal = (name) => {
    props.setCategory(name);
  };
  return (
    <Grid item lg={4} xs={12}>
      <Grid
        className={classNames(
          knowledge ? classes.imageKnowlegeDiv : classes.imageDiv,
          name === category ? classes.boxShadow : classes.noBoxShadow
        )}
        onClick={() => setCategoryVal(name)}
      >
        <img src={image} alt={text} className={classes.setupImg}></img>
        <Typography
          className={classes.subTitle}
          variant="subtitle1"
          component="p"
        >
          {text}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(ImageTextLayout);
