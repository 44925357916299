import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router-dom";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import classNames from "classnames";
import { CircularLoader } from "../../Components/Common/Spinner";
import { capitalize, spacecapitalize } from "../../Utils/StringFunctions";
import ScreenHeader from "../../Components/Common/ScreenHeader";
import NameTruncate from "../../Components/Common/NameTruncate";
import Pagination from "../../Components/Common/Table/TablePagination";
import nodata from "../../Assets/nodata.png";
import SuggestStatus from "./SuggestStatus";
import { getsuggest } from "../../Actions/SuggestFeatureAction";

const moment = require("moment");

const styles = (theme) => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    backgroundColor: "white",
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    padding: "15px 24px 24px",
    flexGrow: 1,
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      padding: "15px 24px 24px",
    },
  },
  root: {
    backgroundColor: "#fff",
  },
  table: {
    minWidth: 650,
    marginTop: "20px",
  },
  resource_form_modal: {
    width: "500px",
    height: "410px",
  },
  form_modal: {
    minWidth: "500px",
    height: "410px",
  },
  correctbutton: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0)",
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  ScreenHeader: {
    marginLeft: "-28px",
    width: "100%",
  },
  cancelbutton: {
    marginRight: "20px",
    backgroundColor: "#dddddd",
    color: "#151039",
    "&:hover": {
      color: "#151039",
      backgroundColor: "#d1d1d1!important",
    },
  },
  addbutton: {
    color: "#fff",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  modelHeader: {
    paddingBottom: "5px",
    position: "relative",
    "&:after": {
      top: "48px",
      left: "24px",
      width: "24px",
      borderBottom: "3px solid #fb0",
      position: "absolute",
    },
  },
  modalFooter: {
    padding: "40px 0px 8px",
    display: "flex",
    justifyContent: "flex-end",
  },
  root1: {
    "&:nth-child(even)": {
      backgroundColor: "#FCFDFE",
    },
  },
  rejected: {
    color: "#cdcdcd",
  },
  addDeleteUser: {
    display: "flex",
  },
  headerSpacing: {
    margin: "13% 0% 0% 3%",
  },
  uploadClubBanner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "50px",
  },
  borderppup: {
    margin: "40px 10px 30px 10px",
    boxsizing: "border-box",
    width: "300px",
    height: "200px",
    borderRadius: "5px",
    border: "3px dashed pink",
  },
  backButton: {
    margin: "0% 0% 2% 0%",
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F8FAFF",
    color: theme.palette.common.black,
    borderBottom: "none",
    padding: "8px 40px 8px 16px",
  },
  body: {
    fontSize: 14,
    borderBottom: "none",
  },
}))(TableCell);

export class CofirmSuggest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestFeature: [],
      page: 0,
      rowsPerPage: 10,
    };
  }

  componentDidMount() {
    this.props.getsuggest();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.suggestFeature !== this.props.suggestFeature) {
      let suggestFeature = this.props.suggestFeature || [];
      suggestFeature = suggestFeature.map((x, id) => {
        return Object.assign({}, x, {
          idx: id + 1,
        });
      });
      this.setState({
        suggestFeature,
      });
    }
    if (
      this.props.statusUpdate !== prevProps.statusUpdate &&
      this.props.statusUpdate.success
    ) {
      this.props.getsuggest();
    }
    if (prevProps.loading !== this.props.loading) {
      this.props.setLoading(this.props.loading);
      this.setState({
        loading: this.props.loading,
      });
    }
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  render() {
    const { loading, page, rowsPerPage, suggestFeature } = this.state;
    const { classes } = this.props;
    const itemLength = suggestFeature ? suggestFeature.length : 0;
    if (loading) {
      return <CircularLoader />;
    }
    if (suggestFeature && suggestFeature.length <= 0) {
      return (
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.headerSpacing}
        >
          <Grid item justify="center">
            <Grid item sm={12} lg={12}>
              <img src={nodata} alt="" width="76%" />
            </Grid>
          </Grid>
        </Grid>
      );
    }

    return (
      <main className={classes.layout}>
        <Button
          color="grey"
          onClick={() => this.props.history.push("/settings")}
          className={classes.backButton}
          variant="contained"
          size="small"
        >
          Back
        </Button>
        <Paper className={classes.paper}>
          <div className={classes.header}>
            <div className={classes.ScreenHeader}>
              <ScreenHeader type="Suggest Feature Confirmation" />
            </div>
          </div>
          <div className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Sr. No.</StyledTableCell>
                  <StyledTableCell align="left">Date</StyledTableCell>
                  <StyledTableCell align="left">Module</StyledTableCell>
                  <StyledTableCell align="left">Title</StyledTableCell>
                  <StyledTableCell align="left">Details</StyledTableCell>
                  <StyledTableCell align="left">Name</StyledTableCell>
                  <StyledTableCell align="left">Contact No.</StyledTableCell>
                  <StyledTableCell align="left">Company Name</StyledTableCell>
                  <StyledTableCell align="left">Status</StyledTableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {suggestFeature &&
                  suggestFeature
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, id) => {
                      return (
                        <TableRow className={classes.root1}>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            className={classNames(classes.accept)}
                          >
                            {page * rowsPerPage + id + 1}
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            className={classNames(classes.accept)}
                          >
                            {moment(item.createdAt).format("DD/MM/YYYY")}
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            className={classNames(classes.accept)}
                          >
                            {capitalize(item.module_name)}
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            className={classNames(classes.accept)}
                          >
                            {capitalize(item.feature_title)}
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            className={classNames(classes.accept)}
                          >
                            {capitalize(item.feature_description)}
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            className={classNames(classes.accept)}
                          >
                            <NameTruncate
                              text={spacecapitalize(
                                `${
                                  item &&
                                  item.created_by &&
                                  item.created_by.first_name
                                } ${
                                  item &&
                                  item.created_by &&
                                  item.created_by.last_name
                                }`
                              )}
                              amount={22}
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            className={classNames(classes.accept)}
                          >
                            {item &&
                              item.created_by &&
                              item.created_by.contact_number}
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            className={classNames(classes.accept)}
                          >
                            {item &&
                            item.created_by &&
                            item.created_by.company_id
                              ? capitalize(
                                  item.created_by.company_id.company_name
                                )
                              : "-"}
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            className={classNames(classes.accept)}
                          >
                            <SuggestStatus
                              selectedItem={item.feature_status}
                              id={item._id}
                            />
                          </StyledTableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </div>
        </Paper>
        <Pagination
          count={itemLength}
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    suggestFeature: state.suggestfeature.all_suggesion,
    loading: state.suggestfeature.loading,
    statusUpdate: state.suggestfeature.suggesion_status,
  };
};

export default connect(mapStateToProps, { getsuggest })(
  withStyles(styles)(withRouter(CofirmSuggest))
);
