/* eslint-disable camelcase */
import React from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
  Grid,
} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const styles = () => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    // marginBottom: "18px"
  },
  mainTitle: {
    fontWeight: "600",
    fontSize: "20px",
    textAlign: "left",
    color: "#222222",
    margin: "0px",
    lineHeight: "25px",
  },
  heading: {
    fontSize: "15px",
    color: "#222222",
    fontWeight: "500",
    margin: "4px 0px",
    textAlign: "left",
  },
  description: {
    color: "#222222",
    fontSize: "13px",
    lineHeight: "18px",
  },
  mainSlot: {
    display: "flex",
    justifyContent: "space-between",
  },
  areaSection: {
    display: "flex",
    flexDirection: "row",
  },
});

const theme = createMuiTheme({
  overrides: {
    MuiPaper: {
      elevation1: {
        boxShadow: "none",
      },
    },
    MuiExpansionPanelDetails: {
      // Name of the component ⚛️ / style sheet
      root: {
        padding: "8px",
        border: "none",
      },
    },
    MuiCheckbox: {
      root: {
        padding: "0px",
      },
    },
    MuiExpansionPanel: {
      rounded: {
        border: "none",
        borderBottom: "1px solid #EFEFEF",
        borderRadius: "0px",
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        padding: "0 8px 0 8px",
      },
    },
  },
});

function Faqs(props) {
  const { classes } = props;
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.main}>
        <div className={classes.header}>
          <Typography align="left" variant="h1" className={classes.mainTitle}>
            FAQs
          </Typography>
        </div>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <ExpansionPanel
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.mainSlot}
              >
                <Typography className={classes.heading}>
                  What parking facilities will I get?
                </Typography>
              </ExpansionPanelSummary>
              <div className={classes.areaSection}>
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <ExpansionPanelDetails>
                      <Typography align="left" className={classes.description}>
                        We have a fixed number of allocated parking space for
                        our community members at every centre. We encourage a
                        first-come-first-serve parking method. In case you need
                        a dedicated parking spot, you can connect with your
                        Community Manager and discuss the additional charges for
                        the same. Charges may vary as per the location.
                      </Typography>
                    </ExpansionPanelDetails>
                  </Grid>
                </Grid>
              </div>
            </ExpansionPanel>
          </Grid>

          <Grid item xs={12} sm={12}>
            <ExpansionPanel
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.mainSlot}
              >
                <Typography className={classes.heading}>
                  How do you charge for the conference and meeting rooms and is
                  there a min-max time limit to book the same?
                </Typography>
              </ExpansionPanelSummary>
              <div className={classes.areaSection}>
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <ExpansionPanelDetails>
                      <Typography align="left" className={classes.description}>
                        You can book the meeting/conference rooms with the
                        credit points you received as part of your welcome kit.
                        The minimum no. of hours to book any of these services
                        are 30 minutes, where as you can book them for as long
                        as you need.
                      </Typography>
                    </ExpansionPanelDetails>
                  </Grid>
                </Grid>
              </div>
            </ExpansionPanel>
          </Grid>
          <Grid item xs={12} sm={12}>
            <ExpansionPanel
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.mainSlot}
              >
                <Typography className={classes.heading}>
                  What if we use the conference rooms/meeting rooms for less
                  than the no. of hours booked?
                </Typography>
              </ExpansionPanelSummary>
              <div className={classes.areaSection}>
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <ExpansionPanelDetails>
                      <Typography align="left" className={classes.description}>
                        There is no provision for the under-usage of the booked
                        hours.
                      </Typography>
                    </ExpansionPanelDetails>
                  </Grid>
                </Grid>
              </div>
            </ExpansionPanel>
          </Grid>
          <Grid item xs={12} sm={12}>
            <ExpansionPanel
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.mainSlot}
              >
                <Typography className={classes.heading}>
                  Will someone attend my guests and/or serve beverages in case
                  we have important guests at the premises?
                </Typography>
              </ExpansionPanelSummary>
              <div className={classes.areaSection}>
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <ExpansionPanelDetails>
                      <Typography align="left" className={classes.description}>
                        Though we have a self-serve culture for both – our
                        clients & their guests, in case of important/special
                        guests you can get in touch with your Community Manager
                        and request for a dedicated resource to attend & serve
                        Water/Tea/Coffee to your clients.
                      </Typography>
                    </ExpansionPanelDetails>
                  </Grid>
                </Grid>
              </div>
            </ExpansionPanel>
          </Grid>

          <Grid item xs={12} sm={12}>
            <ExpansionPanel
              expanded={expanded === "panel5"}
              onChange={handleChange("panel5")}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.mainSlot}
              >
                <Typography className={classes.heading}>
                  Can I get a report of the usage of my credit points? How do I
                  buy extra points in case I exhaust all my points?
                </Typography>
              </ExpansionPanelSummary>
              <div className={classes.areaSection}>
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <ExpansionPanelDetails>
                      <Typography align="left" className={classes.description}>
                        You can download the report from our application –
                        Collab Station. Same goes in case you want to buy extra
                        credit points. If you have any query or are facing any
                        troubles in getting access to your credit usage report
                        or in buying extra credit points, you can get in touch
                        with your Community Manager.
                      </Typography>
                    </ExpansionPanelDetails>
                  </Grid>
                </Grid>
              </div>
            </ExpansionPanel>
          </Grid>
          <Grid item xs={12} sm={12}>
            <ExpansionPanel
              expanded={expanded === "panel6"}
              onChange={handleChange("panel6")}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.mainSlot}
              >
                <Typography className={classes.heading}>
                  What happens in case I lose my RFID card?
                </Typography>
              </ExpansionPanelSummary>
              <div className={classes.areaSection}>
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <ExpansionPanelDetails>
                      <Typography align="left" className={classes.description}>
                        In case you lose your RFID card, you are expected to
                        raise a ticket as soon as possible from our application
                        – Collab Station. You can request for a new RFID card
                        with an additional charge of Rs. 330.
                      </Typography>
                    </ExpansionPanelDetails>
                  </Grid>
                </Grid>
              </div>
            </ExpansionPanel>
          </Grid>
        </Grid>
      </div>
    </ThemeProvider>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.spaces.loading,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(Faqs));
