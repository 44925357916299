import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { checkPermission } from "../../Utils/checkPermission";

const styles = () => {
  return {
    bodystyle: {
      color: "white",
      padding: "7px 16px",
      borderRadius: "4px",
      textDecoration: "none",
      fontSize: "0.875rem",
      fontWeight: "500",
      boxShadow: "rgba(137, 163, 169, 0.3) 0px 3px 5px 2px",
      backgroundColor: "#211b4e !important",
      "&:hover": {
        backgroundColor: "#151039 !important",
      },
    },
  };
};

class AddFormButton extends Component {
  render() {
    const { url, type } = this.props;
    const { classes } = this.props;
    const renderpermission = () => {
      switch (type) {
        case "Company":
          return "COMPANY_CREATE";
        case "Co-Worker":
          return "COWORKER_CREATE";
        case "Event":
          return "EVENT_CREATE";
        case "Visitor":
          return "VISITOR_CREATE";
        case "Partner":
          return "PARTNER_CREATE";
        case "User":
          return "USER_CREATE";
        case "Freelancer":
          return "COMPANY_CREATE";
        default:
          return null;
      }
    };
    return (
      <Grid container direction="row" justify="flex-end" alignItems="flex-end">
        {checkPermission(renderpermission()) && (
          <Grid item style={{ marginRight: "4%" }}>
            <Link
              to={url}
              variant="contained"
              className={classes.bodystyle}
              component="button"
            >
              {/* <Plus className={classes.plus}>size={20}</Plus> */}
              Add {type}
            </Link>
          </Grid>
        )}
      </Grid>
    );
  }
}

export default withStyles(styles)(AddFormButton);
