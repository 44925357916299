/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Grid,
  Button,
  Paper,
  CssBaseline,
  Typography,
} from "@material-ui/core";
import { getPosts, createComment } from "../../Actions/postActions";
import { joinClub, getSingleClubs } from "../../Actions/AllClubActions";
import { capitalize } from "../../Utils/StringFunctions";
import AddPost from "./AddPost";
import PostCard from "./PostCard";
import OwnerDetail from "./OwnerDetail";

const styles = (theme) => {
  const borderRadius = theme.shape.borderRadius * 4;
  return {
    main: {
      backgroundColor: "#FDFDFD",
    },
    clubName: {
      color: "#333",
      fontWeight: 600,
      fontSize: "24px",
      marginLeft: "-0.7%",
    },
    main_content: {
      // padding: "5px 35px",
      padding: "14px 30px 17px",
      boxShadow: "0 9px 10px rgba(0, 0, 0, 0.06), 0 4px 0px rgba(0,0,0,0.05)",
      marginBottom: "34px",
      backgroundColor: "#fff",
    },

    banner: { maxHeight: "300px" },
    clubMain: {
      display: "flex",
      justifyContent: "space-between",
    },
    noPosts: {
      textAlign: "center",
      display: "block",
      // marginTop: "20%",
      color: "lightgrey",
    },
    button: {
      fontSize: 12,
      borderRadius: `${borderRadius}px`,
      color: "#fff",
      padding: "6px 27px",
      backgroundColor: "#211b4e !important",
      "&:hover": {
        backgroundColor: "#151039 !important",
      },
      "&:disabled": {
        backgroundColor: "lightgrey !important",
      },
    },
    addPost: {
      marginBottom: "35px",
    },
    mainPost: {
      margin: "2%",
    },
    noPostPaper: {
      height: "200px",
      display: "flex",
      textAlign: "center",
      alignItems: "center",
      justifyContent: "center",
    },
    disabledButton: {
      fontSize: 12,
      padding: "6px 27px",
      borderRadius: `${borderRadius}px`,
      backgroundColor: "lightgrey !important",
    },
  };
};

class ViewaClub extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      get_single_club: {},
      // eslint-disable-next-line react/no-unused-state
      comment: {},
      // eslint-disable-next-line react/no-unused-state
      join_status: "no",
    };
    this.state.id = this.props.match.params && this.props.match.params.id;
  }

  componentDidMount() {
    this.props.getSingleClubs(this.state.id);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.get_single_club !== prevState.get_single_club) {
      return { get_single_club: nextProps.get_single_club };
      // eslint-disable-next-line no-else-return
    } else return [];
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.get_single_club !== this.props.get_single_club) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ get_single_club: this.props.get_single_club });
    }
    if (prevProps.comment !== this.props.comment) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        comment: this.props.comment,
      });
    }
    if (
      prevProps.post &&
      prevProps.post.success !== this.props.post.success &&
      this.props.post.success
    ) {
      this.props.getSingleClubs(this.state.id);
    }

    if (
      prevProps.comment &&
      prevProps.comment.success !== this.props.comment.success &&
      this.props.comment.success
    ) {
      this.props.getSingleClubs(this.state.id);
    }
    if (prevProps.join_status !== this.props.join_status) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        join_status: "yes",
      });
      this.props.getSingleClubs(this.state.id);
    }
  }

  handleJoinStatus = () => {
    let { join_status } = this.state;
    const { get_single_club } = this.state;
    if (get_single_club.join_status === "yes") {
      this.setState({ join_status: "no" });
      join_status = "no";
    } else {
      this.setState({ join_status: "yes" });
      join_status = "yes";
    }
    this.props.joinClub(get_single_club.club_id, join_status);
  };

  render() {
    const { classes } = this.props;
    const { get_single_club } = this.state;
    const user_id = localStorage.getItem("user_id");
    return (
      <React.Fragment>
        <CssBaseline />
        <main>
          <div className={classes.main}>
            <div className={classes.banner}>
              <div className={classes.banner}>
                <img
                  src={get_single_club.banner}
                  alt="banner"
                  width="100%"
                  className={classes.banner}
                />
              </div>
            </div>

            <div className={classes.main_content}>
              <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12}>
                  <Grid item sm={12} lg={12}>
                    <div className={classes.clubMain}>
                      <Typography component="h2" className={classes.clubName}>
                        {capitalize(get_single_club.club_name)}
                      </Typography>
                      <Button
                        className={classNames(
                          get_single_club.join_status === "yes"
                            ? classes.disabledButton
                            : classes.button
                        )}
                        disabled={
                          (get_single_club.owner &&
                            get_single_club.owner._id === user_id) ||
                          get_single_club.join_status === "yes"
                        }
                        onClick={this.handleJoinStatus}
                      >
                        {get_single_club.join_status === "yes"
                          ? `Joined`
                          : `Join`}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <div className={classes.mainPost}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  {get_single_club.join_status === "yes" && (
                    <div className={classes.addPost}>
                      <AddPost id={this.state.id} type="club" />
                    </div>
                  )}
                  {get_single_club.post && get_single_club.post.length <= 0 && (
                    <Paper className={classes.noPostPaper}>
                      <Typography variant="h6" className={classes.noPosts}>
                        No posts to show!!
                      </Typography>
                    </Paper>
                  )}
                  {get_single_club.post &&
                    get_single_club.post.map((post, i) => {
                      // eslint-disable-next-line react/no-array-index-key
                      return (
                        <PostCard
                          data={post}
                          id={this.state.id}
                          key={i}
                          type="club"
                        />
                      );
                    })}
                </Grid>
                <Grid item xs={6} md={4}>
                  <OwnerDetail data={get_single_club} />
                </Grid>
              </Grid>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

ViewaClub.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    get_single_club: state.clubs.get_single_club,
    post: state.clubs.post || {},
    comment: state.clubs.comment || {},
    join_status: state.clubs.join_status,
  };
};

export default connect(mapStateToProps, {
  getSingleClubs,
  getPosts,
  createComment,
  joinClub,
})(withStyles(styles)(ViewaClub));
