import React from "react";
import { Grid } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import FormLabel from "@material-ui/core/FormLabel";
import {
  StrengthOptions,
  ClienttypeOptions,
  IndustryOptions,
} from "../../Utils/dropDownData";
import { API_URL } from "../../Components/config";
import RichEditorExample from "../../Components/Common/RichEditor";
import InputField from "../../Components/Common/Form/InputField";
import UploadField from "../../Components/Common/Form/UploadField";
import SelectField from "../../Components/Common/Form/SelectField";
import DatePickerField from "../../Components/Common/Form/DatePickerField";

const styles = () => ({
  lightbg: {
    padding: "20px 26px",
  },
  label: {
    margin: "15px 0px 8px 0px",
    fontSize: "12px",
    fontWeight: "400",
    color: "rgba(0, 0, 0, 0.54)",
  },
  contract_main: {
    display: "block",
  },
  contract: {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px 10px",
  },
  durationHour: {
    marginRight: "18px",
  },
});

class Step1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deletedKey: [],
      errors: {},
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      const { data } = this.props;
      data.imagePreviewUrl = API_URL + data.photo;
      this.setState(Object.assign([], data));
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleNext = () => {
    this.props.handleNext(this.state);
  };

  onEditorStateChange = (html) => {
    this.setState({
      description: html,
    });
    this.props.setFieldValue("description", html);
  };

  setDelImageUrl = (url) => {
    const { deletedKey } = this.state;
    this.setState({ deletedKey: deletedKey.push(url) });
  };

  render() {
    const { classes, values } = this.props;
    const { errors } = this.state;

    return (
      <React.Fragment>
        <div className={classes.lightbg}>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={6}>
              <InputField
                name="company_name"
                label="Company Name"
                placeholder="Enter Company Name"
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <InputField
                name="display_name"
                label="Display Name"
                placeholder="Enter Display Name"
                max={20}
              />
            </Grid>
            <Grid item xs={5} sm={5}>
              <UploadField
                label="Upload logo"
                name="logo"
                id="logo"
                onChange={this.props.setFieldValue}
                value={values.logo}
                error={errors.logo}
                folder="company/logo"
                delImageUrl={() => this.setDelImageUrl}
              />
            </Grid>
            <Grid item xs={12} sm={12} style={{ paddingBottom: "0px" }}>
              <FormLabel className={classes.label} component="legend">
                Description
              </FormLabel>
              <RichEditorExample
                name="description"
                editorState={values.editorState}
                onChange={this.props.setFieldValue}
                onBlur={this.props.setFieldTouched}
                placeholder="Enter Company Description"
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <SelectField
                value={values.industry}
                name="industry"
                id="industry"
                label="Industry"
                onChange={this.props.setFieldValue}
                onBlur={this.props.setFieldTouched}
                multi={false}
                error={errors.industry}
                options={IndustryOptions}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <SelectField
                value={values.employee_strength}
                name="employee_strength"
                id="employee_strength"
                label="Employee Strength"
                onChange={this.props.setFieldValue}
                onBlur={this.props.setFieldTouched}
                multi={false}
                error={errors.employee_strength}
                options={StrengthOptions}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <SelectField
                value={values.client_type}
                name="client_type"
                id="client_type"
                label="Type of Client"
                onChange={this.props.setFieldValue}
                onBlur={this.props.setFieldTouched}
                multi={false}
                error={errors.client_type}
                options={ClienttypeOptions}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              {/* <FormLabel
                component="legend"
                className={classes.label}
                style={{
                  fontSize: "15px",
                  fontWeight: "400",
                  color: "#252631",
                  marginTop: "0px",
                  marginBottom: "-6px"
                }}
              >
                Contract
              </FormLabel> */}

              <UploadField
                label="Upload Contract"
                name="contract_upload_contract"
                id="contract_upload_contract"
                onChange={this.props.setFieldValue}
                value={values.contract_upload_contract}
                upload="company/contract"
                fileType="doc"
                folder="company/contract"
                delImageUrl={() => this.setDelImageUrl}
              />
            </Grid>
            <Grid item container className={classes.contract_main} spacing={2}>
              {/* <FormLabel
                component="legend"
                className={classes.label}
                style={{
                  fontSize: "15px",
                  fontWeight: "400",
                  color: "#252631"
                }}
              >
                Contract
              </FormLabel> */}
              <div className={classes.contract}>
                <Grid item xs={6} sm={6} className={classes.durationHour}>
                  <DatePickerField
                    name="contract_start_date"
                    onChange={this.props.setFieldValue}
                    onBlur={this.props.setFieldTouched}
                    value={values.contract_start_date}
                    disableFuture={false}
                    label=" Contract Start Date"
                    style={{ width: "95%" }}
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <DatePickerField
                    name="contract_end_date"
                    onChange={this.props.setFieldValue}
                    onBlur={this.props.setFieldTouched}
                    value={values.contract_end_date}
                    disableFuture={false}
                    minDate={values.contract_start_date}
                    label="Contract End Date"
                  />
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Step1);
