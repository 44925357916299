/* eslint-disable camelcase */
import axios from 'axios'
import {
  GET_ERRORS,
  GET_AVAIL_RESOURCES,
  RESOURCE_LOADING,
  ADD_RESOURCE,
  RESOURCE_ERRORS,
  GET_UNBOOKED_RESOURCES,
  GET_RESOURCES_ERRORS,
  GET_SINGLE_RESOURCE,
  EDIT_RESOURCE_ERROR,
  EDIT_RESOURCE,
  GET_SPACE_RESOURCES,
} from './types'
import { showSuccess, showError } from './ActionsHelper'
import { transformErrorsFromApi } from './TransformError'

//  Loading
export const setresourceLoading = () => {
  return {
    type: RESOURCE_LOADING,
  }
}

export const getAvailableResources = (spaceId) => (dispatch) => {
  let url = `/api/resource`
  if (spaceId) {
    url = `/api/resource/${spaceId}`
  }
  dispatch(setresourceLoading())
  axios
    .get(url)
    .then((result) => {
      dispatch({
        type: GET_AVAIL_RESOURCES,
        payload: result.data,
      })
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : '',
      })
    })
}

export const getSpaceResource = (spaceId) => (dispatch) => {
  dispatch(setresourceLoading())
  axios
    .post(`/api/resource/space/multiple`, { space_ids: spaceId })
    .then((result) => {
      dispatch({
        type: GET_SPACE_RESOURCES,
        payload: result.data,
      })
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : '',
      })
    })
}

export const getUnbookedResources = (userData) => (dispatch) => {
  // eslint-disable-next-line no-param-reassign
  userData.space = localStorage.getItem('space_id')
  // return;
  dispatch(setresourceLoading())
  axios
    .post(`/api/resource/available`, userData)
    .then((result) => {
      dispatch({
        type: GET_UNBOOKED_RESOURCES,
        payload: result.data,
      })
    })
    .catch((err) => {
      dispatch({
        type: GET_RESOURCES_ERRORS,
        payload: err && err.response ? err.response.data : '',
      })
    })
}

export const addresource = (userData, setErrors, history, setSubmitting) => (
  dispatch
) => {
  dispatch(setresourceLoading())
  axios
    .post('/api/resource', userData)
    .then((result) => {
      dispatch({
        type: ADD_RESOURCE,
        payload: result.data,
      })
      // history.push("/resources");
      dispatch(showSuccess('Resource'))
      setSubmitting(false)
    })
    .catch((err) => {
      const error = err.response || {}
      dispatch({ type: RESOURCE_ERRORS, payload: error.data })
      dispatch(showError('There was some error while add resource '))
      setSubmitting(false)
      setErrors(transformErrorsFromApi(error))
    })
}

//GET SINGLE RESOURCE
export const getSingleResource = (id) => (dispatch) => {
  dispatch(setresourceLoading())
  axios
    .get(`/api/resource/${id}`)
    .then((res) => {
      dispatch({
        type: GET_SINGLE_RESOURCE,
        payload: res.data,
      })
    })
    .catch((err) =>
      dispatch({
        type: GET_RESOURCES_ERRORS,
        payload: err.response && err.response.data,
      })
    )
}

export const editResource = (resource_id, data, setErrors, setSubmitting) => (
  dispatch
) => {
  dispatch(setresourceLoading())
  axios
    .put(`/api/resource/${resource_id}`, data)
    .then((result) => {
      dispatch({
        type: EDIT_RESOURCE,
        payload: result.data,
      })
      dispatch(showSuccess('', 'Resource edited successfully!'))
      setSubmitting(false)
    })
    .catch((err) => {
      const error = err.response
      dispatch({ type: EDIT_RESOURCE_ERROR, payload: {} })
      setSubmitting(false)
      setErrors(transformErrorsFromApi(error))
    })
}
