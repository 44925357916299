import withStyles from "@material-ui/core/styles/withStyles";
import React, { Component } from "react";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  Typography,
  Grid,
  Paper,
  Button,
  CardContent,
} from "@material-ui/core";
import { connect } from "react-redux";
import { checkPermission } from "../../Utils/checkPermission";
import { capitalize, spacecapitalize } from "../../Utils/StringFunctions";
// import PDF from "../../Assets/PDF.png";
// import PDF_BW from "../../Assets/pdf_bw.png";
// import PPT from "../../Assets/ppt.png";
// import PPT_BW from "../../Assets/ppt_bw.png";
// import classNames from "classnames";

const moment = require("moment");

const $quoteSym = " ";
const styles = (theme) => {
  const borderRadius = theme.shape.borderRadius * 4;

  return {
    aboutinnerdetail: {
      padding: "25px 0px 15px 1px",
    },
    aboutinnercontent: {
      padding: "25px 0px 15px 1px",
      marginLeft: "-3px",
    },
    main: {
      padding: "0px",
      backgroundColor: "#ffffff",
    },
    applicationHeader: {
      margin: "20px 3px",
      paddingBottom: "5px",
      fontSize: "16px",
      fontWeight: "500",
      position: "relative",
      "&:after": {
        top: "31px",
        left: "1px",
        width: "24px",
        borderBottom: "3px solid #fb0",
        content: `'${$quoteSym}'`,
        position: "absolute",
      },
    },
    eventsubtitle: {
      fontSize: "15px",
      color: "#717070",
      paddingTop: "4px",
    },
    paper: {
      height: 140,
      width: 230,
      padding: theme.spacing(2),
      textAlign: "center",
      backgroundColor: "#fff",
      border: "1px solid #efefef",
      borderRadius: "5px",
      marginTop: "17px",
    },
    mainabout: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      paddingTop: "2%",
      paddingLeft: "20px",
    },
    button: {
      margin: "10px 10px 0px 0px",
      borderRadius: "20px",
      backgroundColor: "#efefef",
      color: "#626262",
      cursor: "default",
      "&:hover": {
        backgroundColor: "#efefef",
        color: "#626262",
      },
    },
    eventInfo: {
      display: "flex",
      "& p": {
        fontSize: "12px",
      },
    },
    contractData: {
      marginRight: "60px",
    },
    description: {
      textAlign: "justify",
    },
    innertitle: {
      fontSize: "12px",
      color: "#ababab",
    },
    presentationTitle: {
      color: "lightgrey",
      fontSize: "22px",
      fontWeight: "600",
      paddingTop: "14px",
    },
    companyContent: {
      fontSize: "14px",
      lineHeight: 1.3,
    },
    cardContent: {
      marginTop: " 1%",
      padding: "14px 4px",
    },
    contentFonts: {
      fontSize: "14px",
    },
    downloadbutton: {
      margin: theme.spacing(1),
      fontSize: 10,
      border: "1px solid #4682B4",
      borderRadius: `${borderRadius}px`,
      backgroundColor: "#fff",
      color: "#4682B4",
      "&:hover": {
        backgroundColor: "#f7f2ff !important",
        color: "#4682B4",
        border: "1px solid #4682B4",
      },
      "&:disabled": {
        border: " 1px solid rgba(0, 0, 0, 0.26)",
      },
    },
    downloadIcon: {
      height: "35%",
      width: "35%",
      padding: "8px",
    },
    media: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "0px",
    },
    downloadSection: {
      textAlign: "center",
      position: "relative",
      top: "-25%",
      right: "0%",
    },
  };
};

class AboutCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // coworkers: [],
      // dense: false,
      // secondary: false
    };
  }

  render() {
    const { classes, company } = this.props;
    return (
      <React.Fragment>
        <CssBaseline />
        <main className={classes.main}>
          <div className={classes.mainabout}>
            <div className={classes.heroUnit}>
              <span className={classes.applicationHeader}>
                About {spacecapitalize(company.company_name)}
              </span>
            </div>
            <Grid
              className={classes.aboutinnercontent}
              container
              item
              xs={12}
              lg={12}
              spacing={2}
            >
              <Grid className={classes.description}>
                <div
                  className={classes.companyContent}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: company.description }}
                />
              </Grid>

              {/* <Grid item xs={5} lg={5}>
                <Grid
                  container
                  className={classes.root}
                  spacing={2}
                  style={{ paddingLeft: "10%" }}
                >
                  <Grid item xs={12}>
                    <Grid
                      container
                      className={classes.demo}
                      justify="center"
                      spacing={2}
                    >
                      <Grid item>
                        <Paper className={classes.paper}>
                          {company.contract_upload_contract && (
                            <Typography
                              className={classNames(
                                classes.presentationTitle,
                                classes.media
                              )}
                              component="p"
                            >
                              <img
                                src={PDF}
                                alt=""
                                className={classes.downloadIcon}
                              />
                              <img
                                src={PPT}
                                alt=""
                                className={classes.downloadIcon}
                              />
                            </Typography>
                          )}
                          {!company.contract_upload_contract && (
                            <Typography
                              className={classNames(
                                classes.presentationTitle,
                                classes.media
                              )}
                              component="p"
                            >
                              <img
                                src={PDF_BW}
                                alt=""
                                className={classes.downloadIcon}
                              />
                              <img
                                src={PPT_BW}
                                alt=""
                                className={classes.downloadIcon}
                              />
                            </Typography>
                          )}
                        </Paper>
                        <div className={classes.downloadSection}>
                          {company.contract_upload_contract && (
                            <a
                              style={{ textDecoration: "none" }}
                              href={company.contract_upload_contract}
                              // eslint-disable-next-line react/jsx-no-target-blank
                              target="_blank"
                              // className={classes.downloadbutton}
                              download
                            >
                              <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                className={classes.downloadbutton}
                              >
                                Presentation Download
                              </Button>
                            </a>
                          )}
                          {!company.contract_upload_contract && (
                            <Button
                              size="small"
                              variant="contained"
                              color="primary"
                              className={classes.downloadbutton}
                              disabled
                            >
                              Presentation not Available
                            </Button>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid> */}
            </Grid>

            {/* Services */}
            {company.services && company.services.length !== 0 && (
              <div className={classes.heroUnit}>
                <span className={classes.applicationHeader}>Services</span>
              </div>
            )}
            <Grid
              container
              direction="row"
              className={classes.aboutinnercontent}
            >
              <Grid item>
                {company.services
                  ? company.services.map((service) => {
                      return (
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.button}
                        >
                          {capitalize(service.label)}
                        </Button>
                      );
                    })
                  : "No services"}
              </Grid>
            </Grid>

            {/* SPOC details and Contract data */}
            {checkPermission("COMPANY_CRITICAL_DETAILS") && (
              <Grid
                className={classes.aboutinnerdetail}
                container
                item
                xs={12}
                lg={12}
                spacing={2}
              >
                <Grid item xs={7} lg={7}>
                  <div className={classes.heroUnit}>
                    <span className={classes.applicationHeader}>
                      Contract Details
                    </span>
                  </div>
                  <Paper className={classes.containerPaper}>
                    <CardContent className={classes.cardContent}>
                      <div className={classes.eventInfo}>
                        <div className={classes.contractData}>
                          <Typography
                            variant="subtitle2"
                            component="p"
                            className={classes.innertitle}
                          >
                            Start Date
                          </Typography>

                          <Typography
                            variant="subtitle2"
                            className={classes.contentFonts}
                          >
                            {moment(company.contract_start_date).format(
                              "DD/MM/YYYY"
                            )}
                          </Typography>
                        </div>
                        <div className={classes.contractData}>
                          <Typography
                            variant="subtitle2"
                            component="p"
                            className={classes.innertitle}
                          >
                            End Date
                          </Typography>

                          <Typography
                            variant="subtitle2"
                            className={classes.contentFonts}
                          >
                            {moment(company.contract_end_date).format(
                              "DD/MM/YYYY"
                            )}
                          </Typography>
                        </div>
                      </div>
                    </CardContent>
                  </Paper>
                </Grid>
              </Grid>
            )}
            {/* {checkPermission("COMPANY_SENSITIVE_DATA") && (
              <Tableview company={this.props.company} />
            )} */}
          </div>
        </main>
      </React.Fragment>
    );
  }
}

AboutCompany.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => {
  return {
    company: state.company.company,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(AboutCompany));
