import React from "react";
import PropTypes from "prop-types";
import { FormikRadioGroupField } from "formik-material-fields";
import { withStyles } from "@material-ui/core/styles";

const styles = () => {
  return {
    mainLabel: {
      fontSize: "13px",
      fontWeight: 400,
    },
  };
};

const RadioField = ({ classes, name, label, options, color, ...rest }) => {
  return (
    <FormikRadioGroupField
      className={classes.mainLabel}
      // style={{ padding: "0px 12px" }}
      name={name}
      label={label}
      margin="normal"
      options={options}
      color={color}
      row
      fullWidth
      {...rest}
    />
  );
};

RadioField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array.isRequired,
};

export default withStyles(styles)(RadioField);
