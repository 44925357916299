/* eslint-disable camelcase */
import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Dialog,
  DialogContent,
  Grid,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  FormLabel,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Moment from "moment";
import SelectField from "../../Components/Common/Form/SelectField";
import { CircularLoader } from "../../Components/Common/Spinner";
import { checkPermission } from "../../Utils/checkPermission";
import { spacecapitalize } from "../../Utils/StringFunctions";
import {
  addplancategory,
  getPlancategory,
} from "../../Actions/plancategoryActions";
import { addplan, getPlans } from "../../Actions/planActions";
import InputField from "../../Components/Common/Form/InputField";
import ScreenHeader from "../../Components/Common/ScreenHeader";
import nodata from "../../Assets/nodata.png";

const $quoteSym = " ";
const styles = () => ({
  main: {
    padding: "24px",
  },
  plancontainer: {
    backgroundColor: "#fff",
  },
  table: {
    marginTop: "20px",
  },
  label: {
    margin: "15px 0px 8px 0px",
    fontSize: "12px",
    fontWeight: "700",
  },
  add_plan_button: {
    display: "flex",
    justifyContent: "flex-end",
  },
  plan_form_modal: {
    width: "500px",
  },
  editbutton: {
    borderRadius: "50px",
  },
  editicon: {
    fontSize: "16px",
    marginRight: "6px",
  },
  addPlanButton: {
    width: "100%",
    textAlign: "right",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  planbutton: {
    color: "#fff",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  ScreenHeader: {
    marginLeft: "-20px",
    width: "100%",
  },
  cancelbutton: {
    marginRight: "20px",
    backgroundColor: "#dddddd",
    color: "#151039",
    "&:hover": {
      color: "#151039",
      backgroundColor: "#d1d1d1!important",
    },
  },
  addbutton: {
    color: "#fff",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  modelHeader: {
    paddingBottom: "5px",
    position: "relative",
    "&:after": {
      top: "48px",
      left: "24px",
      width: "24px",
      borderBottom: "3px solid #fb0",
      content: `'${$quoteSym}'`,
      position: "absolute",
    },
  },
  modalFooter: {
    padding: "8px 0px",
    display: "flex",
    justifyContent: "flex-end",
  },
  root1: {
    "&:nth-child(even)": {
      backgroundColor: "#FCFDFE",
    },
  },
  backButton: {
    margin: "0% 0% 2% 0%",
  },
});
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F8FAFF",
    color: theme.palette.common.black,
    borderBottom: "none",
    padding: "8px 40px 8px 16px",
  },
  body: {
    fontSize: 14,
    textTransform: "capitalize",
    borderBottom: "none",
  },
}))(TableCell);

const SetupSchema = Yup.object().shape({
  seat_per_cabin: Yup.number().required("This field is required."),
  cabin_name: Yup.string().trim().required("This field is required."),
  price_per_seat: Yup.number().required("This field is required."),
  category_id: Yup.string().required("This field is required"),
});

export class AddPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // categories: []
    };
  }

  state = {
    modalOpen: false,
  };

  componentDidMount() {
    this.props.getPlancategory();
    this.props.getPlans();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.plan_categories !== prevState.plan_categories) {
      return { plan_categories: nextProps.plan_categories };
    }
    if (nextProps.all_plans !== prevState.all_plans) {
      return { all_plans: nextProps.all_plans };
    }
    return [];
  }

  componentDidUpdate(prevProps) {
    if (prevProps.plan_categories !== this.props.plan_categories) {
      this.setState({
        plan_categories: this.props.plan_categories,
      });
    }

    if (prevProps.all_plans !== this.props.all_plans) {
      let all_plans = this.props.all_plans || [];
      all_plans = all_plans.map((x, id) => {
        return Object.assign({}, x, {
          idx: id + 1,
        });
      });
      this.setState({
        all_plans,
        // rowData: all_plans
      });
    }
    if (prevProps.loading !== this.props.loading) {
      this.setState({
        loading: this.props.loading,
      });
    }
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.showLoadingOverlay();
  };

  handleClickOpen = () => {
    this.setState({
      modalOpen: true,
    });
  };

  handleClose = () => {
    this.setState({
      modalOpen: false,
    });
    this.props.getPlans();
  };

  getAvailablePlanOptions = (plan_categories) => {
    return plan_categories
      ? // eslint-disable-next-line no-shadow
        plan_categories.map((plan_categories) => {
          return {
            value: plan_categories._id,
            label: spacecapitalize(plan_categories.category_name),
          };
        })
      : [];
  };

  render() {
    const { classes } = this.props;
    const { plan_categories, loading } = this.state;
    let showData = true;
    if (loading) {
      return <CircularLoader />;
    }
    if (this.state.all_plans && this.state.all_plans.length <= 0) {
      showData = false;
    }

    return (
      <>
        <div className={classes.main}>
          <div className={classes.header}>
            {showData && (
              <div className={classes.ScreenHeader}>
                <ScreenHeader type="All Plans" />
              </div>
            )}
            <Dialog
              open={this.state.modalOpen}
              onClose={this.handleClose}
              aria-labelledby="plan-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <Formik
                initialValues={{
                  category_id: "",
                  cabin_name: "",
                  seat_per_cabin: "",
                  shared: "",
                  price_per_seat: "",
                }}
                validationSchema={SetupSchema}
                onSubmit={(values, { setErrors, setSubmitting }) => {
                  const data = { ...values };
                  data.category_id = data.category_id
                    ? data.category_id.value
                    : "";
                  data.created_by = "5cde64595cafe71468f0c483";
                  data.space = localStorage.getItem("space_id");
                  this.props.addplan(
                    data,
                    setErrors,
                    this.props.history,
                    setSubmitting
                  );
                  this.props.getPlans();
                  this.props.history.push("/manage-plans");
                  this.handleClose();
                }}
              >
                {({ setFieldValue, values }) => (
                  <Form className={classes.plan_form_modal}>
                    <DialogTitle
                      id="plan-dialog-title"
                      className={classes.modelHeader}
                    >
                      {"Add Plan "}
                    </DialogTitle>
                    <DialogContent>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={12}>
                          <FormLabel
                            component="legend"
                            className={classes.label}
                          ></FormLabel>
                          <SelectField
                            label="Category"
                            options={this.getAvailablePlanOptions(
                              plan_categories
                            )}
                            name="category_id"
                            placeholder="Select..."
                            isMulti={false}
                            value={values.category_id}
                            onChange={setFieldValue}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <InputField
                            name="cabin_name"
                            label="Cabin"
                            placeholder="Enter Cabin Name"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <InputField
                            name="seat_per_cabin"
                            label="Seats"
                            placeholder="Enter No. of Seats Per Cabin"
                            type="number"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <InputField
                            name="price_per_seat"
                            label="Price"
                            placeholder="Enter Price Per Seat"
                            type="number"
                          />
                        </Grid>
                      </Grid>
                      <Grid className={classes.modalFooter}>
                        <Button
                          onClick={this.handleClose}
                          color="primary"
                          className={classes.cancelbutton}
                        >
                          Cancel
                        </Button>
                        <Button
                          color="primary"
                          className={classes.addbutton}
                          type="submit"
                          variant="contained"
                        >
                          Add
                        </Button>
                      </Grid>
                    </DialogContent>

                    {loading && <CircularLoader />}
                  </Form>
                )}
              </Formik>
            </Dialog>
            {showData && (
              <Grid
                container
                direction="row"
                justify="flex-end"
                spacing={2}
                className={classes.addPlanButton}
              >
                <Grid item>
                  <a target="_blank" href="view-plan">
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.planbutton}
                    >
                      View Plan
                    </Button>
                  </a>
                  {/* <ViewPlan /> */}
                </Grid>
                <Grid item>
                  {checkPermission("PLAN_CREATE") && (
                    <Button
                      onClick={this.handleClickOpen}
                      variant="contained"
                      color="primary"
                      className={classes.planbutton}
                    >
                      Add Plan
                    </Button>
                  )}
                </Grid>
              </Grid>
            )}
          </div>

          <div className={classes.root}>
            {showData && (
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Sr. No.</StyledTableCell>
                    <StyledTableCell align="left">Category</StyledTableCell>
                    <StyledTableCell align="left">Cabin Name</StyledTableCell>
                    <StyledTableCell align="left">
                      Available / Total Seats
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      Price Per Seats
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      Credit Per Seat
                    </StyledTableCell>
                    <StyledTableCell align="left">Availability</StyledTableCell>
                    <StyledTableCell align="left">
                      Contract Expiry
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.all_plans &&
                    this.state.all_plans.map((item, id) => {
                      return (
                        <TableRow className={classes.root1}>
                          <StyledTableCell component="th" scope="row">
                            {id + 1}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {item.category_id && item.category_id.category_name}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {item.cabin_name}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {Number(item.available_seat)} /{" "}
                            {item.seat_per_cabin}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {item.price_per_seat}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {item.category_id &&
                              item.category_id.credit_per_seat}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {item.status}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {item.contract_end_date
                              ? item.contract_end_date.company_id &&
                                Moment(
                                  item.contract_end_date.company_id
                                    .contract_end_date
                                ).format("DD/MM/YYYY")
                              : "-"}
                          </StyledTableCell>

                          {/* <StyledTableCell align="right">
                          <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            className={classes.editbutton}
                          >
                            <CreateIcon
                              size="small"
                              className={classes.editicon}
                            />{" "}
                            Edit
                          </Button>
                        </StyledTableCell> */}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            )}{" "}
          </div>
          {!showData && (
            <>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                className={classes.headerSpacing}
              >
                <Grid item justify="center">
                  <Grid item sm={12} lg={12}>
                    <img src={nodata} alt="" width="76%" />
                  </Grid>
                  <Grid item style={{ marginLeft: "110px", marginTop: "20px" }}>
                    {checkPermission("PLAN_CREATE") && (
                      <Button
                        onClick={this.handleClickOpen}
                        variant="contained"
                        color="primary"
                        className={classes.planbutton}
                      >
                        Add Plan
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    plan_categories: state.plan_categories.plan_categories,
    all_plans: state.plans.all_plans,
    loading: state.plans.loading,
  };
};

export default connect(mapStateToProps, {
  addplancategory,
  getPlancategory,
  addplan,
  getPlans,
})(withStyles(styles)(AddPlan));
