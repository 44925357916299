/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
import axios from 'axios'
import {
  GET_COMPANIES,
  COMPANY_LOADING,
  GET_ERRORS,
  GET_COMPANIES_LIST,
  GET_COMPANY,
  CREATE_ASSOCIATEPLAN,
  GET_COMPANY_USER,
  COMPANY_ERRORS,
  UPDATE_COMPANY_STATUS,
  ASSOCIATEPLAN_ERRORS,
  CREDIT_HISTORY_ERRORS,
  CREDIT_HISTORY,
  DELETE_PLAN_ERRORS,
  DELETE_PLAN,
  ADD_CREDIT_TOPUP,
  COMPANY_CREDIT_TOPUP_ERROR,
  GET_COMPANIES_CREDIT_TOPUP,
  GET_SPACE_COMPANIES_LIST,
  GET_ONBOARDING_PROCESS,
  CREATE_ONBOARDING_PROCESS_POST,
  GET_ONBOARDING_PROCESS_POST,
  GET_ONBOARDING_PROCESS_RATING,
  CREATE_ONBOARDING_PROCESS_RATING,
  GET_CLIENT_FEEDBACK,
  ADD_COMPNAY,
  CLIENT_FEEDBACK_INVITATION,
  GET_CLIENT_FEEDBACK_RATING,
  GET_EXIT_PROCESS,
  CREATE_EXIT_PROCESS_POST,
  GET_EXIT_PROCESS_POST,
} from './types'
import { showSuccess, showError } from './ActionsHelper'
import { transformErrorsFromApi } from './TransformError'

// Profile Loading
export const setCompaniesLoading = () => {
  return {
    type: COMPANY_LOADING,
  }
}

export const getCompanies = (page, search, type, role) => (dispatch) => {
  dispatch(setCompaniesLoading())

  let url = ''
  if (search) {
    url = `/api/company?q=${search}`
  } else if (type) {
    url = `/api/company?type=${type}`
  } else if (role === 'admin' || role === 'communitymanager') {
    url = `/api/company?data=all`
  } else {
    url = '/api/company'
  }
  if (search && type) {
    url = `/api/company?q=${search}&type=${type}`
  }
  axios
    .get(url)
    .then((result) => {
      dispatch({
        type: GET_COMPANIES,
        reset: page ? page === 0 : false,
        payload: result.data,
      })
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : '',
      })
    })
}

export const getCurrentCompany = (id) => (dispatch) => {
  dispatch(setCompaniesLoading())
  axios
    .get(`/api/company/getcompany/${id}`)
    .then((res) => {
      return dispatch({
        type: GET_COMPANY_USER,
        payload: res.data,
      })
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    )
}

export const getCompanyList = () => (dispatch) => {
  dispatch(setCompaniesLoading())
  axios
    .get('/api/company/companylist')
    .then((result) => {
      dispatch({
        type: GET_COMPANIES_LIST,
        payload: result.data,
      })
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    )
}

export const getSpaceCompanyList = (spaceid) => (dispatch) => {
  dispatch(setCompaniesLoading())
  axios
    .get(`/api/company/allcompany/${spaceid}`)
    .then((result) => {
      dispatch({
        type: GET_SPACE_COMPANIES_LIST,
        payload: result.data,
      })
    })
    .catch((err) =>
      dispatch({
        type: COMPANY_ERRORS,
        payload: err.response && err.response.data,
      })
    )
}
export const getCompany = (id, type) => (dispatch) => {
  dispatch(setCompaniesLoading())
  let url = `/api/company/${id}`
  if (type) {
    url = `/api/company/${id}?type=${type}`
  }
  axios
    .get(url)
    .then((res) => {
      dispatch({
        type: GET_COMPANY,
        payload: res.data,
      })
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        // payload: err.response.data
        payload: ((err || {}).response || {}).data || 'Error unexpected',
      })
    )
}

export const addCompany = (userData, history, setErrors, setSubmitting) => (
  dispatch
) => {
  dispatch(setCompaniesLoading())
  axios
    .post('/api/company', userData)
    .then((result) => {
      if (userData.type === 'freelancer') {
        history.push('/freelancers')
      } else {
        history.push('/companies')
      }
      dispatch({
        type: ADD_COMPNAY,
        payload: result.data,
      })
      if (userData.type === 'freelancer') {
        dispatch(showSuccess('Freelancer'))
      } else if (userData.freelancer_status === 'draft') {
        dispatch(showSuccess('', 'Freelancer saved as a draft!'))
      } else if (userData.status === 'draft') {
        dispatch(showSuccess('', 'Company saved as a draft!'))
      } else {
        dispatch(showSuccess('Company'))
      }
      setSubmitting(false)
    })
    .catch((err) => {
      const error = err.response
      dispatch({
        type: COMPANY_ERRORS,
        payload: err.response && err.response.data,
      })
      if (userData.type === 'freelancer') {
        // dispatch(showError("There was some error while creating freelancer"));
        dispatch(
          showError(
            err.response && err.response.data && err.response.data.data
              ? err.response.data.data
              : 'There was some error while creating freelancer'
          )
        )
      } else {
        dispatch(showError('There was some error while creating company'))
      }
      setSubmitting(false)
      setErrors(transformErrorsFromApi(error))
    })
}

export const addCreditTopup = (userData, setSubmitting, setErrors) => (
  dispatch
) => {
  dispatch(setCompaniesLoading())
  axios
    .post('/api/company/credit/topup', userData)
    .then((result) => {
      dispatch({
        type: ADD_CREDIT_TOPUP,
        payload: result.data,
      })
      if (userData.type === 'freelancer') {
        dispatch(showSuccess('Freelancer Credit Top up'))
      } else {
        dispatch(showSuccess('Company Credit Top up'))
      }
      setSubmitting(false)
    })

    .catch((err) => {
      const error = err.response
      dispatch({
        type: COMPANY_CREDIT_TOPUP_ERROR,
        payload: err.response && err.response.data,
      })
      dispatch(
        showError(err.response && err.response.data && err.response.data.data)
      )
      setSubmitting(false)
      setErrors(transformErrorsFromApi(error))
    })
}

export const getCreditTopup = (companyid) => (dispatch) => {
  dispatch(setCompaniesLoading())

  axios
    .get(`/api/company/credit/topuphistroy?companyid=${companyid}`)
    .then((result) => {
      dispatch({
        type: GET_COMPANIES_CREDIT_TOPUP,
        payload: result.data,
      })
    })
    .catch((err) =>
      dispatch({
        type: COMPANY_CREDIT_TOPUP_ERROR,
        payload: err && err.response ? err.response.data : '',
      })
    )
}

export const addAssociatePlan = (userData) => (dispatch) => {
  dispatch(setCompaniesLoading())
  axios
    .post('/api/company/associateplan', userData)
    .then((result) => {
      dispatch({
        type: CREATE_ASSOCIATEPLAN,
        payload: result.data,
      })
      dispatch(showSuccess('Plan'))
    })
    .catch((err) => {
      const error = err.response
      dispatch({
        type: ASSOCIATEPLAN_ERRORS,
        payload: err.response && err.response.data,
      })
      if (error.status === 400) {
        dispatch(showError('Please add proper details of Plans'))
      } else {
        dispatch(showError('There was some error while creating plan'))
      }
    })
}

export const updateCompany = (id, userData, history, setSubmitting) => (
  dispatch
) => {
  dispatch(setCompaniesLoading())
  const bodyFormData = new FormData()
  bodyFormData.append('image', userData.logo)
  for (const key in userData) {
    if (key !== 'logo') {
      bodyFormData.append(key, userData[key])
    }
  }
  // eslint-disable-next-line no-empty
  for (const pair of bodyFormData.entries()) {
  }
  axios
    .put(`/api/company/${id}`, userData)
    .then((result) => {
      if (userData.type === 'freelancer') {
        history.push('/freelancers')
      } else {
        history.push('/companies')
      }
      if (userData.type === 'freelancer') {
        dispatch(showSuccess('', 'Freelancer updated successfully!'))
      } else {
        dispatch(showSuccess('', 'Company updated successfully!'))
      }
      setSubmitting(false)
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        // payload: err.response.data
        payload: ((err || {}).response || {}).data || 'Error unexpected',
      })
    )
}
export const updateCompanyStatus = (data, setSubmitting) => (dispatch) => {
  // dispatch(setCompaniesLoading());
  axios
    .post(`/api/company/companydeactivate`, data)
    .then((result) => {
      dispatch({
        type: UPDATE_COMPANY_STATUS,
        payload: result.data,
      })
      dispatch(showSuccess('', 'Status updated successfully!'))
      setSubmitting(false)
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        // payload: err.response.data
        payload: ((err || {}).response || {}).data || 'Error unexpected',
      })
    )
}

export const getCreditHistory = (userData) => (dispatch) => {
  dispatch(setCompaniesLoading())
  axios
    .post('/api/booking/histroy', userData)
    .then((result) => {
      dispatch({
        type: CREDIT_HISTORY,
        payload: result.data,
      })
    })
    .catch((err) => {
      const error = err.response
      dispatch({
        type: CREDIT_HISTORY_ERRORS,
        payload: err.response && err.response.data,
      })
      dispatch(showError('There was some error'))
    })
}

export const deletePlan = (data) => (dispatch) => {
  dispatch(setCompaniesLoading())
  axios
    .post(`/api/company/disassociate`, data)
    .then((result) => {
      dispatch({
        type: DELETE_PLAN,
        payload: result.data,
      })
      dispatch(showSuccess('', 'Plan deleted successfully!'))
    })

    .catch((err) => {
      dispatch({
        type: DELETE_PLAN_ERRORS,
        payload: err && err.response ? err.response.data : '',
      })
    })
}

export const getExitProcess = (active, id) => (dispatch) => {
  dispatch(setCompaniesLoading())
  let url = '/api/process'
  // api/process/exit?q=active&&company_id=5dfb68eda133ea22c9d77475
  if (active && id) {
    url = `/api/process/exit?q=active&&company_id=${id}`
  }
  axios
    .get(url)
    .then((result) => {
      dispatch({
        type: GET_EXIT_PROCESS,
        payload: result.data,
      })
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : '',
      })
    })
}

export const getOnboardingProcess = (active, id) => (dispatch) => {
  dispatch(setCompaniesLoading())
  let url = '/api/process'
  // api/process/exit?q=active&&company_id=5dfb68eda133ea22c9d77475
  if (active && id) {
    url = `/api/process?q=active&&company_id=${id}`
  }
  axios
    .get(url)
    .then((result) => {
      dispatch({
        type: GET_ONBOARDING_PROCESS,
        payload: result.data,
      })
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err && err.response ? err.response.data : '',
      })
    })
}

export const exitProcessPost = (
  userData,
  setErrors,
  history,
  setSubmitting
) => (dispatch) => {
  dispatch(setCompaniesLoading())
  axios
    .post('/api/process/exit/post', userData)
    .then((result) => {
      dispatch({
        type: CREATE_EXIT_PROCESS_POST,
        payload: result.data,
      })
      dispatch(showSuccess('Comment'))
      setSubmitting(false)
    })

    .catch((err) => {
      const error = err.response

      // dispatch({
      //   type: COMPANY_ERRORS,
      //   payload: err.response && err.response.data,
      // })

      dispatch(
        showError('There was some error while creating exit process post')
      )
      setSubmitting(false)
      setErrors(transformErrorsFromApi(error))
    })
}

export const getExitProcessPostData = (companyid) => (dispatch) => {
  dispatch(setCompaniesLoading())

  axios
    .get(`/api/process/exit/getpost/${companyid}`)
    .then((result) => {
      dispatch({
        type: GET_EXIT_PROCESS_POST,
        payload: result.data,
      })
    })
    .catch((err) =>
      dispatch({
        type: COMPANY_ERRORS,
        payload: err && err.response ? err.response.data : '',
      })
    )
}

export const onBoardingProcessPost = (
  userData,
  history,
  setErrors,
  setSubmitting
) => (dispatch) => {
  dispatch(setCompaniesLoading())
  axios
    .post('/api/process/post', userData)
    .then((result) => {
      dispatch({
        type: CREATE_ONBOARDING_PROCESS_POST,
        payload: result.data,
      })
      dispatch(showSuccess('Comment'))
      setSubmitting(false)
    })

    .catch((err) => {
      const error = err.response

      dispatch({
        type: COMPANY_ERRORS,
        payload: err.response && err.response.data,
      })

      dispatch(showError('There was some error while creating company'))
      setSubmitting(false)
      setErrors(transformErrorsFromApi(error))
    })
}

export const getOnboardingProcessPost = (companyid) => (dispatch) => {
  dispatch(setCompaniesLoading())

  axios
    .get(`/api/process/getpost/${companyid}`)
    .then((result) => {
      dispatch({
        type: GET_ONBOARDING_PROCESS_POST,
        payload: result.data,
      })
    })
    .catch((err) =>
      dispatch({
        type: COMPANY_ERRORS,
        payload: err && err.response ? err.response.data : '',
      })
    )
}

export const createRating = (userData, history, setErrors, setSubmitting) => (
  dispatch
) => {
  dispatch(setCompaniesLoading())
  axios
    .post('/api/process/rating', userData)
    .then((result) => {
      dispatch({
        type: CREATE_ONBOARDING_PROCESS_RATING,
        payload: result.data,
      })
      dispatch(showSuccess('Rating'))
      setSubmitting(false)
    })

    .catch((err) => {
      const error = err.response
      dispatch({
        type: COMPANY_ERRORS,
        payload: err.response && err.response.data,
      })
      dispatch(showError('There was some error while creating company'))
      setSubmitting(false)
      setErrors(transformErrorsFromApi(error))
    })
}

export const getOnboardingProcessRating = (companyid) => (dispatch) => {
  dispatch(setCompaniesLoading())

  axios
    .get(`/api/process/rating/${companyid}`)
    .then((result) => {
      dispatch({
        type: GET_ONBOARDING_PROCESS_RATING,
        payload: result.data,
      })
    })
    .catch((err) =>
      dispatch({
        type: COMPANY_ERRORS,
        payload: err && err.response ? err.response.data : '',
      })
    )
}

export const getClientFeedback = (companyid) => (dispatch) => {
  dispatch(setCompaniesLoading())

  axios
    .get(`/api/process/getanswer/${companyid}`)
    .then((result) => {
      dispatch({
        type: GET_CLIENT_FEEDBACK,
        payload: result.data,
      })
    })
    .catch((err) =>
      dispatch({
        type: COMPANY_ERRORS,
        payload: err && err.response ? err.response.data : '',
      })
    )
}

export const clientFeedbackInvitation = (
  userData,
  history,
  setErrors,
  setSubmitting
) => (dispatch) => {
  dispatch(setCompaniesLoading())
  axios
    .post('/api/process/email/sent', userData)
    .then((result) => {
      dispatch({
        type: CLIENT_FEEDBACK_INVITATION,
        payload: result.data,
      })
      dispatch(showSuccess('', 'Feedback invitation sent successfully!'))
      setSubmitting(false)
    })

    .catch((err) => {
      const error = err.response

      dispatch({
        type: COMPANY_ERRORS,
        payload: err.response && err.response.data,
      })

      dispatch(showError('There was some error while sending invitation'))
      setSubmitting(false)
      setErrors(transformErrorsFromApi(error))
    })
}

export const getClientFeedbackRating = (companyid, rating) => (dispatch) => {
  dispatch(setCompaniesLoading())
  const url = `/api/process/getanswer/${companyid}?q=rating`

  axios
    .get(url)
    .then((result) => {
      dispatch({
        type: GET_CLIENT_FEEDBACK_RATING,
        payload: result.data,
      })
    })
    .catch((err) =>
      dispatch({
        type: COMPANY_ERRORS,
        payload: err && err.response ? err.response.data : '',
      })
    )
}
