import {
  GET_SERVICES,
  SERVICES_ERRORS,
  SERVICES_LOADING,
} from "../Actions/types";

const initialState = {
  loading: false,
  servicelist: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SERVICES:
      return {
        ...state,
        loading: false,
        servicelist: action.payload.data,
      };
    case SERVICES_ERRORS:
      return {
        ...state,
        service_error: { errors: false },
      };
    case SERVICES_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
