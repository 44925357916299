import React from 'react'
import { DatePicker } from 'material-ui-pickers'
import { withStyles } from '@material-ui/core/styles'
import { ErrorMessage } from 'formik'

const styles = () => ({
  input: {
    fontSize: 'inherit',
    paddingLeft: '7px',
  },
  error: {
    color: '#f53b57',
    fontSize: '0.75rem',
    fontWeight: '400',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ],
    textAlign: 'left',
    lineHeight: '1em',
    marginTop: '7px',
  },
  DatePicker: {
    width: '100%',
  },
})

class DatePickerField extends React.Component {
  handleChange = (value) => {
    // this is going to call setFieldValue and manually update values.topcis
    this.props.onChange(this.props.name, value)
  }

  render() {
    const { classes, ...other } = this.props
    return (
      <div className={classes.DatePicker}>
        <DatePicker
          {...other}
          name={this.props.name}
          keyboard
          label={this.props.label}
          format="DD/MM/YYYY"
          disableOpenOnEnter
          onChange={this.handleChange}
          value={this.props.value}
          animateYearScrolling={false}
          InputProps={{ className: classes.input }}
          fullWidth
          style={{ top: '1px' }}
        />
        <div className={classes.error}>
          <ErrorMessage
            name={this.props.name}
            key={this.props.name}
            component="div"
          />
        </div>
      </div>
    )
  }
}
export default withStyles(styles)(DatePickerField)
