/* eslint-disable func-names */
import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Grid, Typography } from "@material-ui/core";
import avatar from "../../Assets/coworker3.png";
import LinkifyString from "../../Utils/LinkifyString";
import { capitalize } from "../../Utils/StringFunctions";

const moment = require("moment");

const styles = () => ({
  avatar: {
    height: "30px",
    width: "30px",
    borderRadius: "50%",
  },
  userName: {
    fontSize: "14px",
    fontWeight: "600",
    color: " #358cee",
  },
  comment: {
    fontSize: "12px",
    fontWeight: "normal",
    color: "#626262",
    padding: "5px",
  },
  postCard: {
    padding: "20px 20px 36px",
  },
  image: {
    display: "block",
    position: "absolute",
  },
  backgroundPatch: {
    background: "#f3f3f3",
    borderRadius: "5px",
    padding: "5px",
    width: "fit-content",
    marginLeft: (props) => (props.announcementType ? "6%" : "5%"),
    marginTop: "-2px",
  },
  container: {
    paddingBottom: "24px",
    marginLeft: "1px",
  },
  name: {
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  commentDate: {
    marginLeft: (props) => (props.announcementType ? "6.6%" : "5.5%"),
    paddingTop: "0.5%",
    marginTop: "-0.8%",
  },
  dateFonts: {
    color: "grey",
  },
});

class ViewComment extends Component {
  render() {
    const { classes, viewcomment } = this.props;

    const timeObj = {
      minutes: "m",
      days: "d",
      minute: "1m",
      day: "1d",
      hours: "h",
      years: "y",
      hour: "1h",
      an: "",
      a: "",
      few: "0m",
      seconds: "",
      months: "mo",
      month: "1mo",
      go: "",
    };

    return (
      <div className={classes.postCard}>
        {viewcomment &&
          viewcomment.map((el) => {
            const postedAt = moment(el.createdAt).fromNow();
            const time = postedAt.replace(
              /minutes|minute|hours|hour|years|few|seconds|months|month|days|day|an|a|go/gi,
              function (matched) {
                return timeObj[matched];
              }
            );
            return (
              <Grid
                container
                sm={12}
                lg={12}
                direction="row"
                spacing={1}
                className={classes.container}
              >
                {el.user && el.user.profile_pic ? (
                  <div className={classes.image}>
                    <img
                      src={el.user.profile_pic}
                      alt="avatar"
                      className={classes.avatar}
                    />
                  </div>
                ) : (
                  <div className={classes.image}>
                    <img src={avatar} alt="avatar" className={classes.avatar} />
                  </div>
                )}
                <Grid item lg={11}>
                  <div className={classes.backgroundPatch}>
                    <Typography variant="subtitle2" className={classes.name}>
                      {capitalize(el.first_name)} {capitalize(el.last_name)}
                    </Typography>
                    <Typography variant="subtitle1" className={classes.comment}>
                      {LinkifyString(el.comment)}
                    </Typography>
                  </div>
                </Grid>
                <Grid item lg={11} spacing={0}>
                  <div className={classes.commentDate}>
                    <Typography variant="caption" className={classes.dateFonts}>
                      {time}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            );
          })}
      </div>
    );
  }
}

export default withStyles(styles)(ViewComment);
