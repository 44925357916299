const HelpdeskStatusTypes = [
  { label: "Open", value: "open" },
  { label: "In Process", value: "inprocess" },
  { label: "Pending", value: "pending" },
  { label: "On Hold", value: "onhold" },
  { label: "Closed", value: "closed" },
  { label: "Revoked", value: "revoked" },
];
const AdminHelpdeskStatusTypes = [
  { label: "Open", value: "open" },
  { label: "In Process", value: "inprocess" },
  { label: "Pending", value: "pending" },
  { label: "On Hold", value: "onhold" },
  { label: "Closed", value: "closed" },
  { label: "QC Passed", value: "qc_pass" },
  { label: "QC Failed", value: "qc_fail" },
];
const closedStatusTypes = [
  { label: "Closed", value: "closed" },
  { label: "Reopen", value: "reopen" },
  { label: "QC Passed", value: "qc_pass" },
  { label: "QC Failed", value: "qc_fail" },

];

export const CommunityAmbassdorStatusTypes = [
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
];

export const SuggestFeatureStatusTypes = [
  { label: "Pending", value: "pending" },
  { label: "Approve for poll", value: "approve_for_poll" },
  { label: "In development", value: "in_development" },
  { label: "Released", value: "released" },
  { label: "Rejected", value: "reject" },
];

export const reportStatusType = [
  { label: "Pending", value: "pending" },
  { label: "Accepted", value: "accept" },
  { label: "Rejected", value: "reject" }
]
export { AdminHelpdeskStatusTypes, HelpdeskStatusTypes, closedStatusTypes };
