/* eslint-disable camelcase */
import React from 'react'
import { withStyles, Grid, Paper, Typography, Button } from '@material-ui/core'
import { connect } from 'react-redux'
import classNames from 'classnames'
import ReferFriendimg from '../../Assets/referfriend.png'
import Thankyou from '../../Assets/Thank-you-Illustration.png'
import DataNotFound from '../../Components/Common/DataNotFound'
import { getReferFriends } from '../../Actions/ReferFriendActions'
import ReferFriendInformation from './ReferFriendInformation'
import AddReferFriend from './AddReferFriend'
import SuccessPopup from '../Visitor/SuccessPopup'
import RefferalCard from './RefferalCard'

const styles = (theme) => ({
  mainCont: {
    padding: '20px',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: '89vh',
    overflowY: 'scroll',
  },
  root: {
    flexGrow: 1,
    overflow: 'hidden',
  },
  paper: {
    padding: '20px',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: '100%',
  },
  TextLayout: {
    paddingBottom: '3%',
  },
  mainTitle: {
    fontWeight: '600',
    fontSize: '40px',
    textAlign: 'left',
    color: '#222222',
    margin: '0px',
    lineHeight: '45px',
  },
  descText: {
    fontSize: '14px',
    textAlign: 'left',
    color: '#626262',
    marginTop: '5px',
  },
  knowledgeLayout: {
    padding: '0% 0% 0%',
    display: 'flex',
    justifyContent: 'center',
  },
  contentLayout: {
    margin: '0px 25px 0px 0px',
  },
  imageDiv: {
    border: '1px solid #EFEFEF',
    borderRadius: '5px',
  },
  setupImg: {
    padding: '10%',
  },
  subTitle: {
    margin: '0% 0% 10%',
    textAlign: 'center',
    fontWeight: '600',
    lineHeight: '18px',
    fontSize: '16px',
  },
  button: {
    // width: "20%",
    marginRight: '1.4%',
    marginBottom: '15px',
    color: 'white',
    padding: '5px 16px',
    borderRadius: '4px',
    textDecoration: 'none',
    fontSize: '0.875rem',
    fontWeight: '500',
    boxShadow: 'rgba(137, 163, 169, 0.3) 0px 3px 5px 2px',
    backgroundColor: '#211b4e !important',
    '&:hover': {
      backgroundColor: '#151039 !important',
    },
  },
  active: {
    backgroundColor: '#211b4e',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#211b4e',
      color: '#fff',
    },
  },
  inActive: {
    color: '#211b4e',
  },
})

class AdminReferFriend extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false,
      successPopup: false,
      refferalEntry: false,
      ReferFriends: [],
    }
  }

  componentDidMount() {
    this.props.getReferFriends()
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.createReferFriend !== prevProps.createReferFriend &&
      this.props.createReferFriend.success
    ) {
      this.openSuccessPopup()
    }
    if (prevProps.ReferFriends !== this.props.ReferFriends) {
      this.setState({
        ReferFriends: this.props.ReferFriends,
      })
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.ReferFriends !== prevState.ReferFriends) {
      return {
        ReferFriends: nextProps.ReferFriends,
      }
    }
    return []
  }

  handleClickOpen = () => {
    this.setState({
      modalOpen: true,
    })
  }

  handleClose = () => {
    this.setState({
      modalOpen: false,
    })
  }

  openSuccessPopup = () => {
    this.setState({ successPopup: true })
  }

  closeSuccessPopup = () => {
    this.setState({ successPopup: false })
    this.props.getReferFriends()
  }

  referralEntries = () => {
    this.setState({ refferalEntry: true })
  }

  render() {
    const { classes } = this.props
    const { refferalEntry, ReferFriends } = this.state
    const defaultUser = localStorage.getItem('user_id')
    // eslint-disable-next-line func-names
    const ReferFriend = ReferFriends.filter(function (u) {
      return u && u.created_by && u.created_by._id === defaultUser
    })
    return (
      <React.Fragment>
        <main className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <Grid className={classes.TextLayout}>
                  <Typography
                    align="center"
                    variant="h1"
                    className={classes.mainTitle}
                  >
                    <div>DevX Referral Program </div>
                  </Typography>
                  <div className={classes.descText}>
                    <Typography align="center" variant="p">
                      Join hands with us to open new avenues of earning while
                      building a vibrant community.
                    </Typography>
                  </div>
                </Grid>
                <Grid className={classes.knowledgeLayout}>
                  <img
                    src={ReferFriendimg}
                    alt=""
                    className={classes.setupImg}
                  ></img>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.mainCont}>
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  className={classes.endSpacing}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classNames(
                      classes[
                        this.state.activeFlag === 'payment'
                          ? 'active'
                          : 'inactive'
                      ],
                      classes.button
                    )}
                    onClick={() => this.handleClickOpen(true)}
                  >
                    Refer a Friend
                  </Button>

                  <Button
                    variant="outlined"
                    color="primary"
                    className={classNames(
                      classes[
                        this.state.activeFlag === 'credit'
                          ? 'active'
                          : 'inactive'
                      ],
                      classes.button
                    )}
                    onClick={() => this.referralEntries()}
                  >
                    Referral Entries
                  </Button>
                </Grid>
                {refferalEntry && ReferFriend && ReferFriend.length === 0 && (
                  <DataNotFound
                    type="visitors"
                    text="You don't have any referral entries!!"
                    subtext=""
                    paddingTop="8%"
                  />
                )}

                <Grid container spacing={2}>
                  {refferalEntry ? (
                    ReferFriend &&
                    ReferFriend.length > 0 &&
                    ReferFriend.map((item) => {
                      return (
                        <Grid
                          item
                          lg={6}
                          xs={12}
                          sm={6}
                          key={item._id}
                          className={classes.coworkerCardContainer}
                        >
                          <RefferalCard data={item} />
                        </Grid>
                      )
                    })
                  ) : (
                    <ReferFriendInformation />
                  )}
                </Grid>
              </Paper>
            </Grid>
            <AddReferFriend
              open={this.state.modalOpen}
              handleClose={this.handleClose}
            />
            <SuccessPopup
              data={this.state.successPopup}
              handleClose={this.closeSuccessPopup}
              image={Thankyou}
              text="Thank you for refer a friend."
            />
          </Grid>
        </main>
      </React.Fragment>
    )
  }
}

AdminReferFriend.propTypes = {}

const mapStateToProps = (state) => {
  return {
    createReferFriend: state.referFriend.referFriend,
    ReferFriends: state.referFriend.referFriends,
  }
}

export default connect(mapStateToProps, { getReferFriends })(
  withStyles(styles)(AdminReferFriend)
)
