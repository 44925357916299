import React from "react";
import { Paper, Tabs, Tab, Typography, withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { getCoworker } from "../../Actions/coworkerActions";
import AddAreaTabs from "./AddAreaTab";
import SupervisorTab from "./SupervisorTab";

const styles = () => ({
  root: {
    paddingTop: "18px",
    paddingBottom: "15px",
  },
  noCoworker: {
    padding: "24px 24px 11px",
    color: "lightgrey",
  },
  layout: {
    marginTop: "19px !important",
    padding: "20px 25px",
  },
  paper: {
    backgroundColor: "#fcfdfe",
  },
});
const AntTabs = withStyles({
  indicator: {
    color: "#435bf2",
    backgroundColor: " #4682B4",
    top: "38px",
  },
})(Tabs);

const AntTab = withStyles(() => ({
  selected: {
    color: " #4682B4",
  },
}))((props) => <Tab disableRipple {...props} />);

function TabContainer(props) {
  return (
    <Typography
      component="div"
      style={{ padding: "0px 14px", marginTop: " 14px" }}
    >
      {props.children}
    </Typography>
  );
}

function AreaTabs(props) {
  const { classes } = props;
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  return (
    <Paper className={classes.root} style={{ backgroundColor: "#FCFDFE" }}>
      <AntTabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <AntTab label="Area" />
        <AntTab label="Supervisor" />
      </AntTabs>
      <TabContainer>
        {value === 0 && <AddAreaTabs />}
        {value === 1 && <SupervisorTab />}
      </TabContainer>
    </Paper>
  );
}

const mapStateToProps = (state) => {
  return {
    errors: state.errors,
    // companyCoworkers: state.coworkers.companyCoworkers
  };
};

export default connect(mapStateToProps, { getCoworker })(
  withStyles(styles)(AreaTabs)
);
