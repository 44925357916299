/* eslint-disable import/prefer-default-export */
import { convertFromHTML, EditorState, ContentState } from "draft-js";

export const setEditorState = rawHtml => {
  let editorState;
  const blocksFromHTML = rawHtml && convertFromHTML(rawHtml);
  if (rawHtml && blocksFromHTML.contentBlocks) {
    const contentState = ContentState.createFromBlockArray(blocksFromHTML);
    // move focus to the end.
    editorState = EditorState.createWithContent(contentState);
    editorState = EditorState.moveFocusToEnd(editorState);
  } else {
    // eslint-disable-next-line new-cap
    editorState = new EditorState.createEmpty();
  }
  return editorState;
};
