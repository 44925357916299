/* eslint-disable camelcase */
import axios from "axios";
import {
  CREDIT_ACCOUNT_ERRORS,
  CREDIT_ACCOUNT_LOADING,
  GET_ALL_CREDIT_ACCOUNT,
  CREATE_CREDIT_ACCOUNT,
  GET_CREDIT_ACCOUNT,
  PAYNOW,
} from "./types";
import { showSuccess, showError } from "./ActionsHelper";

export const setCreditAccountLoading = () => {
  return {
    type: CREDIT_ACCOUNT_LOADING,
  };
};

export const getCreditAccounts = (page, search) => (dispatch) => {
  dispatch(setCreditAccountLoading());
  let url = "/api/cafe";
  if (search) {
    url = `/api/cafe?q=${search}`;
  }
  axios
    .get(url)
    .then((result) => {
      dispatch({
        type: GET_ALL_CREDIT_ACCOUNT,
        payload: result.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: CREDIT_ACCOUNT_ERRORS,
        payload: err && err.response ? err.response.data : "",
      });
    });
};

export const createAccount = (userData, setErrors, history, setSubmitting) => (
  dispatch
) => {
  dispatch(setCreditAccountLoading());
  axios
    .post("/api/cafe", userData)
    .then((result) => {
      dispatch({
        type: CREATE_CREDIT_ACCOUNT,
        payload: result.data,
      });
      // history.push("/credit-accounts");
      dispatch(showSuccess("Credit Account"));
      setSubmitting(false);
    })
    .catch((err) => {
      dispatch({
        type: CREDIT_ACCOUNT_ERRORS,
        payload: err && err.response ? err.response.data.data : "",
      });
      dispatch(
        showError(err.response && err.response.data && err.response.data.data)
      );
    });
};

// //GET SINGLE PLAN
export const getSingleCreditAccount = (id, type) => (dispatch) => {
  dispatch(setCreditAccountLoading());
  let url;
  if (type === "credit") {
    url = `/api/cafe/${id}?q=credit`;
  } else {
    url = `/api/cafe/${id}?q=payment`;
  }
  axios
    .get(url)
    .then((res) => {
      dispatch({
        type: GET_CREDIT_ACCOUNT,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: CREDIT_ACCOUNT_ERRORS,
        payload: err.response && err.response.data,
      })
    );
};

export const payNow = (userData, setErrors, history, setSubmitting) => (
  dispatch
) => {
  dispatch(setCreditAccountLoading());
  axios
    .post("/api/cafe/paynow", userData)
    .then((result) => {
      dispatch({
        type: PAYNOW,
        payload: result.data,
      });
      // history.push("/credit-accounts");
      dispatch(showSuccess("Payment"));
      setSubmitting(false);
    })
    .catch((err) => {
      dispatch({
        type: CREDIT_ACCOUNT_ERRORS,
        payload: err && err.response ? err.response.data.data : "",
      });
      dispatch(showError(err.response.data.data));
    });
};

// export const editPlan = (plan_id, data, setErrors, setSubmitting) => (
//   dispatch
// ) => {
//   dispatch(setCreditAccountLoading());
//   axios
//     .put(`/api/plan/${plan_id}`, data)
//     .then((result) => {
//       dispatch({
//         type: EDIT_PLAN,
//         payload: result.data,
//       });
//       dispatch(showSuccess("", "Plan edited successfully!"));
//       setSubmitting(false);
//     })
//     .catch((err) => {
//       const error = err.response;
//       dispatch({ type: PLAN_ERROR, payload: {} });
//       setSubmitting(false);
//       setErrors(transformErrorsFromApi(error));
//     });
// };
