import React from "react";
import { Paper, Typography, withStyles, Button } from "@material-ui/core";
import { connect } from "react-redux";
import { getCoworker } from "../../Actions/coworkerActions";
import AdminInventoryData from "./AdminInventory";
import AdminInventoryCategory from "./InventoryCategory";

const styles = (theme) => ({
  root: {
    paddingTop: "18px",
    paddingBottom: "15px",
  },
  noCoworker: {
    padding: "24px 24px 11px",
    color: "lightgrey",
  },
  layout: {
    marginTop: "19px !important",
    padding: "20px 25px",
  },
  paper: {
    backgroundColor: "#fcfdfe",
  },
  activeView: {
    margin: theme.spacing(1),
    backgroundColor: "#211b4e",
    color: "white",
    fontSize: "10px",
    borderRadius: `${theme.shape.borderRadius * 4}px`,
    "&:hover": {
      backgroundColor: "#211b4e",
      color: "white",
    },
  },
  // inactive view for non-slected tabs
  inactiveView: {
    margin: theme.spacing(1),
    fontSize: "10px",
    color: "#959595",
    backgroundColor: "#fff",
    border: "1px solid #959595",
    borderRadius: `${theme.shape.borderRadius * 4}px`,
    "&:hover": {
      backgroundColor: "white",
      color: "#959595",
    },
  },
});

function TabContainer(props) {
  return (
    <Typography
      component="div"
      style={{ padding: "0px 14px", marginTop: " 14px" }}
    >
      {props.children}
    </Typography>
  );
}

function AdminTabs(props) {
  const { classes, history } = props;
  const [isOpen, setisOpen] = React.useState(true);

  return (
    <Paper className={classes.root}>
      {/* <AntTabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <AntTab label="Inventory category" />
        <AntTab label="Manage Inventory" />
      </AntTabs> */}
      <div style={{ textAlign: "right" }}>
        <Button
          size="small"
          variant="contained"
          color="primary"
          // className={classes.button}
          className={isOpen ? classes.activeView : classes.inactiveView}
          onClick={() => {
            setisOpen(true);
          }}
        >
          Inventory category
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          className={!isOpen ? classes.activeView : classes.inactiveView}
          // className={classes.button}
          onClick={() => {
            setisOpen(false);
          }}
        >
          Manage Inventory
        </Button>
      </div>
      <TabContainer>
        {isOpen && <AdminInventoryCategory history={history} />}
        {!isOpen && <AdminInventoryData history={history} />}
      </TabContainer>
    </Paper>
  );
}

const mapStateToProps = (state) => {
  return {
    errors: state.errors,
  };
};

export default connect(mapStateToProps, { getCoworker })(
  withStyles(styles)(AdminTabs)
);
