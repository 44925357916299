import React from "react";
import { withStyles, Grid, Button, Typography } from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import SelectField from "../../Components/Common/Form/SelectField";
import TextareaField from "../../Components/Common/Form/TextareaField";
import UploadField from "../../Components/Common/Form/UploadField";
import { CircularLoader } from "../../Components/Common/Spinner";
import { addReportBug, getReportBug } from "../../Actions/ReportBugListAction";
import { moduleList } from "./moduleList";

const reportBugValidator = Yup.object().shape({
  module: Yup.string().min(2).required("This field is required"),
  description: Yup.string()
    .max(300, "The text entered exceeds the maximum length")
    .required("This field is required"),
});

class ReportBugForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deletedKey: [],
    };
  }

  setDelImageUrl = (url) => {
    const { deletedKey } = this.state;
    this.setState({ deletedKey: deletedKey.push(url) });
  };

  render() {
    const { classes, loading } = this.props;

    if (loading) {
      return <CircularLoader />;
    }

    return (
      <Formik
        initialValues={{ module: "", description: "", issue_file: "" }}
        validationSchema={reportBugValidator}
        onSubmit={(data, { setSubmitting }) => {
          const bugData = {
            module_name: data.module.value,
            description: data.description,
            bug_image: data.images,
          };
          this.props.addReportBug(bugData, setSubmitting);
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Typography
              align="left"
              style={{ marginBottom: "-18px" }}
              className={`${classes.description} ${classes.moduleLabel}`}
            >
              Module
            </Typography>
            <SelectField
              name="module"
              id="module"
              onChange={setFieldValue}
              onBlur={setFieldTouched}
              error={errors.module}
              touched={touched.module}
              options={moduleList}
            />
            <Grid>
              <Typography
                align="left"
                className={classes.description}
                style={{ marginBottom: "10px" }}
              >
                Description of the feature
              </Typography>
              <TextareaField
                name="description"
                className={classes.input_field}
                rows="5"
                label="Description"
                placeholder="Add Comments"
                maxLength="300"
              />
            </Grid>
            <Grid item xs={12} className={classes.uploadScreenshot}>
              <UploadField
                label="Add Screenshot"
                name="images"
                id="images"
                onChange={setFieldValue}
                value={values.images}
                error={errors.images}
                folder="reportBug/image"
                delImageUrl={this.setDelImageUrl}
              />
            </Grid>
            <Grid className={classes.buttonAction}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                className={classes.submitButton}
              >
                Submit
              </Button>
            </Grid>
          </form>
        )}
      </Formik>
    );
  }
}
const styles = () => ({
  description: {
    color: "#222222",
    fontSize: "13px",
    lineHeight: "18px",
    // marginBottom: "20px",
  },
  input_field: {
    border: "1px solid #949494",
    height: "81%",
    position: "relative",
    width: "100%",
    padding: "6px 8px",
    margin: "0px",
    fontFamily:
      "'-apple-system,BlinkMacSystemFont','Segoe UI','Roboto','Helvetica Neue','Arial,sans-serif','Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol'",
    color: "currentColor",
    fontSize: "14px",
  },
  uploadScreenshot: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderStyle: "dashed",
    borderRadius: "5px",
    borderColor: "lightgray",
    padding: "50px 50px 50px 50px",
    marginTop: "40px",
    height: "100%",
  },
  buttonAction: {
    padding: "40px 0px 8px",
    display: "flex",
    justifyContent: "flex-end",
  },
  submitButton: {
    width: "10%",
    color: "white",
    borderRadius: "4px",
    textDecoration: "none",
    fontSize: "0.875rem",
    fontWeight: "500",
    boxShadow: "rgba(137, 163, 169, 0.3) 0px 3px 5px 2px",
    backgroundColor: "#211b4e !important",
    padding: "10px 70px 10px 70px",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  moduleLabel: {
    marginBottom: 0,
  },
});
const mapStateToProps = (state) => {
  return {
    reportBug: state.reportBug.bugReports,
    loading: state.reportBug.loading,
  };
};

export default connect(mapStateToProps, {
  addReportBug,
  getReportBug,
})(withStyles(styles)(ReportBugForm));
