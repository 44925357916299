/* eslint-disable func-names */
/* eslint-disable object-shorthand */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import withStyles from '@material-ui/core/styles/withStyles'
import {
  Button,
  Dialog,
  Typography,
  Grid,
  DialogContent,
  DialogTitle,
  colors,
  FormLabel,
} from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'
import { createMuiTheme } from '@material-ui/core/styles'
import * as Yup from 'yup'
import {
  DatePickerField,
  SelectField,
  InputField,
} from '../../Components/Common/Form'
import {
  registerBooking,
  getCompanyCredit,
  getBookings,
} from '../../Actions/bookingActions'
import { getCompanies } from '../../Actions/companiesAction'
import { getUnbookedResources } from '../../Actions/resourceActions'
import { getallCoworkers, getCoworker } from '../../Actions/coworkerActions'
import SplitTime from '../../Utils/SlotSplit'
import '../company.css'
import { getAllocatedBookingSlotsInRange } from './BookingSlotRange'
import { checkPermission } from '../../Utils/checkPermission'
import { capitalize } from '../../Utils/StringFunctions'

const $quoteSym = ' '
const moment = require('moment')

const theme = createMuiTheme({
  overrides: {
    MuiDialogContent: {
      root: {
        // padding: "0px"
      },
    },
  },
})
// eslint-disable-next-line no-unused-vars
const styles = () => ({
  lightbg: {
    padding: '24px',
    backgroundColor: '#fff',
    maxWidth: '500px',
    margin: '0 auto',
  },
  label: {
    margin: '15px 0px 8px 0px',
    fontSize: '13px',
    fontWeight: '400',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  button: {
    width: '100%',
    marginRight: '25px',
  },
  bookingDate: {
    marginBottom: '7px',
  },
  booking_date_time: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  booking_duration: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formfooter: {
    margin: '24px 15px',
  },
  bookingActions: {
    position: 'relative',
    padding: '40px 0px 8px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonProgress: {
    color: colors.green[500],
    position: 'absolute',
    top: '50%',
    right: '30%',
    marginTop: -12,
    marginLeft: -12,
  },

  dateLabel: {
    fontSize: '12px',
    fontWeight: 'normal',
  },
  bookbutton: {
    color: '#fff',
    backgroundColor: '#211b4e !important',
    '&:hover': {
      backgroundColor: '#151039 !important',
    },
  },
  cancelbutton: {
    marginRight: '20px',
    backgroundColor: '#dddddd',
    color: '#151039',
    '&:hover': {
      color: '#151039',
      backgroundColor: '#d1d1d1!important',
    },
  },
  applicationHeader: {
    top: '10px',
    '&:after': {
      width: '24px',
      borderBottom: '3px solid #fb0',
      content: `'${$quoteSym}'`,
      position: 'absolute',
    },
  },
  credit: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.48)',
    marginBottom: '14px',
    margin: '10px 8px',
  },
  creditValue: {
    margin: '10px',
    position: 'relative',
    bottom: '-4px',
  },
  timeSection: {
    margin: '0px 4px',
  },
})

const BookingSchema = Yup.object().shape({
  title: Yup.string().max(15, '').trim().required('This field is required.'),
  start_time: Yup.string().required('This field is required.'),
  end_time: Yup.string().required('This field is required.'),
  company_id: Yup.string().test(
    'match',
    'This field is required',
    function (value) {
      if (checkPermission('ADMIN_BOOKING')) {
        if (!value) {
          return false
        }
        return true
      }
      return true
    }
  ),
})

class BookingForm extends React.Component {
  state = {
    resourceOptions: [],
    isResourcesVisible: false,
    durationMinutes: '',
  }

  constructor(props) {
    super(props)
    this.formik = React.createRef()
    this.state = {
      time: '',
      startTime: '0:00',
      endTime: '0:00',
      duration: 0,
      disabled: true,
      bookingList: [],
      bookingDate: new Date(),
      durationError: false,
      newDate: moment(),
      pickedTime: '00:00',
      selectedDateOption: 'today',
      timeData: {},
      timeSlotArray: SplitTime(),
      bookingListData: [],
    }
  }

  // eslint-disable-next-line consistent-return
  componentDidMount() {
    const company_id = localStorage.getItem('company_id')
    const role = localStorage.getItem('role')
    this.props.getCompanies('', '', '', role)
    if (role === 'admin') {
      return null
    }
    this.props.getCoworker(company_id)
    this.props.getBookings()
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.booking.savedBooking !== this.props.booking.savedBooking &&
      this.props.booking &&
      this.props.booking.savedBooking &&
      this.props.booking.savedBooking.success
    ) {
      this.setState({ resourceOptions: [] })
      this.props.handleClose(true)
    }
    if (prevProps.companyCoworkers !== this.props.companyCoworkers) {
      this.setState({
        companyCoworkers: this.props.companyCoworkers,
      })
    }
    if (prevProps.companies !== this.props.companies) {
      this.setState({
        companies: this.props.companies,
      })
    }
    if (
      this.props.get_credit !== prevProps.get_credit &&
      this.props.get_credit.success
    ) {
      // set credit detail
      this.setState({
        credit_current_booking:
          this.props.get_credit && this.props.get_credit.data.used_credit,
        available_credit:
          this.props.get_credit && this.props.get_credit.data.available_credits,
      })
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.companies !== prevState.companies) {
      return { companies: nextProps.companies }
    }
    // get_credit
    if (nextProps.companyCoworkers !== prevState.companyCoworkers) {
      return { companyCoworkers: nextProps.companyCoworkers }
    }
    if (nextProps.unbooked !== prevState.unbooked) {
      const resourceOptions =
        (nextProps.unbooked &&
          nextProps.unbooked.map((resource) => {
            return {
              label: `${resource.resource_name} || ${resource.pax} seats`,
              value: resource._id,
            }
          })) ||
        []
      return { unbooked: nextProps.unbooked, resourceOptions }
    }
    if (nextProps.booking !== prevState.booking) {
      return { booking: nextProps.booking, loading: nextProps.booking.loading }
    }
    if (nextProps.bookingList !== prevState.bookingList) {
      return { bookingList: nextProps.bookingList }
    }

    return []
  }

  timer = () => {
    this.setState({ time: moment().format('HH:mm') })
  }

  setBookingDate = (values) => {
    // 2020-10-21T00:00:00+05:30
    const date = moment(values.booking_data).format('')
    const currentDateTime = moment().format('DD')
    const selectDateTime = moment(values.booking_data).format('DD')
    this.setState({
      bookingDate: values.booking_data,
      selectedDateOption:
        selectDateTime === currentDateTime ? 'today' : values.booking_data,
      newDate: date,
    })
    const role = localStorage.getItem('role')
    this.props.getCompanies('', '', '', role)
  }

  addBooking = (data, setErrors, setSubmitting) => {
    const values = { ...data }
    const { duration } = this.state
    const { resourceID } = this.props
    values.duration_in_minutes = duration
    values.resource_id = resourceID
    const memberlist = values.member && values.member.map((el) => el.value)
    values.member = memberlist

    if (checkPermission('COMPANY_IN_COWORKER')) {
      const companyVal = values.company_id && values.company_id.value
      values.company_id = companyVal
    } else {
      values.company_id = localStorage.getItem('company_id')
    }
    if (values.member === '') {
      delete values.member
    }
    const selectTime = moment(this.state.pickedTime, 'HH:mm').toDate()
    const date = moment(values.booking_date).format()
    const startTime = moment(selectTime).format()
    const finalSelectDateTime = date
      .split('T')[0]
      .concat('T', startTime.split('T')[1])
    values.booking_date = finalSelectDateTime
    this.props.registerBooking(
      values,
      setErrors,
      setSubmitting,
      this.props.history,
      true
    )
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  getCoworkersOptions = (companyCoworkers) => {
    const role = localStorage.getItem('role')

    if (role === 'coworkeradmin') {
      const FilterCoworkerData = companyCoworkers.filter(function (
        coworkerData
      ) {
        return (
          coworkerData.user_id &&
          coworkerData.user_id._id !== localStorage.getItem('user_id')
        )
      })
      return FilterCoworkerData
        ? FilterCoworkerData.map((coworker) => {
            const { user_id, first_name, last_name } = coworker
            return {
              value: user_id ? user_id._id : '',
              label: capitalize(
                `${first_name && first_name} ${last_name && last_name}`
              ),
            }
          })
        : []
      // eslint-disable-next-line no-else-return
    } else {
      return companyCoworkers
        ? companyCoworkers.map((companyCoworker) => {
            const { user_id, first_name, last_name } = companyCoworker
            return {
              value: user_id ? user_id._id : '',
              label: capitalize(
                `${first_name && first_name} ${last_name && last_name}`
              ),
            }
          })
        : []
    }
  }

  getCompanyOptions = (companies) => {
    const FilterCompanyData = companies.filter(function (activeCompanydata) {
      return activeCompanydata.status !== 'inactive'
    })
    return FilterCompanyData
      ? FilterCompanyData.map((company) => {
          return {
            value: company._id,
            label: capitalize(company.display_name || company.company_name),
          }
        })
      : []
  }

  callComapnyAPI = (value) => {
    const role = localStorage.getItem('role')
    this.props.getCoworker(value.value, role)
    const data = {}
    data.company_id = value.value
    data.resource_id = this.state.resourceID
    data.duration_in_minutes = this.state.durationMinutes

    this.setState(
      {
        companyID: data.company_id,
      },
      () => {
        this.callCreditAPI(this.state.duration)
      }
    )
    if (checkPermission('COMPANY_IN_COWORKER')) {
      data.companyID = this.state.companyID
    }
  }

  CloseModal = () => {
    this.setState({
      credit_current_booking: '',
      available_credit: '',
    })
    this.props.handleClose(true)
  }

  callCreditAPI = (value) => {
    const data = {}
    const { duration } = value
    const { companyID } = this.state
    const { resourceID } = this.props
    data.resource_id = resourceID
    data.duration_in_minutes = value
    data.company_id = companyID
    this.setState({
      resourceID: data.resource_id,
    })
    if (companyID && checkPermission('COMPANY_IN_COWORKER')) {
      data.company_id = companyID
      this.props.getCompanyCredit(data)
    } else if (data.resource_id !== undefined && duration !== undefined) {
      this.props.getCompanyCredit(data)
    } else {
      this.props.getCompanyCredit(data)
    }
  }

  getInitialValues = () => {
    const { bookings } = this.props
    if (this.props.id && bookings) {
      return {
        booking_date: new Date(),
        time: new Date(),
        resources: '',
        member: [],
        title: '',
        company_id: '',
        start_time: '',
        end_time: '',
      }
    }
    return {
      booking_date: this.props.bookingdate
        ? this.props.bookingdate
        : new Date(),
      time: new Date(),
      resources: [],
      member: [],
      title: '',
      company_id: '',
      start_time: '',
      end_time: '',
    }
  }

  getAllocatedSlots = (type) => {
    let computedStartSlots = []
    let bookedSlots = []
    const { bookingDate } = this.state
    const { resourceID, bookingList } = this.props

    // filter booking list data by resource
    const bookingListData =
      bookingList &&
      bookingList.filter((item) => {
        return item.resource_id && item.resource_id._id === resourceID
      })

    // filter booking list data by selected booking date
    const bookingData =
      bookingListData &&
      bookingListData.filter((item) => {
        const itemBookingDate = moment(item.start).format()
        const currentDate = new Date()

        const selectedBookingDate = moment(
          // eslint-disable-next-line no-unneeded-ternary
          bookingDate ? bookingDate : currentDate
        ).format()
        return (
          itemBookingDate.split('T')[0] === selectedBookingDate.split('T')[0]
        )
      })
    bookedSlots = bookingData
    const { newDate } = this.state
    // eslint-disable-next-line no-unused-expressions
    bookedSlots &&
      // eslint-disable-next-line array-callback-return
      bookedSlots.map((bookingSlot) => {
        const startDate = moment(bookingSlot.start)
        const duration = bookingSlot.duration_in_minutes
        const totalSlots = Math.ceil(duration / 30)

        // check is date is same with selected date then remove those only
        if (moment(newDate).isSame(startDate, 'day')) {
          const slotData = getAllocatedBookingSlotsInRange(
            startDate,
            totalSlots,
            type
          )

          // add start time slot to startallocatedSlots to make it deduct
          computedStartSlots = [...computedStartSlots, ...slotData]
        }
      })
    return computedStartSlots
  }

  // convert time into 12 hour format
  convertTime = (time24) => {
    let ts = time24
    const H = +ts.substr(0, 2)
    let h = H % 12 || 12
    h = h < 10 ? `0${h}` : h // leading 0 at the left for 1 digit hours
    const ampm = H < 12 ? ' AM' : ' PM'
    ts = h + ts.substr(2, 3) + ampm
    return ts
  }

  // set start picker time
  startTimeOptions = (type) => {
    let timePickerArray
    const { newDate, pickedTime, selectedDateOption } = this.state
    const allocatedSlots = this.getAllocatedSlots(type) || [] // get allocated slots initially
    const cutDownAllocatedSlots = [...allocatedSlots]
    const currentTime = moment(newDate).format('HH:mm')

    timePickerArray = SplitTime()
    if (type === 'end') {
      // if calculating end options
      const index = cutDownAllocatedSlots.findIndex((slot) => slot > pickedTime)
      timePickerArray = timePickerArray.filter((x) => {
        return (
          !cutDownAllocatedSlots.includes(x.time) && // if it is not allocated
          (selectedDateOption === 'today' ? x.time >= currentTime : true) && // if it is today, time is not elapsed
          (index > -1 ? x.time < cutDownAllocatedSlots[index] : true) && // options from selected start time till allocated slots
          pickedTime < x.time // start from picked time only
        )
      })
    } else {
      timePickerArray = timePickerArray.filter((x) => {
        return (
          !cutDownAllocatedSlots.includes(x.time) && // if it is not allocated
          (selectedDateOption === 'today' ? x.time >= currentTime : true) // if it is today, time is not elapsed
        )
      })
    }
    const selectTimeData = []
    // eslint-disable-next-line no-unused-expressions
    timePickerArray &&
      timePickerArray.map((time) => {
        const timeOutput = this.convertTime(time.time)
        return selectTimeData.push({
          value: time.time,
          label: timeOutput,
        })
      })
    return selectTimeData
  }

  onStartTimeChange = (time) => {
    this.setState({
      pickedTime: time.value,
    })
  }

  onEndTimeChange = (time) => {
    const startDate = moment(this.state.pickedTime, 'hh:mm')
    const endDate = moment(time.value, 'hh:mm')
    const durationMinutes = moment(endDate).diff(moment(startDate), 'minutes')
    this.setState({
      endTime: time.value,
      duration: durationMinutes,
    })
    this.callCreditAPI(durationMinutes)
  }

  render() {
    const { classes, companyCoworkers, companies } = this.props
    const { id } = this.state
    const currentDate = new Date()
    const nextMonthDate = currentDate.setMonth(currentDate.getMonth() + 1)
    const maxDateFormat = moment(nextMonthDate).endOf('month').format()
    return (
      <ThemeProvider theme={theme}>
        <React.Fragment>
          <Dialog
            open={this.props.modalOpen}
            onClose={() => {
              this.setState({ resourceOptions: [] }, () => {
                this.props.handleClose()
              })
            }}
            aria-labelledby="plan-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              className={classes.applicationHeader}
              id="plan-dialog-title"
            >
              New Booking
            </DialogTitle>
            <DialogContent>
              <Formik
                ref={this.formik}
                initialValues={this.getInitialValues(id)}
                validationSchema={BookingSchema}
                // eslint-disable-next-line no-unused-vars
                onSubmit={(values, { setErrors, setSubmitting }) => {
                  const hourValue = values.hour && values.hour.value

                  values.hour = hourValue
                  const minuteValue = values.minute && values.minute.label
                  values.minute = minuteValue
                  this.addBooking(values, setErrors, setSubmitting)
                }}
                render={({
                  errors,
                  touched,
                  setFieldValue,
                  handleSubmit,
                  values,
                  setFieldTouched,
                }) => (
                  // eslint-disable-next-line no-sequences
                  <form
                    className={classes.form}
                    onSubmit={handleSubmit}
                    encType="multipart/form-data"
                    autoComplete="off"
                  >
                    <Grid container spacing={2}>
                      <Grid
                        container
                        item
                        xs={12}
                        sm={6}
                        direction="row"
                        justify="center"
                        alignItems="flex-end"
                      >
                        <div className={classes.booking_date_time}>
                          <Grid item xs={12} sm={11}>
                            <FormLabel
                              className={classes.dateLabel}
                              component="span"
                            >
                              Date
                            </FormLabel>
                            <DatePickerField
                              name="booking_date"
                              onChange={(name, value) => {
                                setFieldValue(name, value)
                                this.setBookingDate({
                                  ...values,
                                  booking_data: value,
                                })
                                setFieldValue('start_time', '')
                                setFieldValue('end_time', '')
                              }}
                              value={values.booking_date}
                              disableFuture={false}
                              disablePast
                              maxDate={maxDateFormat}
                              className={classes.bookingDate}
                            />
                          </Grid>
                        </div>
                      </Grid>

                      {checkPermission('COMPANY_IN_COWORKER') && (
                        <Grid item xs={12} sm={6}>
                          <SelectField
                            value={values.company_id}
                            name="company_id"
                            id="company_id"
                            label="Company"
                            onChange={(name, value) => {
                              setFieldValue(name, value)
                              this.callComapnyAPI(value)
                              // this.callCreditAPI(this.state.duration)
                              setFieldValue('member', '')
                            }}
                            onBlur={setFieldTouched}
                            multi={false}
                            error={errors.company_id}
                            touched={touched.company_id}
                            options={this.getCompanyOptions(companies)}
                          />
                        </Grid>
                      )}
                      <Grid
                        container
                        spacing={2}
                        className={classes.timeSection}
                      >
                        <Grid item xs={6} sm={6} lg={6}>
                          <SelectField
                            value={values.start_time}
                            name="start_time"
                            id="start_time"
                            label="Start time"
                            onChange={(name, value) => {
                              setFieldValue(name, value)
                              this.onStartTimeChange(value)
                              setFieldValue('end_time', '')
                            }}
                            onBlur={setFieldTouched}
                            multi={false}
                            error={errors.start_time}
                            touched={touched.start_time}
                            options={this.startTimeOptions('start')}
                          />
                        </Grid>
                        <Grid item xs={6} sm={6} lg={6}>
                          <SelectField
                            value={values.end_time}
                            name="end_time"
                            id="end_time"
                            label="End time"
                            onChange={(name, value) => {
                              setFieldValue(name, value)
                              this.onEndTimeChange(value)
                              this.callCreditAPI(this.state.duration)
                            }}
                            onBlur={setFieldTouched}
                            multi={false}
                            error={errors.end_time}
                            touched={touched.end_time}
                            options={this.startTimeOptions('end')}
                            // disabled={}
                          />
                        </Grid>
                      </Grid>
                      {checkPermission('ADMIN') ? (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          className={classes.timeSection}
                        >
                          <SelectField
                            value={values.member}
                            name="member"
                            id="member"
                            label="Members"
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                            multi
                            error={errors.member}
                            touched={touched.member}
                            options={this.getCoworkersOptions(companyCoworkers)}
                          />
                        </Grid>
                      ) : (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          className={classes.timeSection}
                        >
                          <SelectField
                            value={values.member}
                            name="member"
                            id="member"
                            label="Members"
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                            multi
                            error={errors.member}
                            touched={touched.member}
                            options={this.getCoworkersOptions(companyCoworkers)}
                          />
                        </Grid>
                      )}
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        className={classes.timeSection}
                      >
                        <InputField
                          name="title"
                          label="Title"
                          placeholder="Enter Title"
                        />
                      </Grid>
                    </Grid>

                    <div>
                      {this.state.credit_current_booking ||
                      this.state.available_credit ? (
                        <Grid container item xs={12} sm={12} spacing={2}>
                          <Grid item xs={4} sm={4} className={classes.credit}>
                            <span className={classes.label}>
                              Credit for current booking
                            </span>
                            <br />
                            <span className={classes.creditValue}>
                              {this.state.credit_current_booking
                                ? parseFloat(
                                    this.state.credit_current_booking
                                  ).toFixed(2)
                                : '0'}
                            </span>
                          </Grid>
                          <Grid item xs={1} sm={1} />
                          <Grid item xs={3} sm={3} className={classes.credit}>
                            <span className={classes.label}>
                              Available credit
                            </span>
                            <br />
                            <span className={classes.creditValue}>
                              {this.state.available_credit
                                ? parseFloat(
                                    this.state.available_credit
                                  ).toFixed(2)
                                : '0'}
                            </span>
                          </Grid>
                        </Grid>
                      ) : (
                        ''
                      )}
                    </div>

                    {/* </Grid> */}
                    <Grid className={classes.bookingActions}>
                      <Typography as="h5" style={{ color: 'red' }}>
                        {errors.generic}
                      </Typography>
                      {/* {loading && <CircularLoader />} */}
                      <Button
                        // onClick={this.props.handleClose}
                        onClick={() => this.CloseModal()}
                        color="grey"
                        variant="contained"
                        className={classes.cancelbutton}
                      >
                        Cancel
                      </Button>

                      <Button
                        color="primary"
                        type="submit"
                        variant="contained"
                        className={classes.bookbutton}
                      >
                        Book
                      </Button>
                    </Grid>
                  </form>
                )}
              />
            </DialogContent>
          </Dialog>
        </React.Fragment>
      </ThemeProvider>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    errors: state.errors,
    companyCoworkers: state.coworkers.companyCoworkers,
    unbooked: state.resources.unbooked,
    companies: state.company.companies,
    booking: state.bookingData,
    bookingList: state.bookingData.bookings.data,
    get_credit: state.bookingData.get_credit,
  }
}

export default connect(mapStateToProps, {
  registerBooking,
  getallCoworkers,
  getUnbookedResources,
  getCompanies,
  getCoworker,
  getCompanyCredit,
  getBookings,
})(withStyles(styles)(withRouter(BookingForm)))
