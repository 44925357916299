/* eslint-disable no-shadow */
import React, { useEffect, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { SelectField } from "../Form";
import { getCompanies } from "../../../Actions/companiesAction";
import { capitalize } from "../../../Utils/StringFunctions";

const styles = () => ({});

const AllCompaniesDropDown = (props) => {
  const { values, setFieldValue, setFieldTouched, errors, touched } = props;
  const [companies, setCompanies] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const role = localStorage.getItem("role");

  // eslint-disable-next-line no-shadow
  const getAvailableCompanyOptions = (companies) => {
    // eslint-disable-next-line func-names
    const FilterCompanyData = companies.filter(function (activeCompanydata) {
      return activeCompanydata.status !== "inactive";
    });
    return FilterCompanyData
      ? FilterCompanyData.map((company) => {
          return {
            value: company._id,
            label: capitalize(company.display_name || company.company_name),
          };
        })
      : [];
  };

  useEffect(() => {
    props.getCompanies("", "", "", "admin");
  }, []);

  useEffect(() => {
    if (role === "coworkeradmin") {
      const selectedValue = [];
      // eslint-disable-next-line no-unused-expressions
      props.companies &&
        props.companies.map((company) => {
          if (company._id === localStorage.getItem("company_id")) {
            selectedValue.push({
              value: company._id,
              label: company.display_name || company.company_name,
            });
          }
          return null;
        });
      setFieldValue("company_id", selectedValue[0]);
      setSelectedValue(selectedValue[0]);
    } else if (values.company_id && values.company_id._id) {
      // eslint-disable-next-line no-shadow
      const selectedValue = [];
      // eslint-disable-next-line no-unused-expressions
      props.companies &&
        props.companies.map((company) => {
          if (company._id === values.company_id._id) {
            selectedValue.push({
              value: company._id,
              label: company.display_name || company.company_name,
            });
          }
          return null;
        });
      setFieldValue("company_id", selectedValue[0]);
      setSelectedValue(selectedValue[0]);
    }

    setCompanies(props.companies);
  }, [props.companies]);

  const changeDropDown = (name, value) => {
    setSelectedValue(value);
    setFieldValue(name, value);
  };

  return (
    <SelectField
      value={selectedValue}
      name="company_id"
      id="company_id"
      label="Company"
      onChange={changeDropDown}
      onBlur={setFieldTouched}
      error={errors.company_id}
      touched={touched.company_id}
      disabled={role === "coworkeradmin"}
      options={getAvailableCompanyOptions(companies)}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    errors: state.errors,
    companies: state.company.companies,
  };
};

export default connect(mapStateToProps, { getCompanies })(
  withStyles(styles)(AllCompaniesDropDown)
);
