import React from "react";
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { capitalize } from "../../Utils/StringFunctions";

const styles = () => {
  return {
    boldStyles: {
      fontWeight: 500,
    },
    smallFont: {
      fontSize: "14px",
    },
  };
};

const CardFooterViewMore = (props) => {
  const { text, classes, isSmallFont } = props;
  const skilltype =
    text.skills &&
    text.skills.map((el) => capitalize(el ? el.label : "")).join(" | ");

  return (
    <Tooltip title={skilltype} className={classes.customTooltip}>
      <span
        className={classNames(
          classes.boldStyles,
          isSmallFont ? classes.smallFont : ""
        )}
      >
        {` ${skilltype.substring(0, 10)}${skilltype.length > 10 ? "..." : ""}`}
      </span>
    </Tooltip>
  );
};

export default withStyles(styles)(CardFooterViewMore);
