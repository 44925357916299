/* eslint-disable camelcase */
import React from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { Typography, Grid } from "@material-ui/core";
import classNames from "classnames";
import tm2 from "../../Assets/tm2.png";
import tm1 from "../../Assets/tm1.png";
import tm3 from "../../Assets/tm3.png";
import tm4 from "../../Assets/tm4.png";
import tm5 from "../../Assets/tm5.png";

const styles = () => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "12px",
  },
  mainTitle: {
    fontWeight: "600",
    fontSize: "20px",
    textAlign: "left",
    color: "#222222",
    lineHeight: "25px",
  },
  heading: {
    fontSize: "16px",
    color: "#222222",
    fontWeight: "500",
    margin: "4px 0px",
    marginBottom: "15px",
  },
  title: {
    fontSize: "13px",
    color: "#222222",
    fontWeight: "500",
    // padding: "10px"
  },
  tableHeader: {
    fontSize: "13px",
    color: "#ffffff",
    fontWeight: "500",
    padding: "6px",
    textAlign: "center",
  },
  subTitle: {
    fontSize: "15px",
    color: "#222222",
    fontWeight: "500",
    padding: "10px",
  },
  phone: {
    color: "#1592E6",
    fontWeight: "500",
  },
  description: {
    color: "#222222",
    fontSize: "13px",
    lineHeight: "20px",
  },
  tableText: {
    color: "#222222",
    fontSize: "13px",
    lineHeight: "18px",
    padding: "6px",
  },
  generalDetails: {
    padding: "2% 0% 0% 0%",
  },
  cardLayout: {
    border: "1px solid #EFEFEF",
    borderRadius: "5px",
    // padding: "6px",
    boxShadow: "0 9px 10px rgba(0, 0, 0, 0.1), 0 4px 0px rgba(0,0,0,0.05)",
    margin: "5px",
  },
  padding: {
    padding: "10px",
  },
  imageLayout: {
    marginTop: "-15px",
    padding: "5px",
  },
  marginTop: {
    marginTop: "15px",
  },
});

function ReferFriendInformation(props) {
  const { classes } = props;

  return (
    <div className={classes.main}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Grid
            container
            spacing={2}
            className={classNames(classes.generalDetails, classes.marginTop)}
          >
            <Grid item className={classes.serviceLayout}>
              <Typography align="left" className={classes.description}>
                An opportunity to earn some quick bucks. Through our referral
                program, we invite you to be part of our growth journey. Earn
                money by referring DevX in your network. The deals that go
                through will earn you not just monetary benefits but an
                appreciation too for introducing new people to be the part of
                our vibrant community.
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.generalDetails}>
            <Grid item className={classes.serviceLayout}>
              <Typography align="left" className={classes.title}>
                Some quick Money
              </Typography>
              <Typography align="left" className={classes.description}>
                For every referral that you make, the ones that get finalized
                will reap you monetary benefits. You can check them below of how
                much you can earn by introducing us in your network.
              </Typography>
            </Grid>
            <Grid item className={classes.serviceLayout}>
              <Typography align="left" className={classes.title}>
                Community Building
              </Typography>
              <Typography align="left" className={classes.description}>
                Community is built through referrals and network. When you
                introduce someone, contribute towards the growth of the
                community by bringing in more people to network.
              </Typography>
            </Grid>
            <Grid item className={classes.serviceLayout}>
              <Typography align="left" className={classes.title}>
                Recognition
              </Typography>
              <Typography align="left" className={classes.description}>
                We have introduced our DevX Evangelist Program. The ones who
                refer three of more clients acquiring 35 seats or more will be
                recognized as DevX Evangelist and be felicitated during the
                community event with the badge and certificate. You also stand a
                chance to earn more on all the previous leads made by you.
              </Typography>
            </Grid>
            <Grid item className={classes.serviceLayout}>
              <Typography align="left" className={classes.title}>
                Take a quick look at our Referral Program
              </Typography>
              <Grid container style={{ display: "flex" }}>
                <Grid className={classes.cardLayout}>
                  <Typography
                    align="left"
                    className={classes.tableHeader}
                    style={{ backgroundColor: "#F9802F" }}
                  >
                    <div>Mo.</div>
                    <br />
                  </Typography>
                  <img src={tm1} alt="" className={classes.imageLayout} />
                  <br />
                  <Typography align="center" className={classes.tableText}>
                    3
                  </Typography>
                  <Typography align="center" className={classes.tableText}>
                    6
                  </Typography>
                  <Typography align="center" className={classes.tableText}>
                    9
                  </Typography>
                  <Typography align="center" className={classes.tableText}>
                    12
                  </Typography>
                </Grid>
                <Grid className={classes.cardLayout}>
                  <Typography
                    align="left"
                    className={classes.tableHeader}
                    style={{ backgroundColor: "#36AA9F" }}
                  >
                    <div>1 to 5</div>
                    <div>No of Seats</div>
                  </Typography>
                  <img src={tm2} alt="" className={classes.imageLayout} />
                  <br />
                  <Typography align="center" className={classes.tableText}>
                    INR 500/seat
                  </Typography>
                  <Typography align="center" className={classes.tableText}>
                    INR 1200/seat
                  </Typography>
                  <Typography align="center" className={classes.tableText}>
                    INR 1200/seat
                  </Typography>
                  <Typography align="center" className={classes.tableText}>
                    INR 1400/seat
                  </Typography>
                </Grid>
                <Grid className={classes.cardLayout}>
                  <Typography
                    align="left"
                    className={classes.tableHeader}
                    style={{ backgroundColor: "#2696F8" }}
                  >
                    <div>6 to 15</div>
                    <div>No of Seats</div>
                  </Typography>
                  <img src={tm3} alt="" className={classes.imageLayout} />
                  <br />
                  <Typography align="center" className={classes.tableText}>
                    INR 400/seat
                  </Typography>
                  <Typography align="center" className={classes.tableText}>
                    INR 1000/seat
                  </Typography>
                  <Typography align="center" className={classes.tableText}>
                    INR 1000/seat
                  </Typography>
                  <Typography align="center" className={classes.tableText}>
                    INR 1200/seat
                  </Typography>
                </Grid>
                <Grid className={classes.cardLayout}>
                  <Typography
                    align="left"
                    className={classes.tableHeader}
                    style={{ backgroundColor: "#F9B030" }}
                  >
                    <div>15 to 30</div>
                    <div>No of Seats</div>
                  </Typography>
                  <img src={tm4} alt="" className={classes.imageLayout} />
                  <br />
                  <Typography align="center" className={classes.tableText}>
                    INR 300/seat
                  </Typography>
                  <Typography align="center" className={classes.tableText}>
                    INR 800/seat
                  </Typography>
                  <Typography align="center" className={classes.tableText}>
                    INR 800/seat
                  </Typography>
                  <Typography align="center" className={classes.tableText}>
                    INR 1000/seat
                  </Typography>
                </Grid>
                <Grid className={classes.cardLayout}>
                  <Typography
                    align="left"
                    className={classes.tableHeader}
                    style={{ backgroundColor: "#F64747" }}
                  >
                    <div>More than 30</div>
                    <div>No of Seats</div>
                  </Typography>
                  <img src={tm5} alt="" className={classes.imageLayout} />
                  <br />
                  <Typography align="center" className={classes.tableText}>
                    N/A
                  </Typography>
                  <Typography align="center" className={classes.tableText}>
                    INR 500/seat
                  </Typography>
                  <Typography align="center" className={classes.tableText}>
                    INR 500/seat
                  </Typography>
                  <Typography align="center" className={classes.tableText}>
                    INR 700/seat
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={classes.serviceLayout}>
              <Typography align="left" className={classes.title}>
                Terms and Condition:
              </Typography>
              <Typography align="left" className={classes.description}>
                It is compulsory to register a referral before the walkthrough
                to be able to qualify for the rewards. <br />
                <br />
                Details required for registration purpose is; Name of the
                client, Email address, Mobile number, Business name of the
                registered entity. In case of duplicate entry, the one who had
                submitted the lead first will be awarded the credit. <br />
                <br />
                The validity of each referral lead will be 90 days from the
                registration of the referral. Any conversion after the cooling
                period of 90 days, will not be entitled to the rewards. However,
                the same can be claimed by a third entity or DevX team after the
                completion of the cooling period.
                <br />
                <br />
                If any client/employee is able to generate a combination; sales
                of 35 seats from 3 or more client, he/she will be entitled to an
                additional reward of INR 400/seat for the entire sales. The
                validity of the same will be one year from the date of joining.
                <br />
                <br />
                For every sale made through the referred client, you will be
                entitled to a 25% reward of existing reward received by the
                existing referring client.
                <br />
                <br />
                The reward will be credited in your DevX wallet within 45
                business days of the contract signing with the new client
                referred by you. <br />
                <br />
                The wallet balance can be redeemed for various partner products,
                cafeteria and purchasing of meeting/conference/event room
                credits. <br />
                <br />
                In case of any ambiguity, Decision taken by Management would be
                treated as final.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.spaces.loading,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withStyles(styles)(ReferFriendInformation));
