import {
  CREATE_REFER_FRIEND,
  REFER_FRIEND_LOADING,
  REFER_FRIEND_ERROR,
  GET_REFER_FRIENDS,
  GET_SINGLE_REFER_FRIEND,
  CREATE_REFER_FRIEND_COMMENT,
} from "../Actions/types";

const initialState = {
  referFriends: [],
  referFriend: {},
  loading: false,
  getSingleReferFriend: {},
  referFriendComments: [],
  //   edit_plan: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case REFER_FRIEND_LOADING:
      return {
        ...state,
        loading: true,
      };
    case REFER_FRIEND_ERROR:
      return {
        ...state,
        loading: false,
        referFriend: {
          success: false,
          data: action.payload && action.payload.data,
        },
      };
    case CREATE_REFER_FRIEND:
      return {
        ...state,
        loading: false,
        referFriend: {
          success: true,
          data: action.payload && action.payload.data,
        },
      };

    case GET_REFER_FRIENDS:
      return {
        ...state,
        loading: false,
        referFriends: action.payload && action.payload.data,
      };
    case GET_SINGLE_REFER_FRIEND:
      return {
        ...state,
        loading: false,
        referFriend: action.payload && action.payload.data,
      };
    case CREATE_REFER_FRIEND_COMMENT:
      return {
        ...state,
        loading: false,
        referFriendComments: {
          success: true,
          data: action.payload && action.payload.data,
        },
      };
    //
    // case EDIT_PLAN:
    //   return {
    //     ...state,
    //     loading: false,
    //     edit_plan: {
    //       success: true,
    //       data: action.payload && action.payload.data,
    //     },
    //   };
    default:
      return state;
  }
}
