import React, { useEffect, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { SelectField } from "../Form";
import { getCoworker } from "../../../Actions/coworkerActions";
import { capitalize } from "../../../Utils/StringFunctions";

const styles = () => ({});

const CoworkersDropDown = (props) => {
  const {
    values,
    setFieldValue,
    setFieldTouched,
    errors,
    touched,
    companyID,
  } = props;
  const [coworkers, setCoworkers] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);

  // eslint-disable-next-line no-shadow
  const getAvailableCoworkerOptions = (coworkers) => {
    return coworkers
      ? coworkers.map((coworker) => {
          return {
            value: coworker._id,
            label: capitalize(`${coworker.first_name} ${coworker.last_name}`),
          };
        })
      : [];
  };

  useEffect(() => {
    props.getCoworker(companyID);
  }, []);

  useEffect(() => {
    setCoworkers(props.coworkers);
    if (values.coworker_id) {
      coworkers.map((coworker) => {
        if (coworker._id === values.coworker_id._id) {
          selectedValue.push({
            value: coworker._id,
            label: coworker.first_name,
          });
        }
        return null;
      });

      setSelectedValue(selectedValue);
    }
  }, [props.coworkers]);

  const changeDropDown = (name, value) => {
    setSelectedValue(value);
    setFieldValue(name, value);
  };

  return (
    <SelectField
      value={selectedValue}
      name="coworker_id"
      id="coworker_id"
      label="Select Co-worker"
      onChange={changeDropDown}
      onBlur={setFieldTouched}
      error={errors.coworker_id}
      touched={touched.coworker_id}
      options={getAvailableCoworkerOptions(coworkers)}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    errors: state.errors,
    coworkers: state.coworkers.companyCoworkers,
  };
};

export default connect(mapStateToProps, { getCoworker })(
  withStyles(styles)(CoworkersDropDown)
);
