/* eslint-disable react/no-unused-state */
/* eslint-disable camelcase */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import { withRouter } from 'react-router-dom'
import {
  Dialog,
  DialogContent,
  Grid,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import classNames from 'classnames'
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import { CircularLoader } from '../../Components/Common/Spinner'
import Icon_Accept_Grey from '../../Assets/icons/Icon_Accept Selected.png'
import { spacecapitalize } from '../../Utils/StringFunctions'
import Icon_Reject_Selected from '../../Assets/icons/Icon_Reject Selected.png'
import Icon_Reject_Grey from '../../Assets/icons/Icon_Reject Grey.png'
import ScreenHeader from '../../Components/Common/ScreenHeader'
import NameTruncate from '../../Components/Common/NameTruncate'
import Pagination from '../../Components/Common/Table/TablePagination'
import DeleteAlert from '../../Components/Common/DeleteAlert'
import nodata from '../../Assets/nodata.png'
import UploadField from '../../Components/Common/Form/UploadField'
import AmbassdorStatus from './AmbasssadorStatus'
import {
  getCommunityAmbassdor,
  AddConfirmation,
  getoneCommunityAmbassdor,
} from '../../Actions/CommunityAmbassadorAction'

const $quoteSym = ' '
const moment = require('moment')

const styles = (theme) => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    backgroundColor: 'white',
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    padding: '15px 24px 24px',
    flexGrow: 1,
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      padding: '15px 24px 24px',
    },
  },
  root: {
    backgroundColor: '#fff',
  },
  table: {
    minWidth: 650,
    marginTop: '20px',
  },
  resource_form_modal: {
    width: '500px',
    height: '410px',
  },
  form_modal: {
    minWidth: '500px',
    height: '410px',
  },
  correctbutton: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  ScreenHeader: {
    marginLeft: '-28px',
    width: '100%',
  },
  cancelbutton: {
    marginRight: '20px',
    backgroundColor: '#dddddd',
    color: '#151039',
    '&:hover': {
      color: '#151039',
      backgroundColor: '#d1d1d1!important',
    },
  },
  addbutton: {
    color: '#fff',
    backgroundColor: '#211b4e !important',
    '&:hover': {
      backgroundColor: '#151039 !important',
    },
  },
  modelHeader: {
    paddingBottom: '5px',
    position: 'relative',
    '&:after': {
      top: '48px',
      left: '24px',
      width: '24px',
      borderBottom: '3px solid #fb0',
      content: `'${$quoteSym}'`,
      position: 'absolute',
    },
  },
  modalFooter: {
    padding: '40px 0px 8px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  root1: {
    '&:nth-child(even)': {
      backgroundColor: '#FCFDFE',
    },
  },
  rejected: {
    color: '#cdcdcd',
  },
  addDeleteUser: {
    display: 'flex',
  },
  headerSpacing: {
    margin: '13% 0% 0% 3%',
  },
  uploadClubBanner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '50px',
  },
  borderppup: {
    margin: '40px 10px 30px 10px',
    boxsizing: 'border-box',
    width: '300px',
    height: '200px',
    borderRadius: '5px',
    border: '3px dashed pink',
  },
  backButton: {
    margin: '0% 0% 2% 0%',
  },
})

const ResourceSchema = Yup.object().shape({
  ambassador_profile_pic: Yup.string().required('This field is required'),
})
const themes = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        minWidth: '40px',
      },
    },
  },
})
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#F8FAFF',
    color: theme.palette.common.black,
    borderBottom: 'none',
    padding: '8px 40px 8px 16px',
  },
  body: {
    fontSize: 14,
    borderBottom: 'none',
  },
}))(TableCell)

export class CofirmAmbassador extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: '',
      communityEmbassdor: [],
      page: 0,
      rowsPerPage: 10,
      deletedKey: [],
      showConfirmDel: false,
    }
  }

  componentDidMount() {
    this.props.getCommunityAmbassdor()
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.communityEmbassdor !== prevState.communityEmbassdor) {
      return { communityEmbassdor: nextProps.communityEmbassdor }
    }
    if (nextProps.oneEmbassador !== prevState.oneEmbassador) {
      return { oneEmbassador: nextProps.oneEmbassador }
    }
    return []
  }

  componentDidUpdate(prevProps) {
    if (prevProps.communityEmbassdor !== this.props.communityEmbassdor) {
      let communityEmbassdor = this.props.communityEmbassdor || []
      communityEmbassdor = communityEmbassdor.map((x, id) => {
        return Object.assign({}, x, {
          idx: id + 1,
        })
      })
      this.setState({
        communityEmbassdor,
      })
    }
    if (
      this.props.statusUpdate !== prevProps.statusUpdate &&
      this.props.statusUpdate.success
    ) {
      this.props.getCommunityAmbassdor()
    }
    if (
      this.props.communityEmbassdor !== prevProps.communityEmbassdor &&
      this.props.communityEmbassdor.success
    ) {
      this.props.getCommunityAmbassdor()
    }

    if (prevProps.oneEmbassador !== this.props.oneEmbassador) {
      if (this.props.oneEmbassador.data !== 'No data found') {
        this.setState({
          oneEmbassador: this.props.oneEmbassador,
        })
      } else {
        return null
      }
    }
    if (prevProps.loading !== this.props.loading) {
      this.props.setLoading(this.props.loading)
      this.setState({
        loading: this.props.loading,
      })
    }
    return null
  }

  handleClickModalOpen = (id, status, user_id) => {
    this.setState({
      id,
      status,
      modalOpen: true,
    })
    this.props.getoneCommunityAmbassdor(user_id)
  }

  handleClose = () => {
    this.setState({
      modalOpen: false,
    })
    this.props.getCommunityAmbassdor()
  }

  handleDelete = (id, status) => {
    this.setState({
      id,
      status,
      showConfirmDel: true,
    })
  }

  handleDeletePlan = () => {
    const { id } = this.state
    const data = {}
    data.ambassador_approve_status = 'reject'
    this.props.AddConfirmation(id, data, '', '')
    this.props.getCommunityAmbassdor()
  }

  setDelImageUrl = (url) => {
    const { deletedKey } = this.state
    this.setState({ deletedKey: deletedKey.push(url) })
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage })
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    })
  }

  render() {
    const {
      loading,
      page,
      rowsPerPage,
      id,
      communityEmbassdor,
      oneEmbassador,
    } = this.state
    const { classes } = this.props
    const itemLength = communityEmbassdor ? communityEmbassdor.length : 0
    if (loading) {
      return <CircularLoader />
    }
    if (communityEmbassdor && communityEmbassdor.length <= 0) {
      return (
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.headerSpacing}
        >
          <Grid item justify="center">
            <Grid item sm={12} lg={12}>
              <img src={nodata} alt="" width="76%" />
            </Grid>
          </Grid>
        </Grid>
      )
    }
    return (
      <main className={classes.layout}>
        <Button
          color="grey"
          onClick={() => this.props.history.push('/settings')}
          className={classes.backButton}
          variant="contained"
          size="small"
        >
          Back
        </Button>
        <Paper className={classes.paper}>
          <div className={classes.header}>
            <div className={classes.ScreenHeader}>
              <ScreenHeader type="Community Ambassador Confirmation" />
            </div>

            <Dialog
              open={this.state.modalOpen}
              onClose={this.handleClose}
              aria-labelledby="form-dialog-title"
            >
              <Formik
                initialValues={{
                  ambassador_profile_pic:
                    (oneEmbassador && oneEmbassador.ambassador_profile_pic) ||
                    '',
                }}
                enableReinitialize
                validationSchema={ResourceSchema}
                onSubmit={(values, { setErrors, setSubmitting }) => {
                  const data = { ...values }
                  data.ambassador_approve_status = 'accept'
                  this.props.AddConfirmation(id, data, setErrors, setSubmitting)
                  this.props.getCommunityAmbassdor()
                  this.handleClose()
                }}
              >
                {({ values, errors, setFieldValue }) => (
                  <Form className={classes.resource_form_modal}>
                    <DialogTitle
                      id="plan-dialog-title"
                      className={classes.modelHeader}
                    >
                      Confirm Community Ambassador
                    </DialogTitle>
                    <DialogContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12} className={classes.borderppup}>
                          <Grid
                            item
                            xs={12}
                            className={classes.uploadClubBanner}
                          >
                            <UploadField
                              label="Upload Photo"
                              name="ambassador_profile_pic"
                              id="ambassador_profile_pic"
                              onChange={setFieldValue}
                              value={values.ambassador_profile_pic}
                              error={errors.ambassador_profile_pic}
                              folder="embassador/image"
                              delImageUrl={this.setDelImageUrl}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid className={classes.modalFooter}>
                        <Button
                          onClick={this.handleClose}
                          color="primary"
                          className={classes.cancelbutton}
                        >
                          Cancel
                        </Button>
                        <Button
                          color="primary"
                          className={classes.addbutton}
                          type="submit"
                          variant="contained"
                          size="medium"
                        >
                          Submit
                        </Button>
                      </Grid>
                    </DialogContent>
                  </Form>
                )}
              </Formik>
            </Dialog>
          </div>
          <div className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Sr. No.</StyledTableCell>
                  <StyledTableCell align="left">Date</StyledTableCell>
                  <StyledTableCell align="left">Name</StyledTableCell>
                  <StyledTableCell align="left">Mobile No.</StyledTableCell>
                  <StyledTableCell align="left">Email ID</StyledTableCell>
                  <StyledTableCell align="left">Company Name</StyledTableCell>
                  <StyledTableCell align="left">Space</StyledTableCell>
                  <StyledTableCell align="center" />
                  <StyledTableCell align="left">Status</StyledTableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {communityEmbassdor &&
                  communityEmbassdor
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((Embassdor) => {
                      return (
                        <TableRow className={classes.root1}>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            className={classNames(
                              classes.accept,
                              Embassdor.ambassador_approve_status === 'reject'
                                ? classes.rejected
                                : ''
                            )}
                          >
                            {page * rowsPerPage + id + 1}
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            className={classNames(
                              classes.accept,
                              Embassdor.ambassador_approve_status === 'reject'
                                ? classes.rejected
                                : ''
                            )}
                          >
                            {moment(Embassdor.createdAt).format('DD/MM/YYYY')}
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            className={classNames(
                              classes.accept,
                              Embassdor.ambassador_approve_status === 'reject'
                                ? classes.rejected
                                : ''
                            )}
                          >
                            <NameTruncate
                              text={spacecapitalize(
                                `${
                                  Embassdor &&
                                  Embassdor.created_by &&
                                  Embassdor.created_by.first_name
                                } ${
                                  Embassdor &&
                                  Embassdor.created_by &&
                                  Embassdor.created_by.last_name
                                }`
                              )}
                              amount={22}
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            className={classNames(
                              classes.accept,
                              Embassdor.ambassador_approve_status === 'reject'
                                ? classes.rejected
                                : ''
                            )}
                          >
                            {Embassdor &&
                            Embassdor.created_by &&
                            Embassdor.created_by.contact_number
                              ? Embassdor.created_by.contact_number
                              : '-'}
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            className={classNames(
                              classes.accept,
                              Embassdor.ambassador_approve_status === 'reject'
                                ? classes.rejected
                                : ''
                            )}
                          >
                            {Embassdor &&
                            Embassdor.created_by &&
                            Embassdor.created_by.email
                              ? Embassdor.created_by.email
                              : '-'}
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            className={classNames(
                              classes.accept,
                              Embassdor.ambassador_approve_status === 'reject'
                                ? classes.rejected
                                : ''
                            )}
                          >
                            {spacecapitalize(
                              Embassdor.company_id
                                ? Embassdor.company_id.company_name
                                : '-'
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            className={classNames(
                              classes.accept,
                              Embassdor.ambassador_approve_status === 'reject'
                                ? classes.rejected
                                : ''
                            )}
                          >
                            {spacecapitalize(
                              Embassdor.space.space_title
                                ? Embassdor.space.space_title
                                : '-'
                            )}
                          </StyledTableCell>

                          <ThemeProvider theme={themes}>
                            <StyledTableCell
                              align="left"
                              className={classNames(classes.accept)}
                            >
                              <div className={classes.addDeleteUser}>
                                <Button
                                  className={classes.correctbutton}
                                  onClick={() =>
                                    this.handleClickModalOpen(
                                      Embassdor._id,
                                      'yes',
                                      Embassdor.created_by._id
                                    )
                                  }
                                >
                                  <img
                                    src={Icon_Accept_Grey}
                                    alt=""
                                    width={30}
                                  />
                                </Button>
                                <Button
                                  className={classes.correctbutton}
                                  disabled={
                                    Embassdor.ambassador_approve_status ===
                                    'reject'
                                  }
                                  onClick={() =>
                                    this.handleDelete(Embassdor._id, 'yes')
                                  }
                                >
                                  {Embassdor.ambassador_approve_status ===
                                  'reject' ? (
                                    <img
                                      src={Icon_Reject_Grey}
                                      alt=""
                                      width={30}
                                    />
                                  ) : (
                                    <img
                                      src={Icon_Reject_Selected}
                                      alt=""
                                      width={30}
                                    />
                                  )}
                                </Button>
                              </div>
                            </StyledTableCell>
                          </ThemeProvider>
                          <StyledTableCell>
                            <AmbassdorStatus
                              selectedItem={
                                Embassdor.ambassador_activation_status
                              }
                              id={Embassdor._id}
                              Embassdor_id={Embassdor._id}
                            />
                          </StyledTableCell>
                        </TableRow>
                      )
                    })}
              </TableBody>
            </Table>
          </div>
        </Paper>
        <Pagination
          count={itemLength}
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
        <DeleteAlert
          message="Are you sure want to reject Community Ambassdor?"
          open={this.state.showConfirmDel}
          setOpen={(data) => this.setState({ showConfirmDel: data })}
          alert={this.handleDeletePlan}
        />
      </main>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    communityEmbassdor: state.communityAmbassador.community_embassadors,
    loading: state.communityAmbassador.loading,
    statusUpdate: state.communityAmbassador.community_embassador_status,
    oneEmbassador: state.communityAmbassador.community_one_embassadors,
  }
}

export default connect(mapStateToProps, {
  getCommunityAmbassdor,
  AddConfirmation,
  getoneCommunityAmbassdor,
})(withStyles(styles)(withRouter(CofirmAmbassador)))
