import axios from "axios";
import {
  GET_ALL_FOOD_ITEM,
  FOOD_ITEM_LOADING,
  ADD_FOOD_ITEM,
  FOOD_ITEM_ERRORS,
  EDIT_FOOD_ITEM_ERRORS,
  EDIT_FOOD_ITEM,
} from "./types";
import { showSuccess, showError } from "./ActionsHelper";
import { transformErrorsFromApi } from "./TransformError";

// Loading
export const setFoodItemLoading = () => {
  return {
    type: FOOD_ITEM_LOADING,
  };
};

export const addfooditem = (userData, setErrors, history, setSubmitting) => (
  dispatch
) => {
  dispatch(setFoodItemLoading());
  axios
    .post("/api/fooditem/", userData)
    .then((result) => {
      dispatch({
        type: ADD_FOOD_ITEM,
        payload: result.data,
      });
      history.push("/food-items");
      dispatch(showSuccess("Food Item "));
      setSubmitting(false);
    })
    .catch((err) => {
      const error = err.response || {};
      dispatch({ type: FOOD_ITEM_ERRORS, payload: error.data });
      // if fooditem found
      if (error.data.error) {
        dispatch(showError("fooditem already exists"));
      } else {
        dispatch(showError("There was some error while add food item "));
      }
      setSubmitting(false);
      setErrors(transformErrorsFromApi(error));
    });
};

export const getallFoodItem = (search) => (dispatch) => {
  dispatch(setFoodItemLoading());
  let url = "/api/fooditem";
  if (search) {
    url = `/api/fooditem?q=${search}`;
  }
  axios
    .get(url)
    .then((result) => {
      dispatch({
        type: GET_ALL_FOOD_ITEM,
        payload: result.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: FOOD_ITEM_ERRORS,
        payload: err && err.response && err.response.data,
      });
    });
};

export const editCafe = (id, data, setErrors, setSubmitting) => (dispatch) => {
  dispatch(setFoodItemLoading());
  axios
    .put(`/api/fooditem/${id}`, data)
    .then((result) => {
      dispatch({
        type: EDIT_FOOD_ITEM,
        payload: result.data,
      });
      dispatch(showSuccess("", "Food item updated successfully!"));
      setSubmitting(false);
    })
    .catch((err) => {
      const error = err.response;
      dispatch({ type: EDIT_FOOD_ITEM_ERRORS, payload: {} });
      setSubmitting(false);
      setErrors(transformErrorsFromApi(error));
    });
};
