import {
  GET_PARTNERS,
  ADD_PARTNER,
  GET_PARTNER,
  PARTNERS_LOADING,
  PARTNER_LOADING,
  UPDATE_PARTNER,
  PARTNER_ERROR,
} from "../Actions/types";

const initialState = {
  partners: [],
  loading: false,
  partner: {},
};
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PARTNERS:
      return {
        ...state,
        partners: action.payload.data,
        loading: false,
      };
    case PARTNER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ADD_PARTNER:
      return {
        ...state,
        partner: { success: true, data: action.payload },
        loading: false,
      };
    case GET_PARTNER:
      return {
        ...state,
        partner: action.payload.data,
        loading: false,
      };
    case PARTNERS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_PARTNER:
      return {
        ...state,
        loading: false,
        update_partner: { success: true, data: action.payload.data },
      };
    case PARTNER_ERROR:
      return {
        ...state,
        loading: false,
        partner: { success: false, data: action.payload.data },
      };
    default:
      return state;
  }
}
