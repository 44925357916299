import withStyles from "@material-ui/core/styles/withStyles";
import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, CssBaseline, Button } from "@material-ui/core";
import classNames from "classnames";
import { connect } from "react-redux";
import { getSingleCreditAccount } from "../../Actions/CreditAccountActions";
import avatar from "../../Assets/coworker3.png";
import rightMark from "../../Assets/right.png";
import OrderHistory from "./OrderHistory";
import PaymentHistory from "./PaymentHistory";

import PayingAccountPopup from "./PayingAccountPopup";

const styles = () => ({
  logo: {
    width: "100%",
  },
  eventTitle: {
    paddingBottom: "8px",
    position: "absolute",
    left: "28%",
    top: "15%",
  },
  eventsubtitle: {
    fontSize: "18px",
    // color: "grey",
    fontWeight: "normal",
    textAlign: "left",
    color: "#626262",
    textTransform: "capitalize",
  },
  eventsubtitle1: {
    fontSize: "20px",
    // color: "grey",
    fontWeight: "500",
    textAlign: "left",
    color: "#222222",
    lineHeight: "20px",
    textTransform: "capitalize",
  },
  useIcon: {
    position: "absolute",
    margin: "10px",
    width: "70px",
    height: "70px",
    opacity: "0.7",
  },
  createPostSection: {
    paddingRight: "20px",
    display: "flex",
    paddingTop: "5%",
  },
  title: {
    fontSize: "13px",
    color: "#222222",
    fontWeight: "500",
  },
  cardLayout: {
    border: "1px solid #EFEFEF",
    borderRadius: "5px",
    boxShadow: "0 9px 10px rgba(0, 0, 0, 0.1), 0 4px 0px rgba(0,0,0,0.05)",
    padding: "10px",
    margin: "45px 7px",
    width: "29%",
    height: "120px",
  },
  cardLayoutPayNow: {
    border: "1px solid #EFEFEF",
    borderRadius: "5px",
    boxShadow: "0 9px 10px rgba(0, 0, 0, 0.1), 0 4px 0px rgba(0,0,0,0.05)",
    padding: "10px",
    margin: "45px 10px",
    backgroundColor: "#5B8CEE",
    width: "70px",
    height: "125px",
  },
  billAmount: {
    color: "#FF8800",
    fontSize: "40px",
    fontWeight: "normal",
  },
  paidAmount: {
    color: "#6FC46C",
    fontSize: "40px",
    fontWeight: "normal",
  },
  pendingAmount: {
    color: "#F84848",
    fontSize: "40px",
    fontWeight: "normal",
  },
  paynow: {
    color: "#ffffff",
    fontSize: "20px",
    fontWeight: "500",
    lineHeight: "22px",
    paddingBottom: "15px",
  },
  rightmark: {
    width: "48px",
    height: "48px",
  },
  button: {
    width: "20%",
    marginRight: "1.4%",
    // color: "white",
    padding: "5px 16px",
    borderRadius: "4px",
    textDecoration: "none",
    fontSize: "0.875rem",
    fontWeight: "500",
    boxShadow: "rgba(137, 163, 169, 0.3) 0px 3px 5px 2px",
    // backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  active: {
    backgroundColor: "#211b4e",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#211b4e",
      color: "#fff",
    },
  },
  inActive: {
    color: "#211b4e",
  },
  marginTop: {
    marginTop: "5%",
  },
});

class CreditAccountDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      activeFlag: "payment",
    };
    this.state.id = this.props.match.params.id;
    this.state.name = this.props.location.state.name;
    this.state.companyName = this.props.location.state.companyName;
  }

  componentDidMount() {
    this.props.getSingleCreditAccount(this.state.id, this.state.activeFlag);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.payingAmount !== prevProps.payingAmount &&
      this.props.payingAmount.success
    ) {
      this.props.getSingleCreditAccount(this.state.id, this.state.activeFlag);
    }
    if (
      this.props.paymentData !== prevProps.paymentData &&
      prevState.activeFlag !== this.state.activeFlag
    ) {
      this.setState({ paymentData: this.props.paymentData });
      // this.props.getSingleCreditAccount(this.state.id, this.state.activeFlag);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.paymentData !== prevState.paymentData &&
      nextProps.activeFlag !== prevState.activeFlag
    ) {
      return { paymentData: nextProps.paymentData };
    }

    return {};
  }

  handleClickOpen = () => {
    this.setState({
      modalOpen: true,
    });
  };

  handleClose = () => {
    this.setState({
      modalOpen: false,
    });
  };

  handleClick = (value) => {
    this.setState({ activeFlag: value });
    this.props.getSingleCreditAccount(this.state.id, value);
  };

  render() {
    const { classes } = this.props;
    const { companyName, name, paymentData } = this.state;
    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.mainsection}>
          <main>
            <div className={classNames(classes.layout, classes.cardGrid)}>
              <div className={classes.eventbasicdetail}>
                <Grid container item lg={12} xs={12}>
                  <Grid item lg={2} xs={2}>
                    <div className={classes.logo}>
                      <img
                        src={paymentData.profile_pic || avatar}
                        // src={avatar}
                        className={classes.useIcon}
                        alt=""
                      />
                    </div>
                    <div className={classes.eventTitle}>
                      <Typography
                        variant="subtitle1"
                        component="p"
                        className={classes.eventsubtitle1}
                      >
                        {name}
                      </Typography>
                      <Typography
                        className={classes.eventsubtitle}
                        variant="subtitle1"
                        component="p"
                      >
                        {companyName}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
            <div>
              <Grid
                className={classes.createPostSection}
                container
                item
                lg={12}
                xs={12}
              >
                <Grid className={classes.cardLayout}>
                  <Typography align="left" className={classes.billAmount}>
                    {paymentData.bill_amount}
                  </Typography>
                  <Typography align="left" className={classes.title}>
                    Total Bill Amount
                  </Typography>
                </Grid>

                <Grid className={classes.cardLayout}>
                  <Typography align="left" className={classes.paidAmount}>
                    {paymentData.paid_amount}
                  </Typography>
                  <Typography align="left" className={classes.title}>
                    Total Paid Amount
                  </Typography>
                </Grid>

                <Grid className={classes.cardLayout}>
                  <Typography align="left" className={classes.pendingAmount}>
                    {paymentData.paid_amount - paymentData.bill_amount}
                  </Typography>
                  <Typography align="left" className={classes.title}>
                    Total Balance Amount
                  </Typography>
                </Grid>
                <Grid
                  className={classes.cardLayoutPayNow}
                  onClick={() => this.handleClickOpen()}
                >
                  <Typography align="left" className={classes.paynow}>
                    PAY NOW
                  </Typography>
                  <img src={rightMark} className={classes.rightmark} alt="" />
                </Grid>
              </Grid>
            </div>
            <Grid
              container
              direction="row"
              justify="flex-end"
              className={classes.endSpacing}
            >
              <Button
                variant="outlined"
                color="primary"
                className={classNames(
                  classes[
                    this.state.activeFlag === "payment" ? "active" : "inactive"
                  ],
                  classes.button
                )}
                onClick={() => this.handleClick("payment")}
              >
                Order History
              </Button>

              <Button
                variant="outlined"
                color="primary"
                className={classNames(
                  classes[
                    this.state.activeFlag === "credit" ? "active" : "inactive"
                  ],
                  classes.button
                )}
                onClick={() => this.handleClick("credit")}
              >
                Payment History
              </Button>
            </Grid>
            <Grid className={classes.marginTop}>
              {this.state.activeFlag === "payment" && (
                <OrderHistory data={paymentData.order_histroy} />
              )}
              {this.state.activeFlag === "credit" && (
                <PaymentHistory data={paymentData.order_histroy} />
              )}
            </Grid>
            <PayingAccountPopup
              open={this.state.modalOpen}
              handleClose={this.handleClose}
              user_id={this.state.id}
            ></PayingAccountPopup>
          </main>
        </div>
      </React.Fragment>
    );
  }
}

CreditAccountDetail.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    accountDetails: state.CreditAccount.getSingleCreditAccount.order_histroy,
    paymentData: state.CreditAccount.getSingleCreditAccount,
    payingAmount: state.CreditAccount.paynow,
  };
};

export default connect(mapStateToProps, {
  getSingleCreditAccount,
})(withStyles(styles)(CreditAccountDetail));
