/* eslint-disable no-unused-expressions */
import React, { useState } from "react";
import { withStyles, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import classNames from "classnames";

const styles = () => {
  return {
    ansStyle: {
      borderRadius: "5px",
      textAlign: "center",
      margin: "10px 5px 5px 5px",
      padding: "5px 10px",
      color: "#ababab",
      fontSize: "12px",
      background: "#EFEFEF",
      cursor: "pointer",
    },
    defaultYes: {
      background: "#D5EED3",
    },
    defaultNo: {
      background: "#F6E0E0",
    },
    defaultNotsure: {
      background: "#FBF7C9",
    },
    yes: {
      background: "#4CAF50",
      color: "#000000",
    },
    no: {
      background: "#F05757",
      color: "#000000",
    },
    notsure: {
      background: "#FFBB00",
      color: "#000000",
    },
  };
};

const AnswerOptions = (props) => {
  const { classes, questionId } = props;
  const [answer, setAnswer] = useState();

  function setAnswerOptions(id, ans, option) {
    setAnswer(ans);
    props.setAnswerData(id, ans, option);
  }

  return (
    <Grid style={{ display: "flex" }}>
      <Grid
        onClick={() => {
          setAnswerOptions(questionId, "yes", "option");
        }}
        className={classNames(
          classes.ansStyle,
          answer === "yes" ? classes.yes : classes.defaultYes
        )}
      >
        Yes
      </Grid>
      <Grid
        onClick={() => setAnswerOptions(questionId, "no", "option")}
        className={classNames(
          classes.ansStyle,
          answer === "no" ? classes.no : classes.defaultNo
        )}
      >
        No
      </Grid>
      <Grid
        onClick={() => setAnswerOptions(questionId, "notsure", "option")}
        className={classNames(
          classes.ansStyle,
          answer === "notsure" ? classes.notsure : classes.defaultNotsure
        )}
      >
        Not Sure
      </Grid>
    </Grid>
  );
};
const mapStateToProps = (state) => {
  return {
    errors: state.errors,
  };
};
export default connect(mapStateToProps, {})(withStyles(styles)(AnswerOptions));
