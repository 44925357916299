import React from "react";
import { Paper, Typography, Grid, withStyles } from "@material-ui/core";
import AddPost from "../Clubs/AddPost";
import PostCard from "../Clubs/PostCard";

const $quoteSym = " ";

const styles = () => ({
  addPost: {
    marginBottom: "35px",
  },
  noPostPaper: {
    height: "200px",
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
  },
  noPosts: {
    textAlign: "center",
    display: "block",
    color: "lightgrey",
  },
  about: {
    margin: "10px 0px",
    fontWeight: 500,
    fontSize: "18px",
    paddingBottom: "5px",
    position: "relative",
    "&:after": {
      top: "31px",
      left: "1px",
      width: "24px",
      borderBottom: "3px solid #fb0",
      content: `'${$quoteSym}'`,
      position: "absolute",
    },
  },
  about_section: {
    padding: "3px 20px",
    marginTop: "2.8%",
    marginRight: "2.5%",
    marginBottom: "3%",
    paddingBottom: "1.5%",
  },
  about_title: {
    color: "#222222",
    paddingTop: "20px",
    fontSize: "16px",
    fontWeight: "500",
  },
  description: {
    // marginLeft: "-4.2%"
  },
  list_text: {
    marginLeft: "0px",
    padding: "0px",
  },
});

function AboutEventTab(props) {
  const { classes, id, event } = props;

  return (
    <React.Fragment>
      <div className={classes.mainPost}>
        <Grid container spacing={2}>
          <Grid item sm={12} lg={12}>
            <Paper className={classes.about_section}>
              <Typography
                component="h5"
                variant="subtitle1"
                className={classes.about}
              >
                About
              </Typography>

              <ul className={classes.list_text}>
                <div
                  className={classes.description}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: event.details }}
                />
              </ul>
            </Paper>
          </Grid>
          <Grid item xs={12} md={12}>
            <div className={classes.addPost}>
              <AddPost id={id} type="event" />
            </div>
            {/* no data in post  */}
            {props.data && props.data.length <= 0 && (
              <Paper className={classes.noPostPaper}>
                <Typography variant="h6" className={classes.noPosts}>
                  No posts to show!!
                </Typography>
              </Paper>
            )}
            {/* View all post of particular event  */}
            {props.data &&
              props.data.map((post) => {
                return <PostCard data={post} id={id} type="event" />;
              })}
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}

export default withStyles(styles)(AboutEventTab);
