/* eslint-disable camelcase */
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  CssBaseline,
  Paper,
  Dialog,
  DialogContent,
  Grid,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import avatar from "../../Assets/coworker3.png";

const $quoteSym = " ";

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    flexGrow: 1,
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      padding: theme.spacing(3),
    },
  },
  member_modal: {
    minWidth: "400px",
  },
  main: {
    padding: "24px",
  },
  members: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#000",
    paddingLeft: "4%",
    textTransform: "capitalize",
    lineHeight: "14px",
  },
  memberInfo: {
    fontSize: "12px",
    fontWeight: "600",
    color: "#ababab",
    paddingLeft: "4%",
    textTransform: "capitalize",
  },
  defaultmemberImage: {
    width: "48px",
    height: "48px",
    borderRadius: "29.5px",
    marginTop: "3px",
  },
  memberImage: {
    width: "44px",
    height: "44px",
    borderRadius: "29.5px",
    margin: "6px 2px",
  },
  postUserDetail: {
    position: "relative",
    bottom: "49px",
    left: "40px",
  },
  clubmemberSection: {
    borderBottom: "1px solid #efefef",
    height: "62px",
    marginTop: "10px",
  },
  DialogContent: {
    padding: " 8px 24px",
    overflowX: "hidden",
  },
  applicationHeader: {
    marginTop: "-2px",
    top: "10px",
    padding: "16px 24px 14px 24px",
    "&:after": {
      width: "24px",
      borderBottom: "3px solid #fb0",
      content: `'${$quoteSym}'`,
      position: "absolute",
    },
  },
});
const ClubMemberModal = (props) => {
  const { classes, open, close, data } = props;

  // const handleClose = () => {
  //   props.close();
  // };

  const returnMemberCompanyDetail = (member) => {
    if (member.company_id && member.company_id.type === "freelancer") {
      return " -";
      // eslint-disable-next-line no-else-return
    } else if (member.company_id && member.company_id) {
      return member.company_id && member.company_id.company_name;
    } else {
      return "DevX";
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Paper className={classes.paper}>
        <Dialog open={open} onClose={close} aria-labelledby="form-dialog-title">
          <div className={classes.member_modal}>
            <DialogTitle
              id="plan-dialog-title"
              className={classes.applicationHeader}
            >
              Club Members
            </DialogTitle>
            <DialogContent className={classes.DialogContent}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <div className={classes.alignment}>
                    {data.members_details &&
                      data.members_details.map((member) => {
                        return (
                          <div className={classes.clubmemberSection}>
                            {member.profile_pic ? (
                              <div className={classes.clubmemberImage}>
                                <img
                                  src={member.profile_pic}
                                  alt="avatar"
                                  className={classes.memberImage}
                                />
                              </div>
                            ) : (
                              <img
                                src={avatar}
                                alt="avatar"
                                className={classes.defaultmemberImage}
                              />
                            )}
                            <div className={classes.postUserDetail}>
                              <Typography
                                variant="subtitle1"
                                className={classes.members}
                              >
                                {member.first_name} {member.last_name}
                              </Typography>
                              <Typography
                                variant="subtitle1"
                                className={classes.memberInfo}
                              >
                                {returnMemberCompanyDetail(member)}
                              </Typography>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </Grid>
              </Grid>
            </DialogContent>
            {/* <DialogActions className={classes.modalFooter}>
              <Button
                onClick={handleClose}
                color="primary"
                className={classes.cancelbutton}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                className={classes.addbutton}
                type="submit"
                variant="contained"
              >
                Add
              </Button>
            </DialogActions> */}
          </div>
        </Dialog>
      </Paper>
    </React.Fragment>
  );
};

export default withStyles(styles)(ClubMemberModal);
