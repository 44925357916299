import React from "react";
import { withStyles, Grid, Paper, Typography, Button } from "@material-ui/core";
import { connect } from "react-redux";
import service1 from "../../Assets/service1.png";
import service2 from "../../Assets/service2.png";
import service3 from "../../Assets/service3.png";
import service4 from "../../Assets/service4.png";
import service5 from "../../Assets/service5.png";
import service6 from "../../Assets/service6.png";
import service7 from "../../Assets/service7.png";
import service8 from "../../Assets/service8.png";
import ImageTextLayout from "../Knowledge/ImageTextLayout";
import HrConsulting from "./HrConsulting";
import DigitalMarketing from "./DigitalMarketing";
import LegalService from "./LegalService";
import WebsiteDesign from "./WebsiteDesign";
import GraphicDesign from "./GraphicDesign";
import AccountingService from "./AccountingService";
import InteriorDesign from "./InteriorDesign";
import CorporateInnovation from "./CorporateInnovation";
import InquiryPopup from "./InquiryPopup";

const styles = (theme) => ({
  mainCont: {
    
  },
  root: {
    flexGrow: 1,
  },
  paper:{
    padding: "20px",
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: "100vh",
    overflowY:"scroll"
  },
  mainpaper: {
    padding: "20px",
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: "100vh",
  },
  mainTitle: {
    fontWeight: "600",
    fontSize: "40px",
    textAlign: "left",
    color: "#222222",
    margin: "0px",
    lineHeight: "45px",
  },
  descText: {
    fontSize: "14px",
    textAlign: "left",
    color: "#626262",
    marginTop: "10px",
  },
  knowledgeLayout: {
    padding: "5% 0% 0% 2.5%",
    display: "flex",
  },
  contentLayout: {
    margin: "0px 25px 0px 0px",
  },
  imageDiv: {
    border: "1px solid #EFEFEF",
    borderRadius: "5px",
  },
  setupImg: {
    padding: "10%",
  },
  subTitle: {
    margin: "0% 0% 10%",
    textAlign: "center",
    fontWeight: "600",
    lineHeight: "18px",
    fontSize: "16px",
  },
  buttonLayout: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    width: "148px",
    height: "40px",
    backgroundColor: "#211b4e !important",
    marginTop: "3%",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
});

class AdminAlliedServices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alliedServices: "accounting",
      inquiryModalOpen: false,
    };
  }

  componentDidMount() {}

  setAlliedServices = (category) => {
    this.setState({ alliedServices: category });
  };

  handleModalClose = () => {
    this.setState({ inquiryModalOpen: false });
  };

  modalOpen = () => {
    this.setState({ inquiryModalOpen: true });
  };

  render() {
    const { classes } = this.props;
    const { alliedServices } = this.state;

    return (
      <React.Fragment>
        <main className={classes.root}>
          <Grid container spacing={3} className={classes.mainCont}>
            <Grid item xs={6}>
              <Paper className={classes.mainpaper}>
                <Grid className={classes.TextLayout}>
                  <Typography
                    align="center"
                    variant="h1"
                    className={classes.mainTitle}
                  >
                    <div>Allied Services </div>
                  </Typography>
                  <div className={classes.descText}>
                    <Typography align="center" variant="p">
                      Focus on the core activity of your business and leave your
                      support activities to us.
                    </Typography>
                  </div>
                </Grid>
                <Grid className={classes.knowledgeLayout}>
                  <ImageTextLayout
                    image={service1}
                    text="Accounting Service"
                    name="accounting"
                    setCategory={this.setAlliedServices}
                    category={alliedServices}
                  ></ImageTextLayout>
                  <ImageTextLayout
                    image={service6}
                    text="Graphic Designing"
                    name="graphic_designing"
                    setCategory={this.setAlliedServices}
                    category={alliedServices}
                  ></ImageTextLayout>
                  <ImageTextLayout
                    image={service4}
                    text="Corporate Innovation"
                    name="corporate_innovation"
                    setCategory={this.setAlliedServices}
                    category={alliedServices}
                  ></ImageTextLayout>
                </Grid>
                <Grid className={classes.knowledgeLayout}>
                  <ImageTextLayout
                    image={service3}
                    text="Legal Service"
                    name="legal"
                    setCategory={this.setAlliedServices}
                    category={alliedServices}
                  ></ImageTextLayout>
                  <ImageTextLayout
                    image={service7}
                    text="Website Design"
                    name="software_development"
                    setCategory={this.setAlliedServices}
                    category={alliedServices}
                  ></ImageTextLayout>
                  <ImageTextLayout
                    image={service8}
                    text="Interior Designing"
                    name="interior_designing"
                    setCategory={this.setAlliedServices}
                    category={alliedServices}
                  ></ImageTextLayout>
                </Grid>
                <Grid className={classes.knowledgeLayout}>
                  <ImageTextLayout
                    image={service5}
                    text="Digital Marketing"
                    name="digital_marketing"
                    setCategory={this.setAlliedServices}
                    category={alliedServices}
                  ></ImageTextLayout>
                  <ImageTextLayout
                    image={service2}
                    text="HR consulting & Recruitment Service"
                    name="hr_consulting&recruitment"
                    setCategory={this.setAlliedServices}
                    category={alliedServices}
                  ></ImageTextLayout>
                </Grid>
                <Grid className={classes.buttonLayout}>
                  <Button
                    variant="contained"
                    className={classes.button}
                    color="primary"
                    onClick={() => this.modalOpen()}
                  >
                    Make an Inquiry
                  </Button>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                {alliedServices === "hr_consulting&recruitment" && (
                  <HrConsulting></HrConsulting>
                )}
                {alliedServices === "digital_marketing" && (
                  <DigitalMarketing></DigitalMarketing>
                )}
                {alliedServices === "legal" && <LegalService></LegalService>}
                {alliedServices === "software_development" && (
                  <WebsiteDesign></WebsiteDesign>
                )}
                {alliedServices === "graphic_designing" && (
                  <GraphicDesign></GraphicDesign>
                )}
                {alliedServices === "accounting" && (
                  <AccountingService></AccountingService>
                )}
                {alliedServices === "interior_designing" && (
                  <InteriorDesign></InteriorDesign>
                )}
                {alliedServices === "corporate_innovation" && (
                  <CorporateInnovation></CorporateInnovation>
                )}
              </Paper>
            </Grid>
          </Grid>
          <InquiryPopup
            data={this.state.inquiryModalOpen}
            handleClose={this.handleModalClose}
            alliedServices={this.state.alliedServices}
          />
        </main>
      </React.Fragment>
    );
  }
}

AdminAlliedServices.propTypes = {};

const mapStateToProps = () => {
  return {};
};

export default connect(
  mapStateToProps,
  {}
)(withStyles(styles)(AdminAlliedServices));
