import React from "react";
import Hidden from "@material-ui/core/Hidden";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import Navigator from "./Layout/Navigator";

const styles = () => ({});
class NavContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    const role = localStorage.getItem("role");
    return (
      <nav className={classes.drawer}>
        <Hidden smUp implementation="js">
          <Navigator
            variant="temporary"
            open={this.props.mobileOpen}
            onClose={this.props.handleDrawerToggle}
          />
        </Hidden>
        {role === "security" ? (
          ""
        ) : (
          <Hidden xsDown implementation="css">
            <Navigator />
          </Hidden>
        )}
      </nav>
    );
  }
}

NavContainer.propTypes = {};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(NavContainer));
