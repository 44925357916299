import {
  GET_TICKETS,
  TICKETS_LOADING,
  ADD_TICKET,
  GET_TICKET,
  TICKET_ERROR,
  CHANGE_STATUS,
  CREATE_HISTORY,
  GET_ASSIGNTO_USER
} from "../Actions/types";

const initialState = {
  ticket: {},
  tickets: [],
  ticketHistory: [],
  status: {},
  assignTo: {},
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_TICKETS:
      return {
        ...state,
        loading: false,
        tickets: action.payload && action.payload.data
      };
    case GET_TICKET:
      return {
        ...state,
        loading: false,
        ticket: action.payload && action.payload.data
      };
    case ADD_TICKET:
      return {
        ...state,
        loading: false,
        ticket: {
          success: true,
          data: action.payload && action.payload.data
        }
      };
    case CREATE_HISTORY:
      return {
        ...state,
        loading: false,
        ticketHistory: {
          success: true,
          data: action.payload && action.payload.data
        }
      };
    case CHANGE_STATUS:
      return {
        ...state,
        loading: false,
        status: action.payload && action.payload.data
      };
    case GET_ASSIGNTO_USER:
      return {
        ...state,
        loading: false,
        assignTo: action.payload && action.payload.data
      };
    case TICKETS_LOADING:
      return {
        ...state,
        loading: true
      };
    case TICKET_ERROR:
      return {
        ...state,
        loading: false,
        ticket: {
          success: false,
          data: action.payload && action.payload.data
        }
      };

    default:
      return state;
  }
}
