import {
  GET_ERRORS,
  GET_ROLES,
  ROLE_LOADING,
  ADD_ROLE,
  ROLE_ERRORS,
  GET_ROLE,
  GET_ROLES_POLICIES,
  UPDATE_ROLE
} from "../Actions/types";

const initialState = {
  roles: null,
  loading: false,
  role: {},
  policies: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ROLES:
      return {
        ...state,
        loading: false,
        roles: action.payload.data
      };

    case ADD_ROLE:
      return {
        ...state,
        loading: false,
        role: { success: true, data: action.payload.data }
      };
    case GET_ROLE:
      return {
        ...state,
        loading: false,
        role: action.payload.data
      };
    case ROLE_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_ROLES_POLICIES:
      return {
        ...state,
        loading: false,
        policies: action.payload.data
      };
    case UPDATE_ROLE:
      return {
        ...state,
        loading: false,
        role: { success: true, data: action.payload.data }
      };
    case ROLE_ERRORS:
      return {
        ...state,
        error: { success: false, errors: action.payload }
      };
    case GET_ERRORS:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}
