/* eslint-disable react/no-unused-state */
import React from "react";
import { withStyles, Grid, Paper, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { ShoppingCart } from "react-feather";
import classNames from "classnames";
import DataNotFound from "../../Components/Common/DataNotFound";
import { CircularLoader } from "../../Components/Common/Spinner";
import {
  getMerchandiseProduct,
  getUserMerchandisePoint,
} from "../../Actions/merchandiseActions";
import bannerImage from "../../Assets/devx_store_bg1.png";
import bannerImage2 from "../../Assets/devx_store_bg2.png";
import MerchandiseProductCard from "../../Components/Common/Cards/MerchandiseProductCard";
import points from "../../Assets/redeem_points.png";
import background from "../../Assets/product_bg2.png";
import PlaceOrderDetailModal from "./PlaceOrderDetailModal";

const styles = (theme) => ({
  layout: {
    backgroundColor: "#fff",
    backgroundImage: `url(${background})`,
  },
  coworkerCardContainer: {
    margin: "4px 0px",
  },
  paper: {
    padding: theme.spacing(2),
    backgroundImage: `url(${background})`,
    flexGrow: 1,
    marginTop: "-28%",
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      padding: theme.spacing(3),
    },
  },
  cartSection: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "24px",
  },
  cartIcon: {
    backgroundColor: "#211B4E",
    padding: "6px 9px",
    borderRadius: "50px",
  },
  icon: {
    position: "relative",
    top: "3px",
  },
  pointsTitle: {
    color: "#FF261F",
  },
  disableAddOrder: {
    backgroundColor: "grey",
    padding: "6px 9px",
    borderRadius: "50px",
  },
  pointsNo: {
    position: "relative",
    top: "-17px",
    left: "-43px",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "15px",
    color: "#ffa304",
  },
  noData: {
    backgroundColor: "#fff",
  },
  bannerImage: {
    position: "relative",
    top: "-316px",
    right: "-156px",
    width: "65%",
  },
  title: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: "44px",
    lineHeight: "47px",
    textAlign: "center",
    color: "#fff",
    position: "relative",
    top: "-363px",
  },
});

class AddtoCart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      page: 0,
      rowsPerPage: 10,
      orderData: [],
      orderModalOpen: false,
    };
  }

  componentDidMount() {
    this.props.getMerchandiseProduct();
    this.props.getUserMerchandisePoint();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.addProduct !== prevProps.addProduct &&
      this.props.addProduct.success
    ) {
      this.props.getMerchandiseProduct();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.noMoreData !== prevState.noMoreData) {
      return {
        noMoreData: nextProps.noMoreData,
      };
    }
    if (nextProps.allProduct !== prevState.allProduct) {
      return {
        allProduct: nextProps.allProduct,
      };
    }
    if (nextProps.userRedeemPoint !== prevState.userRedeemPoint) {
      return {
        userRedeemPoint: nextProps.userRedeemPoint,
      };
    }
    return [];
  }

  addOrder = (data) => {
    const { orderData } = this.state;
    // eslint-disable-next-line camelcase
    const data_ids = [];
    if (orderData.length === 0) {
      orderData.push(data);
      this.setState({
        orderData,
      });
    } else {
      orderData.map((d) => {
        data_ids.push(d._id);
        return null;
      });
      if (data_ids.includes(data._id)) {
        // eslint-disable-next-line func-names
        const filtered = orderData.filter(function (value) {
          return value.quantity !== 0;
        });
        this.setState({
          orderData: filtered,
        });
      } else {
        orderData.push(data);
        this.setState({
          orderData,
        });
      }
    }
    return null;
  };

  addOrderPopup = () => {
    if (this.state.orderData.length > 0) {
      this.setState({ orderModalOpen: true });
    }
  };

  orderModalClose = () => {
    this.setState({ orderModalOpen: false, orderData: [] });
  };

  render() {
    const { classes, allProduct, userRedeemPoint } = this.props;
    const { loading } = this.state;
    let disableAddOrder = false;

    if (loading) {
      return <CircularLoader />;
    }

    if (this.state.orderData.length <= 0) {
      disableAddOrder = true;
    }

    if (allProduct && allProduct.length <= 0) {
      return (
        <div className={classes.noData}>
          <DataNotFound
            type="coworker"
            text="You don't have any Product Listed!!"
          />
        </div>
      );
    }
    return (
      <React.Fragment>
        <main className={classes.layout}>
          <div className={classes.banner}>
            <img
              src={bannerImage}
              alt=""
              width="100%"
              height="50%"
              className={classes.banner}
            />
            <Typography component="h1" className={classes.title}>
              Find the Perfect
              <br /> Products for You!!!
            </Typography>
            <img
              src={bannerImage2}
              alt=""
              width="100%"
              height="50%"
              className={classes.bannerImage}
            />
          </div>
          <Paper className={classes.paper}>
            <div className={classes.cartSection}>
              <div className={classes.pointSecton}>
                <img src={points} alt="img" />
                <span className={classes.pointsNo}>
                  {userRedeemPoint && userRedeemPoint.merchandise_points}
                </span>
              </div>
              <div
                className={classNames(
                  disableAddOrder ? classes.disableAddOrder : classes.cartIcon
                )}
              >
                <ShoppingCart
                  color="white"
                  size={24}
                  className={classes.icon}
                  onClick={() => this.addOrderPopup()}
                />
              </div>
            </div>
            <Grid container spacing={2}>
              {allProduct &&
                allProduct.map((element) => {
                  return (
                    <Grid
                      item
                      lg={4}
                      xs={12}
                      sm={6}
                      key={element.id}
                      className={classes.coworkerCardContainer}
                    >
                      <MerchandiseProductCard
                        data={element}
                        history={this.props.history}
                        type="addOrder"
                        addOrder={this.addOrder}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          </Paper>
        </main>

        <PlaceOrderDetailModal
          openOrderDetailModal={this.state.orderModalOpen}
          closeOrderDetailModal={this.orderModalClose}
          visitorID={this.state.walkthroughID ? this.state.walkthroughID : ""}
          data={this.state.orderData}
          points={userRedeemPoint && userRedeemPoint.merchandise_points}
        />
      </React.Fragment>
    );
  }
}

AddtoCart.propTypes = {};

const mapStateToProps = (state) => {
  return {
    addProduct: state.allMerchandiseOrder.addProduct,
    // loading: state.coworkers.loading,
    noMoreData: state.coworkers.noMoreData,
    allProduct: state.allMerchandiseOrder.allProduct,
    userRedeemPoint: state.allMerchandiseOrder.userRedeemPoint,
  };
};

export default connect(mapStateToProps, {
  getMerchandiseProduct,
  getUserMerchandisePoint,
})(withStyles(styles)(AddtoCart));
