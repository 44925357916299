/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import Walkthrough from "./Walkthrough";
import { getEvents } from "../../Actions/eventActions";
import { CircularLoader } from "../../Components/Common/Spinner";
import DataNotFound from "../../Components/Common/DataNotFound";
import Header from "../../Components/Layout/Header";
import EventCard from "../../Components/Common/Cards/EventCard";

const $quoteSym = " ";
const styles = () => ({
  events_section: {
    marginTop: "50px",
    padding: "40px",
  },
  event_card: {
    margin: "15px 0px",
  },
  noEvent: {
    padding: "10px 24px",
  },
  screenheader: {
    fontSize: "16px",
    fontWeight: "600",
    position: "relative",
    "&:after": {
      top: "24px",
      left: "0px",
      width: "24px",
      borderBottom: "3px solid #fb0",
      content: `'${$quoteSym}'`,
      position: "absolute",
    },
  },
  noVisitor: {
    paddingTop: "18px",
  },
});

const VisitorEvent = (props) => {
  // constructor(props) {
  //   super(props);
  //   this.state = {};
  // }

  // render() {
  //   return (
  //     <main>
  //             <Header />
  //             <Events />
  //     </main>
  //   );
  // }

  const { classes } = props;
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState([]);
  const [modalOpen, setmodalOpen] = useState(false);
  const [eventid, seteventid] = useState();

  useEffect(() => {
    props.getEvents("", "", "today");
  }, []);

  useEffect(() => {
    setEvents(props.events.today);
  }, [props.events]);

  useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);

  if (loading) {
    return <CircularLoader />;
  }
  if (!events) {
    return <div></div>;
  }

  const handleClickOpen = (eid) => {
    setmodalOpen(true);
    seteventid(eid);
  };

  const handleClose = () => {
    setmodalOpen(false);
    // props.history.push("/Dashboard")
  };

  const handleCloseButton = () => {
    setmodalOpen(false);
    props.history.push("/Dashboard");
  };

  const renderEventCards = (event) => {
    if (event && event.length <= 0) {
      return (
        <DataNotFound
          type="event"
          text="You don't have any events today!!"
          subtext=""
        />
      );
    }
    return (
      event &&
      event.map((element, i) => {
        if (i > 2) {
          return "";
        }
        return (
          <Grid item lg={4} xs={12} sm={6} key={element.id}>
            <EventCard
              data={element}
              history={props.history}
              view="security"
              handleClickOpen={handleClickOpen}
              handleClose={handleClose}
            />
          </Grid>
        );
      })
    );
  };
  return (
    <div className={classes.root}>
      <div className={classes.main}>
        <Header />

        <div className={classes.events_section}>
          <span className={classes.screenheader} align="left">
            Today&apos;s Events
          </span>

          <div className={classes.event_card} style={{ marginTop: "15px" }}>
            <Grid container spacing={2}>
              {renderEventCards(events)}
            </Grid>
          </div>
        </div>
        <Walkthrough
          data={modalOpen}
          handleClose={handleClose}
          contact={props.location.state}
          visitordata={props.location.state && props.location.state}
          purpose="for_attend_event"
          eventid={eventid}
          history={props.history}
          handleCloseButton={handleCloseButton}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    events: state.events,
    loading: state.events.loading,
    errors: state.errors,
  };
};

export default connect(mapStateToProps, { getEvents })(
  withStyles(styles)(VisitorEvent)
);
