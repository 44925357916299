import React from "react";
import {
  Card,
  CardContent,
  Typography,
  withStyles,
  Paper,
  Grid,
  LinearProgress,
  CardActions,
  Button,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { checkPermission } from "../../../Utils/checkPermission";
import NameTruncate from "../NameTruncate";
import { spacecapitalize } from "../../../Utils/StringFunctions";
import Default from "../../../Assets/default_space.png";

const styles = (theme) => {
  const borderRadius = theme.shape.borderRadius * 4;

  return {
    card: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      boxShadow: "0 5px 10px rgba(0,0,0,0.04), 0 5px 5px rgba(0,0,0,0.05)",
      transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
      backgroundColor: "#FCFDFE",
      position: "relative",
      border: "1px solid #EFEFEF",
      borderRadius: "5px",
    },

    cardContent: {
      flexGrow: 1,
      textAlign: "left",
      padding: "0px",
      paddingTop: "5px",
      "& p": {
        color: theme.palette.font.head1,
      },
      "& h2": {
        color: "#82A3FE",
      },
      "& h4": {
        color: theme.palette.font.head2,
        fontWeight: "normal",
      },
    },
    containerPaper: {
      border: `1px solid ${theme.palette.border.color}`,
      padding: "0px 10px",
    },
    avatar: {
      width: "100%",
      height: 200,
      position: "relative",
      borderTop: "9px",
    },
    image: {
      height: "auto",
      cursor: "pointer",
    },
    spaceInfo: {
      display: "flex",
      flexDirection: "row",
      margin: "0px 0px 7px",

      "& p": {
        fontSize: "20px",
        color: "#262525eb",
        textTransform: "capitalize",
        fontWeight: "500",
      },
    },
    cardFooter: {
      justifyContent: "space-around",
      "& a": {
        textDecoration: "none",
      },
      "& button": {
        margin: "28px 8px 3px 8px",
        fontSize: 12,
        borderRadius: `${borderRadius}px`,
        backgroundColor: "#0997c8",
        color: "#fff",
        padding: "6px 27px",
        "&:hover": {
          backgroundColor: "#0997c8",
        },
      },
    },
    percentage: {
      float: "right",
    },
    progressbar: {
      padding: "4px",
      borderRadius: "10px",
      margin: "5px",
    },
    bottomtitle: {
      marginTop: "6px",
    },
    spaceDetail: {
      fontSize: "14px",
      textTransform: "capitalize",
    },
  };
};
const themered = createMuiTheme({
  overrides: {
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: "red",
      },
      barColorPrimary: {
        backgroundColor: "b2dfdb",
      },
    },
  },
});
const themegreen = createMuiTheme({
  overrides: {
    MuiLinearProgress: {
      // Name of the component ⚛️ / style sheet
      colorPrimary: {
        backgroundColor: "b2dfdb",
      },
      barColorPrimary: {
        backgroundColor: "green",
      },
    },
  },
});
const themeyello = createMuiTheme({
  overrides: {
    MuiLinearProgress: {
      // Name of the component ⚛️ / style sheet
      colorPrimary: {
        backgroundColor: "b2dfdb",
      },
      barColorPrimary: {
        backgroundColor: "yellow",
      },
    },
  },
});
function SpaceCard(props) {
  const { classes, data } = props;

  // const state = {
  //   completed: 0
  // };

  // const progress = () => {
  //   const { completed } = this.state;
  //   if (completed === 100) {
  //     this.setState({ completed: 0 });
  //   } else {
  //     const diff = Math.random() * 10;
  //     this.setState({ completed: Math.min(completed + diff, 100) });
  //   }
  // };

  return (
    <Card className={classes.card}>
      <div className={classes.profileImage}>
        <div className={classes.image}>
          <Link
            to={`/space/${data._id}`}
            onClick={() => localStorage.setItem("id", data._id)}
          >
            <img
              className={classes.avatar}
              src={data.banner ? data.banner : Default}
              alt="logo"
            />
          </Link>
        </div>
      </div>
      <Paper className={classes.containerPaper}>
        <CardContent className={classes.cardContent}>
          <div className={classes.spaceInfo}>
            <div className={classes.eventParticipant}>
              <Typography
                gutterBottom
                component="p"
                variant="subtitle2"
                className={classes.companyText}
              >
                {data.space_title}
              </Typography>
            </div>
          </div>
        </CardContent>
        <CardContent className={classes.cardContent}>
          <div>
            <div className={classes.eventParticipant}>
              <Grid
                item
                container
                xs={12}
                lg={12}
                alignItems="center"
                alignContent="center"
                direction="row"
                justify="center"
              >
                <Grid item xs={6} lg={6}>
                  <Typography variant="subtitle2">Building Name</Typography>

                  <Typography
                    variant="subtitle1"
                    className={classes.spaceDetail}
                  >
                    <NameTruncate
                      text={spacecapitalize(data.building_name)}
                      amount={15}
                    />
                  </Typography>
                </Grid>
                <Grid item xs={6} lg={6}>
                  <Typography variant="subtitle2">Area</Typography>

                  <Typography
                    variant="subtitle1"
                    className={classes.spaceDetail}
                  >
                    {data.area}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </div>
        </CardContent>
        <CardContent className={classes.cardContent}>
          <div>
            <div className={classes.eventParticipant}>
              <Grid
                item
                container
                xs={12}
                lg={12}
                alignItems="center"
                alignContent="center"
                direction="row"
                justify="center"
              >
                <Grid item xs={6} lg={6}>
                  <Typography variant="subtitle2">City</Typography>
                  <Typography
                    variant="subtitle1"
                    className={classes.spaceDetail}
                  >
                    {data.city}
                  </Typography>
                </Grid>
                <Grid item xs={6} lg={6}>
                  <Typography variant="subtitle2">Total Seats</Typography>

                  <Typography
                    variant="subtitle1"
                    className={classes.spaceDetail}
                  >
                    {data.total_seats}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </div>
        </CardContent>
        <Typography variant="subtitle2" className={classes.bottomtitle}>
          Occupancy
        </Typography>
        <div className={classes.root}>
          {data.occupancy < 30 && (
            <ThemeProvider theme={themered}>
              <LinearProgress
                variant="determinate"
                value={data.occupancy}
                className={classes.progressbar}
              />
            </ThemeProvider>
          )}
          {data.occupancy > 30 && data.occupancy <= 70 && (
            <ThemeProvider theme={themeyello}>
              <LinearProgress
                variant="determinate"
                value={data.occupancy}
                className={classes.progressbar}
              />
            </ThemeProvider>
          )}
          {data.occupancy > 70 && (
            <ThemeProvider theme={themegreen}>
              <LinearProgress
                variant="determinate"
                value={data.occupancy}
                className={classes.progressbar}
              />
            </ThemeProvider>
          )}
          <Typography variant="subtitle2" className={classes.percentage}>
            {data.occupancy}%
          </Typography>
        </div>
        {checkPermission("SPACE_UPDATE") && (
          <CardActions className={classes.cardFooter}>
            <Button
              variant="contained"
              className={classes.button}
              component={Link}
              to={`/space/edit/${data._id}`}
            >
              Edit
            </Button>
          </CardActions>
        )}
      </Paper>
    </Card>
  );
}
export default withStyles(styles)(SpaceCard);
