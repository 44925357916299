import React from 'react'
import Card from '@material-ui/core/Card'
import {
  CardActions,
  CardContent,
  Button,
  Typography,
  Paper,
  Grid,
  withStyles,
} from '@material-ui/core'
import Green from '@material-ui/core/colors/green'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import CreateIcon from '@material-ui/icons/Create'
import {
  Instagram,
  Linkedin,
  Twitter,
  FacebookRoundIcon,
  FacebookGrey,
  InstagramGrey,
  TwitterGrey,
  LinkedinGrey,
} from '../../../Assets/index'
import defaultLogo from '../../../Assets/default_logo.png'
import { checkPermission } from '../../../Utils/checkPermission'
import CompanyStatus from '../CompanyStatus'
import { spacecapitalize } from '../../../Utils/StringFunctions'
import SocialButton from '../SocialButton'
import NameTruncate from '../NameTruncate'

const moment = require('moment')

const styles = (theme) => {
  const borderRadius = theme.shape.borderRadius * 4

  return {
    cardGrid: {
      padding: `${theme.spacing(8)}px 0`,
    },
    root: {
      ':&last-child': {
        paddingBottom: '0px',
      },
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      border: '1px solid #EFEFEF',
      transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
      backgroundColor: '#FCFDFE',
      position: 'relative',
      boxShadow: '0px 0px 20px rgba(0,0,0,0.05)',
      '&:hover': {
        transform: 'scale(1.01)',
        boxShadow:
          'rgb(178 183 187 / 6%) 0px 2px 4px 6px, rgb(212 219 226 / 60%) 0px 2px 10px 0px',
      },
    },
    cardMedia: {
      paddingTop: '56.25%', // 16:9
    },
    cardContent: {
      flexGrow: 1,
      textAlign: 'center',
      '& p': {
        color: theme.palette.font.head1,
      },
      '& h2': {
        color: '#4D7CFE ',
        fontWeight: '600',
      },
      '& h4': {
        color: theme.palette.font.head2,
        fontWeight: 'normal',
      },
    },
    profileImage: {
      display: 'flex',
      flexDirection: 'row',
      height: 50,
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '23px',
    },
    avatar: {
      width: 60,
      height: 60,
      position: 'relative',
      borderRadius: '5px',
      objectFit: 'contain',
    },
    status: {
      color: 'white',
      backgroundColor: Green[700],
      padding: '5px 8px',
      fontSize: 14,
    },
    image: {
      height: 'auto',
      // borderRadius: "10%",
      border: '1px solid #fff',
      marginBottom: '23px',
      // boxShadow: "0 1px 8px rgba(21, 20, 20, 0.08), 0 0px 1px rgba(0,0,0,0.05)"
      boxShadow: '0 0px 0px rgba(21, 20, 20, 0.08), 0 0px 0px rgba(0,0,0,0.05)',
    },
    eventInfo: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      justify: 'center',
      alignItems: 'center',
      marginTop: '-20px',
      '& p': {
        fontSize: '14px',
        color: '#626262',
      },
    },
    teamAvtar: {
      display: 'flex',
      flexDirection: 'row',
    },
    footerContainer: {
      marginTop: '10px',
      color: theme.palette.font.head1,
      fontSize: '12px',
    },
    footerHeader: {
      fontSize: '20px',
    },
    cardFooter: {
      justifyContent: 'center',
      padding: '8px 38px 8px',
      marginTop: '-1px',
      '& a': {
        textDecoration: 'none',
      },
      '& button': {
        margin: theme.spacing(1),
        fontSize: 10,
        border: '1px solid #959595',
        borderRadius: `${borderRadius}px`,
        backgroundColor: '#fff',
        color: '#959595',
        '&:hover': {
          backgroundColor: '#fff',
        },
      },
    },
    customBadge: {
      color: 'white',
      padding: '2px 10px',
      right: '11px',
      clipPath: 'polygon(90% 0, 79% 50%, 90% 100%, 0% 100%, 0% 100%, 0% 0%)',
      fontSize: '10px',
      fontWeight: 'bold',
      height: '21px',
      textTransform: 'capitalize',
      display: 'flex',
      justifyContent: 'flex-start',
      width: '125px',
    },
    enterprise: {
      backgroundColor: '#55A3F1',
    },
    flexi_desk: {
      backgroundColor: '#00BCD4',
    },
    dedicated_desk: {
      backgroundColor: '#5C6BC0',
    },
    dedicated_cabin: {
      backgroundColor: '#9575CD',
    },
    Trial: {
      backgroundColor: theme.palette.applications.trial,
    },
    social: {
      height: '40px',
      width: '40px',
      padding: '8px',
    },
    company_name: {
      marginTop: 10,
      fontWeight: 'bold',
      textTransform: 'capitalize',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    companyText: {
      fontSize: '12px',
    },
    edit_link: {
      textDecoration: 'none',
      color: 'grey',
      border: '1px solid #d1d1d1',
      borderRadius: '56px 38px 54px 100px',
      borderTop: 'none',
      borderRight: 'none',
      backgroundColor: '#d1d1d1',
      padding: '2px 4px 1px 5px',
      position: 'absolute',
      top: '-2px',
      right: '-4px',
    },
    editbutton: {
      height: '35px',
      width: '34px',
    },
    cardheader: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    companyDetail: {
      color: '#ababab',
      fontSize: '12px',
      fontWeight: 'normal',
    },
    pointer: {
      cursor: 'pointer',
    },
  }
}

const CompanyCard = (props) => {
  const { classes, data, history } = props
  const handleCardClick = () => {
    const path = `/company/${data._id}`

    if (history) history.push(path)
  }
  return (
    <div>
      <Card className={classes.card}>
        <Paper className={classes.containerPaper}>
          <div className={classes.cardheader}>
            <Grid
              className={classNames(
                classes.customBadge,
                classes[data.client_type]
              )}
            >
              {spacecapitalize(data.client_type)}
            </Grid>
            {data.status !== 'inactive' && checkPermission('COMPANY_UPDATE') && (
              <Link
                className={classes.edit_link}
                to={`/company/edit/${data._id}`}
              >
                <CreateIcon fontSize="small" />
              </Link>
            )}
          </div>
          <CardContent className={classes.cardContent}>
            <Grid
              className={classNames(classes.image_border, classes.pointer)}
              onClick={handleCardClick}
            >
              <div className={classes.profileImage}>
                <div className={classes.image}>
                  <img
                    className={classes.avatar}
                    src={data.logo ? data.logo : defaultLogo}
                    alt=""
                  />
                </div>
              </div>
            </Grid>
            <Grid
              direction="row"
              className={classes.pointer}
              onClick={handleCardClick}
            >
              <Typography component="h2" className={classes.company_name}>
                <NameTruncate
                  text={data.display_name || data.company_name}
                  amount={26}
                />
              </Typography>
            </Grid>
            <div style={{ clear: 'both' }} />
            <Grid
              container
              alignItems="center"
              alignContent="center"
              direction="row"
              justify="center"
              className={classes.pointer}
              onClick={handleCardClick}
            >
              <Grid item>
                <Typography
                  gutterBottom
                  component="p"
                  variant="subtitle2"
                  className={classes.companyText}
                >
                  {spacecapitalize(data.industry)}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              className={classNames(classes.root, classes.pointer)}
              spacing={2}
              // onClick={handleCardClick}
            >
              <Grid item xs={12}>
                <Grid
                  container
                  className={classes.iconContainer}
                  justify="center"
                  spacing={2}
                >
                  <Grid item>
                    <Paper className={classes.paper}>
                      {data.linkedin ? (
                        <SocialButton type={Linkedin} url={data.linkedin} />
                      ) : (
                        <SocialButton type={LinkedinGrey} disable />
                      )}
                    </Paper>
                  </Grid>
                  <Grid item>
                    <Paper className={classes.paper}>
                      {data.facebook ? (
                        <SocialButton
                          type={FacebookRoundIcon}
                          url={data.facebook}
                        />
                      ) : (
                        <SocialButton type={FacebookGrey} disable />
                      )}
                    </Paper>
                  </Grid>
                  <Grid item>
                    <Paper className={classes.paper}>
                      {data.twitter ? (
                        <SocialButton type={Twitter} url={data.twitter} />
                      ) : (
                        <SocialButton type={TwitterGrey} disable />
                      )}
                    </Paper>
                  </Grid>
                  <Grid item>
                    <Paper className={classes.paper}>
                      {data.instagram ? (
                        <SocialButton type={Instagram} url={data.instagram} />
                      ) : (
                        <SocialButton type={InstagramGrey} disable />
                      )}
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>

          {checkPermission('COMPANY_CRITICAL_DETAILS') && (
            <CardContent
              className={classNames(classes.cardContent, classes.pointer)}
              onClick={handleCardClick}
            >
              <div className={classes.eventInfo}>
                <div className={classes.eventParticipant}>
                  <Typography variant="subtitle2" component="p">
                    {data.total_seats}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    className={classes.companyDetail}
                  >
                    Seats
                  </Typography>
                </div>
                <div className={classes.eventDate}>
                  <Typography variant="subtitle2" component="p">
                    {parseFloat(data.available_credits).toFixed(2)}
                  </Typography>

                  <Typography
                    variant="subtitle2"
                    className={classes.companyDetail}
                  >
                    Monthly credit
                  </Typography>
                </div>
                <div className={classes.eventDate}>
                  <Typography variant="subtitle2" component="p">
                    {moment(data.contract_end_date).format('DD/MM/YYYY')}
                  </Typography>

                  <Typography
                    variant="subtitle2"
                    className={classes.companyDetail}
                  >
                    Contract expiry
                  </Typography>
                </div>
              </div>
            </CardContent>
          )}
        </Paper>

        <CardActions className={classes.cardFooter}>
          <Link to={`/company/${data._id}`}>
            <Button
              size="small"
              variant="contained"
              color="primary"
              className={classes.button}
            >
              View More
            </Button>
          </Link>
          {(checkPermission('COMPANY_STATU_VIEW') ||
            localStorage.getItem('role') === 'communitymanager') && (
            <CompanyStatus
              selectedItem={data.status}
              id={data._id}
              key={data._id}
              changeStatus={props.changeStatus}
            />
          )}
        </CardActions>
      </Card>
    </div>
  )
}
export default withStyles(styles)(CompanyCard)
