import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import { SelectField } from "../../Components/Common/Form";
import TextareaField from "../../Components/Common/Form/TextareaField";
import { checkPermission } from "../../Utils/checkPermission";
import {
  changeStatus,
  getTicket,
  getTickets,
} from "../../Actions/helpdeskActions";
import {
  HelpdeskStatusTypes,
  closedStatusTypes,
  AdminHelpdeskStatusTypes,
} from "../../Components/Common/Types/HelpdeskStatusType";

const $quoteSym = " ";
const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  form_modal: {
    minWidth: "500px",
  },
  modalHeader: {
    paddingBottom: "5px",
    fontSize: "20px",
    fontWeight: "600",
    position: "relative",
    "&:after": {
      top: "46px",
      left: "26px",
      width: "24px",
      borderBottom: "3px solid #fb0",
      content: `'${$quoteSym}'`,
      position: "absolute",
    },
  },
  input_field: {
    border: "1px solid #f5f5f5",
    height: "81%",
    position: "relative",
    width: "100%",
    padding: "6px 8px",
    margin: "0px",
    // borderBottom: "none",
    fontFamily:
      "'-apple-system,BlinkMacSystemFont','Segoe UI','Roboto','Helvetica Neue','Arial,sans-serif','Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol'",
    color: "grey",
    fontSize: "14px",
  },
  eventsubtitle1: {
    fontWeight: "normal",
    fontSize: "14px",
    textAlign: "left",
    color: "#626262",
  },
  addButton: {
    color: "#fff",
    marginRight: "20px",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  cancelButton: {
    marginRight: "25px",
    backgroundColor: "#dddddd",
    color: "#151039",
    "&:hover": {
      color: "#151039",
      backgroundColor: "#d1d1d1!important",
    },
  },
  dialogContent: {
    height: "300px",
  },
});
// const StatusOptions = [
//   { label: "Open", value: "open" },
//   { label: "In Process", value: "inprocess" },
//   { label: "Pending", value: "pending" },
//   { label: "On Board", value: "onboard" },
//   { label: "Closed", value: "closed" }
// ];
// const CoworkerStatusOptions = [
//   { label: "Closed", value: "closed" },
//   { label: "Reopen", value: "reopen" }
// ];
class ChangeStatusPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // errors: {}
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.status !== prevState.status) {
      return { status: nextProps.status };
    }
    return [];
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSuccess = () => {
    this.props.close();
  };

  render() {
    const { classes } = this.props;
    const selectedVal = this.props.selected;
    const selectedValue = [];
    const role = localStorage.getItem("role");
    if (selectedVal === "closed") {
      closedStatusTypes.map((status) => {
        if (status.value === selectedVal) {
          selectedValue.push({
            value: status.value,
            label: status.label,
          });
        }
        return null;
      });
    } else if (selectedVal === "reopen") {
      selectedValue.push({
        value: "reopen",
        label: "Reopen",
      });
    } else if (selectedVal !== "closed") {
      HelpdeskStatusTypes.map((status) => {
        if (status.value === selectedVal) {
          selectedValue.push({
            value: status.value,
            label: status.label,
          });
        }
        return null;
      });
    }
    return (
      <Dialog
        open={this.props.data}
        onClose={this.props.close}
        aria-labelledby="form-dialog-title"
      >
        <Formik
          // initialValues={{ status: selectedVal }}
          onSubmit={(values, { setErrors, setSubmitting }) => {
            const statusVal = values.status
              ? values.status.value
              : this.props.selected;
            // eslint-disable-next-line no-param-reassign
            // values.status = statusVal;
            // eslint-disable-next-line no-param-reassign
            values.status = statusVal;
            const id = this.props.ticket_id;
            this.props.changeStatus(
              id,
              values,
              setErrors,
              this.props.history,
              setSubmitting
            );
            this.handleSuccess();
          }}
        >
          {({ setFieldValue, values, errors, touched }) => (
            <Form className={classes.form_modal}>
              <DialogTitle
                id="issue-category-dialog-title"
                className={classes.modalHeader}
              >
                {this.props.selected === "revoked"
                  ? "Revoke Ticket"
                  : "Change Status"}
              </DialogTitle>
              <DialogContent
                className={
                  this.props.selected === "revoked" ? "" : classes.dialogContent
                }
              >
                <Grid container spacing={2}>
                  {checkPermission("HELPDESK_TICKET_STATUS_UPDATE") && (
                    <Grid item xs={6} sm={6}>
                      {(selectedVal === "closed" || selectedVal === "reopen") && (
                        <SelectField
                          value={values.status}
                          name="status"
                          id="status"
                          label="Status"
                          onChange={setFieldValue}
                          error={errors.status}
                          touched={touched.status}
                          options={closedStatusTypes}
                          defaultValue={selectedValue}
                          selectedItem={this.props.selected}
                        />
                      )}
                      {/* {selectedVal !== "closed" && (role !== "admin" || role !== "communitymanager") && (
                        <SelectField
                          value={values.status}
                          name="status"
                          id="status"
                          label="Status"
                          onChange={setFieldValue}
                          error={errors.status}
                          touched={touched.status}
                          options={HelpdeskStatusTypes}
                          defaultValue={selectedValue}
                          selectedItem={this.props.selected}
                        />
                      )} */}
                      {(selectedVal === "onhold" || selectedVal === "open" || selectedVal === "inprocess" || selectedVal === "pending") && (
                        <SelectField
                          value={values.status}
                          name="status"
                          id="status"
                          label="Status"
                          onChange={setFieldValue}
                          error={errors.status}
                          touched={touched.status}
                          options={HelpdeskStatusTypes}
                          defaultValue={selectedValue}
                          selectedItem={this.props.selected}
                        />
                      )}
                      {(selectedVal === "qc_pass" || selectedVal === "qc_fail") && role === "admin" && (
                        <SelectField
                          value={values.status}
                          name="status"
                          id="status"
                          label="Status"
                          onChange={setFieldValue}
                          error={errors.status}
                          touched={touched.status}
                          options={AdminHelpdeskStatusTypes}
                          defaultValue={selectedValue}
                          selectedItem={this.props.selected}
                        />
                      )}
                    </Grid>
                  )}
                  <Grid item xs={12} sm={12}>
                    <Typography
                      className={classes.eventsubtitle1}
                      variant="subtitle1"
                      component="p"
                    >
                      <TextareaField
                        name="comment"
                        className={classes.input_field}
                        rows="3"
                      ></TextareaField>
                    </Typography>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.handleSuccess}
                  color="primary"
                  className={classes.cancelButton}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  className={classes.addButton}
                  type="submit"
                  variant="contained"
                >
                  {this.props.selected === "revoked" ? "Ok" : "Change"}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog >
    );
  }
}
const mapStateToProps = (state) => {
  return {
    status: state.helpdesk.status,
    errors: state.errors,
  };
};
export default connect(mapStateToProps, {
  changeStatus,
  getTicket,
  getTickets,
})(withStyles(styles)(ChangeStatusPopup));
