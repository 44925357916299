/* eslint-disable react/sort-comp */
import React from "react";
import { Grid, InputAdornment } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import FormLabel from "@material-ui/core/FormLabel";
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import {
  FacebookRoundIcon,
  Instagram,
  Linkedin,
  Twitter,
} from "../../Assets/index";
import { partnerCategories } from "../../Utils/dropDownData";
import { setEditorState } from "../../Utils";
import { CircularLoader } from "../../Components/Common/Spinner";
import InputField from "../../Components/Common/Form/InputField";
import SelectField from "../../Components/Common/Form/SelectField";
import UploadField from "../../Components/Common/Form/UploadField";
import RichEditorExample from "../../Components/Common/RichEditor";
import {
  addPartner,
  getPartner,
  updatePartner,
} from "../../Actions/partnerActions";
import ServiceDropdown from "../../Components/Common/Dropdowns/ServiceDropdown";

const styles = () => ({
  lightbg: {
    // padding: “24px”,
    backgroundColor: "#fff",
    padding: "10px 22px 35px",
  },
  label: {
    margin: "15px 0px 8px 0px",
    fontSize: "12px",
    fontWeight: "700",
  },
  button: {
    width: "148px",
    height: "46px",
    backgroundColor: "#dddddd",
    color: "#151039",
    "&:hover": {
      color: "#151039",
      backgroundColor: "#d1d1d1!important",
    },
  },
  Addbutton: {
    width: "148px",
    height: "46px",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  remove_icon: {
    position: "relative",
  },
  social: {
    height: "20px",
    width: "20px",
  },
  formfooter: {
    marginTop: "10px",
    paddingTop: "25px",
    marginRight: "-2%",
  },
  form: {
    marginLeft: "4px",
  },
  dropinsidetext: {
    marginTop: "31px",
  },
});

const validationShape = {
  company_name: Yup.string()
    .max(15, "")
    .trim()
    .required("This field is required."),
  banner: Yup.mixed().required("This field is required."),
  display_banner: Yup.mixed().required("This field is required."),
  categories: Yup.string().required("This field is required."),
  website: Yup.string().url(),
};
validationShape.description = Yup.mixed().test(
  "match",
  "This field is required",
  function check() {
    const finalContent =
      this.parent &&
      this.parent.editorState.getCurrentContent().getPlainText() !== "";
    if (finalContent === true) {
      return true;
    }
    return false;
  }
);
const PartnerSchema = Yup.object().shape(validationShape);

class PartnerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      value: "",
      id:
        this.props.match && this.props.match.params
          ? this.props.match.params.id
          : "",
      deletedKey: [],
    };
    this.handleClose = this.handleClose.bind(this);
  }

  // eslint-disable-next-line consistent-return
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.partner !== prevState.partner) {
      return { partner: nextProps.partner, loading: nextProps.partner.loading };
    }
    if (nextProps.errors) {
      return { errors: nextProps.errors };
    }
  }

  handleClose = () => {
    const path = "/partners";
    this.props.history.push(path);
  };

  formatDataAndCallAPI = (data, setErrors, setSubmitting) => {
    const values = { ...data };

    values.created_by = localStorage.getItem("user_id");
    values.space_id = localStorage.getItem("space_id");
    values.description = stateToHTML(values.editorState.getCurrentContent());
    const details =
      values.services &&
      // eslint-disable-next-line no-shadow
      values.services.map((details) => details && details.label);
    values.services = details;
    const categories =
      values.categories &&
      values.categories.map((category) => category && category.value);
    values.categories = categories;
    values.company_banner = values.display_banner;
    delete values.editorState;

    if (!this.state.id) {
      this.props.addPartner(
        values,
        setErrors,
        setSubmitting,
        this.props.history
      );
    } else {
      this.props.updatePartner(
        this.state.id,
        values,
        setErrors,
        setSubmitting,
        this.props.history
      );
    }
  };

  componentDidMount() {
    if (this.state.id) {
      this.props.getPartner(this.state.id);
    }
  }

  shouldComponentUpdate() {
    if (this.state.deletedKey.length > 0) {
      return false;
    }
    return true;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.partner !== this.props.partner) {
      const partner = { ...this.props.partner };
      this.setState(Object.assign([], partner), () => {});
    }
    if (prevProps.errors !== this.props.errors) {
      // eslint-disable-next-line react/no-unused-state
      this.setState({ errors: this.props.errors });
    }
  }

  onEditorStateChange = (html, name) => {
    this.setState({
      [name]: html,
    });
  };

  getInitialValues = (id) => {
    const { partner } = this.props;

    if (id && partner) {
      return {
        company_name: partner.company_name,
        company_logo: partner.company_logo,
        display_banner: partner.company_banner,
        banner: partner.banner,
        services: partner.services,
        presentation: partner.presentation,
        description: partner.description,
        work_profile: partner.work_profile,
        website: partner.website,
        facebook: partner.facebook,
        linkedin: partner.linkedin,
        twitter: partner.twitter,
        instagram: partner.instagram,
        categories: partnerCategories.filter(
          (option) =>
            partner.categories && partner.categories.includes(option.value)
        ),
        editorState: setEditorState(partner.description),
      };
    }
    return {
      company_name: "",
      company_logo: "",
      display_banner: "",
      banner: "",
      services: [],
      presentation: "",
      description: "",
      work_profile: "",
      website: "",
      facebook: "",
      linkedin: "",
      twitter: "",
      instagram: "",
      categories: [],
      // eslint-disable-next-line new-cap
      editorState: new EditorState.createEmpty(),
    };
  };

  setDelImageUrl = (url) => {
    const { deletedKey } = this.state;
    this.setState({ deletedKey: deletedKey.push(url) });
  };

  render() {
    const { classes } = this.props;
    const { id, loading } = this.state;

    return (
      <React.Fragment>
        <div className={classes.lightbg}>
          <Typography variant="h6" gutterBottom style={{ marginLeft: "4px" }}>
            {id ? "Update" : "Add"} Partner
          </Typography>
          <Formik
            initialValues={this.getInitialValues(id)}
            enableReinitialize={id}
            validationSchema={PartnerSchema}
            onSubmit={(values, { setErrors, setSubmitting }) => {
              this.formatDataAndCallAPI(values, setErrors, setSubmitting);
            }}
          >
            {({
              values,
              errors,
              touched,
              setFieldTouched,
              setFieldValue,
              handleSubmit,
              handleBlur,
            }) => (
              <form
                onSubmit={handleSubmit}
                className={classes.form}
                encType="multipart/form-data"
                autoComplete="off"
              >
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6}>
                    <InputField
                      name="company_name"
                      label="Company"
                      placeholder="Enter Company Name"
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <UploadField
                      label="Upload Logo"
                      name="company_logo"
                      id="company_logo"
                      onChange={setFieldValue}
                      value={values.company_logo}
                      folder="partners/logo"
                      delImageUrl={() => this.setDelImageUrl}
                    />
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <UploadField
                      label="Upload Banner"
                      name="banner"
                      id="banner"
                      onChange={setFieldValue}
                      value={values.banner}
                      folder="partners/logo"
                      delImageUrl={() => this.setDelImageUrl}
                    />
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <UploadField
                      label="Upload Display Banner"
                      name="display_banner"
                      id="display_banner"
                      onChange={setFieldValue}
                      value={values.display_banner}
                      folder="partners/logo"
                      delImageUrl={() => this.setDelImageUrl}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} style={{ paddingBottom: "0px" }}>
                    <FormLabel className={classes.label} component="legend">
                      Description
                    </FormLabel>
                    <RichEditorExample
                      name="description"
                      editorState={values.editorState}
                      onChange={setFieldValue}
                      onBlur={handleBlur}
                      placeholder="Brief Description"
                    />
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <InputField
                      name="work_profile"
                      label="Work Profile"
                      placeholder="Enter Portfolio Link"
                      className={classes.dropinsidetext}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <SelectField
                      value={values.categories}
                      name="categories"
                      id="categories"
                      label="Categories"
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      multi
                      error={errors.categories}
                      touched={touched.categories}
                      options={partnerCategories}
                    />
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <ServiceDropdown
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      errors={errors}
                      touched={this.props.touched}
                      name="services"
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <InputField
                      name="website"
                      label="Website"
                      placeholder="http://www.example.com"
                      className={classes.dropinsidetext}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <InputField
                      name="facebook"
                      label="Facebook"
                      placeholder="Enter Facebook Profile Link"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src={FacebookRoundIcon}
                              alt=""
                              className={classes.social}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <InputField
                      name="linkedin"
                      label="Linkedin"
                      placeholder="Enter Linkedin Profile Link"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src={Linkedin}
                              alt=""
                              className={classes.social}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <InputField
                      name="twitter"
                      label="Twitter"
                      placeholder="Enter Twitter Profile Link"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src={Twitter}
                              alt=""
                              className={classes.social}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <InputField
                      name="instagram"
                      label="Instagram"
                      placeholder="Enter Instagram Profile Link"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src={Instagram}
                              alt=""
                              className={classes.social}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>

                <div className={classes.formfooter}>
                  <Grid
                    container
                    item
                    display="flex"
                    justify="flex-end"
                    alignItems="center"
                    direction="row"
                  >
                    <Grid xs={6} sm={2}>
                      <Button
                        color="primary"
                        onClick={this.handleClose}
                        className={classes.button}
                        style={{ right: "-10px" }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid xs={6} sm={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        className={classes.Addbutton}
                      >
                        {id ? "Update" : "Add"}
                      </Button>
                    </Grid>
                  </Grid>
                  {loading && <CircularLoader />}
                </div>
              </form>
            )}
          </Formik>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    partner: state.partners.partner,
    auth: state.auth,
    errors: state.errors,
  };
};

export default connect(mapStateToProps, {
  addPartner,
  getPartner,
  updatePartner,
})(withStyles(styles)(withRouter(PartnerForm)));
