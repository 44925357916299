import React, { useState } from "react";
import { Typography, Grid, withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { MapPin } from "react-feather";
import Button from "@material-ui/core/Button";
import { capitalize } from "../../Utils/StringFunctions";
import { EventCategory } from "../../Utils/dropDownData";
import { eventParticipants } from "../../Actions/eventActions";
import SelectTeamOptionModal from "./SelectTeamOptionModal";

const moment = require("moment");

const styles = () => ({
  main: {
    backgroundColor: "#FCFDFE",
  },
  eventName: {
    color: "#111111",
    fontWeight: "500",
    textTransform: "capitalize",
    width: "80%",
  },
  main_content: {
    padding: "5px 17px",
    marginLeft: "2%",
    marginRight: "2%",
    background: "#ffff",
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
  },
  location: {
    fontSize: 12,
    color: "#626262",
  },
  typo: {
    color: "#747474",
    fontSize: 12,
    fontStyle: "italic",
    margin: "7px 1px 0px",
  },
  avatar: {
    height: "25px",
    width: "25px",
  },
  highlight: {
    color: "#211b4e",
    textAlign: "center",
    fontWeight: 500,
  },
  date: {
    color: "#626262",
    fontWeight: "normal",
    fontSize: "12px",
  },
  register: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  left_days: {
    color: "#56B9B0",
    margin: "8px 5px 3px",
    fontWeight: 500,
    textTransform: "capitalize",
    fontSize: "12px",
  },
  button: {
    color: "white",
    backgroundColor: "#ff9100",
    "&:hover": {
      backgroundColor: "#d47902 !important",
    },
  },
  banner: { maxHeight: "300px" },
  paidHighlight: {
    color: "#FF6D00 !important",
    fontWeight: 500,
    display: "flex",
    justifyContent: "center",
  },
  registerGrid: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "-2%",
  },
  socialButtonContainer: {
    marginLeft: "-7px",
  },
  locationIcon: {
    margin: "3px 2px 0px 0px",
    position: "relative",
    top: "1px",
  },
  eventNameWidth: {
    width: "80%",
  },
  registerButtonDiv: {
    display: "contents",
    justifyContent: "center",
    alignItems: "center",
  },
});

function AboutEventTopDetail(props) {
  const { classes, event } = props;
  const [modalOpen, setModalOpen] = useState(false);

  const endsIn = moment(event.start_time).fromNow(); // event left days
  const a = moment().format("LLL");
  const b = moment(event.start_time).format("LLL");
  const currentdate = moment(a);
  const createddate = moment(b);
  const remainingHours = currentdate.diff(createddate, "minutes");

  const endTime = moment(event.end_time).format("LLL");
  const endTimeDate = moment(endTime);
  const registerRemainingHours = currentdate.diff(endTimeDate, "minutes"); // Register event remaining hours

  const eventCategory = EventCategory.filter(
    (option) => option.value === event.event_category
  )[0]; // for event category

  // Register event for team and individual
  // eslint-disable-next-line consistent-return
  const registerEvent = (id, history) => {
    if (event.event_type === "individual") {
      props.eventParticipants(id, history, false);
    } else {
      return setModalOpen(true); // for team register open modalbox
    }
  };

  // for Create team close modal
  const createTeamModalClose = () => {
    setModalOpen(false);
  };

  return (
    <React.Fragment>
      <main className={classes.main}>
        <div className={classes.main}>
          <div className={classes.banner}>
            <img
              src={event.banner}
              alt=""
              width="100%"
              className={classes.banner}
            />
          </div>
          <div className={classes.main_content}>
            <Grid container className={classes.root} spacing={3}>
              <Grid item xs={12}>
                <Grid container className={classes.title} spacing={16}>
                  <Grid item sm={12} lg={12}>
                    <Typography component="h2" className={classes.eventName}>
                      {event.event_name}
                    </Typography>
                  </Grid>
                  <Grid item sm={4} lg={4}>
                    <Typography
                      component="p"
                      variant="subtitle1"
                      className={classes.location}
                    >
                      <MapPin size={13} className={classes.locationIcon} />
                      {event.space_id && event.space_id.space_title}&nbsp;
                      {event.space_id && event.space_id.building_name}
                    </Typography>
                    <Typography
                      component="p"
                      variant="subtitle2"
                      className={classes.typo}
                    >
                      {event.total_attendees}{" "}
                      {event.event_type === "individual" ? (
                        " People"
                      ) : (
                        <>{event.total_attendees <= 1 ? "Team" : "Teams"}</>
                      )}{" "}
                      {remainingHours >= 0 ? "Attended" : "Attending"}
                    </Typography>
                    {/* TODO : in release-2.0 */}
                    {/* <Grid item xs={4}>
                        <Grid
                          container
                          className={classes.avataricon}
                          justify="flex-start"
                          spacing={0}
                        >
                          <Grid item>
                            <Paper className={classes.paper}>
                              <img
                                src={Avatar1}
                                alt=""
                                className={classes.avatar}
                                style={{ borderRadius: "50%" }}
                              />
                            </Paper>
                          </Grid>
                          <Grid item>
                            <Paper className={classes.paper}>
                              <img
                                src={Avatar2}
                                alt=""
                                className={classes.avatar}
                              />
                            </Paper>
                          </Grid>
                          <Grid item>
                            <Paper className={classes.paper}>
                              <img
                                src={Avatar3}
                                alt=""
                                className={classes.avatar}
                              />
                            </Paper>
                          </Grid>
                          <Grid item>
                            <Paper className={classes.paper}>
                              <img
                                src={Avatar5}
                                alt=""
                                className={classes.avatar}
                              />
                            </Paper>
                          </Grid>
                        </Grid>
                      </Grid> */}
                  </Grid>

                  <Grid item sm={6} lg={6} style={{ display: "flex" }}>
                    <Grid item className={classes.workshop}>
                      {event.category_type === "paid" && (
                        <Typography
                          component="h2"
                          className={classes.paidHighlight}
                        >
                          {capitalize(eventCategory ? eventCategory.label : "")}{" "}
                          - ₹{event.price}
                        </Typography>
                      )}
                      {event.category_type === "free" && (
                        <Typography
                          component="h2"
                          className={classes.highlight}
                        >
                          {capitalize(eventCategory ? eventCategory.label : "")}{" "}
                          - {capitalize(event.category_type)}
                        </Typography>
                      )}
                      {/* Event schedule detail */}
                      <Typography
                        component="p"
                        variant="subtitle2"
                        className={classes.date}
                      >
                        {moment(event.start_date).format("LL")},{" "}
                        {moment(event.start_time).format("LT")} -{" "}
                        {moment(event.end_date).format("LL")},{" "}
                        {moment(event.end_time).format("LT")}
                      </Typography>
                    </Grid>
                  </Grid>
                  <div className={classes.registerButtonDiv}>
                    <Grid item sm={2} lg={2} className={classes.registerGrid}>
                      <Grid item className={classes.register}>
                        <Typography
                          component="h2"
                          className={classes.left_days}
                        >
                          {endsIn}
                        </Typography>
                        {registerRemainingHours < 0 && (
                          <Button
                            disabled={event.participant === "yes"}
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            onClick={() =>
                              registerEvent(event._id, props.history)
                            }
                          >
                            {event.participant === "yes"
                              ? `Registered`
                              : `Register`}
                          </Button>
                        )}
                        {registerRemainingHours >= 0 && (
                          <Button
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            disabled
                          >
                            {event.participant === "yes"
                              ? `Registered`
                              : `Register`}
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
        <SelectTeamOptionModal
          close={createTeamModalClose}
          data={modalOpen}
          eventID={event._id}
          eventData={event}
        />
      </main>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    errors: state.errors,
  };
};

export default connect(mapStateToProps, { eventParticipants })(
  withStyles(styles)(AboutEventTopDetail)
);
