import axios from "axios";
import {
  USER_DATA,
  USER_DATA_LOADING,
  USER_DATA_ERROR,
  EDIT_PROFILE,
  EDIT_PROFILE_ERROR,
  NOTIFICATION,
  NOTIFICATION_GET_ERROR,
} from "./types";
import { showSuccess } from "./ActionsHelper";
import { transformErrorsFromApi } from "./TransformError";

// Profile Loading
export const setUsersLoading = () => {
  return {
    type: USER_DATA_LOADING,
  };
};

export const getUserData = () => (dispatch) => {
  //   dispatch(setUsersLoading());
  axios
    .get(`/api/user/me`)
    .then((res) => {
      dispatch({
        type: USER_DATA,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: USER_DATA_ERROR,
        payload: err && err.res ? err.res.data : "",
      })
    );
};
export const editProfile = (id, data, setErrors, setSubmitting) => (
  dispatch
) => {
  dispatch(setUsersLoading());
  axios
    .put(`/api/user/${id}`, data)
    .then((res) => {
      dispatch({
        type: EDIT_PROFILE,
        payload: res.data,
      });
      dispatch(showSuccess(" ", "Profile Updated Successfully!"));
      setSubmitting(false);
    })
    .catch((err) => {
      const error = err.response;
      dispatch({ type: EDIT_PROFILE_ERROR, payload: error.data });
      setSubmitting(false);
      setErrors(transformErrorsFromApi(error));
    });
};

export const getNotification = () => (dispatch) => {
  //   dispatch(setUsersLoading());
  axios
    .get(`/api/notification/history`)
    .then((res) => {
      dispatch({
        type: NOTIFICATION,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: NOTIFICATION_GET_ERROR,
        payload: err && err.res ? err.res.data : "",
      })
    );
};
