/* eslint-disable react/no-unused-state */
import React from "react";
import { withStyles, Grid, Paper, Button } from "@material-ui/core";
import { connect } from "react-redux";
import DataNotFound from "../../Components/Common/DataNotFound";
import { CircularLoader } from "../../Components/Common/Spinner";
import { getMerchandiseProduct } from "../../Actions/merchandiseActions";
import MerchandiseProductCard from "../../Components/Common/Cards/MerchandiseProductCard";
import AddProductModal from "./AddProductModal";

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    flexGrow: 1,
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      padding: theme.spacing(3),
    },
  },
  coworkerCardContainer: {
    margin: "4px 0px",
  },
  addcoworker: {
    color: "#fff",
    padding: "7px 16px",
    borderRadius: "4px",
    textDecoration: "none",
    fontSize: "0.875rem",
    fontWeight: "500",
    marginLeft: "18px",
    boxShadow: "rgba(137, 163, 169, 0.3) 0px 3px 5px 2px",
    backgroundColor: "#211b4e !important",
    "&:hover": {
      backgroundColor: "#151039 !important",
    },
  },
  buttonSection: {
    display: "flex",
    padding: "24px 29px 0px 0px",
    justifyContent: "flex-end",
  },
});

class ViewProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      AddProductModalOpen: false,
    };
  }

  componentDidMount() {
    this.props.getMerchandiseProduct();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.addProduct !== prevProps.addProduct &&
      this.props.addProduct.success
    ) {
      this.props.getMerchandiseProduct();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.noMoreData !== prevState.noMoreData) {
      return {
        noMoreData: nextProps.noMoreData,
      };
    }
    if (nextProps.allProduct !== prevState.allProduct) {
      return {
        allProduct: nextProps.allProduct,
      };
    }
    return [];
  }

  handleOpen = () => {
    this.setState({ AddProductModalOpen: true });
  };

  handleClose = () => {
    this.setState({ AddProductModalOpen: false });
  };

  render() {
    const { classes, allProduct } = this.props;
    const { loading } = this.state;

    if (loading) {
      return <CircularLoader />;
    }

    if (allProduct && allProduct.length <= 0) {
      return (
        <DataNotFound
          type="coworker"
          text="No history found!!"
          subComponent={
            <div className={classes.DataNotFound}>
              {/* {checkPermission("COWORKER_CREATE") && (
                <Link to="/co-worker/add" className={classes.addcoworker}>
                  Add Co-worker
                </Link>
              )} */}
            </div>
          }
        />
      );
    }
    return (
      <React.Fragment>
        <main className={classes.layout}>
          <div className={classes.buttonSection}>
            <Button
              color="primary"
              size="small"
              onClick={this.handleOpen}
              className={classes.addcoworker}
            >
              Add Product
            </Button>
          </div>
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              {allProduct &&
                allProduct.map((element) => {
                  return (
                    <Grid
                      item
                      lg={4}
                      xs={12}
                      sm={6}
                      key={element.id}
                      className={classes.coworkerCardContainer}
                    >
                      <MerchandiseProductCard
                        data={element}
                        history={this.props.history}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          </Paper>
        </main>
        <AddProductModal
          openAddProductModal={this.state.AddProductModalOpen}
          closeAddProductModal={this.handleClose}
          visitorID={this.state.walkthroughID ? this.state.walkthroughID : ""}
        />
      </React.Fragment>
    );
  }
}

ViewProduct.propTypes = {};

const mapStateToProps = (state) => {
  return {
    allProduct: state.allMerchandiseOrder.allProduct,
    // loading: state.coworkers.loading,
    noMoreData: state.coworkers.noMoreData,
    addProduct: state.allMerchandiseOrder.addProduct,
  };
};

export default connect(mapStateToProps, { getMerchandiseProduct })(
  withStyles(styles)(ViewProduct)
);
