/* eslint-disable camelcase */
import axios from "axios";
import {
  ALL_ANNOUNCEMENT,
  ANNOUNCEMENT_LOADING,
  ADD_ANNOUNCEMENT,
  ANNOUNCEMENT_ERROR,
  LIKE_ANNOUNCEMENT,
  LIKE_ANNOUNCEMENT_ERROR,
  CREATE_ANNOUNCEMENT_COMMENT,
  ANNOUNCEMENT_COMMENT_ERROR,
  EDIT_ANNOUNCEMENT,
  EDIT_ANNOUNCEMENT_ERROR,
  DELETE_ANNOUNCEMENT,
  SINGLE_ANNOUNCEMENT
} from "./types";
import { showSuccess, showError } from "./ActionsHelper";
import { transformErrorsFromApi } from "./TransformError";

//  Loading
export const setAnnouncementLoading = () => {
  return {
    type: ANNOUNCEMENT_LOADING
  };
};

export const addAnnouncement = (
  userData,
  setErrors,
  setSubmitting
) => dispatch => {
  dispatch(setAnnouncementLoading());
  axios
    .post("/api/announcement", userData)
    .then(result => {
      dispatch({
        type: ADD_ANNOUNCEMENT,
        payload: result.data
      });
      dispatch(showSuccess("Announcement"));
      setSubmitting(false);
    })
    .catch(err => {
      const error = err.response || {};
      dispatch({ type: ANNOUNCEMENT_ERROR, payload: error.data });
      dispatch(showError("There was some error while adding announcement "));
      setSubmitting(false);
      setErrors(transformErrorsFromApi(error));
    });
};

export const getAnnouncement = () => dispatch => {
  setAnnouncementLoading();
  axios
    .get(`/api/announcement`)
    .then(result => {
      dispatch({
        type: ALL_ANNOUNCEMENT,
        payload: result.data
      });
    })
    .catch(err => {
      dispatch({
        type: ANNOUNCEMENT_ERROR,
        payload: err && err.response ? err.response.data : ""
      });
    });
};
export const likeAnnouncement = userData => dispatch => {
  dispatch(setAnnouncementLoading());
  axios
    .post("/api/announcement/like", userData)
    .then(result => {
      dispatch({
        type: LIKE_ANNOUNCEMENT,
        payload: result.data
      });
    })
    .catch(err => {
      const error = err.response || {};
      dispatch({ type: LIKE_ANNOUNCEMENT_ERROR, payload: error.data });
      dispatch(showError("There was some error while liking announcement "));
    });
};

export const createAnnouncementComment = (userData, id) => dispatch => {
  dispatch(setAnnouncementLoading());
  axios
    .post(`/api/announcement/comment/${id}`, userData)
    .then(result => {
      dispatch({
        type: CREATE_ANNOUNCEMENT_COMMENT,
        payload: result.data
      });
      dispatch(showSuccess("", " Comment added successfully!"));
    })
    .catch(err => {
      const error = err.response || {};
      dispatch({ type: ANNOUNCEMENT_COMMENT_ERROR, payload: error.data });
      dispatch(showError(" Error while Commenting a Announcement "));
    });
};

export const getSingleAnnouncement = id => dispatch => {
  setAnnouncementLoading();
  axios
    .get(`/api/announcement/${id}`)
    .then(result => {
      dispatch({
        type: SINGLE_ANNOUNCEMENT,
        payload: result.data
      });
    })
    .catch(err => {
      dispatch({
        type: ANNOUNCEMENT_ERROR,
        payload: err && err.response ? err.response.data : ""
      });
    });
};
export const editAnnouncement = (
  id,
  userData,
  setErrors,
  setSubmitting
) => dispatch => {
  dispatch(setAnnouncementLoading());
  axios
    .put(`/api/announcement/editannouncement/${id}`, userData)
    .then(result => {
      dispatch({
        type: EDIT_ANNOUNCEMENT,
        payload: result.data
      });
      dispatch(showSuccess("", "Announcement edited successfully!"));

      setSubmitting(false);
    })
    .catch(err => {
      const error = err.response || {};
      dispatch({ type: EDIT_ANNOUNCEMENT_ERROR, payload: error.data });
      dispatch(showError("There was some error while editing announcement "));
      // setSubmitting(false);
      setErrors(transformErrorsFromApi(error));
    });
};

export const deleteAnnouncement = id => dispatch => {
  dispatch(setAnnouncementLoading());
  axios
    .delete(`/api/announcement/${id}`)
    .then(result => {
      dispatch({
        type: DELETE_ANNOUNCEMENT,
        payload: result.data
      });
      dispatch(showSuccess("", "Announcement deleted successfully!"));
    })

    .catch(err => {
      dispatch({
        type: ANNOUNCEMENT_ERROR,
        payload: err && err.response ? err.response.data : ""
      });
    });
};
