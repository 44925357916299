import {
  GET_ALL_ISSUE_TITLE,
  ADD_ISSUE_TITLE,
  ISSUE_TITLE_LOADING,
  ISSUE_TITLE_ERROR,
  EDIT_ISSUE_TITLE,
  EDIT_ISSUE_TITLE_ERRORS,
  GET_SINGLE_ISSUE_TITLE
} from "../Actions/types";

const initialState = {
  issue_title: null,
  all_issue_title: null,
  loading: false,
  edit_issue_title: {},
  single_issue_title: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_ISSUE_TITLE:
      return {
        ...state,
        loading: false,
        all_issue_title: action.payload && action.payload.data
      };
    case ADD_ISSUE_TITLE:
      return {
        ...state,
        loading: false,
        issue_title: {
          success: true,
          data: action.payload && action.payload.data
        }
      };
    case ISSUE_TITLE_LOADING:
      return {
        ...state,
        loading: true
      };
    case ISSUE_TITLE_ERROR:
      return {
        ...state,
        loading: false,
        issue_title: {
          success: false,
          data: action.payload && action.payload.data
        }
      };
    case GET_SINGLE_ISSUE_TITLE:
      return {
        ...state,
        loading: false,
        single_issue_title: action.payload && action.payload.data
      };
    case EDIT_ISSUE_TITLE:
      return {
        ...state,
        loading: false,
        edit_issue_title: {
          success: true,
          data: action.payload && action.payload.data
        }
      };
    case EDIT_ISSUE_TITLE_ERRORS:
      return {
        ...state,
        loading: false,
        issue_title_error: { errors: false }
      };
    default:
      return state;
  }
}
