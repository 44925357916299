import React from 'react'
import { Paper, Tabs, Tab, Typography, withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import { getCoworker } from '../../Actions/coworkerActions'
import { checkPermission } from '../../Utils'
import AboutFreelancer from './AboutFreelancer'
import FreelancerPlanTab from './FreelancerPlanTab'
import FreelancerCreditHistory from './FreelancerCreditHistory'
import FreelancerCreditTopup from './FreelancerCreditTopup'
import FreelancerOnBoarding from './FreelancerOnBoarding'
import FreelancerExitProcess from './FreelancerExitProcess'

const styles = () => ({
  root: {
    paddingTop: '18px',
    paddingBottom: '15px',
  },
  noCoworker: {
    padding: '24px 24px 11px',
    color: 'lightgrey',
  },
  layout: {
    marginTop: '19px !important',
    padding: '20px 25px',
  },
  paper: {
    backgroundColor: '#fcfdfe',
  },
})
const AntTabs = withStyles({
  indicator: {
    color: '#435bf2',
    backgroundColor: ' #4682B4',
    top: '38px',
  },
})(Tabs)

const AntTab = withStyles(() => ({
  selected: {
    color: ' #4682B4',
  },
}))((props) => <Tab disableRipple {...props} />)

function TabContainer(props) {
  return (
    <Typography
      component="div"
      style={{ padding: '0px 14px', marginTop: ' 14px' }}
    >
      {props.children}
    </Typography>
  )
}

function FreelancerTabs(props) {
  const { classes } = props
  const [value, setValue] = React.useState(0)

  function handleChange(event, newValue) {
    setValue(newValue)
  }

  return (
    <Paper className={classes.root} style={{ backgroundColor: '#FCFDFE' }}>
      {checkPermission('COMPANY_CRITICAL_DETAILS') && (
        <AntTabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <AntTab label="Company" />
          <AntTab label="Plans" />
          <AntTab label="Credit History" />
          <AntTab label="Credit Top up" />
          <AntTab label="Onboarding" />
          <AntTab label="Exit Process" />
        </AntTabs>
      )}
      <TabContainer>
        {value === 0 && <AboutFreelancer company={props.company} />}

        {value === 1 && (
          <FreelancerPlanTab
            companyID={props.id}
            plans={props.plans}
            company={props.company}
            allplans={
              props.company &&
              props.company.plan_details &&
              props.company.plan_details
            }
          />
        )}
        {value === 2 && <FreelancerCreditHistory companyID={props.id} />}
        {value === 3 && (
          <FreelancerCreditTopup companyID={props.id} company={props.company} />
        )}
        {value === 4 && (
          <FreelancerOnBoarding companyID={props.id} company={props.company} />
        )}
        {value === 5 && (
          <FreelancerExitProcess companyID={props.id} company={props.company} />
        )}
      </TabContainer>
    </Paper>
  )
}

const mapStateToProps = (state) => {
  return {
    errors: state.errors,
    companyCoworkers: state.coworkers.companyCoworkers,
  }
}

export default connect(mapStateToProps, { getCoworker })(
  withStyles(styles)(FreelancerTabs)
)
